<template>
    <el-popover placement="right"
                width="450"
                trigger="click"
                v-model="show_popover"
                @hide="reset">
        <div class="container-listing rounded p-2"
             v-loading="loading">
            <div class="container-header d-flex">
                <h5 class="listing-heading">Enroll To Sequence</h5>
                <div class="ml-auto">
                    <el-button type="text"
                               class="btn-dialog-close"
                               size="large"
                               data-testid="close-popover"
                               @click="show_popover = false">
                        <i class="fa fa-remove"></i>
                    </el-button>
                </div>
            </div>
            <div class="container-body">
                <p>{{ helperText }}</p>
                <workflow-selector class="mb-2"
                                   v-model="selected_workflow_id"
                                   :multiple="false"
                                   data-testid="workflow-selector"
                                   @change="workflowChanged">
                </workflow-selector>
                <div class="mt-4"
                     v-if="model !== 'contact'">
                    <ul class="list-unstyled">
                        <li class="mb-2">
                            <div class="d-flex flex-row">
                                <div class="mr-2">
                                    <i class="fa fa-info-circle text-warning"></i>
                                </div>
                                <div>
                                    Contacts that are already enrolled in a sequence will not be enrolled.
                                </div>
                            </div>
                        </li>
                        <li class="mb-2">
                            <div class="d-flex flex-row">
                                <div class="mr-2">
                                    <i class="fa fa-info-circle text-warning"></i>
                                </div>
                                <div>
                                    Contacts the has a DNC status will not be enrolled.
                                </div>
                            </div>
                        </li>
                        <li class="mb-2">
                            <div class="d-flex flex-row">
                                <div class="mr-2">
                                    <i class="fa fa-info-circle text-warning"></i>
                                </div>
                                <div>
                                    Contact groups that has thousands of contacts will take time to enroll.
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="mt-4 text-center mb-3">
                    <el-button
                        :disabled="disabled"
                        class="mb-2"
                        type="success"
                        size="medium"
                        round
                        data-testid="enroll-contact-button-popover"
                        @click="enroll">
                        <strong>
                            <i class="fa fa-plus"></i> Enroll Contacts
                        </strong>
                    </el-button>
                </div>
            </div>
            <div v-if="selected_workflow && !selected_workflow.is_enrollable"
                 class="container-body text-center py-5">
                <img-empty class="w-75 mx-auto mb-3"/>
                <p class="lead">
                    <i class="fa fa-ban text-danger"></i> Enrollment is only allowed between <br/>
                    <strong>{{ selected_workflow.converted_open }}</strong>
                    and <strong>{{ selected_workflow.converted_close }}</strong>
                </p>
            </div>
        </div>
        <span
            v-if="phone_number"
            slot="reference">
            <el-popover
                placement="top"
                width="300"
                trigger="hover"
                popper-class="p-2"
                :disabled="!isNumberInvalidOrWrong(phone_number)">
                <span class="text-black-dk">
                    {{ getTooltipMessage(phone_number) }}
                </span>
                <span slot="reference">
                    <el-popover v-if="!enabledToAddSequences()"
                                ref="contact-secuence-number-popover"
                                placement="top"
                                width="200"
                                class="custom-popover"
                                popper-class="btn-primary"
                                trigger="hover">
                        <custom-message-display :config="customMessage('sequences.enroll')" />
                    </el-popover>
                    <el-button
                        v-if="model === 'contact'"
                        round
                        class="bold text-success"
                        size="small"
                        v-popover:contact-secuence-number-popover
                        :disabled="isNumberInvalidOrWrong(phone_number) || !enabledToAddSequences() || !isEnabledToEnroll"
                        data-testid="enroll-to-sequence-contact-button"
                        @click="showEnroller">
                        <strong>
                            <i class="fa fa-plus"></i>
                            <slot>Enroll To Sequence</slot>
                        </strong>
                    </el-button>

                    <el-popover v-if="!enabledToAddSequences()"
                                ref="tag-secuence-number-popover"
                                placement="top"
                                width="200"
                                class="custom-popover"
                                popper-class="btn-primary"
                                trigger="hover">
                        <custom-message-display :config="customMessage('sequences.enroll')" />
                    </el-popover>

                    <button
                        v-if="model !== 'contact'"
                        class="btn btn-block purple btn-sm"
                        @click="showEnroller"
                        v-popover:tag-secuence-number-popover
                        :disabled="!enabledToAddSequences() || !isEnabledToEnroll">
                        <i class="fa fa-user-plus pull-left"></i>
                        <slot>Enroll</slot>
                    </button>
                </span>
            </el-popover>
        </span>

        <template v-else>
            <el-popover v-if="!enabledToAddSequences()"
                        ref="contact-secuence-popover"
                        placement="top"
                        width="200"
                        class="custom-popover"
                        popper-class="btn-primary"
                        trigger="hover">
                <custom-message-display :config="customMessage('sequences.enroll')" />
            </el-popover>

            <el-button
                v-if="model === 'contact'"
                slot="reference"
                class="bold text-success"
                round
                size="small"
                v-popover:contact-secuence-popover
                data-testid="enroll-to-sequence-popover-button"
                :disabled="!enabledToAddSequences() || !isEnabledToEnroll">
                <strong>
                    <i class="fa fa-plus"></i>
                    <slot>Enroll To Sequence</slot>
                </strong>
            </el-button>

            <el-popover v-if="!enabledToAddSequences()"
                        ref="tag-secuence-popover"
                        placement="top"
                        width="200"
                        class="custom-popover"
                        popper-class="btn-primary"
                        trigger="hover">
                <custom-message-display :config="customMessage('sequences.enroll')" />
            </el-popover>

            <button
                v-if="model !== 'contact'"
                slot="reference"
                class="btn btn-block purple btn-sm"
                v-popover:tag-secuence-popover
                :disabled="!enabledToAddSequences() || !isEnabledToEnroll">
                <i class="fa fa-user-plus pull-left"></i>
                <slot>Enroll</slot>
            </button>
        </template>
    </el-popover>
</template>

<script>
import {contact_phone_number_mixin, kyc_mixin} from '../../mixins'
import {mapState} from 'vuex'
import WorkflowSelector from '../workflow-selector'
import ContactGroupSelector from "../contact-group-selector"
import ContactSelect from "../contact-select"
import ImgEmpty from "../misc/img-empty"
import CustomMessageDisplay from '../kyc/custom-message-display'

export default {
    name: "workflow-bulk-enroller",

    mixins: [contact_phone_number_mixin, kyc_mixin],

    components: {
        ImgEmpty,
        ContactSelect,
        ContactGroupSelector,
        WorkflowSelector,
        CustomMessageDisplay
    },

    props: {
        id: {
            required: true
        },

        model: {
            required: true,
            type: String
        },

        phone_number: {
            required: false
        }
    },

    data() {
        return {
            loading: false,
            show_popover: false,
            selected_workflow_id: null,
            selected_workflow: null,
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company,
            workflows: state => state.workflows
        }),

        disabled() {
            return !(this.selected_workflow && this.selected_workflow.is_enrollable)
        },

        helperText() {
            let text = ''

            switch (this.model) {
                case 'contact':
                    text = 'Choose the sequence you want this contact to enroll'
                    break
                case 'contact_list':
                    text = 'Choose the sequence you want these contacts to be enrolled'
                    break
                case 'tag':
                    text = 'Choose the sequence you want this tagged contacts to enroll'
                    break
            }

            return text
        },

        isEnabledToEnroll() {
            return this.current_company?.automation_enabled
        }
    },

    methods: {
        showEnroller() {
            setTimeout(_ => {
                if (this.show_popover) {
                    this.reset()

                    return
                }

                this.show_popover = true
            }, 100)
        },

        reset() {
            this.loading = false
            this.show_popover = false
            this.selected_workflow_id = null
            this.selected_workflow = null
            this.contact_group = null
        },

        workflowChanged(workflow) {
            this.selected_workflow = this.workflows.find(wf => wf.id === workflow)
        },

        enroll() {
            this.loading = true

            axios.post(`/api/v1/automations/workflows/${this.selected_workflow.id}/sequence-contacts`, {
                model: this.model,
                id: this.id
            }).then(res => {
                this.reset();
                this.$notify({
                    offset: 75,
                    title: 'Sequence',
                    message: res.data.message,
                    type: 'success',
                    showClose: true,
                    duration: 6000
                })

                this.$emit('enrolled')
            }).catch(err => {
                this.loading = false
                this.$notify({
                    offset: 75,
                    title: 'Error',
                    message: err.status_code !== 500 ? err.response.data.message : 'Encountered error while enrolling contacts',
                    type: 'error',
                    showClose: true,
                    duration: 6000
                })
            })
        }
    }
}
</script>
