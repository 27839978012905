var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-tool",
    {
      attrs: {
        "tool-name": _vm.tool.name,
        "tool-description": _vm.tool.description,
      },
      on: { back: _vm.goBack },
    },
    [
      _c("tag-selector", {
        staticClass: "w-100",
        attrs: {
          multiple: false,
          category_prop: _vm.TagCategory.CAT_CONTACTS,
          value: _vm.selectedTagIds,
        },
        on: { change: _vm.handleTagsChange },
        model: {
          value: _vm.selectedTagIds,
          callback: function ($$v) {
            _vm.selectedTagIds = $$v
          },
          expression: "selectedTagIds",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }