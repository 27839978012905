<template>
    <div id="reports-activity-reporting-tab">
        <reports-contact-dispositions v-if="base == 'Contact Dispositions'"></reports-contact-dispositions>
        <reports-call-dispositions v-else-if="base == 'Call Dispositions'"></reports-call-dispositions>
        <template v-else>
            <div class="row box">
                <div class="col-12 box-body">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="d-flex justify-content-end w-100"
                             v-if="base == 'Users'">
                            <div class="col-5 d-inline-block">
                                <ring-group-selector first_value="all"
                                                     label="Ring Group:"
                                                     value="all"
                                                     disabled_tooltip_content="Ring group selector is disabled when a team is selected. Reset the team selection to enable it."
                                                     :disabled="selected_team_id !== DEFAULT_SELECTED_TEAM_ID || !loaded"
                                                     @change="selectRingGroup">
                                </ring-group-selector>
                            </div>
                            <div class="col-5 d-inline-block"
                                 v-if="hasPermissionTo('list team')">
                                <team-selector label="Team:"
                                               disabled_tooltip_content="Team selector is disabled when a ring group is selected. Reset the ring group selection to enable it."
                                               :disabled="selected_ring_group !== DEFAULT_SELECTED_RING_GROUP || !loaded"
                                               :first_value="DEFAULT_SELECTED_TEAM_ID"
                                               :value="DEFAULT_SELECTED_TEAM_ID"
                                               @change="selectTeam">
                                </team-selector>
                            </div>
                        </div>
                        <div v-if="base == 'Contacts'"
                             class="col-3 d-inline-block">
                            <div class="row">
                                <div class="col-12">
                                    <label class="p-0 m-0 _500 w-100">Baseline:</label>
                                    <el-select v-model="baseline"
                                               :disabled="!loaded"
                                               class="w-full no-select"
                                               @change="changeGraphByBaseline()">
                                        <el-option v-for="item in baselines"
                                                   :key="item.value + '-' + 'baseline'"
                                                   :label="item.label.replace(/_/g, ' ') | capitalize"
                                                   :value="item.value">
                                            <span class="ml-2">{{ item.label }}</span>
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 d-inline-block"
                             v-if="base === 'CSAT'">
                            <div class="row">
                                <div class="col-12">
                                    <label class="p-0 m-0 _500 w-100">Agents</label>
                                    <el-select v-model="agent_ids"
                                               :disabled="!loaded"
                                               class="w-full no-select"
                                               :max="4"
                                               multiple
                                               clearable
                                               @change="updateReportGraph()"
                                               >
                                        <el-option v-for="user in users"
                                                   :key="user.id"
                                                   :label="user.name"
                                                   :value="user.id">
                                            <span class="ml-2">{{ user.name }}</span>
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 d-inline-block" v-else>
                            <div class="row">
                                <div class="col-12">
                                    <label class="p-0 m-0 _500 w-100">Metric:</label>
                                    <el-select v-model="metric"
                                               :disabled="!loaded"
                                               class="w-full no-select"
                                               @change="changeGraphByMetric()">
                                        <el-option v-for="item in metrics"
                                                   :key="item"
                                                   :label="fixMetricsLabel(item)"
                                                   :value="item">
                                            <span class="ml-2">{{ fixMetricsLabel(item) }}</span>
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-1 d-inline-block"
                             v-if="isTimeMetric">
                            <div class="row">
                                <div class="col-12">
                                    <label class="p-0 m-0 _500 w-100">Unit:</label>
                                    <el-select class="w-full no-select"
                                               v-model="metric_time_mode"
                                               :disabled="!loaded">
                                        <el-option label="Minutes" value="minutes"></el-option>
                                        <el-option label="Hours" value="hours"></el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 d-inline-block"
                             v-if="!['CSAT', 'Sequences', 'Broadcasts', 'Contacts'].includes(base)">
                            <div class="row">
                                <div class="col-12">
                                    <label class="p-0 m-0 _500 w-100">{{ baseSingular }} Filter:</label>
                                    <el-select v-model="overview_base_filter"
                                               :disabled="!loaded || shouldDisableDueTeamAndUserFilterCombination"
                                               class="width-150 no-select"
                                               @change="updateReportGraph">
                                        <el-option v-for="item in overview_base_filters"
                                                   :key="item + '-baseline_filter'"
                                                   :label="item"
                                                   :value="item">
                                            <span class="ml-2">{{ item }}</span>
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 d-inline-block"
                             v-if="base === 'CSAT'">
                            <div class="row">
                                <div class="col-12">
                                    <label class="p-0 m-0 _500 w-100">CSAT Score:</label>
                                    <el-select v-model="score_filter"
                                               :disabled="!loaded"
                                               clearable
                                               class="w-full no-select"
                                               @change="updateReportGraph">
                                        <el-option v-for="item in scores"
                                                   :key="item + '-score_filter'"
                                                   :label="item"
                                                   :value="item">
                                            <span class="ml-2">{{ item }}</span>
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 d-inline-block"
                             v-if="base === 'CSAT'">
                            <div class="row">
                                <div class="col-12">
                                    <label class="p-0 m-0 _500 w-100">Communication tag(s)</label>
                                    <tag-selector v-model="tags"
                                                :multiple="true"
                                                :disabled="!loaded"
                                                style="width: 200px"
                                                @change="changeTags">
                                    </tag-selector>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!['CSAT', 'Contacts'].includes(base)"
                         class="row mt-1 p-3">
                        <div class="col-4">
                            <reports-activity-reporting-graph :key="bottom_report_key"
                                                              :chart_type="'pie'"
                                                              :data="sortedPaginatedData"
                                                              :data_loaded="loaded"
                                                              :type="metric">
                            </reports-activity-reporting-graph>
                        </div>
                        <div class="col-8">
                            <reports-activity-reporting-graph :key="bottom_report_key"
                                                              :data="sortedPaginatedData"
                                                              :data_loaded="loaded"
                                                              :metric_time_mode="metric_time_mode"
                                                              :type="metric">
                            </reports-activity-reporting-graph>
                        </div>
                    </div>
                    <div v-if="base === 'CSAT'"
                         class="row mt-1 p-3">
                        <div class="col-4 mt-5" v-if="!loadings.total_csat_ratio">
                            <div class="d-flex align-items-center flex-column justify-content-center" v-if="total_csat_ratio">
                                <h3 style="font-size: 48px; color: #000">{{ total_csat_ratio }}%</h3>
                                <h3 style="font-size: 28px; color: #000">{{ current_company.name }}' CSAT Score</h3>
                            </div>
                            <div class="d-flex align-items-center flex-column justify-content-center" v-else>
                                <h3 style="font-size: 28px; color: #000">No Data To Display!</h3>
                            </div>
                        </div>
                        <div class="col-4 text-center mt-5" v-else>
                            <i class="material-icons loader text-greenish text-4x">&#xE863;</i>
                        </div>
                        <div class="col-8">
                            <reports-activity-reporting-csat-graph
                                                              :chart_data="csatChartData"
                                                              ref="csat-graph"
                                                              chart_type="column"
                                                              :data_loaded="!loadings.csat_scores"
                                                              @clicked="pointClicked"
                                                              type="data">
                            </reports-activity-reporting-csat-graph>
                        </div>
                    </div>
                    <div v-if="base === 'CSAT' && !hasCsatFilter"
                         class="row mt-1 p-3">
                        <div class="col-4 text-center mt-5" v-if="!loadings.csat_lowest && !loadings.csat_highest">
                            <div class="text-center" v-if="csatLowestChartData.length || csatHighestChartData.length">
                                <div class="mb-5" style="display: inline-flex; gap: 15px">
                                    <div v-for="csatData in csatHighestChartData" :key="csatData.name" class="agent-csat-rate">
                                        <el-avatar :style="`background: ${csatData.color}; color: white; margin: auto 10px`"> {{ csatData.name | fixContactName | initials }} </el-avatar>
                                        <p class="font-weight-bold">{{ csatData.total_csat_ratio }}%</p>
                                        <p>{{ csatData.name }} CSAT Score</p>
                                    </div>
                                </div>
                                <div style="display: inline-flex; gap: 15px">
                                    <div v-for="csatData in csatLowestChartData" :key="csatData.name" class="agent-csat-rate">
                                        <el-avatar :style="`background: ${csatData.color}; color: white; margin: auto 10px`"> {{ csatData.name | fixContactName | initials }} </el-avatar>
                                        <p class="font-weight-bold">{{ csatData.total_csat_ratio }}%</p>
                                        <p>{{ csatData.name }} CSAT Score</p>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center" v-else>
                                <h3 style="font-size: 28px; color: #000">No Data To Display!</h3>
                            </div>
                        </div>
                        <div class="col-4 text-center mt-5" v-else>
                            <i class="material-icons loader text-greenish text-4x">&#xE863;</i>
                        </div>
                        <div class="col-4">
                            <reports-activity-reporting-csat-graph-lowest-highest
                                                              :chart_data="csatLowestChartData"
                                                              ref="csat-graph"
                                                              chart_type="column"
                                                              title="Agents with <strong>lowest</strong> score rating"
                                                              :categories="[1, 2]"
                                                              :data_loaded="!loadings.csat_lowest"
                                                              @clicked="pointClicked"
                                                              type="data">
                            </reports-activity-reporting-csat-graph-lowest-highest>
                        </div>
                        <div class="col-4">
                            <reports-activity-reporting-csat-graph-lowest-highest
                                                              :chart_data="csatHighestChartData"
                                                              ref="csat-graph"
                                                              chart_type="column"
                                                              title="Agents with <strong>highest</strong> score rating"
                                                              :categories="[4, 5]"
                                                              :data_loaded="!loadings.csat_highest"
                                                              @clicked="pointClicked"
                                                              type="data">
                            </reports-activity-reporting-csat-graph-lowest-highest>
                        </div>
                    </div>
                    <div v-if="base == 'Contacts'"
                         class="row mt-1 p-3">
                        <div class="col-12">
                            <div class="pull-right no-select">
                                <strong>Chart type:</strong>
                                <el-radio-group v-model="chart_type"
                                                :disabled="!loaded"
                                                size="mini"
                                                @change="updateReportGraph">
                                    <el-radio-button label="spline">Line</el-radio-button>
                                    <el-radio-button label="areaspline">Area</el-radio-button>
                                    <el-radio-button label="column">Bar</el-radio-button>
                                </el-radio-group>
                            </div>
                        </div>
                    </div>
                    <div v-if="base == 'Contacts'"
                         class="row mt-1 p-3">
                        <div class="col-12">
                            <reports-activity-reporting-contacts-overview-graph :key="bottom_report_key"
                                                                                :base="firstColumn()"
                                                                                :chart_type="chart_type"
                                                                                :data="filtered_call_reports"
                                                                                :data_loaded="loaded"
                                                                                :overview_max_y_axis="contacts_bottom_overview_max_y_axis"
                                                                                :type="metric"
                                                                                @bottomGraphMaxYAxisValueLoaded="bottomGraphMaxYAxisValueLoaded">
                            </reports-activity-reporting-contacts-overview-graph>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 pt-3 text-right">
                            <div class="dropdown d-inline-block mr-3"
                                 v-if="!['Contacts'].includes(base)">
                                <button class="btn btn-outline b-dark-greenish text-dark-greenish dropdown-toggle"
                                        data-toggle="dropdown"
                                        data-flip="false"
                                        aria-expanded="false"
                                        @click="checkBlur">
                                    Table Settings <i class="el-icon-arrow-down"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <div class="filter-item p-3 py-1">
                                        <li>
                                            <b>Columns:</b>
                                        </li>
                                        <el-checkbox-group v-model="checked_table_fields"
                                                           @change="changeTableFields">
                                            <li v-for="field in table_fields"
                                                :key="field.text"
                                                v-on:click="noClose($event)">
                                                <el-checkbox :label="field.val">{{ field.text }}</el-checkbox>
                                            </li>
                                        </el-checkbox-group>
                                    </div>
                                </div>
                            </div>
                            <div class="dropdown dropdown-toggle-caret-none d-inline-block mr-3"
                                 v-if="hasRole('Company Admin')">
                                <button class="btn btn-outline b-dark-greenish text-dark-greenish dropdown-toggle"
                                        data-testid="comm-advanced-filters-toggle-export-menu-button"
                                        :disabled="!loaded || base === 'CSAT'"
                                        @click="toggleExportsMenu">
                                    Export <i class="el-icon-arrow-down"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right"
                                     :class="exports_menu_visible ? 'show' : ''">
                                    <a class="dropdown-item"
                                       data-testid="comm-advanced-filters-cvs-current-view-link"
                                       @click="exportCurrentViewToCsv()">
                                        <i class="fa fa-table"></i>
                                        Export Current View
                                        <i v-if="exportingData" class="fa fa-spinner fa-spin"></i>
                                    </a>
                                    <a class="dropdown-item"
                                       data-testid="comm-advanced-filters-cvs-all-columns-link"
                                       @click="exportData('csv')">
                                        <i class="fa fa-table"></i>
                                        Export All
                                        <i v-if="exportingData" class="fa fa-spinner fa-spin"></i>
                                    </a>
                                </div>
                            </div>

                            <el-button :disabled="!loaded || base === 'CSAT'"
                                       type="primary"
                                       v-if="hasRole('Company Admin')"
                                       @click="copy">
                                Copy Table Data
                            </el-button>
                        </div>
                    </div>
                    <div class="row mt-2 p-3">
                        <div class="col-12">
                            <el-table ref="activity-reporting"
                                      v-if="base !== 'CSAT'"
                                      v-loading="!loaded"
                                      :data="sortedPaginatedData"
                                      :default-sort="{prop: 'all_activity', order: 'descending'}"
                                      class="w-full mt-3 activity-reporting-table"
                                      fit
                                      stripe
                                      height="70vh"
                                      @sort-change="sortChange">
                                <el-table-column v-if="base == 'Users'"
                                                 prop="user_id"
                                                 label="Id"
                                                 fixed="left"
                                                 sortable>
                                    <template v-slot="scope">
                                        {{ scope.row.user_id }}
                                    </template>
                                </el-table-column>
                                <el-table-column v-if="base == 'Users'"
                                                 min-width="150"
                                                 prop="email"
                                                 label="Email"
                                                 fixed="left"
                                                 sortable>
                                    <template v-slot="scope">
                                        {{ scope.row.email }}
                                    </template>
                                </el-table-column>
                                <el-table-column v-if="base !== 'CSAT'"
                                                 :label="firstColumn()"
                                                 :min-width="['Contacts', 'Broadcasts'].includes(base) ? 'auto' : 150"
                                                 fixed="left"
                                                 prop="name"
                                                 sortable>
                                    <template v-slot="scope">
                                        {{ scope.row.name }}
                                    </template>
                                </el-table-column>

                                <template v-if="!['Contacts', 'CSAT'].includes(base)">
                                    <el-table-column :min-width="base == 'Broadcasts' ? 'auto' : 150"
                                                     label="All Activity"
                                                     prop="all_activity"
                                                     sortable
                                                     v-if="showColumn('all_activity')">
                                        <template v-slot="scope">
                                            {{ scope.row.all_activity }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="All Calls"
                                                     prop="all_calls"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('all_calls')">
                                        <template v-slot="scope">
                                            {{ scope.row.all_calls }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="`All Calls over ${current_company.qualified_calls_threshold1} minutes`"
                                                     prop="all_qualified_calls"
                                                     sortable
                                                     min-width="130"
                                                     v-if="showColumn('all_qualified_calls')">
                                        <template v-slot="scope">
                                            {{ scope.row.all_qualified_calls }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="All Missed Calls"
                                                     prop="all_missed_calls"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('all_missed_calls')">
                                        <template v-slot="scope">
                                            {{ scope.row.all_missed_calls }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="All Abandoned Calls"
                                                     prop="all_abandoned_calls"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('all_abandoned_calls')">
                                        <template v-slot="scope">
                                            {{ scope.row.all_abandoned_calls }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Inbound Calls"
                                                     prop="inbound_calls"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('inbound_calls')">
                                        <template v-slot="scope">
                                            {{ scope.row.inbound_calls }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="`Inbound calls over ${current_company.qualified_calls_threshold1} minutes`"
                                                     prop="qualified_inbound_calls"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('qualified_inbound_calls')">
                                        <template v-slot="scope">
                                            {{ scope.row.qualified_inbound_calls }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Inbound Answered Calls"
                                                     prop="inbound_answered_calls"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('inbound_answered_calls')">
                                        <template v-slot="scope">
                                            {{ scope.row.inbound_answered_calls }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Inbound Missed Calls"
                                                     prop="inbound_missed_calls"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('inbound_missed_calls')">
                                        <template v-slot="scope">
                                            {{ scope.row.inbound_missed_calls }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="All Missed Call %"
                                                     prop="missed_calls_percent"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('missed_calls_percent')">
                                        <template v-slot="scope">
                                            {{ scope.row.missed_calls_percent }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Outbound Calls"
                                                     prop="outbound_calls"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('outbound_calls')">
                                        <template v-slot="scope">
                                            {{ scope.row.outbound_calls }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="`Outbound calls over ${current_company.qualified_calls_threshold1} minutes`"
                                                     prop="qualified_outbound_calls"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('qualified_outbound_calls')">
                                        <template v-slot="scope">
                                            {{ scope.row.qualified_outbound_calls }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Qualified Outbound Calls %"
                                                     prop="qualified_outbound_calls_percent"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('qualified_outbound_calls_percent')">
                                        <template v-slot="scope">
                                            {{ scope.row.qualified_outbound_calls_percent }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Average Duration"
                                                     prop="average_duration"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('average_duration')">
                                        <template v-slot="scope">
                                            {{ scope.row.average_duration | fixFullLongDuration }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Total Duration"
                                                     prop="total_duration"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('total_duration')">
                                        <template v-slot="scope">
                                            {{ scope.row.total_duration | fixFullLongDuration }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Average Talk Time"
                                                     prop="average_talk_time"
                                                     sortable
                                                     min-width="130"
                                                     v-if="showColumn('average_talk_time')">
                                        <template v-slot="scope">
                                            {{ scope.row.average_talk_time | fixFullLongDuration }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Total Talk Time"
                                                     prop="total_talk_time"
                                                     sortable
                                                     width="150"
                                                     v-if="showColumn('total_talk_time')">
                                        <template v-slot="scope">
                                            {{ scope.row.total_talk_time | fixFullLongDuration }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Average Wait Time"
                                                     prop="average_wait_time"
                                                     sortable
                                                     min-width="130"
                                                     v-if="showColumn('average_wait_time')">
                                        <template v-slot="scope">
                                            {{ scope.row.average_wait_time | fixFullLongDuration }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Total Wait Time"
                                                     prop="total_wait_time"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('total_wait_time')">
                                        <template v-slot="scope">
                                            {{ scope.row.total_wait_time | fixFullLongDuration }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="All Text Messages"
                                                     prop="all_text_messages"
                                                     sortable
                                                     min-width="130"
                                                     v-if="showColumn('all_text_messages')">
                                        <template v-slot="scope">
                                            {{ scope.row.all_text_messages }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Inbound Text Messages"
                                                     prop="inbound_text_messages"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('inbound_text_messages')">
                                        <template v-slot="scope">
                                            {{ scope.row.inbound_text_messages }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Outbound Text Messages"
                                                     prop="outbound_text_messages"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('outbound_text_messages')">
                                        <template v-slot="scope">
                                            {{ scope.row.outbound_text_messages }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="All Emails"
                                                     prop="all_emails"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('all_emails')">
                                        <template v-slot="scope">
                                            {{ scope.row.all_emails }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Inbound Emails"
                                                     prop="inbound_emails"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('inbound_emails')">
                                        <template v-slot="scope">
                                            {{ scope.row.inbound_emails }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Outbound Emails"
                                                     prop="outbound_emails"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('outbound_emails')">
                                        <template v-slot="scope">
                                            {{ scope.row.outbound_emails }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="All Faxes"
                                                     prop="all_faxes"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('all_faxes')">
                                        <template v-slot="scope">
                                            {{ scope.row.all_faxes }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Inbound Faxes"
                                                     prop="inbound_faxes"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('inbound_faxes')">
                                        <template v-slot="scope">
                                            {{ scope.row.inbound_faxes }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Outbound Faxes"
                                                     prop="outbound_faxes"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('outbound_faxes')">
                                        <template v-slot="scope">
                                            {{ scope.row.outbound_faxes }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Appointments"
                                                     prop="appointments"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('appointments')">
                                        <template v-slot="scope">
                                            {{ scope.row.appointments }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Reminders"
                                                     prop="reminders"
                                                     sortable
                                                     min-width="150"
                                                     v-if="showColumn('reminders')">
                                        <template v-slot="scope">
                                            {{ scope.row.reminders }}
                                        </template>
                                    </el-table-column>
                                </template>
                                <template v-if="base == 'Broadcasts'">
                                    <el-table-column label="Text Messages"
                                                     prop="text_messages"
                                                     sortable
                                                     v-if="showColumn('text_messages')">
                                        <template v-slot="scope">
                                            {{ scope.row.text_messages }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="Ringless Voicemails"
                                        prop="ringless_voicemails"
                                        sortable
                                        v-if="showColumn('ringless_voicemails')">
                                        <template v-slot="scope">
                                            {{ scope.row.ringless_voicemails }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="failed"
                                        sortable
                                        v-if="showColumn('failed')">
                                        <template v-slot:header>
                                            Failed
                                            <el-popover
                                                placement="right"
                                                width="300"
                                                trigger="hover"
                                                popper-class="p-2">
                                                <div class="lh-1">Number of contacts that failed to send because phone number is a landline.</div>
                                                <img
                                                    slot="reference"
                                                    src="/assets/images/app-icons/info-icon-outline.png"
                                                    alt="info-icon"
                                                />
                                            </el-popover>
                                        </template>
                                        <template v-slot="scope">
                                            {{ scope.row.failed }}
                                        </template>
                                    </el-table-column>
                                </template>
                                <template v-if="base == 'Contacts'">
                                    <el-table-column label="New Contacts"
                                                     prop="new_contacts"
                                                     sortable
                                                     v-if="showColumn('new_contacts')">
                                        <template v-slot="scope">
                                            {{ scope.row.new_contacts }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="DNC Contacts"
                                                     prop="dnc_contacts"
                                                     sortable
                                                     v-if="showColumn('dnc_contacts')">
                                        <template v-slot="scope">
                                            {{ scope.row.dnc_contacts }}
                                        </template>
                                    </el-table-column>
                                </template>
                                <el-table-column v-if="!['agents-contacts', 'lines-contacts'].includes(baseline)"
                                                 label="Action"
                                                 max-width="150">
                                    <template v-slot="scope">
                                        <router-link v-if="base == 'Users' || baseline == 'agents-contacts'"
                                                     :to="{ name: 'User Activity', params: { user_id: scope.row.user_id }}">
                                            <button class="btn btn-xs btn-block purplish">
                                                View
                                            </button>
                                        </router-link>
                                        <router-link
                                            v-if="base == 'Lines' || baseline == 'lines-contacts'"
                                            :to="{ name: 'Line Activity', params: { campaign_id: scope.row.campaign_id }}">
                                            <button class="btn btn-xs btn-block purplish">
                                                View
                                            </button>
                                        </router-link>
                                        <router-link
                                            v-if="base == 'Ring Groups'"
                                            :to="{ name: 'Ring Group Activity', params: { ring_group_id: scope.row.ring_group_id }}">
                                            <button class="btn btn-xs btn-block purplish">
                                                View
                                            </button>
                                        </router-link>
                                        <router-link
                                            v-if="base == 'Sequences'"
                                            :to="{ name: 'Sequence Manager', params: { workflow_id: scope.row.workflow_id }}">
                                            <button class="btn btn-xs btn-block purplish">
                                                View
                                            </button>
                                        </router-link>
                                        <router-link
                                            v-if="base == 'Broadcasts'"
                                            :to="{ name: 'Broadcast Activity', params: { broadcast_id: scope.row.broadcast_id }}">
                                            <button class="btn btn-xs btn-block purplish">
                                                View
                                            </button>
                                        </router-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-table ref="activity-reporting"
                                      v-if="base === 'CSAT'"
                                      v-loading="!loaded"
                                      :data="sortedPaginatedData"
                                      @row-click="onCsatRowClick"
                                      :default-sort="{prop: 'all_activity', order: 'descending'}"
                                      class="w-full mt-3 activity-reporting-table csat-table"
                                      fit
                                      stripe
                                      height="70vh"
                                      @sort-change="sortChange">
                                <el-table-column max-width="50"
                                                 prop="user_id"
                                                 fixed="left"
                                                 align="right">
                                    <template v-slot="scope">
                                        <el-avatar> {{ getNameInitials(scope.row.user) }} </el-avatar>
                                    </template>
                                </el-table-column>
                                <el-table-column min-width="150"
                                                 v-if="showColumn('agent')"
                                                 prop="user_id"
                                                 label="Agent"
                                                 fixed="left"
                                                 header-align="left"
                                                 align="left"
                                                 sortable>
                                    <template v-slot="scope">
                                        <a class="user-name font-weight-bold">{{ scope.row.user?.name }}</a>
                                    </template>
                                </el-table-column>
                                <el-table-column max-width="200"
                                                 v-if="showColumn('total_calls')"
                                                 prop="total_calls"
                                                 label="Total Calls"
                                                 fixed="left"
                                                 header-align="center"
                                                 align="center"
                                                 sortable>
                                    <template v-slot="scope">
                                        <span class="font-weight-bold">{{ scope.row.total_calls }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column min-width="200"
                                                 v-if="showColumn('csat_scores')"
                                                 prop="csat_scores"
                                                 label="CSAT Scores"
                                                 fixed="left"
                                                 header-align="center"
                                                 align="center"
                                                 sortable>
                                    <template v-slot="scope">
                                        <div class="calls-badges d-flex align-items-center justify-content-center">
                                            <div v-if="!score_filter || score_filter == 1" class="calls-badge">
                                                <div class="box bg-red">
                                                    <span>1</span>
                                                </div>
                                                <p>{{ scope.row.score_calls[0] }} calls</p>
                                            </div>
                                            <div v-if="!score_filter || score_filter == 2" class="calls-badge">
                                                <div class="box bg-orange">
                                                    <span>2</span>
                                                </div>
                                                <p>{{ scope.row.score_calls[1] }} calls</p>
                                            </div>
                                            <div v-if="!score_filter || score_filter == 3" class="calls-badge">
                                                <div class="box bg-blue">
                                                    <span>3</span>
                                                </div>
                                                <p>{{ scope.row.score_calls[2] }} calls</p>
                                            </div>
                                            <div v-if="!score_filter || score_filter == 4" class="calls-badge">
                                                <div class="box bg-purple">
                                                    <span>4</span>
                                                </div>
                                                <p>{{ scope.row.score_calls[3] }} calls</p>
                                            </div>
                                            <div v-if="!score_filter || score_filter == 5" class="calls-badge">
                                                <div class="box bg-green">
                                                    <span>5</span>
                                                </div>
                                                <p>{{ scope.row.score_calls[4] }} calls</p>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column min-width="150"
                                                 v-if="showColumn('csat_ratio')"
                                                 prop="csat_ratio"
                                                 label="CSAT"
                                                 fixed="left"
                                                 header-align="center"
                                                 align="center"
                                                 sortable>
                                    <template v-slot="scope">
                                        <span class="font-weight-bold">{{ scope.row.csat_ratio }}%</span>
                                    </template>
                                </el-table-column>

                            </el-table>
                            <div class="mt-3">
                                <el-pagination :page-size="per_page"
                                               :page-sizes="[10, 20, 50, 100, 250]"
                                               :total="total"
                                               background
                                               class="pull-right"
                                               layout="sizes, prev, pager, next"
                                               @current-change="changePage"
                                               @size-change="handleSizeChange">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="base == 'Contacts'"
                 class="row mt-3 mb-5 box">
                <div class="col-12">
                    <div class="box-body">
                        <div class="row mb-4">
                            <div class="col-3 d-inline-block">
                                <div class="row">
                                    <div class="col-12">
                                        <label class="p-0 m-0 _500 w-100">Filter By:</label>
                                        <el-select v-model="baseline_filter"
                                                   :disabled="!is_leads_vs_dnc_report_loaded"
                                                   autocomplete="off"
                                                   class="d-inline-block mr-2"
                                                   clearable
                                                   default-first-option
                                                   filterable
                                                   placeholder="Select Filter"
                                                   size="large"
                                                   style="width:300px;"
                                                   @change="topGraphFiltersChange">
                                            <el-option-group key="Lines"
                                                             label="Lines">
                                                <el-option v-for="filter in getLinesFilters('lines')"
                                                           :key="filter + '-lines'"
                                                           :label="filter.replace(/-/g, ' ') | capitalize"
                                                           :value="filter">
                                                    {{ filter.replace(/-/g, ' ') | capitalize }}
                                                </el-option>
                                            </el-option-group>
                                            <el-option-group key="Users"
                                                             label="Users">
                                                <el-option v-for="filter in getLinesFilters('agents')"
                                                           :key="filter + '-agents'"
                                                           :label="filter"
                                                           :value="filter">
                                                    {{ filter.replace(/-/g, ' ') | capitalize }}
                                                </el-option>
                                            </el-option-group>
                                        </el-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 d-inline-block">
                                <div class="row">
                                    <div class="col-12">
                                        <label class="p-0 m-0 _500 w-100">Metric:</label>
                                        <el-select v-model="baseline_metric"
                                                   :disabled="!is_leads_vs_dnc_report_loaded"
                                                   class="w-full no-select"
                                                   @change="topGraphFiltersChange">
                                            <el-option v-for="item in metrics"
                                                       :key="item"
                                                       :label="fixMetricsLabel(item)"
                                                       :value="item">
                                                <span class="ml-2">{{ fixMetricsLabel(item) }}</span>
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 pt-3 d-inline-block text-right">
                                <div class="dropdown dropdown-toggle-caret-none d-inline-block mr-3"
                                     v-if="hasRole('Company Admin')">
                                    <button class="btn btn-outline b-dark-greenish text-dark-greenish dropdown-toggle"
                                            data-testid="comm-advanced-filters-toggle-export-menu-button"
                                            :disabled="!loaded || !is_leads_vs_dnc_report_loaded"
                                            @click="toggleExportsMenu">
                                        Export <i class="el-icon-arrow-down"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right"
                                         :class="exports_menu_visible ? 'show' : ''">
                                        <a class="dropdown-item"
                                           data-testid="comm-advanced-filters-cvs-current-view-link"
                                           @click="exportCurrentViewToCsv()">
                                            <i class="fa fa-table"></i>
                                            Export Current View
                                            <i v-if="exportingData" class="fa fa-spinner fa-spin"></i>
                                        </a>
                                        <a class="dropdown-item"
                                           data-testid="comm-advanced-filters-cvs-all-columns-link"
                                           @click="exportData('csv')">
                                            <i class="fa fa-table"></i>
                                            Export All
                                            <i v-if="exportingData" class="fa fa-spinner fa-spin"></i>
                                        </a>
                                    </div>
                                </div>
                                <el-button :disabled="!is_leads_vs_dnc_report_loaded"
                                           type="primary"
                                           v-if="hasRole('Company Admin')"
                                           @click="copyOverallBaseline">
                                    Copy Table Data
                                </el-button>
                            </div>
                        </div>
                        <reports-leads-created-vs-dnc-graph v-if="allowed_baseline_metric && !allowed_baseline_filter"
                                                            :key="'_leads_vs_dnc_' + leads_created_vs_dnc_report_key"
                                                            :base="null"
                                                            :report_data="overview_report_data"
                                                            :report_type_override="'new_contacts_vs_dnc'"
                                                            :series_name="null"
                                                            :stacked="null"
                                                            :title_prefix="'New Contacts vs DNC '"
                                                            :type="baseline_metric"
                                                            @reportData="reportData"
                                                            @reportLoaded="is_leads_vs_dnc_report_loaded = true"
                                                            @topGraphMaxYAxisValueLoaded="topGraphMaxYAxisValueLoaded">
                        </reports-leads-created-vs-dnc-graph>
                        <reports-activity-reporting-contacts-graph
                            v-if="allowed_baseline_filter"
                            :key="'_contacts_' + top_report_key"
                            :base="firstColumn()"
                            :chart_type="'spline'"
                            :data="overviewBaselineReportData"
                            :data_loaded="loaded"
                            :filter_by="allowed_baseline_filter"
                            :overview_max_y_axis="contacts_top_overview_max_y_axis"
                            :report_type_override="'new_contacts_vs_dnc'"
                            :title_prefix="'New Contacts vs DNC '"
                            :type="allowed_baseline_metric"
                            :unfiltered="!allowed_baseline_filter"
                            @reportData="reportData"
                            @reportLoaded="is_leads_vs_dnc_report_loaded = true"
                            @topGraphMaxYAxisValueLoaded="topGraphMaxYAxisValueLoaded">
                        </reports-activity-reporting-contacts-graph>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import auth from '../auth'
import _ from 'lodash'
import {mapActions, mapGetters, mapState} from 'vuex'
import RingGroupSelector from './ring-group-selector'
import TeamSelector from './team-selector'
import ReportsLeadsCreatedVsDncGraph from './reports-leads-created-vs-dnc-graph'
import ReportsCsatGraph from './reports-activity-reporting-csat-graph.vue'
import ReportsCsatGraphLowestHighest from './reports-activity-reporting-csat-graph-lowest-highest.vue'
import {Notification} from 'element-ui'
import {acl_mixin, user_info_mixin} from '../mixins'
import ReportContactDispositions from './reports/reports-contact-dispositions'
import ReportCallDispositions from './reports/reports-call-dispositions'

const DEFAULT_SELECTED_TEAM_ID = 'all'
const DEFAULT_SELECTED_RING_GROUP = 'all'

export default {
    mixins: [acl_mixin, user_info_mixin],

    components: {
        ReportsLeadsCreatedVsDncGraph,
        RingGroupSelector,
        TeamSelector,
        'reports-activity-reporting-csat-graph': ReportsCsatGraph,
        'reports-activity-reporting-csat-graph-lowest-highest': ReportsCsatGraphLowestHighest,
        'reports-contact-dispositions': ReportContactDispositions,
        'reports-call-dispositions': ReportCallDispositions
    },

    props: {
        base: {
            required: true,
            type: String,
            default: 'Users'
        }
    },

    data() {
        return {
            reporting_url: localStorage.getItem('reporting_url'),
            auth: auth,
            default_metrics: [
                'all_activity',
                'all_calls',
                'all_qualified_calls',
                'inbound_calls',
                'qualified_inbound_calls',
                'all_missed_calls',
                'outbound_calls',
                'qualified_outbound_calls',
                'average_duration',
                'total_duration',
                'average_talk_time',
                'total_talk_time',
                'average_wait_time',
                'total_wait_time',
                'all_text_messages',
                'inbound_text_messages',
                'outbound_text_messages',
                'all_emails',
                'inbound_emails',
                'outbound_emails',
                'all_faxes',
                'inbound_faxes',
                'outbound_faxes',
                'appointments',
                'reminders'
            ],
            csatChartData: [],
            csatLowestChartData: [],
            csatHighestChartData: [],
            scores: [1,2,3,4,5],
            csatColors: ['red', 'orange', 'blue', 'purple', 'green'],
            csatLowestColors: ['#0500FF','#6A00CD', '#0D5231'],
            csatHighestColors: ['#FF5E03', '#3A6DF6', '#15163D'],
            total_csat_ratio: 0,
            agent_ids: [],
            metrics: [],
            metric: 'all_activity',
            score_filter: null,
            tags: [],
            baseline: null,
            baselines: [
                {
                    label: 'Lines',
                    value: 'lines-contacts'
                },
                {
                    label: 'Users',
                    value: 'agents-contacts'
                },
            ],
            metric_time_mode: 'minutes',
            call_reports: [],
            filtered_call_reports: [],
            dataSort: 'all_activity',
            dataSortType: 'DESC',
            DEFAULT_SELECTED_TEAM_ID,
            DEFAULT_SELECTED_RING_GROUP,
            selected_ring_group: DEFAULT_SELECTED_RING_GROUP,
            selected_team_id: DEFAULT_SELECTED_TEAM_ID,
            per_page: 20,
            current_page: 1,
            total: 0,
            loaded: false,
            loadings: { csat_scores: false, total_csat_ratio: false, csat_highest: false, csat_lowest: false },
            csat_scores_loaded: false,
            bottom_report_key: 0,
            top_report_key: 0,
            leads_created_vs_dnc_report_key: 0,
            cancel_token: null,
            source: null,
            chart_period: null,
            chart_type: 'column',
            is_leads_vs_dnc_report_loaded: false,
            baseline_filter: null,
            baseline_metric: 'overview',
            allowed_baseline_filter: null,
            allowed_baseline_metric: 'overview',
            overview_report_data: [],
            contacts_top_overview_max_y_axis: 0,
            contacts_bottom_overview_max_y_axis: 0,
            csat_calls_user_id: null,
            overview_base_filters: [
                'All',
                'Active',
                'Deleted'
            ],
            overview_base_filter: 'Active',
            table_fields: [],
            table_base: null,
            checked_table_fields: [],
            new_reports: [
                'Contact Dispositions',
                'Call Dispositions',
            ],
            exports_menu_visible: false,
            exportingData: false,
        }
    },

    computed: {
        ...mapGetters({
            filter: 'getFilter'
        }),

        ...mapState(['teams', 'ring_groups']),
        ...mapState('cache', ['report_table_fields', 'current_company']),

        baseSingular() {
            return (this.base.toLowerCase()[(this.base.length - 1)] == 's' ? this.base.substring(0, (this.base.length - 1)) : this.base)
        },

        sortedPaginatedData() {
            let filtered_data = this.filtered_call_reports
            if (filtered_data) {
                filtered_data = this.filterByBase(filtered_data)
                this.total = filtered_data.length
                let page_number = this.current_page - 1
                if (this.dataSort) {
                    if (this.dataSortType) {
                        switch (this.dataSortType) {
                            case 'ASC':
                                return filtered_data = filtered_data.sort((a, b) => parseFloat(a[this.dataSort]) - parseFloat(b[this.dataSort])).slice(page_number * this.per_page, (page_number + 1) * this.per_page)
                            case 'DESC':
                                return filtered_data = filtered_data.sort((a, b) => parseFloat(b[this.dataSort]) - parseFloat(a[this.dataSort])).slice(page_number * this.per_page, (page_number + 1) * this.per_page)
                        }
                    }
                }
                this.total = filtered_data.length
                return filtered_data = filtered_data.slice(page_number * this.per_page, (page_number + 1) * this.per_page)
            }

            return []
        },

        hasCsatFilter () {
            return this.agent_ids.length || this.score_filter || this.tags.length
        },

        overviewReportData() {
            return this.overview_report_data
        },

        overviewBaselineReportData() {
            let agents_filters = this.getLinesFilters('agents')
            let baseline = 'lines'
            if (agents_filters.includes(this.allowed_baseline_filter)) {
                baseline = 'agents'
            }
            let data = _.cloneDeep(this.overview_report_data[baseline + '_data'])
            if (this.allowed_baseline_filter) {
                for (let index in data) {
                    if (data[index].series_name != this.allowed_baseline_filter) {
                        delete data[index]
                    }
                }
            }
            data = _.compact(data)
            return data
        },

        isTimeMetric() {
            return [
                'average_duration',
                'average_talk_time',
                'average_wait_time',
                'total_duration',
                'total_talk_time',
                'total_wait_time'
            ].includes(this.metric)
        },

        /* WAT-747: we should disable the User Filter when a team is selected, and make "Active" Default. */
        shouldDisableDueTeamAndUserFilterCombination(){
            return this.base === 'Users' && this.selected_team_id !== DEFAULT_SELECTED_TEAM_ID
        },

        default_table_fields() {
            return [
                { text: 'All activity', val: 'all_activity' },
                { text: 'All calls', val: 'all_calls' },
                { text: `All Calls over ${this.current_company.qualified_calls_threshold1} minutes`, val: 'all_qualified_calls' },
                { text: 'All missed calls', val: 'all_missed_calls' },
                { text: 'All abandoned calls', val: 'all_abandoned_calls' },
                { text: 'Inbound calls', val: 'inbound_calls' },
                { text: `Inbound calls over ${this.current_company.qualified_calls_threshold1} minutes`, val: 'qualified_inbound_calls' },
                { text: 'Outbound calls', val: 'outbound_calls' },
                { text: `Outbound calls over ${this.current_company.qualified_calls_threshold1} minutes`, val: 'qualified_outbound_calls' },
                { text: 'Average duration', val: 'average_duration' },
                { text: 'Total duration', val: 'total_duration' },
                { text: 'Average talk time', val: 'average_talk_time' },
                { text: 'Total talk time', val: 'total_talk_time' },
                { text: 'Average wait time', val: 'average_wait_time' },
                { text: 'Total wait time', val: 'total_wait_time' },
                { text: 'All text message', val: 'all_text_messages' },
                { text: 'Inbound text message', val: 'inbound_text_messages' },
                { text: 'Outbound text message', val: 'outbound_text_messages' },
                { text: 'All emails', val: 'all_emails' },
                { text: 'Inbound emails', val: 'inbound_emails' },
                { text: 'Outbound emails', val: 'outbound_emails' },
                { text: 'All faxes', val: 'all_faxes' },
                { text: 'Inbound faxes', val: 'inbound_faxes' },
                { text: 'Outbound faxes', val: 'outbound_faxes' },
                { text: 'Appointments', val: 'appointments' },
                { text: 'Reminders', val: 'reminders' }
            ]
        }
    },

    created() {
        this.table_base = this.base
        this.cancel_token = axios.CancelToken
        this.source = this.cancel_token.source()

        if (!this.new_reports.includes(this.base)) {
            this.chart_period = this.base == 'Contacts' ? 'day' : this.chart_period
            this.updateInitialSortAndMetricsField()

            this.is_leads_vs_dnc_report_loaded = this.base != 'Contacts' ? true : false

            this.updateBaseline()

            VueEvent.listen('update_activity_reporting', (data) => {
                data.chart_period = this.chart_period
                data.base_filter = 'All'
                this.getActivityReport(data)
            })
        }
    },

    mounted() {
        if (!this.new_reports.includes(this.base)) {
            if (this.base != 'Contacts') {
                this.loadReport()
            }
            this.updateMetrics()
        }

        if (this.base == 'CSAT') {
            this.refreshCsatData()
        }
    },

    methods: {
        changeTags(event) {
            this.tags = event
            this.loadReport(true)
            this.refreshCsatData()
        },

        getNameInitials(user) {
            return user.first_name.charAt(0) + user.last_name.charAt(0)
        },

        loadReport(load_new_contacts_and_dnc_report = false) {
            this.getActivityReport({
                from_date: this.filter.from_date,
                to_date: this.filter.to_date,
                type: 'all',
                base_filter: 'All'
            }, load_new_contacts_and_dnc_report)
        },

        updateBaseline() {
            switch (this.base) {
                case 'Contacts':
                    this.baseline = 'lines-contacts'
                    break;
                default:
                    this.baseline = null
            }
        },

        firstColumn() {
            switch (this.base) {
                case 'Contacts':
                    if (this.baseline == 'lines-contacts') {
                        return 'Line'
                    }
                    return 'User'
                default:
                    let first_column_name = this.base
                    if (first_column_name.slice(-1) == 's') {
                        first_column_name = first_column_name.substring(0, (first_column_name.length - 1))
                    }
                    return first_column_name
            }
        },

        changePage(val) {
            this.current_page = val
            this.updateCallReport(true)
        },

        handleSizeChange(val) {
            this.current_page = 1
            this.per_page = val
            this.updateCallReport(true)
        },

        pointClicked(data) {
            // this.updateCsatPanel(data.user_id, data.csat_ratio)
        },

        getActivityReport(data, load_new_contacts_and_dnc_report = false) {
            this.baseline = this.base == 'Contacts' && !this.baseline ? 'lines-contacts' : this.baseline
            VueEvent.fire('contact_activity_report_baseline_change', this.baseline)
            this.current_page = 1
            this.source.cancel('getCallReport canceled by the user.')
            this.source = this.cancel_token.source()
            this.loaded = false
            this.call_reports = []
            this.filtered_call_reports = []
            data.export_type = 'json'
            data.date_field = 'created_at'
            data.agent_ids = this.agent_ids
            data.score_filter = this.score_filter
            data.tags = this.tags

            if (this.base != 'Contacts') {
                data.baseline = this.base.toLowerCase().replace(' ', '-')
            }

            if (this.base == 'Contacts') {
                data.baseline = !data.baseline ? this.baseline : data.baseline
            }

            axios.get(this.reporting_url + '/api/v1/reports/activity-reporting', {
                params: data,
                cancelToken: this.source.token
            }).then(res => {
                this.call_reports = res.data
                this.loaded = true
                if (load_new_contacts_and_dnc_report) {
                    this.loadNewContactsAndDNCReport()
                }
                this.updateCallReport()
            }).catch(err => {
                this.$root.handleErrors(err.response)
            })
        },

        selectTeam(team_id) {
            this.current_page = 1
            this.selected_team_id = team_id

            /* WAT-747: we should disable the User Filter when a team is selected, and make “Active” Default. */
            if(team_id !== DEFAULT_SELECTED_TEAM_ID) {
                this.overview_base_filter = 'Active'
            }

            this.updateCallReport()
        },

        selectRingGroup(ring_group_id) {
            this.current_page = 1
            this.selected_ring_group = ring_group_id
            this.updateCallReport()
        },

        updateCallReport(do_not_update_sorting = false) {
            if (!this.call_reports.length) {
                return
            }
            if (this.base != 'Contacts') {
                let filtered_data = this.call_reports
                if (this.selected_team_id === DEFAULT_SELECTED_TEAM_ID) {
                    // No team selected, apply ring group filter
                    const ringGroup = this.ring_groups.find(r => r.id == this.selected_ring_group)
                    filtered_data = this.selected_ring_group == DEFAULT_SELECTED_RING_GROUP
                        ? this.call_reports
                        // Check for user_ids to be array else set filtered_data to empty array
                        : (ringGroup && Array.isArray(ringGroup.user_ids)) ? this.call_reports.filter(cp => ringGroup.user_ids.includes(cp.user_id)): []
                } else {
                    // Team selected, ignore ring group filter
                    const team = this.teams.find(team => team.id == this.selected_team_id)
                    if (team && Array.isArray(team.users)) {
                        filtered_data = this.call_reports.filter(cp => team.users.includes(cp.user_id))
                    } else {
                        filtered_data = []
                    }
                }

                this.filtered_call_reports = filtered_data
            } else {
                this.filtered_call_reports = this.filterByBase(this.call_reports)
            }
            this.changeGraphByMetric(do_not_update_sorting)
        },

        changeGraphByMetric(do_not_update_sorting = false) {
            this.bottom_report_key += 1
            if ((!do_not_update_sorting && this.metric != 'overview')) {
                this.$refs['activity-reporting'].sort(this.metric, 'descending')
            }
            if (this.base == 'Contacts' && this.metric == 'overview') {
                this.$refs['activity-reporting'].clearSort()
            }
        },

        changeGraphByBaseline() {
            this.loadReport()
        },

        updateReportGraph() {
            if (this.base == 'Contacts') {
                this.filtered_call_reports = this.filterByBase(this.call_reports)
            }
            if (this.base == 'CSAT') {
                this.loadReport()
                this.refreshCsatData()
            }
            this.bottom_report_key += 1
        },

        refreshCsatData() {
            this.loadReport(true)
            this.csat_scores_loaded = false
            this.getCsatScores()
            this.getCsatHighestScore()
            this.getCsatLowestScore()
            this.getCsatTotalRatio()
        },

        getCsatScores() {
            if (this.base !== 'CSAT')
                return

            this.csat_scores_loaded = false
            this.loadings.csat_scores = true
            this.setCsatCallsLoading(false)

            axios.get(this.reporting_url + '/api/v1/reports/csat-scores', {
                params: {
                    tags: this.tags,
                    agent_ids: this.agent_ids,
                    score_filter: this.score_filter,
                    from_date: this.filter.from_date,
                    to_date: this.filter.to_date,
                    timezone: this.filter.timezone,
                }
            }).then(res => {
                let chartData = []
                let chartScores = _.clone(res.data.scores)
                for (const [key, value] of Object.entries(chartScores)) {
                    chartData.push({
                        color: this.csatColors[key],
                        name: value.score,
                        data: value.total_calls,
                    })
                }
                this.csatChartData = chartData

                this.loadings.csat_scores = false
                this.setCsatCallsLoading(true)

            }).catch(err => {
                this.$root.handleErrors(err.response)
            })
        },

        getCsatTotalRatio() {
            if (this.base !== 'CSAT')
                return

            this.loadings.total_csat_ratio = true
            this.csat_scores_loaded = false
            this.setCsatCallsLoading(false)

            axios.get(this.reporting_url + '/api/v1/reports/total-csat-ratio', {
                params: {
                    tags: this.tags,
                    agent_ids: this.agent_ids,
                    score_filter: this.score_filter,
                    from_date: this.filter.from_date,
                    to_date: this.filter.to_date,
                    timezone: this.filter.timezone,
                }
            }).then(res => {
                this.total_csat_ratio = res.data.total_csat_ratio

                this.csat_scores_loaded = true
                this.loadings.total_csat_ratio = false
                this.setCsatCallsLoading(true)

            }).catch(err => {
                this.$root.handleErrors(err.response)
            })
        },

        getCsatHighestScore() {
            if (this.base !== 'CSAT')
                return

            this.loadings.csat_highest = true
            this.csat_scores_loaded = false
            this.setCsatCallsLoading(false)

            axios.get(this.reporting_url + '/api/v1/reports/csat-highest-scores', {
                params: {
                    from_date: this.filter.from_date,
                    to_date: this.filter.to_date,
                    timezone: this.filter.timezone,
                }
            }).then(res => {
                // Highest
                let highestChartData = []
                let highestChartScores = _.clone(res.data.highest_users)
                for (const [index, [key, value]] of Object.entries(Object.entries(highestChartScores))) {
                    highestChartData.push({
                        color: this.csatHighestColors[index],
                        name: value.name,
                        data: value.scores,
                        total_csat_ratio: value.total_csat_ratio,
                    })
                }
                this.csatHighestChartData = highestChartData

                this.loadings.csat_highest = false
                this.csat_scores_loaded = true
                this.setCsatCallsLoading(true)

            }).catch(err => {
                this.$root.handleErrors(err.response)
            })
        },

        getCsatLowestScore() {
            if (this.base !== 'CSAT')
                return

            this.loadings.csat_lowest = true
            this.csat_scores_loaded = false
            this.setCsatCallsLoading(false)

            axios.get(this.reporting_url + '/api/v1/reports/csat-lowest-scores', {
                params: {
                    from_date: this.filter.from_date,
                    to_date: this.filter.to_date,
                    timezone: this.filter.timezone,
                }
            }).then(res => {
                // Lowest
                let lowestChartData = []
                let lowestChartScores = _.clone(res.data.lowest_users)
                for (const [index, [key, value]] of Object.entries(Object.entries(lowestChartScores))) {
                    lowestChartData.push({
                        color: this.csatLowestColors[index],
                        name: value.name,
                        data: value.scores,
                        total_csat_ratio: value.total_csat_ratio,
                    })
                }
                this.csatLowestChartData = lowestChartData

                this.loadings.csat_lowest = false
                this.csat_scores_loaded = true
                this.setCsatCallsLoading(true)

            }).catch(err => {
                this.$root.handleErrors(err.response)
            })
        },

        print() {
            let filtered_report = this.sortedPaginatedData
            let obj = {
                view: 'user.pdf.call-report',
                options: {
                    orientation: 'landscape'
                },
                data: {
                    report: filtered_report.sort((a, b) => (a.all_activity < b.all_activity) ? 1 : ((b.all_activity < a.all_activity) ? -1 : 0)),
                    meta: {
                        from_date: this.filter.from_date,
                        to_date: this.filter.to_date,
                        timezone: this.filter.timezone,
                        type: 'all'
                    }
                }
            }

            axios.post('/pdf/print', obj, {responseType: 'blob'})
                .then(response => {
                    const blob = new Blob([response.data], {type: 'application/pdf'})
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.target = '_blank';
                    link.click()
                    URL.revokeObjectURL(link.href)
                }).catch(err => {
                this.$root.handleErrors(err.response)
            })
        },

        onCsatRowClick(event) {
            this.csat_calls_user_id = event.user_id
            this.updateCsatPanel(event.csat_ratio)
        },

        updateCsatPanel(csat_ratio) {
            this.setCsatCallsPanelScore(csat_ratio)
            this.getCsatCallsForAgent()
            this.showCsatCallsPanel()
            window.scrollTo({ top: 0, behavior: 'smooth' })
        },

        getCsatCallsForAgent(url = null) {
            this.setCsatCallsLoading(true)
            this.setCsatCallsPanelFilterParams({
                tags: this.tags,
                agent_ids: [this.csat_calls_user_id],
                score_filter: this.score_filter,
                from_date: this.filter.from_date,
                to_date: this.filter.to_date,
                timezone: this.filter.timezone,
            })
            this.setCsatCallsPanelCalls(url)
        },

        copy() {
            if (this.call_reports.length === 0) {
                return
            }

            const visibleColumns = this.getVisibleColumns()
            const headers = this.formatColumnHeaders(visibleColumns)
            let report = _.clone(this.sortedPaginatedData)
            report.sort((a, b) => b[this.metric] - a[this.metric])

            let copied = headers.join('\t') + '\n'
            copied += this.formatRowData(visibleColumns, report).replace(/,/g, '\t')

            const el = document.createElement('textarea')
            el.value = copied
            document.body.appendChild(el)
            el.select()
            document.execCommand('copy')
            document.body.removeChild(el)

            this.$notify({
                offset: 95,
                title: 'Reports',
                dangerouslyUseHTMLString: true,
                message: 'Data has been copied to your clipboard.',
                type: 'success',
                showClose: true
            })
        },

        updateMetrics() {
            switch (this.base) {
                case 'Broadcasts':
                    this.metrics = [
                        'all_activity',
                        'text_messages',
                        'failed',
                        'ringless_voicemails'
                    ]

                    this.table_fields = [
                        {
                            text: 'All Activity',
                            val: 'all_activity',

                        },
                        {
                            text: 'Ringless Voicemails',
                            val: 'ringless_voicemails',
                        },
                        {
                            text: 'Text Messages',
                            val: 'text_messages',
                        },
                        {
                            text: 'Failed',
                            val: 'failed',
                        },
                    ];

                    this.checked_table_fields = ['all_activity', 'ringless_voicemails', 'text_messages', 'failed']

                    break;
                case 'Contacts':
                    this.metrics = [
                        'overview',
                        'new_contacts',
                        'dnc_contacts'
                    ]
                    break;
                case 'CSAT':
                    this.metrics = [
                        'agent',
                        'total_calls',
                        'csat_scores',
                        'csat_ratio'
                    ]
                    this.table_fields = [
                        {
                            text: 'Agent',
                            val: 'agent',

                        },
                        {
                            text: 'Total Calls',
                            val: 'total_calls',
                        },
                        {
                            text: 'CSAT Scores',
                            val: 'csat_scores',
                        },
                        {
                            text: 'CSAT',
                            val: 'csat_ratio',
                        },
                    ];
                    this.checked_table_fields = ['agent', 'total_calls', 'csat_scores', 'csat_ratio']
                    break;
                default:
                    this.table_fields = this.default_table_fields;
                    this.checked_table_fields = ['all_activity', 'all_calls', 'all_qualified_calls', 'all_missed_calls', 'all_abandoned_calls', 'inbound_calls', 'qualified_inbound_calls', 'outbound_calls', 'qualified_outbound_calls', 'average_duration', 'total_duration', 'average_talk_time', 'total_talk_time', 'average_wait_time', 'total_wait_time', 'all_text_messages', 'inbound_text_messages', 'outbound_text_messages', 'all_emails', 'inbound_emails', 'outbound_emails', 'all_faxes', 'inbound_faxes', 'outbound_faxes', 'appointments', 'reminders']
                    this.metrics = this.default_metrics
            }

            if (this.report_table_fields.length > 0) {
                this.checked_table_fields = this.report_table_fields
            }
        },

        sortChange(data) {
            if (data.order) {
                this.dataSort = data.prop
                switch (data.order) {
                    case 'descending':
                        this.dataSortType = 'DESC'
                        break
                    case 'ascending':
                        this.dataSortType = 'ASC'
                        break
                }
            } else {
                this.dataSort = null
                this.dataSortType = null
            }
        },

        fixMetricsLabel(label) {
            switch (label) {
                case 'dnc_contacts':
                    return 'DNC Contacts'
                case 'all_qualified_calls':
                    return `All Calls over ${this.current_company.qualified_calls_threshold1} minutes`
                case 'qualified_inbound_calls':
                    return `Inbound calls over ${this.current_company.qualified_calls_threshold1} minutes`
                case 'qualified_outbound_calls':
                    return `Outbound calls over ${this.current_company.qualified_calls_threshold1} minutes`
                default:
                    return this.$options.filters.ucwords(label.replace(/_/g, ' '))
            }
        },

        updateInitialSortAndMetricsField() {
            if (this.base == 'Contacts') {
                this.metric = 'overview'
                this.dataSort = 'overview'
            } else {
                this.metric = 'all_activity'
                this.dataSort = 'all_activity'
            }
            this.dataSortType = 'DESC'
        },

        changeMetricTitle() {
            switch (this.metric) {
                case 'overview':
                    return 'Overview'
                case 'new_contacts':
                    return 'New Contacts'
                case 'dnc_contacts':
                    return 'DNC Contacts'
            }
        },

        copyOverallBaseline() {
            let event_name = ''
            if (this.allowed_baseline_metric && !this.allowed_baseline_filter) {
                event_name = 'copy_overall_baseline_leads_vs_dnc_' + this.leads_created_vs_dnc_report_key
            } else {
                event_name = 'copy_filtered_baseline_contacts_' + this.top_report_key
            }
            if (event_name) {
                VueEvent.fire(event_name, null)
            }
        },

        copyFilteredBaseline() {
            VueEvent.fire('copy_overall_baseline_' + this.top_report_key, null)
        },

        reportData(data) {
            this.overview_report_data = data
        },

        bottomGraphMaxYAxisValueLoaded(value) {
            this.contacts_bottom_overview_max_y_axis = value
        },

        topGraphMaxYAxisValueLoaded(value) {
            this.contacts_top_overview_max_y_axis = value
        },

        getLinesFilters(baseline) {
            if (this.base != 'Contacts') {
                return []
            }
            let options = []
            baseline = baseline + '_data'
            if (typeof this.overviewReportData[baseline] !== 'undefined' && this.overviewReportData[baseline].length) {
                for (let index in this.overviewReportData[baseline]) {
                    let label = this.overviewReportData[baseline][index].label;
                    // Check if the label is not already in options
                    if (!options.includes(label)) {
                        options.push(label);
                    }
                }
            }
            options = options.sort()
            return options
        },

        getVisibleColumns() {
            const table = this.$refs['activity-reporting']
            return table.store.states.columns.filter(column => column.label !== 'Action')
        },

        formatColumnHeaders(visibleColumns) {
            return visibleColumns.map(column => {
                let label = column.label

                switch (label) {
                    case 'All Activity':
                        label = 'All Activities'
                        break;
                    case 'Average Duration':
                    case 'Total Duration':
                    case 'Average Talk Time':
                    case 'Total Talk Time':
                    case 'Average Wait Time':
                    case 'Total Wait Time':
                        label += ' (in seconds)'
                        break;
                }

                return label
            })
        },

        formatRowData(visibleColumns, report) {
            return report.map(row => {
                return visibleColumns.map(column => {
                    let cellValue = row[column.property] === null || row[column.property] === undefined ? '' : row[column.property]
                    return `"${cellValue.toString().replace(/"/g, '""')}"`
                }).join(',')
            }).join('\n')
        },

        topGraphFiltersChange() {
            if (this.baseline_filter || (this.baseline_metric && !this.baseline_filter)) {
                this.allowed_baseline_metric = this.baseline_metric
                this.allowed_baseline_filter = this.baseline_filter
                this.top_report_key += 1
            }
        },

        exportData(graph_name) {
            if (this.exportingData) {
                return
            }

            this.exports_menu_visible = false
            this.exportingData = true

            let exportParams = _.cloneDeep(this.filter)
            exportParams.page = null
            exportParams.per_page = null
            exportParams.export_type = 'csv'
            exportParams.date_field = 'created_at'
            // this.baseline is used by reports having baseline filter
            // like activity reports' contacts subcategory
            exportParams.baseline = this.baseline ? this.baseline : this.base.replace(/\s|_/g, '-').toLowerCase()
            // Set column|fields included in export
            if (this.table_base == 'Broadcasts') {
                let fields = this.checked_table_fields.filter((field) => {
                    return ['all_activity', 'text_messages', 'ringless_voicemails', 'failed'].includes(field)
                })

                exportParams.column_fields = fields;
            }

            if (this.table_base != 'Broadcasts') {
                exportParams.column_fields = this.checked_table_fields;
            }

            if (graph_name == 'new-contacts-vs-dnc') {
                exportParams.metric = this.baseline_metric
                exportParams.filter_by = this.baseline_filter
                let agents_filters = this.getLinesFilters('agents')
                let baseline = 'lines-contacts'
                if (agents_filters.includes(this.allowed_baseline_filter)) {
                    baseline = 'agents-contacts'
                }
                exportParams.baseline = baseline
            } else {
                exportParams.base_filter = this.overview_base_filter
            }

            axios.get(this.reporting_url + '/api/v1/reports/activity-reporting', {
                params: exportParams
            }).then(res => {
                this.$notify.info({
                    offset: 95,
                    title: 'Exports',
                    dangerouslyUseHTMLString: true,
                    message: `Your export is on its way. You can check the progress from the export page or <b class="text-dark-greenish pointer">click here</b>.`,
                    duration: 3000,
                    onClick: () => {
                        this.$router.push({name: 'Account', query: {tab: 'exports'}}).catch((err) => {
                        })
                        Notification.closeAll()
                    }
                })
            }).catch(err => {
                this.$root.handleErrors(err.response)
            }).finally(() => {
                this.exportingData = false
            })
        },

        exportCurrentViewToCsv() {
            this.exports_menu_visible = false

            if (this.call_reports.length === 0) {
                this.$notify({
                    offset: 95,
                    title: 'Export Error',
                    message: 'No data available to export.',
                    type: 'error',
                    showClose: true
                })

                return
            }

            const visibleColumns = this.getVisibleColumns()
            const headers = this.formatColumnHeaders(visibleColumns)
            let report = _.clone(this.sortedPaginatedData)
            report.sort((a, b) => b[this.metric] - a[this.metric])

            let csvContent = headers.join(',') + '\n'
            csvContent += this.formatRowData(visibleColumns, report)

            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
            const link = document.createElement('a')
            const currentDate = moment().format('YYYY-MM-DD');

            link.href = URL.createObjectURL(blob)
            link.setAttribute('download', `export-${this.base.toLowerCase()}-${currentDate}.csv`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)

            this.$notify({
                offset: 95,
                title: 'Reports',
                message: 'CSV file has been downloaded.',
                type: 'success',
                showClose: true
            })
        },

        loadNewContactsAndDNCReport() {
            if (this.base == 'Contacts') {
                this.is_leads_vs_dnc_report_loaded = false
                this.overview_report_data = {}
                if (this.allowed_baseline_metric && !this.allowed_baseline_filter) {
                    this.leads_created_vs_dnc_report_key += 1
                }
                if (this.allowed_baseline_filter) {
                    this.top_report_key += 1
                }
            }
        },

        filterByBase(data) {
            if (this.base === 'CSAT') {
                return data;
            }
            switch (this.overview_base_filter) {
                case 'Active':
                    data = data.filter(item => !item.name.toLowerCase().includes('_deleted'))
                    break
                case 'Deleted':
                    data = data.filter(item => item.name.toLowerCase().includes('_deleted'))
                    break
            }
            return data
        },

        noClose(event) {
            if (event) {
                event.stopPropagation()
            }
        },

        changeRows(per_page) {
            this.filter.per_page = per_page
        },

        checkBlur: function (e) {
            if (e.currentTarget.getAttribute('aria-expanded') == 'true') {
                e.currentTarget.blur()
            }
        },

        toggleExportsMenu() {
            this.exports_menu_visible = !this.exports_menu_visible
        },

        showColumn(column) {
            if (this.table_base == 'Broadcasts') {
                let broadcast_fields = [
                    'all_activity', 'text_messages', 'ringless_voicemails', 'failed'
                ];
                if (broadcast_fields.includes(column)) {
                    return this.checked_table_fields.includes(column)
                }
            }

            if (this.table_base != 'Broadcasts') {
                return this.checked_table_fields.includes(column)
            }
        },

        changeTableFields() {
            this.setReportTableFields(this.checked_table_fields)

            this.$nextTick(_ => {
                if (this.$refs['activity-reporting']) {
                    this.$refs['activity-reporting'].doLayout()
                }
            })
        },
        ...mapActions(['showCsatCallsPanel', 'setCsatCallsPanelScore', 'setCsatCallsPanelCalls', 'setCsatCallsLoading', 'setCsatCallsPanelFilterParams']),
        ...mapActions('cache', ['setReportTableFields']),
    },

    watch: {
        'filter.from_date': function () {
            if (!this.new_reports.includes(this.base)) {
               this.refreshCsatData()
            }
        },

        'filter.date_range': function () {
            if (!this.new_reports.includes(this.base)) {
                this.refreshCsatData()
            }
        },

        metric_time_mode() {
            if (!this.new_reports.includes(this.base)) {
                this.loadReport(true)
            }
        },

        base() {
            if (!this.new_reports.includes(this.base)) {
                // when changing activity reports subcategories,
                // we have to update the baseline for specific subcategories
                // and set it to null if not
                this.updateBaseline()
                if (this.base !== 'Contacts' || this.is_leads_vs_dnc_report_loaded) {
                    this.updateInitialSortAndMetricsField()
                    this.updateMetrics()
                    this.getActivityReport({
                        chart_period: this.chart_period,
                        from_date: this.filter.from_date,
                        to_date: this.filter.to_date,
                        timezone: this.filter.timezone,
                        type: 'all',
                        base_filter: 'All'
                    }, true)
                    this.bottom_report_key += 1
                    this.table_base = this.base;
                }
                this.selected_ring_group = DEFAULT_SELECTED_RING_GROUP
                this.selected_team_id = DEFAULT_SELECTED_TEAM_ID
                this.overview_report_data = {}
                this.baseline_filter = null
                this.baseline_metric = 'overview'
                this.allowed_baseline_filter = null
                this.allowed_baseline_metric = 'overview'
                // default value. according to Kit, the clients don't want to see the
                // deleted lines, users, teams, ring groups, sequences, broadcasts, and contacts
                // so we show the active ones on load or sub category change
                this.overview_base_filter = 'All'
            }
        },

        is_leads_vs_dnc_report_loaded() {
            if (this.is_leads_vs_dnc_report_loaded) {
                this.loadReport()
                this.bottom_report_key += 1
            } else {
                this.loaded = false
            }
        }
    },

    beforeDestroy() {
        VueEvent.fire('contact_activity_report_baseline_change', null)
    },
}
</script>

<style scoped>
.csat-table > a.user-name {
    color: blue;
}

.csat-table > .el-avatar--circle {
    font-weight: bold;
    color: black;
    background: #D9D9D9;
}
.csat-table > .el-table__cell a,
.csat-table > .el-table__cell div.cell {
    line-height: 40px;
    font-size: 16px!important;
}
.csat-table > .el-table--striped .el-table__body tr.el-table__row--striped td {
    background: #e6f5e6;
}
.calls-badge {
    width: 80px;
}
.calls-badge div.box {
    width: 20px;
    height: 20px;
    margin: 0 auto;
    position: relative;
    border-radius: 3px;
    color: #fff;
}
.calls-badge div.box span{
    position: absolute;
    top: -2px;
    left: 6px;
}
.agent-csat-rate p {
    margin-bottom: 0;
}
</style>
