/**
 * AloAi Tool Mixin
 */
export default {
    props: {
        tool: {
            type: Object,
            required: true
        }
    },

    methods: {
        /**
         * Emit the back event to return to the tool list
         */
        goBack() {
            this.$emit('back')
        },

        /**
         * Check if a selection is valid and close the popover
         * @param {*} selection - The selection to check
         * @returns {Boolean} - Whether the selection is valid
         */
        isValidSelection(selection) {
            if (!selection) return false
            
            // Handle arrays (tags, lists)
            if (Array.isArray(selection)) {
                return selection.length > 0
            }
            
            // Handle objects or primitives
            return !!selection
        }
    }
}
