var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form-item",
    { attrs: { prop: "daily_digest_report_prompt_list" } },
    [
      _c("span", {
        staticClass: "page-anchor",
        attrs: { id: "daily_digest_report_prompt_list" },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "row",
          staticStyle: {
            display: "flex",
            gap: "16px",
            "align-items": "stretch",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "highlights-container",
              class: { "col-md-6": _vm.digestReports.length },
            },
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", [_vm._v("Daily Digest Report Sections")]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    "\n                    Configure sections and insights for your daily digest reports.\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("i", [
                    _vm._v(
                      "\n                        Click an item to edit or drag and drop to reorder sections.\n                    "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "draggable",
                {
                  attrs: { handle: ".drag-handle" },
                  on: { end: _vm.onReorder },
                  model: {
                    value: _vm.digestReports,
                    callback: function ($$v) {
                      _vm.digestReports = $$v
                    },
                    expression: "digestReports",
                  },
                },
                _vm._l(_vm.digestReports, function (item, index) {
                  return _c(
                    "div",
                    { key: item.id || index, staticClass: "draggable-item" },
                    [
                      _c(
                        "el-tag",
                        {
                          staticClass: "highlight-tag font-weight-bold",
                          attrs: {
                            type: "success",
                            closable: "",
                            "disable-transitions": false,
                          },
                          on: {
                            close: function ($event) {
                              return _vm.removeDigest(index)
                            },
                            click: function ($event) {
                              return _vm.editDigest(item, index)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "drag-handle",
                              staticStyle: { cursor: "grab" },
                            },
                            [
                              _vm._v(
                                "\n                            ☰\n                        "
                              ),
                            ]
                          ),
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                item.label.length > 50
                                  ? item.label.slice(0, 50) + "..."
                                  : item.label
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.openDialog } },
                    [
                      _vm._v(
                        "\n                    + Add Section\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml-2",
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.openTestEmailModal },
                    },
                    [
                      _vm._v(
                        "\n                    Send Test Email\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.digestReports.length
            ? _c("div", { staticClass: "col-md-6 summary-preview" }, [
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [_vm._v("Daily Digest Report Preview")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "\n                    Include these items in the daily digest report email.\n                "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ai-effect-container mt-2" }, [
                  _c("div", { staticClass: "ai-effect-gradient" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ai-effect-blur" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ai-effect-content p-2" }, [
                    _c(
                      "div",
                      { staticClass: "flex items-center justify-between" },
                      [
                        _c("div", { staticClass: "flex items-center gap-2" }, [
                          _c("h3", { staticClass: "ai-effect-gradient-text" }, [
                            _vm._v(
                              "\n                                Powered by AloAi\n                            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-left-align" }, [
                          _c(
                            "div",
                            { staticClass: "inline-preview" },
                            _vm._l(_vm.digestReports, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "inline-summary-item",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-size-sm font-weight-bold",
                                      staticStyle: { color: "#000" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(item.label) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "font-size-xs",
                                      staticStyle: { color: "#000" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(item.prompt) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Manage Daily Digest Report Section",
            top: "10vh",
            width: "45%",
            "append-to-bod": "",
            visible: _vm.dialog_visible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialog_visible = $event
            },
          },
        },
        [
          _c(
            "el-steps",
            {
              staticClass: "mb-3",
              attrs: {
                "align-center": "",
                "finish-status": "success",
                active: _vm.current_step,
              },
            },
            [
              _c("el-step", { attrs: { title: "Select Method" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "Choose Template" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "Customize Section" } }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.current_step === 1
            ? [
                _c(
                  "el-radio-group",
                  {
                    on: { change: _vm.handleSelection },
                    model: {
                      value: _vm.selected_type,
                      callback: function ($$v) {
                        _vm.selected_type = $$v
                      },
                      expression: "selected_type",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "row row-filter highlight-options" },
                      [
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c(
                              "el-card",
                              {
                                staticClass: "cursor-pointer equal-height-card",
                                attrs: { shadow: "hover" },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    staticClass: "w-100",
                                    attrs: { label: "scratch" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center mb-2",
                                      },
                                      [
                                        _c(
                                          "i",
                                          {
                                            staticClass:
                                              "text-2x mr-2 material-icons text-green-500",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        create\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-lg font-weight-bold text-grey-9",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Create from scratch\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "text-md l-h" }, [
                                      _vm._v(
                                        "\n                                    Begin with a blank template to design a fully personalized section.\n                                "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c(
                              "el-card",
                              {
                                staticClass: "cursor-pointer equal-height-card",
                                attrs: { shadow: "hover" },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    staticClass: "w-100",
                                    attrs: { label: "template" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center mb-2",
                                      },
                                      [
                                        _c(
                                          "i",
                                          {
                                            staticClass:
                                              "text-2x mr-2 material-icons text-blue-500",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        layers\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-lg font-weight-bold text-grey-9",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Use a template\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "text-md l-h" }, [
                                      _vm._v(
                                        "\n                                    Begin with a predefined template and personalize it to match your requirements.\n                                "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.current_step === 2
            ? [
                _c(
                  "el-form-item",
                  { staticClass: "pb-2" },
                  [
                    _c("div", { staticClass: "form-label" }, [
                      _c("h5", [_vm._v("Predefined Section")]),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(
                          "\n                        Select a predefined section to populate the label and prompt.\n                    "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "w-full",
                        attrs: {
                          placeholder: "Select a predefined section",
                          filterable: "",
                        },
                        on: { change: _vm.applyTemplate },
                        model: {
                          value: _vm.selected_template,
                          callback: function ($$v) {
                            _vm.selected_template = $$v
                          },
                          expression: "selected_template",
                        },
                      },
                      _vm._l(_vm.templates, function (option) {
                        return _c("el-option", {
                          key: option.id,
                          attrs: { label: option.label, value: option },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.current_step === 3
            ? [
                _c(
                  "el-form",
                  { staticClass: "no-border p-0" },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "p-2" },
                      [
                        _c("div", { staticClass: "form-label" }, [
                          _c("h5", [_vm._v("Label")]),
                          _vm._v(" "),
                          _c("small", [
                            _vm._v(
                              "\n                            Enter or edit the label for this section.\n                        "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("el-input", {
                          attrs: {
                            placeholder: "Enter the label for this section",
                          },
                          model: {
                            value: _vm.editing_digest.label,
                            callback: function ($$v) {
                              _vm.$set(_vm.editing_digest, "label", $$v)
                            },
                            expression: "editing_digest.label",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "p-2" },
                      [
                        _c("div", { staticClass: "form-label" }, [
                          _c("h5", [_vm._v("Prompt")]),
                          _vm._v(" "),
                          _c("small", [
                            _vm._v(
                              "\n                            Please provide clear and detailed instructions to guide the AI effectively.\n                        "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: "4",
                            maxlength: "3000",
                            placeholder:
                              "Enter the details for your section prompt",
                          },
                          model: {
                            value: _vm.editing_digest.prompt,
                            callback: function ($$v) {
                              _vm.$set(_vm.editing_digest, "prompt", $$v)
                            },
                            expression: "editing_digest.prompt",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "p-2" },
                      [
                        _c("div", { staticClass: "no-border form-label" }, [
                          _c("h5", [_vm._v("Order")]),
                          _vm._v(" "),
                          _c("small", [
                            _vm._v(
                              "\n                            Set the priority order for this section.\n                        "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("el-input-number", {
                          attrs: {
                            size: "mini",
                            min: 1,
                            max: _vm.digestReports.length + 1,
                          },
                          model: {
                            value: _vm.editing_digest.order,
                            callback: function ($$v) {
                              _vm.$set(_vm.editing_digest, "order", $$v)
                            },
                            expression: "editing_digest.order",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("\n                Cancel\n            "),
              ]),
              _vm._v(" "),
              _vm.current_step === 3
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveDigest },
                    },
                    [_vm._v("\n                Save\n            ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Send Test Daily Digest Report",
            width: "40%",
            visible: _vm.test_email_modal_visible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.test_email_modal_visible = $event
            },
            close: _vm.closeTestEmailModal,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "test_email_form",
              attrs: {
                "label-position": "top",
                model: _vm.test_email_form,
                rules: _vm.test_email_rules,
              },
            },
            [
              _c("div", { staticClass: "alert alert-info" }, [
                _c("i", { staticClass: "el-icon-info mr-2" }),
                _vm._v(
                  "\n                Send a test daily digest report to verify your configuration. The report will include all configured sections and insights.\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Email Addresses", prop: "emails_array" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-100",
                      attrs: {
                        multiple: "",
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": "",
                        placeholder: "Enter or select email addresses",
                        "value-key": "email",
                      },
                      on: { change: _vm.handleEmailsChange },
                      model: {
                        value: _vm.test_email_form.emails_array,
                        callback: function ($$v) {
                          _vm.$set(_vm.test_email_form, "emails_array", $$v)
                        },
                        expression: "test_email_form.emails_array",
                      },
                    },
                    _vm._l(_vm.suggested_emails, function (email, index) {
                      return _c("el-option", {
                        key: "email-option-" + email + "-" + index,
                        attrs: { label: email, value: email },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("small", { staticClass: "form-text text-muted" }, [
                    _c("i", { staticClass: "el-icon-info mr-1" }),
                    _vm._v(
                      "\n                    You can type new email addresses or select from suggested ones. Press Enter to add multiple emails.\n                "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Report Date", prop: "date" } },
                [
                  _c("el-date-picker", {
                    staticClass: "w-100",
                    attrs: {
                      type: "date",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "Select date for the report",
                      "picker-options": {
                        disabledDate(time) {
                          return time.getTime() > Date.now()
                        },
                      },
                    },
                    model: {
                      value: _vm.test_email_form.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.test_email_form, "date", $$v)
                      },
                      expression: "test_email_form.date",
                    },
                  }),
                  _vm._v(" "),
                  _c("small", { staticClass: "form-text text-muted" }, [
                    _c("i", { staticClass: "el-icon-time mr-1" }),
                    _vm._v(
                      "\n                    Select a date to generate the report for that specific day.\n                "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeTestEmailModal } }, [
                _vm._v("\n                Cancel\n            "),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.sending_test_email,
                    disabled: !_vm.isTestEmailFormValid,
                  },
                  on: { click: _vm.submitTestEmail },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.sending_test_email
                          ? "Sending..."
                          : "Send Test Email"
                      ) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }