<template>
    <div class="app-body"
         id="view">
        <image-assets-preloader></image-assets-preloader>

        <el-dialog :visible.sync="show_help"
                   title="The wallboard"
                   top="10vh"
                   append-to-body>
            <p class="text-md _600 break-word">PLACEHOLDER</p>
        </el-dialog>

        <welcome-to-aloware></welcome-to-aloware>

        <div class="fixed-header padding pt-0 pb-0" :style="{top: (top_offset+50) + 'px'}">
            <div class="row mb-0 mt-0">
                <div class="d-flex flex-fill justify-content-between p-2">
                    <div class="d-flex">
                        <ring-group-dropdown :ring_group_id="ring_group_id"/>

                        <button class="btn deep-orange p-x-md pull-right ml-2"
                                style="height: 40px"
                                v-if="ring_group_id"
                                @click="ring_group_id = null">
                            Reset Filters
                        </button>
                    </div>

                    <video-modal-activator size="small"
                                           v-if="isTrial && !isSimpSocial"/>
                </div>
            </div>
        </div>

        <!-- WAT-876: deprecation banner -->
        <al-alert :dimisable="true"
                  class="mt-5 pt-3">
            <span class="mr-5 text-dark"
                  v-html="DEPRECATION_MESSAGE" />
        </al-alert>

        <div class="padding mt-1"
             v-if="hasPermissionTo('list report')">
            <div class="row">
                <div class="col-12 p-0">
                    <wallboard-summary-report :ring_group_id="ring_group_id"></wallboard-summary-report>
                </div>
            </div>
        </div>

        <div class="padding"
             id="queue_log_table"
             v-if="hasPermissionTo('list communication')">
            <div class="row box">
                <div class="col-12">
                    <div class="no-effect">
                        <div class="box-body">
                            <queue-log-table :ring_group_id="ring_group_id"></queue-log-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="padding"
             id="live_log_table"
             v-if="hasPermissionTo('list communication')">
            <div class="row box">
                <div class="col-12">
                    <div class="no-effect">
                        <div class="box-body">
                            <live-log-table :ring_group_id="ring_group_id"></live-log-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="padding"
             id="parked_log_table"
             v-if="hasPermissionTo('list communication')">
            <div class="row box">
                <div class="col-12">
                    <div class="no-effect">
                        <div class="box-body">
                            <parked-log-table :ring_group_id="ring_group_id"></parked-log-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-backtop target="html"
                    ref="backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>

        <video-modal class="ml-auto"
                     title="🚀 Mastering Aloware Wallboard: Elevate Your Contact Center Insights"
                     cookie-name="wallboard"
                     notes="🚀 Amplify your insights with <strong>Aloware Wallboard!</strong> </br></br> 🌟 Visualize, track, and dominate. Dive into real-time data like never before. </br></br> Your dashboard revolution starts here!"
                     video-url="https://www.youtube.com/embed/D2w_cctKdzo?si=yRBRRxGMSTqaO89f"
                     learn-more-link="https://support.aloware.com/en/articles/9034191-introducing-aloware-wallboard-your-real-time-communication-metrics-dashboard"
                     :has-activator-button="true"
                     v-if="isTrial && !isSimpSocial"/>
    </div>
</template>

<script>
import auth from '../auth'
import {mapActions, mapState, mapGetters} from 'vuex'
import { acl_mixin, scroll_mixin, styling_mixin, supervisor_mixin, polling_mixin } from '../mixins'
import RingGroupDropdown from '../components/misc/ring-group-dropdown'
import VideoModal from '../components/video-modal.vue'
import VideoModalActivator from '../components/video-modal-activator.vue'
import Alert from '../components/alert'
import { WALLBOARD_BARGE_WHISPER_DEPRECATION_MESSAGE, buildMessage } from '../constants/deprecation.messages'


export default {
    mixins: [
        styling_mixin,
        acl_mixin,
        scroll_mixin,
        supervisor_mixin,
        polling_mixin
    ],

    components: {
        RingGroupDropdown,
        VideoModal,
        VideoModalActivator,
        'al-alert': Alert
    },

    data() {
        return {
            auth: auth,
            CancelToken: null,
            source: null,
            show_help: false,
            ring_group_id: null
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company,
            ring_groups: state => state.ring_groups,
            settings: state => state.settings,
        }),
        ...mapGetters('cache', [
          'isTrial',
          'isSimpSocial'
        ]),

        queued_ring_groups() {
            return this.ring_groups.filter(ring_group => ring_group.should_queue === true)
        },

        regular_ring_groups() {
            return this.ring_groups.filter(ring_group => ring_group.should_queue === false)
        },

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        },

        DEPRECATION_MESSAGE() {
            const TALK_URL = this.getTalkUrl()
            return  buildMessage(WALLBOARD_BARGE_WHISPER_DEPRECATION_MESSAGE, {
                TALK_URL
            })
        }
    },

    created() {
        this.CancelToken = axios.CancelToken
        this.source = this.CancelToken.source()

        if (!this.hasWallboardAccess) {
            this.goBack()
        }

        VueEvent.listen('show_queued_calls', () => {
            this.scrollWindowTo('queue_log_table')
        })

        VueEvent.listen('show_live_calls', () => {
            this.scrollWindowTo('live_log_table')
        })

        VueEvent.listen('show_parked_calls', () => {
            this.scrollWindowTo('parked_log_table')
        })

        VueEvent.listen('filtered_ring_group', (ring_group_id) => {
            this.ring_group_id = ring_group_id
        })
    },

    mounted() {
        this.setTitle()
        this.addUsersPoll()
    },

    methods: {
        setTitle() {
            axios.get('/get-statics')
                .then(res => {
                    let name = res.data.name
                    this.setPageTitle('Wallboard - ' + name)
                })
                .catch(err => {
                    this.setPageTitle('Wallboard - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },

        scrollWindowTo(element_id) {
            if (!$('#' + element_id).length) {
                return
            }

            let top_position = $('#' + element_id).offset().top
            let header_height = $(".app-header").height()

            $("html,body").animate({
                scrollTop: top_position - header_height - 50
            }, 500)
        },

        toggleHelp() {
            this.show_help = !this.show_help
        },

        ...mapActions(['setSettings'])
    },

    beforeRouteEnter(to, from, next) {
        if (to.query.api_token) {
            return next({name: 'Login', query: {api_token: to.query.api_token}})
        }

        auth.check().then((res) => {
            if (res.data.user.is_reseller) {
                // redirect to account management portal if the company is a reseller
                next({name: 'Account Management Portal'})
            } else {
                next()
            }
        }).catch((err) => {
            next({name: 'Login', query: {redirect: to.fullPath}})
        })
    }
}
</script>
