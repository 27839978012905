<template>
    <div class="alo-ai-voice-analytics">
        <al-alert :dimisable="false">
            <span class="mr-5 text-dark">
                New! Now you can configure focused email reports for this {{ EINBOX_NAME }}
            </span>
        </al-alert>
        <div class="cards-grid">
            <div class="card my-3"
                 :key="index"
                 v-for="(setting, index) in settings">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center">
                        <h6 class="card-title font-weight-bold mb-0">{{ setting.title }}</h6>
                        <el-switch class="ml-3"
                                   active-color="#00a344"
                                   v-model="setting.enabled" />
                    </div>
                    <p class="text-muted small mb-1">
                        {{ getDescription(setting.type) }}
                    </p>
                    <p class="mb-1 fixed-height-explanation">
                        {{ getExplanation(setting.type) }}
                    </p>
                    <div class="card-content mt-3">
                        <div class="d-flex align-items-center mb-3">
                            <i class="fa fa-bell-o mr-2" />
                            <span class="font-weight-bold color-dark mr-2">Notification Status:</span>
                            <span class="font-weight-bold"
                                  :class="setting.enabled ? 'text-success' : 'text-muted'">
                                {{ setting.enabled ? 'Enabled' : 'Disabled' }}
                            </span>
                        </div>

                        <hr class="my-3" />

                        <div class="notification-settings">
                            <p class="text-muted small mb-2">
                                {{ getNotificationSettingsDescription(setting.type, setting.title) }}
                            </p>
                            <div class="settings-group">
                                <div class="d-flex justify-content-between align-items-center mb-2">
                                    <span>
                                        <i class="fa small fa-users" />
                                        <span class="font-weight-bold">
                                            Connected Users
                                        </span>
                                    </span>
                                    <el-switch active-color="#00a344"
                                               :disabled="!setting.enabled"
                                               v-model="setting.connected_users_enabled" />
                                </div>
                                <div class="d-flex justify-content-between align-items-center mb-2">
                                    <span>
                                        <i class="fa small fa-user-plus" />
                                        <span class="font-weight-bold">
                                            Watching Users
                                        </span>
                                    </span>
                                    <el-switch active-color="#00a344"
                                               :disabled="!setting.enabled"
                                               v-model="setting.watching_users_enabled" />
                                </div>
                                <div class="d-flex justify-content-between align-items-center mb-2">
                                    <span>
                                        <i class="fa small fa-user-secret" />
                                        <span class="font-weight-bold">
                                            Teams Supervisors
                                        </span>
                                    </span>
                                    <el-switch active-color="#00a344"
                                               :disabled="!setting.enabled"
                                               v-model="setting.supervisor_enabled" />
                                </div>
                                <div class="d-flex justify-content-between align-items-center mb-3">
                                    <span>
                                        <i class="fa small fa-users" />
                                        <span class="font-weight-bold">
                                            Teams Users
                                        </span>
                                    </span>
                                    <el-switch active-color="#00a344"
                                               :disabled="!setting.enabled"
                                               v-model="setting.all_team_members_enabled" />
                                </div>
                            </div>

                            <div class="reports-section mt-3 text-muted bg-light p-3 rounded">
                                <p class="mb-2">
                                    <i class="fa fa-envelope-o mr-2" />
                                    <span class="small">
                                        Reports will include analytics for this Ring Group and all associated:
                                    </span>
                                </p>
                                <ul class="small ml-4 mb-0">
                                    <li>Lines</li>
                                    <li>Users</li>
                                    <li>Teams</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Alert from '../alert'
import { EINBOX_NAME } from '../../constants/team-inbox'

const DAILY_ACTIVITY_REPORT = 'daily_activity_report'
const ALO_AI_VOICE_ANALYTICS_NOTIFICATIONS = 'alo_ai_voice_analytics_notifications'

export default {
    name: 'ring-group-reporting',
    components: {
        'al-alert': Alert
    },
    props: {
        settings: {
            type: Array,
            required: true
        },
        ringGroupName: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            EINBOX_NAME
        }
    },
    methods: {
        updateReportEnabled (setting) {
            this.$emit('update:settings', this.settings)
        },
        getDescription (type) {
            switch (type) {
            case DAILY_ACTIVITY_REPORT:
                return `Configure daily activity reports for ${this.ringGroupName}`
            case ALO_AI_VOICE_ANALYTICS_NOTIFICATIONS:
                return `Configure AloAi Voice Analytics Notifications for ${this.ringGroupName}`
            }
        },
        getExplanation (type) {
            switch (type) {
            case DAILY_ACTIVITY_REPORT:
                return ''
            case ALO_AI_VOICE_ANALYTICS_NOTIFICATIONS:
                return `Our AI engine will summarize  transcribed calls within this ${EINBOX_NAME} and email the members below.`
            }
        },
        getNotificationSettingsDescription (type, title) {
            switch (type) {
            case DAILY_ACTIVITY_REPORT:
                return `When enabled, ${title} about ${this.ringGroupName} will be sent to:`
            case ALO_AI_VOICE_ANALYTICS_NOTIFICATIONS:
                return `When enabled, ${title} will be sent to:`
            }
        }
    }
}
</script>

<style scoped>
.alo-ai-voice-analytics {
  margin: 1rem 0;
}
.cards-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
@media (min-width: 769px) {
  .cards-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
}
.settings-group {
  margin-bottom: 1rem;
}
.settings-group span {
  font-size: 0.95rem;
  color: #2c3e50;
}
hr {
  border-color: #e9ecef;
}
.fixed-height-explanation {
  min-height: 30px;
  height: auto;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media (min-width: 769px) {
  .fixed-height-explanation {
    min-height: 40px;
  }
}
</style>
