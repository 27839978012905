<template>
    <div class="row">
        <div class="col-12">
            <el-form-item class="mb-2">
                <div class="form-label">
                    <h5>Email Notification</h5>
                    <small>
                        By enabling this, an email with the summary is sent at the end of each call.
                    </small>
                </div>

                <div class="switch-label d-flex align-items-center">
                    <el-switch
                        v-model="bot.post_call_notification"
                        active-color="#00BF50"
                    />
                    <small class="font-weight-bold custom-toggle-label ml-2">Enable email notification</small>
                </div>

                <el-radio-group
                    v-if="bot.post_call_notification"
                    v-model="bot.post_call_notification_mode"
                    class="w-100 pl-5"
                >
                    <el-radio 
                        :label="AloAi.POST_CALL_NOTIFICATION_MODE_ALL_USERS"
                        class="w-100"
                    >
                        <small>All users</small>
                    </el-radio>
                    <el-radio
                        :label="AloAi.POST_CALL_NOTIFICATION_MODE_CONTACT_OWNER"
                        class="w-100"
                    >
                        <small>Contact's Owner</small>
                    </el-radio>
                    <el-radio
                        :label="AloAi.POST_CALL_NOTIFICATION_MODE_SPECIFIC_USERS"
                        class="w-100"
                    >
                        <small>Specific Users</small>
                    </el-radio>
                    <div class="pt-1 pl-4 w-100">
                        <el-form-item
                            :rules="[
                                {
                                    validator: validateSpecificUsers,
                                    trigger: 'change'
                                }
                            ]"
                            prop="post_call_notification_users"
                        >
                            <user-selector
                                v-model="bot.post_call_notification_users"
                                :value="bot.post_call_notification_users"
                                :disabled="bot.post_call_notification_mode !== AloAi.POST_CALL_NOTIFICATION_MODE_SPECIFIC_USERS"
                                :selected-users="selectedUsers"
                                :hide_extensions="true"
                                @change="changeUser"
                                multiple
                                placeholder="Select users to notify"
                            />
                        </el-form-item>
                    </div>
                    <!-- Custom Email Input -->
                    <el-form-item
                        v-if="bot.post_call_notification"
                        class="mt-2 w-100"
                        :rules="[
                            {
                                validator: validateEmailNotification,
                                trigger: ['change', 'blur']
                            }
                        ]"
                        prop="post_call_notification_emails"
                    >
                        <div class="d-flex align-items-center mb-1 custom-label">
                            <small>Send to specific email addresses</small>
                            <el-tooltip
                                :disabled="!bot.post_call_notification_mode"
                                class="ml-2"
                                effect="dark"
                                content="Press 'Enter' to add multiple email addresses."
                                placement="top"
                            >
                                <i class="el-icon-info" />
                            </el-tooltip>
                        </div>
                        <el-select
                            v-model="bot.post_call_notification_emails"
                            :disabled="!bot.post_call_notification_mode"
                            multiple
                            filterable
                            allow-create
                            default-first-option
                            placeholder="Enter email addresses"
                            class="w-100"
                            @create="handleEmailCreate"
                        >
                            <el-option
                                v-for="(email, index) in emailHistory"
                                :key="'email-option-' + email + '-' + index"
                                :label="email"
                                :value="email"
                            />
                        </el-select>
                    </el-form-item>
                </el-radio-group>
            </el-form-item>
        </div>
    </div>
</template>

<script>
import * as AloAi from '../../../../constants/aloai'

export default {
    name: 'PostCallActionsTab',

    props: {
        bot: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            AloAi,
            loading: false,
            users: [],
            emailHistory: [],
        }
    },

    created() {
        // Initialize emailHistory with any existing emails
        if (this.bot.post_call_notification_emails?.length > 0) {
            this.emailHistory = [...this.bot.post_call_notification_emails];
        }
    },

    watch: {
        'bot.post_call_notification': {
            handler(newValue) {
                if (!newValue) {
                    this.bot.post_call_notification_mode = AloAi.POST_CALL_NOTIFICATION_MODE_DEFAULT
                }
            },
            deep: true
        },
        'bot.post_call_notification_emails': {
            handler(newEmails) {
                // Update emailHistory to include any new emails
                newEmails.forEach(email => {
                    if (!this.emailHistory.includes(email)) {
                        this.emailHistory.push(email);
                    }
                });
            },
            deep: true
        }
    },

    computed: {
        selectedUsers() {
            return this.bot.post_call_notification_users || []
        },
    },

    methods: {
        changeUser(users) {
            this.bot.post_call_notification_users = users
        },
        handleAlsoSendToToggle(value) {
            if (!value) {
                // When turning off, set to default mode
                this.bot.post_call_notification_mode = AloAi.POST_CALL_NOTIFICATION_MODE_DEFAULT
            }
        },
        handleEmailCreate(email) {
            // Add new email to history if it doesn't exist
            if (!this.emailHistory.includes(email)) {
                this.emailHistory.push(email);
            }
        },
        validateSpecificUsers(rule, value, callback) {
            if (this.bot.post_call_notification_mode === AloAi.POST_CALL_NOTIFICATION_MODE_SPECIFIC_USERS) {
                if (!value || value?.length === 0) {
                    callback(new Error('Please select at least one user to notify'))
                } else {
                    callback()
                }
            } else {
                callback()
            }
        },
        validateEmailNotification(rule, value, callback) {
            if (value) {
                // If email notification is enabled
                if (this.bot.post_call_notification_mode === AloAi.POST_CALL_NOTIFICATION_MODE_DEFAULT && 
                    (!this.bot.post_call_notification_emails || this.bot.post_call_notification_emails.length === 0)) {
                    callback(new Error('Please either select a notification mode or add at least one email address'))
                } else {
                    callback()
                }
            } else {
                callback()
            }
        },
    },
}
</script>

<style>
.custom-toggle-label {
    margin-top: 2px;
    color: #6d6e6e;
}
.custom-label {
    color: #686f89;
}
</style>
