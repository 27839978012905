var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body", attrs: { id: "view" } },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.show_help,
            "append-to-body": "",
            title: "What are ring groups?",
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_help = $event
            },
          },
        },
        [
          _c("p", { staticClass: "text-md _600 break-word" }, [
            _vm._v(
              "Ring Groups are a set of Users (Agents & Reps) that receive and\n            answer your calls in a predefined manner. Each ring group has a queue."
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-md _600 break-word" }, [
            _vm._v(
              "Lines route your calls to teams, group of agents, or queues that you\n            create here. Queue parameters are defined within each ring group."
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "fixed-header padding pt-0 pb-0",
          style: { top: _vm.top_offset + 50 + "px" },
        },
        [
          _c("div", { staticClass: "row mb-0 mt-0" }, [
            _c("div", { staticClass: "d-flex flex-fill flex-wrap p-2" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-fill flex-xl-grow-0 mr-auto pr-lg-2 search-toolbar",
                },
                [
                  _c("el-input", {
                    ref: "ringGroupSearch",
                    attrs: {
                      placeholder: "Search",
                      "prefix-icon": "el-icon-search",
                      clearable: "",
                      autofocus: "",
                    },
                    model: {
                      value: _vm.search_text,
                      callback: function ($$v) {
                        _vm.search_text = $$v
                      },
                      expression: "search_text",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex flex-wrap" }, [
                !_vm.hasRole("Company Agent") &&
                _vm.hasPermissionTo("create ring group")
                  ? _c("div", { staticClass: "mt-2 mt-lg-0 mr-2" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-md rounded greenish",
                          on: { click: _vm.addRingGroup },
                        },
                        [
                          _c("i", { staticClass: "fa fa-plus" }),
                          _vm._v(
                            "\n                            Add Ring Group\n                        "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isTrial && !_vm.isSimpSocial
                  ? _c(
                      "div",
                      { staticClass: "mt-3 mt-lg-0 mr-2" },
                      [_c("video-modal-activator")],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "mt-2 mt-lg-0 mr-2 mr-lg-0 d-flex flex-column justify-content-center text-blue",
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass:
                              "material-icons text-lg cursor-pointer",
                            on: { click: _vm.toggleHelp },
                          },
                          [
                            _vm._v(
                              "\n                            info_outline\n                        "
                            ),
                          ]
                        ),
                      ]
                    ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.hasPermissionTo("list report") && !_vm.search_text
        ? _c("div", { staticClass: "padding mt-4 pt-3" }, [
            _c("div", { staticClass: "row box" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "no-effect" }, [
                  _c(
                    "div",
                    { staticClass: "box-header pb-0" },
                    [
                      _vm.isCompanyPartOfAlowareDemoCompanies(false)
                        ? _c(
                            "al-alert",
                            {
                              staticClass: "text-center py-3",
                              attrs: { dimisable: true },
                            },
                            [
                              _c("p", { staticClass: "mr-5 text-dark" }, [
                                _vm._v(
                                  "\n                                Ring Groups are transitioning into "
                                ),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.EINBOX_NAME_PLURAL)),
                                ]),
                                _vm._v(
                                  ". While we haven't updated Classic's UI, the functionality is already in your account.\n                            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "mt-2 mb-0 text-dark" }, [
                                _vm._v(
                                  "\n                                Click "
                                ),
                                _c("strong", [_vm._v('"Go to Inbox"')]),
                                _vm._v(" below to get started with "),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.EINBOX_NAME_PLURAL)),
                                ]),
                                _vm._v(".\n                            "),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("activity-graph", {
                        attrs: {
                          base: "ring_group",
                          default_date_range: 7,
                          is_first_load: _vm.reports_first_load,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "padding" }, [
        _vm.hasPermissionTo("list ring group")
          ? _c("div", [
              _c("div", { staticClass: "row box" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "no-effect" }, [
                    _c("div", { staticClass: "box-header" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("span", { staticClass: "pull-left font-120" }, [
                            _c("strong", [_vm._v(_vm._s(_vm.dataCount || 0))]),
                            _vm._v(
                              "\n                                        " +
                                _vm._s(
                                  _vm.dataCount > 1
                                    ? "ring groups"
                                    : "ring group"
                                ) +
                                "\n                                    "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "box-body" }, [
                      _c(
                        "div",
                        { on: { mouseleave: _vm.tableLeave } },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading,
                                  expression: "loading",
                                },
                              ],
                              staticClass: "w-full",
                              attrs: {
                                data: _vm.paginatedData,
                                "default-sort": {
                                  prop: "name",
                                  order: "ascending",
                                },
                                fit: "",
                                stripe: "",
                              },
                              on: { "cell-mouse-enter": _vm.cellEnter },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "ID",
                                  prop: "id",
                                  sortable: "",
                                  width: "100",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "text-greyish" },
                                            [_vm._v(_vm._s(scope.row.id))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2427425926
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Name",
                                  prop: "name",
                                  sortable: "",
                                  width: "300",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "Ring Group Dialog",
                                                  params: {
                                                    ring_group_id: scope.row.id,
                                                  },
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-dark-greenish text-ellipsis",
                                                  attrs: {
                                                    title: scope.row.name,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    " +
                                                      _vm._s(scope.row.name) +
                                                      "\n                                                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3576729668
                                ),
                              }),
                              _vm._v(" "),
                              _vm.hasPermissionTo("list campaign")
                                ? _c("el-table-column", {
                                    attrs: {
                                      "min-width": 200,
                                      label: "Connected Lines",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.campaign_ids.length
                                                ? [
                                                    _c(
                                                      "ul",
                                                      [
                                                        _vm._l(
                                                          scope.row
                                                            .campaign_ids,
                                                          function (
                                                            campaign_id,
                                                            index
                                                          ) {
                                                            return (!_vm.showAllLines(
                                                              scope.row.id
                                                            ) &&
                                                              index < 5) ||
                                                              _vm.showAllLines(
                                                                scope.row.id
                                                              )
                                                              ? _c(
                                                                  "li",
                                                                  {
                                                                    key: index,
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "text-greyish",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "campaignName"
                                                                            )(
                                                                              campaign_id,
                                                                              _vm.campaigns
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        scope.row.campaign_ids
                                                          .length > 5
                                                          ? [
                                                              _vm.showAllLines(
                                                                scope.row.id
                                                              )
                                                                ? _c(
                                                                    "li",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "list-style-type":
                                                                            "none",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "text-blue",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return _vm.seeMoreLinesToggle(
                                                                                  scope
                                                                                    .row
                                                                                    .id,
                                                                                  false
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Show less"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              !_vm.showAllLines(
                                                                scope.row.id
                                                              )
                                                                ? _c(
                                                                    "li",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "list-style-type":
                                                                            "none",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "text-blue",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return _vm.seeMoreLinesToggle(
                                                                                  scope
                                                                                    .row
                                                                                    .id
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "and " +
                                                                              _vm._s(
                                                                                scope
                                                                                  .row
                                                                                  .campaign_ids
                                                                                  .length -
                                                                                  5
                                                                              ) +
                                                                              " more, click to expand"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                : _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-greyish",
                                                    },
                                                    [_vm._v("-")]
                                                  ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      973209092
                                    ),
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isAdmin
                                ? _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "Team",
                                      prop: "teams",
                                      sortable: "",
                                      width: "150",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.teams &&
                                              scope.row.teams.length
                                                ? [
                                                    scope.row.teams.length > 1
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-dark-greenish",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  scope.row
                                                                    .teams
                                                                    .length
                                                                ) +
                                                                " Teams\n                                                "
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-dark-greenish",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  scope.row
                                                                    .teams[0]
                                                                    .name
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                          ]
                                                        ),
                                                  ]
                                                : _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-dark-greenish",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                -\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2695662796
                                    ),
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: "Users",
                                  prop: "user_ids",
                                  sortable: "",
                                  width: "150",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.user_ids
                                            ? [
                                                scope.row.user_ids.length > 1
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-dark-greenish",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    " +
                                                            _vm._s(
                                                              scope.row.user_ids
                                                                .length
                                                            ) +
                                                            " Users\n                                                "
                                                        ),
                                                      ]
                                                    )
                                                  : scope.row.user_ids.length ==
                                                      1 &&
                                                    _vm.getUser(
                                                      scope.row.user_ids[0]
                                                    )
                                                  ? [
                                                      _vm.getUser(
                                                        scope.row.user_ids[0]
                                                      )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-dark-greenish",
                                                              attrs: {
                                                                title:
                                                                  _vm.getUserName(
                                                                    _vm.getUser(
                                                                      scope.row
                                                                        .user_ids[0]
                                                                    )
                                                                  ),
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                        " +
                                                                  _vm._s(
                                                                    _vm.getUserName(
                                                                      _vm.getUser(
                                                                        scope
                                                                          .row
                                                                          .user_ids[0]
                                                                      )
                                                                    )
                                                                  ) +
                                                                  "\n                                                    "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-dark-greenish",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    -\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                              ]
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1909353036
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: "Active Users",
                                  sortable: "",
                                  width: "200",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.user_ids
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "text-ellipsis",
                                                  class: [
                                                    _vm.getActiveUsers(
                                                      scope.row?.user_ids,
                                                      scope.row?.team_ids
                                                    ) == 0
                                                      ? "text-danger"
                                                      : "text-greyish",
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                " +
                                                      _vm._s(
                                                        _vm.getActiveUsers(
                                                          scope.row?.user_ids,
                                                          scope.row?.team_ids
                                                        )
                                                      ) +
                                                      "\n                                            "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "text-muted" },
                                                [
                                                  _vm._v(
                                                    "\n                                                -\n                                            "
                                                  ),
                                                ]
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2426598175
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: "Available Users",
                                  sortable: "",
                                  width: "200",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.user_ids
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "text-ellipsis",
                                                  class: [
                                                    _vm.getAvailableUsers(
                                                      scope.row?.user_ids,
                                                      scope.row?.team_ids
                                                    ) == 0
                                                      ? "text-danger"
                                                      : "text-greyish",
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                " +
                                                      _vm._s(
                                                        _vm.getAvailableUsers(
                                                          scope.row?.user_ids,
                                                          scope.row?.team_ids
                                                        )
                                                      ) +
                                                      "\n                                            "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "text-muted" },
                                                [
                                                  _vm._v(
                                                    "\n                                                -\n                                            "
                                                  ),
                                                ]
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4234214591
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Dial Mode",
                                  prop: "dial_mode",
                                  sortable: "",
                                  width: "150",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-greyish text-ellipsis",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "humanReadableDialMode"
                                                    )(scope.row.dial_mode)
                                                  ) +
                                                  "\n                                            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3294836527
                                ),
                              }),
                              _vm._v(" "),
                              !_vm.hasRole("Company Agent")
                                ? _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      fixed: "right",
                                      label: "Operations",
                                      width: "180",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  style: _vm.getStyle(
                                                    scope.row.id
                                                  ),
                                                  attrs: { slot: "reference" },
                                                  slot: "reference",
                                                },
                                                [
                                                  _vm.hasPermissionTo([
                                                    "view ring group",
                                                    "list communication",
                                                    "view communication",
                                                  ])
                                                    ? _c(
                                                        "div",
                                                        { staticClass: "row" },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-12",
                                                            },
                                                            [
                                                              _c(
                                                                "router-link",
                                                                {
                                                                  attrs: {
                                                                    to: {
                                                                      name: "Ring Group Activity",
                                                                      params: {
                                                                        ring_group_id:
                                                                          scope
                                                                            .row
                                                                            .id,
                                                                      },
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "btn btn-sm btn-block purplish",
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fa fa-signal pull-left",
                                                                      }),
                                                                      _vm._v(
                                                                        "\n                                                                Activity\n                                                            "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.hasPermissionTo(
                                                    "update ring group"
                                                  ) ||
                                                  _vm.hasPermissionTo(
                                                    "delete ring group"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row mt-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-12",
                                                            },
                                                            [
                                                              _c(
                                                                "router-link",
                                                                {
                                                                  attrs: {
                                                                    to: {
                                                                      name: "Ring Group Dialog",
                                                                      params: {
                                                                        ring_group_id:
                                                                          scope
                                                                            .row
                                                                            .id,
                                                                      },
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "btn btn-sm btn-block info",
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fa fa-cogs pull-left",
                                                                      }),
                                                                      _vm._v(
                                                                        "\n                                                                Settings\n                                                            "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.hasPermissionTo(
                                                    "delete ring group"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row mt-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-12",
                                                            },
                                                            [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn btn-sm btn-block danger",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleteRingGroupRemote(
                                                                          scope
                                                                            .row
                                                                            .id
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fa fa-trash pull-left",
                                                                  }),
                                                                  _vm._v(
                                                                    "\n                                                            Delete\n                                                        "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "row mt-2" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-12",
                                                        },
                                                        [
                                                          _vm.isCompanyPartOfAlowareDemoCompanies(
                                                            false
                                                          ) &&
                                                          scope.row
                                                            .is_part_of_ring_group
                                                            ? _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn btn-sm btn-block bluish",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openInbox(
                                                                          scope
                                                                            .row
                                                                            .id
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fa fa-inbox pull-left",
                                                                  }),
                                                                  _vm._v(
                                                                    "\n                                                            Go to Inbox\n                                                        "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2495414881
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.pageCount >= 1
                        ? _c(
                            "div",
                            { staticClass: "mt-3" },
                            [
                              _c("el-pagination", {
                                staticClass: "pull-right",
                                attrs: {
                                  "current-page": _vm.pagination.current_page,
                                  "page-size": 20,
                                  "page-sizes": [10, 20, 50, 100, 250],
                                  total: _vm.dataCount,
                                  background: "",
                                  layout: "sizes, prev, pager, next",
                                },
                                on: {
                                  "update:currentPage": function ($event) {
                                    return _vm.$set(
                                      _vm.pagination,
                                      "current_page",
                                      $event
                                    )
                                  },
                                  "update:current-page": function ($event) {
                                    return _vm.$set(
                                      _vm.pagination,
                                      "current_page",
                                      $event
                                    )
                                  },
                                  "current-change": _vm.changePage,
                                  "size-change": _vm.handleSizeChange,
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "clearfix" }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("el-backtop", {
        ref: "backtop",
        attrs: { target: "html", bottom: 20, right: 20 },
      }),
      _vm._v(" "),
      _vm.isTrial && !_vm.isSimpSocial
        ? _c("video-modal", {
            staticClass: "pl-2",
            attrs: {
              title: "🔥 Dive into Ring Groups!",
              "cookie-name": "ring-group",
              notes:
                "🔥 Dive into Ring Groups! A select squad of Agents & Reps awaiting your calls with precision. </br></br>Each group, its own unique queue. With Lines, steer those calls to dream teams, agent clusters, or the perfect queue. </br></br>Customize that queue magic within each group! 💥📞🔥",
              "video-url":
                "https://www.youtube.com/embed/4_Y5MAUjh3k?si=xPantGBLf_VQC9T-",
              "learn-more-link":
                "https://support.aloware.com/en/articles/9031514-mapping-ring-groups-for-inbound-calls-in-aloware",
              "has-activator-button": true,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }