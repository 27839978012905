<template>
    <div>
        <el-dialog :visible.sync="visible"
                   title="Local Presence Wizard"
                   width="70vw"
                   top="4vh"
                   append-to-body
                   custom-class="wizard-dialog dialog-no-border"
                   v-loading="loading"
                   @close="closeModal">
            <div slot="title">
                <h5 class="mb-0">
                    <i class="fas fa-hat-wizard"></i>
                    Local Presence Wizard
                </h5>
            </div>
            <template>
                <el-steps class="mb-3"
                          align-center
                          finish-status="success"
                          :active="current_step">
                    <el-step title="Information"/>
                    <el-step title="Confirmation"/>
                </el-steps>
                <div>
                    <div class="step-1"
                         v-show="current_step === 0">
                        <p class="form-head font-weight-bold">
                            Local Presence
                        </p>
                        <div class="dropdown-divider"></div>
                        <p style="word-break: keep-all;">
                            By dynamically matching your outbound Caller ID with the prospect's phone number,
                            our local presence engine increases the likelihood of pickups and connections.
                            With our service, you can use local area codes from anywhere in the US, the UK, and
                            Canada, making your calls appear like they're coming from your customer's own neighborhood.
                        </p>
                        <p style="word-break: keep-all;">
                            <span class="font-weight-bold">Note: </span> To enable SMS/MMS, a separate A2P Messaging Campaign is required for Local Presence lines.
                        </p>
                        <div class="mb-3">
                            <p class="mb3">
                                Please, choose from the following
                            </p>
                            <el-select placeholder="Select Country"
                                       size="large"
                                       v-model="country">
                                <el-option :key="key"
                                           :value="key"
                                           v-for="([key, value]) in Object.entries(availableCountries)"
                                           :disabled="shouldDisableLocalPresenceOption(key)">
                                    <el-tooltip v-if="shouldDisableLocalPresenceOption(key)"
                                                :content="country_tooltip">
                                        <span>{{ value }}</span>
                                    </el-tooltip>
                                    <span v-else>{{ value }}</span>
                                </el-option>
                            </el-select>
                        </div>
                        <el-radio-group class="ml-3"
                                        v-model="selectedLocalPresencePackage">
                            <div class="mb-3"
                                 v-bind:key="id"
                                 v-for="(option, id) in options">
                                <el-radio size="large"
                                          :label="option.name"
                                >
                                    {{ option?.name }} | {{ option?.data?.price | toCurrency }}
                                </el-radio>
                            </div>
                        </el-radio-group>
                        <div class="text-right mt-4">
                            <el-button type="success"
                                       :disabled="selectedLocalPresencePackage == null"
                                       @click.prevent="current_step += 1">
                                Next
                                <i class="el-icon-arrow-right el-icon-right"></i>
                            </el-button>
                        </div>
                    </div>
                    <div class="step-2"
                         v-show="current_step === 1">
                        <p class="form-head font-weight-bold">
                            Confirm your purchase
                        </p>
                        <div class="dropdown-divider"></div>
                        <p>
                            Your selected plan:
                            <span class="font-weight-bold">
                                {{ countries[country] }} - {{ selectedLocalPresencePackage }} | {{ getPriceByName(selectedLocalPresencePackage) | toCurrency }}
                            </span>
                        </p>
                        <p>
                            By clicking Confirm, you are agreeing to be charged for this package on a per {{ period }} basis.
                        </p>
                        <div class="text-right mt-4">
                            <el-button type="warning"
                                       @click.prevent="back">
                                Back
                                <el-icon>
                                    <Close/>
                                </el-icon>
                            </el-button>
                            <el-button type="danger"
                                       @click.prevent="closeModal">
                                Cancel
                                <el-icon>
                                    <Close/>
                                </el-icon>
                            </el-button>
                            <el-button type="success"
                                       @click.prevent="proceed">
                                Confirm
                                <el-icon><Select/></el-icon>
                            </el-button>
                        </div>
                    </div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<script>

import Auth from '../auth'
import {acl_mixin, addons_mixin, phone_wizard_mixin} from '../mixins'

const countries = {
    'US': 'United States',
    'GB': 'United Kingdom',
    'CA': 'Canada',
}

export default {
    mixins: [
        addons_mixin,
        acl_mixin,
        phone_wizard_mixin
    ],

    data() {
        return {
            current_step: 0,
            selectedLocalPresencePackage: null,
            visible: false,
            loading: false,
            Auth,
            countries: countries,
            country: null
        }
    },

    methods: {
        closeModal() {
            this.visible = false
            this.current_step = 0
            this.country = null
            this.selectedLocalPresencePackage = null
        },

        open() {
            this.visible = true
        },

        back() {
            this.current_step = 0
        },

        proceed() {
            this.loading = true

            let body = {local_presence: this.getTypeByName(this.selectedLocalPresencePackage)}

            axios.post('/api/v1/purchase-local-presence', body)
                .then(res => {
                    this.closeModal()
                    this.$notify({
                        offset: 95,
                        title: 'Local Presence Wizard',
                        message: 'We are populating your lines',
                        type: 'success',
                        showClose: true,
                    })
                })
                .catch(err => {
                    console.log('Local presence purchase error', err)

                    let error_message = err.response.data.message ?? "It was't possible to purchase local presence. Please, contact our support."

                    this.closeModal()
                    this.$notify({
                        offset: 95,
                        title: 'Local Presence Wizard',
                        message: error_message,
                        type: 'error',
                        showClose: true,
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },

    computed: {
        options() {
            if (this.country === null) {
                return []
            }

            return this.getLocalPresenceOptionsByCountry(this.country)
        },

        availableCountries() {
            return this.countries
        },

        period() {
            for (let addon of this.addons) {
                if (addon.name === this.selectedLocalPresencePackage) {
                    switch (addon.period) {
                        case 1:
                            return 'month'
                        case 3:
                            return 'quarter'
                        case 12:
                            return 'year'
                    }
                }
            }
        }
    },

    watch: {
        country() {
            this.selectedLocalPresencePackage = null
        }
    }
}
</script>
