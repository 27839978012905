var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "subscription-modal" },
    [
      _c(
        "el-steps",
        {
          staticClass: "mb-4",
          attrs: {
            "align-center": "",
            "finish-status": "success",
            active: _vm.currentStep,
          },
        },
        [
          _c("el-step", { attrs: { title: "Plan Selection" } }),
          _vm._v(" "),
          _c("el-step", { attrs: { title: "Confirmation" } }),
          _vm._v(" "),
          _c("el-step", { attrs: { title: "Payment Successful" } }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.currentStep === 0
        ? _c(
            "div",
            [
              _c(
                "el-form",
                { ref: "subscriptionForm", attrs: { rules: _vm.rules } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "selectedPlanIdentifier" } },
                        [
                          _c("div", { staticClass: "plans-header-container" }, [
                            _c("div", { staticClass: "plans-header-section" }, [
                              _c("h3", [_vm._v("Startup Packages")]),
                              _vm._v(" "),
                              _c("p", { staticClass: "section-subtitle" }, [
                                _vm._v(
                                  "Simple, all-inclusive solution for up to 5 users"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "plans-header-section" }, [
                              _c("h3", [_vm._v("Pro Plans + AI ✨")]),
                              _vm._v(" "),
                              _c("p", { staticClass: "section-subtitle" }, [
                                _vm._v(
                                  "Truly unlimited agent minutes, no long term contracts, no hidden fees."
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "plan-cards-container" }, [
                            _c(
                              "div",
                              { staticClass: "plans-grid" },
                              [
                                _vm._l(_vm.regularPlans, function (plan) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        key: _vm.getPlanIdentifier(plan),
                                        staticClass: "plan-card",
                                        class: {
                                          "plan-card-selected":
                                            _vm.getPlanIdentifier(plan) ===
                                            _vm.selectedPlanIdentifier,
                                          "startup-plan":
                                            _vm.getPlanCategory(plan) ===
                                            "startup",
                                          "is-expanded": _vm.isExpanded(plan),
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "plan-card-header flex-grow-1 d-flex flex-column",
                                          },
                                          [
                                            _c(
                                              "h4",
                                              { staticClass: "plan-name" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getDisplayName(plan)
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "plan-price startup-price",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "amount" },
                                                  [
                                                    _vm._v(
                                                      "$" + _vm._s(plan.price)
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                plan.included_users
                                                  ? [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "period",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " / up to " +
                                                              _vm._s(
                                                                plan.included_users
                                                              ) +
                                                              " users"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "period-text" },
                                              [_vm._v("per account per month")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "expand-section mt-auto",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "expand-toggle-button",
                                                    class: {
                                                      "is-expanded":
                                                        _vm.isExpanded(plan),
                                                    },
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.togglePlanExpansion(
                                                          plan
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.isExpanded(plan)
                                                            ? "Hide Features"
                                                            : "View Features"
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("i", {
                                                      class: _vm.isExpanded(
                                                        plan
                                                      )
                                                        ? "el-icon-arrow-up"
                                                        : "el-icon-arrow-down",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "plan-card-body",
                                            class: {
                                              "is-expanded":
                                                _vm.isExpanded(plan),
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "plan-features" },
                                              [
                                                _vm.planFeatures[
                                                  _vm.getPlanIdentifier(plan)
                                                ]
                                                  ? _vm._l(
                                                      _vm.planFeatures[
                                                        _vm.getPlanIdentifier(
                                                          plan
                                                        )
                                                      ],
                                                      function (
                                                        feature,
                                                        index
                                                      ) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "feature",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-check",
                                                            }),
                                                            _vm._v(" "),
                                                            _c("div", {
                                                              staticClass:
                                                                "feature-content",
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    feature.text
                                                                  ),
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            feature.tooltip
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "tooltiptext",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "tooltip-content",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            feature.tooltip
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      }
                                                    )
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "plan-card-footer" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "plan-select-button",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.selectPlan(plan)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Get " +
                                                    _vm._s(
                                                      _vm.getDisplayName(plan)
                                                    ) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                }),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.groupedPlans,
                                  function (groupedPlan) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          key: groupedPlan.title,
                                          staticClass: "plan-card",
                                          class: {
                                            "plan-card-selected":
                                              _vm.isSelectedPlanGroup(
                                                groupedPlan
                                              ),
                                            "most-popular":
                                              groupedPlan.title ===
                                              "xPro + AI ✨",
                                            "is-expanded":
                                              _vm.isExpanded(groupedPlan),
                                          },
                                        },
                                        [
                                          groupedPlan.title === "xPro + AI ✨"
                                            ? _c("img", {
                                                staticClass: "ribbon-icon",
                                                attrs: {
                                                  src: "/assets/images/ribbon.png",
                                                  alt: "Most Popular",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "plan-card-header" },
                                            [
                                              _c(
                                                "h4",
                                                { staticClass: "plan-name" },
                                                [
                                                  _vm._v(
                                                    _vm._s(groupedPlan.title)
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "plan-price" },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "amount" },
                                                    [
                                                      _vm._v(
                                                        "$" +
                                                          _vm._s(
                                                            _vm.getSelectedVariantPrice(
                                                              groupedPlan
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "period" },
                                                    [_vm._v("/ user")]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "period-text" },
                                                [_vm._v("minimum 5 users")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "billing-toggle mt-3",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      class: {
                                                        active:
                                                          _vm.isMonthlySelected(
                                                            groupedPlan
                                                          ),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.selectBillingPeriod(
                                                            groupedPlan,
                                                            "monthly"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                Monthly\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      class: {
                                                        active:
                                                          !_vm.isMonthlySelected(
                                                            groupedPlan
                                                          ),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.selectBillingPeriod(
                                                            groupedPlan,
                                                            "quarterly"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                Quarterly\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "expand-section",
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "expand-toggle-button",
                                                      class: {
                                                        "is-expanded":
                                                          _vm.isExpanded(
                                                            groupedPlan
                                                          ),
                                                      },
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.togglePlanExpansion(
                                                            groupedPlan
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.isExpanded(
                                                              groupedPlan
                                                            )
                                                              ? "Hide Features"
                                                              : "View Features"
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("i", {
                                                        class: _vm.isExpanded(
                                                          groupedPlan
                                                        )
                                                          ? "el-icon-arrow-up"
                                                          : "el-icon-arrow-down",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "plan-card-body",
                                              class: {
                                                "is-expanded":
                                                  _vm.isExpanded(groupedPlan),
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "plan-features",
                                                },
                                                [
                                                  groupedPlan.title ===
                                                  "uPro + AI ✨"
                                                    ? _vm._l(
                                                        _vm.uProFeatures,
                                                        function (
                                                          feature,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "feature tooltip-container",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-check",
                                                              }),
                                                              _vm._v(" "),
                                                              _c("div", {
                                                                staticClass:
                                                                  "feature-content",
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      feature.text
                                                                    ),
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              feature.tooltip
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "tooltiptext",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "tooltip-content",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              feature.tooltip
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    : groupedPlan.title ===
                                                      "xPro + AI ✨"
                                                    ? _vm._l(
                                                        _vm.xProFeatures,
                                                        function (
                                                          feature,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "feature tooltip-container",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-check",
                                                              }),
                                                              _vm._v(" "),
                                                              _c("div", {
                                                                staticClass:
                                                                  "feature-content",
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      feature.text
                                                                    ),
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              feature.tooltip
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "tooltiptext",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "tooltip-content",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              feature.tooltip
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "plan-card-footer" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "plan-select-button",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      _vm.selectBillingPeriod(
                                                        groupedPlan,
                                                        _vm.isMonthlySelected(
                                                          groupedPlan
                                                        )
                                                          ? "monthly"
                                                          : "quarterly",
                                                        true
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Get " +
                                                      _vm._s(
                                                        groupedPlan.title
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isAccountBasedPlan && _vm.selectedPlan
                    ? _c(
                        "div",
                        {
                          ref: "quantitySection",
                          staticClass: "quantity-section mb-4",
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "quantity_user_seat" } },
                            [
                              _c("label", { staticClass: "form-label" }, [
                                _c("b", [_vm._v("Number of Users")]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "quantity-controls" },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      type: "default",
                                      icon: "el-icon-minus",
                                      disabled:
                                        _vm.quantity_user_seat <=
                                        _vm.minUserSeat,
                                    },
                                    on: { click: _vm.decrementUsers },
                                  }),
                                  _vm._v(" "),
                                  _c("el-input-number", {
                                    staticClass: "quantity-input",
                                    attrs: {
                                      min: _vm.minUserSeat,
                                      max: _vm.maxUserSeat,
                                      controls: false,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$refs.subscriptionForm.validateField(
                                          "quantity_user_seat"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.quantity_user_seat,
                                      callback: function ($$v) {
                                        _vm.quantity_user_seat = $$v
                                      },
                                      expression: "quantity_user_seat",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-button", {
                                    attrs: {
                                      type: "default",
                                      icon: "el-icon-plus",
                                      disabled:
                                        _vm.quantity_user_seat >=
                                        _vm.maxUserSeat,
                                    },
                                    on: { click: _vm.incrementUsers },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.selectedPlan
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "quantity-info mt-2 text-muted",
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(_vm.message) +
                                          "\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedPlan
                    ? _c("div", { staticClass: "cost-summary" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center p-3 bg-light",
                          },
                          [
                            _c("div", { staticClass: "cost-label" }, [
                              _c("h6", { staticClass: "mb-0" }, [
                                _vm._v("Estimated Total"),
                              ]),
                              _vm._v(" "),
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v(
                                  "\n                            $" +
                                    _vm._s(_vm.selectedPlan.price) +
                                    " × " +
                                    _vm._s(
                                      _vm.isAccountBasedPlan
                                        ? "1 account"
                                        : `${_vm.quantity_user_seat} users`
                                    ) +
                                    "\n                        "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "cost-amount" }, [
                              _c("h5", { staticClass: "mb-0" }, [
                                _vm._v("$" + _vm._s(_vm.totalCost)),
                              ]),
                              _vm._v(" "),
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v(
                                  "\n                          per " +
                                    _vm._s(
                                      _vm
                                        .getTermPeriodName(
                                          _vm.selectedPlan.term
                                        )
                                        .toLowerCase()
                                    ) +
                                    "\n                        "
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentStep === 1
        ? _c("div", [
            _c("div", { staticClass: "p-4 border" }, [
              _c("h5", { staticClass: "mb-3 text-center" }, [
                _vm._v("Review Your Purchase"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "order-details" },
                [
                  _c("div", { staticClass: "detail-row mb-3" }, [
                    _c("div", { staticClass: "detail-label" }, [
                      _vm._v("Selected Plan:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "detail-value fw-bold" }, [
                      _vm._v(_vm._s(_vm.formatPlanName(_vm.selectedPlan.name))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "detail-row mb-3" }, [
                    _c("div", { staticClass: "detail-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.isAccountBasedPlan
                            ? "Number of Accounts:"
                            : "Number of Users:"
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "detail-value fw-bold" }, [
                      _vm._v(
                        _vm._s(
                          _vm.isAccountBasedPlan ? "1" : _vm.quantity_user_seat
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "detail-row mb-3" }, [
                    _c("div", { staticClass: "detail-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.isAccountBasedPlan
                            ? "Price per Account:"
                            : "Price per User:"
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "detail-value" }, [
                      _vm._v("$" + _vm._s(_vm.selectedPlan.price)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "detail-row mb-3" }, [
                    _c("div", { staticClass: "detail-label" }, [
                      _vm._v("Billing Frequency:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "detail-value" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getBillingFrequencyName(_vm.selectedPlan.term)
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "total-cost p-3 mt-4 bg-light" }, [
                    _c("div", { staticClass: "detail-row" }, [
                      _c("div", { staticClass: "detail-label h6 mb-0" }, [
                        _vm._v("Total Amount:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "detail-value h5 mb-0 text-success" },
                        [_vm._v("$" + _vm._s(_vm.totalCost))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-muted small text-end" }, [
                      _vm._v(
                        "\n                    per " +
                          _vm._s(
                            _vm
                              .getTermPeriodName(_vm.selectedPlan.term)
                              .toLowerCase()
                          ) +
                          "\n                "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "checkbox-md mt-2 q-pr-xs",
                      attrs: { color: "primary", dense: "" },
                      model: {
                        value: _vm.agreed_to_terms,
                        callback: function ($$v) {
                          _vm.agreed_to_terms = $$v
                        },
                        expression: "agreed_to_terms",
                      },
                    },
                    [
                      _c("template", { slot: "default" }, [
                        _vm._v(
                          "\n                    I agree to\n                    "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "text-bluish _600",
                            attrs: {
                              href: _vm.statics.link_terms_conditions,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Terms and Conditions\n                    "
                            ),
                          ]
                        ),
                        _vm._v(
                          "\n                    and\n                    "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "text-bluish _600",
                            attrs: {
                              href: _vm.statics.acceptable_use_policy,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Acceptable Use Policy.\n                    "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "checkbox-md mt-2",
                      model: {
                        value: _vm.accepted_non_refundable_subscription,
                        callback: function ($$v) {
                          _vm.accepted_non_refundable_subscription = $$v
                        },
                        expression: "accepted_non_refundable_subscription",
                      },
                    },
                    [
                      _vm._v(
                        '\n                By clicking "Confirm & Pay", I acknowledge that I will be charged immediately, and all purchases are final and non-refundable.\n            '
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.currentStep === 2
        ? _c("div", { staticClass: "payment-success" }, [
            _c("div", { staticClass: "summary-container p-3 border" }, [
              _c("h5", { staticClass: "mb-3 text-success text-center" }, [
                _vm._v("Payment Successful"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-3" }, [
                _c("b", { staticClass: "mb-3" }, [
                  _vm._v("Subscription Details:"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ml-3 mt-3" }, [
                  _c("div", { staticClass: "mb-2" }, [
                    _vm._v("Status: "),
                    _c("span", { staticClass: "text-capitalize" }, [
                      _vm._v(_vm._s(_vm.paymentResult.subscription.status)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-2" }, [
                    _vm._v(
                      "Billing Period: " +
                        _vm._s(_vm.paymentResult.subscription.billing_period) +
                        "\n                      " +
                        _vm._s(
                          _vm.paymentResult.subscription.billing_period_unit
                        ) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-2" }, [
                    _vm._v(
                      "Start Date: " +
                        _vm._s(
                          _vm.formatDate(
                            _vm.paymentResult.subscription.started_at
                          )
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-2" }, [
                    _vm._v(
                      "Next Billing: " +
                        _vm._s(
                          _vm.formatDate(
                            _vm.paymentResult.subscription.next_billing_at
                          )
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-2" }, [
                    _vm._v(
                      "Plan Amount: $" +
                        _vm._s(
                          (
                            _vm.paymentResult.subscription.plan_amount / 100
                          ).toFixed(2)
                        )
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.paymentResult.invoice
                ? _c("div", { staticClass: "mb-3" }, [
                    _c("b", { staticClass: "mb-3" }, [
                      _vm._v("Invoice Details:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-3 mt-3" }, [
                      _c("div", { staticClass: "mb-2" }, [
                        _vm._v(
                          "Invoice Number: #" +
                            _vm._s(_vm.paymentResult.invoice.id)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-2" }, [
                        _vm._v(
                          "Amount Paid: $" +
                            _vm._s(
                              (
                                _vm.paymentResult.invoice.amount_paid / 100
                              ).toFixed(2)
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-2" }, [
                        _vm._v("Status: "),
                        _c("span", { staticClass: "text-capitalize" }, [
                          _vm._v(_vm._s(_vm.paymentResult.invoice.status)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-2" }, [
                        _vm._v(
                          "Payment Date: " +
                            _vm._s(
                              _vm.formatDate(_vm.paymentResult.invoice.paid_at)
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-2" }, [
                        _vm._v(
                          "Payment Period: " +
                            _vm._s(
                              _vm.formatPaymentPeriod(_vm.paymentResult.invoice)
                            )
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.paymentResult.invoice?.billing_address
                ? _c("div", { staticClass: "mb-3" }, [
                    _c("b", [_vm._v("Billing Address:")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-3 mt-3" }, [
                      _c("div", { staticClass: "mb-2" }, [
                        _vm._v(
                          "Billing Admin: " +
                            _vm._s(
                              _vm.paymentResult.invoice.billing_address
                                .first_name
                            ) +
                            "\n                      " +
                            _vm._s(
                              _vm.paymentResult.invoice.billing_address
                                .last_name
                            ) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-2" }, [
                        _vm._v(
                          "Company: " +
                            _vm._s(
                              _vm.paymentResult.invoice.billing_address.company
                            )
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-center mt-4" },
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.closeModal } },
                  [_vm._v("\n            Done\n          ")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.currentStep < 2
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-between mt-4" },
            [
              _vm.currentStep > 0
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "info" },
                      on: { click: _vm.previousStep },
                    },
                    [_vm._v("\n          Back\n        ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ml-auto" },
                [
                  _vm.currentStep < 1
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "success",
                            disabled: !_vm.isStep1Valid,
                          },
                          on: { click: _vm.nextStep },
                        },
                        [_vm._v("\n              Next\n            ")]
                      )
                    : _c(
                        "el-tooltip",
                        {
                          attrs: {
                            disabled: !_vm.disablePayment,
                            content: _vm.payment_disable_message,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "success",
                                loading: _vm.loading,
                                disabled: _vm.disablePayment,
                              },
                              on: { click: _vm.confirmSubscription },
                            },
                            [
                              _vm._v(
                                "\n                  Confirm & Pay\n                "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }