var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-body", attrs: { id: "view" } }, [
    _vm.shouldShowAloAis
      ? _c("div", { staticClass: "container-listing" }, [
          _c("div", { staticClass: "container-header d-flex" }, [
            _c("h3", { staticClass: "listing-heading" }, [
              _vm._v("\n                AloAi Text Bot\n            "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ml-auto" }, [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "info", round: "", size: "medium" },
                      on: { click: _vm.gotoChatbots },
                    },
                    [
                      _c("i", { staticClass: "fa fa-arrow-left mr-1" }),
                      _vm._v(" "),
                      _c("strong", [_vm._v("Back")]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "container-fluid pb-3" },
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c("alo-ai-form-settings", {
                    attrs: { lines: _vm.lines, chatbot: _vm.chatbot },
                  }),
                  _vm._v(" "),
                  _c("alo-ai-form-instructions", {
                    attrs: { chatbot: _vm.chatbot },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: !_vm.contentIsValid,
                            type: "success",
                          },
                          on: { click: _vm.submitForm },
                        },
                        [
                          _vm._v(
                            "\n                        Save\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }