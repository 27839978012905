var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "show-close": false,
        visible: _vm.open,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "destroy-on-close": true,
        top: "10vh",
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.open = $event
        },
        open: _vm.resetDialog,
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function ({ close, titleId, titleClass }) {
            return [
              _c("div", { staticClass: "my-header" }, [
                _c("h4", { class: titleClass, attrs: { id: titleId } }, [
                  _vm._v(_vm._s(_vm.dialogTitle)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("close")
                          },
                        },
                      },
                      [_vm._v("\n                    Close\n                ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", disabled: _vm.loading },
                        on: { click: _vm.submitForm },
                      },
                      [_vm._v("\n                    Save\n                ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "custom_function_settings",
          staticClass: "custom-function",
          attrs: { rules: _vm.rules, model: _vm.tool },
        },
        [
          _c(
            "el-card",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h6", [_vm._v("Set up your custom function details")]),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(
                          "Tell your AloAi Agent what this custom function is for, when to use it, and what to say when it's running."
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("div", { staticClass: "row justify-content-center" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "name" } },
                      [
                        _c("div", { staticClass: "form-label" }, [
                          _c("h5", { staticClass: "text-dark" }, [
                            _vm._v("Function name"),
                          ]),
                          _vm._v(" "),
                          _c("small", [
                            _vm._v(
                              "\n                                What name would you like to give to this custom function?\n                            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("el-input", {
                          on: {
                            input: function ($event) {
                              return _vm.preValidateForm(
                                "custom_function_settings"
                              )
                            },
                          },
                          model: {
                            value: _vm.tool.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.tool, "name", $$v)
                            },
                            expression: "tool.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "description" } },
                      [
                        _c("div", { staticClass: "form-label" }, [
                          _c("h5", { staticClass: "text-dark" }, [
                            _vm._v("Function description"),
                          ]),
                          _vm._v(" "),
                          _c("small", [
                            _vm._v(
                              "Give a description to help the agent understand what this custom function does."
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("el-input", {
                          attrs: { type: "textarea", rows: 2 },
                          model: {
                            value: _vm.tool.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.tool, "description", $$v)
                            },
                            expression: "tool.description",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "type" } },
                      [
                        _c("div", { staticClass: "form-label" }, [
                          _c("h5", { staticClass: "text-dark" }, [
                            _vm._v("Reply to the contact during execution?"),
                          ]),
                          _vm._v(" "),
                          _c("small", [
                            _vm._v(
                              "Use this option if you want the agent to say something during the function execution. This is useful when the execution takes more than 3 seconds."
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("el-switch", {
                          model: {
                            value: _vm.tool.reply_during_execution,
                            callback: function ($$v) {
                              _vm.$set(_vm.tool, "reply_during_execution", $$v)
                            },
                            expression: "tool.reply_during_execution",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "type" } },
                      [
                        _c("div", { staticClass: "form-label" }, [
                          _c("h5", { staticClass: "text-dark" }, [
                            _vm._v("Reply to the contact after execution?"),
                          ]),
                          _vm._v(" "),
                          _c("small", [
                            _vm._v(
                              "Use this option if you want the agent to say something after the function execution."
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("el-switch", {
                          model: {
                            value: _vm.tool.reply_after_execution,
                            callback: function ($$v) {
                              _vm.$set(_vm.tool, "reply_after_execution", $$v)
                            },
                            expression: "tool.reply_after_execution",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-card",
            {
              staticClass: "mt-3",
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h6", [_vm._v("Variables")]),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(
                          "These are the inputs this agent will request the contact to provide in the middle of the conversation."
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("div", { staticClass: "row justify-content-center" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _vm.tool.variables.length > 0
                      ? _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 2 } }, [
                              _c("div", { staticClass: "form-label" }, [
                                _c(
                                  "h5",
                                  { staticClass: "text-dark invisible" },
                                  [_vm._v("Actions")]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 6 } }, [
                              _c("div", { staticClass: "form-label" }, [
                                _c("h5", { staticClass: "text-dark" }, [
                                  _vm._v("Name"),
                                ]),
                                _vm._v(" "),
                                _c("small", [
                                  _vm._v("Tell what this variable is called."),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 6 } }, [
                              _c("div", { staticClass: "form-label" }, [
                                _c("h5", { staticClass: "text-dark" }, [
                                  _vm._v("Description"),
                                ]),
                                _vm._v(" "),
                                _c("small", [
                                  _vm._v("Tell what this variable is for."),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("div", { staticClass: "form-label" }, [
                                _c("h5", { staticClass: "text-dark" }, [
                                  _vm._v("Type"),
                                ]),
                                _vm._v(" "),
                                _c("small", [_vm._v("What type is this?")]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("div", { staticClass: "form-label" }, [
                                _c("h5", { staticClass: "text-dark" }, [
                                  _vm._v("Required"),
                                ]),
                                _vm._v(" "),
                                _c("small", [
                                  _vm._v("Is required to execute?"),
                                ]),
                              ]),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.tool.variables, function (variable, index) {
                      return _c(
                        "el-row",
                        { staticClass: "mt-2", attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { staticClass: "text-center", attrs: { span: 2 } },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm danger",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.removeVariable(index)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-trash pull-left",
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "name" } },
                                [
                                  _c("el-input", {
                                    on: {
                                      blur: function ($event) {
                                        return _vm.sanitizeVariableName(
                                          index,
                                          variable.name
                                        )
                                      },
                                    },
                                    model: {
                                      value: variable.name,
                                      callback: function ($$v) {
                                        _vm.$set(variable, "name", $$v)
                                      },
                                      expression: "variable.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "description" } },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: variable.description,
                                      callback: function ($$v) {
                                        _vm.$set(variable, "description", $$v)
                                      },
                                      expression: "variable.description",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 5 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "type" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "w-100",
                                      model: {
                                        value: variable.type,
                                        callback: function ($$v) {
                                          _vm.$set(variable, "type", $$v)
                                        },
                                        expression: "variable.type",
                                      },
                                    },
                                    _vm._l(_vm.types, function (type) {
                                      return _c("el-option", {
                                        key: type,
                                        attrs: { label: type, value: type },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 5 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "required" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "w-100",
                                      model: {
                                        value: variable.required,
                                        callback: function ($$v) {
                                          _vm.$set(variable, "required", $$v)
                                        },
                                        expression: "variable.required",
                                      },
                                    },
                                    _vm._l(
                                      [
                                        { key: true, label: "Yes" },
                                        { key: false, label: "No" },
                                      ],
                                      function (required) {
                                        return _c("el-option", {
                                          key: required.key,
                                          attrs: {
                                            label: required.label,
                                            value: required.key,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "my-2",
                        attrs: {
                          type: "primary",
                          plain: "",
                          size: "mini",
                          icon: "el-icon-plus",
                        },
                        on: { click: _vm.addVariable },
                      },
                      [
                        _vm._v(
                          "\n                        Add variable\n                    "
                        ),
                      ]
                    ),
                  ],
                  2
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("generic-webhook-form", {
            ref: "generic_webhook_form",
            attrs: {
              webhook: _vm.tool.tool_parameters,
              variables: _vm.availableVariables,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }