<template>
    <div class="app-body"
         id="view"
         v-if="!loading_whitelabel && current_company && current_company.simpsocial_integration_enabled">
        <div class="padding">
            <div class="row box">
                <div class="col-12">
                    <div class="no-effect">
                        <div class="box-body">
                            <div>
                                <h2 class="pull-left">Dealer Profile</h2>
                            </div>
                            <hr class="clear-both no-top-margin">
                            <simpsocial-settings></simpsocial-settings>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import auth from '../../auth'
    import {mapState} from 'vuex'
    import {acl_mixin, scroll_mixin} from '../../mixins'
    import SimpsocialSettings from '../../components/simpsocial/simpsocial-settings'

    export default {
        mixins: [acl_mixin, scroll_mixin],

        components: {
            SimpsocialSettings,
        },

        data() {
            return {
                auth: auth,
                is_impersonated: localStorage.getItem('impersonate'),
                statics: {
                    logo: null,
                    logo_inverse: null,
                    logo_square: null,
                    logo_square_inverse: null,
                    host: null,
                    referer: null,
                    name: null,
                    domain: null,
                    whitelabel: false,
                    path: null
                },
                loading_whitelabel: true,
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),
        },

        created() {
            this.getStatics()
        },

        mounted() {
            if (!this.hasPermissionTo('update company') || !this.current_company || !this.current_company.simpsocial_integration_enabled) {
                this.goBack()
            }
        },

        methods: {
            getStatics() {
                this.loading_whitelabel = true
                axios.get('/get-statics')
                    .then(res => {
                        this.statics = res.data
                        this.setPageTitle('Dealer Profile - ' + this.statics.name)
                        this.loading_whitelabel = false
                    })
                    .catch(err => {
                        this.setPageTitle('Dealer Profile - Aloware')
                        console.log(err)
                        this.$root.handleErrors(err.response)
                        this.loading_whitelabel = false
                    })
            },
        },

        beforeRouteEnter(to, from, next) {
            auth.check()
                .then((res) => {
                    if (res.data.user.is_reseller) {
                        // redirect to account management portal if the company is a reseller
                        next({name: 'Account Management Portal'})
                    } else {
                        next()
                    }
                })
                .catch((err) => {
                    next({name: 'Login', query: {redirect: to.fullPath}})
                })
        }
    }
</script>
