var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-testid": "communcation-audio-wrapper" } },
    [
      !_vm.isDeleted && _vm.hasAudio
        ? _c(
            "el-popover",
            {
              attrs: {
                trigger: "click",
                "popper-class": "v-c",
                placement: _vm.popoverDirection,
                "open-delay": 100,
                "append-to-body": true,
                "data-testid": "communication-audio-popover",
              },
              on: { show: _vm.onShow, hide: _vm.onHide },
              model: {
                value: _vm.isOpen,
                callback: function ($$v) {
                  _vm.isOpen = $$v
                },
                expression: "isOpen",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    slot: "reference",
                    size: "small",
                    "data-testid": "communication-audio-play-button",
                    type: "text text-dark-greenish m-0 p-0",
                  },
                  slot: "reference",
                },
                [
                  _c("i", { staticClass: "fa fa-play" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-1" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.remoteUrl && _vm.isOpen
                ? [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column width-300 fixed" },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("waveform", {
                                attrs: { remote_url: _vm.remoteUrl },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row mt-2" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _vm.isMigrated
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "btn btn-block btn-primary _600",
                                    attrs: {
                                      title: "Download",
                                      target: "_blank",
                                      role: "button",
                                      download: "",
                                      "data-testid":
                                        "communication-audio-download-button",
                                      href: _vm.downloadUrl,
                                    },
                                    on: { click: _vm.blur },
                                  },
                                  [
                                    _vm._v(
                                      "\n              Download recording\n            "
                                    ),
                                  ]
                                )
                              : _c("p", { staticClass: "text-black _600" }, [
                                  _vm._v(
                                    "\n              We are processing the " +
                                      _vm._s(
                                        _vm._f("toLowerCase")(_vm.typeString)
                                      ) +
                                      ". It will be shortly available for download.\n            "
                                  ),
                                ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isDeleted && !_vm.hasAudio
        ? _c("span", { staticClass: "text-grey-900" }, [_vm._v("-")])
        : _vm._e(),
      _vm._v(" "),
      _vm.isDeleted
        ? _c("span", { staticClass: "text-grey-900" }, [
            _vm._v("record was deleted"),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }