<template>
    <el-popover width="400"
                trigger="click"
                class="p-0"
                popper-class="variable-popover"
                :placement="popoverPlacement"
                @show="popoverShow"
                @hide="popoverHide">
        <div class="row">
            <div class="col-12">
                <campaign-selector
                    check_blocked_messaging
                    is_automated
                    :show_paused="false"
                    @input="emitSelectedCampaign($event)"
                >
                </campaign-selector>
            </div>
        </div>
        <el-button id="campaigns-popover-trigger"
                   slot="reference"
                   size="mini"
                   circle
                   :class="variableBtnClass"
                   :disabled="disabled">
            <i :class="'text-primary ' + icon"></i>
        </el-button>
    </el-popover>
</template>

<script>
import CampaignSelector from "../../campaign-selector";

export default {
    components: {
        CampaignSelector
    },

    props: {
        disabled: {
            required: false,
            default: false
        },
        popoverPlacement: {
            required: false,
            default: "bottom-start"
        },
        icon: {
            required: false,
            default: "fa fa-phone"
        },
        variableBtnClass: {
            required: false,
            default: "border-0 bg-transparent"
        },
    },

    data() {
        return {
            select_campaign: null,	            
        }
    },

    methods: {
        popoverShow() {
            if (this.$refs[('campaigns_dropdown')]) {
                this.$refs[('campaigns_dropdown')].focusToSelector()
            }
        },

        popoverHide() {
            this.select_campaign = null
        },

        emitSelectedCampaign(id) {
            this.$emit('campaign-selected', id)
        }
    },
}
</script>
