var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.team
    ? _c(
        "div",
        { staticClass: "app-body", attrs: { id: "view" } },
        [
          _c(
            "div",
            {
              staticClass: "fixed-header padding pt-0 pb-0",
              style: { top: _vm.top_offset + 50 + "px" },
            },
            [
              _c("div", { staticClass: "row mb-0 mt-0" }, [
                _c("div", { staticClass: "col-12 p-2" }, [
                  _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-fill" },
                      [
                        _c("comm-advanced-filters-wrapper", {
                          attrs: {
                            "data-testid": "activity-comm-advance-filters",
                            team_id: _vm.team_id,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.section === "Activities",
                  expression: "section === 'Activities'",
                },
              ],
            },
            [
              _c("div", { staticClass: "padding" }, [
                _vm.hasPermissionTo("list report")
                  ? _c("div", { staticClass: "padding" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 p-0 mt-5" },
                          [
                            _c("new-summary-report", {
                              attrs: {
                                "data-testid": "activity-new-summary-report",
                                team_id: _vm.team_id,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasPermissionTo("list communication")
                  ? _c("div", { staticClass: "padding" }, [
                      _c("div", { staticClass: "row box" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "no-effect" }, [
                            _c(
                              "div",
                              { staticClass: "box-body" },
                              [
                                _c("comm-log-table", {
                                  attrs: {
                                    "data-testid": "activity-comm-log-table",
                                    team_id: _vm.team_id,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("el-backtop", {
            ref: "backtop",
            attrs: {
              target: "html",
              "data-testid": "activity-backtop",
              bottom: 20,
              right: 20,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }