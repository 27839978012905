var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "px-3 pb-3" }, [
    _c(
      "div",
      {
        staticClass: "px-3 pt-3 pb-0",
        attrs: { slot: "title" },
        slot: "title",
      },
      [
        _c("h5", { staticClass: "mb-0" }, [
          _vm._v("\n            Credit Usage Charges\n        "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "_300 mb-0" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.date_range_legend) +
              "        \n        "
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm.visibleResources.length > 0
      ? _c("div", { staticClass: "padding" }, [
          _c("div", { staticClass: "row box" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "no-effect" }, [
                _c("div", { staticClass: "box-header pb-0" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("el-input", {
                          ref: "resourceSearch",
                          attrs: {
                            placeholder: "Search a resource",
                            "prefix-icon": "el-icon-search",
                            clearable: "",
                            autofocus: "",
                          },
                          model: {
                            value: _vm.search_text,
                            callback: function ($$v) {
                              _vm.search_text = $$v
                            },
                            expression: "search_text",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box-body pt-0" },
                  [
                    _c(
                      "el-table",
                      {
                        staticClass: "w-full",
                        attrs: {
                          data: _vm.visibleResources,
                          "default-sort": { prop: "name", order: "ascending" },
                          fit: "",
                          stripe: "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "name",
                            label: "Resource",
                            "min-width": 200,
                            sortable: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("i", {
                                      staticClass: "fa fa-circle mr-2",
                                      style: "color: " + scope.row.color,
                                    }),
                                    _vm._v(
                                      _vm._s(scope.row.name) +
                                        "\n                                "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2778766483
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "total_usage",
                            label: "Total Usage",
                            "min-width": 100,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm._f("numFormat")(
                                            scope.row.total_quantity
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            223271127
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "free_usage",
                            label: "Free Usage",
                            "min-width": 100,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c("span", [_vm._v("Free Usage")]),
                                    _vm._v(" "),
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "top",
                                          width: "auto",
                                          trigger: "hover",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        Included in your plan\n                                        "
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "ml-1 fas fa-info-circle",
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        }),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm._f("numFormat")(
                                            scope.row.total_included
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1945083859
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "paid_usage",
                            label: "Paid Usage",
                            "min-width": 100,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c("span", [_vm._v("Paid Usage")]),
                                    _vm._v(" "),
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "top",
                                          width: "auto",
                                          trigger: "hover",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        Overage above your plan\n                                        "
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "ml-1 fas fa-info-circle",
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        }),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm._f("numFormat")(
                                            scope.row.total_paid
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1283606834
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "rate",
                            label: "Rate",
                            "min-width": 100,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c("span", [_vm._v("Rate")]),
                                    _vm._v(" "),
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "top",
                                          width: "auto",
                                          trigger: "hover",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        Cost per unit of paid usage\n                                        "
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "ml-1 fas fa-info-circle",
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        }),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.getFormatedAmount(scope.row.rate)
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            936228885
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "cost",
                            label: "Cost",
                            "min-width": 100,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c("span", [_vm._v("Cost")]),
                                    _vm._v(" "),
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "top",
                                          width: "auto",
                                          trigger: "hover",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        Cost for paid usage\n                                        "
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "ml-1 fas fa-info-circle",
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        }),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.getFormatedAmount(
                                            scope.row.total_credit
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4180663389
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body d-flex py-3 px-3" }, [
              _c("h4", { staticClass: "mb-0" }, [_vm._v("Total")]),
              _vm._v(" "),
              _c("h4", { staticClass: "mb-0 ml-auto text-dark" }, [
                _vm._v(
                  _vm._s(
                    _vm.getFormatedAmount(_vm.summarizeTotalOfVisibleResources)
                  )
                ),
              ]),
            ]),
          ]),
        ])
      : _c("div", { staticClass: "w-100" }, [
          _c("h3", { staticClass: "text-center" }, [_vm._v("No results.")]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }