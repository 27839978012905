<template>
    <base-tool
        :tool-name="tool.name"
        :tool-description="tool.description"
        @back="goBack"
    >
        <div class="appointment-tool-container">
            <p class="mb-2 text-dark">Assign the appointments to this user</p>
            <!-- User Selector -->
            <user-selector
                v-model="selectedUser"
                :multiple="false"
                :hide_extensions="true"
                @change="handleUserChange"
            />
        </div>
    </base-tool>
</template>

<script>
import BaseTool from './base-tool.vue'
import * as AloAi from '../../../../constants/aloai'
import ToolMixin from './tool-mixin'
import UserSelector from '../../../user-selector'
import { mapState } from 'vuex'
export default {
    name: 'AppointmentTool',

    mixins: [ToolMixin],

    components: {
        BaseTool,
        UserSelector
    },

    props: {
        tool: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            selectedUser: null
        }
    },

    computed: {
        ...mapState(['users'])
    },

    methods: {
        handleUserChange(user_id) {
            if (!user_id) {
                return
            }

            // Get user from store
            const user = this.users.find(u => u.id === user_id)

            // Format: {Create appointment with: Name|UserId}
            const appointmentText = `{Create appointment with: ${user.name}|${user.id}}`

            // Emit the appointment text as an object with a text property
            this.$emit('appointment-selected', { text: appointmentText })

            // Emit that the tool was used
            this.emitToolUsed(AloAi.TOOL_CREATE_APPOINTMENT)
        }
    }
}
</script>
