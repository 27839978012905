import Vuex from 'vuex'
import Vue from 'vue'
import _ from 'lodash'
import VuexPersistence from 'vuex-persist'
import createMutationsSharer from "vuex-shared-mutations"
import * as DncFilterOptions from "./constants/filter-dnc-options";
import accountRegistration from './stores/modules/account-registration'
import pipedriveAppExtensions from './stores/modules/pipedrive-app-extensions-store'
import hubspotPropertyMapping from './stores/modules/hubspot-property-mapping-store'

const vuexLocal = new VuexPersistence({
    key: 'aloware_vuex_local',
    storage: window.localStorage,
    modules: ['cache']
})

Vue.use(Vuex)
window.timezone = 'Intl' in window ? new Intl.DateTimeFormat().resolvedOptions().timeZone : 'America/Los_Angeles'

function resourceExists(arr, resource) {
    return !!arr.find(item => item.id === resource.id)
}

const default_state = {
    app_loading: false,
    user_logged_out: false,
    fishing_mode_notification_audio: new Audio('/static/ivr/incoming.mp3'),
    csat_calls_panel: {
        loading: false,
        enabled: false,
        score: 0,
        params: {},
        calls: []
    },
    filter: {
        from_date: null,
        date_field: 'last_engagement_at',
        to_date: null,
        type: 'all',
        transfer_type: null,
        direction: 'all',
        report_type: 'date_v_campaign',
        chart_period: 'day',
        answer_status: 'all',
        export_type: 'json',
        min_talk_time: 0,
        campaign_id: null,
        ring_group_id: null,
        user_id: null,
        team_id: null,
        workflow_id: null,
        page: 1,
        per_page: 20,
        checked_table_fields: null,
        first_time_only: 0,
        untagged_only: 0,
        exclude_automated_communications: 0,
        creator_type: null,
        has_untagged_call: 0,
        not_disposed: 0,
        is_blocked: 0,
        dnc_option: DncFilterOptions.ALL_CONTACTS,
        has_unread: 0,
        is_new_lead: 0,
        has_scheduled_messages: 0,
        no_scheduled_messages: 0,
        enrolled_in_sequence: 0,
        not_enrolled_in_sequence: 0,
        callback_status: null,
        unassigned_leads: 0,
        should_follow_the_sun: 0,
        not_contacted: 0,
        not_contacted_hours: null,
        not_responded: 0,
        not_responded_hours: null,
        responded: 0,
        responded_hours: null,
        text_authorized: 0,
        lrn_types: [],
        has_appointments: 0,
        has_reminders: 0,
        event_range: null,
        intake_source: [],
        lead_source: [],
        tags: [],
        doesnt_have_tags: [],
        disposition_statuses: [],
        no_disposition_statuses: [],
        call_dispositions: [],
        campaigns: [],
        workflows: [],
        broadcasts: [],
        ring_groups: [],
        contact_lists: [],
        incoming_numbers: [],
        users: [],
        teams:[],
        owners: [],
        timezone: window.timezone,
        contact_country: '',
        contact_timezone: '',
        changed: false,
        states_limit: {
            us: [],
            ca: []
        },
        initial_line_only: 0,
        search_text: '',
        search_fields: [],
        comm_sort_by: null,
        outbound_call_count_start: null,
        outbound_call_count_end: null,
        transcription_keywords: [],
        transcription_category: null,
        has_international: 0,
        agent_sentiment: null,
        contact_sentiment: null
    },
    section: 'Activities',
    transcription_reporting_data: [],
    transcription_history: [],
    transcription_page: 1,
    transcription_report_loading: false,
    users: [],
    campaigns: [],
    disposition_statuses: [],
    disposition_statuses_loaded: false,
    call_dispositions: [],
    call_dispositions_loaded: false,
    companies: [],
    usage: [],
    exports: {
        data: [],
        scope: 'user'
    },
    filters: [],
    workflows: [],
    broadcasts: [],
    ring_groups: [],
    contact_lists: [],
    scripts: [],
    settings: null,
    first_login: false,
    user_status: false,
    on_call: false,
    compliance_wizard_dialog_visible: false,
    easy_wizard: false,
    unregistered_lines: [],
    tag_options: {
        is_reset: false
    },
    filter_options: {
        filters_only: false,
        force_size: 'normal',
        is_bulk: false,
        is_changed: false,
        is_filter_visible: false,
        is_reset: false,
        is_reset_from_filter: false,
        no_export: false,
        no_save_as_tag: false
    },
    communications_count: null,
    countries: [],
    lead_sources: [],
    attribute_dictionaries: [],
    top_offset: 0,
    sms_templates: [],
    timezones: [],
    addons: [],
    shouldOpenComplianceWizard: 0,
    companyTokenVisibility: false,
    current_timezone: null
}

const default_cached_state = {
    sidebar_folded: false,
    skip_outside_day_times: true,
    show_graph: false,
    current_company: null,
    comm_table_fields: ['disposition_status', 'incoming_number', 'created_at', 'user', 'talk_time', 'lead_number', 'destination_number', 'tags', 'notes', 'operations'],
    queue_table_fields: ['disposition_status', 'incoming_number', 'created_at', 'ring_group', 'lead_number', 'lead_location', 'attempting_users', 'tags', 'operations'],
    live_table_fields: ['disposition_status', 'incoming_number', 'created_at', 'talk_time', 'ring_group', 'user', 'lead_number', 'lead_location', 'tags', 'operations'],
    report_table_fields: [],
}

const cachedStatesModule = {
    namespaced: true,

    state: {
        sidebar_folded: false,
        skip_outside_day_times: true,
        show_graph: false,
        current_company: null,
        comm_table_fields: ['disposition_status', 'incoming_number', 'created_at', 'user', 'talk_time', 'lead_number', 'destination_number', 'tags', 'notes', 'operations'],
        queue_table_fields: ['disposition_status', 'incoming_number', 'created_at', 'ring_group', 'lead_number', 'lead_location', 'attempting_users', 'tags', 'operations'],
        live_table_fields: ['disposition_status', 'incoming_number', 'created_at', 'talk_time', 'ring_group', 'user', 'lead_number', 'lead_location', 'tags', 'operations'],
        report_table_fields: [],
        timezones: []
    },

    getters: {
        isSimpSocial(state) {
            return state.current_company && state.current_company.reseller_id === 357
        },

        isModGen(state) {
            return state.current_company && state.current_company.reseller_id === 2132
        },

        getCurrentCompany(state) {
            return state.current_company
        },

        getTimezones(state) {
            return state.timezones
        },

        isTrial (state) {
            return state.current_company && state.current_company?.is_trial
        }
    },

    actions: {
        setShowGraph({commit}, status) {
            commit('SET_SHOW_GRAPH', status)
        },

        setSkipOutsideDayTimes({commit}, status) {
            commit('SET_SKIP_OUTSIDE_DAY_TIMES', status)
        },

        setSidebarFolded({commit}, status) {
            commit('SET_SIDEBAR_FOLDED', status)
        },

        setCommTableFields({commit}, fields) {
            commit('SET_COMM_TABLE_FIELDS', fields)
        },

        setQueueTableFields({commit}, fields) {
            commit('SET_QUEUE_TABLE_FIELDS', fields)
        },

        setLiveTableFields({commit}, fields) {
            commit('SET_LIVE_TABLE_FIELDS', fields)
        },

        setReportTableFields({commit}, fields) {
            commit('SET_REPORT_TABLE_FIELDS', fields)
        },

        setCurrentCompany({commit}, current_company) {
            commit('SET_CURRENT_COMPANY', current_company)
        },

        setCurrentCompanyDefaultFilterId({commit}, filter_id) {
            commit('SET_CURRENT_COMPANY_DEFAULT_FILTER_ID', filter_id)
        },

        deleteCurrentCompany({commit}) {
            commit('DELETE_CURRENT_COMPANY')
        },

        resetCache({commit}) {
            commit('RESET_CACHE')
        },

        setUrlShortenerEnabled({commit}, value) {
            commit('SET_URL_SHORTENER_ENABLED', value)
        },

        setTimezones({commit}, value) {
            commit('SET_TIMEZONES', value);
        },

    },

    mutations: {
        SET_SHOW_GRAPH(state, status) {
            state.show_graph = status
        },

        SET_SKIP_OUTSIDE_DAY_TIMES(state, status) {
            state.skip_outside_day_times = status
        },

        SET_SIDEBAR_FOLDED(state, status) {
            state.sidebar_folded = status
        },

        SET_COMM_TABLE_FIELDS(state, fields) {
            state.comm_table_fields = fields
        },

        SET_QUEUE_TABLE_FIELDS(state, fields) {
            state.queue_table_fields = fields
        },

        SET_LIVE_TABLE_FIELDS(state, fields) {
            state.queue_table_fields = fields
        },

        SET_REPORT_TABLE_FIELDS(state, fields) {
            state.report_table_fields = fields
        },

        SET_CURRENT_COMPANY_DEFAULT_FILTER_ID(state, filter_id) {
            state.current_company.default_filter_id = filter_id
        },

        DELETE_CURRENT_COMPANY(state) {
            state.current_company = null
        },

        SET_CURRENT_COMPANY(state, current_company) {
            state.current_company = current_company
        },

        RESET_CACHE(state) {
            state = Object.assign(state, default_cached_state)
        },

        SET_URL_SHORTENER_ENABLED(state, value) {
            state.current_company.url_shortener_enabled = value
        },

        SET_TIMEZONES(state, list) {
            state.timezones = list
        },

    }
}

export default new Vuex.Store({
    modules: {
        cache: cachedStatesModule,
        pipedriveAppExtensions,
        hubspotPropertyMapping,
        accountRegistration,
    },

    state: {
        app_loading: false,
        user_logged_out: false,
        fishing_mode_notification_audio: new Audio('/static/ivr/incoming.mp3'),
        csat_calls_panel: {
            loading: false,
            enabled: false,
            score: 0,
            params: {},
            calls: []
        },
        filter: {
            from_date: null,
            date_field: 'last_engagement_at',
            to_date: null,
            type: 'all',
            transfer_type: null,
            direction: 'all',
            report_type: 'date_v_campaign',
            chart_period: 'day',
            answer_status: 'all',
            export_type: 'json',
            min_talk_time: 0,
            campaign_id: null,
            ring_group_id: null,
            user_id: null,
            team_id: null,
            workflow_id: null,
            page: 1,
            per_page: 20,
            checked_table_fields: null,
            first_time_only: 0,
            untagged_only: 0,
            exclude_automated_communications: 0,
            creator_type: null,
            has_untagged_call: 0,
            not_disposed: 0,
            is_blocked: 0,
            dnc_option: DncFilterOptions.ALL_CONTACTS,
            has_unread: 0,
            is_new_lead: 0,
            unassigned_leads: 0,
            should_follow_the_sun: 0,
            not_contacted: 0,
            not_contacted_hours: null,
            not_responded: 0,
            not_responded_hours: null,
            responded: 0,
            has_scheduled_messages: 0,
            no_scheduled_messages: 0,
            enrolled_in_sequence: 0,
            not_enrolled_in_sequence: 0,
            responded_hours: null,
            text_authorized: 0,
            lrn_types: [],
            has_appointments: 0,
            has_reminders: 0,
            event_range: null,
            callback_status: null,
            intake_source: [],
            lead_source: [],
            tags: [],
            doesnt_have_tags: [],
            disposition_statuses: [],
            no_disposition_statuses: [],
            call_dispositions: [],
            campaigns: [],
            workflows: [],
            broadcasts: [],
            ring_groups: [],
            contact_lists: [],
            incoming_numbers: [],
            users: [],
            teams:[],
            owners: [],
            timezone: window.timezone,
            contact_country: '',
            contact_timezone: '',
            changed: false,
            states_limit: {
                us: [],
                ca: []
            },
            initial_line_only: 0,
            search_text: '',
            search_fields: [],
            comm_sort_by: null,
            outbound_call_count_start: null,
            outbound_call_count_end: null,
            transcription_keywords: [],
            transcription_category: null,
            has_international: 0,
            agent_sentiment: null,
            contact_sentiment: null
        },
        section: 'Activities',
        transcription_reporting_data: [],
        transcription_history: [],
        transcription_page: 1,
        transcription_report_loading: false,
        users: [],
        campaigns: [],
        disposition_statuses: [],
        disposition_statuses_loaded: false,
        call_dispositions: [],
        activity_types: [],
        call_dispositions_loaded: false,
        activity_types_loaded: false,
        companies: [],
        usage: [],
        exports: {
            data: [],
            scope: 'user'
        },
        filters: [],
        workflows: [],
        broadcasts: [],
        ring_groups: [],
        contact_lists: [],
        ivrs: [],
        ivr_prompt_files: [],
        scripts: [],
        settings: null,
        first_login: false,
        user_status: false,
        on_call: false,
        compliance_wizard_dialog_visible: false,
        easy_wizard: false,
        unregistered_lines: [],
        tag_options: {
            is_reset: false
        },
        filter_options: {
            filters_only: false,
            force_size: 'normal',
            is_bulk: false,
            is_changed: false,
            is_filter_visible: false,
            is_reset: false,
            is_reset_from_filter: false,
            no_export: false,
            no_save_as_tag: false
        },
        communications_count: null,
        countries: [],
        lead_sources: [],
        attribute_dictionaries: [],
        top_offset: 0,
        sms_templates: [],
        addons: [],
        shouldShowVideoModal: false,
        showedKycDialog: false,
        showedKycReloadDialog: false,
        isIntroVideoVisible: false,
        shouldOpenComplianceWizard: 0,
        companyTokenVisibility: false,
        current_selected_team: null,
        all_teams: [],
        should_reload_all_teams: false,
        current_timezone: null,
        teams: [],
        is_updating_current_company: false
    },

    getters: {
        isAppLoading: state => {
            return state.app_loading
        },

        userLoggedOut: state => {
            return state.user_logged_out
        },

        getFilter: state => {
            return state.filter
        },

        getDefaultStateFilter: () => {
            return default_state.filter
        },

        getCompanies: state => {
            return state.companies
        },

        getCommunicationsCount: state => {
            return state.communications_count
        },

        getCountries: state => {
            return state.countries
        },

        getLeadSources: state => {
            return state.lead_sources
        },

        getAttributeDictionaries: state => {
            return state.attribute_dictionaries
        },

        getSmsTemplates: state => {
            return state.sms_templates
        },

        getSection: (state) => {
            return state.section;
        },

        getTranscriptionPage: (state) => {
            return state.transcription_page;
        },

        getAddons: state => {
            return state.addons
        },

        currentSelectedTeamName: state => {
            return state.current_selected_team?.name
        }
    },

    actions: {
        setAppLoading({commit}, loading) {
            commit('SET_APP_LOADING', loading)
        },

        logoutUser({commit}) {
            commit('LOG_OUT_USER')
        },

        setOnCall({commit}, status) {
            commit('SET_ON_CALL', status)
        },

        setComplianceWizardDialogVisible({commit}, status) {
            commit('SET_COMPLIANCE_WIZARD_DIALOG_VISIBILITY', status)
        },

        setEasyComplianceWizard({commit}, value) {
            commit('SET_EASY_COMPLIANCE_WIZARD', value)
        },

        setUnregisteredLines({commit}, unregistered_lines) {
            commit('SET_UNREGISTERED_LINES', unregistered_lines)
        },

        setUserStatus({commit}, status) {
            commit('SET_USER_STATUS', status)
        },

        newCampaign({commit}, campaign) {
            commit('NEW_CAMPAIGN', campaign)
        },

        updateCampaign({commit}, campaign) {
            commit('UPDATE_CAMPAIGN', campaign)
        },

        deleteCampaign({commit}, campaign) {
            commit('DELETE_CAMPAIGN', campaign)
        },

        newFilter({commit}, filter) {
            commit('NEW_FILTER', filter)
        },

        setFilters({commit}, filters) {
            commit('SET_FILTERS', filters)
        },

        setFilter({commit}, filter) {
            commit('SET_FILTER', filter)
        },

        updateFilter({commit}, filter) {
            commit('UPDATE_FILTER', filter)
        },

        deleteFilter({commit}, filter) {
            commit('DELETE_FILTER', filter)
        },

        setCampaigns({commit}, campaigns) {
            commit('SET_CAMPAIGNS', campaigns)
        },

        newDispositionStatus({commit}, disposition_status) {
            commit('NEW_DISPOSITION_STATUS', disposition_status)
        },

        updateDispositionStatus({commit}, disposition_status) {
            commit('UPDATE_DISPOSITION_STATUS', disposition_status)
        },

        deleteDispositionStatus({commit}, disposition_status) {
            commit('DELETE_DISPOSITION_STATUS', disposition_status)
        },

        setDispositionStatuses({commit}, disposition_statuses) {
            commit('SET_DISPOSITION_STATUSES', disposition_statuses)
        },

        setDispositionStatusesLoaded({commit}, disposition_statuses_loaded) {
            commit('SET_DISPOSITION_STATUSES_LOADED', disposition_statuses_loaded)
        },

        newBulkCallDisposition({commit}, call_dispositions) {
            commit('NEW_BULK_CALL_DISPOSITION', call_dispositions)
        },

        newCallDisposition({commit}, call_disposition) {
            commit('NEW_CALL_DISPOSITION', call_disposition)
        },

        updateCallDisposition({commit}, call_disposition) {
            commit('UPDATE_CALL_DISPOSITION', call_disposition)
        },

        deleteCallDisposition({commit}, call_disposition) {
            commit('DELETE_CALL_DISPOSITION', call_disposition)
        },

        setCallDispositions({commit}, call_dispositions) {
            commit('SET_CALL_DISPOSITIONS', call_dispositions)
        },

        setCallDispositionsLoaded({commit}, call_dispositions_loaded) {
            commit('SET_CALL_DISPOSITIONS_LOADED', call_dispositions_loaded)
        },

        setActivityTypes({commit}, activity_types) {
            commit('SET_ACTIVITY_TYPES', activity_types)
        },

        setActivityTypesLoaded({commit}, activity_types_loaded) {
            commit('SET_ACTIVITY_TYPES_LOADED', activity_types_loaded)
        },

        newRingGroup({commit}, ring_group) {
            commit('NEW_RING_GROUP', ring_group)
        },

        updateRingGroup({commit}, ring_group) {
            commit('UPDATE_RING_GROUP', ring_group)
        },

        deleteRingGroup({commit}, ring_group) {
            commit('DELETE_RING_GROUP', ring_group)
        },

        setRingGroups({commit}, ring_groups) {
            commit('SET_RING_GROUPS', ring_groups)
        },

        setContactLists({ commit }, contact_lists) {
          commit('SET_CONTACT_LISTS', contact_lists)
        },

        newIvr({commit}, ivr) {
            commit('NEW_IVR', ivr)
        },

        updateIvr({commit}, ivr) {
            commit('UPDATE_IVR', ivr)
        },

        deleteIvr({commit}, ivr) {
            commit('DELETE_IVR', ivr)
        },

        setIvrs({commit}, ivrs) {
            commit('SET_IVRS', ivrs)
        },

        newIvrPromptFile({commit}, ivr_prompt_file) {
            commit('NEW_IVR_PROMPT_FILE', ivr_prompt_file)
        },

        updateIvrPromptFile({commit}, ivr_prompt_file) {
            commit('UPDATE_IVR_PROMPT_FILE', ivr_prompt_file)
        },

        deleteIvrPromptFile({commit}, ivr_prompt_file) {
            commit('DELETE_IVR_PROMPT_FILE', ivr_prompt_file)
        },

        setIvrPromptFiles({commit}, ivr_prompt_files) {
            commit('SET_IVR_PROMPT_FILES', ivr_prompt_files)
        },

        newScript({commit}, script) {
            commit('NEW_SCRIPT', script)
        },

        updateScript({commit}, script) {
            commit('UPDATE_SCRIPT', script)
        },

        deleteScript({commit}, script) {
            commit('DELETE_SCRIPT', script)
        },

        setScripts({commit}, scripts) {
            commit('SET_SCRIPTS', scripts)
        },

        newCompany({commit}, company) {
            commit('NEW_COMPANY', company)
        },

        setCompanies({commit}, companies) {
            commit('SET_COMPANIES', companies)
        },

        resetVuex({commit}) {
            commit('RESET_VUEX')
        },

        resetFilters({commit}) {
            commit('RESET_FILTERS')
        },

        resetContactFilters({commit}) {
            commit('RESET_CONTACT_FILTERS')
        },

        setUsage({commit}, usage) {
            commit('SET_USAGE', usage)
        },

        setSettings({commit}, settings) {
            commit('SET_SETTINGS', settings)
        },

        setTopOffset({commit}, top_offset) {
            commit('SET_TOP_OFFSET', top_offset)
        },

        setFirstLogin({commit}, first_login) {
            commit('SET_FIRST_LOGIN', first_login)
        },

        newBroadcast({commit}, broadcast) {
            commit('NEW_BROADCAST', broadcast)
        },

        setBroadcast({commit}, broadcasts) {
            commit('SET_BROADCASTS', broadcasts)
        },

        updateBroadcast({commit}, broadcast) {
            commit('UPDATE_BROADCAST', broadcast)
        },

        deleteBroadcast({commit}, broadcast) {
            commit('DELETE_BROADCAST', broadcast)
        },

        newWorkflow({commit}, workflow) {
            commit('NEW_WORKFLOW', workflow)
        },

        setWorkflows({commit}, workflows) {
            commit('SET_WORKFLOWS', workflows)
        },

        updateWorkflow({commit}, workflow) {
            commit('UPDATE_WORKFLOW', workflow)
        },

        deleteWorkflow({commit}, workflow) {
            commit('DELETE_WORKFLOW', workflow)
        },

        setExports({commit}, exports) {
            commit('SET_EXPORTS', exports)
        },

        newExport({commit}, exports) {
            commit('NEW_EXPORT', exports)
        },

        updateExport({commit}, exports) {
            commit('UPDATE_EXPORT', exports)
        },

        deleteExport({commit}, exports) {
            commit('DELETE_EXPORT', exports)
        },

        newUser({commit}, user) {
            commit('NEW_USER', user)
        },

        setUsers({commit}, users) {
            commit('SET_USERS', users)
        },

        updateUser({commit}, user) {
            commit('UPDATE_USER', user)
        },

        deleteUser({commit}, user) {
            commit('DELETE_USER', user)
        },

        setCommunicationsCount({commit}, communications_count) {
            commit('SET_COMMUNICATIONS_COUNT', communications_count)
        },

        setCountries({commit}, countries) {
            commit('SET_COUNTRIES', countries)
        },

        setLeadSources({commit}, lead_sources) {
            commit('SET_LEAD_SOURCES', lead_sources)
        },

        addLeadSource({commit}, lead_source) {
            commit('ADD_LEAD_SOURCE', lead_source)
        },

        updateLeadSource({commit}, lead_source) {
            commit('UPDATE_LEAD_SOURCE', lead_source)
        },

        deleteLeadSource({commit}, lead_source_id) {
            commit('DELETE_LEAD_SOURCE', lead_source_id)
        },

        setAttributeDictionaries({commit}, attribute_dictionaries) {
            commit('SET_ATTRIBUTE_DICTIONARIES', attribute_dictionaries)
        },

        newSmsTemplate({commit}, sms_template) {
            commit('NEW_SMS_TEMPLATE', sms_template)
        },

        setSmsTemplates({commit}, sms_templates) {
            commit('SET_SMS_TEMPLATES', sms_templates)
        },

        updateSmsTemplate({commit}, sms_template) {
            commit('UPDATE_SMS_TEMPLATE', sms_template)
        },

        deleteSmsTemplate({commit}, sms_template_id) {
            commit('DELETE_SMS_TEMPLATE', sms_template_id)
        },

        setSection({commit}, section) {
            commit("SET_SECTION", section);
        },

        setTranscriptionPage({commit}, transcription_page) {
            commit("SET_TRANSCRIPTION_PAGE", transcription_page);
        },

        setAddons({commit}, addons) {
            commit('SET_ADDONS', addons)
        },

        stopNotificationAudio({commit}) {
            commit('STOP_NOTIFICATION_AUDIO')
        },

        showCsatCallsPanel({commit}) {
            commit('SHOW_CSAT_CALLS_PANEL')
        },

        setCsatCallsLoading({commit}, data) {
            commit('SET_CSAT_CALLS_LOADING', data)
        },

        hideCsatCallsPanel({commit}) {
            commit('HIDE_CSAT_CALLS_PANEL')
        },

        setCsatCallsPanelScore({commit}, data) {
            commit('SET_CSAT_CALLS_PANEL_SCORE', data)
        },

        setCsatCallsPanelFilterParams({commit}, data) {
            commit('SET_CSAT_CALLS_PANEL_FILTER_PARAMS', data)
        },

        setCsatCallsPanelCalls({commit, state}, url = null) {
            commit('SET_CSAT_CALLS_LOADING', true)
            axios.get(url ? url : `/api/v1/reports/csat-calls`, {
                params: state.csat_calls_panel.params
            }).then(res => {
                commit('SET_CSAT_CALLS_PANEL_CALLS', res.data)
                commit('SET_CSAT_CALLS_LOADING', false)
            }).catch(err => {
                this.handleErrors(err.response)
            })
        },

        setShouldShowVideoModal({commit}, value) {
            commit('SET_SHOULD_SHOW_VIDEO_MODAL', value)
        },

        setShowedKycDialog ({ commit }, value) {
            commit('SET_SHOWED_KYC_DIALOG', value)
        },

        setShowedKycReloadDialog ({ commit }, value) {
            commit('SET_SHOWED_KYC_RELOAD_DIALOG', value)
        },

        setIsIntroVideoVisible ({ commit }, value) {
            commit('SET_IS_INTRO_VIDEO_VISIBLE', value)
        },

        setShouldOpenComplianceWizard({ commit }, value) {
            commit('SET_SHOULD_OPEN_COMPLIANCE_WIZARD', value)
        },

        toggleCompanyTokenVisibility({ commit }) {
            commit('TOGGLE_COMPANY_TOKEN_VISIBILITY')
        },

        setCurrentTeam({ commit }, team) {
            commit('SET_CURRENT_SELECTED_TEAM', team)
        },

        setAllTeams({ commit }, teams) {
            commit('SET_ALL_TEAMS', teams)
        },

        setShouldReloadAllTeams({ commit }, value) {
            commit('SET_SHOULD_RELOAD_ALL_TEAMS', value)
        },

        setCurrentTimezone({ commit }, timezone) {
            commit('SET_CURRENT_TIMEZONE', timezone)
        },

        setTeams({ commit }, teams) {
            commit('SET_TEAMS', teams)
        },

        setIsUpdatingCurrentCompany({ commit }, value) {
            commit('SET_IS_UPDATING_CURRENT_COMPANY', value)
        },
    },

    mutations: {
        SET_APP_LOADING(state, loading) {
            state.app_loading = loading
        },

        LOG_OUT_USER(state) {
            state.user_logged_out = true
        },

        SET_ON_CALL(state, status) {
            state.on_call = status
        },

        SET_COMPLIANCE_WIZARD_DIALOG_VISIBILITY(state, visible) {
            state.compliance_wizard_dialog_visible = visible
        },

        SET_EASY_COMPLIANCE_WIZARD(state, value) {
            state.easy_wizard = value
        },

        SET_UNREGISTERED_LINES(state, unregistered_lines) {
            state.unregistered_lines = unregistered_lines
        },

        SET_USER_STATUS(state, status) {
            state.user_status = status
        },

        NEW_CAMPAIGN(state, campaign) {
            if (resourceExists(state.campaigns, campaign)) {
                return
            }
            state.campaigns.push(campaign)
        },

        UPDATE_CAMPAIGN(state, campaign) {
            let found = state.campaigns.find(cmp => cmp.id === campaign.id)
            if (found) {
                let updated_campaign = _.extend(found, campaign)
                Vue.set(state.campaigns, state.campaigns.indexOf(found), updated_campaign)
            }
        },

        DELETE_CAMPAIGN(state, campaign) {
            let found = state.campaigns.find(cmp => cmp.id === campaign.id)
            if (found) {
                state.campaigns.splice(state.campaigns.indexOf(found), 1)
            }
        },

        SET_CAMPAIGNS(state, campaigns) {
            state.campaigns = campaigns
        },

        NEW_FILTER(state, filter) {
            if (resourceExists(state.filters, filter)) {
                return
            }
            state.filters.push(filter)
        },

        SET_FILTERS(state, filters) {
            state.filters = filters
        },

        SET_FILTER(state, filter) {
            filter.page = 1
            state.filter = _.extend(state.filter, filter, {
                timezone: window.timezone
            })
        },

        UPDATE_FILTER(state, filter) {
            let found = state.filters.find(o => o.id === filter.id)
            if (found) {
                let updated_filter = _.extend(found, filter)
                Vue.set(state.filters, state.filters.indexOf(found), updated_filter)
            }
        },

        DELETE_FILTER(state, filter) {
            let found = state.filters.find(o => o.id === filter.id)
            if (found) {
                state.filters.splice(state.filters.indexOf(found), 1)
            }
        },

        NEW_DISPOSITION_STATUS(state, disposition_status) {
            if (resourceExists(state.disposition_statuses, disposition_status)) {
                return
            }
            state.disposition_statuses.push(disposition_status)
        },

        UPDATE_DISPOSITION_STATUS(state, disposition_status) {
            let found = state.disposition_statuses.find(o => o.id === disposition_status.id)
            if (found) {
                let updated_disposition_status = _.extend(found, disposition_status)
                Vue.set(state.disposition_statuses, state.disposition_statuses.indexOf(found), updated_disposition_status)
            }
        },

        DELETE_DISPOSITION_STATUS(state, disposition_status) {
            let found = state.disposition_statuses.find(o => o.id === disposition_status.id)
            if (found) {
                state.disposition_statuses.splice(state.disposition_statuses.indexOf(found), 1)
            }
        },

        SET_DISPOSITION_STATUSES(state, disposition_statuses) {
            state.disposition_statuses = disposition_statuses
        },

        SET_DISPOSITION_STATUSES_LOADED(state, disposition_statuses_loaded) {
            state.disposition_statuses_loaded = disposition_statuses_loaded
        },

        NEW_BULK_CALL_DISPOSITION(state, call_dispositions) {
            if (call_dispositions.length === 0) {
                return
            }
            state.call_dispositions = _.union(state.call_dispositions, call_dispositions)
        },

        NEW_CALL_DISPOSITION(state, call_disposition) {
            if (resourceExists(state.call_dispositions, call_disposition)) {
                return
            }
            state.call_dispositions.push(call_disposition)
        },

        UPDATE_CALL_DISPOSITION(state, call_disposition) {
            let found = state.call_dispositions.find(o => o.id === call_disposition.id)
            if (found) {
                let updated_call_disposition = _.extend(found, call_disposition)
                Vue.set(state.call_dispositions, state.call_dispositions.indexOf(found), updated_call_disposition)
            }
        },

        DELETE_CALL_DISPOSITION(state, call_disposition) {
            let found = state.call_dispositions.find(o => o.id === call_disposition.id)
            if (found) {
                state.call_dispositions.splice(state.call_dispositions.indexOf(found), 1)
            }
        },

        SET_CALL_DISPOSITIONS(state, call_dispositions) {
            state.call_dispositions = call_dispositions
        },

        SET_CALL_DISPOSITIONS_LOADED(state, call_dispositions_loaded) {
            state.call_dispositions_loaded = call_dispositions_loaded
        },

        NEW_ACTIVITY_TYPE(state, activity_type) {
            if (!!state.activity_types.find(item => item === activity_type)) {
                return
            }

            state.activity_types.push(activity_type)
        },

        DELETE_ACTIVITY_TYPE(state, activity_type) {
            let found = state.activity_types.find(o => o === activity_type)
            if (found) {
                state.activity_types.splice(state.activity_types.indexOf(found), 1)
            }
        },

        SET_ACTIVITY_TYPES(state, activity_types) {
            state.activity_types = activity_types
        },

        SET_ACTIVITY_TYPES_LOADED(state, activity_types_loaded) {
            state.activity_types_loaded = activity_types_loaded
        },

        NEW_RING_GROUP(state, ring_group) {
            if (resourceExists(state.ring_groups, ring_group)) {
                return
            }
            state.ring_groups.push(ring_group)
        },

        UPDATE_RING_GROUP(state, ring_group) {
            let found = state.ring_groups.find(o => o.id === ring_group.id)
            if (found) {
                let updated_ring_group = _.extend(found, ring_group)
                Vue.set(state.ring_groups, state.ring_groups.indexOf(found), updated_ring_group)
            }
        },

        DELETE_RING_GROUP(state, ring_group) {
            let found = state.ring_groups.find(o => o.id === ring_group.id)
            if (found) {
                state.ring_groups.splice(state.ring_groups.indexOf(found), 1)
            }
        },

        SET_RING_GROUPS(state, ring_groups) {
            state.ring_groups = ring_groups
        },

        SET_CONTACT_LISTS(state, contact_lists) {
          state.contact_lists = contact_lists
        },

        NEW_IVR(state, ivr) {
            if (resourceExists(state.ivrs, ivr)) {
                return
            }
            state.ivrs.push(ivr)
        },

        UPDATE_IVR(state, ivr) {
            let found = state.ivrs.find(o => o.id === ivr.id)
            if (found) {
                let updated_ring_group = _.extend(found, ivr)
                Vue.set(state.ivrs, state.ivrs.indexOf(found), updated_ring_group)
            }
        },

        DELETE_IVR(state, ivr) {
            let found = state.ivrs.find(o => o.id === ivr.id)
            if (found) {
                state.ivrs.splice(state.ivrs.indexOf(found), 1)
            }
        },

        SET_IVRS(state, ivrs) {
            state.ivrs = ivrs
        },

        NEW_IVR_PROMPT_FILE(state, ivr_prompt_file) {
            if (resourceExists(state.ivr_prompt_files, ivr_prompt_file)) {
                return
            }
            state.ivr_prompt_files.push(ivr_prompt_file)
        },

        UPDATE_IVR_PROMPT_FILE(state, ivr_prompt_file) {
            let found = state.ivr_prompt_files.find(o => o.id === ivr_prompt_file.id)
            if (found) {
                let updated_ivr_prompt_file = _.extend(found, ivr_prompt_file)
                Vue.set(state.ivr_prompt_files, state.ivr_prompt_files.indexOf(found), updated_ivr_prompt_file)
            }
        },

        DELETE_IVR_PROMPT_FILE(state, ivr_prompt_file) {
            let found = state.ivr_prompt_files.find(o => o.id === ivr_prompt_file.id)
            if (found) {
                state.ivr_prompt_files.splice(state.ivr_prompt_files.indexOf(found), 1)
            }
        },

        SET_IVR_PROMPT_FILES(state, ivrs) {
            state.ivr_prompt_files = ivrs
        },

        NEW_SCRIPT(state, script) {
            if (resourceExists(state.scripts, script)) {
                return
            }
            state.scripts.push(script)
        },

        UPDATE_SCRIPT(state, script) {
            let found = state.scripts.find(o => o.id === script.id)
            if (found) {
                let updated_script = _.extend(found, script)
                Vue.set(state.scripts, state.scripts.indexOf(found), updated_script)
            }
        },

        DELETE_SCRIPT(state, script) {
            let found = state.scripts.find(o => o.id === script.id)
            if (found) {
                state.scripts.splice(state.scripts.indexOf(found), 1)
            }
        },

        SET_SCRIPTS(state, scripts) {
            state.scripts = scripts
        },

        NEW_COMPANY(state, company) {
            if (resourceExists(state.companies, company)) {
                return
            }
            state.companies.push(company)
        },

        SET_COMPANIES(state, companies) {
            state.companies = companies
        },

        RESET_VUEX(state) {
            state = Object.assign(state, default_state)
            // a new filter object, not a reference from the default state filter
            state.filter = JSON.parse(JSON.stringify(default_state.filter))
        },

        RESET_FILTERS(state) {
            const campaign_id = state.filter.campaign_id
            const user_id = state.filter.user_id
            const workflow_id = state.filter.workflow_id
            // a new filter object, not a reference from the default state filter
            state.filter = JSON.parse(JSON.stringify(default_state.filter))
            if (campaign_id) {
                state.filter.campaign_id = campaign_id
            }
            if (workflow_id) {
                state.filter.workflow_id = workflow_id
            }
            if (user_id) {
                state.filter.user_id = user_id
            }
            state.filter.changed = false
        },

        RESET_CONTACT_FILTERS(state) {
            state.filter_options = Object.assign(state.filter_options, default_state.filter_options)
        },

        SET_USAGE(state, usage) {
            state.usage = usage
        },

        SET_SETTINGS(state, settings) {
            state.settings = settings
        },

        SET_TOP_OFFSET(state, top_offset) {
            state.top_offset = top_offset
        },

        SET_FIRST_LOGIN(state, first_login) {
            state.first_login = first_login
        },

        NEW_BROADCAST(state, broadcast) {
            if (resourceExists(state.broadcasts, broadcast)) {
                return
            }
            state.broadcasts.push(broadcast)
        },

        SET_BROADCASTS(state, broadcasts) {
            state.broadcasts = broadcasts
        },

        UPDATE_BROADCAST(state, broadcast) {
            let found = state.broadcasts.find(bc => bc.id === broadcast.id)
            if (found) {
                let updated_broadcast = _.extend(found, broadcast)
                Vue.set(state.broadcasts, state.broadcasts.indexOf(found), updated_broadcast)
            }
        },

        DELETE_BROADCAST(state, broadcast) {
            let found = state.broadcasts.find(bc => bc.id === broadcast.id)
            if (found) {
                state.broadcasts.splice(state.broadcasts.indexOf(found), 1)
            }
        },

        NEW_WORKFLOW(state, workflow) {
            if (resourceExists(state.workflows, workflow)) {
                return
            }
            state.workflows.push(workflow)
        },

        SET_WORKFLOWS(state, workflows) {
            state.workflows = workflows
        },

        UPDATE_WORKFLOW(state, workflow) {
            let found = state.workflows.find(wf => wf.id === workflow.id)
            if (found) {
                let updated_workflow = _.extend(found, workflow)
                Vue.set(state.workflows, state.workflows.indexOf(found), updated_workflow)
            }
        },

        DELETE_WORKFLOW(state, workflow) {
            let found = state.workflows.find(wf => wf.id === workflow.id)
            if (found) {
                state.workflows.splice(state.workflows.indexOf(found), 1)
            }
        },

        SET_EXPORTS(state, exports) {
            state.exports.data = exports
        },

        NEW_EXPORT(state, exp) {
            if (resourceExists(state.exports.data, exp)) {
                return
            }
            // state.exports.data.splice(0, 0, exp)
            state.exports.data.push(exp)
        },

        UPDATE_EXPORT(state, exp) {
            let found = state.exports.data.find(item => {
                return item.id === exp.id
            })
            if (!found) {
                return
            }
            _.extend(found, exp)
        },

        DELETE_EXPORT(state, exp) {
            let found = state.exports.data.find(o => o.id === exp.id)
            if (found) {
                state.exports.data.splice(state.exports.data.indexOf(found), 1)
            }
        },

        NEW_USER(state, user) {
            if (resourceExists(state.users, user)) {
                return
            }
            state.users.push(user)
        },

        SET_USERS(state, users) {
            state.users = users
        },

        UPDATE_USER(state, user) {
            let found = state.users.find(u => u.id === user.id)
            if (found) {
                let updated_user = _.extend(found, user)
                Vue.set(state.users, state.users.indexOf(found), updated_user)
            }
        },

        DELETE_USER(state, user) {
            let found = state.users.find(u => u.id === user.id)
            if (found) {
                state.users.splice(state.users.indexOf(found), 1)
            }
        },

        SET_COMMUNICATIONS_COUNT(state, communications_count) {
            state.communications_count = communications_count
        },

        SET_COUNTRIES(state, countries) {
            state.countries = countries
        },

        SET_LEAD_SOURCES(state, lead_sources) {
            state.lead_sources = lead_sources
        },

        ADD_LEAD_SOURCE(state, lead_source) {
            if (resourceExists(state.lead_sources, lead_source)) {
                return
            }
            state.lead_sources.push(lead_source)
        },

        UPDATE_LEAD_SOURCE(state, lead_source) {
            let found = state.lead_sources.find(ls => ls.id === lead_source.id)
            if (found) {
                Vue.set(state.lead_sources, state.lead_sources.indexOf(found), lead_source)
            }
        },

        DELETE_LEAD_SOURCE(state, lead_source_id) {
            let found = state.lead_sources.find(ls => ls.id === lead_source_id)
            if (found) {
                state.lead_sources.splice(state.lead_sources.indexOf(found), 1)
            }
        },

        SET_ATTRIBUTE_DICTIONARIES(state, attribute_dictionaries) {
            state.attribute_dictionaries = attribute_dictionaries
        },

        NEW_SMS_TEMPLATE(state, sms_template) {
            if (resourceExists(state.sms_templates, sms_template)) {
                return
            }
            state.sms_templates.push(sms_template)
        },

        SET_SMS_TEMPLATES(state, sms_templates) {
            state.sms_templates = sms_templates
        },

        UPDATE_SMS_TEMPLATE(state, sms_template) {
            let found = state.sms_templates.find(template => template.id === sms_template.id)
            if (found) {
                Vue.set(state.sms_templates, state.sms_templates.indexOf(found), sms_template)
            }
        },

        DELETE_SMS_TEMPLATE(state, sms_template_id) {
            let found = state.sms_templates.find(template => template.id === sms_template_id)
            if (found) {
                state.sms_templates.splice(state.sms_templates.indexOf(found), 1)
            }
        },

        UPDATE_AGENT_STATUS(state, event) {
            let found = state.users.find(u => u.id === event.user_id)
            if (found) {
                const index = state.users.indexOf(found)
                Vue.set(state.users[index], 'agent_status', event.agent_status)
                Vue.set(state.users[index], 'last_agent_status_change', event.last_agent_status_change)
                Vue.set(state.users[index], 'updated_at', event.last_agent_status_change)
            }
        },

        SET_SECTION(state, section) {
            state.section = section;
        },

        SET_TRANSCRIPTION_PAGE(state, transcription_page) {
            state.transcription_page = transcription_page;
        },

        SET_ADDONS(state, addons) {
            state.addons = addons
        },

        STOP_NOTIFICATION_AUDIO(state) {
            state.fishing_mode_notification_audio.pause()
        },

        SHOW_CSAT_CALLS_PANEL(state) {
            state.csat_calls_panel.enabled = true
        },

        SET_CSAT_CALLS_LOADING(state, data) {
            state.csat_calls_panel.loading = data
        },

        HIDE_CSAT_CALLS_PANEL(state) {
            state.csat_calls_panel.enabled = false
        },

        SET_CSAT_CALLS_PANEL_SCORE(state, data) {
            state.csat_calls_panel.score = data
        },

        SET_CSAT_CALLS_PANEL_FILTER_PARAMS(state, data) {
            state.csat_calls_panel.params = data
        },

        SET_CSAT_CALLS_PANEL_CALLS(state, data) {
            state.csat_calls_panel.calls = data
        },

        SET_SHOULD_SHOW_VIDEO_MODAL(state, value) {
            console.log('SET_SHOULD_SHOW_VIDEO_MODAL', value)
            state.shouldShowVideoModal = value
        },

        SET_SHOWED_KYC_DIALOG (state, value) {
            state.showedKycDialog = value
        },

        SET_SHOWED_KYC_RELOAD_DIALOG (state, value) {
            state.showedKycReloadDialog  = value
        },

        SET_IS_INTRO_VIDEO_VISIBLE (state, value) {
            state.isIntroVideoVisible = value
        },

        SET_SHOULD_OPEN_COMPLIANCE_WIZARD(state, value) {
            state.shouldOpenComplianceWizard += 1
        },

        TOGGLE_COMPANY_TOKEN_VISIBILITY(state) {
            console.log('TOGGLE_COMPANY_TOKEN_VISIBILITY', state.companyTokenVisibility)
            state.companyTokenVisibility = !state.companyTokenVisibility
        },

        SET_CURRENT_SELECTED_TEAM(state, team) {
            state.current_selected_team = team
        },

        SET_ALL_TEAMS(state, teams) {
            state.all_teams = teams
        },

        SET_SHOULD_RELOAD_ALL_TEAMS(state, value) {
            state.should_reload_all_teams = value
        },

        SET_CURRENT_TIMEZONE(state, timezone) {
            state.current_timezone = timezone
        },

        SET_TEAMS(state, teams) {
            state.teams = teams
        },

        SET_IS_UPDATING_CURRENT_COMPANY(state, value) {
            state.is_updating_current_company = value
        },
    },

    plugins: [
        vuexLocal.plugin,
        createMutationsSharer({
            predicate: ['LOG_OUT_USER']
        })
    ]
})
