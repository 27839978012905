var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-tool",
    {
      attrs: {
        "tool-name": _vm.tool.name,
        "tool-description": _vm.tool.description,
      },
      on: { back: _vm.goBack },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          staticClass: "row",
        },
        [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Property" } },
                [
                  _c("contact-property-selector", {
                    attrs: { placeholder: "Select Contact Property" },
                    on: { "property-selected": _vm.handlePropertySelected },
                    model: {
                      value: _vm.property,
                      callback: function ($$v) {
                        _vm.property = $$v
                      },
                      expression: "property",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }