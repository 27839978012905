<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <h5 class="text-dark">
                    <i class="el-icon-time"></i>
                    <span>Working Hours</span>
                </h5>

                <el-form-item prop="operating_hours">
                    <div class="form-label">
                        <small>
                            This setting sets the working hours for
                            this agent. Note that the times are
                            local to <b>your company</b> timezone, currently set
                            to
                            <b v-if="current_company">{{ current_company.timezone }}</b>
                        </small>
                    </div>
                    <business-hours
                        type="select"
                        color="#00BF50"
                        class="custom_operating_hours_font"
                        :days="bot.operating_hours"
                        :time-increment="time_increment">
                    </business-hours>
                </el-form-item>

                <el-form-item class="no-border">
                    <el-checkbox name="process-holidays"
                                class="w-full checkbox-label-description mt-2"
                                v-model="bot.process_on_holidays"
                                border>
                        <label class="mb-1">
                            <span class="mr-1">Work on US national holidays</span>
                        </label>
                        <small>
                            Enabling this will allow this agent to work during (US) holidays.
                        </small>
                    </el-checkbox>
                </el-form-item>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'ScheduleTab',
    props: {
        bot: {
            type: Object,
            required: true
        },
        process_on_holidays: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            time_increment: 30,
        }
    },
    computed: {
        ...mapState('cache', ['current_company']),
    }
}
</script>

<style scoped>
.custom_operating_hours_font {
    font-family: 'Nunito', sans-serif;
}
</style>
