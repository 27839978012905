<template>
    <div class="wizard d-flex flex-column full-height"
         v-loading="loading">
        <el-form label-position="left"
                 class="flex-1 scroll-y px-4"
                 :ref="cardname"
                 :rules="rules"
                 :model="card">
            <div class="row">
                <div class="col-md-12">
                    <info-alert-box v-if="cardname === 'hubspot'"
                                    text="HubSpot Workflow > Send a text (SMS/MMS) action's usage is considered automated and paid. You are agreeing to our billing terms by using the action in HubSpot."
                                    kb-link="https://support.aloware.com/en/articles/9033804-aloware-pricing-plans-empowering-your-teams-with-flexible-solutions">
                    </info-alert-box>

                    <h5>
                        General
                        <el-tooltip
                            v-if="cardname == 'stripe'"
                            class="item"
                            effect="dark"
                            placement="right">
                            <div slot="content">
                                <small>
                                    Our Stripe integration will only add a search button to your contacts and does
                                    <br>
                                    NOT
                                    push or pull data from your Stripe account.
                                </small>
                            </div>
                            <i class="material-icons text-lg cursor-pointer text-blue p-0">info_outline</i>
                        </el-tooltip>
                    </h5>
                    <hr class="no-top-margin">
                    <div v-if="hasOtherEmailIntegrationEnabled(cardname)">
                        <strong>Note:</strong>
                        <small>
                            You must disable other email integrations first before you can enable this integration.
                        </small>
                    </div>
                    <template v-else>
                        <div v-if="integrations_always_show_status.includes(cardname)"
                             class="row form-group align-content-center">
                            <div class="col">
                                <el-form-item
                                    prop="status"
                                    class="my-0 inline">
                                    <template v-if="isStatusToggleDisabled">
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            content="You can only enable one CRM integration."
                                            placement="top">
                                            <el-switch
                                                :disabled="isStatusToggleDisabled"
                                                v-model="card.status"
                                                active-color="#00a344"
                                                @change="checkAndSave">
                                            </el-switch>
                                        </el-tooltip>
                                    </template>
                                    <el-switch
                                        v-else
                                        :value="!isStatusToggleDisabled"
                                        active-color="#00a344"
                                        @change="checkAndSave">
                                    </el-switch>
                                </el-form-item>
                                Integration Status
                            </div>
                        </div>
                        <div class="row form-group align-content-center"
                             v-else-if="!integrations_always_show_status.includes(cardname) && card.status">
                            <div class="col">
                                <el-form-item v-if="cardname == 'sendgrid' || cardname == 'mailgun' || cardname == 'SMTP'"
                                              prop="status"
                                              class="ml-1 my-0 inline">
                                    <el-switch v-model="card.status"
                                               active-color="#00a344"
                                               @change="checkCompanyUpdatedInfo">
                                    </el-switch>
                                </el-form-item>
                                <el-form-item
                                    prop="status"
                                    class="my-0 inline"
                                    v-else>
                                    <template v-if="isStatusToggleDisabled">
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            content="You can only enable one CRM integration."
                                            placement="top">
                                            <el-switch
                                                :disabled="isStatusToggleDisabled"
                                                v-model="card.status"
                                                active-color="#00a344"
                                                @change="checkAndSave">
                                            </el-switch>
                                        </el-tooltip>
                                    </template>
                                    <el-switch
                                        v-else
                                        v-model="card.status"
                                        active-color="#00a344"
                                        @change="checkAndSave">
                                    </el-switch>
                                </el-form-item>
                                Integration Status
                            </div>
                        </div>
                    </template>

                    <template v-if="cardname == 'google-calendar'">
                        <div v-if="card.credentials.email"
                             class="row form-group align-items-center">
                            <div class="col-6">
                                <small>Synched Google Account: <b>{{ card.credentials.email }}</b></small>
                            </div>
                        </div>

                        <div>
                            <el-tooltip class="item"
                                        effect="dark"
                                        placement="right">
                                <div slot="content">
                                    <strong>Authentication</strong>
                                    <br>
                                    <small>
                                        This will redirect you to Google for authentication.
                                    </small>
                                </div>
                                <GoogleSignInButton
                                    v-if="!card.credentials.token"
                                    :text="'Sync with Google'"
                                    @click.native="oauth('google', getGoogleCalendarParams)"
                                    class="d-inline py-2"
                                />
                                <GoogleSignInButton
                                    v-else
                                    :text="'Re-Sync with Google'"
                                    @click.native="oauth('google', getGoogleCalendarParams)"
                                    class="d-inline py-2"
                                />
                            </el-tooltip>

                            <div v-if="card.credentials.email">
                                <h5 class="mt-4">Google Calendar Settings</h5>
                                <hr class="no-top-margin">
                                <h6 class="mt-4 text-black">Synched Calendar</h6>
                                <el-select v-model="card.settings.calendar.id"
                                           filterable
                                           placeholder="Calendar"
                                           @change="submit(card.name, false)">
                                    <el-option v-for="(calendar_list_entry, idx) in card.settings.calendars"
                                               :key="idx"
                                               :label="renderCalendarSummary(calendar_list_entry)"
                                               :value="calendar_list_entry.id"/>
                                </el-select>

                                <h6 class="mt-4 text-black"
                                    v-if="card.settings.calendar.id">
                                    Calendar Timezone
                                </h6>
                                <el-select v-show="card.settings.calendar.id"
                                           v-model="card.settings.calendar.timeZone"
                                           filterable
                                           placeholder="Timezone">
                                    <el-option :label="card.settings.calendar.timeZone"
                                               :value="card.settings.calendar.timeZone"/>
                                </el-select>
                            </div>

                            <template v-if="card.settings.calendar.id">
                                <h5 class="mt-4">Manual Sync</h5>
                                <hr class="no-top-margin">
                                <div class="row form-group align-items-center">
                                    <div class="col">
                                        <h6 class="text-black">Sync Calendar</h6>
                                        <small class="p-0 m-0 block text-black-lt">
                                            <span class="mb-1">
                                                Use <b>push calendar</b> to see your <b>upcoming</b> Aloware Calendar Events and Appointments in Google Calendar.
                                            </span>
                                            <br>
                                            <span class="mb-1">
                                                Use <b>pull calendar</b> to see all your <b>upcoming</b> Google Calendar Events within Aloware Calendar.
                                            </span>
                                        </small>
                                        <el-alert class="my-2"
                                                  type="info"
                                                  :closable="false"
                                                  show-icon>
                                            <b>Note</b>: Pulling calendar events will update users and contacts events and appointments.
                                        </el-alert>
                                    </div>
                                </div>
                                <div class="row form-group align-items-center">
                                    <div class="col d-flex justify-content-end">
                                        <div class="d-flex flex-column mr-2">
                                            <el-button :disabled="loading_push || [585, 1000].includes(current_company.id)"
                                                       plain
                                                       @click="pushCalendar()">
                                                <span>Push Upcoming Events</span>
                                                <i class="fa fa-arrow-up"
                                                   v-if="!loading_push"></i>
                                                <i class="material-icons loader pull-left"
                                                   v-if="loading_push">&#xE863;</i>
                                            </el-button>
                                        </div>
                                        <div class="d-flex flex-column">
                                            <el-button :disabled="loading_pull || [585, 1000].includes(current_company.id)"
                                                       plain
                                                       @click="pullCalendar()">
                                                <span>Pull Upcoming Events</span>
                                                <i class="fa fa-arrow-down"
                                                   v-if="!loading_pull"></i>
                                                <i class="material-icons loader pull-left"
                                                   v-if="loading_pull">&#xE863;</i>
                                            </el-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row form-group align-items-center">
                                    <div class="col">
                                        <h6 class="text-black">Watch Google Calendar</h6>
                                        <small class="p-0 m-0 block text-black-lt">
                                            <span class="mb-1">
                                                Use <b>start watching</b> to let Aloware know when an event is added in your Google Calendar from now on.
                                            </span>
                                            <br>
                                            <span class="mb-1">
                                                Use <b>stop watching</b> to stop adding events created in you Google Calendar.
                                            </span>
                                        </small>
                                        <el-alert
                                            class="my-2"
                                            type="info"
                                            :closable="false"
                                            show-icon>
                                            <b>Note</b>: If you <b>stop watching</b>, events and appointments created in Aloware Calendar will still be reflected in Google Calendar
                                        </el-alert>
                                    </div>
                                    <div class="col d-flex justify-content-end">
                                        <div class="d-flex flex-column mr-2">
                                            <el-switch
                                                v-model="shouldWatchGoogleCalendar"
                                                active-text="Watch Google Calendar Events"
                                                @change="watchCalendar">
                                            </el-switch>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>

                    <template v-if="cardname == 'custom_link_builder' && card.status">
                        <div class="row form-group align-items-center">
                            <div class="col">
                                <h6 class="text-black">Custom link</h6>
                                <small class="p-0 m-0 block text-black-lt">
                                    Our custom link integration will help you add a dynamically generated link
                                    to an external system based on contact information
                                </small>
                            </div>
                        </div>
                        <div class="row form-group align-items-center">
                            <div class="col">
                                <el-form-item prop="settings.custom_link"
                                              class="pull-right m-0 w-100">
                                    <el-input
                                        placeholder="Put your custom link here"
                                        v-model="card.settings.custom_link"
                                        @input="getNewCustomLink()"
                                        clearable>
                                        <el-tooltip content="Add variable"
                                                    placement="bottom"
                                                    slot="append">
                                            <variables @variable-selected="variableSelected"
                                                       popover_placement="bottom-start"
                                                       icon="fa fa-plus"
                                                       variable_btn_class="mx-auto border-0 bg-transparent"
                                            >
                                            </variables>
                                        </el-tooltip>
                                    </el-input>
                                </el-form-item>
                                <el-tag
                                    class="mt-4 mx-1"
                                    v-for="tag in card.settings.tags"
                                    :key="tag.name"
                                    closable
                                    :type="tag.type"
                                    effect="dark"
                                    @close="removeTag(tag)">
                                    {{ tag.name }}
                                </el-tag>
                            </div>
                        </div>
                        <div v-if="card.settings.selected_contact"
                             class="row form-group align-items-center">
                            <div class="col">
                                Link preview:
                                <small class="p-0 m-0 text-black-lt text-xs">
                                    <a :href="card.settings.selected_contact.custom_link"
                                       target="_blank"
                                       class="text-bluish _600">
                                        {{ card.settings.selected_contact.custom_link }}
                                    </a>
                                </small>
                            </div>
                        </div>
                        <div class="row form-group align-items-center mt-4">
                            <div class="col">
                                <h6 class="text-black">Variable Separator</h6>
                                <small class="p-0 m-0 block text-black-lt">
                                    Automatically insert before each new variable in the url. You can also type the character manually in the link builder
                                </small>
                            </div>
                        </div>
                        <div class="row form-group align-items-center">
                            <div class="col-6">
                                <div class="d-flex">
                                    <el-form-item prop="settings.prefix"
                                                  class="m-0 p-0 w-full">
                                        <el-input
                                            placeholder="e.g. (/, &, etc.)"
                                            v-model="card.settings.prefix"
                                            clearable>
                                            <el-button :disabled="!card.settings.prefix"
                                                       plain
                                                       @click="replacePrefix()"
                                                       size="small"
                                                       slot="append">
                                                <span>Replace all</span>
                                            </el-button>
                                        </el-input>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group align-items-center mt-4 mb-0">
                            <div class="col">
                                <h6 class="text-black">Settings</h6>
                            </div>
                        </div>
                        <div class="row form-group align-items-center">
                            <div class="col">
                                <el-form-item
                                    prop="settings.remove_special_char"
                                    class="my-0 inline">
                                    <el-switch
                                        v-model="card.settings.remove_special_char"
                                        active-color="#00a344"
                                        @change="getNewCustomLink()">
                                    </el-switch>
                                </el-form-item>
                                Remove special characters in phone number
                            </div>
                        </div>
                        <div class="row form-group align-items-center mt-4">
                            <div class="col">
                                <h6 class="text-black">Contact</h6>
                                <small class="p-0 m-0 block text-black-lt">
                                    Search contacts to see link preview while building your URL
                                </small>
                            </div>
                        </div>
                        <div class="row form-group align-items-center">
                            <div class="col-6">
                                <contact-select
                                    v-model="card.settings.selected_contact"
                                    label="Search contact"
                                    @select-contacts="selectContact"
                                    :contact_selected="card.settings.selected_contact">
                                </contact-select>
                            </div>
                        </div>
                        <div v-if="card.settings.selected_contact"
                             class="row form-group align-items-center">
                            <div class="col">
                                Currently selected contact:
                                <small class="p-0 m-0 text-black-lt text-xs">
                                    {{ card.settings.selected_contact.name }}
                                </small>
                            </div>
                        </div>
                    </template>

                    <template v-if="cardname == 'hubspot'">
                        <div class="row form-group">
                            <div class="col d-flex">
                                <div class="mr-2">
                                    <integration-settings-tester-btn :name="cardname"
                                                                     :card="card"
                                                                     v-if="card.credentials.access_token !== ''"
                                                                     @reload="fetchDetails">
                                    </integration-settings-tester-btn>
                                </div>
                                <div>
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        placement="right">
                                        <div slot="content">
                                            <strong>Authentication</strong>
                                            <br>
                                            <small>
                                                This will redirect you to HubSpot for authentication.
                                            </small>
                                        </div>
                                        <el-button v-if="card.credentials.access_token !== ''"
                                                   plain
                                                   @click="oauth(cardname)">
                                            <span>Re-Authenticate</span>
                                            <i class="fa fa-sign-in mr-2"></i>
                                        </el-button>
                                        <el-button v-else-if="!isStatusToggleDisabled"
                                                   plain
                                                   @click="oauth(cardname)">
                                            <span>Authenticate</span>
                                            <i class="fa fa-sign-in mr-2"></i>
                                        </el-button>
                                    </el-tooltip>
                                    <el-alert
                                        v-if="isStatusToggleDisabled"
                                        class="my-2"
                                        type="info"
                                        :closable="false"
                                        show-icon>
                                        You can only enable one CRM integration.
                                    </el-alert>
                                </div>
                            </div>
                        </div>
                        <template v-if="card.credentials.access_token !== ''">
                            <h5 class="mt-4">Synchronization</h5>
                            <hr class="no-top-margin">
                            <div class="row form-group align-items-center">
                                <div class="col">
                                    <h6 class="text-black">Sync Contacts</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        <span class="mb-1">
                                            Use <b>push contacts</b> to see your {{ statics.name }} contacts in HubSpot
                                        </span>
                                        <br>
                                        <span class="mb-1">
                                            Use <b>pull contacts</b> to see all your HubSpot contacts within {{ statics.name }}
                                        </span>
                                    </small>
                                    <el-alert
                                        class="my-2"
                                        type="info"
                                        :closable="false"
                                        show-icon>
                                        <b>Note</b>: Pulling contacts will update {{ statics.name }} contacts using CRM data
                                    </el-alert>
                                    <el-alert
                                        class="my-2"
                                        type="warning"
                                        :closable="false"
                                        show-icon>
                                        <span class="d-block mb-1 text-black">
                                            Pulling contacts will cause LRN lookup charges to be applied
                                        </span>
                                    </el-alert>
                                </div>
                                <div class="col d-flex justify-content-end">
                                    <div class="d-flex flex-column mr-2">
                                        <el-button :disabled="loading_push || [585, 1000].includes(current_company.id)"
                                                   plain
                                                   @click="pushContacts(cardname)">
                                            <span>Push Contacts</span>
                                            <i class="fa fa-arrow-up"
                                               v-if="!loading_push"></i>
                                            <i class="material-icons loader pull-left"
                                               v-if="loading_push">&#xE863;</i>
                                        </el-button>
                                        <div class="pt-2">
                                            <small v-if="card.settings && card.settings.last_sync_push_contact"
                                                   class="p-0 m-0 block text-black-lt text-xs">
                                                Last push contact: <b>{{ card.settings.last_sync_push_contact | fixFullDateUTCRelative }}</b>
                                            </small>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <el-button :disabled="loading_pull || [585, 1000].includes(current_company.id)"
                                                   plain
                                                   @click="pullContacts(cardname)">
                                            <span>Pull Contacts</span>
                                            <i class="fa fa-arrow-down"
                                               v-if="!loading_pull"></i>
                                            <i class="material-icons loader pull-left"
                                               v-if="loading_pull">&#xE863;</i>
                                        </el-button>
                                        <div class="pt-2">
                                            <small v-if="card.settings && card.settings.last_sync_pull_contact"
                                                   class="p-0 m-0 block text-black-lt text-xs">
                                                Last pull contact: <b>{{ card.settings.last_sync_pull_contact | fixFullDateUTCRelative }}</b>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group mt-3 align-items-center">
                                <div class="col">
                                    <h6 class="text-black">Sync Users</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        Connect your HubSpot users to {{ statics.name }} users.
                                        <br>
                                        Only current users in {{ statics.name }} are synced, and no new users will be created.
                                    </small>
                                    <el-alert
                                        class="my-2"
                                        type="info"
                                        :closable="false"
                                        show-icon>
                                        Heads up! Users are synced by email, please make sure your agents have the same email address on {{ statics.name }} and HubSpot.
                                    </el-alert>
                                </div>
                                <div class="col d-flex justify-content-end">
                                    <div class="d-flex flex-column">
                                        <el-button
                                            :disabled="loading_pull_users"
                                            @click="pullUsers(cardname)"
                                            plain
                                            class="pull-right">
                                            <span>Sync Users</span>
                                            <i class="material-icons loader pull-left"
                                               v-if="loading_pull_users">&#xE863;</i>
                                        </el-button>
                                        <div class="pt-2">
                                            <small class="p-0 m-0 block text-black-lt text-xs"
                                                   v-if="card.settings && card.settings.last_sync_user">
                                                Last user sync: <b>{{ card.settings.last_sync_user | fixFullDateUTCRelative }}</b>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group mt-3 align-items-center">
                                <div class="col">
                                    <h6 class="text-black">Pull Lead Statuses</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        Pulls your HubSpot lead statuses to {{ statics.name }} as disposition status
                                    </small>
                                </div>
                                <div class="col d-flex justify-content-end">
                                    <div class="d-flex flex-column width-300 fixed">
                                        <el-button
                                            :disabled="loading_pull_lead_status"
                                            @click="pullLeadStatus(cardname)"
                                            plain
                                            class="pull-right">
                                            <span>Pull Lead Statuses</span>
                                            <i v-if="!loading_pull_lead_status"
                                               class="fa fa-arrow-down"></i>
                                            <i v-if="loading_pull_lead_status"
                                               class="material-icons loader pull-left">&#xE863;</i>
                                        </el-button>
                                        <div class="pt-2">
                                            <small v-if="card.settings && card.settings.last_sync_disposition_status"
                                                   class="p-0 m-0 block text-black-lt text-xs">
                                                Last disposition status sync: <b>{{ card.settings.last_sync_disposition_status | fixFullDateUTCRelative }}</b>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group mt-3 align-items-center">
                                <div class="col">
                                    <h6 class="text-black">Pull Call Outcomes</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        Pulls your HubSpot call outcomes to {{ statics.name }} as call dispositions
                                    </small>
                                </div>
                                <div class="col d-flex justify-content-end">
                                    <div class="d-flex flex-column width-300 fixed">
                                        <el-button
                                            :disabled="loading_pull_call_dispositions"
                                            @click="pullCallDispositions(cardname)"
                                            plain
                                            class="pull-right">
                                            <span>Pull Call Outcomes</span>
                                            <i v-if="!loading_pull_call_dispositions"
                                               class="fa fa-arrow-down"></i>
                                            <i v-if="loading_pull_call_dispositions"
                                               class="material-icons loader pull-left">&#xE863;</i>
                                        </el-button>
                                        <div class="pt-2">
                                            <small v-if="card.settings && card.settings.last_sync_call_disposition"
                                                   class="p-0 m-0 block text-black-lt text-xs">
                                                Last call disposition sync: <b>{{ card.settings.last_sync_call_disposition | fixFullDateUTCRelative }}</b>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group mt-3 align-items-center"
                                 v-if="current_company.hubspot_integration_enabled">
                                <div class="col">
                                    <h6 class="text-black">Pull Activity Types</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        Pulls your HubSpot activity types to {{ statics.name }}
                                    </small>
                                </div>
                                <div class="col d-flex justify-content-end">
                                    <div class="d-flex flex-column width-300 fixed">
                                        <el-button
                                            :disabled="loading_pull_activity_types"
                                            @click="pullActivityTypes(cardname)"
                                            plain
                                            class="pull-right">
                                            <span>Pull Activity Types</span>
                                            <i v-if="!loading_pull_activity_types"
                                               class="fa fa-arrow-down"></i>
                                            <i v-if="loading_pull_activity_types"
                                               class="material-icons loader pull-left">&#xE863;</i>
                                        </el-button>
                                        <div class="pt-2">
                                            <small v-if="card.settings && card.settings.last_sync_call_activity_types"
                                                   class="p-0 m-0 block text-black-lt text-xs">
                                                Last call activity types sync: <b>{{ card.settings.last_sync_call_activity_types | fixFullDateUTCRelative }}</b>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group mt-3 align-items-center"
                                 v-if="current_company.hubspot_integration_enabled">
                                <div class="col">
                                    <h6 class="text-black">Pull Deals</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        Pulls your HubSpot deals to {{ statics.name }}
                                    </small>
                                </div>
                                <div class="col d-flex justify-content-end">
                                    <div class="d-flex flex-column width-300 fixed">
                                        <el-button
                                            :disabled="loading_pull_deals"
                                            @click="pullDeals(cardname)"
                                            plain
                                            class="pull-right">
                                            <span>Pull Deals</span>
                                            <i v-if="!loading_pull_deals"
                                               class="fa fa-arrow-down"></i>
                                            <i v-if="loading_pull_deals"
                                               class="material-icons loader pull-left">&#xE863;</i>
                                        </el-button>
                                        <div class="pt-2">
                                            <small v-if="card.settings && card.settings.last_sync_deals"
                                                   class="p-0 m-0 block text-black-lt text-xs">
                                                Last call deals sync: <b>{{ card.settings.last_sync_deals | fixFullDateUTCRelative }}</b>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div v-if="showHubspotSendAsNoteOption || showHubspotSendCallEvents"
                             class="mt-4">
                            <h5>Settings</h5>
                            <hr class="no-top-margin">
                            <div class="row">
                                <div class="col">
                                    <el-form-item v-if="showHubspotSendAsNoteOption"
                                                  class="my-0 inline">
                                        <el-switch v-model="card.settings.send_sms_as_note"
                                                   active-color="#00a344">
                                        </el-switch>
                                    </el-form-item>
                                    Log text messages as SMS activity
                                </div>
                            </div>
                            <div class="row form-group mt-3">
                                <div class="col">
                                    <el-form-item v-if="showHubspotSendCallEvents"
                                                  class="my-0 inline">
                                        <el-switch v-model="card.settings.send_call_events"
                                                   active-color="#00a344">
                                        </el-switch>
                                    </el-form-item>
                                    Send call events (Beta)
                                </div>
                            </div>
                        </div>
                        <!-- Start Property Mapper -->
                        <template v-if="card.credentials.access_token !== ''">
                            <h5 class="my-2">Contact Property Mapping
                                <!-- Start tooltip -->
                                <el-tooltip class="item" effect="dark" placement="right">
                                    <div slot="content">
                                        <small class="font-weight-bold mb-1">
                                            Map HubSpot Contact Properties to Aloware's default and Custom Contact Fields.
                                        </small>
                                        <br/><br/>
                                        <small class="font-weight-bold">Legends</small><br/>
                                        <small class="mb-1">
                                            <i class="fa fa-lock mr-2"></i>The contact property mapping is disabled for modifications<br/>
                                        </small>
                                        <small class="mb-1">
                                            <i class="fa fa-ban mr-2"></i>The contact property is already selected and cannot be mapped multiple times<br/>
                                        </small>
                                        <small class="mb-1">
                                            <i class="fa fa-trash mr-2"></i>Delete a contact property mapping<br/>
                                        </small>
                                    </div>
                                    <i class="material-icons text-lg cursor-pointer text-blue p-0">info_outline</i>
                                </el-tooltip>
                                <!-- End tooltip -->
                            </h5>
                            <small v-if="card.settings.last_mapping_update && card.updated_by"
                                   class="p-0 m-0 block text-black-lt">
                                Last edited by: {{ getUser(card.updated_by).name }} {{ card.settings.last_mapping_update | fixFullDateUTCRelative }}
                            </small>
                            <div v-if="card.credentials.access_token"
                                 class="row form-group">
                                <div class="col-md-12">
                                    <integration-property-mapper :integration="cardname" ref="propertyMapper" />
                                </div>
                            </div>
                        </template>
                        <!-- End property mapper -->
                    </template>

                    <template v-if="cardname == 'guesty'">
                        <div class="row form-group">
                            <div class="col d-flex">
                                <div class="mr-2">
                                    <integration-settings-tester-btn
                                        :name="cardname"
                                        :card="card"
                                        @reload="fetchDetails"
                                        v-if="card.credentials.access_token !== ''"
                                    >
                                    </integration-settings-tester-btn>
                                </div>
                                <div>

                                </div>
                            </div>
                        </div>
                        <template v-if="!isStatusToggleDisabled">
                            <div class="row form-group mt-3 align-items-center" v-if="!isStatusToggleDisabled && card.credentials.access_token === ''">
                                <div v-if="!isStatusToggleDisabled"
                                     class="col">
                                    <h6 class="text-black">Integrations Token</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        You can find your Integrations token from here:
                                        <a target="_blank"
                                           class="text-bluish _600"
                                           :href="guestyTokenUrl">
                                            here
                                        </a>
                                        and integrate with {{ statics.name }}. Then copy your key and paste it here.
                                    </small>
                                </div>
                                <div class="col" v-else>
                                    <el-alert
                                        class="my-2"
                                        type="info"
                                        :closable="false"
                                        show-icon>
                                        You can only enable one CRM integration.
                                    </el-alert>
                                </div>
                            </div>
                            <div class="row form-group align-items-center" v-if="!isStatusToggleDisabled && card.credentials.access_token === ''">
                                <div class="col-6">
                                    <el-form-item prop="credentials.api_token"
                                                  class="m-0">
                                        <div class="input-group">
                                            <input type="text"
                                                   class="form-control"
                                                   v-model="card.credentials.api_token"
                                                   @input="customPreValidateForm"/>
                                        </div>
                                    </el-form-item>
                                </div>
                            </div>
                        </template>
                        <div class="row form-group align-items-center" v-else>
                            <div class="col-6">
                                <el-alert
                                    class="my-2"
                                    type="info"
                                    :closable="false"
                                    show-icon>
                                    You can only enable one CRM integration.
                                </el-alert>
                            </div>
                        </div>
                        <template v-if="card.credentials.access_token !== ''">
                            <h5 class="mt-4">Synchronization</h5>
                            <hr class="no-top-margin">
                            <div class="row form-group align-items-center">
                                <div class="col">
                                    <h6 class="text-black">Sync Contacts</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        <span class="mb-1">
                                            Use <b>push contacts</b> to see your {{ statics.name }} contacts in Guesty
                                        </span>
                                        <br>
                                        <span class="mb-1">
                                            Use <b>pull contacts</b> to see all your Guesty contacts within {{ statics.name }}
                                        </span>
                                    </small>
                                    <el-alert
                                        class="my-2"
                                        type="info"
                                        :closable="false"
                                        show-icon>
                                        <b>Note</b>: Pulling contacts will update {{ statics.name }} contacts using CRM data
                                    </el-alert>
                                    <el-alert
                                        class="my-2"
                                        type="warning"
                                        :closable="false"
                                        show-icon>
                                        <span class="d-block mb-1 text-black">
                                            Pulling contacts will cause LRN lookup charges to be applied
                                        </span>
                                    </el-alert>
                                </div>
                                <div class="col d-flex justify-content-end">
                                    <div class="d-flex flex-column mr-2">
                                        <el-button :disabled="loading_push"
                                                   plain
                                                   @click="pushContacts(cardname)">
                                            <span>Push Contacts</span>
                                            <i class="fa fa-arrow-up"
                                               v-if="!loading_push"></i>
                                            <i class="material-icons loader pull-left"
                                               v-if="loading_push">&#xE863;</i>
                                        </el-button>
                                        <div class="pt-2">
                                            <small v-if="card.settings && card.settings.last_sync_push_contact"
                                                   class="p-0 m-0 block text-black-lt text-xs">
                                                Last push contact: <b>{{ card.settings.last_sync_push_contact | fixFullDateUTCRelative }}</b>
                                            </small>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <el-button :disabled="loading_pull"
                                                   plain
                                                   @click="pullContacts(cardname)">
                                            <span>Pull Contacts</span>
                                            <i class="fa fa-arrow-down"
                                               v-if="!loading_pull"></i>
                                            <i class="material-icons loader pull-left"
                                               v-if="loading_pull">&#xE863;</i>
                                        </el-button>
                                        <div class="pt-2">
                                            <small v-if="card.settings && card.settings.last_sync_pull_contact"
                                                   class="p-0 m-0 block text-black-lt text-xs">
                                                Last pull contact: <b>{{ card.settings.last_sync_pull_contact | fixFullDateUTCRelative }}</b>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>

                    <template v-if="cardname == 'zoho'">
                        <div class="row form-group">
                            <div class="col d-flex">
                                <div class="mr-2">
                                    <integration-settings-tester-btn :name="cardname"
                                                                     :card="card"
                                                                     v-if="card.credentials.access_token !== ''"
                                                                     @reload="fetchDetails">
                                    </integration-settings-tester-btn>
                                </div>
                                <div>
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        placement="right">
                                        <div slot="content">
                                            <strong>Authentication</strong>
                                            <br>
                                            <small>
                                                This will redirect you to Zoho for authentication.
                                            </small>
                                        </div>
                                        <el-button v-if="card.credentials.access_token !== ''"
                                                   plain
                                                   @click="oauth(cardname)">
                                            <span>Re-Authenticate</span>
                                            <i class="fa fa-sign-in mr-2"></i>
                                        </el-button>
                                        <el-button v-else-if="!isStatusToggleDisabled"
                                                   plain
                                                   @click="oauth(cardname)">
                                            <span>Authenticate</span>
                                            <i class="fa fa-sign-in mr-2"></i>
                                        </el-button>
                                    </el-tooltip>
                                    <el-alert
                                        v-if="isStatusToggleDisabled"
                                        class="my-2"
                                        type="info"
                                        :closable="false"
                                        show-icon>
                                        You can only enable one CRM integration.
                                    </el-alert>
                                </div>
                            </div>
                        </div>

                        <template v-if="card.credentials.access_token !== ''">
                            <h5 class="mt-4">Synchronization</h5>
                            <hr class="no-top-margin">
                            <div class="row form-group align-items-center">
                                <div class="col">
                                    <h6 class="text-black">Sync Users</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        Connect your Zoho users to {{ statics.name }} users.
                                    </small>
                                </div>
                                <div class="col d-flex justify-content-end">
                                    <div class="d-flex flex-column">
                                        <el-button :disabled="loading_pull_users"
                                                   plain
                                                   @click="pullUsers(cardname)">
                                            <span>Connect Zoho users to {{ statics.name }}</span>
                                            <i class="material-icons loader pull-left"
                                               v-if="loading_pull_users">&#xE863;</i>
                                        </el-button>
                                        <div class="pt-2">
                                            <small v-if="card.settings && card.settings.last_sync_user"
                                                   class="p-0 m-0 block text-black-lt text-xs">
                                                <small>Last user sync: <b>{{ card.settings.last_sync_user | fixFullDateUTCRelative }}</b></small>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row form-group mt-3 align-items-center">
                                <div class="col">
                                    <h6 class="text-black">Pull Lead Statuses</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        Pulls your Zoho lead statuses to {{ statics.name }} as disposition status
                                    </small>
                                </div>
                                <div class="col d-flex justify-content-end">
                                    <div class="d-flex flex-column width-300 fixed">
                                        <el-button :disabled="loading_pull_lead_status"
                                                   plain
                                                   @click="pullLeadStatus(cardname)">
                                            <span>Pull Lead Statuses</span>
                                            <i class="material-icons loader pull-left"
                                               v-if="loading_pull_lead_status">&#xE863;</i>
                                        </el-button>
                                        <div class="pt-2">
                                            <small v-if="card.settings && card.settings.last_sync_disposition_status"
                                                   class="p-0 m-0 block text-black-lt text-xs">
                                                Last disposition status sync: <b>{{ card.settings.last_sync_disposition_status | fixFullDateUTCRelative }}</b>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row form-group mt-3 align-items-center">
                                <div class="col">
                                    <h6 class="text-black">Default Zoho CRM Module</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        {{ statics.name }} will integrate with the module that you select here.
                                        All new contacts created in {{ statics.name }} will be added to this module type in Zoho.
                                    </small>
                                </div>
                                <div class="col">
                                    <el-form-item prop="settings.module"
                                                  class="m-0">
                                        <div class="input-group">
                                            <el-select v-model="card.settings.module"
                                                       placeholder="Select Module"
                                                       @change="submit(cardname, false)"
                                                       class="w-100">
                                                <el-option
                                                    v-for="item in zohoModuleOptions"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </div>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="row form-group mt-3 align-items-center">
                                <div class="col">
                                    <h6 class="text-black">Sync Contacts</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        <span class="mb-1">
                                            Use <b>push contacts</b> to see your {{ statics.name }} contacts in Zoho
                                        </span>
                                        <br>
                                        <span class="mb-1">
                                            Use <b>pull contacts</b> to see all your Zoho contacts within {{ statics.name }}
                                        </span>
                                    </small>
                                    <el-alert
                                        class="my-2"
                                        type="info"
                                        :closable="false"
                                        show-icon>
                                        <b>Note</b>: Pulling contacts will update {{ statics.name }} contacts using CRM data
                                    </el-alert>
                                    <el-alert
                                        class="my-2"
                                        type="warning"
                                        :closable="false"
                                        show-icon>
                                        <span class="d-block mb-1 text-black">
                                            Pulling contacts will cause LRN lookup charges to be applied
                                        </span>
                                    </el-alert>
                                </div>
                                <div class="col d-flex justify-content-end">
                                    <div class="d-flex flex-column mr-2">
                                        <el-button :disabled="loading_push"
                                                   plain
                                                   @click="pushContacts(cardname)">
                                            <span>Push Contacts</span>
                                            <i class="fa fa-arrow-up"
                                               v-if="!loading_push"></i>
                                            <i class="material-icons loader pull-left"
                                               v-if="loading_push">&#xE863;</i>
                                        </el-button>
                                        <div class="pt-2">
                                            <small v-if="card.settings && card.settings.last_sync_push_contact"
                                                   class="p-0 m-0 block text-black-lt text-xs">
                                                Last push contact: <b>{{ card.settings.last_sync_push_contact | fixFullDateUTCRelative }}</b>
                                            </small>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <el-button :disabled="loading_pull"
                                                   plain
                                                   @click="pullContacts(cardname)">
                                            <span>Pull Contacts</span>
                                            <i class="fa fa-arrow-down"
                                               v-if="!loading_pull"></i>
                                            <i class="material-icons loader pull-left"
                                               v-if="loading_pull">&#xE863;</i>
                                        </el-button>
                                        <div class="pt-2">
                                            <small v-if="card.settings && card.settings.last_sync_pull_contact"
                                                   class="p-0 m-0 block text-black-lt text-xs">
                                                Last pull contact: <b>{{ card.settings.last_sync_pull_contact | fixFullDateUTCRelative }}</b>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>

                    <template v-if="cardname == 'pipedrive'">
                        <div class="row form-group">
                            <div class="col d-flex">
                                <div class="mr-2">
                                    <integration-settings-tester-btn :name="cardname"
                                                                     :card="card"
                                                                     v-if="card.credentials.access_token !== ''"
                                                                     @reload="fetchDetails">
                                    </integration-settings-tester-btn>
                                </div>
                                <div>
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        placement="right">
                                        <div slot="content">
                                            <strong>Authentication</strong>
                                            <br>
                                            <small>
                                                This will redirect you to Pipedrive for authentication.
                                            </small>
                                        </div>
                                        <el-button v-if="card.credentials.access_token !== ''"
                                                   plain
                                                   @click="oauth(cardname)">
                                            <span>Re-Authenticate</span>
                                            <i class="fa fa-sign-in mr-2"></i>
                                        </el-button>
                                        <el-button v-else-if="!isStatusToggleDisabled"
                                                   plain
                                                   @click="oauth(cardname)">
                                            <span>Authenticate</span>
                                            <i class="fa fa-sign-in mr-2"></i>
                                        </el-button>
                                    </el-tooltip>
                                    <el-alert
                                        v-if="isStatusToggleDisabled"
                                        class="my-2"
                                        type="info"
                                        :closable="false"
                                        show-icon>
                                        You can only enable one CRM integration.
                                    </el-alert>
                                </div>
                            </div>
                        </div>

                        <template v-if="card.credentials.access_token !== ''">
                            <h5 class="mt-4">Synchronization</h5>
                            <hr class="no-top-margin">
                            <div class="row form-group align-items-center">
                                <div class="col">
                                    <h6 class="text-black">Sync Contacts</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        <span class="mb-1">
                                            Use <b>push contacts</b> to see your {{ statics.name }} contacts in Pipedrive
                                        </span>
                                        <br>
                                        <span class="mb-1">
                                            Use <b>pull contacts</b> to see all your Pipedrive contacts within {{ statics.name }}
                                        </span>
                                        <br>
                                        <span class="mb-1">
                                            Pull operation will update {{ statics.name }} for lead owners
                                        </span>
                                    </small>
                                    <el-alert
                                        class="my-2"
                                        type="info"
                                        :closable="false"
                                        show-icon>
                                        <b>Note</b>: Pulling contacts will update {{ statics.name }} contacts using CRM data
                                    </el-alert>
                                    <el-alert
                                        class="my-2"
                                        type="warning"
                                        :closable="false"
                                        show-icon>
                                        <span class="d-block mb-1 text-black">
                                            Pulling contacts will cause LRN lookup charges to be applied
                                        </span>
                                    </el-alert>
                                </div>
                                <div class="col d-flex justify-content-end">
                                    <div class="d-flex flex-column mr-2">
                                        <el-button :disabled="loading_push"
                                                   plain
                                                   @click="pushContacts(cardname)">
                                            <span>Push Contacts</span>
                                            <i class="fa fa-arrow-up"
                                               v-if="!loading_push"></i>
                                            <i class="material-icons loader pull-left"
                                               v-if="loading_push">&#xE863;</i>
                                        </el-button>
                                        <div class="pt-2">
                                            <small v-if="card.settings && card.settings.last_sync_push_contact"
                                                   class="p-0 m-0 block text-black-lt text-xs">
                                                Last push contact: <b>{{ card.settings.last_sync_push_contact | fixFullDateUTCRelative }}</b>
                                            </small>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <el-button :disabled="loading_pull"
                                                   plain
                                                   @click="pullContacts(cardname)">
                                            <span>Pull Contacts</span>
                                            <i class="fa fa-arrow-down"
                                               v-if="!loading_pull"></i>
                                            <i class="material-icons loader pull-left"
                                               v-if="loading_pull">&#xE863;</i>
                                        </el-button>
                                        <div class="pt-2">
                                            <small v-if="card.settings && card.settings.last_sync_pull_contact"
                                                   class="p-0 m-0 block text-black-lt text-xs">
                                                Last pull contact: <b>{{ card.settings.last_sync_pull_contact | fixFullDateUTCRelative }}</b>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group mt-3 align-items-center mt-3">
                                <div class="col">
                                    <h6 class="text-black">Sync Users</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        Connect your Pipedrive users to {{ statics.name }} users.
                                    </small>
                                </div>
                                <div class="col d-flex justify-content-end">
                                    <div class="d-flex flex-column">
                                        <el-button :disabled="loading_pull_users"
                                                   plain
                                                   @click="pullUsers(cardname)">
                                            <span>Connect Pipedrive users to {{ statics.name }}</span>
                                            <i class="material-icons loader pull-left"
                                               v-if="loading_pull_users">&#xE863;</i>
                                        </el-button>
                                        <div class="pt-2">
                                            <small v-if="card.settings && card.settings.last_sync_user"
                                                   class="p-0 m-0 block text-black-lt text-xs">
                                                Last user sync: <b>{{ card.settings.last_sync_user | fixFullDateUTCRelative }}</b>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group mt-3 align-items-center">
                                <div class="col">
                                    <h6 class="text-black">Pull Deal Stages</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        Pulls your Pipedrive deal stages to {{ statics.name }} as disposition status
                                    </small>
                                </div>
                                <div class="col d-flex justify-content-end">
                                    <div class="d-flex flex-column width-300 fixed">
                                        <el-button :disabled="loading_pull_lead_status"
                                                   plain
                                                   @click="pullLeadStatus(cardname)">
                                            <span>Pull deal stages from Pipedrive</span>
                                            <i class="material-icons loader pull-left"
                                               v-if="loading_pull_lead_status">&#xE863;</i>
                                        </el-button>
                                        <div class="pt-2">
                                            <small v-if="card.settings && card.settings.last_sync_disposition_status"
                                                   class="p-0 m-0 block text-black-lt text-xs">
                                                Last disposition status sync: <b>{{ card.settings.last_sync_disposition_status | fixFullDateUTCRelative }}</b>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>

                    <template v-if="cardname == 'gohighlevel'">
                        <div class="row form-group">
                            <div class="col d-flex">
                                <div class="mr-2">
                                    <integration-settings-tester-btn :name="cardname"
                                                                     :card="card"
                                                                     v-if="card.credentials.access_token !== ''"
                                                                     @reload="fetchDetails">
                                    </integration-settings-tester-btn>
                                </div>
                                <div>
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        placement="right">
                                        <div slot="content">
                                            <strong>Authentication</strong>
                                            <br>
                                            <small>
                                                This will redirect you to HighLevel for authentication. After authentication the GHL contacts will be synced with your Aloware contacts
                                            </small>
                                        </div>
                                        <el-button v-if="card.credentials.access_token !== ''"
                                                   plain
                                                   @click="oauth(cardname)">
                                            <span>Re-Authenticate</span>
                                            <i class="fa fa-sign-in mr-2"></i>
                                        </el-button>
                                        <el-button v-else-if="!isStatusToggleDisabled"
                                                   plain
                                                   @click="oauth(cardname)">
                                            <span>Authenticate</span>
                                            <i class="fa fa-sign-in mr-2"></i>
                                        </el-button>
                                    </el-tooltip>
                                    <el-alert
                                        v-if="isStatusToggleDisabled"
                                        class="my-2"
                                        type="info"
                                        :closable="false"
                                        show-icon>
                                        You can only enable one CRM integration.
                                    </el-alert>
                                </div>
                            </div>
                        </div>

                        <template v-if="card.credentials.access_token !== ''">
                            <h5 class="mt-4">Synchronization</h5>
                            <hr class="no-top-margin">
                            <div class="row form-group align-items-center">
                                <div class="col">
                                    <h6 class="text-black">Sync Contacts</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        <span class="mb-1">
                                            Use <b>push contacts</b> to see your {{ statics.name }} contacts in HighLevel
                                        </span>
                                        <br/>
                                        <span class="mb-1">
                                            Use <b>pull contacts</b> to see all your HighLevel contacts within {{ statics.name }}
                                        </span>
                                    </small>
                                    <el-alert
                                        class="my-2"
                                        type="info"
                                        :closable="false"
                                        show-icon>
                                        <b>Note</b>: Pulling contacts will update {{ statics.name }} contacts using CRM data
                                    </el-alert>
                                    <el-alert
                                        class="my-2"
                                        type="warning"
                                        :closable="false"
                                        show-icon>
                                        <span class="d-block mb-1 text-black">
                                            Pulling contacts will cause LRN lookup charges to be applied
                                        </span>
                                    </el-alert>
                                </div>
                                <div class="col d-flex justify-content-end">
                                    <div class="d-flex flex-column mr-2">
                                        <el-button plain
                                                   :disabled="loading_push"
                                                   @click="pushContacts(cardname)">
                                            <span>Push Contacts</span>
                                            <i class="fa fa-arrow-up"
                                               v-if="!loading_push"></i>
                                            <i class="material-icons loader pull-left"
                                               v-if="loading_push">&#xE863;</i>
                                        </el-button>
                                        <div class="pt-2">
                                            <small class="p-0 m-0 block text-black-lt text-xs"
                                                   v-if="card.settings && card.settings.last_sync_push_contact">
                                                Last push contact: <b>{{ card.settings.last_sync_push_contact | fixFullDateUTCRelative }}</b>
                                            </small>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <el-button :disabled="loading_pull"
                                                   plain
                                                   @click="pullContacts(cardname)">
                                            <span>Pull Contacts</span>
                                            <i class="fa fa-arrow-down"
                                               v-if="!loading_pull"></i>
                                            <i class="material-icons loader pull-left"
                                               v-if="loading_pull">&#xE863;</i>
                                        </el-button>
                                        <div class="pt-2">
                                            <small v-if="card.settings && card.settings.last_sync_contact"
                                                   class="p-0 m-0 block text-black-lt text-xs">
                                                Last pull contact: <b>{{ card.settings.last_sync_contact | fixFullDateUTCRelative }}</b>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group mt-3 align-items-center">
                                <div class="col">
                                    <h6 class="text-black">Sync Users</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        Connect your HighLevel users to {{ statics.name }} users.
                                        <br>
                                        Only current users in {{ statics.name }} are synced, and no new users will be created.
                                    </small>
                                    <el-alert
                                        class="my-2"
                                        type="info"
                                        show-icon
                                        :closable="false">
                                        Heads up! Users are synced by email, please make sure your agents have the same email address on {{ statics.name }} and HighLevel.
                                    </el-alert>
                                </div>
                                <div class="col d-flex justify-content-end">
                                    <div class="d-flex flex-column">
                                        <el-tooltip class="item"
                                                    effect="dark"
                                                    placement="top"
                                                    :disabled="!! card.credentials.company_id">
                                            <div slot="content">
                                                You need to re-authenticate the integration before you can start using this feature.
                                            </div>
                                            <el-button class="pull-right"
                                                       plain
                                                       :disabled="loading_pull_users || !card.credentials.company_id"
                                                       @click="pullUsers(cardname)">
                                                <span>Sync Users</span>
                                                <i class="material-icons loader pull-left"
                                                   v-if="loading_pull_users">&#xE863;</i>
                                            </el-button>
                                        </el-tooltip>
                                        <div class="pt-2">
                                            <small class="p-0 m-0 block text-black-lt text-xs"
                                                   v-if="card.settings && card.settings.last_sync_user">
                                                Last user sync: <b>{{ card.settings.last_sync_user | fixFullDateUTCRelative }}</b>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h6 class="mt-4 text-black">Aloware Line to send from <i class="text-danger">*</i></h6>
                            <el-tooltip class="item"
                                        effect="dark"
                                        placement="top"
                                        :disabled="!! card.credentials.scopes && !! card.credentials.scopes.includes('conversations/message.write')">
                                <div slot="content">
                                    You need to re-authenticate the integration before you can start using this feature.
                                </div>
                                <el-select class="text-black"
                                           placeholder="Select Line"
                                           filterable
                                           v-model="card.settings.filters.sms_campaign"
                                           :disabled="! card.credentials.scopes || ! card.credentials.scopes.includes('conversations/message.write')"
                                >
                                    <el-option
                                        v-for="item in listSmsCampaigns"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-tooltip>
                        </template>
                    </template>

                  <template v-if="cardname == 'salesforce'">
                    <div class="row form-group">
                      <div class="col d-flex">
                        <div class="mr-2">
                          <integration-settings-tester-btn :name="cardname"
                                                           :card="card"
                                                           v-if="card.credentials.access_token !== ''"
                                                           @reload="fetchDetails">
                          </integration-settings-tester-btn>
                        </div>
                        <div>
                          <el-tooltip
                              class="item"
                              effect="dark"
                              placement="right">
                            <div slot="content">
                              <strong>Authentication</strong>
                              <br>
                              <small>
                                This will redirect you to Salesforce for authentication.
                              </small>
                            </div>
                            <el-button v-if="card.credentials.access_token !== ''"
                                       plain
                                       @click="openPrismaticConfig()">
                              <span>Re-Authenticate</span>
                              <i class="fa fa-sign-in mr-2"></i>
                            </el-button>
                            <el-button v-else-if="!isStatusToggleDisabled"
                                       plain
                                       @click="openPrismaticConfig()">
                              <span>Authenticate</span>
                              <i class="fa fa-sign-in mr-2"></i>
                            </el-button>
                          </el-tooltip>
                          <el-alert
                              v-if="isStatusToggleDisabled"
                              class="my-2"
                              type="info"
                              :closable="false"
                              show-icon>
                            You can only enable one CRM integration.
                          </el-alert>
                        </div>
                      </div>
                    </div>
                    <template v-if="card.credentials.access_token !== ''">
                      <h5 class="mt-4">Synchronization</h5>
                      <hr class="no-top-margin">
                      <div class="row form-group align-items-center">
                        <div class="col">
                          <h6 class="text-black">Sync Contacts</h6>
                          <small class="p-0 m-0 block text-black-lt">
                            <span class="mb-1">
                                Use <b>pull contacts</b> to see all your Salesforce contacts within {{ statics.name }}
                            </span>
                          </small>
                          <el-alert
                              class="my-2"
                              type="info"
                              :closable="false"
                              show-icon>
                            <b>Note</b>: Pulling contacts will update {{ statics.name }} contacts using CRM data
                          </el-alert>
                          <el-alert
                              class="my-2"
                              type="warning"
                              :closable="false"
                              show-icon>
                                        <span class="d-block mb-1 text-black">
                                            Pulling contacts will cause LRN lookup charges to be applied
                                        </span>
                          </el-alert>
                        </div>
                        <div class="col d-flex justify-content-end">
                          <div class="d-flex flex-column">
                            <el-button :disabled="loading_pull
                                       || [585, 1000].includes(current_company.id)
                                       || (card.settings?.contact_settings && !card.settings?.contact_settings?.enable_contact)"
                                       plain
                                       @click="pullContacts(cardname)">
                              <span>Pull Contacts</span>
                              <i class="fa fa-arrow-down"
                                 v-if="!loading_pull"></i>
                              <i class="material-icons loader pull-left"
                                 v-if="loading_pull">&#xE863;</i>
                            </el-button>
                            <div class="pt-2">
                              <small v-if="card.settings && card.settings.last_sync_contact"
                                     class="p-0 m-0 block text-black-lt text-xs">
                                Last pull contact: <b>{{ card.settings.last_sync_contact | fixFullDateUTCRelative }}</b>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row form-group mt-3 align-items-center">
                        <div class="col">
                          <h6 class="text-black">Pull Salesforce Leads</h6>
                          <small class="p-0 m-0 block text-black-lt">
                            Pulls your Salesforce leads as contacts
                          </small>
                        </div>
                        <div class="col d-flex justify-content-end">
                          <div class="d-flex flex-column width-300 fixed">
                            <el-button
                                :disabled="loading_salesforce_pull_leads
                                  || (card.settings?.contact_settings && !card.settings?.contact_settings?.enable_lead)"
                                @click="pullSalesforceLeads()"
                                plain
                                class="pull-right">
                              <span>Pull Salesforce Leads</span>
                              <i v-if="!loading_salesforce_pull_leads"
                                 class="fa fa-arrow-down"></i>
                              <i v-if="loading_salesforce_pull_leads"
                                 class="material-icons loader pull-left">&#xE863;</i>
                            </el-button>
                            <div class="pt-2">
                              <small v-if="card.settings && card.settings.last_sync_lead"
                                     class="p-0 m-0 block text-black-lt text-xs">
                                Last pull leads: <b>{{ card.settings.last_sync_lead | fixFullDateUTCRelative }}</b>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row form-group mt-3 align-items-center">
                        <div class="col">
                          <h6 class="text-black">Sync Users</h6>
                          <small class="p-0 m-0 block text-black-lt">
                            Connect your Salesforce users to {{ statics.name }} users.
                            <br>
                            Only current users in {{ statics.name }} are synced, and no new users will be created.
                          </small>
                          <el-alert
                              class="my-2"
                              type="info"
                              :closable="false"
                              show-icon>
                            Heads up! Users are synced by email, please make sure your agents have the same email address on {{ statics.name }} and Salesforce.
                          </el-alert>
                        </div>
                        <div class="col d-flex justify-content-end">
                          <div class="d-flex flex-column">
                            <el-button
                                :disabled="loading_pull_users"
                                @click="pullUsers(cardname)"
                                plain
                                class="pull-right">
                              <span>Sync Users</span>
                              <i class="material-icons loader pull-left"
                                 v-if="loading_pull_users">&#xE863;</i>
                            </el-button>
                            <div class="pt-2">
                              <small class="p-0 m-0 block text-black-lt text-xs"
                                     v-if="card.settings && card.settings.last_sync_user">
                                Last user sync: <b>{{ card.settings.last_sync_user | fixFullDateUTCRelative }}</b>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row form-group">
                        <div class="col-md-12">
                          <salesforce-contact-settings
                              ref="salesforceContactSettings"
                              :contact_settings="card.settings.contact_settings"
                          />
                        </div>
                      </div>
                    </template>


                    <!-- Start Property Mapper -->
                    <template v-if="card.credentials.access_token !== '' && (card.settings?.contact_settings?.enable_lead || card.settings?.contact_settings?.enable_contact)">
                      <h5 class="my-2">Contact Property Mapping
                        <!-- Start tooltip -->
                        <el-tooltip class="item" effect="dark" placement="right">
                          <div slot="content">
                            <small class="font-weight-bold mb-1">
                              Map Salesforce Contact Properties to Aloware's default Fields.
                            </small>
                            <br/><br/>
                            <small class="font-weight-bold">Legends</small><br/>
                            <small class="mb-1">
                              <i class="fa fa-lock mr-2"></i>The contact property mapping is disabled for modifications<br/>
                            </small>
                            <small class="mb-1">
                              <i class="fa fa-ban mr-2"></i>The contact property is already selected and cannot be mapped multiple times<br/>
                            </small>
                            <small class="mb-1">
                              <i class="fa fa-trash mr-2"></i>Delete a contact property mapping<br/>
                            </small>
                          </div>
                          <i class="material-icons text-lg cursor-pointer text-blue p-0">info_outline</i>
                        </el-tooltip>
                        <!-- End tooltip -->
                      </h5>
                      <small v-if="card.settings.last_mapping_update && card.updated_by"
                             class="p-0 m-0 block text-black-lt">
                        Last edited by: {{ getUser(card.updated_by).name }} {{ card.settings.last_mapping_update | fixFullDateUTCRelative }}
                      </small>
                      <div v-if="card.credentials.access_token !== ''"
                           class="row form-group">
                        <div class="col-md-12">
                          <integration-property-mapper :integration="cardname" ref="propertyMapper" />
                        </div>
                      </div>
                    </template>
                    <!-- End property mapper -->

                    <template v-if="card.credentials.access_token !== ''">
                        <div class="salesforce-activity-settings mt-4">
                            <h5 class="text-black">Activity Settings</h5>
                            <table class="table subject-settings-table">
                                <thead>
                                    <tr>
                                        <td colspan="2" class="text-center">
                                            <div class="section-header">
                                                <strong>Subject Format</strong>
                                                <el-tooltip class="item" effect="dark" placement="right">
                                                    <div slot="content">
                                                        When a task is created, the subject line will be dynamically generated using the selected fields.
                                                    </div>
                                                    <i class="material-icons text-lg cursor-pointer text-blue p-0">info_outline</i>
                                                </el-tooltip>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="text-center">Call</th>
                                        <th class="text-center">SMS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <el-checkbox-group class="vertical-checkboxes" v-model="card.settings.activities_settings.call">
                                                <el-checkbox v-for="field in callSubjectFields" :key="field.value" :label="field.value">
                                                    {{ field.label }}
                                                    <el-tooltip class="item" effect="dark" placement="top">
                                                        <div slot="content">{{ field.description || '' }}</div>
                                                        <i class="material-icons text-lg cursor-pointer text-blue p-0">info_outline</i>
                                                    </el-tooltip>
                                                </el-checkbox>
                                            </el-checkbox-group>
                                        </td>
                                        <td>
                                            <el-checkbox-group class="vertical-checkboxes" v-model="card.settings.activities_settings.sms">
                                                <el-checkbox v-for="field in smsSubjectFields" :key="field.value" :label="field.value">
                                                    {{ field.label }}
                                                    <el-tooltip class="item" effect="dark" placement="top">
                                                        <div slot="content">{{ field.description || '' }}</div>
                                                        <i class="material-icons text-lg cursor-pointer text-blue p-0">info_outline</i>
                                                    </el-tooltip>
                                                </el-checkbox>
                                            </el-checkbox-group>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table description-settings-table">
                                <thead>
                                    <tr>
                                        <td colspan="2" class="text-center">
                                            <div class="section-header">
                                                <strong>Description Settings</strong>
                                                <el-tooltip class="item" effect="dark" placement="right">
                                                    <div slot="content">
                                                        When a task is created, the description will be dynamically generated using the selected fields.
                                                    </div>
                                                    <i class="material-icons text-lg cursor-pointer text-blue p-0">info_outline</i>
                                                </el-tooltip>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="text-center">Call</th>
                                        <th class="text-center">SMS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <el-checkbox-group class="vertical-checkboxes" v-model="card.settings.custom_activity_description_sections.call">
                                                <el-checkbox v-for="field in callDescriptionFields" :key="field.value" :label="field.value">
                                                    {{ field.label }}
                                                    <el-tooltip class="item" effect="dark" placement="top">
                                                        <div slot="content">{{ field.description || '' }}</div>
                                                        <i class="material-icons text-lg cursor-pointer text-blue p-0">info_outline</i>
                                                    </el-tooltip>
                                                </el-checkbox>
                                            </el-checkbox-group>
                                        </td>
                                        <td>
                                            <el-checkbox-group class="vertical-checkboxes" v-model="card.settings.custom_activity_description_sections.sms">
                                                <el-checkbox v-for="field in smsDescriptionFields" :key="field.value" :label="field.value">
                                                    {{ field.label }}
                                                    <el-tooltip class="item" effect="dark" placement="top">
                                                        <div slot="content">{{ field.description || '' }}</div>
                                                        <i class="material-icons text-lg cursor-pointer text-blue p-0">info_outline</i>
                                                    </el-tooltip>
                                                </el-checkbox>
                                            </el-checkbox-group>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                  </template>

                    <template v-if="cardname == 'facebook'">
                        <div class="row form-group">
                            <div class="col d-flex">
                                <div class="mr-2">
                                    <integration-settings-tester-btn :name="cardname"
                                                                     :card="card"
                                                                     v-if="card.credentials.access_token !== ''"
                                                                     @reload="fetchDetails">
                                    </integration-settings-tester-btn>
                                </div>
                                <div>
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        placement="right">
                                        <div slot="content">
                                            <strong>Authentication</strong>
                                            <br>
                                            <small>
                                                This will redirect you to Facebook for authentication.
                                            </small>
                                        </div>
                                        <el-button v-if="card.credentials.access_token !== ''"
                                                   plain
                                                   @click="oauth(cardname)">
                                            <span>Re-Authenticate</span>
                                            <i class="fa fa-sign-in mr-2"></i>
                                        </el-button>
                                        <el-button v-else
                                                   plain
                                                   @click="oauth(cardname)">
                                            <span>Authenticate</span>
                                            <i class="fa fa-sign-in mr-2"></i>
                                        </el-button>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>

                        <div v-if="card.credentials.access_token !== '' && card.status === true && current_company.facebook_integration_enabled"
                             class="row form-group mt-3 align-items-center">
                            <div class="col">
                                <h6 class="text-black">Facebook Page</h6>
                                <small class="p-0 m-0 block text-black-lt">
                                    <span v-if="!facebook_pages.length && !loading_pages">
                                        Please create a page on facebook and select it here
                                    </span>
                                    <span v-else>
                                        Select the facebook page that you want to receive leads from.
                                    </span>
                                </small>
                            </div>
                            <div class="col">
                                <el-form-item prop="settings.page_id"
                                              class="m-0">
                                    <el-select v-model="card.settings.page_id"
                                               :value="card.settings.page_id"
                                               :loading="loading_pages"
                                               :disabled="loading_pages || !facebook_pages.length"
                                               filterable
                                               class="w-100"
                                               @change="setFacebookPage">
                                        <el-option
                                            v-for="page in facebook_pages"
                                            :key="page.id"
                                            :label="page.name"
                                            :value="page.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </div>

                        <template v-if="card.credentials.access_token !== '' && card.status === true && card.settings.page_id && card.settings.page">
                            <h5 class="mt-4">Synchronization</h5>
                            <hr class="no-top-margin">
                            <div class="row form-group align-items-center">
                                <div class="col">
                                    <h6 class="text-black">Sequence</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        <b>Auto Enroll (Optional)</b>
                                        <br>
                                        Please select the sequence that you want to enroll the leads to
                                    </small>
                                </div>
                                <div class="col">
                                    <el-form-item prop="settings.workflow_id"
                                                  class="m-0">
                                        <workflow-selector v-model="card.settings.workflow_id"
                                                           style="width: 100%"
                                                           @change="setWorkflow">
                                        </workflow-selector>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="row form-group mt-3 align-items-center">
                                <div class="col">
                                    <h6 class="text-black">Leadgen Forms</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        You can pull your leads from your existing leadgen forms in Facebook
                                    </small>
                                </div>
                                <div class="col d-flex justify-content-end">
                                    <el-form-item class="inline mr-2">
                                        <el-select v-model="leadgen_form_id"
                                                   :loading="loading_leadgen_forms"
                                                   :disabled="loading_leadgen_forms || !leadgen_forms.length"
                                                   filterable>
                                            <el-option
                                                v-for="leadgen_form in leadgen_forms"
                                                :key="leadgen_form.id"
                                                :label="leadgen_form.name"
                                                :value="leadgen_form.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-button :disabled="!leadgen_form_id || loading_pull_leadgen_leads"
                                               plain
                                               @click="pullFacebookLeads">
                                        <span>Pull Leads</span>
                                        <i class="fa fa-arrow-down"
                                           v-if="!loading_pull"></i>
                                        <i class="material-icons loader pull-left"
                                           v-if="loading_pull">&#xE863;</i>
                                    </el-button>
                                </div>
                            </div>
                        </template>
                    </template>

                    <template v-if="cardname == 'slack'">
                        <div class="row form-group">
                            <div class="col">
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    placement="right">
                                    <div slot="content">
                                        <strong>Authentication</strong>
                                        <br>
                                        <small>
                                            This will redirect you to Slack for authentication.
                                        </small>
                                    </div>
                                    <el-button v-if="card.credentials.api_token !== ''"
                                               plain
                                               @click="oauth(cardname)">
                                        <span>Re-Authenticate</span>
                                        <i class="fa fa-sign-in mr-2"></i>
                                    </el-button>
                                    <el-button v-else
                                               plain
                                               @click="oauth(cardname)">
                                        <span>Authenticate</span>
                                        <i class="fa fa-sign-in mr-2"></i>
                                    </el-button>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>

                    <template
                        v-if="cardname == 'sendgrid' && !hasOtherEmailIntegrationEnabled(cardname)">
                        <div class="row form-group align-items-center">
                            <div class="col">
                                <h6 class="text-black">API key</h6>
                                <small class="p-0 m-0 block text-black-lt">
                                    Your Sendgrid API key.
                                    You can find your API keys from here:
                                    <a href="https://app.sendgrid.com/settings/api_keys"
                                       target="_blank"
                                       class="text-bluish _600">
                                        here
                                    </a>
                                </small>
                            </div>
                        </div>
                        <div class="row form-group align-items-center">
                            <div class="col-6">
                                <el-form-item prop="credentials.api_token"
                                              class="m-0">
                                    <div class="input-group">
                                        <input type="text"
                                               class="form-control"
                                               placeholder="Put your API key here"
                                               v-model="card.credentials.api_token"
                                               @input="customPreValidateForm"/>
                                    </div>
                                </el-form-item>
                                <div class="pt-3">
                                    <small class="p-0 m-0 block text-black-lt">
                                        <span :class="testingApiKeyElementsAttrs.color">
                                            {{ testingApiKeyElementsAttrs.TestStatus }}
                                            <i :class="testingApiKeyElementsAttrs.icon"></i>
                                        </span>
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div class="row form-group align-items-center">
                            <div class="col">
                                <h6 class="text-black">Sender address</h6>
                                <small class="p-0 m-0 block text-black-lt">
                                    The sender email address that {{ statics.name }} should send email from it.
                                </small>
                            </div>
                        </div>
                        <div class="row form-group align-items-center">
                            <div class="col-6">
                                <el-form-item prop="settings.sender_address"
                                              class="m-0">
                                    <div class="input-group">
                                        <input type="text"
                                               class="form-control"
                                               placeholder="Put your sender address here"
                                               v-model="card.settings.sender_address"
                                               @input="customPreValidateForm"/>
                                    </div>
                                </el-form-item>
                                <div class="pt-3">
                                    <small class="p-0 m-0 block text-black-lt">
                                        <span :class="testingApiKeyElementsAttrs.color">
                                            {{ testingApiKeyElementsAttrs.TestStatus }}
                                            <i :class="testingApiKeyElementsAttrs.icon"></i>
                                        </span>
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div class="row form-group align-items-center">
                            <div class="col">
                                <h6 class="text-black">Sender name</h6>
                                <small class="p-0 m-0 block text-black-lt">
                                    The sender name that {{ statics.name }} should send email from it.
                                </small>
                            </div>
                        </div>
                        <div class="row form-group align-items-center">
                            <div class="col-6">
                                <el-form-item prop="settings.sender_name"
                                              class="m-0">
                                    <div class="input-group">
                                        <input type="text"
                                               class="form-control"
                                               placeholder="Put your sender name here"
                                               v-model="card.settings.sender_name"
                                               @input="customPreValidateForm"/>
                                    </div>
                                </el-form-item>
                                <div class="pt-3">
                                    <small class="p-0 m-0 block text-black-lt">
                                        <span :class="testingApiKeyElementsAttrs.color">
                                            {{ testingApiKeyElementsAttrs.TestStatus }}
                                            <i :class="testingApiKeyElementsAttrs.icon"></i>
                                        </span>
                                    </small>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-if="cardname == 'mailgun' && !hasOtherEmailIntegrationEnabled(cardname)">
                        <template v-if="api_key_input">
                            <div class="row form-group align-items-center">
                                <div class="col">
                                    <h6 class="text-black">API key</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        Your Mailgun Private API key.
                                        You can find your API keys from:
                                        <a href="https://app.mailgun.com/app/account/security/api_keys"
                                           target="_blank"
                                           class="text-bluish _600">
                                            here
                                        </a>
                                    </small>
                                </div>
                            </div>
                            <div class="row form-group align-items-center">
                                <div class="col-6">
                                    <el-form-item prop="credentials.api_token"
                                                  class="m-0">
                                        <div class="input-group">
                                            <input type="text"
                                                   class="form-control"
                                                   placeholder="Put your API key here"
                                                   v-model="card.credentials.api_token"
                                                   @input="customPreValidateForm"/>
                                        </div>
                                    </el-form-item>
                                    <div class="pt-3">
                                        <small class="p-0 m-0 block text-black-lt">
                                            <span v-if="card.credentials.api_token"
                                                  :class="testingApiKeyElementsAttrs.color">
                                                {{ testingApiKeyElementsAttrs.TestStatus }}
                                                <i :class="testingApiKeyElementsAttrs.icon"></i>
                                            </span>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template
                            v-if="apiTokenTestStatus === WebhookTestStatuses.SUCCESS && api_key_input && card.status">
                            <div class="row form-group mt-3 align-items-center">
                                <div class="col">
                                    <h6 class="text-black">Mailgun Domain</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        This is the domain you will use to send email using your mailgun account.
                                    </small>
                                </div>
                            </div>
                            <div class="row form-group align-items-center">
                                <div class="col-6">
                                    <el-form-item prop="settings.domain"
                                                  class="m-0">
                                        <div class="input-group">
                                            <el-select placeholder="Select Domain"
                                                       :clearable="true"
                                                       :disabled="!mailgun_domains.length"
                                                       v-model="card.settings.domain"
                                                       @change="customPreValidateForm">
                                                <el-option v-for="opt in mailgun_domains"
                                                           :key="opt.id"
                                                           :label="opt.name"
                                                           :value="opt.name">
                                                    <span class="ml-2">{{ opt.name }}</span>
                                                </el-option>
                                            </el-select>
                                        </div>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="row form-group mt-5 align-items-center">
                                <div class="col">
                                    <h6 class="text-black">Sender address</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        The sender email address that {{ statics.name }} should send email from it.
                                    </small>
                                </div>
                            </div>
                            <div class="row form-group align-items-center">
                                <div class="col-6">
                                    <el-form-item prop="settings.sender_address"
                                                  class="m-0">
                                        <div class="input-group">
                                            <input type="text"
                                                   class="form-control"
                                                   placeholder="Put your sender address here"
                                                   :disabled="!mailgun_domains.length"
                                                   v-model="card.settings.sender_address"
                                                   @input="customPreValidateForm"/>
                                        </div>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="row form-group mt-5 align-items-center">
                                <div class="col">
                                    <h6 class="text-black">Sender name</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        The sender name that {{ statics.name }} should send email from it.
                                    </small>
                                </div>
                            </div>
                            <div class="row form-group align-items-center">
                                <div class="col-6">
                                    <el-form-item prop="settings.sender_name"
                                                  class="m-0">
                                        <div class="input-group">
                                            <input type="text"
                                                   class="form-control"
                                                   placeholder="Put your sender name here"
                                                   :disabled="!mailgun_domains.length"
                                                   v-model="card.settings.sender_name"
                                                   @input="customPreValidateForm"/>
                                        </div>
                                    </el-form-item>
                                </div>
                            </div>
                        </template>
                    </template>

                    <template v-if="cardname == 'gong'">
                        <div class="row form-group">
                            <div class="col d-flex">
                                <div class="mr-2">
                                    <integration-settings-tester-btn :name="cardname"
                                                                     :card="card"
                                                                     v-if="card.credentials.access_token !== ''"
                                                                     @reload="fetchDetails">
                                    </integration-settings-tester-btn>
                                </div>
                                <div>
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        placement="right">
                                        <div slot="content">
                                            <strong>Authentication</strong>
                                            <br>
                                            <small>
                                                This will redirect you to Gong for authentication.
                                            </small>
                                        </div>
                                        <el-button v-if="current_company.gong_integration_enabled && (card.credentials.hasOwnProperty('access_key') && card.credentials['access_key'] !== '')"
                                                   plain
                                                   @click="oauth(cardname)">
                                            <span>Upgrade to OAuth</span>
                                            <i class="fa fa-sign-in mr-2"></i>
                                        </el-button>

                                        <el-button v-else-if="!current_company.gong_integration_enabled"
                                                   plain
                                                   @click="oauth(cardname)">
                                            <span>Authenticate</span>
                                            <i class="fa fa-sign-in mr-2"></i>
                                        </el-button>
                                    </el-tooltip>
                                    <el-alert
                                        v-if="isStatusToggleDisabled"
                                        class="my-2"
                                        type="info"
                                        :closable="false"
                                        show-icon>
                                        You can only enable one CRM integration.
                                    </el-alert>
                                </div>
                            </div>
                        </div>

                        <div class="row form-group align-items-center" v-if="card.credentials.hasOwnProperty('access_key') && card.credentials['access_key'] !== ''">
                            <div class="col">
                                <h6 class="text-black">Access Key</h6>
                                <small class="p-0 m-0 block text-black-lt">
                                    You can find your access keys from your Workspace Settings > Data Export > API
                                    <br>
                                    <a href="https://help.gong.io/hc/en-us/articles/360042449471-View-API-documentation-on-call-data"
                                       target="_blank"
                                       class="text-bluish _600">
                                        Learn more
                                    </a>
                                </small>
                            </div>
                        </div>
                        <div class="row form-group align-items-center" v-if="card.credentials.hasOwnProperty('access_key') && card.credentials['access_key'] !== ''">
                            <div class="col-6">
                                <el-form-item prop="credentials.access_key"
                                              class="m-0">
                                    <div class="input-group">
                                        <input type="text"
                                               class="form-control"
                                               placeholder="Put your access key here"
                                               v-model="card.credentials.access_key"
                                               @input="customPreValidateForm"/>
                                    </div>
                                </el-form-item>
                            </div>
                        </div>

                        <div class="row form-group mt-5 align-items-center" v-if="card.credentials.hasOwnProperty('access_key') && card.credentials['access_key'] !== ''">
                            <div class="col">
                                <h6 class="text-black">Access Key Secret</h6>
                                <small class="p-0 m-0 block text-black-lt">
                                    You can find your access keys from your Workspace Settings > Data Export > API
                                    <br>
                                    <a href="https://help.gong.io/hc/en-us/articles/360042449471-View-API-documentation-on-call-data"
                                       target="_blank"
                                       class="text-bluish _600">
                                        Learn more
                                    </a>
                                </small>
                            </div>
                        </div>
                        <div class="row form-group align-items-center" v-if="card.credentials.hasOwnProperty('access_key') && card.credentials['access_key'] !== ''">
                            <div class="col-6">
                                <el-form-item prop="credentials.access_key_secret"
                                              class="m-0">
                                    <div class="input-group">
                                        <input type="text"
                                               class="form-control w-100"
                                               placeholder="Put your access key secret here"
                                               v-model="card.credentials.access_key_secret"
                                               @input="customPreValidateForm"/>
                                    </div>
                                </el-form-item>
                            </div>
                        </div>

                        <template v-if="card.credentials.access_key !== '' && card.credentials.access_key_secret !== '' && card.status === true && current_company.gong_integration_enabled">
                            <h5 class="mt-4">Synchronization</h5>
                            <hr class="no-top-margin">
                            <div class="row form-group align-items-center">
                                <div class="col">
                                    <h6 class="text-black">Workspace Settings</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        <span v-if="!gong_workspaces.length && !loading_workspaces">
                                            Please create a workspace on your Gong account and select it here
                                        </span>
                                        <span v-else>
                                            Select one of your Gong workspaces to integrate with it
                                        </span>
                                    </small>
                                </div>
                                <div class="col">
                                    <el-form-item prop="settings.workspace_id"
                                                  class="m-0">
                                        <el-select v-model="card.settings.workspace_id"
                                                   class="w-100"
                                                   :value="card.settings.workspace_id"
                                                   :loading="loading_workspaces"
                                                   :disabled="loading_workspaces || !gong_workspaces.length"
                                                   filterable
                                                   @change="customPreValidateForm">
                                            <el-option
                                                v-for="workspace in gong_workspaces"
                                                :key="workspace.id"
                                                :label="workspace.name"
                                                :value="workspace.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div v-if="card.settings.workspace_id"
                                 class="row form-group align-items-center mt-3">
                                <div class="col">
                                    <h6 class="text-black">Sync users</h6>
                                    <small class="p-0 m-0 block text-black-lt">
                                        Sync your Gong users with {{ statics.name }}. This will allow us to send out
                                        call information and assign it to the correct user in Gong.
                                    </small>
                                </div>
                                <div class="col d-flex justify-content-end">
                                    <div class="d-flex flex-column">
                                        <el-button :disabled="loading_pull_users"
                                                   plain
                                                   @click="pullUsers(cardname)">
                                            <span>Sync Gong users</span>
                                            <i class="material-icons loader pull-left"
                                               v-if="loading_pull_users">&#xE863;</i>
                                        </el-button>
                                        <div class="pt-2">
                                            <small v-if="card.settings && card.settings.last_sync_user"
                                                   class="p-0 m-0 block text-black-lt text-xs">
                                                Last user sync: <b>{{ card.settings.last_sync_user | fixFullDateUTCRelative }}</b>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>

                    <template
                        v-if="['slack', 'hubspot','pipedrive', 'zoho', 'guesty', 'gong', 'gohighlevel'].includes(cardname) && card.status == true && card.settings.filters
                        && (card.credentials.access_token && card.credentials.access_token !== ''
                        || card.credentials.user_data
                        || card.credentials.user
                        || card.credentials.api_token
                        )">
                        <h5 class="mt-4">Filters</h5>
                        <hr class="no-top-margin">
                        <div class="row form-group">
                            <div class="col-12">
                                <el-timeline class="ivr-timeline">
                                    <el-timeline-item placement="top"
                                                      size="large">
                                        <template slot="dot">
                                            <el-switch v-model="card.settings.filters.status"
                                                       active-color="#00a344">
                                            </el-switch>
                                            <label class="el-form-item__label ml-2">Choose Events To Send</label>
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                placement="right">
                                                <div slot="content">
                                                    <small>
                                                        All
                                                        {{
                                                            cardname | prettifyCamelCase | ucwords | fixIntegrationName
                                                        }} events will be enabled by default.
                                                        <br>
                                                        Use this if you want to filter our specific events like direction, type and more.
                                                        <br>
                                                        <br>
                                                        <b>Note:</b> if you select a filter but no options for that filter are selected,
                                                        <br>
                                                        no events will be sent.
                                                    </small>
                                                </div>
                                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                                            </el-tooltip>
                                        </template>
                                    </el-timeline-item>
                                    <el-timeline-item v-if="cardname == 'pipedrive' && card.settings.filters.status"
                                                      placement="top"
                                                      size="large">
                                        <template slot="dot">
                                            <el-switch v-model="card.settings.filters.create_deal"
                                                       active-color="#00a344">
                                            </el-switch>
                                            <label class="el-form-item__label ml-2">Create Deal</label>
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                placement="right">
                                                <div slot="content">
                                                    <small>
                                                        Create deal when contact created or disposed
                                                    </small>
                                                </div>
                                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                                            </el-tooltip>
                                        </template>
                                        <transition name="el-fade-in-linear">
                                            <div v-show="card.settings.filters.create_deal" class="mt-2">
                                                <el-radio-group v-model="card.settings.filters.create_deal_when"
                                                                :min="1"
                                                                @change="customPreValidateForm">
                                                    <el-radio label="created"
                                                              border>Contact Created
                                                    </el-radio>
                                                    <el-radio label="disposed"
                                                              border>Contact Disposed
                                                    </el-radio>
                                                </el-radio-group>

                                                <el-form-item
                                                    prop="settings.filters.create_deal_disposition_status_ids">
                                                    <disposition-status-selector
                                                        v-show="card.settings.filters.create_deal_when === 'disposed'"
                                                        select_style="width: 100%;"
                                                        multiple
                                                        clearable
                                                        v-model="card.settings.filters.create_deal_disposition_status_ids"
                                                        @change="changeCreateDealWhenDisposed">
                                                    </disposition-status-selector>
                                                </el-form-item>
                                            </div>
                                        </transition>
                                    </el-timeline-item>
                                    <el-timeline-item v-if="card.settings.filters.status && cardname != 'gohighlevel'"
                                                      placement="top"
                                                      type="primary"
                                                      size="large">
                                        <template slot="dot">
                                            <el-switch v-model="card.settings.filters.filter_direction"
                                                       active-color="#00a344">
                                            </el-switch>
                                            <label class="el-form-item__label ml-2">
                                                Direction
                                                <i class="text-danger"
                                                   v-if="card.settings.filters.filter_direction">
                                                    *
                                                </i>
                                            </label>
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                placement="right">
                                                <div slot="content">
                                                    <small>
                                                        Filter by communication direction
                                                    </small>
                                                </div>
                                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                                            </el-tooltip>
                                        </template>
                                        <transition name="el-fade-in-linear">
                                            <div v-show="card.settings.filters.filter_direction" class="mt-2">
                                                <el-form-item prop="settings.filters.direction">
                                                    <el-checkbox-group v-model="card.settings.filters.direction"
                                                                       @change="customPreValidateForm">
                                                        <el-checkbox
                                                            v-for="direction in [CommunicationDirections.INBOUND, CommunicationDirections.OUTBOUND]"
                                                            :key="direction"
                                                            :label="direction"
                                                            class="mt-2 mx-1"
                                                            border>
                                                            {{ direction | fixCommDirection }}
                                                        </el-checkbox>
                                                    </el-checkbox-group>
                                                </el-form-item>
                                            </div>
                                        </transition>
                                    </el-timeline-item>
                                    <el-timeline-item v-if="card.settings.filters.status"
                                                      placement="top"
                                                      type="primary"
                                                      size="large">
                                        <template slot="dot">
                                            <el-switch v-model="card.settings.filters.filter_type"
                                                       active-color="#00a344">
                                            </el-switch>
                                            <label class="el-form-item__label ml-2">
                                                Type
                                                <i v-if="card.settings.filters.filter_type"
                                                   class="text-danger">
                                                    *
                                                </i>
                                            </label>
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                placement="right">
                                                <div slot="content">
                                                    <small>
                                                        Filter by communication type
                                                    </small>
                                                </div>
                                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                                            </el-tooltip>
                                        </template>
                                        <transition name="el-fade-in-linear">
                                            <div v-show="card.settings.filters.filter_type" class="mt-2">
                                                <el-form-item prop="settings.filters.type">
                                                    <el-checkbox-group v-model="card.settings.filters.type"
                                                                       v-if="cardname !== 'gohighlevel'"
                                                                       @change="customPreValidateForm">
                                                        <el-checkbox v-for="type in filteredCommunicationTypes"
                                                                     :key="type"
                                                                     :label="type"
                                                                     class="mt-2 mx-1"
                                                                     border>
                                                            {{ type | fixCommType }}
                                                        </el-checkbox>
                                                    </el-checkbox-group>
                                                    <el-checkbox-group v-model="card.settings.filters.type"
                                                                       v-else
                                                                       @change="customPreValidateForm">
                                                        <el-checkbox v-for="type in filteredCommunicationTypesForGHL"
                                                                     :key="type"
                                                                     :label="type"
                                                                     class="mt-2 mx-1"
                                                                     border>
                                                            {{ type | fixCommType }}
                                                        </el-checkbox>
                                                    </el-checkbox-group>
                                                </el-form-item>
                                            </div>
                                        </transition>
                                    </el-timeline-item>
                                    <el-timeline-item v-if="card.settings.filters.status && cardname != 'gohighlevel'"
                                                      placement="top"
                                                      type="primary"
                                                      size="large">
                                        <template slot="dot">
                                            <el-switch
                                                v-model="card.settings.filters.filter_communication_disposition_status"
                                                active-color="#00a344">
                                            </el-switch>
                                            <label class="el-form-item__label ml-2">
                                                Communication Disposition Status
                                                <i v-if="card.settings.filters.filter_communication_disposition_status"
                                                   class="text-danger">
                                                    *
                                                </i>
                                            </label>
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                placement="right">
                                                <div slot="content">
                                                    <small>
                                                        Filter by communication disposition status
                                                    </small>
                                                </div>
                                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                                            </el-tooltip>
                                        </template>
                                        <transition name="el-fade-in-linear">
                                            <div
                                                v-show="card.settings.filters.filter_communication_disposition_status"
                                                class="mt-2">
                                                <el-form-item
                                                    prop="settings.filters.communication_disposition_statuses">
                                                    <el-checkbox-group
                                                        v-model="card.settings.filters.communication_disposition_statuses"
                                                        @change="customPreValidateForm">
                                                        <el-checkbox
                                                            v-for="status in filteredCommunicationDispositionStatuses"
                                                            :key="status"
                                                            :label="status"
                                                            class="mt-2 mx-1"
                                                            border>
                                                            {{
                                                                status | translateDispositionStatusText | fixName
                                                            }}
                                                        </el-checkbox>
                                                    </el-checkbox-group>
                                                </el-form-item>
                                            </div>
                                        </transition>
                                    </el-timeline-item>
                                    <el-timeline-item v-if="card.settings.filters.status && cardname != 'gohighlevel'"
                                                      placement="top"
                                                      type="primary"
                                                      size="large">
                                        <template slot="dot">
                                            <el-switch v-model="card.settings.filters.filter_disposed_contacts"
                                                       active-color="#00a344">
                                            </el-switch>
                                            <label class="el-form-item__label ml-2">
                                                Contacts
                                                <i v-if="card.settings.filters.filter_disposed_contacts"
                                                   class="text-danger">
                                                    *
                                                </i>
                                            </label>
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                placement="right">
                                                <div slot="content">
                                                    <small>
                                                        Filter by contact type
                                                    </small>
                                                </div>
                                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                                            </el-tooltip>
                                        </template>
                                        <transition name="el-fade-in-linear">
                                            <div v-show="card.settings.filters.filter_disposed_contacts" class="mt-2">
                                                <el-form-item>
                                                    <el-radio-group v-model="card.settings.filters.contacts"
                                                                    @change="customPreValidateForm">
                                                        <el-radio label="all"
                                                                  border>
                                                            All
                                                        </el-radio>
                                                        <el-radio label="disposed_contacts"
                                                                  border>
                                                            Disposed contacts
                                                        </el-radio>
                                                        <el-radio label="selected_disposition_statuses"
                                                                  border>
                                                            Selected dispositions
                                                        </el-radio>
                                                    </el-radio-group>
                                                </el-form-item>

                                                <el-form-item prop="settings.filters.disposition_status_ids">
                                                    <disposition-status-selector
                                                        v-show="card.settings.filters.contacts === 'selected_disposition_statuses'"
                                                        select_style="width: 100%;"
                                                        multiple
                                                        clearable
                                                        v-model="card.settings.filters.disposition_status_ids"
                                                        @change="changeSyncContactsWithDisposition">
                                                    </disposition-status-selector>
                                                </el-form-item>
                                            </div>
                                        </transition>
                                    </el-timeline-item>
                                    <el-timeline-item v-if="card.settings.filters.status && cardname != 'gohighlevel'"
                                                      placement="top"
                                                      type="primary"
                                                      size="large">
                                        <template slot="dot">
                                            <el-switch v-model="card.settings.filters.filter_lines"
                                                       active-color="#00a344">
                                            </el-switch>
                                            <label class="el-form-item__label ml-2">
                                                Skip lines
                                                <i v-if="card.settings.filters.filter_lines"
                                                   class="text-danger">
                                                    *
                                                </i>
                                            </label>
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                placement="right">
                                                <div slot="content">
                                                    <small>
                                                        Here you can exclude the lines <br> you don't want to send to
                                                        this integration
                                                    </small>
                                                </div>
                                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                                            </el-tooltip>
                                        </template>
                                        <transition name="el-fade-in-linear">
                                            <div v-show="card.settings.filters.filter_lines" class="mt-2">
                                                <el-form-item prop="settings.filters.skip_line_ids">
                                                    <campaign-selector select_style="width: 100%;"
                                                                       multiple
                                                                       v-model="card.settings.filters.skip_line_ids"
                                                                       @change="changeSkipLines">
                                                    </campaign-selector>
                                                </el-form-item>
                                            </div>
                                        </transition>
                                    </el-timeline-item>
                                    <el-timeline-item v-if="card.settings.filters.status && cardname != 'gohighlevel'"
                                                      placement="top"
                                                      type="primary"
                                                      size="large">
                                        <template slot="dot">
                                            <el-switch
                                                v-model="card.settings.filters.filter_call_duration"
                                                active-color="#00a344">
                                            </el-switch>
                                            <label class="el-form-item__label ml-2">
                                                Duration
                                                <i v-if="card.settings.filters.filter_call_duration"
                                                   class="text-danger">
                                                    *
                                                </i>
                                            </label>
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                placement="right">
                                                <div slot="content">
                                                    <small>
                                                        Filter by call talk-time/duration
                                                    </small>
                                                </div>
                                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                                            </el-tooltip>
                                        </template>
                                        <transition name="el-fade-in-linear">
                                            <div
                                                v-show="card.settings.filters.filter_call_duration" class="mt-2">
                                                <el-form-item prop="settings.filters.call_duration_type"
                                                              style="display: inline-block;">
                                                    <span>Don't send calls with</span>
                                                    <el-select size="mini"
                                                               v-model="card.settings.filters.call_duration_type"
                                                               placeholder="Select Duration Type">
                                                        <el-option
                                                            v-for="item in callTypeOptions"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <span>less than</span>
                                                <el-form-item prop="settings.filters.call_duration"
                                                              style="display: inline-block;">
                                                    <el-input size="mini"
                                                              placeholder="Duration"
                                                              v-model="card.settings.filters.call_duration">
                                                    </el-input>
                                                </el-form-item>
                                                <span>minutes</span>
                                            </div>
                                        </transition>
                                    </el-timeline-item>
                                    <el-timeline-item v-if="card.settings.filters.status && cardname != 'gohighlevel'"
                                                      placement="top"
                                                      type="primary"
                                                      size="large">
                                        <template slot="dot">
                                            <el-switch
                                                v-model="card.settings.filters.filter_qualified_only"
                                                active-color="#00a344">
                                            </el-switch>
                                            <label class="el-form-item__label ml-2">Qualified Contacts</label>
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                placement="right">
                                                <div slot="content">
                                                    <small>
                                                        Only create contacts when a qualified communication passes the
                                                        filters
                                                    </small>
                                                </div>
                                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                                            </el-tooltip>
                                        </template>
                                    </el-timeline-item>
                                    <!-- Do not create new accounts on (hubspot | zoho | pipedrive | GHL) -->
                                    <el-timeline-item v-if="['hubspot', 'zoho', 'pipedrive', 'gohighlevel'].includes(cardname)"
                                                      placement="top"
                                                      type="primary"
                                                      size="large">
                                        <template slot="dot">
                                            <el-switch
                                                v-model="card.settings.filters.filter_never_create_new_contact"
                                                active-color="#00a344">
                                            </el-switch>
                                            <label class="el-form-item__label ml-2">Don't create new contacts</label>
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                placement="right">
                                                <div slot="content">
                                                    When new contacts are created in Aloware,
                                                    <br>
                                                    {{
                                                        cardname | prettifyCamelCase | ucwords | fixIntegrationName
                                                    }}
                                                    will not reflect that new contact.
                                                    <br>
                                                    This option will only update the contacts that are already in
                                                    {{
                                                        cardname | prettifyCamelCase | ucwords | fixIntegrationName
                                                    }}.
                                                </div>
                                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                                            </el-tooltip>
                                        </template>
                                    </el-timeline-item>
                                    <!-- Do not update contacts on (hubspot | zoho | pipedrive | GHL) -->
                                    <el-timeline-item v-if="['hubspot', 'zoho', 'pipedrive', 'gohighlevel'].includes(cardname)"
                                                      placement="top"
                                                      type="primary"
                                                      size="large">
                                        <template slot="dot">
                                            <el-switch
                                                v-model="card.settings.filters.filter_never_update_contact"
                                                active-color="#00a344">
                                            </el-switch>
                                            <label class="el-form-item__label ml-2">Don't update contact information</label>
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                placement="right">
                                                <div slot="content">
                                                    Changes to contact information in Aloware will not update your
                                                    <br>
                                                    {{
                                                        cardname | prettifyCamelCase | ucwords | fixIntegrationName
                                                    }}
                                                    contact details
                                                </div>
                                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                                            </el-tooltip>
                                        </template>
                                    </el-timeline-item>
                                    <!-- Do not update contact dispositions on (hubspot)-->
                                    <el-timeline-item v-if="['hubspot'].includes(cardname)"
                                                      placement="top"
                                                      type="primary"
                                                      size="large">
                                        <template slot="dot">
                                            <el-switch
                                                v-model="card.settings.filters.filter_never_update_contact_dispositions"
                                                active-color="#00a344">
                                            </el-switch>
                                            <label class="el-form-item__label ml-2">Don't update contact's lead status</label>
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                placement="right">
                                                <div slot="content">
                                                    Changes to contact lead status in Aloware will not update your
                                                    <br>
                                                    {{
                                                        cardname | prettifyCamelCase | ucwords | fixIntegrationName
                                                    }}
                                                    lead status
                                                </div>
                                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                                            </el-tooltip>
                                        </template>
                                    </el-timeline-item>
                                    <!-- Do not update contact owners on (hubspot | zoho | pipedrive | gohighlevel)-->
                                    <el-timeline-item v-if="['hubspot', 'zoho', 'pipedrive', 'gohighlevel'].includes(cardname)"
                                                      placement="top"
                                                      type="primary"
                                                      size="large">
                                        <template slot="dot">
                                            <el-tooltip class="item"
                                                        effect="dark"
                                                        placement="top-start"
                                                        :disabled="cardname === 'gohighlevel' ? !! card.credentials.company_id : true">
                                                <div slot="content">
                                                    You need to re-authenticate the integration before you can start using this feature.
                                                </div>
                                                <el-switch active-color="#00a344"
                                                           :disabled="cardname === 'gohighlevel' ? !card.credentials.company_id : false"
                                                           v-model="card.settings.filters.filter_never_update_contact_owners">
                                                </el-switch>
                                            </el-tooltip>
                                            <label class="el-form-item__label ml-2">Don't update contact owners</label>
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                placement="right">
                                                <div slot="content">
                                                    Changes to contact ownership in Aloware
                                                    <br>
                                                    will not update the owner in
                                                    {{
                                                        cardname | prettifyCamelCase | ucwords | fixIntegrationName
                                                    }}
                                                </div>
                                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                                            </el-tooltip>
                                        </template>
                                    </el-timeline-item>
                                    <!-- Only update empty fields (for zoho only)-->
                                    <el-timeline-item v-if="cardname == 'zoho'"
                                                      placement="top"
                                                      type="primary"
                                                      size="large">
                                        <template slot="dot">
                                            <el-switch
                                                v-model="card.settings.filters.filter_only_update_empty_fields"
                                                active-color="#00a344">
                                            </el-switch>
                                            <label class="el-form-item__label ml-2">Only update empty fields</label>
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                placement="right">
                                                <div slot="content">
                                                    <small>
                                                        Only update first name, last name, phone, email, website &
                                                        company from Aloware if the data
                                                        in {{
                                                            cardname | prettifyCamelCase | ucwords | fixIntegrationName
                                                        }} is empty.
                                                    </small>
                                                </div>
                                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                                            </el-tooltip>
                                        </template>
                                    </el-timeline-item>
                                </el-timeline>
                            </div>
                        </div>
                    </template>
                    <template v-if="cardname === 'hubspot' && card.credentials.access_token !== ''">
                        <h5 class="mt-4">
                            Connection Information
                            <el-tooltip
                                class="item"
                                effect="dark"
                                placement="right">
                                <div slot="content">
                                    This will show the HubSpot account that has been integrated with {{
                                        statics.name
                                    }}.
                                </div>
                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                            </el-tooltip>
                        </h5>
                        <hr class="no-top-margin">

                        <div class="mb-4">
                            <template
                                v-if="card.credentials.user && card.credentials.hub_domain && card.credentials.hub_id">
                                <b>Email</b>: {{ card.credentials.user }}
                                <br>
                                <b>Account Name</b>: {{ card.credentials.hub_domain }}
                                <br>
                                <b>Account ID</b>: {{ card.credentials.hub_id }}
                            </template>
                            <template v-if="card.credentials.scopes">
                                <br>
                                <strong>Scopes</strong>
                                <br>
                                <code>{{ card.credentials.scopes }}</code>
                            </template>
                            <template v-if="card.credentials.user"> <!-- Check if there is an auth user first -->
                                <br>
                                <strong>UI Domain</strong>:
                                <span v-if="card.credentials.uiDomain">
                                    {{ card.credentials.uiDomain }}
                                </span>
                                <span v-else>
                                    Please re-authenticate to fetch from HubSpot (default: app.hubspot.com)
                                </span>
                            </template>
                        </div>
                    </template>
                    <template v-if="cardname === 'zoho' && card.credentials.access_token !== ''">
                        <h5 class="mt-4">
                            Connection Information
                            <el-tooltip
                                class="item"
                                effect="dark"
                                placement="right">
                                <div slot="content">
                                    This will show the Zoho account that has been integrated with {{
                                        statics.name
                                    }}.
                                </div>
                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                            </el-tooltip>
                        </h5>
                        <hr class="no-top-margin">

                        <div class="mb-4">
                            <template
                                v-if="card.credentials.org_id && card.credentials.accounts_server && card.credentials.api_domain">
                                <strong>Zoho Account</strong>
                                <br>
                                <b>Org id:</b> {{ card.credentials.org_id }}
                                <br>
                                <b>Accounts server:</b> {{ card.credentials.accounts_server }}
                                <br>
                                <b>API domain:</b> {{ card.credentials.api_domain }}
                            </template>
                        </div>
                    </template>
                    <template v-if="cardname === 'pipedrive' && card.credentials.access_token !== ''">
                        <h5 class="mt-4">
                            Connection Information
                            <el-tooltip
                                class="item"
                                effect="dark"
                                placement="right">
                                <div slot="content">
                                    This will show the Pipedrive account that has been integrated with {{
                                        statics.name
                                    }}.
                                </div>
                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                            </el-tooltip>
                        </h5>
                        <hr class="no-top-margin">

                        <div class="mb-4">
                            <template
                                v-if="card.credentials.user_data && card.credentials.user_data.company_name">
                                <strong>Company Name</strong>: {{ card.credentials.user_data.company_name }}
                            </template>
                            <template
                                v-if="card.credentials.user_data && card.credentials.user_data.company_domain">
                                <br>
                                <strong>Company Domain</strong>: {{ card.credentials.user_data.company_domain }}
                            </template>
                            <template v-if="card.credentials.scope">
                                <br>
                                <strong>Scopes</strong>: <code>{{ card.credentials.scope }}</code>
                            </template>
                        </div>
                    </template>
                    <template v-if="cardname === 'gohighlevel' && card.credentials.access_token">
                        <h5 class="mt-4">
                            Connection Information
                            <el-tooltip
                                class="item"
                                effect="dark"
                                placement="right">
                                <div slot="content">
                                    This will show the HighLevel account that has been integrated with {{
                                        statics.name
                                    }}.
                                </div>
                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                            </el-tooltip>
                        </h5>
                        <hr class="no-top-margin">

                        <div class="mb-4">
                            <template
                                v-if="card.credentials.user_data.first_name">
                                <strong>User Name</strong>: {{ card.credentials.user_data.first_name }} {{
                                    card.credentials.user_data.last_name
                                }}
                            </template>
                            <template
                                v-if="card.credentials.user_id">
                                <br>
                                <strong>User ID</strong>: {{ card.credentials.user_id }}
                            </template>
                            <template
                                v-if="card.credentials.location_id">
                                <br>
                                <strong>Location ID</strong>: {{ card.credentials.location_id }}
                            </template>
                            <template
                                v-if="card.credentials.company_id">
                                <br>
                                <strong>Company ID</strong>: {{ card.credentials.company_id }}
                            </template>
                        </div>
                    </template>

                    <template v-if="cardname === 'facebook' && card.credentials.user">
                        <h5 class="mt-4">
                            Connection Information
                            <el-tooltip
                                class="item"
                                effect="dark"
                                placement="right">
                                <div slot="content">
                                    This will show the Facebook account that has been integrated with {{
                                        statics.name
                                    }}.
                                </div>
                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                            </el-tooltip>
                        </h5>
                        <hr class="no-top-margin">

                        <div class="mb-4">
                            <div>
                                <el-avatar :src="card.credentials.user.avatar"
                                           :size="60">
                                </el-avatar>
                            </div>
                            <div>
                                <strong>{{ card.credentials.user.name }}</strong>
                                <br>
                                <span>{{ card.credentials.user.email }}</span>
                            </div>
                        </div>
                    </template>
                    <template
                        v-if="cardname === 'slack' && card.credentials.api_token && card.credentials.api_token !== '' && card.credentials.values">
                        <h5 class="mt-4">
                            Connection Information
                            <el-tooltip
                                class="item"
                                effect="dark"
                                placement="right">
                                <div slot="content">
                                    This will show the Slack account that has been integrated with {{
                                        statics.name
                                    }}.
                                </div>
                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                            </el-tooltip>
                        </h5>
                        <hr class="no-top-margin">

                        <div class="mb-4">
                            <b>Workspace:</b> {{ card.credentials.values.team_name }}<br/>
                            <b>Channel:</b> {{ card.credentials.values.incoming_webhook.channel }}
                        </div>
                    </template>
                    <template
                        v-if="cardname === 'sendgrid' && card.credentials.user_data">
                        <h5 class="my-4">
                            Connection Information
                            <el-tooltip
                                class="item"
                                effect="dark"
                                placement="right">
                                <div slot="content">
                                    This will show the Sendgrid account that has been integrated with {{
                                        statics.name
                                    }}.
                                </div>
                                <i class="material-icons text-lg cursor-pointer text-blue p-0 ml-1">info_outline</i>
                            </el-tooltip>
                        </h5>

                        <div class="mb-4">
                            <template
                                v-if="card.credentials.user_data.userid">
                                <strong>User ID</strong> {{ card.credentials.user_data.userid }}
                            </template>
                            <template
                                v-if="card.credentials.user_data.first_name">
                                <br>
                                <strong>First Name</strong> {{ card.credentials.user_data.first_name }}
                            </template>
                            <template
                                v-if="card.credentials.user_data.last_name">
                                <br>
                                <strong>Last Name</strong> {{ card.credentials.user_data.last_name }}
                            </template>
                            <template v-if="card.credentials.phone">
                                <br>
                                <strong>Phone</strong> {{ card.credentials.phone }}
                            </template>
                            <template v-if="card.credentials.user_data.website">
                                <br>
                                <strong>Website</strong> {{ card.credentials.user_data.website }}
                            </template>
                        </div>
                    </template>
                    <template v-if="cardname === 'SMTP' && !hasOtherEmailIntegrationEnabled(cardname)">
                        <div class="row px-2">
                            <div class="col-6">
                                <span class="h5">Server Address</span>
                                <el-form-item prop="settings.address">
                                    <div class="input-group">
                                        <el-input type="text"
                                                  v-model="card.settings.address"/>
                                    </div>
                                </el-form-item>
                            </div>
                            <div class="col-2">
                                <span class="h5">Port</span>
                                <el-form-item prop="settings.port">
                                    <div class="input-group">
                                        <el-input type="number"
                                                  v-model="card.settings.port"
                                                  @input="customPreValidateForm"/>
                                    </div>
                                </el-form-item>
                            </div>
                            <div class="col-4">
                                <div>
                                    <span class="h5">Encryption type</span>
                                    <el-form-item class="m-0"
                                                  prop="settings.encryption">
                                        <el-select v-model="card.settings.encryption">
                                            <el-option :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value"
                                                       v-for="item in smtp_encryption_types">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>

                        <div class="row px-2 mt-3">
                            <div class="col-6">
                                <span class="h5">Sender Address</span>
                                <el-form-item prop="settings.sender_address">
                                    <div class="input-group">
                                        <el-input type="text"
                                                  v-model="card.settings.sender_address"
                                                  @input="customPreValidateForm"/>
                                    </div>
                                </el-form-item>
                            </div>
                            <div class="col-6">
                                <span class="h5">Sender Name</span>
                                <el-form-item prop="settings.sender_name">
                                    <div class="input-group">
                                        <el-input type="email"
                                                  v-model="card.settings.sender_name"
                                                  @input="customPreValidateForm"/>
                                    </div>
                                </el-form-item>
                            </div>
                        </div>

                        <h5 class="mt-5">Credentials</h5>
                        <hr class="no-top-margin">

                        <div class="row px-2">
                            <div class="col-8">
                                <span class="h5">Username</span>
                                <el-form-item prop="credentials.username">
                                    <div class="input-group">
                                        <el-input type="text"
                                                  v-model="card.credentials.username"
                                                  @input="customPreValidateForm"/>
                                    </div>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="row px-2 mt-3">
                            <div class="col-8">
                                <span class="h5">Password</span>
                                <el-form-item prop="credentials.password"
                                              class="m-0">
                                    <div class="input-group">
                                        <el-input type="password"
                                                  v-model="card.credentials.password"
                                                  @input="customPreValidateForm"/>
                                    </div>
                                </el-form-item>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </el-form>
        <div class="pt-3 px-4">
            <div v-if="isTestAndSaveButtonDisabled()"
                 class="form-group text-right">
                <el-button class="pull-left"
                           @click.prevent="goBack">
                    <i class="fa fa-arrow-left"></i>
                    Back
                </el-button>
            </div>
            <div class="form-group text-right"
                 v-else>
                <el-button class="pull-left"
                           @click.prevent="goBack">
                    <i class="fa fa-arrow-left"></i>
                    Back
                </el-button>
                <el-button class="right"
                           @click.prevent="resetForm(cardname)">
                    Reset
                </el-button>
                <el-button type="primary"
                           :loading="mailgun_testing_status"
                           :disabled="mailgun_testing_status || card.credentials.api_token == ''"
                           @click="mailgunTestAndGetDomains(card.credentials.api_token)"
                           v-if="cardname == 'mailgun'">
                    Test
                </el-button>
                <el-button type="success"
                           :loading="saveLoading || testLoading"
                           :disabled="!validated || testLoading || apiTokenTestStatus !== WebhookTestStatuses.SUCCESS"
                           @click="testAndSave(cardname)"
                           v-if="cardname == 'mailgun'">
                    Save
                </el-button>
                <el-button type="success"
                           :loading="saveLoading || testLoading"
                           :disabled="!validated || testLoading"
                           @click="testAndSave(cardname)"
                           v-else-if="cardname == 'sendgrid' && card.status == true">
                    Test & Save
                </el-button>
                <el-button type="success"
                           :loading="saveLoading || testLoading"
                           :disabled="!validated || testLoading"
                           @click="testAndSave(cardname)"
                           v-else-if="cardname == 'SMTP'">
                    Test & Save
                </el-button>
                <el-button type="success"
                           :loading="saveLoading"
                           :disabled="!validated || !card.credentials.token"
                           @click="submit(cardname)"
                           v-else-if="cardname == 'google-calendar'">
                    Save
                </el-button>
                <el-button type="success"
                           :loading="saveLoading"
                           :disabled="!validated"
                           @click="submit(cardname)"
                           v-else>
                    Save
                </el-button>
            </div>
        </div>
        <el-dialog top="4vh"
                   width="70vw"
                   :visible="show_prismatic_dialog"
                   append-to-body
                   :title="prismaticModalConfigTitle"
                   @close="show_prismatic_dialog = false">
            <div class="height-600" v-loading="loading">
              <prismatic-config-page
                  :integration="cardname"
                  :company_id="current_company.id"
                  @finishLoading="loading = false"
                  @reload="fetchDetails"
                  @close="show_prismatic_dialog = false"
                  @back="goBack"
              />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import auth from '../auth'
import {mapActions, mapState} from 'vuex'
import {acl_mixin, form_validation_mixin, user_info_mixin, validator_mixin} from '../mixins'
import IntegrationSettingsTesterBtn from './integration-settings-tester-btn'
import * as AuthMethods from '../constants/integration-auth-methods'
import * as HttpMethods from '../constants/integration-http-methods'
import * as WebhookTestStatuses from '../constants/webhook-test-statuses'
import * as CommunicationDirections from '../constants/communication-direction'
import * as CommunicationTypes from '../constants/communication-types'
import * as CommunicationDispositionStatus from '../constants/communication-disposition-status'
import IntegrationPropertyMapper from './integrations/hubspot/property-mapper.vue'
import Variables from './messenger/variables.vue'
import ContactSelect from './contact-select.vue'
import GoogleSignInButton from '../../admin/components/widgets/google-sign-in-button.vue'
import UpgradeNowLite from "./upgrade-now-lite.vue"
import InfoAlertBox from "./info-alert-box.vue"
import PrismaticConfigPage from "./integrations/prismatic/prismatic-config-page.vue";
import SalesforceContactSettings from "./integrations/salesforce/salesforce-contact-settings.vue";

const default_rules = {
    credentials: [],
    settings: []
}

const default_filters = {
    status: false,
    filter_direction: false,
    filter_type: false,
    filter_disposed_contacts: false,
    filter_communication_disposition_status: false,
    filter_lines: false,
    filter_qualified_only: false,
    filter_never_create_new_contact: false,
    filter_never_update_contact_owners: true,
    filter_never_update_contact: true,
    filter_never_update_contact_dispositions: true,
    filter_only_update_empty_fields: false,
    create_deal: false,
    create_deal_when: 'created',
    contacts: 'all',
    direction: [CommunicationDirections.INBOUND, CommunicationDirections.OUTBOUND],
    type: [CommunicationTypes.CALL, CommunicationTypes.SMS, CommunicationTypes.RVM, CommunicationTypes.EMAIL, CommunicationTypes.APPOINTMENT, CommunicationTypes.REMINDER],
    skip_line_ids: [],
    disposition_status_ids: [],
    create_deal_disposition_status_ids: [],
    communication_disposition_statuses: [],
    filter_call_duration: false,
    call_duration_type: null,
    sms_campaign: null,
    call_duration: null
}

const smtp_encryption_types = [
    {
        value: null,
        label: 'None'
    },
    {
        value: 'tls',
        label: 'TLS'
    },
    {
        value: 'ssl',
        label: 'SSL'
    }
]

export default {
    components: {
        SalesforceContactSettings,
        PrismaticConfigPage,
        InfoAlertBox,
        UpgradeNowLite,
        IntegrationSettingsTesterBtn,
        IntegrationPropertyMapper,
        Variables,
        ContactSelect,
        GoogleSignInButton
    },

    mixins: [
        acl_mixin,
        validator_mixin,
        form_validation_mixin,
        user_info_mixin
    ],

    props: {
        cardname: {
            required: true
        },
        aloware_link: {
            required: false
        }
    },

    data() {
        return {
            auth: auth,
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true,
            env: null,
            loading: true,
            loading_push: false,
            loading_pull: false,
            loading_pull_users: false,
            loading_pull_lead_status: false,
            loading_pull_call_dispositions: false,
            loading_pull_activity_types: false,
            loading_pull_deals: false,
            loading_mailboxes: true,
            loading_workspaces: true,
            loading_guesty_test: true,
            loading_pages: true,
            saveLoading: false,
            testLoading: false,
            checkAll: true,
            isIndeterminate: false,
            apiTokenTestStatus: WebhookTestStatuses.NOT_TESTED,
            rules: _.clone(default_rules),
            card: {
                status: false,
                credentials: {
                    tracking_id: '',
                    api_token: '',
                    access_token: '',
                    access_key: '',
                    access_key_secret: '',
                    uiDomain: '', // HubSpot
                    client_id: '', // Google
                    client_secret: '' // Google
                },
                settings: {
                    report_outbound: false,
                    lead_status_locked: false,
                    mailbox_id: '',
                    workspace_id: '',
                    page_id: null,
                    workflow_id: null,
                    page: null,
                    sender_address: '',
                    sender_name: '',
                    events: [],
                    domain: "",
                    module: 'contacts',
                    filters: _.clone(default_filters),
                    send_sms_as_note: true,
                    send_call_events: false,
                    selected_contact: null,
                    prefix: '',
                    currently_saved_prefix: '',
                    tags: [],
                    encryption: 'tls',
                    calendars: [],
                    calendar: {
                        id: null,
                        timeZone: null
                    },
                    push_notification_channel: {
                        status: false
                    },
                    activities_settings: {
                        call: [],
                        sms: []
                    },
                    custom_activity_description_sections: {
                        call: [],
                        sms: []
                    }
                },
                name: '',
                connection_status: 0,
                last_connection_error: '',
                updated_by: null,
            },
            callTypeOptions: [{
                value: 'duration',
                label: 'Duration'
            }, {
                value: 'talk_time',
                label: 'Talk Time'
            }],
            listSmsCampaigns: [],
            gong_workspaces: [],
            facebook_pages: [],
            mailgun_domains: [],
            company_updated: {},
            api_key_input: false,
            mailgun_testing_status: false,
            filteredCommunicationTypes: [
                CommunicationTypes.CALL,
                CommunicationTypes.SMS,
                CommunicationTypes.RVM,
                CommunicationTypes.EMAIL,
                CommunicationTypes.NOTE,
                CommunicationTypes.SYSNOTE,
                CommunicationTypes.APPOINTMENT,
                CommunicationTypes.REMINDER
            ],
            filteredCommunicationTypesForGHL: [
                CommunicationTypes.CALL,
                CommunicationTypes.SMS,
            ],
            filteredCommunicationDispositionStatuses: [
                CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW,
                CommunicationDispositionStatus.DISPOSITION_STATUS_ABANDONED_NEW,
                CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW,
                CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW,
                CommunicationDispositionStatus.DISPOSITION_STATUS_PLACED_NEW
            ],
            loading_leadgen_forms: false,
            loading_pull_leadgen_leads: false,
            leadgen_form_id: null,
            leadgen_forms: [],
            zohoModuleOptions: [
                {
                    label: 'Contacts',
                    value: 'contacts',
                },
                {
                    label: 'Leads',
                    value: 'leads',
                },
            ],
            testing: false,
            AuthMethods,
            HttpMethods,
            WebhookTestStatuses,
            CommunicationDirections,
            CommunicationDispositionStatus,
            CommunicationTypes,
            integrations_always_show_status: [
                'stripe',
                'custom_link_builder'
            ],
            contact_select_key: 0,
            smtp_encryption_types,
            google_calendar_options: {
                guard: 'user',
                service: 'calendar'
            },
            show_prismatic_dialog: false,
            loading_salesforce_pull_leads: false
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company
        }),

        showHubspotSendAsNoteOption() {
            let scopes = this.card.credentials.scopes
            scopes = Array.isArray(scopes) ? scopes : []

            if (this.cardname === 'hubspot' && scopes.includes('timeline')) {
                return true
            }

            return false
        },

        showHubspotSendCallEvents() {
            let scopes = this.card.credentials.scopes
            scopes = Array.isArray(scopes) ? scopes : []

            if (this.cardname === 'hubspot' && scopes.includes('timeline')) {
                return true
            }

            return false
        },

        testingApiKeyElementsAttrs() {
            let attrs = {}
            switch (this.apiTokenTestStatus) {
                case this.WebhookTestStatuses.FAILED:
                    attrs['icon'] = 'el-icon-error'
                    attrs['color'] = 'text-danger'
                    attrs['TestStatus'] = 'Tests failed'
                    break
                case this.WebhookTestStatuses.NOT_TESTED:
                    attrs['icon'] = ''
                    attrs['color'] = 'text-greyish'
                    attrs['TestStatus'] = 'Not Tested'
                    break
                case this.WebhookTestStatuses.SUCCESS:
                    attrs['icon'] = 'el-icon-success'
                    attrs['color'] = 'text-success'
                    attrs['TestStatus'] = 'Tests passed'
                    break
            }

            return attrs
        },

        guestyTokenUrl() {
            return localStorage.getItem('aloware_guesty_find_dev_token_url')
        },

        isStatusToggleDisabled() {
            let integrations = {
                pipedrive: 'pipedrive_integration_enabled',
                hubspot: 'hubspot_integration_enabled',
                zoho: 'zoho_integration_enabled',
                guesty: 'guesty_integration_enabled',
                salesforce: 'salesforce_integration_enabled',
            }

            // Aloware demo accounts are allowed to turn on multiples CRMs
            if (localStorage.getItem('aloware_demo_companies') && localStorage.getItem('aloware_demo_companies').split(',').includes(this.current_company.id.toString())) {
                return false
            }

            let crm_integration_name = null
            for (let integration in integrations) {
                if (this.current_company && this.current_company[integrations[integration]]) {
                    crm_integration_name = integration
                }
            }

            if (crm_integration_name && integrations.hasOwnProperty(crm_integration_name)) {
                delete integrations[crm_integration_name]
            }

            if (Object.keys(integrations).includes(this.cardname) && crm_integration_name) {
                return true
            }

            return false
        },

        shouldWatchGoogleCalendar(value) {
            if (this.card.settings.push_notification_channel && this.card.settings.push_notification_channel.token) {
                return true
            }

            return false
        },

        getGoogleCalendarParams() {
            this.google_calendar_options.client_id = this.card.credentials.client_id
            this.google_calendar_options.client_secret = this.card.credentials.client_secret

            return this.google_calendar_options
        },
        prismaticModalConfigTitle() {
            let integrationName = this.cardname.toLowerCase().replace(/\b\w/g, s => s.toUpperCase())
            return `${integrationName} Integration Configuration`
        },
        callDescriptionFields() {
            return [
                { value: "call_completed", label: "Call Completed", description: "Date/Time that the call was completed" },
                { value: "call_outcome", label: "Call Outcome", description: "Result/Outcome of the call" },
                { value: "call_duration", label: "Call Duration", description: "Length of the call" },
                { value: "call_direction", label: "Call Direction", description: "Direction of the call (Inbound/Outbound)" },
                { value: "line", label: "Line", description: "Phone line used" },
                { value: "from", label: "From", description: "Caller information" },
                { value: "to", label: "To", description: "Call recipient" },
                { value: "recording_url", label: "Recording URL", description: "Link to the call recording" },
                { value: "voicemail_url", label: "Voicemail URL", description: "Link to the voicemail" },
                { value: "tags", label: "Tags", description: "Tags associated with the call" },
                { value: "notes", label: "Notes", description: "Additional notes" },
                { value: "source", label: "Source", description: "The source of the call" },
            ];
        },
        smsDescriptionFields() {
            return [
                { value: "message_date", label: "Message Date", description: "Date/Time the message was sent" },
                { value: "message", label: "Message", description: "Content of the message" },
                { value: "direction", label: "Direction", description: "Message direction (Inbound/Outbound)" },
                { value: "line", label: "Line", description: "Phone line used" },
                { value: "from", label: "From", description: "Sender information" },
                { value: "to", label: "To", description: "Message recipient" },
                { value: "attachments", label: "Attachments", description: "Attached files" },
                { value: "tags", label: "Tags", description: "Tags associated with the message" },
                { value: "notes", label: "Notes", description: "Additional notes" },
                { value: "source", label: "Source", description: "The source of the message" },
            ];
        },
        callSubjectFields() {
            return [
                { value: "call_disposition", label: "Call Outcome (Disposition)", description: "Result/Outcome of the call" },
                { value: "from", label: "From", description: "Indicates the assigned owner of a task. Who called (in call)" },
                { value: "communication_direction", label: "Communication Direction", description: "Communication direction Inbound or Outbound" },
                { value: "to", label: "To", description: "Who received the call or message" },
            ];
        },
        smsSubjectFields() {
            return [
                { value: "from", label: "From", description: "Indicates the assigned owner of a task. Who Sent the message (in SMS)" },
                { value: "communication_direction", label: "Communication Direction", description: "Communication direction Inbound or Outbound" },
                { value: "to", label: "To", description: "Who received the call or message" },
            ];
        }
    },

    created() {
        this.getStatics()
        this.fetchDetails().then(() => {
            // save settings
            if (this.$route.query.save) {
                this.removePropertyFromQuery('save')
                this.submit(this.cardname, false)
            }
        })

        // show error dialog
        if (this.$route.query.error) {
            this.removePropertyFromQuery('error')
            let error = {
                status: 422,
                data: {
                    errors: [
                        `Unable to connect to ${this.firstLetterCapitalized(this.cardname)}.`,
                        `Integration connection error: Please re-authenticate by <a href="/integrations/${this.cardname}/oauth"><b>clicking here</b></a>`,
                    ]
                }
            }
            this.$root.handleErrors(error)
        }

        if (!this.card.settings.activities_settings) {
            this.card.settings.activities_settings = {};
        } 
        
        if (!this.card.settings.activities_settings.call || this.card.settings.activities_settings.call.length === 0) {
            // Default for Call
            this.$set(this.card.settings.activities_settings, 'call', ["call_disposition", "to"]);
        }

        if (!this.card.settings.activities_settings.sms || this.card.settings.activities_settings.sms.length === 0) {
            // Default for SMS
            this.$set(this.card.settings.activities_settings, 'sms', ["from", "communication_direction", "to"]);
        }

        if (!this.card.settings.custom_activity_description_sections) {
            this.card.settings.custom_activity_description_sections = {};
        }
        
        if (!this.card.settings.custom_activity_description_sections.call || this.card.settings.custom_activity_description_sections.call.length === 0) {
            // Default for Call
            this.$set(this.card.settings.custom_activity_description_sections, 'call', this.callDescriptionFields.map(field => field.value));
        }

        if (!this.card.settings.custom_activity_description_sections.sms || this.card.settings.custom_activity_description_sections.sms.length === 0) {
            // Default for SMS
            this.$set(this.card.settings.custom_activity_description_sections, 'sms', this.smsDescriptionFields.map(field => field.value));
        }
    },

    mounted() {
        this.checkCompanyUpdatedInfo()

        this.handleCallbackErrors()
    },

    methods: {
        renderCalendarSummary(calendar) {
            if (calendar.accessRole === 'reader') {
                return `${calendar.summary} (readonly)`
            }
            return calendar.summary
        },
        hasOtherEmailIntegrationEnabled(cardname) {
            switch (cardname) {
                case 'mailgun':
                    return this.company_updated.sendgrid_integration_enabled || this.company_updated.smtp_integration_enabled
                case 'sendgrid':
                    return this.company_updated.mailgun_integration_enabled || this.company_updated.smtp_integration_enabled
                case 'SMTP':
                    return this.company_updated.mailgun_integration_enabled || this.company_updated.sendgrid_integration_enabled
                default:
                    return false
            }
        },
        handleCallbackErrors() {
            // if authentication failed/blocked due to one CRM integration is
            // already enabled.
            if (this.$route.query.conflict) {
                this.$notify({
                    offset: 95,
                    title: 'Integration Error',
                    dangerouslyUseHTMLString: true,
                    message: 'Only one CRM integration can be enabled.',
                    type: 'error',
                    showClose: true
                })

                // clean-up query so that when this page is reloaded
                // same error will not show
                this.removePropertyFromQuery('conflict')
            }

            // if authentication failed/blocked due to a hubspot account is already integrated to another Aloware account
            if (this.$route.query.is_integration_error) {
                this.$notify({
                    offset: 95,
                    title: 'Integration Error',
                    dangerouslyUseHTMLString: true,
                    message: this.$route.query.message,
                    type: 'error',
                    showClose: true,
                    duration: 10000
                })
                // clean-up query so that when this page is reloaded
                // same error will not show
                this.removePropertyFromQuery('is_integration_error')
                this.removePropertyFromQuery('message')
            }
        },

        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics')
                .then(res => {
                    this.statics = res.data
                    this.loading_whitelabel = false
                })
                .catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading_whitelabel = false
                })
        },

        removePropertyFromQuery(property_name) {
            let query = Object.assign({}, this.$route.query)
            delete query[property_name]
            this.$router.replace({query})
        },

        isTestAndSaveButtonDisabled() {
            if ((this.company_updated.sendgrid_integration_enabled == true && this.cardname == 'mailgun') || (this.company_updated.mailgun_integration_enabled == true && this.cardname == 'sendgrid')) {
                return true
            }

            return false
        },

        pushContacts(cardname) {
            this.loading_push = true
            axios.post('/api/v1/integration/' + cardname + '/push-contacts').then(res => {
                let notification_title = cardname.split('-')
                for (let index in notification_title) {
                    notification_title[index] = notification_title[index].charAt(0).toUpperCase() + notification_title[index].substr(1)
                }
                this.$notify({
                    offset: 95,
                    title: notification_title.join(' '),
                    message: 'Contacts are being pushed to ' + this.resolveIntegrationCardNameCasing(cardname) + ', please wait for a few minutes for this process to finish. You will receive an email when we finish processing your request.',
                    type: 'success'
                })
                this.loading_push = false
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_push = false
            })
        },

        pullContacts(cardname) {
            this.loading_pull = true
            axios.post('/api/v1/integration/' + cardname + '/pull-contacts').then(res => {
                let notification_title = cardname.split('-')
                for (let index in notification_title) {
                    notification_title[index] = notification_title[index].charAt(0).toUpperCase() + notification_title[index].substr(1)
                }
                this.$notify({
                    offset: 95,
                    title: (cardname === 'gohighlevel') ? 'HighLevel' : notification_title.join(' '),
                    message: 'Contacts are being pulled from ' + this.resolveIntegrationCardNameCasing(cardname) + ', please wait for a few minutes for this process to finish. You will receive an email when we finish processing your request.',
                    type: 'success'
                })
                this.loading_pull = false
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_pull = false
            })
        },

        pullUsers(cardname) {
            this.loading_pull_users = true
            axios.post('/api/v1/integration/' + cardname + '/pull-users').then(res => {
                let notification_title = cardname.split('-')
                for (let index in notification_title) {
                    notification_title[index] = notification_title[index].charAt(0).toUpperCase() + notification_title[index].substr(1)
                }
                this.$notify({
                    offset: 95,
                    title: notification_title.join(' '),
                    message: 'Users are being synced with ' + this.resolveIntegrationCardNameCasing(cardname) + ', please wait for a few minutes for this process to finish. You will receive an email when we finish processing your request.',
                    type: 'success'
                })
                this.loading_pull_users = false
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_pull_users = false
            })
        },

        pullLeadStatus(cardname) {
            this.loading_pull_lead_status = true
            axios.post('/api/v1/integration/' + cardname + '/pull-lead-statuses').then(res => {
                let notification_title = cardname.split('-')
                for (let index in notification_title) {
                    notification_title[index] = notification_title[index].charAt(0).toUpperCase() + notification_title[index].substr(1)
                }
                this.$notify({
                    offset: 95,
                    title: notification_title.join(' '),
                    message: 'Lead status are being pulled from ' + this.resolveIntegrationCardNameCasing(cardname) + ', please wait for a few minutes for this process to finish. You will receive an email when we finish processing your request.',
                    type: 'success'
                })
                this.loading_pull_lead_status = false
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_pull_lead_status = false
            })
        },

        pullCallDispositions(cardname) {
            this.loading_pull_call_dispositions = true
            axios.post('/api/v1/integration/' + cardname + '/pull-call-dispositions').then(res => {
                let notification_title = cardname.split('-')
                for (let index in notification_title) {
                    notification_title[index] = notification_title[index].charAt(0).toUpperCase() + notification_title[index].substr(1)
                }
                this.$notify({
                    offset: 95,
                    title: notification_title.join(' '),
                    message: 'Call dispositions are being pulled from ' + this.resolveIntegrationCardNameCasing(cardname) + ', please wait for a few minutes for this process to finish. You will receive an email when we finish processing your request.',
                    type: 'success'
                })
                this.loading_pull_call_dispositions = false
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_pull_call_dispositions = false
            })
        },

        pullActivityTypes(cardname) {
            this.loading_pull_activity_types = true
            axios.post('/api/v1/integration/' + cardname + '/pull-activity-types').then(res => {
                let notification_title = cardname.split('-')
                for (let index in notification_title) {
                    notification_title[index] = notification_title[index].charAt(0).toUpperCase() + notification_title[index].substr(1)
                }
                this.$notify({
                    offset: 95,
                    title: notification_title.join(' '),
                    message: 'Activity types are being pulled from ' + this.resolveIntegrationCardNameCasing(cardname) + ', please wait for a few minutes for this process to finish. You will receive an email when we finish processing your request.',
                    type: 'success'
                })
                this.loading_pull_activity_types = false
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_pull_activity_types = false
            })
        },

        pullDeals(cardname) {
            this.loading_pull_deals = true
            axios.post('/api/v1/integration/' + cardname + '/pull-deals').then(res => {
                let notification_title = cardname.split('-')
                for (let index in notification_title) {
                    notification_title[index] = notification_title[index].charAt(0).toUpperCase() + notification_title[index].substr(1)
                }
                this.$notify({
                    offset: 95,
                    title: notification_title.join(' '),
                    message: 'Deals are being pulled from ' + this.resolveIntegrationCardNameCasing(cardname) + ', please wait for a few minutes for this process to finish. You will receive an email when we finish processing your request.',
                    type: 'success'
                })
                this.loading_pull_deals = false
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_pull_deals = false
            })
        },

        resolveIntegrationCardNameCasing(cardname) {
            let casedCardname = ''
            if (cardname === 'hubspot') casedCardname = 'HubSpot'
            else if (cardname === 'gohighlevel') casedCardname = 'HighLevel'
            else casedCardname = (cardname || '').charAt(0).toUpperCase() + (cardname || '').substring(1)

            return casedCardname
        },

        fetchDetails() {
            return axios.get('/api/v1/integration/' + this.cardname).then(res => {
                if (res.data.integration !== null
                    && res.data.integration.credentials
                    && res.data.integration.credentials !== 'null') {
                    this.card.credentials = JSON.parse(res.data.integration.credentials)
                }

                if (res.data.integration !== null
                    && res.data.integration.settings
                    && res.data.integration.settings !== 'null') {
                    this.card.settings = _.assign({}, this.card.settings, JSON.parse(res.data.integration.settings))
                }

                // get information for this card
                switch (this.cardname) {
                    case 'google-adwords':
                        this.card.status = this.current_company.g_adwords_integration_enabled
                        break
                    case 'pipedrive':
                        this.card.status = this.current_company.pipedrive_integration_enabled
                        break
                    case 'hubspot':
                        this.card.status = this.current_company.hubspot_integration_enabled
                        break
                    case 'slack':
                        this.card.status = this.current_company.slack_integration_enabled
                        break
                    case 'stripe':
                        this.card.status = this.current_company.stripe_integration_enabled
                        break
                    case 'zoho':
                        this.card.status = this.current_company.zoho_integration_enabled
                        break
                    case 'sendgrid':
                        this.card.status = this.current_company.sendgrid_integration_enabled
                        if (this.card.credentials.api_token) {
                            this.apiTokenTestStatus = this.WebhookTestStatuses.SUCCESS
                        }
                        break
                    case 'mailgun':
                        this.card.status = this.current_company.mailgun_integration_enabled
                        if (this.card.credentials.api_token) {
                            this.apiTokenTestStatus = this.WebhookTestStatuses.SUCCESS
                            this.mailgunTestAndGetDomains(this.card.credentials.api_token, this.card.settings.domain)
                        }
                        break
                    case 'guesty':
                        this.card.status = this.current_company.guesty_integration_enabled
                        break
                    case 'simpsocial':
                        this.card.status = this.current_company.simpsocial_integration_enabled
                        break
                    case 'gong':
                        this.card.status = this.current_company.gong_integration_enabled
                        if (
                            (this.card.credentials.access_key && this.card.credentials.access_key_secret) ||
                            (this.card.credentials.access_token)
                        ) {
                            this.loading_workspaces = true
                            this.getGongWorkspaces()
                        }
                        break
                    case 'facebook':
                        this.card.status = this.current_company.facebook_integration_enabled
                        if (this.card.credentials.access_token) {
                            this.loading_pages = true
                            this.getFacebookPages()
                            if (this.card.settings.page_id && this.card.settings.page) {
                                this.getLeadgenForms()
                            }
                        }
                        break
                    case 'custom_link_builder':
                        this.card.status = this.current_company.custom_link_builder_integration_enabled
                        if (this.card?.settings?.selected_contact?.id) {
                            this.getContact(this.card.settings.selected_contact.id)
                        }
                        break
                    case 'gohighlevel':
                        this.card.status = this.current_company.gohighlevel_integration_enabled
                        this.getGoHighLevelSmsCampaigns()
                        break
                    case 'salesforce':
                        this.card.status = this.card.settings.enabled || this.current_company.salesforce_integration_enabled
                        break
                }

                this.card.connection_status = res.data.integration?.connection_status || 0
                this.card.last_connection_error = res.data.integration?.last_connection_error || ''
                this.card.name = this.cardname
                this.card.updated_by = res.data.integration?.updated_by
                this.initializeForm()
                this.loading = false

                return Promise.resolve(res)
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading = false

                return Promise.reject(err)
            })
        },

        getGoHighLevelSmsCampaigns() {
            axios.get('/api/v1/integration/gohighlevel/sms-campaigns').then((res) => {
                this.listSmsCampaigns = res.data
                console.log(res)
            }).catch((err) => {
                this.$root.handleErrors(err.response)
            })
        },

        getGongWorkspaces() {
            axios.get('/api/v1/integration/gong/workspaces').then((res) => {
                this.loading_workspaces = false
                this.gong_workspaces = res.data
                if (!this.card.settings.workspace_id && this.gong_workspaces.length) {
                    this.card.settings.workspace_id = this.gong_workspaces[0].id
                    this.customPreValidateForm()
                    this.submit('gong', false)
                }
            }).catch((err) => {
                this.loading_workspaces = false
                this.$root.handleErrors(err.response)
            })
        },

        getFacebookPages() {
            axios.get('/api/v1/integration/facebook/pages').then((res) => {
                this.loading_pages = false
                this.facebook_pages = res.data
                if (!this.card.settings.page && this.facebook_pages.length) {
                    this.card.settings.page = this.facebook_pages[0]
                    this.customPreValidateForm()
                    this.submit('facebook', false)
                }
            }).catch((err) => {
                this.loading_pages = false
                this.$root.handleErrors(err.response)
            })
        },

        setFormRules() {
            this.rules = _.clone(default_rules)
            switch (this.cardname) {
                case 'mailgun':
                    this.rules.credentials['api_token'] = [
                        {
                            required: true,
                            message: 'Please provide a API token',
                            trigger: 'blur',
                        }
                    ]
                    this.rules.settings['domain'] = [
                        {
                            required: true,
                            message: 'Please provide a mailgun domain',
                            trigger: 'blur',
                        }
                    ]
                    this.rules.settings['sender_address'] = [
                        {
                            required: true,
                            message: 'Please provide a mailgun email address',
                            trigger: 'blur',
                            type: 'email'
                        }
                    ]
                    this.rules.settings['sender_name'] = [
                        {
                            required: true,
                            message: 'Please provide a sender name',
                            trigger: 'blur',
                        }
                    ]
                    break
                case 'sendgrid':
                    this.rules.settings['sender_address'] = [
                        {
                            required: true,
                            message: 'Please provide a sender address',
                            trigger: 'blur',
                            type: 'email'
                        }
                    ]
                    break
                case 'SMTP':
                    this.rules.settings['sender_address'] = [{
                        required: true,
                        message: 'Please provide a sender address',
                        trigger: 'blur',
                        type: 'email'
                    }]
                    this.rules.settings['sender_name'] = [{
                        required: true,
                        message: 'Please provide a sender name',
                        trigger: 'blur'
                    }]
                    this.rules.settings['address'] = [{
                        required: true,
                        message: 'Please provide a server address',
                        trigger: 'blur'
                    }]
                    this.rules.settings['port'] = [{
                        required: true,
                        message: 'Please provide a server port',
                        trigger: 'blur'
                    }]
                    this.rules.settings['encryption'] = [{
                        required: true,
                        message: 'Please provide the encryption being used',
                        trigger: 'blur'
                    }]
                    this.rules.credentials['username'] = [{
                        required: true,
                        message: 'Please provide a username',
                        trigger: 'blur'
                    }]
                    this.rules.credentials['password'] = [{
                        required: true,
                        message: 'Please provide a password',
                        trigger: 'blur'
                    }]
                    break
                case 'guesty':
                    this.rules.credentials = []
                    this.rules.credentials['api_token'] = [
                        {
                            required: true,
                            message: 'Please provide a Integrations Token',
                            trigger: 'blur',
                        }
                    ]
                    break
                case 'gong':
                    // authentication validation rules
                    this.rules.credentials = []
                    if (this.card.credentials.hasOwnProperty('access_token') && this.card.credentials['access_token'] !== '') {
                        this.rules.credentials['access_key'] = [
                            {
                                required: true,
                                message: 'Please provide an access key',
                                trigger: 'blur',
                            }
                        ]
                        this.rules.credentials['access_key_secret'] = [
                            {
                                required: true,
                                message: 'Please provide an access key secret',
                                trigger: 'blur',
                            }
                        ]
                    }

                    // settings validation rules
                    if (this.current_company.gong_integration_enabled && this.gong_workspaces.length > 0) {
                        this.rules.settings = []
                        this.rules.settings['workspace_id'] = [
                            {
                                required: true,
                                message: 'Please select a workspace',
                                trigger: 'change',
                            }
                        ]
                    }
                    break
                case 'zoho':
                    // settings validation rules
                    if (this.current_company.zoho_integration_enabled && !this.card.settings.module) {
                        this.rules.settings = []
                        this.rules.settings['module'] = [
                            {
                                required: true,
                                message: 'Please select a module',
                                trigger: 'change',
                            }
                        ]
                    }
                    break
                case 'custom_link_builder':
                    this.rules.settings = []
                    this.rules.settings['custom_link'] = [
                        {
                            validator: this.urlValidator,
                            trigger: 'blur',
                        }
                    ]
                    break
                default:
                    break
            }
            this.rules.settings['filters'] = {
                create_deal_disposition_status_ids: [
                    {
                        validator: this.dealCreationDispositionStatusesValidator
                    }
                ],
                disposition_status_ids: [
                    {
                        validator: this.dispositionStatusesValidator
                    }
                ],
                skip_line_ids: [
                    {
                        validator: this.skipLineIdsValidator
                    }
                ],
                call_duration_type: [
                    {
                        validator: this.callDurationTypeValidator
                    }
                ],
                call_duration: [
                    {
                        validator: this.callDurationValidator
                    }
                ],
                direction: [
                    {
                        validator: this.directionValidator
                    }
                ],
                type: [
                    {
                        validator: this.typeValidator
                    }
                ],
                communication_disposition_statuses: [
                    {
                        validator: this.communicationDispositionStatusesValidator
                    }
                ]
            }
        },

        async saveSettings() {
            console.log(`Saving ${this.cardname} settings`)
            this.saveLoading = true
            let previousConfig = null

            if (this.cardname === 'salesforce') {
              previousConfig = this.card.settings.contact_settings
              this.card.settings.contact_settings = this.$refs.salesforceContactSettings.getSettings()
            }

            // call the save function from the property mapper
            const componentMapper = this.$refs.propertyMapper

            const previousModule = previousConfig?.enable_contact ? 'contact' : 'lead'
            const currentModule = this.card.settings?.contact_settings?.enable_contact ? 'contact' : 'lead'

            const shouldSaveMappingsSalesforce = this.cardname === 'salesforce' && previousModule === currentModule
            
            if (componentMapper && (this.cardname !== 'salesforce' || shouldSaveMappingsSalesforce)) {
                const mappingsRes = await componentMapper.saveMappings()
                if (!mappingsRes) {
                    this.saveLoading = false
                    return Promise.reject(new Error('Missing contact property mapping value'))
                }
            }

            this.card.name = this.cardname
            return axios.post('/api/v1/integration', this.card).then(res => {
                this.saveLoading = false
                return Promise.resolve(res)
            }).catch(err => {
                this.saveLoading = false
                this.$root.handleErrors(err.response)
                return Promise.reject(err)
            })
        },

        async testAndSave(cardname) {
            console.log('Test and save', {cardname})
            switch (cardname) {
                case 'sendgrid':
                case 'mailgun':
                case 'SMTP':
                    await this.checkCompanyUpdatedInfo()
                    if (this.hasOtherEmailIntegrationEnabled(cardname)) {
                        break
                    }
                    this.testApiToken(cardname).then(() => {
                        this.submit(cardname)
                    }).catch((err) => {
                        if (err && err.response) {
                            this.$root.handleErrors(err.response)
                        }
                    })
                    break
                default:
                    return false
            }
        },

        checkAndSave() {
            // if there is one other integration enabled (HubSpot, Pipedrive, Zoho, Guesty, GHL)
            // prevent from saving
            if (this.isStatusToggleDisabled) {
                return false
            }

            if (this.card.status === false) {
                this.saveSettings().then(res => {
                    // reload page
                    window.location.reload()
                })
            }
        },

        submit(cardname, should_refresh = true) {
            if (!this.validateForm(cardname)) {
                return
            }

            // Manually toggle this since hide Integration status if it's disabled
            this.card.status = true;

            this.saveSettings().then(res => {
                let notification_title = cardname.split('-')
                for (let index in notification_title) {
                    notification_title[index] = notification_title[index].charAt(0).toUpperCase() + notification_title[index].substr(1)
                }
                if (should_refresh) {
                    // reload page
                    window.location.reload()
                } else {
                    if (this.cardname !== 'custom_link_builder') {

                        if (this.cardname === 'google-calendar') {
                            this.card.settings.calendar.timeZone = JSON.parse(res.data.settings).calendar.timeZone;
                        }

                        this.$notify({
                            offset: 95,
                            title: notification_title.join(' '),
                            message: 'Settings saved successfully.',
                            type: 'success'
                        })
                    }
                }
            }).catch(err => {
                this.$root.handleErrors(err.response)
            })
        },

        goBack: function () {
            this.$emit('back')
        },

        resetForm(form_name) {
            let form_element = _.get(this.$refs, form_name, null)

            if (form_element) {
                form_element.resetFields()
            }

            if (form_name === 'salesforce') {
              this.$refs.salesforceContactSettings.resetSalesforceSettings()
            }

            this.card.settings.filters = _.clone(default_filters)
            this.customPreValidateForm(true)
            this.mailgun_domains = []
            this.apiTokenTestStatus = WebhookTestStatuses.NOT_TESTED
        },

        oauth(cardName, options = null) {
            let params = '';
            if (options) {
                params = `?${this.$options.filters.queryString(options)}`
            }
            window.location.href = `/integrations/${cardName}/oauth${params}`
        },

        testApiToken(cardname) {
            if (this.validateForm(cardname)) {
                this.testLoading = true
                return axios.post(`/api/v1/integration/${cardname}/test`, this.card).then((res) => {
                    this.testLoading = false
                    this.apiTokenTestStatus = this.WebhookTestStatuses.SUCCESS
                    this.initializeForm()

                    return Promise.resolve(res)
                }).catch((err) => {
                    this.testLoading = false
                    this.apiTokenTestStatus = this.WebhookTestStatuses.FAILED
                    this.initializeForm()

                    return Promise.reject(err)
                })
            } else {
                this.initializeForm()

                return Promise.reject()
            }
        },

        initializeForm() {
            this.setFormRules()
            setTimeout(() => {
                this.customPreValidateForm()
            }, 100)
        },

        async checkCompanyUpdatedInfo() {
            return this.getCompanyUpdatedInfo(this.current_company.id).then((res) => {
                this.company_updated = res.data
                if (this.company_updated.mailgun_integration_enabled || this.company_updated.sendgrid_integration_enabled || this.card.status) {
                    this.api_key_input = true
                } else {
                    this.api_key_input = !this.api_key_input
                }
                this.setCurrentCompany(this.company_updated)
            }).catch((err) => {
                console.log(err.response)
            })
        },

        getCompanyUpdatedInfo(company_id) {
            this.testLoading = true
            return axios.get(`/api/v1/company/${company_id}`).then((res) => {
                this.testLoading = false
                return Promise.resolve(res)
            }).catch((err) => {
                this.testLoading = false
                return Promise.reject(err)
            })
        },

        /** this will test and get domains of mailgun at them same time **/
        mailgunTestAndGetDomains(token, domain = null) {
            if (!domain) {
                this.card.settings.domain = null
            }
            this.mailgun_testing_status = true
            axios.post(`/api/v1/integration/mailgun/test`, {credentials: {api_token: token}}).then((res) => {
                this.mailgun_testing_status = false
                this.card.status = true
                if (res.data.items.length == 1 && !domain) {
                    this.card.settings.domain = res.data.items[0].name
                }
                this.mailgun_domains = res.data.items
                this.apiTokenTestStatus = this.WebhookTestStatuses.SUCCESS
                this.initializeForm()
            }).catch((err) => {
                this.mailgun_testing_status = false
                this.apiTokenTestStatus = this.WebhookTestStatuses.FAILED
                this.initializeForm()
            })
        },

        customPreValidateForm(reset = false) {
            this.preValidateForm(this.cardname, reset)
        },

        changeSkipLines(val) {
            this.card.settings.filters.skip_line_ids = val
            this.customPreValidateForm()
        },

        changeSyncContactsWithDisposition(val) {
            this.card.settings.filters.disposition_status_ids = val
            this.customPreValidateForm()
        },

        changeCreateDealWhenDisposed(val) {
            this.card.settings.filters.create_deal_disposition_status_ids = val
            this.customPreValidateForm()
        },

        setFacebookPage() {
            if (this.card.settings.page_id) {
                this.card.settings.page = this.facebook_pages.find(facebook_page => facebook_page.id == this.card.settings.page_id)
            }
            this.customPreValidateForm()
            this.resetLeadgenForm()
            this.loading_leadgen_forms = true
            this.saveSettings().then(_ => {
                this.getLeadgenForms()
            })
        },

        setWorkflow(val) {
            this.card.settings.workflow_id = val
            this.customPreValidateForm()
            this.resetLeadgenForm()
            this.loading_leadgen_forms = true
            this.saveSettings().then(_ => {
                this.getLeadgenForms()
            })
        },

        resetLeadgenForm() {
            this.leadgen_form_id = null
            this.leadgen_forms = []
        },

        getLeadgenForms() {
            this.resetLeadgenForm()
            this.loading_leadgen_forms = true
            axios.get('/api/v1/integration/facebook/leadgen-forms').then((res) => {
                this.loading_leadgen_forms = false
                this.leadgen_forms = res.data
            }).catch((err) => {
                this.loading_leadgen_forms = false
                this.$root.handleErrors(err.response)
            })
        },

        pullFacebookLeads() {
            this.loading_pull_leadgen_leads = true
            axios.post('/api/v1/integration/facebook/pull-leadgen-form-leads', {
                leadgen_form_id: this.leadgen_form_id
            }).then((res) => {
                this.loading_pull_leadgen_leads = false
                this.$notify({
                    offset: 95,
                    title: 'Facebook',
                    message: 'Contacts are being pulled from the selected leadgen form, please wait for a few minutes for this process to finish. You will receive an email when we finish processing your request.',
                    type: 'success'
                })
            }).catch((err) => {
                this.loading_pull_leadgen_leads = false
                this.$root.handleErrors(err.response)
            })
        },

        async pullSalesforceLeads() {
            try {
                this.loading_salesforce_pull_leads = true
                await axios.post('/api/v1/integration/salesforce/pull-leads')

                this.$notify({
                    offset: 95,
                    title: 'Salesforce',
                    message: 'Leads are being pulled from the Salesforce, please wait for a few minutes for this process to finish. You will receive an email when we finish processing your request.',
                    type: 'success'
                })

                this.loading_salesforce_pull_leads = false
            } catch (err) {
                this.loading_salesforce_pull_leads = false
                this.$root.handleErrors(err.response)
            }
        },

        variableSelected(variable) {
            if (this.card.settings.tags.some(tag => tag.name === variable)) {
                return
            }
            this.card.settings.tags.push({name: variable, type: 'success'})

            if (this.card.settings.prefix != '' && this.card.settings.prefix !== null) {
                variable = this.card.settings.prefix + variable
            }
            this.card.settings.custom_link = (this.card.settings.custom_link ?? '') + variable
            this.getNewCustomLink()
        },

        selectContact(contact) {
            this.card.settings.selected_contact = contact
            this.getNewCustomLink()
        },

        removeTag(tag) {
            this.card.settings.custom_link = this.card.settings.custom_link.replace(tag.name, "")
            this.card.settings.tags.splice(this.card.settings.tags.indexOf(tag), 1)
            this.getNewCustomLink()
        },

        getNewCustomLink: _.debounce(async function () {
            if (!this.validateForm(this.cardname)) {
                return
            }
            if (this.card.settings?.selected_contact === null) {
                return
            }
            if (this.card.settings.selected_contact === undefined) {
                return
            }
            // Save prefix to another prop for replacePrefix function
            this.card.settings.currently_saved_prefix = this.card.settings.prefix
            // Save settings in order to update custom link to be fetched below
            await this.saveSettings()
            // Call contact api then update selected contact data
            const contact_id = this.card.settings.selected_contact.id
            this.getContact(contact_id)
        }, 300),

        replacePrefix() {
            if (this.card.settings.custom_link == '' && this.card.settings.prefix != '') {
                return false
            }
            this.card.settings.custom_link = this.card.settings.custom_link.replaceAll(
                this.card.settings.currently_saved_prefix + '[',
                this.card.settings.prefix + '['
            )
            this.getNewCustomLink()
        },

        getContact(contact_id) {
            axios.get(`/api/v1/contact/${contact_id}`).then((res) => {
                this.card.settings.selected_contact = res.data
            }).catch((err) => {
                this.$root.handleErrors(err.response)
            })
        },

        setCalendar(calendar_id, summary) {
            this.$notify({
                offset: 95,
                title: 'Setting Updated',
                message: `Calendar ${summary} successfully linked`,
                type: 'success'
            })
        },

        pushCalendar() {
            this.loading_push = true
            this.$notify({
                offset: 95,
                title: 'Google Calendar Integration',
                message: `Pushing Aloware Calendar Events into your Google Calendar.`,
                type: 'success'
            })
            axios.get('/api/v1/integration/google-calendar/push-upcoming-events')
                .then(res => {
                    console.log(res)
                    this.loading_push = false
                })
                .catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.loading_push = false
                })
        },

        pullCalendar() {
            this.loading_pull = true
            this.$notify({
                offset: 95,
                title: 'Google Calendar Integration',
                message: `Pulling Google Calendar Events into your Aloware Calendar.`,
                type: 'success'
            })
            axios.get('/api/v1/integration/google-calendar/pull-upcoming-events')
                .then(res => {
                    console.log(res)
                    this.loading_pull = false
                })
                .catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.loading_pull = false
                })
        },

        watchCalendar() {
            let message = `Aloware is watching your Google Calendar for new events created, updated or cancelled.`;
            if (this.shouldWatchGoogleCalendar) {
                message = `Aloware stopped watching your Google Calendar Events.`
            }

            const _this = this

            axios.post('/api/v1/integration/google-calendar/toggle-watcher', {current_status: this.shouldWatchGoogleCalendar})
                .then(res => {

                    _this.card.settings.push_notification_channel = _this.shouldWatchGoogleCalendar ? {} : {token: "token"}

                    _this.$notify({
                        offset: 95,
                        title: 'Automatic Sync',
                        message,
                        type: 'success'
                    })
                })
                .catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },

        stopCalendar() {
            console.log('stop watching calendar')
        },

        firstLetterCapitalized(text) {
            return text.charAt(0).toUpperCase() + text.slice(1);
        },

        openPrismaticConfig() {
            this.loading = true
            this.show_prismatic_dialog = true
        },

        ...mapActions('cache', ['setCurrentCompany'])
    },

    beforeCreate() {
        axios.get('/build-info.json').then(res => {
            this.env = res.data.env
        }).catch(err => {
            console.log(err)
        })
    },

    watch: {
        'card.settings.http_method': function () {
            this.initializeForm()
        },

        'card.settings.auth_method': function () {
            this.initializeForm()
        },

        'card.settings.filters.filter_communication_disposition_status': function (newVal) {
            if (!newVal) {
                this.card.settings.filters.communication_disposition_statuses = []
            }
        },

        'card.settings.filters.filter_type': function (newVal) {
            if (!newVal) {
                this.card.settings.filters.type = [CommunicationTypes.CALL, CommunicationTypes.SMS, CommunicationTypes.RVM, CommunicationTypes.EMAIL, CommunicationTypes.APPOINTMENT, CommunicationTypes.REMINDER]
            }
        },

        'card.settings.filters.filter_direction': function (newVal) {
            if (!newVal) {
                this.card.settings.filters.direction = [CommunicationDirections.INBOUND, CommunicationDirections.OUTBOUND]
            }
        },

        'card.settings.filters.contacts': function (newVal) {
            if (!newVal) {
                this.card.settings.filters.disposition_status_ids = []
            }
        },

        'card.settings.filters.filter_lines': function (newVal) {
            if (!newVal) {
                this.card.settings.filters.skip_line_ids = []
                this.customPreValidateForm()
            }
        },

        'card.credentials.access_key': function (newVal, oldVal) {
            if (newVal && oldVal) {
                this.card.settings.workspace_id = null
                this.gong_workspaces = []
            }
        },

        'card.credentials.access_key_secret': function (newVal, oldVal) {
            if (newVal && oldVal) {
                this.card.settings.workspace_id = null
                this.gong_workspaces = []
            }
        },

        'card.settings.filters.filter_call_duration': function (newVal) {
            if (!newVal) {
                this.card.settings.filters.call_duration = null
                this.card.settings.filters.call_duration_type = null
            }
        },

        'card.settings.filters.call_duration_type': function (newVal) {
            if (!newVal) {
                this.card.settings.filters.call_duration_type = null
            }
        },

        'card.settings.filters.call_duration': function (newVal) {
            if (!newVal) {
                this.card.settings.filters.call_duration = null
            }
        },
    }
}
</script>

<style scoped>
.vertical-checkboxes {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
</style>
