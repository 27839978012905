var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body", attrs: { id: "view" } },
    [
      _c("aup-consent", {
        on: {
          loaded: function ($event) {
            _vm.loading_aup = false
          },
        },
      }),
      _vm._v(" "),
      _vm.hasPermissionTo("create broadcast message") &&
      _vm.auth.user.profile.bulk_sms_enabled &&
      !_vm.loading_aup
        ? _c("div", { staticClass: "container-fluid pt-3 d-relative" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "box col-md-8",
                      staticStyle: { "z-index": "1" },
                    },
                    [
                      _c("div", { staticClass: "no-effect" }, [
                        _c(
                          "div",
                          { staticClass: "box-body pt-3" },
                          [
                            _c(
                              "el-form",
                              {
                                ref: "bulk_message_form",
                                attrs: {
                                  "label-position": "top",
                                  model: _vm.bulk_message,
                                  rules: _vm.rules,
                                },
                                nativeOn: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return _vm.preventSubmit.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "name", label: "Name:" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "width-400" },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { prop: "name" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "Optional",
                                                maxlength: "190",
                                                "show-word-limit": "",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.preValidateForm(
                                                    "bulk_message_form"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.bulk_message.name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.bulk_message,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression: "bulk_message.name",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "From:",
                                      prop: "campaign_id",
                                    },
                                  },
                                  [
                                    _c("campaign-selector", {
                                      ref: "campaign_selector",
                                      staticStyle: { width: "400px" },
                                      attrs: {
                                        placeholder: "Lines",
                                        clearable: "",
                                        is_automated: "",
                                        check_blocked_messaging: "",
                                        value: _vm.bulk_message.campaign_id,
                                        capabilities: _vm.capabilities,
                                      },
                                      on: { change: _vm.updateCampaignId },
                                    }),
                                    _vm._v(" "),
                                    _c("el-popover", {
                                      ref: "popover_campaign",
                                      staticClass: "ml-2",
                                      attrs: {
                                        placement: "right",
                                        title: "Line",
                                        width: "400",
                                        trigger: "hover",
                                        content:
                                          "The line you want to send the bulk messages campaign from.",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "popover",
                                            rawName:
                                              "v-popover:popover_campaign",
                                            arg: "popover_campaign",
                                          },
                                        ],
                                        staticClass: "form-hover-help",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            What is this?\n                                        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mt-5" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label:
                                              "Select a group of contacts:",
                                          },
                                        },
                                        [
                                          _c("contact-tags-filters-selector", {
                                            ref: "contactTagFilterSelector",
                                            attrs: {
                                              show_integrations: true,
                                              tag_category:
                                                _vm.TagCategories.CAT_CONTACTS,
                                            },
                                            on: {
                                              contactTagsGroupChanged:
                                                _vm.contactTagsGroupChanged,
                                              hubspotListChanged:
                                                _vm.hubspotListChanged,
                                              zohoViewChanged:
                                                _vm.zohoViewChanged,
                                              pipedriveFilterChanged:
                                                _vm.pipedriveFilterChanged,
                                              resetTagSelector:
                                                _vm.resetTagSelector,
                                              resetContactFiltersSelector:
                                                _vm.resetContactFiltersSelector,
                                              resetIntegrationsSelector:
                                                _vm.resetIntegrationsSelector,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mt-3 mb-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12" },
                                    [
                                      _c("broadcast-schedule", {
                                        ref: "broadcast_schedule",
                                        attrs: { message: _vm.bulk_message },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Throttling:",
                                      prop: "throttle_limit",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          placement: "top",
                                          disabled: _vm.mpsTooltip === "",
                                        },
                                      },
                                      [
                                        _c("div", {
                                          attrs: { slot: "content" },
                                          domProps: {
                                            innerHTML: _vm._s(_vm.mpsTooltip),
                                          },
                                          slot: "content",
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "no-select",
                                            staticStyle: { width: "400px" },
                                            attrs: { placeholder: "Options" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.preValidateForm(
                                                  "bulk_message_form"
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.bulk_message.throttle_limit,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.bulk_message,
                                                  "throttle_limit",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "bulk_message.throttle_limit",
                                            },
                                          },
                                          _vm._l(
                                            _vm.throttlingOptions,
                                            function (option) {
                                              return _c(
                                                "el-option",
                                                {
                                                  key: option.id,
                                                  attrs: {
                                                    label: option.name,
                                                    value: option.value,
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(_vm._s(option.name)),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("el-popover", {
                                      ref: "popover_throttle_limit",
                                      staticClass: "ml-2",
                                      attrs: {
                                        placement: "right",
                                        title: "Throttling",
                                        width: "400",
                                        trigger: "hover",
                                        content:
                                          "This is an hourly throttling limit on your bulk message campaign.",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "popover",
                                            rawName:
                                              "v-popover:popover_throttle_limit",
                                            arg: "popover_throttle_limit",
                                          },
                                        ],
                                        staticClass: "form-hover-help",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            What is this?\n                                        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-3" }, [
                                  _c("div", { staticClass: "col-12 d-flex" }, [
                                    !_vm.bulk_message.view_id &&
                                    !_vm.bulk_message.filter_id
                                      ? _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "loading",
                                                rawName: "v-loading",
                                                value: _vm.loading_count,
                                                expression: "loading_count",
                                              },
                                            ],
                                            staticClass:
                                              "box pt-2 pb-2 bulk-sms-counter pull-left mr-2",
                                            style:
                                              _vm.contacts_count == 0
                                                ? {
                                                    border: "solid 1px #C4183C",
                                                  }
                                                : {},
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "inline p-x text-center",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "h4 block m-a-0",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.contacts_count)
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass:
                                                      "text-xs text-muted",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.contacts_count >
                                                          1 ||
                                                          _vm.contacts_count ==
                                                            0
                                                          ? "Contacts"
                                                          : "Contact"
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.current_company &&
                                    _vm.current_company.reseller_id != 357 &&
                                    !_vm.bulk_message.view_id
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "box pt-2 pb-2 bulk-sms-counter pull-left mr-2",
                                            class: _vm.getClass,
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "inline p-x text-center",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "h4 block m-a-0",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("toCurrency")(
                                                          _vm._f("fixRounding")(
                                                            _vm.messagesPrice
                                                          )
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass:
                                                      "text-xs text-muted",
                                                  },
                                                  [_vm._v("Credits")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.current_company &&
                                    _vm.current_company.reseller_id != 357
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "box pt-2 pb-2 bulk-sms-counter pull-left",
                                            class: _vm.segmentsCounterClass,
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "inline p-x text-center",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "h4 block m-a-0",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.messageCount)
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass:
                                                      "text-xs text-muted",
                                                  },
                                                  [_vm._v("Segments")]
                                                ),
                                                _vm._v(" "),
                                                _vm.hasMoreThanAscii
                                                  ? _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-xs text-muted",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                        with non ASCII Characters\n                                                    "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  !_vm.isSimpSocial
                                    ? _c("div", { staticClass: "col-12" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "warning-card rounded d-flex flex-column mt-3 p-3",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                This calculator is meant to provide the best estimate for the cost of the broadcast. Actual charges from carriers may vary. "
                                            ),
                                            _vm.selected_campaign &&
                                            _vm.contacts_count
                                              ? _c("span", [
                                                  _vm._v(
                                                    "Estimated carrier surcharges of $" +
                                                      _vm._s(_vm.carrierFee) +
                                                      " will also apply."
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2 mt-5" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c(
                                      "div",
                                      { staticClass: "nav-active-greenish" },
                                      [
                                        _c(
                                          "ul",
                                          { staticClass: "nav nav-tabs" },
                                          [
                                            _c(
                                              "li",
                                              { staticClass: "nav-item" },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "border border-right-0 rounded-top-left nav-link active",
                                                    attrs: {
                                                      href: "",
                                                      "data-toggle": "tab",
                                                      "aria-expanded": "true",
                                                      "data-target":
                                                        "#bulk_message",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.preventSubmit.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "material-icons",
                                                      },
                                                      [_vm._v("message")]
                                                    ),
                                                    _vm._v(
                                                      " Message\n                                                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tab-content p-a m-b-md border",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "tab-pane animated fadeIn text-muted active",
                                                attrs: {
                                                  id: "bulk_message",
                                                  "aria-expanded": "true",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "col-lg-8",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "pull-right",
                                                          },
                                                          [
                                                            _c("b", [
                                                              _vm._v("Limit:"),
                                                            ]),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.smartEncodedMessageLength
                                                                ) +
                                                                " / 1600"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      prop: "message_body",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-lg-8",
                                                          },
                                                          [
                                                            _c(
                                                              "input-with-info-text",
                                                              {
                                                                staticClass:
                                                                  "pt-1 pb-1",
                                                                attrs: {
                                                                  "info-text":
                                                                    "[Text <b>STOP</b> to unsubscribe]",
                                                                  placeholder:
                                                                    "Compose your message:",
                                                                  "model-value":
                                                                    _vm
                                                                      .bulk_message
                                                                      .message_body,
                                                                  "is-loading":
                                                                    _vm.generatingUrls,
                                                                  "is-info-text-active":
                                                                    _vm.is_optout_active,
                                                                  rows: 2,
                                                                  "left-pixels": 12,
                                                                  "bottom-pixels": 2,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .bulk_message
                                                                      .message_body,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.bulk_message,
                                                                        "message_body",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "bulk_message.message_body",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-lg-4",
                                                          },
                                                          [
                                                            _c(
                                                              "variable-dialog",
                                                              {
                                                                attrs: {
                                                                  btn_class:
                                                                    "w-full",
                                                                  in_input_group: false,
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c("br"),
                                                            _vm._v(" "),
                                                            _c(
                                                              "template-list-dialog",
                                                              {
                                                                attrs: {
                                                                  btn_class:
                                                                    "w-full",
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c("el-popover", {
                                                              ref: "popover_message",
                                                              staticClass:
                                                                "ml-2",
                                                              attrs: {
                                                                placement:
                                                                  "right",
                                                                title:
                                                                  "Message",
                                                                width: "400",
                                                                trigger:
                                                                  "hover",
                                                                content:
                                                                  "The text message you want to send to the selected group of contacts. If the user has no contact name or 'Aloware Contact' as the name, then the variable will be blank",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "popover",
                                                                    rawName:
                                                                      "v-popover:popover_message",
                                                                    arg: "popover_message",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "form-hover-help",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                                        What is this?\n                                                                    "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-checkbox",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm
                                                                      .current_company
                                                                      .force_opt_out_phrase,
                                                                },
                                                                on: {
                                                                  change:
                                                                    _vm.imposeMessageCharLimit,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.is_optout_active,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.is_optout_active =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "is_optout_active",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-tooltip",
                                                                  {
                                                                    staticClass:
                                                                      "item",
                                                                    attrs: {
                                                                      effect:
                                                                        "dark",
                                                                      placement:
                                                                        "bottom",
                                                                      disabled:
                                                                        !_vm.isOptoutForced,
                                                                      content:
                                                                        _vm.optout_tooltip_text,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Add opt-out phrase for this message"
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.current_company
                                                              .simpsocial_integration_enabled
                                                              ? _c("div", [
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "el-button",
                                                                        {
                                                                          staticClass:
                                                                            "mb-2 w-full",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return _vm.openCarForm.apply(
                                                                                  null,
                                                                                  arguments
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fa fa-car",
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            "\n                                                                                Inventory\n                                                                            "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "el-button",
                                                                        {
                                                                          staticClass:
                                                                            "w-full",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return _vm.sendCreditApplicationLink.apply(
                                                                                  null,
                                                                                  arguments
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fa fa-edit",
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            "\n                                                                                Credit Application\n                                                                            "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-md-8",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "pull-right ml-4",
                                                              },
                                                              [
                                                                _c("b", [
                                                                  _vm._v(
                                                                    "Message parts:"
                                                                  ),
                                                                ]),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.messageTtsCount
                                                                    ) +
                                                                    " / " +
                                                                    _vm._s(
                                                                      _vm.charactersPerPage
                                                                    ) +
                                                                    "\n\n                                                                    "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "pull-right",
                                                                class:
                                                                  _vm.messageCounterClass,
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                                        Message(s): " +
                                                                    _vm._s(
                                                                      _vm.messageCount
                                                                    ) +
                                                                    "\n                                                                    "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm.showMessageSentAsMmsDueToAssets
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "warning-card rounded d-flex flex-column p-3 mt-5",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                            The message will be sent via MMS because it has an attachment or GIF attached to it, which may lead to higher-than-expected charges for this broadcast.\n                                                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm.showMessageSentAsMmsWarning
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "warning-card rounded d-flex flex-column p-3 mt-5",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                            The selected line is configured to send long messages via MMS, which may lead to higher-than-expected charges for this broadcast.\n                                                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm.showMessageSentFromTollFreeNumberWarning
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "warning-card rounded d-flex flex-column p-3 mt-5",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                            The selected line is configured with a Toll-free Number, which may lead to higher-than-expected charges for this broadcast.\n                                                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm.showMessageSentFromTollFreeNumberAsMmsWarning
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "warning-card rounded d-flex flex-column p-3 mt-5",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                            The selected line is configured with a Toll-free Number and to send long messages via MMS which may lead to higher-than-expected charges for this broadcast.\n                                                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2 mt-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12" },
                                    [
                                      _c(
                                        "el-form-item",
                                        [
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#00a344",
                                              "active-value": 1,
                                              "inactive-value": 0,
                                            },
                                            on: { change: _vm.changeMMSSwitch },
                                            model: {
                                              value: _vm.mms_switch,
                                              callback: function ($$v) {
                                                _vm.mms_switch = $$v
                                              },
                                              expression: "mms_switch",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "el-form-item__label ml-2 float-none",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                    Send a GIF / MMS\n                                                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.bulk_message.file_name !== null &&
                                      _vm.mms_switch
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label:
                                                  "Media File or GIF (Optional):",
                                                prop: "file_name",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-start align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-sm blue mr-3",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.removeMediaFileOrGif(
                                                            _vm.active_tab
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-sync-alt",
                                                      }),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v("Change"),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-sm btn-danger",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.removeMediaFileOrGif(
                                                            _vm.active_tab
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-trash",
                                                      }),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v("Remove"),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm.mms_switch
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "nav-active-greenish",
                                            },
                                            [
                                              _c(
                                                "ul",
                                                { staticClass: "nav nav-tabs" },
                                                [
                                                  _c(
                                                    "li",
                                                    { staticClass: "nav-item" },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "border border-right-0 rounded-top-left",
                                                          class:
                                                            _vm.uploadsNavClass
                                                              .media,
                                                          attrs: {
                                                            href: "",
                                                            "data-toggle":
                                                              "tab",
                                                            "aria-expanded":
                                                              "true",
                                                            "data-target":
                                                              _vm.attachments
                                                                .media.target,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.clearAttachmentsAlert(
                                                                "media"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "material-icons",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "insert_photo"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " Media file\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "li",
                                                    { staticClass: "nav-item" },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "border rounded-top-right",
                                                          class:
                                                            _vm.uploadsNavClass
                                                              .gif,
                                                          attrs: {
                                                            "aria-expanded":
                                                              "false",
                                                            href: "",
                                                            "data-toggle":
                                                              "tab",
                                                            "data-target":
                                                              _vm.attachments
                                                                .gif.target,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.clearAttachmentsAlert(
                                                                "gif"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "material-icons",
                                                            },
                                                            [_vm._v("gif")]
                                                          ),
                                                          _vm._v(
                                                            " GIF file\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "tab-content p-a m-b-md border",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm
                                                          .uploadsContainerClass
                                                          .media,
                                                      attrs: {
                                                        id: "media-file",
                                                        "aria-expanded": "true",
                                                      },
                                                    },
                                                    [
                                                      _vm.active_tab === "media"
                                                        ? _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Media File:",
                                                                prop: "file_name",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-upload",
                                                                {
                                                                  attrs: {
                                                                    action:
                                                                      "/api/v1/broadcasts/upload/media",
                                                                    headers:
                                                                      _vm.headers,
                                                                    "on-success":
                                                                      _vm.onSuccessUpload,
                                                                    "on-error":
                                                                      _vm.onFailedUpload,
                                                                    "on-progress":
                                                                      _vm.progressUpload,
                                                                    "before-upload":
                                                                      _vm.beforeUpload,
                                                                    "file-list":
                                                                      _vm
                                                                        .uploadFileList
                                                                        .upload,
                                                                    limit: 1,
                                                                    multiple: false,
                                                                    drag: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-upload",
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "el-upload__text",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                                    Drop file here or "
                                                                      ),
                                                                      _c("em", [
                                                                        _vm._v(
                                                                          "click to upload"
                                                                        ),
                                                                      ]),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "el-upload__tip",
                                                                      attrs: {
                                                                        slot: "tip",
                                                                      },
                                                                      slot: "tip",
                                                                    },
                                                                    [
                                                                      _vm
                                                                        .current_company
                                                                        .id ==
                                                                      2859
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Media file such as jpg, png or gif (less than 1 MB)"
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Media file such as jpg, png or gif (less than 5 MB)"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.bulk_message
                                                                .file_name ===
                                                              null
                                                                ? _c(
                                                                    "el-progress",
                                                                    {
                                                                      attrs: {
                                                                        "text-inside": true,
                                                                        "stroke-width": 18,
                                                                        percentage:
                                                                          _vm
                                                                            .uploadPercentage
                                                                            .upload,
                                                                        status:
                                                                          _vm
                                                                            .uploadStatus
                                                                            .upload,
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.active_tab === "gif"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          class:
                                                            _vm
                                                              .uploadsContainerClass
                                                              .gif,
                                                          attrs: {
                                                            id: "search-gif",
                                                            "aria-expanded":
                                                              "false",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label: "GIF:",
                                                                prop: "file_name",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pos-rlt",
                                                                },
                                                                [
                                                                  _c(
                                                                    "search-giphy",
                                                                    {
                                                                      on: {
                                                                        selected:
                                                                          _vm.setGif,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12" },
                                    [
                                      _c(
                                        "el-card",
                                        {
                                          staticClass:
                                            "box-card help-card d-none d-sm-none d-md-block",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "text item" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "_600" },
                                                [
                                                  _vm._v(
                                                    "Tips for a successful bulk message campaign:"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "_400" },
                                                [
                                                  _vm._v(
                                                    "\n                                                        - Make sure you have enough numbers attached to the line.\n                                                        A good estimate is a new number per 200 attempts.\n                                                        Go to the line settings and add more numbers.\n                                                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "_400" },
                                                [
                                                  _vm._v(
                                                    "\n                                                        - Avoid including unbranded URL shorteners like "
                                                  ),
                                                  _c("b", [_vm._v("bit.ly")]),
                                                  _vm._v(
                                                    ".\n                                                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" "),
                                              !_vm.statics.whitelabel
                                                ? _c(
                                                    "span",
                                                    { staticClass: "_400" },
                                                    [
                                                      _vm._v(
                                                        "\n                                                        - If you have any questions about large volumes, please contact our support at support@aloware.com.\n                                                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" "),
                                              !_vm.isSimpSocial
                                                ? _c(
                                                    "span",
                                                    { staticClass: "_400" },
                                                    [
                                                      _vm._v(
                                                        "\n                                                        - Make sure you have enough credits before sending out a bulk message campaign. To recharge, please visit\n                                                        "
                                                      ),
                                                      _c(
                                                        "router-link",
                                                        {
                                                          staticClass:
                                                            "text-dark-greenish _600",
                                                          attrs: {
                                                            to: {
                                                              name: "Account",
                                                              query: {
                                                                tab: "billing",
                                                              },
                                                            },
                                                            title: "Billing",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Billing\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                        .\n                                                    "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.isSimpSocial
                                                ? _c("br")
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "_400" },
                                                [
                                                  _vm._v(
                                                    "\n                                                        - Make sure to select at least one contact.\n                                                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "_400" },
                                                [
                                                  _vm._v(
                                                    "\n                                                        - Make sure to select a line.\n                                                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "text-right mt-3" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-md greyish mr-2",
                                  on: {
                                    click: function ($event) {
                                      return _vm.resetForm("bulk_message_form")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                        Reset\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-md greenish",
                                  attrs: {
                                    disabled:
                                      _vm.loading ||
                                      !_vm.validated ||
                                      !_vm.countValidated ||
                                      _vm.isMessagingBlocked,
                                  },
                                  on: { click: _vm.sendBulkMessage },
                                },
                                [
                                  _c(
                                    "i",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.loading,
                                          expression: "loading",
                                        },
                                      ],
                                      staticClass: "material-icons loader",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                            \n                                        "
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                                        Send Bulk Message\n                                    "
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-4 pos-rlt",
                      staticStyle: { "z-index": "1" },
                    },
                    [
                      _vm.previewMessage || _vm.attachments.file_url
                        ? _c(
                            "div",
                            {
                              staticClass: "pos-fix col-lg-3 col-md-4 p-0",
                              staticStyle: { right: "10px" },
                            },
                            [
                              _c("div", { staticClass: "m-b message" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column align-items-end text-right",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "inline r-2x message-body effect7 mt-1 blue-800 text-left",
                                        staticStyle: { "max-width": "unset" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "arrow pull-top arrow-bluish right",
                                        }),
                                        _vm._v(" "),
                                        _vm.attachments.file_url !== null
                                          ? _c("vue-load-image", [
                                              _vm.attachments.file_url !== null
                                                ? _c("img", {
                                                    staticClass:
                                                      "img-fluid d-block r-2x",
                                                    attrs: {
                                                      slot: "image",
                                                      src: _vm.attachments
                                                        .file_url,
                                                    },
                                                    slot: "image",
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c("img", {
                                                attrs: {
                                                  slot: "preloader",
                                                  src: "/assets/images/loading.svg",
                                                },
                                                slot: "preloader",
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "error" },
                                                  slot: "error",
                                                },
                                                [_vm._v("Error!")]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.previewMessage
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "p-a p-y-sm handle-whitespace",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.previewMessage)
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.attachments.file_url &&
                                        !_vm.previewMessage
                                          ? _c(
                                              "div",
                                              { staticClass: "p-a p-y-sm" },
                                              [
                                                _c("i", [
                                                  _vm._v(
                                                    "Your message will appear here"
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("el-backtop", {
        ref: "backtop",
        attrs: { target: "html", bottom: 20, right: 20 },
      }),
      _vm._v(" "),
      _c("new-car", {
        key: _vm.prop_counter,
        ref: "newCarMessage",
        attrs: {
          contact_id: null,
          selected_campaign_id: _vm.bulk_message.campaign_id,
        },
        on: { "send-car": _vm.sendCar, hide: _vm.newCarFormClosed },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }