<template>
    <div class="app-body"
         id="view"
         v-if="user">
        <div class="fixed-header padding pt-0 pb-0"
             :style="{top: (top_offset+50) + 'px'}">
            <div class="row mb-0 mt-0">
                <div class="col-12 p-2">
                    <div class="d-flex">
                        <div class="d-flex flex-fill">
                            <comm-advanced-filters-wrapper data-testid="activity-comm-advance-filters"
                                                           :user_id="user_id">
                            </comm-advanced-filters-wrapper>
                        </div>
                        <div class="ml-auto" v-if="user.alo_ai_bot">
                            <router-link
                                :to="{ name: 'AloAi: Configure an agent', params: {bot_id: user.alo_ai_bot.id}}"
                                v-if="hasPermissionTo('update user') || (hasPermissionTo('update profile') && auth.user.profile.id == user_id)">
                                <button class="btn btn-md greenish ml-2"
                                        data-testid="activity-settings-button"
                                        style="height: 40px;">
                                    <i class="fa fa-cogs"></i>
                                    Settings
                                </button>
                            </router-link>
                        </div>
                        <div class="ml-auto" v-else>
                            <router-link
                                :to="{ name: 'User Dialog', params: {user_id: user_id}}"
                                v-if="hasPermissionTo('update user') || (hasPermissionTo('update profile') && auth.user.profile.id == user_id)">
                                <button class="btn btn-md greenish ml-2"
                                        data-testid="activity-settings-button"
                                        style="height: 40px;">
                                    <i class="fa fa-cogs"></i>
                                    Settings
                                </button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="section === 'Activities'">
            <div class="padding">

                <div class="padding"
                     v-if="hasPermissionTo('list report')">
                    <div class="row">
                        <div class="col-12 p-0 mt-5">
                            <new-summary-report :user_id="user_id"
                                                data-testid="activity-new-summary-report">
                            </new-summary-report>
                        </div>
                    </div>
                </div>

                <div class="padding"
                     v-if="hasPermissionTo('list report') && show_graph">
                    <div class="row box">
                        <div class="col-12">
                            <div class="no-effect">
                                <div class="box-header pb-0">
                                    <activity-graph base="user"
                                                    data-testid="user-activity-graph"
                                                    :user_id="user_id"
                                                    :is_first_load="reports_first_load">
                                    </activity-graph>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="padding"
                     v-if="hasPermissionTo('list communication')">
                    <div class="row box">
                        <div class="col-12">
                            <div class="no-effect">
                                <div class="box-body">
                                    <comm-log-table :user_id="user_id"
                                                    data-testid="activity-comm-log-table">
                                    </comm-log-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="section === 'Transcriptions' && shouldDisplayTranscriptionSections">
            <div class="padding mt-5 pt-3">
                <transcription-reporting-panel :user_id="user_id"
                                               data-testid="activity-transcription-reporting-panel"/>
            </div>
        </div>

        <el-backtop target="html"
                    ref="backtop"
                    data-testid="activity-backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import auth from '../../auth'
import {
    acl_mixin,
    chargebee_mixin,
    filter_mixin,
    scroll_mixin,
    styling_mixin,
    user_info_mixin
} from '../../mixins'
import * as AnswerTypes from '../../constants/answer-types'
import TranscriptionReportingPanel from '../../components/transcription-reporting-panel'

export default {
    components: {
        'transcription-reporting-panel': TranscriptionReportingPanel
    },

    mixins: [
        chargebee_mixin,
        scroll_mixin,
        filter_mixin,
        user_info_mixin,
        acl_mixin,
        styling_mixin
    ],

    props: {
        user_id: {
            required: true
        }
    },

    data() {
        return {
            auth: auth,
            user: null,
            AnswerTypes,
            reports_first_load: true
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company,
            show_graph: state => state.cache.show_graph
        }),

        ...mapGetters({
            section: 'getSection'
        }),

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        }
    },

    mounted() {
        this.setTitle()
        if (this.hasPermissionTo('view user')) {
            this.getUser(this.user_id).then(() => {
                VueEvent.fire('page_title_updated', this.getUserName(this.user))
            })
        }
    },

    methods: {
        setTitle() {
            axios.get('/get-statics')
                .then(res => {
                    let name = res.data.name
                    this.setPageTitle('User Activity - ' + name)
                })
                .catch(err => {
                    this.setPageTitle('User Activity - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },

        getUser: function (user) {
            return axios.get('/api/v1/user/' + user, {
                mode: 'no-cors',
            }).then(res => {
                this.user = res.data
                return Promise.resolve(res)
            }).catch(err => {
                console.log(err)
                this.$root.handleErrors(err.response)
                return Promise.reject(err)
            })
        },

        editUser: function (user_id) {
            this.$router.push({name: 'User Dialog', params: {user_id: user_id}})
        },

        reset: function () {
            this.user = null
        },

        handleCommand(command) {
            if (command == "edit") {
                this.editUser(this.user.id)
            }
        }
    },

    watch: {
        $route({params, query}) {
            this.reset()
            if (this.hasPermissionTo('view user')) {
                this.getUser(this.user_id).then(() => {
                    VueEvent.fire('page_title_updated', this.getUserName(this.user))
                })
            }
        },
        'show_graph': function () {
            this.reports_first_load = false
        }
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next()
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script>
