<template>
    <div v-if="active.includes(name)">
        <el-tooltip class="item"
                    effect="dark"
                    content="Integration connection is working"
                    placement="top">
            <div slot="content">
                <strong>Test Connection</strong>
                <br>
                <small>
                    This will check your if your connection to {{ name }} still works.
                </small>
            </div>
            <el-button :loading="testing"
                       @click="checkStatus()"
                       plain>
                <span v-if="testing">Checking...</span>
                <span v-if="!testing">Check Connection</span>
                <i v-if="!testing" class="fa fa-plug mr-2"></i>
            </el-button>
        </el-tooltip>
        <div class="pt-2">
            <div class="d-flex text-black-lt text-xs">
                <div class="mr-2">Status:</div>
                <el-tooltip class="item"
                            effect="dark"
                            placement="right">
                    <div slot="content">
                        <template v-if="card.connection_status === ConnectionStatus.UNKNOWN">
                            Connection testing has not yet performed
                        </template>
                        <template v-if="card.connection_status === ConnectionStatus.CONNECTED">
                            Integration connection is working
                        </template>
                        <template v-if="card.connection_status === ConnectionStatus.FAILED">
                            Connection health check failed. Try to re-authenticate.
                        </template>
                    </div>
                    <div class="text-muted font-weight-bold d-flex"
                         v-if="card.connection_status === ConnectionStatus.UNKNOWN">
                        <span>Unknown</span>
                    </div>
                    <div class="text-success font-weight-bold d-flex"
                         v-if="card.connection_status === ConnectionStatus.CONNECTED">
                        <span>Connected</span>
                    </div>
                    <div class="text-danger font-weight-bold d-flex pointer"
                         v-if="card.connection_status === ConnectionStatus.FAILED"
                         @click="toggleDialog">
                        <u>Failed</u>
                    </div>
                </el-tooltip>
            </div>
        </div>

        <el-dialog
            width="30%"
            title="Debug Connection Error"
            :visible="dialog"
            @close="closeDialog"
            :modal="false">
            <div class="p-3 border">
                <code>
                    {{ String(card.last_connection_error).trim() }}
                </code>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">Close</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { ConnectionStatus } from '../utils/integration-helpers'

export default {
    name: "integration-settings-tester-btn",

    data() {
        return {
            testing: false,
            dialog: false,
            active: ['facebook', 'hubspot', 'guesty', 'zoho', 'helpscout', 'pipedrive', 'gong', 'gohighlevel', 'salesforce'],
            ConnectionStatus
        }
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        card: {
            type: Object,
            default: {
                credentials: {
                    access_token: '',
                },
                name: '',
                connection_status: 0,
                last_connection_error: ''
            }
        },
        errorMessage: {
            type: String,
        },
        duration: {
            type: Number,
            default: 5000
        },
        errorTitle: {
            type: String,
            default: 'Connection Failed',
        },
        successTitle: {
            type: String,
            default: 'Connection Success',
        }
    },
    created() {
        if (this.card.connection_status === ConnectionStatus.UNKNOWN) {
          this.checkStatus();
        }

        /*
            This delay is necessary because once the integration is done, it will throw an error
            saying that integration settings has been saved, and if we don't have this delay,
            the error message will be displayed first!
        */
        const delayToDisplayErrorMessage = 5000
        setTimeout(() => {
            if(this.card.connection_status === ConnectionStatus.UNKNOWN) {
                this.removePropertyFromQuery('error')
                let error = {
                    status: 422,
                    data: {
                        errors: [
                            `Unable to check ${this.firstLetterCapitalized(this.card.name)} connection. Please click on <b>check connection</b> button to verify it.`,
                        ]
                    }
                }
                this.$root.handleErrors(error)
            }

            else if(this.card.connection_status === ConnectionStatus.FAILED) {
                this.removePropertyFromQuery('error')
                let error = {
                    status: 422,
                    data: {
                        errors: [
                            `Unable to connect to ${this.firstLetterCapitalized(this.card.name)}.`,
                            `Integration connection error: Please re-authenticate by <a href="/integrations/${this.card.name}/oauth"><b>clicking here</b></a>`,
                        ]
                    }
                }
                this.$root.handleErrors(error)
            }
        }, delayToDisplayErrorMessage)

    },
    methods: {
        toggleDialog() {
            this.dialog = !this.dialog
        },
        closeDialog() {
            this.dialog = false
        },
        async checkStatus() {
            try {
                this.testing = true

                await axios.get(`/api/v1/integration/${this.name}/status`, {
                    headers: {
                        'Accept': 'application/json'
                    }
                })

                this.testing = false

                this.$notify.success({
                    offset: 95,
                    title: this.successTitle,
                    message: `Your ${this.name} connection is working!`,
                    duration: this.duration,
                })

                this.$emit('reload');
            } catch (err) {
                this.testing = false

                this.$notify.error({
                    offset: 95,
                    title: this.errorTitle,
                    message: `Unable to connect to ${this.name} service!`,
                    duration: this.duration,
                })

                this.$emit('reload');
            }
        },
        removePropertyFromQuery(property_name) {
            let query = Object.assign({}, this.$route.query)
            delete query[property_name]
            this.$router.replace({query})
        },
        firstLetterCapitalized(text) {
            return text.charAt(0).toUpperCase() + text.slice(1);
        },
    }
}
</script>
