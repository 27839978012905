var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-tool",
    {
      attrs: {
        "tool-name": _vm.tool.name,
        "tool-description": _vm.tool.description,
      },
      on: { back: _vm.goBack },
    },
    [
      _c("disposition-status-selector", {
        attrs: { show_not_disposed: false },
        on: { change: _vm.handleDispositionChange },
        model: {
          value: _vm.selectedDispositionId,
          callback: function ($$v) {
            _vm.selectedDispositionId = $$v
          },
          expression: "selectedDispositionId",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }