var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      staticClass: "p-0",
      attrs: {
        width: "400",
        trigger: "click",
        "popper-class": "variable-popover",
        placement: _vm.popoverPlacement,
      },
      on: { show: _vm.popoverShow },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "el-select",
              {
                staticClass: "w-full",
                attrs: {
                  placeholder: "Select disposition status",
                  "data-testid": "contact-disposition-select",
                  clearable: "",
                  filterable: "",
                  multiple: false,
                },
                on: {
                  change: function ($event) {
                    return _vm.emitSelectedDisposition($event)
                  },
                },
                model: {
                  value: _vm.dispositionStatusId,
                  callback: function ($$v) {
                    _vm.dispositionStatusId = $$v
                  },
                  expression: "dispositionStatusId",
                },
              },
              _vm._l(
                _vm.dispositionStatusesAlphabeticalOrder,
                function (disposition_status, index) {
                  return _c(
                    "el-option",
                    {
                      key: disposition_status.id + "-disp-" + index,
                      attrs: {
                        label: disposition_status.name,
                        value: disposition_status.id,
                      },
                    },
                    [
                      disposition_status.is_external !== undefined
                        ? [
                            disposition_status.is_external
                              ? _c(
                                  "i",
                                  {
                                    staticClass: "material-icons",
                                    style: { color: disposition_status.color },
                                  },
                                  [
                                    _vm._v(
                                      "\n                           lock\n                        "
                                    ),
                                  ]
                                )
                              : _c(
                                  "i",
                                  {
                                    staticClass: "material-icons",
                                    style: { color: disposition_status.color },
                                  },
                                  [
                                    _vm._v(
                                      "\n                           label\n                        "
                                    ),
                                  ]
                                ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                        [" +
                            _vm._s(disposition_status.id) +
                            "] " +
                            _vm._s(disposition_status.name) +
                            "\n                    "
                        ),
                      ]),
                    ],
                    2
                  )
                }
              ),
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-button",
        {
          class: _vm.variableBtnClass,
          attrs: {
            slot: "reference",
            id: "dispositions-popover-trigger",
            size: "mini",
            circle: "",
            disabled: _vm.disabled,
          },
          slot: "reference",
        },
        [_c("i", { class: "text-warning " + _vm.icon })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }