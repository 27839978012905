var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tool-selector d-flex" }, [
    _c(
      "div",
      [
        _c(
          "el-popover",
          {
            attrs: {
              placement: "bottom",
              width: "400",
              trigger: "click",
              "popper-class": "tool-selector-popover",
              disabled: _vm.disabled,
            },
            on: { hide: _vm.onHide },
            model: {
              value: _vm.popoverVisible,
              callback: function ($$v) {
                _vm.popoverVisible = $$v
              },
              expression: "popoverVisible",
            },
          },
          [
            !_vm.selectedTool
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "tool-list" },
                    _vm._l(_vm.filteredTools, function (tool, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "tool-item mb-2 p-3",
                          on: {
                            click: function ($event) {
                              return _vm.selectTool(tool.id)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center mb-2" },
                            [
                              _c("i", {
                                staticClass: "mr-2",
                                class: [tool.icon, "tool-icon"],
                              }),
                              _vm._v(" "),
                              _c("strong", [
                                _vm._v(_vm._s(tool.name) + " "),
                                _vm.toolCounts[tool.id] > 0
                                  ? _c("span", [
                                      _vm._v(
                                        "(" +
                                          _vm._s(_vm.toolCounts[tool.id]) +
                                          ")"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "tool-description" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(tool.description) +
                                "\n                        "
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _c(
                  "div",
                  { staticClass: "tool-content-wrapper" },
                  [
                    _vm.currentToolComponent
                      ? _c(_vm.currentToolComponent, {
                          tag: "component",
                          attrs: { tool: _vm.selectedToolObj },
                          on: {
                            back: _vm.backToToolList,
                            "property-selected": _vm.onPropertySelected,
                            "campaign-selected": _vm.onCampaignSelected,
                            "send-sms-selected": _vm.onSendSmsSelected,
                            "appointment-selected": _vm.onAppointmentSelected,
                            "disposition-selected": _vm.onDispositionSelected,
                            "tags-selected": _vm.onTagsSelected,
                            "lists-selected": _vm.onListsSelected,
                            "custom-function-selected":
                              _vm.onCustomFunctionSelected,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "tool-selector-button my-2",
                attrs: {
                  slot: "reference",
                  type: "primary",
                  plain: "",
                  size: "mini",
                  icon: "el-icon-plus",
                  disabled: _vm.disabled,
                },
                slot: "reference",
              },
              [
                _vm._v("\n                Add Action "),
                _vm.totalToolCount > 0
                  ? _c("span", [_vm._v("(" + _vm._s(_vm.totalToolCount) + ")")])
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }