<template>
    <div class="d-flex items-center justify-content-center w-full">
        <div class="width-500"
             v-if="!isSubmitted && !isSubmitSuccess">
            <div class="text-center">
                <h3 class="signup--title">Welcome to Aloware</h3>
                <p class="signup--subtitle">Start your trial, <strong>no credit card required.</strong></p>
            </div>
            <div class="signup-wrapper">
                <div class="login-brand">
                    <img :src="statics.logo"
                         alt="."/>
                </div>

                <el-form class="mt-3"
                         ref="signupForm"
                         label-position="top"
                         label-width="300px"
                         :model="ssu"
                         :rules="rules">

                    <el-form-item prop="email">
                        <el-input placeholder="Work email"
                                  type="email"
                                  :loading="isValidatingEmail"
                                  v-model="ssu.email">
                            <template slot="append"
                                      v-if="isValidatingEmail">
                                <i class="material-icons loader pull-right">
                                    &#xE863;
                                </i>
                            </template>
                        </el-input>
                    </el-form-item>
                    <!-- two columns first_name and last_name -->
                    <el-row class="p-0">
                        <el-col class="p-0 pr-3"
                                :span="12">
                            <el-form-item prop="first_name">
                                <el-input placeholder="First name"
                                          v-model="ssu.first_name">
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col class="p-0"
                                :span="12">
                            <el-form-item prop="last_name">
                                <el-input placeholder="Last name"
                                          v-model="ssu.last_name">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item prop="company_name">
                        <el-input class=""
                                  placeholder="Company name"
                                  v-model="ssu.company_name">
                        </el-input>
                    </el-form-item>

                    <el-form-item prop="team_size">
                        <el-select v-model="ssu.team_size"
                                   class="no-select w-100"
                                   placeholder="Team size"
                                   clearable>
                            <el-option label="1 => Just Me 👻" value="1"/>
                            <el-option label="2-5 => We’re starting 📞" value="2-5"/>
                            <el-option label="6-20 => It's quite a team 🎯" value="6-20"/>
                            <el-option label="21-50 => Growing fast 🚀" value="21-50"/>
                            <el-option label="50+ => We’re bigger than Aloware, call us fast 👔" value="50+"/>
                        </el-select>
                    </el-form-item>

                    <phone :has-error="hasPhoneNumberError"
                           @input="updatePhoneNumber"></phone>
                    <el-form-item prop="phone_number">
                        <span class="el-form-item__error"
                              v-show="hasPhoneNumberError">
                            {{ validatePhoneNumber() }}
                        </span>
                    </el-form-item>

                    <el-checkbox class="mt-3 signup-checkbox__container"
                                 v-model="ssu.terms_and_conditions">
                        I affirm that I've reviewed and accepted Aloware's
                        <a class="signup-checkbox__link"
                           href="https://aloware.com/terms-and-conditions"
                           target="_blank">
                            Terms and Conditions
                        </a>
                        and
                        <a class="signup-checkbox__link"
                           href="https://aloware.com/privacy-policy"
                           target="_blank">
                            Privacy Policy.
                        </a>
                    </el-checkbox>

                    <el-checkbox class="mt-3 signup-checkbox__container"
                                 v-model="ssu.communications">
                        By checking this box, I agree to receive communications, including email, calls, and text messages from Aloware regarding announcements and company updates.
                        Reply to any messages with STOP at any time to stop receiving messages and request for help by replying HELP.
                        The frequency of messages varies. Message and data rates may apply.
                    </el-checkbox>

                    <vue-recaptcha ref="recaptcha"
                                   class="g-recaptcha pt-3"
                                   :sitekey="site_key"
                                   v-if="site_key"
                                   @verify="onVerify"
                                   @expired="onExpired">
                    </vue-recaptcha>

                    <el-button class="btn-block mt-3 signup-submit__button"
                               type="primary"
                               size="large"
                               :disabled="isStartTrialButtonDisabled"
                               @click="onSubmit">
                        <i class="material-icons loader pull-right"
                           v-show="isLoading">
                            &#xE863;
                        </i>
                        Start Trial
                        <i class="el-icon-arrow-right el-icon-right pull-right"
                           v-show="!isLoading"/>
                    </el-button>
                </el-form>
            </div>
        </div>

        <!-- SSU rejected validation message -->
        <div class="signup-wrapper__success"
             v-else-if="isSubmitted && !isSubmitSuccess && !submissionError && isSSURejected">
            <div>
                <h3 class="signup-wrapper__success__title">
                    Sorry, we couldn’t verify your business information.
                </h3>
                <hr/>
                <p>
                    Please call us at: (855) 256-2001 <br/>
                    or email us at: <a href="mailto:sales@aloware.com">sales@aloware.com</a>
                </p>
            </div>
        </div>

        <!-- All validation errors with login redirect -->
        <div class="signup-wrapper__success" v-else-if="isSubmitted && !isSubmitSuccess && submissionError && !isSSURejected">
            <div>
                <h3 class="signup-wrapper__success__title">{{ submissionError }}</h3>
                <p class="signup-wrapper__success__subtitle">
                    <a href="/login">
                        Click here to proceed to the login page
                    </a>
                </p>
            </div>
        </div>

        <div class="signup-wrapper__success" v-else>
            <div>
                <h3 class="signup-wrapper__success__title">Your trial account is almost ready!</h3>
                <p class="signup-wrapper__success__subtitle">Please check your inbox and confirm your email</p>
            </div>
        </div>

        <static-assets-preloader :assets="statics"/>
    </div>
</template>

<script>
import {VueRecaptcha} from "vue-recaptcha"
import Phone from "./utilities/phone"
import { PRE_SIGNUP_SUBMISSION_TYPES } from "../../admin/constants/pre-signup"

export default {
    name: 'signup',

    components: {
        'vue-recaptcha': VueRecaptcha,
        Phone,
    },

    props: {
        statics: {required: true}
    },

    data() {
        return {
            site_key: localStorage.getItem('recaptcha_site_key'),
            isLoading: false,
            isValidatingEmail: false,
            error: null,
            ssu: {
                email: null,
                first_name: null,
                last_name: null,
                company_name: null,
                phone_number: null,
                country_code: '1',
                country: 'US',
                terms_and_conditions: false,
                communications: false,
                recaptcha_response: null,
                team_size: null,
                referrer: null,
            },
            rules: {
                email: [
                    {required: true, message: 'Please input the email address', trigger: 'blur'},
                    {type: 'email', message: 'Please input a valid email address', trigger: ['blur', 'change']},
                    {required: true, validator: this.validateEmailWithServer, message: 'You need to enter a valid work email address. Please use another one.', trigger: 'blur'}
                ],
                first_name: [
                    {required: true, message: 'Please input the first name', trigger: 'blur'}
                ],
                last_name: [
                    {required: true, message: 'Please input the last name', trigger: 'blur'}
                ],
                company_name: [
                    {required: true, message: 'Please input the company name', trigger: 'blur'}
                ],
                team_size: [
                    {required: true, message: 'Please select the team size', trigger: 'change'}
                ],
            },
            userIpAddress: '',
            isEmailValidated: false,
            isSubmitted: false,
            isSubmitSuccess: false,
            isHubspotSubmissionSuccess: false,
            submissionError: null,
            isSSURejected: false,
        }
    },

    computed: {
        isStartTrialButtonDisabled() {
            return this.isLoading || !this.validateFirstStepFieldsFilled()
        },

        hasPhoneNumberError() {
            return this.ssu.phone_number && this.validatePhoneNumber() !== true
        },

        hubspotFormURL() {
            return (!localStorage || typeof localStorage.getItem !== 'function' || localStorage.getItem('app_env') === 'production')
                ? "https://api.hsforms.com/submissions/v3/integration/submit/4592972/9d391000-0cea-41fd-81fe-d795aeadbfb7"
                : "https://api.hsforms.com/submissions/v3/integration/submit/8446098/be426fa2-e7db-4712-8b90-b1e4b94c9dcc"
        },
    },

    methods: {
        clearError() {
            this.verification_message_type = 'success'
            this.verification_message = ''
        },

        onVerify(response) {
            this.ssu.recaptcha_response = response
        },

        onExpired() {
            this.ssu.recaptcha_response = null
        },

        getCleanedPhoneNumber(phone) {
            if (!phone) {
                return ''
            }

            return phone.replace(/[^\d]/g, '')
        },

        updatePhoneNumber(phoneData) {
            this.ssu.phone_number = phoneData?.phoneNumber
            this.ssu.country_code = `+${phoneData?.countryData?.dialCode}`
            this.ssu.country = phoneData?.countryData?.iso2.toUpperCase()
        },

        validateEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            return emailRegex.test(email) || 'Invalid email address'
        },

        async validateEmailWithServer(rule, value, callback) {
            const email = value || this.ssu.email
            this.isValidatingEmail = true

            try {
                const response = await axios.get('/api/v1/risk-assessment/email-validation', {params: {email}})

                if (response.status === 200 && response.data?.email_risky !== undefined) {
                    if (response.data.email_risky) {
                        this.isEmailValidated = false
                        return callback(new Error('You need to enter a valid work email address. Please use another one.'))
                    }

                    this.isEmailValidated = true
                    return callback()
                }

                this.isEmailValidated = false
                return callback(new Error('Failed to validate email'))
            } catch (error) {
                console.error('Error validating email:', error)
                this.isEmailValidated = false
                return callback(new Error('Failed to validate email'))
            } finally {
                this.isValidatingEmail = false
            }
        },


        validatePhoneNumber() {
            const formattedPhone = this.$options.filters.fixPhone(this.ssu.phone_number, 'E164', true)

            // if the phone number is empty or invalid
            if (!formattedPhone || formattedPhone === '-') {
                return 'Please enter a valid phone number'
            }

            return true
        },

        validateFirstStepFieldsFilled() {
            return (
                this.isEmailValidated &&
                this.validateEmail(this.ssu.email) === true &&
                this.ssu.first_name &&
                this.ssu.last_name &&
                this.ssu.company_name &&
                this.ssu.team_size &&
                this.validatePhoneNumber() === true &&
                this.ssu.terms_and_conditions &&
                (this.ssu.recaptcha_response || !this.site_key)
            )
        },

        checkIfFormFieldsAreValid() {
            if (!this.$refs.signupForm) {
                return false
            }

            let isValid

            this.$refs.signupForm.validate(async (valid) => {
                if (!valid) {
                    return isValid = false
                }

                return isValid = true
            })

            if (!isValid) {
                return false
            }

            return true
        },

        getHubspotCookie() {
            const name = 'hubspotutk='
            const decodedCookie = decodeURIComponent(document.cookie)
            const ca = decodedCookie.split(';')

            for (let i = 0; i < ca.length; i++) {
                let c = ca[i]

                while (c.charAt(0) === ' ') {
                    c = c.substring(1)
                }

                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length)
                }
            }

            return ''
        },

        async submitToHubspot() {
            const hutk = this.getHubspotCookie() || '';

            const hubspotData = {
                fields: [
                    {name: 'email', value: this.ssu.email},
                    {name: 'firstname', value: this.ssu.first_name},
                    {name: 'lastname', value: this.ssu.last_name},
                    {name: 'company', value: this.ssu.company_name},
                    {name: 'phone', value: this.ssu.phone_number},
                    {name: 'pre_sign_up_user_count_indication', value: this.ssu.team_size},
                ],
                context: {
                    ipAddress: this.userIpAddress,
                    pageUri: window.location.href,
                    pageName: document.title
                }
            }

            if (hutk) {
                hubspotData.context.hutk = hutk
            }

            try {
                const response = await axios.post(this.hubspotFormURL, hubspotData)

                if (response.status === 500) {
                    this.isHubspotSubmissionSuccess = false
                    return
                }

                const data = response.data

                if (data.errors && data.errors.length > 0) {
                    this.isHubspotSubmissionSuccess = false
                    return
                }

                this.isHubspotSubmissionSuccess = true
                return data
            } catch (error) {
                console.error('Failed to submit to HubSpot', error)
                // Reload the page
                return false
            }
        },

        async submitToRiskAssessment(payload) {
            try {
                const response = await axios.post('/api/v1/risk-assessment/pre-signup', payload)

                if (response.status === 500) {
                    this.isSubmitSuccess = false
                    return false
                }

                const data = response.data

                if (data.errors) {
                    // Case 1: ['validation', 'message'] array format from RiskAssessmentController
                    if (Array.isArray(data.errors) && data.errors[0] === 'validation') {
                        this.isSubmitSuccess = false
                        this.isSSURejected = true
                        return data
                    }
                    
                    // Case 2: {validation: ['message']} object format from validation
                    if (data.errors.validation) {
                        this.isSubmitSuccess = false
                        this.submissionError = data.errors.validation[0]
                        return data
                    }

                    // For other types of errors
                    this.submissionError = Array.isArray(data.errors) 
                        ? data.errors[1] 
                        : Object.values(data.errors)[0][0]
                    return false
                }

                this.isSubmitSuccess = true
                return data
            } catch (error) {
                console.error('Failed to submit to Risk Assessment', error)
                
                if (error.response?.status === 422) {
                    const errors = error.response.data.errors
                    // Handle all validation errors with submissionError
                    if (errors.validation) {
                        this.isSubmitSuccess = false
                        this.submissionError = errors.validation[0]
                        return error.response.data
                    }
                    // For other validation errors
                    this.submissionError = Object.values(errors)[0][0]
                    return false
                }
                
                this.submissionError = 'An unexpected error occurred. Please try again later.'
                return false
            }
        },

        async onSubmit() {
            this.isLoading = true

            const cleanedPhone = this.getCleanedPhoneNumber(this.ssu.phone_number)
            const formattedPhone = this.$options.filters.fixPhone(cleanedPhone, 'E164', true)
            const phoneWithoutCountryCode = formattedPhone.replaceAll(this.ssu.country_code, '')

            const payload = {
                email: this.ssu.email,
                first_name: this.ssu.first_name,
                last_name: this.ssu.last_name,
                full_name: `${this.ssu.first_name} ${this.ssu.last_name}`,
                company_name: this.ssu.company_name,
                phone_number: phoneWithoutCountryCode,
                country_code: this.ssu.country_code,
                country: this.ssu.country,
                team_size: this.ssu.team_size,
                terms_and_conditions: this.ssu.terms_and_conditions,
                communications: this.ssu.communications,
                recaptcha: this.ssu.recaptcha_response || 'test-recaptcha-token',
                hubspotutk: this.getHubspotCookie(),
                referrer: this.ssu.referrer,
                submission_type: PRE_SIGNUP_SUBMISSION_TYPES.ssu.id,
            }

            try {
                const riskAssessmentResponse = await this.submitToRiskAssessment(payload)
                
                // Check for both validation error formats
                const hasValidationError = riskAssessmentResponse?.errors && (
                    (Array.isArray(riskAssessmentResponse.errors) && riskAssessmentResponse.errors[0] === 'validation') ||
                    riskAssessmentResponse.errors.validation
                )
                
                // Proceed with Hubspot if success or validation error
                if (riskAssessmentResponse && (hasValidationError || !riskAssessmentResponse.errors)) {
                    await this.submitToHubspot()
                }
            } catch (error) {
                console.error('Failed to submit', error)
            } finally {
                this.isLoading = false
                this.isSubmitted = true
            }
        },

        async getUserIpAddress() {
            try {
                const response = await axios.get('/api/v1/risk-assessment/get-ip-address')

                return response?.data?.ip
            } catch (error) {
                console.error('Failed to get user IP address', error)
                return ''
            }
        },

        captureReferrer() {
            const referrerVariants = ['referrer', 'referer', 'referral', 'referal']

            // Loop through each variant to see if it exists in the query parameters
            for (const variant of referrerVariants) {
                const found = this.$route.query[variant] || this.$route.query[variant.toLowerCase()]
                if (found) {
                    this.ssu.referrer = found
                    break // Exit the loop once a match is found
                }
            }
        },

        captureEmail() {
            const emailVariants = ['email', 'email_address']

            // Loop through each variant to see if it exists in the query parameters
            for (const variant of emailVariants) {
                const found = this.$route.query[variant] || this.$route.query[variant.toLowerCase()]
                if (found) {
                    this.ssu.email = found
                    break // Exit the loop once a match is found
                }
            }
        }
    },

    watch: {
        'ssu.email': function () {
            this.isEmailValidated = false
        }
    },

    mounted() {
        this.getUserIpAddress().then(ipAddress => {
            this.userIpAddress = ipAddress
        })
        this.captureReferrer()
        this.captureEmail()
    }
}
</script>
