var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-upload",
    {
      attrs: {
        drag: "",
        action: _vm.action,
        headers: _vm.headers,
        "on-success": _vm.onSuccessUpload,
        "on-error": _vm.onFailedUpload,
        "on-progress": _vm.progressUpload,
        "before-upload": _vm.beforeUpload,
        "file-list": _vm.uploadFiles,
        "on-change": _vm.onChangeFileList,
        "on-remove": _vm.onChangeFileList,
        "before-remove": _vm.beforeRemove,
        disabled: _vm.disabled,
        "show-file-list": _vm.showFileList,
        multiple: "",
      },
    },
    [
      _c(
        "div",
        { class: [_vm.disabled ? "is-disabled" : ""] },
        [
          _vm.disabled
            ? [
                _c("i", { staticClass: "el-icon-upload" }),
                _vm._v(" "),
                _c("div", { staticClass: "el-upload__text" }, [
                  _vm._v(
                    "\n                Upload is disabled at this stage\n            "
                  ),
                ]),
              ]
            : [
                _c("i", { staticClass: "el-icon-upload" }),
                _vm._v(" "),
                _c("div", { staticClass: "el-upload__text" }, [
                  _vm._v("\n                Drop file here or "),
                  _c("em", [_vm._v("click to upload")]),
                ]),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }