<template>
    <div id="aside"
         class="app-aside modal nav-dropdown no-select"
         :class="{ 'folded md show-text': sidebar_folded }"
         :style="{top: top_offset + 'px'}"
         :key="sidebarKey"
         ref="sidebar">
        <!-- fluid app aside -->
        <div class="left navside text-blackish"
             data-layout="column">
            <div class="navbar no-radius">
                <!-- brand -->
                <a class="navbar-brand mx-auto d-block">
                    <img :src="statics.logo"
                         v-if="!sidebar_folded && !loading_whitelabel"
                         alt=".">
                    <img :src="statics.logo_square"
                         v-if="sidebar_folded && !loading_whitelabel"
                         alt=".">
                </a>
                <!-- / brand -->
            </div>
            <div class="hide-scroll pt-2"
                 data-flex>
                <nav
                    class="scroll nav-border b-greenish h-v"
                >
                    <ul class="nav"
                        ui-nav
                    >

                        <app-sidebar-dashboard v-if="!isSupervisor"/>

                        <router-link :to="{ name: 'Wallboard' }"
                                     tag="li"
                                     exact-active-class="active green-gradient rounded"
                                     active-class="active green-gradient rounded"
                                     v-if="hasWallboardAccess && !isSupervisor">
                            <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-wallboard-grey.svg">
                                </span>
                                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-wallboard-white.svg">
                                </span>
                                <span class="nav-text">Wallboard</span>
                            </a>
                        </router-link>

                        <router-link :to="{ name: 'Lines' }"
                                     tag="li"
                                     exact-active-class="active green-gradient rounded"
                                     active-class="active green-gradient rounded"
                                     v-if="shouldShowLines">
                            <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-lines-grey.svg">
                                </span>
                                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-lines-white.svg">
                                </span>
                                <span class="nav-text">Lines</span>
                            </a>
                        </router-link>

                        <router-link :to="{ name: 'Ring Groups' }"
                                     tag="li"
                                     exact-active-class="active green-gradient rounded"
                                     active-class="active green-gradient rounded"
                                     v-if="hasPermissionTo('list ring group') && !hasReporterAccess">
                            <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-ring-groups-grey.svg">
                                </span>
                                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-ring-groups-white.svg">
                                </span>
                                <span class="nav-text">Ring Groups</span>
                            </a>
                        </router-link>

                        <router-link :to="{ name: 'Teams' }"
                                     active-class="active green-gradient rounded"
                                     exact-active-class="active green-gradient rounded"
                                     tag="li"
                                     data-testid="teams-sidebar"
                                     v-if="isAdminOrSupervisor">
                            <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-teams.svg">
                                </span>
                                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-teams-white.svg">
                                </span>

                                <div class="d-flex align-items-center justify-center"
                                     :class="sidebar_folded ? 'flex-column' : ''"
                                >
                                    <span class="nav-text">Teams</span>
                                    <el-tag :class="sidebar_folded ? '' : 'ml-2' "
                                            size="mini"
                                            type="info">
                                        Beta
                                    </el-tag>
                                </div>
                            </a>
                        </router-link>

                        <router-link :to="{ name: 'Users' }"
                                     tag="li"
                                     exact-active-class="active green-gradient rounded"
                                     active-class="active green-gradient rounded"
                                     v-if="shouldShowUsers"
                                     data-testid="users-sidebar">
                            <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-user-grey.svg">
                                </span>
                                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-user-white.svg">
                                </span>
                                <span class="nav-text">Users</span>
                            </a>
                        </router-link>

                        <router-link active-class="active green-gradient rounded"
                                     exact-active-class="active green-gradient rounded"
                                     tag="li"
                                     data-testid="import-sidebar"
                                     :to="{ name: 'Imports' }"
                                     v-if="isAdminOrSupervisor">
                            <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-imports-grey.svg">
                                </span>
                                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-imports-white.svg">
                                </span>
                                <div class="d-flex align-items-center justify-center"
                                     :class="sidebar_folded ? 'flex-column' : ''">
                                    <span class="nav-text">Imports</span>
                                </div>
                            </a>
                        </router-link>

                        <app-sidebar-admin-list v-if="isAdminOrSupervisor"/>

                        <router-link :to="{ name: 'Tags' }"
                                     tag="li"
                                     exact-active-class="active green-gradient rounded"
                                     active-class="active green-gradient rounded"
                                     v-if="hasPermissionTo('list tag') && !isSupervisor">
                            <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-tags-grey.svg">
                                </span>
                                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-tags-white.svg">
                                </span>
                                <span class="nav-text">Tags</span>
                            </a>
                        </router-link>

                        <router-link
                            v-if="shouldShowAloAiMenu"
                            :to="{ name: 'AloAi Agents' }"
                            tag="li"
                            exact-active-class="active green-gradient rounded"
                            active-class="active green-gradient rounded">
                            <a>
                                <span class="nav-label">
                                    <b class="label pro-label"
                                       v-if="!sidebar_folded">Pro</b>
                                    <b class="fa fa-circle pro-label-text"
                                       v-else></b>
                                </span>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/aloai-text-bot-gray.svg">
                                </span>
                                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/aloai-text-bot-white.svg">
                                </span>
                                <span class="nav-text">AloAi Agents</span>
                            </a>
                        </router-link>

                        <router-link
                            v-if="shouldShowAloVoiceAiMenu"
                            :to="{ name: 'AloAi Voice Analytics' }"
                            tag="li"
                            exact-active-class="active green-gradient rounded"
                            active-class="active green-gradient rounded">
                            <a>
                                <span class="nav-label">
                                    <el-tooltip
                                        content="Pro"
                                        placement="right"
                                    >
                                        <b class="fa fa-circle pro-label-text"/>
                                    </el-tooltip>
                                </span>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/aloai-voice-analytics-grey.svg">
                                </span>
                                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/aloai-voice-analytics-white.svg">
                                </span>
                                <span class="nav-text">AloAi Voice Analytics</span>
                            </a>
                        </router-link>

                        <router-link
                            v-if="shouldShowBroadcast"
                            :to="{ name: 'Broadcast' }"
                            tag="li"
                            exact-active-class="active green-gradient rounded"
                            active-class="active green-gradient rounded">
                            <a>
                                <span class="nav-label">
                                    <b class="label pro-label"
                                       v-if="!sidebar_folded">Pro</b>
                                    <b class="fa fa-circle pro-label-text"
                                       v-else></b>
                                </span>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-broadcast-grey.svg">
                                </span>
                                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-broadcast-white.svg">
                                </span>
                                <span class="nav-text">Broadcast</span>
                            </a>
                        </router-link>

                        <router-link v-if="shouldSeeSequences"
                                     :to="{ name: 'Sequences 2' }"
                                     tag="li"
                                     exact-active-class="active green-gradient rounded"
                                     active-class="active green-gradient rounded">
                            <a>
                                <span class="nav-label">
                                    <b class="label pro-label"
                                       v-if="!sidebar_folded">Pro</b>
                                    <b class="fa fa-circle pro-label-text"
                                       v-else></b>
                                </span>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-workflow-grey.svg"
                                         width="24">
                                </span>
                                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-workflow-white.svg"
                                         width="24">
                                </span>
                                <span class="nav-text">Sequences+</span>
                            </a>
                        </router-link>

                        <router-link :to="{ name: 'Reports' }"
                                     tag="li"
                                     exact-active-class="active green-gradient rounded"
                                     active-class="active green-gradient rounded"
                                     v-if="hasPermissionTo('list report')">
                            <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-reports-grey.svg">
                                </span>
                                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-reports-white.svg">
                                </span>
                                <span class="nav-text">Reports</span>
                            </a>
                        </router-link>
                        <router-link :to="{ name: 'Insights' }"
                                     tag="li"
                                     exact-active-class="active green-gradient rounded"
                                     active-class="active green-gradient rounded"
                                     v-if="hasPermissionTo('list insights')">
                            <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-insights-grey.svg">
                                </span>
                                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-insights-white.svg">
                                </span>
                                <span class="nav-text">Insights</span>
                            </a>
                        </router-link>

                        <router-link v-if="hasPermissionTo('change integration settings company') && current_company && current_company.reseller_id !== 357"
                                     :to="{ name: 'Integrations' }"
                                     tag="li"
                                     exact-active-class="active green-gradient rounded"
                                     active-class="active green-gradient rounded">
                            <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-integrations-grey.svg">
                                </span>
                                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-integrations-white.svg">
                                </span>
                                <span class="nav-text">Integrations</span>
                            </a>
                        </router-link>

                        <router-link v-if="current_company && hasPermissionTo('update company') && auth.user.profile"
                                     :to="{ name: 'Account' }"
                                     tag="li"
                                     exact-active-class="active green-gradient rounded"
                                     active-class="active green-gradient rounded">
                            <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-company-grey.svg">
                                </span>
                                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-company-white.svg">
                                </span>
                                <span class="nav-text">Account</span>
                            </a>
                        </router-link>

                        <router-link v-if="canSeeDealerProfile"
                                     :to="{ name: 'Dealer Profile' }"
                                     tag="li"
                                     exact-active-class="active green-gradient rounded"
                                     active-class="active green-gradient rounded">
                            <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-car-grey.svg">
                                </span>
                                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-car-white.svg">
                                </span>
                                <span class="nav-text">Dealer Profile</span>
                            </a>
                        </router-link>
                        <div class="h-2x my-4"></div>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {mapActions, mapGetters, mapState} from 'vuex'
import {acl_mixin, styling_mixin,} from '../mixins'
import AppSidebarDashboard from '../components/app-sidebar-items/dashboard'
import AppSidebarAdminList from '../components/app-sidebar-items/admin-list'

export default {
    components: {
        AppSidebarDashboard,
        AppSidebarAdminList,
    },

    mixins: [
        styling_mixin,
        acl_mixin,
    ],

    data() {
        return {
            auth: auth,
            is_impersonated: localStorage.getItem('impersonate'),
            env: null,
            profile: auth.user.profile,
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true,
            simpsocialEmailModalOpen: false,
            sidebarKey: 0
        }
    },

    computed: {
        ...mapState({
            campaigns: state => state.campaigns,
            current_company: state => state.cache.current_company,
            sidebar_folded: state => state.cache.sidebar_folded,
        }),

        ...mapGetters('cache', ['isSimpSocial']),

        shouldShowLines() {
            if (!this.hasPermissionTo('list campaign')) {
                return false
            }

            if (this.current_company && this.current_company.reseller_id == 357 && !this.hasRole('Billing Admin')) {
                return false
            }

            return true
        },

        shouldShowUsers() {
            if (!this.hasPermissionTo('list user') || this.isSupervisor) {
                return false
            }

            return true
        },

        shouldShowShortUrls() {
            return true
        },

        isSimpSocialSidebarItem() {
            return (this.current_company && this.current_company.simpsocial_integration_enabled && this.auth.user.profile) || this.isSimpSocial
        },

        canSeeDealerProfile() {
            return (this.current_company?.simpsocial_integration_enabled || this.isSimpSocial) &&
                this.hasPermissionTo('update company') &&
                localStorage.getItem('is_admin_impersonating')
        },
    },

    created() {
        this.getStatics()
    },

    mounted() {
        this.addActiveClass()
    },

    methods: {
        contactSupport() {
            this.$alert('Sorry, you don\'t have permission to use this feature. Please contact support to activate it.', 'Oops!', {
                confirmButtonText: 'Ok',
                center: true,
                callback: cancel => {
                }
            })
        },

        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics')
                .then(res => {
                    this.statics = res.data
                    this.loading_whitelabel = false
                })
                .catch(err => {
                    console.log(err)
                    this.loading_whitelabel = false
                    this.$root.handleErrors(err.response)
                })
        },

        addActiveClass() {
            setTimeout(() => {
                $("body").find(".navside .nav li.active").each(function () {
                    $(this).parent().parent().addClass("active")
                })
            }, 100)
        },

        beforeCloseModal(done) {
            if (!this.campaign_created) {
                this.$confirm('Are you sure you want to leave. Your changes will not be saved.', 'Warning', {
                    confirmButtonText: 'Yes, Leave',
                    cancelButtonText: 'No, Stay',
                    customClass: 'width-500 fixed',
                    type: 'warning'
                })
                    .then(res => {
                        done()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                done()
            }
        },

        campaignCreated() {
            this.campaign_created = true
        },

        openSimpsocialEmailModal() {
            this.simpsocialEmailModalOpen = !this.simpsocialEmailModalOpen
        },

        ...mapActions('cache', ['resetCache'])
    },

    watch: {
        '$route'(to, from) {
            this.addActiveClass()
        },
    },

    beforeCreate() {
        axios.get('/build-info.json')
            .then(res => {
                this.env = res.data.env
            })
            .catch(err => {
                console.log(err)
            })
    }
}
</script>
