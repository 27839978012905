<template>
    <base-tool
        :tool-name="tool.name"
        :tool-description="tool.description"
        @back="goBack"
    >
        <el-input
            class="mb-2"
            type="textarea"
            placeholder="Enter your message here"
            v-model="message"
            :rows="4"
        />

        <el-button
            type="primary"
            @click="addSms"
            :disabled="!isValidMessage"
        >
            Add SMS
        </el-button>
    </base-tool>
</template>

<script>
import BaseTool from './base-tool.vue'
import CampaignSelector from '../../../campaign-selector'
import ToolMixin from './tool-mixin'

export default {
    name: 'SendSmsTool',

    mixins: [ToolMixin],

    components: {
        BaseTool,
        CampaignSelector
    },

    props: {
        tool: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            message: ''
        }
    },

    computed: {
        isValidMessage() {
            return this.message && this.message.trim() !== ''
        }
    },

    methods: {
        addSms() {
            if (!this.isValidMessage){
                return
            }
            
            // Trim the message to remove leading/trailing whitespace
            const trimmedMessage = this.message.trim()
            this.$emit('send-sms-selected', trimmedMessage)
        }
    }
}
</script>
