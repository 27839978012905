<template>
    <el-form ref="generic_webhook_settings" class="generic-webhook" :rules="rules" :model="webhook">
        <!-- Connect to the API -->
        <el-card class="mt-3">
            <template #header>
                <h6>Connect to the API</h6>
                <small>Build the API call for this custom function, choosing the method,
                    URL, headers, query parameters, inputs from the contact and
                    authentication.</small>
            </template>
            <div class="row">
                <div class="col-12">
                    <!-- TABS -->
                    <el-tabs v-model="activeName">
                        <!-- Endpoint tab -->
                        <el-tab-pane label="Endpoint" name="endpoint" data-tab-error="endpoint">
                            <span slot="label">
                                Endpoint<span v-html="tabLabelErrorIcon(tab_errors.endpoint)"></span>
                            </span>
                            <div class="row justify-content-center pt-3">
                                <div class="col-12">
                                    <el-form-item prop="url">
                                        <div class="row justify-content-center">
                                            <div class="col-2">
                                                <el-select v-model="webhook.http_method" class="w-100">
                                                    <el-option v-for="http_method in http_method_options"
                                                        :key="http_method" :label="http_method" :value="http_method" />
                                                </el-select>
                                            </div>
                                            <div class="col-10">
                                                <el-input type="url" v-model="webhook.url"
                                                    @input="preValidateForm('generic_webhook_settings')" />
                                            </div>
                                        </div>
                                    </el-form-item>
                                </div>

                                <div class="col-12">
                                    <el-form-item prop="timeout_in_seconds">
                                        <div class="form-label">
                                            <h5 class="text-dark">Timeout</h5>
                                            <small>Set the timeout for the API call in seconds.</small>
                                        </div>
                                        <el-input type="number"
                                            v-model="webhook.timeout_in_seconds"
                                            @input="preValidateForm('generic_webhook_settings')" />
                                    </el-form-item>
                                </div>
                            </div>
                        </el-tab-pane>

                        <!-- Headers tab -->
                        <el-tab-pane label="Headers" name="headers" data-tab-error="headers">
                            <span slot="label">
                                Headers<span v-html="tabLabelErrorIcon(tab_errors.headers)"></span>
                            </span>
                            <div class="row justify-content-center pt-3">
                                <div class="col-12">
                                    <p>
                                        Headers allow you to send additional information with your
                                        custom function. You can use them to include content types,
                                        or other custom data.
                                    </p>
                                    <el-row v-if="webhook.headers.length > 0">
                                        <el-col :span="2">
                                            <div class="form-label">
                                                <h5 class="text-dark invisible">Actions</h5>
                                            </div>
                                        </el-col>
                                        <el-col :span="11">
                                            <div class="form-label">
                                                <h5 class="text-dark">Name</h5>
                                                <small>Tell what this header is called.</small>
                                            </div>
                                        </el-col>
                                        <el-col :span="11">
                                            <div class="form-label">
                                                <h5 class="text-dark">Value</h5>
                                                <small>Tell what this header is for.</small>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row class="mb-2" :gutter="20" v-for="(header, index) in webhook.headers">
                                        <el-col :span="2" class="text-center">
                                            <button class="btn btn-sm danger" @click.stop.prevent="removeHeader(index)">
                                                <i class="fa fa-trash pull-left"></i>
                                            </button>
                                        </el-col>
                                        <el-col :span="11">
                                            <el-form-item prop="key">
                                                <el-input v-model="header.key"
                                                    @blur="sanitizeHeaderKey(index, header.key)" />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="11">
                                            <el-form-item prop="value">
                                                <el-input v-model="header.value" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-button type="primary" plain size="mini" icon="el-icon-plus" class="my-2"
                                        @click="addHeader">
                                        Add header
                                    </el-button>
                                </div>
                            </div>
                        </el-tab-pane>

                        <!-- Authorization tab -->
                        <el-tab-pane label="Authorization" name="authorization" data-tab-error="authorization">
                            <span slot="label">
                                Authorization<span v-html="tabLabelErrorIcon(tab_errors.authorization)"></span>
                            </span>
                            <div class="row justify-content-center pt-3">
                                <div class="col-12">
                                    <p>
                                        If your API requires a token, you can add it here. The AloAi
                                        will use it when running this custom function.
                                    </p>
                                    <el-row v-if="webhook.authorization.length > 0">
                                        <el-col :span="2">
                                            <div class="form-label">
                                                <h5 class="text-dark invisible">Actions</h5>
                                            </div>
                                        </el-col>
                                        <el-col :span="11">
                                            <div class="form-label">
                                                <h5 class="text-dark">Name</h5>
                                                <small>Tell what this header is called.</small>
                                            </div>
                                        </el-col>
                                        <el-col :span="11">
                                            <div class="form-label">
                                                <h5 class="text-dark">Value</h5>
                                                <small>Tell what this header is for.</small>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row class="mb-2" :gutter="20"
                                        v-for="(authorization, index) in webhook.authorization">
                                        <el-col :span="2" class="text-center">
                                            <button class="btn btn-sm danger"
                                                @click.stop.prevent="removeAuthorization(index)">
                                                <i class="fa fa-trash pull-left"></i>
                                            </button>
                                        </el-col>
                                        <el-col :span="11">
                                            <el-form-item prop="key">
                                                <el-input v-model="authorization.key" @blur="
                                                    sanitizeAuthorizationKey(index, authorization.key)
                                                    " />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="11">
                                            <el-form-item prop="value">
                                                <el-input v-model="authorization.value" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-button type="primary" plain size="mini" icon="el-icon-plus" class="my-2"
                                        @click="addAuthorization">
                                        Add authorization
                                    </el-button>
                                </div>
                            </div>
                        </el-tab-pane>

                        <!-- Body tab -->
                        <el-tab-pane v-if="webhook.http_method !== 'GET'" label="Body" name="body"
                            data-tab-error="body">
                            <span slot="label">
                                Body<span v-html="tabLabelErrorIcon(tab_errors.body)"></span>
                            </span>
                            <div class="row justify-content-center pt-3">
                                <div class="col-12">
                                    <p>
                                        The body contains the main data sent with your custom
                                        function. It is typically used to share detailed
                                        information. You can customize the body to match the data
                                        structure required.
                                    </p>
                                    <el-row v-if="webhook.body.length > 0">
                                        <el-col :span="2">
                                            <div class="form-label">
                                                <h5 class="text-dark invisible">Actions</h5>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="form-label">
                                                <h5 class="text-dark">Type</h5>
                                                <small>Tell from where this data comes.</small>
                                            </div>
                                        </el-col>
                                        <el-col :span="8">
                                            <div class="form-label">
                                                <h5 class="text-dark">Name</h5>
                                                <small>Tell what this field is called.</small>
                                            </div>
                                        </el-col>
                                        <el-col :span="8">
                                            <div class="form-label">
                                                <h5 class="text-dark">Value</h5>
                                                <small>Tell what is this field value.</small>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row class="mb-2" :gutter="20" v-for="(body, index) in webhook.body">
                                        <el-col :span="2" class="text-center">
                                            <button class="btn btn-sm danger" @click.stop.prevent="removeBody(index)">
                                                <i class="fa fa-trash pull-left"></i>
                                            </button>
                                        </el-col>
                                        <el-col :span="6">
                                            <el-form-item prop="type">
                                                <el-select v-model="body.type" class="w-100">
                                                    <el-option v-for="type in types" :key="type" :label="type"
                                                        :value="type" />
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item prop="key">
                                                <el-input v-model="body.key" @blur="sanitizeBodyKey(index, body.key)" />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item prop="value">
                                                <el-select v-model="body.value" class="w-100"
                                                    v-if="body.type == 'Variable'">
                                                    <el-option v-for="variable in variables" :key="variable"
                                                        :label="variable" :value="variable" />
                                                </el-select>
                                                <el-input v-model="body.value" v-if="body.type == 'Static'" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-button type="primary" plain size="mini" icon="el-icon-plus" class="my-2"
                                        @click="addBody">
                                        Add body
                                    </el-button>
                                </div>
                            </div>
                        </el-tab-pane>

                        <!-- Query Parameters tab -->
                        <el-tab-pane v-if="webhook.http_method == 'GET'" label="Query Parameters"
                            name="query_parameters" data-tab-error="query_parameters">
                            <span slot="label">
                                Query Parameters<span v-html="tabLabelErrorIcon(tab_errors.query_parameters)"></span>
                            </span>
                            <div class="row justify-content-center pt-3">
                                <div class="col-12">
                                    <p>
                                        Query parameters let you send extra information in the
                                        custom function URL. They are useful for passing
                                        identifiers, filters, or other custom data.
                                    </p>
                                    <el-row v-if="webhook.query_parameters.length > 0">
                                        <el-col :span="2">
                                            <div class="form-label">
                                                <h5 class="text-dark invisible">Actions</h5>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="form-label">
                                                <h5 class="text-dark">Type</h5>
                                                <small>Tell from where this data comes.</small>
                                            </div>
                                        </el-col>
                                        <el-col :span="8">
                                            <div class="form-label">
                                                <h5 class="text-dark">Name</h5>
                                                <small>Tell what this field is called.</small>
                                            </div>
                                        </el-col>
                                        <el-col :span="8">
                                            <div class="form-label">
                                                <h5 class="text-dark">Value</h5>
                                                <small>Tell what is this field value.</small>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row class="mb-2" :gutter="20"
                                        v-for="(query_parameter, index) in webhook.query_parameters">
                                        <el-col :span="2" class="text-center">
                                            <button class="btn btn-sm danger"
                                                @click.stop.prevent="removeQueryParameter(index)">
                                                <i class="fa fa-trash pull-left"></i>
                                            </button>
                                        </el-col>
                                        <el-col :span="6">
                                            <el-form-item prop="type">
                                                <el-select v-model="query_parameter.type" class="w-100">
                                                    <el-option v-for="type in types" :key="type" :label="type"
                                                        :value="type" />
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item prop="key">
                                                <el-input v-model="query_parameter.key" @blur="
                                                    sanitizeQueryParameterKey(
                                                        index,
                                                        query_parameter.key
                                                    )
                                                    " />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item prop="value">
                                                <el-select v-model="query_parameter.value" class="w-100"
                                                    v-if="query_parameter.type == 'Variable'">
                                                    <el-option v-for="variable in variables" :key="variable"
                                                        :label="variable" :value="variable" />
                                                </el-select>
                                                <el-input v-model="query_parameter.value"
                                                    v-if="query_parameter.type == 'Static'" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-button type="primary" plain size="mini" icon="el-icon-plus" class="my-2"
                                        @click="addQueryParameter">
                                        Add query parameter
                                    </el-button>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </el-card>

        <!-- Test the API Call -->
        <el-card class="mt-3">
            <template #header>
                <h6>Test the API Call</h6>
                <small>Get a sample response, with test values for any placeholder in the
                    API call.</small>
            </template>
            <div class="row" v-loading="isLoading">
                <div class="col-12">
                    <el-button type="primary" size="mini" icon="el-icon-plus" class="my-2" plain
                        @click="initializeCustomFunction" v-if="!isInitializing && variables.length > 0">
                        Initialize custom function
                    </el-button>

                    <div v-if="isInitializing || variables.length === 0">
                        <p v-if="variables.length > 0">
                            Please, provide some values for the mapped variables before
                            testing the custom function.
                        </p>

                        <el-row class="mb-2" :gutter="20" v-for="(value, index) in values">
                            <el-col :span="24">
                                <el-form-item>
                                    <div class="form-label">
                                        <h5 class="text-dark">{{ value.key }}</h5>
                                    </div>
                                    <el-input v-model="value.value" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-button type="primary" size="mini" class="my-2" plain @click="callCustomFunction">
                            Make API Call
                        </el-button>
                    </div>
                </div>
            </div>
        </el-card>

        <!-- Map the API response fields -->
        <el-card class="mt-3" v-if="fields.length > 0 && !isLoading">
            <template #header>
                <h6>Map the API response fields to be used in the conversation</h6>
                <small>Provide the API response fields to be used in the conversation to be
                    mapped.</small>
            </template>

            <div class="row justify-content-center">
                <div class="col-12">
                    <el-row v-if="webhook.response_mapping.length > 0">
                        <el-col :span="2">
                            <div class="form-label">
                                <h5 class="text-dark invisible">Actions</h5>
                            </div>
                        </el-col>
                        <el-col :span="11">
                            <div class="form-label">
                                <h5 class="text-dark">Name</h5>
                                <small>Tell what this field is called.</small>
                            </div>
                        </el-col>
                        <el-col :span="11">
                            <div class="form-label">
                                <h5 class="text-dark">Value</h5>
                                <small>Tell what is this field value.</small>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row class="mb-2" :gutter="20" v-for="(response_mapping, index) in webhook.response_mapping">
                        <el-col :span="2" class="text-center">
                            <button class="btn btn-sm danger" @click.stop.prevent="removeResponse(index)">
                                <i class="fa fa-trash pull-left"></i>
                            </button>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item class="mb-0" prop="key">
                                <el-input v-model="response_mapping.key" @blur="
                                    sanitizeResponseMappingKey(index, response_mapping.key)
                                    " />
                            </el-form-item>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item class="mb-0" prop="value">
                                <el-select v-model="response_mapping.value" class="w-100">
                                    <el-option v-for="field in fields" :key="field" :label="field" :value="field" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-button type="primary" size="mini" icon="el-icon-plus" class="my-2" plain
                        @click="addResponseMapping">
                        Add response mapping
                    </el-button>
                </div>
            </div>
        </el-card>
    </el-form>
</template>

<script>
import _ from "lodash"
import { acl_mixin, form_validation_mixin } from "../../mixins"

export default {
    name: "generic-webhook-settings",

    mixins: [form_validation_mixin, acl_mixin],

    props: {
        variables: [],
        webhook: {
            type: Object,
            required: false,
            default: () => ({
                name: "",
                description: "",
                url: "",
                timeout_in_seconds: 10,
                http_method: "POST",
                headers: [],
                body: [],
                authorization: [],
                query_parameters: [],
                response_mapping: [],
            }),
        },
    },

    data() {
        return {
            fields: [],
            values: [],
            isInitializing: false,
            isLoading: false,
            types: ["Static", "Variable"],
            http_method_options: ["GET", "POST", "PUT", "DELETE", "PATCH"],
            rules: {
                url: [
                    {
                        required: true,
                        message: "Please provide a URL for the custom function",
                        trigger: "blur",
                    },
                ],
                http_method: [
                    {
                        required: true,
                        message: "Please provide a HTTP method for the custom function",
                        trigger: "blur",
                    },
                ],
                headers: [
                    {
                        required: false,
                        type: "array",
                        len: 0,
                        fields: {
                            key: {
                                required: true,
                                message: "Please provide a name",
                            },
                            value: {
                                required: true,
                                message: "Please provide a value",
                            },
                        },
                    },
                ],
                authorization: [
                    {
                        required: false,
                        type: "array",
                        len: 0,
                        fields: {
                            key: {
                                required: true,
                                message: "Please provide a name",
                            },
                            value: {
                                required: true,
                                message: "Please provide a value",
                            },
                        },
                    },
                ],
                body: [
                    {
                        required: false,
                        type: "array",
                        len: 0,
                        fields: {
                            key: {
                                required: true,
                                message: "Please provide a name",
                            },
                            type: {
                                required: true,
                                message: "Please provide a type",
                            },
                            value: {
                                required: true,
                                message: "Please provide a value",
                            },
                        },
                    },
                ],
                query_parameters: [
                    {
                        required: false,
                        type: "array",
                        len: 0,
                        fields: {
                            key: {
                                required: true,
                                message: "Please provide a name",
                            },
                            type: {
                                required: true,
                                message: "Please provide a type",
                            },
                            value: {
                                required: true,
                                message: "Please provide a value",
                            },
                        },
                    },
                ],
                response_mapping: [
                    {
                        required: false,
                        type: "array",
                        len: 0,
                        fields: {
                            key: {
                                required: true,
                                message: "Please provide a name",
                            },
                            value: {
                                required: true,
                                message: "Please provide a value",
                            },
                        },
                    },
                ],
            },
            loading: false,
            tab_errors: {
                endpoint: false,
                headers: false,
                body: false,
                query_parameters: false,
                authorization: false,
                response: false,
            },
            error_notification: null,
            activeName: "endpoint",
        }
    },
    watch: {
        webhook:function () {
            this.values = []
            this.fillFieldsBasedOnCurrentResponseMapping()
            this.activeName = "endpoint"
            this.isInitializing = false
        },
    },
    methods: {
        fillFieldsBasedOnCurrentResponseMapping() {
            this.fields = this.webhook.response_mapping.map((field) => {
                return field.value
            })
        },
        callCustomFunction() {
            if (this.submitForm()) {
                this.isLoading = true

                axios({
                    method: "POST",
                    url: `/api/v1/generic-webhook/test`,
                    data: {
                        ...this.webhook,
                        values: this.values?.reduce((acc, { key, value }) => {
                            acc[key] = value
                            return acc
                        }, {}),
                    },
                })
                    .then((response) => {
                        this.isInitializing = false
                        this.fields = response.data.fields
                        let message = "API call was successful!"

                        if (this.fields.length > 0) {
                            message += ` Now, you can map the response fields to be used during the conversation.`
                        }

                        this.$notify.success({
                            offset: 95,
                            title: "AloAi Custom Function",
                            message,
                        })
                    })
                    .catch((error) => {
                        this.$notify.error({
                            offset: 95,
                            title: "AloAi Custom Function",
                            message:
                                error.response?.data?.message ??
                                "There was an error creating the custom function",
                        })
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            }
        },
        initializeCustomFunction() {
            this.values = []

            this.variables.forEach((variable) => {
                this.values.push({
                    key: variable,
                    value: "",
                })
            })

            this.isInitializing = true
        },
        sanitizeBodyKey(index, key) {
            this.webhook.body[index].key = this.sanitizeKey(key, "_", true)
        },
        sanitizeResponseMappingKey(index, key) {
            this.webhook.response_mapping[index].key = this.sanitizeKey(
                key,
                "_",
                true
            )
        },
        sanitizeQueryParameterKey(index, key) {
            this.webhook.query_parameters[index].key = this.sanitizeKey(key)
        },
        sanitizeAuthorizationKey(index, key) {
            this.webhook.authorization[index].key = this.sanitizeKey(key)
        },
        sanitizeHeaderKey(index, key) {
            this.webhook.headers[index].key = this.sanitizeKey(key)
        },
        sanitizeKey(value, divider = "-", lowerCase = false) {
            value = value
                .trim() // Remove whitespace from beginning and end
                .split(/\s+/) // Split by spaces
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
                .join(divider) // Replace spaces with '-'

            if (lowerCase) {
                return value.toLowerCase()
            }

            return value
        },
        clearForm() {
            const form_element = _.get(this.$refs, "generic_webhook_settings", null)
            form_element.resetFields()
        },
        removeResponse(position) {
            this.webhook.response_mapping = this.webhook.response_mapping.filter(
                (response_mapping, index) => index !== position
            )
        },
        removeQueryParameter(position) {
            this.webhook.query_parameters = this.webhook.query_parameters.filter(
                (query_parameter, index) => index !== position
            )
        },
        removeBody(position) {
            this.webhook.body = this.webhook.body.filter(
                (body, index) => index !== position
            )
        },
        removeAuthorization(position) {
            this.webhook.authorization = this.webhook.authorization.filter(
                (authorization, index) => index !== position
            )
        },
        removeHeader(position) {
            this.webhook.headers = this.webhook.headers.filter(
                (header, index) => index !== position
            )
        },
        addResponseMapping() {
            this.webhook.response_mapping.push({
                key: "",
                value: "",
            })
        },
        addQueryParameter() {
            this.webhook.query_parameters.push({
                key: "",
                type: "Static",
                value: "",
            })
        },
        addBody() {
            this.webhook.body.push({
                key: "",
                type: "Static",
                value: "",
            })
        },
        addAuthorization() {
            this.webhook.authorization.push({
                key: "",
                value: "",
            })
        },
        addHeader() {
            this.webhook.headers.push({
                key: "",
                value: "",
            })
        },
        getFormData() {
            return this.webhook
        },
        getFormErrors() {
            return this.getFormErrorsMessage("generic_webhook_settings")
        },
        isFormValidate() {
            return this.validateForm("generic_webhook_settings")
        },
        submitForm() {
            this.resetTabErrors()
            this.closeErrorNotification()

            if (this.validateForm("generic_webhook_settings") === true) {
                return true
            } else {
                this.error_notification = this.$notify({
                    offset: 95,
                    title: "Custom Functions",
                    dangerouslyUseHTMLString: true,
                    message: this.getFormErrorsMessage("generic_webhook_settings"),
                    type: "error",
                    showClose: true,
                    duration: 5000,
                })
            }

            return false
        },

        getFormErrorsMessage(form_name) {
            let form_element = _.get(this.$refs, form_name, null)
            let err_message =
                '<div class="mb-2">Please resolve custom function configuration errors</div>'

            form_element.fields.forEach((element) => {
                if (element.validateState === "error") {
                    // get the parent tab pane of the element
                    let parent = element.$parent

                    while (parent && parent.$options._componentTag !== "el-tab-pane") {
                        parent = parent.$parent
                    }

                    const tab =
                        parent.$attrs["data-tab-error"] || parent.name.replace("-", "_")
                    const tab_label = parent.$slots.label[0].children[0].text

                    // show the error icon on the element's parent tab pane
                    if (tab !== "") {
                        this.tab_errors[tab] = true
                    }

                    err_message += `<div class="mb-2"><i class="fa fa-remove text-danger mr-2"></i> ${tab_label} > ${element.validateMessage}</div>`
                }
            })

            return err_message
        },

        tabLabelErrorIcon(tab_error) {
            if (tab_error) {
                return ' <i class="fa fa-exclamation-circle text-danger ml-1"></i>'
            }

            return ""
        },

        resetTabErrors() {
            this.tab_errors = {
                configure: false,
                instructions: false,
                actions: false,
                deployment: false,
            }
        },

        closeErrorNotification() {
            if (this.error_notification) {
                this.error_notification.close()
            }
        },
    },

    beforeRouteLeave(to, from, next) {
        this.closeErrorNotification()
        next()
    },
}
</script>
<style>
.generic-webhook .el-tabs__header {
    top: 0 !important
}
</style>
