<template>
    <div :key="sequence_form_key">
        <div class="row"
             v-if="[SequenceTypes.TYPE_CALL_LEAD, SequenceTypes.TYPE_SMS_LEAD, SequenceTypes.TYPE_CONFERENCE, SequenceTypes.TYPE_RVM, SequenceTypes.TYPE_MMS, SequenceTypes.TYPE_CALL_RING_GROUP, SequenceTypes.TYPE_SMS_RING_GROUP, SequenceTypes.TYPE_AUTO_DIAL, SequenceTypes.TYPE_BIRTHDAY, SequenceTypes.TYPE_EMAIL_LEAD, SequenceTypes.TYPE_EMAIL_RING_GROUP, SequenceTypes.TYPE_PREDICTIVE_DIAL].includes(sequence.type)">
            <div class="col-md-12">
                <div class="b-b nav-active-greenish"
                     v-if="hasExtraStep">
                    <ul class="nav nav-tabs">
                        <li class="nav-item"
                            v-if="[SequenceTypes.TYPE_EMAIL_LEAD, SequenceTypes.TYPE_EMAIL_RING_GROUP].includes(sequence.type)">
                            <a class="border border-right-0 rounded-top-left"
                               :class="messageNavClass.read"
                               href="#"
                               data-toggle="tab"
                               data-target="#messagebody"
                               aria-expanded="false">
                                <i class="fa fa-robot"></i>
                                Send
                            </a>
                        </li>
                        <li class="nav-item"
                            v-if="![SequenceTypes.TYPE_SMS_LEAD, SequenceTypes.TYPE_RVM, SequenceTypes.TYPE_MMS, SequenceTypes.TYPE_SMS_RING_GROUP, SequenceTypes.TYPE_WEBHOOK, SequenceTypes.TYPE_EMAIL_LEAD, SequenceTypes.TYPE_EMAIL_RING_GROUP].includes(sequence.type)">
                            <a class="border border-right-0 rounded-top-left"
                               :class="messageNavClass.read"
                               href="#"
                               data-toggle="tab"
                               data-target="#messagetts"
                               aria-expanded="false">
                                <i class="fa fa-robot"></i>
                                Text to Speech
                            </a>
                        </li>
                        <li class="nav-item"
                            v-if="[SequenceTypes.TYPE_SMS_LEAD, SequenceTypes.TYPE_SMS_RING_GROUP].includes(sequence.type)">
                            <a class="border border-right-0 rounded-top-left"
                               :class="messageNavClass.read"
                               href="#"
                               data-toggle="tab"
                               data-target="#messagetts"
                               aria-expanded="false">
                                Message
                            </a>
                        </li>
                        <li class="nav-item"
                            v-if="hasPermissionTo('upload file sequence') && [SequenceTypes.TYPE_CALL_LEAD, SequenceTypes.TYPE_RVM, SequenceTypes.TYPE_CALL_RING_GROUP, SequenceTypes.TYPE_AUTO_DIAL].includes(sequence.type)">
                            <a class="border rounded-top-right"
                               :class="messageNavClass.play"
                               href="#"
                               data-toggle="tab"
                               data-target="#messagefile"
                               aria-expanded="true">
                                <i class="fa fa-play text-md"></i>
                                Play Recording
                            </a>
                        </li>
                        <li class="nav-item"
                            v-if="hasPermissionTo('upload file sequence') && sequence.type == SequenceTypes.TYPE_MMS">
                            <a class="border rounded-top"
                               :class="messageNavClass.send"
                               href="#"
                               data-toggle="tab"
                               data-target="#mediafile"
                               aria-expanded="true">
                                <i class="material-icons">insert_photo</i>
                                Media file
                            </a>
                        </li>
                        <li class="nav-item"
                            v-if="sequence.type == SequenceTypes.TYPE_MMS">
                            <a class="border rounded-top-right"
                               :class="messageNavClass.gif"
                               href="#"
                               data-toggle="tab"
                               data-target="#giffile"
                               aria-expanded="true">
                                <i class="material-icons">gif</i>
                                GIF file
                            </a>
                        </li>
                    </ul>
                </div>

                <!-- Email lead & ring group -->
                <div class="tab-content pt-2 border border-top-0">
                    <div class="mx-0"
                         :class="messageContainerClass.read"
                         id="messagebody"
                         aria-expanded="true"
                         v-if="[SequenceTypes.TYPE_EMAIL_LEAD, SequenceTypes.TYPE_EMAIL_RING_GROUP].includes(sequence.type)">
                         <div class="d-flex flex-grow-1 align-items-baseline mx-2">
                             <label for="message_body"
                                    class="el-form-item__label">
                                Message body:
                            </label>
                         </div>
                        <div class="d-flex justify-content-right mb-2">
                            <div class="d-flex step-editor-email-buttons mx-2">
                                <variable-dialog :in_input_group="false"
                                                 :has_agent="hasAgent">
                                </variable-dialog>
                                <template-list-dialog class="ml-2"/>
                            </div>
                        </div>
                        <el-form-item
                            class="no-border"
                            prop="message_body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="input-group">
                                        <wysiwyg v-model="sequence.message_body"
                                                 v-if="!edit_html"
                                                 @input="imposeMessageCharLimit('message_body')" />
                                        <el-input v-model="sequence.message_body"
                                                  type="textarea"
                                                  :autosize="{ minRows: 7 }"
                                                  v-else
                                                  @imput="imposeMessageCharLimit('message_body')" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 mx-2">
                                    <el-switch v-model="edit_html"/>
                                    <label class="el-form-item__label ml-2 float-none">
                                        Edit in HTML
                                        <span class="ml-3">
                                            <el-tooltip content="Tag's on visual mode will be visible to users"
                                                        placement="top"
                                                        effect="dark">
                                                (Warning: Don't write tags on visual editor mode)
                                            </el-tooltip>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </el-form-item>
                    </div>

                    <!-- MMS picture -->
                    <div class="mx-3 my-3"
                         :class="messageContainerClass.send"
                         id="mediafile"
                         aria-expanded="true"
                         v-if="hasPermissionTo('upload file sequence') && sequence.type == SequenceTypes.TYPE_MMS">
                        <el-form-item class="no-border"
                                      prop="message_file">
                            <el-upload v-if="sequence.message_file === null"
                                       action="/api/v1/automations/sequences/upload/media"
                                       :headers="headers"
                                       :on-success="onSuccessSendMedia"
                                       :on-error="onFailedSendMedia"
                                       :on-progress="progressUploadSendMedia"
                                       :before-upload="beforeUploadSendMedia"
                                       :file-list="uploadFileList.message"
                                       :limit="1"
                                       :multiple="false"
                                       drag>
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">
                                    Drop file here or <em>click to upload</em>
                                </div>
                                <div class="el-upload__tip"
                                     slot="tip">
                                    Media file such as jpg, png or gif (less than 5MB)
                                </div>
                            </el-upload>
                            <el-progress :text-inside="true"
                                         :stroke-width="18"
                                         :percentage="uploadPercentage.message"
                                         :status="uploadStatus.message"
                                         v-if="sequence.message_file === null">
                            </el-progress>
                            <vue-load-image v-if="sequence.message_file !== null">
                                <img slot="image"
                                     class="img-fluid r-2x preview mb-2"
                                     :src="'/static/uploaded_file/' + sequence.message_file"/>
                                <img slot="preloader"
                                     src="/assets/images/loading.svg"/>
                                <div slot="error">Error!</div>
                            </vue-load-image>
                            <div class="w-full text-right">
                                <button class="btn btn-sm btn-danger"
                                        v-if="sequence.message_file !== null && hasPermissionTo('delete file sequence')"
                                        @click.prevent="deleteFile(sequence.message_file)">
                                    <i class="material-icons loader"
                                       v-show="loading_message">&#xE863;</i>
                                    <i class="material-icons"
                                       v-show="!loading_message">&#xE872;</i>
                                    <span>Remove file</span>
                                </button>
                            </div>
                        </el-form-item>
                    </div>

                    <!-- MMS gif -->
                    <div class="mx-3 my-3"
                         :class="messageContainerClass.gif"
                         id="giffile"
                         aria-expanded="true"
                         v-if="sequence.type == SequenceTypes.TYPE_MMS">
                        <div id="search-gif"
                             aria-expanded="false">
                            <vue-load-image v-if="sequence.gif_url !== null">
                                <img slot="image"
                                     class="img-fluid d-block r-2x"
                                     :src='sequence.gif_url'/>
                                <img slot="preloader"
                                     src="/assets/images/loading.svg"/>
                                <div slot="error">Error!</div>
                            </vue-load-image>
                            <div class="w-full text-right">
                                <button class="btn btn-sm btn-danger"
                                        v-if="sequence.gif_url !== null && hasPermissionTo('delete file sequence') && isValidImageUrl(sequence.gif_url)"
                                        @click.prevent="removeGif">
                                    <i class="material-icons loader"
                                       v-show="loading_message">&#xE863;</i>
                                    <i class="material-icons"
                                       v-show="!loading_message">&#xE872;</i>
                                    <span>Remove file</span>
                                </button>
                            </div>
                            <el-form-item class="no-border"
                                          label="GIF:"
                                          prop="file_name"
                                          v-show="sequence.gif_url == null">
                                <div class="pos-rlt">
                                    <search-giphy @selected="setGif"></search-giphy>
                                </div>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="mx-3 my-3"
                         :class="messageContainerClass.read"
                         id="messagetts"
                         aria-expanded="true"
                         v-if="hasMessageComposer">
                        <div class="row" v-if="![SequenceTypes.TYPE_CALL_RING_GROUP, SequenceTypes.TYPE_CALL_LEAD].includes(sequence.type)">
                            <div class="col-md-8">
                                <span class="pull-right"><b>Limit:</b> {{ message_total_count }} / 1600</span>
                            </div>
                        </div>
                        <el-form-item class="no-border"
                                      :error="errors.first('message_tts')"
                                      prop="message_tts">

                            <el-popover ref="popover_messagetext"
                                        placement="bottom-start"
                                        title="Title"
                                        width="200"
                                        trigger="hover"
                                        content="">
                            </el-popover>
                            <div class="row">
                                <div class="col-8">
                                    <div class="input-group" v-loading="generatingUrls">
                                        <input-with-info-text class="form-control pt-2 pb-2"
                                                              info-text="[Text <b>STOP</b> to unsubscribe]"
                                                              :model-value="sequence.message_tts"
                                                              :is-loading="generatingUrls"
                                                              :is-info-text-active="is_optout_active"
                                                              :rows="msg_field_rows"
                                                              :left-pixels="22"
                                                              :bottom-pixels="10"
                                                              :label-size="13"
                                                              v-model="sequence.message_tts"
                                                              @input="imposeMessageCharLimit('message_tts')">
                                        </input-with-info-text>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <variable-dialog :in_input_group="false"
                                                     :has_agent="hasAgent">
                                    </variable-dialog>
                                    <template-list-dialog class="mt-2"/>
                                </div>
                            </div>
                            <div class="row" v-if="![SequenceTypes.TYPE_CALL_RING_GROUP, SequenceTypes.TYPE_CALL_LEAD].includes(sequence.type)">
                                <div class="col-md-8">
                                    <span class="pull-right ml-4"><b>Message parts:</b> {{ messageTtsCount }} / 160</span>
                                    <span class="pull-right"
                                          :class="messageCounterClass">
                                        Message(s): {{ messageCount }}
                                    </span>
                                </div>
                            </div>

                            <div class="checkbox-group">
                                <div class="checkbox-group-area">
                                    <el-checkbox
                                        class="w-full checkbox-group-item mt-1"
                                        v-model="sequence.confirm_message">
                                        <small class="ml-0 pl-0">
                                            Repeat message & require agent to press any key to connect to contact
                                        </small>
                                    </el-checkbox>

                                    <el-checkbox
                                        class="w-full checkbox-group-item"
                                        :value="is_optout_active"
                                        :disabled="isOptoutForced"
                                        @change="updateIsOptoutActive($event)">
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            placement="bottom"
                                            :disabled="!isOptoutForced"
                                            :content="optout_tooltip_text">
                                            <small class="ml-0 pl-0">
                                                Add opt-out phrase for this message
                                            </small>
                                        </el-tooltip>
                                    </el-checkbox>
                                </div>
                            </div>
                            <div class="alert alert-warning alert-aloware"
                                 v-if="sequence.confirm_message">
                                <small><i class="fas fa-exclamation-circle"></i> Because <strong>Repeat Message</strong> setting is enabled, add a prompt like “Press any key to connect” to the end of the message so the agent knows how to connect once they receive this message</small>
                            </div>
                        </el-form-item>
                    </div>

                    <div class="mx-3 my-3"
                         :class="messageContainerClass.play"
                         id="messagefile"
                         aria-expanded="true"
                         v-if="hasPermissionTo('upload file sequence') && [SequenceTypes.TYPE_CALL_LEAD, SequenceTypes.TYPE_RVM, SequenceTypes.TYPE_CALL_RING_GROUP, SequenceTypes.TYPE_AUTO_DIAL].includes(sequence.type)">
                        <audio-recorder v-if="sequence.message_file === null"
                                        class="audio-recorder-wrapper"
                                        :action="'/api/v1/automations/sequences/upload-file/prompt'"
                                        :upload_type="'message_file'"
                                        @applyUploadedAudio="applyUploadedAudio">
                        </audio-recorder>
                        <div class="audio-uploader-wrapper">
                            <el-upload v-if="sequence.message_file === null"
                                       action="/api/v1/automations/sequences/upload-file/prompt"
                                       :headers="headers"
                                       :on-success="onSuccessMessage"
                                       :on-error="onFailedMessage"
                                       :on-progress="progressUploadMessage"
                                       :before-upload="beforeUploadMessage"
                                       :file-list="uploadFileList.message"
                                       drag>
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">
                                    Drop file here or <em>click to upload</em>
                                </div>
                                <div class="el-upload__tip"
                                     slot="tip">
                                    MP3/WAV file (less than 8MB)
                                </div>
                            </el-upload>
                            <el-progress :text-inside="true"
                                         :stroke-width="18"
                                         :percentage="uploadPercentage.message"
                                         :status="uploadStatus.message"
                                         v-if="sequence.message_file === null">
                            </el-progress>
                            <audio v-if="sequence.message_file !== null"
                                   controls>
                                <source :src="'/static/uploaded_file/' + sequence.message_file">
                                Your browser does not support the audio element.
                            </audio>
                            <button class="btn btn-sm btn-danger pull-right"
                                    v-if="sequence.message_file !== null && hasPermissionTo('delete file sequence')"
                                    @click.prevent="deleteFile(sequence.message_file)">
                                <i class="material-icons loader"
                                   v-show="loading_message">&#xE863;</i>
                                <i class="material-icons"
                                   v-show="!loading_message">&#xE872;</i>
                                <span>Remove file</span>
                            </button>
                        </div>
                        <el-checkbox
                            class="w-full checkbox-label-description mb-4"
                            v-model="sequence.confirm_message"
                            border>
                            <small>
                                Repeat message & require agent to press any key to connect to contact
                            </small>
                        </el-checkbox>
                        <div class="alert alert-warning alert-aloware"
                             v-if="sequence.confirm_message">
                            <small><i class="fas fa-exclamation-circle"></i> Because <strong>Repeat Message</strong> setting is enabled, make sure the audio message contains a prompt like “Press any key to connect” so the agent knows how to connect once they receive this message</small>
                        </div>
                    </div>
                </div>

                <!-- RVM -->
                <div class="row"
                     v-if="sequence.type == SequenceTypes.TYPE_RVM">
                    <div class="col-12">
                        <p class="mb-0 mb-3">
                            <strong>Please let us know before using this step type.</strong>
                            <br>
                            <span class="text-muted">Some jurisdictions do not allow Ringless Voicemail</span>
                        </p>
                    </div>
                </div>

                <!-- MMS -->
                <div class="row"
                     v-if="sequence.type == SequenceTypes.TYPE_MMS">
                    <div class="col-12">
                        <p class="mb-0 mb-3">
                            <strong>Only image files are supported.</strong>
                        </p>
                    </div>
                </div>

                <!-- Call Ring Group -->
                <div class="row mt-3"
                     v-if="sequence.type === SequenceTypes.TYPE_CALL_RING_GROUP">
                    <div class="col-12">
                        <p class="lh-1p4">
                            This text-to-speech prompt automatically plays immediately as soon as the call gets connected.
                            It’s important to make sure that the forwarding number does not have any prompts as the
                            text-to-speech will play while the prompt is also playing causing the agent to miss the
                            text-to-speech prompt.
                        </p>
                    </div>
                </div>

                <!-- SMS Ring Group and Email Ring Group -->
                <div class="row"
                     v-if="[SequenceTypes.TYPE_SMS_RING_GROUP, SequenceTypes.TYPE_EMAIL_RING_GROUP].includes(sequence.type)">
                    <div class="col-12">
                        <p class="mb-0 mb-3">
                            <strong>This message will be sent to all your agents. Use it as a reminder or
                                call for action.</strong>
                            <br>
                            <span class="text-muted">Example: "Hey [AgentName], dont forget to take your lunch break after 10 straight calls."</span>
                        </p>
                    </div>
                </div>

                <!-- Webhook -->
                <div class="row"
                     v-if="sequence.type == SequenceTypes.TYPE_WEBHOOK">
                    <div class="col-12">
                        <p class="mb-0 mb-3">
                            <strong>Triggers a webhook with the contact information attached.</strong>
                            <br>
                            <span class="text-muted">Consider letting our support know. We might already have a direct integration.</span>
                        </p>
                    </div>
                </div>

                <!-- Birthday -->
                <div class="row"
                     v-if="sequence.type == SequenceTypes.TYPE_BIRTHDAY && !statics.whitelabel">
                    <div class="col-12">
                        <p class="mb-0 mb-3">
                            <strong>Did you know Aloware was born in November 2017?</strong>
                        </p>
                    </div>
                </div>

                <!-- Add Contact Tag -->
                <div class="row"
                     v-if="sequence.type == SequenceTypes.TYPE_ADD_TAG_CONTACT">
                    <div class="col-12">
                        <p class="mb-0 mb-3">
                            <strong>Attach a specific tag to this contact.</strong>
                            <br>
                            <span class="text-muted">Use this sequence to mark the lead as "unresponsive".</span>
                        </p>
                    </div>
                </div>

                <!-- Remove Contact Tag -->
                <div class="row"
                     v-if="sequence.type == SequenceTypes.TYPE_REMOVE_TAG_CONTACT">
                    <div class="col-12">
                        <p class="mb-0 mb-3">
                            <strong>Detach a specific tag from this contact.</strong>
                            <br>
                            <span class="text-muted">Use this sequence remove the lead's mark.</span>
                        </p>
                    </div>
                </div>

                <!-- Change Contact Owner -->
                <div class="row"
                     v-if="sequence.type == SequenceTypes.TYPE_CHANGE_CONTACT_OWNER">
                    <div class="col-12">
                        <p class="mb-0 mb-3">
                            <strong>Assigns this lead to another agent.</strong>
                        </p>
                    </div>
                </div>

                <!-- Call Lead -->
                <el-form-item class="no-border my-4"
                              prop="amd_enabled"
                              v-if="sequence.type === SequenceTypes.TYPE_CALL_LEAD">
                    <el-checkbox
                        v-if="sequence.type === SequenceTypes.TYPE_CALL_LEAD"
                        class="w-full checkbox-label-description mb-4"
                        v-model="sequence.amd_enabled"
                        border>
                        <label>Detect answering machines and voicemails?</label>
                        <small>
                            Detects answering machines and voicemails
                        </small>
                    </el-checkbox>
                </el-form-item>

                <!-- Call Lead with AMD -->
                <div v-if="sequence.type == SequenceTypes.TYPE_CALL_LEAD && sequence.amd_enabled"
                     class="b-b nav-active-greenish">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="border border-right-0 rounded-top-left"
                               :class="amdVoicemailNavClass.read"
                               href="#"
                               data-toggle="tab"
                               data-target="#amd-voicemail-tts"
                               aria-expanded="false">
                                <i class="fa fa-robot"></i>
                                Text to Speech
                            </a>
                        </li>
                        <li class="nav-item"
                            v-if="hasPermissionTo('upload file sequence')">
                            <a class="border rounded-top-right"
                               :class="amdVoicemailNavClass.play"
                               href="#"
                               data-toggle="tab"
                               data-target="#amd-voicemail-file"
                               aria-expanded="true">
                                <i class="fa fa-play text-md"></i>
                                Play Recording
                            </a>
                        </li>
                    </ul>
                </div>

                <!-- Call Lead with AMD -->
                <div v-if="sequence.type == SequenceTypes.TYPE_CALL_LEAD && sequence.amd_enabled"
                     class="tab-content pt-2 border border-top-0">
                    <div class="mx-3 mb-5"
                         :class="amdVoicemailContainerClass.read"
                         id="amd-voicemail-tts"
                         aria-expanded="true">
                        <el-form-item class="no-border mb-4"
                                      label="Voicemail text:"
                                      :error="errors.first('amd_voicemail_tts')"
                                      prop="amd_voicemail_tts">
                            <el-popover ref="popover_amd_voicemail_text"
                                        placement="bottom-start"
                                        title="Title"
                                        width="200"
                                        trigger="hover"
                                        content="">
                            </el-popover>
                            <div class="row">
                                <div class="col-8">
                                    <div class="input-group">
                                        <el-input type="textarea"
                                                  class="form-control pt-2 pb-2"
                                                  maxlength="190"
                                                  :rows="4"
                                                  v-model="sequence.amd_voicemail_tts"
                                                  @input="imposeMessageCharLimit('amd_voicemail_tts')">
                                        </el-input>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <variable-dialog :in_input_group="false"
                                                     :has_agent="hasAgent">
                                    </variable-dialog>
                                    <template-list-dialog class="mt-2"/>
                                </div>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="mx-3 mb-3"
                         :class="amdVoicemailContainerClass.play"
                         id="amd-voicemail-file"
                         aria-expanded="true"
                         v-if="hasPermissionTo('upload file sequence')">
                        <audio-recorder v-if="sequence.amd_voicemail_file === null"
                                        class="audio-recorder-wrapper"
                                        :action="'/api/v1/automations/sequences/upload-file/amd-voicemail'"
                                        :upload_type="'amd_voicemail_file'"
                                        @applyUploadedAudio="applyUploadedAudio">
                        </audio-recorder>
                        <div class="audio-uploader-wrapper">
                            <el-upload v-if="sequence.amd_voicemail_file === null"
                                       action="/api/v1/automations/sequences/upload-file/amd-voicemail"
                                       :headers="headers"
                                       :on-success="onSuccessAMDVoicemail"
                                       :on-error="onFailedAMDVoicemail"
                                       :on-progress="progressUploadAMDVoicemail"
                                       :before-upload="beforeUploadAMDVoicemail"
                                       :file-list="uploadFileList.amd_voicemail"
                                       drag>
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">
                                    Drop file here or <em>click to upload</em>
                                </div>
                                <div class="el-upload__tip"
                                     slot="tip">
                                    MP3/WAV file (less than 8MB)
                                </div>
                            </el-upload>
                            <el-progress :text-inside="true"
                                         :stroke-width="18"
                                         :percentage="uploadPercentage.amd_voicemail"
                                         :status="uploadStatus.amd_voicemail"
                                         v-if="sequence.amd_voicemail_file === null">
                            </el-progress>
                            <audio v-if="sequence.amd_voicemail_file !== null"
                                   controls>
                                <source :src="'/static/uploaded_file/' + sequence.amd_voicemail_file">
                                Your browser does not support the audio element.
                            </audio>
                            <button class="btn btn-sm btn-danger pull-right"
                                    v-if="sequence.amd_voicemail_file !== null && hasPermissionTo('delete file sequence')"
                                    @click.prevent="deleteFile(sequence.amd_voicemail_file, 'amd_voicemail')">
                                <i class="material-icons loader"
                                   v-show="loading_amd_voicemail">&#xE863;</i>
                                <i class="material-icons"
                                   v-show="!loading_amd_voicemail">&#xE872;</i>
                                <span>Remove file</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row"
             v-if="sequence.type == SequenceTypes.TYPE_MMS">
            <div class="col-md-12">
                <div class="b-b nav-active-greenish">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="border border-right-0 rounded-top-left"
                               :class="{'nav-link': true, 'active': true}"
                               href="#"
                               data-toggle="tab"
                               data-target="#messagetts"
                               aria-expanded="false">
                                Message
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="tab-content p-2 border border-top-0">
                    <div class="mx-3 my-3"
                         :class="messageContainerClass.read"
                         id="messagetts"
                         aria-expanded="true">
                        <div class="row">
                            <div class="col-md-8">
                                <span class="pull-right"><b>Limit:</b> {{ message_total_count }} / 1600</span>
                            </div>
                        </div>
                        <el-form-item
                            :error="errors.first('message_tts')"
                            prop="message_tts">
                            <el-popover ref="popover_messagetext"
                                        placement="bottom-start"
                                        title="Title"
                                        width="200"
                                        trigger="hover"
                                        content="">
                            </el-popover>
                            <div class="row">
                                <div class="col-8">
                                    <div class="input-group">
                                        <input-with-info-text class="form-control pt-2 pb-2"
                                                              info-text="[Text <b>STOP</b> to unsubscribe]"
                                                              :model-value="sequence.message_tts"
                                                              :is-loading="generatingUrls"
                                                              :is-info-text-active="is_optout_active"
                                                              :rows="msg_field_rows"
                                                              :left-pixels="22"
                                                              :bottom-pixels="10"
                                                              :label-size="13"
                                                              v-model="sequence.message_tts"
                                                              @input="imposeMessageCharLimit('message_tts')">
                                        </input-with-info-text>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <variable-dialog :in_input_group="false"
                                                     :has_agent="hasAgent">
                                    </variable-dialog>
                                    <template-list-dialog class="mt-2"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-8">
                                    <span class="pull-right ml-4"><b>Message parts:</b> {{ messageTtsCount }} / 160</span>
                                    <span class="pull-right"
                                          :class="messageCounterClass">
                                        Message(s): {{ messageCount }}
                                    </span>
                                </div>
                            </div>

                            <div class="checkbox-group">
                                <div class="checkbox-group-area">
                                    <el-checkbox
                                        class="w-full checkbox-group-item"
                                        :value="is_optout_active"
                                        :disabled="current_company.force_opt_out_phrase"
                                        @change="updateIsOptoutActive($event)">
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            placement="bottom"
                                            :disabled="!isOptoutForced"
                                            :content="optout_tooltip_text">
                                            <small class="ml-0 pl-0">
                                                Add opt-out phrase for this message
                                            </small>
                                        </el-tooltip>
                                    </el-checkbox>
                                </div>
                            </div>
                        </el-form-item>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
import { acl_mixin, form_validation_mixin, url_shortener_mixin } from '../../mixins'
import auth from "../../auth"
import TemplateListDialog from "../template-list-dialog"
import VariableDialog from "../variable-dialog"
import SearchGiphy from "../search-giphy"
import VueLoadImage from "vue-load-image"
import * as AuthMethods from '../../constants/sequence-auth-methods'
import * as HttpMethods from '../../constants/sequence-http-methods'
import * as SequenceTypes from '../../constants/sequence-types'
import { IS_OPT_OUT_FORCED_TEXT } from '../../constants/compliance-messages'
import InputWithInfoText from '../input-with-info-text'

export default {
    name: "comm-tools",

    components: {
        TemplateListDialog,
        VariableDialog,
        SearchGiphy,
        'vue-load-image': VueLoadImage,
        InputWithInfoText,
    },

    mixins: [
        acl_mixin,
        form_validation_mixin,
        url_shortener_mixin
    ],

    props: {
        value: {
            required: true,
            type: Object
        },
        errors: {
            type: Object,
            require: true
        },
        is_optout_active: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            auth: auth,
            sequence: this.value,
            sequence_form_key: 1,
            AuthMethods,
            HttpMethods,
            SequenceTypes,
            loading_message: false,
            loading_amd_voicemail: false,
            uploadPercentage: {
                message: 0,
                amd_voicemail: 0,
            },
            uploadStatus: {
                message: 'success',
                amd_voicemail: 'success',
            },
            uploadFileList: {
                message: [],
                amd_voicemail: [],
            },
            time_options: {
                format: 'h:mma',
                value_format: 'HH:mm:ss',
                picker_option: {
                    selectableRange: '00:00:00 - 23:59:59'
                }
            },
            audio_file_messages: {
                message_file: 'File has been uploaded successfully.',
                amd_voicemail_file: 'Voicemail file has been uploaded successfully.'
            },
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + auth.user.profile.api_token,
                'X-Socket-Id': window.Echo.socketId()
            },
            message_total_count: 0,
            msg_field_rows: 2,
            edit_html: false,
            optout_message: '\nText STOP to unsubscribe.',
            optout_tooltip_text: IS_OPT_OUT_FORCED_TEXT,
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        hasAgent() {
            if (this.sequence) {
                return [
                    SequenceTypes.TYPE_SMS_RING_GROUP,
                    SequenceTypes.TYPE_EMAIL_RING_GROUP,
                    SequenceTypes.TYPE_CALL_RING_GROUP,
                    SequenceTypes.TYPE_WEBHOOK,
                    SequenceTypes.TYPE_AUTO_DIAL,
                    SequenceTypes.TYPE_ADD_TO_POWER_DIALER,
                    SequenceTypes.TYPE_PREDICTIVE_DIAL,
                    SequenceTypes.TYPE_REMOVE_FROM_POWER_DIALER,
                    SequenceTypes.TYPE_ENROLL_TO_OTHER_WORKFLOW,
                    SequenceTypes.TYPE_START_ALOAI_CONVERSATION
                ].includes(this.sequence.type)
            }

            return false
        },

        hasExtraStep () {
            if (this.sequence.type) {
                return ![
                    SequenceTypes.TYPE_WEBHOOK,
                    SequenceTypes.TYPE_ADD_TAG_CONTACT,
                    SequenceTypes.TYPE_CHANGE_CONTACT_OWNER,
                    SequenceTypes.TYPE_ADD_TO_POWER_DIALER,
                    SequenceTypes.TYPE_PREDICTIVE_DIAL,
                    SequenceTypes.TYPE_REMOVE_TAG_CONTACT,
                    SequenceTypes.TYPE_REMOVE_FROM_POWER_DIALER,
                    SequenceTypes.TYPE_ENROLL_TO_OTHER_WORKFLOW,
                    SequenceTypes.TYPE_START_ALOAI_CONVERSATION
                ].includes(this.sequence.type)
            }

            return false
        },

        hasMessageComposer () {
            return ![
                SequenceTypes.TYPE_MMS,
                SequenceTypes.TYPE_RVM,
                SequenceTypes.TYPE_ADD_TAG_CONTACT,
                SequenceTypes.TYPE_CHANGE_CONTACT_OWNER,
                SequenceTypes.TYPE_EMAIL_LEAD,
                SequenceTypes.TYPE_EMAIL_RING_GROUP,
                SequenceTypes.TYPE_ADD_TO_POWER_DIALER,
                SequenceTypes.TYPE_REMOVE_TAG_CONTACT,
                SequenceTypes.TYPE_REMOVE_FROM_POWER_DIALER,
                SequenceTypes.TYPE_ENROLL_TO_OTHER_WORKFLOW,
                SequenceTypes.TYPE_START_ALOAI_CONVERSATION
            ].includes(this.sequence.type)
        },

        messageNavClass() {
            return {
                read: {
                    'disabled': this.sequence.message_file !== null,
                    'nav-link': true,
                    'active': this.sequence.message_file === null
                },
                play: {
                    'nav-link': true,
                    'active': this.sequence.message_file !== null || this.sequence.type == SequenceTypes.TYPE_RVM
                },
                send: {
                    'nav-link': true,
                    'active': (this.sequence.message_file !== null || this.sequence.type == SequenceTypes.TYPE_MMS) && !this.sequence.gif_url
                },
                gif: {
                    'nav-link': true,
                    'active': this.sequence.gif_url !== null && this.sequence.type == SequenceTypes.TYPE_MMS
                },
            }
        },

        messageContainerClass() {
            return {
                read: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.sequence.message_file === null || this.sequence.type == SequenceTypes.TYPE_MMS
                },
                play: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.sequence.message_file !== null || this.sequence.type == SequenceTypes.TYPE_RVM
                },
                send: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': (this.sequence.message_file !== null || this.sequence.type == SequenceTypes.TYPE_MMS) && !this.sequence.gif_url
                },
                gif: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.sequence.gif_url !== null && this.sequence.type == SequenceTypes.TYPE_MMS
                }
            }
        },

        amdVoicemailNavClass() {
            return {
                read: {
                    'disabled': this.sequence.amd_voicemail_file !== null,
                    'nav-link': true,
                    'active': this.sequence.amd_voicemail_file === null
                },
                play: {
                    'nav-link': true,
                    'active': this.sequence.amd_voicemail_file !== null
                }
            }
        },

        amdVoicemailContainerClass() {
            return {
                read: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.sequence.amd_voicemail_file === null
                },
                play: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.sequence.amd_voicemail_file !== null
                }
            }
        },

        getPrecision() {
            switch (this.sequence.unit) {
                case 'seconds':
                    return 0
                case 'minutes':
                case 'hours':
                    return 0
                case 'days':
                    return 0
                default:
                    return 0
            }
        },

        getTitle() {
            if (this.editingSequence) {
                return 'Modify Step > ' + this.$options.filters.capitalize(this.$options.filters.fixSequenceType(this.sequence.type))
            }

            if (this.sequence.type) {
                return 'Add Step > ' + this.$options.filters.capitalize(this.$options.filters.fixSequenceType(this.sequence.type))
            }

            return 'Add Step'
        },

        messageTtsCount() {
            if (this.getPropertyValue('message_tts') && this.getPropertyValueLength('message_tts')) {
                return this.getPropertyValueLength('message_tts') % 160
            }

            return 0
        },

        messageCount() {
            if (this.getPropertyValue('message_tts') && this.getPropertyValueLength('message_tts')) {
                return Math.ceil(this.getPropertyValueLength('message_tts') / 160)
            }

            return 0
        },

        messageCounterClass() {
            if (this.messageCount > 1) {
                return 'text-danger'
            }

            return 'text-success'
        },

        isOptoutForced() {
            return this.current_company.force_opt_out_phrase
        },
    },

    methods: {
        imposeMessageCharLimit(property) {
            const property_value = this.sequence[property] || ''
            const property_value_length = this.getPropertyValueLength(property)

            if (property_value && property_value_length > this.getPropertyLengthLimit(property)) {
                this.sequence[property] = property_value.substr(0, this.getPropertyLengthLimit(property))
                this.message_total_count = this.getPropertyValueLength(property)
            } else {
                this.message_total_count = property_value_length
            }

            this.preValidateForm('add_sequence')
        },

        removeGif() {
            this.sequence.gif_url = null
            this.sequence_form_key++
        },

        onSuccessMessage(res) {
            this.$notify({
                offset: 95,
                title: 'Step',
                message: this.audio_file_messages.message_file,
                type: 'success',
                showClose: true,
            })
            this.sequence.message_file = res.file_name
            this.uploadStatus.message = 'success'
            this.uploadPercentage.message = 0
            this.preValidateForm('add_sequence')
        },

        onFailedMessage(err) {
            this.$root.handleUploadErrors(err.message, err)
            this.uploadStatus.message = 'exception'
            this.uploadPercentage.message = 0
            this.preValidateForm('add_sequence')
        },

        beforeUploadMessage() {
            this.uploadStatus.message = 'success'
            this.uploadPercentage.message = 0
        },

        progressUploadMessage(event) {
            this.uploadPercentage.message = parseInt(event.percent)
        },

        onSuccessSendMedia(res) {
            this.$notify({
                offset: 95,
                title: 'Step',
                message: 'Media file has been uploaded successfully.',
                type: 'success',
                showClose: true,
            })
            this.sequence.message_file = res.file_name
            this.uploadStatus.message = 'success'
            this.uploadPercentage.message = 0
            this.sequence.gif_url = null
            this.preValidateForm('add_sequence')
        },

        setGif(url) {
            if (this.sequence.message_file !== null) {
                this.$confirm('If you set GIF image it will delete the image you uploaded, Are you sure you want to replace it?', 'Warning', {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    customClass: 'width-500 fixed',
                    type: 'warning'
                }).then(async (res) => {
                    await this.deleteFile(this.sequence.message_file)
                    this.sequence.message_file = null
                    this.sequence.gif_url = url
                    this.preValidateForm('add_sequence')
                }).catch(() => {

                })
            } else {
                this.sequence.gif_url = url
                this.preValidateForm('add_sequence')
            }

            this.sequence_form_key++
        },

        isValidImageUrl(url) {
            if (!url) {
                return false
            }
            return (url.match(/\.(jpeg|jpg|gif|png)?\?.+/) != null)
        },

        onFailedSendMedia(err) {
            this.$root.handleUploadErrors(err.message, err)
            this.uploadStatus.message = 'exception'
            this.uploadPercentage.message = 0
            this.preValidateForm('add_sequence')
        },

        beforeUploadSendMedia() {
            this.uploadStatus.message = 'success'
            this.uploadPercentage.message = 0
        },

        progressUploadSendMedia(event) {
            this.uploadPercentage.message = parseInt(event.percent)
        },

        onSuccessAMDVoicemail(res) {
            this.$notify({
                offset: 95,
                title: 'Step',
                message: this.audio_file_messages.amd_voicemail_file,
                type: 'success',
                showClose: true,
            })
            this.sequence.amd_voicemail_file = res.file_name
            this.uploadStatus.amd_voicemail = 'success'
            this.uploadPercentage.amd_voicemail = 0
            this.preValidateForm('add_sequence')
        },

        onFailedAMDVoicemail(err) {
            this.$root.handleUploadErrors(err.amd_voicemail, err)
            this.uploadStatus.amd_voicemail = 'exception'
            this.uploadPercentage.amd_voicemail = 0
            this.preValidateForm('add_sequence')
        },

        beforeUploadAMDVoicemail() {
            this.uploadStatus.amd_voicemail = 'success'
            this.uploadPercentage.amd_voicemail = 0
        },

        progressUploadAMDVoicemail(event) {
            this.uploadPercentage.amd_voicemail = parseInt(event.percent)
        },

        deleteFile(file_name, type = 'message') {
            let message = ''
            if (type == 'message') {
                this.sequence.message_file = null
                this.uploadPercentage.message = 0
                message = 'File has been deleted successfully'
            } else {
                this.sequence.amd_voicemail_file = null
                this.uploadPercentage.amd_voicemail = 0
                message = 'Voicemail file has been deleted successfully'
            }
            this.$notify({
                offset: 95,
                title: 'Step',
                message: message,
                type: 'success',
                showClose: true,
            })
            this.loading_message = false
            this.loading_amd_voicemail = false
            this.preValidateForm('add_sequence')
        },

        handleUploadErrors(error, full_error = null) {
            if (!full_error) {
                full_error = {}
            }
            if (typeof full_error.status !== 'number') {
                full_error.status = 500
                this.$root.handleErrors(full_error)
                return
            }
            if (typeof error === 'string') {
                error = JSON.parse(error)
            }
            let err
            if (error.message === 'This action is unauthorized.') {
                err = {
                    status: 403,
                }
            } else {
                let is_error = typeof error.error !== 'undefined'
                err = {
                    status: 422,
                    data: {
                        errors: is_error ? [error.error] : error.errors.file
                    }
                }
            }

            this.$root.handleErrors(err)
        },

        addToMessage(event) {
            this.sequence.message_tts += event.target.text.trim()
        },

        applyUploadedAudio(data) {
            this.$set(this.sequence, data.upload_type, null)
            setTimeout(() => {
                this.$set(this.sequence, data.upload_type, data.uid)
                this.$notify({
                    offset: 95,
                    title: '',
                    message: this.audio_file_messages[data.upload_type],
                    type: 'success',
                    showClose: true,
                })
                this.preValidateForm('add_sequence')
            }, 100)
        },

        getOptoutMessage() {
            return this.is_optout_active ? ` ${this.optout_message}` : ''
        },

        getOptoutMessageLength() {
            return this.getOptoutMessage().length
        },

        getPropertyValueLength(property) {
            const property_value = this.sequence[property] || ''
            return property_value.length + this.getOptoutMessageLength()
        },

        getPropertyValue(property, with_optout_message = true) {
            const property_value = this.sequence[property] || ''
            return `${property_value}${with_optout_message ? this.getOptoutMessage() : ''}`
        },

        getPropertyLengthLimit(property) {
            return this.is_optout_active ? 1600 - this.getOptoutMessageLength() : 1600
        },

        updateIsOptoutActive(event) {
            this.$emit('change-is-optout-active', event)
        }
    },

    watch: {
        is_optout_active () {
            this.imposeMessageCharLimit('message_tts')
        },
    },

    mounted() {
        if ([SequenceTypes.TYPE_MMS, SequenceTypes.TYPE_RVM, SequenceTypes.TYPE_ADD_TAG_CONTACT, SequenceTypes.TYPE_CHANGE_CONTACT_OWNER, SequenceTypes.TYPE_EMAIL_LEAD, SequenceTypes.TYPE_EMAIL_RING_GROUP, SequenceTypes.TYPE_ADD_TO_POWER_DIALER, SequenceTypes.TYPE_REMOVE_TAG_CONTACT].includes(this.sequence.type)) {
            this.updateIsOptoutActive(this.is_optout_active)
        }

        this.imposeMessageCharLimit('message_tts')
    },
}
</script>
