var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body page-ring-group-dialog", attrs: { id: "view" } },
    [
      _c(
        "div",
        { staticClass: "container-fluid d-relative" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 py-4" }, [
              _c("div", { staticClass: "card" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _vm.ring_group
                      ? _c(
                          "el-form",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            ref: "ring_group_form",
                            staticClass: "p-0 form-aloware",
                            attrs: {
                              "label-position": "top",
                              rules: _vm.rules_ring_group,
                              model: _vm.ring_group,
                            },
                            nativeOn: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.submitForm("ring_group_form")
                              },
                            },
                          },
                          [
                            _c(
                              "el-tabs",
                              {
                                on: { "tab-click": _vm.tabClicked },
                                model: {
                                  value: _vm.active_tab_name,
                                  callback: function ($$v) {
                                    _vm.active_tab_name = $$v
                                  },
                                  expression: "active_tab_name",
                                },
                              },
                              [
                                _c(
                                  "el-tab-pane",
                                  {
                                    staticClass: "p-2",
                                    attrs: {
                                      name: "general-settings",
                                      label: "General",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-8" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "no-border",
                                              attrs: { prop: "name" },
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "page-anchor",
                                                attrs: { id: "name" },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-label" },
                                                [
                                                  _c("h5", [_vm._v("Name")]),
                                                  _vm._v(" "),
                                                  _c("small", [
                                                    _vm._v(
                                                      "\n                                                        Use a name that is short and easily identifiable in your\n                                                        reports.\n                                                    "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("el-input", {
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.preValidateForm(
                                                      "ring_group_form"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.ring_group.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.ring_group,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "ring_group.name",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "no-border",
                                              attrs: { prop: "description" },
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "page-anchor",
                                                attrs: { id: "description" },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-label" },
                                                [
                                                  _c("h5", [
                                                    _vm._v(
                                                      "Description (Optional)"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("el-input", {
                                                attrs: {
                                                  type: "textarea",
                                                  maxlength: "190",
                                                  autosize: { minRows: 2 },
                                                  "show-word-limit": "",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.preValidateForm(
                                                      "ring_group_form"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.ring_group.description,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.ring_group,
                                                      "description",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "ring_group.description",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "mt-4",
                                              attrs: {
                                                prop: "ordered_user_team_ids",
                                              },
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "page-anchor",
                                                attrs: { id: "users" },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-12" },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-search-p",
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "w-50 mx-auto text-center mb-3",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fas fa-phone mr-2 color-999",
                                                            staticStyle: {
                                                              "font-size":
                                                                "40px",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "lh-1p4",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                                When calls come in, it will run through the sequence of layers below.\n                                                                Each layer must contain one team, or one or more users.\n                                                                You can add and move layers as you like.\n                                                            "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "ordered-user-team-selector",
                                                        {
                                                          ref: "ordered_user_team_selector",
                                                          attrs: {
                                                            ordered_user_ids_value:
                                                              _vm.ring_group
                                                                .ordered_user_ids,
                                                            ordered_team_ids_value:
                                                              _vm.ring_group
                                                                .ordered_team_ids,
                                                            disabledDialogTeam:
                                                              _vm.ringGroupToTeam,
                                                            ringGroup:
                                                              _vm.attributesRingGroup,
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.changeOrderedUsersAndTeams,
                                                            ringGroupToTeam:
                                                              _vm.addRingGroupToTeam,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            [
                                              _c("span", {
                                                staticClass: "page-anchor",
                                                attrs: { id: "layer_settings" },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-label mb-3",
                                                },
                                                [
                                                  _c("h5", [
                                                    _vm._v("Routing Settings"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("small", [
                                                    _vm._v(
                                                      "Select how calls are being routed to each user of this ring group and and the dial duration."
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-popover",
                                                {
                                                  ref: "popover_simul",
                                                  attrs: {
                                                    placement: "bottom-start",
                                                    title: _vm._f(
                                                      "humanReadableDialMode"
                                                    )(_vm.DIAL_MODE_SIMUL),
                                                    width: "400",
                                                    trigger: "hover",
                                                  },
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      "\n                                                        Also known as SimulDial, this option rings all phones within the same order at the same time.\n                                                        The first person that picks up the phone wins the call, all other rings will be disconnected.\n                                                    "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-popover",
                                                {
                                                  ref: "popover_rand",
                                                  attrs: {
                                                    placement: "bottom-start",
                                                    title: _vm._f(
                                                      "humanReadableDialMode"
                                                    )(_vm.DIAL_MODE_RAND),
                                                    width: "400",
                                                    trigger: "hover",
                                                  },
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      "\n                                                        Randomly select a user within the same order. If that user does not pick up,\n                                                        randomly pick another user to offer the call to.\n                                                    "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-popover",
                                                {
                                                  ref: "popover_round",
                                                  attrs: {
                                                    placement: "bottom-start",
                                                    title: _vm._f(
                                                      "humanReadableDialMode"
                                                    )(_vm.DIAL_MODE_ROUND),
                                                    width: "400",
                                                    trigger: "hover",
                                                    content:
                                                      "Round-robin between users within the same order.",
                                                  },
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      "\n                                                        In each layer, attempt to route the calls one by one to your reps in a round-robin fashion based on longest availability.\n                                                        The user that previously answered a call will be excluded for the next attempt.\n                                                        Each phone will ring until someone answers. If nobody answers, the next layer will be executed.\n                                                    "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-popover",
                                                {
                                                  ref: "popover_longest_available",
                                                  attrs: {
                                                    placement: "bottom-start",
                                                    title: _vm._f(
                                                      "humanReadableDialMode"
                                                    )(
                                                      _vm.DIAL_MODE_LONGEST_AVAILABLE
                                                    ),
                                                    width: "400",
                                                    trigger: "hover",
                                                    content:
                                                      "Route the calls between users within the same order based on longest availability.",
                                                  },
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      "\n                                                        In each layer, attempt to route the calls one by one to your reps based on longest availability.\n                                                        Each phone will ring until someone answers. If nobody answers, the next layer will be executed. Optimized for reducing agent idle time.\n                                                    "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "d-lg-flex" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mb-2" },
                                                    [
                                                      _c(
                                                        "strong",
                                                        {
                                                          staticClass:
                                                            "mr-2 align-self-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "On each layer"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-radio-group",
                                                        {
                                                          staticClass: "mr-2",
                                                          model: {
                                                            value:
                                                              _vm.ring_group
                                                                .dial_mode,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ring_group,
                                                                "dial_mode",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ring_group.dial_mode",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-radio-button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "popover",
                                                                  rawName:
                                                                    "v-popover:popover_simul",
                                                                  arg: "popover_simul",
                                                                },
                                                              ],
                                                              attrs: {
                                                                label:
                                                                  _vm.DIAL_MODE_SIMUL,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                                " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "humanReadableDialMode"
                                                                    )(
                                                                      _vm.DIAL_MODE_SIMUL
                                                                    )
                                                                  ) +
                                                                  "\n                                                            "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-radio-button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "popover",
                                                                  rawName:
                                                                    "v-popover:popover_rand",
                                                                  arg: "popover_rand",
                                                                },
                                                              ],
                                                              attrs: {
                                                                label:
                                                                  _vm.DIAL_MODE_RAND,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                                " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "humanReadableDialMode"
                                                                    )(
                                                                      _vm.DIAL_MODE_RAND
                                                                    )
                                                                  ) +
                                                                  "\n                                                            "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-radio-button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "popover",
                                                                  rawName:
                                                                    "v-popover:popover_round",
                                                                  arg: "popover_round",
                                                                },
                                                              ],
                                                              attrs: {
                                                                label:
                                                                  _vm.DIAL_MODE_ROUND,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                                " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "humanReadableDialMode"
                                                                    )(
                                                                      _vm.DIAL_MODE_ROUND
                                                                    )
                                                                  ) +
                                                                  "\n                                                            "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-radio-button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "popover",
                                                                  rawName:
                                                                    "v-popover:popover_longest_available",
                                                                  arg: "popover_longest_available",
                                                                },
                                                              ],
                                                              attrs: {
                                                                label:
                                                                  _vm.DIAL_MODE_LONGEST_AVAILABLE,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                                " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "humanReadableDialMode"
                                                                    )(
                                                                      _vm.DIAL_MODE_LONGEST_AVAILABLE
                                                                    )
                                                                  ) +
                                                                  "\n                                                            "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "strong",
                                                        {
                                                          staticClass:
                                                            "mr-2 align-self-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Dial users for\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-select",
                                                        {
                                                          attrs: {
                                                            placeholder:
                                                              "Select",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ring_group
                                                                .call_handling_options
                                                                .timeout,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ring_group
                                                                  .call_handling_options,
                                                                "timeout",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ring_group.call_handling_options.timeout",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.call_timeout_options,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.value,
                                                                attrs: {
                                                                  label:
                                                                    item.label,
                                                                  value:
                                                                    item.value,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            [
                                              _c("span", {
                                                staticClass: "page-anchor",
                                                attrs: {
                                                  id: "repeat_customer_call",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-label" },
                                                [
                                                  _c("h5", [
                                                    _vm._v(
                                                      "Repeat Call Routing"
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("small", [
                                                    _vm._v(
                                                      "\n                                                        Choose how this ring group handles repeat customer calls.\n                                                    "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-radio-group",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.ring_group
                                                        .repeat_contact_route_to,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.ring_group,
                                                        "repeat_contact_route_to",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ring_group.repeat_contact_route_to",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      staticClass: "d-block",
                                                      attrs: {
                                                        label:
                                                          _vm
                                                            .RingGroupRepeatContactTo
                                                            .REPEAT_CONTACT_ROUTE_TO_RING_GROUP,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                        Route the call to ring group, do not respect contact ownership (default).\n                                                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      staticClass: "d-block",
                                                      attrs: {
                                                        label:
                                                          _vm
                                                            .RingGroupRepeatContactTo
                                                            .REPEAT_CONTACT_ROUTE_TO_OWNER,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                        Route the call to the owner of the contact first and then route to the ring group.\n                                                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      staticClass: "d-block",
                                                      attrs: {
                                                        label:
                                                          _vm
                                                            .RingGroupRepeatContactTo
                                                            .REPEAT_CONTACT_ROUTE_TO_OWNER_ONLY,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                        Route the call to the owner of the contact, and stop. For unassigned contacts, route to the ring group.\n                                                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      staticClass: "d-block",
                                                      attrs: {
                                                        label:
                                                          _vm
                                                            .RingGroupRepeatContactTo
                                                            .REPEAT_CONTACT_ROUTE_TO_OWNER_ONLY_STRICT,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                        Route the call to the owner of the contact, and stop. For unassigned contacts, do not route.\n                                                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      staticClass: "d-block",
                                                      attrs: {
                                                        label:
                                                          _vm
                                                            .RingGroupRepeatContactTo
                                                            .REPEAT_CONTACT_ROUTE_TO_PREVIOUS_USER,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                        Route the call to the agent who last spoke with this contact first and then route to the ring group.\n                                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop: "text_assign_mode",
                                              },
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "page-anchor",
                                                attrs: {
                                                  id: "text_assign_mode",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-label" },
                                                [
                                                  _c("h5", [
                                                    _vm._v(
                                                      "Text Message Handling (Beta)"
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("small", [
                                                    _vm._v(
                                                      "Choose how this ring group handles incoming text messages."
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-radio-group",
                                                {
                                                  staticClass: "mt-2",
                                                  model: {
                                                    value:
                                                      _vm.ring_group
                                                        .text_assign_mode,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.ring_group,
                                                        "text_assign_mode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ring_group.text_assign_mode",
                                                  },
                                                },
                                                [
                                                  _c("br"),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label:
                                                          _vm
                                                            .RingGroupTextAssignModes
                                                            .TEXT_ASSIGN_MODE_TO_CONTACT_OWNER,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                        " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "humanReadableTextAssignMode"
                                                            )(
                                                              _vm
                                                                .RingGroupTextAssignModes
                                                                .TEXT_ASSIGN_MODE_TO_CONTACT_OWNER
                                                            )
                                                          ) +
                                                          "\n                                                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("br"),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label:
                                                          _vm
                                                            .RingGroupTextAssignModes
                                                            .TEXT_ASSIGN_MODE_RANDOM_TO_AGENTS,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                        " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "humanReadableTextAssignMode"
                                                            )(
                                                              _vm
                                                                .RingGroupTextAssignModes
                                                                .TEXT_ASSIGN_MODE_RANDOM_TO_AGENTS
                                                            )
                                                          ) +
                                                          "\n                                                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("br"),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label:
                                                          _vm
                                                            .RingGroupTextAssignModes
                                                            .TEXT_ASSIGN_MODE_TO_LAST_CALLED_AGENT,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                        " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "humanReadableTextAssignMode"
                                                            )(
                                                              _vm
                                                                .RingGroupTextAssignModes
                                                                .TEXT_ASSIGN_MODE_TO_LAST_CALLED_AGENT
                                                            )
                                                          ) +
                                                          "\n                                                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("br"),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label:
                                                          _vm
                                                            .RingGroupTextAssignModes
                                                            .TEXT_ASSIGN_MODE_TO_LAST_CALLED_AGENT_REGARDLESS_OWNERSHIP,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                        " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "humanReadableTextAssignMode"
                                                            )(
                                                              _vm
                                                                .RingGroupTextAssignModes
                                                                .TEXT_ASSIGN_MODE_TO_LAST_CALLED_AGENT_REGARDLESS_OWNERSHIP
                                                            )
                                                          ) +
                                                          "\n                                                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("br"),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label:
                                                          _vm
                                                            .RingGroupTextAssignModes
                                                            .TEXT_ASSIGN_MODE_DO_NOTHING,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                        " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "humanReadableTextAssignMode"
                                                            )(
                                                              _vm
                                                                .RingGroupTextAssignModes
                                                                .TEXT_ASSIGN_MODE_DO_NOTHING
                                                            )
                                                          ) +
                                                          "\n                                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "missed-call" } },
                                            [
                                              _c("span", {
                                                staticClass: "page-anchor",
                                                attrs: { id: "missed-call" },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-label" },
                                                [
                                                  _c("h5", [
                                                    _vm._v(
                                                      "Missed Call Settings"
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("small", [
                                                    _vm._v(
                                                      "Ring groups can take a voicemail if no agents are available to take the call, and a call has been routed to this ring group."
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-12" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group",
                                                        },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              staticClass:
                                                                "no-border",
                                                              attrs: {
                                                                prop: "missed_call_handling_mode",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-radio-group",
                                                                {
                                                                  staticClass:
                                                                    "w-full mt-2",
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .ring_group
                                                                        .missed_calls_settings
                                                                        .missed_call_handling_mode,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .ring_group
                                                                            .missed_calls_settings,
                                                                          "missed_call_handling_mode",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "ring_group.missed_calls_settings.missed_call_handling_mode",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "row",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "col-12",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-radio",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    _vm
                                                                                      .MissedCallBehaviors
                                                                                      .MISSED_CALL_BEHAVIOR_NOTHING,
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                                Use the line's missed call settings (default)\n                                                                            "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "row",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "col-12",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-radio",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    _vm
                                                                                      .MissedCallBehaviors
                                                                                      .MISSED_CALL_BEHAVIOR_VOICEMAIL,
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                                Take a voicemail\n                                                                            "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.ring_group
                                                              .missed_calls_settings
                                                              .missed_call_handling_mode ===
                                                            _vm
                                                              .MissedCallBehaviors
                                                              .MISSED_CALL_BEHAVIOR_VOICEMAIL,
                                                          expression:
                                                            "ring_group.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_VOICEMAIL",
                                                        },
                                                      ],
                                                      staticClass: "col-12",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "b-b nav-active-greenish",
                                                            },
                                                            [
                                                              _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "nav nav-tabs",
                                                                },
                                                                [
                                                                  _c(
                                                                    "li",
                                                                    {
                                                                      staticClass:
                                                                        "nav-item",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "border border-right-0 rounded-top-left",
                                                                          class:
                                                                            _vm
                                                                              .voicemailNavClass
                                                                              .read,
                                                                          attrs:
                                                                            {
                                                                              href: "",
                                                                              "data-toggle":
                                                                                "tab",
                                                                              "data-target":
                                                                                "#voicemail-tts",
                                                                              "aria-expanded":
                                                                                "true",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fa fa-robot",
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            "\n                                                                            Text to Speech\n                                                                        "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm.hasPermissionTo(
                                                                    "upload file campaign"
                                                                  )
                                                                    ? _c(
                                                                        "li",
                                                                        {
                                                                          staticClass:
                                                                            "nav-item",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              staticClass:
                                                                                "border rounded-top-right",
                                                                              class:
                                                                                _vm
                                                                                  .voicemailNavClass
                                                                                  .play,
                                                                              attrs:
                                                                                {
                                                                                  href: "",
                                                                                  "data-toggle":
                                                                                    "tab",
                                                                                  "data-target":
                                                                                    "#voicemail-file",
                                                                                  "aria-expanded":
                                                                                    "false",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  staticClass:
                                                                                    "fa fa-play text-md",
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                "\n                                                                            Play Recording\n                                                                        "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm.ring_group
                                                                    .missed_calls_settings
                                                                    .voicemail_file
                                                                    ? _c(
                                                                        "li",
                                                                        {
                                                                          staticClass:
                                                                            "nav-item",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              staticClass:
                                                                                "nav-link",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.preventDefault()
                                                                                    return _vm.deleteFile(
                                                                                      "voicemail"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "tab-content p-a m-b-md border border-top-0",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  class:
                                                                    _vm
                                                                      .voicemailContainerClass
                                                                      .read,
                                                                  attrs: {
                                                                    id: "voicemail-tts",
                                                                    "aria-expanded":
                                                                      "true",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-form-item",
                                                                    {
                                                                      staticClass:
                                                                        "no-border mb-4",
                                                                      attrs: {
                                                                        label:
                                                                          "Voicemail Message",
                                                                        prop: "voicemail",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-popover",
                                                                        {
                                                                          ref: "popover_voicemail",
                                                                          attrs:
                                                                            {
                                                                              placement:
                                                                                "bottom-start",
                                                                              title:
                                                                                "Voicemail",
                                                                              width:
                                                                                "200",
                                                                              trigger:
                                                                                "hover",
                                                                              content:
                                                                                "We are sorry, but no agents\n                                                                                  are available to take this call.\n                                                                                  Please leave your message after the beep.",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "col-12",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "input-group",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "input",
                                                                                    {
                                                                                      directives:
                                                                                        [
                                                                                          {
                                                                                            name: "model",
                                                                                            rawName:
                                                                                              "v-model",
                                                                                            value:
                                                                                              _vm
                                                                                                .ring_group
                                                                                                .missed_calls_settings
                                                                                                .voicemail_tts,
                                                                                            expression:
                                                                                              "ring_group.missed_calls_settings.voicemail_tts",
                                                                                          },
                                                                                        ],
                                                                                      staticClass:
                                                                                        "form-control pt-2 pb-2",
                                                                                      attrs:
                                                                                        {
                                                                                          type: "text",
                                                                                        },
                                                                                      domProps:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .ring_group
                                                                                              .missed_calls_settings
                                                                                              .voicemail_tts,
                                                                                        },
                                                                                      on: {
                                                                                        input:
                                                                                          [
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              if (
                                                                                                $event
                                                                                                  .target
                                                                                                  .composing
                                                                                              )
                                                                                                return
                                                                                              _vm.$set(
                                                                                                _vm
                                                                                                  .ring_group
                                                                                                  .missed_calls_settings,
                                                                                                "voicemail_tts",
                                                                                                $event
                                                                                                  .target
                                                                                                  .value
                                                                                              )
                                                                                            },
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.preValidateForm(
                                                                                                "ring_group_form"
                                                                                              )
                                                                                            },
                                                                                          ],
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "variable-dialog"
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.hasPermissionTo(
                                                                "upload file campaign"
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      class:
                                                                        _vm
                                                                          .voicemailContainerClass
                                                                          .play,
                                                                      attrs: {
                                                                        id: "voicemail-file",
                                                                        "aria-expanded":
                                                                          "false",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm
                                                                        .ring_group
                                                                        .missed_calls_settings
                                                                        .voicemail_file ===
                                                                      null
                                                                        ? _c(
                                                                            "audio-recorder",
                                                                            {
                                                                              staticClass:
                                                                                "audio-recorder-wrapper",
                                                                              attrs:
                                                                                {
                                                                                  action:
                                                                                    "/api/v1/ring-group/" +
                                                                                    _vm
                                                                                      .ring_group
                                                                                      .id +
                                                                                    "/upload-file/voicemails",
                                                                                  upload_type:
                                                                                    "voicemail_file",
                                                                                },
                                                                              on: {
                                                                                applyUploadedAudio:
                                                                                  _vm.applyUploadedAudio,
                                                                              },
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "audio-uploader-wrapper",
                                                                        },
                                                                        [
                                                                          _vm
                                                                            .ring_group
                                                                            .missed_calls_settings
                                                                            .voicemail_file ===
                                                                          null
                                                                            ? _c(
                                                                                "el-upload",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      drag: "",
                                                                                      action:
                                                                                        "/api/v1/ring-group/" +
                                                                                        _vm
                                                                                          .ring_group
                                                                                          .id +
                                                                                        "/upload-file/voicemails",
                                                                                      headers:
                                                                                        _vm.headers,
                                                                                      "on-success":
                                                                                        _vm.onSuccessVoicemail,
                                                                                      "on-error":
                                                                                        _vm.onFailedVoicemail,
                                                                                      "on-progress":
                                                                                        _vm.progressUploadVoicemail,
                                                                                      "before-upload":
                                                                                        _vm.beforeUploadVoicemail,
                                                                                      "file-list":
                                                                                        _vm
                                                                                          .uploadFileList
                                                                                          .voicemail,
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "i",
                                                                                    {
                                                                                      staticClass:
                                                                                        "el-icon-upload",
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "el-upload__text",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                                                                Drop file here or "
                                                                                      ),
                                                                                      _c(
                                                                                        "em",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "click to\n                                                                                upload"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "el-upload__tip",
                                                                                      attrs:
                                                                                        {
                                                                                          slot: "tip",
                                                                                        },
                                                                                      slot: "tip",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                                                                MP3/WAV file (less than 8MB)\n                                                                            "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm
                                                                            .ring_group
                                                                            .missed_calls_settings
                                                                            .voicemail_file ===
                                                                          null
                                                                            ? _c(
                                                                                "el-progress",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      "text-inside": true,
                                                                                      "stroke-width": 18,
                                                                                      percentage:
                                                                                        _vm
                                                                                          .uploadPercentage
                                                                                          .voicemail,
                                                                                      status:
                                                                                        _vm
                                                                                          .uploadStatus
                                                                                          .voicemail,
                                                                                    },
                                                                                }
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm
                                                                            .ring_group
                                                                            .missed_calls_settings
                                                                            .voicemail_file !==
                                                                          null
                                                                            ? _c(
                                                                                "audio",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      controls:
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "source",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          src:
                                                                                            "/static/uploaded_file/" +
                                                                                            _vm
                                                                                              .ring_group
                                                                                              .missed_calls_settings
                                                                                              .voicemail_file,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    "\n                                                                            Your browser does not support the audio\n                                                                            element.\n                                                                        "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm
                                                                            .ring_group
                                                                            .missed_calls_settings
                                                                            .voicemail_file !==
                                                                            null &&
                                                                          _vm.hasPermissionTo(
                                                                            "delete file campaign"
                                                                          )
                                                                            ? _c(
                                                                                "button",
                                                                                {
                                                                                  staticClass:
                                                                                    "btn btn-sm btn-danger pull-right",
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        $event.preventDefault()
                                                                                        return _vm.deleteFile(
                                                                                          "voicemails"
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "i",
                                                                                    {
                                                                                      directives:
                                                                                        [
                                                                                          {
                                                                                            name: "show",
                                                                                            rawName:
                                                                                              "v-show",
                                                                                            value:
                                                                                              _vm.loading_voicemail,
                                                                                            expression:
                                                                                              "loading_voicemail",
                                                                                          },
                                                                                        ],
                                                                                      staticClass:
                                                                                        "material-icons loader pull-left",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        ""
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "i",
                                                                                    {
                                                                                      directives:
                                                                                        [
                                                                                          {
                                                                                            name: "show",
                                                                                            rawName:
                                                                                              "v-show",
                                                                                            value:
                                                                                              !_vm.loading_voicemail,
                                                                                            expression:
                                                                                              "!loading_voicemail",
                                                                                          },
                                                                                        ],
                                                                                      staticClass:
                                                                                        "material-icons pull-left",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        ""
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Remove file"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop: "enable_sms_notifications",
                                              },
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "page-anchor",
                                                attrs: {
                                                  id: "enable_sms_notifications",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-label mb-3",
                                                },
                                                [
                                                  _c(
                                                    "h5",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _vm._v(
                                                        "Inbound Text Notifications"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("small", [
                                                    _vm._v(
                                                      "\n                                                        By enabling this option, users on this ring group will receive notifications for inbound texts coming to this ring group if they have access\n                                                        to the contact.\n                                                    "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "switch-label" },
                                                [
                                                  _c("el-switch", {
                                                    attrs: {
                                                      "active-color": "#00a344",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.preValidateForm(
                                                          "ring_group_form"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ring_group
                                                          .enable_sms_notifications,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ring_group,
                                                          "enable_sms_notifications",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ring_group.enable_sms_notifications",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("label", [
                                                    _vm._v(
                                                      "Enable Inbound Text Notifications?"
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "mb-2 no-border",
                                              attrs: {
                                                prop: "enable_voicemail_notifications",
                                              },
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "page-anchor",
                                                attrs: {
                                                  id: "enable_voicemail_notifications",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-label mb-3",
                                                },
                                                [
                                                  _c(
                                                    "h5",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _vm._v(
                                                        "Voicemail Notifications"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("small", [
                                                    _vm._v(
                                                      "\n                                                        Send voicemail notifications to all users of this ring group with access to the contact.\n                                                    "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "switch-label" },
                                                [
                                                  _c("el-switch", {
                                                    attrs: {
                                                      "active-color": "#00a344",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.preValidateForm(
                                                          "ring_group_form"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ring_group
                                                          .enable_voicemail_notifications,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ring_group,
                                                          "enable_voicemail_notifications",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ring_group.enable_voicemail_notifications",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("label", [
                                                    _vm._v(
                                                      "Enable Voicemail Notifications?"
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-3 d-none d-md-block ml-auto",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70",
                                            },
                                            [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass:
                                                    "list-group-item",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    On This Page\n                                                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "list-group-item list-group-item-action",
                                                  attrs: { href: "#name" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.shine("name")
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    Name\n                                                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "list-group-item list-group-item-action",
                                                  attrs: {
                                                    href: "#description",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.shine(
                                                        "description"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    Description\n                                                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "list-group-item list-group-item-action",
                                                  attrs: { href: "#users" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.shine("users")
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    Ring group builder\n                                                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "list-group-item list-group-item-action",
                                                  attrs: {
                                                    href: "#layer_settings",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.shine(
                                                        "layer_settings"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    Routing Settings\n                                                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "list-group-item list-group-item-action",
                                                  attrs: {
                                                    href: "#repeat_customer_call",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.shine(
                                                        "repeat_customer_call"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    Repeat Call routing\n                                                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "list-group-item list-group-item-action",
                                                  attrs: {
                                                    href: "#text_assign_mode",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.shine(
                                                        "text_assign_mode"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    Text Message Handling\n                                                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "list-group-item list-group-item-action",
                                                  attrs: {
                                                    href: "#missed-call",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.shine(
                                                        "missed-call"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    Missed Call Settings\n                                                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "list-group-item list-group-item-action",
                                                  attrs: {
                                                    href: "#enable_sms_notifications",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.shine(
                                                        "enable_sms_notifications"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    Inbound Text Notifications\n                                                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "list-group-item list-group-item-action",
                                                  attrs: {
                                                    href: "#enable_voicemail_notifications",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.shine(
                                                        "enable_voicemail_notifications"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    Voicemail Notifications\n                                                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tab-pane",
                                  {
                                    staticClass: "p-2",
                                    attrs: {
                                      name: "queue-settings",
                                      label: "Queue",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-8" },
                                        [
                                          _c("el-form-item", [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: { id: "smart_queue" },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-label mb-3",
                                              },
                                              [
                                                _c(
                                                  "h5",
                                                  { staticClass: "mb-0" },
                                                  [_vm._v("Smart Queue")]
                                                ),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "\n                                                        If you receive more calls than your agents can answer, calls will be placed in a waiting queue.\n                                                        As soon as an agent becomes available, call will be dispatched (best for contact center).\n                                                    "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "switch-label" },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-color": "#00a344",
                                                    disabled:
                                                      !_vm.current_company
                                                        .conferencing_enabled,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.onChangeShouldQueue()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ring_group
                                                        .should_queue,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.ring_group,
                                                        "should_queue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ring_group.should_queue",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("label", [
                                                  _vm._v("Smart Queue"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ring_group
                                                      .should_queue &&
                                                    _vm.current_company &&
                                                    _vm.current_company
                                                      .conferencing_enabled,
                                                  expression:
                                                    "ring_group.should_queue && current_company && current_company.conferencing_enabled",
                                                },
                                              ],
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "page-anchor",
                                                attrs: { id: "fishing_mode" },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-label mb-3",
                                                },
                                                [
                                                  _c(
                                                    "h5",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _vm._v(
                                                        "Fishing Mode (Beta)"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("small", [
                                                    _vm._v(
                                                      "\n                                                        When enabled, fishing mode notifications will simultaneously notify all users within a ring group at the same time. Fishing Mode will still\n                                                        respect user agent status, operating hours, area code and state restrictions.\n                                                    "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "switch-label" },
                                                [
                                                  _c("el-switch", {
                                                    attrs: {
                                                      "active-color": "#00a344",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.preValidateForm(
                                                          "ring_group_form"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ring_group
                                                          .fishing_mode,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ring_group,
                                                          "fishing_mode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ring_group.fishing_mode",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("label", [
                                                    _vm._v(
                                                      "Enable Fishing Mode?"
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ring_group
                                                      .should_queue &&
                                                    _vm.current_company &&
                                                    _vm.current_company
                                                      .conferencing_enabled &&
                                                    _vm.ring_group.fishing_mode,
                                                  expression:
                                                    "ring_group.should_queue && current_company && current_company.conferencing_enabled && ring_group.fishing_mode",
                                                },
                                              ],
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "page-anchor",
                                                attrs: {
                                                  id: "experimental_fishing_mode_repeat_call_routing",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-label mb-3",
                                                },
                                                [
                                                  _c(
                                                    "h5",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _vm._v(
                                                        "Contact Owner Priority Routing (Beta)"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("small", [
                                                    _vm._v(
                                                      "\n                                                      When enabled, this flag prioritizes notifying the owner of the contact first. If the owner is in an active PD session, they will be the only one notified, and the call will be added to their session. If not, the normal routing applies, notifying all users in the ring group.\n                                                    "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "switch-label" },
                                                [
                                                  _c("el-switch", {
                                                    attrs: {
                                                      "active-color": "#00a344",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.preValidateForm(
                                                          "ring_group_form"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ring_group
                                                          .experimental_fishing_mode_repeat_call_routing,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ring_group,
                                                          "experimental_fishing_mode_repeat_call_routing",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ring_group.experimental_fishing_mode_repeat_call_routing",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("label", [
                                                    _vm._v(
                                                      "Enable Contact Owner Priority Routing"
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ring_group.should_queue,
                                                  expression:
                                                    "ring_group.should_queue",
                                                },
                                              ],
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "page-anchor",
                                                attrs: {
                                                  id: "queue_should_say_caller_position",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-label mb-3",
                                                },
                                                [
                                                  _c(
                                                    "h5",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _vm._v(
                                                        "Say Caller's Position In Queue"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("small", [
                                                    _vm._v(
                                                      "\n                                                        Notify the caller of their position in queue.\n                                                    "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "switch-label mb-4",
                                                },
                                                [
                                                  _c("el-switch", {
                                                    attrs: {
                                                      "active-color": "#00a344",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.preValidateForm(
                                                          "ring_group_form"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ring_group
                                                          .queue_should_say_caller_position,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ring_group,
                                                          "queue_should_say_caller_position",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ring_group.queue_should_say_caller_position",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("label", [
                                                    _vm._v(
                                                      "\n                                                        Should Say Caller's Position In Queue?\n                                                    "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ring_group.should_queue,
                                                  expression:
                                                    "ring_group.should_queue",
                                                },
                                              ],
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "page-anchor",
                                                attrs: {
                                                  id: "show_custom_wait_music",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-label mb-3",
                                                },
                                                [
                                                  _c(
                                                    "h5",
                                                    { staticClass: "mb-0" },
                                                    [_vm._v("Wait Music")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("small", [
                                                    _vm._v(
                                                      "\n                                                        Here you can customize the wait music for this ring group. Leave blank to use our default wait music.\n                                                    "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "switch-label mb-4",
                                                },
                                                [
                                                  _c("el-switch", {
                                                    attrs: {
                                                      "active-color": "#00a344",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.show_custom_wait_music,
                                                      callback: function ($$v) {
                                                        _vm.show_custom_wait_music =
                                                          $$v
                                                      },
                                                      expression:
                                                        "show_custom_wait_music",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "el-form-item__label ml-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                        Customize wait music\n                                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("div", [
                                                _vm.show_custom_wait_music &&
                                                _vm.hasPermissionTo([
                                                  "update ring group",
                                                  "create ring group",
                                                  "view ring group",
                                                ])
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _vm.ring_group
                                                          .queue_wait_music_file ===
                                                        null
                                                          ? _c(
                                                              "audio-recorder",
                                                              {
                                                                staticClass:
                                                                  "audio-recorder-wrapper",
                                                                attrs: {
                                                                  action:
                                                                    "/api/v1/ring-group/" +
                                                                    _vm
                                                                      .ring_group
                                                                      .id +
                                                                    "/upload-file/queue_wait_musics",
                                                                  upload_type:
                                                                    "queue_wait_music_file",
                                                                },
                                                                on: {
                                                                  applyUploadedAudio:
                                                                    _vm.applyUploadedAudio,
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "audio-uploader-wrapper",
                                                          },
                                                          [
                                                            _vm.ring_group
                                                              .queue_wait_music_file ===
                                                            null
                                                              ? _c(
                                                                  "el-upload",
                                                                  {
                                                                    attrs: {
                                                                      action:
                                                                        "/api/v1/ring-group/" +
                                                                        _vm
                                                                          .ring_group
                                                                          .id +
                                                                        "/upload-file/queue_wait_musics",
                                                                      headers:
                                                                        _vm.headers,
                                                                      "on-success":
                                                                        _vm.onSuccessWaitMusic,
                                                                      "on-error":
                                                                        _vm.onFailedWaitMusic,
                                                                      "on-progress":
                                                                        _vm.progressUploadWaitMusic,
                                                                      "before-upload":
                                                                        _vm.beforeUploadWaitMusic,
                                                                      "file-list":
                                                                        _vm
                                                                          .uploadFileList
                                                                          .queueWaitMusic,
                                                                      drag: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-upload",
                                                                    }),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "el-upload__text",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                                    Drop file here or "
                                                                        ),
                                                                        _c(
                                                                          "em",
                                                                          [
                                                                            _vm._v(
                                                                              "click to upload"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "el-upload__tip",
                                                                        attrs: {
                                                                          slot: "tip",
                                                                        },
                                                                        slot: "tip",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                                    MP3/WAV file (less than 8MB)\n                                                                "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm.ring_group
                                                              .queue_wait_music_file ===
                                                            null
                                                              ? _c(
                                                                  "el-progress",
                                                                  {
                                                                    attrs: {
                                                                      "text-inside": true,
                                                                      "stroke-width": 18,
                                                                      percentage:
                                                                        _vm
                                                                          .uploadPercentage
                                                                          .queueWaitMusic,
                                                                      status:
                                                                        _vm
                                                                          .uploadStatus
                                                                          .queueWaitMusic,
                                                                    },
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm.ring_group
                                                              .queue_wait_music_file !==
                                                            null
                                                              ? _c(
                                                                  "audio",
                                                                  {
                                                                    attrs: {
                                                                      controls:
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "source",
                                                                      {
                                                                        attrs: {
                                                                          src:
                                                                            "/static/uploaded_file/" +
                                                                            _vm
                                                                              .ring_group
                                                                              .queue_wait_music_file,
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(
                                                                      "\n                                                                Your browser does not support the audio element.\n                                                            "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm.ring_group
                                                              .queue_wait_music_file !==
                                                              null &&
                                                            _vm.hasPermissionTo(
                                                              "delete ring group"
                                                            )
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-sm btn-danger pull-right",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.deleteFile(
                                                                            "queue_wait_musics"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "i",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "show",
                                                                              rawName:
                                                                                "v-show",
                                                                              value:
                                                                                _vm.loading_queue_wait_music,
                                                                              expression:
                                                                                "loading_queue_wait_music",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "material-icons loader pull-left",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          ""
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "i",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "show",
                                                                              rawName:
                                                                                "v-show",
                                                                              value:
                                                                                !_vm.loading_queue_wait_music,
                                                                              expression:
                                                                                "!loading_queue_wait_music",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "material-icons pull-left",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          ""
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Remove file"
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ring_group.should_queue,
                                                  expression:
                                                    "ring_group.should_queue",
                                                },
                                              ],
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "page-anchor",
                                                attrs: {
                                                  id: "queue_repeat_prompts_delay",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-label mb-3",
                                                },
                                                [
                                                  _c(
                                                    "h5",
                                                    { staticClass: "mb-0" },
                                                    [_vm._v("Repeat Prompts")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("small", [
                                                    _vm._v(
                                                      "\n                                                        Configure how frequently the system offers those prompts. Best is to use 30 seconds.\n                                                    "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "Select",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ring_group
                                                        .queue_repeat_prompts_delay,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.ring_group,
                                                        "queue_repeat_prompts_delay",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ring_group.queue_repeat_prompts_delay",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.queue_repeat_prompts_delay_options,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.value,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ring_group.should_queue,
                                                  expression:
                                                    "ring_group.should_queue",
                                                },
                                              ],
                                              staticClass: "no-border",
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "page-anchor",
                                                attrs: { id: "max_wait_time" },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-label mb-3",
                                                },
                                                [
                                                  _c(
                                                    "h5",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _vm._v(
                                                        "Max Queue Hold Time"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("small", [
                                                    _vm._v(
                                                      "\n                                                        Max queue hold time is the maximum amount of time a call can stay on wait in the queued state.\n                                                        After this period the call will be terminated and it will be considered as missed.\n                                                        Please check Call Behavior > Missed Calls section to decide on what happens to call before termination.\n                                                    "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass: "mb-4",
                                                  attrs: {
                                                    placeholder: "Select",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ring_group
                                                        .max_wait_time,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.ring_group,
                                                        "max_wait_time",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ring_group.max_wait_time",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.max_wait_time_options,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.value,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "switch-label" },
                                                [
                                                  _c("el-switch", {
                                                    attrs: {
                                                      "active-color": "#00a344",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ring_group
                                                          .queue_send_voicemail_when_no_agents,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ring_group,
                                                          "queue_send_voicemail_when_no_agents",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ring_group.queue_send_voicemail_when_no_agents",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "el-form-item__label ml-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                        Send to voicemail when no agents are available\n                                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-3 d-none d-md-block ml-auto",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70",
                                            },
                                            [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass:
                                                    "list-group-item",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    On This Page\n                                                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "list-group-item list-group-item-action",
                                                  attrs: {
                                                    href: "#smart_queue",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.shine(
                                                        "smart_queue"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    Smart Queue\n                                                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.ring_group.should_queue &&
                                              _vm.current_company &&
                                              [
                                                7, 47, 460, 461, 574, 585, 662,
                                                708, 775, 945, 1070, 1134, 1339,
                                                1461,
                                              ].includes(
                                                _vm.current_company.id
                                              ) &&
                                              _vm.current_company
                                                .conferencing_enabled
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "list-group-item list-group-item-action",
                                                      attrs: {
                                                        href: "#fishing_mode",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.shine(
                                                            "fishing_mode"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    Fishing Mode (Beta)\n                                                "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.ring_group.should_queue
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "list-group-item list-group-item-action",
                                                      attrs: {
                                                        href: "#queue_should_say_caller_position",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.shine(
                                                            "queue_should_say_caller_position"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    Say Caller's Position In Queue\n                                                "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.ring_group.should_queue
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "list-group-item list-group-item-action",
                                                      attrs: {
                                                        href: "#queue_should_ask_for_callback",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.shine(
                                                            "queue_should_ask_for_callback"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    Offer Callback Option\n                                                "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.ring_group.should_queue
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "list-group-item list-group-item-action",
                                                      attrs: {
                                                        href: "#queue_should_handle_by_text",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.shine(
                                                            "queue_should_handle_by_text"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    Offer Handle By Text Option\n                                                "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.ring_group.should_queue
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "list-group-item list-group-item-action",
                                                      attrs: {
                                                        href: "#queue_should_ask_for_voicemail",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.shine(
                                                            "queue_should_ask_for_voicemail"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    Offer Voicemail Option\n                                                "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.ring_group.should_queue
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "list-group-item list-group-item-action",
                                                      attrs: {
                                                        href: "#show_custom_wait_music",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.shine(
                                                            "show_custom_wait_music"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    Wait Music\n                                                "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.ring_group.should_queue
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "list-group-item list-group-item-action",
                                                      attrs: {
                                                        href: "#queue_repeat_prompts_delay",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.shine(
                                                            "queue_repeat_prompts_delay"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    Repeat Prompts\n                                                "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.ring_group.should_queue
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "list-group-item list-group-item-action",
                                                      attrs: {
                                                        href: "#max_wait_time",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.shine(
                                                            "max_wait_time"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    Max Queue Hold Time\n                                                "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tab-pane",
                                  {
                                    staticClass: "p-2",
                                    attrs: {
                                      name: "advanced-queue-settings",
                                      label: "Advanced Queue Settings",
                                      disabled: !_vm.ring_group.should_queue,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-8" },
                                        [
                                          _c("el-form-item", [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: {
                                                id: "queue_should_ask_for_callback",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-label mb-3",
                                              },
                                              [
                                                _c(
                                                  "h5",
                                                  { staticClass: "mb-0" },
                                                  [
                                                    _vm._v(
                                                      "Offer Callback Option"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "\n                                                            Offer the caller a choice to keep their position in the queue\n                                                            by hanging up and receiving a call back when it's their turn. Caller will be asked to press ONE (1) to confirm.\n                                                        "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "switch-label mb-4",
                                              },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-color": "#00a344",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.preValidateForm(
                                                        "ring_group_form"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ring_group
                                                        .queue_should_ask_for_callback,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.ring_group,
                                                        "queue_should_ask_for_callback",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ring_group.queue_should_ask_for_callback",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("label", [
                                                  _vm._v(
                                                    "\n                                                            Offer Callback Option\n                                                        "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.ring_group
                                              .queue_should_ask_for_callback
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "b-b nav-active-greenish",
                                                  },
                                                  [
                                                    _c(
                                                      "ul",
                                                      {
                                                        staticClass:
                                                          "nav nav-tabs",
                                                      },
                                                      [
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "nav-item",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "border border-right-0 rounded-top-left",
                                                                class:
                                                                  _vm
                                                                    .queueCallbackNavClass
                                                                    .read,
                                                                attrs: {
                                                                  href: "",
                                                                  "data-toggle":
                                                                    "tab",
                                                                  "data-target":
                                                                    "#ask-for-callback-tts",
                                                                  "aria-expanded":
                                                                    "true",
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-robot",
                                                                }),
                                                                _vm._v(
                                                                  "\n                                                                    Text to Speech\n                                                                "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.hasPermissionTo([
                                                          "update ring group",
                                                          "create ring group",
                                                          "view ring group",
                                                        ])
                                                          ? _c(
                                                              "li",
                                                              {
                                                                staticClass:
                                                                  "nav-item",
                                                              },
                                                              [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    staticClass:
                                                                      "border rounded-top-right",
                                                                    class:
                                                                      _vm
                                                                        .queueCallbackNavClass
                                                                        .play,
                                                                    attrs: {
                                                                      href: "",
                                                                      "data-toggle":
                                                                        "tab",
                                                                      "data-target":
                                                                        "#ask-for-callback-file",
                                                                      "aria-expanded":
                                                                        "false",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fa fa-play text-md",
                                                                    }),
                                                                    _vm._v(
                                                                      "\n                                                                    Play Recording\n                                                                "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.ring_group
                                              .queue_should_ask_for_callback
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tab-content p-a m-b-md border border-top-0",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        class:
                                                          _vm
                                                            .queueCallbackContainerClass
                                                            .read,
                                                        attrs: {
                                                          id: "ask-for-callback-tts",
                                                          "aria-expanded":
                                                            "true",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticClass: "mb-4",
                                                            attrs: {
                                                              label: "Prompt",
                                                              prop: "queue_should_ask_for_callback",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-12",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "input-group",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    _vm
                                                                                      .ring_group
                                                                                      .queue_ask_for_callback_tts,
                                                                                  expression:
                                                                                    "ring_group.queue_ask_for_callback_tts",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "form-control pt-2 pb-2",
                                                                            attrs:
                                                                              {
                                                                                type: "text",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .ring_group
                                                                                    .queue_ask_for_callback_tts,
                                                                              },
                                                                            on: {
                                                                              input:
                                                                                [
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    if (
                                                                                      $event
                                                                                        .target
                                                                                        .composing
                                                                                    )
                                                                                      return
                                                                                    _vm.$set(
                                                                                      _vm.ring_group,
                                                                                      "queue_ask_for_callback_tts",
                                                                                      $event
                                                                                        .target
                                                                                        .value
                                                                                    )
                                                                                  },
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.preValidateForm(
                                                                                      "ring_group_form"
                                                                                    )
                                                                                  },
                                                                                ],
                                                                            },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "variable-dialog"
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "template-list-dialog",
                                                                      {
                                                                        attrs: {
                                                                          text_button: true,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.hasPermissionTo([
                                                      "update ring group",
                                                      "create ring group",
                                                      "view ring group",
                                                    ])
                                                      ? _c(
                                                          "div",
                                                          {
                                                            class:
                                                              _vm
                                                                .queueCallbackContainerClass
                                                                .play,
                                                            attrs: {
                                                              id: "ask-for-callback-file",
                                                              "aria-expanded":
                                                                "false",
                                                            },
                                                          },
                                                          [
                                                            _vm.ring_group
                                                              .queue_ask_for_callback_file ===
                                                            null
                                                              ? _c(
                                                                  "audio-recorder",
                                                                  {
                                                                    staticClass:
                                                                      "audio-recorder-wrapper",
                                                                    attrs: {
                                                                      action:
                                                                        "/api/v1/ring-group/" +
                                                                        _vm
                                                                          .ring_group
                                                                          .id +
                                                                        "/upload-file/queue_callbacks",
                                                                      upload_type:
                                                                        "queue_ask_for_callback_file",
                                                                    },
                                                                    on: {
                                                                      applyUploadedAudio:
                                                                        _vm.applyUploadedAudio,
                                                                    },
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "audio-uploader-wrapper",
                                                              },
                                                              [
                                                                _vm.ring_group
                                                                  .queue_ask_for_callback_file ===
                                                                null
                                                                  ? _c(
                                                                      "el-upload",
                                                                      {
                                                                        attrs: {
                                                                          action:
                                                                            "/api/v1/ring-group/" +
                                                                            _vm
                                                                              .ring_group
                                                                              .id +
                                                                            "/upload-file/queue_callbacks",
                                                                          headers:
                                                                            _vm.headers,
                                                                          "on-success":
                                                                            _vm.onSuccessCallback,
                                                                          "on-error":
                                                                            _vm.onFailedCallback,
                                                                          "on-progress":
                                                                            _vm.progressUploadCallback,
                                                                          "before-upload":
                                                                            _vm.beforeUploadCallback,
                                                                          "file-list":
                                                                            _vm
                                                                              .uploadFileList
                                                                              .queueAskForCallback,
                                                                          drag: "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "el-icon-upload",
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "el-upload__text",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                                        Drop file here or "
                                                                            ),
                                                                            _c(
                                                                              "em",
                                                                              [
                                                                                _vm._v(
                                                                                  "click to upload"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "el-upload__tip",
                                                                            attrs:
                                                                              {
                                                                                slot: "tip",
                                                                              },
                                                                            slot: "tip",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                                        MP3/WAV file (less than 8MB)\n                                                                    "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _vm.ring_group
                                                                  .queue_ask_for_callback_file ===
                                                                null
                                                                  ? _c(
                                                                      "el-progress",
                                                                      {
                                                                        attrs: {
                                                                          "text-inside": true,
                                                                          "stroke-width": 18,
                                                                          percentage:
                                                                            _vm
                                                                              .uploadPercentage
                                                                              .queueAskForCallback,
                                                                          status:
                                                                            _vm
                                                                              .uploadStatus
                                                                              .queueAskForCallback,
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _vm.ring_group
                                                                  .queue_ask_for_callback_file !==
                                                                null
                                                                  ? _c(
                                                                      "audio",
                                                                      {
                                                                        attrs: {
                                                                          controls:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "source",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                src:
                                                                                  "/static/uploaded_file/" +
                                                                                  _vm
                                                                                    .ring_group
                                                                                    .queue_ask_for_callback_file,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          "\n                                                                    Your browser does not support the audio element.\n                                                                "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _vm.ring_group
                                                                  .queue_ask_for_callback_file !==
                                                                  null &&
                                                                _vm.hasPermissionTo(
                                                                  "delete ring group"
                                                                )
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-sm btn-danger pull-right",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              return _vm.deleteFile(
                                                                                "queue_callbacks"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value:
                                                                                    _vm.loading_queue_ask_for_callback,
                                                                                  expression:
                                                                                    "loading_queue_ask_for_callback",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "material-icons loader pull-left",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              ""
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value:
                                                                                    !_vm.loading_queue_ask_for_callback,
                                                                                  expression:
                                                                                    "!loading_queue_ask_for_callback",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "material-icons pull-left",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              ""
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "Remove file"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]),
                                          _vm._v(" "),
                                          _c("el-form-item", [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: {
                                                id: "queue_should_handle_by_text",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-label mb-3",
                                              },
                                              [
                                                _c(
                                                  "h5",
                                                  { staticClass: "mb-0" },
                                                  [
                                                    _vm._v(
                                                      "Offer Handle By Text Option"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "\n                                                            Ask the caller to hangup and handle their issue via text instead.\n                                                            Useful for small requests, like appointment modifications or status checks.\n                                                            Caller will be asked to press TWO (2) to confirm.\n                                                        "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "switch-label mb-4",
                                              },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-color": "#00a344",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.preValidateForm(
                                                        "ring_group_form"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ring_group
                                                        .queue_should_handle_by_text,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.ring_group,
                                                        "queue_should_handle_by_text",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ring_group.queue_should_handle_by_text",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("label", [
                                                  _vm._v(
                                                    "\n                                                            Offer Handle By Text Option\n                                                        "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("div", [
                                              _vm.ring_group
                                                .queue_should_handle_by_text
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "b-b nav-active-greenish",
                                                    },
                                                    [
                                                      _c(
                                                        "ul",
                                                        {
                                                          staticClass:
                                                            "nav nav-tabs",
                                                        },
                                                        [
                                                          _c(
                                                            "li",
                                                            {
                                                              staticClass:
                                                                "nav-item",
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "border border-right-0 rounded-top-left",
                                                                  class:
                                                                    _vm
                                                                      .queueHandleByTextNavClass
                                                                      .read,
                                                                  attrs: {
                                                                    href: "",
                                                                    "data-toggle":
                                                                      "tab",
                                                                    "data-target":
                                                                      "#handle-by-text-tts",
                                                                    "aria-expanded":
                                                                      "true",
                                                                  },
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fa fa-robot",
                                                                  }),
                                                                  _vm._v(
                                                                    "\n                                                                        Text to Speech\n                                                                    "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.hasPermissionTo([
                                                            "update ring group",
                                                            "create ring group",
                                                            "view ring group",
                                                          ])
                                                            ? _c(
                                                                "li",
                                                                {
                                                                  staticClass:
                                                                    "nav-item",
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      staticClass:
                                                                        "border rounded-top-right",
                                                                      class:
                                                                        _vm
                                                                          .queueHandleByTextNavClass
                                                                          .play,
                                                                      attrs: {
                                                                        href: "",
                                                                        "data-toggle":
                                                                          "tab",
                                                                        "data-target":
                                                                          "#handle-by-text-file",
                                                                        "aria-expanded":
                                                                          "false",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fa fa-play text-md",
                                                                      }),
                                                                      _vm._v(
                                                                        "\n                                                                        Play Recording\n                                                                    "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.ring_group
                                                .queue_should_handle_by_text
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "tab-content p-a m-b-md border border-top-0",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          class:
                                                            _vm
                                                              .queueHandleByTextContainerClass
                                                              .read,
                                                          attrs: {
                                                            id: "handle-by-text-tts",
                                                            "aria-expanded":
                                                              "true",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              staticClass:
                                                                "mb-4",
                                                              attrs: {
                                                                label: "Prompt",
                                                                prop: "queue_should_handle_by_text",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-12",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "input-group",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "model",
                                                                                    rawName:
                                                                                      "v-model",
                                                                                    value:
                                                                                      _vm
                                                                                        .ring_group
                                                                                        .queue_handle_by_text_tts,
                                                                                    expression:
                                                                                      "ring_group.queue_handle_by_text_tts",
                                                                                  },
                                                                                ],
                                                                              staticClass:
                                                                                "form-control pt-2 pb-2",
                                                                              attrs:
                                                                                {
                                                                                  type: "text",
                                                                                },
                                                                              domProps:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .ring_group
                                                                                      .queue_handle_by_text_tts,
                                                                                },
                                                                              on: {
                                                                                input:
                                                                                  [
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      if (
                                                                                        $event
                                                                                          .target
                                                                                          .composing
                                                                                      )
                                                                                        return
                                                                                      _vm.$set(
                                                                                        _vm.ring_group,
                                                                                        "queue_handle_by_text_tts",
                                                                                        $event
                                                                                          .target
                                                                                          .value
                                                                                      )
                                                                                    },
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.preValidateForm(
                                                                                        "ring_group_form"
                                                                                      )
                                                                                    },
                                                                                  ],
                                                                              },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "variable-dialog"
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "template-list-dialog",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              text_button: true,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.hasPermissionTo([
                                                        "update ring group",
                                                        "create ring group",
                                                        "view ring group",
                                                      ])
                                                        ? _c(
                                                            "div",
                                                            {
                                                              class:
                                                                _vm
                                                                  .queueHandleByTextContainerClass
                                                                  .play,
                                                              attrs: {
                                                                id: "handle-by-text-file",
                                                                "aria-expanded":
                                                                  "false",
                                                              },
                                                            },
                                                            [
                                                              _vm.ring_group
                                                                .queue_handle_by_text_file ===
                                                              null
                                                                ? _c(
                                                                    "audio-recorder",
                                                                    {
                                                                      staticClass:
                                                                        "audio-recorder-wrapper",
                                                                      attrs: {
                                                                        action:
                                                                          "/api/v1/ring-group/" +
                                                                          _vm
                                                                            .ring_group
                                                                            .id +
                                                                          "/upload-file/queue_handle_by_texts",
                                                                        upload_type:
                                                                          "queue_handle_by_text_file",
                                                                      },
                                                                      on: {
                                                                        applyUploadedAudio:
                                                                          _vm.applyUploadedAudio,
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "audio-uploader-wrapper",
                                                                },
                                                                [
                                                                  _vm.ring_group
                                                                    .queue_handle_by_text_file ===
                                                                  null
                                                                    ? _c(
                                                                        "el-upload",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              action:
                                                                                "/api/v1/ring-group/" +
                                                                                _vm
                                                                                  .ring_group
                                                                                  .id +
                                                                                "/upload-file/queue_handle_by_texts",
                                                                              headers:
                                                                                _vm.headers,
                                                                              "on-success":
                                                                                _vm.onSuccessHandleByText,
                                                                              "on-error":
                                                                                _vm.onFailedHandleByText,
                                                                              "on-progress":
                                                                                _vm.progressUploadHandleByText,
                                                                              "before-upload":
                                                                                _vm.beforeUploadHandleByText,
                                                                              "file-list":
                                                                                _vm
                                                                                  .uploadFileList
                                                                                  .queueHandleByText,
                                                                              drag: "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "el-icon-upload",
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "el-upload__text",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                            Drop file here or "
                                                                              ),
                                                                              _c(
                                                                                "em",
                                                                                [
                                                                                  _vm._v(
                                                                                    "click to upload"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "el-upload__tip",
                                                                              attrs:
                                                                                {
                                                                                  slot: "tip",
                                                                                },
                                                                              slot: "tip",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                            MP3/WAV file (less than 8MB)\n                                                                        "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm.ring_group
                                                                    .queue_handle_by_text_file ===
                                                                  null
                                                                    ? _c(
                                                                        "el-progress",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "text-inside": true,
                                                                              "stroke-width": 18,
                                                                              percentage:
                                                                                _vm
                                                                                  .uploadPercentage
                                                                                  .queueHandleByText,
                                                                              status:
                                                                                _vm
                                                                                  .uploadStatus
                                                                                  .queueHandleByText,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm.ring_group
                                                                    .queue_handle_by_text_file !==
                                                                  null
                                                                    ? _c(
                                                                        "audio",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              controls:
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "source",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  src:
                                                                                    "/static/uploaded_file/" +
                                                                                    _vm
                                                                                      .ring_group
                                                                                      .queue_handle_by_text_file,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            "\n                                                                        Your browser does not support the audio element.\n                                                                    "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm.ring_group
                                                                    .queue_handle_by_text_file !==
                                                                    null &&
                                                                  _vm.hasPermissionTo(
                                                                    "delete ring group"
                                                                  )
                                                                    ? _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "btn btn-sm btn-danger pull-right",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return _vm.deleteFile(
                                                                                  "queue_handle_by_texts"
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "show",
                                                                                    rawName:
                                                                                      "v-show",
                                                                                    value:
                                                                                      _vm.loading_queue_handle_by_text,
                                                                                    expression:
                                                                                      "loading_queue_handle_by_text",
                                                                                  },
                                                                                ],
                                                                              staticClass:
                                                                                "material-icons loader pull-left",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                ""
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "show",
                                                                                    rawName:
                                                                                      "v-show",
                                                                                    value:
                                                                                      !_vm.loading_queue_handle_by_text,
                                                                                    expression:
                                                                                      "!loading_queue_handle_by_text",
                                                                                  },
                                                                                ],
                                                                              staticClass:
                                                                                "material-icons pull-left",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                ""
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Remove file"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label:
                                                              "Text message to be sent",
                                                            prop: "queue_handle_by_text_response_tts",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-12",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "input-group",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  _vm
                                                                                    .ring_group
                                                                                    .queue_handle_by_text_response_tts,
                                                                                expression:
                                                                                  "ring_group.queue_handle_by_text_response_tts",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "form-control pt-2 pb-2",
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .ring_group
                                                                                  .queue_handle_by_text_response_tts,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              [
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    $event
                                                                                      .target
                                                                                      .composing
                                                                                  )
                                                                                    return
                                                                                  _vm.$set(
                                                                                    _vm.ring_group,
                                                                                    "queue_handle_by_text_response_tts",
                                                                                    $event
                                                                                      .target
                                                                                      .value
                                                                                  )
                                                                                },
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.preValidateForm(
                                                                                    "ring_group_form"
                                                                                  )
                                                                                },
                                                                              ],
                                                                          },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "variable-dialog"
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "template-list-dialog",
                                                                    {
                                                                      attrs: {
                                                                        text_button: true,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ]),
                                          _vm._v(" "),
                                          _c("el-form-item", [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: {
                                                id: "queue_should_ask_for_voicemail",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-label mb-3",
                                              },
                                              [
                                                _c(
                                                  "h5",
                                                  { staticClass: "mb-0" },
                                                  [
                                                    _vm._v(
                                                      "Offer Voicemail Option"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "\n                                                            Callers can press THREE (3) and leave a voicemail rather than staying in the queue.\n                                                        "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "switch-label mb-4",
                                              },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-color": "#00a344",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.preValidateForm(
                                                        "ring_group_form"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ring_group
                                                        .queue_should_ask_for_voicemail,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.ring_group,
                                                        "queue_should_ask_for_voicemail",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ring_group.queue_should_ask_for_voicemail",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("label", [
                                                  _vm._v(
                                                    "\n                                                            Offer Voicemail Option\n                                                        "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("div", [
                                              _vm.ring_group
                                                .queue_should_ask_for_voicemail
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "b-b nav-active-greenish",
                                                    },
                                                    [
                                                      _c(
                                                        "ul",
                                                        {
                                                          staticClass:
                                                            "nav nav-tabs",
                                                        },
                                                        [
                                                          _c(
                                                            "li",
                                                            {
                                                              staticClass:
                                                                "nav-item",
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "border border-right-0 rounded-top-left",
                                                                  class:
                                                                    _vm
                                                                      .queueVoicemailNavClass
                                                                      .read,
                                                                  attrs: {
                                                                    href: "",
                                                                    "data-toggle":
                                                                      "tab",
                                                                    "data-target":
                                                                      "#ask-for-voicemail-tts",
                                                                    "aria-expanded":
                                                                      "true",
                                                                  },
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fa fa-robot",
                                                                  }),
                                                                  _vm._v(
                                                                    "\n                                                                        Text to Speech\n                                                                    "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.hasPermissionTo([
                                                            "update ring group",
                                                            "create ring group",
                                                            "view ring group",
                                                          ])
                                                            ? _c(
                                                                "li",
                                                                {
                                                                  staticClass:
                                                                    "nav-item",
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      staticClass:
                                                                        "border rounded-top-right",
                                                                      class:
                                                                        _vm
                                                                          .queueVoicemailNavClass
                                                                          .play,
                                                                      attrs: {
                                                                        href: "",
                                                                        "data-toggle":
                                                                          "tab",
                                                                        "data-target":
                                                                          "#ask-for-voicemail-file",
                                                                        "aria-expanded":
                                                                          "false",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fa fa-play text-md",
                                                                      }),
                                                                      _vm._v(
                                                                        "\n                                                                        Play Recording\n                                                                    "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.ring_group
                                                .queue_should_ask_for_voicemail
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "tab-content p-a m-b-md border border-top-0",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          class:
                                                            _vm
                                                              .queueVoicemailContainerClass
                                                              .read,
                                                          attrs: {
                                                            id: "ask-for-voicemail-tts",
                                                            "aria-expanded":
                                                              "true",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              staticClass:
                                                                "mb-4",
                                                              attrs: {
                                                                label: "Prompt",
                                                                prop: "queue_should_ask_for_voicemail",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-12",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "input-group",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "model",
                                                                                    rawName:
                                                                                      "v-model",
                                                                                    value:
                                                                                      _vm
                                                                                        .ring_group
                                                                                        .queue_ask_for_voicemail_tts,
                                                                                    expression:
                                                                                      "ring_group.queue_ask_for_voicemail_tts",
                                                                                  },
                                                                                ],
                                                                              staticClass:
                                                                                "form-control pt-2 pb-2",
                                                                              attrs:
                                                                                {
                                                                                  type: "text",
                                                                                },
                                                                              domProps:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .ring_group
                                                                                      .queue_ask_for_voicemail_tts,
                                                                                },
                                                                              on: {
                                                                                input:
                                                                                  [
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      if (
                                                                                        $event
                                                                                          .target
                                                                                          .composing
                                                                                      )
                                                                                        return
                                                                                      _vm.$set(
                                                                                        _vm.ring_group,
                                                                                        "queue_ask_for_voicemail_tts",
                                                                                        $event
                                                                                          .target
                                                                                          .value
                                                                                      )
                                                                                    },
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.preValidateForm(
                                                                                        "ring_group_form"
                                                                                      )
                                                                                    },
                                                                                  ],
                                                                              },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "variable-dialog"
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "template-list-dialog",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              text_button: true,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.hasPermissionTo([
                                                        "update ring group",
                                                        "create ring group",
                                                        "view ring group",
                                                      ])
                                                        ? _c(
                                                            "div",
                                                            {
                                                              class:
                                                                _vm
                                                                  .queueVoicemailContainerClass
                                                                  .play,
                                                              attrs: {
                                                                id: "ask-for-voicemail-file",
                                                                "aria-expanded":
                                                                  "false",
                                                              },
                                                            },
                                                            [
                                                              _vm.ring_group
                                                                .queue_ask_for_voicemail_file ===
                                                              null
                                                                ? _c(
                                                                    "audio-recorder",
                                                                    {
                                                                      staticClass:
                                                                        "audio-recorder-wrapper",
                                                                      attrs: {
                                                                        action:
                                                                          "/api/v1/ring-group/" +
                                                                          _vm
                                                                            .ring_group
                                                                            .id +
                                                                          "/upload-file/queue_voicemails",
                                                                        upload_type:
                                                                          "queue_ask_for_voicemail_file",
                                                                      },
                                                                      on: {
                                                                        applyUploadedAudio:
                                                                          _vm.applyUploadedAudio,
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "audio-uploader-wrapper",
                                                                },
                                                                [
                                                                  _vm.ring_group
                                                                    .queue_ask_for_voicemail_file ===
                                                                  null
                                                                    ? _c(
                                                                        "el-upload",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              action:
                                                                                "/api/v1/ring-group/" +
                                                                                _vm
                                                                                  .ring_group
                                                                                  .id +
                                                                                "/upload-file/queue_voicemails",
                                                                              headers:
                                                                                _vm.headers,
                                                                              "on-success":
                                                                                _vm.onSuccessQueueVoicemail,
                                                                              "on-error":
                                                                                _vm.onFailedQueueVoicemail,
                                                                              "on-progress":
                                                                                _vm.progressUploadQueueVoicemail,
                                                                              "before-upload":
                                                                                _vm.beforeUploadQueueVoicemail,
                                                                              "file-list":
                                                                                _vm
                                                                                  .uploadFileList
                                                                                  .queueAskForVoicemail,
                                                                              drag: "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "el-icon-upload",
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "el-upload__text",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                            Drop file here or "
                                                                              ),
                                                                              _c(
                                                                                "em",
                                                                                [
                                                                                  _vm._v(
                                                                                    "click to upload"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "el-upload__tip",
                                                                              attrs:
                                                                                {
                                                                                  slot: "tip",
                                                                                },
                                                                              slot: "tip",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                            MP3/WAV file (less than 8MB)\n                                                                        "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm.ring_group
                                                                    .queue_ask_for_voicemail_file ===
                                                                  null
                                                                    ? _c(
                                                                        "el-progress",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "text-inside": true,
                                                                              "stroke-width": 18,
                                                                              percentage:
                                                                                _vm
                                                                                  .uploadPercentage
                                                                                  .queueAskForVoicemail,
                                                                              status:
                                                                                _vm
                                                                                  .uploadStatus
                                                                                  .queueAskForVoicemail,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm.ring_group
                                                                    .queue_ask_for_voicemail_file !==
                                                                  null
                                                                    ? _c(
                                                                        "audio",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              controls:
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "source",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  src:
                                                                                    "/static/uploaded_file/" +
                                                                                    _vm
                                                                                      .ring_group
                                                                                      .queue_ask_for_voicemail_file,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            "\n                                                                        Your browser does not support the audio element.\n                                                                    "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm.ring_group
                                                                    .queue_ask_for_voicemail_file !==
                                                                    null &&
                                                                  _vm.hasPermissionTo(
                                                                    "delete ring group"
                                                                  )
                                                                    ? _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "btn btn-sm btn-danger pull-right",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return _vm.deleteFile(
                                                                                  "queue_voicemails"
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "show",
                                                                                    rawName:
                                                                                      "v-show",
                                                                                    value:
                                                                                      _vm.loading_queue_ask_for_voicemail,
                                                                                    expression:
                                                                                      "loading_queue_ask_for_voicemail",
                                                                                  },
                                                                                ],
                                                                              staticClass:
                                                                                "material-icons loader pull-left",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                ""
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "show",
                                                                                    rawName:
                                                                                      "v-show",
                                                                                    value:
                                                                                      !_vm.loading_queue_ask_for_voicemail,
                                                                                    expression:
                                                                                      "!loading_queue_ask_for_voicemail",
                                                                                  },
                                                                                ],
                                                                              staticClass:
                                                                                "material-icons pull-left",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                ""
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Remove file"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ]),
                                          _vm._v(" "),
                                          _c("el-form-item", [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: {
                                                id: "queue_should_call_screen",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-label mb-3",
                                              },
                                              [
                                                _c(
                                                  "h5",
                                                  { staticClass: "mb-0" },
                                                  [_vm._v("Press One")]
                                                ),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "\n                                                            Require called parties to press one to accept the call.\n                                                        "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "switch-label mb-4",
                                              },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-color": "#00a344",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.preValidateForm(
                                                        "ring_group_form"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ring_group
                                                        .queue_should_call_screen,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.ring_group,
                                                        "queue_should_call_screen",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ring_group.queue_should_call_screen",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("label", [
                                                  _vm._v(
                                                    "\n                                                            Enable Press One\n                                                        "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("div", [
                                              _vm.ring_group
                                                .queue_should_call_screen
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "b-b nav-active-greenish",
                                                    },
                                                    [
                                                      _c(
                                                        "ul",
                                                        {
                                                          staticClass:
                                                            "nav nav-tabs",
                                                        },
                                                        [
                                                          _c(
                                                            "li",
                                                            {
                                                              staticClass:
                                                                "nav-item",
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "border border-right-0 rounded-top-left",
                                                                  class:
                                                                    _vm
                                                                      .queueCallScreenNavClass
                                                                      .read,
                                                                  attrs: {
                                                                    href: "",
                                                                    "data-toggle":
                                                                      "tab",
                                                                    "data-target":
                                                                      "#call-screen-tts",
                                                                    "aria-expanded":
                                                                      "true",
                                                                  },
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fa fa-robot",
                                                                  }),
                                                                  _vm._v(
                                                                    "\n                                                                        Text to Speech\n                                                                    "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.hasPermissionTo([
                                                            "update ring group",
                                                            "create ring group",
                                                            "view ring group",
                                                          ])
                                                            ? _c(
                                                                "li",
                                                                {
                                                                  staticClass:
                                                                    "nav-item",
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      staticClass:
                                                                        "border rounded-top-right",
                                                                      class:
                                                                        _vm
                                                                          .queueCallScreenNavClass
                                                                          .play,
                                                                      attrs: {
                                                                        href: "",
                                                                        "data-toggle":
                                                                          "tab",
                                                                        "data-target":
                                                                          "#call-screen-file",
                                                                        "aria-expanded":
                                                                          "false",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fa fa-play text-md",
                                                                      }),
                                                                      _vm._v(
                                                                        "\n                                                                        Play Recording\n                                                                    "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.ring_group
                                                .queue_should_call_screen
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "tab-content p-a m-b-md border border-top-0",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          class:
                                                            _vm
                                                              .queueCallScreenContainerClass
                                                              .read,
                                                          attrs: {
                                                            id: "call-screen-tts",
                                                            "aria-expanded":
                                                              "true",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              staticClass:
                                                                "mb-4",
                                                              attrs: {
                                                                label: "Prompt",
                                                                prop: "queue_call_screen_tts",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-12",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "input-group",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "model",
                                                                                    rawName:
                                                                                      "v-model",
                                                                                    value:
                                                                                      _vm
                                                                                        .ring_group
                                                                                        .queue_call_screen_tts,
                                                                                    expression:
                                                                                      "ring_group.queue_call_screen_tts",
                                                                                  },
                                                                                ],
                                                                              staticClass:
                                                                                "form-control pt-2 pb-2",
                                                                              attrs:
                                                                                {
                                                                                  type: "text",
                                                                                },
                                                                              domProps:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .ring_group
                                                                                      .queue_call_screen_tts,
                                                                                },
                                                                              on: {
                                                                                input:
                                                                                  [
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      if (
                                                                                        $event
                                                                                          .target
                                                                                          .composing
                                                                                      )
                                                                                        return
                                                                                      _vm.$set(
                                                                                        _vm.ring_group,
                                                                                        "queue_call_screen_tts",
                                                                                        $event
                                                                                          .target
                                                                                          .value
                                                                                      )
                                                                                    },
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.preValidateForm(
                                                                                        "ring_group_form"
                                                                                      )
                                                                                    },
                                                                                  ],
                                                                              },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "variable-dialog"
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "template-list-dialog",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              text_button: true,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.hasPermissionTo([
                                                        "update ring group",
                                                        "create ring group",
                                                        "view ring group",
                                                      ])
                                                        ? _c(
                                                            "div",
                                                            {
                                                              class:
                                                                _vm
                                                                  .queueCallScreenContainerClass
                                                                  .play,
                                                              attrs: {
                                                                id: "call-screen-file",
                                                                "aria-expanded":
                                                                  "false",
                                                              },
                                                            },
                                                            [
                                                              _vm.ring_group
                                                                .queue_call_screen_file ===
                                                              null
                                                                ? _c(
                                                                    "audio-recorder",
                                                                    {
                                                                      staticClass:
                                                                        "audio-recorder-wrapper",
                                                                      attrs: {
                                                                        action:
                                                                          "/api/v1/ring-group/" +
                                                                          _vm
                                                                            .ring_group
                                                                            .id +
                                                                          "/upload-file/queue_call_screens",
                                                                        upload_type:
                                                                          "queue_call_screen_file",
                                                                      },
                                                                      on: {
                                                                        applyUploadedAudio:
                                                                          _vm.applyUploadedAudio,
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "audio-uploader-wrapper",
                                                                },
                                                                [
                                                                  _vm.ring_group
                                                                    .queue_call_screen_file ===
                                                                  null
                                                                    ? _c(
                                                                        "el-upload",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              action:
                                                                                "/api/v1/ring-group/" +
                                                                                _vm
                                                                                  .ring_group
                                                                                  .id +
                                                                                "/upload-file/queue_call_screens",
                                                                              headers:
                                                                                _vm.headers,
                                                                              "on-success":
                                                                                _vm.onSuccessCallScreen,
                                                                              "on-error":
                                                                                _vm.onFailedCallScreen,
                                                                              "on-progress":
                                                                                _vm.progressUploadCallScreen,
                                                                              "before-upload":
                                                                                _vm.beforeUploadCallScreen,
                                                                              "file-list":
                                                                                _vm
                                                                                  .uploadFileList
                                                                                  .queueCallScreen,
                                                                              drag: "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "el-icon-upload",
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "el-upload__text",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                            Drop file here or "
                                                                              ),
                                                                              _c(
                                                                                "em",
                                                                                [
                                                                                  _vm._v(
                                                                                    "click to upload"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "el-upload__tip",
                                                                              attrs:
                                                                                {
                                                                                  slot: "tip",
                                                                                },
                                                                              slot: "tip",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                            MP3/WAV file (less than 8MB)\n                                                                        "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm.ring_group
                                                                    .queue_call_screen_file ===
                                                                  null
                                                                    ? _c(
                                                                        "el-progress",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "text-inside": true,
                                                                              "stroke-width": 18,
                                                                              percentage:
                                                                                _vm
                                                                                  .uploadPercentage
                                                                                  .queueCallScreen,
                                                                              status:
                                                                                _vm
                                                                                  .uploadStatus
                                                                                  .queueCallScreen,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm.ring_group
                                                                    .queue_call_screen_file !==
                                                                  null
                                                                    ? _c(
                                                                        "audio",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              controls:
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "source",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  src:
                                                                                    "/static/uploaded_file/" +
                                                                                    _vm
                                                                                      .ring_group
                                                                                      .queue_call_screen_file,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            "\n                                                                        Your browser does not support the audio element.\n                                                                    "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm.ring_group
                                                                    .queue_call_screen_file !==
                                                                    null &&
                                                                  _vm.hasPermissionTo(
                                                                    "delete ring group"
                                                                  )
                                                                    ? _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "btn btn-sm btn-danger pull-right",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return _vm.deleteFile(
                                                                                  "queue_call_screens"
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "show",
                                                                                    rawName:
                                                                                      "v-show",
                                                                                    value:
                                                                                      _vm.loading_queue_call_screen,
                                                                                    expression:
                                                                                      "loading_queue_call_screen",
                                                                                  },
                                                                                ],
                                                                              staticClass:
                                                                                "material-icons loader pull-left",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                ""
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "show",
                                                                                    rawName:
                                                                                      "v-show",
                                                                                    value:
                                                                                      !_vm.loading_queue_call_screen,
                                                                                    expression:
                                                                                      "!loading_queue_call_screen",
                                                                                  },
                                                                                ],
                                                                              staticClass:
                                                                                "material-icons pull-left",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                ""
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Remove file"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-3 d-none d-md-block ml-auto",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70",
                                            },
                                            [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass:
                                                    "list-group-item",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                        On This Page\n                                                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "list-group-item list-group-item-action",
                                                  attrs: {
                                                    href: "#queue_should_ask_for_callback",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.shine(
                                                        "queue_should_ask_for_callback"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                        Offer Callback Option\n                                                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "list-group-item list-group-item-action",
                                                  attrs: {
                                                    href: "#queue_should_handle_by_text",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.shine(
                                                        "queue_should_handle_by_text"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                        Offer Handle By Text Option\n                                                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "list-group-item list-group-item-action",
                                                  attrs: {
                                                    href: "#queue_should_ask_for_voicemail",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.shine(
                                                        "queue_should_ask_for_voicemail"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                        Offer Voicemail Option\n                                                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "list-group-item list-group-item-action",
                                                  attrs: {
                                                    href: "#queue_should_call_screen",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.shine(
                                                        "queue_should_call_screen"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                        Press One\n                                                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.ring_group_id && _vm.ring_group.id
                                  ? _c(
                                      "el-tab-pane",
                                      {
                                        attrs: {
                                          name: "routing-test",
                                          label: "Test Routing",
                                        },
                                      },
                                      [
                                        _c("ring-group-routing-test", {
                                          ref: "ringGroupRoutingTest",
                                          attrs: { ring_group: _vm.ring_group },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.ring_group_id && _vm.ring_group
                                  ? _c(
                                      "el-tab-pane",
                                      {
                                        staticClass: "p-2",
                                        attrs: {
                                          name: "connected-lines",
                                          label: "Connected Lines",
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-md-8" },
                                            [
                                              _c(
                                                "el-form-item",
                                                { staticClass: "no-border" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-label",
                                                    },
                                                    [
                                                      _c(
                                                        "h5",
                                                        { staticClass: "mb-0" },
                                                        [
                                                          _vm._v(
                                                            "Connected Lines"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("small", [
                                                        _vm._v(
                                                          "\n                                                        Here you can see the lines that are directly connected to this ring group. "
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          "\n                                                        Note: IVRs are not shown.\n                                                    "
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.ring_group.campaign_ids &&
                                                  _vm.ring_group.campaign_ids
                                                    .length === 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "alert alert-danger alert-aloware d-inline-block",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    This ring group is not directly connected to any lines.\n                                                "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.ring_group.campaign_ids &&
                                                  _vm.ring_group.campaign_ids
                                                    .length
                                                    ? _c(
                                                        "ul",
                                                        {
                                                          staticClass:
                                                            "pl-0 ml-4",
                                                        },
                                                        _vm._l(
                                                          _vm.ring_group
                                                            .campaign_ids,
                                                          function (
                                                            campaign_id
                                                          ) {
                                                            return _c(
                                                              "li",
                                                              {
                                                                key: campaign_id,
                                                                staticClass:
                                                                  "pl-0",
                                                              },
                                                              [
                                                                _c(
                                                                  "router-link",
                                                                  {
                                                                    attrs: {
                                                                      to: {
                                                                        name: "Line Settings",
                                                                        params:
                                                                          {
                                                                            campaign_id:
                                                                              campaign_id,
                                                                          },
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "text-md text-dark-greenish _600",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "campaignName"
                                                                            )(
                                                                              campaign_id,
                                                                              _vm.campaigns
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("div", {
                                            staticClass:
                                              "col-3 d-none d-md-block ml-auto",
                                          }),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.ring_group_id &&
                                _vm.ring_group.id &&
                                _vm.isCompanyPartOfAlowareDemoCompanies(false)
                                  ? _c(
                                      "el-tab-pane",
                                      {
                                        attrs: {
                                          name: "manage-team-inbox",
                                          label: `Manage ${_vm.EINBOX_NAME} (New)`,
                                        },
                                      },
                                      [
                                        _c("ring-group-manage-team-inbox", {
                                          ref: "ringGroupManageTeamInbox",
                                          attrs: { ring_group: _vm.ring_group },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("form-height-indicator"),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "fixed-footer padding pb-0" }, [
        _c("div", { staticClass: "form-group row mb-0 mt-0" }, [
          _c(
            "div",
            { staticClass: "col-12 d-none d-md-block p-2 edit-operations" },
            [
              _vm.canGoBack
                ? _c(
                    "el-button",
                    {
                      staticClass: "pull-left",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.goBack.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-arrow-left" }),
                      _vm._v("\n                    Back\n                "),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPermissionTo([
                "update ring group",
                "create ring group",
                "view ring group",
              ])
                ? _c(
                    "el-button",
                    {
                      staticClass: "pull-right ml-0",
                      attrs: {
                        type: "success",
                        disabled: _vm.loading_btn || !_vm.validated,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.submitForm("ring_group_form")
                        },
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.loading_btn,
                              expression: "loading_btn",
                            },
                          ],
                          staticClass: "material-icons loader pull-left",
                        },
                        [_vm._v("")]
                      ),
                      _vm._v(" "),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.loading_btn,
                            expression: "!loading_btn",
                          },
                        ],
                        staticClass: "fa fa-floppy-o",
                      }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.ring_group_id ? "Save" : "Add") +
                          "\n                "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPermissionTo("delete ring group") && _vm.ring_group_id
                ? _c(
                    "button",
                    {
                      staticClass: "btn el-btn pull-right danger mr-2",
                      attrs: { disabled: _vm.loading_delete },
                      on: {
                        click: function ($event) {
                          return _vm.deleteRingGroupRemote()
                        },
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.loading_delete,
                              expression: "loading_delete",
                            },
                          ],
                          staticClass: "material-icons loader pull-left",
                        },
                        [_vm._v("")]
                      ),
                      _vm._v(" "),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.loading_delete,
                            expression: "!loading_delete",
                          },
                        ],
                        staticClass: "fa fa-trash",
                      }),
                      _vm._v("\n                    Delete\n                "),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 d-sm-block d-md-none p-2 edit-operations" },
            [
              _vm.canGoBack
                ? _c(
                    "el-button",
                    {
                      staticClass: "pull-left",
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.goBack.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-arrow-left" }),
                      _vm._v("\n                    Back\n                "),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPermissionTo([
                "update ring group",
                "create ring group",
                "view ring group",
              ])
                ? _c(
                    "el-button",
                    {
                      staticClass: "pull-right",
                      attrs: {
                        size: "mini",
                        type: "success",
                        disabled: _vm.loading_btn || !_vm.validated,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.submitForm("ring_group_form")
                        },
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.loading_btn,
                              expression: "loading_btn",
                            },
                          ],
                          staticClass: "material-icons loader pull-left",
                        },
                        [_vm._v("")]
                      ),
                      _vm._v(" "),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.loading_btn,
                            expression: "!loading_btn",
                          },
                        ],
                        staticClass: "fa fa-floppy-o",
                      }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.ring_group_id ? "Save" : "Add") +
                          "\n                "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-popover",
                {
                  attrs: { placement: "top", width: "100%", trigger: "click" },
                },
                [
                  _vm.hasPermissionTo("delete ring group") && _vm.ring_group_id
                    ? _c(
                        "el-button",
                        {
                          staticClass: "pull-right ml-0",
                          attrs: {
                            type: "danger",
                            size: "mini",
                            disabled: _vm.loading_delete,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteRingGroupRemote()
                            },
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.loading_delete,
                                  expression: "loading_delete",
                                },
                              ],
                              staticClass: "material-icons loader pull-left",
                            },
                            [_vm._v("")]
                          ),
                          _vm._v(" "),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.loading_delete,
                                expression: "!loading_delete",
                              },
                            ],
                            staticClass: "fa fa-trash",
                          }),
                          _vm._v(
                            "\n                        Delete\n                    "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-button", {
                    staticClass: "pull-right",
                    attrs: {
                      slot: "reference",
                      size: "mini",
                      type: "primary",
                      icon: "el-icon-more-outline",
                      plain: "",
                    },
                    slot: "reference",
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }