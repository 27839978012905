<template>
    <el-select 
        v-model="selectedTemplate" 
        class="w-100 mb-3" 
        placeholder="Select an instruction template"
        popper-class="template-select-dropdown"
        @change="onTemplateChange"
    >
        <!-- Custom template option -->
        <el-option
            :key="0"
            label="Custom Template"
            :value="0"
        >
            <div class="template-option">
                <p class="font-weight-bold mb-0">Custom Template</p>
                <span class="text-muted text-wrap">
                    Create your own custom instructions for your agent's unique requirements.
                </span>
            </div>
        </el-option>

        <!-- Predefined templates -->
        <el-option
            v-for="template in templates"
            :key="template.id"
            :label="template.name"
            :value="template.id"
        >
            <div class="template-option">
                <p class="font-weight-bold mb-0">{{ template.name }}</p>
                <span class="text-muted text-wrap">
                    {{ getShortDescription(template.description) }}
                </span>
            </div>
        </el-option>
    </el-select>
</template>

<script>
import { INSTRUCTION_TEMPLATES } from '../../../constants/aloai'
export default {
    name: 'TemplateSelector',
    data() {
        return {
            selectedTemplate: 0, // Default to Custom Template
            templates: INSTRUCTION_TEMPLATES,
            maxDescriptionLength: 100
        }
    },
    methods: {
        onTemplateChange(value) {
            if (value === 0) {
                // Custom template selected - do nothing
                return
            }

            // Find the selected template
            const template = this.templates.find(t => t.id === value)
            if (template) {
                // Emit event with template content and ID
                this.$emit('template-selected', template.content, value)
            }
        },

        getShortDescription(description) {
            // Keep descriptions at a reasonable length to prevent overflow
            if (description.length > this.maxDescriptionLength) {
                return description.substring(0, this.maxDescriptionLength) + '...'
            }

            return description
        },

        resetSelection() {
            this.selectedTemplate = 0
        }
    }
}
</script>
