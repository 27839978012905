<template>
    <div>
        <el-select
            v-model="propertyKey"
            class="w-full"
            :disabled="disabled"
            default-first-option
            filterable
            :placeholder="placeholder"
            data-testid="contact-property-selector"
            autocomplete="off"
            @change="handlePropertySelected"
        >
            <el-option-group
                key="DefaultProperties"
                label="Default Properties"
                v-if="defaultProperties && defaultProperties.length > 0"
            >
                <el-option
                    v-for="prop in defaultProperties"
                    :key="prop.slug"
                    :label="prop.name"
                    :value="prop.slug">
                    <div class="media">
                        <div class="media-body">
                            <label class="d-flex align-items-center">
                                {{ prop.name }}
                                <span class="label dark ml-2 custom-property-type">
                                    {{ prop.type_values_text }}
                                </span>
                            </label>
                            <small v-if="prop.description">{{ prop.description }}</small>
                        </div>
                    </div>
                </el-option>
            </el-option-group>

            <slot/>

            <el-option-group
                key="CustomProperties"
                label="Custom Properties"
                v-if="customProperties && customProperties.length > 0"
            >
                <el-option
                    v-for="prop in customProperties"
                    :key="prop.slug"
                    :label="prop.name"
                    :value="prop.slug"
                >
                    <div class="media">
                        <div class="media-body">
                            <label class="d-flex align-items-center">
                                {{ prop.name }}
                                <span class="label dark ml-2 custom-property-type">
                                    {{ prop.type_values_text }}
                                </span>
                            </label>
                            <small v-if="prop.description" class="text-muted">
                                {{ prop.description }}
                            </small>
                        </div>
                    </div>
                </el-option>
            </el-option-group>
        </el-select>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import axios from 'axios'

export default {
    props: {
        disabled: {
            default: false,
            type: Boolean,
            required: false
        },

        placeholder: {
            default: 'Select Property'
        }
    },

    data() {
        return {
            propertyKey: null,
            defaultProperties: [],
            customProperties: [],
            isLoading: false
        }
    },

    created() {
        this.loadContactProperties()
    },

    methods: {
        async loadContactProperties() {
            try {
                this.isLoading = true
                // Direct API call to get Aloware contact properties without relying on Hubspot integration
                const response = await axios.get('/api/v1/aloai/contact-properties')

                this.defaultProperties = response.data.default_properties
                this.customProperties = response.data.custom_properties
            } catch (error) {
                console.error('Error fetching contact properties:', error)
                // Don't fallback to default properties, just leave them empty
                this.defaultProperties = []
                this.customProperties = []
            } finally {
                this.isLoading = false
            }
        },
        handlePropertySelected(propertyKey) {
            let property = this.customProperties.find(prop => prop.slug === propertyKey)

            if (!property) {
                property = this.defaultProperties.find(prop => prop.slug === propertyKey)
            }

            this.$emit('property-selected', property)
        }
    },
}
</script>

<style>
.custom-property-type {
    font-size: 10px;
}
</style>
