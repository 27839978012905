var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          staticClass: "w-full",
          attrs: {
            disabled: _vm.disabled,
            "default-first-option": "",
            filterable: "",
            placeholder: _vm.placeholder,
            "data-testid": "contact-property-selector",
            autocomplete: "off",
          },
          on: { change: _vm.handlePropertySelected },
          model: {
            value: _vm.propertyKey,
            callback: function ($$v) {
              _vm.propertyKey = $$v
            },
            expression: "propertyKey",
          },
        },
        [
          _vm.defaultProperties && _vm.defaultProperties.length > 0
            ? _c(
                "el-option-group",
                {
                  key: "DefaultProperties",
                  attrs: { label: "Default Properties" },
                },
                _vm._l(_vm.defaultProperties, function (prop) {
                  return _c(
                    "el-option",
                    {
                      key: prop.slug,
                      attrs: { label: prop.name, value: prop.slug },
                    },
                    [
                      _c("div", { staticClass: "media" }, [
                        _c("div", { staticClass: "media-body" }, [
                          _c(
                            "label",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(prop.name) +
                                  "\n                            "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "label dark ml-2 custom-property-type",
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(prop.type_values_text) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          prop.description
                            ? _c("small", [_vm._v(_vm._s(prop.description))])
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default"),
          _vm._v(" "),
          _vm.customProperties && _vm.customProperties.length > 0
            ? _c(
                "el-option-group",
                {
                  key: "CustomProperties",
                  attrs: { label: "Custom Properties" },
                },
                _vm._l(_vm.customProperties, function (prop) {
                  return _c(
                    "el-option",
                    {
                      key: prop.slug,
                      attrs: { label: prop.name, value: prop.slug },
                    },
                    [
                      _c("div", { staticClass: "media" }, [
                        _c("div", { staticClass: "media-body" }, [
                          _c(
                            "label",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(prop.name) +
                                  "\n                            "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "label dark ml-2 custom-property-type",
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(prop.type_values_text) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          prop.description
                            ? _c("small", { staticClass: "text-muted" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(prop.description) +
                                    "\n                        "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }