<template>
    <el-form
        ref="agent_settings"
        class="aloai-agents"
        :rules="rules"
        :model="bot"
    >
        <div class="row">
            <div class="col-12">
                <el-tabs
                    v-model="activeName"
                    @tab-click="handleClick"
                >
                    <!-- Configure tab -->
                    <el-tab-pane
                        label="Configure"
                        name="configure"
                        data-tab-error="configure"
                    >
                        <span slot="label">
                            Configure<span v-html="tabLabelErrorIcon(tab_errors.configure)"></span>
                        </span>
                        <div class="row justify-content-center pt-3">
                            <div class="col-md-12 col-lg-10 main-col">
                                <el-form-item prop="name">
                                    <div class="form-label">
                                        <h5 class="text-dark">Name</h5>
                                        <small>
                                            What name would you like to give to this agent?
                                        </small>
                                    </div>
                                    <el-input
                                        v-model="bot.name"
                                        @input="preValidateForm('agent_settings')"
                                    />
                                </el-form-item>

                                <el-form-item>
                                    <div class="form-label">
                                        <h5 class="text-dark">Description</h5>
                                        <small>Describe the purpose of this agent.</small>
                                    </div>
                                    <el-input
                                        type="textarea"
                                        :rows="2"
                                        v-model="bot.description"
                                    />
                                </el-form-item>

                                <hr />

                                <el-form-item>
                                    <div class="form-label">
                                        <h5 class="text-dark">Agent type</h5>
                                        <small>
                                            What is the main channel of communication for this agent?
                                        </small>
                                    </div>
                                    <el-select
                                        class="w-100"
                                        v-model="bot.type"
                                        @change="updateBotType"
                                    >
                                        <el-option
                                            v-for="type in types"
                                            :key="type.value"
                                            :label="type.name"
                                            :value="type.value"
                                            :disabled="lockVoiceOptions(type)"
                                        />
                                    </el-select>
                                </el-form-item>

                                <el-form-item v-if="bot.type == AloAi.TYPE_TEXT">
                                    <div class="form-label">
                                        <h5 class="text-dark">Direction</h5>
                                        <small>
                                            Will this agent start the conversations or wait for your
                                            contacts to reach out first?
                                        </small>
                                    </div>
                                    <el-select
                                        v-model="bot.direction"
                                        class="w-100"
                                    >
                                        <el-option
                                            v-for="direction in directions"
                                            :key="direction.value"
                                            :label="direction.name"
                                            :value="direction.value"
                                        />
                                    </el-select>
                                </el-form-item>

                                <el-form-item v-if="bot.type == AloAi.TYPE_TEXT && bot.direction == AloAi.DIRECTION_OUTBOUND">
                                    <div class="form-label">
                                        <h5 class="text-dark">Default Outbound Line</h5>
                                        <small>
                                            Select the default outbound line for this agent.
                                        </small>
                                    </div>
                                    <el-select
                                        class="w-100"
                                        v-model="bot.default_outbound_campaign_id"
                                        placeholder="Select default outbound line"
                                    >
                                        <el-option
                                            v-for="line in lines"
                                            :key="line.id"
                                            :label="line.name"
                                            :value="line.id"
                                        />
                                    </el-select>
                                </el-form-item>

                                <hr />

                                <el-form-item v-if="bot.type === AloAi.TYPE_TEXT">
                                    <div class="form-label">
                                        <h5 class="text-dark">Text model</h5>
                                        <small>
                                            Choose the text model the agent needs to operate on. Opt for
                                            speed or depth to suit your agent's role.
                                        </small>
                                    </div>
                                    <el-select
                                        class="w-100"
                                        v-model="bot.model"
                                    >
                                        <el-option
                                            v-for="model in models"
                                            :key="model.value"
                                            :label="model.name"
                                            :value="model.value"
                                        />
                                    </el-select>
                                </el-form-item>

                                <el-form-item v-if="bot.type === AloAi.TYPE_VOICE">
                                    <div class="form-label">
                                        <h5 class="text-dark">Voice model</h5>
                                        <small>
                                            Choose the voice model the agent needs to operate on. Opt for
                                            speed or depth to suit your agent's role.
                                        </small>
                                    </div>
                                    <el-select
                                        v-model="bot.voice_model"
                                        class="w-100"
                                    >
                                        <el-option
                                            v-for="model in voice_models"
                                            :key="model.value"
                                            :label="model.name"
                                            :value="model.value"
                                        />
                                    </el-select>
                                </el-form-item>

                                <el-form-item v-if="bot.type === AloAi.TYPE_VOICE">
                                    <div class="form-label">
                                        <h5 class="text-dark">Select voice</h5>
                                        <small> Choose a preset voice for this agent. </small>
                                    </div>
                                    <div class="d-flex align-items-center width-300">
                                        <el-select
                                            class="w-100"
                                            v-model="bot.voice"
                                            @change="stopAudio"
                                        >
                                            <el-option
                                                v-for="voice in voices"
                                                :key="voice.value"
                                                :label="voice.name"
                                                :value="voice.value"
                                            />
                                        </el-select>
                                        <el-button
                                            class="ml-2"
                                            circle
                                            @click="toggleAudio"
                                        >
                                            <i :class="isPlaying ? 'fas fa-stop' : 'fas fa-play'"></i>
                                        </el-button>
                                    </div>
                                </el-form-item>

                                <hr />

                                <el-form-item>
                                    <div class="form-label">
                                        <h5 class="text-dark">Engagement expiration</h5>
                                        <small>
                                            How long will the agent's engagement with a contact last before it expires?
                                        </small>
                                    </div>
                                    <div class="row align-items-center">
                                        <div class="col-6">
                                            <el-form-item
                                                class="mb-0"
                                                prop="ttl"
                                            >
                                                <el-input-number
                                                    class="w-100"
                                                    v-model="bot_ttl"
                                                    :min="1"
                                                    :max="14"
                                                    :precision="0"
                                                    :controls="true"
                                                    @change="updateBotTTL"
                                                />
                                            </el-form-item>
                                        </div>
                                        <div class="col-6">
                                            Days
                                        </div>
                                    </div>
                                </el-form-item>
                            </div>
                        </div>
                    </el-tab-pane>

                    <!-- Instructions tab -->
                    <el-tab-pane
                        label="Instructions"
                        name="instructions"
                        data-tab-error="instructions"
                    >
                        <span slot="label">
                            Prompt<span v-html="tabLabelErrorIcon(tab_errors.instructions)"></span>
                        </span>
                        <div class="row justify-content-center pt-3">
                            <div class="col-md-12 col-lg-10 main-col">
                                <alo-ai-form-instructions
                                    :bot="bot"
                                    @validate="preValidateForm('agent_settings')"
                                />
                            </div>
                        </div>
                    </el-tab-pane>

                    <!-- Schedule tab -->
                    <el-tab-pane
                        label="Schedule"
                        name="schedule"
                        data-tab-error="schedule"
                        v-if="bot.type === AloAi.TYPE_TEXT"
                    >
                        <span slot="label">
                            Schedule<span v-html="tabLabelErrorIcon(tab_errors.schedule)"></span>
                        </span>
                        <div class="row justify-content-center pt-3">
                            <div class="col-md-12 col-lg-10 main-col">
                                <schedule-tab :bot="bot"/>
                            </div>
                        </div>
                    </el-tab-pane>

                    <!-- Actions tab -->
                    <el-tab-pane
                        label="Actions"
                        name="actions"
                        data-tab-error="actions"
                        v-if="bot.type === AloAi.TYPE_TEXT"
                    >
                        <span slot="label">
                            Actions<span v-html="tabLabelErrorIcon(tab_errors.actions)"></span>
                        </span>
                        <div class="row justify-content-center pt-3">
                            <div class="col-md-12 col-lg-10 main-col">
                                <h5 class="text-dark">
                                    <i class="el-icon-setting"></i>
                                    Tools
                                </h5>
                                <el-form-item>
                                    <div class="form-label">
                                        <small>
                                            Select the functions to use with this bot.
                                        </small>
                                    </div>

                                    <el-select
                                        class="w-100"
                                        v-model="functions_list"
                                        multiple
                                        filterable
                                        placeholder="Select functions"
                                        @change="changeFunction"
                                    >
                                        <el-option
                                            v-for="(tool, index) in toolsList"
                                            :key="index"
                                            :label="tool.name"
                                            :value="tool.uuid"
                                        />
                                    </el-select>
                                </el-form-item>

                                <div v-if="bot.type == AloAi.TYPE_TEXT">
                                    <div class="form-label d-flex">
                                        <el-switch
                                            class="mr-2 align-self-center"
                                            v-model="bot.follow_up_enabled"
                                            active-color="#00BF50"
                                        />
                                        <h5 class="mb-0 mt-1">Follow Up</h5>
                                    </div>

                                    <el-form-item>
                                        <div class="form-label">
                                            <small>
                                                Set the frequency you want this agent to follow up the
                                                conversation when the contact is unresponsive.
                                            </small>
                                        </div>

                                        <!-- Follow Up -->
                                        <div
                                            v-show="bot.follow_up_enabled"
                                            class="row"
                                        >
                                            <div class="col-4">
                                                <el-form-item class="no-border">
                                                    <el-input
                                                        type="number"
                                                        class="w-100"
                                                        v-model="bot.follow_up_frequency"
                                                        :disabled="!bot.follow_up_enabled"
                                                        :max="100"
                                                        :min="1"
                                                        @input="handleFollowUpFrequencyInput"
                                                    >
                                                        <template slot="append">
                                                            Attempt<span v-if="bot.follow_up_frequency != 1">s</span>
                                                        </template>
                                                    </el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div
                                            v-show="bot.follow_up_enabled"
                                            class="form-label my-2"
                                        >
                                            <small>
                                                Set a delay within each follow up attempt.
                                            </small>
                                        </div>

                                        <DurationPicker
                                            v-show="bot.follow_up_enabled"
                                            :current_seconds="bot.follow_up_delay"
                                            @update_seconds="onUpdateFollowUpDelay"
                                        />
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>

                    <!-- Routing tab -->
                    <el-tab-pane
                        label="Deployment"
                        name="deployment"
                        data-tab-error="deployment"
                    >
                        <span slot="label">
                            Routing<span v-html="tabLabelErrorIcon(tab_errors.deployment)"></span>
                        </span>
                        <div class="row justify-content-center pt-3">
                            <div class="col-md-12 col-lg-10 main-col">
                                <el-alert
                                    class="my-2"
                                    type="info"
                                    title="Lines"
                                    :closable="false"
                                    show-icon
                                >
                                    <span v-if="bot.type == AloAi.TYPE_TEXT">
                                        Go to Lines > Settings to route your inbound SMS to this agent.
                                    </span>
                                    <span v-if="bot.type == AloAi.TYPE_VOICE">
                                        Go to Lines > Settings to route your inbound or missed calls
                                        to the agent to start using it.
                                    </span>
                                </el-alert>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </el-form>
</template>

<script>
import _ from 'lodash'
import * as AloAi from '../../../constants/aloai'
import { acl_mixin, aloai_mixin, form_validation_mixin } from '../../../mixins'
import Variables from '../../messenger/variables.vue'
import DurationPicker from './duration-picker.vue'
import AloAiFormInstructions from './model-settings.vue'
import ScheduleTab from './schedule-tab.vue'

export default {
    name: 'aloai-form-settings',

    mixins: [form_validation_mixin, acl_mixin, aloai_mixin],

    components: {
        AloAiFormInstructions,
        Variables,
        DurationPicker,
        ScheduleTab,
    },

    props: {
        lines: {
            required: false,
            default: []
        },
        bot: {
            required: true,
        },
    },

    mounted() {
        this.types[1].name = !this.isCompanyPartOfAlowareDemoCompanies() ? 'Voice (Coming Soon)' : 'Voice'
    },

    data() {
        return {
            types: [{
                name: 'Text',
                value: AloAi.TYPE_TEXT
            }, {
                name: 'Voice',
                value: AloAi.TYPE_VOICE
            }],
            directions: [{
                name: 'Inbound',
                value: AloAi.DIRECTION_INBOUND
            }, {
                name: 'Outbound',
                value: AloAi.DIRECTION_OUTBOUND
            }],
            models: [{
                name: 'GPT-4o mini',
                value: AloAi.MODEL_GPT_4O_MINI
            }, {
                name: 'GPT-4o',
                value: AloAi.MODEL_GPT_4O
            }],
            voice_models: [{
                name: 'GPT-4o Realtime Preview',
                value: AloAi.VOICE_MODEL_GPT_4O_REALTIME_PREVIEW
            }, {
                name: 'GPT-4o mini Realtime Preview',
                value: AloAi.VOICE_MODEL_GPT_4O_MINI_REALTIME_PREVIEW
            }],
            voices: [
                {name: 'Alloy', value: AloAi.VOICE_ALLOY, url: 'https://cdn.openai.com/API/voice-previews/alloy.flac'},
                {name: 'Ash', value: AloAi.VOICE_ASH, url: 'https://cdn.openai.com/API/voice-previews/ash.flac'},
                {name: 'Ballad', value: AloAi.VOICE_BALLAD, url: 'https://cdn.openai.com/API/voice-previews/ballad.flac'},
                {name: 'Coral', value: AloAi.VOICE_CORAL, url: 'https://cdn.openai.com/API/voice-previews/coral.flac'},
                {name: 'Echo', value: AloAi.VOICE_ECHO, url: 'https://cdn.openai.com/API/voice-previews/echo.flac'},
                {name: 'Sage', value: AloAi.VOICE_SAGE, url: 'https://cdn.openai.com/API/voice-previews/sage.flac'},
                {name: 'Shimmer', value: AloAi.VOICE_SHIMMER, url: 'https://cdn.openai.com/API/voice-previews/shimmer.flac'},
                {name: 'Verse', value: AloAi.VOICE_VERSE, url: 'https://cdn.openai.com/API/voice-previews/verse.flac'},
            ],
            rules: {
                name: [
                    {
                        required: true,
                        message: 'Please provide a name for the agent',
                        trigger: 'blur'
                    }
                ],
                opener: [
                    {
                        required: true,
                        message: 'Please provide an opener for the agent',
                        trigger: 'blur'
                    }
                ],
                ttl: [
                    {
                        required: true,
                        message: 'Please provide an engagement expiration time for the agent',
                        trigger: 'blur'
                    }
                ],
                instructions: {
                    validator: (_, value, callback) => {
                        if (!this.bot.instructions[0].content) {
                        callback(new Error('Please provide instructions for the agent'))
                        }

                        callback()
                    },
                    trigger: 'blur',
                },
            },
            loading: false,
            selected_campaigns: [],
            functions_list: [],
            tab_errors: {
                configure: false,
                instructions: false,
                actions: false,
                deployment: false,
            },
            bot_ttl: 7,
            error_notification: null,
            activeName: 'configure',
            isPlaying: false,
            audio: null,
            toolsList: null,
            AloAi,
        }
    },
    methods: {
        handleClick(tab, event) {},

        changeFunction(selected) {
            // Set the selected functions to the bot tools by filtering tools
            this.bot.tools = this.toolsList
                .filter((item) => {
                    return selected.includes(item.uuid)
                })
                .map((item) => item.uuid)
        },

        submitForm() {
            this.resetTabErrors()
            this.closeErrorNotification()

            if (this.validateForm('agent_settings') === true) {
                return true
            } else {
                this.error_notification = this.$notify({
                offset: 95,
                title: 'AloAi Agents',
                dangerouslyUseHTMLString: true,
                message: this.getFormErrorsMessage('agent_settings'),
                type: 'error',
                showClose: true,
                duration: 5000,
            })

            return false
        }
        },

        getFormErrorsMessage(form_name) {
            let form_element = _.get(this.$refs, form_name, null)
            let err_message = '<div class="mb-2">Please resolve AloAi agent configuration errors</div>'

            form_element.fields.forEach((element) => {
                if (element.validateState === 'error') {
                    // get the parent tab pane of the element
                    let parent = element.$parent

                    while (parent && parent.$options._componentTag !== 'el-tab-pane') {
                        parent = parent.$parent
                    }

                    const tab = parent.$attrs['data-tab-error'] || parent.name.replace('-', '_')
                    const tab_label = parent.$slots.label[0].children[0].text

                    // show the error icon on the element's parent tab pane
                    if (tab !== "") {
                        this.tab_errors[tab] = true
                    }

                    err_message += `<div class="mb-2"><i class="fa fa-remove text-danger mr-2"></i> ${tab_label} > ${element.validateMessage}</div>`
                }
            })

            return err_message
        },

        tabLabelErrorIcon(tab_error) {
            if (tab_error) {
                return ' <i class="fa fa-exclamation-circle text-danger ml-1"></i>'
            }

            return ''
        },

        resetTabErrors() {
            this.tab_errors = {
                configure: false,
                instructions: false,
                actions: false,
                deployment: false,
            }
        },

        closeErrorNotification() {
            if (this.error_notification) {
                this.error_notification.close()
            }
        },

        updateBotTTL() {
            this.bot.ttl = this.bot_ttl * 60 * 24
            this.preValidateForm('agent_settings')
        },

        updateBotType() {
            if (this.bot.type === AloAi.TYPE_VOICE) {
                this.bot.direction = AloAi.DIRECTION_INBOUND
                this.bot.voice_model = AloAi.VOICE_MODEL_GPT_4O_MINI_REALTIME_PREVIEW
                this.bot.voice = AloAi.VOICE_ALLOY
                this.bot.follow_up_enabled = false
            }
            this.fetchTools()
        },

        toggleAudio() {
            // Stop the current audio if it's playing
            if (this.isPlaying && this.audio) {
                this.audio.pause()
                this.audio.currentTime = 0
                this.isPlaying = false
                return
            }

            // Get the URL of the selected voice
            const selectedVoice = this.voices.find(
                (voice) => voice.value === this.bot.voice
            )

            if (selectedVoice && selectedVoice.url) {
                // Play the new audio
                this.audio = new Audio(selectedVoice.url)
                this.audio.play()
                this.audio.addEventListener('ended', this.stopAudio)
                this.isPlaying = true
            }
        },

        stopAudio() {
            if (this.audio) {
                this.audio.pause()
                this.audio.currentTime = 0
            }
            this.isPlaying = false
        },

        onUpdateFollowUpDelay(seconds) {
            this.bot.follow_up_delay = seconds
        },

        handleFollowUpFrequencyInput(value) {
            // The element UI component handles the input as a string, so we need to parse it to an integer
            this.bot.follow_up_frequency = parseInt(value) || 0
        },

        fetchTools() {
            axios
                .get(`/api/v1/aloai/tools?type=${this.bot.type ?? 1}`)
                .then((res) => {
                    this.toolsList = res.data.data
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
    mounted() {
        this.functions_list = this.bot.tools
    },
    watch: {
        bot: function (newVal) {
            this.fetchTools()

            if (newVal.ttl) {
                this.bot_ttl = newVal.ttl / 60 / 24
            }

            if (newVal.tools && newVal.tools.length) {
                this.functions_list = newVal.tools
            }
        },
        'bot.direction': function(newDir) {
            if (newDir !== AloAi.DIRECTION_OUTBOUND) {
                this.bot.default_outbound_campaign_id = null
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        this.closeErrorNotification()
        next()
    },
}
</script>

<style scoped>
.main-col {
    max-width: 700px;
    min-width: 400px;
}
</style>
