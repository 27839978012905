<template>
    <div class="app-body" id="view">
        <div v-if="shouldShowAloAis"
             class="container-listing">
            <div class="container-header d-flex">
                <h3 class="listing-heading">AloAi Text Bot</h3>
                <div class="ml-auto">
                    <div class="d-flex">
                        <el-button
                            type="success"
                            round
                            size="medium"
                            @click="$router.push({ name: 'AloAi: Create new chatbot'})">
                            <i class="fa fa-plus mr-1"></i>
                            <strong>New Chatbot</strong>
                        </el-button>
                    </div>
                </div>
            </div>

            <alo-ai-table
                :chatbots="chatbots"
                @chatbot-update="onChatbotUpdate"
                @chatbot-delete="onChatbotDelete"/>
        </div>
        <upgrade-now-page image-link="/assets/images/AloAi.svg"
                          text="The leading conversational AI supercharging your path to explosive sales growth"
                          extra-text="Enhance your team’s efficiency and lower operational expenses by automating conversations throughout the entire customer journey.Our AI bot replaces most BDRs and SDRs and will escalate automatically to humans."
                          title-text="AloAi Text Bot"
                          kb-link="https://aloware.com/ai-bot/"
                          custom-link="https://share.hsforms.com/1RLbs7DggS1i2Lhwld-nwlQ2qfyk"
                          class="mt-5"
                          v-if="!shouldShowAloAis && shouldShowUpgradeNow">
        </upgrade-now-page>
    </div>
</template>

<script>
import auth from '../../auth'
import AloAiTable from '../../components/aloai/aloai-table'
import {acl_mixin, html_mixin} from '../../mixins'
import {MessageBox} from 'element-ui'
import UpgradeNowPage from "../../components/upgrade-now-page"

export default {
    name: 'AloAiIndex',
    components: {
        UpgradeNowPage,
        AloAiTable,
    },
    mixins: [acl_mixin, html_mixin],
    data() {
        return {
            auth: auth,
            chatbots: [],
        }
    },
    beforeRouteEnter(to, from, next) {
        // This logic help us wrap the Chatbots section with the Aloware App components.
        if (to.query.api_token) {
            return next({
                name: 'Login',
                query: {
                    api_token: to.query.api_token
                }
            })
        }

        auth.check().then((res) => {
            if (res.data.user.is_reseller) {
                // redirect to account management portal if the company is a reseller
                next({name: 'Account Management Portal'})
            } else {
                next()
            }
        }).catch((err) => {
            next({
                name: 'Login',
                query: {
                    redirect: to.fullPath
                }
            })
        })
    },
    methods: {
        onChatbotUpdate(chatbot) {
            this.chatbots = this.chatbots.map((c) => {
                if (c.id === chatbot.id) {
                    return chatbot
                }

                return c
            })
        },
        onChatbotDelete(chatbotId) {
            MessageBox.confirm('Are you sure you want to delete this chatbot?', 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(async () => {
                axios.delete(`/api/v1/aloai/chat-bot/${chatbotId}`).then(res => {
                    this.chatbots = this.chatbots.filter((c) => {
                        return c.id !== chatbotId
                    })

                    this.$message({
                        message: 'Chatbot deleted successfully',
                        type: 'success'
                    });
                }).catch(err => {
                    console.log(err)
                })
            }).catch(() => {
                // do nothing
            });
        },
        fetchChatbots() {
            axios.get('/api/v1/aloai/chat-bot').then(res => {
                this.chatbots = res.data.data
            }).catch(err => {
                console.log(err)
            })
        },
    },
    mounted() {
        this.setPageTitle('AloAi Text Bot')
        this.fetchChatbots()

        if (!this.shouldShowAloAiMenu) {
            this.goBack()
        }
    },
    watch: {
        $route({params, query}) {
            if (!this.shouldShowAloAiMenu) {
                this.goBack()
            }
        }
    }
}
</script>
