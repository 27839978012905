var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container-account-imports app-body",
      attrs: { id: "view" },
    },
    [
      _c("div", { staticClass: "container-listing min-height-screen" }, [
        _c("div", { staticClass: "container-header" }, [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("h3", { staticClass: "listing-heading" }, [_vm._v("Lists")]),
              _vm._v(" "),
              _c(
                "el-dropdown",
                {
                  directives: [
                    {
                      name: "popover",
                      rawName: "v-popover:import-contacts-popover",
                      arg: "import-contacts-popover",
                    },
                  ],
                  ref: "importSelection",
                  staticClass: "ml-auto",
                  attrs: { size: "medium", trigger: "click" },
                  on: { command: _vm.importContact },
                  scopedSlots: _vm._u([
                    {
                      key: "dropdown",
                      fn: function () {
                        return [
                          _c(
                            "el-dropdown-menu",
                            {
                              staticClass: "account-import-selection-container",
                            },
                            [
                              _vm.current_company?.hubspot_integration_enabled
                                ? _c(
                                    "el-dropdown-item",
                                    {
                                      staticClass: "d-flex align-items-center",
                                      attrs: { command: "import_from_hubspot" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "icon-container hubspot-icon-container",
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fab fa-hubspot item-icon",
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "ml-2" }, [
                                        _vm._v("Import from HubSpot"),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.current_company
                                ?.salesforce_integration_enabled
                                ? _c(
                                    "el-dropdown-item",
                                    {
                                      staticClass: "d-flex align-items-center",
                                      attrs: {
                                        command: "import_from_salesforce",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "icon-container salesforce-icon-container",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fab fa-salesforce",
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "ml-2" }, [
                                        _vm._v("Import from Salesforce"),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-item",
                                {
                                  staticClass: "d-flex align-items-center",
                                  attrs: { command: "import_from_csv" },
                                },
                                [
                                  _c("img", {
                                    staticClass: "csv-icon",
                                    attrs: {
                                      src: "/assets/images/icons/csv-svg-icon-12.svg",
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v("Import CSV"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "import-selection-button",
                      attrs: { type: "primary" },
                    },
                    [
                      _vm._v("\n                        Create List "),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right ml-2",
                      }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isTrial && !_vm.isSimpSocial
                ? _c("video-modal", {
                    staticClass: "pl-2",
                    attrs: {
                      title: "🚀 Mastering List Management in Aloware",
                      "cookie-name": "lists",
                      notes:
                        "🔥 Unleash the power of <strong>Aloware Lists!</strong> </br></br> 📊 Organize, categorize, and skyrocket your contact management game. </br></br> Dive in and watch your efficiency soar! 💥📈",
                      "video-url":
                        "https://www.youtube.com/embed/aSdCAyV0bW4?si=EGKB-l66moF9q9HZ",
                      "learn-more-link":
                        "https://support.aloware.com/en/articles/9031374-user-s-guide-simplifying-contact-imports-for-admins-and-agents",
                      "activator-size": "small",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", { staticClass: "mb-1 text-grey-8 _600" }, [
            _vm._v(
              "\n                Create public lists that agents can access in " +
                _vm._s(_vm.applicationName) +
                " Talk.\n                "
            ),
            !_vm.isTrial && !_vm.isSimpSocial
              ? _c(
                  "a",
                  {
                    staticClass: "text-blue _600",
                    attrs: {
                      href: "https://support.aloware.com/en/articles/9034179-creating-contact-lists-for-users-in-aloware-talk",
                      target: "_blank",
                    },
                  },
                  [_vm._v("\n                    Learn more\n                ")]
                )
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container-body" }, [
          _c(
            "div",
            { staticClass: "container-listing-tools d-flex flex-wrap mb-1" },
            [
              _c("div", { staticClass: "d-flex flex-wrap mr-auto" }, [
                _c(
                  "div",
                  { staticClass: "d-flex mr-2 search-toolbar" },
                  [
                    _c(
                      "el-input",
                      {
                        ref: "contactListSearch",
                        attrs: {
                          id: "search-input",
                          placeholder: "Search contact lists...",
                          clearable: "",
                        },
                        on: { change: _vm.searchContactLists },
                        nativeOn: {
                          keyup: function ($event) {
                            $event.preventDefault()
                            return _vm.searchContactLists.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.filters.search,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "search", $$v)
                          },
                          expression: "filters.search",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-input__icon el-icon-search",
                          attrs: { slot: "prefix" },
                          slot: "prefix",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex mt-2 mt-xl-0 flex-wrap flex-lg-nowrap" },
                [
                  _c("div", { staticClass: "mr-2 align-self-center" }, [
                    _c("span", { staticClass: "text-grey" }, [
                      _vm._v("Total Contacts List:"),
                    ]),
                    _vm._v(" "),
                    _c("strong", [_vm._v(_vm._s(_vm.pagination.total))]),
                  ]),
                  _vm._v(" "),
                  _c("el-pagination", {
                    staticClass: "align-self-center",
                    attrs: {
                      layout: "prev, pager, next",
                      "current-page": _vm.filters.page,
                      "page-size": _vm.filters.size,
                      total: _vm.pagination.total,
                      "pager-count": 5,
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.filters, "page", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.filters, "page", $event)
                      },
                      "update:pageSize": function ($event) {
                        return _vm.$set(_vm.filters, "size", $event)
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(_vm.filters, "size", $event)
                      },
                      "size-change": function ($event) {
                        return _vm.fetchContactList(1)
                      },
                      "current-change": _vm.fetchContactList,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "table-responsive" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    fit: "",
                    stripe: "",
                    "data-testid": "lists-table",
                    data: _vm.pagination.data,
                    "default-sort": { prop: "created_at", order: "descending" },
                  },
                  on: { "sort-change": _vm.onSortChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "ID",
                      prop: "id",
                      width: "120",
                      sortable: "custom",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n                            " +
                                _vm._s(scope.row.id) +
                                "\n                        "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Name",
                      prop: "name",
                      sortable: "custom",
                      "min-width": 260,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.show_in_public_folder
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "p-0 text-blue",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openAdminList(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "i",
                                      {
                                        staticClass: "material-icons text-blue",
                                      },
                                      [_vm._v("launch")]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.name) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              : _c("span", { staticClass: "_400" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(scope.row.name) +
                                      "\n                            "
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Owner Name",
                      prop: "owner_name",
                      sortable: "custom",
                      "min-width": 180,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n                            " +
                                _vm._s(scope.row.owner_name) +
                                "\n                        "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Date Created",
                      prop: "created_at",
                      width: "225",
                      sortable: "custom",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm._f("fixDateTime")(scope.row.created_at)
                                ) +
                                "\n                        "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Type",
                      prop: "type",
                      width: "170",
                      sortable: "custom",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.getContactListType(scope.row)) +
                                "\n                        "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Show in Public Folder",
                      prop: "show_in_public_folder",
                      width: "210",
                      sortable: "custom",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-switch", {
                              attrs: {
                                "active-color": "#13ce66",
                                value: scope.row.show_in_public_folder,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleShowInPublicFolder(scope.row)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Source",
                      prop: "source_name",
                      width: "150",
                      sortable: "custom",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm._f("ucwords")(scope.row.source_name)
                                ) +
                                "\n                        "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Import Status",
                      prop: "import_status_name",
                      width: "150",
                      sortable: "custom",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                class: `import-status-${
                                  scope.row.import_status + 1
                                }`,
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm._f("ucwords")(
                                        scope.row.import_status_name
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Imported At",
                      prop: "imported_at",
                      width: "240",
                      sortable: "custom",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm._f("fixDateTime")(scope.row.imported_at)
                                ) +
                                "\n                        "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Operations",
                      width: "190",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.source === 3
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-block btn-info btn-sm",
                                    on: {
                                      click: function ($event) {
                                        return _vm.showPullHubspotListLogModal(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fas fa-info-circle pull-left",
                                    }),
                                    _vm._v(
                                      "\n                                View Logs\n                            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.shouldShowSplitOption(scope.row)
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-block btn-dark-warning text-white btn-sm",
                                    attrs: {
                                      disabled: _vm.isCheckingListCount,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showSplitListForm(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "pull-left",
                                      class: _vm.isCheckingListCount
                                        ? "fas fa-spinner fa-spin"
                                        : "fas fa-clone",
                                    }),
                                    _vm._v(
                                      "\n                                Split\n                            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-block btn-warning btn-sm",
                                on: {
                                  click: function ($event) {
                                    return _vm.showAssignContactForm(scope.row)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-sign-in-alt pull-left",
                                }),
                                _vm._v(
                                  "\n                                Assign Contacts\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.current_company?.automation_enabled
                              ? _c(
                                  "div",
                                  { staticClass: "my-2" },
                                  [
                                    _c(
                                      "workflow-bulk-enroller",
                                      {
                                        attrs: {
                                          model: "contact_list",
                                          id: scope.row.id,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Enroll Contacts\n                                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.current_company?.auto_dialer_enabled
                              ? _c("add-list-to-power-dialer", {
                                  attrs: { "contact-list": scope.row },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-block info text-white btn-sm",
                                on: {
                                  click: function ($event) {
                                    return _vm.stageToEditContactList(scope.row)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-pen pull-left",
                                }),
                                _vm._v(
                                  "\n                                Rename\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-block btn-danger btn-sm",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteContactList(scope.row)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-trash pull-left",
                                }),
                                _vm._v(
                                  "\n                                Delete\n                            "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.loading && _vm.pagination.data.length <= 0
                ? _c("div", { staticClass: "text-center mx-auto w-50 py-5" }, [
                    _c(
                      "div",
                      { staticClass: "w-75 mx-auto" },
                      [_c("img-empty")],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "lead" }, [
                      _vm._v(
                        "\n                        We can't seem to find contact list\n                        "
                      ),
                      _vm.filters.search.length > 0
                        ? _c("span", [
                            _vm._v(
                              'that matches "' +
                                _vm._s(_vm.filters.search) +
                                '"'
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container-footer d-flex mb-5" }, [
          _c(
            "div",
            { staticClass: "ml-auto" },
            [
              _c("el-pagination", {
                attrs: {
                  layout: "sizes, prev, pager, next",
                  "current-page": _vm.filters.page,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.filters.size,
                  "hide-on-single-page": true,
                  total: _vm.pagination.total,
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.filters, "page", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.filters, "page", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.filters, "size", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.filters, "size", $event)
                  },
                  "size-change": function ($event) {
                    return _vm.fetchContactList(1)
                  },
                  "current-change": _vm.fetchContactList,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "35vw",
            top: "10vh",
            "append-to-body": "",
            title: "Rename Contact List",
            "before-close": _vm.closeDialog,
            visible: _vm.show_dialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_dialog = $event
            },
          },
        },
        [
          _vm.selected_model
            ? _c(
                "el-form",
                { ref: "rename_form", attrs: { model: _vm.selected_model } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Name",
                        prop: "name",
                        error: _vm.errors.first("name"),
                        rules: {
                          required: true,
                          message: "Please specify the name.",
                          trigger: ["blur", "change"],
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder: "Static List One...",
                        },
                        model: {
                          value: _vm.selected_model.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.selected_model, "name", $$v)
                          },
                          expression: "selected_model.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "flex-grow-1",
                  attrs: { size: "small" },
                  on: { click: _vm.closeDialog },
                },
                [_vm._v("\n                Cancel\n            ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.isNameChanged,
                    size: "small",
                    type: "success",
                  },
                  on: { click: _vm.updateContactListName },
                },
                [_vm._v("\n                Save\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("import-wizard", {
        ref: "csv_import",
        attrs: { is_contact_list: true },
        on: { importStarted: _vm.handleImportStarted },
      }),
      _vm._v(" "),
      _c("admin-list-import-from-hubspot-list", {
        ref: "import_from_hubspot_list",
        attrs: { contact_list: _vm.selected_model },
        on: { importStarted: _vm.handleImportStarted },
      }),
      _vm._v(" "),
      _c("admin-list-import-from-salesforce-list", {
        ref: "import_from_salesforce_list",
        attrs: { contact_list: _vm.selected_model },
        on: { importStarted: _vm.handleImportStarted },
      }),
      _vm._v(" "),
      _c("admin-list-assign-contact-form", {
        ref: "assign_contact_form",
        attrs: { contact_list: _vm.selected_model },
        on: { closed: _vm.closeAssignmentForm },
      }),
      _vm._v(" "),
      _c("admin-split-list-form", {
        ref: "split_list_form",
        attrs: { contact_list: _vm.selected_model },
        on: { closed: _vm.closeSplitListForm, hidden: _vm.hideSplitListForm },
      }),
      _vm._v(" "),
      _c("pull-hubspot-list-logs", {
        ref: "pull_hubspot_list_logs",
        attrs: { contactList: _vm.selectedContactList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }