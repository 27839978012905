var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h5", { staticClass: "text-dark mb-1" }, [
        _vm._v("Communication Logs"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          !_vm.communications_count_loading
            ? _c("span", [
                _c("strong", [
                  _vm._v(_vm._s(_vm._f("numFormat")(_vm.communicationsCount))),
                ]),
                _vm._v(" Communications"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.current_company &&
          _vm.current_company.is_high_volume &&
          !_vm.loading
            ? [
                _c("br"),
                _vm._v(" "),
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n                Due to high volume, your metrics are not live. Please refresh the screen to see the latest updates.\n            "
                  ),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex my-2" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-grow-1" },
          [
            _c("el-input", {
              ref: "commLogTableSearch",
              staticClass: "width-500",
              attrs: {
                placeholder: "Press ENTER to search...",
                "suffix-icon": "el-icon-search",
                clearable: "",
                autofocus: "",
                "data-testid": "comm-log-table-input-search",
              },
              on: { change: _vm.searchCommsChange },
              model: {
                value: _vm.search_text,
                callback: function ($$v) {
                  _vm.search_text = $$v
                },
                expression: "search_text",
              },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn greenish pointer ml-2",
                staticStyle: { height: "40px" },
                attrs: { "data-testid": "comm-log-table-refresh-button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.getCommunications()
                  },
                },
              },
              [_c("i", { staticClass: "material-icons" }, [_vm._v("refresh")])]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex ml-auto" }, [
          _c("div", { staticClass: "dropdown dropdown-toggle-caret-none" }, [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-outline b-dark-greenish text-dark-greenish dropdown-toggle ml-2",
                attrs: {
                  "data-toggle": "dropdown",
                  "data-flip": "false",
                  "aria-expanded": "false",
                  "data-testid": "comm-log-table-settings-button",
                },
                on: { click: _vm.checkBlur },
              },
              [
                _vm._v("\n                    Table Settings "),
                _c("i", { staticClass: "el-icon-arrow-down" }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dropdown-menu dropdown-menu-right p-2" },
              [
                _c(
                  "div",
                  { staticClass: "filter-item" },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.noClose($event)
                          },
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "row-filter",
                            attrs: {
                              "data-testid": "comm-log-table-per-page-filter",
                            },
                            on: { change: _vm.changeRows },
                            model: {
                              value: _vm.filter.per_page,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "per_page", $$v)
                              },
                              expression: "filter.per_page",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 10 } }, [
                              _vm._v("10"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 20 } }, [
                              _vm._v("20"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 50 } }, [
                              _vm._v("50"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 100 } }, [
                              _vm._v("100"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 250 } }, [
                              _vm._v("250"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: {
                          "data-testid": "comm-log-table-fields-checkbox",
                        },
                        on: { change: _vm.changeTableFields },
                        model: {
                          value: _vm.checked_table_fields,
                          callback: function ($$v) {
                            _vm.checked_table_fields = $$v
                          },
                          expression: "checked_table_fields",
                        },
                      },
                      _vm._l(_vm.table_fields, function (field) {
                        return _c(
                          "li",
                          {
                            key: field.text,
                            on: {
                              click: function ($event) {
                                return _vm.noClose($event)
                              },
                            },
                          },
                          [
                            _c("el-checkbox", { attrs: { label: field.val } }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(field.text) +
                                  "\n                                "
                              ),
                            ]),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn blackish pointer ml-2",
              staticStyle: { height: "40px" },
              attrs: { "data-testid": "comm-log-table-graph-button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.toggleGraph.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-area-chart" }),
              _vm._v(
                " " +
                  _vm._s(_vm.show_graph ? "Hide" : "Show") +
                  " Graph\n            "
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "w-full mt-3",
          attrs: {
            stripe: "",
            "row-key": "id",
            "default-sort": { prop: "created_at", order: "descending" },
            data: _vm.communications,
            "expand-row-keys": _vm.expandableCommunicationsId,
            "data-testid": "comm-log-table",
          },
          on: { "cell-mouse-enter": _vm.cellEnter },
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand", "data-testid": "expand-column" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "row align-items-top" }, [
                      scope.row.contact
                        ? _c("div", { staticClass: "col-md-3" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  target: "_blank",
                                  href: _vm.getContactTalkRedirectURL(
                                    scope.row.contact.id
                                  ),
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item pull-left",
                                    attrs: {
                                      effect: "dark",
                                      content: "Click For More Info",
                                      "data-testid":
                                        "comm-log-tooltip-for-more-info",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "text-dark-greenish",
                                        attrs: {
                                          title: _vm._f("fixContactName")(
                                            scope.row.contact.name
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm._f("fixContactName")(
                                                scope.row.contact.name
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                scope.row.first_time_caller
                                  ? _c("i", {
                                      staticClass:
                                        "on b-white bottom caller-status pull-left",
                                      attrs: {
                                        title: "First Time Conversation",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            scope.row.type !== 5
                              ? _c("span", { staticClass: "text-greyish" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm._f("fixPhone")(
                                          scope.row.lead_number
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ])
                              : _c(
                                  "span",
                                  { staticClass: "text-greyish text-xs" },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(scope.row.lead_number) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                            _vm._v(" "),
                            scope.row.contact.disposition_status_id
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "mt-1",
                                    attrs: {
                                      "data-testid": "comm-log-tag-div",
                                    },
                                  },
                                  [
                                    scope.row.contact.disposition_status_id
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "text-greyish text-xs",
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass: "material-icons",
                                                style: {
                                                  color:
                                                    _vm.$options.filters.dispositionStatusColor(
                                                      scope.row.contact
                                                        .disposition_status_id
                                                    ),
                                                },
                                              },
                                              [_vm._v("label")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "text-grey-900" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f(
                                                      "dispositionStatusName"
                                                    )(
                                                      scope.row.contact
                                                        .disposition_status_id
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.contact.company_name
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "mt-1 text-pink-500 text-xs",
                                    attrs: {
                                      "data-testid": "business-center-div",
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "material-icons" }, [
                                      _vm._v("business_center"),
                                    ]),
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(scope.row.contact.company_name) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.contact.tags &&
                            scope.row.contact.tags.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "mt-1" },
                                  [
                                    _vm._l(
                                      _vm.$options.filters.lastTags(
                                        scope.row.contact.tags,
                                        3
                                      ),
                                      function (tag) {
                                        return _c(
                                          "div",
                                          {
                                            key: tag.id,
                                            staticClass: "text-xs",
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-circle",
                                              style: { color: tag.color },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "ml-1 text-grey-900",
                                              },
                                              [_vm._v(_vm._s(tag.name))]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    scope.row.contact.tags.length > 3
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center justify-content-center text-muted text-sm",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                ...\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.contact.cnam_city ||
                            scope.row.contact.cnam_state
                              ? _c("div", { staticClass: "mt-1" }, [
                                  scope.row.contact.cnam_city
                                    ? _c(
                                        "span",
                                        { staticClass: "text-greyish" },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                scope.row.contact.cnam_city
                                              ) +
                                              ",\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.contact.cnam_state
                                    ? _c(
                                        "span",
                                        { staticClass: "text-greyish" },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                scope.row.contact.cnam_state
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.contact
                        ? _c(
                            "div",
                            {
                              staticClass: "col-md-3",
                              class: [_vm.checkSize() ? "b-l" : "mt-2"],
                            },
                            [
                              scope.row.type === _vm.CommunicationTypes.CALL
                                ? _c("target-users-tree", {
                                    attrs: {
                                      "data-testid":
                                        "comm-log-target-users-tree",
                                      communication: scope.row,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.type !== _vm.CommunicationTypes.CALL &&
                      (scope.row.body || scope.row.attachments)
                        ? _c(
                            "div",
                            {
                              staticClass: "col-md-3",
                              class: [_vm.checkSize() ? "b-l" : "mt-2"],
                            },
                            [
                              scope.row.type === _vm.CommunicationTypes.FAX
                                ? _c(
                                    "strong",
                                    { staticClass: "text-greyish" },
                                    [
                                      _vm._v(
                                        "\n                            Files:\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.type === _vm.CommunicationTypes.FAX
                                ? _c("br")
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(
                                scope.row.attachments,
                                function (attachment, index) {
                                  return scope.row.attachments &&
                                    scope.row.attachments.length > 0
                                    ? _c(
                                        "div",
                                        { key: index },
                                        [
                                          (attachment.mime_type &&
                                            _vm.isAttachmentImage(
                                              attachment.mime_type
                                            )) ||
                                          !attachment.mime_type
                                            ? _c("vue-load-image", [
                                                _c("img", {
                                                  staticClass:
                                                    "img-fluid d-block r-2x",
                                                  class:
                                                    index > 0 ? "mb-1" : "",
                                                  staticStyle: {
                                                    "max-height": "150px",
                                                  },
                                                  attrs: {
                                                    slot: "image",
                                                    "data-testid":
                                                      "comm-log-attachment-image",
                                                    src: attachment.url,
                                                  },
                                                  slot: "image",
                                                }),
                                                _vm._v(" "),
                                                _c("img", {
                                                  staticStyle: {
                                                    "max-height": "150px",
                                                  },
                                                  attrs: {
                                                    slot: "preloader",
                                                    "data-testid":
                                                      "comm-log-loading-img",
                                                    src: "/assets/images/loading.svg",
                                                  },
                                                  slot: "preloader",
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "error" },
                                                    slot: "error",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Error on loading the image attachment"
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          attachment.mime_type
                                            ? [
                                                _vm.isAttachmentAudio(
                                                  attachment.mime_type
                                                )
                                                  ? _c("div", [
                                                      _c(
                                                        "audio",
                                                        {
                                                          staticClass:
                                                            "audio-player",
                                                          attrs: {
                                                            "data-testid":
                                                              "comm-log-attachment-audio",
                                                            controls: "",
                                                          },
                                                        },
                                                        [
                                                          _c("source", {
                                                            attrs: {
                                                              src: attachment.url,
                                                              type: attachment.mime_type,
                                                            },
                                                          }),
                                                          _vm._v(
                                                            "\n                                        Your browser does not support the audio element.\n                                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.isAttachmentVideo(
                                                  attachment.mime_type
                                                )
                                                  ? _c("div", [
                                                      _c(
                                                        "video",
                                                        {
                                                          staticClass:
                                                            "rounded",
                                                          attrs: {
                                                            width: "320",
                                                            "data-testid":
                                                              "comm-log-attachment-video",
                                                            controls: "",
                                                          },
                                                        },
                                                        [
                                                          _c("source", {
                                                            attrs: {
                                                              src: attachment.url,
                                                              type: attachment.mime_type,
                                                            },
                                                          }),
                                                          _vm._v(
                                                            "\n                                        Your browser does not support the video tag.\n                                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.isAttachmentText(
                                                  attachment.mime_type
                                                ) ||
                                                _vm.isAttachmentApplication(
                                                  attachment.mime_type
                                                )
                                                  ? _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: attachment.url,
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "p-2 text-center",
                                                          },
                                                          [
                                                            _c("figure", [
                                                              _c("img", {
                                                                attrs: {
                                                                  height: "32",
                                                                  width: "32",
                                                                  "data-testid":
                                                                    "comm-log-attachment-file",
                                                                  src: "/assets/images/app-icons/file.svg",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c("figcaption", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    attachment.name
                                                                      ? _vm.truncateFileName(
                                                                          attachment.name
                                                                        )
                                                                      : "Click Here To Download"
                                                                  )
                                                                ),
                                                              ]),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                }
                              ),
                              _vm._v(" "),
                              scope.row.body &&
                              scope.row.type !== _vm.CommunicationTypes.EMAIL
                                ? _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "text-greyish break-word",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(scope.row.body) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ])
                                : scope.row.body &&
                                  scope.row.type ===
                                    _vm.CommunicationTypes.EMAIL
                                ? _c("div", [
                                    _c("span", {
                                      staticClass: "text-greyish break-word",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$options.filters.nl2br(
                                            scope.row.body
                                          )
                                        ),
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.notes
                        ? _c(
                            "div",
                            {
                              staticClass: "col-md-3",
                              class: [_vm.checkSize() ? "b-l" : "mt-2"],
                            },
                            [
                              _c("strong", { staticClass: "text-greyish" }, [
                                _vm._v("Notes:"),
                              ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c("span", { staticClass: "text-greyish" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(scope.row.notes) +
                                    "\n                        "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.checkField("disposition_status")
            ? _c("el-table-column", {
                attrs: {
                  "data-testid": "disposition-column",
                  prop: "disposition_status",
                  width: "105",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                "data-testid": "comm-log-tooltip-disposition",
                                content: _vm.dispositionTooltipData(
                                  scope.row.disposition_status2,
                                  scope.row.direction,
                                  scope.row.type,
                                  scope.row.callback_status
                                ),
                                placement: "right",
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    "data-testid": "comm-log-link-disposition",
                                    to: {
                                      name: "Communication",
                                      params: {
                                        communication_id: scope.row.id,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c("div", {
                                    staticClass: "status-icon centered-content",
                                    attrs: {
                                      state: scope.row.disposition_status2,
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.stateToIcon(
                                          scope.row.disposition_status2,
                                          scope.row.direction,
                                          scope.row.type,
                                          scope.row.callback_status
                                        )
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "text-center" }, [
                                    scope.row.resolution2 ===
                                    _vm.CommunicationResolution
                                      .RESOLUTION_FORWARD_NEW
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "badge d-inline-flex align-items-center py-1",
                                            class: _vm.stateToIcon(
                                              scope.row.disposition_status2,
                                              scope.row.direction,
                                              scope.row.type,
                                              scope.row.callback_status,
                                              true
                                            ),
                                          },
                                          [
                                            _vm._v(
                                              "\n                                External FWD\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1737862134
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("campaign")
            ? _c("el-table-column", {
                attrs: {
                  "data-testid": "line-column",
                  label: "Line",
                  "min-width": 150,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.campaign_id
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "Line Activity",
                                      params: {
                                        campaign_id: scope.row.campaign_id,
                                      },
                                    },
                                    "data-testid": "comm-log-link-campaign",
                                  },
                                },
                                [
                                  _vm.getCampaign(scope.row.campaign_id)
                                    ? _c(
                                        "a",
                                        { staticClass: "text-dark-greenish" },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.getCampaign(
                                                  scope.row.campaign_id
                                                ).name
                                              ) +
                                              "\n                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3993323043
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("incoming_number")
            ? _c("el-table-column", {
                attrs: {
                  label: "Number",
                  prop: "incoming_number",
                  "data-testid": "incoming-number-column",
                  "min-width": 150,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 d-flex align-items-center justify-content-left",
                              },
                              [
                                scope.row.campaign_id
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          "data-testid":
                                            "comm-log-link-line-activity",
                                          to: {
                                            name: "Line Activity",
                                            params: {
                                              campaign_id:
                                                scope.row.campaign_id,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _vm.getCampaign(scope.row.campaign_id)
                                          ? _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "text-dark-greenish",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(
                                                      _vm.getCampaign(
                                                        scope.row.campaign_id
                                                      ).name
                                                    ) +
                                                    "\n                            "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            scope.row.type !== 5
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 d-flex align-items-center justify-content-left",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-greyish" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("fixPhone")(
                                              scope.row.incoming_number
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 d-flex align-items-center justify-content-left",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-greyish" },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.incoming_number)
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1014416986
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("team")
            ? _c("el-table-column", {
                attrs: {
                  label: "Team",
                  "data-testid": "team-column",
                  prop: "teams",
                  sortable: "",
                  "sort-method": _vm.sortTeams,
                  "min-width": 150,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.teams
                            ? _c(
                                "span",
                                { staticClass: "text-dark-greenish" },
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "mb-0" },
                                    _vm._l(
                                      scope.row.teams,
                                      function (teamName, index) {
                                        return _c("li", { key: index }, [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(teamName) +
                                              "\n                        "
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              )
                            : _c("span", { staticClass: "text-greyish" }, [
                                _vm._v(
                                  "\n                    -\n                "
                                ),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2515148022
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("ring_group")
            ? _c("el-table-column", {
                attrs: {
                  label: "Ring Group",
                  "data-testid": "ring-group-column",
                  "min-width": 150,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.ring_group_id &&
                          _vm.getRingGroup(scope.row.ring_group_id)
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    "data-testid": "comm-log-link-ring-group",
                                    to: {
                                      name: "Ring Group Dialog",
                                      params: {
                                        ring_group_id: scope.row.ring_group_id,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text-dark-greenish" },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.getRingGroup(
                                              scope.row.ring_group_id
                                            ).name
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _c("span", { staticClass: "text-greyish" }, [
                                _vm._v(
                                  "\n                    -\n                "
                                ),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3927146701
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("workflow")
            ? _c("el-table-column", {
                attrs: {
                  label: "Sequence",
                  "data-testid": "sequence-column",
                  "min-width": 150,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.workflow_id &&
                          _vm.getWorkflow(scope.row.workflow_id)
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    "data-testid":
                                      "comm-log-link-sequence-manager",
                                    to: {
                                      name: "Sequence Manager",
                                      params: {
                                        workflow_id: scope.row.workflow_id,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text-dark-greenish" },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.getWorkflow(
                                              scope.row.workflow_id
                                            ).name
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _c("span", { staticClass: "text-greyish" }, [
                                _vm._v(
                                  "\n                    -\n                "
                                ),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  743870916
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("broadcast")
            ? _c("el-table-column", {
                attrs: {
                  label: "Broadcast",
                  "data-testid": "broadcast-column",
                  "min-width": 150,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.broadcast_id &&
                          _vm.getBroadcast(scope.row.broadcast_id)
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    "data-testid":
                                      "comm-log-link-broadcast-activity",
                                    to: {
                                      name: "Broadcast Activity",
                                      params: {
                                        broadcast_id: scope.row.broadcast_id,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text-dark-greenish" },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.getBroadcast(
                                              scope.row.broadcast_id
                                            ).name
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _c("span", { staticClass: "text-greyish" }, [
                                _vm._v(
                                  "\n                    -\n                "
                                ),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  457644488
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("created_at")
            ? _c("el-table-column", {
                attrs: {
                  label: "Start Time",
                  prop: "created_at",
                  "data-testid": "start-time-column",
                  width: "162",
                  sortable: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", { staticClass: "text-greyish" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("fixDateTime")(scope.row.created_at)
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          scope.row.call_disposition_id
                            ? _c(
                                "div",
                                {
                                  staticClass: "row",
                                  attrs: { "data-testid": "start-time-row" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-12 d-flex align-items-center justify-content-left text-xs",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-bolt",
                                        style: {
                                          color:
                                            _vm.$options.filters.callDispositionColor(
                                              scope.row.call_disposition_id
                                            ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "ml-1 text-grey-900" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("callDispositionName")(
                                                scope.row.call_disposition_id
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.has_recording ||
                          scope.row.recording_is_deleted
                            ? _c(
                                "div",
                                {
                                  staticClass: "row mt-2",
                                  attrs: { "data-testid": "start-time-row" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-12" },
                                    [
                                      _c("communication-audio", {
                                        attrs: {
                                          communication: scope.row,
                                          "data-testid":
                                            "comm-log-communication-audio",
                                          type: _vm.UploadedFileTypes
                                            .TYPE_CALL_RECORDING,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.has_voicemail
                            ? _c(
                                "div",
                                {
                                  staticClass: "row mt-2",
                                  attrs: { "data-testid": "start-time-row" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-12" },
                                    [
                                      _c("communication-audio", {
                                        attrs: {
                                          communication: scope.row,
                                          "data-testid":
                                            "comm-log-communication-voicemail",
                                          type: _vm.UploadedFileTypes
                                            .TYPE_CALL_VOICEMAIL,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.has_transcription &&
                          !scope.row.transcription_is_deleted
                            ? _c("div", { staticClass: "row mt-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12" },
                                  [
                                    _c("communication-transcription-button", {
                                      attrs: {
                                        "data-testid":
                                          "comm-log-communication-transcription-button",
                                        communication: scope.row,
                                        button_text: "Show Transcription",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3369117553
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("talk_time")
            ? _c("el-table-column", {
                attrs: {
                  label: "Talk Time",
                  prop: "talk_time",
                  sortable: "",
                  "data-testid": "talk-time-column",
                  width: "120",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.type === _vm.CommunicationTypes.CALL
                            ? _c(
                                "div",
                                {
                                  staticClass: "row",
                                  attrs: { "data-testid": "talk-time-row" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-12 d-flex align-items-center justify-content-left",
                                    },
                                    [
                                      scope.row.disposition_status2 !==
                                        _vm.CommunicationDispositionStatus
                                          .DISPOSITION_STATUS_INPROGRESS_NEW &&
                                      scope.row.current_status2 ===
                                        _vm.CommunicationCurrentStatus
                                          .CURRENT_STATUS_COMPLETED_NEW
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("fixDuration")(
                                                  scope.row.talk_time
                                                )
                                              )
                                            ),
                                          ])
                                        : scope.row.disposition_status2 ===
                                            _vm.CommunicationDispositionStatus
                                              .DISPOSITION_STATUS_INPROGRESS_NEW &&
                                          scope.row.current_status2 ===
                                            _vm.CommunicationCurrentStatus
                                              .CURRENT_STATUS_INPROGRESS_NEW
                                        ? _c("relative-time", {
                                            staticClass: "text-muted",
                                            attrs: {
                                              "data-testid":
                                                "comm-log-relative-time",
                                              from_time: scope.row.created_at,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 d-flex align-items-center justify-content-left",
                              },
                              [
                                scope.row.direction ===
                                  _vm.CommunicationDirection.INBOUND &&
                                scope.row.disposition_status2 ===
                                  _vm.CommunicationDispositionStatus
                                    .DISPOSITION_STATUS_ABANDONED_NEW &&
                                [
                                  _vm.CallbackStatus.CALLBACK_STATUS_INITIATED,
                                  _vm.CallbackStatus.CALLBACK_STATUS_REQUESTED,
                                ].includes(scope.row.callback_status)
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                            Callback " +
                                          _vm._s(
                                            _vm.getVisibleCallbackStatus(
                                              scope.row
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.getVisibleStatus(scope.row))
                                      ),
                                    ]),
                              ]
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1715117678
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("wait_time")
            ? _c("el-table-column", {
                attrs: {
                  label: "Wait Time",
                  prop: "wait_time",
                  "data-testid": "wait-time-column",
                  sortable: "",
                  width: "120",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 d-flex align-items-center justify-content-left",
                                attrs: { "data-testid": "wait-time-div" },
                              },
                              [
                                scope.row.type === _vm.CommunicationTypes.CALL
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("fixDuration")(
                                            scope.row.wait_time
                                          )
                                        )
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "\n                            -\n                        "
                                      ),
                                    ]),
                              ]
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2958463648
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("hold_time")
            ? _c("el-table-column", {
                attrs: {
                  label: "Hold Time",
                  prop: "hold_time",
                  "data-testid": "hold-time-column",
                  sortable: "",
                  width: "120",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              attrs: { "data-testid": "hold-time-row" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-12 d-flex align-items-center justify-content-left",
                                  attrs: { "data-testid": "hold-time-div" },
                                },
                                [
                                  scope.row.type === _vm.CommunicationTypes.CALL
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("fixDuration")(
                                              scope.row.hold_time
                                            )
                                          )
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          "\n                            -\n                        "
                                        ),
                                      ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  970613116
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("duration")
            ? _c("el-table-column", {
                attrs: {
                  label: "Duration",
                  prop: "duration",
                  "data-testid": "duration-column",
                  sortable: "",
                  width: "110",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.type === _vm.CommunicationTypes.CALL
                            ? _c(
                                "div",
                                {
                                  staticClass: "row",
                                  attrs: { "data-testid": "duration-div" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-12 d-flex align-items-center justify-content-left",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("fixDuration")(
                                              scope.row.duration
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 d-flex align-items-center justify-content-left",
                                attrs: { "data-testid": "status-div" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.getVisibleStatus(scope.row))
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2943963722
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("resolution2")
            ? _c("el-table-column", {
                attrs: {
                  label: "Resolution",
                  prop: "resolution2",
                  "data-testid": "resolution-column",
                  sortable: "",
                  width: "120",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              attrs: { "data-testid": "resolution-row" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-12 d-flex align-items-center justify-content-left",
                                  attrs: { "data-testid": "resolution-div" },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getVisibleResolution(scope.row)
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2179577384
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("queue_resolution2")
            ? _c("el-table-column", {
                attrs: {
                  label: "Queue Resolution",
                  prop: "queue_resolution2",
                  "data-testid": "queue-resolution-column",
                  sortable: "",
                  width: "120",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              attrs: { "data-testid": "queue-resolution-row" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-12 d-flex align-items-center justify-content-left",
                                  attrs: { "data-testid": "queue-resolution" },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getVisibleQueueResolution(scope.row)
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1661873583
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("lead_number")
            ? _c("el-table-column", {
                attrs: {
                  label: "Contact",
                  prop: "lead_number",
                  "data-testid": "contact-column",
                  "min-width": 300,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "row d-flex align-items-center",
                              attrs: { "data-testid": "contact-div" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: [
                                    scope.row.contact &&
                                    scope.row.contact.tags &&
                                    scope.row.contact.tags.length > 0
                                      ? "col-6"
                                      : "col-12",
                                  ],
                                },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    scope.row.contact
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 d-flex align-items-center justify-content-left",
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  target: "_blank",
                                                  href: _vm.getContactTalkRedirectURL(
                                                    scope.row.contact.id
                                                  ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass:
                                                      "item pull-left",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        "Click For More Info",
                                                      "data-testid":
                                                        "comm-log-tooltip-contact",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-dark-greenish",
                                                        attrs: {
                                                          title: _vm._f(
                                                            "fixContactName"
                                                          )(
                                                            scope.row.contact
                                                              .name
                                                          ),
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "fixContactName"
                                                              )(
                                                                scope.row
                                                                  .contact.name
                                                              )
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                scope.row.first_time_caller
                                                  ? _c("i", {
                                                      staticClass:
                                                        "on b-white bottom caller-status pull-left",
                                                      attrs: {
                                                        "data-testid":
                                                          "first-time-conv-i",
                                                        title:
                                                          "First Time Conversation",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 d-flex align-items-center justify-content-left",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-dark-greenish pull-left",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("fixPhone")(
                                                      scope.row.lead_number
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            scope.row.first_time_caller
                                              ? _c("i", {
                                                  staticClass:
                                                    "on b-white bottom caller-status pull-left",
                                                  attrs: {
                                                    "data-testid":
                                                      "first-time-conv-i",
                                                    title:
                                                      "First Time Conversation",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-12 d-flex align-items-center justify-content-left",
                                        attrs: { "data-testid": "lead-number" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-greyish" },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm._f("fixPhone")(
                                                    scope.row.lead_number
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  scope.row.contact &&
                                  scope.row.contact.disposition_status_id
                                    ? _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 d-flex align-items-center justify-content-left text-xs",
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass: "material-icons",
                                                style: {
                                                  color:
                                                    _vm.$options.filters.dispositionStatusColor(
                                                      scope.row.contact
                                                        .disposition_status_id
                                                    ),
                                                },
                                                attrs: {
                                                  "data-test":
                                                    "disposition-status-icon",
                                                },
                                              },
                                              [_vm._v("label")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "ml-1 text-grey-900",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f(
                                                      "dispositionStatusName"
                                                    )(
                                                      scope.row.contact
                                                        .disposition_status_id
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.contact &&
                                  scope.row.contact.company_name
                                    ? _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 d-flex align-items-center justify-content-left text-pink-500 text-xs",
                                            attrs: {
                                              "data-testid":
                                                "company-name-icon",
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              { staticClass: "material-icons" },
                                              [_vm._v("business_center")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "ml-1" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.contact
                                                      .company_name
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              scope.row.contact &&
                              scope.row.contact.tags &&
                              scope.row.contact.tags.length
                                ? _c(
                                    "div",
                                    { staticClass: "col-6" },
                                    [
                                      _vm._l(
                                        _vm.$options.filters.lastTags(
                                          scope.row.contact.tags,
                                          3
                                        ),
                                        function (tag) {
                                          return _c(
                                            "div",
                                            {
                                              key: tag.id,
                                              staticClass:
                                                "d-flex align-items-center justify-content-left text-xs",
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-circle",
                                                style: { color: tag.color },
                                                attrs: {
                                                  "data-testid": "tag-circle",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ml-1 text-grey-900",
                                                },
                                                [_vm._v(_vm._s(tag.name))]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      scope.row.contact.tags.length > 3
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center justify-content-center text-muted text-sm",
                                            },
                                            [
                                              _vm._v(
                                                "\n                            ...\n                        "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2610259934
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("email")
            ? _c("el-table-column", {
                attrs: {
                  "data-testid": "email-column",
                  align: "center",
                  label: "Email",
                  "min-width": 120,
                  prop: "contact.email",
                  sortable: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            { attrs: { "data-testid": "email-span" } },
                            [
                              _vm._v(
                                _vm._s(
                                  scope.row.contact
                                    ? scope.row.contact.email
                                    : ""
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  36121702
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("lead_location")
            ? _c("el-table-column", {
                attrs: {
                  label: "Location",
                  prop: "lead_location",
                  "data-testid": "location-column",
                  sortable: "",
                  width: "140",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              attrs: { "data-testid": "location-column-row" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-12 d-flex align-items-center justify-content-left",
                                  attrs: { "data-testid": "location-div" },
                                },
                                [
                                  scope.row.state
                                    ? _c(
                                        "span",
                                        { staticClass: "text-greyish" },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(scope.row.city || "") +
                                              _vm._s(
                                                scope.row.city &&
                                                  scope.row.state
                                                  ? ", "
                                                  : ""
                                              ) +
                                              _vm._s(scope.row.state) +
                                              "\n                        "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "text-greyish" },
                                        [
                                          _vm._v(
                                            "\n                            -\n                        "
                                          ),
                                        ]
                                      ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1268503503
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("user")
            ? _c("el-table-column", {
                attrs: {
                  label: "User",
                  prop: "user",
                  "data-testid": "users-column",
                  sortable: "",
                  "min-width": 150,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 d-flex align-items-center justify-content-left",
                              },
                              [
                                scope.row.rejected_by_app != 0
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          "popper-class": "rejection-tooltip",
                                          "data-testid": "rejected-by-app",
                                          content: _vm.rejectionTooltipData(
                                            scope.row.rejected_by_app,
                                            scope.row.type
                                          ),
                                          placement: "right",
                                        },
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "Communication",
                                                params: {
                                                  communication_id:
                                                    scope.row.id,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "status-icon d-inline-block",
                                              attrs: {
                                                state:
                                                  scope.row.rejected_by_app,
                                              },
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.rejectionToIcon(
                                                    scope.row.rejected_by_app
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : scope.row.user_id &&
                                    _vm.getUser(scope.row.user_id)
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              "data-testid":
                                                "users-router-link",
                                              to: {
                                                name: "User Activity",
                                                params: {
                                                  user_id: scope.row.user_id,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-dark-greenish",
                                                attrs: {
                                                  title: _vm.getUserName(
                                                    _vm.getUser(
                                                      scope.row.user_id
                                                    )
                                                  ),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm.getUserName(
                                                        _vm.getUser(
                                                          scope.row.user_id
                                                        )
                                                      )
                                                    ) +
                                                    "\n                                "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "text-greyish" },
                                        [
                                          _vm._v(
                                            "\n                                -\n                            "
                                          ),
                                        ]
                                      ),
                                    ]),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3296602414
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("attempting_users")
            ? _c("el-table-column", {
                attrs: {
                  label: "Attempting",
                  prop: "attempting_users",
                  "data-testid": "attempting-users-column",
                  sortable: "",
                  "min-width": 150,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              attrs: {
                                "data-test": "attempting-users-column-row",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-12 d-flex align-items-center justify-content-left",
                                },
                                [
                                  scope.row.attempting_users &&
                                  scope.row.attempting_users.length > 0
                                    ? _c(
                                        "span",
                                        { staticClass: "text-greyish" },
                                        [
                                          _c(
                                            "ul",
                                            {
                                              staticClass:
                                                "list list-unstyled inset mb-0",
                                              attrs: {
                                                "data-test":
                                                  "attempting-users-ul",
                                              },
                                            },
                                            [
                                              !_vm.show_more_list.includes(
                                                scope.row.id
                                              )
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      scope.row.attempting_users.slice(
                                                        0,
                                                        3
                                                      ),
                                                      function (
                                                        attempting_user
                                                      ) {
                                                        return _vm.getUser(
                                                          attempting_user
                                                        ) &&
                                                          _vm.getUser(
                                                            attempting_user
                                                          ).id
                                                          ? _c(
                                                              "li",
                                                              {
                                                                key: attempting_user,
                                                                staticClass:
                                                                  "pb-1",
                                                                attrs: {
                                                                  "data-test":
                                                                    "attempting-users-li",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "router-link",
                                                                  {
                                                                    attrs: {
                                                                      "data-test":
                                                                        "attempting-users-router-link",
                                                                      to: {
                                                                        name: "User Activity",
                                                                        params:
                                                                          {
                                                                            user_id:
                                                                              _vm.getUser(
                                                                                attempting_user
                                                                              )
                                                                                .id,
                                                                          },
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        class:
                                                                          _vm.getAttemptingClass(
                                                                            attempting_user,
                                                                            scope
                                                                              .row
                                                                              .disposition_status2,
                                                                            scope
                                                                              .row
                                                                              .user_id
                                                                          ),
                                                                        attrs: {
                                                                          title:
                                                                            _vm.getUserName(
                                                                              _vm.getUser(
                                                                                attempting_user
                                                                              )
                                                                            ),
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                " +
                                                                            _vm._s(
                                                                              _vm.getUserName(
                                                                                _vm.getUser(
                                                                                  attempting_user
                                                                                )
                                                                              )
                                                                            ) +
                                                                            "\n                                            "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _c(
                                                    "div",
                                                    _vm._l(
                                                      scope.row
                                                        .attempting_users,
                                                      function (
                                                        attempting_user
                                                      ) {
                                                        return _vm.getUser(
                                                          attempting_user
                                                        ) &&
                                                          _vm.getUser(
                                                            attempting_user
                                                          ).id
                                                          ? _c(
                                                              "li",
                                                              {
                                                                key: attempting_user,
                                                                staticClass:
                                                                  "pb-1",
                                                              },
                                                              [
                                                                _c(
                                                                  "router-link",
                                                                  {
                                                                    attrs: {
                                                                      "data-test":
                                                                        "attempting-users-router-link",
                                                                      to: {
                                                                        name: "User Activity",
                                                                        params:
                                                                          {
                                                                            user_id:
                                                                              _vm.getUser(
                                                                                attempting_user
                                                                              )
                                                                                .id,
                                                                          },
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        class:
                                                                          _vm.getAttemptingClass(
                                                                            attempting_user,
                                                                            scope
                                                                              .row
                                                                              .disposition_status2,
                                                                            scope
                                                                              .row
                                                                              .user_id
                                                                          ),
                                                                        attrs: {
                                                                          title:
                                                                            _vm.getUserName(
                                                                              _vm.getUser(
                                                                                attempting_user
                                                                              )
                                                                            ),
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                " +
                                                                            _vm._s(
                                                                              _vm.getUserName(
                                                                                _vm.getUser(
                                                                                  attempting_user
                                                                                )
                                                                              )
                                                                            ) +
                                                                            "\n                                            "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      }
                                                    ),
                                                    0
                                                  ),
                                              _vm._v(" "),
                                              scope.row.attempting_users
                                                .length > 3
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "d-flex btn align-items-center justify-content-center text-muted text-sm",
                                                      attrs: {
                                                        "data-test":
                                                          "attempting-users-a",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.showMore(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                    " +
                                                          _vm._s(
                                                            !_vm.show_more_list.includes(
                                                              scope.row.id
                                                            )
                                                              ? "more ..."
                                                              : "less"
                                                          ) +
                                                          "\n                                "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "text-greyish" },
                                        [
                                          _vm._v(
                                            "\n                            -\n                        "
                                          ),
                                        ]
                                      ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  561124601
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("in_cold_transfer")
            ? _c("el-table-column", {
                attrs: {
                  label: "Cold Transferred?",
                  prop: "in_cold_transfer",
                  "data-testid": "in-cold-transfer-column",
                  sortable: "",
                  "min-width": 180,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              attrs: {
                                "data-testid": "in-cold-transfer-column-div",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-12 d-flex align-items-center justify-content-center",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text-dark-greenish _400" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.in_cold_transfer
                                            ? "Yes"
                                            : "No"
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3730927071
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("transfer_prior_user_ids")
            ? _c("el-table-column", {
                attrs: {
                  label: "Transferred From",
                  prop: "transfer_prior_user_ids",
                  "data-testid": "transfer-prior-user-ids-column",
                  sortable: "",
                  "min-width": 180,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              attrs: {
                                "data-testid":
                                  "transfer-prior-user-ids-column-row",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-12 d-flex align-items-center justify-content-left",
                                },
                                [
                                  scope.row.transfer_prior_user_ids &&
                                  scope.row.transfer_prior_user_ids.length > 0
                                    ? _c(
                                        "span",
                                        { staticClass: "text-greyish" },
                                        [
                                          _c(
                                            "ul",
                                            {
                                              staticClass:
                                                "list list-unstyled inset mb-0",
                                              attrs: {
                                                "data-testid":
                                                  "transfer-prior-user-ids-column-ul",
                                              },
                                            },
                                            _vm._l(
                                              scope.row.transfer_prior_user_ids,
                                              function (
                                                transfer_prior_user_id
                                              ) {
                                                return _vm.getUser(
                                                  transfer_prior_user_id
                                                ) &&
                                                  _vm.getUser(
                                                    transfer_prior_user_id
                                                  ).id
                                                  ? _c(
                                                      "li",
                                                      {
                                                        key: transfer_prior_user_id,
                                                        staticClass: "pb-1",
                                                        attrs: {
                                                          "data-testid":
                                                            "transfer-prior-user-ids-column-li",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "router-link",
                                                          {
                                                            attrs: {
                                                              to: {
                                                                name: "User Activity",
                                                                params: {
                                                                  user_id:
                                                                    _vm.getUser(
                                                                      transfer_prior_user_id
                                                                    ).id,
                                                                },
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                class:
                                                                  _vm.getAttemptingClass(
                                                                    transfer_prior_user_id,
                                                                    scope.row
                                                                      .disposition_status2,
                                                                    scope.row
                                                                      .user_id
                                                                  ),
                                                                attrs: {
                                                                  title:
                                                                    _vm.getUserName(
                                                                      _vm.getUser(
                                                                        transfer_prior_user_id
                                                                      )
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                            " +
                                                                    _vm._s(
                                                                      _vm.getUserName(
                                                                        _vm.getUser(
                                                                          transfer_prior_user_id
                                                                        )
                                                                      )
                                                                    ) +
                                                                    "\n                                        "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "text-greyish" },
                                        [
                                          _vm._v(
                                            "\n                            -\n                        "
                                          ),
                                        ]
                                      ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2221904709
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("transfer_target_user_ids")
            ? _c("el-table-column", {
                attrs: {
                  label: "Transferred To",
                  prop: "transfer_target_user_ids",
                  "data-testid": "transfer-target-user-ids-column",
                  sortable: "",
                  "min-width": 180,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              attrs: {
                                "data-testid":
                                  "transfer-target-user-ids-column-row",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-12 d-flex align-items-center justify-content-left",
                                },
                                [
                                  scope.row.transfer_target_user_ids &&
                                  scope.row.transfer_target_user_ids.length > 0
                                    ? _c(
                                        "span",
                                        { staticClass: "text-greyish" },
                                        [
                                          _c(
                                            "ul",
                                            {
                                              staticClass:
                                                "list list-unstyled inset mb-0",
                                              attrs: {
                                                "data-testid":
                                                  "transfer-target-user-ids-ul",
                                              },
                                            },
                                            _vm._l(
                                              scope.row
                                                .transfer_target_user_ids,
                                              function (
                                                transfer_target_user_id
                                              ) {
                                                return _vm.getUser(
                                                  transfer_target_user_id
                                                ) &&
                                                  _vm.getUser(
                                                    transfer_target_user_id
                                                  ).id
                                                  ? _c(
                                                      "li",
                                                      {
                                                        key: transfer_target_user_id,
                                                        staticClass: "pb-1",
                                                        attrs: {
                                                          "data-testid":
                                                            "transfer-target-user-ids-li",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "router-link",
                                                          {
                                                            attrs: {
                                                              "data-testid":
                                                                "transfer-target-user-ids-router-link",
                                                              to: {
                                                                name: "User Activity",
                                                                params: {
                                                                  user_id:
                                                                    _vm.getUser(
                                                                      transfer_target_user_id
                                                                    ).id,
                                                                },
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                class:
                                                                  _vm.getAttemptingClass(
                                                                    transfer_target_user_id,
                                                                    scope.row
                                                                      .disposition_status2,
                                                                    scope.row
                                                                      .user_id
                                                                  ),
                                                                attrs: {
                                                                  title:
                                                                    _vm.getUserName(
                                                                      _vm.getUser(
                                                                        transfer_target_user_id
                                                                      )
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                            " +
                                                                    _vm._s(
                                                                      _vm.getUserName(
                                                                        _vm.getUser(
                                                                          transfer_target_user_id
                                                                        )
                                                                      )
                                                                    ) +
                                                                    "\n                                        "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "text-greyish" },
                                        [
                                          _vm._v(
                                            "\n                            -\n                        "
                                          ),
                                        ]
                                      ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  198752998
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("transfer_type")
            ? _c("el-table-column", {
                attrs: {
                  label: "Transfer Type",
                  prop: "transfer_type",
                  "data-testid": "transfer-type-column",
                  width: "150",
                  sortable: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.transfer_type
                            ? _c(
                                "span",
                                {
                                  staticClass: "text-greyish",
                                  attrs: {
                                    "data-testid": "transfer-type-span",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm._f("translateTransferTypeText")(
                                          scope.row.transfer_type
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              )
                            : _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "text-greyish",
                                    attrs: {
                                      "data-testid": "transfer-type-span",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      -\n                    "
                                    ),
                                  ]
                                ),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3948338625
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("callback_status")
            ? _c("el-table-column", {
                attrs: {
                  label: "Callback Status",
                  prop: "callback_status",
                  "data-testid": "callback-status-column",
                  width: "150",
                  sortable: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.callback_status
                            ? _c(
                                "span",
                                {
                                  staticClass: "text-greyish",
                                  attrs: {
                                    "data-testid": "callback-status-span",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm._f("translateCallbackStatusText")(
                                          scope.row.callback_status
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              )
                            : _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "text-greyish",
                                    attrs: {
                                      "data-testid": "callback-status-span",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      -\n                    "
                                    ),
                                  ]
                                ),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3139354319
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("creator_type")
            ? _c("el-table-column", {
                attrs: {
                  label: "Creator Type",
                  prop: "creator_type",
                  "data-testid": "creator-type-column",
                  width: "150",
                  sortable: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "text-greyish",
                              attrs: { "data-testid": "creator-type-span" },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm._f("translateCreatorTypeText")(
                                      scope.row.creator_type
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1622760402
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("tags")
            ? _c("el-table-column", {
                attrs: {
                  label: "Tags",
                  "data-testid": "tags-column",
                  "min-width": 140,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("communication-tags", {
                            attrs: { communication: scope.row },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1817138699
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("notes")
            ? _c("el-table-column", {
                attrs: { align: "center", label: "Notes", "min-width": 70 },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("communication-note", {
                            attrs: {
                              "data-testid": "communication-note-component",
                              communication: scope.row,
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3451917205
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("csat_score")
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  "data-testid": "csat-score-column",
                  label: "CSAT Score",
                  "min-width": 140,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-rate", {
                            attrs: {
                              "data-testid": "el-rate-component",
                              disabled: "",
                            },
                            model: {
                              value: scope.row.csat_score,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "csat_score", $$v)
                              },
                              expression: "scope.row.csat_score",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  184971009
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("operations")
            ? _c("el-table-column", {
                attrs: {
                  align: "left",
                  label: "Operations",
                  "data-testid": "operation-column",
                  width: "250",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.contact &&
                          scope.row.type == 2 &&
                          _vm.hasPermissionTo("send sms")
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "Reply",
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn btn-sm blue btn-icon opaq",
                                      attrs: {
                                        target: "_blank",
                                        href: _vm.getContactTalkRedirectURL(
                                          scope.row.contact.id
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("reply")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.hasReporterAccess &&
                          scope.row.contact &&
                          scope.row.type !== 5
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "Call Back",
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm dark-greenish btn-icon opaq",
                                      attrs: { "data-test": "call-back-btn" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.callContact(
                                            scope.row.contact,
                                            scope.row.lead_number
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("call")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "More Details",
                                placement: "bottom",
                              },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-sm greyish btn-icon opaq",
                                  attrs: {
                                    "data-testid":
                                      "go-to-communication-info-btn",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoCommunicationInfo(
                                        scope.row.id
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("info_outline"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.hasPermissionTo("archive communication")
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "Archive",
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm deep-orange btn-icon opaq",
                                      attrs: {
                                        loading: _vm.archive_loading,
                                        disabled: _vm.archive_loading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.archiveCommunication(
                                            scope.row.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("archive")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasRole("Company Admin") &&
                          scope.row.type == _vm.CommunicationTypes.CALL &&
                          scope.row.disposition_status2 ==
                            _vm.CommunicationDispositionStatus
                              .DISPOSITION_STATUS_INPROGRESS_NEW
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "Terminate",
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm danger btn-icon opaq",
                                      attrs: {
                                        loading: _vm.terminate_loading,
                                        disabled: _vm.terminate_loading,
                                        "data-testid":
                                          "terminate-communication-btn",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.terminateCommunication(
                                            scope.row.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("power_settings_new")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.userCanBargeAndWhisper(scope.row)
                            ? [
                                _vm.isBlockedFrom("barge & whisper")
                                  ? [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            content:
                                              "The Barge option is not included in your current plan, to use it, you have to upgrade to one of our plans that offers it!",
                                            effect:
                                              "power-dialer-start-button-tooltip",
                                            "data-testid": "barge-tooltip",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm deep-purple btn-icon opaq",
                                              attrs: {
                                                "data-testid": "barge-btn",
                                                disabled: true,
                                              },
                                            },
                                            [
                                              _c(
                                                "i",
                                                {
                                                  staticClass: "material-icons",
                                                },
                                                [_vm._v("volume_up")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            content:
                                              "The Whisper option is not included in your current plan, to use it, you have to upgrade to one of our plans that offers it!",
                                            effect:
                                              "power-dialer-start-button-tooltip",
                                            "data-testid": "whisper-tooltip",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm lime btn-icon opaq",
                                              attrs: {
                                                "data-testid": "whisper-btn",
                                                disabled: true,
                                              },
                                            },
                                            [
                                              _c(
                                                "i",
                                                {
                                                  staticClass: "material-icons",
                                                },
                                                [_vm._v("hearing")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  : [
                                      _vm.userCanBargeAndWhisper(scope.row)
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content: "Barge",
                                                placement: "bottom",
                                                "data-testid": "barge-tooltip",
                                              },
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-sm deep-purple btn-icon opaq",
                                                  attrs: {
                                                    loading: _vm.barge_loading,
                                                    disabled: _vm.barge_loading,
                                                    "data-testid": "barge-btn",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.bargeCommunicationDialog(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticClass:
                                                        "material-icons",
                                                    },
                                                    [_vm._v("volume_up")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.userCanBargeAndWhisper(scope.row)
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content: "Whisper",
                                                placement: "bottom",
                                                "data-testid":
                                                  "whisper-tooltip",
                                              },
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-sm lime btn-icon opaq",
                                                  attrs: {
                                                    loading:
                                                      _vm.whisper_loading,
                                                    disabled:
                                                      _vm.whisper_loading,
                                                    "data-testid":
                                                      "whisper-btn",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.whisperCommunicationDialog(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticClass:
                                                        "material-icons",
                                                    },
                                                    [_vm._v("hearing")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1881757525
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "template",
            { staticClass: "text-center text-muted", slot: "empty" },
            [
              !_vm.loading
                ? _c("span", { attrs: { "data-testid": "no-data" } }, [
                    _vm._v("\n                  No Data\n            "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.loading
                ? _c("span", { attrs: { "data-testid": "loading" } })
                : _vm._e(),
            ]
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-3" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "el-button-group",
              { staticClass: "pull-right" },
              [
                !_vm.pagination_pages ||
                !_vm.pagination_pages.prev_page_url ||
                _vm.pagination_loading ||
                _vm.loading
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          size: "small",
                          icon: "el-icon-arrow-left",
                          disabled: "",
                          "data-testid": "prev-page-btn",
                          plain: "",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Previous Page\n                "
                        ),
                      ]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: {
                          type: "success",
                          size: "small",
                          icon: "el-icon-arrow-left",
                          "data-testid": "prev-page-btn",
                        },
                        on: { click: _vm.previousPage },
                      },
                      [
                        _vm._v(
                          "\n                    Previous Page\n                "
                        ),
                      ]
                    ),
                _vm._v(" "),
                !_vm.pagination_pages ||
                !_vm.pagination_pages.next_page_url ||
                _vm.pagination_loading ||
                _vm.loading
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          size: "small",
                          disabled: "",
                          "data-testid": "next-page-btn",
                          plain: "",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Next Page\n                    "
                        ),
                        _c("i", { staticClass: "el-icon-arrow-right" }),
                      ]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: {
                          type: "success",
                          size: "small",
                          "data-testid": "next-page-btn",
                        },
                        on: { click: _vm.nextPage },
                      },
                      [
                        _vm._v(
                          "\n                    Next Page\n                    "
                        ),
                        _c("i", { staticClass: "el-icon-arrow-right" }),
                      ]
                    ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [_c("b", [_vm._v("Rows:")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [_c("b", [_vm._v("Columns:")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }