import _ from 'lodash'
import auth from './auth'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {CrmList} from './constants/crm-list'
import * as MissedCallBehaviors from './constants/missed-call-behavior'
import * as Roles from './constants/roles'
import * as AnswerTypes from './constants/answer-types'
import * as CommunicationCurrentStatus from './constants/communication-current-status'
import * as CommunicationDispositionStatus from './constants/communication-disposition-status'
import * as CallbackStatus from './constants/callback-status'
import * as SequenceTypes from './constants/sequence-types'
import * as CommunicationTypes from './constants/communication-types'
import * as CommunicationAccessTypes from './constants/communication-access-types'
import * as CommunicationDirection from './constants/communication-direction'
import * as CommunicationRejectionReasons from './constants/communication-rejection-reasons'
import * as CommunicationSourceCallTypes from "./constants/communication-call-source-types"
import * as ContactAccessTypes from './constants/contact-access-types'
import * as LRNTypes from './constants/lrn-types'
import * as TrustBundleStatus from './constants/trust-bundle-status'
import * as AlowareAppTypes from './constants/aloware-app-types'
import * as KycLogs from './constants/kyc-logs'
import phone_masker_mixin from './mixins/mask.mixin'
import * as SMS from './constants/sms'
import * as KYC_STATUSES from '../admin/constants/kyc-status'
import * as AloAi from './constants/aloai'
import {STARTUP_PACKAGES} from "./constants/startup_packages"

let sms_mixin = {
    data() {
        return {
            hasReplaceableBySmartEncoding: false,
            smartEncodingExtraChars: 0,
            smartEncodedMessageLength: 0,
            base: 160,
            shouldSendAsMms: false,
            segments: 0,
            limit: 0,
            hasUnicode: false,
            message: {
                body: '',
                bodyLength: 0, // typed characters length
                length: 0, //total length after encoding
                encodedLength: 0, // total length after encoding
                smsEncodingType: 'GSM-7',
                hasLineBreaks: false,
                characters: [],
                characterSegments: [],
                segments: [],
            },
            numberOfSegments: 0,
            limitOfCharactersPerSegment: 160,
            baseOfCharactersPerSegment: 160,
        }
    },
    methods: {
        messageLength(message) {
            this.hasUnicode = false
            this.smartEncodingExtraChars = 0;
            // iterating over each character
            [...message].forEach(char => {
                // if unicode number is over 127 or less than 32 we know it's UCS-2
                let charCode = char.charCodeAt(0)
                let isMoreThanAscii = charCode > 127
                if (isMoreThanAscii) {
                    // change char to unicode
                    // for example: e => 0064
                    let unicode = char.codePointAt(0).toString(16).padStart(4, '0').toUpperCase();
                    let isReplaceableBySmartEncoding = SMS.SMART_ENCODING_CHARS.includes(unicode)
                    // check if this UCS-2 character is replaceable with ASCII character
                    if (!isReplaceableBySmartEncoding) {
                        this.hasUnicode = true
                    }
                    // check if this UCS-2 character is replaceable with ASCII character with more than one character
                    // for example => ½ will be replaced by 1/2 which is 3 characters and not 1
                    let smartCharCount = SMS.SMART_ENCODING_CHAR_COUNT.find(char => char.key === unicode);

                    if (smartCharCount) {
                        this.smartEncodingExtraChars += smartCharCount.value - 1
                    }
                }
            })
            this.smartEncodedMessageLength = message.length + this.smartEncodingExtraChars
            return this.smartEncodedMessageLength
        },
        $_sms_mixin_inspectMessage(message) {
            this.message.body = message;
            this.message.hasLineBreaks = message.includes('\r\n') || message.includes('\n')

            // sets the visible characters and its length
            this.$_sms_mixin_setBodyLength();

            // sets whole message smsEncodingType
            this.$_sms_mixin_setSMSEncodingType();

            // sets the encoded characters length
            this.$_sms_mixin_setEncodedLength();

            // calculates segment data: max length, number of segments
            this.$_sms_mixin_setSegmentsData();

            // add each character to the characters array
            this.$_sms_mixin_setMessageBodyCharacters();

            this.messageLength(this.message.body)
        },
        $_sms_mixin_setSMSEncodingType() {
            // Checks each character in the text
            let text = this.message.body;
            for (let i = 0; i < text.length; i++) {
                let char = text.charAt(i);
                if (!SMS.SMS_GSM_7_CHARS.includes(char) && !SMS.SMS_GSM_7_EXTENSION_CHARS.includes(char)) {
                    this.message.smsEncodingType = SMS.SMS_ENCODING_TYPE_UCS_2;
                    // there is a character that is not found in GSM-7 and GSM-7 Extension sets. The message is not GSM-7 encoded.
                    return;
                }
            }
            this.message.smsEncodingType = SMS.SMS_ENCODING_TYPE_GSM_7; // All characters are GSM-7 encoded
        },
        $_sms_mixin_setBodyLength() {
            // count visible characters. Even emoji and special characters are counted as 1
            const segmenter = new Intl.Segmenter()
            const segments = segmenter.segment(this.message.body);
            this.message.characterSegments = Array.from(segments).map((segment) => segment.segment);
            this.message.bodyLength = this.message.characterSegments.length;
        },
        $_sms_mixin_setEncodedLength() {
            this.message.encodedLength = 0;
            if (this.message.smsEncodingType === SMS.SMS_ENCODING_TYPE_GSM_7) {
                for (let i = 0; i < this.message.bodyLength; i++) {
                    const char = this.message.body[i];
                    if (SMS.SMS_GSM_7_EXTENSION_CHARS.includes(char)) {
                        this.message.encodedLength += 2;
                    } else {
                        this.message.encodedLength++;
                    }
                }
            } else {
                this.message.encodedLength = this.message.body.length;
            }
        },
        $_sms_mixin_setMessageBodyCharacters() {
            this.message.characters = [];
            let totalDecodedLength = 0;

            Object.values(this.message.characterSegments).forEach((characterSegment, index) => {
                let codePointDecodedChars = [];
                let hexCodePoints = [];

                for (let i = 0; i < characterSegment.length; i++) {
                    let codePoint = characterSegment.codePointAt(i).toString(16);
                    let utf16Char = codePoint.toString(16);
                    let hexCodePoint = "0x" + utf16Char;
                    hexCodePoints.push(hexCodePoint);
                    let codePointDecodedChar = String.fromCodePoint(hexCodePoint);
                    codePointDecodedChars.push(codePointDecodedChar);
                }

                let character = {
                    visibleChar: characterSegment,
                    codePointDecodedChars: codePointDecodedChars,
                    hexCodePoints: hexCodePoints,
                    length: codePointDecodedChars.length,
                    segmentNumber: Math.trunc(totalDecodedLength / this.limitOfCharactersPerSegment) + 1,
                    smsEncodingType: this.$_sms_mixin_getCharacterSMSEncodingType(characterSegment),
                    position: index,
                    decodedPosition: totalDecodedLength
                }
                totalDecodedLength += character.length

                this.message.characters.push(character)

                if (this.message.segments[character.segmentNumber - 1] === undefined) {
                    this.message.segments[character.segmentNumber - 1] = {
                        initialPosition: index,
                        finalPosition: index,
                    }
                } else {
                    this.message.segments[character.segmentNumber - 1].finalPosition = index;
                }
            })
        },
        /**
         * Checks if the character is GSM-7, GSM-7 Extension or UCS-2
         * @param char
         * @returns {string}
         */
        $_sms_mixin_getCharacterSMSEncodingType(char) {
            // Check if the character is in the GSM-7 set
            if (SMS.SMS_GSM_7_CHARS.indexOf(char) !== -1) {
                return SMS.SMS_ENCODING_TYPE_GSM_7;
            }

            // Check if the character is in the SMS_GSM7_EXTENSION_CHARS
            if (SMS.SMS_GSM_7_EXTENSION_CHARS.indexOf(char) !== -1) {
                return SMS.SMS_ENCODING_TYPE_GSM_7_EXTENSION;
            }

            return SMS.SMS_ENCODING_TYPE_UCS_2;
        },
        $_sms_mixin_setSegmentsData() {
            // this.message.smsEncodingType = SMS.SMS_ENCODING_TYPE_GSM_7;
            const singleLength = SMS.SMS_ENCODING_TYPE_SEGMENT_LENGTH[this.message.smsEncodingType]
            const concatLength = SMS.SMS_CONCATENATED_ENCODING_TYPE_SEGMENT_LENGTH[this.message.smsEncodingType]

            if (this.message.encodedLength > singleLength) {
                this.numberOfSegments = Math.ceil(this.message.encodedLength / concatLength);
                this.limitOfCharactersPerSegment = concatLength;
            } else {
                this.numberOfSegments = this.message.encodedLength >= 1 ? 1 : 0;
                this.limitOfCharactersPerSegment = singleLength;
            }

        },
    }
}

let base_report_mixin = {
    data() {
        return {
            CancelToken: null,
            source: null,
            is_done: false
        }
    },

    computed: {
        ...mapGetters({
            filter: 'getFilter'
        }),
        ...mapState(['campaigns']),
        ...mapState('cache', ['current_company'])
    },

    created() {
        this.CancelToken = axios.CancelToken
        this.source = this.CancelToken.source()
    },

    mounted() {
        this.filter.campaign_id = this.campaign_id
        this.filter.workflow_id = this.workflow_id
        this.filter.ring_group_id = this.ring_group_id
        this.filter.user_id = this.user_id
        this.filter.team_id = this.team_id
        this.filter.broadcast_id = this.broadcast_id
        if (this.tag_id) {
            this.filter.tags = [this.tag_id]
        }
    },

    methods: {
        ...mapActions(['resetFilters'])
    }
}

// default mixin for all graphs and tables
let report_mixin = {
    data() {
        return {
            CancelToken: null,
            source: null,
            countSource: null,
            is_done: false,
            report_is_filter_changed: false,
            report_can_reset: false,
            is_fired_report_event: false,
            prevent_first_load: true
        }
    },

    computed: {
        ...mapGetters({
            filter: 'getFilter'
        }),
        ...mapState(['campaigns']),
        ...mapState('cache', ['current_company'])
    },

    created() {
        this.CancelToken = axios.CancelToken
        this.source = this.CancelToken.source()
        this.countSource = this.CancelToken.source()
    },

    mounted() {
        // don't change date range filters on report page, sequence tasks and broadcast tasks
        if (!['Reports', 'Sequence Tasks', 'Broadcast Tasks'].includes(this.$route.name) && this.is_first_load && !this.$route.query.from_date) {
            this.resetFilters()
            VueEvent.fire('reset_date_range')
        }

        this.filter.campaign_id = this.campaign_id
        this.filter.workflow_id = this.workflow_id
        this.filter.ring_group_id = this.ring_group_id
        this.filter.user_id = this.user_id
        this.filter.team_id = this.team_id
        this.filter.broadcast_id = this.broadcast_id
        if (this.tag_id) {
            this.filter.tags = [this.tag_id]
        }
    },

    methods: {
        applyReportFilters() {
            this.filter.page = 1
            // for reports-specific filter change(s), we no longer need to
            // call getCommunications. the report components will
            // call it during the loading process
            let is_report = this.$route.path.includes('/reports/')
            if (this.report_type && is_report) {
                this.$emit('reportFiltersChanged')
                return
            }

            // we should make sure that this function exist on each vue component scope
            if (typeof this.getCommunications === "function") {
                // get communications
                this.getCommunications()
            }
            // we should make sure that this function exist on each vue component scope
            if (typeof this.getTranscriptionReportingPanelData === "function") {
                // get transcription reporting panel
                this.getTranscriptionReportingPanelData()
            }

            // we should make sure that this function exist on each vue component scope
            if (typeof this.fetchSentimentCharts === "function") {
                // get transcription reporting panel
                this.fetchSentimentCharts()
            }

            // we should make sure that this function exist on each vue component scope
            if (typeof this.fetchHistoryTable === "function") {
                // get transcription reporting panel
                this.setTranscriptionPage(1);
                this.fetchHistoryTable()
            }
        },

        ...mapActions(['resetFilters'])
    },

    watch: {
        'filter.from_date': function (newValue, oldValue) {
            this.applyReportFilters()
        },

        'filter.to_date': function (newValue, oldValue) {
            this.applyReportFilters()
        },

        'filter.direction': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.answer_status': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.type': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.transfer_type': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.min_talk_time': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.tags': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.call_dispositions': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.not_disposed': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.first_time_only': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.untagged_only': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.unread_only': function (newValue, oldValue) {
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.exclude_automated_communications': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.creator_type': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.campaigns': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.workflows': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.broadcasts': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.ring_groups': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.contact_lists': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.incoming_numbers': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.users': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.teams': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.owners': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },
        'filter.callback_status': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.transcription_keywords': function (newValue, oldValue) {
            if (!_.isEqual(oldValue, newValue)) {
                this.applyReportFilters()
            }
        },

        'filter.transcription_category': function (newValue, oldValue) {
            if (!_.isEqual(oldValue, newValue)) {
                this.applyReportFilters()
            }
        },

        'filter.has_international': function (newValue, oldValue) {
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                this.applyReportFilters()
            }
        },

        'filter.agent_sentiment': function (newValue, oldValue) {
            if (!_.isEqual(oldValue, newValue)) {
                this.applyReportFilters()
            }
        },

        'filter.contact_sentiment': function (newValue, oldValue) {
            if (!_.isEqual(oldValue, newValue)) {
                this.applyReportFilters()
            }
        },
    }
}

let date_mixin = {
    computed: {
        filteredTimezones() {
            if (this.current_company && this.current_company.country) {
                if (!['US'].includes(this.current_company.country)) {
                    return window.countriesAndTimezones.getTimezonesForCountry(this.current_company.country)
                        .map((timezone) => {
                            return {
                                name: timezone.name + ' GMT ' + timezone.utcOffsetStr,
                                value: timezone.name
                            }
                        })
                }

                if (['US'].includes(this.current_company.country)) {
                    return [
                        {
                            value: 'America/New_York',
                            name: 'New York (Eastern)',
                        },
                        {
                            value: 'America/Chicago',
                            name: 'Chicago (Central Standard Time)',
                        },
                        {
                            value: 'America/Denver',
                            name: 'Denver (Mountain Daylight Time)',
                        },
                        {
                            value: 'America/Phoenix',
                            name: 'Phoenix (Mountain Standard Time)',
                        },
                        {
                            value: 'America/Los_Angeles',
                            name: 'Los Angeles (Pacific Standard Time)',
                        },
                        {
                            value: 'Pacific/Honolulu',
                            name: 'Honolulu (Hawaii Standard Time)',
                        },
                        {
                            value: 'America/Halifax',
                            name: 'Atlantic (Atlantic Standard Time)',
                        },
                    ]
                }
            }

            return []
        },
    },

    methods: {
        thirtyDaysAgo() {
            if (window.timezone) {
                return moment.utc().tz(window.timezone).subtract(30, 'days').startOf('day')
            } else {
                return moment.utc().local().subtract(30, 'days').startOf('day')
            }
        },

        localizedMoment(dt, format) {
            if (dt) {
                if (window.timezone) {
                    return moment(dt, format).utc().tz(window.timezone)
                } else {
                    return moment(dt, format).utc().local()
                }
            } else {
                if (window.timezone) {
                    return moment.tz(window.timezone)
                } else {
                    return moment.locale()
                }
            }
        },

        utcToLocalizedMoment(dt) {
            if (dt) {
                if (window.timezone) {
                    return moment.utc(dt).tz(window.timezone)
                } else {
                    return moment.utc(dt).local()
                }
            } else {
                if (window.timezone) {
                    return moment.utc().tz(window.timezone)
                } else {
                    return moment.utc().local()
                }
            }
        }
    }
}

let custom_highcharts_mixin = {
    mounted() {
        this.logarithmicWithZeroYAxis()
    },

    methods: {
        logarithmicWithZeroYAxis() {
            // added to allow 0 on logarithmic type of axis
            (function (H) {
                H.addEvent(H.Axis, 'afterInit', function () {
                    const logarithmic = this.logarithmic
                    if (logarithmic && this.options.custom.allowNegativeLog) {
                        // Avoid errors on negative numbers on a log axis
                        this.positiveValuesOnly = false
                        // Override the converter functions
                        logarithmic.log2lin = num => {
                            const isNegative = num < 0
                            let adjustedNum = Math.abs(num)
                            if (adjustedNum < 10) {
                                adjustedNum += (10 - adjustedNum) / 10
                            }
                            const result = Math.log(adjustedNum) / Math.LN10
                            return isNegative ? -result : result
                        }
                        logarithmic.lin2log = num => {
                            const isNegative = num < 0
                            let result = Math.pow(10, Math.abs(num))
                            if (result < 10) {
                                result = (10 * (result - 1)) / (10 - 1)
                            }
                            return isNegative ? -result : result
                        }
                    }
                })
            }(Highcharts))
        }
    }
}

let graph_mixin = {
    data() {
        return {
            reporting_url: localStorage.getItem('reporting_url'),
            loading: false,
            aggregated_counts: [],
            chart_period: 'none',
            report_type: '',
            first_time_only: '',
            direction: '',
            options: {
                title: {
                    text: '',
                    style: {
                        fontSize: '18px',
                        color: '#090A0D',
                        fontWeight: 'bold'
                    }
                },
                chart: {
                    type: 'column',
                },
                plotOptions: {},
                xAxis: {
                    labels: {
                        style: {
                            fontSize: '14px'
                        }
                    }
                },
                yAxis: {
                    title: {
                        text: 'Number Of Communications',
                        style: {
                            'font-size': '14px',
                            'color': '#090A0D'
                        }
                    },
                    labels: {
                        style: {
                            fontSize: '14px'
                        }
                    }
                },
                tooltip: {
                    headerFormat: "",
                    split: false,
                    enabled: true,
                    style: {
                        fontSize: '14px'
                    }
                },
                navigator: {
                    enabled: false
                },
                rangeSelector: {
                    inputEnabled: false,
                    allButtonsEnabled: false,
                    enabled: false,
                },
                credits: {
                    enabled: false
                },
                exporting: {
                    enabled: true,
                    sourceWidth: 0,
                    sourceHeight: 0
                },
                scrollbar: {
                    enabled: false
                },
                legend: {
                    enabled: true,
                    borderWidth: 0,
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal',
                    itemStyle: {
                        fontSize: '14px'
                    }
                },
                series: [],
            }
        }
    },

    created() {
        VueEvent.listen('content_resized', () => {
            setTimeout(() => {
                this.changeChartSize()
            }, 50)
        })
    },

    mounted() {
        if (!['leads_created_vs_dnc', 'new_contacts_vs_dnc'].includes(this.report_type)) {
            this.getCommunications()
        }

        window.addEventListener('resize', () => {
            this.changeChartSize()
        })
    },

    methods: {
        changeChartSize() {
            if (this.$refs.highchart) {
                this.$refs.highchart.chart.reflow()
            }
        },

        changeChartPeriod(chart_period) {
            this.getCommunications()
        },

        getCommunications() {
            this.source.cancel('getCommunications canceled by the user.')
            this.source = this.CancelToken.source()
            this.is_done = false
            this.loading = true
            this.options.series = []
            this.aggregated_counts = []
            let default_filters = _.clone(this.filter)
            default_filters['report_type'] = this.report_type
            default_filters['campaign_id'] = ''
            default_filters['chart_period'] = this.chart_period

            if (this.first_time_only !== '') {
                default_filters['first_time_only'] = this.first_time_only
            }
            if (this.direction !== '') {
                default_filters['direction'] = this.direction
            }

            return axios.get('/api/v1/reports', {
                params: default_filters,
                cancelToken: this.source.token
            }).then(res => {
                this.aggregated_counts = res.data

                this.generateGraphData()

                this.loading = false
                this.is_done = true
                this.$nextTick(() => {
                    let highcharts_container = document.getElementById(this.graph_id)

                    if (highcharts_container) {
                        this.options.exporting.sourceWidth = highcharts_container.clientWidth
                        this.options.exporting.sourceHeight = highcharts_container.clientHeight
                    }
                })

                return Promise.resolve(res)
            }).catch(err => {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message)

                    // return Promise.reject(err)
                } else {
                    console.log(err)
                    this.loading = false

                    return Promise.reject(err)
                }
            })
        },

        getLeadsIntakeReport() {
            this.source.cancel('getLeadsIntakeReport canceled by the user.')
            this.source = this.CancelToken.source()
            let default_filters = _.clone(this.filter)
            let api_url = ''

            default_filters.chart_period = 'day'
            switch (this.report_type) {
                case 'leads_created_vs_dnc':
                case 'new_contacts_vs_dnc':
                    default_filters.baseline = this.report_type
                    api_url = this.reporting_url + '/api/v1/reports/activity-reporting'
                    break
                case 'leads_intake':
                    default_filters.report_type = this.report_type
                    api_url = this.reporting_url + '/api/v1/reports/contact-phone-number'
                    break
            }
            default_filters.date_field = 'created_at'

            if (!api_url) {
                return
            }

            this.is_done = false
            this.loading = true
            this.options.series = []
            this.aggregated_counts = []

            return axios.get(api_url, {
                params: default_filters,
                cancelToken: this.source.token
            }).then(res => {
                this.aggregated_counts = res.data

                this.generateGraphData()

                this.loading = false
                this.is_done = true
                this.$nextTick(() => {
                    let highcharts_container = document.getElementById(this.graph_id)

                    if (highcharts_container) {
                        this.options.exporting.sourceWidth = highcharts_container.clientWidth
                        this.options.exporting.sourceHeight = highcharts_container.clientHeight
                    }
                })

                return Promise.resolve(res)
            }).catch(err => {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message)

                    // return Promise.reject(err)
                } else {
                    console.log(err)
                    this.loading = false

                    return Promise.reject(err)
                }
            })
        },

        getPointStart(base_series) {
            return typeof base_series[0] !== 'undefined' && typeof base_series[0][0] !== 'undefined' ? base_series[0][0] : 0
        }
    }
}

let map_graph_mixin = {
    data() {
        let self = this
        return {
            aggregated_counts: [],
            total_locations: 0,
            report_type: '',
            us_ca_states: {
                'Alabama': 'us-al',
                'Alaska': 'us-ak',
                'Arizona': 'us-az',
                'Arkansas': 'us-ar',
                'California': 'us-ca',
                'Colorado': 'us-co',
                'Connecticut': 'us-ct',
                'Delaware': 'us-de',
                'Florida': 'us-fl',
                'Georgia': 'us-ga',
                'Hawaii': 'us-hi',
                'Idaho': 'us-id',
                'Illinois': 'us-il',
                'Indiana': 'us-in',
                'Iowa': 'us-ia',
                'Kansas': 'us-ks',
                'Kentucky': 'us-ky',
                'Louisiana': 'us-la',
                'Maine': 'us-me',
                'Maryland': 'us-md',
                'Massachusetts': 'us-ma',
                'Michigan': 'us-mi',
                'Minnesota': 'us-mn',
                'Mississippi': 'us-ms',
                'Missouri': 'us-mo',
                'Montana': 'us-mt',
                'Nebraska': 'us-ne',
                'Nevada': 'us-nv',
                'New Hampshire': 'us-nh',
                'New Jersey': 'us-nj',
                'New Mexico': 'us-nm',
                'New York': 'us-ny',
                'North Carolina': 'us-nc',
                'North Dakota': 'us-nd',
                'Ohio': 'us-oh',
                'Oklahoma': 'us-ok',
                'Oregon': 'us-or',
                'Pennsylvania': 'us-pa',
                'Rhode Island': 'us-ri',
                'South Carolina': 'us-sc',
                'South Dakota': 'us-sd',
                'Tennessee': 'us-tn',
                'Texas': 'us-tx',
                'Utah': 'us-ut',
                'Vermont': 'us-vt',
                'Virginia': 'us-va',
                'Washington': 'us-wa',
                'West Virginia': 'us-wv',
                'Wisconsin': 'us-wi',
                'Wyoming': 'us-wy',
                'Saskatchewan': 'ca-sk',
                'British Columbia': 'ca-bc',
                'Nunavut': 'ca-nu',
                'Northwest Territories': 'ca-nt',
                'Alberta': 'ca-ab',
                'Ontario': 'ca-on',
                'Québec': 'ca-qc',
                'New Brunswick': 'ca-nb',
                'Nova Scotia': 'ca-ns',
                'Newfoundland and Labrador': 'ca-nl',
                'Manitoba': 'ca-mb',
                'Yukon': 'ca-yt',
                'Prince Edward Island': 'ca-pe'
            },
            options: {
                title: {
                    text: '',
                    style: {
                        'font-size': '14px',
                        'color': '#090A0D'
                    }
                },
                chart: {
                    marginTop: 70
                },
                exporting: {
                    enabled: true,
                    sourceWidth: 600,
                    sourceHeight: 600
                },
                mapNavigation: {
                    enabled: true,
                    buttonOptions: {
                        verticalAlign: 'bottom'
                    },
                    enableMouseWheelZoom: false
                },
                plotOptions: {
                    map: {
                        allAreas: true,
                        allowPointSelect: true,
                        events: {
                            legendItemClick: function (e) {
                                let seriesIndex = this.index
                                let series = this.chart.series
                                let locations_count = 0

                                for (let i = 0; i < series.length; i++) {
                                    if (series[i].index != seriesIndex) {
                                        series[i].hide()
                                    }
                                }
                                // get current series' locations count
                                for (let series_data_index in series[seriesIndex].data) {
                                    if (series[seriesIndex].data[series_data_index].value) {
                                        locations_count++
                                    }
                                }

                                series[seriesIndex].show()
                                self.total_locations = locations_count

                                return false
                            }
                        }
                    }
                },
                legend: {
                    layout: 'horizontal',
                    borderWidth: 0,
                    backgroundColor: 'rgba(255,255,255,0.85)',
                    floating: true,
                    verticalAlign: 'top'
                },
                tooltip: {
                    headerFormat: "",
                    split: false,
                    enabled: true,
                    shared: true,
                    style: {
                        fontSize: '14px'
                    }
                },

                series: []
            }
        }
    },

    created() {
        this.getCommunications().then(res => {
            if (this.report_index !== undefined) {
                this.$emit('reportLoaded', this.report_index)
            }
        }).catch(err => {
            if (axios.isCancel(err) && this.reports_loading !== undefined) {
                this.reports_loading = false
            }
        })

        VueEvent.listen('content_resized', () => {
            setTimeout(() => {
                this.changeChartSize()
            }, 50)
        })
    },

    mounted() {
        window.addEventListener('resize', () => {
            this.changeChartSize()
        })
    },

    methods: {
        changeChartSize() {
            if (this.$refs.highchart) {
                this.$refs.highchart.chart.reflow()
            }
        },

        changeChartPeriod(chart_period) {
            this.getCommunications().then(res => {
                if (this.report_index !== undefined) {
                    this.$emit('reportLoaded', this.report_index)
                }
            }).catch(err => {
                if (axios.isCancel(err) && this.reports_loading) {
                    this.reports_loading = false
                }
            })
        },

        getCommunications() {
            this.source.cancel('getCommunications canceled by the user.')
            this.source = this.CancelToken.source()
            this.is_done = false
            this.loading = true
            this.options.series = []
            this.aggregated_counts = []
            let default_filters = _.clone(this.filter)
            default_filters['report_type'] = this.report_type
            default_filters['campaign_id'] = ''
            default_filters['chart_period'] = 'none'
            return axios.get('/api/v1/reports', {
                params: default_filters,
                cancelToken: this.source.token
            }).then(res => {
                this.aggregated_counts = res.data
                this.total_locations = 0
                let locations = []
                let has_selected = 0
                let keys = Object.keys(this.aggregated_counts)
                for (let index in keys) {
                    let campaign_data = this.aggregated_counts[keys[index]]
                    // do not include empty data
                    if (Object.keys(campaign_data.data).length > 0) {
                        // reset locations array
                        locations = []

                        for (let campaign_key in campaign_data.data) {
                            let count = parseInt(campaign_data.data[campaign_key].count)

                            // don't continue if location is null
                            if (!campaign_data.data[campaign_key].name) {
                                continue
                            }

                            // filter lead_location generated by twilio: [CITY STATE]: we only need the state
                            if (typeof this.us_ca_states[campaign_data.data[campaign_key].name] == 'undefined') {
                                let state_abbr_arr = campaign_data.data[campaign_key].name.split(' ')
                                let state_abbr = state_abbr_arr[state_abbr_arr.length - 1]

                                for (let state in this.us_ca_states) {
                                    let us_state_index = 'us-' + state_abbr.toLowerCase()
                                    let ca_state_index = 'ca-' + state_abbr.toLowerCase()
                                    if (this.us_ca_states[state] == us_state_index) {
                                        // record unique location with count
                                        if (typeof locations[us_state_index] == 'undefined') {
                                            locations[us_state_index] = count
                                        } else {
                                            locations[us_state_index] += count
                                        }
                                        break
                                    } else if (this.us_ca_states[state] == ca_state_index) {
                                        // record unique location with count
                                        if (typeof locations[ca_state_index] == 'undefined') {
                                            locations[ca_state_index] = count
                                        } else {
                                            locations[ca_state_index] += count
                                        }
                                        break
                                    }
                                }
                            } else {
                                // record unique locations
                                if (typeof locations[this.us_ca_states[campaign_data.data[campaign_key].name]] == 'undefined') {
                                    locations[this.us_ca_states[campaign_data.data[campaign_key].name]] = count
                                } else {
                                    locations[this.us_ca_states[campaign_data.data[campaign_key].name]] += count
                                }
                            }
                        }

                        // only add a series if map will not be empty
                        if (Object.keys(locations).length <= 0) {
                            // thou shall not pass
                            continue
                        }

                        // add campaign data
                        let series = {
                            name: campaign_data.series_name,
                            visible: false,
                            data: []
                        }

                        for (let location in locations) {
                            series.data.push([location, locations[location]])
                        }

                        // select only the first campaign onpageload
                        if (!has_selected) {
                            series.visible = true
                            has_selected = 1

                            this.total_locations = Object.keys(locations).length
                        }

                        // add map series
                        this.options.series.push(series)
                    }
                }

                this.loading = false
                this.is_done = true
                this.$nextTick(() => {
                    let highcharts_container = document.getElementById(this.graph_id)

                    if (highcharts_container) {
                        this.options.exporting.sourceWidth = highcharts_container.clientWidth
                        this.options.exporting.sourceHeight = highcharts_container.clientHeight
                    }

                })

                return Promise.resolve(res)
            }).catch(err => {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message)
                    // return Promise.reject(err)
                } else {
                    console.log(err)
                    this.loading = false

                    return Promise.reject(err)
                }
            })
        },
    }
}

let validator_mixin = {
    methods: {
        contactValidator(rule, value, callback) {
            if (this.schedule.contact !== null && !_.isEmpty(this.schedule.contact)) {
                callback()
            } else {
                callback(new Error('A contact is required'))
            }
        },

        // TODO: update to use the same validators as the AccountRegistration component
        newPasswordValidator(rule, value, callback) {
            if (this.user.password && this.user.password.length < 6) {
                callback(new Error('Password must be at least 6 characters'))
            } else {
                callback()
            }
        },

        newPasswordConfirmationValidator(rule, value, callback) {
            if (this.user.password && !this.user.password_confirmation) {
                callback(new Error('Please confirm new password'))
            } else if (this.user.password !== this.user.password_confirmation) {
                callback(new Error('Password and confirmation are not matching'))
            } else {
                callback()
            }
        },

        outboundCampaignValidator(rule, value, callback) {
            if (this.outbound_calling_selector == 1 && !this.profile.default_outbound_campaign_id) {
                callback(new Error('Please select an outbound line'))
            } else {
                callback()
            }
        },

        userMissedCallBehaviourValidator(rule, value, callback) {
            if (this.profile.missed_calls_settings.missed_call_handling_mode == MissedCallBehaviors.MISSED_CALL_BEHAVIOR_VOICEMAIL && !this.profile.missed_calls_settings.voicemail_file) {
                callback(new Error('Please upload a voicemail file'))
            } else {
                callback()
            }
        },

        userOutboundCampaignValidator(rule, value, callback) {
            if (this.user.outbound_calling_selector == 1 && !this.user.read_only_access && this.user.answer_by != (AnswerTypes.BY_NONE + 2) && !this.user.default_outbound_campaign_id) {
                callback(new Error('Please select an outbound line'))
            } else {
                callback()
            }
        },

        transferUserValidator(rule, value, callback) {
            if (this.transfer.mode == 'user') {
                if (this.transfer.user_id) {
                    callback()
                } else {
                    callback(new Error('Please select a user'))
                }
            } else {
                callback()
            }
        },

        transferRingGroupValidator(rule, value, callback) {
            if (this.transfer.mode == 'ring_group') {
                if (this.transfer.ring_group_id) {
                    callback()
                } else {
                    callback(new Error('Please select a ring group'))
                }
            } else {
                callback()
            }
        },

        transferPhoneValidator(rule, value, callback) {
            if (this.transfer.mode == 'phone') {
                if (this.transfer.phone_number) {
                    if (this.validatePhone(value)) {
                        callback()
                    } else {
                        callback(new Error('Phone number is not valid'))
                    }
                } else {
                    callback(new Error('Please provide a phone number'))
                }
            } else {
                callback()
            }
        },

        addUserValidator(rule, value, callback) {
            if (this.add.mode == 'user') {
                if (this.add.user_id) {
                    callback()
                } else {
                    callback(new Error('Please select a user'))
                }
            } else {
                callback()
            }
        },

        addRingGroupValidator(rule, value, callback) {
            if (this.add.mode == 'ring_group') {
                if (this.add.ring_group_id) {
                    callback()
                } else {
                    callback(new Error('Please select a ring group'))
                }
            } else {
                callback()
            }
        },

        addPhoneValidator(rule, value, callback) {
            if (this.add.mode == 'phone') {
                if (this.add.phone_number) {
                    if (this.validatePhone(value)) {
                        callback()
                    } else {
                        callback(new Error('Phone number is not valid'))
                    }
                } else {
                    callback(new Error('Please provide a phone number'))
                }
            } else {
                callback()
            }
        },

        phoneValidator(rule, value, callback) {
            // for user dialog page
            if (this.user && this.user.answer_by !== undefined && this.user.answer_by !== null && this.user.answer_by != AnswerTypes.BY_PHONE_NUMBER) {
                if (value && this.validatePhone(value)) {
                    callback()
                } else if (!value) {
                    callback()
                } else {
                    callback(new Error('Phone number is not valid'))
                }
            }

            if (value === '') {
                callback(new Error('Please provide a phone number'))
            } else {
                if (this.validatePhone(value)) {
                    callback()
                } else {
                    callback(new Error('Phone number is not valid'))
                }
            }
        },

        secondPhoneValidator(rule, value, callback) {
            if (this.user.enabled_two_legged_outbound) {
                if (value === '') {
                    callback(new Error('Please provide a phone number'))
                } else {
                    if (this.validatePhone(value)) {
                        callback()
                    } else {
                        callback(new Error('Phone number is not valid'))
                    }
                }
            } else {
                callback()
            }
        },

        customPhoneNumberValidator(rule, value, callback) {
            if (this.set_custom_number) {
                if (!value) {
                    callback(new Error('Please provide a phone number'))
                } else {
                    if (!this.validatePhone(value)) {
                        callback(new Error('Phone number is not valid'))
                    }
                }
            }

            callback()
        },

        answerByValidator(rule, value, callback) {
            // for profile settings page
            if (this.profile && (this.profile.answer_by !== undefined || this.profile.answer_by !== null)) {
                callback()
            }
            if (this.profile && (this.profile.answer_by === undefined || this.profile.answer_by === null)) {
                callback(new Error('Please select an answer type'))
            }

            if (this.user && (this.user.answer_by !== undefined || this.user.answer_by !== null)) {
                callback()
            }

            if (this.user && !this.user.read_only_access) {
                if (this.user.answer_by === undefined || this.user.answer_by === null) {
                    callback(new Error('Please select an answer type'))
                } else {
                    callback()
                }
            }
        },

        whisperValidator(rule, value, callback) {
            if (value == true) {
                if ((this.campaign.whisper_tts && this.campaign.whisper_tts.trim() != '') ||
                    (this.campaign.whisper_file && this.campaign.whisper_file.trim() != '')) {
                    callback()
                } else {
                    callback(new Error('Whisper message must not be empty'))
                }
            } else {
                callback()
            }
        },

        greetingValidator(rule, value, callback) {
            if (value == true) {
                if ((this.campaign.greeting_tts && this.campaign.greeting_tts.trim() != '') ||
                    (this.campaign.greeting_file && this.campaign.greeting_file.trim() != '')) {
                    callback()
                } else {
                    callback(new Error('Greeting message must not be empty'))
                }
            } else {
                callback()
            }
        },

        ivrPromptValidator(rule, value, callback) {
            if (rule.required()) {
                if ((this.ivr.prompt_tts && this.ivr.prompt_tts.trim() !== '') ||
                    (this.ivr.prompt_file && this.ivr.prompt_file.trim() !== '')) {
                    callback()
                } else {
                    callback(new Error('IVR prompt message must not be empty'))
                }
            } else {
                callback()
            }
        },

        textAuthorizationValidator(rule, value, callback) {
            if (value == true) {
                if ((this.campaign.ask_for_text_authorization_tts && this.campaign.ask_for_text_authorization_tts.trim() != '') ||
                    (this.campaign.ask_for_text_authorization_file && this.campaign.ask_for_text_authorization_file.trim() != '')) {
                    callback()
                } else {
                    callback(new Error('Text authorization prompt must not be empty'))
                }
            } else {
                callback()
            }
        },

        callbackValidator(rule, value, callback) {
            if (value == true) {
                if ((this.ring_group.queue_ask_for_callback_tts && this.ring_group.queue_ask_for_callback_tts.trim() != '') ||
                    (this.ring_group.queue_ask_for_callback_file && this.ring_group.queue_ask_for_callback_file.trim() != '')) {
                    callback()
                } else {
                    callback(new Error('Callback prompt must not be empty'))
                }
            } else {
                callback()
            }
        },

        handleByTextValidator(rule, value, callback) {
            if (value == true) {
                if ((this.ring_group.queue_handle_by_text_tts && this.ring_group.queue_handle_by_text_tts.trim() != '') ||
                    (this.ring_group.queue_handle_by_text_file && this.ring_group.queue_handle_by_text_file.trim() != '')) {
                    callback()
                } else {
                    callback(new Error('Handle by text prompt must not be empty'))
                }
            } else {
                callback()
            }
        },

        askForVoicemailValidator(rule, value, callback) {
            if (value == true) {
                if ((this.ring_group.queue_ask_for_voicemail_tts && this.ring_group.queue_ask_for_voicemail_tts.trim() != '') ||
                    (this.ring_group.queue_ask_for_voicemail_file && this.ring_group.queue_ask_for_voicemail_file.trim() != '')) {
                    callback()
                } else {
                    callback(new Error('Voicemail prompt must not be empty'))
                }
            } else {
                callback()
            }
        },

        callScreenValidator(rule, value, callback) {
            if (value == true) {
                if ((this.ring_group.queue_call_screen_tts && this.ring_group.queue_call_screen_tts.trim() != '') ||
                    (this.ring_group.queue_call_screen_file && this.ring_group.queue_call_screen_file.trim() != '')) {
                    callback()
                } else {
                    callback(new Error('Call screen prompt must not be empty'))
                }
            } else {
                callback()
            }
        },

        handleByTextMessageValidator(rule, value, callback) {
            if (this.ring_group.queue_handle_by_text_response_tts && this.ring_group.queue_handle_by_text_response_tts.trim() != '') {
                callback()
            } else {
                callback(new Error('Text message must not be empty'))
            }
        },

        recordValidator(rule, value, callback) {
            if (value == true) {
                if ((this.campaign.record_tts && this.campaign.record_tts.trim() != '') ||
                    (this.campaign.record_file && this.campaign.record_file.trim() != '')) {
                    callback()
                } else {
                    callback(new Error('Recording message must not be empty'))
                }
            } else {
                callback()
            }
        },

        csatPreambleValidator(rule, value, callback) {
            if (this.campaign.has_csat) {
                if ((this.campaign.csat.preamble_tts && this.campaign.csat.preamble_tts.trim() !== '') ||
                    (this.campaign.csat.preamble_file && this.campaign.csat.preamble_file.trim() !== '')) {
                    callback()
                } else {
                    callback(new Error('CSAT preamble message must not be empty'))
                }
            } else {
                callback()
            }
        },

        csatPromptValidator(rule, value, callback) {
            if (this.campaign.has_csat) {
                if ((this.campaign.csat.prompt_tts && this.campaign.csat.prompt_tts.trim() !== '') ||
                    (this.campaign.csat.prompt_file && this.campaign.csat.prompt_file.trim() !== '')) {
                    callback()
                } else {
                    callback(new Error('CSAT prompt message must not be empty'))
                }
            } else {
                callback()
            }
        },

        csatOutroValidator(rule, value, callback) {
            if (this.campaign.has_csat) {
                if ((this.campaign.csat.outro_tts && this.campaign.csat.outro_tts.trim() !== '') ||
                    (this.campaign.csat.outro_file && this.campaign.csat.outro_file.trim() !== '')) {
                    callback()
                } else {
                    callback(new Error('CSAT outro message must not be empty'))
                }
            } else {
                callback()
            }
        },

        voicemailValidator(rule, value, callback) {
            if (this.campaign.missed_calls_settings.missed_call_handling_mode == MissedCallBehaviors.MISSED_CALL_BEHAVIOR_VOICEMAIL) {
                if ((this.campaign.missed_calls_settings.voicemail_tts && this.campaign.missed_calls_settings.voicemail_tts.trim() != '') ||
                    (this.campaign.missed_calls_settings.voicemail_file && this.campaign.missed_calls_settings.voicemail_file.trim() != '')) {
                    callback()
                } else {
                    callback(new Error('Voicemail message must not be empty'))
                }
            } else {
                callback()
            }
        },

        validateMissedCallVoicemail(rule, value, callback) {
            if (this.user.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_VOICEMAIL && this.user.missed_calls_settings.voicemail_file === null) {
                callback(new Error('Please upload a voicemail file for Personal Voicemail.'))
            } else {
                callback()
            }
        },

        lineAccessLimitValidator(rule, value, callback) {
            if (this.user.line_access_limit && this.user.selected_campaign_ids && this.user.selected_campaign_ids.length === 0) {
                callback(new Error('Please select a line'))
            } else {
                callback()
            }
        },

        userAccessLimitValidator(rule, value, callback) {
            if (this.user.user_access_limit && this.user.selected_user_ids && this.user.selected_user_ids.length === 0) {
                callback(new Error('Please select a user'))
            } else {
                callback()
            }
        },

        closedHoursMissedCallVoicemailValidator(rule, value, callback) {
            if (this.campaign.closed_hours_voice_prompt.enabled) {
                if ((this.campaign.closed_hours_voice_prompt.tts && this.campaign.closed_hours_voice_prompt.tts.trim() != '') ||
                    (this.campaign.closed_hours_voice_prompt.file && this.campaign.closed_hours_voice_prompt.file.trim() != '')) {
                    callback()
                } else {
                    callback(new Error('Voicemail message must not be empty'))
                }
            } else {
                callback()
            }
        },

        closedHoursMissedTextAutoReplyValidator(rule, value, callback) {
            if (this.campaign.closed_hours_auto_reply_text.enabled) {
                if (this.campaign.closed_hours_auto_reply_text.message && this.campaign.closed_hours_auto_reply_text.message.trim() != '') {
                    callback()
                } else {
                    callback(new Error('Message body must not be empty'))
                }
            } else {
                callback()
            }
        },

        missedCallHandlingForwardNumber(rule, value, callback) {
            if (this.campaign.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_FORWARD) {
                if (this.campaign.missed_calls_settings.forward_to && this.$options.filters.fixPhone(this.campaign.missed_calls_settings.forward_to)) {
                    callback()
                } else {
                    callback(new Error('Please enter a valid phone number'))
                }
                return
            }
            callback()
        },

        missedCallHandlingAloAi(rule, value, callback) {
            if (this.campaign.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_ALOAI) {
                if (this.campaign.missed_calls_settings.aloai_bot_id) {
                    callback()
                } else {
                    callback(new Error('Please select an AloAi agent'))
                }
                return
            }
            callback()
        },

        missedCallHandlingRouteToOtherLine(rule, value, callback) {
            if (this.campaign.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_REROUTE) {
                if (this.campaign.missed_calls_settings.reroute_to_campaign_id) {
                    callback()
                } else {
                    callback(new Error('Please select a line'))
                }
                return
            }
            callback()
        },

        sequenceValidator(rule, value, callback) {
            if ((this.sequence.message_tts !== '' && this.sequence.message_tts) ||
                (this.sequence.message_file !== '' && this.sequence.message_file)) {
                callback()
            } else {
                callback(new Error('Message must not be empty'))
            }
        },

        amdVoicemailValidator(rule, value, callback) {
            if ((this.sequence.amd_voicemail_tts !== '' && this.sequence.amd_voicemail_tts) ||
                (this.sequence.amd_voicemail_file !== '' && this.sequence.amd_voicemail_file)) {
                callback()
            } else {
                callback(new Error('Voicemail message must not be empty'))
            }
        },

        passValidator(rule, value, callback) {
            if (this.user.password === '') {
                callback(new Error('Please provide a password'))
            } else if (this.user.password !== '' && this.user.password.length < 6) {
                callback(new Error('Password length must be at least 6 characters'))
            } else {
                callback()
            }
        },

        termsValidator(rule, value, callback) {
            if (value === false) {
                callback(new Error('Please agree to terms of service'))
            } else {
                callback()
            }
        },

        labelValidator(rule, value, callback) {
            let validation_result = this.validateLabel()
            if (validation_result) {
                callback()
            } else {
                callback(new Error(this.post_error.label))
                this.post_error.label = null
            }
        },

        validatePhone(phoneNumber) {
            return this.$options.filters.fixPhone(phoneNumber) ? true : false
        },

        validateLabel() {
            if (this.post_error.label !== null) {
                return false
            }
            return true
        },

        callerIdValidator(rule, value, callback) {
            if (value === '') {
                callback(new Error('Please provide a phone number'))
            } else {
                if (!this.validatePhone(value)) {
                    callback(new Error('Phone number is not valid'))
                }

                if (!this.checkIfCallerIdIsOnCampaignList(this.$options.filters.fixPhone(value))) {
                    callback(new Error('Please use one of your line\'s numbers as your Caller ID. If you need a Caller ID that is not hosted with us, please contact our support.'))
                }

                callback()
            }
        },

        addExtensionPhoneNumberValidator(rule, value, callback) {
            if (value === '') {
                callback(new Error('Please provide a phone number'))
                return
            } else if (!this.validatePhone(value)) {
                callback(new Error('Phone number is not valid'))
                return
            }
            callback()
        },

        skipLineIdsValidator(rule, value, callback) {
            if (this.card.settings.filters.filter_lines && value.length === 0) {
                callback(new Error('Please select a line'))
            }
            callback()
        },

        dispositionStatusesValidator(rule, value, callback) {
            if (this.card.settings.filters.contacts == 'selected_disposition_statuses' && value.length === 0) {
                callback(new Error('Please select a disposition status'))
            }
            callback()
        },

        dealCreationDispositionStatusesValidator(rule, value, callback) {
            if (this.card.settings.filters.create_deal_when == 'disposed' && value.length === 0) {
                callback(new Error('Please select a disposition status'))
            }
            callback()
        },

        sendDailyActivityReportsOnValidator(rule, value, callback) {
            if (this.company_clone.daily_activity_reports_enabled && !value) {
                callback(new Error('Please select a time to send reports'))
            }
            callback()
        },

        callDurationTypeValidator(rule, value, callback) {
            if (this.card.settings.filters.filter_call_duration && _.isEmpty(value)) {
                callback(new Error('Please select the duration type to filter calls'))
            }
            callback()
        },

        callDurationValidator(rule, value, callback) {
            if (this.card.settings.filters.filter_call_duration && _.isEmpty(value)) {
                callback(new Error('Please enter the duration in minutes'))
            } else if (this.card.settings.filters.filter_call_duration && parseInt(value) < 1) {
                callback(new Error('Please enter a value greater than or equal to 1'))
            }
            callback()
        },

        directionValidator(rule, value, callback) {
            if (this.card.settings.filters.filter_direction && _.isEmpty(value)) {
                callback(new Error('Please select communication direction(s)'))
            }
            callback()
        },

        typeValidator(rule, value, callback) {
            if (this.card.settings.filters.filter_type && _.isEmpty(value)) {
                callback(new Error('Please select communication type(s)'))
            }
            callback()
        },

        communicationDispositionStatusesValidator(rule, value, callback) {
            if (this.card.settings.filters.filter_communication_disposition_status && _.isEmpty(value)) {
                callback(new Error('Please select communication disposition status(es)'))
            }
            callback()
        },

        checkIfCallerIdIsOnCampaignList(phoneNumber) {
            // get campaign list
            let campaign_list = []

            this.$store.state.campaigns.forEach(item => {
                if (item.incoming_numbers && item.incoming_numbers.length > 0) {
                    item.incoming_numbers.forEach(incoming_number => {
                        campaign_list.push(incoming_number.phone_number)
                    })
                }
            })

            if (campaign_list.indexOf(phoneNumber) == -1) {
                return false
            }

            return true
        },

        firstNameValidator(rule, value, callback) {
            if (value !== null && value.length > 191) {
                callback(new Error('First name must not exceed 191 characters'))
            } else {
                callback()
            }
        },

        lastNameValidator(rule, value, callback) {
            if (value !== null && value.length > 191) {
                callback(new Error('Last name must not exceed 191 characters'))
            } else {
                callback()
            }
        },

        firstNameRequiredValidator(rule, value, callback) {
            if (_.isEmpty(value)) {
                callback(new Error('Please provide a first name for the user'))
            } else if (value.length > 191) {
                callback(new Error('First name must not exceed 191 characters'))
            } else {
                callback()
            }
        },

        lastNameRequiredValidator(rule, value, callback) {
            if (_.isEmpty(value)) {
                callback(new Error('Please provide a last name for the user'))
            } else if (value.length > 191) {
                callback(new Error('First name must not exceed 191 characters'))
            } else {
                callback()
            }
        },

        registerFirstNameRequiredValidator(rule, value, callback) {
            if (_.isEmpty(value)) {
                callback(new Error('Please provide your first name'))
            } else if (value.length > 191) {
                callback(new Error('First name must not exceed 191 characters'))
            } else {
                callback()
            }
        },

        registerLastNameRequiredValidator(rule, value, callback) {
            if (_.isEmpty(value)) {
                callback(new Error('Please provide your last name'))
            } else if (value.length > 191) {
                callback(new Error('First name must not exceed 191 characters'))
            } else {
                callback()
            }
        },

        outboundCallCountValidator(rule, value, callback) {
            if (this.outbound_call_count_switch &&
                !this.outbound_call_count_model.outbound_call_count_start) {
                callback(new Error('Please enter call count start'))
            } else if (this.outbound_call_count_switch &&
                !this.outbound_call_count_model.outbound_call_count_end) {
                callback(new Error('Please enter call count end'))
            }
            callback()
        },

        urlValidator(rule, value, callback) {
            var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'); // validate port and path
            if (!!urlPattern.test(value)) {
                callback()
            } else {
                callback(new Error('Invalid URL'))
            }
        },
    }
}

let form_validation_mixin = {
    data() {
        return {
            validated: false
        }
    },

    methods: {
        preValidateFormLogic: function (form_name, reset = false, force_validate = false, override = null) {
            let form_element = _.get(this.$refs, form_name, null)

            if (!form_element) {
                return
            }

            if (reset == true) {
                form_element.clearValidate()

                // halt if we don't want to continue the validation
                if (!force_validate) {
                    return
                }
            }

            let fields = form_element.fields
            if (fields.find((f) => f.validateState === 'validating')) {
                setTimeout(() => {
                    this.preValidateFormLogic(form_name)
                }, 100)

                return
            }

            this.validated = this.validateForm(form_name)

            if (override === false && this.validated) {
                this.validated = false
            }
        },

        preValidateForm: _.debounce(function (form_name, reset = false, force_validate = false, override = null) {
            this.preValidateFormLogic(form_name, reset, force_validate, override)
        }, 100),

        preValidateFormNow: function (form_name, reset = false, force_validate = false, override = null) {
            this.preValidateFormLogic(form_name, reset, force_validate, override)
        },

        validateForm(form_name) {
            let form_element = _.get(this.$refs, form_name, null)

            if (!form_element) {
                return false
            }

            let res = null
            form_element.validate((valid) => {
                res = valid ? true : false
            })

            if (res) {
                form_element.clearValidate()
            }

            return res
        },

        resetForm(form_name) {
            let form_element = _.get(this.$refs, form_name, null)

            if (!form_element) {
                return
            }

            form_element.resetFields()
            setTimeout(() => {
                form_element.clearValidate()
            }, 100)
        },

        transformUrl(value) {
            let url = value.trim().toLowerCase();

            if (['http', 'http:', 'http:/', 'https', 'https:', 'https:/'].includes(url) || !url || url === 'https://') {
                return '';
            }

            if (url.startsWith('http://')) {
                url = url.replace('http://', 'https://');
            } else if (!url.startsWith('https://')) {
                url = 'https://' + url;
            }

            return url;
        }
    }
}

let styling_mixin = {
    computed: {
        ...mapState(['top_offset'])
    }
}

let phone_wizard_mixin = {
    data() {
        return {
            country_tooltip: null,
            local_tooltip: null,
            mobile_tooltip: null
        }
    },
    methods: {
        disableCountry(country_code) {
            // Only check GB country code for now.
            if (country_code !== 'GB') {
                return false
            }

            // If the company cannot purchase any UK number.
            if (!this.canBuyUkNumbers) {
                this.country_tooltip = 'An Approved UK Regulatory Bundle & Address are required to purchase UK Numbers.'
                return true
            }

            return false
        },
        shouldDisableLocalPresenceOption(country_code) {
            // Only check GB country code for now.
            if (country_code !== 'GB') {
                return false
            }

            // If the company has no GB Address & GB Regulatory Compliance, disable the option
            if (!this.canBuyUkLocalNumbers) {
                this.country_tooltip = 'An Approved UK Regulatory Bundle & Address is required to purchase UK Local Presence Package.'
                return true
            }

            this.country_tooltip = null

            return false
        },
        shouldDisableLocalOption(country_code) {
            // Only check GB country code for now.
            if (country_code !== 'GB') {
                return false
            }

            // If the company has no GB Address & GB Regulatory Compliance, disable the option
            if (!this.canBuyUkLocalNumbers) {
                this.local_tooltip = 'An Approved UK Regulatory Bundle & Address is required to purchase UK Local Numbers.'
                return true
            }

            this.local_tooltip = null

            return false
        },
        shouldDisableMobileOption(country_code) {
            // Only check GB country code for now.
            if (country_code !== 'GB') {
                return false
            }

            // If the company has no GB Address & GB Regulatory Compliance, disable the option
            if (!this.canBuyUkMobileNumbers) {
                this.mobile_tooltip = 'An Approved UK Mobile Regulatory Bundle & Address is required to purchase UK Mobile Numbers.'
                return true
            }

            this.mobile_tooltip = null

            return false
        },
    }
}


let goback_mixin = {
    computed: {
        canGoBack() {
            return window.history.length > 1
        }
    },

    methods: {
        goBack: function () {
            if (this.canGoBack) {
                this.$router.go(-1)
            } else {
                this.$router.push({name: 'Dashboard'})
            }
        }
    }
}

let acl_mixin = {

    mixins: [goback_mixin],

    methods: {
        /*
        * Updated on 08/15/2024
        * Campaign Permissions:
        *
        * 'list campaign'
        * 'view campaign'
        * 'create campaign'
        * 'update campaign'
        * 'archive campaign'
        * 'restore campaign'
        * 'toggle active status campaign'
        * 'upload file campaign'
        * 'delete file campaign'

        * Communication Permissions:
        *
        * 'list communication'
        * 'view communication'
        * 'tag communication'
        * 'note communication'

        * Company Permissions:
        *
        * 'list company'
        * 'view company'
        * 'create company'
        * 'update company'
        * 'archive company'
        * 'restore company'
        * 'change current company'
        * 'change integration settings company'
        * 'see integration details company'

        * Contact Permissions:
        *
        * 'list contact'
        * 'view contact'
        * 'update contact'
        * 'archive contact'
        * 'restore contact'
        * 'toggle block contact'

        * Filter Permissions:
        *
        * 'list filter'
        * 'view filter'
        * 'create filter'
        * 'update filter'
        * 'archive filter'
        * 'restore filter'

        * Report Permissions:
        *
        * 'list report'

        * Tag Permissions:
        *
        * 'list tag'
        * 'view tag'
        * 'create tag'
        * 'update tag'
        * 'delete tag'

        * User Permissions:
        *
        * 'list user'
        * 'view user'
        * 'create user'
        * 'update user'
        * 'archive user'
        * 'restore user'
        * 'toggle active user'

        * Telephony Permissions:
        *
        * 'send sms'
        * 'send fax'
        * 'answer call'
        * 'make call'

        * Billing Permissions:
        *
        * 'add credit card'
        * 'change plan'
        * 'buy credits'
        * 'see usage page'
        * 'see chargebee portal'
        *

        * Team Permissions:
        * 'list team'
        */
        hasPermissionTo(permissions, source = null) {
            // this has been added to provide us with a way to check permissions in beforeRouteEnter
            if (!source) {
                // check if the user has any permissions
                if (!this.auth || !this.auth.user || !this.auth.user.profile || !this.auth.user.profile.user_permissions) {
                    return false
                }
                source = this.auth.user.profile.user_permissions
            }

            // if user doesn't have permissions
            if (!source) {
                return false
            }

            if (Array.isArray(permissions)) {
                for (let permission of permissions) {
                    if (!source.includes(permission)) {
                        return false
                    }
                }
                return true
            } else {
                return source.includes(permissions)
            }
        },

        isCompanyPartOfAlowareDemoCompanies(onlyProduction = true) {
            const demo_company_ids = localStorage.getItem('aloware_demo_companies')?.split(',')?.map(Number)

            if ((localStorage.getItem('app_env') !== 'production' && onlyProduction) || !demo_company_ids) {
                return true
            }

            return demo_company_ids?.includes(this.current_company?.id)
        },

        hasRole(roles, source = null) {
            // this has been added to provide us with a way to check roles in beforeRouteEnter
            if (!source) {
                // check if the user has any roles
                if (!this.auth || !this.auth.user || !this.auth.user.profile || !this.auth.user.profile.user_roles) {
                    return false
                }
                source = this.auth.user.profile.user_roles
            }

            // if user doesn't have roles
            if (!source) {
                return false
            }

            if (Array.isArray(roles)) {
                for (let role of roles) {
                    if (source.includes(role)) {
                        return true
                    }
                }
                return false
            } else {
                return source.includes(roles)
            }
        },

        hasPerSeatPlanUseCase() {
            return ['iPro', 'uPro', 'xPro'].includes(this.usage.plan.use_case)
        },

        shouldShowUpgradeNow() {
            return !this.isSimpSocial
        },

        logout() {
            this.setAppLoading(true)
            let user_id = localStorage.getItem('previous_user_id')
            if (this.is_impersonated && user_id) {
                this.auth.impersonate(user_id).then(res => {
                    localStorage.removeItem('impersonate')
                    localStorage.removeItem('previous_user_id')
                    this.setAppLoading(false)
                    this.logoutUser()
                }).catch(err => {
                    this.setAppLoading(false)
                    console.log(err)
                })
            } else {
                auth.logout().then(res => {
                    this.response = res.data
                    this.resetCache()
                    localStorage.removeItem('impersonate')
                    localStorage.removeItem('previous_user_id')
                    localStorage.removeItem('is_admin_impersonating')
                    this.setAppLoading(false)
                    this.logoutUser()
                }).catch(err => {
                    this.response = err.response.data.error
                    this.setAppLoading(false)
                })
            }
        },

        simpsocialRedirectToTalkPage(to, blank = true, routeName, href) {
            const talk2Url = this.getTalkUrl()

            if ([routeName].includes(to.name)) {
                const data = this.$router.resolve(to)
                const url = talk2Url + (href ? href : data.href)
                blank ? window.open(url, '_blank') : window.location.href = url;

                return true
            }

            return false
        },

        getTalkUrl() {
            let env = localStorage.getItem('app_env')
            let talk2Url = localStorage.getItem('talk_url')

            const noodle = env !== 'production' ? 'alodev.org' : 'aloware'
            const replacement = env !== 'production' ? 'aloreseller.com' : 'simpsocial'

            talk2Url = this.isSimpSocial ? talk2Url.replace(noodle, replacement) : talk2Url

            return talk2Url
        },

        ...mapActions(['setAppLoading', 'logoutUser'])
    },

    computed: {
        ...mapState('cache', ['current_company']),

        ...mapState(['usage']),

        ...mapGetters('cache', ['isSimpSocial']),

        isAdmin() {
            return this.hasRole(Roles.COMPANY_ADMIN)
        },

        isSupervisor() {
            return this.hasRole('Company Supervisor')
        },

        isAdminOrSupervisor() {
            return this.hasRole('Company Admin') || this.hasRole('Company Supervisor')
        },

        isAdminOrAgentOrSupervisor() {
            return this.hasRole('Company Admin') || this.hasRole('Company Agent') || this.hasRole('Company Supervisor')
        },

        hasWallboardAccess() {
            if (this.hasReporterAccess) {
                return false
            }

            return true
        },

        hasReporterAccess() {
            return this.auth && this.auth.user && this.auth.user.profile && this.auth.user.profile.read_only_access
        },

        isResold() {
            return this.current_company && this.current_company.reseller_id
        },

        shouldDisplayTranscriptionSections() {
            // routes where sections should be shown
            let display_on = [
                'Line Activity',
                'Ring Group Activity',
                'User Activity',
            ]
            // check if sections should be displayed in view
            if (display_on.includes(this.$route.name) && !this.isSimpSocial) {
                return true
            }
            return false
        },

        /**
         * Decides if the AloAi menu should be shown.
         *
         * @return {boolean}
         */
        shouldShowAloAiMenu() {
            // disable it for SimpSocial
            if (this.isSimpSocial) {
                return false
            }

            if (this.hasRole(Roles.COMPANY_ADMIN)) {
                return true
            }

            return false
        },

        /**
         * Decides if the AloVoiceAi should be shown.
         *
         * @return {boolean}
         */
        shouldShowAloVoiceAiMenu() {
            // disable it for SimpSocial
            return !this.isSimpSocial
        },

        /**
         * Decides if the Broadcast should be shown.
         *
         * @return {boolean}
         */
        shouldShowBroadcast() {
            return (this.current_company?.bulk_sms_enabled || this.current_company?.bulk_rvm_enabled)
                && this.current_company?.sms_enabled
                && this.hasPermissionTo(['create broadcast message', 'create broadcast rvm', 'update broadcast'])
                && !this.isSupervisor
        },

        /**
         * Decides if the Sequences should be shown.
         *
         * @return {boolean}
         */
        shouldShowSequences() {
            return this.current_company?.automation_enabled
        },

        shouldSeeSequences() {
            if (!this.hasPermissionTo('view workflow')) {
                return false
            }

            if (!this.hasRole('Company Admin') && !this.hasRole('Company Supervisor')) {
                return false
            }

            if (this.hasReporterAccess) {
                return false
            }

            if (this.current_company && this.current_company.reseller_id == 357 && !this.hasRole('Billing Admin')) {
                return false
            }

            return true
        },

        /**
         * Decides if the AloAi list should be shown.
         *
         * @return {boolean}
         */
        shouldShowAloAi() {
            // disable it for SimpSocial
            if (this.isSimpSocial) {
                return false
            }

            // disable it for supervisors
            if (this.isSupervisor) {
                return false
            }

            if (this.current_company?.aloai_enabled) {
                return true
            }

            return false
        },

        isWhitelabelAccount() {
            return this.current_company?.is_whitelabel
        },

        canBuyUkLocalNumbers() {
            return this.current_company.can_buy_uk_local_numbers
        },

        canBuyUkMobileNumbers() {
            return this.current_company.can_buy_uk_mobile_numbers
        },

        canBuyUkNumbers() {
            return this.canBuyUkLocalNumbers || this.canBuyUkMobileNumbers
        },

        isStartupPackage(){
            return STARTUP_PACKAGES.includes(this.current_company.plan?.name)
        },
    }
}

let avatar_mixin = {
    computed: {
        ...mapState(['disposition_statuses']),
        ...mapState('cache', ['current_company'])
    },

    data() {
        return {
            blue_disposition_status: null,
            green_disposition_statuses: []
        }
    },

    created() {
        let dispositionMatchStatusIds = []
        let appointmentSetStatus = null

        for (let dispositionStatus of this.disposition_statuses) {
            // check for blue disposition status
            if (dispositionStatus.name === 'Appointment-Set') {
                appointmentSetStatus = dispositionStatus
            }

            // check for green disposition status
            if (dispositionStatus.name === 'Appointment-Showed' || dispositionStatus.name.toLowerCase().startsWith('sold')) {
                dispositionMatchStatusIds.push(dispositionStatus.id)
            }
        }

        this.blue_disposition_status = appointmentSetStatus
        this.green_disposition_statuses = dispositionMatchStatusIds
    },

    methods: {
        daysPassedSinceCreated(contact) {
            if (!contact.created_at) {
                return 0
            }

            let createdAtDate = moment(contact.created_at)
            let now = moment()

            return now.diff(createdAtDate, 'days')
        },

        avatarTooltip(contact, communication = null) {
            const avatarStyle = this.avatarStyle(contact, communication)

            if ((communication !== null && communication.direction === CommunicationDirection.OUTBOUND) || !avatarStyle) {
                return ''
            }

            switch (avatarStyle.backgroundColor) {
                // Red = New unanswered lead
                case '#FF0000':
                    return 'New unanswered lead'
                // Orange = Answered lead that came in 7 days or newer
                case '#FFA500':
                    return 'Answered lead that came in 7 days or newer'
                // Yellow = Answered lead that came in between 8 to 30 days
                case '#FFFF00':
                    return 'Answered lead that came in between 8 to 30 days'
                // Blue = Pending appointment
                case '#0000FF':
                    return 'Pending appointment'
                // Green = Sold
                case '#00FF00':
                    return 'Sold'
                // Grey = Default Color
                default:
                    return ''
            }
        },

        avatarStyle(contact, communication = null) {
            if (!contact.name) {
                return {}
            }

            // default avatar color grey
            let bg = '#D3D3D3'

            // check if agent
            if (communication !== null && communication.direction === CommunicationDirection.OUTBOUND) {
                return {
                    backgroundColor: bg,
                    color: this.overlayColor(bg),
                    cursor: 'auto'
                }
            }

            // unanswered lead
            if ((contact.unread_count > 0 || contact.unread_missed_call_count > 0 || contact.unread_voicemail_count > 0)) {
                // Red color for unanswered lead
                bg = '#FF0000'
            }

            // answered lead
            if ((contact.unread_count === 0 && contact.unread_missed_call_count === 0 && contact.unread_voicemail_count === 0) && contact.last_engagement_at !== null) {
                const daysPassedSinceCreated = this.daysPassedSinceCreated(contact)

                if (daysPassedSinceCreated <= 7) {
                    // Orange color for leads that came in 7 days and newer
                    bg = '#FFA500'
                } else if (daysPassedSinceCreated >= 8 && daysPassedSinceCreated <= 30) {
                    // Yellow color for leads that came in for 8 to 30 days
                    bg = '#FFFF00'
                }
            }

            if (contact.disposition_status_id !== null) {
                // Limit blue and green for this company
                if (this.current_company && this.current_company.reseller_id === 357) {
                    // find disposition status: “Appointment-Set”
                    const appointmentDispositionStatus = this.blue_disposition_status

                    // check if contact disposition status is Appointment-Set
                    if (appointmentDispositionStatus && contact.disposition_status_id === appointmentDispositionStatus.id) {
                        // Blue color #0000FF for appointment set
                        bg = '#0000FF'
                    }

                    // find disposition status: “Appointment-Showed” or "Sold"
                    const appointmentShowedOrSoldDispositionIds = this.green_disposition_statuses

                    // check if contact disposition status is “Appointment-Showed” or "Sold"
                    if (appointmentShowedOrSoldDispositionIds.length !== 0 && appointmentShowedOrSoldDispositionIds.includes(contact.disposition_status_id)) {
                        // Green color #00FF00 for appointment showed or sold
                        bg = '#00FF00'
                    }
                }
            }

            return {
                backgroundColor: bg,
                color: this.overlayColor(bg),
                cursor: 'pointer'
            }
        },

        intToRGB(i) {
            let c = (i & 0x00FFFFFF)
                .toString(16)
                .toUpperCase()

            return "#" + "00000".substring(0, 6 - c.length) + c
        },

        hashCode(str) {
            let hash = 0
            for (let i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash)
            }
            return hash
        },

        overlayColor(color) {
            // if only first half of color is defined, repeat it
            if (color.length < 5) {
                color += color.slice(1)
            }
            return (color.replace('#', '0x')) > (0xffffff / 2) ? '#333' : '#fff'
        }
    }
}

let campaign_mixin = {
    data() {
        return {
            ten_dlc_lines: [],
            selected_lines_to_attach: [],
            selected_lines_to_detach: [],
            reason: ''
        }
    },
    methods: {
        possibleToAttachLine(messaging_service) {
            // If line type is valid and number count is less than 400, then it is possible to attach
            if (this.isLineTypeValid(messaging_service) && (this.isNumberCountLessThan400(messaging_service) || this.canTakeActionOnRegisteredLines)) {
                return true
            }

            return false
        },
        isLineTypeValid(messaging_service) {
            // Starting from April 5th, 2024, Local Presence Lines will be required to have a separate A2P Campaign
            if (!this.isCompanyNetNew) {
                return true
            }

            // If both line types are selected, then it is not possible to attach
            if (this.selectedLinesHaveLocalPresence() && this.selectedLinesDoesntHaveLocalPresence()) {
                this.reason = 'You cannot combine Local Presence & Non-Local Presence Line in a single A2P Campaign.'
                return false
            }

            // If messaging service has no lines, then it is possible to attach any line type
            if (messaging_service.lines.length === 0) {
                return true
            }

            // If selected lines have local presence, but the messaging service does not have local presence
            if (this.selectedLinesHaveLocalPresence() && !this.messagingServiceHasLocalPresenceLines(messaging_service)) {
                this.reason = 'You can only attach a Non-Local Presence Line to this A2P Campaign.'
                return false
            }

            // If selected lines do not have local presence, but the messaging service has local presence
            if (!this.selectedLinesHaveLocalPresence() && this.messagingServiceHasLocalPresenceLines(messaging_service)) {
                this.reason = 'You can only attach a Local Presence Line to this A2P Campaign.'
                return false
            }

            return true
        },
        isNumberCountLessThan400(messaging_service) {
            if (messaging_service.incoming_numbers.length <= 400) {
                return true
            }

            this.reason = 'You cannot attach more than 400 numbers to a campaign'
            return false
        },
        messagingServiceHasLocalPresenceLines(messaging_service) {
            return messaging_service.lines.some(line => line.has_local_presence)
        },
        selectedLinesHaveLocalPresence() {
            if (this.ten_dlc_lines.some(line => this.selected_lines_to_attach.includes(line.id) && line.has_local_presence)) {
                return true
            }

            return false
        },
        selectedLinesDoesntHaveLocalPresence() {
            if (this.ten_dlc_lines.some(line => this.selected_lines_to_attach.includes(line.id) && !line.has_local_presence)) {
                return true
            }

            return false
        },
        messagingServiceFormattedUseCase(use_case) {
            // if we don't have a use case, return empty string
            if (!use_case) {
                return ''
            }

            // Replace underscores with spaces
            let a2p_use_case_name = use_case.replace(/_/g, ' ')
            // Lowercase before capitalizing first letters
            a2p_use_case_name = a2p_use_case_name.toLowerCase()
            // Capitalize first letters
            a2p_use_case_name = a2p_use_case_name.replace(/\b\w/g, l => l.toUpperCase())

            return a2p_use_case_name
        },
        isUkNumber(phone_number) {
            let code = this.$options.filters.getCountryCode(phone_number)

            // We check the code for UK
            if (code === 44) {
                return true
            }

            return false
        },
        ukNumberTag(incoming_number) {
            let is_uk_number = this.isUkNumber(incoming_number.phone_number)

            // We don't need to tag other countries as of now.
            if (!is_uk_number) {
                return '-'
            }

            // All UK mobile numbers are SMS capable
            if (incoming_number.is_sms_capable) {
                return 'UK Mobile'
            }

            return 'UK Local'
        },
        lineHasUkNumbers(line) {
            // check we have any incoming number that has is_local flag set to true
            return line.incoming_numbers?.length > 0 && line.incoming_numbers.some(number => this.isUkNumber(number.phone_number))
        },
        ukLineTag(line) {
            let is_uk_line = this.lineHasUkNumbers(line)

            // We don't need to tag other countries as of now.
            if (!is_uk_line) {
                return '-'
            }

            let sms_uk_number = line.incoming_numbers.some(number => this.isUkNumber(number.phone_number) && number.is_sms_capable)

            // All UK mobile numbers are SMS capable
            if (sms_uk_number) {
                return 'UK Mobile'
            }

            return 'UK Local'
        },

        findFirstUserPhoneNumberFromUsers(user_teams, users) {
            if (!user_teams.user_ids || user_teams.user_ids.length === 0) {
                return null;
            }

            const user_id = user_teams.user_ids[0]
            const user = users.find(o => o.id === user_id)
            if (user && user.phone_number) {
                return user.phone_number
            }

            return null
        },

        findFirstUserPhoneNumberFromTeams(user_teams, allTeams) {
            for (let team_id of user_teams.team_ids) {
                const team = allTeams.find(t => t.id === team_id)
                if (team && team.users && team.users.length > 0) {
                    for (const user of team.users) {
                        if (user.phone_number) {
                            return user.phone_number
                        }
                    }
                }
            }

            return null
        },

        getCampaignRingGroupOrderedUserTeamIds(campaign, ring_groups) {
            const ring_group = ring_groups.find(ring_group => ring_group.id == campaign.ring_group_id)

            if (!ring_group) {
                return null
            }

            if (!ring_group.ordered_user_ids && !ring_group.ordered_team_ids) {
                return null
            }

            return {
                user_ids: ring_group.ordered_user_ids,
                team_ids: ring_group.ordered_team_ids
            }
        },

        getCampaignUserObject(campaign, users) {
            const user_id = campaign.user_id
            if (!user_id) {
                return null
            }

            const user = users.find(o => o.id === user_id)
            if (user) {
                return user
            }

            return null
        },

        getCampaignFirstUserPhoneNumber(campaign, ring_groups, users, allTeams, shouldRouteToUser = false) {
            if (campaign.ring_group_id) {
                const user_teams = this.getCampaignRingGroupOrderedUserTeamIds(campaign, ring_groups)

                if (user_teams) {
                    let user_phone_number = null

                    if (user_teams.user_ids && user_teams.user_ids.length > 0) {
                        user_phone_number = this.findFirstUserPhoneNumberFromUsers(user_teams, users)

                        if (user_phone_number) {
                            return user_phone_number
                        }
                    }

                    if (user_teams.team_ids && user_teams.team_ids.length > 0) {
                        user_phone_number = this.findFirstUserPhoneNumberFromTeams(user_teams, allTeams)

                        if (user_phone_number) {
                            return user_phone_number
                        }
                    }
                }
            }

            // route to user
            if (shouldRouteToUser && campaign.user_id) {
                return this.getCampaignUserObject(campaign, users)
            }

            return null
        }
    },
    computed: {
        ...mapState(['unregistered_lines']),
        canTakeActionOnRegisteredLines() {
            return this.selected_lines_to_detach.length > 0
        },
        selectedLinesToAttachIncomingNumbersCount() {
            // find all selected lines and sum their incoming_numbers_count
            const selected_lines_to_attach = this.unregistered_lines.filter(({id}) =>
                this.selected_lines_to_attach.includes(id)
            )
            return selected_lines_to_attach.reduce((total, line) => {
                return total + line.incoming_numbers
            }, 0)
        },
        lineHasMessagingService() {
            return this.campaign.messaging_service ? true : false
        },
        lineMessagingService() {
            return this.campaign?.messaging_service
        },
        isCompanyNetNew() {
            return this.current_company.is_net_new
        }
    }
}

let paginator_mixin = {
    data() {
        return {
            pagination: {
                current_page: 1,
                per_page: 20
            },
            // this should be overridden by the component extending this mixin
            dataKey: ''
        }
    },
    computed: {
        pageCount() {
            return Math.ceil(_.get(this, this.dataKey).length / this.pagination.per_page)
        },

        paginatedData() {
            let page_number = this.pagination.current_page - 1
            if (this.dataSort) {
                if (this.dataSortType) {
                    switch (this.dataSortType) {
                        case 'ASC':
                            return _.get(this, this.dataKey).sort((a, b) => parseFloat(a[this.dataSort]) - parseFloat(b[this.dataSort])).slice(page_number * this.pagination.per_page, (page_number + 1) * this.pagination.per_page)
                        case 'DESC':
                            return _.get(this, this.dataKey).sort((a, b) => parseFloat(b[this.dataSort]) - parseFloat(a[this.dataSort])).slice(page_number * this.pagination.per_page, (page_number + 1) * this.pagination.per_page)
                    }
                }

                return _.get(this, this.dataKey).sort((a, b) => parseFloat(b[this.dataSort]) - parseFloat(a[this.dataSort])).slice(page_number * this.pagination.per_page, (page_number + 1) * this.pagination.per_page)
            }
            return _.get(this, this.dataKey).slice(page_number * this.pagination.per_page, (page_number + 1) * this.pagination.per_page)
        },

        dataCount() {
            return _.get(this, this.dataKey).length
        }
    },
    methods: {
        changePage(page) {
            this.pagination.current_page = page
        },

        handleSizeChange(val) {
            this.pagination.per_page = val
        }
    }
}

let communication_mixin = {
    data() {
        return {
            auth
        }
    },
    computed: {
        ...mapState(['ring_groups']),
    },
    methods: {
        getRingGroup(id) {
            if (!id) {
                return null
            }
            let found = this.ring_groups.find(ring_group => ring_group.id === id)
            if (found) {
                return found
            }

            return null
        },

        checkCommunicationMatchesUserAccessibility(communication) {
            // check auth exists to prevent js errors
            if (!this.auth || !this.auth.user || !this.auth.user.profile) {
                return false
            }

            // checks if accessible_campaigns is available and then looks for communication campaign_id in that array
            if (this.auth.user.profile.accessible_campaigns && this.auth.user.profile.line_access_limit && communication.campaign_id && !this.auth.user.profile.accessible_campaigns.includes(communication.campaign_id)) {
                return false
            }

            // checks if accessible_users is available and then looks for communication user_id in that array
            if (this.auth.user.profile.accessible_users && this.auth.user.profile.user_access_limit && communication.user_id && !this.auth.user.profile.accessible_users.includes(communication.user_id)) {
                return false
            }

            // checks if accessible_users is available and then looks for an intersection between accessible_users and attempting_users
            if (this.auth.user.profile.accessible_users && this.auth.user.profile.user_access_limit && communication.attempting_users && [CommunicationCurrentStatus.CURRENT_STATUS_TRANSFERRING_NEW, CommunicationCurrentStatus.CURRENT_STATUS_GREETING_NEW].includes(communication.current_status2) && _.intersection(this.auth.user.profile.accessible_users, communication.attempting_users).length === 0) {
                return false
            }

            // checks if communication matches user communication visibility
            if (this.auth.user.profile.communications_visibility === CommunicationAccessTypes.COMMUNICATIONS_OWNED_ONLY && communication.user_id && communication.user_id !== this.auth.user.profile.id) {
                return false
            }

            // ring group only access
            if (this.auth.user.profile.contacts_visibility === ContactAccessTypes.CONTACTS_ACCESS_RING_GROUP) {
                // if user does not have unassigned access
                if (communication.contact && !communication.contact.user_id && !this.auth.user.profile.can_view_unassigned_contacts) {
                    return false
                }

                // if contact does not exist
                if (!communication.contact) {
                    return false
                }

                // @todo for ring group only access (UI doesn't know that contact relationship with ring groups at this stage)
            }

            // team only access
            if (this.auth.user.profile.contacts_visibility === ContactAccessTypes.CONTACTS_ACCESS_RING_GROUP_USERS) {
                // if user does not have unassigned access
                if (communication.contact && !communication.contact.user_id && !this.auth.user.profile.can_view_unassigned_contacts) {
                    return false
                }

                // checks if user is part of the communication's ring group
                if (communication.ring_group_id && !this.auth.user.profile.ring_group_ids.includes(communication.ring_group_id)) {
                    return false
                }

                // checks if communication's contact is owned by the team
                if (communication.contact && communication.contact.user_id) {
                    for (let ring_group_id of this.auth.user.profile.ring_group_ids) {
                        let ring_group = this.getRingGroup(ring_group_id)
                        if (ring_group && ring_group.user_ids.includes(communication.contact.user_id)) {
                            return true
                        }
                    }
                }

                // if contact does not exist
                if (!communication.contact) {
                    return false
                }
            }

            // owned only access
            if (this.auth.user.profile.contacts_visibility === ContactAccessTypes.CONTACTS_ACCESS_OWNED_ONLY) {
                // if user does not have unassigned access
                if (communication.contact && !communication.contact.user_id && !this.auth.user.profile.can_view_unassigned_contacts) {
                    return false
                }

                // checks if communication's contact is owned by the user
                if (communication.contact && communication.contact.user_id && communication.contact.user_id !== this.auth.user.profile.id) {
                    return false
                }

                // if contact does not exist
                if (!communication.contact) {
                    return false
                }
            }

            // jon's agents has limited access to messages and contacts
            if (this.auth.user.profile.company_id === 11 && this.hasRole('Company Reporter Access')) {
                // checks if communication matches contact's user visibility
                if (communication.contact.user_id && communication.contact.user_id !== this.auth.user.profile.id) {
                    return false
                }

                // checks if communication matches user visibility
                if (communication.user_id && communication.user_id !== this.auth.user.profile.id) {
                    return false
                }
            }

            return true
        },

        checkContactMatchesUserAccessibility(contact) {
            // check auth exists to prevent js errors
            if (!this.auth || !this.auth.user || !this.auth.user.profile) {
                return false
            }

            // checks if contact matches user visibility
            if (contact.user_id != this.auth.user.profile.id) {
                return false
            }

            return true
        },

        getSource(communication) {
            if (!communication) {
                return ''
            }

            if (this.isColdTransferUser(communication)) {
                return 'Transfer to User'
            }

            if (this.isColdTransferRg(communication)) {
                return 'Transfer to Ring Group'
            }

            if (this.isIntroduceToUser(communication)) {
                return 'Introduce to User'
            }

            if (this.isIntroduceToRg(communication)) {
                return 'Introduce to Ring Group'
            }

            if (this.isAddToUser(communication)) {
                return 'Add to User'
            }

            if (this.isCallWaiting(communication)) {
                return 'Call Waiting'
            }

            if (this.isAddToRg(communication)) {
                return 'Add to Ring Group'
            }

            if (this.isSequence(communication)) {
                return 'Call from Sequence'
            }

            if (communication.type === CommunicationTypes.CALL) {
                return 'New Inbound Call'
            }
        },

        isSequence(communication) {
            return communication.workflow_id
        },

        isIntroduceToRg(communication) {
            return this.callIsIntroduced(communication) && communication.last_call_source === CommunicationSourceCallTypes.SOURCE_ADD_RG
        },

        isIntroduceToUser(communication) {
            return this.callIsIntroduced(communication) && communication.last_call_source === CommunicationSourceCallTypes.SOURCE_ADD_USER
        },

        isAddToRg(communication) {
            return !this.callIsIntroduced(communication) && communication.last_call_source === CommunicationSourceCallTypes.SOURCE_ADD_RG
        },

        isAddToUser(communication) {
            return !this.callIsIntroduced(communication) && communication.last_call_source === CommunicationSourceCallTypes.SOURCE_ADD_USER
        },

        isCallWaiting(communication) {
            return !this.callIsIntroduced(communication) && communication.last_call_source === CommunicationSourceCallTypes.SOURCE_CALL_WAITING
        },

        callIsIntroduced(communication) {
            return communication.is_introduce
        },

        isColdTransferRg(communication) {
            return communication.last_call_source === CommunicationSourceCallTypes.SOURCE_COLD_RG
        },

        isColdTransferUser(communication) {
            return communication.last_call_source === CommunicationSourceCallTypes.SOURCE_COLD_USER
        },
    }
}

let html_mixin = {
    methods: {
        sanitizeText(string) {
            if (string) {
                let entityMap = {
                    '&': '&amp;',
                    '<': '&lt;',
                    '>': '&gt;',
                    '"': '&quot;',
                    "'": '&#39;',
                    '/': '&#x2F;',
                    '`': '&#x60;',
                    '=': '&#x3D;'
                }
                return String(string).replace(/[&<>"'`=\/]/g, function (s) {
                    return entityMap[s]
                })
            }
            return ''
        },

        setPageTitle(title) {
            document.title = title
        },

        setPageTitleInfoText(info_text) {
            this.setPageTitle(document.title + ' ' + info_text)
        }
    }
}

let communication_info_mixin = {
    methods: {
        stateToIcon(disposition_status, direction, type, callback_status, only_color = false) {
            let color = ''
            let icon = ''
            if (type === 1 || type === 3) {
                if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW) {
                    color = 'text-indigo-500'
                    icon += `<i class="material-icons ${color}">phone_in_talk</i>`
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW) {
                    color = 'text-green-500'
                    icon += `<i class="material-icons ${color}">call_end</i>`
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_ABANDONED_NEW) {
                    color = 'text-purple-500'
                    if (direction === CommunicationDirection.INBOUND && [CallbackStatus.CALLBACK_STATUS_INITIATED, CallbackStatus.CALLBACK_STATUS_REQUESTED].includes(callback_status)) {
                        icon += `<i class="material-icons ${color}">phone_forwarded</i>`
                    } else {
                        icon += `<i class="material-icons ${color}">phone_missed</i>`
                    }
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW) {
                    color = 'text-red-500'
                    icon += `<i class="material-icons ${color}">phone_missed</i>`
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW) {
                    color = 'text-red-500'
                    icon += `<i class="material-icons ${color}">phone_locked</i>`
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_INVALID_NEW) {
                    color = 'text-red-A400'
                    icon += `<i class="material-icons ${color}">phone_locked</i>`
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_DEADEND_NEW) {
                    color = 'text-orange-500'
                    icon += `<i class="material-icons ${color}">block</i>`
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_VOICEMAIL_NEW) {
                    color = 'text-red-500'
                    icon += `<i class="material-icons ${color}">voicemail</i>`
                } else {
                    icon += disposition_status
                }
            } else if (type === 2) {
                // if type is sms
                if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW) {
                    color = 'text-indigo-500'
                    icon += `<i class="material-icons ${color}">sms</i>`
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW) {
                    color = 'text-green-500'
                    icon += `<i class="material-icons ${color}">message</i>`
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW) {
                    color = 'text-red-500'
                    icon += `<i class="material-icons ${color}">sms</i>`
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_INVALID_NEW) {
                    color = 'text-red-A400'
                    icon += `<i class="material-icons ${color}">sms</i>`
                } else {
                    icon += disposition_status
                }
            } else if (type === 5) {
                // if type is sms
                if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW) {
                    color = 'text-indigo-500'
                    icon += `<i class="material-icons ${color}">email</i>`
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW) {
                    color = 'text-green-500'
                    icon += `<i class="material-icons ${color}">email</i>`
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW) {
                    color = 'text-red-500'
                    icon += `<i class="material-icons ${color}">email</i>`
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_INVALID_NEW) {
                    color = 'text-red-A400'
                    icon += `<i class="material-icons ${color}">email</i>`
                } else {
                    icon += disposition_status
                }
            } else if (type === 8) {
                // if type is fax
                if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW) {
                    color = 'text-indigo-500'
                    icon += `<i class="fa fa-fax ${color}"></i>`
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW) {
                    color = 'text-green-500'
                    icon += `<i class="fa fa-fax ${color}"></i>`
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW) {
                    color = 'text-red-500'
                    icon += `<i class="fa fa-fax ${color}"></i>`
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_ABANDONED_NEW) {
                    color = 'text-purple-500'
                    icon += `<i class="fa fa-fax ${color}"></i>`
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW) {
                    color = 'text-red-500'
                    icon += `<i class="fa fa-fax ${color}"></i>`
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_INVALID_NEW) {
                    color = 'text-red-A400'
                    icon += `<i class="fa fa-fax ${color}"></i>`
                } else {
                    icon += disposition_status
                }
            } else if ([10, 11].includes(type)) {
                // if type is note or sys note
                color = 'text-green-500'
                icon += `<i class="material-icons ${color}">event_note</i>`
            } else if (type === 12) {
                // if type is appointment
                color = 'text-green-500'
                icon += `<i class="material-icons ${color}">alarm_add</i>`
            } else if (type === 13) {
                // if type is reminder
                color = 'text-green-500'
                icon += `<i class="material-icons ${color}">access_alarms</i>`
            } else {
                // if type is ringless voicemail
                if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW) {
                    color = 'text-indigo-500'
                    icon += `<i class="material-icons ${color}">voicemail</i>`
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW) {
                    color = 'text-green-500'
                    icon += `<i class="material-icons ${color}">voicemail</i>`
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW) {
                    color = 'text-red-500'
                    icon += `<i class="material-icons ${color}">voicemail</i>`
                } else if (disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_INVALID_NEW) {
                    color = 'text-red-A400'
                    icon += `<i class="material-icons ${color}">voicemail</i>`
                } else {
                    icon += disposition_status
                }
            }

            if (direction == 1 && ![10, 11, 12, 13].includes(type)) {
                icon += `<i class="material-icons ${color}">call_received</i>`
            }

            if (direction == 2 && ![10, 11, 12, 13].includes(type)) {
                icon += `<i class="material-icons ${color}">call_made</i>`
            }

            if (only_color) {
                return color.replace('text-', '')
            }

            return icon
        },

        rejectionToIcon(rejection_reason) {
            switch (rejection_reason) {
                case CommunicationRejectionReasons.REJECTION_REASON_BLOCKED:
                    return '<i class="material-icons text-danger">phone_locked</i>'
                case CommunicationRejectionReasons.REJECTION_REASON_CREDITS:
                    return '<i class="material-icons text-danger">money_off</i>'
                case CommunicationRejectionReasons.REJECTION_REASON_OTHER:
                    return '<i class="material-icons text-danger">warning</i>'
                case CommunicationRejectionReasons.REJECTION_REASON_USER_NOT_FOUND:
                    return '<i class="material-icons text-danger">error</i>'
                case CommunicationRejectionReasons.REJECTION_REASON_FAILED:
                    return '<i class="material-icons text-danger">error</i>'
                default:
                    return '<i class="material-icons text-danger">warning</i>'
            }
        },

        rejectionTooltipData(rejection_reason, type) {
            switch (rejection_reason) {
                case CommunicationRejectionReasons.REJECTION_REASON_BLOCKED:
                    return 'The contact was blocked.'
                case CommunicationRejectionReasons.REJECTION_REASON_CREDITS:
                    return 'Account didn\'t have enough credits.'
                case CommunicationRejectionReasons.REJECTION_REASON_OTHER:
                    return 'Please contact support.'
                case CommunicationRejectionReasons.REJECTION_REASON_USER_NOT_FOUND:
                    return 'No eligible users could be found.'
                case CommunicationRejectionReasons.REJECTION_REASON_FAILED:
                    return 'Our carrier could not route this communication.'
                case CommunicationRejectionReasons.REJECTION_REASON_ANONYMOUS_CONTACT:
                    let placeHolder = 'send a message'
                    switch (type) {
                        case CommunicationTypes.CALL:
                            placeHolder = 'make a call'
                            break
                        case CommunicationTypes.RVM:
                            placeHolder = 'send an RVM'
                            break
                        case CommunicationTypes.EMAIL:
                            placeHolder = 'send an email'
                            break
                        case CommunicationTypes.FAX:
                            placeHolder = 'send a fax'
                            break
                    }
                    return `Cannot ${placeHolder} to an anonymous contact.`
                case CommunicationRejectionReasons.REJECTION_REASON_TRAFFIC_BLOCKED:
                    return 'SMS traffic was blocked by carriers.'
                case CommunicationRejectionReasons.REJECTION_REASON_NOT_MESSAGING_ENABLED:
                    return 'Contact phone number was not messaging enabled.'
                case CommunicationRejectionReasons.REJECTION_REASON_CAMPAIGN_DELETED:
                    return 'Line was deleted.'
                case CommunicationRejectionReasons.REJECTION_REASON_CAMPAIGN_PAUSED:
                    return 'Line was paused.'
                case CommunicationRejectionReasons.REJECTION_REASON_CAMPAIGN_PROXY:
                    return 'Line was proxied.'
                case CommunicationRejectionReasons.REJECTION_REASON_CONTACT_DNC:
                    return 'Contact was DNC.'
                case CommunicationRejectionReasons.REJECTION_REASON_PHONE_NUMBER_OPTED_OUT:
                    return 'Contact was opted out from receiving messages.'
                case CommunicationRejectionReasons.REJECTION_REASON_CONTACT_IS_NOT_TCPA_APPROVED:
                    return 'Contact was non-TCPA Approved.'
                case CommunicationRejectionReasons.REJECTION_REASON_COMPANY_DISABLED:
                    return 'Company was not enabled.'
                case CommunicationRejectionReasons.REJECTION_REASON_MESSAGE_EMPTY:
                    return 'Message body was required.'
                case CommunicationRejectionReasons.REJECTION_REASON_NUMBER_IS_INTERNATIONAL:
                    return 'The contact phone number was international.'
                case CommunicationRejectionReasons.REJECTION_REASON_FAX_NUMBER_NOT_FOUND:
                    return 'We couldn\'t send a fax from a non-fax capable number.'
                case CommunicationRejectionReasons.REJECTION_REASON_INVALID_OR_WRONG_PHONE_NUMBER:
                    return 'Phone number was wrong or invalid.'
                case CommunicationRejectionReasons.REJECTION_REASON_TOLLFREE_NUMBER:
                    return 'Phone number was toll-free.'
                case CommunicationRejectionReasons.REJECTION_REASON_USER_NOT_PERMITTED_TO_MAKE_CALL:
                    return 'Received a call from a user without the permission to make an outbound call.'
                case CommunicationRejectionReasons.REJECTION_REASON_USER_NOT_ACTIVE:
                    return 'Received a call from a paused user.'
                case CommunicationRejectionReasons.REJECTION_REASON_NO_POWER_DIALER_TASK_REMAINING:
                    return 'There were no power dialer tasks remaining to run.'
                case CommunicationRejectionReasons.REJECTION_REASON_POWER_DIALER_TASK_NOT_QUEUED:
                    return 'Tried to run a power dialer task that is not queued.'
                case CommunicationRejectionReasons.REJECTION_REASON_CAMPAIGN_NOT_FOUND:
                    return 'Couldn\'t find an outbound line for this call.'
                case CommunicationRejectionReasons.REJECTION_REASON_INCOMING_NUMBER_NOT_FOUND:
                    return 'Couldn\'t find an outbound incoming number for this call.'
                case CommunicationRejectionReasons.REJECTION_REASON_INVALID_USER:
                    return 'Received a call from an undefined user.'
                case CommunicationRejectionReasons.REJECTION_REASON_INVALID_LINK_FORMAT:
                    return '[From HubSpot] The link format was incorrect.'
                case CommunicationRejectionReasons.REJECTION_REASON_CONNECTED_HS_ACCOUNT_NOT_FOUND:
                    return '[From HubSpot] Could not find the connected HubSpot account.'
                case CommunicationRejectionReasons.REJECTION_REASON_HS_CONTACT_NOT_FOUND:
                    return '[From HubSpot] Could not find the associated contact with the HubSpot deal.'
                case CommunicationRejectionReasons.REJECTION_REASON_HS_INVALID_PHONE_NUMBER:
                    return '[From HubSpot] Could not find a phone number associated with the contact on the HubSpot deal.'
                case CommunicationRejectionReasons.REJECTION_REASON_DAILY_LIMIT_EXCEEDED:
                    return 'Daily outbound messages limit exceeded.'
                case CommunicationRejectionReasons.REJECTION_REASON_ACCOUNT_DISABLED_MESSAGING:
                    return 'Account messaging capability is disabled.'
                case CommunicationRejectionReasons.REJECTION_REASON_COMPANY_DELETED:
                    return 'Company was deleted.'
                case CommunicationRejectionReasons.REJECTION_REASON_COMPANY_SUSPENDED:
                    return 'Company was suspended.'
                case CommunicationRejectionReasons.REJECTION_REASON_LINE_IS_SPAMMING:
                    return 'SPAM Detected: Stopped sending the same message multiple times from the same line.'
                case CommunicationRejectionReasons.REJECTION_REASON_SHORTCODE_TO_NON_US_NUMBER:
                    return 'Short Code is not allowed to send messages to non-US numbers.'
                case CommunicationRejectionReasons.REJECTION_REASON_LRN_TYPE_IS_LANDLINE:
                    return 'Landlines are not capable of receiving SMS/MMS.'
                case CommunicationRejectionReasons.REJECTION_REASON_MESSAGING_DISABLED:
                    return 'Messaging is disabled for this line.'
                case CommunicationRejectionReasons.REJECTION_REASON_CALL_TO_SELF_NUMBER:
                    return 'You cannot place a call to your own number.'
                case CommunicationRejectionReasons.REJECTION_REASON_TRIAL_CALLS_LIMIT_EXCEEDED:
                    return `You have reached the maximum number of trial calls.`;
                case CommunicationRejectionReasons.REJECTION_REASON_SMS_TO_SELF_NUMBER:
                    return 'You cannot send a text to your own number.'
                case CommunicationRejectionReasons.REJECTION_REASON_PROVIDER_ALLOWS_ONLY_ONE_ATTACHMENT:
                    return 'Provider allows only one attachment.'
                case CommunicationRejectionReasons.REJECTION_REASON_NOT_MULTIMEDIA_MESSAGING_ENABLED:
                    return 'The selected number cannot send an MMS. Use an MMS-capable number or remove media attachments and try again.'
                case CommunicationRejectionReasons.REJECTION_REASON_EMERGENCY_NUMBER:
                    return 'Emergency calling is not allowed.'
                case CommunicationRejectionReasons.REJECTION_REASON_KYC_CALLS_OUTBOUND_RESTRICTION:
                    return 'Your trial plan does not allow making calls to this number. Submit your business information for verification to unlock calling features to other numbers.'
            }
        },

        getAttemptingClass(attempting_user, disposition_status, user_id) {
            if (user_id && user_id == attempting_user && disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW) {
                return 'text-dark-greenish'
            } else if (user_id && user_id != attempting_user && disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW) {
                return 'text-muted'
            } else if (user_id && user_id != attempting_user && disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW) {
                return 'text-muted'
            } else if (user_id && user_id == attempting_user && disposition_status == CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW) {
                return 'text-indigo-500'
            } else {
                return 'text-danger'
            }
        },

        dispositionTooltipData(disposition_status, direction, type, callback_status) {
            if (direction === CommunicationDirection.INBOUND && [CallbackStatus.CALLBACK_STATUS_INITIATED, CallbackStatus.CALLBACK_STATUS_REQUESTED].includes(callback_status)) {
                return this.$options.filters.capitalize(this.$options.filters.replaceDash(this.$options.filters.translateCallbackStatusText(callback_status)))
            }

            return this.$options.filters.capitalize(this.$options.filters.replaceDash(this.$options.filters.translateDispositionStatusText(disposition_status))) + ' ' + this.$options.filters.fixCommDirection(direction) + ' ' + this.$options.filters.fixCommType(type)
        },

        isAttachmentImage(mime_type) {
            return mime_type.includes('image/')
        },

        isAttachmentVideo(mime_type) {
            return mime_type.includes('video/')
        },

        isAttachmentAudio(mime_type) {
            return mime_type.includes('audio/')
        },

        isAttachmentText(mime_type) {
            return mime_type.includes('text/')
        },

        isAttachmentApplication(mime_type) {
            return mime_type.includes('application/')
        },

        truncateFileName(original_name, length = 40) {
            let ext = original_name.substring(original_name.lastIndexOf('.') + 1, original_name.length).toLowerCase()
            let filename = original_name.replace('.' + ext, '')
            if (filename.length <= length) {
                return original_name
            }
            filename = filename.substr(0, length) + (original_name.length > length ? '[...]' : '')
            return filename + '.' + ext
        },
    }
}


let helper_mixin = {
    methods: {
        /**
         * Check a value if it is in an assumed array variable
         * if the assumed variable is not an array, just return false
         *
         * @param item
         * @param array
         * @returns {boolean|*}
         */
        inArray(item, array) {
            if (Array.isArray(array)) {
                return array.includes(item)
            }

            return false
        },
        getIntercomIframeHeight(intercom_iframe) {
            let intercom_iframe_inner_doc = intercom_iframe ? (intercom_iframe.contentDocument || intercom_iframe.contentWindow.document) : null
            let intercom_iframe_dom_body = intercom_iframe_inner_doc ? intercom_iframe_inner_doc.getElementById('intercom-container-body') : null
            return intercom_iframe_dom_body ? intercom_iframe_dom_body.clientHeight : 0
        },
        getValueBasedOnBreakpoint(breakpoints, defaultValue, type = 'width') {
            const dimension = type === 'width' ? window.innerWidth : window.innerHeight;
            const validBreakpoint = Object.keys(breakpoints)
                .find(breakpoint => dimension <= breakpoint);

            return validBreakpoint ? breakpoints[validBreakpoint] : defaultValue;
        }
    },
    computed: {
        ...mapState('cache', ['current_company']),
        ...mapGetters('cache', ['isSimpSocial']),

        alowareClassic() {
            if (this.isSimpSocial) {
                return 'Admin'
            }

            return 'Aloware Admin'
        },

        alowareTalk() {
            if (this.isSimpSocial) {
                return 'Talk'
            }

            return 'Aloware Talk'
        }
    },
}

let user_mixin = {
    data() {
        return {
            auth
        }
    },

    mixins: [
        acl_mixin,
        helper_mixin,
    ],

    methods: {
        allowUserUpdate(user) {
            // For updating secondary user
            if (this.auth && this.auth.user && this.auth.user.profile && user && user.main_user_access && this.auth.user.profile.company_id != user.main_user_access.company_id) {
                return false
            }

            return true
        },

        allowUserSuspension(user) {
            // billing admins can suspend other billing admins
            // regular admins can't suspend billing admins
            if (this.auth && this.auth.user && this.auth.user.profile && user && this.hasRole('Company Admin') && !this.hasRole('Billing Admin') && this.inArray('Billing Admin', user.role_names)) {
                return false
            }

            return true
        },

        allowUserDelete(user) {
            // billing admins can delete other billing admins
            // regular admins can't delete billing admins
            if (this.auth && this.auth.user && this.auth.user.profile && user && this.hasRole('Company Admin') && !this.hasRole('Billing Admin') && this.inArray('Billing Admin', user.role_names)) {
                return false
            }

            return true
        },

        getRedirectUrl(response) {
            let url = null

            /**
             * If user have many accessible companies and not yet selecting a company to access
             * then redirect to account selector page
             */
            if (response.has_multiple_access) {
                url = this.$route.query.redirect || '/account-selector'
            } else {
                // Hardcoded homepage when user is supervisor
                if (response.user_roles.includes('Company Supervisor')) {
                    return '/reports'
                }

                url = this.$route.query.redirect || '/dashboard'
            }

            return url
        },
    }
}

let user_info_mixin = {
    computed: {
        ...mapState(['users']),
        ...mapState('cache', ['current_company']),
    },

    methods: {
        getUser(id, user = null) {
            const users = _.get(this, 'users', null)

            if (!id || !users) {
                return null
            }

            let found = users.find(user => user.id === id)

            if (found) {
                return found
            }

            if (user) {
                this.newUser(user)
                return user
            }

            return {
                id: id,
                name: 'Deleted User',
                answer_by: AnswerTypes.BY_NONE
            }
        },

        getUserName(user) {
            if (!user) {
                return
            }

            // sanity check
            if (!user.id) {
                return
            }

            if (user.answer_by !== undefined) {
                switch (user.answer_by) {
                    case AnswerTypes.BY_BROWSER:
                        return user.name + ' - Browser / Apps'
                    case AnswerTypes.BY_IP_PHONE:
                        return user.name + ' - SIP (IP Phone)'
                    case AnswerTypes.BY_PHONE_NUMBER:
                        return user.name + ' - Phone Number (' + user.phone_number + ')'
                    case AnswerTypes.BY_NONE:
                        return user.name + ' - Will Not Answer'
                }
            }

            switch (true) {
                case user.name !== '' && user.name && !user.sip_uri:
                    return user.name + ' (' + user.phone_number + ')'
                case user.name !== '' && user.name && user.sip_uri:
                    return user.name + ' - SIP'
                case !user.name && user.sip_uri:
                    return 'No Name - SIP'
                default:
                    return 'No Name (' + user.phone_number + ')'
            }
        },

        isUserUsingTalk(user_id) {
            return this.getUser(user_id).default_app === AlowareAppTypes.APP_ALOWARE_TALK
        },

        ...mapActions(['newUser'])
    }
}

let users_mixin = {
    computed: {
        ...mapState(['users']),

        activeQualifiedUsers() {
            return this.users.filter(user => !user.is_destination && !user.read_only_access && user.enabled && user.active)
        }
    },

    methods: {
        getAvailableLicenses() {
            // getExtensions() populates the same this.users state with Extensions, which shouldn't be taken into account for Licenses count
            // and Company Reporters (user.read_only_access) are not billed for
            return (this.current_company?.purchased_licenses || 0) - this.users.filter(user => !user.is_destination && !user.read_only_access).length
        }
    }
}

let scroll_mixin = {
    mounted() {
        window.addEventListener('scroll', () => {
            this.onScroll()
        })
    },

    methods: {
        onScroll: _.throttle(function () {
            if (this.$refs.backtop) {
                this.$refs.backtop.onScroll()
            }
        }, 300)
    },

    destroyed() {
        window.removeEventListener('scroll', () => {
            this.onScroll()
        })
    }
}

let sequence_info_mixin = {
    methods: {
        typeToIcon: function (sequence_type) {
            let color = 'text-indigo-500'
            let icon = ''


            if ([SequenceTypes.TYPE_CALL_LEAD, SequenceTypes.TYPE_CALL_RING_GROUP].includes(sequence_type)) {
                icon += `<i class="material-icons text-2x m-y-sm ${color}">call</i>`
            }

            if ([SequenceTypes.TYPE_SMS_LEAD, SequenceTypes.TYPE_SMS_RING_GROUP].includes(sequence_type)) {
                icon += `<i class="material-icons text-2x m-y-sm ${color}">sms</i>`
            }

            if ([SequenceTypes.TYPE_EMAIL_LEAD, SequenceTypes.TYPE_EMAIL_RING_GROUP].includes(sequence_type)) {
                icon += `<i class="material-icons text-2x m-y-sm ${color}">email</i>`
            }

            if ([SequenceTypes.TYPE_ADD_TAG_CONTACT, SequenceTypes.TYPE_REMOVE_TAG_CONTACT].includes(sequence_type)) {
                icon += `<i class="fa fa-circle text-2x m-y-sm ${color}"></i>`
            }

            if ([SequenceTypes.TYPE_DISPOSE_CONTACT].includes(sequence_type)) {
                icon += `<i class="material-icons text-2x m-y-sm ${color}">label</i>`
            }

            if ([SequenceTypes.TYPE_DNC_CONTACT].includes(sequence_type)) {
                icon += `<i class="material-icons text-2x m-y-sm text-red">do_not_disturb_on</i>`
            }

            if (sequence_type == SequenceTypes.TYPE_RVM) {
                icon += `<i class="material-icons text-2x m-y-sm ${color}">voicemail</i>`
            }

            if (sequence_type == SequenceTypes.TYPE_MMS) {
                icon += `<i class="material-icons text-2x m-y-sm ${color}">mms</i>`
            }

            if (sequence_type == SequenceTypes.TYPE_WEBHOOK) {
                icon += `<i class="material-icons text-2x m-y-sm ${color}">open_in_new</i>`
            }

            if (sequence_type == SequenceTypes.TYPE_AUTO_DIAL) {
                icon += `<i class="material-icons text-2x m-y-sm ${color}">phonelink_setup</i>`
            }

            if (sequence_type == SequenceTypes.TYPE_BIRTHDAY) {
                icon += `<i class="material-icons text-2x m-y-sm ${color}">cake</i>`
            }

            if (sequence_type == SequenceTypes.TYPE_CHANGE_CONTACT_OWNER) {
                icon += `<i class="material-icons text-2x m-y-sm ${color}">person</i>`
            }

            if (sequence_type == SequenceTypes.TYPE_ADD_TO_POWER_DIALER) {
                icon += `<i class="material-icons text-2x m-y-sm ${color}">person_add</i>`
            }

            if (sequence_type == SequenceTypes.TYPE_PREDICTIVE_DIAL) {
                icon += `<i class="material-icons text-2x m-y-sm ${color}">flash_on</i>`
            }

            if (sequence_type == SequenceTypes.TYPE_REMOVE_FROM_POWER_DIALER) {
                icon += `<i class="material-icons text-2x m-y-sm ${color}">?</i>`
            }

            return icon
        },

        typeTooltipData(sequence_type) {
            return this.$options.filters.capitalize(this.$options.filters.fixSequenceType(sequence_type))
        }
    }
}

const animateTabBackground = el => {
    el.closest('.el-form-item').classList.add('shine')
    setTimeout(() => {
        el.closest('.el-form-item').classList.remove('shine')
    }, 2000)
}

const tab_section_mixin = {
    data() {
        return {
            isComponentMounted: false,
            tabsTimeout: null,
            tabsTimeoutInterval: 1000,
            tabsAnchorId: 'page-anchor'
        }
    },

    methods: {
        handleRouteChanged({hash}) {
            if (typeof hash === 'string' && hash !== '') {
                const el = document.querySelector(hash)
                if (el) {
                    el.scrollIntoView({behavior: "smooth"})
                    animateTabBackground(el)
                }
            }
        },

        tabsHasLoaded() {
            try {
                return new Promise(resolve => {
                    this.tabsTimeout = setTimeout(() => {
                        this.findTabsWrapper(resolve)
                    }, this.tabsTimeoutInterval)
                })
            } catch (err) {
                clearTimeout(this.tabsTimeout)
                return Promise.resolve()
            }
        },

        findTabsWrapper(resolve) {
            const anchors = document.getElementsByClassName(this.tabsAnchorId)
            if (!anchors.length) {
                this.tabsTimeout = setTimeout(() => {
                    this.findTabsWrapper(resolve)
                }, this.tabsTimeoutInterval)
                return
            }
            clearTimeout(this.tabsTimeout)
            resolve()
        },

        handleWindowResize() {
            if (this.isComponentMounted) return
            this.isComponentMounted = true
            this.tabsHasLoaded().then(() => {
                this.handleRouteChanged(this.$route)
            })
        }
    },

    mounted() {
        window.addEventListener('resize', this.handleWindowResize)
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize)
        clearTimeout(this.tabsTimeout)
    }
}

let form_handler = {
    methods: {
        shine(id) {
            const el = document.getElementById(id)
            if (el) {
                animateTabBackground(el)
                el.scrollIntoView(true)
            }
        },
    }
}

let summary_widgets_mixin = {
    mounted() {
        this.changeChartSize()
    },

    methods: {
        changeChartSize() {
            if (this.$refs.highchart) {
                this.$refs.highchart.chart.reflow()
                this.$refs.highchart.chart.redraw()
            }
        }
    }
}

let filter_mixin = {
    created() {
        this.resetFilters()
    },

    methods: {
        ...mapActions(['resetFilters'])
    }
}

let contact_filters_mixin = {
    data() {
        return {
            lead_source: [],
            from_date: null,
            to_date: null,
            default_filter: {},
            custom_filter: {},
            exclude_from_filter_changes: ['search_fields', 'page', 'changed'],
            search_fields: ['name', 'phone_number', 'email']
        }
    },

    computed: {
        ...mapGetters({
            filter: 'getFilter',
            defaultStateFilter: 'getDefaultStateFilter'
        }),

        leadSourceId() {
            const lead_source_id = _.get(this.$route, 'query.lead_source_id', null)
            return lead_source_id ? parseInt(lead_source_id) : lead_source_id
        }
    },

    created() {
        // assign new default state filter objects, no reference
        this.default_filter = JSON.parse(JSON.stringify(this.defaultStateFilter))
        this.custom_filter = JSON.parse(JSON.stringify(this.defaultStateFilter))
    },

    mounted() {
        if (localStorage.getItem('PREVIOUS_ROUTE_NAME') === 'Contact') {
            this.default_filter.changed = this.filter.changed
        }
    },

    methods: {
        initLeadSource() {
            if (!this.leadSourceId) {
                return
            }

            if (!this.filter.lead_source.includes(this.leadSourceId)) {
                this.filter.lead_source.push(this.leadSourceId)
            }

            if (!this.custom_filter.lead_source.includes(this.leadSourceId)) {
                this.custom_filter.lead_source.push(this.leadSourceId)
            }

            if (!this.lead_source.includes(this.leadSourceId)) {
                this.lead_source.push(this.leadSourceId)
            }

            this.filter.changed = true
        },

        initFromToDates() {
            this.from_date = _.get(this.$route, 'query.from_date', null)
            this.to_date = _.get(this.$route, 'query.to_date', null)

            if (!this.from_date || !this.to_date) {
                return
            }

            this.filter.from_date = this.from_date
            this.filter.to_date = this.to_date
        },

        hasFilterMenuChanges(filter, filter_to_compare) {
            let clean_filter = Object.assign({}, filter)
            let filters_with_changes = Object.assign({}, filter_to_compare)
            // we remove unnecessarry filters
            for (let excluded_index of this.exclude_from_filter_changes) {
                delete clean_filter[excluded_index]
                delete filters_with_changes[excluded_index]
            }
            let arranged_filter_with_changes = {}
            // let's re-arrange the properties
            for (let key in clean_filter) {
                if (typeof filters_with_changes[key] !== 'undefined') {
                    arranged_filter_with_changes[key] = filter_to_compare[key]
                }
            }

            // compare the two filters for changes
            return JSON.stringify(clean_filter) !== JSON.stringify(arranged_filter_with_changes)
        }
    }
}

let contact_activities_mixin = {
    data() {
        return {
            auth: auth,
            has_more_communications: true,
            selected_phone_number: null,
            selected_campaign_id: null,
            communications_and_audits: [],
            loading_contact: false,
            loading_contact_communications: false,
            loading_send_message: false,
            loading_mark_as_read: false,
            selected_contact: {
                id: null,
                first_name: null,
                last_name: null,
                cnam_country: null,
                cnam_state: null,
                cnam_city: null,
                cnam_zipcode: null,
                company_name: null,
                address: null,
                email: null,
                timezone: null,
                notes: '',
                csf1: null,
                csf2: null,
                date_of_birth: false,
                phone_number: null,
                is_blocked: false,
                communications: [],
                campaign_ids: [],
                user: null,
                user_id: null,
                lrn_type: null,
                is_dnc: false,
                tags: [],
                tag_ids: [],
                unread_count: 0,
                unread_voicemail_count: 0,
                unread_missed_call_count: 0
            },
            selected_contact_campaigns: [],
            contact_phone_numbers: [],
            communications_summary: {
                first_outbound_call: null,
                summaries: {
                    inbound_calls_count: 0,
                    outbound_calls_count: 0,
                    inbound_texts_count: 0,
                    outbound_texts_count: 0,
                    total_count: 0
                }
            },
            communications_page: 1,
            communications_per_page: 10,
            contact_incoming_number: null,
            can_email: false,
            type: 0,
            sms_only: false,
            reply_text: '',
            sendMediaDialogVisible: false,
            giphyMediaDialogVisible: false,
            loading_send_media_btn: false,
            media: {
                file_name: null,
                body: null,
                files: []
            },
            uploadPercentage: {
                import: 0,
                upload: 0
            },
            uploadStatus: {
                import: 'success',
                upload: 'success'
            },
            uploadFileList: {
                import: [],
                upload: []
            },
            rules_media: {
                file_name: [
                    {
                        required: true,
                        message: 'Please upload your media file',
                        trigger: 'change'
                    }
                ]
            },
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('api_token'),
                'X-Socket-Id': window.Echo.socketId()
            },
            is_loading_previous_activities: false,
            activity_types: [
                'communication',
                'contact-audit'
            ],
        }
    },

    computed: {
        ...mapState(['campaigns']),
        ...mapState('cache', ['current_company']),

        selectedCampaign() {
            if (this.campaigns) {
                return this.campaigns.find(campaign => campaign.id === this.selected_campaign_id)
            }

            return null
        },

        filteredCommunications() {
            let communications = []
            if (this.communications_and_audits) {
                if (this.type !== undefined && this.type == 0) {
                    // returns all communications
                    communications = this.communications_and_audits
                } else if (this.type !== undefined && this.type === CommunicationTypes.NOTE) {
                    // returns all note communications
                    communications = this.communications_and_audits.filter(communication => ((communication.type !== undefined && [CommunicationTypes.NOTE, CommunicationTypes.SYSNOTE].includes(communication.type)) || communication.type === undefined))
                } else if (this.type === undefined) {
                    communications = this.communications_and_audits.filter(communication => [CommunicationTypes.NOTE, CommunicationTypes.SYSNOTE].includes(communication.type))
                } else {
                    // returns selected filter communications
                    communications = this.communications_and_audits.filter(communication => communication.type === this.type)
                }
            }

            return communications
        },

        contactCampaignsFromCommunications() {
            if (this.selected_contact && this.campaigns.length) {
                let contact_campaigns = this.campaignsAlphabeticalOrder.filter((cmp) => {
                    if (this.selected_contact_campaigns.includes(cmp.id)) {
                        return true
                    }
                })

                for (let campaign of contact_campaigns) {
                    campaign.unread_count = this.communications_and_audits.filter((comm) => {
                        if (comm.type && (comm.type == CommunicationTypes.SMS || (comm.type == CommunicationTypes.CALL && [CommunicationDispositionStatus.DISPOSITION_STATUS_VOICEMAIL_NEW, CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW].includes(comm.disposition_status2))) && comm.is_read == false && comm.campaign_id == campaign.id) {
                            return true
                        }
                    }).length
                }

                return contact_campaigns
            }

            return []
        },

        otherCampaignsFromCommunications() {
            if (this.campaigns && this.contactCampaignsFromCommunications) {
                return _.difference(this.campaignsAlphabeticalOrder, this.contactCampaignsFromCommunications)
            } else if (this.campaigns) {
                return this.campaignsAlphabeticalOrder
            }

            return []
        },

        campaignsAlphabeticalOrder() {
            if (this.campaigns) {
                let campaigns = _.clone(this.campaigns)
                return campaigns.sort((a, b) => {
                    let textA = a.name.toUpperCase()
                    let textB = b.name.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
            }

            return []
        },
    },

    created() {
        VueEvent.listen('new_communication', (data) => {
            this.addNewCommunication(data)
        })

        VueEvent.listen('update_communication', (data) => {
            this.updateCommunication(data)
        })

        VueEvent.listen('delete_communication', (data) => {
            this.deleteCommunication(data)
        })

        VueEvent.listen('contact_updated', (data) => {
            // update the current contact if it's updated
            if (this.selected_contact && this.selected_contact.id === data.id) {
                this.updateSelectedContact(data)
            }
        })

        VueEvent.listen('contact_deleted', (data) => {
            // redirect to the contacts page if the current contact is deleted
            if (this.selected_contact && data.id == this.selected_contact.id) {
                this.$router.push({name: 'Contacts'}).catch(err => {
                    console.log(err)
                })
            }
        })
    },

    methods: {
        addNewCommunication(data) {
            if (this.sms_only && data.type !== CommunicationTypes.SMS) {
                return false
            }

            // checks if contact is the same in communication
            if (this.selected_contact && data.contact && this.selected_contact.id != data.contact.id) {
                return false
            }

            // check data loaded
            if (this.communications_and_audits) {
                // check new communication exists in the old list
                let found = this.communications_and_audits.find(communication => communication.id === data.id)
                if (!found) {
                    // push new data to top of array
                    this.communications_and_audits.push(data)
                    this.scrollMessages()
                }
            }
        },

        updateCommunication(data) {
            // checks if contact is the same in communication
            if (this.selected_contact && data.contact && this.selected_contact.id != data.contact.id) {
                return false
            }

            // check data loaded
            if (this.communications_and_audits) {
                // check new communication exists in the old list
                let found = this.communications_and_audits.find(communication => communication.id === data.id)
                if (found) {
                    // update communication
                    data = _.merge(found[0], data)
                    this.$set(this.communications_and_audits, this.communications_and_audits.indexOf(found), data)
                }
            }
        },

        deleteCommunication(data) {
            // checks if contact is the same in communication
            if (this.selected_contact && data.contact && this.selected_contact.id != data.contact.id) {
                return false
            }

            // check data loaded
            if (this.communications_and_audits) {
                // try to find the communication
                let found = this.communications_and_audits.find(communication => communication.id === data.id)
                if (found) {
                    // remove it from the list
                    this.communications_and_audits.splice(this.communications_and_audits.indexOf(found), 1)
                }
            }
        },

        async fetchContactInfo(contact_id = null) {
            let id = contact_id ? contact_id : this.contact_id
            this.communications_and_audits = []
            this.communications_page = 1
            this.has_more_communications = true
            this.loading_contact = true
            this.loading_contact_communications = true
            return axios.get('/api/v1/contact/' + id).then(res => {
                this.fetchContactCommunications(id, false)
                    .then(() => {
                        this.loading_contact = false

                        // if route hash contains activity info, retrieve communications
                        // until id is found
                        if (this.isHashActivityType()) {
                            this.loading_contact_communications = true
                            this.fetchContactCommunicationsUntilFound()
                        } else {
                            this.loading_contact_communications = false
                        }

                        // basically for child components to get (and react) to the updated values after promise
                        VueEvent.fire('contact_communications_loaded', {
                            contact_id: id,
                            selected_campaign_id: this.selected_campaign_id,
                            communications_and_audits: this.communications_and_audits
                        })
                    })
                return res
            }).catch(err => {
                this.loading_contact = false
                this.loading_contact_communications = false
                this.$root.handleErrors(err.response)
                console.log(err)
            })
        },

        fetchedContactInfo(selected_contact) {
            this.communications_and_audits = []
            this.selected_contact = selected_contact
            this.selected_contact.tag_ids = this.selected_contact.tags.map((a) => a.id)
        },

        showContactInfo(contact_id, force_clear_loading = false) {
            this.mapCommunicationsData()

            // 1. if contact has initial campaign and there were no communications select initial campaign
            if (!this.communications_and_audits.length && this.selected_contact && this.selected_contact.initial_campaign_id) {
                this.selected_campaign_id = this.selected_contact.initial_campaign_id
            }

            // 2. if contact has communications select last communication campaign
            if (!this.selectedCampaign && this.communications_and_audits.length) {
                let latest_communication = _.find(_.orderBy(this.communications_and_audits, item => item.created_at, ['desc']), item => {
                    return item.type === CommunicationTypes.SMS
                })
                if (latest_communication) {
                    this.selected_campaign_id = latest_communication.campaign_id
                }
            }

            // 3. if user has a personal line and contact does not have an initial line
            if (!this.selectedCampaign && this.auth.user && this.auth.user.profile && this.auth.user.profile.campaign_id) {
                this.selected_campaign_id = this.auth.user.profile.campaign_id
            }

            // 4. if contact doesn't have situation 1 and 2 and selected_contact_campaigns has one campaign select the campaign
            if (!this.selectedCampaign && this.selected_contact_campaigns.length == 1 && this.selected_contact_campaigns[0] !== null) {
                this.selected_campaign_id = this.selected_contact_campaigns[0].id
            }

            // 5. if contact doesn't have situation 1 and 2 and 3 and company has one campaign select that campaign
            if (!this.selectedCampaign && this.selected_contact_campaigns.length == 0 && this.campaigns && this.campaigns.length == 1) {
                this.selected_campaign_id = this.campaigns[0].id
            }

            // 6. if contact doesn't have situation 1 and 2 and 3 and 4 and company has more then one campaign select the first one
            if (!this.selectedCampaign && this.campaigns && this.campaigns.length > 0) {
                this.selected_campaign_id = this.campaigns[0].id
            }

            this.selected_phone_number = this.selected_contact ? this.selected_contact.phone_number : this.selected_phone_number

            this.scrollMessages()

            if (!this.sms_only && (localStorage.getItem('PREVIOUS_ROUTE_NAME') !== 'Contacts' || force_clear_loading)) {
                this.loading_contact_communications = false
            }
        },

        updateSelectedContact(contact) {
            if (_.isEmpty(contact) || _.isEmpty(this.selected_contact)) {
                return
            }

            for (let index in contact) {
                if (index === 'communications_and_audits') {
                    continue
                }

                this.$set(this.selected_contact, index, contact[index])
            }
        },

        updateSelectedContactAudit(audit) {
            if (_.isEmpty(audit) || _.isEmpty(this.selected_contact)) {
                return
            }

            this.communications_and_audits.push(audit)
        },

        async fetchContactCommunications(contact_id, skip_contact_info = true) {
            let last_audit_created_at = null
            for (let index in this.communications_and_audits) {
                try {
                    if (typeof this.communications_and_audits[index].property !== 'undefined') {
                        last_audit_created_at = this.communications_and_audits[index].created_at
                        break
                    }
                } catch (err) {
                    console.log(err)
                }
            }
            return axios.get('/api/v1/contact/' + contact_id + '/communications', {
                params: {
                    page: this.communications_page,
                    per_page: this.communications_per_page,
                    last_audit_created_at: last_audit_created_at
                }
            }).then(res => {
                if (res.data.data && res.data.data.length) {
                    this.communications_and_audits = res.data.data.concat(this.communications_and_audits)
                }

                this.has_more_communications = res.data.has_more_pages
                this.communications_page++

                if (!skip_contact_info) {
                    this.showContactInfo(this.contact_id, true)
                }

                return res
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_contact_communications = false
                console.log(err)
            })
        },

        fetchContactCommunicationsUntilFound() {
            this.loading_contact_communications = true

            // fetch communications until we found the activity id
            if (!this.isHashActivityFound()) {
                this.fetchContactCommunications(this.contact_id).then(res => {
                    if (res.data.has_more_pages) {
                        this.fetchContactCommunicationsUntilFound()
                    } else if (this.isHashActivityFound) {
                        this.scrollIntoActivity()
                        this.loading_contact_communications = false
                    } else {
                        this.scrollMessages()
                        this.loading_contact_communications = false
                    }
                })
            } else { // we found the activity, scroll to it
                this.scrollIntoActivity()
                this.loading_contact_communications = false
            }
        },

        mapCommunicationsData() {
            this.selected_contact_campaigns = []

            if (!this.selected_contact) {
                return
            }

            this.communications_and_audits.map((o) => {
                if (o.type !== undefined) {
                    let found = this.selected_contact_campaigns.find(cmp => cmp === o.campaign_id)
                    if (!found) {
                        this.selected_contact_campaigns.push(o.campaign_id)
                    }
                    if (o.tags) {
                        o.tag_ids = o.tags.map((a) => a.id)
                    }
                }
            })
        },

        loadMorePreviousActivities() {
            this.is_loading_previous_activities = true
            this.fetchContactCommunications(this.contact_id)
                .then(() => {
                    this.is_loading_previous_activities = false
                })
                .catch(() => {
                    this.is_loading_previous_activities = false
                })
        },

        changeSelectedPhoneNumber(phone_number) {
            this.selected_phone_number = phone_number
            this.$notify({
                offset: 95,
                title: 'Contact',
                message: 'Changed selected contact phone number to: ' + this.selected_phone_number,
                type: 'success',
                showClose: true
            })
            // this.setFocus()
        },

        resetSelectedContact() {
            this.selected_contact = null
            this.selected_campaign_id = null
            this.selected_phone_number = null
            this.contact_phone_numbers = []
        },

        markAllAsRead() {
            if (this.selected_campaign_id) {
                this.loading_mark_as_read = true
                axios.post('/api/v1/contact/' + this.selected_contact.id + '/mark-as-read').then(res => {
                    this.loading_mark_as_read = false
                    for (let communication of this.filteredCommunications) {
                        this.$set(communication, 'is_read', true)
                    }
                    VueEvent.fire('contact_updated', res.data)
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading_mark_as_read = false
                })
            }
        },

        sendMessage(event) {
            if (event && event.shiftKey === true && event.key === "Enter") {
                return
            }

            this.markAllAsRead()
            this.loading_send_message = true
            axios.post('/api/v1/campaign/send-message/' + this.selected_campaign_id + '/' + this.selected_contact.id, {
                message: this.reply_text,
                phone_number: this.selected_phone_number,
            }).then(res => {
                this.reply_text = ''
                this.loading_send_message = false
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_send_message = false
            })
        },

        sendGifMessage(url) {
            this.closeGiphyMediaModal()
            this.markAllAsRead()
            this.loading_send_message = true
            axios.post('/api/v1/campaign/send-gif/' + this.selected_campaign_id + '/' + this.selected_contact.id, {
                url: url,
                phone_number: this.selected_phone_number
            }).then(res => {
                this.loading_send_message = false
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_send_message = false
            })
        },

        openSendMediaModal() {
            this.sendMediaDialogVisible = true
            this.resetSendMediaContactsForm('media')
        },

        closeSendMediaModal() {
            this.sendMediaDialogVisible = false
            this.resetSendMediaContactsForm('media')
        },

        openGiphyMediaModal() {
            this.giphyMediaDialogVisible = true
        },

        closeGiphyMediaModal() {
            this.giphyMediaDialogVisible = false
        },

        beforeCloseSendMediaModal(done) {
            this.$confirm('Are you sure you want to leave? You have not sent any media yet', 'Warning', {
                confirmButtonText: 'Yes, Leave',
                cancelButtonText: 'No, Stay',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(res => {
                done()
            }).catch(err => {
                console.log(err)
            })
        },

        onChangeFileList(file, fileList) {
            this.uploadFileList.upload = fileList
        },

        onSuccessSendMedia(res) {
            this.$notify({
                offset: 95,
                title: 'Media Upload',
                message: 'Media file has been uploaded successfully.',
                type: 'success',
                showClose: true,
            })
            this.$set(this.media, 'file_name', res.file_name)
            this.uploadStatus.upload = 'success'
            this.preValidateForm('media')
        },

        onFailedSendMedia(err) {
            this.$root.handleUploadErrors(err.message)
            this.uploadStatus.upload = 'exception'
            this.uploadPercentage.upload = 0
            this.preValidateForm('media')
        },

        beforeUploadSendMedia() {
            this.uploadStatus.upload = 'success'
            this.uploadPercentage.upload = 0
        },

        progressUploadSendMedia(event) {
            this.uploadPercentage.upload = parseInt(event.percent)
        },

        sendMedia() {
            if (this.validateForm('media') == true) {
                this.loading_send_media_btn = true
                this.media.phone_number = this.selected_phone_number
                this.media.files = this.uploadFileList.upload.map(item => item.response.file_name)
                axios.post('/api/v1/campaign/send-mms/' + this.selected_campaign_id + '/' + this.selected_contact.id, this.media)
                    .then(res => {
                        this.loading_send_media_btn = false
                        this.resetSendMediaContactsForm('media')
                        this.sendMediaDialogVisible = false
                        this.uploadPercentage.upload = 0
                        this.uploadStatus.upload = 'success'
                        this.uploadFileList.upload = []
                    })
                    .catch(err => {
                        console.log(err)
                        this.$root.handleErrors(err.response)
                        this.loading_send_media_btn = false
                    })
            } else {
                return false
            }
        },

        resetSendMediaContactsForm(form_name) {
            this.media.file_name = null
            this.media.body = null
            this.media.files = []
            this.uploadFileList.upload = []
            this.uploadPercentage.upload = 0
            this.uploadStatus.upload = 'success'
            setTimeout(() => {
                this.resetForm(form_name)
            }, 100)
        },

        setFocus() {
            this.$nextTick(() => {
                if (this.$refs.reply_text) {
                    this.$refs.reply_text.focus()
                }
            })
        },

        addToMessage(event) {
            this.reply_text += event.target.text.trim()
            this.setFocus()
        },

        scrollMessages() {
            setTimeout(() => {
                let activitiesWrap = this.$refs.activitiesWrap
                if (activitiesWrap && activitiesWrap.scrollHeight) {
                    activitiesWrap.scrollTop = activitiesWrap.scrollHeight
                }
            }, 250)
        },

        isHashActivityType() {
            if (!this.$route.hash) {
                return false
            }

            let has_activity = false

            for (let index in this.activity_types) {
                if (this.$route.hash.includes(this.activity_types[index])) {
                    has_activity = true
                    break
                }
            }

            if (!has_activity) {
                return false
            }

            let hash = this.$route.hash
            hash = hash.split('-')

            // hash only has 2 items: activity type and id
            if (hash.length == 2) {
                return true
            }

            return false
        },

        isHashActivityFound() {
            let hash = this.$route.hash.replace('#', '')
            hash = hash.split('-')

            let activity_type = hash[0] // communication, contact-audit, etc...
            let id = hash[1].trim()

            let found = null
            if (activity_type === 'communication') {
                found = this.communications_and_audits.find(communication => communication.type !== undefined && communication.id.toString() === id)
            } else {
                found = this.communications_and_audits.find(communication => communication.property !== undefined && communication.id.toString() === id)
            }

            return !!found
        },

        scrollIntoActivity() {
            let hash = this.$route.hash.replace('#', '')
            let count = 0

            // scroll to activity
            let scroll_interval = setInterval(() => {
                const communication_activity = _.get(this.$refs, `${hash}.0`, null)
                if (communication_activity) {
                    communication_activity.$el.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "start"
                    })
                    // highlight the activity
                    this.highlightActivity(communication_activity.$el)
                    clearInterval(scroll_interval)
                }

                // if we've been waiting for too long to load,
                // clear this interval
                if (count >= 40) {
                    clearInterval(scroll_interval)
                }

                count++
            }, 250)
        },

        highlightActivity(element) {
            element.classList.add('shine')
            setTimeout(() => {
                element.classList.remove('shine')
            }, 3000)
        },

        fetchIncomingNumber() {
            this.contact_incoming_number = null
            axios.get('/api/v1/contact/' + this.selected_contact.id + '/campaign/' + this.selectedCampaign.id + '/get-incoming-number').then(res => {
                this.contact_incoming_number = res.data
            }).catch(err => {
                this.$root.handleErrors(err.response)
                console.log(err)
            })
        },

        checkEmailCapability() {
            let has_email_integration_enabled = this.current_company && (this.current_company.sendgrid_integration_enabled || this.current_company.mailgun_integration_enabled || this.current_company.smtp_integration_enabled)

            if (has_email_integration_enabled) {
                this.can_email = true
                return
            }

            this.can_email = (this.selectedCampaign.email_intake && this.selectedCampaign.email_intake_route_id)
        },

        updateMessageComposer() {
            if (this.selectedCampaign && this.selectedCampaign.id && this.selected_contact && this.selected_contact.id) {
                this.fetchIncomingNumber()
                this.checkEmailCapability()
            }
        },

        getCommunicationsSummary(contact) {
            if (contact) {
                axios.get(`/api/v1/contact/${contact}/communications-summary`).then(res => {
                    // sanitize summaries data before merging
                    Object.keys(res.data.summaries).forEach(key => res.data.summaries[key] = res.data.summaries[key] || 0)

                    this.communications_summary = {...this.communications_summary, ...res.data}
                }).catch(err => {
                    console.log(err)
                })
            }
        },
    },

    watch: {
        selectedCampaign: _.debounce(function () {
            this.updateMessageComposer()
        }, 300)
    }
}

let crm_link_mixin = {
    computed: {
        ...mapState('cache', ['current_company']),

        hasCrmLink() {
            return this.current_company && (this.current_company.hubspot_integration_enabled || this.current_company.zoho_integration_enabled || this.current_company.pipedrive_integration_enabled || this.current_company.guesty_integration_enabled)
        },

        hasPowerDialerCrmLink() {
            return this.current_company && (this.current_company.hubspot_integration_enabled || this.current_company.guesty_integration_enabled)
        }
    },

    methods: {
        getCrmLink(contact) {
            return this.getHubspotLink(contact) || this.getPipedriveLink(contact) || this.getZohoLink(contact) || this.getGuestyLink(contact)
        },

        getPowerDialerCrmLink(contact) {
            return this.getHubspotLink(contact) || this.getGuestyLink(contact)
        },

        getHubspotLink(contact) {
            if (contact &&
                contact.integration_data &&
                contact.integration_data.hubspot &&
                contact.integration_data.hubspot.link) {
                return contact.integration_data.hubspot.link
            }

            return false
        },

        getPipedriveLink(contact) {
            if (contact &&
                contact.integration_data &&
                contact.integration_data.pipedrive &&
                contact.integration_data.pipedrive.link) {
                return contact.integration_data.pipedrive.link
            }

            return false
        },

        getZohoLink(contact) {
            if (contact &&
                contact.integration_data &&
                contact.integration_data.zoho &&
                contact.integration_data.zoho.contact_link) {
                return contact.integration_data.zoho.contact_link
            }

            if (contact &&
                contact.integration_data &&
                contact.integration_data.zoho &&
                contact.integration_data.zoho.lead_link) {
                return contact.integration_data.zoho.lead_link
            }

            return false
        },

        getGuestyLink(contact) {
            if (contact &&
                contact.integration_data &&
                contact.integration_data.guesty &&
                contact.integration_data.guesty.link) {
                return contact.integration_data.guesty.link
            }

            return false
        },
    }
}

let crm_integration_access_mixin = {
    data() {
        return {
            auth: auth
        }
    },

    computed: {
        isCrmIntegrationsEnabled() {
            return _.get(this.auth, 'user.profile.company.crm_integrations_enabled', false)
        }
    },

    methods: {
        integrationsNotAllowedMessage(created_at) {
            const company_created_date = moment(created_at).utc()
            const max_date = moment('2021-05-01').utc()

            if (company_created_date.isBefore(max_date)) {
                return "Heads up! Starting 5/1/21, your plan will not support CRM integrations. Please contact your account rep for details."
            }

            return "Your plan does not support CRM integrations. Please contact your account rep for details."
        },

        allowIntegrationSettingsView(link_name) {
            // not crm integrations should be allowed by default
            if (!CrmList.includes(link_name)) {
                return true
            }

            // don't allow if crm integrations is disabled and
            // crm integration is in the selected crms list. else, allow
            return this.isCrmIntegrationsEnabled && CrmList.includes(link_name)
        }
    }
}

let string_mixin = {
    methods: {
        cleanMicrosoftEncodedQuotes(string) {
            string = string.replace(String.fromCharCode(145), "'")
            string = string.replace(String.fromCharCode(146), "'")
            string = string.replace(String.fromCharCode(147), '"')
            string = string.replace(String.fromCharCode(148), '"')
            string = string.replace(String.fromCharCode(151), '-')
            string = string.replace(String.fromCharCode(8216), "'")
            string = string.replace(String.fromCharCode(8217), "'")
            string = string.replace(String.fromCharCode(8220), '"')
            string = string.replace(String.fromCharCode(8221), '"')

            return string
        },

        cleanSmartEncodedQuotes(string) {
            string = string.replace("\xC2\xAB", '"')
            string = string.replace("\xC2\xBB", '"')
            string = string.replace("\xE2\x80\x98", "'")
            string = string.replace("\xE2\x80\x99", "'")
            string = string.replace("\xE2\x80\x9A", "'")
            string = string.replace("\xE2\x80\x9B", "'")
            string = string.replace("\xE2\x80\x9C", '"')
            string = string.replace("\xE2\x80\x9D", '"')
            string = string.replace("\xE2\x80\x9E", '"')
            string = string.replace("\xE2\x80\x9F", '"')
            string = string.replace("\xE2\x80\xB9", "'")
            string = string.replace("\xE2\x80\xBA", "'")

            return string
        },

        cleanStringToUTF8(string) {
            string = this.cleanMicrosoftEncodedQuotes(string)
            string = this.cleanSmartEncodedQuotes(string)

            return string
        }
    }
}

let contact_phone_number_mixin = {
    computed: {
        ...mapState('cache', ['current_company'])
    },

    methods: {
        isNumberInvalidOrWrong(phone_number) {
            return !phone_number || phone_number.is_invalid || phone_number.is_wrong_number
        },

        getTooltipMessage(phone_number, cant_send_non_tcpa_contacts = false, blocked_message = '') {
            if (blocked_message !== '') {
                return '<span class="not-registered-desc">' + blocked_message + '</span>'
            }

            if (!phone_number) {
                return 'Phone number is empty.'
            }

            if (phone_number.is_invalid) {
                return 'This is an invalid number. Please fix before using this action.'
            }
            if (phone_number.is_wrong_number) {
                return 'This is a wrong number. Please fix before using this action.'
            }
            if (phone_number.is_opted_out) {
                return 'This number has been opted out.'
            }

            if (cant_send_non_tcpa_contacts) {
                let tooltip = '<span class="text-black-dk">This number cannot be texted based on TCPA enforcement.</span> '

                // If company is not white labeled then display see more link, otherwise don't show it
                if (this.current_company && !this.current_company.is_whitelabel) {
                    tooltip += `<a class="text-blue"
                        target="_blank"
                        href="https://support.aloware.com/en/articles/9031270-lines-menu-general-settings">
                        See more here
                        </a>`
                }
                return tooltip
            }

            return ''
        },

        isLandLine(phone_number) {
            if (!phone_number) {
                return false
            }

            return phone_number.lrn_type === LRNTypes.LRN_TYPE_LANDLINE
        },

        isOptedOut(phone_number) {
            if (!phone_number) {
                return false
            }

            return phone_number.is_opted_out
        },

        shouldAllowSmsTraffic(selectedLine) {
            if (!selectedLine) {
                return true
            }
            /**
             * Allow sms traffic on Dialer and Contact text Composer component
             */
            if (!selectedLine.is_10_dlc) {
                /**
                 *  1 - No A2P Campaign + No 10DLC Line -> Allow messaging
                 *  2 - A2P Campaign  + No 10DLC (TF) ->  Allow messaging
                 */
                return true
            }

            if (this.isCanadaLine(selectedLine)) {
                /**
                 * 3 - 10DLC Canada lines -> Allow messaging
                 */
                return true
            }

            /**
             *   3 - A2P Campaign + 10DLC Line -> Allow messaging
             *   4 - No A2P Campaign + 10DLC -> Not allowed
             */
            return selectedLine.is_10_dlc && selectedLine.has_approved_a2p_use_case
        },

        isCanadaLine(selectedLine) {
            return selectedLine?.incoming_numbers?.filter(number => number.country === 'CA').length > 0
        }
    }
}

let contact_talk_mixin = {
    mixins: [acl_mixin],

    methods: {
        getContactTalkRedirectURL(contactId, tagId) {
            let baseUrl = this.getTalkUrl() + '/contacts'

            if (contactId) {
                baseUrl = `${baseUrl}/${contactId}`
            }

            if (tagId) {
                baseUrl = `${baseUrl}?tag_id=${tagId}`
            }

            return baseUrl
        }
    }
}

let calendar_talk_mixin = {
    mixins: [acl_mixin],

    methods: {
        getCalendarTalkRedirectURL(query = {}) {
            let baseUrl = this.getTalkUrl() + '/calendar'

            // If the query object is provided, append it to the URL
            if (query && Object.keys(query).length > 0) {
                const queryString = new URLSearchParams(query).toString()
                baseUrl = `${baseUrl}?${queryString}`
            }

            return baseUrl
        }
    }
}

let compliance_mixin = {
    data: () => ({
        loading: false,
        messaging_service: null,
        a2p_use_case: null,
    }),
    props: {
        currentStep: {
            type: Number,
            default: 0,
        },
        alertMessage: {
            type: String,
            default: '',
        },
    },
    computed: {
        standardBusinessProfileApproved() {
            return this.current_company && this.current_company.primary_carrier && this.current_company.primary_carrier.business_profile_status === TrustBundleStatus.BUNDLE_STATUS_APPROVED
        },

        cnamStatusData() {
            let data = {
                color: 'danger',
                status: 'Unsubmitted',
                message: '',
                failure: '',
            }
            if (this.caller_id) {
                data = {
                    color: 'blue',
                    status: 'Pending',
                    message: 'May take up to 48 hours to process',
                    failure: '',
                }
                switch (this.caller_id.bundle_status) {
                    case TrustBundleStatus.BUNDLE_STATUS_APPROVED:
                        data.color = 'green'
                        data.status = 'Approved'
                        data.message = 'Your Caller ID (CNAM) is approved. No further action needed.'
                        break
                    case TrustBundleStatus.BUNDLE_STATUS_REJECTED:
                        data.color = 'danger'
                        data.status = 'Failed'
                        data.message = 'Your request failed for the following reasons:'
                        data.failure = this.caller_id.bundle_failure_reason
                        break
                }
            }

            return data
        },

        ...mapState('cache', ['current_company']),
    },
    methods: {
        ...mapActions('cache', ['setCurrentCompany']),
        submitAlert(alert = '') {
            this.$emit('alert', alert);
        },
        fixToUcFirst(text) {
            return text
                ?.toLowerCase()
                ?.split('_')
                ?.map(word => this.$options.filters.ucfirst(word))
                ?.join(' ');
        },
        submitMessagingServiceForm() {
            this.loading = true

            axios.post('/api/v1/compliance/messaging-services', this.messagingServiceForm)
                .then(response => {
                    this.messaging_service = response.data

                    // Show success message
                    this.$message({
                        showClose: true,
                        message: 'Messaging Service form has been submitted successfully.',
                        type: 'success',
                    })

                    // Go to next step
                    this.$emit('next', this.messaging_service)
                }).catch(response => {
                this.submitAlert(response.message)
            }).finally(() => {
                this.loading = false
            })
        },
        submitUseCaseRegistrationForm() {
            this.preValidateForm('useCaseRegistrationForm')

            if (!this.validateForm('useCaseRegistrationForm')) {
                return
            }

            this.loading = true

            axios.post(`/api/v1/compliance/messaging-services/${this.messaging_service?.id}/a2p-use-case`, this.useCaseRegistrationForm)
                .then(response => {
                    this.loading = false
                    this.$message({
                        showClose: true,
                        message: 'A2P Campaign Registration form has been submitted successfully. We will notify you about its status.',
                        type: 'success',
                    })

                    this.$emit('next', {a2p_use_case: response.data})
                    this.submitAlert()
                }).catch(({response}) => {
                this.loading = false
                this.submitAlert(response)
            })
        },
    },
}

let url_shortener_mixin = {
    data() {
        return {
            user: auth.user.profile,
            domains: [],
            domain: null,
            generatingUrls: false,
        }
    },

    computed: {
        ...mapState('cache', ['current_company'])
    },

    async mounted() {
        if (this.current_company && !this.current_company.is_whitelabel && this.current_company.url_shortener_enabled && this.user && this.user.url_shortener_enabled) {
            try {
                let {data} = await axios.get('/api/v1/url-shortener/domains')
                this.domains = data.domains
                if (this.domains.length > 0) {
                    this.domain = this.domains[0]
                }
            } catch (err) {
                this.$root.handleErrors(err.response)
            }
        }
    },

    beforeUnmount() {
        this.domains = []
        this.domain = null
        this.generatingUrls = false
    },

    methods: {
        setDontAsk() {
            const el = document.querySelector('.url-shortener-dont-ask .el-checkbox__original')
            if (!el) {
                return false
            }
            if (el.checked) {
                this.user.url_shortener_enabled = false
                axios.put('/api/v1/user/' + this.user.id, this.user).then(() => {
                    this.$notify({
                        offset: 50,
                        duration: 5000,
                        dangerouslyUseHTMLString: true,
                        title: 'URL Shortener disabled',
                        message: 'To enable it again, visit the <br><b>Profile Settings &rarr; Personalization page</b>',
                        type: 'success'
                    })
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                })
            }
        }
    }
}

let mentions_mixin = {
    computed: {
        ...mapState['users']
    },

    methods: {
        parseMentionToView(content) {
            if (!content) {
                return content
            }

            const markups = content.match(/(<user:([^>]+)>)/gi)
            let parsedBody = {data: content}
            const _this = this

            if (markups) {
                markups.forEach(function (value, i) {
                    const userId = value.match(/\d/g).join('')
                    const user = _this.users.find(user => user.id.toString() === userId)

                    if (user) {
                        const idPattern = new RegExp(`<user:${userId}>`, 'gi')
                        parsedBody.data = parsedBody.data.replace(idPattern, `<span class="mention">@${user.name}</span>`)
                    }
                })
            }

            return parsedBody.data
        },

        parseMentionToMarkup(content) {
            const parser = new DOMParser()
            const doc = parser.parseFromString(content, 'text/html')

            const spanEl = doc.querySelectorAll('span.mention')
            spanEl.forEach(function (value, i) {
                const id = value.getAttribute('data-id')

                value.parentNode.replaceChild(document.createTextNode('<user:' + id + '>'), value)
            })
            return doc.body.innerText
        }
    }
}

let statics_mixin = {
    mixins: [html_mixin],

    data() {
        return {
            loading_whitelabel: false,
            statics: {}
        }
    },

    methods: {
        getStatics({page_title = ''} = {}) {
            this.loading_whitelabel = true
            axios.get('/get-statics')
                .then(res => {
                    this.statics = res.data
                    this.loading_whitelabel = false

                    if (page_title) {
                        this.setPageTitle(`${page_title} - ${this.statics.name}`)
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.loading_whitelabel = false
                })
        }
    }
}

const ADDON_LOCAL_PRESENCE_CATEGORY = 1

let addons_mixin = {
    async mounted() {
        await this.initializeAddons()
    },

    computed: {
        ...mapState(['addons']),

        allAddons() {
            return this.addons || []
        },

        localPresencePackages() {
            return this.allAddons
                .filter(addon => addon.category == ADDON_LOCAL_PRESENCE_CATEGORY)
        },

        countryByType() {
            let dict = {}

            for (let localPresencePackage of this.localPresencePackages) {
                dict[localPresencePackage.type] = localPresencePackage.data.country
            }

            return dict
        },

        localPresencesByType() {
            let dict = {}

            for (let localPresencePackage of this.localPresencePackages) {
                dict[localPresencePackage.type] = localPresencePackage
            }

            return dict
        },

        nationwideLocalPresenceOptions() {
            return this.localPresencePackages
                .filter(localPresencePackage => localPresencePackage.data?.nationwide)
                .map(localPresencePackage => localPresencePackage.type)
        },

        localPresencePricesByType() {
            let prices = {}

            for (let localPresencePackage of this.localPresencePackages) {
                let price = 0
                if (localPresencePackage.data?.price) {
                    price = localPresencePackage.data.price
                }
                prices[`${localPresencePackage.type}`] = price
            }

            return prices
        }
    },

    methods: {
        ...mapActions(['setAddons']),

        initializeAddons() {
            return axios.get('/api/v1/addons')
                .then(({data}) => {
                    this.setAddons(data.addons)
                })
        },

        getLocalPresenceOptionsByCountry(country) {
            return this.localPresencePackages
                .filter(({data}) => data.country == country)
        },

        getTypeByName(name) {
            return this.localPresencePackages
                .filter(localPresencePackage => localPresencePackage.name == name)[0]
                .type
        },

        getPriceByName(name) {
            let localPresence = this.localPresencePackages
                .filter(localPresencePackage => localPresencePackage.name == name)[0]

            if (localPresence?.data?.price) {
                return localPresence.data.price
            }

            return 0
        }
    }
}

let kyc_mixin = {
    data() {
        return {
            auth
        }
    },

    computed: {
        ...mapState(['shouldOpenComplianceWizard']),
        ...mapState('cache', ['current_company']),

        currentKycStatus() {
            return this.getStatus()
        },

        isTrial() {
            return this.current_company?.is_trial
        },

        isKYCFilled() {
            return this.current_company?.kyc_filled
        },

        isKYCFilledNotReviewed() {
            return this.isKYCFilled && this.isKYCFilledButNotReviewed()
        },

        isCompanyKYCAbleToAddUser() {
            if (this.kycApproved) {
                return true
            }
            // if it is creating a user return true
            if (this.user_id == null) {
                return true
            }

            if (this.user_id === this.current_company.first_billing_admin.id) {
                return false
            }

            return Number(this.user_id) !== Number(this.auth.user.profile.id)
        },

        kycApproved() {
            return this.current_company.kyc_status === KYC_STATUSES.KYC_STATUS_APPROVED
        },

        kycPending() {
            return this.current_company.kyc_status === KYC_STATUSES.KYC_STATUS_PENDING
        },

        kycRejected() {
            return this.current_company.kyc_status === KYC_STATUSES.KYC_STATUS_REJECTED
        },
    },

    methods: {
        getStatus() {
            return this.current_company?.kyc_status
        },

        enabledToSkipTrialAndSubscribe() {
            return !this.isTrial || this.kycApproved
        },

        isKYCFilledButNotReviewed() {
            const kyc_status = this.getStatus()

            return KycLogs.KYC_FILLED_BUT_NOT_REVIEWED.includes(kyc_status)
        },

        customMessage(task) {
            if (!this.current_company) {
                return {
                    prefix: '',
                    suffix: '',
                    showLink: false,
                }
            }

            const status = this.currentKycStatus
            const action = this.textForTask(task)
            const routeData = {
                name: 'Account',
                query: {
                    tab: 'compliance',
                },
            }

            if (task === 'text' && this.isTrial) {
                return {
                    prefix: 'Your account is in trial. According to regulations, you cannot send outbound messages without registration.',
                    suffix: ' Please convert to a subscription and register to use messaging services.',
                    showLink: false,
                }
            } else if (task === 'billing.subscribe' && !this.isKYCFilled) {
                return {
                    prefix: 'Please submit your business information before adding your payment method. Check ',
                    suffix: '.',
                    showLink: true,
                    linkText: action,
                    linkRoute: routeData,
                }
            } else if (task === 'billing.subscribe' && this.isKYCFilledNotReviewed) {
                return {
                    prefix: 'Registration is in review.',
                    suffix: ' You will be able to subscribe once your registration is approved.',
                    showLink: false,
                }
            } else if (task === 'line.create') {
                return {
                    prefix: 'Please submit your business information before adding your line. Click on this message.',
                    showLink: true,
                    linkText: 'Check this form.',
                    linkRoute: routeData,
                }
            } else {
                switch (status) {
                    case KycLogs.KYC_STATUS_ONE:
                        return {
                            prefix: '',
                            suffix: '',
                            showLink: false,
                        }
                    case KycLogs.KYC_STATUS_REJECTED:
                        return {
                            prefix: 'You need to ',
                            suffix: ` about your business to ${action}`,
                            showLink: true,
                            linkText: 'submit again the info',
                            linkRoute: routeData,
                        }
                    default:
                        return {
                            prefix: `Your account is not on a plan with ${action}.`,
                            suffix: ' Please upgrade today to gain full access.',
                            showLink: false,
                        }
                }
            }
        },

        textForTask(task) {
            let text = ''

            switch (task) {
                case 'text':
                    text = 'text messages'
                    break
                case 'billing.subscribe':
                    text = 'this form'
                    break
            }

            return text
        },

        onOpenFinishRegistration() {
            return this.$router.push({
                name: 'Account',
                query: {
                    tab: 'compliance',
                }
            })
        },
    }
}


let chargebee_mixin = {

    mixins: [acl_mixin, kyc_mixin],

    data() {
        return {
            chargebeeInstance: null,
            hasChargeBeeSession: false
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        cardAdded() {
            return this.current_company?.card_added
        },

        isChargeBeeSessionOrSubscriptionDisabled() {
            return !this.hasChargeBeeSession || !this.enabledToSkipTrialAndSubscribe()
        }
    },

    methods: {
        prepareChargeBee() {
            if (!this.hasChargeBeeSession && localStorage.getItem('billing_status') == true) {
                try {
                    // Chargebee.registerAgain() @janssen commented this line it's causing the getCart error
                    this.chargebeeInstance = this.$root.chargebeeInstance
                    if (this.hasPermissionTo('see chargebee portal')) {
                        this.fetchChargeBeePortalSession()
                        if (!window.chargebeeIntervalId) {
                            window.chargebeeIntervalId = setInterval(() => {
                                this.fetchChargeBeePortalSession()
                            }, 60 * 1000)
                        }
                    }
                } catch (err) {
                    console.log(err)
                }
            }
        },

        fetchChargeBeePortalSession() {
            if (this.hasPermissionTo('see chargebee portal')) {
                axios.get('/generate-portal-session').then(res => {
                    this.hasChargeBeeSession = true
                    let portal_session = res.data
                    this.setChargeBeePortalSession(portal_session)
                }).catch(err => {
                    this.hasChargeBeeSession = false
                    console.log(err)
                })
            }
        },

        setChargeBeePortalSession(portal_session) {
            if (this.hasPermissionTo('see chargebee portal')) {
                this.hasChargeBeeSession = true
                this.chargebeeInstance.setPortalSession(() => {
                    return new Promise((resolve, reject) => {
                        resolve(portal_session)
                    })
                })
            }
        },

        paymentSourceSettings(cb) {
            if (this.hasPermissionTo('see chargebee portal')) {
                let cbInstance = Chargebee.getInstance()
                let cbPortal = cbInstance.createChargebeePortal()
                let callbacks = {
                    paymentSourceAdd: (status) => {
                        // called whenever a new payment source is added in portal
                        if (status == 'success') {
                            cbInstance.closeAll()
                            cb()
                        }
                    },
                }

                cbPortal.open(callbacks, {
                    sectionType: Chargebee.getPortalSections().PAYMENT_SOURCES,
                })
            }
        },

        billingHistory() {
            if (this.hasPermissionTo('see chargebee portal')) {
                let cbInstance = Chargebee.getInstance()
                let cbPortal = cbInstance.createChargebeePortal()

                cbPortal.openSection({
                    sectionType: Chargebee.getPortalSections().BILLING_HISTORY,
                })
            }
        },

        chargebeePortal() {
            if (this.hasPermissionTo('see chargebee portal')) {
                let cbInstance = Chargebee.getInstance()
                let cbPortal = cbInstance.createChargebeePortal()
                cbPortal.open()
            }
        },

        chargebeePaymentSources() {
            if (this.hasPermissionTo('see chargebee portal')) {
                let cbInstance = Chargebee.getInstance()
                let cbPortal = cbInstance.createChargebeePortal()

                cbPortal.openSection({
                    sectionType: Chargebee.getPortalSections().PAYMENT_SOURCES,
                })
            }
        },

        chargebeeUpgrade() {
            if (this.hasPermissionTo('see chargebee portal')) {
                let chargebeeInstance = Chargebee.getInstance()
                chargebeeInstance.openCheckout({
                    hostedPage: () => {
                        return axios.post("/api/v1/subscription/upgrade", {}).then((response) => response.data)
                    },
                    loaded: function () {

                    },
                    close: function () {

                    },
                    success: function () {
                        // Reload the page to get all the updates from the backend
                        return axios.post("/api/v1/subscription/convert-trial", {}).then(() => {
                            window.location.reload()
                        })
                    },
                    step: function (value) {

                    }
                })
            }
        },

        chargebeeUpdatePaymentMethod(activate = false) {
            if (this.hasPermissionTo('see chargebee portal')) {
                let chargebeeInstance = Chargebee.getInstance()
                let chargebeePortalInstance = chargebeeInstance.createChargebeePortal()

                chargebeeInstance.setPortalSession(function () {
                    return axios.post("/api/v1/subscription/portal-session")
                        .then((response) => response.data)
                })

                let callbacks = {
                    paymentSourceAdd: (status) => {
                        // called whenever a new payment source is added in portal
                        if (status == 'success') {
                            chargebeeInstance.closeAll()
                            window.location.reload()
                        }
                    },

                    close() {
                        window.location.reload()
                    }
                }

                chargebeePortalInstance.open(callbacks, {
                    sectionType: Chargebee.getPortalSections().PAYMENT_SOURCES,
                })
            }
        }
    },

    beforeDestroy() {
        clearInterval(window.chargebeeIntervalId)
    }
}

let custom_script_mixin = {
    computed: {
        ...mapState('cache', ['current_company']),

        isAloware() {
            return this.current_company?.id === (process.env.NODE_ENV === 'development' ? 7 : 47)
        },

        isSimpSocial() {
            return this.current_company?.reseller_id === 357
        },

        authenticated() {
            return this.auth.user.authenticated
        },

        signup() {
            return this.$route.name === 'SSU'
        },

        isHSScriptPresent() {
            return localStorage.getItem('hs_custom_js') !== null && localStorage.getItem('hs_custom_js') !== ''
        },

        isLocal() {
            return localStorage.getItem('app_env') === 'local'
        }
    }
}

const supervisor_mixin = {
    data: () => ({
        defaultModule: 'Reports',
        allowedModules: [
            'Lines',
            'Ring Groups',
            'Lists',
            'Sequences 2',
            'Sequence Manager',
            'Reports'
        ]
    }),

    mounted() {
        // redirect user to default module if not allowed
        if (this.isSupervisor && !this.allowedModules.includes(this.$route.name)) {
            this.$router.push({name: this.defaultModule})
        }
    }
}

const login_mixin = {
    methods: {
        verifyRedirect(res, next) {
            // Hardcoded homepage when user is supervisor
            if (this.isSupervisor(res.data.user.user_roles)) {
                next({name: 'Reports'})
                return
            }

            next({name: 'Dashboard'})
        },

        isAdmin(roles) {
            return roles.some(role => ['Billing Admin', 'Company Admin'].includes(role))
        },

        isSupervisor(roles) {
            return roles.includes('Company Supervisor')
        }
    }
}

const company_token_mixin = {
    data: () => ({
        renderRefreshToken: 1,
    }),
    computed: {
        ...mapState('cache', ['current_company']),
        ...mapState(['companyTokenVisibility']),

        companyToken() {
            return this.companyTokenVisibility ? this.current_company?.form_capture_token : '[API_TOKEN]'
        },
    },

    methods: {
        ...mapActions(['toggleCompanyTokenVisibility']),
    },

    watch: {
        companyTokenVisibility: {
            handler() {
                // this is a hack to force the UI to re-render the parts that are wrapped between <pre> tags
                // that tag is not reactive, so we need to force the re-render
                this.renderRefreshToken++
            },
            immediate: true,
        },

    }
}

const teams_mixin = {
    data() {
        return {
            isLoadingAllTeams: false,
            isLoadingUserTeams: false,
            teams: [],
            isAddUserToTeamModalVisible: false,
        }
    },

    computed: {
        ...mapState([
            'all_teams',
            'should_reload_all_teams'
        ])
    },

    methods: {
        ...mapActions([
            'setAllTeams',
            'setShouldReloadAllTeams'
        ]),

        openAddUserToTeamModal() {
            this.isAddUserToTeamModalVisible = true
        },

        closeAddUserToTeamModal() {
            this.isAddUserToTeamModalVisible = false
        },

        fetchTeams() {
            this.isLoadingAllTeams = true

            const params = {
                per_page: 100
            }

            this.$APIV2.Teams.index(params)
                .then(res => {
                    this.setAllTeams(res.data?.data)
                    this.setShouldReloadAllTeams(false)
                })
                .catch(err => {
                    console.log(err)
                }).finally(() => {
                this.isLoadingAllTeams = false
            })
        },

        fetchUserTeams(userId) {
            if (!userId) {
                return
            }

            this.isLoadingUserTeams = true

            this.$APIV1.User.getUserTeams(userId)
                .then(response => {
                    this.teams = response.data?.teams
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    this.isLoadingUserTeams = false
                })
        },
    },

    mounted() {
        if (!this.all_teams.length || this.should_reload_all_teams) {
            this.fetchTeams()
        }
    },

    watch: {
        should_reload_all_teams() {
            if (this.should_reload_all_teams) {
                this.fetchTeams()
            }
        }
    }
}

const csm_info_mixin = {
    data() {
        return {
            auth
        }
    },

    computed: {
        hasCsm() {
            return auth.hasCsm()
        },

        hasSalesRep() {
            return auth.hasSalesRep()
        },

        csmInfo() {
            return this.auth.hasCsm() ?
                {
                    // we've a mix of combination for names so we use the name column as first option or the combination of first and last name
                    name: auth.user.profile.csm_rep.name || `${auth.user.profile.csm_rep.first_name || ''} ${auth.user.profile.csm_rep.last_name || ''}`,
                    email: auth.user.profile.csm_rep.email || '',
                    phone: auth.user.profile.csm_rep.phone_number || '',
                    calendar_link: auth.user.profile.csm_rep.calendar_link || '',
                    internal: Boolean(auth.user.profile.csm_rep.is_internal_account)
                }
                : {}
        },
    }
}

/*
This is used to automatically fetch data from API servers periodically
*/
const polling_mixin = {
    data() {
        return {
            polling_interval: 5, // in seconds
            users_poll_interval: null
        }
    },

    methods: {
        ...mapMutations(['UPDATE_AGENT_STATUS']),

        addUsersPoll() {
            // runs after 'polling_interval' seconds the app is initiated, every 'polling_interval' seconds
            this.users_poll_interval = setInterval(() => {
                this.pollUsers()
            }, this.polling_interval * 1000)
        },

        pollUsers() {
            console.log('Polling agents status...')

            this.$APIV1.Company.getAgentsStatus().then((result) => {
                // update agent_status of every user
                for (const user of result.data) {
                    this.UPDATE_AGENT_STATUS(user)

                    if (this.current_company.id === user.company_id) {
                        VueEvent.fire('agent_status_updated', user)
                    }
                }
            })
        }
    },

    beforeDestroy() {
        clearInterval(this.users_poll_interval)
    }
}

const aloai_mixin = {
    methods: {
        lockVoiceOptions(type) {
            return type.value === AloAi.TYPE_VOICE && !this.isCompanyPartOfAlowareDemoCompanies()
        },
    }
}

export {
    base_report_mixin,
    report_mixin,
    date_mixin,
    csm_info_mixin,
    custom_highcharts_mixin,
    graph_mixin,
    map_graph_mixin,
    chargebee_mixin,
    validator_mixin,
    form_validation_mixin,
    styling_mixin,
    acl_mixin,
    goback_mixin,
    avatar_mixin,
    campaign_mixin,
    paginator_mixin,
    communication_mixin,
    html_mixin,
    communication_info_mixin,
    user_mixin,
    user_info_mixin,
    users_mixin,
    scroll_mixin,
    sequence_info_mixin,
    form_handler,
    summary_widgets_mixin,
    filter_mixin,
    contact_filters_mixin,
    contact_activities_mixin,
    crm_link_mixin,
    tab_section_mixin,
    crm_integration_access_mixin,
    contact_phone_number_mixin,
    contact_talk_mixin,
    calendar_talk_mixin,
    string_mixin,
    compliance_mixin,
    url_shortener_mixin,
    helper_mixin,
    mentions_mixin,
    statics_mixin,
    addons_mixin,
    kyc_mixin,
    sms_mixin,
    custom_script_mixin,
    supervisor_mixin,
    login_mixin,
    phone_masker_mixin,
    company_token_mixin,
    teams_mixin,
    phone_wizard_mixin,
    polling_mixin,
    aloai_mixin
}
