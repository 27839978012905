<template>
    <base-tool
        :tool-name="tool.name"
        :tool-description="tool.description"
        @back="goBack"
    >
        <disposition-status-selector
            v-model="selectedDispositionId"
            :show_not_disposed="false"
            @change="handleDispositionChange"
        />
    </base-tool>
</template>

<script>
import { mapState } from 'vuex'
import BaseTool from './base-tool.vue'
import ToolMixin from './tool-mixin'

export default {
    name: 'DispositionTool',

    mixins: [ToolMixin],

    components: {
        BaseTool
    },

    data() {
        return {
            selectedDispositionId: null
        }
    },

    computed: {
        ...mapState(['disposition_statuses']),
    },

    methods: {
        handleDispositionChange(id) {
            if (!id) {
                this.$emit('disposition-selected', null)
                return
            }

            const disposition = this.disposition_statuses.find(d => d.id === id)
            this.$emit('disposition-selected', disposition)
        }
    }
}
</script>
