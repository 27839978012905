var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "formatted-instructions" }, [
    _c(
      "div",
      { staticClass: "instructions-container" },
      [
        !_vm.isEditing
          ? _c(
              "div",
              {
                staticClass: "formatted-display el-textarea",
                on: { click: _vm.enableEditMode },
              },
              [
                _c(
                  "div",
                  {
                    ref: "formattedContent",
                    staticClass: "el-textarea__inner formatted-content",
                    class: { "is-empty": !_vm.localValue },
                    attrs: { "data-placeholder": _vm.placeholder },
                  },
                  [
                    _vm.localValue
                      ? [
                          _vm._l(_vm.formattedParts, function (part, index) {
                            return [
                              part.type === "text"
                                ? _c("span", { key: "text-" + index }, [
                                    _vm._v(_vm._s(part.content)),
                                  ])
                                : part.type === "tool"
                                ? _c(
                                    "el-tag",
                                    {
                                      key: "tool-" + index,
                                      staticClass: "tool-tag",
                                      attrs: {
                                        type: "primary",
                                        size: "small",
                                        effect: "plain",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(part.content) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ]
                      : [
                          _vm._l(
                            _vm.formattedPlaceholder,
                            function (part, index) {
                              return [
                                part.type === "text"
                                  ? _c(
                                      "span",
                                      {
                                        key: "placeholder-text-" + index,
                                        staticClass: "placeholder-text",
                                      },
                                      [_vm._v(_vm._s(part.content))]
                                    )
                                  : part.type === "tool"
                                  ? _c(
                                      "el-tag",
                                      {
                                        key: "placeholder-tool-" + index,
                                        staticClass: "tool-tag placeholder-tag",
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                          effect: "plain",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(part.content) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            }
                          ),
                        ],
                  ],
                  2
                ),
              ]
            )
          : _c("el-input", {
              ref: "textareaInput",
              staticClass: "w-100 aloai-instructions",
              attrs: {
                type: "textarea",
                placeholder: _vm.placeholder,
                rows: _vm.rows,
                readonly: _vm.readonly,
              },
              on: {
                input: _vm.onInput,
                focus: _vm.onFocus,
                blur: _vm.handleBlur,
              },
              model: {
                value: _vm.localValue,
                callback: function ($$v) {
                  _vm.localValue = $$v
                },
                expression: "localValue",
              },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }