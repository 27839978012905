<template>
    <div class="app-body"
         id="view">
        <el-dialog :visible.sync="show_help"
                   title="What are tags?"
                   top="10vh"
                   append-to-body>
            <p class="text-md _600 break-word">Tags help you categorize and segment your audience in a way like Lists,
                but with the added benefit of having the same person in multiple places.</p>
            <p class="text-md _600 break-word">The entire audience of a Tag can be enrolled in a sequence. Your contact imports show up as a new tag with the date of upload.</p>
        </el-dialog>
        <delete-tag-contacts-dialog
            :show_hide_dialog="dialog_visibility"
            :tag_id="tag.id"
            :tagged_contacts_count="tag.tagged_contacts_count"
            @toggle-dialog="toggleDialog"
            @dialog-delete-btn-clicked="deleteTagRemote"
        ></delete-tag-contacts-dialog>
        <div class="fixed-header padding pt-0 pb-0" :style="{top: (top_offset+50) + 'px'}">
            <div class="row mb-0 mt-0">
                <div class="d-flex flex-fill flex-wrap p-2">
                    <div class="d-flex flex-fill flex-wrap mr-auto pr-xl-2">
                        <div class="mr-sm-2 flex-fill flex-xl-grow-0 search-toolbar">
                            <el-input placeholder="Search"
                                      prefix-icon="el-icon-search"
                                      ref="searchBar"
                                      clearable
                                      autofocus
                                      v-model="search_text"
                                      @input="search">
                            </el-input>
                        </div>

                        <div class="no-select mt-2 mt-md-0 mr-2 flex-sm-shrink-0">
                            <el-radio-group class="no-select"
                                            v-model="tag_filter"
                                            @change="onFilterChange">
                                <el-radio-button label="communication">Communication Tags</el-radio-button>
                                <el-radio-button label="contact">Contact Tags</el-radio-button>
                                <el-radio-button label="import">Import Tags</el-radio-button>
                            </el-radio-group>
                        </div>
                    </div>

                    <div class="d-flex flex-wrap">
                        <tag-creator id="add-tag"
                                     ref="addTag"
                                     class="mr-2 mt-2 mt-xl-0"
                                     @success="addTag">
                            <template v-slot:trigger>
                                <button class="btn btn-xl rounded greenish h-100"
                                        @click="hideAddTagButton">
                                    <i class="fa fa-plus"></i>
                                    Add Tag
                                </button>
                            </template>
                        </tag-creator>

                        <video-modal-activator class="pr-2"
                                               v-if="isTrial && !isSimpSocial"/>
                        <div class="mt-2 mt-xl-0 mr-2 mr-xl-0 d-flex flex-column justify-content-center text-blue">
                            <i class="material-icons text-lg cursor-pointer"
                               @click="toggleHelp">
                                info_outline
                            </i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="padding mt-5 pt-3">
            <div v-if="hasPermissionTo('list tag')">
                <div class="row box">
                    <div class="col-12">
                        <div class="no-effect">
                            <div class="box-header">
                                <div class="row">
                                    <div class="col-12">
                                        <span class="pull-left font-120">
                                            <strong>{{ tags_count }}</strong>
                                            {{ tags_count > 1 ? 'tags' : 'tag' }}
                                        </span>
                                    </div>
                                </div>
                                <!-- WAT-876: deprecation banner -->
                                <al-alert :dimisable="true"
                                          v-if="shouldShowTagsDeprecationBanner">
                                    <span class="mr-5 text-dark"
                                          v-html="TAGS_DEPRECATION_MESSAGE" />
                                </al-alert>
                            </div>
                            <div class="box-body">
                                <tags-table ref="tags_table"
                                            :tag_filter="tag_filter"
                                            :search_text="search_text"
                                            @toggle-dialog-triggered="onToggleDialogTriggered"
                                            @tags-count="setTagsCount">
                                </tags-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-backtop target="html"
                    ref="backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>

        <video-modal class="pl-2"
                     title="🏷️ Mastering Aloware Tags: Organize Your Contacts Effectively"
                     cookie-name="tags"
                     notes="🌟 <strong>Tags</strong> = Your Ultimate Categorizer! </br></br> 🌟 Like Lists, but leveled up! Place the same person everywhere. </br></br> Bonus? Enroll a whole Tag audience in a sequence. </br></br> Plus, new contacts? They flash in with a fresh tag stamped with their upload date. 🚀"
                     video-url="https://www.youtube.com/embed/VUYVx0XGeLc?si=EgN3EQJBGay15kGz"
                     learn-more-link="https://support.aloware.com/en/articles/9031892-tags-in-aloware-admin"
                     :has-activator-button="true"
                     v-if="isTrial && !isSimpSocial"/>
    </div>
</template>

<script>
import auth from '../../auth'
import {
    acl_mixin,
    scroll_mixin,
    styling_mixin,
    kyc_mixin,
    supervisor_mixin
} from '../../mixins'
import { mapGetters } from 'vuex'
import TagCreator from '../../components/tag-creator'
import DeleteTagContactsDialog from "../../components/delete-tag-contacts-dialog"
import TagsTable from './tags-table'
import VideoModal from '../../components/video-modal.vue'
import VideoModalActivator from '../../components/video-modal-activator.vue'
import Alert from '../../components/alert'

import { TAGS_DEPRECATION_MESSAGE } from '../../constants/deprecation.messages'

export default {
    mixins: [
        styling_mixin,
        acl_mixin,
        scroll_mixin,
        kyc_mixin,
        supervisor_mixin
    ],

    components: {
        TagCreator,
        DeleteTagContactsDialog,
        TagsTable,
        VideoModal,
        VideoModalActivator,
        'al-alert': Alert
    },

    data() {
        return {
            auth,
            tag_filter: 'contact',
            tags_count: 0,
            show_help: false,
            search_text: '',
            dialog_visibility: false,
            tag: {id: 0, tagged_contacts_count: 0},
            loading_extend_tag: [],
            tags_table: null,
            TAGS_DEPRECATION_MESSAGE
        }
    },

    computed: {
        ...mapGetters('cache', ['isTrial', 'isSimpSocial']),

        shouldShowTagsDeprecationBanner () {
            return this.tag_filter === 'contact'
        }
    },

    mounted() {
        this.setTitle()

        if (!this.hasPermissionTo('list tag')) {
            this.goBack()
        }

        if ('tag_filter' in this.$route.query) {
            this.tag_filter = this.$route.query.tag_filter.length > 0 ? this.$route.query.tag_filter : 'company'
        }

        if (this.$refs.tags_table) {
            this.tags_table = this.$refs.tags_table
        }
        // focus tag search input on page visit
        if (this.$refs.searchBar) {
            this.$refs.searchBar.focus()
        }
    },

    methods: {
        setTitle() {
            axios.get('/get-statics')
                .then(res => {
                    let name = res.data.name
                    this.setPageTitle('Tags - ' + name)
                })
                .catch(err => {
                    this.setPageTitle('Tags - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },

        search: _.debounce(function () {
            if (this.tags_table) {
                this.tags_table.loadTags()
            }
        }, 700),

        onFilterChange() {
            if (this.tags_table) {
                this.search_text = ''
                setTimeout(() => {
                    this.tags_table.loadTags()
                    this.$router.push({name: 'Tags', query: {tag_filter: this.tag_filter}})
                }, 100)
            }
        },

        toggleHelp() {
            this.show_help = !this.show_help
        },

        onToggleDialogTriggered({tag_id, tagged_contacts_count}) {
            this.toggleDialog(tag_id, tagged_contacts_count)
        },

        toggleDialog(tag_id, tagged_contacts_count) {
            this.tag.id = tag_id
            this.tag.tagged_contacts_count = tagged_contacts_count
            this.dialog_visibility = !this.dialog_visibility
        },

        deleteTagRemote(info) {
            this.toggleDialog()
            axios.delete('/api/v1/tag/' + info.tag_id, {data: info.data})
                .then(res => {
                    this.tag.id = 0
                    this.tag.tagged_contacts_count = 0
                    if (this.tags_table) {
                        this.tags_table.deleteTag(info.tag_id)
                        this.tags_count--
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.tag.id = 0
                    this.tag.tagged_contacts_count = 0
                })
        },

        hideAddTagButton() {
            if (_.has(this.$refs, 'addTag.hide_add')) {
                this.$refs.addTag.hide_add = true
            }
        },

        addTag(tag) {
            if (this.tags_table) {
                this.tags_table.addTag(tag)
            }
        },

        setTagsCount(tags_count) {
            this.tags_count = tags_count
        }
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next()
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script>
