<template>
    <div class="app-body" id="view">
        <div
            v-if="shouldShowAloAi"
            class="container-listing"
        >
            <div class="container-header d-flex">
                <h3 class="listing-heading">AloAi Agents</h3>
                <div class="ml-auto">
                    <div class="d-flex">
                        <el-button
                            type="primary"
                            size="medium"
                            round
                            @click="openCreateAgentDialog"
                        >
                            <i class="fa fa-plus mr-1"></i>
                            <strong>New Agent</strong>
                        </el-button>
                    </div>
                </div>
            </div>

            <!-- Search & filter controls -->
            <div class="filter-controls d-flex mb-3">
                <!-- Search input -->
                <el-input
                    v-model="search_query"
                    placeholder="Search by name or ID"
                    prefix-icon="el-icon-search"
                    clearable
                    class="mr-2 w-25"
                    @input="handleSearch"
                />

                <!-- Type filter -->
                <el-select
                    v-model="agent_type"
                    placeholder="Select Type"
                    @change="updateFilters"
                >
                    <el-option
                        label="All"
                        value="all"
                    />
                    <el-option
                        label="Text"
                        value="text"
                    />
                    <el-option
                        label="Voice"
                        value="voice"
                    />
                </el-select>

                <!-- Direction filter -->
                <el-select
                    class="ml-2"
                    v-model="agent_direction"
                    placeholder="Select Direction"
                    @change="updateFilters"
                >
                    <el-option
                        label="All"
                        value="all"
                    />
                    <el-option
                        label="Inbound"
                        value="inbound"
                    />
                    <el-option
                        label="Outbound"
                        value="outbound"
                    />
                </el-select>
            </div>

            <!-- Pricing alert -->
            <el-alert
                type="warning"
                class="mb-3"
                :closable="false"
            >
                <div class="text-dark">
                    All agent interactions are charged per enrollment. Enrollment is defined as a contact talking to an agent, either inbound or outbound.
                </div>
                <div class="text-dark">
                    Your account currently has the following rates:
                </div>
                <div class="text-dark">
                    <b>📞 Voice agent:</b> ${{ displayVoiceRate }} / call
                </div>
                <div class="text-dark">
                    <b>💬 Text agent:</b> ${{ displayTextRate }} / enrollment
                </div>
                <div class="text-dark mt-2">
                    Please contact us for volume pricing.
                </div>
            </el-alert>

            <!-- AloAi Agent type -->
            <el-dialog
                title="Choose your AloAi Agent type:"
                :visible.sync="createAgentDialogVisible"
                top="10vh"
                width="45%"
            >
                <el-radio-group
                    class="w-100"
                    v-model="agent_type"
                    @change="goToSelectDirection"
                >
                    <div class="row row-filter aloai-agents">
                        <div class="col-md-6">
                            <!-- Text option -->
                            <el-card
                                shadow="hover"
                                class="cursor-pointer"
                            >
                                <el-radio
                                    label="text"
                                    class="w-100"
                                >
                                    <div class="d-flex align-items-center mb-2">
                                        <i class="text-2x mr-2 material-icons text-indigo-500">sms</i>
                                        <span class="text-lg font-weight-bold text-grey-9">Text</span>
                                    </div>
                                    <p class="text-md l-h">This agent can be used to handle marketing campaigns, sales & support over text.</p>
                                </el-radio>
                            </el-card>
                        </div>
                        <div class="col-md-6">
                            <!-- Voice option -->
                            <el-card
                                :shadow="isAloAiVoiceAgentsEnabled() ? 'hover' : 'never'"
                                class="cursor-pointer"
                            >
                                <el-radio
                                    label="voice"
                                    class="w-100"
                                    :disabled="!isAloAiVoiceAgentsEnabled()"
                                >
                                    <div class="d-flex align-items-center mb-2">
                                        <i class="text-2x mr-2 material-icons text-indigo-500">phone_in_talk</i>
                                        <span class="text-lg font-weight-bold text-grey-9">{{ !isAloAiVoiceAgentsEnabled() ? 'Voice (Coming Soon)' : 'Voice' }}</span>
                                    </div>
                                    <p class="text-md l-h">This agent can be used to handle inbound and missed calls for different use cases.</p>
                                </el-radio>
                            </el-card>
                        </div>
                    </div>
                </el-radio-group>
            </el-dialog>

            <!-- AloAi Agent direction -->
            <el-dialog
                title="Choose your AloAi Agent direction:"
                :visible.sync="selectAgentDirectionVisible"
                top="10vh"
                width="45%"
            >
                <el-radio-group
                    class="w-100"
                    v-model="agent_direction"
                    @change="createAgent"
                >
                    <!-- Inbound option -->
                    <div class="row row-filter aloai-agents">
                        <div class="col-md-6">
                            <!-- Inbound option -->
                            <el-card
                                shadow="hover"
                                class="cursor-pointer"
                            >
                                <el-radio
                                    label="inbound"
                                    class="w-100"
                                >
                                    <div class="d-flex align-items-center mb-2">
                                        <i class="text-2x mr-2 material-icons text-indigo-500">call_received</i>
                                        <span class="text-lg font-weight-bold text-grey-9">Inbound</span>
                                    </div>
                                    <p class="text-md l-h">This agent can be used for Q&A, Support and other use cases.</p>
                                </el-radio>
                            </el-card>
                        </div>
                        <div class="col-md-6">
                            <!-- Outbound option -->
                            <el-card
                                shadow="hover"
                                class="cursor-pointer"
                                :shadow="shouldLockOutbound ? 'never' : 'hover'"
                            >
                                <el-radio
                                    label="outbound"
                                    class="w-100"
                                    :disabled="shouldLockOutbound"
                                >
                                    <div class="d-flex align-items-center mb-2">
                                        <i class="text-2x mr-2 material-icons text-indigo-500">call_made</i>
                                        <span class="text-lg font-weight-bold text-grey-9">{{ shouldLockOutbound ? 'Outbound (Coming Soon)' : 'Outbound' }}</span>
                                    </div>
                                    <p class="text-md l-h">This agent can be used for initial reach-out for your contacts.</p>
                                </el-radio>
                            </el-card>
                        </div>
                    </div>
                </el-radio-group>
            </el-dialog>

            <!-- AloAi Agents table -->
            <alo-ai-table
                :bots="bots"
                v-loading="is_loading"
                @bot-update="onBotUpdate"
                @bot-delete="onBotDelete"
            />

            <!-- Pagination -->
            <div class="mt-3" v-if="total_bots > 0">
                <el-pagination
                    :current-page.sync="current_page"
                    :page-size="page_size"
                    :page-sizes="[10, 20, 50, 100]"
                    :total="total_bots"
                    @current-change="handlePageChange"
                    @size-change="pageSizeChange"
                    background
                    class="pull-right"
                    layout="sizes, prev, pager, next">
                </el-pagination>
                <div class="clearfix"></div>
            </div>
        </div>

        <!-- Promotion -->
        <upgrade-now-page
            image-link="/assets/images/AloAi.svg"
            text="The leading conversational AI supercharging your path to explosive sales growth"
            extra-text="Enhance your team's efficiency and lower operational expenses by automating conversations throughout the entire customer journey. Our AI agent replaces most BDRs and SDRs and will escalate automatically to humans."
            title-text="AloAi Agents"
            kb-link="https://aloware.com/ai-bot/"
            custom-link="https://share.hsforms.com/1RLbs7DggS1i2Lhwld-nwlQ2qfyk"
            class="mt-5"
            v-if="!shouldShowAloAi && shouldShowUpgradeNow"
        />
    </div>
</template>

<script>
import { MessageBox } from 'element-ui'
import _ from 'lodash'
import { mapState } from 'vuex'
import auth from '../../auth'
import AloAiTable from '../../components/aloai/aloai-table'
import UpgradeNowPage from "../../components/upgrade-now-page"
import { acl_mixin, html_mixin, aloai_mixin } from '../../mixins'

export default {
    name: 'AloAiIndex',

    components: {
        UpgradeNowPage,
        AloAiTable,
    },

    mixins: [acl_mixin, html_mixin, aloai_mixin],

    data() {
        return {
            auth: auth,
            bots: [],
            agent_type: null,
            createAgentDialogVisible: false,
            agent_direction: null,
            selectAgentDirectionVisible: false,
            current_page: 1,
            page_size: 10,
            total_bots: 0,
            search_query: '',
            is_loading: false,
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        shouldLockOutbound() {
            return this.agent_type === 'voice' && !this.isAloAiOutboundVoiceAgentsEnabled()
        },

        displayVoiceRate() {
            if (this.current_company && this.current_company.rate) {
                const rate = parseFloat(this.current_company.rate.aloai_voice_bot_enrollment);
                return isNaN(rate) ? '0.50' : rate.toFixed(2);
            }
            return '0.50';
        },

        displayTextRate() {
            if (this.current_company && this.current_company.rate) {
                const rate = parseFloat(this.current_company.rate.aloai_text_bot_enrollment);
                return isNaN(rate) ? '0.10' : rate.toFixed(2);
            }
            return '0.10';
        },

        displayedBots() {
            if (!this.search_query) {
                return this.bots;
            }

            const query = this.search_query.toLowerCase();
            return this.bots.filter(bot => {
                const nameMatch = bot.name.toLowerCase().includes(query);
                const idMatch = bot.id.toString().includes(query);
                return nameMatch || idMatch;
            });
        }
    },

    beforeRouteEnter(to, from, next) {
        // This logic help us wrap the Bots section with the Aloware App components.
        if (to.query.api_token) {
            return next({
                name: 'Login',
                query: {
                    api_token: to.query.api_token
                }
            })
        }

        auth.check().then((res) => {
            if (res.data.user.is_reseller) {
                // redirect to account management portal if the company is a reseller
                next({name: 'Account Management Portal'})
            } else {
                next()
            }
        }).catch((err) => {
            next({
                name: 'Login',
                query: {
                    redirect: to.fullPath
                }
            })
        })
    },

    methods: {
        isNumeric(str) {
            return /^\d+$/.test(str);
        },

        handleSearch: _.debounce(function() {
            // Search if:
            // 1. Query is empty (show all)
            // 2. Query is numeric (ID search)
            // 3. Query has 3 or more characters (name search)
            if (!this.search_query || this.isNumeric(this.search_query) || this.search_query.length >= 3) {
                this.current_page = 1;
                this.fetchBots();
            }
        }, 200),
        onBotUpdate(bot) {
            this.bots = this.bots.map((c) => {
                if (c.id === bot.id) {
                    return bot
                }

                return c
            })
        },
        onBotDelete(botId) {
            MessageBox.confirm('Are you sure you want to delete this bot?', 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(async () => {
                this.is_loading = true;

                axios.delete(`/api/v1/aloai/bot/${botId}`).then(res => {
                    this.bots = this.bots.filter((c) => {
                        return c.id !== botId
                    })

                    this.$message({
                        message: 'Bot deleted successfully',
                        type: 'success'
                    })

                    this.fetchBots()
                }).catch(err => {
                    this.$message({
                        message: 'Failed to delete bot',
                        type: 'error'
                    })
                    console.log(err)
                }).finally(() => {
                    this.is_loading = false
                })
            }).catch(() => {
                // do nothing
            })
        },
        fetchBots() {
            this.is_loading = true;
            const params = {
                page: this.current_page,
                size: this.page_size,
                search: this.search_query && (this.isNumeric(this.search_query) || this.search_query.length >= 3) ? this.search_query : null
            };
            if (this.agent_type && this.agent_type !== 'all') {
                params.type = this.agent_type;
            }
            if (this.agent_direction && this.agent_direction !== 'all') {
                params.direction = this.agent_direction;
            }

            axios.get('/api/v1/aloai/bot', { params }).then(res => {
                this.bots = res.data.data;
                this.total_bots = res.data.pagination.total;
                this.is_loading = false;
            }).catch(err => {
                console.log(err);
                this.is_loading = false;
            });
        },
        openCreateAgentDialog() {
            this.createAgentDialogVisible = true
            this.agent_type = null
            this.agent_direction = null
        },
        goToSelectDirection() {
            this.createAgentDialogVisible = false
            this.selectAgentDirectionVisible = true
        },
        createAgent() {
            this.$router.push({name: 'AloAi: Create an agent', query: {type: this.agent_type, direction: this.agent_direction}})
        },
        updateFilters() {
            // Reset to page 1 when filters change
            this.current_page = 1;

            const params = {
                page: this.current_page,
                size: this.page_size
            };

            // Check for specific values to clear filters
            if (this.agent_type && this.agent_type !== 'all') {
                params.type = this.agent_type;
            }
            if (this.agent_direction && this.agent_direction !== 'all') {
                params.direction = this.agent_direction;
            }

            this.$router.push({ query: params });
            this.fetchBots();
        },
        handlePageChange(page) {
            this.current_page = page;
            this.fetchBots();

            // Update the URL with the current page
            const params = {
                page: this.current_page,
                size: this.page_size,
            };
            if (this.agent_type && this.agent_type !== 'all') {
                params.type = this.agent_type;
            }
            if (this.agent_direction && this.agent_direction !== 'all') {
                params.direction = this.agent_direction;
            }

            this.$router.push({ query: params });
        },
        pageSizeChange(size) {
            this.page_size = size;
            this.current_page = 1;
            const params = { ...this.$route.query, page: this.current_page, size: this.page_size };
            this.$router.push({ query: params });
            this.fetchBots();
        },
    },

    mounted() {
        this.setPageTitle('AloAi Agents')
        this.agent_type = this.$route.query.type || null;
        this.agent_direction = this.$route.query.direction || null;
        this.current_page = parseInt(this.$route.query.page) || 1;
        this.fetchBots()

        if (!this.shouldShowAloAiMenu) {
            this.goBack()
        }
    },

    watch: {
        $route({ query }) {
            if (!this.shouldShowAloAiMenu) {
                this.goBack()
            }

            this.agent_type = query.type || null;
            this.agent_direction = query.direction || null;
            this.fetchBots();
        }
    }
}
</script>
