export const BROADCAST_DEPRECATION_MESSAGE = `The <b>Admin Broadcast</b> feature will be phased out soon. Please <a class="alert-link text-decoration-underline" href="<%TALK_URL%>/broadcasts">click here</a> for the new <b>Talk Broadcast</b>.`
export const TAGS_DEPRECATION_MESSAGE = `We are migrating the behavior of <b>contact tags</b> into <b>lists</b> soon. Please <a class="alert-link text-decoration-underline" href="${window.location.origin}/lists/">click here</a> to utilize the <b>list page.</b>`
export const TAGS_DEPRECATION_IMPORT_CONTACTS_MESSAGE = `<b>Contact imports</b> will generate a <b>List</b> in addition to a tag. We will <b>migrate contact tags to lists</b> soon.`
export const WALLBOARD_BARGE_WHISPER_DEPRECATION_MESSAGE = `The ability to barge and whisper in Admin will be phased out soon. Please <a class="alert-link text-decoration-underline" href="<%TALK_URL%>/wallboard/overview">click here</a> to barge and whisper in <b>Talk Wallboard.</b>`

// MESSAGE FUNCTION TO REPLACE THE PLACEHOLDERS
export const buildMessage = (message, placeholders) => {
  let newMessage = message
  for (const key in placeholders) {
    newMessage = newMessage.replace(new RegExp(`<%${key.toUpperCase()}%>`, 'g'), placeholders[key])
  }
  return newMessage
}
