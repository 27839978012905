var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-tool",
    {
      attrs: {
        "tool-name": _vm.tool.name,
        "tool-description": _vm.tool.description,
      },
      on: { back: _vm.goBack },
    },
    [
      _c("el-input", {
        staticClass: "mb-2",
        attrs: {
          type: "textarea",
          placeholder: "Enter your message here",
          rows: 4,
        },
        model: {
          value: _vm.message,
          callback: function ($$v) {
            _vm.message = $$v
          },
          expression: "message",
        },
      }),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: { type: "primary", disabled: !_vm.isValidMessage },
          on: { click: _vm.addSms },
        },
        [_vm._v("\n        Add SMS\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }