var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    {
      staticClass: "item ml-1",
      attrs: { effect: "dark", placement: _vm.placement },
    },
    [
      _c("div", { attrs: { slot: "content" }, slot: "content" }, [
        _vm._v(
          "\n        Supervisors will help you to manage the team members. They are able to add and remove users from the teams they are supervising.\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "i",
        {
          staticClass: "material-icons text-sm cursor-pointer text-blue",
          style: `z-index: ${_vm.zIndex} !important;`,
        },
        [_vm._v("info_outline")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }