<template>
    <div class="ring-group-manage-team-inbox" v-if="hasPermissionTo('list user')">
      <div class="text-left mb-2">
        <div class="form-label">
            <h5>Connected Users and Teams</h5>
            <small>
                These are the users and teams that have direct access to the <strong>{{ EINBOX_NAME }}</strong> created by this <strong>Ring Group</strong>. To make changes, update the <strong>Ring Group Builder</strong> under the <strong>General</strong> tab.
            </small>
        </div>
      </div>
  
      <div class="list-ring-group mb-5">
        <div class="list-ring-group-item d-flex align-items-center">
          <div class="layer-label">Connected</div>
          <div class="w-100">
            <div class="d-flex align-items-center">
              <div class="select-label">Users:</div>
              <el-select placeholder=""
                         class="flex-grow-1 w-100"
                         multiple
                         :disabled="true"
                         v-model="user_ids">
                <el-option-group key="Users"
                                 label="Users"
                                 v-if="allUsers && allUsers.length > 0"
                >
                  <el-option class="p-0"
                             :key="`user-${user.id}`"
                             :label="user.name"
                             :value="user.id"
                             v-for="user in allUsers"
                  >
                    <div class="media">
                      <div class="media-body">
                        <label>{{ user.name }}</label>
                        <small>{{ user.email }} - {{ getLabel(user) }}</small>
                      </div>
                    </div>
                  </el-option>
                </el-option-group>
              </el-select>
            </div>
  
            <div class="d-flex align-items-center mt-3">
              <div class="select-label">Teams:</div>
              <el-select placeholder=""
                         class="teams-select flex-grow-1 w-100"
                         multiple
                         :disabled="true"
                         v-model="team_ids">
                <el-option-group label="Teams"
                                 v-if="filteredTeams && filteredTeams.length > 0">
                  <el-option class="p-0"
                             :key="team.id"
                             :label="team.name"
                             :value="team.id"
                             v-for="team in filteredTeams">
                    <div class="media">
                      <div class="media-body">
                        <label>{{ team.name }}</label>
                      </div>
                    </div>
                  </el-option>
                </el-option-group>
              </el-select>
            </div>
          </div>
        </div>
      </div>
  
      <div class="text-left mb-2">
        <div class="form-label">
            <h5>Watching Users and Teams</h5>
            <small>
                These users and teams have view-only access to the <strong>{{ EINBOX_NAME }}</strong> created by this <strong>Ring Group</strong>. They will receive notifications for <strong>Missed</strong> and <strong>Abandoned</strong> calls.
            </small>
        </div>
      </div>
  
      <div class="list-ring-group mb-2">
        <div class="list-ring-group-item d-flex align-items-center">
          <div class="layer-label">Watching</div>
          <div class="w-100">
            <div class="d-flex align-items-center">
              <div class="select-label">Users:</div>
              <el-tooltip class="item"
                          effect="dark"
                          content="Select one or more users."
                          placement="top">
                <el-select placeholder="Add one or more users"
                           class="flex-grow-1 w-100"
                           multiple
                           filterable
                           default-first-option
                           :disabled="loading"
                           v-model="ring_group.watcher_user_ids">
                  <el-option-group key="Users"
                                   label="Users"
                                   v-if="allUsers && allUsers.length > 0">
                    <el-option class="p-0"
                              :key="`user-${user.id}`"
                              :label="user.name"
                              :value="user.id"
                              v-for="user in allUsers">
                      <div class="media">
                        <div class="media-body">
                          <label>{{ user.name }}</label>
                          <small>{{ user.email }} - {{ getLabel(user) }}</small>
                        </div>
                      </div>
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-tooltip>
            </div>
  
            <div class="d-flex align-items-center mt-3">
              <div class="select-label">Teams:</div>
              <el-tooltip class="item"
                          effect="dark"
                          content="Select one or more teams."
                          placement="top">
                <el-select placeholder="Add one or more teams"
                           class="teams-select flex-grow-1 w-100"
                           multiple
                           filterable
                           default-first-option
                           popper-class="teams-select-popper"
                           :disabled="loading"
                           v-model="ring_group.watcher_team_ids">
                  <el-option-group label="Teams"
                                   v-if="filteredTeams && filteredTeams.length > 0">
                    <el-option class="p-0"
                               :key="team.id"
                               :label="team.name"
                               :value="team.id"
                               v-for="team in filteredTeams">
                      <div class="media">
                        <div class="media-body">
                          <label>{{ team.name }}</label>
                        </div>
                      </div>
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <ring-group-reporting class="mt-4"
                            :settings="ring_group.reporting_settings"
                            :ring-group-name="ring_group.name" />
    </div>
  </template>
  
  <script>
  import auth from "../../auth"
  import { mapState } from "vuex"
  import { acl_mixin, user_info_mixin, teams_mixin } from "../../mixins"
  import * as AnswerTypes from "../../constants/answer-types"
  import RingGroupReporting from './ring-group-reporting.vue'
  import { EINBOX_NAME } from '../../constants/team-inbox'
  
  export default {
    name: "ring-group-manage-team-inbox",
    components: {
      RingGroupReporting
    },
    mixins: [acl_mixin, user_info_mixin, teams_mixin],
  
    props: {
      ring_group: {
        required: true,
        type: Object,
      },
    },
  
    data() {
      return {
        auth: auth,
        loading: false,
        filtered_text: null,
        user_ids: [],
        team_ids: [],
        AnswerTypes,
        EINBOX_NAME
      }
    },
  
    computed: {
      ...mapState({
        current_company: (state) => state.cache.current_company,
      }),
  
      filteredUsers() {
        if (this.users) {
          let filtered_users = this.users.filter(
            (user) =>
              !(
                user.role_names &&
                user.role_names.length === 1 &&
                user.read_only_access
              ) &&
              user.answer_by !== AnswerTypes.BY_NONE &&
              user.enabled
          )
  
          if (this.filtered_text) {
            return filtered_users.filter(
              (user) =>
                (user.name &&
                  user.name
                    .toLowerCase()
                    .includes(this.filtered_text.toLowerCase())) ||
                (user.phone_number &&
                  user.phone_number.includes(this.filtered_text)) ||
                (user.email &&
                  user.email
                    .toLowerCase()
                    .includes(this.filtered_text.toLowerCase()))
            )
          }
  
          return filtered_users
        }
  
        return []
      },
  
      filteredTeams() {
        if (this.all_teams && this.filtered_text) {
          return this.all_teams.filter((team) =>
            team.name.toLowerCase().includes(this.filtered_text.toLowerCase())
          )
        }
  
        return this.all_teams
      },
  
      normalUsers() {
        return this.filteredUsers.filter((user) => {
          return !user.is_destination && user.active
        })
      },
  
      extensionUsers() {
        return this.filteredUsers.filter((user) => {
          return user.is_destination && user.active
        })
      },
  
      pausedUsers() {
        return this.filteredUsers.filter((user) => {
          return !user.is_destination && !user.active
        })
      },
  
      pausedExtensions() {
        return this.filteredUsers.filter((user) => {
          return user.is_destination && !user.active
        })
      },
  
      allUsers() {
        return [
          ...this.normalUsers,
          ...this.extensionUsers,
          ...this.pausedUsers,
          ...this.pausedExtensions,
        ]
      },
    },
  
    mounted() {
      this.loadRingGroupManageTeamInbox()
    },
  
    methods: {
      getLabel(user) {
        if (!user) {
          return
        }
  
        switch (user.answer_by) {
          case AnswerTypes.BY_PHONE_NUMBER:
            return "Phone Number (" + user.phone_number + ")"
          case AnswerTypes.BY_BROWSER:
            return "Browser / Apps"
          case AnswerTypes.BY_IP_PHONE:
            return "SIP (IP Phone)"
          case AnswerTypes.BY_NONE:
            return "Will Not Answer"
        }
      },
  
      loadRingGroupManageTeamInbox() {
        // Get all unique user IDs from all layers
        const allUserIds = this.getUniqueIds(this.ring_group.ordered_user_ids)
  
        // Get all unique team IDs from all layers
        const allTeamIds = this.getUniqueIds(this.ring_group.ordered_team_ids)
  
        // Update the component data
        this.user_ids = Array.from(allUserIds)
        this.team_ids = Array.from(allTeamIds)
      },
  
      getUniqueIds(object) {
        return new Set(Object.values(object || {}).flatMap(ids => ids))
      },
    },
  
    watch: {
      ring_group: {
        handler: "loadRingGroupManageTeamInbox",
        immediate: true,
      },
    },
  }
  </script>
  