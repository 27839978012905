<template>
    <div class="app-body page-campaign-settings"
         :style="{'min-height': '100vh'}"
         id="view"
         v-loading="loading_campaign">
        <div v-if="campaign && hasPermissionTo('view campaign')">
            <div class="fixed-header padding pt-0 pb-0" :style="{top: (top_offset+50) + 'px'}">
                <div class="row mb-0 mt-0">
                    <div class="col-12 p-2">
                        <router-link
                            :to="{ name: 'Line Activity', params: {campaign_id: campaign_id}}">
                            <button class="btn purplish pull-left">
                                <i class="fa fa-signal"></i>
                                Activity
                            </button>
                        </router-link>
                        <a href="https://support.aloware.com/en/articles/9020245-managing-lines-settings-in-aloware-admin"
                           target="_blank"
                           v-if="!statics.whitelabel">
                            <button class="btn btn-md pull-left ml-2 text-bluish">
                                <i class="material-icons text-lg">help</i>
                                Tutorial
                            </button>
                        </a>
                    </div>
                </div>
            </div>

            <div class="container-fluid pt-5 pb-3 d-relative">
                <div class="mb-4"></div>
                <div class="card p-2">
                    <el-form ref="campaign_settings"
                             class="form-aloware"
                             label-position="top"
                             v-loading="loading"
                             :model="campaign"
                             :rules="rules"
                             @submit.prevent.native="submitForm('campaign_settings')">
                        <template v-on:keyup.enter="submitForm('campaign_settings')">
                            <el-tabs v-model="active_tab_name"
                                     @tab-click="tabClicked">
                                <el-tab-pane name="general-settings"
                                             class="p-3"
                                             data-tab-error="general_settings">
                                    <span slot="label">General<span v-html="tabLabelErrorIcon(tab_errors.general_settings)"></span></span>
                                    <div class="row">
                                        <div class="col-md-10">
                                            <el-form-item prop="name">
                                                <span class="page-anchor" id="name"></span>
                                                <div class="form-label">
                                                    <h5>Name</h5>
                                                </div>
                                                <el-input v-model="campaign.name"
                                                          @input="preValidateForm('campaign_settings')">
                                                </el-input>
                                            </el-form-item>
                                            <el-form-item>
                                                <div class="form-label">
                                                    <h5>Type</h5>
                                                    <b class="mt-2 label"
                                                       :class="campaign.is_fax ? 'purple-500' : 'bg-black opacity'">
                                                        {{ campaign.is_fax ? 'Fax Only' : 'Standard' }}
                                                    </b>
                                                </div>
                                            </el-form-item>
                                            <el-form-item prop="description">
                                                <span class="page-anchor" id="description"></span>
                                                <div class="form-label">
                                                    <h5>Description</h5>
                                                    <small>Describe the purpose of this line.</small>
                                                </div>
                                                <el-input v-model="campaign.description"
                                                          type="textarea"
                                                          maxlength="190"
                                                          :autosize="{ minRows: 3, maxRows: 3}"
                                                          show-word-limit
                                                          @input="preValidateForm('campaign_settings')">
                                                </el-input>
                                            </el-form-item>

                                            <el-form-item v-if="impersonated"
                                                          prop="calling_only">
                                                <span class="page-anchor"
                                                      id="calling_only">
                                                </span>
                                                <div :class="{'cursor-not-allowed width-fit-content': current_company.calling_only}">
                                                    <span v-if="current_company.calling_only" class="badge badge-warning py-1 mb-2 ">
                                                        Set as Calling Only
                                                    </span>
                                                    <div :class="{'p-2 px-3 border border-warning': current_company.calling_only}">
                                                        <div>
                                                            <div class="form-label">
                                                                <h5>Calling Only</h5>
                                                                <small>When this is active, the whole line will be flagged as calling only, including all the numbers in it.</small>
                                                                <small>
                                                                    <div class="mbt-2 mb-3"
                                                                         v-if="hasRole(['Company Admin']) && current_company.calling_only">
                                                                        <a :href="'/admin/company/' + current_company.id"
                                                                           class="text-blue-14"
                                                                           target="_blank">
                                                                            <u>Modify</u>
                                                                        </a>
                                                                    </div>
                                                                </small>
                                                            </div>
                                                            <div class="switch-label">
                                                                <el-switch active-color="#00a344"
                                                                           :disabled="current_company.calling_only"
                                                                           v-model="campaign.calling_only">
                                                                </el-switch>
                                                                <label>Enable Calling Only</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-form-item>

                                            <el-form-item prop="send_long_messages_as_mms_toggle">
                                                <span class="page-anchor"
                                                      id="send_long_messages_as_mms_toggle">
                                                </span>
                                                <div :class="{'cursor-not-allowed width-fit-content': current_company.send_long_messages_as_mms}">
                                                    <span v-if="current_company.send_long_messages_as_mms" class="badge badge-warning py-1 mb-2 ">
                                                        Set by Admin in Account Settings
                                                    </span>
                                                    <div :class="{'p-2 px-3 border border-warning': current_company.send_long_messages_as_mms}">
                                                        <el-tooltip
                                                            :disabled="!current_company.send_long_messages_as_mms"
                                                            placement="top"
                                                            effect="dark"
                                                            content="Requires Admin to change in Account Settings">
                                                            <div>
                                                                <div class="form-label">
                                                                    <h5>Send long text messages as MMS</h5>
                                                                    <small>Messages longer than 160 characters will automatically send as an MMS rather than multiple SMS segments.</small>
                                                                    <small>This setting can also be applied globally in
                                                                        <router-link :to="{ name: 'Account'}" class="text-green">Account Settings</router-link>
                                                                    </small>
                                                                </div>
                                                                <div class="switch-label">
                                                                    <el-switch active-color="#00a344"
                                                                               :disabled="disableSendLongMessages"
                                                                               v-model="sendLongMessages">
                                                                    </el-switch>
                                                                    <label>Enable Send long text messages as MMS</label>
                                                                </div>
                                                            </div>
                                                        </el-tooltip>
                                                    </div>
                                                    <div class="mbt-2 mb-3"
                                                         v-if="hasRole(['Company Admin']) && current_company.send_long_messages_as_mms">
                                                        <a href="/account?tab=settings#send_long_messages_as_mms_toggle"
                                                           class="text-blue-14"
                                                           target="_blank">
                                                            <u>Modify</u>
                                                        </a>
                                                    </div>
                                                </div>
                                            </el-form-item>

                                            <el-form-item prop="unsticky"
                                                          v-if="campaign.incoming_numbers?.length > 1">
                                                <span class="page-anchor"
                                                      id="unsticky">
                                                </span>
                                                <div>
                                                    <div class="form-label">
                                                        <h5>Unsticky Caller</h5>
                                                        <small>By default, Aloware phone numbers are sticky, ensuring
                                                            succeeding calls come from the same number for consistency and
                                                            recognition.<br>
                                                            When enabled, dynamic rotation changes the outbound number for
                                                            each call, improving connection rates and reducing flagging
                                                            risks. This feature applies only to calls.
                                                            <a href="https://support.aloware.com/en/articles/9020267-step-by-step-guide-setting-up-local-presence-with-aloware"
                                                               class="text-primary"
                                                               target="_blank">
                                                              Learn more about our Local Presence feature.
                                                            </a>
                                                        </small>
                                                    </div>
                                                    <div class="switch-label">
                                                        <el-switch
                                                            active-color="#00a344"
                                                            :disabled="campaign.incoming_numbers?.length == 1"
                                                            v-model="campaign.unsticky"
                                                        ></el-switch>
                                                        <label>Enable Unsticky Caller</label>
                                                    </div>
                                                </div>
                                            </el-form-item>

                                            <el-form-item v-if="lineHasMessagingService">
                                                <span class="page-anchor"
                                                      id="messaging_service">
                                                </span>
                                                <div>
                                                    <div class="form-label">
                                                        <h5>A2P Campaign</h5>
                                                        <small>This line is attached to the following A2P Campaign:</small>
                                                        <ul class="mt-3">
                                                            <li v-for="messaging_service in campaign.messaging_services" :key="messaging_service.sid">
                                                                <router-link :to="{ name: 'Account', query: {tab: 'compliance'}}"
                                                                             data-testid="word-analytics-router-link">
                                                                    <span class="label py-1 mx-1 blue">
                                                                        #{{ messaging_service.id }} {{ messagingServiceFormattedUseCase(messaging_service?.a2p_use_case?.use_case) }}
                                                                    </span>
                                                                </router-link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </el-form-item>

                                            <el-form-item class="no-border">
                                                <span class="page-anchor" id="phone_numbers"></span>
                                                <div class="d-flex w-full align-content-center justify-content-between">
                                                    <div class="form-label">
                                                        <h5>Phone Numbers</h5>
                                                        <small v-if="shouldShowLocalPresenceText">
                                                            These are Local Presence phone numbers that your customers call or
                                                            send messages to. Matching the outbound Caller ID with the prospect's
                                                            phone number can increase pickups and connection rates in outbound
                                                            calling campaigns. Learn more about Local Presence
                                                            <a href="https://support.aloware.com/en/articles/9020267-step-by-step-guide-setting-up-local-presence-with-aloware"
                                                               class="text-primary"
                                                               target="_blank">
                                                                here.
                                                            </a>
                                                        </small>
                                                        <small v-if="!campaign.has_local_presence">
                                                            These are the phone numbers that your customers call or send message to.
                                                        </small>
                                                    </div>

                                                    <div class="d-flex w-full-lg flex-wrap align-items-center justify-content-end">
                                                        <el-button type="primary"
                                                                   plain
                                                                   @click="dialogCallerIdVisible = true"
                                                                   :disabled="campaign.is_fax"
                                                                   class="m-2">
                                                            Add Verified Caller ID
                                                        </el-button>

                                                        <el-button type="success"
                                                                   plain
                                                                   class="m-2"
                                                                   @click="openModal"
                                                                   v-if="!loading && hasPermissionTo('create campaign') && !campaign.is_fax && !campaign.has_local_presence">
                                                            <i class="fa fa-plus mr-2"></i>
                                                            Add Number To Line
                                                        </el-button>

                                                        <el-tooltip effect="dark"
                                                                    content="To add another number, convert this line to a Standard type (currently a Fax Only type)"
                                                                    placement="top"
                                                                    v-if="campaign.is_fax">
                                                            <el-button type="success"
                                                                       plain
                                                                       class="m-2 is-disabled">
                                                                <i class="fa fa-plus mr-2"></i>
                                                                Add Number To Line
                                                            </el-button>
                                                        </el-tooltip>
                                                    </div>
                                                </div>
                                                <div>
                                                    <el-dialog top="10vh"
                                                               title="Unrent Phone Number"
                                                               :width="'500px'"
                                                               :visible.sync="dialogUnrentNumber"
                                                               append-to-body>
                                                        <div class="alert alert-danger alert-aloware">
                                                            This process is irreversible and the phone number will be lost forever.
                                                        </div>
                                                        <p v-if="lineHasMessagingService"
                                                           class="text-black mb-2">
                                                            This number will be removed from this A2P Campaign:
                                                            <br/>
                                                            <span class="font-weight-bold">#{{
                                                                    to_unrent?.messaging_service?.id
                                                                }} {{ messagingServiceFormattedUseCase(to_unrent?.messaging_service?.a2p_use_case?.use_case) }}</span>
                                                        </p>
                                                        <p class="text-black mb-1"
                                                           style="word-break: break-word">
                                                            Do you really want to unrent the phone number?
                                                            <br>
                                                            <br>
                                                            Please enter the phone number in the box below and click on unrent:
                                                        </p>
                                                        <el-input placeholder="Enter Phone Number"
                                                                  v-model="verify_unrent"
                                                                  maxlength="15"
                                                                  :keyup.prevent="verifyUnrentNumber()"></el-input>
                                                        <small class="text-muted">e.g. {{ to_unrent.phone_number | fixPhone }}</small>

                                                        <span slot="footer"
                                                              class="dialog-footer">
                                                            <button class="btn"
                                                                    @click.prevent="dialogUnrentNumber = false">
                                                                Cancel
                                                            </button>
                                                            <button class="btn danger ml-2"
                                                                    :disabled="confirm_unrent"
                                                                    @click.prevent="unrentNumber(to_unrent.id, to_unrent.phone_number)">
                                                                Unrent
                                                            </button>
                                                        </span>
                                                    </el-dialog>

                                                    <el-dialog top="10vh"
                                                               title="Delete Phone Number"
                                                               :width="'500px'"
                                                               :visible.sync="dialogDeleteNumber"
                                                               append-to-body>
                                                        <div class="alert alert-warning alert-aloware">
                                                            If in the future you want to use this number, you will have to validate it again.
                                                        </div>
                                                        <p class="text-black mb-1"
                                                           style="word-break: break-word">
                                                            Do you really want to delete the phone number?
                                                            <br>
                                                            <br>
                                                            Please enter the phone number in the box below and click on delete:
                                                        </p>
                                                        <el-input placeholder="Enter Phone Number"
                                                                  v-model="verify_delete"
                                                                  maxlength="15"
                                                                  :keyup.prevent="verifyDeleteNumber()"></el-input>
                                                        <small class="text-muted">e.g. {{ to_delete.phone_number | fixPhone }}</small>

                                                        <span slot="footer"
                                                              class="dialog-footer">
                                                            <button class="btn"
                                                                    @click.prevent="dialogDeleteNumber = false">
                                                                Cancel
                                                            </button>
                                                            <button class="btn danger ml-2"
                                                                    :disabled="confirm_delete"
                                                                    @click.prevent="deleteNumber(to_delete.id, to_delete.phone_number)">
                                                                Delete
                                                            </button>
                                                        </span>
                                                    </el-dialog>

                                                    <el-table class="w-full"
                                                              v-loading="incoming_numbers_loading"
                                                              :data="campaign.incoming_numbers"
                                                              :key="incoming_numbers_key"
                                                              fit
                                                              stripe>
                                                        <el-table-column label="Phone Number">
                                                            <template v-slot="scope">
                                                                <span class="text-greyish">{{ scope.row.phone_number | fixPhone }}</span>
                                                                <div>
                                                                    <span class="mr-2 d-inline-block label red"
                                                                          v-if="scope.row.do_not_use">
                                                                        Do Not Use
                                                                    </span>
                                                                    <span class="mr-2 d-inline-block label blue"
                                                                          v-if="scope.row.phone_number === campaign.incoming_number && campaign.incoming_numbers.length === 1">
                                                                        Primary
                                                                    </span>
                                                                    <span class="mr-2 d-inline-block label greenish"
                                                                          v-if="scope.row.default_callerid === true">
                                                                        Default Call Mask
                                                                    </span>
                                                                    <span class="mr-2 d-inline-block label purple"
                                                                          v-if="scope.row.is_fax">
                                                                        Fax
                                                                    </span>
                                                                    <span class="mr-2 d-inline-block label teal"
                                                                          v-if="scope.row.is_verified_caller_id">
                                                                        Verified Caller ID
                                                                    </span>
                                                                </div>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column label="Carrier" v-if="impersonated">
                                                            <template v-slot="scope">
                                                                <div v-if="!scope.row.is_verified_caller_id">
                                                                    <span class="d-inline-block label text-capitalize"
                                                                          :class="carrierBadgeClass(scope.row.carrier_name)">
                                                                        {{ scope.row.carrier_name }}
                                                                    </span>
                                                                </div>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column label="Type">
                                                            <template v-slot="scope">
                                                                <div v-if="!isUkNumber(scope.row.phone_number)">
                                                                    <span :class="`d-inline-block label ${number_type.class}`"
                                                                          v-for="number_type in phoneNumberTypes(scope.row)" :key="number_type.order">
                                                                        {{ number_type.label }}
                                                                    </span>
                                                                    <br>
                                                                </div>
                                                                <span v-else class="mr-2 d-inline-block label purple">
                                                                    {{ ukNumberTag(scope.row) }}
                                                                </span>
                                                                <span class="d-inline-block label black" v-if="scope.row.calling_only">
                                                                    Call Only
                                                                </span>
                                                                <span class="d-inline-block label black" v-if="isFakeNumber(scope.row)">
                                                                    Fake
                                                                </span>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column label="Location">
                                                            <template v-slot="scope">
                                                                <template v-if="!scope.row.is_tollfree">
                                                                    <span v-if="scope.row.city && scope.row.state">{{ scope.row.city }}, {{ scope.row.state }}</span>
                                                                    <span v-else-if="scope.row.city">{{ scope.row.city }}</span>
                                                                    <span v-else-if="scope.row.state">{{ scope.row.state }}</span>
                                                                    <span v-else>-</span>
                                                                </template>
                                                                <template v-else>
                                                                    <span>-</span>
                                                                </template>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column label="Compliance Met">
                                                            <template v-slot="scope">
                                                                <span v-if="scope.row.registered_stir_shaken" class="label text-xxs mx-1 green">STIR/SHAKEN</span>
                                                                <span v-if="scope.row.registered_cnam" class="label text-xxs mx-1">CNAM</span>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column label="A2P Campaign">
                                                            <template v-slot="scope">
                                                                <span v-if="scope.row.messaging_service">
                                                                    <span class="d-inline-block label text-xxs mx-1 blue">
                                                                        #{{ scope.row.messaging_service.id }} {{
                                                                            messagingServiceFormattedUseCase(scope.row.messaging_service?.a2p_use_case?.use_case)
                                                                        }}
                                                                    </span>
                                                                </span>
                                                                <span v-else>-</span>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column label="Line Capabilities">
                                                            <template v-slot="scope">
                                                                <div class="clear mt-2">
                                                                    <div class="row"
                                                                         v-if="line_capability_icon_colors[scope.row.id]">
                                                                        <div class="col">
                                                                            <el-tooltip class="item mr-2"
                                                                                        effect="dark"
                                                                                        :content="scope.row.id | incomingNumberVoiceCapabilityContent(campaign)"
                                                                                        placement="top">
                                                                                <span class="cursor-default"
                                                                                      :class="line_capability_icon_colors[scope.row.id]['voice']">
                                                                                    <i class="material-icons">call</i>
                                                                                </span>
                                                                            </el-tooltip>
                                                                            <el-tooltip class="item mr-2"
                                                                                        effect="dark"
                                                                                        :content="scope.row.id | incomingNumberFaxCapabilityContent(campaign)"
                                                                                        placement="top">
                                                                                <span class="cursor-default"
                                                                                      :class="line_capability_icon_colors[scope.row.id]['fax']">
                                                                                    <i class="fa fa-fax"></i>
                                                                                </span>
                                                                            </el-tooltip>
                                                                            <el-tooltip class="item mr-2"
                                                                                        effect="dark"
                                                                                        :content="scope.row.id | incomingNumberSMSCapabilityContent(campaign)"
                                                                                        placement="top">
                                                                                <span class="cursor-default"
                                                                                      :class="line_capability_icon_colors[scope.row.id]['sms']">
                                                                                    <i class="material-icons">forum</i>
                                                                                </span>
                                                                            </el-tooltip>
                                                                            <el-tooltip class="item"
                                                                                        effect="dark"
                                                                                        :content="scope.row.id | incomingNumberMMSCapabilityContent(campaign)"
                                                                                        placement="top">
                                                                                <span class="cursor-default"
                                                                                      :class="line_capability_icon_colors[scope.row.id]['mms']">
                                                                                    <i class="material-icons">insert_photo</i>
                                                                                </span>
                                                                            </el-tooltip>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column align="center"
                                                                         width="215"
                                                                         fixed="right">
                                                            <template v-slot:header>
                                                                <el-input
                                                                    clearable
                                                                    v-model="incoming_numbers_search"
                                                                    @input="searchIncomingNumbers"
                                                                    size="medium"
                                                                    placeholder="Type to search"/>
                                                            </template>
                                                            <template v-slot="scope">
                                                                <div>
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <button class="btn btn-sm btn-block greenish"
                                                                                    :disabled="scope.row.do_not_use === true"
                                                                                    v-if="(scope.row.default_callerid === false && usableNumbers.length > 1) || show_mask_button && scope.row.default_callerid === false"
                                                                                    @click.prevent="defaultCallerIdNumber(scope.row.id, scope.row.phone_number, true)">
                                                                                <i class="fa fa-mobile-alt pull-left"></i>
                                                                                Default Call Mask
                                                                            </button>
                                                                            <button class="btn btn-sm btn-block purple-500"
                                                                                    v-if="scope.row.default_callerid === true"
                                                                                    @click.prevent="defaultCallerIdNumber(scope.row.id, scope.row.phone_number, false)">
                                                                                <i class="fa fa-mobile-alt pull-left"></i>
                                                                                Unset Default Call Mask
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mt-2" v-if="!campaign.is_fax">
                                                                        <div class="col-12">
                                                                            <transfer-phone-number-popover
                                                                                :current_campaign="campaign"
                                                                                :campaigns="campaigns"
                                                                                :incoming_number="scope.row">
                                                                            </transfer-phone-number-popover>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mt-2">
                                                                        <div class="col-12">
                                                                            <button class="btn btn-sm btn-block deep-orange-500"
                                                                                    v-if="!scope.row.do_not_use"
                                                                                    @click.prevent="doNotUseOrActivateNumber(scope.row, true)">
                                                                                <i class="fa fa-phone-slash pull-left"></i>
                                                                                Do Not Use
                                                                            </button>
                                                                            <button class="btn btn-sm btn-block deep-orange-500"
                                                                                    v-else
                                                                                    @click.prevent="doNotUseOrActivateNumber(scope.row, false)">
                                                                                <i class="fa fa-play pull-left"></i>
                                                                                Activate
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mt-2">
                                                                        <div class="col-12">
                                                                            <button v-if="scope.row.is_verified_caller_id"
                                                                                    class="btn btn-sm btn-block danger"
                                                                                    @click.prevent="openDeleteDialog(scope.row)">
                                                                                <i class="fa fa-trash pull-left"></i>
                                                                                Delete
                                                                            </button>
                                                                            <template v-if="campaign.has_local_presence">
                                                                                <el-tooltip content="Unable to unrent because this is part of the Local Presence package"
                                                                                            placement="left-start">
                                                                                    <span>
                                                                                        <button class="btn btn-sm btn-block danger"
                                                                                                :disabled="true"
                                                                                                @click.prevent="openUnrentDialog(scope.row)">
                                                                                            <i class="fa fa-power-off pull-left"></i>
                                                                                            Unrent
                                                                                        </button>
                                                                                    </span>
                                                                                </el-tooltip>
                                                                            </template>
                                                                            <template v-else>
                                                                                <button class="btn btn-sm btn-block danger"
                                                                                        @click.prevent="openUnrentDialog(scope.row)">
                                                                                    <i class="fa fa-power-off pull-left"></i>
                                                                                    Unrent
                                                                                </button>
                                                                            </template>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </el-table-column>
                                                    </el-table>
                                                    <div class="py-2 text-center">
                                                        <el-pagination
                                                            background
                                                            layout="prev, pager, next"
                                                            :current-page.sync="incoming_numbers_pagination.current_page"
                                                            :page-count="incoming_numbers_pagination.last_page"
                                                            :page-size="parseInt(incoming_numbers_pagination.per_page)"
                                                            :total="incoming_numbers_pagination.total"
                                                            :hide-on-single-page="true"
                                                            @current-change="fetchIncomingNumbers">
                                                        </el-pagination>
                                                    </div>

                                                    <el-dialog top="10vh"
                                                               custom-class="wizard-dialog dialog-no-border"
                                                               v-if="hasPermissionTo('create campaign')"
                                                               :visible.sync="dialogVisible"
                                                               :before-close="beforeCloseModal"
                                                               append-to-body
                                                               @close="closeModal">
                                                        <line-add-number :key="prop_counter"
                                                                         :campaign="campaign"
                                                                         @add="addedIncomingNumberToCampaign">
                                                        </line-add-number>
                                                    </el-dialog>

                                                    <el-dialog top="10vh"
                                                               custom-class="wizard-dialog dialog-no-border"
                                                               :visible.sync="dialogCallerIdVisible"
                                                               :before-close="beforeCloseCallerIdModal"
                                                               append-to-body
                                                               @close="closeCallerIdModal">
                                                        <line-add-caller-id :key="prop_counter"
                                                                            :campaign="campaign"
                                                                            @close="closeCallerIdModal"
                                                                            @moved="newCallerIdAddedToCampaign">
                                                        </line-add-caller-id>
                                                    </el-dialog>
                                                </div>
                                            </el-form-item>
                                        </div>
                                        <div class="col-md-2 d-none d-md-block ml-auto">
                                            <div
                                                class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header">
                                                <h6 class="list-group-item">
                                                    On This Page
                                                </h6>
                                                <a href="#name"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('name')">
                                                    Name
                                                </a>
                                                <a href="#description"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('description')">
                                                    Description
                                                </a>
                                                <a href="#send_long_messages_as_mms_toggle"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('send_long_messages_as_mms_toggle')">
                                                    Send long text messages as MMS
                                                </a>
                                                <a href="#phone_numbers"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('phone_numbers')">
                                                    Phone Numbers
                                                </a>
                                                <a v-if="lineHasMessagingService"
                                                   href="#messaging_service"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('messaging_service')">
                                                    A2P Campaigns
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </el-tab-pane>

                                <el-tab-pane name="ivr-settings"
                                             class="p-3 line-greet-record"
                                             data-tab-error="ivr_settings"
                                             v-if="!campaign.is_fax">
                                    <span slot="label">Greet & Record<span v-html="tabLabelErrorIcon(tab_errors.ivr_settings)"></span></span>
                                    <div class="row">
                                        <div class="col-8">
                                            <div class="text-center mb-4">
                                                <i class="fas fa-phone mb-2 color-999"
                                                   style="font-size: 40px"></i>
                                                <p>Call starts</p>
                                                <i class="fas fa-arrow-down" style="font-size: 30px;color: #acacac"></i>
                                            </div>

                                            <el-form-item class="card pb-0">
                                                <span class="page-anchor" id="greet_caller"></span>
                                                <el-popover ref="popover_record_info"
                                                            placement="top-start"
                                                            width="600"
                                                            trigger="hover">
                                                    <p class="mb-0">
                                                        Record a greeting or welcome message to callers.
                                                    </p>
                                                    <div class="card-header" slot="reference">
                                                        <div class="switch-label">
                                                            <el-switch v-model="campaign.should_greet"
                                                                       active-color="#00a344">
                                                            </el-switch>
                                                            <label>Greet / Welcome Callers?</label>
                                                        </div>
                                                    </div>
                                                </el-popover>
                                                <transition name="el-fade-in-linear">
                                                    <div v-show="campaign.should_greet" class="card-body">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p>Welcome the caller to this Line using text-to-speech or pre-recorded audio.</p>
                                                                <div class="b-b nav-active-greenish">
                                                                    <ul class="nav nav-tabs">
                                                                        <li class="nav-item">
                                                                            <a class="border border-right-0 rounded-top-left"
                                                                               :class="greetingNavClass.read"
                                                                               href=""
                                                                               data-toggle="tab"
                                                                               data-target="#greet-tts"
                                                                               aria-expanded="true">
                                                                                <i class="fa fa-robot"></i>
                                                                                Text to Speech
                                                                            </a>
                                                                        </li>
                                                                        <li class="nav-item"
                                                                            v-if="hasPermissionTo('upload file campaign')">
                                                                            <a class="border rounded-top-right"
                                                                               :class="greetingNavClass.play"
                                                                               href=""
                                                                               data-toggle="tab"
                                                                               data-target="#greet-file"
                                                                               aria-expanded="false">
                                                                                <i class="fa fa-play text-md"></i>
                                                                                Play Recording
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div class="tab-content p-a m-b-md border border-top-0">
                                                                    <div :class="greetingContainerClass.read"
                                                                         id="greet-tts"
                                                                         aria-expanded="true">
                                                                        <el-form-item label="Greeting Message"
                                                                                      class="no-border mb-4"
                                                                                      prop="should_greet">
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <div class="input-group">
                                                                                        <input type="text"
                                                                                               class="form-control pt-2 pb-2"
                                                                                               v-model="campaign.greeting_tts"
                                                                                               @input="preValidateForm('campaign_settings')"/>
                                                                                        <variable-dialog></variable-dialog>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </el-form-item>
                                                                    </div>
                                                                    <div :class="greetingContainerClass.play"
                                                                         id="greet-file"
                                                                         aria-expanded="false"
                                                                         v-if="hasPermissionTo('upload file campaign')">
                                                                        <audio-recorder
                                                                            v-if="campaign.greeting_file === null"
                                                                            class="audio-recorder-wrapper"
                                                                            :action="'/api/v1/campaign/' + campaign.id + '/upload-ivr/greetings'"
                                                                            :upload_type="'greeting_file'"
                                                                            @applyUploadedAudio="applyUploadedAudio">
                                                                        </audio-recorder>
                                                                        <div class="audio-uploader-wrapper">
                                                                            <el-upload ref="greetingAudio"
                                                                                       v-if="campaign.greeting_file === null"
                                                                                       drag
                                                                                       :action="'/api/v1/campaign/' + campaign.id + '/upload-ivr/greetings'"
                                                                                       :headers="headers"
                                                                                       :on-success="onSuccessGreeting"
                                                                                       :on-error="onFailedGreeting"
                                                                                       :on-progress="progressUploadGreeting"
                                                                                       :before-upload="beforeUploadGreeting"
                                                                                       :file-list="uploadFileList.greeting">
                                                                                <i class="el-icon-upload"></i>
                                                                                <div class="el-upload__text">
                                                                                    Drop file here or <em>click to
                                                                                    upload</em>
                                                                                </div>
                                                                                <div class="el-upload__tip"
                                                                                     slot="tip">
                                                                                    MP3/WAV file (less than 8MB)
                                                                                </div>
                                                                            </el-upload>
                                                                            <el-progress :text-inside="true"
                                                                                         :stroke-width="18"
                                                                                         :percentage="uploadPercentage.greeting"
                                                                                         :status="uploadStatus.greeting"
                                                                                         v-if="campaign.greeting_file === null">
                                                                            </el-progress>
                                                                            <audio controls
                                                                                   v-if="campaign.greeting_file !== null">
                                                                                <source :src="'/static/uploaded_file/' + campaign.greeting_file">
                                                                                Your browser does not support the audio element.
                                                                            </audio>
                                                                            <button class="btn btn-sm btn-danger pull-right"
                                                                                    v-if="campaign.greeting_file !== null && hasPermissionTo('delete file campaign')"
                                                                                    @click.prevent="deleteFile('greetings')">
                                                                                <i class="material-icons loader pull-left"
                                                                                   v-show="loading_greeting">&#xE863;</i>
                                                                                <i class="material-icons pull-left"
                                                                                   v-show="!loading_greeting">&#xE872;</i>
                                                                                <span>Remove file</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>
                                            </el-form-item>

                                            <div class="text-center my-4">
                                                <i class="fas fa-arrow-down" style="font-size: 30px;color: #acacac"></i>
                                            </div>

                                            <el-form-item class="card pb-0">
                                                <span class="page-anchor" id="call_recording"></span>
                                                <el-popover ref="popover_record_info"
                                                            placement="top-start"
                                                            width="600"
                                                            trigger="hover">
                                                    <p class="mb-0">
                                                        This will enable call recordings on this line,
                                                        you can modify the inbound/outbound recording behavior globally in account settings or
                                                        you can customize it on the user level by changing the user settings.
                                                    </p>
                                                    <div class="card-header" slot="reference">
                                                        <div class="switch-label">
                                                            <el-switch v-model="campaign.should_record"
                                                                       active-color="#00a344">
                                                            </el-switch>
                                                            <label>Should Record Calls?</label>
                                                        </div>
                                                    </div>
                                                </el-popover>

                                                <transition name="el-fade-in-linear">
                                                    <div class="card-body" v-show="campaign.should_record">
                                                        <div class="alert alert-warning alert-aloware p-2 d-inline-block">
                                                            <i class="fa fa-info-circle"></i>
                                                            In the US, you must let the caller know
                                                            that their conversation is being recorded.
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="b-b nav-active-greenish">
                                                                    <ul class="nav nav-tabs">
                                                                        <li class="nav-item">
                                                                            <a class="border border-right-0 rounded-top-left"
                                                                               :class="recordNavClass.read"
                                                                               href=""
                                                                               data-toggle="tab"
                                                                               data-target="#record-tts"
                                                                               aria-expanded="true">
                                                                                <i class="fa fa-robot"></i>
                                                                                Text to Speech
                                                                            </a>
                                                                        </li>
                                                                        <li class="nav-item"
                                                                            v-if="hasPermissionTo('upload file campaign')">
                                                                            <a class="border rounded-top-right"
                                                                               :class="recordNavClass.play"
                                                                               href=""
                                                                               data-toggle="tab"
                                                                               data-target="#record-file"
                                                                               aria-expanded="false">
                                                                                <i class="fa fa-play text-md"></i>
                                                                                Play Recording
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div class="tab-content p-a m-b-md border border-top-0">
                                                                    <div :class="recordContainerClass.read"
                                                                         id="record-tts"
                                                                         aria-expanded="true">
                                                                        <el-form-item label="Recording Message"
                                                                                      class="no-border mb-4"
                                                                                      prop="should_record">
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <div class="input-group">
                                                                                        <input type="text"
                                                                                               class="form-control pt-2 pb-2"
                                                                                               v-model="campaign.record_tts"
                                                                                               @input="preValidateForm('campaign_settings')"/>
                                                                                        <variable-dialog></variable-dialog>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </el-form-item>
                                                                    </div>
                                                                    <div :class="recordContainerClass.play"
                                                                         id="record-file"
                                                                         aria-expanded="false"
                                                                         v-if="hasPermissionTo('upload file campaign')">
                                                                        <audio-recorder
                                                                            v-if="campaign.record_file === null"
                                                                            class="audio-recorder-wrapper"
                                                                            :action="'/api/v1/campaign/' + campaign.id + '/upload-ivr/recording_notices'"
                                                                            :upload_type="'record_file'"
                                                                            @applyUploadedAudio="applyUploadedAudio">
                                                                        </audio-recorder>
                                                                        <div class="audio-uploader-wrapper">
                                                                            <el-upload
                                                                                v-if="campaign.record_file === null"
                                                                                drag
                                                                                :action="'/api/v1/campaign/' + campaign.id + '/upload-ivr/recording_notices'"
                                                                                :headers="headers"
                                                                                :on-success="onSuccessRecord"
                                                                                :on-error="onFailedRecord"
                                                                                :on-progress="progressUploadRecord"
                                                                                :before-upload="beforeUploadRecord"
                                                                                :file-list="uploadFileList.record">
                                                                                <i class="el-icon-upload"></i>
                                                                                <div class="el-upload__text">
                                                                                    Drop file here or <em>click to
                                                                                    upload</em>
                                                                                </div>
                                                                                <div class="el-upload__tip"
                                                                                     slot="tip">
                                                                                    MP3/WAV file (less than 8MB)
                                                                                </div>
                                                                            </el-upload>
                                                                            <el-progress :text-inside="true"
                                                                                         :stroke-width="18"
                                                                                         :percentage="uploadPercentage.record"
                                                                                         :status="uploadStatus.record"
                                                                                         v-if="campaign.record_file === null">
                                                                            </el-progress>
                                                                            <audio v-if="campaign.record_file !== null"
                                                                                   controls>
                                                                                <source :src="'/static/uploaded_file/' + campaign.record_file">
                                                                                Your browser does not support the audio element.
                                                                            </audio>
                                                                            <button class="btn btn-sm btn-danger pull-right"
                                                                                    v-if="campaign.record_file !== null && hasPermissionTo('delete file campaign')"
                                                                                    @click.prevent="deleteFile('recording_notices')">
                                                                                <i class="material-icons loader pull-left"
                                                                                   v-show="loading_recording">&#xE863;</i>
                                                                                <i class="material-icons pull-left"
                                                                                   v-show="!loading_recording">&#xE872;</i>
                                                                                <span>Remove file</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <el-form-item
                                                            class="pl-3 border-left"
                                                            prop="call_recording_retention">
                                                            <span class="page-anchor"
                                                                  id="call_recording_retention">
                                                            </span>
                                                            <div class="form-label">
                                                                <h5>Call records retention duration</h5>
                                                            </div>
                                                            <retention-duration-feature-overview/>
                                                            <el-tooltip
                                                                placement="top"
                                                                effect="dark"
                                                                :disabled="hasAccessToEditRetentionDuration">
                                                                <template v-slot:content>
                                                                    <div v-html="getRetentionDurationTooltipText"></div>
                                                                </template>
                                                                <el-select
                                                                    class="width-300"
                                                                    :disabled="!hasAccessToEditRetentionDuration"
                                                                    v-model="campaign.call_recording_retention">
                                                                    <el-option
                                                                        :value="CallRecordsRetentionDurations.CALL_RECORDS_RETENTION_DURATIONS_INDEFINITE"
                                                                        label="Indefinite">
                                                                    </el-option>
                                                                    <el-option
                                                                        :value="CallRecordsRetentionDurations.CALL_RECORDS_RETENTION_DURATIONS_10_DAYS"
                                                                        label="10 days">
                                                                    </el-option>
                                                                    <el-option
                                                                        :value="CallRecordsRetentionDurations.CALL_RECORDS_RETENTION_DURATIONS_30_DAYS"
                                                                        label="30 days">
                                                                    </el-option>
                                                                    <el-option
                                                                        :value="CallRecordsRetentionDurations.CALL_RECORDS_RETENTION_DURATIONS_60_DAYS"
                                                                        label="60 days">
                                                                    </el-option>
                                                                </el-select>
                                                            </el-tooltip>
                                                        </el-form-item>
                                                    </div>
                                                </transition>
                                            </el-form-item>
                                            <div class="alert alert-danger alert-aloware p-2 d-block"
                                                 v-if="recordingSetInAccountSettings">
                                                <i class="fa fa-warning"></i>
                                                This setting is being forced at the account level, please go to Account > Calling Settings to change it.
                                            </div>

                                            <div class="text-center my-4">
                                                <i class="fas fa-arrow-down" style="font-size: 30px;color: #acacac"></i>
                                            </div>

                                            <el-form-item class="card pb-0">
                                                <span class="page-anchor" id="check_operating_hours"></span>
                                                <el-popover ref="popover_record_info"
                                                            placement="top-start"
                                                            width="600"
                                                            trigger="hover">
                                                    <p class="mb-0">
                                                        Set what this line should do on calls outside your business' operating hours.
                                                    </p>
                                                    <div class="card-header" slot="reference">
                                                        <div class="switch-label">
                                                            <el-switch v-model="show_operating_hours"
                                                                       active-color="#00a344">
                                                            </el-switch>
                                                            <label>Check Operating Hours?</label>
                                                        </div>
                                                    </div>
                                                </el-popover>
                                                <transition name="el-fade-in-linear">
                                                    <div class="card-body"
                                                         v-show="show_operating_hours">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="form-label">
                                                                    <label class="lh-1">
                                                                        Here you can define the open/close hours of your business.
                                                                        If a call comes in during closed hours, it will follow your 'missed call' logic defined on the next page.
                                                                    </label>
                                                                </div>
                                                                <div class="alert alert-success alert-aloware p-2 d-inline-block">
                                                                    <i class="fa fa-info-circle"></i>
                                                                    The times below are local to your account's timezone, currently set to {{ current_company.timezone }}
                                                                </div>
                                                                <div class="form-group">
                                                                    <business-hours :days="campaign.operating_hours"
                                                                                    :time-increment="timeIncrement"
                                                                                    v-if="!loading_operating_hours && campaign.operating_hours"
                                                                                    type="select"
                                                                                    color="#00BF50">
                                                                    </business-hours>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row mt-3">
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <el-checkbox
                                                                        v-model="campaign.closed_hours_voice_prompt.enabled"
                                                                        @change="preValidateForm('campaign_settings')">
                                                                        Closed Hours Voice Prompt
                                                                    </el-checkbox>
                                                                    <label class="help-text">
                                                                        This message will be played when a call
                                                                        comes in during business closure hours.
                                                                    </label>
                                                                </div>
                                                                <div v-if="campaign.closed_hours_voice_prompt.enabled">
                                                                    <div class="b-b nav-active-greenish">
                                                                        <ul class="nav nav-tabs">
                                                                            <li class="nav-item">
                                                                                <a class="border border-right-0 rounded-top-left"
                                                                                   :class="closedHoursMissedCallVoicemailNavClass.read"
                                                                                   href=""
                                                                                   data-toggle="tab"
                                                                                   data-target="#closed-hours-voice-prompt-tts"
                                                                                   aria-expanded="true">
                                                                                    <i class="fa fa-robot"></i>
                                                                                    Text to Speech
                                                                                </a>
                                                                            </li>
                                                                            <li class="nav-item"
                                                                                v-if="hasPermissionTo('upload file campaign')">
                                                                                <a class="border rounded-top-right"
                                                                                   :class="closedHoursMissedCallVoicemailNavClass.play"
                                                                                   href=""
                                                                                   data-toggle="tab"
                                                                                   data-target="#closed-hours-voice-prompt-file"
                                                                                   aria-expanded="false">
                                                                                    <i class="fa fa-play text-md"></i>
                                                                                    Play Recording
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>

                                                                    <div class="tab-content p-a m-b-md border border-top-0">
                                                                        <div :class="closedHoursMissedCallVoicemailContainerClass.read"
                                                                             id="closed-hours-voice-prompt-tts"
                                                                             aria-expanded="true">
                                                                            <el-form-item label="Voice Prompt"
                                                                                          class="no-border mb-4"
                                                                                          prop="closed_hours_voice_prompt">
                                                                                <div class="row">
                                                                                    <div class="col-12">
                                                                                        <div class="input-group">
                                                                                            <input type="text"
                                                                                                   class="form-control pt-2 pb-2"
                                                                                                   v-model="campaign.closed_hours_voice_prompt.tts"
                                                                                                   @input="preValidateForm('campaign_settings')"/>
                                                                                            <variable-dialog></variable-dialog>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </el-form-item>
                                                                        </div>
                                                                        <div :class="closedHoursMissedCallVoicemailContainerClass.play"
                                                                             id="closed-hours-voice-prompt-file"
                                                                             aria-expanded="false"
                                                                             v-if="hasPermissionTo('upload file campaign')">
                                                                            <audio-recorder
                                                                                v-if="campaign.closed_hours_voice_prompt.file === null"
                                                                                class="audio-recorder-wrapper"
                                                                                :action="'/api/v1/campaign/' + campaign.id + '/upload-ivr/closed_hours_voice_prompts'"
                                                                                :upload_type="'closed_hours_voice_prompt_file'"
                                                                                @applyUploadedAudio="applyUploadedAudio">
                                                                            </audio-recorder>
                                                                            <div class="audio-uploader-wrapper">
                                                                                <el-upload
                                                                                    v-if="campaign.closed_hours_voice_prompt.file === null"
                                                                                    drag
                                                                                    :action="'/api/v1/campaign/' + campaign.id + '/upload-ivr/closed_hours_voice_prompts'"
                                                                                    :headers="headers"
                                                                                    :on-success="onSuccessClosedHoursMissedCallVoicemail"
                                                                                    :on-error="onFailedClosedHoursMissedCallVoicemail"
                                                                                    :on-progress="progressUploadClosedHoursMissedCallVoicemail"
                                                                                    :before-upload="beforeUploadClosedHoursMissedCallVoicemail"
                                                                                    :file-list="uploadFileList.closed_hours_voice_prompt">
                                                                                    <i class="el-icon-upload"></i>
                                                                                    <div class="el-upload__text">
                                                                                        Drop file here or <em>click
                                                                                        to
                                                                                        upload</em>
                                                                                    </div>
                                                                                    <div class="el-upload__tip"
                                                                                         slot="tip">
                                                                                        MP3/WAV file (less than 8MB)
                                                                                    </div>
                                                                                </el-upload>
                                                                                <el-progress :text-inside="true"
                                                                                             :stroke-width="18"
                                                                                             :percentage="uploadPercentage.closed_hours_voice_prompt"
                                                                                             :status="uploadStatus.closed_hours_voice_prompt"
                                                                                             v-if="campaign.closed_hours_voice_prompt.file === null">
                                                                                </el-progress>
                                                                                <audio controls
                                                                                       v-if="campaign.closed_hours_voice_prompt.file !== null">
                                                                                    <source :src="'/static/uploaded_file/' + campaign.closed_hours_voice_prompt.file">
                                                                                    Your browser does not support the audio element.
                                                                                </audio>
                                                                                <button class="btn btn-sm btn-danger pull-right"
                                                                                        v-if="campaign.closed_hours_voice_prompt.file !== null && hasPermissionTo('delete file campaign')"
                                                                                        @click.prevent="deleteFile('closed_hours_voice_prompts')">
                                                                                    <i class="material-icons loader pull-left"
                                                                                       v-show="loading_closed_hours_voice_prompt">&#xE863;</i>
                                                                                    <i class="material-icons pull-left"
                                                                                       v-show="!loading_closed_hours_voice_prompt">&#xE872;</i>
                                                                                    <span>Remove file</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row mt-3">
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <el-checkbox
                                                                        v-model="campaign.closed_hours_auto_reply_text.enabled"
                                                                        @change="preValidateForm('campaign_settings')">
                                                                        Closed Hours Auto Reply Text
                                                                    </el-checkbox>
                                                                    <label class="help-text">
                                                                        Auto reply all incoming messages with a
                                                                        canned response during business closure
                                                                        hours.
                                                                    </label>
                                                                </div>
                                                                <div v-if="campaign.closed_hours_auto_reply_text.enabled">
                                                                    <el-form-item label="Auto Reply Text"
                                                                                  class="no-border"
                                                                                  prop="closed_hours_auto_reply_text">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="input-group">
                                                                                            <textarea type="text"
                                                                                                      class="form-control pt-2 pb-2"
                                                                                                      rows="2"
                                                                                                      v-model="campaign.closed_hours_auto_reply_text.message"
                                                                                                      @input="preValidateForm('campaign_settings')">
                                                                                            </textarea>
                                                                                    <variable-dialog></variable-dialog>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </el-form-item>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>
                                            </el-form-item>

                                            <div class="text-center my-4">
                                                <i class="fas fa-arrow-down" style="font-size: 30px;color: #acacac"></i>
                                            </div>

                                            <el-form-item class="card pb-0">
                                                <span class="page-anchor" id="tcpa"></span>
                                                <el-popover ref="popover_record_info"
                                                            placement="top-start"
                                                            width="600"
                                                            trigger="hover">
                                                    <p class="mb-0">
                                                        Prompt the caller to press one and explicitly authorize receiving a text message from you.
                                                        Although not mandatory, this feature satisfies most TCPA compliance requirements.
                                                        Prompt the caller to press ONE.
                                                    </p>
                                                    <div class="card-header"
                                                         slot="reference">
                                                        <div class="switch-label">
                                                            <el-switch v-model="campaign.should_ask_for_text_authorization"
                                                                       active-color="#00a344">
                                                            </el-switch>
                                                            <label>TCPA: Ask For Text Authorization?</label>
                                                        </div>
                                                    </div>
                                                </el-popover>
                                                <transition name="el-fade-in-linear">
                                                    <div class="card-body"
                                                         v-show="campaign.should_ask_for_text_authorization">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="b-b nav-active-greenish">
                                                                    <ul class="nav nav-tabs">
                                                                        <li class="nav-item">
                                                                            <a class="border border-right-0 rounded-top-left"
                                                                               :class="textAuthorizationNavClass.read"
                                                                               href=""
                                                                               data-toggle="tab"
                                                                               data-target="#ask-for-text-auth-tts"
                                                                               aria-expanded="true">
                                                                                <i class="fa fa-robot"></i>
                                                                                Text to Speech
                                                                            </a>
                                                                        </li>
                                                                        <li class="nav-item"
                                                                            v-if="hasPermissionTo(['update ring group', 'create ring group', 'view ring group'])">
                                                                            <a class="border rounded-top-right"
                                                                               :class="textAuthorizationNavClass.play"
                                                                               href=""
                                                                               data-toggle="tab"
                                                                               data-target="#ask-for-text-auth-file"
                                                                               aria-expanded="false">
                                                                                <i class="fa fa-play text-md"></i>
                                                                                Play Recording
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div class="tab-content p-a m-b-md border border-top-0">
                                                                    <div :class="textAuthorizationContainerClass.read"
                                                                         id="ask-for-text-auth-tts"
                                                                         aria-expanded="true">
                                                                        <el-form-item label="Prompt"
                                                                                      class="no-border mb-4"
                                                                                      prop="should_ask_for_text_authorization">
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <div class="input-group">
                                                                                        <input type="text"
                                                                                               class="form-control pt-2 pb-2"
                                                                                               v-model="campaign.ask_for_text_authorization_tts"
                                                                                               @input="preValidateForm('campaign_settings')"/>
                                                                                        <variable-dialog></variable-dialog>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </el-form-item>
                                                                    </div>
                                                                    <div :class="textAuthorizationContainerClass.play"
                                                                         id="ask-for-text-auth-file"
                                                                         aria-expanded="false"
                                                                         v-if="hasPermissionTo(['update ring group', 'create ring group', 'view ring group'])">
                                                                        <audio-recorder
                                                                            v-if="campaign.ask_for_text_authorization_file === null"
                                                                            class="audio-recorder-wrapper"
                                                                            :action="'/api/v1/campaign/' + campaign.id + '/upload-ivr/text_authorizations'"
                                                                            :upload_type="'ask_for_text_authorization_file'"
                                                                            @applyUploadedAudio="applyUploadedAudio">
                                                                        </audio-recorder>
                                                                        <div class="audio-uploader-wrapper">
                                                                            <el-upload
                                                                                v-if="campaign.ask_for_text_authorization_file === null"
                                                                                drag
                                                                                :action="'/api/v1/campaign/' + campaign.id + '/upload-ivr/text_authorizations'"
                                                                                :headers="headers"
                                                                                :on-success="onSuccessTextAuthorization"
                                                                                :on-error="onFailedTextAuthorization"
                                                                                :on-progress="progressUploadTextAuthorization"
                                                                                :before-upload="beforeUploadTextAuthorization"
                                                                                :file-list="uploadFileList.askForTextAuthorization">
                                                                                <i class="el-icon-upload"></i>
                                                                                <div class="el-upload__text">
                                                                                    Drop file here or <em>click to
                                                                                    upload</em>
                                                                                </div>
                                                                                <div class="el-upload__tip"
                                                                                     slot="tip">
                                                                                    MP3/WAV file (less than 8MB)
                                                                                </div>
                                                                            </el-upload>
                                                                            <el-progress :text-inside="true"
                                                                                         :stroke-width="18"
                                                                                         :percentage="uploadPercentage.askForTextAuthorization"
                                                                                         :status="uploadStatus.askForTextAuthorization"
                                                                                         v-if="campaign.ask_for_text_authorization_file === null">
                                                                            </el-progress>
                                                                            <audio v-if="campaign.ask_for_text_authorization_file !== null"
                                                                                   controls>
                                                                                <source :src="'/static/uploaded_file/' + campaign.ask_for_text_authorization_file">
                                                                                Your browser does not support the audio element.
                                                                            </audio>
                                                                            <button class="btn btn-sm btn-danger pull-right"
                                                                                    v-if="campaign.ask_for_text_authorization_file !== null && hasPermissionTo('delete ring group')"
                                                                                    @click.prevent="deleteFile('text_authorizations')">
                                                                                <i class="material-icons loader pull-left"
                                                                                   v-show="loading_ask_for_text_authorization">&#xE863;</i>
                                                                                <i class="material-icons pull-left"
                                                                                   v-show="!loading_ask_for_text_authorization">&#xE872;</i>
                                                                                <span>Remove file</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>
                                            </el-form-item>

                                            <div class="text-center my-4">
                                                <i class="fas fa-arrow-down" style="font-size: 30px;color: #acacac"></i>
                                            </div>

                                            <div class="text-center form-label">
                                                <i class="fas fa-network-wired" style="font-size: 40px;color: #acacac"></i>
                                                <h5 class="mt-2">
                                                    <i class="fa fa-check-circle-o text-success"></i>
                                                    Ready to route
                                                </h5>
                                                <small class="">
                                                    Proceed to
                                                    <a class="text-success" @click="loadRoutingIvr">
                                                        Routing and IVR
                                                    </a>
                                                    settings
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-2 d-none d-md-block ml-auto">
                                            <div
                                                class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header">
                                                <h6 class="list-group-item">
                                                    On This Page
                                                </h6>
                                                <a href="#greet_caller"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('greet_caller')">
                                                    Greet/Welcome Callers
                                                </a>
                                                <a href="#call_recording"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('call_recording')">
                                                    Call Recording
                                                </a>
                                                <a href="#check_operating_hours"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('check_operating_hours')">
                                                    Check Operating Hours
                                                </a>
                                                <a href="#tcpa"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('tcpa')">
                                                    TCPA: Text Authorization
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </el-tab-pane>

                                <el-tab-pane name="routing-settings"
                                             class="p-3"
                                             data-tab-error="routing_settings"
                                             v-if="!campaign.is_fax">
                                    <span slot="label">Routing & IVR<span v-html="tabLabelErrorIcon(tab_errors.routing_settings)"></span></span>
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="row">
                                                <div class="col-6">
                                                    <el-form-item>
                                                        <span class="page-anchor" id="answer"></span>
                                                        <el-timeline class="ivr-timeline">
                                                            <el-timeline-item placement="top"
                                                                              size="large">
                                                                <template slot="dot">
                                                                    <div class="timeline-dot">
                                                                        <div class="el-timeline-item__node el-timeline-item__node--xlarge"></div>
                                                                    </div>
                                                                    <label class="el-form-item__label ml-2">
                                                                        Who should answer incoming calls?
                                                                    </label>
                                                                </template>
                                                            </el-timeline-item>

                                                            <!-- A Ring Group -->
                                                            <el-timeline-item placement="top"
                                                                              type="primary"
                                                                              size="large">
                                                                <template slot="dot">
                                                                    <el-switch v-model="to_ring_group"
                                                                               active-color="#00a344"
                                                                               :disabled="to_ring_group">
                                                                    </el-switch>
                                                                    <label class="el-form-item__label ml-2">
                                                                        A Ring Group
                                                                    </label>
                                                                </template>
                                                                <label class="ml-1 help-text">
                                                                    Send the call to a group of agents. Queues are defined
                                                                    per ring group.
                                                                    <el-popover ref="popover_ring_group"
                                                                                placement="right"
                                                                                class="ml-2"
                                                                                title="Ring Group"
                                                                                width="400"
                                                                                trigger="hover"
                                                                                content="A ring group is an ordered list of agents
                                                                                that receive and process calls. This feature
                                                                                is often used to efficiently distribute
                                                                                calls to specific departments such as
                                                                                Sales, Customer Support or others.
                                                                                You can set up smart queues and callback
                                                                                options within your ring groups.">
                                                                    </el-popover>
                                                                    <span class="form-hover-help text-blue"
                                                                          v-popover:popover_ring_group>
                                                                            More Info
                                                                    </span>
                                                                    <br>
                                                                </label>
                                                                <el-card v-show="to_ring_group">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <el-form-item prop="ring_group_id"
                                                                                          class="no-border"
                                                                                          label="Select Ring Group:">
                                                                                <ring-group-selector
                                                                                    v-model="campaign.ring_group_id"
                                                                                    style="width: 300px"
                                                                                    class="pull-left"
                                                                                    @change="changeCallRingGroup">
                                                                                </ring-group-selector>
                                                                            </el-form-item>
                                                                        </div>
                                                                    </div>
                                                                </el-card>
                                                            </el-timeline-item>

                                                            <!-- A User -->
                                                            <el-timeline-item placement="top"
                                                                              type="primary"
                                                                              size="large">
                                                                <template slot="dot">
                                                                    <el-switch v-model="to_user"
                                                                               active-color="#00a344"
                                                                               :disabled="to_user">
                                                                    </el-switch>
                                                                    <label class="el-form-item__label ml-2">A User</label>
                                                                </template>
                                                                <label class="ml-1 help-text">
                                                                    Send the call (and assign texts) to this user.
                                                                </label>
                                                                <el-card v-show="to_user">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <el-form-item prop="user_id"
                                                                                          label="Select User:">
                                                                                <user-selector v-model="campaign.user_id"
                                                                                               style="width: 300px"
                                                                                               class="pull-left"
                                                                                               @change="changeUser">
                                                                                </user-selector>
                                                                                <div class="mt-1">
                                                                                    <el-popover ref="popover_call_waiting"
                                                                                                placement="top"
                                                                                                title="Call Waiting"
                                                                                                width="200"
                                                                                                trigger="hover"
                                                                                                content="Place new calls on hold while on a call">
                                                                                    </el-popover>
                                                                                    <el-switch class="ml-2" v-model="has_call_waiting"
                                                                                               active-color="#00a344">
                                                                                    </el-switch>
                                                                                    <span v-popover:popover_call_waiting>Call Waiting</span>
                                                                                </div>
                                                                            </el-form-item>
                                                                        </div>
                                                                    </div>
                                                                </el-card>
                                                            </el-timeline-item>

                                                            <!-- An IVR -->
                                                            <el-timeline-item placement="top"
                                                                              type="primary"
                                                                              size="large">
                                                                <template slot="dot">
                                                                    <el-switch v-model="to_ivr"
                                                                               active-color="#00a344"
                                                                               :disabled="to_ivr">
                                                                    </el-switch>
                                                                    <label class="el-form-item__label ml-2">
                                                                        An IVR
                                                                    </label>
                                                                </template>
                                                                <label class="ml-1 help-text">
                                                                    An IVR, or phone tree, routes your calls based on caller's choice.
                                                                    <el-popover ref="popover_ivr"
                                                                                placement="right"
                                                                                class="ml-2"
                                                                                title="IVR"
                                                                                width="400"
                                                                                trigger="hover"
                                                                                content="Interactive Voice Response (IVR) is a telephony
                                                                                menu system. Using an IVR
                                                                                allows your customers to navigate your
                                                                                phone tree and get connected to the
                                                                                extension (user) or department (ring group)
                                                                                that they want.">
                                                                    </el-popover>
                                                                    <span class="form-hover-help text-blue"
                                                                          v-popover:popover_ivr>
                                                                            More Info
                                                                    </span>
                                                                </label>
                                                                <transition name="el-fade-in-linear">
                                                                    <el-card v-show="to_ivr">
                                                                        <ivr-selector v-model="campaign.ivr_id"
                                                                                      style="width: 300px"
                                                                                      class="pull-left"
                                                                                      @change="changeIvr"/>
                                                                        <el-button class="ml-2"
                                                                                   type="primary"
                                                                                   :disabled="!selected_ivr_id"
                                                                                   @click="showIvrModal(false)">
                                                                            Edit
                                                                        </el-button>
                                                                        <el-button type="success"
                                                                                   @click="showIvrModal">
                                                                            Create
                                                                        </el-button>
                                                                    </el-card>
                                                                </transition>
                                                            </el-timeline-item>

                                                            <el-dialog :top="'5vh'"
                                                                       :width="'70%'"
                                                                       :visible="show_ivr_modal"
                                                                       :append-to-body="true"
                                                                       :before-close="beforeCloseIvrModal">
                                                                <el-card>
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <el-form-item class="text-md _600"
                                                                                          label="IVR name">
                                                                                <el-input v-model="ivr.name"
                                                                                          placeholder="Name of IVR">
                                                                                </el-input>
                                                                            </el-form-item>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <div class="b-b nav-active-greenish">
                                                                                <ul class="nav nav-tabs">
                                                                                    <li class="nav-item">
                                                                                        <a class="border border-right-0 rounded-top-left"
                                                                                           :class="ivrPromptNavClass.read"
                                                                                           href=""
                                                                                           data-toggle="tab"
                                                                                           data-target="#ivr-prompt-tts"
                                                                                           aria-expanded="true">
                                                                                            <i class="fa fa-robot"></i>
                                                                                            Text to Speech
                                                                                        </a>
                                                                                    </li>
                                                                                    <li class="nav-item"
                                                                                        v-if="hasPermissionTo('upload file campaign')">
                                                                                        <a class="border rounded-top-right"
                                                                                           :class="ivrPromptNavClass.play"
                                                                                           href=""
                                                                                           data-toggle="tab"
                                                                                           data-target="#ivr-prompt-file"
                                                                                           aria-expanded="false">
                                                                                            <i class="fa fa-play text-md"></i>
                                                                                            Play Recording
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>

                                                                            <div class="tab-content p-a m-b-md border border-top-0">
                                                                                <div :class="ivrPromptContainerClass.read"
                                                                                     id="ivr-prompt-tts"
                                                                                     aria-expanded="true">
                                                                                    <el-form-item prop="ivr_prompt_tts"
                                                                                                  class="mb-4">
                                                                                        <div>
                                                                                            <label class="el-form-item__label">
                                                                                                IVR Prompt
                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="row">
                                                                                            <div class="col-12">
                                                                                                <div class="input-group">
                                                                                                    <input type="text"
                                                                                                           class="form-control pt-2 pb-2"
                                                                                                           placeholder="Press 1 for Sales, Press 2 for Support, ..."
                                                                                                           v-model="ivr.prompt_tts"
                                                                                                           @input="preValidateForm('campaign_settings')"/>
                                                                                                    <variable-dialog></variable-dialog>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </el-form-item>
                                                                                </div>
                                                                                <div :class="ivrPromptContainerClass.play"
                                                                                     id="ivr-prompt-file"
                                                                                     aria-expanded="false"
                                                                                     v-if="hasPermissionTo('upload file campaign')">
                                                                                    <audio-recorder
                                                                                        class="audio-recorder-wrapper"
                                                                                        :action="'/api/v1/campaign/' + campaign.id + '/upload-ivr/ivr_prompts'"
                                                                                        :upload_type="'ivr_prompt_file'"
                                                                                        @applyUploadedAudio="applyUploadedAudio">
                                                                                    </audio-recorder>
                                                                                    <div class="audio-uploader-wrapper">
                                                                                        <el-upload drag
                                                                                                   :action="'/api/v1/campaign/' + campaign.id + '/upload-ivr/ivr_prompts'"
                                                                                                   :headers="headers"
                                                                                                   :on-success="onSuccessIvrPrompt"
                                                                                                   :on-error="onFailedIvrPrompt"
                                                                                                   :on-progress="progressUploadIvrPrompt"
                                                                                                   :before-upload="beforeUploadIvrPrompt"
                                                                                                   :file-list="uploadFileList.ivr_prompt">
                                                                                            <i class="el-icon-upload"></i>
                                                                                            <div class="el-upload__text">
                                                                                                Drop file here or <em>click to
                                                                                                upload</em>
                                                                                            </div>
                                                                                            <div class="el-upload__tip"
                                                                                                 slot="tip">
                                                                                                MP3/WAV file (less than 8MB)
                                                                                            </div>
                                                                                        </el-upload>
                                                                                        <el-progress :text-inside="true"
                                                                                                     :stroke-width="18"
                                                                                                     :percentage="uploadPercentage.ivr_prompt"
                                                                                                     :status="uploadStatus.ivr_prompt"
                                                                                                     v-if="uploadPercentage.ivr_prompt > 0">
                                                                                        </el-progress>
                                                                                    </div>
                                                                                    <div class="mt-2" v-if="ivrPromptFile !== null">
                                                                                        <span class="text-md _600">Current file:</span>
                                                                                        <el-form :model="ivrPromptFile"
                                                                                                 inline>
                                                                                            <el-form-item>
                                                                                                <audio controls>
                                                                                                    <source :src="'/static/uploaded_file/' + ivrPromptFile.uuid">
                                                                                                    Your browser does not support the audio element.
                                                                                                </audio>
                                                                                            </el-form-item>
                                                                                            <el-form-item>
                                                                                                <el-input v-model="ivrPromptFile.name"
                                                                                                          :disabled="ivr_prompt_file_rename === ivrPromptFile.id"
                                                                                                          placeholder="Unnamed File"
                                                                                                          size="small"
                                                                                                          style="width: 500px">
                                                                                                    <template slot="append">
                                                                                                        <el-button type="primary"
                                                                                                                   :disabled="ivr_prompt_file_rename === ivrPromptFile.id || !ivrPromptFile.name"
                                                                                                                   :loading="ivr_prompt_file_rename === ivrPromptFile.id"
                                                                                                                   icon="el-icon-check"
                                                                                                                   @click="renameIvrPromptFile(ivrPromptFile)">
                                                                                                        </el-button>
                                                                                                    </template>
                                                                                                </el-input>
                                                                                            </el-form-item>
                                                                                            <el-form-item>
                                                                                                <button class="btn btn-sm btn-danger pull-right"
                                                                                                        v-if="hasPermissionTo('delete file campaign')"
                                                                                                        @click.prevent="deleteFile('ivr_prompts')">
                                                                                                    <i class="material-icons loader pull-left"
                                                                                                       v-show="loading_ivr_prompt">&#xE863;</i>
                                                                                                    <i class="material-icons pull-left"
                                                                                                       v-show="!loading_ivr_prompt">&#xE872;</i>
                                                                                                    <span> Remove file</span>
                                                                                                </button>
                                                                                            </el-form-item>
                                                                                        </el-form>
                                                                                    </div>
                                                                                    <div class="mt-2 scrollable height-300" v-if="filteredIvrPromptFiles.length > 0 && show_ivr_prompt_files">
                                                                                        <span class="text-md _600">All files:</span>
                                                                                        <el-form v-for="promptFile in filteredIvrPromptFiles"
                                                                                                 :key="promptFile.id"
                                                                                                 :model="promptFile"
                                                                                                 inline>
                                                                                            <el-form-item>
                                                                                                <audio controls>
                                                                                                    <source :src="'/static/uploaded_file/' + promptFile.uuid">
                                                                                                    Your browser does not support the audio element.
                                                                                                </audio>
                                                                                            </el-form-item>
                                                                                            <el-form-item>
                                                                                                <el-input v-model="promptFile.name"
                                                                                                          :disabled="ivr_prompt_file_rename === promptFile.id"
                                                                                                          placeholder="Unnamed File"
                                                                                                          size="small"
                                                                                                          style="width: 500px">
                                                                                                    <template slot="append">
                                                                                                        <el-button type="primary"
                                                                                                                   icon="el-icon-check"
                                                                                                                   :disabled="ivr_prompt_file_rename === promptFile.id || !promptFile.name"
                                                                                                                   :loading="ivr_prompt_file_rename === promptFile.id"
                                                                                                                   @click="renameIvrPromptFile(promptFile)">
                                                                                                        </el-button>
                                                                                                    </template>
                                                                                                </el-input>
                                                                                            </el-form-item>
                                                                                            <el-form-item>
                                                                                                <button class="btn btn-sm btn-info pull-right"
                                                                                                        @click.prevent="useIvrPromptFile(promptFile)">
                                                                                                    <i class="material-icons loader pull-left"
                                                                                                       v-show="loading_ivr_prompt">&#xE863;</i>
                                                                                                    <i class="material-icons pull-left"
                                                                                                       v-show="!loading_ivr_prompt">add_box</i>
                                                                                                    <span> Select</span>
                                                                                                </button>
                                                                                            </el-form-item>
                                                                                        </el-form>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="row mt-3">
                                                                        <div class="col-12">
                                                                            <p class="d-none">
                                                                                <a href="#"
                                                                                   title="help"
                                                                                   class="help"
                                                                                   target="_blank">
                                                                                    <el-button type="text"
                                                                                               class="p-0">
                                                                                        <i class="material-icons text-lg">help</i>
                                                                                        <span class="text-md">Read our tutorial</span>
                                                                                    </el-button>
                                                                                </a>
                                                                            </p>
                                                                            <div class="el-form--label-top">
                                                                                <label class="el-form-item__label">
                                                                                    Text Messaging Ring Group
                                                                                </label>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <el-form-item prop="ivr_text_ring_group_id">
                                                                                    <ring-group-selector
                                                                                        v-model="ivr.text_ring_group_id"
                                                                                        style="width: 300px"
                                                                                        class="pull-left"
                                                                                        @change="changeTextRingGroup">
                                                                                    </ring-group-selector>
                                                                                </el-form-item>
                                                                            </div>
                                                                            <div class="el-form--label-top">
                                                                                <label class="el-form-item__label">
                                                                                    IVR Menu Options
                                                                                </label>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <el-form-item class="mb-0">
                                                                                    <div class="row">
                                                                                        <div class="col-12">
                                                                                            <campaign-ivr-steps
                                                                                                v-if="show_ivr_modal"
                                                                                                :ivr_steps="ivr.steps"
                                                                                                :campaign="campaign"
                                                                                                @updateIvrSteps="updateIvrSteps">
                                                                                            </campaign-ivr-steps>
                                                                                        </div>
                                                                                    </div>
                                                                                </el-form-item>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </el-card>

                                                                <span slot="footer" class="dialog-footer">
                                                                        <el-button type="danger"
                                                                                   @click="beforeCloseIvrModal">
                                                                            Cancel
                                                                        </el-button>
                                                                        <el-button type="primary"
                                                                                   @click="submitIvr"
                                                                                   :loading="ivr_button_loading">
                                                                            {{ this.should_create_ivr ? 'Create' : 'Update' }}
                                                                        </el-button>
                                                                </span>
                                                            </el-dialog>

                                                            <!-- Dead End -->
                                                            <el-timeline-item placement="top"
                                                                              type="primary"
                                                                              size="large">
                                                                <template slot="dot">
                                                                    <el-switch v-model="to_dead_end"
                                                                               active-color="#00a344"
                                                                               :disabled="to_dead_end">
                                                                    </el-switch>
                                                                    <div>
                                                                        <label class="el-form-item__label ml-2">
                                                                            Dead End
                                                                        </label>
                                                                    </div>
                                                                </template>
                                                                <label class="ml-1 help-text">
                                                                    Terminate all calls without connecting to any agents.
                                                                    <el-popover ref="popover_deadend"
                                                                                placement="right"
                                                                                class="ml-2"
                                                                                title="Dead End"
                                                                                width="400"
                                                                                trigger="hover"
                                                                                content="Using this option will prevent your callers
                                                                                from reaching your agents. A dead end line
                                                                                is only useful when you are running a sequence,
                                                                                or an SMS campaign, and don't want callbacks.">
                                                                    </el-popover>
                                                                    <span class="form-hover-help text-blue"
                                                                          v-popover:popover_deadend>
                                                                            More Info
                                                                    </span>
                                                                </label>
                                                            </el-timeline-item>

                                                            <!-- AloAi -->
                                                            <el-timeline-item placement="top"
                                                                              type="primary"
                                                                              size="large"
                                                                              v-if="!isSimpSocial">
                                                                <template slot="dot">
                                                                    <el-switch v-model="to_aloai"
                                                                               active-color="#00a344"
                                                                               :disabled="!isAloAiVoiceAgentsEnabled() || !current_company.aloai_enabled || to_aloai">
                                                                    </el-switch>
                                                                    <label class="el-form-item__label ai-effect-gradient-text ml-2">
                                                                        <sparkle-icon width="16" height="16"/>
                                                                        An AloAi Agent
                                                                        <span v-if="!isAloAiVoiceAgentsEnabled()">(Coming Soon)</span>
                                                                        <span v-else>(Demo)</span>
                                                                    </label>
                                                                </template>
                                                                <label class="ml-1 help-text">
                                                                    Route the conversation to an AloAi Agent for an AI-powered conversation.
                                                                    <el-popover ref="popover_aloai"
                                                                                placement="right"
                                                                                class="ml-2"
                                                                                title="AloAi Agent"
                                                                                width="400"
                                                                                trigger="hover"
                                                                                content="AloAi Agent is an AI-powered agent that can handle your calls.">
                                                                    </el-popover>
                                                                    <span class="form-hover-help text-blue"
                                                                          v-popover:popover_aloai>
                                                                            More Info
                                                                    </span>
                                                                </label>
                                                                <el-card v-show="to_aloai">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <el-form-item prop="aloai_bot_id"
                                                                                          class="no-border"
                                                                                          label="Select AloAi Agent:">
                                                                                <alo-ai-bots-selector v-model="campaign.aloai_bot_id"
                                                                                                      class="pull-left width-300"
                                                                                                      :type="AloAi.TYPE_VOICE"
                                                                                                      :direction="AloAi.DIRECTION_INBOUND"
                                                                                                      @change="changeLineAloAiBot">
                                                                                </alo-ai-bots-selector>
                                                                            </el-form-item>
                                                                        </div>
                                                                    </div>
                                                                </el-card>
                                                            </el-timeline-item>
                                                        </el-timeline>
                                                    </el-form-item>
                                                </div>
                                                <div v-if="!isSimpSocial"
                                                     class="col-6">
                                                    <el-form-item>
                                                        <span class="page-anchor" id="answer-sms"></span>
                                                        <el-timeline class="ivr-timeline">
                                                            <el-timeline-item placement="top"
                                                                              size="large">
                                                                <template slot="dot">
                                                                    <div class="timeline-dot">
                                                                        <div class="el-timeline-item__node el-timeline-item__node--xlarge"></div>
                                                                    </div>
                                                                    <label class="el-form-item__label ml-2">
                                                                        Who should handle incoming messages?
                                                                    </label>
                                                                </template>
                                                            </el-timeline-item>

                                                            <el-timeline-item placement="top"
                                                                              type="primary"
                                                                              size="large">
                                                                <template slot="dot">
                                                                    <el-switch v-model="to_default"
                                                                               active-color="#00a344"
                                                                               :disabled="to_default"
                                                                               @change="toggleDefaultRouting">
                                                                    </el-switch>
                                                                    <div>
                                                                        <label class="el-form-item__label ml-2">
                                                                            Default Routing
                                                                        </label>
                                                                    </div>
                                                                </template>
                                                                <label class="ml-1 help-text">
                                                                    Route incoming messages using the default call router behavior.
                                                                </label>
                                                            </el-timeline-item>

                                                            <el-timeline-item placement="top"
                                                                              type="primary"
                                                                              size="large">
                                                                <template slot="dot">
                                                                    <el-switch v-model="to_aloai_text_bot"
                                                                               active-color="#00a344"
                                                                               :disabled="to_aloai_text_bot || !current_company.aloai_enabled"
                                                                               @change="toggleAloAiTextBot">
                                                                    </el-switch>
                                                                    <label class="el-form-item__label ai-effect-gradient-text ml-2">
                                                                        <sparkle-icon width="16" height="16"/>
                                                                        An AloAi Agent
                                                                    </label>
                                                                </template>
                                                                <label class="ml-1 help-text">
                                                                    Route incoming messages to an AloAi Agent for an AI-powered messaging conversation.
                                                                    <el-popover ref="popover_aloai_text_bot"
                                                                                placement="right"
                                                                                class="ml-2"
                                                                                title="AloAi Agent"
                                                                                width="400"
                                                                                trigger="hover"
                                                                                content="AloAi Agent is an AI-powered agent that handles your incoming messages."
                                                                    />
                                                                    <span class="form-hover-help text-blue"
                                                                          v-popover:popover_aloai_text_bot>
                                                                            More Info
                                                                    </span>
                                                                </label>
                                                                <el-card v-show="to_aloai_text_bot">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <el-form-item prop="aloai_text_bot_id"
                                                                                          class="no-border"
                                                                                          label="Select AloAi Agent:">
                                                                                <alo-ai-bots-selector v-model="campaign.aloai_text_bot_id"
                                                                                                      class="pull-left width-300"
                                                                                                      :type="AloAi.TYPE_TEXT"
                                                                                                      :direction="AloAi.DIRECTION_INBOUND"
                                                                                                      @change="changeLineAloAiTextBot"
                                                                                />
                                                                            </el-form-item>
                                                                        </div>
                                                                    </div>
                                                                </el-card>
                                                            </el-timeline-item>
                                                        </el-timeline>
                                                    </el-form-item>
                                                </div>
                                            </div>

                                            <el-form-item>
                                                <span class="page-anchor" id="csat_survey"></span>
                                                <div class="form-label">
                                                    <h5>CSAT Survey</h5>
                                                    <small>
                                                        Enable CSAT collection for all calls to this line.
                                                        CSAT (Customer Satisfaction) is a measure of how happy or pleased customers are with a product or service they received.
                                                    </small>
                                                </div>
                                                <div class="switch-label mb-3">
                                                    <el-switch v-model="has_csat"
                                                               active-color="#00a344">
                                                    </el-switch>
                                                    <label>Enable CSAT</label>
                                                </div>
                                                <div v-if="has_csat && campaign.csat">
                                                    <text-audio-input form='campaign_settings'
                                                                      tts-form-item='csat_preamble'
                                                                      type='csat_preambles'
                                                                      upload-type='preamble_file'
                                                                      label="CSAT preamble message"
                                                                      :audio-file-messages="audio_file_messages"
                                                                      :campaign="campaign"
                                                                      :headers="headers"
                                                                      :ivr="ivr"
                                                                      :tts="campaign.csat.preamble_tts"
                                                                      :file="campaign.csat.preamble_file"
                                                                      :allow-delete-file="hasPermissionTo('delete file campaign')"
                                                                      :allow-upload-file="hasPermissionTo('upload file campaign')"
                                                                      v-model="campaign.csat.preamble_tts"
                                                                      @input="preValidateForm('campaign_settings')"
                                                                      @success=onSuccessCsatPreamble
                                                                      @applyUploadedAudio="applyUploadedAudio"
                                                                      @deleteFile="campaign.csat.preamble_file = null"
                                                    ></text-audio-input>
                                                    <text-audio-input form='campaign_settings'
                                                                      tts-form-item='csat_prompt'
                                                                      type='csat_prompts'
                                                                      upload-type='prompt_file'
                                                                      label="CSAT prompt message"
                                                                      :audio-file-messages="audio_file_messages"
                                                                      :campaign="campaign"
                                                                      :headers="headers"
                                                                      :ivr="ivr"
                                                                      :tts="campaign.csat.prompt_tts"
                                                                      :file="campaign.csat.prompt_file"
                                                                      :allow-delete-file="hasPermissionTo('delete file campaign')"
                                                                      :allow-upload-file="hasPermissionTo('upload file campaign')"
                                                                      v-model="campaign.csat.prompt_tts"
                                                                      @input="preValidateForm('campaign_settings')"
                                                                      @success=onSuccessCsatPrompt
                                                                      @applyUploadedAudio="applyUploadedAudio"
                                                                      @deleteFile="campaign.csat.prompt_file = null"
                                                    ></text-audio-input>
                                                    <text-audio-input form='campaign_settings'
                                                                      tts-form-item='csat_outro'
                                                                      type='csat_outros'
                                                                      upload-type='outro_file'
                                                                      label="CSAT outro message"
                                                                      :audio-file-messages="audio_file_messages"
                                                                      :campaign="campaign"
                                                                      :headers="headers"
                                                                      :ivr="ivr"
                                                                      :tts="campaign.csat.outro_tts"
                                                                      :file="campaign.csat.outro_file"
                                                                      :allow-delete-file="hasPermissionTo('delete file campaign')"
                                                                      :allow-upload-file="hasPermissionTo('upload file campaign')"
                                                                      v-model="campaign.csat.outro_tts"
                                                                      @input="preValidateForm('campaign_settings')"
                                                                      @success="onSuccessCsatOutro"
                                                                      @applyUploadedAudio="applyUploadedAudio"
                                                                      @deleteFile="campaign.csat.outro_file = null"
                                                    ></text-audio-input>
                                                </div>
                                            </el-form-item>

                                            <el-form-item prop="missed_call_handling_mode">
                                                <span class="page-anchor" id="missed_call_handling_mode"></span>
                                                <div class="form-label">
                                                    <h5>Missed Calls</h5>
                                                    <small>
                                                        If your business is closed, or your routing setting is set
                                                        to Dead End, the call will be missed. Here you can configure
                                                        what happens to missed calls.
                                                    </small>
                                                </div>
                                                <el-radio-group
                                                    v-model="campaign.missed_calls_settings.missed_call_handling_mode"
                                                    @change="changedMissedCallHandlingMode"
                                                    class="w-full mt-2">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <el-radio :label="MissedCallBehaviors.MISSED_CALL_BEHAVIOR_VOICEMAIL" style="width: 100%">
                                                                Take a voicemail
                                                                <div v-show="campaign.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_VOICEMAIL"
                                                                     class="m-t-md ml-1">
                                                                    <p class="_600">Create a Voicemail Message</p>
                                                                    <div>
                                                                        <div class="b-b nav-active-greenish"
                                                                             v-show="campaign.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_VOICEMAIL">
                                                                            <ul class="nav nav-tabs">
                                                                                <li class="nav-item">
                                                                                    <a class="border border-right-0 rounded-top-left"
                                                                                       :class="voicemailNavClass.read"
                                                                                       href=""
                                                                                       data-toggle="tab"
                                                                                       data-target="#voicemail-tts"
                                                                                       aria-expanded="true">
                                                                                        <i class="fa fa-robot"></i>
                                                                                        Text to Speech
                                                                                    </a>
                                                                                </li>
                                                                                <li class="nav-item"
                                                                                    v-if="hasPermissionTo('upload file campaign')">
                                                                                    <a class="border rounded-top-right"
                                                                                       :class="voicemailNavClass.play"
                                                                                       href=""
                                                                                       data-toggle="tab"
                                                                                       data-target="#voicemail-file"
                                                                                       aria-expanded="false">
                                                                                        <i class="fa fa-play text-md"></i>
                                                                                        Play Recording
                                                                                    </a>
                                                                                </li>
                                                                                <li class="nav-item"
                                                                                    v-if="campaign.missed_calls_settings.voicemail_file">
                                                                                    <a class="nav-link"
                                                                                       @click.prevent="deleteFile('voicemail')">
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div class="tab-content p-a m-b-md border border-top-0"
                                                                             v-show="campaign.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_VOICEMAIL">
                                                                            <div :class="voicemailContainerClass.read"
                                                                                 id="voicemail-tts"
                                                                                 aria-expanded="true">
                                                                                <el-form-item class="mb-4"
                                                                                              label="Voicemail Message"
                                                                                              prop="voicemail">
                                                                                    <el-popover ref="popover_voicemail"
                                                                                                placement="bottom-start"
                                                                                                title="Voicemail"
                                                                                                width="200"
                                                                                                trigger="hover"
                                                                                                content="We are sorry, but no agents
                                                                                                                are available to take this call.
                                                                                                                Please leave your message after the beep.">
                                                                                    </el-popover>
                                                                                    <div class="row">
                                                                                        <div class="col-12">
                                                                                            <div class="input-group">
                                                                                                <input type="text"
                                                                                                       class="form-control pt-2 pb-2"
                                                                                                       v-model="campaign.missed_calls_settings.voicemail_tts"
                                                                                                       @input="preValidateForm('campaign_settings')"/>
                                                                                                <variable-dialog></variable-dialog>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </el-form-item>
                                                                            </div>
                                                                            <div :class="voicemailContainerClass.play"
                                                                                 id="voicemail-file"
                                                                                 aria-expanded="false"
                                                                                 v-if="hasPermissionTo('upload file campaign')">
                                                                                <audio-recorder
                                                                                    v-if="campaign.missed_calls_settings.voicemail_file === null"
                                                                                    class="audio-recorder-wrapper"
                                                                                    :action="'/api/v1/campaign/' + campaign.id + '/upload-ivr/voicemails'"
                                                                                    :upload_type="'voicemail_file'"
                                                                                    @applyUploadedAudio="applyUploadedAudio">
                                                                                </audio-recorder>
                                                                                <div class="audio-uploader-wrapper">
                                                                                    <el-upload
                                                                                        v-if="campaign.missed_calls_settings.voicemail_file === null"
                                                                                        drag
                                                                                        :action="'/api/v1/campaign/' + campaign.id + '/upload-ivr/voicemails'"
                                                                                        :headers="headers"
                                                                                        :on-success="onSuccessVoicemail"
                                                                                        :on-error="onFailedVoicemail"
                                                                                        :on-progress="progressUploadVoicemail"
                                                                                        :before-upload="beforeUploadVoicemail"
                                                                                        :file-list="uploadFileList.voicemail">
                                                                                        <i class="el-icon-upload"></i>
                                                                                        <div class="el-upload__text">
                                                                                            Drop file here or <em>click to
                                                                                            upload</em>
                                                                                        </div>
                                                                                        <div class="el-upload__tip"
                                                                                             slot="tip">
                                                                                            MP3/WAV file (less than 8MB)
                                                                                        </div>
                                                                                    </el-upload>
                                                                                    <el-progress :text-inside="true"
                                                                                                 :stroke-width="18"
                                                                                                 :percentage="uploadPercentage.voicemail"
                                                                                                 :status="uploadStatus.voicemail"
                                                                                                 v-if="campaign.missed_calls_settings.voicemail_file === null">
                                                                                    </el-progress>
                                                                                    <audio v-if="campaign.missed_calls_settings.voicemail_file !== null"
                                                                                           controls>
                                                                                        <source :src="'/static/uploaded_file/' + campaign.missed_calls_settings.voicemail_file">
                                                                                        Your browser does not support the audio element.
                                                                                    </audio>
                                                                                    <button class="btn btn-sm btn-danger pull-right"
                                                                                            v-if="campaign.missed_calls_settings.voicemail_file !== null && hasPermissionTo('delete file campaign')"
                                                                                            @click.prevent="deleteFile('voicemails')">
                                                                                        <i class="material-icons loader pull-left"
                                                                                           v-show="loading_voicemail">&#xE863;</i>
                                                                                        <i class="material-icons pull-left"
                                                                                           v-show="!loading_voicemail">&#xE872;</i>
                                                                                        <span>Remove file</span>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="mt-1">
                                                                        <p class="_600">
                                                                            Require contact to press 1 to leave a voicemail
                                                                            <el-popover
                                                                                placement="right"
                                                                                trigger="hover"
                                                                                content="Use this if you find your contacts are accidentally leaving voicemails">
                                                                                <i slot="reference" class="fa fa-info-circle text-primary ml-2"></i>
                                                                            </el-popover>
                                                                        </p>
                                                                        <div class="ml-3">
                                                                            <el-radio-group
                                                                                v-model="campaign.missed_calls_settings.voicemail_confirmation"
                                                                                class="w-full mt-2">
                                                                                <el-radio :label="false" style="width: 100%">No</el-radio>
                                                                                <el-radio :label="true" class="mt-2" style="width: 100%">Yes</el-radio>
                                                                            </el-radio-group>
                                                                            <div v-if="campaign.missed_calls_settings.voicemail_confirmation"
                                                                                 class="voicemail-attention-box mb-4">
                                                                                <p class="note">Note - Platform waits 5 seconds for contact to press 1 to leave a voicemail.</p>
                                                                                <div style="width: fit-content;">
                                                                                    <div class="contain">
                                                                                        <img class="icon" src="/assets/images/app-icons/warning-triangle.svg">
                                                                                        <div class="voicemail-confirmation-attention">Include this in Voicemail Message</div>
                                                                                    </div>
                                                                                    <div class="message">
                                                                                        <p class="m-2 _400">Your <span class="_600">Voicemail Message</span> above should explain to contacts they
                                                                                            should press 1 if they want to leave a voicemail.</p>
                                                                                        <p class="m-2 mt-4 _600">Voicemail Message Example:</p>
                                                                                        <p class="ml-2 mr-2 _400">"Sorry, we don't have any agents available to take your call. Press 1 to leave a
                                                                                            voicemail and we'll get back to you."</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </el-radio>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12"
                                                             :class="campaign.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_REROUTE ? 'mb-1' : ''">
                                                            <el-form-item prop="missed_call_handling_mode_route_to_other_line"
                                                                          class="no-border"
                                                                          :class="campaign.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_REROUTE ? 'mb-3' : ''">
                                                                <el-radio :label="MissedCallBehaviors.MISSED_CALL_BEHAVIOR_REROUTE">
                                                                    Route to other line
                                                                    <campaign-selector
                                                                        v-if="campaign.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_REROUTE"
                                                                        class="mt-2"
                                                                        style="margin-left: 27px !important;"
                                                                        ref="campaign_selector"
                                                                        :exclude="campaign.id"
                                                                        :value="campaign.missed_calls_settings.reroute_to_campaign_id"
                                                                        v-model="campaign.missed_calls_settings.reroute_to_campaign_id"
                                                                        @change="changeRerouteToCampaign">
                                                                    </campaign-selector>
                                                                </el-radio>
                                                            </el-form-item>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12"
                                                             :class="campaign.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_FORWARD ? 'mb-1' : ''">
                                                            <el-form-item prop="missed_call_handling_mode_forward_number"
                                                                          class="no-border"
                                                                          :class="campaign.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_FORWARD ? 'mb-3' : ''">
                                                                <el-radio :label="MissedCallBehaviors.MISSED_CALL_BEHAVIOR_FORWARD">
                                                                    Forward externally
                                                                    <el-form-item
                                                                        v-if="campaign.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_FORWARD"
                                                                        class="mt-2 no-border">
                                                                            <el-input
                                                                                placeholder="Forward Number"
                                                                                style="width: 200px; margin-left: 27px !important;"
                                                                                v-model="campaign.missed_calls_settings.forward_to"
                                                                                @input="preValidateForm('campaign_settings')">
                                                                            </el-input>
                                                                    </el-form-item>
                                                                </el-radio>
                                                            </el-form-item>
                                                        </div>
                                                    </div>
                                                    <div class="row"
                                                         v-if="!isSimpSocial">
                                                        <div class="col-12"
                                                             :class="campaign.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_ALOAI ? 'mb-1' : ''">
                                                            <el-form-item prop="missed_call_handling_mode_aloai"
                                                                          class="no-border"
                                                                          :class="campaign.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_ALOAI ? 'mb-3' : ''">
                                                                <el-radio class="ai-effect-gradient-text"
                                                                          :label="MissedCallBehaviors.MISSED_CALL_BEHAVIOR_ALOAI"
                                                                          :disabled="!isAloAiVoiceAgentsEnabled()">
                                                                    <sparkle-icon width="16" height="16"/>
                                                                    Send an AloAi Agent to handle it
                                                                    <span v-if="!isAloAiVoiceAgentsEnabled()">(Coming Soon)</span>
                                                                    <span v-else>(Demo)</span>
                                                                    <el-form-item
                                                                        v-if="campaign.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_ALOAI"
                                                                        class="mt-2 no-border">
                                                                        <alo-ai-bots-selector
                                                                            v-model="campaign.missed_calls_settings.aloai_bot_id"
                                                                            :type="AloAi.TYPE_VOICE"
                                                                            :direction="AloAi.DIRECTION_INBOUND"
                                                                            class="width-300"
                                                                            @change="changeMissedCallAloAiBot"/>
                                                                    </el-form-item>
                                                                </el-radio>
                                                            </el-form-item>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12"
                                                             :class="campaign.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_NOTHING ? 'mb-1' : ''">
                                                            <el-form-item prop="missed_call_handling_mode_do_nothing"
                                                                          class="no-border">
                                                                <el-radio :label="MissedCallBehaviors.MISSED_CALL_BEHAVIOR_NOTHING">
                                                                    Do nothing and hang up
                                                                </el-radio>
                                                            </el-form-item>
                                                        </div>
                                                    </div>
                                                </el-radio-group>
                                            </el-form-item>

                                            <el-form-item prop="missed_calls_extra_notifications">
                                                <span class="page-anchor" id="missed_calls_extra_notifications"></span>
                                                <div class="form-label">
                                                    <h5>Missed Calls Extra Notifications</h5>
                                                    <small>
                                                        Select who receives a notification when your business has a missed call
                                                    </small>
                                                </div>
                                                <el-checkbox
                                                    v-model="campaign.last_called_agent_missed_call_notification"
                                                    @change="preValidateForm('campaign_settings')">
                                                    Notify the last agent who called the contact
                                                </el-checkbox>
                                                <br>
                                                <el-checkbox
                                                    v-model="campaign.contact_owner_missed_call_notification"
                                                    @change="preValidateForm('campaign_settings')">
                                                    Notify the contact owner
                                                </el-checkbox>
                                            </el-form-item>

                                            <el-form-item class="no-border">
                                                <span class="page-anchor" id="should_message_if_missed"></span>
                                                <div class="form-label">
                                                    <h5>Text message after missed call</h5>
                                                    <small>
                                                        If the call is abandoned or missed, send a text message to the
                                                        caller.
                                                    </small>
                                                </div>
                                                <div class="switch-label mb-3">
                                                    <el-switch v-model="campaign.should_message_if_missed"
                                                               active-color="#00a344">
                                                    </el-switch>
                                                    <label>Enable text message after missed call</label>
                                                </div>
                                                <el-form-item prop="missed_call_message"
                                                              class="no-border"
                                                              v-if="campaign.should_message_if_missed">
                                                    <el-input type="textarea"
                                                              placeholder="Follow up text"
                                                              v-model="campaign.missed_call_message"
                                                              :rows="2"
                                                              @input="preValidateForm('campaign_settings')">
                                                    </el-input>
                                                </el-form-item>
                                            </el-form-item>
                                        </div>
                                        <div class="col-md-2 d-none d-md-block ml-auto">
                                            <div
                                                class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header">
                                                <h6 class="list-group-item">
                                                    On This Page
                                                </h6>
                                                <a href="#answer"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('answer')">
                                                    Call and Messaging Assignment
                                                </a>
                                                <a
                                                    href="#csat_survey"
                                                    class="list-group-item list-group-item-action"
                                                    @click="shine('csat_survey')">
                                                    CSAT Survey
                                                </a>
                                                <a href="#missed_call_handling_mode"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('missed_call_handling_mode')">
                                                    Missed Calls
                                                </a>
                                                <a href="#missed_calls_extra_notifications"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('missed_calls_extra_notifications')">
                                                    Missed Calls Extra Notifications
                                                </a>
                                                <a href="#should_message_if_missed"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('should_message_if_missed')">
                                                    Text After Missed Calls
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </el-tab-pane>

                                <el-tab-pane name="advanced-settings"
                                             class="p-3"
                                             data-tab-error="advanced_settings"
                                             v-if="!campaign.is_fax">
                                    <span slot="label">Advanced Settings<span v-html="tabLabelErrorIcon(tab_errors.advanced_settings)"></span></span>
                                    <div class="row">
                                            <div class="col-md-9">
                                                <el-form-item>
                                                    <span class="page-anchor" id="should_whisper"></span>
                                                    <div class="form-label">
                                                        <h5>Whisper</h5>
                                                        <small>
                                                            Whisper something to the agent before connecting the call. The
                                                            caller does not hear the message.
                                                        </small>
                                                        <el-popover ref="popover_whisper"
                                                                    placement="right"
                                                                    class="ml-2"
                                                                    title="Whisper"
                                                                    width="400"
                                                                    trigger="hover"
                                                                    content="Useful when your agents are taking calls from
                                                                        different campaigns. Your can whisper someone's name,
                                                                        marketing source or other data into their ears.">
                                                        </el-popover>
                                                        <span class="form-hover-help text-blue"
                                                              v-popover:popover_whisper>
                                                                More Info
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <div class="switch-label mb-3">
                                                            <el-switch v-model="campaign.should_whisper"
                                                                       active-color="#00a344">
                                                            </el-switch>
                                                            <label>
                                                                Enable whisper
                                                            </label>
                                                        </div>
                                                        <div class="b-b nav-active-greenish"
                                                             v-show="campaign.should_whisper">
                                                            <ul class="nav nav-tabs">
                                                                <li class="nav-item">
                                                                    <a class="border border-right-0 rounded-top-left"
                                                                       :class="whisperNavClass.read"
                                                                       href=""
                                                                       data-toggle="tab"
                                                                       data-target="#whisper-tts"
                                                                       aria-expanded="true">
                                                                        <i class="fa fa-robot"></i>
                                                                        Text to Speech
                                                                    </a>
                                                                </li>
                                                                <li class="nav-item"
                                                                    v-if="hasPermissionTo('upload file campaign')">
                                                                    <a class="border rounded-top-right"
                                                                       :class="whisperNavClass.play"
                                                                       href=""
                                                                       data-toggle="tab"
                                                                       data-target="#whisper-file"
                                                                       aria-expanded="false">
                                                                        <i class="fa fa-play text-md"></i>
                                                                        Play Recording
                                                                    </a>
                                                                </li>
                                                                <li class="nav-item"
                                                                    v-if="campaign.whisper_file">
                                                                    <a class="nav-link"
                                                                       @click.prevent="deleteFile('whisper')">
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="tab-content p-a m-b-md border border-top-0"
                                                             v-show="campaign.should_whisper">
                                                            <div class="break-word"
                                                                 :class="whisperContainerClass.read"
                                                                 id="whisper-tts"
                                                                 aria-expanded="true">
                                                                <el-form-item label="Whisper Message"
                                                                              class="no-border mb-4"
                                                                              prop="should_whisper">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <div class="input-group">
                                                                                <input type="text"
                                                                                       class="form-control pt-2 pb-2"
                                                                                       v-model="campaign.whisper_tts"
                                                                                       @input="preValidateForm('campaign_settings')"/>
                                                                                <variable-dialog :has_agent="true"></variable-dialog>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </el-form-item>
                                                                <p>
                                                                    This text-to-speech prompt automatically plays immediately as soon as the call gets connected. It's important to make sure that the
                                                                    forwarding number does not have any prompts as the text-to-speech will play while the prompt is also playing causing the agent to
                                                                    miss
                                                                    the text-to-speech prompt.
                                                                </p>
                                                            </div>
                                                            <div :class="whisperContainerClass.play"
                                                                 id="whisper-file"
                                                                 aria-expanded="false"
                                                                 v-if="hasPermissionTo('upload file campaign')">
                                                                <audio-recorder v-if="campaign.whisper_file === null"
                                                                                class="audio-recorder-wrapper"
                                                                                :action="'/api/v1/campaign/' + campaign.id + '/upload-ivr/whispers'"
                                                                                :upload_type="'whisper_file'"
                                                                                @applyUploadedAudio="applyUploadedAudio">
                                                                </audio-recorder>
                                                                <div class="audio-uploader-wrapper">
                                                                    <el-upload
                                                                        v-if="campaign.whisper_file === null"
                                                                        drag
                                                                        :action="'/api/v1/campaign/' + campaign.id + '/upload-ivr/whispers'"
                                                                        :headers="headers"
                                                                        :on-success="onSuccessWhisper"
                                                                        :on-error="onFailedWhisper"
                                                                        :on-progress="progressUploadWhisper"
                                                                        :before-upload="beforeUploadWhisper"
                                                                        :file-list="uploadFileList.whisper">
                                                                        <i class="el-icon-upload"></i>
                                                                        <div class="el-upload__text">
                                                                            Drop file here or <em>click to
                                                                            upload</em>
                                                                        </div>
                                                                        <div class="el-upload__tip"
                                                                             slot="tip">
                                                                            MP3/WAV file (less than 8MB)
                                                                        </div>
                                                                    </el-upload>
                                                                    <el-progress :text-inside="true"
                                                                                 :stroke-width="18"
                                                                                 :percentage="uploadPercentage.whisper"
                                                                                 :status="uploadStatus.whisper"
                                                                                 v-if="campaign.whisper_file === null">
                                                                    </el-progress>
                                                                    <audio v-if="campaign.whisper_file !== null"
                                                                           controls>
                                                                        <source :src="'/static/uploaded_file/' + campaign.whisper_file">
                                                                        Your browser does not support the audio element.
                                                                    </audio>
                                                                    <button class="btn btn-sm btn-danger pull-right"
                                                                            v-if="campaign.whisper_file !== null && hasPermissionTo('delete file campaign')"
                                                                            @click.prevent="deleteFile('whispers')">
                                                                        <i class="material-icons loader pull-left"
                                                                           v-show="loading_whisper">&#xE863;</i>
                                                                        <i class="material-icons pull-left"
                                                                           v-show="!loading_whisper">&#xE872;</i>
                                                                        <span>Remove file</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </el-form-item>

                                                <el-form-item>
                                                    <span class="page-anchor" id="caller_id_option"></span>
                                                    <div class="form-label">
                                                        <h5>Caller ID Suppression</h5>
                                                        <small>
                                                            Redact caller's phone number when transferring calls.
                                                            Only useful if you are selling calls
                                                            to third parties.
                                                        </small>
                                                    </div>
                                                    <div class="switch-label">
                                                        <el-switch v-model="campaign.caller_id_option"
                                                                   active-color="#00a344">
                                                        </el-switch>
                                                        <label>
                                                            Enable caller ID suppression
                                                        </label>
                                                    </div>
                                                </el-form-item>

                                                <el-form-item>
                                                    <span class="page-anchor" id="should_block_spam"></span>
                                                    <div class="form-label">
                                                        <h5>Spam Detection (Beta)</h5>
                                                        <small>
                                                            This feature blocks calls from known spammers and robocallers.
                                                            All calls will be rejected without ringing you.
                                                        </small>
                                                    </div>
                                                    <div class="switch-label">
                                                        <el-switch v-model="campaign.should_block_spam"
                                                                   active-color="#00a344">
                                                        </el-switch>
                                                        <label>
                                                            Enable spam detection
                                                        </label>
                                                    </div>
                                                </el-form-item>
                                            </div>
                                            <div class="col-md-2 d-none d-md-block ml-auto">
                                                <div
                                                    class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header">
                                                    <h6 class="list-group-item">
                                                        On This Page
                                                    </h6>
                                                    <a href="#should_whisper"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('should_whisper')">
                                                        Whisper
                                                    </a>
                                                    <a href="#caller_id_option"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('caller_id_option')">
                                                        Call Mask Suppression
                                                    </a>
                                                    <a href="#should_block_spam"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('should_block_spam')">
                                                        Spam Detection
                                                    </a>
                                                </div>
                                            </div>
                                    </div>
                                </el-tab-pane>

                                <el-tab-pane name="triggers"
                                             class="p-3"
                                             data-tab-error="triggers"
                                             v-if="!campaign.is_fax">
                                    <span slot="label">Triggers<span v-html="tabLabelErrorIcon(tab_errors.triggers)"></span></span>
                                    <div class="row">
                                            <div class="col-md-12">
                                                <el-form-item class="no-border">
                                                    <div class="form-label mb-3">
                                                        <h5>Triggers</h5>
                                                        <small class="w-75 d-block mb-2">
                                                            Here you can define triggers to be applied to
                                                            your calls, messages, and contacts based on their
                                                            disposition (how they finished) and duration, status or content.
                                                            All triggers will be applied independently without order or precedence. One trigger will not modify another trigger.
                                                        </small>
                                                        <a href="https://support.aloware.com/en/articles/9031270-lines-menu-general-settings"
                                                           title="help"
                                                           class="help"
                                                           target="_blank">
                                                            <el-button type="primary"
                                                                       plain
                                                                       size="small">
                                                                <i class="fa fa-question-circle"></i>
                                                                Read Tutorial
                                                            </el-button>
                                                        </a>
                                                    </div>
                                                    <div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <el-form-item class="mb-0 no-border">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <triggers
                                                                                    :triggers="campaign.triggers"
                                                                                    :intake_route_list="intake_route_list"
                                                                                    @validateTrigger="updateValidation">
                                                                                </triggers>
                                                                            </div>
                                                                        </div>
                                                                    </el-form-item>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </el-form-item>
                                            </div>
                                    </div>
                                </el-tab-pane>

                                <el-tab-pane name="cnam"
                                             class="p-3"
                                             v-if="current_company && campaign && hasRole('Company Admin') && !campaign.is_fax">
                                    <span slot="label">CNAM</span>
                                    <cnam-tab :campaign_id="campaign.id"
                                              :caller_id.sync="campaign.cnam_caller_id"
                                              @CNAM-deleted="removeCNAM">
                                    </cnam-tab>
                                </el-tab-pane>

                                <el-tab-pane name="email_conversations"
                                             class="p-3"
                                             data-tab-error="email_conversations"
                                             v-if="canSeeEmailConversations">
                                    <span slot="label">Email Conversations<span v-html="tabLabelErrorIcon(tab_errors.email_conversations)"></span></span>
                                    <el-form-item class="no-border">
                                        <div class="form-label">
                                            <h5>Email Conversations</h5>
                                            <small>Here you can define an email route to have two-way email conversations with your customers</small>
                                        </div>
                                        <a href="https://support.aloware.com/en/articles/9037643-sending-emails-with-aloware-talk"
                                           title="help"
                                           target="_blank">
                                            <el-button type="primary"
                                                       plain
                                                       size="small">
                                                <i class="fa fa-question-circle"></i>
                                                Read Tutorial
                                            </el-button>
                                        </a>
                                        <div class="mt-3">
                                            <el-form ref="intake_email_form"
                                                     autocomplete="off"
                                                     label-position="top"
                                                     :model="intake_data"
                                                     @submit.prevent.native="saveIntakeRoute">
                                                <el-form-item label="Email" class="no-border">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <el-input v-model="intake_username"
                                                                      class="btn-input-group"
                                                                      :keyup.prevent="checkSpecialCharacter()"
                                                                      :style="{'min-width' : '370px'}">
                                                                <template slot="append">@{{ conversation_domain }}</template>
                                                            </el-input>
                                                        </div>
                                                    </div>
                                                </el-form-item>
                                            </el-form>
                                        </div>
                                    </el-form-item>
                                </el-tab-pane>

                                <el-tab-pane name="audit"
                                             class="p-3"
                                             v-if="campaign && hasRole('Company Admin')">
                                    <span slot="label">Audit Log</span>
                                    <AuditTab :campaign_id="campaign.id" ref="auditTab"/>
                                </el-tab-pane>
                            </el-tabs>
                        </template>
                    </el-form>
                </div>
                <form-height-indicator/>
            </div>

            <div class="fixed-footer padding pb-0">
                <div class="form-group row mb-0 mt-0">
                    <div v-if="disableOperationButtons" class="col-12 d-none d-md-block p-2 edit-operations">
                        <el-button class="pull-left"
                                   v-if="canGoBack"
                                   @click.prevent="goBack">
                            <i class="fa fa-arrow-left"></i>
                            Back
                        </el-button>
                        <el-button class="pull-right ml-0"
                                   type="success"
                                   @click.prevent="submitForm('campaign_settings')"
                                   v-if="canUpdateCampaign">
                            <i class="material-icons loader pull-left"
                               v-show="loading">&#xE863;</i>
                            Save
                        </el-button>
                        <button class="btn el-btn pull-right mr-2"
                                :class="campaign.active == true ? 'deep-orange-500' : 'light-blue-500'"
                                @click="toggleActive"
                                v-if="hasPermissionTo('toggle active status campaign')"
                                :disabled="loading_btn">
                            <i class="fa fa-spin fa-spinner"
                               v-if="loading_btn"></i>
                            <template v-else>
                                <i class="fa fa-pause"
                                   v-if="campaign.active == true"></i>
                                <i class="fa fa-play"
                                   v-else></i>
                            </template>
                            {{ campaign.active == true ? 'Pause' : 'Activate' }}
                        </button>
                        <button class="btn el-btn pull-right danger mr-2"
                                v-if="hasPermissionTo('archive campaign')"
                                :disabled="loading_delete"
                                @click="deleteCampaignRemote()">
                            <i class="material-icons loader pull-left"
                               v-show="loading_delete">&#xE863;</i>
                            <i class="fa fa-trash"
                               v-show="!loading_delete"></i>
                            Delete
                        </button>
                    </div>
                    <div v-if="disableOperationButtons" class="col-12 d-sm-block d-md-none p-2 edit-operations">
                        <el-button class="pull-left"
                                   size="mini"
                                   v-if="canGoBack"
                                   @click.prevent="goBack">
                            <i class="fa fa-arrow-left"></i>
                        </el-button>
                        <el-button class="pull-right"
                                   size="mini"
                                   type="success"
                                   :disabled="loading || !validated"
                                   @click.prevent="submitForm('campaign_settings')"
                                   v-if="canUpdateCampaign">
                            <i class="material-icons loader pull-left"
                               v-show="loading">&#xE863;</i>
                            Save
                        </el-button>

                        <el-popover placement="top"
                                    width="100%"
                                    trigger="click">
                            <el-button size="mini"
                                       :type="campaign.active == true ? 'deep-orange-500' : 'light-blue-500'"
                                       class="pull-right ml-2"
                                       v-popover:dialog-pause-popover
                                       v-if="hasPermissionTo('toggle active status campaign')"
                                       :disabled="loading_btn"
                                       @click="toggleActive">
                                <i class="material-icons loader pull-left"
                                   v-if="loading_btn">&#xE863;</i>
                                <template v-else>
                                    <i class="material-icons pull-left"
                                       v-if="campaign.active == true">pause</i>
                                    <i class="material-icons pull-left"
                                       v-else>autorenew</i>
                                </template>
                                {{ campaign.active == true ? 'Pause' : 'Activate' }}
                            </el-button>
                            <el-button type="danger"
                                       size="mini"
                                       class="pull-right ml-0"
                                       v-if="hasPermissionTo('archive campaign')"
                                       :disabled="loading_delete"
                                       @click="deleteCampaignRemote()">
                                <i class="material-icons loader pull-left"
                                   v-show="loading_delete">&#xE863;</i>
                                <i class="fa fa-trash"
                                   v-show="!loading_delete"></i>
                                Delete
                            </el-button>
                            <el-button slot="reference"
                                       size="mini"
                                       type="primary"
                                       class="pull-right"
                                       icon="el-icon-more-outline"
                                       plain>
                            </el-button>
                        </el-popover>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.el-radio {
    white-space: normal;
}

.text-warning {
    color: #FFB020;
    font-size: 0.85em;
}
</style>

<script>
import auth from '../../auth'
import {mapActions, mapGetters, mapState} from 'vuex'
import {acl_mixin, campaign_mixin, form_handler, form_validation_mixin, kyc_mixin, paginator_mixin, styling_mixin, tab_section_mixin, validator_mixin, aloai_mixin} from '../../mixins'
import Triggers from '../../components/triggers'
import CampaignIvrSteps from '../../components/campaign-ivr-steps'
import VariableDialog from '../../components/variable-dialog'
import RingGroupSelector from '../../components/ring-group-selector'
import InputReadonlyWithCopy from '../../components/input-readonly-with-copy'
import FormHeightIndicator from '../../components/form-height-indicator'
import TransferPhoneNumberPopover from '../../components/transfer-phone-number-popover'
import {EventBus} from '../../event-bus'
import CnamTab from './settings/CNAM'
import IvrSelector from "../../components/ivr-selector"
import UpgradeNow from "../../components/upgrade-now"
import UpgradeNowLite from "../../components/upgrade-now-lite"
import CustomMessageDisplay from '../../components/kyc/custom-message-display'
import RetentionDurationFeatureOverview from "../settings/retention-duration-feature-overview.vue"
import AuditTab from './settings/Audit'
import AloAiBotsSelector from "../../components/aloai/alo-ai-bots-selector.vue"
import SparkleIcon from "../../../images/app-icons/sparkle-bold-icon.vue"
import TextAudioInput from "../../components/text-audio-input.vue";
import * as AnswerTypes from '../../constants/answer-types'
import * as CampaignCallRouterBehavior from "../../constants/campaign-call-router-behaviors"
import * as CampaignSmsRouterBehavior from "../../constants/campaign-sms-router-behaviors"
import * as CallRecordsRetentionDurations from "../../constants/call-records-retention-durations"
import * as MissedCallBehaviors from '../../constants/missed-call-behavior'
import * as AloAi from "../../constants/aloai"
const defaultIvr = {
    name: '',
    prompt_tts: '',
    prompt_file: null,
    text_ring_group_id: null,
    steps: [],
}

export default {
    mixins: [
        acl_mixin,
        styling_mixin,
        paginator_mixin,
        campaign_mixin,
        form_handler,
        tab_section_mixin,
        validator_mixin,
        form_validation_mixin,
        kyc_mixin,
        aloai_mixin
    ],

    components: {
        SparkleIcon,
        AloAiBotsSelector,
        RetentionDurationFeatureOverview,
        UpgradeNow,
        UpgradeNowLite,
        IvrSelector,
        InputReadonlyWithCopy,
        FormHeightIndicator,
        CampaignIvrSteps,
        Triggers,
        VariableDialog,
        RingGroupSelector,
        TransferPhoneNumberPopover,
        CnamTab,
        AuditTab,
        CustomMessageDisplay,
        TextAudioInput
    },

    props: {
        campaign_id: {
            required: true
        }
    },

    data() {
        return {
            show_mask_button: false,
            auth: auth,
            env: null,
            show_operating_hours: false,
            campaign: null,
            incoming_numbers_loading: false,
            incoming_numbers_pagination: {},
            incoming_numbers_key: 1,
            incoming_numbers_search: '',
            loading: false,
            loading_btn: false,
            loading_delete: false,
            loading_recording: false,
            loading_greeting: false,
            loading_ivr_prompt: false,
            loading_ask_for_text_authorization: false,
            loading_campaign: false,
            to_ring_group: false,
            to_user: false,
            to_dead_end: false,
            to_aloai: false,
            to_ivr: false,
            to_default: false,
            to_aloai_text_bot: false,
            has_call_waiting: false,
            has_csat: false,
            has_aloai_text_bot: false,
            loading_whisper: false,
            loading_voicemail: false,
            show_ivr_modal: false,
            should_create_ivr: false,
            show_ivr_prompt_files: true,
            ivr: defaultIvr,
            ivr_button_loading: false,
            selected_ivr_id: null,
            ivr_prompt_file_rename: null,
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + auth.user.profile.api_token,
                'X-Socket-Id': window.Echo.socketId()
            },
            uploadPercentage: {
                record: 0,
                greeting: 0,
                ivr_prompt: 0,
                closed_hours_voice_prompt: 0,
                askForTextAuthorization: 0,
                whisper: 0,
                voicemail: 0
            },
            uploadStatus: {
                record: 'success',
                greeting: 'success',
                ivr_prompt: 'success',
                closed_hours_voice_prompt: 'success',
                askForTextAuthorization: 'success',
                whisper: 'success',
                voicemail: 'success'
            },
            uploadFileList: {
                record: [],
                greeting: [],
                ivr_prompt: [],
                closed_hours_voice_prompt: [],
                askForTextAuthorization: [],
                whisper: [],
                voicemail: []
            },
            hide_add: false,
            sm_only: null,
            ppc_only: null,
            organic_only: null,
            rules: {
                name: [
                    {
                        required: true,
                        message: 'Please provide a line name',
                        trigger: 'blur'
                    }
                ],
                call_router_behavior: [
                    {
                        required: true,
                        message: 'Please select a call routing mode',
                        trigger: 'change'
                    },
                ],
                sms_router_behavior: [
                    {
                        required: true,
                        message: 'Please select a messaging routing mode',
                        trigger: 'change'
                    },
                ],
                ring_group_id: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.to_ring_group) {
                                if (this.campaign.ring_group_id) {
                                    callback()
                                } else {
                                    callback(new Error('Please select a ring group'))
                                }
                            } else {
                                callback()
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                ivr_text_ring_group_id: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.campaign.call_router_behavior === CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_IVR) {
                                if (this.ivr.text_ring_group_id) {
                                    callback()
                                } else {
                                    callback(new Error('Please select a ring group'))
                                }
                            } else {
                                callback()
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                user_id: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.to_user) {
                                if (this.campaign.user_id) {
                                    callback()
                                } else {
                                    callback(new Error('Please select a user'))
                                }
                            } else {
                                callback()
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                ivr_id: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.campaign.call_router_behavior === CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_IVR) {
                                if (this.campaign.ivr_id) {
                                    callback()
                                } else {
                                    callback(new Error('Please select an IVR'))
                                }
                            } else {
                                callback()
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                aloai_bot_id: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.to_aloai) {
                                if (this.campaign.aloai_bot_id) {
                                    callback()
                                } else {
                                    callback(new Error('Please select an AloAi agent'))
                                }
                            } else {
                                callback()
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                aloai_text_bot_id: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.to_aloai_text_bot) {
                                if (this.campaign.aloai_text_bot_id) {
                                    callback()
                                } else {
                                    callback(new Error('Please select an AloAi agent for messaging'))
                                }
                            } else {
                                callback()
                            }
                        },
                        message: 'Please select an AloAi Agent',
                        trigger: 'blur',
                    }
                ],
                should_whisper: [
                    {
                        validator: this.whisperValidator,
                        trigger: 'change'
                    },
                ],
                should_greet: [
                    {
                        validator: this.greetingValidator,
                        trigger: 'change'
                    },
                ],
                ivr_prompt_tts: [
                    {
                        required: () => this.to_ivr,
                        validator: this.ivrPromptValidator,
                        trigger: 'blur'
                    },
                ],
                should_record: [
                    {
                        validator: this.recordValidator,
                        trigger: 'change'
                    },
                ],
                csat_preamble: [
                  {
                    validator: this.csatPreambleValidator,
                    trigger: 'blur'
                  },
                ],
                csat_prompt: [
                  {
                    validator: this.csatPromptValidator,
                    trigger: 'blur'
                  },
                ],
                csat_outro: [
                  {
                    validator: this.csatOutroValidator,
                    trigger: 'blur'
                  },
                ],
                voicemail: [
                    {
                        validator: this.voicemailValidator,
                        trigger: 'blur'
                    },
                ],
                closed_hours_voice_prompt: [
                    {
                        validator: this.closedHoursMissedCallVoicemailValidator,
                        trigger: 'blur'
                    },
                ],
                closed_hours_auto_reply_text: [
                    {
                        validator: this.closedHoursMissedTextAutoReplyValidator,
                        trigger: 'blur'
                    },
                ],
                should_ask_for_text_authorization: [
                    {
                        required: () => this.campaign.ask_for_text_authorization_file === null,
                        validator: this.textAuthorizationValidator,
                        trigger: 'blur'
                    },
                ],
                missed_call_message: [
                    {
                        required: () => this.campaign.should_message_if_missed == true,
                        message: 'Please provide a missed call message',
                        trigger: 'blur'
                    }
                ],
                voicemail_confirmation_by_customer: [
                    {
                        validator: this.voicemailConfirmationByCustomer,
                        trigger: 'blur'
                    }
                ],
                missed_call_handling_mode_forward_number: [
                    {
                        validator: this.missedCallHandlingForwardNumber,
                        trigger: 'blur'
                    }
                ],
                missed_call_handling_mode_route_to_other_line: [
                    {
                        validator: this.missedCallHandlingRouteToOtherLine,
                        trigger: 'blur'
                    }
                ],
                missed_call_handling_mode_aloai: [
                    {
                        validator: this.missedCallHandlingAloAi,
                        trigger: 'blur'
                    }
                ],
            },
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true,
            dialogVisible: false,
            dialogCallerIdVisible: false,
            numberAdded: false,
            prop_counter: 0,
            active_tab_name: 'general-settings',
            campaign_name: null,
            has_private_inbox: false,
            timeIncrement: 30,
            loading_operating_hours: false,
            loading_closed_hours_voice_prompt: false,
            editingCallRingGroup: null,
            editingTextRingGroup: null,
            audio_file_messages: {
                greeting_file: 'Greeting uploaded successfully.',
                closed_hours_voice_prompt_file: 'Closed hours missed call voicemail file uploaded successfully.',
                ask_for_text_authorization_file: 'Text authorization uploaded successfully.',
                ivr_prompt_file: 'IVR prompt uploaded successfully.',
                voicemail_file: 'Voicemail uploaded successfully.',
                whisper_file: 'Whisper uploaded successfully.',
                record_file: 'Recording notice uploaded successfully.',
                preamble_file: 'CSAT preamble uploaded successfully.',
                prompt_file: 'CSAT prompt uploaded successfully.',
                outro_file: 'CSAT outro uploaded successfully.'
            },
            conversation_domain: localStorage.getItem('conversation_mailgun_domain'),
            intake_data: {
                campaign_id: this.campaign_id,
                intake: '',
                type: 'email'
            },
            intake_username: '',
            intake_route_list: [],
            popoverTransferLine: false,
            dialogUnrentNumber: false,
            verify_unrent: '',
            confirm_unrent: true,
            to_unrent: {
                id: null,
                phone_number: null
            },
            verify_delete: '',
            confirm_delete: true,
            to_delete: {
                id: null,
                phone_number: null
            },
            dialogDeleteNumber: false,
            tab_errors: {
                general_settings: false,
                ivr_settings: false,
                routing_settings: false,
                advanced_settings: false,
                triggers: false,
                email_conversations: false,
            },
            error_notification: null,
            line_capability_icon_colors: {},
            canEnableSendLongMessages: false,
            AnswerTypes,
            CampaignCallRouterBehavior,
            CampaignSmsRouterBehavior,
            CallRecordsRetentionDurations,
            MissedCallBehaviors,
            AloAi
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company,
            campaigns: state => state.campaigns,
            users: state => state.users,
            ring_groups: state => state.ring_groups,
            ivrs: state => state.ivrs,
            ivr_prompt_files: state => state.ivr_prompt_files,
        }),

        ...mapGetters('cache', ['isSimpSocial']),

        impersonated() {
            return localStorage.getItem('impersonate') === 'true'
        },

        getRetentionDurationTooltipText() {
            let text = []
            if (this.current_company.call_recording_retention !== -1) {
                text.push('the company-wide setting takes precedence, ensuring all lines follow a unified retention policy')
            }

            if (!this.hasRole('Billing Admin')) {
                text.push('only Billing Admins can change this setting')
            }

            return 'Disabled - ' + text.join('<br />and ')
        },

        hasAccessToEditRetentionDuration() {
            return this.current_company.call_recording_retention === -1 && this.hasRole('Billing Admin')
        },

        ivrPromptFile() {
            if (this.ivr && this.ivr.prompt_file) {
                return this.ivr_prompt_files.find(file => file.uuid === this.ivr.prompt_file)
            }

            return null
        },

        filteredIvrPromptFiles() {
            if (this.ivr_prompt_files.length === 0) {
                return []
            }

            return this.ivr_prompt_files.filter(file => file.uuid !== this.ivr.prompt_file)
        },

        usableNumbers() {
            if (this.campaign && this.campaign.incoming_numbers) {
                return this.campaign.incoming_numbers.filter(incoming_number => !incoming_number.do_not_use)
            }

            return []
        },

        exitsAlphabeticalOrder() {
            if (this.exits) {
                let exits = _.clone(this.exits)
                return exits.sort((a, b) => {
                    let textA = a.name.toUpperCase()
                    let textB = b.name.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
            }

            return []
        },

        recordNavClass() {
            return {
                read: {
                    'disabled': this.campaign.record_file !== null,
                    'nav-link': true,
                    'active': this.campaign.record_file === null
                },
                play: {
                    'nav-link': true,
                    'active': this.campaign.record_file !== null
                }
            }
        },

        recordContainerClass() {
            return {
                read: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.campaign.record_file === null
                },
                play: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.campaign.record_file !== null
                },
            }
        },

        greetingNavClass() {
            return {
                read: {
                    'disabled': this.campaign.greeting_file !== null,
                    'nav-link': true,
                    'active': this.campaign.greeting_file === null
                },
                play: {
                    'nav-link': true,
                    'active': this.campaign.greeting_file !== null
                }
            }
        },

        greetingContainerClass() {
            return {
                read: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.campaign.greeting_file === null
                },
                play: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.campaign.greeting_file !== null
                },
            }
        },

        ivrPromptNavClass() {
            return {
                read: {
                    'disabled': this.ivr.prompt_file !== null,
                    'nav-link': true,
                    'active': this.ivr.prompt_file === null
                },
                play: {
                    'nav-link': true,
                    'active': this.ivr.prompt_file !== null
                }
            }
        },

        ivrPromptContainerClass() {
            return {
                read: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'disabled': this.ivr.prompt_file !== null,
                    'active': this.ivr.prompt_file === null
                },
                play: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'disabled': this.ivr.prompt_file === null,
                    'active': this.ivr.prompt_file !== null
                },
            }
        },

        closedHoursMissedCallVoicemailNavClass() {
            return {
                read: {
                    'disabled': this.campaign.closed_hours_voice_prompt.file !== null,
                    'nav-link': true,
                    'active': this.campaign.closed_hours_voice_prompt.file === null
                },
                play: {
                    'nav-link': true,
                    'active': this.campaign.closed_hours_voice_prompt.file !== null
                }
            }
        },

        closedHoursMissedCallVoicemailContainerClass() {
            return {
                read: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.campaign.closed_hours_voice_prompt.file === null
                },
                play: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.campaign.closed_hours_voice_prompt.file !== null
                },
            }
        },

        textAuthorizationNavClass() {
            return {
                read: {
                    'disabled': this.campaign.ask_for_text_authorization_file !== null,
                    'nav-link': true,
                    'active': this.campaign.ask_for_text_authorization_file === null
                },
                play: {
                    'nav-link': true,
                    'active': this.campaign.ask_for_text_authorization_file !== null
                }
            }
        },

        textAuthorizationContainerClass() {
            return {
                read: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.campaign.ask_for_text_authorization_file === null
                },
                play: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.campaign.ask_for_text_authorization_file !== null
                },
            }
        },

        whisperNavClass() {
            return {
                read: {
                    'disabled': this.campaign.whisper_file !== null,
                    'nav-link': true,
                    'active': this.campaign.whisper_file === null
                },
                play: {
                    'nav-link': true,
                    'active': this.campaign.whisper_file !== null
                }
            }
        },

        whisperContainerClass() {
            return {
                read: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.campaign.whisper_file === null
                },
                play: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.campaign.whisper_file !== null
                },
            }
        },

        voicemailNavClass() {
            return {
                read: {
                    'disabled': this.campaign.missed_calls_settings.voicemail_file !== null,
                    'nav-link': true,
                    'active': this.campaign.missed_calls_settings.voicemail_file === null
                },
                play: {
                    'nav-link': true,
                    'active': this.campaign.missed_calls_settings.voicemail_file !== null
                }
            }
        },

        voicemailContainerClass() {
            return {
                read: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.campaign.missed_calls_settings.voicemail_file === null
                },
                play: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.campaign.missed_calls_settings.voicemail_file !== null
                },
            }
        },

        businessHoursHelp() {
            return `The times below are local to your account's timezone, currently set to ${this.current_company.timezone}`
        },

        recordingSetInAccountSettings() {
            return this.current_company.inbound_call_recording_mode > 1
        },

        canSeeEmailConversations() {
            return !this.campaign.is_fax && this.current_company && !this.isSimpSocial
        },

        shouldShowLocalPresenceText() {
            return this.campaign.has_local_presence
        },

        disableOperationButtons() {
            return this.active_tab_name !== 'cnam'
        },

        canUpdateCampaign() {
            const hasPermission = this.hasPermissionTo('update campaign')

            // Supervisors can only edit their own campaigns
            return this.isSupervisor
                ? hasPermission && this.campaign?.user_id === this.auth?.user?.profile?.id
                : hasPermission
        },

        disableSendLongMessages() {
            return this.current_company.send_long_messages_as_mms || !this.canEnableSendLongMessages
        },

        sendLongMessages: {
            get() {
                return this.current_company.send_long_messages_as_mms || this.campaign?.send_long_messages_as_mms
            },
            set(value) {
                this.campaign.send_long_messages_as_mms = value
            }
        }
    },

    created() {
        this.getStatics()
    },

    mounted() {
        if (!this.hasPermissionTo('update campaign')) {
            this.goBack()
        }

        if (this.hasPermissionTo('update campaign')) {
            this.getCampaign()
        }

        let tab = this.$route.query.tab
        if (tab) {
            this.active_tab_name = tab
        }

        EventBus.$on('transfer-complete', this.onTransferComplete)

        VueEvent.listen('cnam_called_id_created', (cnam_caller_id) => {
            this.updateCnamBundle(cnam_caller_id)
        })

        VueEvent.listen('cnam_called_id_updated', (cnam_caller_id) => {
            this.updateCnamBundle(cnam_caller_id)
        })
    },

    beforeDestroy() {
        EventBus.$off('transfer-complete')
    },

    methods: {
        carrierBadgeClass(carrier) {
            switch (carrier) {
                case 'telnyx':
                    return 'telnyx-badge'
                case 'twilio':
                    return 'twilio-badge'
                case 'commio':
                    return 'commio-badge'
                default:
                    return 'badge-secondary'
            }
        },
        getUser(id) {
            if (!id) {
                return null
            }
            let found = this.users.find(user => user.id === id)
            if (found) {
                return found
            }

            return null
        },

        getUserName(user) {
            if (!user) {
                return
            }

            if (user.answer_by !== undefined) {
                switch (user.answer_by) {
                    case AnswerTypes.BY_BROWSER:
                        return user.name + ' - Browser / Apps'
                    case AnswerTypes.BY_IP_PHONE:
                        return user.name + ' - SIP (IP Phone)'
                    case AnswerTypes.BY_PHONE_NUMBER:
                        return user.name + ' - Phone Number (' + user.phone_number + ')'
                    case AnswerTypes.BY_NONE:
                        return user.name + ' - Will Not Answer'
                }
            }
            if (user.name !== '' && user.name && !user.sip_uri) {
                return user.name + ' (' + user.phone_number + ')'
            } else if (user.name !== '' && user.name && user.sip_uri) {
                return user.name + ' - SIP'
            } else if (!user.name && user.sip_uri) {
                return 'No Name - SIP'
            } else {
                return 'No Name (' + user.phone_number + ')'
            }
        },

        checkSpecialCharacter() {
            this.intake_username = this.intake_username.replace(/[!@#$%^&*]/g, '')
        },

        showIvrModal(should_create = true) {
            this.show_ivr_modal = true
            this.should_create_ivr = should_create

            if (should_create) {
                this.ivr = defaultIvr
            } else {
                this.ivr = this.ivrs.find(ivr => ivr.id === this.selected_ivr_id)
                if (typeof this.ivr.steps === 'string') {
                    this.ivr.steps = JSON.parse(this.ivr.steps)
                }
            }
        },

        beforeCloseIvrModal() {
            this.ivr = defaultIvr
            this.show_ivr_modal = false
        },

        updateIvrSteps(steps) {
            this.ivr.steps = steps
        },

        defaultCallerIdNumber(id, phone_number, ans) {
            let message = ans === true ? 'Are you sure you want to set this as default caller ID?' : 'Remove default caller ID to this number?'
            let notification = ans === true ? 'is now the default caller ID for this line' : 'is removed as the default caller ID for this line'
            this.$confirm(message, 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.loading = true
                axios.put(`/api/v1/campaign/${this.campaign_id}/incoming-number/${id}`, {'default_callerid': ans}).then(res => {
                    this.loading = false
                    this.campaign.incoming_numbers.map((item) => {
                        item.default_callerid = false
                        if (item.id == id) item.default_callerid = ans
                    })
                    this.campaign.incoming_numbers.sort((a, b) => {
                        if (a.default_callerid && !b.default_callerid) {
                            return -1
                        } else if (!a.default_callerid && b.default_callerid) {
                            return 1
                        } else {
                            return 0
                        }
                    })
                    this.$notify({
                        offset: 95,
                        title: 'Phone Number',
                        dangerouslyUseHTMLString: true,
                        message: `This number <strong>${phone_number}</strong> ${notification}.`,
                        type: 'success',
                        showClose: true,
                    })
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading = false
                })
            }).catch(() => {
            })
        },

        onTransferComplete(data) {
            const incoming_numbers = this.campaign.incoming_numbers
            this.campaign.incoming_numbers = incoming_numbers.filter(item => item.id != data.transferred_incoming_number.id)

            this.$notify({
                offset: 95,
                title: 'Phone Number',
                dangerouslyUseHTMLString: true,
                message: `This <strong>${data.transferred_incoming_number.phone_number}</strong> number is now transferred successfully.`,
                type: 'success',
                showClose: true,
            })

            if (data.transfer_type == 'existing_line') {
                this.fetchIncomingNumbers(1)
            }
        },

        doNotUseOrActivateNumber(incoming_number, ans) {
            let message = ans === false ? 'Do you want to use this number again?' : "Are you sure you don't want to use this number anymore?"
            // Add aditional message if ans is true and the line belongs to a messaging service.
            if (ans && this.lineHasMessagingService && !incoming_number.is_verified_caller_id) {
                message = `This number will not get removed from the A2P Campaign #${incoming_number.messaging_service?.id} ${this.messagingServiceFormattedUseCase(incoming_number.messaging_service?.a2p_use_case?.use_case)}. ` + message
            }
            let status = ans === false ? 'enabled' : 'disabled'
            this.$confirm(message, 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.loading = true
                axios.put(`/api/v1/campaign/${this.campaign_id}/incoming-number/${incoming_number.id}`, {'do_not_use': ans}).then(res => {
                    this.loading = false
                    this.campaign.incoming_numbers.find((item) => {
                        if (item.id == incoming_number.id) {
                            item.do_not_use = ans
                            item.default_callerid = false
                        }
                    })
                    this.$notify({
                        offset: 95,
                        title: 'Line',
                        dangerouslyUseHTMLString: true,
                        message: `Your <strong>${incoming_number.phone_number}</strong> phone number is now ${status} for this line.`,
                        type: 'success',
                        showClose: true,
                    })
                }).catch(err => {
                    this.loading = false
                    this.$root.handleErrors(err.response)
                })
            }).catch(() => {
            })
        },

        // this will verify everytime input phone number occur. The fixPhone filter is being use due to the format it's output
        verifyUnrentNumber() {
            if (this.$options.filters.fixPhone(this.verify_unrent) == this.$options.filters.fixPhone(this.to_unrent.phone_number) && this.verify_unrent != '') {
                this.confirm_unrent = false
            } else {
                this.confirm_unrent = true
            }
        },

        verifyDeleteNumber() {
            if (this.$options.filters.fixPhone(this.verify_delete) == this.$options.filters.fixPhone(this.to_delete.phone_number) && this.verify_delete != '') {
                this.confirm_delete = false
            } else {
                this.confirm_delete = true
            }
        },

        openUnrentDialog(incoming_number) {
            this.verify_unrent = ''
            this.to_unrent = incoming_number
            this.dialogUnrentNumber = true
        },

        unrentNumber(id, phone_number) {
            this.dialogUnrentNumber = false
            this.loading = true
            axios.delete(`/api/v1/campaign/${this.campaign_id}/incoming-number/${id}`).then(res => {
                const incoming_numbers = this.campaign.incoming_numbers
                this.loading = false
                this.campaign.incoming_numbers = incoming_numbers.filter(item => item.id != id)
                this.$notify({
                    offset: 95,
                    title: 'Line',
                    dangerouslyUseHTMLString: true,
                    message: `Your <strong>${phone_number}</strong> phone number has been unrented successfully.`,
                    type: 'success',
                    showClose: true,
                })

                this.fetchIncomingNumbers(1)
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading = false
            })
        },

        deleteNumber(id, phone_number) {
            this.dialogDeleteNumber = false
            this.loading = true
            axios.delete(`/api/v1/campaign/${this.campaign_id}/incoming-number/${id}`).then(res => {
                const incoming_numbers = this.campaign.incoming_numbers
                this.loading = false
                this.campaign.incoming_numbers = incoming_numbers.filter(item => item.id != id)
                this.$notify({
                    offset: 95,
                    title: 'Line',
                    dangerouslyUseHTMLString: true,
                    message: `Your <strong>${phone_number}</strong> phone number has been deleted successfully.`,
                    type: 'success',
                    showClose: true,
                })

                this.fetchIncomingNumbers(1)
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading = false
            })
        },

        openDeleteDialog(incoming_number) {
            this.verify_unrent = ''
            this.to_delete = incoming_number
            this.dialogDeleteNumber = true
        },

        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics').then(res => {
                this.statics = res.data
                this.setPageTitle('Line Settings - ' + this.statics.name)
                this.loading_whitelabel = false
            }).catch(err => {
                this.setPageTitle('Line Settings - Aloware')
                console.log(err)
                this.$root.handleErrors(err.response)
                this.loading_whitelabel = false
            })
        },

        getCampaign() {
            this.loading_campaign = true
            axios.get('/api/v1/campaign/' + this.campaign_id, {
                mode: 'no-cors',
            }).then(res => {
                this.setUpCampaign(res.data)
                this.loading_campaign = false
            }).catch(err => {
                console.log(err)
                this.$root.handleErrors(err.response)
                this.loading_campaign = false
            })
        },

        fixOperatingHours() {
            this.loading_operating_hours = true
            // fixes null issue
            for (let day of ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']) {
                for (let item in this.campaign.operating_hours[day]) {
                    if (!this.campaign.operating_hours[day][item].open) {
                        this.campaign.operating_hours[day][item].open = ''
                    }

                    if (!this.campaign.operating_hours[day][item].close) {
                        this.campaign.operating_hours[day][item].close = ''
                    }

                    if (this.campaign.operating_hours[day][item].isOpen && this.campaign.operating_hours[day][item].open === '') {
                        this.campaign.operating_hours[day][item].open = '24hrs'
                    }

                    if (this.campaign.operating_hours[day][item].isOpen && this.campaign.operating_hours[day][item].close === '') {
                        this.campaign.operating_hours[day][item].close = '24hrs'
                    }

                    if (this.campaign.operating_hours[day][item].open != '24hrs' || this.campaign.operating_hours[day][item].close != '24hrs') {
                        this.show_operating_hours = true
                    }
                }
            }

            // fixes the order of weekdays
            this.campaign.operating_hours = {
                monday: this.campaign.operating_hours.monday,
                tuesday: this.campaign.operating_hours.tuesday,
                wednesday: this.campaign.operating_hours.wednesday,
                thursday: this.campaign.operating_hours.thursday,
                friday: this.campaign.operating_hours.friday,
                saturday: this.campaign.operating_hours.saturday,
                sunday: this.campaign.operating_hours.sunday,
            }

            this.loading_operating_hours = false
        },

        fixClosedHoursMissedCallVoicemail() {
            if (!this.campaign.closed_hours_voice_prompt) {
                this.campaign.closed_hours_voice_prompt = {}
            }

            // fix all null issues
            if (!this.campaign.closed_hours_voice_prompt.enabled) {
                this.campaign.closed_hours_voice_prompt.enabled = false
            }
            if (!this.campaign.closed_hours_voice_prompt.tts) {
                this.campaign.closed_hours_voice_prompt.tts = null
            }
            if (!this.campaign.closed_hours_voice_prompt.file) {
                this.campaign.closed_hours_voice_prompt.file = null
            }
        },

        fixClosedHoursMissedTextAutoReply() {
            if (!this.campaign.closed_hours_auto_reply_text) {
                this.campaign.closed_hours_auto_reply_text = {}
            }

            // fix all null issues
            if (!this.campaign.closed_hours_auto_reply_text.enabled) {
                this.campaign.closed_hours_auto_reply_text.enabled = false
            }
            if (!this.campaign.closed_hours_auto_reply_text.message) {
                this.campaign.closed_hours_auto_reply_text.message = null
            }
        },

        setUpCampaign(data) {
            this.campaign = data
            this.campaign.caller_id_option = parseInt(data.caller_id_option) === 1
            this.campaign.call_router_behavior = parseInt(data.call_router_behavior)

            if (typeof this.campaign.operating_hours === 'string') {
                this.campaign.operating_hours = JSON.parse(this.campaign.operating_hours)
                this.fixOperatingHours()
            }

            if (typeof this.campaign.closed_hours_voice_prompt === 'string') {
                this.campaign.closed_hours_voice_prompt = JSON.parse(this.campaign.closed_hours_voice_prompt)
                this.fixClosedHoursMissedCallVoicemail()
            }

            if (typeof this.campaign.closed_hours_auto_reply_text === 'string') {
                this.campaign.closed_hours_auto_reply_text = JSON.parse(this.campaign.closed_hours_auto_reply_text)
                this.fixClosedHoursMissedTextAutoReply()
            }

            if (typeof this.campaign.triggers === 'string') {
                this.campaign.triggers = JSON.parse(this.campaign.triggers)
            } else {
                this.campaign.triggers = []
            }

            if (!this.campaign.missed_calls_settings.voicemail_tts) {
                this.campaign.missed_calls_settings.voicemail_tts = 'We are sorry, but no agents are available to take this call. Please leave your message after the beep.'
            }

            if (!this.campaign.ask_for_text_authorization_tts) {
                this.campaign.ask_for_text_authorization_tts = 'Press 1 for text message authorization.'
            }

            if (this.campaign.ring_group_id) {
                this.to_ring_group = true
            } else if (this.campaign.user_id) {
                this.to_user = true
            } else if (this.campaign.call_router_behavior === CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_IVR) {
                this.to_ivr = true
            } else if (this.campaign.call_router_behavior === CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_ALOAI) {
                this.to_aloai = true
            } else if (this.campaign.call_router_behavior === CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_DEAD_END) {
                this.to_dead_end = true
            }

            if (this.campaign.sms_router_behavior === CampaignSmsRouterBehavior.SMS_ROUTER_BEHAVIOR_DEFAULT) {
                this.to_default = true
            } else if (this.campaign.sms_router_behavior === CampaignSmsRouterBehavior.SMS_ROUTER_BEHAVIOR_MODE_ALOAI) {
                this.to_aloai_text_bot = true
            }

            if (!!this.campaign.call_waiting_ring_group_id) {
                this.has_call_waiting = true
            }

            this.has_csat = !!this.campaign.csat_id
            this.campaign.has_csat = this.has_csat

            if (!this.has_csat) {
                this.campaign.csat = {
                  'preamble_tts': 'As part of our commitment to improving our services, we will be conducting a post-call survey to gather your thoughts and opinions.',
                  'prompt_tts': 'Now that our conversation is coming to an end, we kindly ask for a few moments of your time to complete a quick survey.',
                  'outro_tts': 'We appreciate your willingness to participate in the survey. Your feedback is important to us.',
                }
            } else {
                const step = this.campaign.csat.steps.find(step => step.task === 'csat')
                this.campaign.csat = {
                  'outro_tts': step.message_tts,
                  'outro_file': step.message_file,
                  ...this.campaign.csat,
                }
            }

            if (this.campaign.ring_group_id) {
                this.editingCallRingGroup = this.ring_groups.find(ring_group => ring_group.id == this.campaign.ring_group_id)
            }

            if (this.campaign.email_intake) {
                this.intake_username = this.campaign.email_intake.split('@')[0]
            }

            this.campaign_name = this.campaign.name
            this.fetchIncomingNumbers(1)

            VueEvent.fire('page_title_updated', this.campaign_name)

            Vue.nextTick(() => {
                this.preValidateForm('campaign_settings')
            })
        },

        async fetchIncomingNumbers(page = 1) {
            this.incoming_numbers_loading = true
            axios.get(`/api/v1/campaign/${this.campaign.id}/incoming-number`, {
                params: {
                    page: page,
                    search: this.incoming_numbers_search,
                }
            }).then(res => {
                this.campaign.incoming_numbers = res.data.data.sort((a, b) => {
                    if (a.default_callerid && !b.default_callerid) {
                        return -1
                    } else if (!a.default_callerid && b.default_callerid) {
                        return 1
                    } else {
                        return 0
                    }
                })
                this.setLineCapabilityIconColors(res.data.data)
                this.canEnableSendLongMessagesAsMms(res.data.data)
                this.incoming_numbers_pagination = res.data
                this.incoming_numbers_loading = false
                this.incoming_numbers_key++
            }).catch(err => {
                this.incoming_numbers_loading = false
            })
        },

        submitForm: function (form_name) {
            this.resetTabErrors()
            this.closeErrorNotification()

            if (this.validateForm(form_name) === true) {
                this.editCampaign()
            } else {
                this.error_notification = this.$notify({
                    offset: 95,
                    title: 'Line',
                    dangerouslyUseHTMLString: true,
                    message: this.getFormErrorsMessage(form_name),
                    type: 'error',
                    showClose: true,
                    duration: 0
                })

                return false
            }
        },

        getFormErrorsMessage(form_name) {
            let form_element = _.get(this.$refs, form_name, null)
            let err_message = '<div class="mb-2">Please resolve line configuration errors</div>'

            form_element.fields.forEach(element => {
                if (element.validateState === 'error') {
                    // get the parent tab pane of the element
                    let parent = element.$parent

                    while (parent && parent.$options._componentTag !== 'el-tab-pane') {
                        parent = parent.$parent
                    }

                    const tab = parent.$attrs['data-tab-error'] || parent.name.replace('-', '_')
                    const tab_label = parent.$slots.label[0].children[0].text

                    // show the error icon on the element's parent tab pane
                    if (tab !== '') {
                        this.tab_errors[tab] = true
                    }

                    err_message += `<div class="mb-2"><i class="fa fa-remove text-danger mr-2"></i> ${tab_label} > ${element.validateMessage}</div>`
                }
            })

            return err_message
        },

        tabLabelErrorIcon(tab_error) {
            if (tab_error) {
                return ' <i class="fa fa-exclamation-circle text-danger ml-1"></i>'
            }

            return ''
        },

        resetTabErrors() {
            this.tab_errors = {
                general_settings: false,
                ivr_settings: false,
                routing_settings: false,
                advanced_settings: false,
                triggers: false,
                email_conversations: false,
            }
        },

        closeErrorNotification() {
            if (this.error_notification) {
                this.error_notification.close()
            }
        },

        submitIvr() {
            this.ivr_button_loading = true
            let exit_step = this.ivr.steps.find(ivr_step => ivr_step.type && ivr_step.type === 'exit')

            if (!this.ivr || !exit_step || !exit_step.valid) {
                this.loading = false
                this.$notify({
                    offset: 95,
                    title: 'Line',
                    dangerouslyUseHTMLString: true,
                    message: 'Please resolve IVR exit configuration errors',
                    type: 'error',
                    showClose: true,
                })
                return
            }

            this.ivr.steps = this.ivr.steps.filter(ivr_step => ivr_step.valid === true).sort((a, b) => {
                let textA = a.key
                let textB = b.key
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
            })

            let path = `/api/v1/campaign/${this.campaign.id}/ivr/${this.ivr.id}/update`
            if (this.should_create_ivr) {
                path = `/api/v1/campaign/${this.campaign.id}/ivr/create`
            }

            axios.post(path, this.ivr).then(res => {
                this.ivr_button_loading = false
                this.show_ivr_modal = false
                if (this.should_create_ivr) {
                    this.newIvr(res.data)
                    this.selected_ivr_id = res.data.id
                }
                this.$notify({
                    offset: 95,
                    title: 'IVR',
                    message: 'Successfully submitted.',
                    type: 'success',
                    showClose: true
                })
            }).catch(err => {
                this.ivr_button_loading = false
                this.$root.handleErrors(err.response)
            })
        },

        editCampaign: function () {
            this.loading = true
            this.fixOperatingHours()
            this.campaign.triggers = this.campaign.triggers.filter(trigger => trigger.valid === true)

            this.campaign.caller_id_option = this.campaign.caller_id_option === true ? 1 : 0
            this.campaign.has_call_waiting = this.has_call_waiting
            this.campaign.has_csat = this.has_csat
            this.campaign.intake_username = this.intake_username.trim()

            if (this.impersonated) {
                this.campaign.admin_api_token = localStorage.getItem('admin_api_token')
            }

            axios.patch('/api/v1/campaign/' + this.campaign.id, this.campaign).then(res => {
                this.$notify({
                    offset: 95,
                    title: 'Line',
                    message: 'Your line has been updated successfully.',
                    type: 'success',
                    showClose: true,
                })
                this.updateCampaign(res.data)
                this.setUpCampaign(res.data)
                this.loading = false
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading = false
            })
        },

        goBack: function () {
            this.$router.go(-1)
        },

        onSuccessRecord(res) {
            this.$notify({
                offset: 95,
                title: 'Line',
                message: this.audio_file_messages.record_file,
                type: 'success',
                showClose: true,
            })
            this.$set(this.campaign, 'record_file', res.file_name)
            this.uploadStatus.record = 'success'
            this.uploadPercentage.record = 0
            this.preValidateForm('campaign_settings')
        },

        onFailedRecord(err) {
            this.handleUploadErrors(err.message)
            this.uploadStatus.record = 'exception'
            this.uploadPercentage.record = 0
            this.preValidateForm('campaign_settings')
        },

        beforeUploadRecord() {
            this.uploadStatus.record = 'success'
            this.uploadPercentage.record = 0
        },

        progressUploadRecord(event) {
            this.uploadPercentage.record = parseInt(event.percent)
        },

        onSuccessGreeting(res) {
            this.$notify({
                offset: 95,
                title: 'Line',
                message: this.audio_file_messages.greeting_file,
                type: 'success',
                showClose: true,
            })
            this.$set(this.campaign, 'greeting_file', res.file_name)
            this.uploadStatus.greeting = 'success'
            this.uploadPercentage.greeting = 0
            this.preValidateForm('campaign_settings')
        },

        onFailedGreeting(err) {
            this.handleUploadErrors(err.message)
            this.uploadStatus.greeting = 'exception'
            this.uploadPercentage.greeting = 0
            this.preValidateForm('campaign_settings')
        },

        beforeUploadGreeting() {
            this.uploadStatus.greeting = 'success'
            this.uploadPercentage.greeting = 0
        },

        progressUploadGreeting(event) {
            this.uploadPercentage.greeting = parseInt(event.percent)
        },

        onSuccessIvrPrompt(res) {
            this.$notify({
                offset: 95,
                title: 'Line',
                message: this.audio_file_messages.ivr_prompt_file,
                type: 'success',
                showClose: true,
            })
            this.$set(this.ivr, 'prompt_file', res.file_name)
            this.uploadStatus.ivr_prompt = 'success'
            this.uploadPercentage.ivr_prompt = 0
            this.preValidateForm('campaign_settings')
            this.newIvrPromptFile(res)
        },

        onFailedIvrPrompt(err) {
            this.handleUploadErrors(err.message)
            this.uploadStatus.ivr_prompt = 'exception'
            this.uploadPercentage.ivr_prompt = 0
            this.preValidateForm('campaign_settings')
        },

        beforeUploadIvrPrompt() {
            this.uploadStatus.ivr_prompt = 'success'
            this.uploadPercentage.ivr_prompt = 0
        },

        progressUploadIvrPrompt(event) {
            this.uploadPercentage.ivr_prompt = parseInt(event.percent)
        },

        onSuccessClosedHoursMissedCallVoicemail(res) {
            this.$notify({
                offset: 95,
                title: 'Ring Group',
                message: this.audio_file_messages.closed_hours_voice_prompt_file,
                type: 'success',
                showClose: true,
            })
            this.$set(this.campaign.closed_hours_voice_prompt, 'file', res.file_name)
            this.uploadStatus.closed_hours_voice_prompt = 'success'
            this.uploadPercentage.closed_hours_voice_prompt = 0
            this.preValidateForm('campaign_settings')
        },

        onFailedClosedHoursMissedCallVoicemail(err) {
            this.handleUploadErrors(err.message)
            this.uploadStatus.closed_hours_voice_prompt = 'exception'
            this.uploadPercentage.closed_hours_voice_prompt = 0
            this.preValidateForm('campaign_settings')
        },

        beforeUploadClosedHoursMissedCallVoicemail() {
            this.uploadStatus.closed_hours_voice_prompt = 'success'
            this.uploadPercentage.closed_hours_voice_prompt = 0
        },

        progressUploadClosedHoursMissedCallVoicemail(event) {
            this.uploadPercentage.closed_hours_voice_prompt = parseInt(event.percent)
        },

        onSuccessTextAuthorization(res) {
            this.$notify({
                offset: 95,
                title: 'Ring Group',
                message: this.audio_file_messages.ask_for_text_authorization_file,
                type: 'success',
                showClose: true,
            })
            this.$set(this.campaign, 'ask_for_text_authorization_file', res.file_name)
            this.uploadStatus.askForTextAuthorization = 'success'
            this.uploadPercentage.askForTextAuthorization = 0
            this.preValidateForm('campaign_settings')
        },

        onFailedTextAuthorization(err) {
            this.handleUploadErrors(err.message)
            this.uploadStatus.askForTextAuthorization = 'exception'
            this.uploadPercentage.askForTextAuthorization = 0
            this.preValidateForm('campaign_settings')
        },

        beforeUploadTextAuthorization() {
            this.uploadStatus.askForTextAuthorization = 'success'
            this.uploadPercentage.askForTextAuthorization = 0
        },

        progressUploadTextAuthorization(event) {
            this.uploadPercentage.askForTextAuthorization = parseInt(event.percent)
        },

        onSuccessWhisper(res) {
            this.$notify({
                offset: 95,
                title: 'Line',
                message: this.audio_file_messages.whisper_file,
                type: 'success',
                showClose: true,
            })
            this.$set(this.campaign, 'whisper_file', res.file_name)
            this.uploadStatus.whisper = 'success'
            this.uploadPercentage.whisper = 0
            this.preValidateForm('campaign_settings')
        },

        onFailedWhisper(err) {
            this.handleUploadErrors(err.message)
            this.uploadStatus.whisper = 'exception'
            this.uploadPercentage.whisper = 0
            this.preValidateForm('campaign_settings')
        },

        beforeUploadWhisper() {
            this.uploadStatus.whisper = 'success'
            this.uploadPercentage.whisper = 0
        },

        progressUploadWhisper(event) {
            this.uploadPercentage.whisper = parseInt(event.percent)
        },

        onSuccessCsatPreamble(res){
            this.$set(this.campaign.csat, 'preamble_file', res)
            this.preValidateForm('campaign_settings')
        },

        onSuccessCsatPrompt(res){
            this.$set(this.campaign.csat, 'prompt_file', res)
            this.preValidateForm('campaign_settings')
        },

        onSuccessCsatOutro(res){
            this.$set(this.campaign.csat, 'outro_file', res)
            this.preValidateForm('campaign_settings')
        },

        onSuccessVoicemail(res) {
            this.$notify({
                offset: 95,
                title: 'Line',
                message: this.audio_file_messages.voicemail_file,
                type: 'success',
                showClose: true,
            })
            this.$set(this.campaign.missed_calls_settings, 'voicemail_file', res.file_name)
            this.uploadStatus.whisper = 'success'
            this.uploadPercentage.voicemail = 0
            this.preValidateForm('campaign_settings')
        },

        onFailedVoicemail(err) {
            this.handleUploadErrors(err.message)
            this.uploadStatus.voicemail = 'exception'
            this.uploadPercentage.voicemail = 0
            this.preValidateForm('campaign_settings')
        },

        beforeUploadVoicemail() {
            this.uploadStatus.voicemail = 'success'
            this.uploadPercentage.voicemail = 0
        },

        progressUploadVoicemail(event) {
            this.uploadPercentage.voicemail = parseInt(event.percent)
        },

        deleteFile(type) {
            const params = {}
            if (this.ivr.id) {
                params.ivr_id = this.ivr.id
            }
            switch (type) {
                case 'recording_notices':
                    this.loading_recording = true
                    break
                case 'greetings':
                    this.loading_greeting = true
                    break
                case 'text_authorizations':
                    this.loading_ask_for_text_authorization = true
                    break
                case 'whispers':
                    this.loading_whisper = true
                    break
                case 'voicemails':
                    this.loading_voicemail = true
                    break
                case 'closed_hours_voice_prompts':
                    this.loading_closed_hours_voice_prompt = true
                    break
                case 'ivr_prompts':
                    this.loading_ivr_prompt = true
                    break
            }

            axios.delete('/api/v1/campaign/' + this.campaign.id + '/delete/' + type, {params})
                .then(res => {
                    let message = ''
                    switch (type) {
                        case 'recording_notices':
                            this.uploadPercentage.record = 0
                            this.uploadStatus.record = 'success'
                            this.uploadFileList.record = []
                            this.loading_recording = false
                            this.campaign.record_file = null
                            message = 'Recording notice file deleted successfully'
                            break
                        case 'greetings':
                            this.uploadPercentage.greeting = 0
                            this.uploadStatus.greeting = 'success'
                            this.uploadFileList.greeting = []
                            this.loading_greeting = false
                            this.campaign.greeting_file = null
                            message = 'Greeting file deleted successfully'
                            break
                        case 'closed_hours_voice_prompts':
                            this.uploadPercentage.closed_hours_voice_prompt = 0
                            this.uploadStatus.closed_hours_voice_prompt = 'success'
                            this.uploadFileList.closed_hours_voice_prompt = []
                            this.loading_closed_hours_voice_prompt = false
                            this.campaign.closed_hours_voice_prompt.file = null
                            message = 'Closed hours missed call voicemail file deleted successfully'
                            break
                        case 'text_authorizations':
                            this.uploadPercentage.askForTextAuthorization = 0
                            this.uploadStatus.askForTextAuthorization = 'success'
                            this.uploadFileList.askForTextAuthorization = []
                            this.loading_ask_for_text_authorization = false
                            this.campaign.ask_for_text_authorization_file = null
                            message = 'Text authorization file deleted successfully'
                            break
                        case 'whispers':
                            this.uploadPercentage.whisper = 0
                            this.uploadStatus.whisper = 'success'
                            this.uploadFileList.whisper = []
                            this.loading_whisper = false
                            this.campaign.whisper_file = null
                            message = 'Whisper file deleted successfully'
                            break
                        case 'voicemails':
                            this.uploadPercentage.voicemail = 0
                            this.uploadStatus.voicemail = 'success'
                            this.uploadFileList.voicemail = []
                            this.loading_voicemail = false
                            this.campaign.missed_calls_settings.voicemail_file = null
                            message = 'Voicemail file deleted successfully'
                            break
                        case 'ivr_prompts':
                            this.uploadPercentage.ivr_prompt = 0
                            this.uploadStatus.ivr_prompt = 'success'
                            this.uploadFileList.ivr_prompt = []
                            this.loading_ivr_prompt = false
                            this.ivr.prompt_file = null
                            message = 'IVR prompt file deleted successfully'
                            break
                    }
                    this.$notify({
                        offset: 95,
                        title: 'Line',
                        message: message,
                        type: 'success',
                        showClose: true,
                    })
                }).catch(err => {
                switch (type) {
                    case 'recording_notices':
                        this.loading_recording = false
                        this.uploadPercentage.record = 0
                        break
                    case 'greetings':
                        this.loading_greeting = false
                        this.uploadPercentage.greeting = 0
                        break
                    case 'ivr_prompts':
                        this.loading_ivr_prompt = false
                        this.uploadPercentage.ivr_prompt = 0
                    case 'closed_hours_voice_prompts':
                        this.uploadPercentage.closed_hours_voice_prompt = 0
                        this.loading_closed_hours_voice_prompt = false
                        break
                    case 'text_authorizations':
                        this.loading_ask_for_text_authorization = false
                        this.uploadPercentage.askForTextAuthorization = 0
                        break
                    case 'whispers':
                        this.loading_whisper = false
                        this.uploadPercentage.whisper = 0
                        break
                    case 'voicemails':
                        this.loading_voicemail = false
                        this.uploadPercentage.voicemail = 0
                        break
                }
                this.$root.handleErrors(err.response)
            })
        },

        useIvrPromptFile(ivrPromptFile) {
            this.show_ivr_prompt_files = false
            this.ivr.prompt_file = null // set to null so the audio element will reload
            setTimeout(() => {
                this.ivr.prompt_file = ivrPromptFile.uuid
                this.show_ivr_prompt_files = true
            }, 100)
        },

        renameIvrPromptFile(ivrPromptFile) {
            this.ivr_prompt_file_rename = ivrPromptFile.id
            axios.post(`/api/v1/campaign/ivr-prompt-file/${ivrPromptFile.id}/rename`, {name: ivrPromptFile.name})
                .then(() => {
                    this.updateIvrPromptFile(ivrPromptFile)
                    this.ivr_prompt_file_rename = null
                })
                .catch((err) => {
                    this.$root.handleErrors(err.response)
                    this.ivr_prompt_file_rename = null
                })
        },

        handleUploadErrors(error) {
            if (typeof error === 'string') {
                error = JSON.parse(error)
            }
            let err
            if (error.message === 'This action is unauthorized.') {
                err = {
                    status: 403,
                }
            } else {
                err = {
                    status: 422,
                    data: {
                        errors: error.errors.file
                    }
                }
            }

            this.$root.handleErrors(err)
        },

        checkRouting: _.debounce(function (reset = false) {
            if (!this.to_ring_group && !this.to_user && !this.to_dead_end && !this.to_aloai && !this.to_ivr) {
                this.to_ring_group = true
            }
        }, 100),

        changeUser(id) {
            this.campaign.user_id = id
            this.campaign.ring_group_id = null
            this.campaign.ivr_id = null
            this.preValidateForm('campaign_settings')
        },

        changeIvr(id) {
            this.selected_ivr_id = id
            this.campaign.ivr_id = id
            this.campaign.user_id = null
            this.campaign.ring_group_id = null
            this.preValidateForm('campaign_settings')
        },

        changeCallRingGroup(id) {
            this.campaign.ring_group_id = id
            this.campaign.user_id = null
            if (this.campaign.ring_group_id) {
                this.editingCallRingGroup = this.ring_groups.find(ring_group => ring_group.id == this.campaign.ring_group_id)
            } else {
                this.editingCallRingGroup = null
            }
            this.preValidateForm('campaign_settings')
        },

        changeTextRingGroup(id) {
            this.ivr.text_ring_group_id = id
            if (this.ivr.text_ring_group_id) {
                this.editingTextRingGroup = this.ring_groups.find(ring_group => ring_group.id === this.ivr.text_ring_group_id)
            } else {
                this.editingTextRingGroup = null
            }
            this.preValidateForm('campaign_settings')
        },

        changeLineAloAiBot(aloai_bot_id) {
            this.campaign.aloai_bot_id = aloai_bot_id
            this.campaign.user_id = null
            this.campaign.ring_group_id = null
            this.campaign.ivr_id = null

            if (aloai_bot_id) {
                this.preValidateForm('campaign_settings')
            }
        },

        toggleDefaultRouting(value) {
            this.to_default = value

            // Reset other sms routing options
            if (value) {
                this.campaign.sms_router_behavior = CampaignSmsRouterBehavior.SMS_ROUTER_BEHAVIOR_DEFAULT
                this.toggleAloAiTextBot(false)
            }

            this.preValidateForm('campaign_settings')
        },

        toggleAloAiTextBot(value) {
            this.to_aloai_text_bot = value

            if (!value) {
                this.campaign.aloai_text_bot_id = null
            } else {
                this.campaign.sms_router_behavior = CampaignSmsRouterBehavior.SMS_ROUTER_BEHAVIOR_MODE_ALOAI
                this.toggleDefaultRouting(false)
            }

            this.preValidateForm('campaign_settings')
        },

        changeLineAloAiTextBot(aloai_bot_id) {
            this.campaign.aloai_text_bot_id = aloai_bot_id

            if (aloai_bot_id) {
                this.preValidateForm('campaign_settings')
            }
        },

        toggleActive() {
            let new_state = this.campaign.active == true ? 'pause' : 'activate'
            this.$confirm('This will ' + new_state + ' the line. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.loading_btn = true
                axios.post('/api/v1/campaign/' + this.campaign.id + '/toggle-active').then(res => {
                    this.updateCampaign(res.data)
                    this.setUpCampaign(res.data)
                    this.loading_btn = false
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading_btn = false
                })
            }).catch(() => {

            })
        },

        changeRerouteToCampaign(id) {
            this.campaign.missed_calls_settings.reroute_to_campaign_id = id
            this.preValidateForm('campaign_settings')
        },

        deleteCampaignRemote() {
            this.$confirm('Deleting a line will remove it from all reports, plots and deletes all communication data. Furthermore, the phone numbers associated with this line will be released and cannot be re-rented. If you want to temporarily stop a campaign, use the pause button. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.loading_delete = true
                axios.delete('/api/v1/campaign/' + this.campaign.id).then(res => {
                    this.deleteCampaign(this.campaign)
                    this.loading_delete = false
                    this.$notify({
                        offset: 95,
                        title: 'Line',
                        message: 'Line has been deleted.',
                        type: 'success',
                        showClose: true
                    })
                    this.$router.push({
                        name: 'Lines'
                    })
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading_delete = false
                })
            })
        },

        addedIncomingNumberToCampaign(campaign) {
            this.updateCampaign(campaign)
            this.setUpCampaign(campaign)
            this.closeModal()
            this.$notify({
                offset: 95,
                title: 'Line',
                dangerouslyUseHTMLString: true,
                message: 'New phone number has been added to the line.',
                type: 'success',
                showClose: true,
            })
        },

        openModal() {
            this.dialogVisible = true
            this.numberAdded = false
        },

        closeModal() {
            this.dialogVisible = false
            this.numberAdded = false
            setTimeout(() => {
                this.prop_counter += 1
            }, 200)
        },

        closeCallerIdModal() {
            this.dialogCallerIdVisible = false
            setTimeout(() => {
                this.prop_counter += 1
            }, 200)
        },

        newCallerIdAddedToCampaign() {
            this.show_mask_button = true
            this.fetchIncomingNumbers(1)
        },

        beforeCloseModal(done) {
            if (!this.numberAdded) {
                this.$confirm('Are you sure you want to leave? Your have not added a number yet', 'Warning', {
                    confirmButtonText: 'Yes, Leave',
                    cancelButtonText: 'No, Stay',
                    customClass: 'width-500 fixed',
                    type: 'warning'
                }).then(res => {
                    done()
                }).catch(err => {
                    console.log(err)
                })
            } else {
                done()
            }
        },

        beforeCloseCallerIdModal(done) {
            if (!this.numberAdded) {
                this.$confirm('Are you sure you want to leave? Your have not added a caller ID yet', 'Warning', {
                    confirmButtonText: 'Yes, Leave',
                    cancelButtonText: 'No, Stay',
                    customClass: 'width-500 fixed',
                    type: 'warning'
                }).then(res => {
                    done()
                }).catch(err => {
                    console.log(err)
                })
            } else {
                done()
            }
        },

        changedMissedCallHandlingMode() {
            this.campaign.missed_calls_settings.reroute_to_campaign_id = null
            this.campaign.missed_calls_settings.forward_to = null
            this.campaign.missed_calls_settings.aloai_bot_id = null
        },

        applyUploadedAudio(data) {
            const setValues = (type, data) => {
                switch (type) {
                    case 'closed_hours_voice_prompt_file':
                        this.$set(this.campaign.closed_hours_voice_prompt, 'file', data)
                        break
                    case 'voicemail_file':
                        this.$set(this.campaign.missed_calls_settings, type, data)
                        break
                    case 'ivr_prompt_file':
                        this.$set(this.ivr, 'prompt_file', data)
                        break
                    case 'preamble_file':
                    case 'prompt_file':
                    case 'outro_file':
                        this.$set(this.campaign.csat, type, data)
                        break
                    default:
                        this.$set(this.campaign, type, data)
                        break
                }
            }
            setValues(data.upload_type, null)
            setTimeout(() => {
                this.$notify({
                    offset: 95,
                    title: 'Campaign',
                    message: this.audio_file_messages[data.upload_type],
                    type: 'success',
                    showClose: true,
                })
                setValues(data.upload_type, data.uid)
                if (data.upload_type === 'ivr_prompt_file') {
                    this.newIvrPromptFile(data)
                }
                this.preValidateForm('campaign_settings')
            }, 200)
        },

        tabClicked(tab) {
            this.$router.replace({
                name: 'Line Settings',
                params: {
                    campaign_id: this.campaign.id
                },
                query: {
                    tab: tab.name
                }
            }).catch(err => {

            })

            if (tab.name === 'audit') {
                this.$refs.auditTab?.update()
            }
        },

        loadRoutingIvr() {
            this.active_tab_name = 'routing-settings'
        },

        updateValidation(valid) {
            this.validated = valid
        },

        setLineCapabilityIconColors(incoming_numbers) {
            if (incoming_numbers) {
                incoming_numbers.forEach(number => {
                    this.line_capability_icon_colors[number.id] = {
                        'fax': (this.campaign.is_fax && number.is_fax_capable) ? 'text-deep-purple-500' : 'text-grey-200',
                        'sms': (this.campaign.is_fax || !number.is_sms_capable) ? 'text-grey-200' : 'text-blue',
                        'mms': (this.campaign.is_fax || !number.is_mms_capable) ? 'text-grey-200' : 'text-orangish',
                        'voice': (this.campaign.is_fax || !number.is_voice_capable) ? 'text-grey-200' : 'text-greenish'
                    }
                })
            }
        },

        searchIncomingNumbers: _.debounce(function () {
            this.fetchIncomingNumbers(1)
        }, 500),

        phoneNumberTypes(incoming_number) {
            const types = [
                {
                    order: 1,
                    label: 'Local',
                    field: 'is_local',
                    class: 'deep-orange'
                },
                {
                    order: 2,
                    label: 'Toll Free',
                    field: 'is_tollfree',
                    class: 'blue'
                },
                {
                    order: 3,
                    label: 'Local Presence',
                    field: 'is_lp',
                    class: 'brown'
                },
                {
                    order: 4,
                    label: 'Short Code',
                    field: 'is_short_code',
                    class: 'blue-grey'
                },
                {
                    order: 5,
                    label: 'International Local',
                    field: 'is_international_local',
                    class: 'orange'
                },
                {
                    order: 6,
                    label: 'International Mobile',
                    field: 'is_international_mobile',
                    class: 'amber'
                }
            ]

            return types.filter(type => incoming_number[type.field])
        },

        updateCnamBundle(cnam_caller_id) {
            if (+cnam_caller_id.campaign_id === +this.campaign_id) {
                this.campaign.cnam_caller_id = cnam_caller_id
            }
        },

        removeCNAM() {
            this.campaign.cnam_caller_id = null
        },

        isFakeNumber(incoming_number) {
            return incoming_number.sid && incoming_number.sid.includes('fake-')
        },

        canEnableSendLongMessagesAsMms(incomingNumbers) {
            this.canEnableSendLongMessages = incomingNumbers?.some(number => number.is_mms_capable) || false
        },

        changeMissedCallAloAiBot(aloai_bot_id) {
            this.campaign.missed_calls_settings.aloai_bot_id = aloai_bot_id

            if (aloai_bot_id) {
                this.preValidateForm('campaign_settings')
            }
        },

        ...mapActions(['newIvr', 'newIvrPromptFile', 'updateIvrPromptFile', 'updateCampaign', 'deleteCampaign'])
    },

    watch: {
        campaign_id: function (value) {
            this.getCampaign(value)
        },

        show_operating_hours: function () {
            if (!this.show_operating_hours) {
                this.campaign.operating_hours = JSON.parse('{ "sunday":[ { "open":"24hrs", "close":"24hrs", "id":"7", "isOpen":true } ], "monday":[ { "open":"24hrs", "close":"24hrs", "id":"1", "isOpen":true } ], "tuesday":[ { "open":"24hrs", "close":"24hrs", "id":"2", "isOpen":true } ], "wednesday":[ { "open":"24hrs", "close":"24hrs", "id":"3", "isOpen":true } ], "thursday":[ { "open":"24hrs", "close":"24hrs", "id":"4", "isOpen":true } ], "friday":[ { "open":"24hrs", "close":"24hrs", "id":"5", "isOpen":true } ], "saturday":[ { "open":"24hrs", "close":"24hrs", "id":"6", "isOpen":true } ] }')
                this.fixOperatingHours()
            }
        },

        to_ring_group: function () {
            if (this.to_ring_group) {
                this.campaign.call_router_behavior = CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD
                this.campaign.user_id = null
                this.campaign.ivr_text_ring_group_id = null
                this.to_user = false
                this.to_ivr = false
                this.to_aloai = false
                this.to_dead_end = false
            }
            this.checkRouting()
            this.preValidateForm('campaign_settings')
        },

        to_user: function () {
            if (this.to_user) {
                this.campaign.call_router_behavior = CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD
                this.campaign.ring_group_id = null
                this.campaign.ivr_text_ring_group_id = null
                this.to_ring_group = false
                this.to_ivr = false
                this.to_aloai = false
                this.to_dead_end = false
            }
            this.checkRouting()
            this.preValidateForm('campaign_settings')
        },

        to_ivr: function () {
            if (this.to_ivr) {
                // we should also retrieve the ivr text ring group id
                // when switching to ivr prompt
                this.selected_ivr_id = this.campaign.ivr_id
                const current_campaign = this.campaigns ? this.campaigns.find(line => line.id == this.campaign_id) : null
                this.campaign.ivr_text_ring_group_id = current_campaign ? current_campaign.ivr_text_ring_group_id : null

                this.campaign.call_router_behavior = CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_IVR
                this.campaign.ring_group_id = null
                this.campaign.user_id = null
                this.to_user = false
                this.to_ring_group = false
                this.to_aloai = false
                this.to_dead_end = false
            }
            this.checkRouting()
            this.preValidateForm('campaign_settings')
        },

        to_aloai: function () {
            if (this.to_aloai) {
                this.campaign.call_router_behavior = CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_ALOAI
                this.campaign.ring_group_id = null
                this.campaign.ivr_text_ring_group_id = null
                this.campaign.user_id = null
                this.to_user = false
                this.to_ring_group = false
                this.to_ivr = false
                this.to_dead_end = false
            } else {
                this.campaign.aloai_bot_id = null
            }
            this.checkRouting()
            this.preValidateForm('campaign_settings')
        },

        to_dead_end: function () {
            if (this.to_dead_end) {
                this.campaign.call_router_behavior = CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_DEAD_END
                this.campaign.ring_group_id = null
                this.campaign.ivr_text_ring_group_id = null
                this.campaign.user_id = null
                this.to_user = false
                this.to_ring_group = false
                this.to_ivr = false
                this.to_aloai = false
            }
            this.checkRouting()
            this.preValidateForm('campaign_settings')
        },

        has_csat: function (value) {
            this.campaign.has_csat = value
        },

        'campaign.call_router_behavior': function () {
            this.preValidateForm('campaign_settings')
        },

        'campaign.should_record': function () {
            this.preValidateForm('campaign_settings')
        },

        'campaign.should_greet': function () {
            this.preValidateForm('campaign_settings')
        },

        'campaign.should_ask_for_text_authorization': function () {
            this.preValidateForm('campaign_settings')
        },

        'campaign.should_whisper': function () {
            this.preValidateForm('campaign_settings')
        },

        'campaign.should_message_if_missed': function () {
            if (this.campaign.should_message_if_missed != true) {
                this.campaign.missed_call_message = null
            }
            this.preValidateForm('campaign_settings')
        },

        'campaign.missed_calls_settings.missed_call_handling_mode': function () {
            this.preValidateForm('campaign_settings')
        },

        'ivr.steps': {
            deep: true,
            handler: function () {
                if (this.ivr.steps && this.ivr.steps.length) {
                    for (let step of this.ivr.steps) {
                        if (!step.valid) {
                            this.validated = false
                            return
                        }
                    }
                    this.preValidateForm('campaign_settings', false, false, true)
                }
            }
        },
    },

    beforeCreate() {
        axios.get('/build-info.json').then(res => {
            this.env = res.data.env
        }).catch(err => {
            console.log(err)
        })
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                next()
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    },

    beforeRouteLeave(to, from, next) {
        this.closeErrorNotification()
        next()
    }
}
</script>
