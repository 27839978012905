<template>
    <div class="app-body"
         id="view">
        <image-assets-preloader></image-assets-preloader>

        <el-dialog :visible.sync="show_help"
                   top="10vh"
                   width="600px"
                   append-to-body>
            <div slot="title">
                <h5 class="mb-0">
                    The Dashboard
                </h5>
            </div>
            <article v-show="loading_whitelabel">
                <p class="">
                    The dashboard is a central place to view your agent's activity, historical
                    call records and a list of all calls and messages that you have received, sent or made.
                </p>
                <p class="">
                    You can track the progress of a call or historical calling and messaging
                    records right on the dashboard.
                </p>
                <p v-if="!statics.whitelabel">
                    To learn more about the summary report, open our knowledge base.
                </p>
                <a class="btn btn-success"
                   href="https://support.aloware.com/en/articles/9033811-dashboard-communication-activities"
                   v-if="!statics.whitelabel"
                   target="_blank">
                    Go to Knowledge Base
                </a>
            </article>
        </el-dialog>

        <welcome-to-aloware></welcome-to-aloware>

        <div class="fixed-header padding pt-0 pb-0" :style="{top: (top_offset+50) + 'px'}">
            <div class="row mb-0 mt-0">
                <div class="col-12 p-2">
                    <div class="d-flex">
                        <div class="d-flex flex-fill">
                            <comm-advanced-filters-wrapper :tag_id="tag_id"
                                                           :call_disposition_id="call_disposition_id">
                            </comm-advanced-filters-wrapper>
                        </div>
                        <div class="ml-auto">
                            <button class="btn btn-md ml-2 text-bluish"
                                    style="height: 40px;"
                                    v-if="false"
                                    @click="toggleHelp">
                                <i class="material-icons text-lg">help</i>
                                Help
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="section === 'Activities'">
            <div class="padding mt-5 pt-3"
                 v-if="hasPermissionTo('list report')">
                <div class="row">
                    <div class="col-12 p-0">
                        <new-summary-report></new-summary-report>
                    </div>
                </div>
            </div>

            <div class="padding"
                 v-if="hasPermissionTo('list report') && show_graph">
                <div class="row box">
                    <div class="col-12">
                        <div class="no-effect">
                            <div class="box-body">
                                <activity-graph base="campaign"
                                                :is_first_load="reports_first_load">
                                </activity-graph>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="padding"
                 v-if="hasPermissionTo('list communication')">
                <div class="row box">
                    <div class="col-12">
                        <div class="no-effect">
                            <div class="box-body">
                                <comm-log-table></comm-log-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="section === 'Transcriptions' && shouldDisplayTranscriptionSections">
            <div class="padding mt-5 pt-3 mb-0 pb-0">
                <transcription-reporting-panel/>
            </div>
        </div>

        <el-backtop target="html"
                    ref="backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>
    </div>
</template>

<script>
import auth from '../auth'
import {mapGetters, mapState} from 'vuex'
import {
    acl_mixin,
    filter_mixin,
    scroll_mixin,
    styling_mixin,
    supervisor_mixin
} from '../mixins'
import TranscriptionReportingPanel from '../components/transcription-reporting-panel'

export default {
    mixins: [
        styling_mixin,
        acl_mixin,
        scroll_mixin,
        filter_mixin,
        supervisor_mixin
    ],

    components: {
        'transcription-reporting-panel': TranscriptionReportingPanel
    },

    data() {
        return {
            auth: auth,
            profile: auth.user.profile,
            CancelToken: null,
            source: null,
            loading: true,
            loading_verification: false,
            show_help: false,
            tag_id: null,
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true,
            tourUniqueId: 'CLDvfv9NffPKeyG6',
            reports_first_load: true,
            call_disposition_id: null,
        }
    },

    computed: {
        ...mapGetters({
            filter: 'getFilter',
            section: 'getSection'
        }),

        ...mapState({
            current_company: state => state.cache.current_company,
            show_graph: state => state.cache.show_graph,
        }),

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        }
    },

    created() {
        this.CancelToken = axios.CancelToken
        this.source = this.CancelToken.source()

        if (this.$route.query && this.$route.query.call) {
            let query = Object.assign({}, this.$route.query)
            delete query.call
            this.$router.replace({query})
        }

        if (this.$route.query && this.$route.query.add_contact) {
            VueEvent.fire('add_contact', {
                phone_number: this.$options.filters.fixPhone(this.$route.query.add_contact)
            })
            this.$router.replace('/dashboard').catch(err => {
            })
        }

        if (this.$route.query && this.$route.query.tag_id) {
            this.tag_id = parseInt(this.$route.query.tag_id)
        }

        if (this.$route.query && this.$route.query.call_disposition_id) {
            this.call_disposition_id = parseInt(this.$route.query.call_disposition_id)
        }

        VueEvent.listen('scroll_to_graph', () => {
            setTimeout(() => {
                this.scrollWindowToGraph()
            }, 500)
        })
    },

    mounted() {
        this.setTitle()
        this.filter.timezone = window.timezone
        VueEvent.listen('start_tour', () => {
            this.startTourExperience()
        })
    },

    methods: {
        setTitle() {
            this.loading_whitelabel = true
            axios.get('/get-statics').then(res => {
                this.statics = res.data
                this.loading_whitelabel = false
                let name = res.data.name
                this.setPageTitle('Dashboard - ' + name)
            }).catch(err => {
                this.setPageTitle('Dashboard - Aloware')
                console.log(err)
                this.$root.handleErrors(err.response)
                this.loading_whitelabel = false
            })
        },

        scrollWindowToGraph() {
            if (!$('#activity-graph').length) {
                return
            }

            let top_position = $("#activity-graph").offset().top
            let header_height = $(".app-header").height()

            $("html,body").animate({
                scrollTop: top_position - header_height - 90
            }, 500)
        },

        toggleHelp() {
            this.show_help = !this.show_help
        }
    },

    watch: {
        'show_graph': function () {
            this.reports_first_load = false
        }
    },

    beforeRouteEnter(to, from, next) {
        if (to.query.api_token) {
            return next({name: 'Login', query: {api_token: to.query.api_token}})
        }

        auth.check().then((res) => {
            if (res.data.user.is_reseller) {
                // redirect to account management portal if the company is a reseller
                next({name: 'Account Management Portal'})
            } else {
                next()
            }
        }).catch((err) => {
            next({name: 'Login', query: {redirect: to.fullPath}})
        })
    }
}
</script>
