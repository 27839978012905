import { render, staticRenderFns } from "./instructions.vue?vue&type=template&id=5134e084&scoped=true"
import script from "./instructions.vue?vue&type=script&lang=js"
export * from "./instructions.vue?vue&type=script&lang=js"
import style0 from "./instructions.vue?vue&type=style&index=0&id=5134e084&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5134e084",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/app/releases/20250214223740Z/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5134e084')) {
      api.createRecord('5134e084', component.options)
    } else {
      api.reload('5134e084', component.options)
    }
    module.hot.accept("./instructions.vue?vue&type=template&id=5134e084&scoped=true", function () {
      api.rerender('5134e084', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/user/components/aloai/form/instructions.vue"
export default component.exports