const base_path = `/api/v1/company`

export default {
    /**
     * Endpoint for fetching agents status.
     * params parameter is used to paginate the data that will be fetch
     * @handler Api/V1/CompanyController@getAgentsStatus
     * @return Promise
     */
    getAgentsStatus() {
        return axios.get(`${base_path}/agents-status/get`)
    }
}
