<template>
    <div class="app-body"
         id="view">
        <div class="fixed-header padding pt-0 pb-0" :style="{top: (top_offset+50) + 'px'}">
            <div class="row mb-0 mt-0">
                <div class="col-12 p-2">
                    <div class="block pull-right">
                        <el-date-picker ref="datetime_picker"
                                        v-model="date_time_rage"
                                        type="datetimerange"
                                        :picker-options="datePickerOptions"
                                        :default-time="['00:00:00', '23:59:59']"
                                        range-separator="To"
                                        start-placeholder="Start date"
                                        end-placeholder="End date"
                                        align="right"
                                        data-testid="timeline-date-picker"
                                        @change="changeDateRange">
                        </el-date-picker>
                        <div class="dropdown pull-right ml-2"
                             v-if="hasRole('Company Admin')">
                            <button class="btn btn-outline b-dark-greenish text-dark-greenish"
                                    :disabled="!user_activity.length || export_loading_btn"
                                    data-testid="export-timeline-button"
                                    @click.prevent="exportTimeline">
                                <i class="fa fa-spin fa-spinner"
                                   v-show="export_loading_btn"></i>
                                Export
                            </button>
                        </div>
                        <button class="btn deep-orange p-x-md pull-right ml-2"
                                v-if="can_reset"
                                data-testid="reset-timeline-button"
                                @click.prevent="resetDateFilters">
                            Reset Filters
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="padding mt-5">
            <div class="row box">
                <div class="col-12 pt-lg-5"
                     v-loading="loading"
                     :style="{'min-height': '600px'}">
                    <div class="block">
                        <div class="ml-lg-5"
                             v-if="user_activity.length">
                            <el-timeline class="user_timeline" data-testid="el-timeline">
                                <el-timeline-item v-for="activity in user_activity"
                                                  data-testid="timeline-item"
                                                  :key="activity.id"
                                                  :timestamp="getTimestamp(activity)"
                                                  :color="getColor(activity.to)"
                                                  placement="top">
                                    <div>
                                        <span v-if="activity.property == 'agent_status'">
                                            Availability status has changed from
                                            <strong>{{ activity.from | fixAgentStatus }}</strong>
                                        </span>
                                        <span v-else>
                                            Status has changed from
                                            <strong>{{ activity.from | capitalize }}</strong>
                                        </span>
                                        <span>to</span>
                                        <span v-if="activity.property == 'agent_status'">
                                            <strong>{{ activity.to | fixAgentStatus }}</strong>
                                        </span>
                                        <span v-else>
                                            <strong>{{ activity.to | capitalize }}</strong>
                                        </span>
                                        <span>
                                            at <b>{{ activity.created_at | fixDateTimeFull }}</b>
                                        </span>
                                        <span class="badge badge-warning"
                                              v-if="agent_audit_status && agent_audit_status.id == activity.id">
                                            Current status of the user
                                        </span>
                                    </div>
                                    <div>
                                        <span v-if="activity.property == 'agent_status'">
                                            <strong>{{ activity.from | fixAgentStatus }}</strong>
                                        </span>
                                        <span v-else>
                                            <strong>{{ activity.from | capitalize }}</strong>
                                        </span>
                                        Duration: <strong>{{ activity.duration | fixFullLongDuration }}</strong>
                                    </div>
                                    <small v-if="activity.property == 'agent_status'"
                                           class="small text-grey-6">
                                        By: {{ activity.source }}
                                    </small>
                                    <small v-if="activity.communication_id"
                                           class="small text-bluish">
                                        - <router-link :to="{ name: 'Communication', params: {communication_id: activity.communication_id }}" target="_blank">Communication info</router-link>
                                    </small>
                                </el-timeline-item>
                            </el-timeline>
                        </div>
                        <div class="text-center mb-5"
                             v-else>No activity
                        </div>
                        <div class="row">
                            <div class="col-12 text-center">
                                <el-button class="mb-4 mt-3"
                                           type="success"
                                           size="medium"
                                           plain
                                           round
                                           data-testid="load-more-button"
                                           :disabled="loading_btn"
                                           :loading="loading_btn"
                                           v-if="user_activity.length && last_page > 1 && last_page > page_counter"
                                           @click.prevent="loadMoreUserActivity">
                                    Load more...
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-backtop target="html"
                    ref="backtop"
                    :bottom="20"
                    data-testid="timeline-backtop"
                    :right="20">
        </el-backtop>
    </div>
</template>

<script>
import auth from '../../auth'
import {mapState} from 'vuex'
import {chargebee_mixin, paginator_mixin, scroll_mixin, styling_mixin, user_info_mixin} from '../../mixins'
import {Notification} from 'element-ui'
import * as AnswerTypes from '../../constants/answer-types'

const default_date_range = () => {
    return [
        moment.utc(new Date()).tz(window.timezone).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        moment.utc(new Date()).tz(window.timezone).format('YYYY-MM-DD HH:mm:ss')
    ]
}

export default {
    mixins: [
        styling_mixin,
        chargebee_mixin,
        paginator_mixin,
        scroll_mixin,
        user_info_mixin
    ],

    props: {
        user_id: {
            required: false
        }
    },

    data() {
        return {
            auth: auth,
            loading: false,
            loading_btn: false,
            export_loading_btn: false,
            user: null,
            user_activity: [],
            page_counter: 1,
            last_page: 0,
            date_time_rage: default_date_range(),
            datePickerOptions: {
                shortcuts: [{
                    text: 'Today',
                    onClick(picker) {
                        picker.$emit('pick', [...default_date_range(), 'today'])
                    }
                }, {
                    text: 'Last 8 Hours',
                    onClick(picker) {
                        const start = new Date()
                        const end = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 8)
                        picker.$emit('pick', [start, end, 'last_8_hours'])
                    }
                }, {
                    text: 'Last 12 Hours',
                    onClick(picker) {
                        const start = new Date()
                        const end = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 12)
                        picker.$emit('pick', [start, end, 'last_12_hours'])
                    }
                }, {
                    text: 'All Time',
                    onClick(picker) {
                        picker.$emit('pick', [])
                    }
                }]
            },
            date_range_link_selected: 'today',
            can_reset: false,
            agent_audit_status: null,
            parameters: {},
            AnswerTypes
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),
    },

    mounted() {
        this.setTitle()
        this.getUserActivityStatus(this.user_id)
        this.getUser(this.user_id)
    },

    methods: {
        setTitle() {
            axios.get('/get-statics')
                .then(res => {
                    let name = res.data.name
                    this.setPageTitle('User Timeline - ' + name)
                })
                .catch(err => {
                    this.setPageTitle('User Timeline - Aloware')
                    this.$root.handleErrors(err.response)
                })
        },

        changeDateRange() {
            if (this.date_time_rage == null) {
                return
            }
            this.can_reset = true
            this.date_range_link_selected = this.date_time_rage.length ? this.date_time_rage[this.date_time_rage.length - 1] : 'all_time'
            this.date_time_rage = this.date_time_rage.length ? this.date_time_rage.splice(0, 2) : this.date_time_rage
            this.page_counter = 1
            this.getUserActivityStatus(this.user_id)
        },

        resetDateFilters() {
            this.can_reset = false
            this.date_range_link_selected = 'today'
            this.date_time_rage = default_date_range()
            this.page_counter = 1
            this.getUserActivityStatus(this.user_id)
        },

        getUser(user_id) {
            if (!user_id) {
                return
            }
            let user = this.users.find(user => user.id == user_id)
            if (user) {
                this.setUpUser(user)
                VueEvent.fire('page_title_updated', this.getUserName(this.user))
            }
        },

        loadMoreUserActivity() {
            this.page_counter++
            this.loading_btn = true
            this.getUserActivityStatus(this.user_id)
        },

        getLocalTime(dt) {
            if (!dt) {
                return null
            }

            if (window.timezone) {
                return moment.utc(dt).tz(window.timezone)
            } else {
                return moment.utc(dt).local()
            }
        },

        getStartTimestamp(activity) {
            let local_timestamp = this.getLocalTime(activity.created_at)
            if (local_timestamp) {
                local_timestamp = local_timestamp.subtract(activity.duration, 'seconds')
            }

            return local_timestamp.utc().format()
        },

        getTimestamp(activity) {
            let start_timestamp = this.getStartTimestamp(activity)
            return this.$options.filters.fixDateTimeFull(start_timestamp) + ' - ' + this.$options.filters.fixDateTimeFull(activity.created_at)
        },

        getUserActivityStatus(user_id) {
            if (this.loading_btn == false) {
                this.loading = true
            }
            let date_rage
            let parameters = {
                page: this.page_counter,
                timezone: window.timezone
            }

            if (this.date_time_rage.length) {
                date_rage = {
                    from_date: this.date_range_link_selected == 'today' ? this.date_time_rage[0] : moment.utc(this.date_time_rage[0]).tz(window.timezone).format('YYYY-MM-DD HH:mm:ss'),
                    to_date: this.date_range_link_selected == 'today' ? this.date_time_rage[1] : moment.utc(this.date_time_rage[1]).tz(window.timezone).format('YYYY-MM-DD HH:mm:ss')
                }
                parameters = Object.assign(parameters, date_rage)
                this.parameters = parameters
            }

            axios.get('/api/v1/user/' + user_id + '/activity-status', {
                mode: 'no-cors',
                params: parameters
            }).then(res => {
                if (this.page_counter == 1) {
                    this.user_activity = []
                }
                this.loading = false
                this.loading_btn = false
                let response = res.data
                this.user_activity = [...this.user_activity, ...response.data]
                this.last_page = response.last_page
                this.agent_audit_status = response.agent_audit_status
            }).catch(err => {
                this.loading = false
                this.loading_btn = false
                this.$root.handleErrors(err.response)
            })
        },

        exportTimeline() {
            this.loading = true
            this.loading_btn = true
            this.export_loading_btn = true
            delete this.parameters['page']
            if (this.date_range_link_selected == 'all_time') {
                delete this.parameters['from_date']
                delete this.parameters['to_date']
            }
            let parameters = Object.assign({'export_type': 'csv'}, this.parameters)
            axios.get('/api/v1/user/' + this.user_id + '/activity-status', {
                mode: 'no-cors',
                params: parameters
            }).then(res => {
                this.loading = false
                this.loading_btn = false
                this.export_loading_btn = false
                this.$notify.info({
                    offset: 95,
                    title: 'Exports',
                    dangerouslyUseHTMLString: true,
                    message: `Your export is on its way. You can check the progress from the export page or <b class="text-dark-greenish pointer">click here</b>.`,
                    duration: 3000,
                    onClick: () => {
                        this.$router.push({name: 'Account', query: {tab: 'exports'}}).catch((err) => {
                        })
                        Notification.closeAll()
                    }
                })
            }).catch(err => {
                this.loading = false
                this.loading_btn = false
                this.export_loading_btn = false
                this.$root.handleErrors(err.response)
            })
        },

        setUpUser(data) {
            this.user = data
        },

        getColor(status) {
            let agent_status = ['offline', 'online'].includes(status) ? this.$options.filters.capitalize(status) : this.$options.filters.fixAgentStatus(status)
            let color

            switch (agent_status) {
                case 'Offline':
                    color = '#828282'
                    break
                case 'Online':
                case 'Available':
                    color = '#27ae60'
                    break
                case 'Busy':
                    color = '#eb5757'
                    break
                case 'On Break':
                    color = '#f2994a'
                    break
                case 'On Call':
                    color = '#2f80ed'
                    break
                case 'Wrap-up':
                    color = '#9b51e0'
                    break
                case 'Ringing':
                    color = '#00c0cc'
                    break
                case 'Sentry mode':
                    color = '#1d1d1d'
                    break
                case 'Auto Dial':
                default:
                    color = '#bdbdbd'
            }

            return color
        },
    },

    watch: {
        users() {
            this.getUser(this.user_id)
        }
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next()
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script>
