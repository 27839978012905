<template>
    <el-popover width="400"
                trigger="click"
                class="p-0"
                popper-class="variable-popover"
                :placement="popoverPlacement"
                @show="popoverShow">
        <div class="row">
            <div class="col-12">
                <tag-selector data-testid="aloai-tags-category-selector"
                              :category_prop="category"
                              :multiple="true"
                              :no-collapse="true"
                              :custom_tags="customTags"
                              :disabled_options="disabled"
                              :disabled="disabled"
                              :clearable="false"
                              v-model="selected_tags"
                              @change="emitSelectedTags($event)">
                </tag-selector>
            </div>
        </div>
        <el-button id="tags-popover-trigger"
                   slot="reference"
                   size="mini"
                   circle
                   :class="variableBtnClass"
                   :disabled="disabled">
            <i :class="'text-green ' + icon"></i>
        </el-button>
    </el-popover>
</template>

<script>
import * as TagCategory from '../../../constants/tag-categories'
import TagSelector from '../../tag-selector'

export default {
    components: {
        TagSelector
    },

    props: {
        disabled: {
            required: false,
            default: false
        },
        popoverPlacement: {
            required: false,
            default: "bottom-start"
        },
        icon: {
            required: false,
            default: "fa fa-tag"
        },
        variableBtnClass: {
            required: false,
            default: "border-0 bg-transparent"
        },
        customTags: {
            type: Array,
            required: false,
            default: () => []
        },
        selectedTagsList: {
            type: Array,
            required: false,
        },
    },

    data() {
        return {
            selected_tags: [],	
            category: TagCategory.CAT_CONTACTS,
        }
    },

    methods: {
        popoverShow() {
            if (this.$refs[('tags_dropdown')]) {
                this.$refs[('tags_dropdown')].focusToSelector()
            }
        },

        emitSelectedTags(tags) {
            this.$emit('tags-selected', tags)
        }
    },

    watch: {
        selectedTagsList() {
            this.selected_tags = this.selectedTagsList
        }
    }
}
</script>
