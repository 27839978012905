var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("h5", { staticClass: "text-dark" }, [
            _c("i", { staticClass: "el-icon-top-right" }),
            _vm._v(" "),
            _vm.bot.type === _vm.AloAi.TYPE_TEXT &&
            _vm.bot.direction === _vm.AloAi.DIRECTION_OUTBOUND
              ? _c("span", [_vm._v("Opener Message")])
              : _c("span", [_vm._v("Greeting Message")]),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "opener" } },
            [
              _c("div", { staticClass: "form-label" }, [
                _vm.bot.type === _vm.AloAi.TYPE_TEXT
                  ? _c("small", [
                      _vm._v(
                        "\n                        The first message the agent sends to the contact. Use variables to personalize it.\n                    "
                      ),
                    ])
                  : _c("small", [
                      _vm._v(
                        "\n                        The first message the agent says to the contact. Use variables to personalize it.\n                    "
                      ),
                    ]),
              ]),
              _vm._v(" "),
              _c("el-input", {
                ref: "openerInput",
                attrs: { type: "textarea" },
                on: {
                  input: function ($event) {
                    return _vm.preValidateForm("agent_settings")
                  },
                  focus: _vm.onOpenerFocus,
                  blur: _vm.onOpenerBlur,
                },
                model: {
                  value: _vm.bot.opener,
                  callback: function ($$v) {
                    _vm.$set(_vm.bot, "opener", $$v)
                  },
                  expression: "bot.opener",
                },
              }),
              _vm._v(" "),
              _vm.bot.allow_access_contact_information
                ? _c(
                    "el-tooltip",
                    { attrs: { content: "Add variable", placement: "top" } },
                    [
                      _c("variables", {
                        on: {
                          "variable-selected": _vm.variableSelectedOnOpener,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("hr", { staticClass: "mb-0 mt-1" }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "instructions" } },
            [
              _c("div", { staticClass: "form-label" }, [
                _c("small", [
                  _vm._v(
                    "\n                        Choose a template to quickly start with predefined instructions for common agent roles:\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.isCompanyPartOfAlowareDemoCompanies(false)
                ? _c("template-selector", {
                    ref: "templateSelector",
                    on: { "template-selected": _vm.onTemplateSelected },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "form-label" }, [
                _c("small", [
                  _vm._v(
                    "\n                        Write a personality for the agent and try to explain what you want the agent to perform in details.\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("formatted-instructions-input", {
                ref: "textareaInput",
                staticClass: "w-100 aloai-instructions",
                attrs: {
                  rows: 14,
                  readonly: _vm.loadingTags,
                  minRows: 14,
                  isEditing: _vm.isEditing,
                },
                on: {
                  "update:isEditing": _vm.enableEditMode,
                  input: _vm.emitValidate,
                  focus: _vm.onInstructionsFocus,
                  blur: _vm.onInstructionsBlur,
                },
                model: {
                  value: _vm.bot.instructions[0].content,
                  callback: function ($$v) {
                    _vm.$set(_vm.bot.instructions[0], "content", $$v)
                  },
                  expression: "bot.instructions[0].content",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  !_vm.isEditing
                    ? [
                        _c(
                          "el-button",
                          {
                            staticClass: "my-2 ml-auto",
                            attrs: {
                              type: "success",
                              plain: "",
                              size: "mini",
                              icon: "el-icon-edit",
                            },
                            on: { click: _vm.enableEditMode },
                          },
                          [
                            _vm._v(
                              "\n                            Edit\n                        "
                            ),
                          ]
                        ),
                      ]
                    : [
                        _vm.bot.allow_access_contact_information
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: "Add variable",
                                  placement: "top",
                                },
                              },
                              [
                                _c("variables", {
                                  on: {
                                    "variable-selected": _vm.onVariableSelected,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("tool-selector", {
                          ref: "toolSelector",
                          attrs: {
                            "selected-disposition": _vm.currentDisposition,
                            "custom-tags": _vm.currentTags,
                            "selected-tags-ids": _vm.currentTagsIds,
                            "custom-lists": _vm.currentLists,
                            "selected-list-ids": _vm.currentListsIds,
                            "selected-campaign": _vm.selectCampaign,
                            loading: _vm.loadingTags,
                            "available-tools": _vm.toolsList,
                            "instructions-content":
                              _vm.bot.instructions[0].content,
                          },
                          on: {
                            "disposition-selected": _vm.onDispositionSelected,
                            "tags-selected": _vm.onTagsSelected,
                            "lists-selected": _vm.onListsSelected,
                            "campaign-selected": _vm.onCampaignSelected,
                            "variable-selected": _vm.onVariableSelected,
                            "create-appointment-selected":
                              _vm.onCreateAppointmentSelected,
                            "disengage-contact-selected":
                              _vm.onDisengageContactSelected,
                            "send-sms-selected": _vm.onSmsSelected,
                            "custom-function-selected":
                              _vm.onCustomFunctionSelected,
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "ml-2 my-2",
                            attrs: { type: "success", plain: "", size: "mini" },
                            on: { click: _vm.preSaveInstructions },
                          },
                          [
                            _vm._v(
                              "\n                            Save\n                        "
                            ),
                          ]
                        ),
                      ],
                ],
                2
              ),
              _vm._v(" "),
              _c("hr", { staticClass: "mb-0 mt-1" }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "mb-0",
            },
            [
              _c("div", { staticClass: "form-label" }, [
                _c("small", [
                  _vm._v(
                    "\n                        This help AloAi Bot give more accurate responses by learning from your uploaded documents. This makes replies smarter, faster, and more consistent while improving over\n                        time.\n                    "
                  ),
                ]),
                _vm._v(" "),
                _vm.bot.id
                  ? _c("small", [
                      _c("i", [
                        _vm._v(
                          "Supported formats are: docx, json, md, pdf, pptx, txt."
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.bot.id && _vm.bot.model !== _vm.AloAi.MODEL_GPT_35_T
                ? _c("multi-upload", {
                    attrs: {
                      action: _vm.getAction,
                      "delete-action": _vm.deleteAction,
                      showFileList: false,
                    },
                    on: {
                      success: _vm.successFileUpload,
                      failed: _vm.failedFileUpload,
                      "before-upload": _vm.beforeFileUpload,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.bot.model === _vm.AloAi.MODEL_GPT_35_T
                ? _c("p", { staticClass: "text-md _400 text-danger" }, [
                    _vm._v(
                      "\n                    You can't have KB files with this model.\n                "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.bot.id
                ? _c("p", { staticClass: "text-md _400 text-primary" }, [
                    _vm._v(
                      "\n                    You need to create an AloAi Bot before uploading files.\n                "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.bot.id && _vm.knowledge_base_files
                ? _c(
                    "el-table",
                    {
                      staticClass: "w-full",
                      attrs: {
                        "row-key": "id",
                        data: _vm.knowledge_base_files,
                        stripe: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "Name" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.name))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2020036417
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "Uploaded At" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("fixDateTime")(
                                          scope.row.created_at
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2135987715
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "Uploaded by" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.created_by.name +
                                          " - #" +
                                          scope.row.created_by.id
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          803177666
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          width: "100",
                          label: "Status",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.status === "PROCESSING"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ml-2 d-inline-block label yellow",
                                        },
                                        [_vm._v("Processing")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status === "SUCCESS"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ml-2 d-inline-block label green",
                                        },
                                        [_vm._v("Success")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status === "FAILED"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ml-2 d-inline-block label red",
                                        },
                                        [_vm._v("Error")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          896939696
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "50", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: "Delete",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "hover-lower-opacity-danger",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleKnowledgeBaseFileDelete(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-trash-o",
                                                staticStyle: {
                                                  "font-size": "17px",
                                                },
                                                attrs: {
                                                  "aria-hidden": "true",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          563148505
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", { staticClass: "text-dark" }, [
      _c("i", { staticClass: "el-icon-document" }),
      _vm._v("\n                Instructions\n            "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", { staticClass: "text-dark" }, [
      _c("i", { staticClass: "el-icon-collection" }),
      _vm._v("\n                Knowledge Base Files\n            "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }