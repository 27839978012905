var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          staticClass: "w-full",
          attrs: { "row-key": "id", data: _vm.botsData, stripe: "" },
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "row align-items-top" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("span", { staticClass: "text-greyish break-word" }, [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.description ?? "-")),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-6 b-l" }, [
                        _c("p", [
                          _c("b", [_vm._v("Created By:")]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.created_by
                                  ? scope.row.created_by.name +
                                      " - #" +
                                      scope.row.created_by.id
                                  : "-"
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c("b", [_vm._v("Last Modified By:")]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.modified_by
                                  ? scope.row.modified_by.name +
                                      " - #" +
                                      scope.row.modified_by.id
                                  : "-"
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Name", align: "left", "min-width": 150 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c("el-switch", {
                          staticClass: "mr-4 align-self-center",
                          attrs: { "active-color": "#00BF50" },
                          on: {
                            change: function ($event) {
                              return _vm.updateBotEnabled(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.enabled,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "enabled", $$v)
                            },
                            expression: "scope.row.enabled",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "AloAi: Configure an agent",
                                    params: { bot_id: scope.row.id },
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "hover-lower-opacity" },
                                  [
                                    _c("p", { staticClass: "mb-0" }, [
                                      _c("b", [_vm._v(_vm._s(scope.row.name))]),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v("#" + _vm._s(scope.row.id)),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "100", label: "Type", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      { staticClass: "ml-2 d-inline-block label blue" },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(scope.row.type_text) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "150", label: "Direction", align: "left" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.direction_text))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "150", label: "Model", align: "left" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type === _vm.AloAi.TYPE_TEXT
                      ? _c("span", [
                          _vm._v(_vm._s(scope.row.model_human_readable_text)),
                        ])
                      : _c("span", [
                          _vm._v(
                            _vm._s(scope.row.voice_model_human_readable_text)
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { "min-width": "100", label: "Lines", align: "left" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("a", [
                      _c("span", { staticClass: "text-dark-greenish" }, [
                        scope.row.campaigns && scope.row.campaigns.length === 1
                          ? _c(
                              "span",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "text-dark-greenish",
                                    attrs: {
                                      to: {
                                        name: "Line Settings",
                                        params: {
                                          campaign_id:
                                            scope.row.campaigns[0].id,
                                        },
                                        query: {
                                          tab: "routing-settings",
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(scope.row.campaigns[0]?.name)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    scope.row.campaigns[0]?.incoming_numbers &&
                                    scope.row.campaigns[0].incoming_numbers
                                      .length
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm._f("fixPhone")(
                                                  scope.row.campaigns[0]
                                                    .incoming_numbers[0]
                                                    ?.phone_number
                                                )
                                              ) +
                                              "\n                                "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("span", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(scope.row.campaigns.length) +
                                  " Lines\n                        "
                              ),
                            ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "left", label: "Status", "min-width": 40 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.enabled
                      ? _c("span", { staticClass: "text-green" }, [
                          _c("i", {
                            staticClass: "fa fa-play-circle text-success mr-1",
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Running")]),
                        ])
                      : _c("span", { staticClass: "text-red" }, [
                          _c("i", {
                            staticClass: "fa fa-pause-circle text-warning mr-1",
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Paused")]),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "right", "min-width": 40 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "Modify",
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "hover-lower-opacity-warning ml-4 mr-4",
                                attrs: { disabled: "true" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(scope.row)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-pencil",
                                  staticStyle: { "font-size": "17px" },
                                  attrs: { "aria-hidden": "true" },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "Delete",
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "hover-lower-opacity-danger",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(scope.row)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-trash-o",
                                  staticStyle: { "font-size": "17px" },
                                  attrs: { "aria-hidden": "true" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }