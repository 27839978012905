var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body teams", attrs: { id: "view" } },
    [
      !_vm.isEditingTeam
        ? [
            _c("div", {
              staticClass: "header-placeholder",
              style: { height: "60px" },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "fixed-header padding pt-0 pb-0",
                style: { top: _vm.top_offset + 50 + "px" },
              },
              [
                _c("div", { staticClass: "row mb-0 mt-0" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-fill align-items-center flex-wrap p-2",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-wrap flex-xl-nowrap flex-fill mr-auto",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex-fill flex-xl-grow-0 search-toolbar",
                            },
                            [
                              _c("el-input", {
                                staticClass: "w-full",
                                attrs: {
                                  placeholder: "Search Teams",
                                  "prefix-icon": "el-icon-search",
                                  clearable: "",
                                },
                                on: { input: _vm.onSearchInput },
                                model: {
                                  value: _vm.searchInput,
                                  callback: function ($$v) {
                                    _vm.searchInput = $$v
                                  },
                                  expression: "searchInput",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-wrap flex-xl-nowrap flex-shrink-0",
                        },
                        [
                          _vm.hasPermissionTo(_vm.CREATE_TEAM_PERMISSION)
                            ? _c(
                                "div",
                                { staticClass: "ml-3" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "btn rounded greenish h-100",
                                      attrs: {
                                        type: "success",
                                        "data-testid":
                                          "team-creator-open-modal-button",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "Team",
                                          })
                                        },
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "fa fa-plus" }),
                                      _vm._v(
                                        "\n                                Add Team\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mt-2 mt-lg-0 ml-2 d-flex flex-column justify-content-center text-blue",
                            },
                            [
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons text-lg cursor-pointer",
                                  attrs: { "data-testid": "info-outline-i" },
                                  on: { click: _vm.toggleHelp },
                                },
                                [
                                  _vm._v(
                                    "\n                                info_outline\n                            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "px-3" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.isLoading,
                        expression: "isLoading",
                      },
                    ],
                    ref: "company-teams-list",
                    staticClass: "w-full company-teams-list",
                    staticStyle: { width: "100%" },
                    attrs: { stripe: "", data: _vm.teams },
                    on: {
                      "cell-mouse-enter": _vm.cellEnter,
                      mouseleave: _vm.tableLeave,
                      "sort-change": _vm.handleSortChange,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "Team",
                        prop: "name",
                        sortable: "custom",
                        "min-width": 200,
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-dark-greenish cursor-pointer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onManageMembers(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row?.name))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1142308354
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "Description",
                        prop: "description",
                        sortable: "custom",
                        "show-overflow-tooltip": "",
                        "min-width": 200,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(scope.row?.description || "-") +
                                    "\n                    "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3739033257
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "Ring Groups",
                        prop: "total_ring_groups",
                        "min-width": 120,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.ring_groups?.length
                                  ? _c(
                                      "el-tooltip",
                                      { attrs: { placement: "top" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          _vm._l(
                                            scope.row.ring_groups,
                                            function (rg) {
                                              return _c("div", { key: rg.id }, [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(rg.name) +
                                                    "\n                                "
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "cursor-help" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.total_ring_groups || 0
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(scope.row.total_ring_groups || 0)
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2463477004
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "Users",
                        prop: "total_users",
                        align: "center",
                        "min-width": 120,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.users?.length
                                  ? _c(
                                      "el-tooltip",
                                      { attrs: { placement: "top" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          _vm._l(
                                            scope.row.users,
                                            function (user) {
                                              return _c(
                                                "div",
                                                { key: user.id },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(user.first_name) +
                                                      " " +
                                                      _vm._s(user.last_name) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "cursor-help" },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.total_users || 0)
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(scope.row.total_users || 0)
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        667679693
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "Supervisors",
                        prop: "total_supervisors",
                        "min-width": 120,
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.supervisors?.length
                                  ? _c(
                                      "el-tooltip",
                                      { attrs: { placement: "top" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          _vm._l(
                                            scope.row.supervisors,
                                            function (supervisor) {
                                              return _c(
                                                "div",
                                                { key: supervisor.id },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        supervisor.first_name
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        supervisor.last_name
                                                      ) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "cursor-help" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.total_supervisors || 0
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(scope.row.total_supervisors || 0)
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2799706829
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "Created by",
                        prop: "created_by",
                        sortable: "custom",
                        "min-width": 150,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.getFullName(scope.row?.created_by)
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3984092933
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "Created on",
                        prop: "created_at",
                        sortable: "custom",
                        "min-width": 150,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm._f("fixFullDateLocal")(
                                        scope.row?.created_at
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1499457900
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "Operations",
                        align: "center",
                        width: "150",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  { style: _vm.getStyle(scope.row.id) },
                                  [
                                    _c("div", { staticClass: "row mt-2" }, [
                                      _c("div", { staticClass: "col-12" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-block greenish",
                                            attrs: {
                                              "data-testid": "settings-button",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.onManageMembers(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fa fa-cogs pull-left",
                                            }),
                                            _vm._v(
                                              "\n                                        Settings\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _vm.hasPermissionTo(
                                      _vm.CREATE_TEAM_PERMISSION
                                    )
                                      ? _c("div", { staticClass: "row mt-2" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-12" },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "Team Activity",
                                                      params: {
                                                        team_id: scope.row.id,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-sm btn-block purplish",
                                                      attrs: {
                                                        "data-testid":
                                                          "activity-button",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-signal pull-left",
                                                      }),
                                                      _vm._v(
                                                        "\n                                            Activity\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.hasPermissionTo(
                                      _vm.DELETE_TEAM_PERMISSION
                                    )
                                      ? _c("div", { staticClass: "row mt-2" }, [
                                          _c("div", { staticClass: "col-12" }, [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-block danger",
                                                attrs: {
                                                  "data-testid":
                                                    "delete-button",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.openDeleteTeamModal(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-trash pull-left",
                                                }),
                                                _vm._v(
                                                  "\n                                        Delete\n                                    "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        11810138
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center justify-content-end py-3 px-3",
              },
              [
                _c("el-pagination", {
                  staticClass: "pull-right",
                  attrs: {
                    layout: "sizes, prev, pager, next",
                    background: "",
                    "data-testid": "teams-list-pagination",
                    "current-page": _vm.teamsPagination.page,
                    "page-sizes": [10, 20, 50, 100, 250],
                    "page-size": _vm.teamsPagination.size,
                    total: _vm.teamsPagination.total,
                  },
                  on: {
                    "update:currentPage": function ($event) {
                      return _vm.$set(_vm.teamsPagination, "page", $event)
                    },
                    "update:current-page": function ($event) {
                      return _vm.$set(_vm.teamsPagination, "page", $event)
                    },
                    "update:pageSize": function ($event) {
                      return _vm.$set(_vm.teamsPagination, "size", $event)
                    },
                    "update:page-size": function ($event) {
                      return _vm.$set(_vm.teamsPagination, "size", $event)
                    },
                    "size-change": function ($event) {
                      return _vm.paginateTeams(1)
                    },
                    "current-change": _vm.paginateTeams,
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "clearfix" }),
              ],
              1
            ),
          ]
        : _c("edit-team-members", {
            attrs: {
              "selected-team": _vm.selectedTeam,
              company: _vm.current_company,
            },
            on: { "back-to-teams-list": _vm.onBackToTeamsList },
          }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Delete Team",
            width: "30%",
            center: "",
            visible: _vm.isDeleteTeamModalVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isDeleteTeamModalVisible = $event
            },
          },
        },
        [
          _c("p", { staticClass: "break-word text-center" }, [
            _vm._v("You're about to delete the "),
            _c("strong", [_vm._v(_vm._s(_vm.selectedTeam?.name))]),
            _vm._v(" Team. This action can't be undone."),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "break-word text-center mb-4" }, [
            _vm._v("Are you sure?"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex justify-content-center mt-5 row" },
            [
              _c(
                "el-button",
                {
                  staticClass: "mb-2",
                  attrs: { slot: "reference", type: "default" },
                  on: { click: _vm.closeDeleteTeamModal },
                  slot: "reference",
                },
                [_vm._v("\n                No, Keep Team\n            ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "mb-2",
                  attrs: {
                    slot: "reference",
                    type: "danger",
                    "data-testid": "team-deleter-delete-button",
                    disabled: _vm.isLoading,
                  },
                  on: { click: _vm.onDeleteTeam },
                  slot: "reference",
                },
                [_vm._v("\n                Yes, Delete Team\n            ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Delete Team",
            width: "30%",
            center: "",
            visible: _vm.isDeleteTeamModalErrorVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isDeleteTeamModalErrorVisible = $event
            },
          },
        },
        [
          _c("p", [
            _vm._v("The "),
            _c("strong", [_vm._v(_vm._s(_vm.selectedTeam?.name))]),
            _vm._v(
              " Team may be being used on a Ring Group or has users assigned to it. To delete, you have to remove it from the Billing Ring Group or remove the users from the team."
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex justify-content-center mt-5" },
            [
              _c(
                "el-button",
                {
                  attrs: { slot: "reference", type: "default" },
                  on: {
                    click: function ($event) {
                      _vm.isDeleteTeamModalErrorVisible = false
                    },
                  },
                  slot: "reference",
                },
                [_vm._v("\n                Close\n            ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "What are teams?",
            top: "10vh",
            "data-testid": "what-are-teams-dialog",
            "append-to-body": "",
            visible: _vm.show_help,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_help = $event
            },
          },
        },
        [
          _c("p", { staticClass: "text-md _600 break-word" }, [
            _vm._v(
              "Teams help you organize agents efficiently for smarter call routing, streamlined messaging, and balanced workloads."
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-4" }, [
            _c("ul", { staticClass: "text-md" }, [
              _c("li", { staticClass: "mb-3" }, [
                _c("strong", [_vm._v("Optimize Routing & Inbox Management")]),
                _vm._v(
                  " – Assign agents to Ring Groups and Inbox Routing to ensure calls and messages reach the right team.\n                "
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "mb-3" }, [
                _c("strong", [_vm._v("Control Visibility & Access")]),
                _vm._v(
                  " – Agents can only see conversations, contacts, and data relevant to their assigned team, preventing cross-team confusion.\n                "
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "mb-3" }, [
                _c("strong", [_vm._v("Simplify Permissions & Reporting")]),
                _vm._v(
                  " – Manage access and track performance based on team roles.\n                "
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "mb-3" }, [
                _c("strong", [_vm._v("Boost Productivity")]),
                _vm._v(
                  " – Organize users by department, skill, or role for better collaboration and faster response times.\n                "
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }