var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog-blank",
      attrs: {
        width: _vm.getModalSize,
        top: "4vh",
        "before-close": _vm.closeDialog,
        visible: _vm.is_visible,
        "append-to-body": true,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "padding pt-3 mt-3",
          attrs: { id: "container-usage-summary" },
        },
        [
          [
            _c("credited-usage-summary", {
              attrs: {
                date_range_legend: _vm.date_range,
                usages: _vm.getUsageByChargeType("credit"),
              },
            }),
          ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }