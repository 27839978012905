var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "alo-ai-voice-analytics" },
    [
      _c("al-alert", { attrs: { dimisable: false } }, [
        _c("span", { staticClass: "mr-5 text-dark" }, [
          _vm._v(
            "\n            New! Now you can configure focused email reports for this " +
              _vm._s(_vm.EINBOX_NAME) +
              "\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cards-grid" },
        _vm._l(_vm.settings, function (setting, index) {
          return _c("div", { key: index, staticClass: "card my-3" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center",
                },
                [
                  _c(
                    "h6",
                    { staticClass: "card-title font-weight-bold mb-0" },
                    [_vm._v(_vm._s(setting.title))]
                  ),
                  _vm._v(" "),
                  _c("el-switch", {
                    staticClass: "ml-3",
                    attrs: { "active-color": "#00a344" },
                    model: {
                      value: setting.enabled,
                      callback: function ($$v) {
                        _vm.$set(setting, "enabled", $$v)
                      },
                      expression: "setting.enabled",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticClass: "text-muted small mb-1" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.getDescription(setting.type)) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mb-1 fixed-height-explanation" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.getExplanation(setting.type)) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-content mt-3" }, [
                _c("div", { staticClass: "d-flex align-items-center mb-3" }, [
                  _c("i", { staticClass: "fa fa-bell-o mr-2" }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "font-weight-bold color-dark mr-2" },
                    [_vm._v("Notification Status:")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold",
                      class: setting.enabled ? "text-success" : "text-muted",
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(setting.enabled ? "Enabled" : "Disabled") +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("hr", { staticClass: "my-3" }),
                _vm._v(" "),
                _c("div", { staticClass: "notification-settings" }, [
                  _c("p", { staticClass: "text-muted small mb-2" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.getNotificationSettingsDescription(
                            setting.type,
                            setting.title
                          )
                        ) +
                        "\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "settings-group" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center mb-2",
                      },
                      [
                        _vm._m(0, true),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#00a344",
                            disabled: !setting.enabled,
                          },
                          model: {
                            value: setting.connected_users_enabled,
                            callback: function ($$v) {
                              _vm.$set(setting, "connected_users_enabled", $$v)
                            },
                            expression: "setting.connected_users_enabled",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center mb-2",
                      },
                      [
                        _vm._m(1, true),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#00a344",
                            disabled: !setting.enabled,
                          },
                          model: {
                            value: setting.watching_users_enabled,
                            callback: function ($$v) {
                              _vm.$set(setting, "watching_users_enabled", $$v)
                            },
                            expression: "setting.watching_users_enabled",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center mb-2",
                      },
                      [
                        _vm._m(2, true),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#00a344",
                            disabled: !setting.enabled,
                          },
                          model: {
                            value: setting.supervisor_enabled,
                            callback: function ($$v) {
                              _vm.$set(setting, "supervisor_enabled", $$v)
                            },
                            expression: "setting.supervisor_enabled",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center mb-3",
                      },
                      [
                        _vm._m(3, true),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#00a344",
                            disabled: !setting.enabled,
                          },
                          model: {
                            value: setting.all_team_members_enabled,
                            callback: function ($$v) {
                              _vm.$set(setting, "all_team_members_enabled", $$v)
                            },
                            expression: "setting.all_team_members_enabled",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(4, true),
                ]),
              ]),
            ]),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fa small fa-users" }),
      _vm._v(" "),
      _c("span", { staticClass: "font-weight-bold" }, [
        _vm._v(
          "\n                                        Connected Users\n                                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fa small fa-user-plus" }),
      _vm._v(" "),
      _c("span", { staticClass: "font-weight-bold" }, [
        _vm._v(
          "\n                                        Watching Users\n                                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fa small fa-user-secret" }),
      _vm._v(" "),
      _c("span", { staticClass: "font-weight-bold" }, [
        _vm._v(
          "\n                                        Teams Supervisors\n                                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fa small fa-users" }),
      _vm._v(" "),
      _c("span", { staticClass: "font-weight-bold" }, [
        _vm._v(
          "\n                                        Teams Users\n                                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "reports-section mt-3 text-muted bg-light p-3 rounded" },
      [
        _c("p", { staticClass: "mb-2" }, [
          _c("i", { staticClass: "fa fa-envelope-o mr-2" }),
          _vm._v(" "),
          _c("span", { staticClass: "small" }, [
            _vm._v(
              "\n                                    Reports will include analytics for this Ring Group and all associated:\n                                "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "small ml-4 mb-0" }, [
          _c("li", [_vm._v("Lines")]),
          _vm._v(" "),
          _c("li", [_vm._v("Users")]),
          _vm._v(" "),
          _c("li", [_vm._v("Teams")]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }