import axios from 'axios'

const baseApiUrl = '/api/v1/integrations/property-mappings/hubspot'

const state = {
  propertyMappings: [],
  crmContactProperties: [],
  alowareContactProperties: []
}

const getters = {
  getPropertyMappings: state => state.propertyMappings,
  getCrmContactProperties: state => state.crmContactProperties,
  getAlowareContactProperties: state => state.alowareContactProperties
}

const actions = {
  async fetchPropertyMappings({ commit }) {
    const token = localStorage.getItem('api_token')
    const res = await axios.get(baseApiUrl, {
      headers: { Authorization: `Bearer ${token}` }
    })
    commit('SET_PROPERTY_MAPPINGS', res.data.mappings)
  },
  async storePropertyMappings({ commit }, payload) {
    const token = localStorage.getItem('api_token')
    const res = await axios.post(`/api/v1/integrations/property-mappings/${payload.integration}`,payload, {
      headers: { Authorization: `Bearer ${token}` }
    })
    commit('SET_PROPERTY_MAPPINGS', res.data.mappings)
  },
  async fetchCrmContactProperties({ commit }) {
    const token = localStorage.getItem('api_token')
    const res = await axios.get(`${baseApiUrl}/hubspot-contact-properties`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    commit('SET_CRM_CONTACT_PROPERTIES', res.data.properties)
  },
  async fetchAlowareContactProperties({ commit }) {
    const token = localStorage.getItem('api_token')
    const res = await axios.get(`${baseApiUrl}/aloware-contact-properties`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    commit('SET_ALOWARE_CONTACT_PROPERTIES', res.data.properties)
  },
  async getIntegrationPropertyMappings({ commit }, integration) {
    const { data } = await axios.get(`/api/v1/integrations/property-mappings/${integration}`)
    commit('SET_PROPERTY_MAPPINGS', data.mappings)
  },

  async getIntegrationItemProperties({ commit }, integration) {
    const { data } = await axios.get(`/api/v1/integrations/property-mappings/${integration}/integration-contact-properties`)
    commit('SET_CRM_CONTACT_PROPERTIES', data.properties)
  },

  async getAlowareDefaultProperties({ commit }, integration) {
    const { data } = await axios.get(`/api/v1/integrations/property-mappings/${integration}/aloware-contact-properties`)
    commit('SET_ALOWARE_CONTACT_PROPERTIES', data.properties)
  },
}

const mutations = {
  SET_PROPERTY_MAPPINGS: (state, data) => (state.propertyMappings = data),
  SET_CRM_CONTACT_PROPERTIES: (state, data) => (state.crmContactProperties = data),
  SET_ALOWARE_CONTACT_PROPERTIES: (state, data) => (state.alowareContactProperties = data)
}

export default { namespaced: true, state, getters, actions, mutations }
