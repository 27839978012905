var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "35vw",
        top: "10vh",
        "append-to-body": "",
        title: "Import From Salesforce List",
        "before-close": _vm.closeSalesforceListImport,
        visible: _vm.show_dialog,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show_dialog = $event
        },
      },
    },
    [
      _c("al-alert", { attrs: { dimisable: true } }, [
        _c("span", {
          staticClass: "mr-5 text-dark break-word",
          domProps: {
            innerHTML: _vm._s(_vm.TAGS_DEPRECATION_IMPORT_CONTACTS_MESSAGE),
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "import_salesforce_form",
              staticClass: "mt-2 p-0",
              attrs: {
                "label-position": "top",
                rules: _vm.rules,
                model: _vm.salesforce_list_form,
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.importSalesforceList.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "mb-3",
                  attrs: { prop: "list_id", label: "Select a Salesforce list" },
                },
                [
                  _c("salesforce-list-selector", {
                    ref: "salesforceListSelector",
                    attrs: { value: _vm.salesforce_list_form.list_id },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.salesforce_list_form,
                          "list_id",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "container-footer d-flex mb-0 mt-5" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.closeSalesforceListImport },
                },
                [_vm._v("\n                Close\n            ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml-auto",
                  attrs: {
                    disabled: _vm.disableImportButton,
                    type: "success",
                    size: "small",
                    loading: _vm.checking,
                  },
                  on: { click: _vm.checkIfSalesforceListAlreadyExist },
                },
                [_vm._v("\n                Import\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }