var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.communication
    ? _c(
        "div",
        [
          _c(
            "el-collapse",
            {
              staticClass: "border-0 contact-activity",
              attrs: { accordion: "" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { name: "main" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("div", { staticClass: "text-lt p-x" }, [
                      _c(
                        "span",
                        [
                          _vm.communication.disposition_status2
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: _vm.dispositionTooltipData(
                                      _vm.communication.disposition_status2,
                                      _vm.communication.direction,
                                      _vm.communication.type,
                                      _vm.communication.callback_status
                                    ),
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      state:
                                        _vm.communication.disposition_status2,
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.stateToIcon(
                                          _vm.communication.disposition_status2,
                                          _vm.communication.direction,
                                          _vm.communication.type,
                                          _vm.communication.callback_status
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          ![
                            _vm.CommunicationTypes.NOTE,
                            _vm.CommunicationTypes.SYSNOTE,
                            _vm.CommunicationTypes.APPOINTMENT,
                            _vm.CommunicationTypes.REMINDER,
                          ].includes(_vm.communication.type)
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("fixCommDirection")(
                                      _vm.communication.direction
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("fixCommType")(_vm.communication.type)
                              ) +
                              "\n                    "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.communication.type === _vm.CommunicationTypes.CALL &&
                      _vm.activity_mode
                        ? _c("span", [
                            _vm._v(
                              "\n                        - " +
                                _vm._s(
                                  _vm._f("fixDuration")(
                                    _vm.communication.duration
                                  )
                                ) +
                                "\n                    "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "p-x p-t b-t b-light" },
                    [
                      [
                        _vm.CommunicationTypes.SMS,
                        _vm.CommunicationTypes.EMAIL,
                        _vm.CommunicationTypes.NOTE,
                        _vm.CommunicationTypes.SYSNOTE,
                        _vm.CommunicationTypes.APPOINTMENT,
                        _vm.CommunicationTypes.REMINDER,
                      ].includes(_vm.communication.type)
                        ? [
                            _vm._l(
                              _vm.communication.attachments,
                              function (attachment, index) {
                                return _vm.communication.attachments &&
                                  _vm.communication.attachments.length > 0
                                  ? _c(
                                      "div",
                                      { key: index },
                                      [
                                        (attachment.mime_type &&
                                          _vm.isAttachmentImage(
                                            attachment.mime_type
                                          )) ||
                                        !attachment.mime_type
                                          ? _c("vue-load-image", [
                                              _c("img", {
                                                staticClass:
                                                  "img-fluid d-block r-2x",
                                                class: index > 0 ? "mb-1" : "",
                                                attrs: {
                                                  slot: "image",
                                                  src: attachment.url,
                                                },
                                                slot: "image",
                                              }),
                                              _vm._v(" "),
                                              _c("img", {
                                                attrs: {
                                                  slot: "preloader",
                                                  src: "/assets/images/loading.svg",
                                                },
                                                slot: "preloader",
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "error" },
                                                  slot: "error",
                                                },
                                                [
                                                  _vm._v(
                                                    "Error on loading the image attachment"
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        attachment.mime_type
                                          ? [
                                              attachment.mime_type &&
                                              _vm.isAttachmentAudio(
                                                attachment.mime_type
                                              )
                                                ? _c("div", [
                                                    _c(
                                                      "audio",
                                                      {
                                                        staticClass:
                                                          "audio-player",
                                                        attrs: { controls: "" },
                                                      },
                                                      [
                                                        _c("source", {
                                                          attrs: {
                                                            src: attachment.url,
                                                            type: attachment.mime_type,
                                                          },
                                                        }),
                                                        _vm._v(
                                                          "\n                                    Your browser does not support the audio element.\n                                "
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              attachment.mime_type &&
                                              _vm.isAttachmentVideo(
                                                attachment.mime_type
                                              )
                                                ? _c("div", [
                                                    _c(
                                                      "video",
                                                      {
                                                        staticClass: "rounded",
                                                        attrs: {
                                                          width: "320",
                                                          controls: "",
                                                        },
                                                      },
                                                      [
                                                        _c("source", {
                                                          attrs: {
                                                            src: attachment.url,
                                                            type: attachment.mime_type,
                                                          },
                                                        }),
                                                        _vm._v(
                                                          "\n                                    Your browser does not support the video tag.\n                                "
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              attachment.mime_type &&
                                              (_vm.isAttachmentText(
                                                attachment.mime_type
                                              ) ||
                                                _vm.isAttachmentApplication(
                                                  attachment.mime_type
                                                ))
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: attachment.url,
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "p-2 text-center",
                                                        },
                                                        [
                                                          _c("figure", [
                                                            _c("img", {
                                                              attrs: {
                                                                height: "100",
                                                                width: "100",
                                                                src: "/assets/images/app-icons/file.svg",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("figcaption", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  attachment.name
                                                                    ? attachment.name
                                                                    : "Click Here To Download"
                                                                )
                                                              ),
                                                            ]),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              }
                            ),
                            _vm._v(" "),
                            _vm.communication.body
                              ? _c("div", [
                                  _vm.communication.type !==
                                  _vm.CommunicationTypes.SMS
                                    ? _c("span", {
                                        directives: [
                                          {
                                            name: "linkified",
                                            rawName: "v-linkified:options",
                                            value: { target: "_blank" },
                                            expression: "{ target: '_blank' }",
                                            arg: "options",
                                          },
                                        ],
                                        staticClass: "text-muted",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$options.filters.nl2br(
                                              _vm.parsedBody
                                            )
                                          ),
                                        },
                                      })
                                    : _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "linkified",
                                              rawName: "v-linkified:options",
                                              value: { target: "_blank" },
                                              expression:
                                                "{ target: '_blank' }",
                                              arg: "options",
                                            },
                                          ],
                                          staticClass: "text-muted",
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(_vm.communication.body) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                ])
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.communication.type === _vm.CommunicationTypes.CALL
                        ? _c("span", { staticClass: "text-muted" }, [
                            _vm._v(
                              "\n                    This call " +
                                _vm._s(
                                  _vm.communication.disposition_status2 ===
                                    _vm.CommunicationDispositionStatus
                                      .DISPOSITION_STATUS_INPROGRESS_NEW
                                    ? "is"
                                    : "was"
                                ) +
                                " " +
                                _vm._s(
                                  _vm._f("replaceDash")(
                                    _vm._f("translateDispositionStatusText")(
                                      _vm.communication.disposition_status2
                                    )
                                  )
                                ) +
                                ".\n                "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.communication.type === _vm.CommunicationTypes.CALL &&
                      _vm.current_company &&
                      _vm.call_dispositions.length > 0 &&
                      _vm.dialer_mode
                        ? _c(
                            "div",
                            { staticClass: "form-horizontal b-b mt-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "form-group mb-2 mt-3" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-items-center",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label p-0",
                                        },
                                        [_vm._v("Call Disposition:")]
                                      ),
                                      _vm._v(" "),
                                      _vm.current_company.force_call_disposition
                                        ? _c(
                                            "el-popover",
                                            {
                                              staticClass: "ml-1",
                                              attrs: {
                                                placement: "right",
                                                width: "200",
                                                trigger: "click",
                                                content:
                                                  "Your account admin has mandated call dispositions.",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticClass: "p-0",
                                                  attrs: {
                                                    slot: "reference",
                                                    type: "text",
                                                  },
                                                  slot: "reference",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-info text-danger",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center pt-2",
                                    },
                                    [
                                      _c("call-disposition-selector", {
                                        attrs: {
                                          communication: _vm.communication,
                                        },
                                        on: { callDisposed: _vm.callDisposed },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.current_company.hubspot_integration_enabled
                                ? _c(
                                    "div",
                                    { staticClass: "form-group mb-2 mt-3" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "form-control-label p-0",
                                            },
                                            [_vm._v("HubSpot Type:")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center pt-2",
                                        },
                                        [
                                          _c("hubspot-activity-type-selector", {
                                            attrs: {
                                              communication: _vm.communication,
                                            },
                                            on: {
                                              activityTypeChosen:
                                                _vm.activityTypeChosen,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.verbose || _vm.display_contact
                        ? _c(
                            "div",
                            { staticClass: "form-horizontal b-b mt-2" },
                            [
                              _vm.communication.contact
                                ? _c(
                                    "div",
                                    { staticClass: "form-group row mb-0" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-5"
                                              : "col-xl-5 col-12",
                                          ],
                                        },
                                        [_vm._v("Contact:")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-7"
                                              : "col-xl-7 col-12",
                                          ],
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                target: "_blank",
                                                href: _vm.getContactTalkRedirectURL(
                                                  _vm.communication.contact_id
                                                ),
                                              },
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item pull-left",
                                                  attrs: {
                                                    effect: "dark",
                                                    content:
                                                      "Click For More Info",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-dark-greenish",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "fixContactName"
                                                            )(
                                                              _vm.communication
                                                                .contact.name
                                                            )
                                                          ) +
                                                          "\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.communication.contact &&
                              _vm.disposition_statuses.length > 0 &&
                              _vm.dialer_mode &&
                              !_vm.hide_contact_disposition
                                ? _c(
                                    "div",
                                    { staticClass: "form-group mb-2" },
                                    [
                                      _vm.hasPermissionTo("dispose contact")
                                        ? _c(
                                            "label",
                                            {
                                              staticClass:
                                                "form-control-label p-0",
                                            },
                                            [_vm._v("Contact Disposition:")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.current_company
                                        .force_contact_disposition
                                        ? _c(
                                            "el-popover",
                                            {
                                              staticClass: "ml-1",
                                              attrs: {
                                                content:
                                                  "Your account admin has mandated contact dispositions.",
                                                placement: "right",
                                                trigger: "click",
                                                width: "200",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticClass: "p-0",
                                                  attrs: {
                                                    slot: "reference",
                                                    type: "text",
                                                  },
                                                  slot: "reference",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-info text-danger",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasPermissionTo("dispose contact")
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center pt-2",
                                            },
                                            [
                                              _c(
                                                "contact-disposition-selector",
                                                {
                                                  key:
                                                    "contact-disposition-" +
                                                    _vm.communication
                                                      .contact_id,
                                                  attrs: {
                                                    contact:
                                                      _vm.communication.contact,
                                                  },
                                                  on: {
                                                    contactDisposed:
                                                      _vm.contactDisposed,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.dialer_mode
                        ? _c(
                            "div",
                            { staticClass: "form-horizontal b-b mt-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "form-group mb-2" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "form-control-label p-0" },
                                    [_vm._v("Send message:")]
                                  ),
                                  _vm._v(" "),
                                  _c("send-template", {
                                    attrs: {
                                      contact_id: _vm.communication.contact_id,
                                      campaign_id:
                                        _vm.communication.campaign_id,
                                      phone_number:
                                        _vm.communication.lead_number,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      (_vm.verbose || _vm.activity_mode) &&
                      ![
                        _vm.CommunicationTypes.NOTE,
                        _vm.CommunicationTypes.SYSNOTE,
                        _vm.CommunicationTypes.APPOINTMENT,
                        _vm.CommunicationTypes.REMINDER,
                      ].includes(_vm.communication.type) &&
                      !_vm.dialer_mode
                        ? _c("div", { staticClass: "form-horizontal b-b a" }, [
                            _c("div", { staticClass: "form-group row mb-0" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "form-control-label col-xl-5 col-12",
                                },
                                [_vm._v("Disposition:")]
                              ),
                              _vm._v(" "),
                              _vm.communication.direction ===
                                _vm.CommunicationDirections.INBOUND &&
                              _vm.communication.disposition_status2 ===
                                _vm.CommunicationDispositionStatus
                                  .DISPOSITION_STATUS_ABANDONED_NEW &&
                              [
                                _vm.CallbackStatus.CALLBACK_STATUS_INITIATED,
                                _vm.CallbackStatus.CALLBACK_STATUS_REQUESTED,
                              ].includes(_vm.communication.callback_status)
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center col-xl-7 col-12",
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Callback " +
                                          _vm._s(
                                            _vm._f("capitalize")(
                                              _vm._f("replaceDash")(
                                                _vm._f(
                                                  "translateCallbackStatusText"
                                                )(
                                                  _vm.communication
                                                    .callback_status
                                                )
                                              )
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center col-xl-7 col-12",
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm._f("capitalize")(
                                              _vm._f("replaceDash")(
                                                _vm._f(
                                                  "translateDispositionStatusText"
                                                )(
                                                  _vm.communication
                                                    .disposition_status2
                                                )
                                              )
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      (_vm.verbose || _vm.activity_mode) &&
                      ![
                        _vm.CommunicationTypes.NOTE,
                        _vm.CommunicationTypes.SYSNOTE,
                        _vm.CommunicationTypes.APPOINTMENT,
                        _vm.CommunicationTypes.REMINDER,
                      ].includes(_vm.communication.type)
                        ? _c("div", { staticClass: "form-horizontal" }, [
                            _c("div", { staticClass: "form-group row mb-0" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  class: [
                                    _vm.dialer_mode
                                      ? "col-5"
                                      : "col-xl-5 col-12",
                                  ],
                                },
                                [_vm._v("From:")]
                              ),
                              _vm._v(" "),
                              _vm.communication.direction ===
                                _vm.CommunicationDirections.INBOUND &&
                              _vm.communication.type !==
                                _vm.CommunicationTypes.EMAIL
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-control-item d-flex align-items-center",
                                      class: [
                                        _vm.dialer_mode
                                          ? "col-7"
                                          : "col-xl-7 col-12",
                                      ],
                                    },
                                    [
                                      _c("div", [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("fixPhone")(
                                                _vm.communication.lead_number
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _vm.communication.contact
                                          ? _c(
                                              "a",
                                              {
                                                attrs: {
                                                  target: "_blank",
                                                  href: _vm.getContactTalkRedirectURL(
                                                    _vm.communication.contact_id
                                                  ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass:
                                                      "item pull-left",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        "Click For More Info",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-dark-greenish",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "fixContactName"
                                                              )(
                                                                _vm
                                                                  .communication
                                                                  .contact.name
                                                              )
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  )
                                : _vm.communication.direction ===
                                    _vm.CommunicationDirections.INBOUND &&
                                  _vm.communication.type ===
                                    _vm.CommunicationTypes.EMAIL
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-control-item d-flex align-items-center",
                                      class: [
                                        _vm.dialer_mode
                                          ? "col-7"
                                          : "col-xl-7 col-12",
                                      ],
                                    },
                                    [
                                      _c("div", [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.communication.lead_number
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _vm.communication.contact
                                          ? _c(
                                              "a",
                                              {
                                                attrs: {
                                                  target: "_blank",
                                                  href: _vm.getContactTalkRedirectURL(
                                                    _vm.communication.contact_id
                                                  ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass:
                                                      "item pull-left",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        "Click For More Info",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-dark-greenish",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "fixContactName"
                                                              )(
                                                                _vm
                                                                  .communication
                                                                  .contact.name
                                                              )
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  )
                                : _vm.communication.direction ===
                                    _vm.CommunicationDirections.OUTBOUND &&
                                  _vm.communication.type ===
                                    _vm.CommunicationTypes.EMAIL
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-control-item d-flex align-items-center",
                                      class: [
                                        _vm.dialer_mode
                                          ? "col-7"
                                          : "col-xl-7 col-12",
                                      ],
                                    },
                                    [
                                      _c("div", [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.communication.incoming_number
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _vm.usedCampaign
                                          ? _c(
                                              "div",
                                              { staticClass: "d-flex" },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: {
                                                        name: "Line Activity",
                                                        params: {
                                                          campaign_id:
                                                            _vm.usedCampaign.id,
                                                        },
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        staticClass:
                                                          "item pull-left",
                                                        attrs: {
                                                          effect: "dark",
                                                          content:
                                                            "Click For More Info",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-dark-greenish",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  _vm
                                                                    .usedCampaign
                                                                    ?.name
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "User Activity",
                                                    params: {
                                                      user_id:
                                                        _vm.communication
                                                          .user_id,
                                                    },
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass:
                                                      "item pull-left",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        "Click For More Info",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-dark-greenish",
                                                        attrs: {
                                                          title:
                                                            _vm.getUserName(
                                                              _vm.getUser(
                                                                _vm
                                                                  .communication
                                                                  .user_id,
                                                                _vm
                                                                  .communication
                                                                  ?.user
                                                              )
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm.getUserName(
                                                                _vm.getUser(
                                                                  _vm
                                                                    .communication
                                                                    .user_id,
                                                                  _vm
                                                                    .communication
                                                                    ?.user
                                                                )
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-control-item d-flex align-items-center",
                                      class: [
                                        _vm.dialer_mode
                                          ? "col-7"
                                          : "col-xl-7 col-12",
                                      ],
                                    },
                                    [
                                      _c("div", [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("fixPhone")(
                                                _vm.communication
                                                  .incoming_number
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "d-flex" },
                                          [
                                            _vm.usedCampaign
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: {
                                                        name: "Line Activity",
                                                        params: {
                                                          campaign_id:
                                                            _vm.usedCampaign.id,
                                                        },
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        staticClass:
                                                          "item pull-left",
                                                        attrs: {
                                                          effect: "dark",
                                                          content:
                                                            "Click For More Info",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-dark-greenish",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  _vm
                                                                    .usedCampaign
                                                                    ?.name
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "User Activity",
                                                    params: {
                                                      user_id:
                                                        _vm.communication
                                                          .user_id,
                                                    },
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass:
                                                      "item pull-left",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        "Click For More Info",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-dark-greenish",
                                                        attrs: {
                                                          title:
                                                            _vm.getUserName(
                                                              _vm.getUser(
                                                                _vm
                                                                  .communication
                                                                  .user_id,
                                                                _vm
                                                                  .communication
                                                                  ?.user
                                                              )
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm.getUserName(
                                                                _vm.getUser(
                                                                  _vm
                                                                    .communication
                                                                    .user_id,
                                                                  _vm
                                                                    .communication
                                                                    ?.user
                                                                )
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      (_vm.verbose || _vm.activity_mode) &&
                      ![
                        _vm.CommunicationTypes.NOTE,
                        _vm.CommunicationTypes.SYSNOTE,
                        _vm.CommunicationTypes.APPOINTMENT,
                        _vm.CommunicationTypes.REMINDER,
                      ].includes(_vm.communication.type)
                        ? _c("div", { staticClass: "form-horizontal b-b" }, [
                            _c("div", { staticClass: "form-group row mb-0" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  class: [
                                    _vm.dialer_mode
                                      ? "col-5"
                                      : "col-xl-5 col-12",
                                  ],
                                },
                                [_vm._v("To:")]
                              ),
                              _vm._v(" "),
                              _vm.communication.direction ===
                                _vm.CommunicationDirections.INBOUND &&
                              _vm.communication.type !==
                                _vm.CommunicationTypes.EMAIL
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-control-item d-flex align-items-center",
                                      class: [
                                        _vm.dialer_mode
                                          ? "col-7"
                                          : "col-xl-7 col-12",
                                      ],
                                    },
                                    [
                                      _c("div", [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("fixPhone")(
                                                _vm.communication
                                                  .incoming_number
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _vm.usedCampaign
                                          ? _c(
                                              "div",
                                              { staticClass: "d-flex" },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: {
                                                        name: "Line Activity",
                                                        params: {
                                                          campaign_id:
                                                            _vm.usedCampaign.id,
                                                        },
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        staticClass:
                                                          "item pull-left",
                                                        attrs: {
                                                          effect: "dark",
                                                          content:
                                                            "Click For More Info",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-dark-greenish",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  _vm
                                                                    .usedCampaign
                                                                    ?.name
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          [
                                            _vm.communication.user_id
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: {
                                                        name: "User Activity",
                                                        params: {
                                                          user_id:
                                                            _vm.communication
                                                              .user_id,
                                                        },
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        staticClass:
                                                          "item pull-left",
                                                        attrs: {
                                                          effect: "dark",
                                                          content:
                                                            "Click For More Info",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-dark-greenish",
                                                            attrs: {
                                                              title:
                                                                _vm.getUserName(
                                                                  _vm.getUser(
                                                                    _vm
                                                                      .communication
                                                                      .user_id,
                                                                    _vm
                                                                      .communication
                                                                      ?.user
                                                                  )
                                                                ),
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  _vm.getUserName(
                                                                    _vm.getUser(
                                                                      _vm
                                                                        .communication
                                                                        .user_id,
                                                                      _vm
                                                                        .communication
                                                                        ?.user
                                                                    )
                                                                  )
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm.communication.direction ===
                                    _vm.CommunicationDirections.INBOUND &&
                                  _vm.communication.type ===
                                    _vm.CommunicationTypes.EMAIL
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-control-item d-flex align-items-center",
                                      class: [
                                        _vm.dialer_mode
                                          ? "col-7"
                                          : "col-xl-7 col-12",
                                      ],
                                    },
                                    [
                                      _c("div", [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("fixPhone")(
                                                _vm.communication
                                                  .incoming_number
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _vm.usedCampaign
                                          ? _c(
                                              "div",
                                              { staticClass: "d-flex" },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: {
                                                        name: "Line Activity",
                                                        params: {
                                                          campaign_id:
                                                            _vm.usedCampaign.id,
                                                        },
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        staticClass:
                                                          "item pull-left",
                                                        attrs: {
                                                          effect: "dark",
                                                          content:
                                                            "Click For More Info",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-dark-greenish",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  _vm
                                                                    .usedCampaign
                                                                    ?.name
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.communication.contact
                                          ? _c(
                                              "a",
                                              {
                                                attrs: {
                                                  target: "_blank",
                                                  href: _vm.getContactTalkRedirectURL(
                                                    _vm.communication.contact_id
                                                  ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass:
                                                      "item pull-left",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        "Click For More Info",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-dark-greenish",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "fixContactName"
                                                              )(
                                                                _vm
                                                                  .communication
                                                                  .contact.name
                                                              )
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  )
                                : _vm.communication.direction ===
                                    _vm.CommunicationDirections.OUTBOUND &&
                                  _vm.communication.type ===
                                    _vm.CommunicationTypes.EMAIL
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-control-item d-flex align-items-center",
                                      class: [
                                        _vm.dialer_mode
                                          ? "col-7"
                                          : "col-xl-7 col-12",
                                      ],
                                    },
                                    [
                                      _c("div", [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.communication.lead_number
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _vm.communication.contact
                                          ? _c(
                                              "a",
                                              {
                                                attrs: {
                                                  target: "_blank",
                                                  href: _vm.getContactTalkRedirectURL(
                                                    _vm.communication.contact_id
                                                  ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass:
                                                      "item pull-left",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        "Click For More Info",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-dark-greenish",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "fixContactName"
                                                              )(
                                                                _vm
                                                                  .communication
                                                                  .contact.name
                                                              )
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-control-item d-flex align-items-center",
                                      class: [
                                        _vm.dialer_mode
                                          ? "col-7"
                                          : "col-xl-7 col-12",
                                      ],
                                    },
                                    [
                                      _c("div", [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("fixPhone")(
                                                _vm.communication.lead_number
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _vm.communication.contact
                                          ? _c(
                                              "a",
                                              {
                                                attrs: {
                                                  target: "_blank",
                                                  href: _vm.getContactTalkRedirectURL(
                                                    _vm.communication.contact_id
                                                  ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass:
                                                      "item pull-left",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        "Click For More Info",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-dark-greenish",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "fixContactName"
                                                              )(
                                                                _vm
                                                                  .communication
                                                                  .contact.name
                                                              )
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.communication.type === _vm.CommunicationTypes.CALL &&
                      !_vm.dialer_mode
                        ? _c(
                            "div",
                            { staticClass: "form-horizontal b-b" },
                            [
                              _c("target-users-tree", {
                                staticClass: "form-group row mb-0 pt-2 pb-2",
                                attrs: {
                                  communication: _vm.communication,
                                  isForm: true,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      [
                        _vm.CommunicationTypes.CALL,
                        _vm.CommunicationTypes.SMS,
                        _vm.CommunicationTypes.EMAIL,
                        _vm.CommunicationTypes.NOTE,
                        _vm.CommunicationTypes.APPOINTMENT,
                        _vm.CommunicationTypes.REMINDER,
                      ].includes(_vm.communication.type)
                        ? _c(
                            "div",
                            {
                              staticClass: "form-horizontal",
                              class: [
                                _vm.communication.type !==
                                _vm.CommunicationTypes.NOTE
                                  ? "b-b"
                                  : "",
                              ],
                            },
                            [
                              _vm.communication.type !==
                              _vm.CommunicationTypes.NOTE
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-group row mb-0 pt-2 pb-2",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-5"
                                              : "col-xl-5 col-12",
                                          ],
                                        },
                                        [_vm._v("User:")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-7"
                                              : "col-xl-7 col-12",
                                          ],
                                        },
                                        [
                                          _vm.communication.rejected_by_app !==
                                          0
                                            ? _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    "popper-class":
                                                      "rejection-tooltip",
                                                    content:
                                                      _vm.rejectionTooltipData(
                                                        _vm.communication
                                                          .rejected_by_app,
                                                        _vm.communication.type
                                                      ),
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "status-icon d-inline-block",
                                                    attrs: {
                                                      state:
                                                        _vm.communication
                                                          .rejected_by_app,
                                                    },
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.rejectionToIcon(
                                                          _vm.communication
                                                            .rejected_by_app
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm.getUser(
                                                _vm.communication.user_id,
                                                _vm.communication?.user
                                              )
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: {
                                                          name: "User Activity",
                                                          params: {
                                                            user_id:
                                                              _vm.communication
                                                                .user_id,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          staticClass:
                                                            "item pull-left",
                                                          attrs: {
                                                            effect: "dark",
                                                            content:
                                                              "Click For More Info",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm.communication
                                                              .user.type ===
                                                            _vm.User
                                                              .TYPE_AI_AGENT
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "ai-effect-gradient-text",
                                                                    attrs: {
                                                                      title:
                                                                        _vm.getUserName(
                                                                          _vm.getUser(
                                                                            _vm
                                                                              .communication
                                                                              .user_id,
                                                                            _vm
                                                                              .communication
                                                                              ?.user
                                                                          )
                                                                        ),
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "sparkle-icon",
                                                                      {
                                                                        attrs: {
                                                                          width:
                                                                            "16",
                                                                          height:
                                                                            "16",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(
                                                                      "\n                                                " +
                                                                        _vm._s(
                                                                          _vm.getUserName(
                                                                            _vm.getUser(
                                                                              _vm
                                                                                .communication
                                                                                .user_id,
                                                                              _vm
                                                                                .communication
                                                                                ?.user
                                                                            )
                                                                          )
                                                                        ) +
                                                                        "\n                                            "
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-dark-greenish",
                                                                    attrs: {
                                                                      title:
                                                                        _vm.getUserName(
                                                                          _vm.getUser(
                                                                            _vm
                                                                              .communication
                                                                              .user_id,
                                                                            _vm
                                                                              .communication
                                                                              ?.user
                                                                          )
                                                                        ),
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                " +
                                                                        _vm._s(
                                                                          _vm.getUserName(
                                                                            _vm.getUser(
                                                                              _vm
                                                                                .communication
                                                                                .user_id,
                                                                              _vm
                                                                                .communication
                                                                                ?.user
                                                                            )
                                                                          )
                                                                        ) +
                                                                        "\n                                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c("div", [
                                                _vm.communication
                                                  .resolution2 ===
                                                _vm.CommunicationResolution
                                                  .RESOLUTION_ALOAI_NEW
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "ai-effect-gradient-text",
                                                      },
                                                      [
                                                        _c("sparkle-icon", {
                                                          attrs: {
                                                            width: "16",
                                                            height: "16",
                                                          },
                                                        }),
                                                        _vm._v(
                                                          "\n                                    AloAi Agent\n                                "
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-greyish",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    -\n                                "
                                                        ),
                                                      ]
                                                    ),
                                              ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.communication.type === _vm.CommunicationTypes.CALL &&
                      _vm.communication.attempting_users &&
                      _vm.communication.attempting_users.length > 0 &&
                      _vm.verbose
                        ? _c("div", { staticClass: "form-horizontal b-b" }, [
                            _c(
                              "div",
                              { staticClass: "form-group row mb-0 pt-2 pb-2" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-control-label",
                                    class: [
                                      _vm.dialer_mode
                                        ? "col-5"
                                        : "col-xl-5 col-12",
                                    ],
                                  },
                                  [_vm._v("Attempting Users:")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex align-items-center",
                                    class: [
                                      _vm.dialer_mode
                                        ? "col-7"
                                        : "col-xl-7 col-12",
                                    ],
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-greyish" },
                                      [
                                        _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "list list-unstyled inset mb-0",
                                          },
                                          _vm._l(
                                            _vm.communication.attempting_users,
                                            function (attempting_user, index) {
                                              return _vm.getUser(
                                                attempting_user
                                              )
                                                ? _c(
                                                    "li",
                                                    {
                                                      key:
                                                        attempting_user +
                                                        "-user-" +
                                                        index,
                                                      staticClass: "pb-1",
                                                    },
                                                    [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          attrs: {
                                                            to: {
                                                              name: "User Activity",
                                                              params: {
                                                                user_id:
                                                                  _vm.getUser(
                                                                    attempting_user
                                                                  ).id,
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              class:
                                                                _vm.getAttemptingClass(
                                                                  attempting_user,
                                                                  _vm
                                                                    .communication
                                                                    .disposition_status2,
                                                                  _vm
                                                                    .communication
                                                                    .user_id
                                                                ),
                                                              attrs: {
                                                                title:
                                                                  _vm.getUserName(
                                                                    _vm.getUser(
                                                                      attempting_user
                                                                    )
                                                                  ),
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                " +
                                                                  _vm._s(
                                                                    _vm.getUserName(
                                                                      _vm.getUser(
                                                                        attempting_user
                                                                      )
                                                                    )
                                                                  ) +
                                                                  "\n                                            "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.communication.type === _vm.CommunicationTypes.CALL &&
                      _vm.communication.metadata &&
                      _vm.communication.metadata.reports &&
                      _vm.verbose
                        ? _c("div", { staticClass: "form-horizontal b-b" }, [
                            _c(
                              "div",
                              { staticClass: "form-group row mb-0 pt-2 pb-2" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-control-label",
                                    class: [
                                      _vm.dialer_mode
                                        ? "col-5"
                                        : "col-xl-5 col-12",
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Answered with Fishing Mode:\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex align-items-center",
                                    class: [
                                      _vm.dialer_mode
                                        ? "col-7"
                                        : "col-xl-7 col-12",
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.communication.metadata.reports
                                          .is_fishing
                                          ? "Yes"
                                          : "No"
                                      ) + "\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-control-label",
                                    class: [
                                      _vm.dialer_mode
                                        ? "col-5"
                                        : "col-xl-5 col-12",
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Barged:\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex align-items-center",
                                    class: [
                                      _vm.dialer_mode
                                        ? "col-7"
                                        : "col-xl-7 col-12",
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.communication.metadata.reports
                                          .is_barge
                                          ? "Yes"
                                          : "No"
                                      ) + "\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-control-label",
                                    class: [
                                      _vm.dialer_mode
                                        ? "col-5"
                                        : "col-xl-5 col-12",
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Whispered:\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex align-items-center",
                                    class: [
                                      _vm.dialer_mode
                                        ? "col-7"
                                        : "col-xl-7 col-12",
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.communication.metadata.reports
                                          .is_whisper
                                          ? "Yes"
                                          : "No"
                                      ) + "\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-control-label",
                                    class: [
                                      _vm.dialer_mode
                                        ? "col-5"
                                        : "col-xl-5 col-12",
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Queued:\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex align-items-center",
                                    class: [
                                      _vm.dialer_mode
                                        ? "col-7"
                                        : "col-xl-7 col-12",
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.communication.metadata.reports
                                          .is_queued
                                          ? "Yes"
                                          : "No"
                                      ) + "\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.communication.disposition_status2 ===
                                  _vm.CommunicationDispositionStatus
                                    .DISPOSITION_STATUS_ABANDONED_NEW &&
                                _vm.communication.metadata.reports
                                  .last_queue_position &&
                                _vm.communication.metadata.reports
                                  .last_queue_position !== null
                                  ? _c(
                                      "label",
                                      {
                                        staticClass: "form-control-label",
                                        class: [
                                          _vm.dialer_mode
                                            ? "col-5"
                                            : "col-xl-5 col-12",
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Last position in queue:\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.communication.disposition_status2 ===
                                  _vm.CommunicationDispositionStatus
                                    .DISPOSITION_STATUS_ABANDONED_NEW &&
                                _vm.communication.metadata.reports
                                  .last_queue_position &&
                                _vm.communication.metadata.reports
                                  .last_queue_position !== null
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                        class: [
                                          _vm.dialer_mode
                                            ? "col-7"
                                            : "col-xl-7 col-12",
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.communication.metadata.reports
                                              .last_queue_position
                                          ) + "\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      ![
                        _vm.CommunicationTypes.NOTE,
                        _vm.CommunicationTypes.SYSNOTE,
                        _vm.CommunicationTypes.APPOINTMENT,
                        _vm.CommunicationTypes.REMINDER,
                      ].includes(_vm.communication.type)
                        ? _c("div", { staticClass: "form-horizontal" }, [
                            _c("div", { staticClass: "form-group row mb-0" }, [
                              _vm.communication.type ===
                              _vm.CommunicationTypes.CALL
                                ? _c(
                                    "label",
                                    {
                                      staticClass: "form-control-label",
                                      class: [
                                        _vm.dialer_mode
                                          ? "col-5"
                                          : "col-xl-5 col-12",
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Started at:\n                        "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "label",
                                    {
                                      staticClass: "form-control-label",
                                      class: [
                                        _vm.dialer_mode
                                          ? "col-5"
                                          : "col-xl-5 col-12",
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Sent at:\n                        "
                                      ),
                                    ]
                                  ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-items-center",
                                  class: [
                                    _vm.dialer_mode
                                      ? "col-7"
                                      : "col-xl-7 col-12",
                                  ],
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm._f("fixCommunicationDateTime")(
                                          _vm.communication.created_at
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.communication.type === _vm.CommunicationTypes.SMS
                        ? _c("div", { staticClass: "form-horizontal b-b" }, [
                            _c("div", { staticClass: "form-group row mb-0" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  class: [
                                    _vm.dialer_mode
                                      ? "col-5"
                                      : "col-xl-5 col-12",
                                  ],
                                },
                                [
                                  _vm._v(
                                    "\n                            Current Status:\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-items-center",
                                  class: [
                                    _vm.dialer_mode
                                      ? "col-7"
                                      : "col-xl-7 col-12",
                                  ],
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm._f("capitalize")(
                                          _vm._f("replaceDash")(
                                            _vm.communication
                                              .current_status2 !==
                                              _vm.CommunicationCurrentStatus
                                                .CURRENT_STATUS_COMPLETED_NEW
                                              ? _vm.$options.filters.translateCurrentStatusText(
                                                  _vm.communication
                                                    .current_status2
                                                )
                                              : _vm.$options.filters.translateDispositionStatusText(
                                                  _vm.communication
                                                    .disposition_status2
                                                )
                                          )
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.communication.type === _vm.CommunicationTypes.CALL &&
                      _vm.verbose
                        ? _c("div", { staticClass: "form-horizontal b-b" }, [
                            _c("div", { staticClass: "form-group row mb-0" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  class: [
                                    _vm.dialer_mode
                                      ? "col-5"
                                      : "col-xl-5 col-12",
                                  ],
                                },
                                [_vm._v("Ended at:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-items-center",
                                  class: [
                                    _vm.dialer_mode
                                      ? "col-7"
                                      : "col-xl-7 col-12",
                                  ],
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm._f("fixCommunicationDateTime")(
                                          _vm.communication.created_at,
                                          _vm.communication.duration
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      [
                        _vm.CommunicationTypes.CALL,
                        _vm.CommunicationTypes.RVM,
                      ].includes(_vm.communication.type) && _vm.verbose
                        ? _c("div", { staticClass: "form-horizontal" }, [
                            _c("div", { staticClass: "form-group row mb-0" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  class: [
                                    _vm.dialer_mode
                                      ? "col-5"
                                      : "col-xl-5 col-12",
                                  ],
                                },
                                [_vm._v("Duration:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-items-center",
                                  class: [
                                    _vm.dialer_mode
                                      ? "col-7"
                                      : "col-xl-7 col-12",
                                  ],
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm._f("fixDuration")(
                                          _vm.communication.duration
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.communication.type === _vm.CommunicationTypes.SMS &&
                      _vm.verbose
                        ? _c("div", { staticClass: "form-horizontal" }, [
                            _c("div", { staticClass: "form-group row mb-0" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  class: [
                                    _vm.dialer_mode
                                      ? "col-5"
                                      : "col-xl-5 col-12",
                                  ],
                                },
                                [_vm._v("Parts:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-items-center",
                                  class: [
                                    _vm.dialer_mode
                                      ? "col-7"
                                      : "col-xl-7 col-12",
                                  ],
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.communication.duration) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.communication.type === _vm.CommunicationTypes.CALL
                        ? _c("div", { staticClass: "form-horizontal b-b" }, [
                            _c("div", { staticClass: "form-group row mb-0" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  class: [
                                    _vm.dialer_mode
                                      ? "col-5"
                                      : "col-xl-5 col-12",
                                  ],
                                },
                                [_vm._v("Talk time:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-items-center",
                                  class: [
                                    _vm.dialer_mode
                                      ? "col-7"
                                      : "col-xl-7 col-12",
                                  ],
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm._f("fixDuration")(
                                          _vm.communication.talk_time
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.communication.type === _vm.CommunicationTypes.CALL &&
                      _vm.verbose
                        ? _c("div", { staticClass: "form-horizontal b-b" }, [
                            _c("div", { staticClass: "form-group row mb-0" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  class: [
                                    _vm.dialer_mode
                                      ? "col-5"
                                      : "col-xl-5 col-12",
                                  ],
                                },
                                [_vm._v("Wait time:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-items-center",
                                  class: [
                                    _vm.dialer_mode
                                      ? "col-7"
                                      : "col-xl-7 col-12",
                                  ],
                                },
                                [
                                  _vm.communication.disposition_status2 ===
                                  _vm.CommunicationDispositionStatus
                                    .DISPOSITION_STATUS_COMPLETED_NEW
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("fixDuration")(
                                              _vm.communication.wait_time
                                            )
                                          )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.communication.type === _vm.CommunicationTypes.CALL &&
                      _vm.verbose
                        ? _c("div", { staticClass: "form-horizontal b-b" }, [
                            _c("div", { staticClass: "form-group row mb-0" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control-label",
                                  class: [
                                    _vm.dialer_mode
                                      ? "col-5"
                                      : "col-xl-5 col-12",
                                  ],
                                },
                                [_vm._v("Hold time:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-items-center",
                                  class: [
                                    _vm.dialer_mode
                                      ? "col-7"
                                      : "col-xl-7 col-12",
                                  ],
                                },
                                [
                                  _vm.communication.disposition_status2 ===
                                  _vm.CommunicationDispositionStatus
                                    .DISPOSITION_STATUS_COMPLETED_NEW
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("fixDuration")(
                                              _vm.communication.hold_time
                                            )
                                          )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      ![
                        _vm.CommunicationTypes.NOTE,
                        _vm.CommunicationTypes.SYSNOTE,
                        _vm.CommunicationTypes.APPOINTMENT,
                        _vm.CommunicationTypes.REMINDER,
                      ].includes(_vm.communication.type)
                        ? _c("div", { staticClass: "form-horizontal" }, [
                            _c(
                              "div",
                              { staticClass: "form-group row mb-0" },
                              [
                                _vm.communication.ring_group_id
                                  ? [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-5"
                                              : "col-xl-5 col-12",
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Ring Group:\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-7"
                                              : "col-xl-7 col-12",
                                          ],
                                        },
                                        [
                                          _vm.getRingGroup(
                                            _vm.communication.ring_group_id
                                          ) &&
                                          !_vm.getRingGroup(
                                            _vm.communication.ring_group_id
                                          ).call_waiting
                                            ? _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "Ring Group Activity",
                                                      params: {
                                                        ring_group_id:
                                                          _vm.communication
                                                            .ring_group_id,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass:
                                                        "item pull-left",
                                                      attrs: {
                                                        effect: "dark",
                                                        content:
                                                          "Click For More Info",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-dark-greenish",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        " +
                                                              _vm._s(
                                                                _vm.getRingGroup(
                                                                  _vm
                                                                    .communication
                                                                    .ring_group_id
                                                                ).name
                                                              ) +
                                                              "\n                                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : [
                                                _vm.getRingGroup(
                                                  _vm.communication
                                                    .ring_group_id
                                                )?.call_waiting
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "\n                                        Call waiting Queue\n                                    "
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        "\n                                        Deleted Ring Group\n                                    "
                                                      ),
                                                    ]),
                                              ],
                                        ],
                                        2
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.communication.workflow_id
                                  ? [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-5"
                                              : "col-xl-5 col-12",
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Sequence:\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-7"
                                              : "col-xl-7 col-12",
                                          ],
                                        },
                                        [
                                          _vm.getWorkflow(
                                            _vm.communication.workflow_id
                                          )
                                            ? _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "Sequence Manager",
                                                      params: {
                                                        workflow_id:
                                                          _vm.communication
                                                            .workflow_id,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass:
                                                        "item pull-left",
                                                      attrs: {
                                                        effect: "dark",
                                                        content:
                                                          "Click For More Info",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-dark-greenish",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            " +
                                                              _vm._s(
                                                                _vm.getWorkflow(
                                                                  _vm
                                                                    .communication
                                                                    .workflow_id
                                                                ).name
                                                              ) +
                                                              "\n                                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : [
                                                _vm._v(
                                                  "\n                                    Deleted Sequence\n                                "
                                                ),
                                              ],
                                        ],
                                        2
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.communication.broadcast_id
                                  ? [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-5"
                                              : "col-xl-5 col-12",
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Broadcast:\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-7"
                                              : "col-xl-7 col-12",
                                          ],
                                        },
                                        [
                                          _vm.getBroadcast(
                                            _vm.communication.broadcast_id
                                          )
                                            ? _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "Broadcast Activity",
                                                      params: {
                                                        broadcast_id:
                                                          _vm.communication
                                                            .broadcast_id,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass:
                                                        "item pull-left",
                                                      attrs: {
                                                        effect: "dark",
                                                        content:
                                                          "Click For More Info",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-dark-greenish",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        " +
                                                              _vm._s(
                                                                _vm.getBroadcast(
                                                                  _vm
                                                                    .communication
                                                                    .broadcast_id
                                                                ).name
                                                              ) +
                                                              "\n                                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : [
                                                _vm._v(
                                                  "\n                                    Deleted Broadcast\n                                "
                                                ),
                                              ],
                                        ],
                                        2
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.communication.transfer_prior_user_ids
                                  ? _c(
                                      "label",
                                      {
                                        staticClass: "form-control-label",
                                        class: [
                                          _vm.dialer_mode
                                            ? "col-5"
                                            : "col-xl-5 col-12",
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Transferred from:\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.communication.transfer_prior_user_ids
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                        class: [
                                          _vm.dialer_mode
                                            ? "col-7"
                                            : "col-xl-7 col-12",
                                        ],
                                      },
                                      _vm._l(
                                        _vm.communication
                                          .transfer_prior_user_ids,
                                        function (user_id, index) {
                                          return _vm.getUser(user_id)
                                            ? _c(
                                                "router-link",
                                                {
                                                  key:
                                                    user_id + "-user-" + index,
                                                  attrs: {
                                                    to: {
                                                      name: "User Activity",
                                                      params: {
                                                        user_id: user_id,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass:
                                                        "item pull-left",
                                                      attrs: {
                                                        effect: "dark",
                                                        content:
                                                          "Click For More Info",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-dark-greenish",
                                                          attrs: {
                                                            title:
                                                              _vm.getUserName(
                                                                _vm.getUser(
                                                                  user_id
                                                                )
                                                              ),
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        " +
                                                              _vm._s(
                                                                _vm.getUserName(
                                                                  _vm.getUser(
                                                                    user_id
                                                                  )
                                                                )
                                                              ) +
                                                              "\n                                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.communication.transfer_target_user_ids
                                  ? _c(
                                      "label",
                                      {
                                        staticClass: "form-control-label",
                                        class: [
                                          _vm.dialer_mode
                                            ? "col-5"
                                            : "col-xl-5 col-12",
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Transferred to:\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.communication.transfer_target_user_ids
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                        class: [
                                          _vm.dialer_mode
                                            ? "col-7"
                                            : "col-xl-7 col-12",
                                        ],
                                      },
                                      _vm._l(
                                        _vm.communication
                                          .transfer_target_user_ids,
                                        function (user_id, index) {
                                          return _vm.getUser(user_id)
                                            ? _c(
                                                "router-link",
                                                {
                                                  key:
                                                    user_id + "-user-" + index,
                                                  attrs: {
                                                    to: {
                                                      name: "User Activity",
                                                      params: {
                                                        user_id: user_id,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass:
                                                        "item pull-left",
                                                      attrs: {
                                                        effect: "dark",
                                                        content:
                                                          "Click For More Info",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-dark-greenish",
                                                          attrs: {
                                                            title:
                                                              _vm.getUserName(
                                                                _vm.getUser(
                                                                  user_id
                                                                )
                                                              ),
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        " +
                                                              _vm._s(
                                                                _vm.getUserName(
                                                                  _vm.getUser(
                                                                    user_id
                                                                  )
                                                                )
                                                              ) +
                                                              "\n                                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.communication.transfer_target_user_ids
                                  ? _c(
                                      "label",
                                      {
                                        staticClass: "form-control-label",
                                        class: [
                                          _vm.dialer_mode
                                            ? "col-5"
                                            : "col-xl-5 col-12",
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Cold transferred?\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.communication.transfer_target_user_ids
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                        class: [
                                          _vm.dialer_mode
                                            ? "col-7"
                                            : "col-xl-7 col-12",
                                        ],
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-dark" },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm._f("fixBooleanType")(
                                                    _vm.communication
                                                      .in_cold_transfer
                                                  )
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.communication.metadata &&
                                _vm.communication.metadata.new_communication_id
                                  ? [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-5"
                                              : "col-xl-5 col-12",
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Child Call:\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-7"
                                              : "col-xl-7 col-12",
                                          ],
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text-dark-greenish",
                                            },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "Communication",
                                                      params: {
                                                        communication_id:
                                                          _vm.communication
                                                            .metadata
                                                            .new_communication_id,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        More info\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.communication.metadata &&
                                _vm.communication.metadata
                                  .original_communication_id
                                  ? [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-5"
                                              : "col-xl-5 col-12",
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Parent Call:\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-7"
                                              : "col-xl-7 col-12",
                                          ],
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text-dark-greenish",
                                            },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "Communication",
                                                      params: {
                                                        communication_id:
                                                          _vm.communication
                                                            .metadata
                                                            .original_communication_id,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        More info\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.communication.metadata &&
                                _vm.communication.metadata
                                  .active_communication_id
                                  ? [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-5"
                                              : "col-xl-5 col-12",
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Child Call:\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-7"
                                              : "col-xl-7 col-12",
                                          ],
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text-dark-greenish",
                                            },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "Communication",
                                                      params: {
                                                        communication_id:
                                                          _vm.communication
                                                            .metadata
                                                            .active_communication_id,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        More info\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.communication.metadata &&
                                _vm.communication.metadata.fake_communication_id
                                  ? [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-5"
                                              : "col-xl-5 col-12",
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Parent Call:\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-7"
                                              : "col-xl-7 col-12",
                                          ],
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text-dark-greenish",
                                            },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "Communication",
                                                      params: {
                                                        communication_id:
                                                          _vm.communication
                                                            .metadata
                                                            .fake_communication_id,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        More info\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.getUser(
                                  _vm.communication.user_id,
                                  _vm.communication?.user
                                ) &&
                                _vm.communication.type ===
                                  _vm.CommunicationTypes.SMS &&
                                _vm.communication.direction ===
                                  _vm.CommunicationDirections.INBOUND
                                  ? _c(
                                      "label",
                                      {
                                        staticClass: "form-control-label",
                                        class: [
                                          _vm.dialer_mode
                                            ? "col-5"
                                            : "col-xl-5 col-12",
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Received by:\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.getUser(
                                  _vm.communication.user_id,
                                  _vm.communication?.user
                                ) &&
                                _vm.communication.type ===
                                  _vm.CommunicationTypes.SMS &&
                                _vm.communication.direction ===
                                  _vm.CommunicationDirections.OUTBOUND
                                  ? _c(
                                      "label",
                                      {
                                        staticClass: "form-control-label",
                                        class: [
                                          _vm.dialer_mode
                                            ? "col-5"
                                            : "col-xl-5 col-12",
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Sent by:\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.getUser(
                                  _vm.communication.user_id,
                                  _vm.communication?.user
                                ) &&
                                _vm.communication.type ===
                                  _vm.CommunicationTypes.SMS
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                        class: [
                                          _vm.dialer_mode
                                            ? "col-7"
                                            : "col-xl-7 col-12",
                                        ],
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-dark" },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.getUser(
                                                    _vm.communication.user_id,
                                                    _vm.communication?.user
                                                  ).name
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.communication.type ===
                                _vm.CommunicationTypes.CALL
                                  ? _c(
                                      "label",
                                      {
                                        staticClass: "form-control-label",
                                        class: [
                                          _vm.dialer_mode
                                            ? "col-5"
                                            : "col-xl-5 col-12",
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Recording:\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.communication.type ===
                                _vm.CommunicationTypes.CALL
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                        class: [
                                          _vm.dialer_mode
                                            ? "col-7"
                                            : "col-xl-7 col-12",
                                        ],
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-dark-greenish" },
                                          [
                                            _c("communication-audio", {
                                              attrs: {
                                                communication:
                                                  _vm.communication,
                                                type: _vm.UploadedFileTypes
                                                  .TYPE_CALL_RECORDING,
                                                "popover-direction":
                                                  _vm.communication
                                                    .direction ===
                                                  _vm.CommunicationDirections
                                                    .INBOUND
                                                    ? "right"
                                                    : "left",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.communication.type ===
                                  _vm.CommunicationTypes.CALL &&
                                _vm.communication.metadata &&
                                _vm.communication.metadata.transcription_info
                                  ? _c(
                                      "label",
                                      {
                                        staticClass: "form-control-label",
                                        class: [
                                          _vm.dialer_mode
                                            ? "col-5"
                                            : "col-xl-5 col-12",
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Transcription:\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.communication.type ===
                                  _vm.CommunicationTypes.CALL &&
                                _vm.communication.metadata &&
                                _vm.communication.metadata.transcription_info
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                        class: [
                                          _vm.dialer_mode
                                            ? "col-7"
                                            : "col-xl-7 col-12",
                                        ],
                                      },
                                      [
                                        _vm.communication.metadata &&
                                        _vm.communication.metadata
                                          .transcription_info &&
                                        _vm.communication.metadata
                                          .transcription_info.status == 4
                                          ? _c(
                                              "div",
                                              { staticClass: "w-full" },
                                              [
                                                !_vm.communication
                                                  .transcription_is_deleted &&
                                                _vm.communication.metadata
                                                  .transcription_info.summary
                                                  ? _c(
                                                      "communication-transcription-button",
                                                      {
                                                        attrs: {
                                                          communication:
                                                            _vm.communication,
                                                          show_form:
                                                            _vm.showTranscription,
                                                        },
                                                      }
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.communication
                                                  .transcription_is_deleted
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-grey-900",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                  deleted\n                                "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _c("div", [
                                              _c("span", [
                                                _vm._v("Processing..."),
                                              ]),
                                            ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                [
                                  _vm.CommunicationTypes.FAX,
                                  _vm.CommunicationTypes.EMAIL,
                                ].includes(_vm.communication.type) &&
                                _vm.communication.attachments &&
                                _vm.communication.attachments.length > 0
                                  ? [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-5"
                                              : "col-xl-5 col-12",
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Files:\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-7"
                                              : "col-xl-7 col-12",
                                          ],
                                        },
                                        _vm._l(
                                          _vm.communication.attachments,
                                          function (attachment, index) {
                                            return _c(
                                              "span",
                                              {
                                                key: index,
                                                staticClass:
                                                  "text-dark-greenish",
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "text-dark-greenish",
                                                    attrs: {
                                                      target: "_blank",
                                                      href: attachment.url,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        Click Here To Download\n                                    "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                [
                                  _vm.CommunicationTypes.CALL,
                                  _vm.CommunicationTypes.RVM,
                                ].includes(_vm.communication.type)
                                  ? _c(
                                      "label",
                                      {
                                        staticClass: "form-control-label",
                                        class: [
                                          _vm.dialer_mode
                                            ? "col-5"
                                            : "col-xl-5 col-12",
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Voicemail:\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                [
                                  _vm.CommunicationTypes.CALL,
                                  _vm.CommunicationTypes.RVM,
                                ].includes(_vm.communication.type)
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-column align-items-start",
                                        class: [
                                          _vm.dialer_mode
                                            ? "col-7"
                                            : "col-xl-7 col-12",
                                        ],
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-dark-greenish" },
                                          [
                                            _c("communication-audio", {
                                              attrs: {
                                                communication:
                                                  _vm.communication,
                                                type: _vm.UploadedFileTypes
                                                  .TYPE_CALL_VOICEMAIL,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        (_vm.activity_mode || _vm.verbose) &&
                                        _vm.communication.has_voicemail
                                          ? [
                                              _vm.communication.metadata &&
                                              _vm.communication.metadata
                                                .voicemail_transcription
                                                ? _c(
                                                    "div",
                                                    { staticClass: "w-full" },
                                                    [
                                                      _c("p", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "600",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Transcription:"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " “" +
                                                            _vm._s(
                                                              _vm.communication
                                                                .metadata
                                                                .voicemail_transcription
                                                            ) +
                                                            "”"
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    { staticClass: "w-full" },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "fixDuration"
                                                            )(
                                                              _vm.communication
                                                                .voicemail_duration
                                                            )
                                                          ) +
                                                            " - (Transcription Failed) "
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.communication.type ===
                                  _vm.CommunicationTypes.SMS &&
                                _vm.communication.direction ===
                                  _vm.CommunicationDirections.OUTBOUND &&
                                _vm.sentAsMmsLabel
                                  ? [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-5"
                                              : "col-xl-5 col-12",
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Sent as MMS:\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-7"
                                              : "col-xl-7 col-12",
                                          ],
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-dark" },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(_vm.sentAsMmsLabel) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-control-label",
                                    class: [
                                      _vm.dialer_mode
                                        ? "col-5"
                                        : "col-xl-5 col-12",
                                    ],
                                  },
                                  [_vm._v("Notes:")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column justify-content-center pt-2",
                                    class: [
                                      _vm.dialer_mode
                                        ? "col-7"
                                        : "col-xl-7 col-12",
                                    ],
                                  },
                                  [
                                    _vm.activity_mode || _vm.verbose
                                      ? [
                                          _vm.communication.notes
                                            ? _c(
                                                "div",
                                                { staticClass: "w-full" },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        _vm.communication.notes
                                                      ) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            [
                                              _c("communication-note", {
                                                ref: "communication_notes",
                                                attrs: {
                                                  small: _vm.dialer_mode,
                                                  communication:
                                                    _vm.communication,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "trigger",
                                                      fn: function () {
                                                        return [
                                                          _c("div", [
                                                            _c("i", {
                                                              staticClass:
                                                                "text-dark-greenish el-icon-document",
                                                            }),
                                                            _vm._v(" "),
                                                            _vm.communication
                                                              .notes
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-dark-greenish _700 pointer",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Change Notes"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-dark-greenish _700 pointer",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Add Notes"
                                                                    ),
                                                                  ]
                                                                ),
                                                          ]),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  226843984
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _c("communication-note", {
                                          ref: "communication_notes",
                                          attrs: {
                                            small: _vm.dialer_mode,
                                            communication: _vm.communication,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "trigger",
                                                fn: function () {
                                                  return [
                                                    _c("div", [
                                                      _c("i", {
                                                        staticClass:
                                                          "text-dark-greenish el-icon-document",
                                                      }),
                                                      _vm._v(" "),
                                                      _vm.communication.notes
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-dark-greenish _700 pointer",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Change Notes"
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-dark-greenish _700 pointer",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Add Notes"
                                                              ),
                                                            ]
                                                          ),
                                                    ]),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            226843984
                                          ),
                                        }),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _vm.verbose && _vm.communication.csat_score
                                  ? [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-5"
                                              : "col-xl-5 col-12",
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "\n                                CSAT Score:\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-column justify-content-center pt-2",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-7"
                                              : "col-xl-7 col-12",
                                          ],
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "w-full" },
                                            [
                                              _c("el-rate", {
                                                attrs: { disabled: "" },
                                                model: {
                                                  value:
                                                    _vm.communication
                                                      .csat_score,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.communication,
                                                      "csat_score",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "communication.csat_score",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.verbose
                                  ? [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-control-label",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-5"
                                              : "col-xl-5 col-12",
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Creator Type:\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-column justify-content-center pt-2",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-7"
                                              : "col-xl-7 col-12",
                                          ],
                                        },
                                        [
                                          _c("div", { staticClass: "w-full" }, [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm._f(
                                                    "translateCreatorTypeText"
                                                  )(
                                                    _vm.communication
                                                      .creator_type
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-control-label",
                                    class: [
                                      _vm.dialer_mode
                                        ? "col-5"
                                        : "col-xl-5 col-12",
                                    ],
                                  },
                                  [_vm._v("Tags:")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-items-center pt-2",
                                    class: [
                                      _vm.dialer_mode
                                        ? "col-7"
                                        : "col-xl-7 col-12",
                                    ],
                                  },
                                  [
                                    _c("communication-tags", {
                                      attrs: {
                                        communication: _vm.communication,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.communication.type ===
                                  _vm.CommunicationTypes.CALL &&
                                _vm.current_company &&
                                _vm.call_dispositions.length > 0 &&
                                !_vm.dialer_mode
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-5"
                                              : "col-xl-5 col-12",
                                          ],
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "form-control-label",
                                            },
                                            [_vm._v("Call Disposition:")]
                                          ),
                                          _vm._v(" "),
                                          _vm.current_company
                                            .force_call_disposition
                                            ? _c(
                                                "el-popover",
                                                {
                                                  staticClass: "ml-1",
                                                  attrs: {
                                                    placement: "right",
                                                    width: "200",
                                                    trigger: "click",
                                                    content:
                                                      "Your account admin has mandated call dispositions.",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticClass: "p-0",
                                                      attrs: {
                                                        slot: "reference",
                                                        type: "text",
                                                      },
                                                      slot: "reference",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-info text-danger",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center pt-2",
                                          class: [
                                            _vm.dialer_mode
                                              ? "col-7"
                                              : "col-xl-7 col-12",
                                          ],
                                        },
                                        [
                                          _c("call-disposition-selector", {
                                            attrs: {
                                              communication: _vm.communication,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.current_company
                                        .hubspot_integration_enabled
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center",
                                              class: [
                                                _vm.dialer_mode
                                                  ? "col-5"
                                                  : "col-xl-5 col-12",
                                              ],
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "form-control-label",
                                                },
                                                [_vm._v("HubSpot Type:")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.current_company
                                        .hubspot_integration_enabled
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center pt-2",
                                              class: [
                                                _vm.dialer_mode
                                                  ? "col-7"
                                                  : "col-xl-7 col-12",
                                              ],
                                            },
                                            [
                                              _c(
                                                "hubspot-activity-type-selector",
                                                {
                                                  attrs: {
                                                    communication:
                                                      _vm.communication,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _vm.hasCustomFields
                              ? _c(
                                  "div",
                                  { staticClass: "b-t" },
                                  _vm._l(
                                    _vm.communication.metadata?.custom_fields,
                                    function (custom_field, key) {
                                      return _c(
                                        "div",
                                        {
                                          key: key,
                                          staticClass: "form-group row mb-0",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "form-control-label col-xl-5",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.convertToTitleCase(key)
                                                ) + ":"
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center col-xl-7",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(custom_field) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  ![
                    _vm.CommunicationTypes.NOTE,
                    _vm.CommunicationTypes.SYSNOTE,
                    _vm.CommunicationTypes.APPOINTMENT,
                    _vm.CommunicationTypes.REMINDER,
                  ].includes(_vm.communication.type) && _vm.activity_mode
                    ? _c("div", { staticClass: "row p-l p-r" }, [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "Communication",
                                    params: {
                                      communication_id: _vm.communication.id,
                                    },
                                  },
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm greyish text-left",
                                  },
                                  [
                                    _c(
                                      "i",
                                      { staticClass: "material-icons mr-2" },
                                      [_vm._v("info")]
                                    ),
                                    _vm._v(
                                      "\n                            More Details\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  [
                    _vm.CommunicationTypes.APPOINTMENT,
                    _vm.CommunicationTypes.REMINDER,
                  ].includes(_vm.communication.type)
                    ? _c("div", { staticClass: "row p-l p-r" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 text-center" },
                          [
                            _c(
                              "div",
                              { staticClass: "text-center pb-3 b-b" },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    attrs: {
                                      disabled: _vm.loading_update_engagement,
                                    },
                                    on: { change: _vm.changeEngagementStatus },
                                    model: {
                                      value:
                                        _vm.communication.disposition_status2,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.communication,
                                          "disposition_status2",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "communication.disposition_status2",
                                    },
                                  },
                                  [
                                    _vm.communication.type ==
                                    _vm.CommunicationTypes.APPOINTMENT
                                      ? [
                                          _c(
                                            "el-radio-button",
                                            {
                                              attrs: {
                                                label:
                                                  _vm
                                                    .CommunicationDispositionStatus
                                                    .DISPOSITION_STATUS_APPOINTMENT_SET,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Set\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio-button",
                                            {
                                              attrs: {
                                                label:
                                                  _vm
                                                    .CommunicationDispositionStatus
                                                    .DISPOSITION_STATUS_APPOINTMENT_SHOWN,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.isSimpSocial
                                                      ? "Shown"
                                                      : "Completed"
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio-button",
                                            {
                                              attrs: {
                                                label:
                                                  _vm
                                                    .CommunicationDispositionStatus
                                                    .DISPOSITION_STATUS_APPOINTMENT_CANCELED,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Canceled\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio-button",
                                            {
                                              attrs: {
                                                label:
                                                  _vm
                                                    .CommunicationDispositionStatus
                                                    .DISPOSITION_STATUS_APPOINTMENT_MISSED,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Missed\n                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      : [
                                          _c(
                                            "el-radio-button",
                                            {
                                              attrs: {
                                                label:
                                                  _vm
                                                    .CommunicationDispositionStatus
                                                    .DISPOSITION_STATUS_PLACED_NEW,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Pending\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio-button",
                                            {
                                              attrs: {
                                                label:
                                                  _vm
                                                    .CommunicationDispositionStatus
                                                    .DISPOSITION_STATUS_COMPLETED_NEW,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Completed\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio-button",
                                            {
                                              attrs: {
                                                label:
                                                  _vm
                                                    .CommunicationDispositionStatus
                                                    .DISPOSITION_STATUS_FAILED_NEW,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Canceled\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio-button",
                                            {
                                              attrs: {
                                                label:
                                                  _vm
                                                    .CommunicationDispositionStatus
                                                    .DISPOSITION_STATUS_MISSED_NEW,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Missed\n                                "
                                              ),
                                            ]
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "text-center pt-3" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.getCalendarTalkRedirectURL({
                                      communication_id: _vm.communication.id,
                                      view: "month",
                                    }),
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c("el-button", { attrs: { size: "" } }, [
                                    _c("i", {
                                      staticClass: "fa fa-calendar mr-1",
                                    }),
                                    _vm._v(
                                      "\n                                Open in Calendar\n                            "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("sms-reminders", {
                              attrs: {
                                communication_id: _vm.communication.id,
                                campaign_id: _vm.campaign_id,
                                contact: _vm.contact,
                                appointment_datetime:
                                  _vm.communication.engagement_data
                                    .appointment_datetime,
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.communication.notes && !_vm.activeName
            ? _c("div", { staticClass: "p-x-sm p-y-sm b-t width-300" }, [
                _c("strong", [_vm._v("Notes:")]),
                _vm._v(" "),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$options.filters.nl2br(_vm.communication.notes)
                    ),
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.communication.transcription_is_deleted &&
          _vm.communication.has_transcription &&
          !_vm.activeName &&
          !_vm.dialer_mode
            ? _c(
                "div",
                { staticClass: "p-x-sm p-y-sm b-t width-300 ml-2" },
                [
                  _c("communication-transcription-button", {
                    attrs: {
                      communication: _vm.communication,
                      button_text: "Show Transcription",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.communication.body &&
          _vm.communication.type === _vm.CommunicationTypes.NOTE &&
          _vm.communication.direction === _vm.CommunicationDirections.INBOUND &&
          !_vm.activeName
            ? _c("div", { staticClass: "p-x-sm p-y-sm b-t width-300" }, [
                _c("strong", [_vm._v("Notes:")]),
                _vm._v(" "),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$options.filters.nl2br(_vm.parsedBody)
                    ),
                  },
                }),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }