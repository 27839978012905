<template>
    <div>
        <el-form :model="transcription_settings"
                 class="form-aloware"
                 ref="companySettingsForm">
            <div class="row">
                <div class="col-md-10">
                    <div class="row pb-3">
                        <div class="d-block w-100">
                            <div class="ai-info-box">
                                <div class="ai-info-box-header">
                                    <span class="ai-info-box-icon"
                                          v-if="usagePercentage < 100 && isTrial">
                                        🎁
                                    </span>
                                    <span class="ai-info-box-title">
                                        {{ modalContent.title }}
                                    </span>
                                </div>
                                <p class="ai-info-box-content text-white">
                                    {{ modalContent.message }}
                                </p>
                                <div class="ai-info-box-links">
                                    <strong>Guides:</strong>
                                    <ul class="pl-4">
                                        <li>
                                            <a href="https://support.aloware.com/en/articles/10233960-guide-for-agents-using-aloai-voice-analytics" target="_blank">
                                                Agents guide to AloAi Voice Analytics
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://support.aloware.com/en/articles/10235067-guide-for-admins-using-aloai-voice-analytics" target="_blank">
                                                Admins guide to AloAi Voice Analytics
                                            </a>
                                        </li>
                                    </ul>
                                    Revolutionize your calls with AloAi Voice Analytics; read the
                                    <a href="https://aloware.com/blog/aloai-voice-analytics-announcement" target="_blank">
                                        blog post
                                    </a>
                                    to learn more!
                                    <div v-if="isAnyCallRecordingDisabled">
                                        <br>
                                        <span class="mr-1">⚠️</span>
                                        <strong v-if="isAllCallRecordingDisabled">You’re currently not recording any calls. To take advantage of our AloAi Voice Analytics product, turn on call recordings:</strong>
                                        <strong v-else>You’re currently not recording all your calls. To take advantage of our AloAi Voice Analytics product, turn on call recordings:</strong>
                                        <ul class="pl-4">
                                            <li>
                                                <span>For inbound call recordings: </span>
                                                <a :href="inboundCallRecordingUrl">Link</a>
                                            </li>
                                            <li>
                                                <span>For outbound call recordings: </span>
                                                <a :href="outboundCallRecordingUrl">Link</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row pb-3"
                         v-if="showWarning">
                        <el-alert show-icon
                                  type="warning">
                            <span class="d-block mb-1 text-black">
                                You have used <b>{{ transcription_usage.used | numFormat }} / {{ transcription_usage.available | numFormat }}</b> of AloAi Voice Analytics minutes.
                                <span v-if="!transcription_settings.overusage_restriction_enabled">
                                    Additional minutes will be billed at <b>{{ auth.user.profile.rate.transcription | fixRounding(2) | toCurrency }}</b> per minute.
                                </span>
                            </span>
                        </el-alert>
                    </div>
                    <div class="row pb-3"
                         v-if="current_company && current_company.transcription_settings?.call_transcription_enabled && current_company.hubspot_integration_enabled">
                        <el-alert type="info"
                                  show-icon>
                            <span class="d-block mb-1">
                                <b>Attention:</b> HubSpot integration is enabled for this account. To avoid redundancy, consider disabling transcriptions in HubSpot.
                            </span>
                        </el-alert>
                    </div>

                    <el-form-item class="pb-1"
                                  prop="call_transcription_enabled"
                                  :class="{ 'no-border': transcription_settings.call_transcription_enabled }">
                        <span id="call_transcription_enabled"
                              class="page-anchor"/>
                        <div class="form-label">
                            <h5>Call Transcription</h5>
                            <small>
                                This feature allows you to review your calls with AloAi Voice Analytics.
                            </small>
                        </div>
                        <div class="switch-label">
                            <el-switch @change="updateSetting"
                                       v-model="transcription_settings.call_transcription_enabled"
                                       active-color="#00a344"/>
                            <label>Enable call transcription</label>
                        </div>
                        <template v-if="transcription_settings.call_transcription_enabled">
                            <el-button type="success"
                                       class="my-2"
                                       @click="updateTranscriptionEnabledUsers(true)">
                                <span>Enable Transcription for all users</span>
                                <i v-if="loading_enable_transcription_for_users"
                                   class="material-icons loader mr-2 pull-left">
                                    &#xE863;
                                </i>
                            </el-button>
                            <el-button type="danger"
                                       class="ml-md-2"
                                       @click="updateTranscriptionEnabledUsers(false)">
                                <span>Disable Transcription for all users</span>
                                <i v-if="loading_disable_transcription_for_users"
                                   class="material-icons loader mr-2 pull-left">
                                    &#xE863;
                                </i>
                            </el-button>
                        </template>
                        <template v-if="transcription_settings.call_transcription_enabled">
                            <p class="mb-0">
                                <a class="text-dark-greenish el-green-hover mt-1"
                                   v-if="!loading_users_list"
                                   @click="displayUserTranscriptionManagementDialog()">
                                    <strong>
                                        Manage User Transcription Settings
                                        <i class="fas fa-angle-right mr-1"></i>
                                    </strong>
                                </a>
                                <i v-if="loading_users_list"
                                   class="text-dark-greenish material-icons loader mr-2 pull-left mb-1">
                                    &#xE863;
                                </i>
                            </p>
                        </template>
                    </el-form-item>

                    <el-form-item class="no-border pb-2"
                                  prop="on_demand_enabled"
                                  v-if="transcription_settings.call_transcription_enabled">
                        <span id="on_demand_enabled"
                              class="page-anchor"/>
                        <div class="form-label">
                            <h5>On-Demand Transcription and Summarization</h5>
                            <small>
                                Enable this option to manually transcribe and generate call summaries instead of automatically.
                            </small>
                        </div>
                        <div class="switch-label">
                            <el-switch @change="updateSetting"
                                       v-model="transcription_settings.on_demand_enabled"
                                       active-color="#00a344"/>
                            <label>Enable on-demand transcription and summarization</label>
                        </div>
                    </el-form-item>

                    <el-form-item class="pb-1"
                                  prop="summarization_enabled"
                                  :class="{ 'no-border': transcription_settings.summarization_enabled }"
                                  v-if="transcription_settings?.call_transcription_enabled">
                        <span id="summarization_enabled"
                              class="page-anchor"/>
                        <div class="form-label">
                            <h5>Summarization Settings</h5>
                            <small>
                                This feature allows you to summarize your calls using AloAi.
                            </small>
                        </div>
                        <div class="switch-label">
                            <el-switch @change="updateSetting"
                                       v-model="transcription_settings.summarization_enabled"
                                       active-color="#00a344"/>
                            <label>Enable call summarization</label>
                        </div>
                    </el-form-item>

                    <el-form-item class="no-border pb-2"
                                  prop="summary_length"
                                  v-if="transcription_settings?.summarization_enabled &&
                                        transcription_settings?.call_transcription_enabled">
                        <span id="summary_length"
                              class="page-anchor"/>
                        <div class="form-label">
                            <h5>Call Summary Length</h5>
                            <small>Target length for the call summary. AloAi will do its best to honor this value, but it is not guaranteed.</small>
                        </div>
                        <el-input-number v-model="transcription_settings.summary_length"
                                         :min="50"
                                         :max="500"
                                         :step="50"
                                         size="mini"
                                         @keydown.enter.prevent.native=""
                                         @change="handleInputConfirm"/>
                        <span class="ml-2">words</span>
                    </el-form-item>

                    <el-form-item
                        class="no-border pb-2"
                        prop="summary_highlights"
                        v-if="transcription_settings?.summarization_enabled && transcription_settings?.call_transcription_enabled"
                    >
                        <span id="summary_highlights" class="page-anchor"/>
                        <div class="row" style="display: flex; gap: 16px; align-items: stretch;">
                            <!-- Call Summary Highlights -->
                            <div class="highlights-container"
                                 :class="{'col-md-6': transcription_settings.summary_prompt_list.length}">
                                <div class="form-label">
                                    <h5>Call Summary Highlights</h5>
                                    <small>Include key details in the call summary or specify your custom highlights.</small>
                                    <p><i>Click an item to edit or drag and drop to reorder highlights.</i></p>
                                </div>
                                <draggable handle=".drag-handle"
                                           v-model="transcription_settings.summary_prompt_list"
                                           @end="handleReorder">
                                    <div v-for="(item, index) in transcription_settings.summary_prompt_list" :key="item.id || index" class="draggable-item">
                                        <el-tag
                                            class="highlight-tag font-weight-bold"
                                            type="success"
                                            closable
                                            :disable-transitions="false"
                                            @close="removeSummaryHighlight(index)"
                                            @click.native="editSummaryHighlight(item, index)"
                                        >
                                            <span class="drag-handle" style="cursor: grab;">☰</span>
                                            {{ item.label.length > 50 ? item.label.slice(0, 50) + '...' : item.label }}
                                        </el-tag>
                                    </div>
                                </draggable>

                                <el-button size="small" @click="openSummaryHighlightModal">+ Add Highlight</el-button>
                            </div>
                            <!-- Call Summary Preview -->
                            <div class="col-md-6 summary-preview"
                                 v-if="transcription_settings.summary_prompt_list.length">
                                <div class="form-label">
                                    <h5>Call Summary Preview</h5>
                                    <small>Include these highlights in the call summary.</small>
                                </div>
                                <div class="ai-effect-container mt-2">
                                    <div class="ai-effect-gradient"></div>
                                    <div class="ai-effect-blur"></div>
                                    <div class="ai-effect-content p-2">
                                        <div class="flex items-center justify-between">
                                            <div class="flex items-center gap-2">
                                                <h3 class="ai-effect-gradient-text">
                                                    Powered by AloAi
                                                </h3>
                                            </div>
                                            <div class="text-left-align">
                                                <div v-if="transcription_settings.summary_prompt_list.length" 
                                                    class="call_summary"></div>
                                                <div class="inline-preview">
                                                    <div v-for="(item, index) in transcription_settings.summary_prompt_list" 
                                                        :key="index" 
                                                        class="inline-summary-item">
                                                        <span class="font-size-sm font-weight-bold" style="color: #000">
                                                            {{ item.label }}
                                                        </span>
                                                        <br>
                                                        <span class="font-size-xs" style="color: #000">
                                                            {{ item.prompt }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template>
                            <div>
                                <!-- Dialog for Creating or Editing Summary Highlight -->
                                <el-dialog title="Manage Call Summary Highlight"
                                        :visible.sync="highlight_dialog_visible"
                                        top="10vh"
                                        width="45%">
                                    <!-- Steps Navigation -->
                                    <el-steps 
                                        class="mb-3"
                                        align-center
                                        finish-status="success"
                                        :active="current_step"
                                    >
                                        <el-step title="Select Method" />
                                        <el-step title="Choose Template" />
                                        <el-step title="Customize Highlight" />
                                    </el-steps>

                                    <!-- Step 1: Selection Options -->
                                    <template v-if="current_step === 1">
                                        <el-radio-group v-model="selected_highlight_type"
                                                        @change="handleHighlightSelection">
                                            <div class="row row-filter highlight-options">
                                                <!-- Create from Scratch -->
                                                <div class="col-md-6">
                                                    <el-card shadow="hover"
                                                            class="cursor-pointer equal-height-card">
                                                        <el-radio label="scratch"
                                                                class="w-100">
                                                            <div class="d-flex align-items-center mb-2">
                                                                <i class="text-2x mr-2 material-icons text-green-500">create</i>
                                                                <span class="text-lg font-weight-bold text-grey-9">Create from scratch</span>
                                                            </div>
                                                            <p class="text-md l-h">
                                                                Begin with a blank template to design a fully personalized highlight.
                                                            </p>
                                                        </el-radio>
                                                    </el-card>
                                                </div>

                                                <!-- Use Template -->
                                                <div class="col-md-6">
                                                    <el-card shadow="hover"
                                                            class="cursor-pointer equal-height-card">
                                                        <el-radio label="template"
                                                                class="w-100">
                                                            <div class="d-flex align-items-center mb-2">
                                                                <i class="text-2x mr-2 material-icons text-blue-500">layers</i>
                                                                <span class="text-lg font-weight-bold text-grey-9">Use a template</span>
                                                            </div>
                                                            <p class="text-md l-h">
                                                                Begin with a predefined template and personalize it to match your requirements.
                                                            </p>
                                                        </el-radio>
                                                    </el-card>
                                                </div>
                                            </div>
                                        </el-radio-group>
                                    </template>

                                    <!-- Step 2: Use Template -->
                                    <template v-if="current_step === 2">
                                        <el-form-item class="pb-2">
                                            <div class="form-label">
                                                <h5>Predefined Highlight</h5>
                                                <small>Select a predefined highlight to populate the label and prompt.</small>
                                            </div>
                                            <el-select class="w-full"
                                                    v-model="selected_highlight"
                                                    placeholder="Select a predefined highlight"
                                                    filterable
                                                    @change="applyPredefinedPrompt">
                                                <el-option v-for="option in summary_highlight_options"
                                                        :key="option.label"
                                                        :label="option.label"
                                                        :value="option" />
                                            </el-select>
                                        </el-form-item>
                                    </template>

                                    <!-- Step 3: Final Customization -->
                                    <template v-if="current_step === 3">
                                        <el-form class="no-border p-0"
                                                v-if="editing_summary_highlight">
                                            <!-- Label Field -->
                                            <el-form-item class="p-2">
                                                <div class="form-label">
                                                    <h5>Label</h5>
                                                    <small>Enter or edit the label for this highlight.</small>
                                                </div>
                                                <el-input v-model="editing_summary_highlight.label"
                                                        placeholder="Enter the label for this highlight" />
                                            </el-form-item>

                                            <!-- Prompt Field -->
                                            <el-form-item class="p-2">
                                                <div class="form-label">
                                                    <h5>Prompt</h5>
                                                    <small>Please provide clear and detailed instructions to guide the AI effectively.</small>
                                                </div>
                                                <el-input type="textarea"
                                                        v-model="editing_summary_highlight.prompt"
                                                        rows="4"
                                                        maxlength="3000"
                                                        placeholder="Enter the details of your highlight prompt" />
                                            </el-form-item>

                                            <!-- Order Field -->
                                            <el-form-item class="p-2">
                                                <div class="no-border form-label">
                                                    <h5>Order</h5>
                                                    <small>Set the priority order for this highlight.</small>
                                                </div>
                                                <el-input-number v-model="editing_summary_highlight.order"
                                                                :min="1"
                                                                :max="transcription_settings.summary_prompt_list.length + 1"
                                                                size="mini" />
                                            </el-form-item>
                                        </el-form>
                                    </template>

                                    <!-- Footer Buttons -->
                                    <div slot="footer">
                                        <el-button @click="closeStepDialog">Cancel</el-button>
                                        <el-button type="primary"
                                                   @click="saveSummaryHighlight"
                                                   v-if="current_step === 3">Save</el-button>
                                    </div>
                                </el-dialog>
                            </div>
                        </template>
                    </el-form-item>

                    <el-form-item class="pb-1"
                                  prop="sync_summaries_to_notes"
                                  v-if="transcription_settings?.summarization_enabled &&
                                        transcription_settings?.call_transcription_enabled">
                        <span id="sync_summaries_to_notes"
                              class="page-anchor"/>
                        <div class="form-label">
                            <h5>Sync summaries to notes (CRM activity sync)</h5>
                            <small>Include call summaries in the notes during the CRM activity syncing to provide a quick overview of the conversation.</small>
                        </div>
                        <div class="switch-label">
                            <el-switch @change="updateSetting"
                                       v-model="transcription_settings.sync_summaries_to_notes"
                                       active-color="#00a344"/>
                            <label>Enable summary sync with notes</label>
                        </div>
                    </el-form-item>

                    <el-form-item class="pb-1"
                                  prop="overusage_restriction_enabled"
                                  v-if="transcription_settings.call_transcription_enabled">
                        <span id="overusage_restriction_enabled"
                              class="page-anchor"/>
                        <div class="form-label">
                            <h5>Overusage Billing Restriction</h5>
                            <small>
                                Automatically turn off the call transcription feature once your account exceeds the allotted minutes of AloAi Voice Analytics included in your plan. This ensures you
                                won't
                                incur additional charges of {{ transcriptionRate | fixRounding(2) | toCurrency }} per minute for additional transcription minutes.
                            </small>
                        </div>
                        <div class="switch-label">
                            <el-tooltip v-if="transcription_settings.is_trial"
                                        content="Contact support to convert and start using AloAi Voice Analytics on your account"
                                        placement="top-start">
                                <template #default>
                                    <el-switch
                                        @change="updateSetting"
                                        v-model="transcription_settings.overusage_restriction_enabled"
                                        active-color="#00a344"
                                        :disabled="transcription_settings.is_trial"
                                    />
                                </template>
                            </el-tooltip>
                            <el-switch
                                v-else
                                @change="updateSetting"
                                v-model="transcription_settings.overusage_restriction_enabled"
                                active-color="#00a344"
                                :disabled="transcription_settings.is_trial"
                            />
                            <label>
                                Turn off call transcriptions automatically when monthly plan limit is reached
                            </label>
                        </div>
                    </el-form-item>

                    <el-form-item prop="minimum_talk_time"
                                  class="pb-1"
                                  v-if="transcription_settings.call_transcription_enabled">
                        <span id="minimum_talk_time"
                              class="page-anchor"/>
                        <div class="form-label">
                            <h5>Minimum Talk-Time</h5>
                            <small>
                                Calls will only be transcribed when talk time is greater than this limit.
                            </small>
                        </div>
                        <el-input-number v-model="transcription_settings.minimum_talk_time"
                                         :min="45"
                                         :max="max_talk_time"
                                         :step="15"
                                         size="mini"
                                         @keydown.enter.prevent.native=""
                                         @change="handleInputConfirm"/>
                        <span class="ml-2">Seconds</span>
                    </el-form-item>

                    <el-form-item prop="custom_keywords"
                                  class="pb-1"
                                  v-if="transcription_settings.call_transcription_enabled">
                        <span id="custom_keywords"
                              class="page-anchor"/>
                        <div class="form-label">
                            <h5>Custom Keywords</h5>
                            <small>
                                Custom Keywords will be counted in AloAi Voice Analytics panel.
                            </small>
                        </div>
                        <el-tag class="m-1"
                                :key="custom_keyword"
                                v-for="custom_keyword in transcription_settings.custom_keywords"
                                closable
                                type="warning"
                                style="font-size:15px!important"
                                :disable-transitions="false"
                                @close="handleKeywordsClose(custom_keyword)">
                            <b>{{ custom_keyword }}</b>
                        </el-tag>
                        <el-input v-if="keyword_input_visible"
                                  v-model="custom_keyword_value"
                                  ref="saveKeywordInput"
                                  size="medium"
                                  style="width: 120px"
                                  @keydown.enter.prevent.native="handleInputConfirm"
                                  @blur="handleInputConfirm"/>
                        <el-button v-else size="small"
                                   @click="showKeywordInput">
                            + New Keyword
                        </el-button>
                    </el-form-item>

                    <el-form-item prop="custom_dictionary"
                                  class="pb-1"
                                  v-if="transcription_settings.call_transcription_enabled">
                        <span id="custom_dictionary"
                              class="page-anchor"/>
                        <div class="form-label">
                            <h5>Custom Vocabulary</h5>
                            <small>
                                Add words specific to your use case (industry terms, technical terms, names, etc.) when transcribing calls in order to increase accuracy for those custom
                                vocabulary terms.
                            </small>
                        </div>
                        <el-tag class="m-1"
                                style="font-size: 15px"
                                closable
                                :key="dictionary_word"
                                :disable-transitions="false"
                                v-for="dictionary_word in transcription_settings.custom_dictionary"
                                @close="handleDictionaryClose(dictionary_word)">
                            <b>{{ dictionary_word }}</b>
                        </el-tag>
                        <el-input ref="saveDictionaryWordInput"
                                  size="medium"
                                  style="width: 120px"
                                  v-model="dictionary_word_value"
                                  v-if="dictionary_input_visible"
                                  @keydown.enter.prevent.native="handleInputConfirm"
                                  @blur="handleInputConfirm"/>
                        <el-button size="small"
                                   v-else
                                   @click="showDictionaryInput">
                            + New Word
                        </el-button>
                    </el-form-item>

                    <el-form-item prop="custom_spelling"
                                  class="pb-1"
                                  v-if="transcription_settings.call_transcription_enabled">
                        <span id="custom_spelling"
                              class="page-anchor"/>
                        <div class="form-label">
                            <h5>Custom Spelling</h5>
                            <small>
                                Custom Spelling lets you customize how words are spelled or formatted in the transcriptions.
                            </small>
                        </div>
                        <div>
                            <el-tag class="m-1"
                                    style="font-size: 15px; cursor: pointer;"
                                    type="success"
                                    closable
                                    :key="index"
                                    :disable-transitions="false"
                                    v-for="(spelling, index) in transcription_settings.custom_spelling"
                                    @close="removeSpelling(index)"
                                    @click="editSpelling(index)">
                                <b>{{ spelling.from.join(', ') }} → {{ spelling.to }}</b>
                            </el-tag>
                            <el-button size="small"
                                       @click="show_spelling_modal = true">
                                + Add Spelling
                            </el-button>
                        </div>
                    </el-form-item>

                    <el-dialog width="30%"
                               center
                               class="dialog-padding auto"
                               :visible.sync="show_spelling_modal"
                               v-if="transcription_settings.call_transcription_enabled"
                               @close="resetSpellingForm">
                        <template #title>
                            <div class="d-flex align-items-center">
                                <span>Add Custom Spelling</span>
                                <el-tooltip>
                                    <template #content>
                                        Whenever <strong>From</strong> appears in the transcription, it will be replaced with <strong>To</strong>.
                                    </template>
                                    <i class="el-icon-info ml-2"
                                       style="cursor: pointer; font-size: 16px;"></i>
                                </el-tooltip>
                            </div>
                        </template>
                        <el-form class="pb-0"
                                 style="margin-top: -10px;">
                            <!-- Individual input for "From" words -->
                            <el-form-item prop="custom_spelling_from"
                                          class="no-border p-0">
                                <div class="form-label">
                                    <h5>From (Words to Replace)</h5>
                                    <small>Enter the words to be replaced, one at a time.</small>
                                </div>
                                <el-tag class="m-1"
                                        style="font-size: 15px"
                                        type="success"
                                        closable
                                        :key="index"
                                        :disable-transitions="false"
                                        v-for="(word, index) in spelling_input.from"
                                        @close="removeWord(index)">
                                    <b>{{ word }}</b>
                                </el-tag>
                                <el-input v-model="current_from_word"
                                          ref="fromWordInput"
                                          size="medium"
                                          style="width: 120px"
                                          v-if="from_word_input_visible"
                                          @keydown.enter.prevent.native="addFromWord"
                                          @blur="addFromWord"/>
                                <el-button size="small"
                                           @click="showFromWordInput"
                                           v-else>
                                    + New Word
                                </el-button>
                            </el-form-item>
                            <!-- Single input for "To" word -->
                            <el-form-item prop="custom_spelling_to"
                                          class="no-border p-0">
                                <div class="form-label">
                                    <h5>To (Replacement Word)</h5>
                                    <small>Provide the word or phrase that will replace the specified input words.</small>
                                </div>
                                <el-tag class="m-1"
                                        type="success"
                                        style="font-size: 15px"
                                        closable
                                        :disable-transitions="false"
                                        v-if="spelling_input.to"
                                        @close="removeToWord">
                                    <b>{{ spelling_input.to }}</b>
                                </el-tag>
                                <el-input ref="toWordInput"
                                          size="medium"
                                          placeholder="Enter replacement word"
                                          style="width: 200px"
                                          v-if="!spelling_input.to || to_word_input_visible"
                                          v-model="current_to_word"
                                          @keydown.enter.prevent.native="addToWord"
                                          @blur="addToWord"/>
                                <el-button size="small"
                                           v-else
                                           @click="showToWordInput">
                                    Edit Word
                                </el-button>
                            </el-form-item>
                        </el-form>
                        <p>The value in the <strong>To</strong> key is case-sensitive, while the values in the <strong>From</strong> key is not.</p>
                        <div slot="footer"
                             class="d-flex justify-content-end p-0">
                            <el-button @click="resetSpellingForm">Cancel</el-button>
                            <el-button type="primary"
                                       @click="addCustomSpelling">
                                Save
                            </el-button>
                        </div>
                    </el-dialog>

                    <el-form-item prop="neutral_sentiment_messages"
                                  class="pb-1"
                                  v-if="transcription_settings.call_transcription_enabled">
                        <span id="neutral_sentiment_messages" class="page-anchor"/>
                        <div class="form-label">
                            <h5>Automatic Messages Sentiment Configuration</h5>
                            <small>This setting allows administrators to mark specific messages as neutral, ensuring they do not impact overall sentiment scores.</small>
                        </div>
                        <div>
                            <el-tag class="neutral-sentiment-tag m-1"
                                    style="font-size: 15px; cursor: pointer;"
                                    closable
                                    :key="index"
                                    :disable-transitions="false"
                                    v-for="(message, index) in transcription_settings.neutral_sentiment_messages"
                                    @close="removeNeutralMessage(index)"
                                    @click="editNeutralMessage(index)">
                                    <template v-if="message.length > 100">
                                        <el-tooltip class="item pull_left"
                                                    placement="bottom"
                                                    effect="dark"
                                                    :content="message"
                                                    :popper-style="{ maxWidth: '300px', whiteSpace: 'normal', overflowWrap: 'break-word', wordBreak: 'break-word' }">
                                                    <span>{{ message.slice(0, 100) + '...' }}</span>
                                        </el-tooltip>
                                    </template>
                                    <template v-else>
                                        <span>{{ message }}</span>
                                    </template>
                            </el-tag>
                            <el-button size="small" 
                                       @click="openNeutralDialog()">
                                + Add Message
                            </el-button>
                        </div>
                    </el-form-item>

                    <!-- Dialog for Creating or Editing Neutral Messages -->
                    <el-dialog class="p-0"
                               title="Configure Neutral Sentiment Message"
                               width="40%"
                               :visible.sync="neutral_dialog_visible"
                               @close="resetNeutralMessageDialog">
                        <p class="neutral-message-help-text">
                            If this exact phrase appears in the transcription, that section will be marked as neutral in the sentiment analysis.
                        </p>
                        <el-form>
                            <el-form-item class="no-border p-0 w-100">
                                <el-input type="textarea"
                                          rows="6"
                                          maxlength="3000"
                                          show-word-limit
                                          placeholder="Enter Message with Neutral Sentiment"
                                          v-model="current_neutral_message">
                                </el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer"
                             class="d-flex justify-content-end p-0">
                            <el-button @click="resetNeutralMessageDialog">Cancel</el-button>
                            <el-button type="primary" @click="saveNeutralMessage">Save</el-button>
                        </div>
                    </el-dialog>
                    
                    <el-form-item class="pb-1"
                                  prop="daily_digest_report_enabled"
                                  :class="{ 'no-border': transcription_settings.daily_digest_report_enabled }"
                                  v-if="transcription_settings?.call_transcription_enabled">
                        <span id="daily_digest_report_enabled"
                              class="page-anchor"/>
                        <div class="form-label">
                            <h5>Daily Digest Report</h5>
                            <small>
                                Enable daily digest reports to receive comprehensive summaries of your call activities and insights. To assign roles for receiving these reports, configure the settings in <a :href="`/account?tab=reporting-settings#set_reports_recipients`" class="text-dark-greenish el-green-hover font-weight-bold" target="_blank">System Reports Recipients</a>.
                            </small>
                        </div>
                        <div class="switch-label">
                            <el-switch @change="updateSetting"
                                       v-model="transcription_settings.daily_digest_report_enabled"
                                       active-color="#00a344"/>
                            <label>Enable daily digest report</label>
                        </div>
                    </el-form-item>

                    <daily-digest-report
                        v-if="transcription_settings.call_transcription_enabled && transcription_settings.daily_digest_report_enabled"
                        v-model="transcription_settings.daily_digest_report_prompt_list"
                        :templates="digest_report_templates"
                        :company_clone="company_clone"
                        @update-setting="updateSetting"
                    />
                    
                    <el-form-item prop="automatic_redaction_enabled"
                                  class="pb-1"
                                  v-if="transcription_settings.call_transcription_enabled">
                        <span id="automatic_redaction_enabled"
                              class="page-anchor"/>
                        <div class="form-label">
                            <h5>PII Redaction</h5>
                            <small>
                                This feature minimizes sensitive information about individuals by automatically identifying and removing it from call transcripts.
                            </small>
                        </div>
                        <div class="switch-label">
                            <el-tooltip v-if="transcription_settings.is_trial"
                                        content="Contact support to convert and start using AloAi Voice Analytics on your account"
                                        placement="top-start">
                                <template #default>
                                    <el-switch
                                        @change="updateSetting"
                                        v-model="transcription_settings.automatic_redaction_enabled"
                                        active-color="#00a344"
                                        :disabled="transcription_settings.is_trial"
                                    />
                                </template>
                            </el-tooltip>
                            <el-switch
                                v-else
                                @change="updateSetting"
                                v-model="transcription_settings.automatic_redaction_enabled"
                                active-color="#00a344"
                                :disabled="transcription_settings.is_trial"
                            />
                            <label>Enable PII redaction</label>
                        </div>

                        <div role="alert"
                             class="el-alert el-alert--info is-light mt-3">
                            <i class="el-alert__icon el-icon-info is-big"></i>
                            <div class="el-alert__content">
                                <p class="el-alert__description">
                                    Personal Identifiable Information (PII) is any information that can be used to identify a person, such as a name, email address, or phone number.
                                    <br>
                                    When you enable the feature, your call transcripts will look like this:
                                    <br>
                                    <ol class="mb-0">
                                        <li>Hi, my name is [PERSON_NAME]!</li>
                                    </ol>
                                </p>
                            </div>
                        </div>
                    </el-form-item>

                    <el-form-item prop="content_moderation_enabled"
                                  class="no-border pb-1"
                                  v-if="false">
                        <span id="content_moderation_enabled"
                              class="page-anchor"/>
                        <div class="form-label">
                            <h5>Sensitive Content Moderation</h5>
                            <small>
                                By enabling this feature, any conversations with sensitive
                                issues like drugs, racism and other inappropriate subjects will be
                                filtered and flagged automatically.
                            </small>
                        </div>
                        <div class="switch-label">
                            <el-tooltip v-if="transcription_settings.is_trial"
                                        content="Contact support to convert and start using AloAi Voice Analytics on your account"
                                        placement="top-start">
                                <template #default>
                                    <el-switch
                                        @change="updateSetting"
                                        v-model="transcription_settings.content_moderation_enabled"
                                        active-color="#00a344"
                                        :disabled="transcription_settings.is_trial"
                                    />
                                </template>
                            </el-tooltip>
                            <el-switch
                                v-else
                                @change="updateSetting"
                                v-model="transcription_settings.content_moderation_enabled"
                                active-color="#00a344"
                                :disabled="transcription_settings.is_trial"
                            />
                            <label>Enable sensitive content moderation</label>
                        </div>
                    </el-form-item>
                </div>

                <div class="col-md-2 d-none d-md-block ml-auto">
                    <div class="list-group list-group-alo list-group-flush sticky-top">
                        <h6 class="list-group-item">On This Page</h6>
                        <a href="#call_transcription_enabled"
                           class="list-group-item list-group-item-action"
                           @click="shine('call_transcription_enabled')">
                            Call Transcription
                        </a>
                        <a href="#on_demand_enabled"
                           class="list-group-item list-group-item-action"
                           v-if="transcription_settings.call_transcription_enabled"
                           @click="shine('on_demand_enabled')">
                            On-Demand Transcription and Summarization
                        </a>
                        <a href="#summarization_enabled"
                           class="list-group-item list-group-item-action"
                           v-if="transcription_settings.call_transcription_enabled"
                           @click="shine('summarization_enabled')">
                            Summarization Settings
                        </a>
                        <a href="#summary_length"
                           class="list-group-item list-group-item-action"
                           v-if="transcription_settings.summarization_enabled &&
                        transcription_settings.call_transcription_enabled"
                           @click="shine('summary_length')">
                            Call Summary Length
                        </a>
                        <a href="#summary_highlights"
                           class="list-group-item list-group-item-action"
                           v-if="transcription_settings.summarization_enabled &&
                        transcription_settings.call_transcription_enabled"
                           @click="shine('summary_highlights')">
                            Call Summary Highlights
                        </a>
                        <a href="#sync_summaries_to_notes"
                           class="list-group-item list-group-item-action"
                           v-if="transcription_settings.summarization_enabled &&
                        transcription_settings.call_transcription_enabled"
                           @click="shine('sync_summaries_to_notes')">
                            Sync summaries to notes (CRM activity sync)
                        </a>
                        <a href="#overusage_restriction_enabled"
                           class="list-group-item list-group-item-action"
                           v-if="transcription_settings.call_transcription_enabled"
                           @click="shine('overusage_restriction_enabled')">
                            Overusage Billing Restriction
                        </a>
                        <a href="#minimum_talk_time"
                           class="list-group-item list-group-item-action"
                           v-if="transcription_settings.call_transcription_enabled"
                           @click="shine('minimum_talk_time')">
                            Minimum Talk-Time
                        </a>
                        <a href="#min_max_communication_duration"
                           class="list-group-item list-group-item-action"
                           v-if="transcription_settings.call_transcription_enabled"
                           @click="shine('min_max_communication_duration')">
                            Sync transcriptions with Zoho CRM
                        </a>
                        <a href="#custom_keywords"
                           class="list-group-item list-group-item-action"
                           v-if="transcription_settings.call_transcription_enabled"
                           @click="shine('custom_keywords')">
                            Custom Keywords
                        </a>
                        <a href="#custom_dictionary"
                           class="list-group-item list-group-item-action"
                           v-if="transcription_settings.call_transcription_enabled"
                           @click="shine('custom_dictionary')">
                            Custom Vocabulary
                        </a>
                        <a href="#custom_spelling"
                           class="list-group-item list-group-item-action"
                           v-if="transcription_settings.call_transcription_enabled"
                           @click="shine('custom_spelling')">
                            Custom Spelling
                        </a>
                        <a href="#neutral_sentiment_messages"
                           class="list-group-item list-group-item-action"
                           v-if="transcription_settings.call_transcription_enabled"
                           @click="shine('neutral_sentiment_messages')">
                            Automatic Messages Sentiment Configuration
                        </a>
                        <a href="#daily_digest_report_enabled"
                           class="list-group-item list-group-item-action"
                           v-if="transcription_settings.call_transcription_enabled"
                           @click="shine('daily_digest_report_enabled')">
                            Daily Digest Report
                        </a>
                        <a href="#daily_digest_report_prompt_list"
                           class="list-group-item list-group-item-action"
                           v-if="transcription_settings.call_transcription_enabled && transcription_settings.daily_digest_report_enabled"
                           @click="shine('daily_digest_report_prompt_list')">
                            Daily Digest Report Sections
                        </a>
                        <a href="#automatic_redaction_enabled"
                           class="list-group-item list-group-item-action"
                           v-if="transcription_settings.call_transcription_enabled"
                           @click="shine('automatic_redaction_enabled')">
                            PII Redaction
                        </a>
                        <a href="#content_moderation_enabled"
                           class="list-group-item list-group-item-action"
                           v-if="false"
                           @click="shine('content_moderation_enabled')">
                            Sensitive Content Moderation
                        </a>
                    </div>
                </div>
                <el-dialog
                    title="Manage User Transcription Settings"
                    :visible.sync="users_enablement.display_dialog"
                    width="40%"
                    @close="closeUserTranscriptionManagementDialog">
                    <account-transcription-user-enablement/>
                </el-dialog>
            </div>
        </el-form>
    </div>
</template>

<script>
import AccountTranscriptionUserEnablement from './account-transcription-user-enablement'
import DailyDigestReport from './daily-digest-report'
import debounce from 'lodash/debounce'
import {mapState} from 'vuex'
import {form_handler} from '../../mixins'
import auth from '../../auth'
import * as InboundCallRecordingModes from '../../constants/inbound-call-recording-modes'
import * as OutboundCallRecordingModes from '../../constants/outbound-call-recording-modes'
import draggable from 'vuedraggable'
import SparkleIcon from "../../../images/app-icons/sparkle-bold-icon.vue"


export default {
    name: 'account-transcription-settings',

    components: {
        AccountTranscriptionUserEnablement,
        DailyDigestReport,
        draggable,
        SparkleIcon,
    },

    mixins: [form_handler],

    props: {
        company_clone: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            auth,
            users_enablement: {
                display_dialog: false,
            },
            transcription_usage: {
                available: 0,
                used: 0,
                is_ninety_percent_usage: false,
            },
            transcription_settings: {},
            keyword_input_visible: false,
            custom_keyword_value: '',
            dictionary_input_visible: false,
            dictionary_word_value: '',
            summary_highlight_options: [],
            digest_report_templates: [],
            loading_enable_transcription_for_users: false,
            loading_disable_transcription_for_users: false,
            loading_users_list: false,
            max_talk_time: parseInt(
                localStorage.getItem('transcriber_max_talk_time')
            ),
            show_spelling_modal: false,
            current_from_word: '',
            from_word_input_visible: false,
            current_to_word: '',
            to_word_input_visible: false,
            editing_index: null,
            spelling_input: {
                from: [],
                to: '',
            },
            neutral_dialog_visible: false,
            current_neutral_message: '',
            editing_neutral_index: null,
            editing_summary_highlight: {},
            highlight_editing_index: null,
            selected_highlight: null,
            highlight_dialog_visible: false,
            current_step: 1, // Tracks the current step (1: Selection, 2: Template, 3: Customization)
            selected_highlight_type: '',
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        ...mapState(['users']),

        showWarning() {
            return this.current_company &&
                this.current_company.transcription_settings &&
                this.current_company.transcription_settings?.call_transcription_enabled &&
                this.transcription_usage.is_ninety_percent_usage
        },

        includedMinutes() {
            return this.current_company?.plan?.included_transcription_min
        },

        usedMinutes() {
            return this.current_company?.used_transcription_min
        },

        transcriptionRate() {
            return this.auth?.user?.profile?.rate?.transcription
        },

        transcriptionEnabled() {
            return this.current_company?.transcription_settings?.call_transcription_enabled
        },

        overusageRestrictionEnabled() {
            return this.current_company?.transcription_settings?.overusage_restriction_enabled
        },

        isTrial() {
            return this.current_company?.transcription_settings?.is_trial
        },

        

        usagePercentage() {
            if (this.includedMinutes === 0) return 0
            return (this.usedMinutes * 100) / this.includedMinutes
        },

        modalContent() {
            // Centralized modal logic based on conditions
            if (this.usagePercentage >= 80 && this.usagePercentage < 100) {
                return {
                    title: `You’ve almost reached the limit of your ${this.includedMinutes} minutes included in your AloAi Voice Analytics plan. Our AI engine has been working hard to transcribe, analyze, and summarize your calls, helping you get the most out of every conversation.`,
                    message: `To avoid interruptions in service, consider upgrading your plan for more minutes and additional features tailored to meet your growing needs.`
                }
            }

            if (this.usedMinutes >= this.includedMinutes) {
                if (this.overusageRestrictionEnabled) {
                    // If the plan is not an AI plan, hide cost-related and plan-specific messages.
                    if (!this.current_company?.plan?.ai_plan) {
                        return {
                            title: `You’ve used all ${this.includedMinutes} trial minutes, but don’t worry! You can easily purchase additional transcription minutes by reaching out to our CSM team, you can upgrade your plan to include more minutes and unlock additional features.`,
                            message: `To ensure uninterrupted access and additional benefits, consider upgrading your plan for more included minutes and enhanced features.`
                        }
                    }
                    // If the plan is an AI plan, show cost-related messages.
                    return {
                        title: `You’ve reached the ${this.includedMinutes} minutes included in your AloAi Voice Analytics plan. However, with Overusage Billing Restriction disabled, you can continue using the service seamlessly. Additional transcription minutes will be charged at just ${Math.round(Number(this.transcriptionRate) * 100)} cents/min.`,
                        message: `To ensure uninterrupted access and additional benefits, consider upgrading your plan for more included minutes and enhanced features.`
                    }
                }
                return {
                    title: `You’ve used all ${this.includedMinutes} minutes included in your AloAi Voice Analytics plan. But don’t worry — you can continue using the service! After your free minutes, each transcription minute will cost just ${Math.round(Number(this.transcriptionRate) * 100)} cents/min.`,
                    message: `To maintain uninterrupted service, you can choose to enable the option to continue using the service beyond your included minutes. By enabling this option, you agree to be charged $${Number(this.transcriptionRate)} per additional transcription minute after hitting your limit. Alternatively, you may consider upgrading your plan to receive more included minutes and access additional features.`
                }
            }

            return {
                title: `You currently have ${this.includedMinutes} minutes included in your AloAi Voice Analytics plan. Our AI engine will transcribe, analyze, and summarize your calls effortlessly. Simply navigate to any contact you've called to see it in action.`,
                message: `Need more minutes to keep up with your growing needs? Upgrade your plan now for additional minutes and enhanced features.`
            }
        },

        outboundCallRecordingUrl () {
            return `/account?tab=calling-settings#outbound_call_recording_mode`
        },

        inboundCallRecordingUrl () {
            return `/account?tab=calling-settings#inbound_call_recording_mode`
        },

        isAllCallRecordingDisabled () {
            return (
                this.current_company?.inbound_call_recording_mode === InboundCallRecordingModes.INBOUND_CALL_RECORDING_MODE_NEVER &&
                this.current_company?.outbound_call_recording_mode === OutboundCallRecordingModes.OUTBOUND_CALL_RECORDING_MODE_NEVER
            )
        },

        isAnyCallRecordingDisabled () {
            return (
                this.current_company?.inbound_call_recording_mode === InboundCallRecordingModes.INBOUND_CALL_RECORDING_MODE_NEVER ||
                this.current_company?.outbound_call_recording_mode === OutboundCallRecordingModes.OUTBOUND_CALL_RECORDING_MODE_NEVER
            )
        },
    },

    created() {
        // sets the needed property of the component
        if (this.company_clone && this.company_clone.id) {
            this.initializeProperties()
        }

        // Fetch summary highlight options from API
        this.fetchSummaryHighlightOptions()
        
        // Fetch daily digest prompt options from API
        this.fetchDigestReportTemplates()

        // If Transcription Settings are accessed via URL
        if (this.$route.query.tab === 'transcription-settings' && !this.company_clone.transcription_enabled) {
            // Redirect the user to Account Settings when AloAi Voice Analytics is not enabled.
            this.$router.push({
                name: 'Account',
                query: {
                    tab: 'settings'
                }
            })
        }
    },

    methods: {
        // initialize the company props that are being used in this component
        initializeProperties() {
            this.transcription_settings = {
                call_transcription_enabled: this.company_clone?.transcription_settings?.call_transcription_enabled ?? false,
                overusage_restriction_enabled: this.company_clone?.transcription_settings?.overusage_restriction_enabled ?? false,
                minimum_talk_time: this.company_clone?.transcription_settings?.minimum_talk_time ?? 0,
                voicemail_transcription_enabled: this.company_clone?.transcription_settings?.voicemail_transcription_enabled ?? false,
                custom_keywords: this.company_clone?.transcription_settings?.custom_keywords ?? [],
                custom_dictionary: this.company_clone?.transcription_settings?.custom_dictionary ?? [],
                custom_spelling: this.company_clone?.transcription_settings?.custom_spelling ?? [],
                automatic_redaction_enabled: this.company_clone?.transcription_settings?.automatic_redaction_enabled ?? false,
                content_moderation_enabled: this.company_clone?.transcription_settings?.content_moderation_enabled ?? false,
                summary_length: this.company_clone?.transcription_settings?.summary_length ?? 300,
                summarization_enabled: this.company_clone?.transcription_settings?.summarization_enabled ?? false,
                summary_prompt_list: this.company_clone?.transcription_settings?.summary_prompt_list ?? [],
                sync_summaries_to_notes: this.company_clone?.transcription_settings?.sync_summaries_to_notes ?? false,
                is_trial: this.company_clone?.transcription_settings?.is_trial ?? false,
                neutral_sentiment_messages: this.company_clone?.transcription_settings?.neutral_sentiment_messages ?? [],
                daily_digest_report_enabled: this.company_clone?.transcription_settings?.daily_digest_report_enabled ?? false,
                daily_digest_report_prompt_list: this.company_clone?.transcription_settings?.daily_digest_report_prompt_list ?? [],
                on_demand_enabled: this.company_clone?.transcription_settings?.on_demand_enabled ?? false,
            }

            const ninety_perc_usage = this.company_clone?.plan?.included_transcription_min * 0.9

            this.transcription_usage = {
                available: this.company_clone?.plan?.included_transcription_min ?? 0,
                used: this.company_clone?.used_transcription_min ?? 0,
                is_ninety_percent_usage: this.company_clone?.used_transcription_min >= ninety_perc_usage,
            }
        },

        // fetches the summary highlight options
        fetchSummaryHighlightOptions() {
            axios.get('/api/v1/summarization-prompt-options')
            .then((response) => {
                this.summary_highlight_options = response.data.map(option => ({
                    id: option.id,
                    label: option.label,
                    prompt: option.prompt,
                }))
            })
            .catch((err) => {
                this.$root.handleErrors(err.response)
            })
        },

        // fetches the daily digest prompt options
        fetchDigestReportTemplates() {
            axios.get('/api/v1/daily-digest-prompt-options')
            .then((response) => {
                this.digest_report_templates = response.data.map(option => ({
                    id: option.id,
                    label: option.label,
                    prompt: option.prompt,
                }))
            })
            .catch((err) => {
                this.$root.handleErrors(err.response)
            })
        },

        //update transcription settings when a setting has changed
        updateSetting: debounce(function () {
            // get company id from parent component
            const company_id = this.company_clone.id

            axios.post(`/api/v1/company/${company_id}/transcription-setting`, this.transcription_settings).then((res) => {
                this.$notify({
                    offset: 95,
                    title: 'Account',
                    message: res.data.message,
                    type: 'success',
                    showClose: true,
                })
            }).catch((err) => {
                this.$root.handleErrors(err.response)
            })
        }, 500),

        // remove dictionary word handler.
        handleDictionaryClose(tag) {
            this.transcription_settings.custom_dictionary.splice(
                this.transcription_settings.custom_dictionary.indexOf(tag),
                1
            )
            this.updateSetting()
        },

        // remove custom keyword handler.
        handleKeywordsClose(tag) {
            this.transcription_settings.custom_keywords.splice(
                this.transcription_settings.custom_keywords.indexOf(tag),
                1
            )
            this.updateSetting()
        },

        // show dictionary input handler.
        showDictionaryInput() {
            this.dictionary_input_visible = true
            this.$nextTick((_) => {
                this.$refs.saveDictionaryWordInput.$refs.input.focus()
            })
        },

        // show custom keywords input handler.
        showKeywordInput() {
            this.keyword_input_visible = true
            this.$nextTick((_) => {
                this.$refs.saveKeywordInput.$refs.input.focus()
            })
        },

        // handles any input confirmation from the panel.
        handleInputConfirm() {
            // check if last added element already exist in Custom Vocabulary.
            let dictionary_word_value = this.dictionary_word_value.trim()
            if (dictionary_word_value && !this.transcription_settings.custom_dictionary.includes(dictionary_word_value)) {
                // Before updating, validate that the word does not contain any sort of digits.
                if (/\d/.test(dictionary_word_value)) {
                    this.$notify({
                        offset: 95,
                        title: 'Account',
                        message: 'Custom Vocabulary words cannot contain any digits.',
                        type: 'error',
                    })
                    return
                }
                this.transcription_settings.custom_dictionary.push(dictionary_word_value)
                this.updateSetting()
            }

            // check if last added element already exist in custom keywords.
            let custom_keyword_value = this.custom_keyword_value.trim()
            const ucfirst_keyword = this.$options.filters.ucfirst(custom_keyword_value)
            if (ucfirst_keyword && !this.transcription_settings.custom_keywords.includes(ucfirst_keyword)) {
                this.transcription_settings.custom_keywords.push(ucfirst_keyword)
                this.updateSetting()
            }

            // check if minimum talk_time has changed.
            if (this.transcription_settings.minimum_talk_time != this.company_clone?.transcription_settings?.minimum_talk_time) {
                this.updateSetting()
            }

            if (this.transcription_settings.summary_length !== this.company_clone?.transcription_settings?.summary_length) {
                this.updateSetting()
            }


            if (!_.isEqual(this.transcription_settings.summary_prompt_list, this.company_clone?.transcription_settings?.summary_prompt_list)) {
                this.updateSetting()
            }

            // reset components
            this.dictionary_input_visible = false
            this.keyword_input_visible = false
            this.dictionary_word_value = ''
            this.custom_keyword_value = ''
        },

        // enable transcription for all company users
        updateTranscriptionEnabledUsers(enabled) {
            const company_id = this.company_clone.id

            // loading state in one button at a time
            if (enabled) {
                this.loading_enable_transcription_for_users = true
            } else {
                this.loading_disable_transcription_for_users = true
            }

            const option = enabled ? 'enabled' : 'disabled'

            axios.post(`/api/v1/company/${company_id}/update-users-transcription`, {
                enabled,
            }).then((res) => {
                this.$notify({
                    offset: 95,
                    title: 'Account',
                    message: `AloAi Voice Analytics is being ${option} for all users.`,
                    type: 'success',
                })
                this.loading_enable_transcription_for_users = false
                this.loading_disable_transcription_for_users = false
            }).catch((err) => {
                this.$root.handleErrors(err.response)
                this.loading_enable_transcription_for_users = false
                this.loading_disable_transcription_for_users = false
            })
        },

        // add new "From" word to custom spelling
        addFromWord() {
            const trimmed_word = this.current_from_word.trim()
            if (trimmed_word && !this.spelling_input.from.includes(trimmed_word)) {
                this.spelling_input.from.push(trimmed_word)
            }
            this.current_from_word = ''
            this.from_word_input_visible = false
        },

        // show "From" word input
        showFromWordInput() {
            this.from_word_input_visible = true
            this.$nextTick(() => {
                this.$refs.fromWordInput.$refs.input.focus()
            })
        },

        removeWord(index) {
            this.spelling_input.from.splice(index, 1)
        },

        // edit custom spelling pair and set index to edit
        editSpelling(index) {
            const spelling = this.transcription_settings.custom_spelling[index]
            this.spelling_input = {
                from: [...spelling.from],
                to: spelling.to,
            }
            this.editing_index = index
            this.show_spelling_modal = true
        },

        // add custom spelling pair
        addCustomSpelling() {
            const {from, to} = this.spelling_input

            // Validate the input
            if (from.length === 0) {
                this.$notify({
                    type: 'error',
                    message: 'Please provide at least one "From" word.',
                })
                return
            }

            const trimmed_to = to.trim()
            // Check if the "To" word is provided
            if (!trimmed_to) {
                this.$notify({
                    type: 'error',
                    message: 'Please provide a valid "To" word.',
                })
                return
            }

            // Check if the "To" word contains spaces
            if (/\s/.test(trimmed_to)) {
                this.$notify({
                    type: 'error',
                    message: 'The "To" field must be a single word without spaces.',
                })
                return
            }

            // Check if the custom spelling pair already exists and prevent adding duplicates
            if (this.transcription_settings.custom_spelling.some(
                (spelling) =>
                    spelling.to === trimmed_to &&
                    spelling.from.length === from.length &&
                    from.every((word) => spelling.from.includes(word))
            )) {
                this.$notify({
                    type: 'error',
                    message: 'This custom spelling pair already exists.',
                })
                return
            }

            if (this.editing_index !== null) {
                // Remove the old entry before updating
                this.transcription_settings.custom_spelling.splice(this.editing_index, 1)
            }

            // Check if there is an existing entry with the same "to" word
            const existingEntry = this.transcription_settings.custom_spelling.find(
                (spelling) => spelling.to === trimmed_to
            )
            if (existingEntry) {
                // Merge new "from" words into the existing entry with same "to" word
                const existingFromWords = new Set(existingEntry.from)
                const newFromWords = from.filter((word) => !existingFromWords.has(word))
                existingEntry.from.push(...newFromWords)
            } else {
                this.transcription_settings.custom_spelling.push({from, to: trimmed_to})
            }

            // Reset the form
            this.resetSpellingForm()
            this.updateSetting()
        },

        // remove custom spelling pair
        removeSpelling(index) {
            if (index >= 0 && index < this.transcription_settings.custom_spelling.length) {
                this.transcription_settings.custom_spelling.splice(index, 1)
                this.updateSetting()
            }
        },

        // show "From" word input
        showToWordInput() {
            this.to_word_input_visible = true
            this.current_to_word = this.spelling_input.to
            this.$nextTick(() => {
                this.$refs.toWordInput.$refs.input.focus()
            })
        },

        addToWord() {
            const trimmed_word = this.current_to_word.trim()
            if (trimmed_word) {
                this.spelling_input.to = trimmed_word
            }
            this.current_to_word = ''
            this.to_word_input_visible = false
        },

        removeToWord() {
            this.spelling_input.to = ''
        },

        // reset custom spelling form
        resetSpellingForm() {
            this.show_spelling_modal = false
            this.spelling_input = {from: [], to: ''}
            this.from_word_input_visible = false
            this.current_from_word = ''
            this.to_word_input_visible = false
            this.current_to_word = ''
            this.editing_index = null
        },

        displayUserTranscriptionManagementDialog() {
            this.loading_users_list = true
            // reload the users list before opening the modal
            this.$root.getUsers().then(() => {
                this.users_enablement.display_dialog = true
                this.loading_users_list = false
            })
        },

        closeUserTranscriptionManagementDialog() {
            this.users_enablement.display_dialog = false
        },

        // Open the dialog for adding or editing a neutral message
        openNeutralDialog(index = null) {
            this.editing_neutral_index = index
            this.current_neutral_message =
                index !== null
                    ? this.transcription_settings.neutral_sentiment_messages[index]
                    : ''
            this.neutral_dialog_visible = true
        },

        // Reset the dialog state
        resetNeutralMessageDialog() {
            this.neutral_dialog_visible = false
            this.current_neutral_message = ''
            this.editing_neutral_index = null
        },

        // Save or update the neutral message
        saveNeutralMessage() {
            const trimmedMessage = this.current_neutral_message.trim()
            if (!trimmedMessage) {
                this.$notify({
                    title: 'Error',
                    message: 'Neutral sentiment message cannot be empty.',
                    type: 'error',
                })
                return
            }

            if (this.editing_neutral_index !== null) {
                // Update an existing message
                this.$set(
                    this.transcription_settings.neutral_sentiment_messages,
                    this.editing_neutral_index,
                    trimmedMessage
                )
            } else if (
                !this.transcription_settings.neutral_sentiment_messages.includes(trimmedMessage)
            ) {
                // Add a new message
                this.transcription_settings.neutral_sentiment_messages.push(trimmedMessage)
            }

            this.updateSetting()
            this.resetNeutralMessageDialog()
        },

        // Remove a neutral message
        removeNeutralMessage(index) {
            this.transcription_settings.neutral_sentiment_messages.splice(index, 1)
            this.updateSetting()
        },

        // Edit an existing neutral message
        editNeutralMessage(index) {
            this.openNeutralDialog(index)
        },

        // Open Step 1 dialog
        openSummaryHighlightModal() {
            this.highlight_dialog_visible = true
            this.current_step = 1
            this.highlight_editing_index = null
            this.selected_highlight_type = ''
            this.editing_summary_highlight = {
                label: '',
                prompt: '',
                order: this.getNextOrder(),
            }
        },

        editSummaryHighlight(item, index) {
            this.editing_summary_highlight = { ...item }
            this.highlight_editing_index = index
            this.highlight_dialog_visible = true
            this.current_step = 3 // Start directly on the Customize Highlight step
        },

        // Save the summary highlight (No changes to order logic)
        saveSummaryHighlight() {
            const trimmedLabel = this.editing_summary_highlight.label?.trim()

            if (!trimmedLabel || !this.editing_summary_highlight.prompt?.trim()) {
                this.$notify({ type: 'error', message: 'Both label and prompt are required.' })
                return
            }

            const isDuplicateLabel = this.transcription_settings.summary_prompt_list.some(
                (item, index) =>
                    item.label.toLowerCase() === trimmedLabel.toLowerCase() &&
                    index !== this.highlight_editing_index
            )

            if (isDuplicateLabel) {
                this.$notify({ type: 'error', message: 'The label must be unique. A duplicate label was found.' })
                return
            }

            // Adjust order for logical placement
            this.editing_summary_highlight.order -= 0.5

            if (this.highlight_editing_index !== null) {
                this.$set(this.transcription_settings.summary_prompt_list, this.highlight_editing_index, {
                    ...this.editing_summary_highlight,
                    label: trimmedLabel,
                })
            } else {
                this.transcription_settings.summary_prompt_list.push({
                    ...this.editing_summary_highlight,
                    label: trimmedLabel,
                })
            }
            // Sort the list by order
            this.transcription_settings.summary_prompt_list.sort((a, b) => a.order - b.order)
            this.handleReorder()
            this.closeStepDialog()
        },

        removeSummaryHighlight(index) {
            if (this.transcription_settings.summary_prompt_list.length <= 1) {
                this.$notify({
                    type: 'error',
                    message: 'At least one custom highlight is required.',
                })
                return
            }
            this.transcription_settings.summary_prompt_list.splice(index, 1)
            this.handleReorder()
        },

        handleReorder() {
            // Reindex the list for standard order
            this.transcription_settings.summary_prompt_list.forEach((item, index) => {
                item.order = index + 1
            })
            this.updateSetting()
        },

        getNextOrder() {
            return this.transcription_settings.summary_prompt_list.length + 1
        },

        // Apply a predefined template
        applyPredefinedPrompt(selectedOption) {
            if (selectedOption) {
            this.editing_summary_highlight.label = selectedOption.label
            this.editing_summary_highlight.prompt = selectedOption.prompt
            this.current_step = 3 // Move to Final Customization
            }
        },

        // Handle selection in Step 1
        handleHighlightSelection(value) {
            if (value === 'template') {
                this.current_step = 2
                this.selected_highlight = null
            } else {
                this.current_step = 3
                this.editing_summary_highlight = { label: '', prompt: '', order: this.getNextOrder() }
            }
        },

        closeStepDialog() {
            this.highlight_dialog_visible = false
            this.current_step = 1
            this.editing_summary_highlight = {}
        },
    },

    watch: {
        'transcription_settings.call_transcription_enabled'(to, from) {
            if (from !== undefined) {
                this.transcription_settings.summarization_enabled = to
                this.updateSetting()
            }

        },
        'transcription_settings.summarization_enabled'(to, from) {
            if (from !== undefined) {
                this.transcription_settings.sync_summaries_to_notes = to
                this.updateSetting()
            }
        },
        $route({params, query, hash}) {
            // If somehow a user manage to click the Transcription Settings tab even when AloAi Voice Analytics is disabled.
            if (query.tab === 'transcription-settings' && !this.company_clone.transcription_enabled) {
                this.goBack()
            }
        }
    }
}
</script>

<style scoped>
.neutral-sentiment-tag {
    background-color: #f8f0ff;
    color: #7a3eb1;
    border-color: #d8bff9;
    font-weight: bold;
}

.draggable-item {
    margin-bottom: 8px;
}

.highlight-tag {
    font-size: 15px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
}

.drag-handle {
    margin-right: 8px;
    font-weight: bold;
    cursor: grab;
    user-select: none;
}

.highlight-options .equal-height-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; 
}

.highlights-container {
    flex: 1;
}

.summary-preview {
    flex: 1;
    overflow-y: hidden;
    background: #f9f9f9;
    padding: 8px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    max-height: 480px;
}

.ai-effect-content {
    overflow-y: auto;
    max-height: 400px;
}

.ai-effect-container {
    max-height: 450px;
    overflow: hidden;
}

.inline-summary-item {
    margin-bottom: 4px;
    line-height: 1.5;
}
</style>
