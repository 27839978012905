<template>
    <el-tooltip class="item ml-1"
                effect="dark"
                :placement="placement">
        <div slot="content">
            Supervisors will help you to manage the team members. They are able to add and remove users from the teams they are supervising.
        </div>
        <i class="material-icons text-sm cursor-pointer text-blue"
           :style="`z-index: ${zIndex} !important;`">info_outline</i>
    </el-tooltip>
</template>

<script>
export default {
    name: 'TeamSupervisorTooltip',

    props: {
        placement: {
            type: String,
            default: 'top'
        },
        zIndex: {
            type: Number,
            default: 2005
        }
    }
}
</script>
