<template>
    <div class="app-body teams"
         id="view">
        <template v-if="!isEditingTeam">
            <div class="header-placeholder"
                 :style="{height: '60px'}">
            </div>
            <div class="fixed-header padding pt-0 pb-0"
                 :style="{top: (top_offset+50) + 'px'}">
                <div class="row mb-0 mt-0">
                    <div class="d-flex flex-fill align-items-center flex-wrap p-2">
                        <div class="d-flex flex-wrap flex-xl-nowrap flex-fill mr-auto">
                            <div class="flex-fill flex-xl-grow-0 search-toolbar">
                                <el-input class="w-full"
                                          placeholder="Search Teams"
                                          prefix-icon="el-icon-search"
                                          clearable
                                          v-model="searchInput"
                                          @input="onSearchInput" />
                            </div>
                        </div>
                        <div class="d-flex flex-wrap flex-xl-nowrap flex-shrink-0">
                            <div class="ml-3"
                                 v-if="hasPermissionTo(CREATE_TEAM_PERMISSION)">
                                <el-button class="btn rounded greenish h-100"
                                           type="success"
                                           data-testid="team-creator-open-modal-button"
                                           @click="$router.push({ name: 'Team' })">
                                    <i class="fa fa-plus"></i>
                                    Add Team
                                </el-button>
                            </div>
                            <div class="mt-2 mt-lg-0 ml-2 d-flex flex-column justify-content-center text-blue">
                                <i class="material-icons text-lg cursor-pointer"
                                   data-testid="info-outline-i"
                                   @click="toggleHelp">
                                    info_outline
                                </i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-3">
                <el-table class="w-full company-teams-list"
                          ref="company-teams-list"
                          stripe
                          style="width: 100%"
                          :data="teams"
                          v-loading="isLoading"
                          @cell-mouse-enter="cellEnter"
                          @mouseleave="tableLeave"
                          @sort-change="handleSortChange">
                    <el-table-column label="Team"
                                     prop="name"
                                     sortable="custom"
                                     :min-width="200"
                                     show-overflow-tooltip>
                        <template v-slot="scope">
                            <span class="text-dark-greenish cursor-pointer"
                                  @click="onManageMembers(scope.row)">{{ scope.row?.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Description"
                                     prop="description"
                                     sortable="custom"
                                     show-overflow-tooltip
                                     :min-width="200">
                        <template v-slot="scope">
                            {{ scope.row?.description || '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center"
                                     label="Ring Groups"
                                     prop="total_ring_groups"
                                     :min-width="120">
                        <template v-slot="scope">
                            <el-tooltip placement="top"
                                        v-if="scope.row.ring_groups?.length">
                                <div slot="content">
                                    <div :key="rg.id"
                                         v-for="rg in scope.row.ring_groups">
                                        {{ rg.name }}
                                    </div>
                                </div>
                                <span class="cursor-help">{{ scope.row.total_ring_groups || 0 }}</span>
                            </el-tooltip>
                            <span v-else>{{ scope.row.total_ring_groups || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Users"
                                     prop="total_users"
                                     align="center"
                                     :min-width="120">
                        <template v-slot="scope">
                            <el-tooltip placement="top"
                                        v-if="scope.row.users?.length">
                                <div slot="content">
                                    <div v-for="user in scope.row.users"
                                         :key="user.id">
                                        {{ user.first_name }} {{ user.last_name }}
                                    </div>
                                </div>
                                <span class="cursor-help">{{ scope.row.total_users || 0 }}</span>
                            </el-tooltip>
                            <span v-else>{{ scope.row.total_users || 0 }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="Supervisors"
                                     prop="total_supervisors"
                                     :min-width="120"
                                     align="center">
                        <template v-slot="scope">
                            <el-tooltip placement="top"
                                        v-if="scope.row.supervisors?.length">
                                <div slot="content">
                                    <div v-for="supervisor in scope.row.supervisors"
                                         :key="supervisor.id">
                                        {{ supervisor.first_name }} {{ supervisor.last_name }}
                                    </div>
                                </div>
                                <span class="cursor-help">{{ scope.row.total_supervisors || 0 }}</span>
                            </el-tooltip>
                            <span v-else>{{ scope.row.total_supervisors || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Created by"
                                     prop="created_by"
                                     sortable="custom"
                                     :min-width="150">
                        <template v-slot="scope">
                            {{ getFullName(scope.row?.created_by) }}
                        </template>
                    </el-table-column>

                    <el-table-column label="Created on"
                                     prop="created_at"
                                     sortable="custom"
                                     :min-width="150">
                        <template v-slot="scope">
                            {{ scope.row?.created_at | fixFullDateLocal }}
                        </template>
                    </el-table-column>

                    <el-table-column label="Operations"
                                     align="center"
                                     width="150"
                                     fixed="right">
                        <template v-slot="scope">
                            <div v-bind:style="getStyle(scope.row.id)">
                                <div class="row mt-2">
                                    <div class="col-12">
                                        <button class="btn btn-sm btn-block greenish"
                                                data-testid="settings-button"
                                                @click.stop="onManageMembers(scope.row)">
                                            <i class="fa fa-cogs pull-left"></i>
                                            Settings
                                        </button>
                                    </div>
                                </div>
                                <div class="row mt-2" v-if="hasPermissionTo(CREATE_TEAM_PERMISSION)">
                                    <div class="col-12">
                                        <router-link :to="{ name: 'Team Activity', params: {team_id: scope.row.id }}">
                                            <button class="btn btn-sm btn-block purplish"
                                                    data-testid="activity-button">
                                                <i class="fa fa-signal pull-left"></i>
                                                Activity
                                            </button>
                                        </router-link>
                                    </div>
                                </div>
                                <div class="row mt-2"
                                     v-if="hasPermissionTo(DELETE_TEAM_PERMISSION)">
                                    <div class="col-12">
                                        <button class="btn btn-sm btn-block danger"
                                                data-testid="delete-button"
                                                @click.stop="openDeleteTeamModal(scope.row)">
                                            <i class="fa fa-trash pull-left"></i>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="d-flex align-items-center justify-content-end py-3 px-3">
                <el-pagination layout="sizes, prev, pager, next"
                               background
                               class="pull-right"
                               data-testid="teams-list-pagination"
                               :current-page.sync="teamsPagination.page"
                               :page-sizes="[10, 20, 50, 100, 250]"
                               :page-size.sync="teamsPagination.size"
                               :total="teamsPagination.total"
                               @size-change="paginateTeams(1)"
                               @current-change="paginateTeams" />
                <div class="clearfix"></div>
            </div>
        </template>

        <edit-team-members :selected-team="selectedTeam"
                           :company="current_company"
                           v-else
                           @back-to-teams-list="onBackToTeamsList" />

        <el-dialog title="Delete Team"
                   width="30%"
                   center
                   :visible.sync="isDeleteTeamModalVisible">
            <p class="break-word text-center">You're about to delete the <strong>{{ selectedTeam?.name }}</strong> Team. This action can't be undone.</p>
            <p class="break-word text-center mb-4">Are you sure?</p>
            <div class="d-flex justify-content-center mt-5 row">
                <el-button class="mb-2"
                           type="default"
                           slot="reference"
                           @click="closeDeleteTeamModal">
                    No, Keep Team
                </el-button>
                <el-button class="mb-2"
                           type="danger"
                           slot="reference"
                           data-testid="team-deleter-delete-button"
                           :disabled="isLoading"
                           @click="onDeleteTeam">
                    Yes, Delete Team
                </el-button>
            </div>
        </el-dialog>

        <el-dialog title="Delete Team"
                   width="30%"
                   center
                   :visible.sync="isDeleteTeamModalErrorVisible">
            <p>The <strong>{{ selectedTeam?.name }}</strong> Team may be being used on a Ring Group or has users assigned to it. To delete, you have to remove it from the Billing Ring Group or remove the users from the team.</p>
            <div class="d-flex justify-content-center mt-5">
                <el-button type="default"
                           slot="reference"
                           @click="isDeleteTeamModalErrorVisible = false">
                    Close
                </el-button>
            </div>
        </el-dialog>

        <el-dialog title="What are teams?"
                   top="10vh"
                   data-testid="what-are-teams-dialog"
                   append-to-body
                   :visible.sync="show_help">
            <p class="text-md _600 break-word">Teams help you organize agents efficiently for smarter call routing, streamlined messaging, and balanced workloads.</p>
            <div class="mt-4">
                <ul class="text-md">
                    <li class="mb-3">
                        <strong>Optimize Routing & Inbox Management</strong> – Assign agents to Ring Groups and Inbox Routing to ensure calls and messages reach the right team.
                    </li>
                    <li class="mb-3">
                        <strong>Control Visibility & Access</strong> – Agents can only see conversations, contacts, and data relevant to their assigned team, preventing cross-team confusion.
                    </li>
                    <li class="mb-3">
                        <strong>Simplify Permissions & Reporting</strong> – Manage access and track performance based on team roles.
                    </li>
                    <li class="mb-3">
                        <strong>Boost Productivity</strong> – Organize users by department, skill, or role for better collaboration and faster response times.
                    </li>
                </ul>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import auth from '../../auth'
import { acl_mixin, statics_mixin, scroll_mixin, styling_mixin } from '../../mixins'
import { mapActions, mapState } from 'vuex'
import { debounce } from 'lodash'

import {
    CREATE_TEAM as CREATE_TEAM_PERMISSION,
    UPDATE_TEAM as UPDATE_TEAM_PERMISSION,
    DELETE_TEAM as DELETE_TEAM_PERMISSION,
} from '../../constants/permissions'
import { COMPANY_SUPERVISOR } from '../../constants/roles'
import { TEAM_SUPERVISOR_TYPE } from '../../constants/team'

export default {
    components: {},

    mixins: [
        acl_mixin,
        statics_mixin,
        scroll_mixin,
        styling_mixin
    ],

    data () {
        return {
            CREATE_TEAM_PERMISSION,
            UPDATE_TEAM_PERMISSION,
            DELETE_TEAM_PERMISSION,
            auth: auth,
            isLoading: false,
            teams: [],
            searchInput: '',
            isDeleteTeamModalVisible: false,
            isDeleteTeamModalErrorVisible: false,
            selectedTeam: null,
            show_help: false,
            visible_row_id: null,
            teamsPagination: {
                page: 1,
                size: 20,
                total: 0
            },
            isEditingTeam: false,
            totalTeams: 0,
            sortBy: 'name',
            sortOrder: 'ASC'
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),
        ...mapState(['users']),

        filteredUsersToAdd () {
            return this.users.filter(user => !user.is_destination)
        },

        filteredSupervisorsToAdd () {
            return this.users.filter(({ role_names = [] }) => role_names.includes(COMPANY_SUPERVISOR))
        },

        filteredTeams () {
            if (this.searchInput.length > 0) {
                let teams = this.teams

                teams = this.teams.filter(team => {
                    return team.name.toLowerCase().includes(this.searchInput.toLowerCase())
                })
                this.teamsPagination.total = teams?.length

                return teams
            }

            this.teamsPagination.total = this.totalTeams
            return this.teams
        }
    },

    methods: {
        ...mapActions([
            'setCurrentTeam',
            'setShouldReloadAllTeams'
        ]),

        openDeleteTeamModal (team) {
            this.selectedTeam = team
            this.isDeleteTeamModalVisible = true
        },

        closeDeleteTeamModal () {
            this.selectedTeam = null
            this.isDeleteTeamModalVisible = false
        },

        paginateTeams (page) {
            this.teamsPagination.page = page
            this.fetchTeams()
        },

        onManageMembers (team) {
            this.selectedTeam = team
            this.setCurrentTeam(team)
            this.$router.push({ name: 'Team', params: { team_id: team.id } })
        },

        onBackToTeamsList () {
            this.isEditingTeam = false
            this.selectedTeam = null
            this.fetchTeams()
        },

        fetchTeams () {
            this.isLoading = true

            const params = {
                page: this.teamsPagination.page,
                per_page: this.teamsPagination.size,
                search: this.searchInput,
                sort_by: this.sortBy,
                sort_order: this.sortOrder,
                /* for supervisors getting only the teams where the user is supervising */
                type: TEAM_SUPERVISOR_TYPE
            }
            this.$APIV2.Teams.index(params)
                .then(res => {
                    this.teamsPagination.total = res.data.meta?.total
                    this.totalTeams = res.data.meta?.total
                    this.teams = res.data?.data

                    if (this.selectedTeam) {
                        this.selectedTeam = this.teams.find(team => team.id === this.selectedTeam.id)
                    }
                })
                .catch(err => {
                    console.log(err)
                }).finally(() => {
                    this.isLoading = false
                })
        },

        onSearchInput: debounce(function () {
            if (this.searchInput.length === 0 || this.searchInput.length >= 3) {
                this.teamsPagination.page = 1
                this.fetchTeams()
            }
        }, 1000),

        getFullName (object) {
            if (!object) {
                return '-'
            }

            return `${object?.first_name} ${object?.last_name}`
        },

        onDeleteTeam () {
            this.isLoading = true

            this.$APIV2.Teams.destroy(this.selectedTeam.id)
                .then(res => {
                    this.$notify({
                        title: 'Success',
                        message: 'Team deleted successfully',
                        type: 'success',
                        showClose: true
                    })

                    this.isDeleteTeamModalVisible = false
                    this.setShouldReloadAllTeams(true)
                    this.fetchTeams()
                })
                .catch(err => {
                    this.$root.handleErrors(err.response)
                    this.isDeleteTeamModalVisible = true
                    console.log(err)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },

        toggleHelp () {
            this.show_help = !this.show_help
        },

        getStyle (id) {
            let opacity = 0.5
            if (id === this.visible_row_id) {
                opacity = 1
            }
            return {
                opacity: opacity
            }
        },

        cellEnter (row, column, cell, event) {
            this.visible_row_id = row.id
        },

        tableLeave () {
            this.visible_row_id = null
        },

        handleSortChange ({ prop, order }) {
            if (prop && order) {
                this.sortBy = prop
                this.sortOrder = order === 'ascending' ? 'ASC' : 'DESC'
                this.fetchTeams()
            }
        }
    },

    created () {
        this.fetchTeams()
        this.getStatics({ page_title: 'Teams Beta' })
    },

    beforeRouteEnter (to, from, next) {
        auth.check()
            .then((res) => {
                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({ name: 'Account Management Portal' })
                } else {
                    next(vm => {
                        if (!vm.hasRole('Company Admin') && !vm.hasRole('Company Supervisor')) {
                            next({ name: 'Dashboard' })
                        }
                    })
                }
            })
            .catch(() => {
                next({ name: 'Login', query: { redirect: to.fullPath } })
            })
    }
}
</script>

<style scoped>
.company-teams-list ::v-deep .el-table__fixed-right::before {
    content: '';
    position: absolute;
    left: -1px;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #EBEEF5;
    z-index: 1;
}

.company-teams-list ::v-deep .el-table__fixed-right {
    height: 100% !important;
    background-color: #fff;
}

.cursor-help {
    cursor: help;
}

.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
    content: '*';
    color: #F56C6C;
    margin-right: 4px;
}

.supervisor-form-item {
    :deep(.el-form-item__label) {
        overflow: visible;
    }
}
</style>
