import { render, staticRenderFns } from "./integrations.vue?vue&type=template&id=1b9f8f10"
import script from "./integrations.vue?vue&type=script&lang=js"
export * from "./integrations.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/app/releases/20250403222539Z/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b9f8f10')) {
      api.createRecord('1b9f8f10', component.options)
    } else {
      api.reload('1b9f8f10', component.options)
    }
    module.hot.accept("./integrations.vue?vue&type=template&id=1b9f8f10", function () {
      api.rerender('1b9f8f10', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/user/views/integrations.vue"
export default component.exports