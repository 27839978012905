<template>
    <div class="app-footer">
        <div class="footer"
             v-show="shouldShow">
            <div class="row text-xs">
                <div class="col-4 d-none d-md-block"
                     v-if="!loading_whitelabel && !whitelabel">
                    <div class="m-y">
                        <span>&copy; <strong>Aloware, Inc</strong> 2016-{{ new Date().getFullYear() }}.</span>
                    </div>
                </div>

                <div class="col-3 d-none d-md-block"
                     v-if="!loading_whitelabel && !whitelabel">
                    <div class="m-y">
                        <div class="nav text-xs d-flex justify-content-center">
                            <a class="nav-link m-r text-new-blue _500"
                               target="_blank"
                               href="https://aloware.com/terms-and-conditions">
                                Terms
                            </a>
                            <a class="nav-link m-r text-new-blue _500"
                               target="_blank"
                               href="https://aloware.com/privacy-policy">
                                Privacy
                            </a>
                            <a class="nav-link m-r text-danger _500"
                               target="_blank"
                               href="https://angel.co/company/aloware/jobs">
                                We are Hiring!
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-5 d-none d-md-block">
                    <div class="m-y pull-right"
                         v-if="!loading_whitelabel && !whitelabel">
                        <span>Built with <i class="fa fa-heart text-danger"></i> in City of Angels.</span>
                    </div>
                    <div class="m-y pull-right"
                         v-if="host == 'app.simpsocial.com'">
                        <span>© {{ new Date().getFullYear() }} All rights reserved. SimpSocial®, Text Gurus®, Warrior Crm® are a registered trademark of SimpSocial®, LLC</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {mapState} from 'vuex'
import { acl_mixin } from '../mixins'

export default {
    mixins: [acl_mixin],

    data() {
        return {
            auth: auth,
            branch: null,
            env: null,
            host: null,
            whitelabel: false,
            loading_whitelabel: true,
            current_route_name: this.$route.name,
            is_impersonated: localStorage.getItem('impersonate'),
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        shouldShow() {
            if (this.current_route_name) {
                if (this.current_route_name == 'Line Settings') {
                    return false
                }
                if (this.current_route_name == 'User Dialog') {
                    return false
                }
                if (this.current_route_name == 'Ring Group Dialog') {
                    return false
                }
                if (this.current_route_name == 'Calendar') {
                    return false
                }
                if (this.current_route_name == 'Sequence Manager') {
                    return false
                }
                if (this.current_route_name == 'Sequences 2') {
                    return false
                }
            }

            return true
        },
    },

    beforeCreate() {
        axios.get('/build-info.json')
            .then(res => {
                this.branch = res.data.branch
                this.env = res.data.env
            })
            .catch(err => {
                console.log(err)
            })
    },

    created() {
        this.getWhitelabelStatus()
    },

    methods: {
        getWhitelabelStatus() {
            this.loading_whitelabel = true
            axios.get('/get-statics')
                .then(res => {
                    this.host = res.data.host
                    this.whitelabel = res.data.whitelabel
                    this.loading_whitelabel = false
                })
                .catch(err => {
                    console.log(err)
                    this.loading_whitelabel = false
                    this.$root.handleErrors(err.response)
                })
        }
    }
}
</script>
