var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.closeAllMenus,
          expression: "closeAllMenus",
        },
      ],
    },
    [
      _c("div", { staticClass: "d-flex" }, [
        _vm.shouldDisplayTranscriptionSections
          ? _c(
              "div",
              { staticClass: "mr-2" },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: {
                      "data-testid": "comm-advanced-filters-section-radio",
                    },
                    on: { change: _vm.changeSectionFromBtn },
                    model: {
                      value: _vm.section,
                      callback: function ($$v) {
                        _vm.section = $$v
                      },
                      expression: "section",
                    },
                  },
                  [
                    _c("el-radio-button", { attrs: { label: "Activities" } }),
                    _vm._v(" "),
                    _c("el-radio-button", {
                      attrs: { label: "Transcriptions" },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mr-2" },
          [
            _c("date-selector", {
              attrs: { default_date_range: _vm.default_date_range },
              on: { clearDefaultDateRange: _vm.clearDefaultDateRange },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "align-self-center mr-2" }, [
          _vm.filter.from_date
            ? _c(
                "span",
                {
                  directives: [
                    {
                      name: "visible",
                      rawName: "v-visible",
                      value: !_vm.loading,
                      expression: "!loading",
                    },
                  ],
                  staticClass: "call-log-head",
                },
                [
                  _vm._v("\n                From\n                "),
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("fixDateTimeCompanyTimeZone")(
                          _vm.filter.from_date
                        )
                      )
                    ),
                  ]),
                  _vm._v("\n                To\n                "),
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("fixDateTimeCompanyTimeZone")(_vm.filter.to_date)
                      )
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.filter.from_date
            ? _c(
                "span",
                {
                  directives: [
                    {
                      name: "visible",
                      rawName: "v-visible",
                      value: !_vm.loading,
                      expression: "!loading",
                    },
                  ],
                  staticClass: "call-log-head",
                },
                [_c("strong", [_vm._v("All Time")])]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mr-2" }, [
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.changed,
                  expression: "changed",
                },
              ],
              staticClass: "btn btn-md blackish p-x-md",
              staticStyle: { height: "40px" },
              attrs: { "data-testid": "comm-advanced-filters-reset-button" },
              on: {
                click: function ($event) {
                  return _vm.resetOurFilters(false)
                },
              },
            },
            [_vm._v("\n                Reset\n            ")]
          ),
        ]),
        _vm._v(" "),
        !_vm.only_date_filter
          ? _c(
              "div",
              { staticClass: "mr-2 no-select filters-menu" },
              [
                _vm.checkSize()
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-md white",
                        class: [_vm.changed ? "deep-orange" : ""],
                        staticStyle: { height: "40px" },
                        attrs: {
                          disabled: _vm.loading,
                          "data-testid": "comm-advanced-filters-button",
                        },
                        on: { click: _vm.toggleFiltersMenu },
                      },
                      [_vm._v("\n                Filters\n            ")]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn btn-md white",
                        class: [_vm.changed ? "deep-orange" : ""],
                        staticStyle: { height: "40px" },
                        attrs: {
                          disabled: _vm.loading,
                          "data-testid": "comm-advanced-i-filters-button",
                        },
                        on: { click: _vm.toggleFiltersMenu },
                      },
                      [_c("i", { staticClass: "fa fa-filter" })]
                    ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      "before-close": _vm.closeFiltersMenu,
                      visible: _vm.filters_menu_visible,
                      width: _vm.isLargeEnough ? "50%" : "100%",
                      "append-to-body": "",
                      title: "Filters",
                      "data-testid": "comm-advanced-filters-dialog",
                      top: "10vh",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.filters_menu_visible = $event
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "row nav-padding lean-bottom-margin bold-label right-align",
                      },
                      [
                        !_vm.only_campaign_selector
                          ? _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "filter-header text-muted" },
                                  [_vm._v("Handling")]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "dropdown-divider" }),
                                _vm._v(" "),
                                _c(
                                  "el-form",
                                  {
                                    attrs: {
                                      "label-position": "left",
                                      "data-testid":
                                        "comm-advanced-filters-direction-form",
                                      "label-width": "100px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "Direction" } },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "filter-select",
                                            attrs: {
                                              placeholder: "Select Direction",
                                              "data-testid":
                                                "comm-advanced-filters-direction-select",
                                            },
                                            on: { change: _vm.changeDirection },
                                            model: {
                                              value: _vm.direction,
                                              callback: function ($$v) {
                                                _vm.direction = $$v
                                              },
                                              expression: "direction",
                                            },
                                          },
                                          _vm._l(
                                            _vm.directions,
                                            function (item) {
                                              return _c(
                                                "el-option",
                                                {
                                                  key:
                                                    item.value +
                                                    "-" +
                                                    item.label,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    item.icon
                                                      ? _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "material-icons",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.icon)
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(item.label) +
                                                        "\n                                        "
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form",
                                  {
                                    attrs: {
                                      "label-position": "left",
                                      "data-testid":
                                        "comm-advanced-filters-answer-status-form",
                                      "label-width": "120px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "Answer Status" } },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "filter-select",
                                            attrs: {
                                              placeholder:
                                                "Select Answer State",
                                              "data-testid":
                                                "comm-advanced-filters-answer-status-select",
                                            },
                                            on: {
                                              change: _vm.changeAnswerStatus,
                                            },
                                            model: {
                                              value: _vm.answer_state,
                                              callback: function ($$v) {
                                                _vm.answer_state = $$v
                                              },
                                              expression: "answer_state",
                                            },
                                          },
                                          _vm._l(
                                            _vm.answer_status,
                                            function (item) {
                                              return _c(
                                                "el-option",
                                                {
                                                  key:
                                                    item.value +
                                                    "-" +
                                                    item.label,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    item.icon
                                                      ? _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "material-icons",
                                                            class:
                                                              item.icon_class,
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.icon)
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(item.label) +
                                                        "\n                                        "
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.show_custom_min_talk_time,
                                        expression:
                                          "!show_custom_min_talk_time",
                                      },
                                    ],
                                    attrs: {
                                      "label-position": "left",
                                      "label-width": "100px",
                                      "data-testid":
                                        "comm-advanced-filters-min-talk-time-form",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "Talk Time" } },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "filter-select",
                                            attrs: {
                                              placeholder: "Select Talk Time",
                                              "data-testid":
                                                "comm-advanced-filters-min-talk-time-select",
                                            },
                                            on: {
                                              change: _vm.changeMinTalkTime,
                                            },
                                            model: {
                                              value: _vm.min_talk_time,
                                              callback: function ($$v) {
                                                _vm.min_talk_time = $$v
                                              },
                                              expression: "min_talk_time",
                                            },
                                          },
                                          _vm._l(
                                            _vm.min_talk_times,
                                            function (item) {
                                              return _c(
                                                "el-option",
                                                {
                                                  key:
                                                    item.value +
                                                    "-" +
                                                    item.label,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(_vm._s(item.label)),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.show_custom_min_talk_time,
                                        expression: "show_custom_min_talk_time",
                                      },
                                    ],
                                    staticClass: "row",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-12 el-form--label-left",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "el-form-item__label",
                                            staticStyle: { width: "250px" },
                                          },
                                          [_vm._v("Talk Time (Seconds):")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "pull-right" },
                                          [
                                            _c("el-input-number", {
                                              attrs: {
                                                min: 0,
                                                size: "mini",
                                                "data-testid":
                                                  "comm-advanced-filters-custom-min-talk-time-input",
                                              },
                                              model: {
                                                value: _vm.custom_min_talk_time,
                                                callback: function ($$v) {
                                                  _vm.custom_min_talk_time = $$v
                                                },
                                                expression:
                                                  "custom_min_talk_time",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "filter-button",
                                                attrs: {
                                                  size: "mini",
                                                  "data-testid":
                                                    "comm-advanced-filters-custom-min-talk-time-ok-button",
                                                  type: "success",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.changeCustomMinTalkTime.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        Ok\n                                    "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form",
                                  {
                                    attrs: {
                                      "label-position": "left",
                                      "data-testid":
                                        "comm-advanced-filters-change-type-form",
                                      "label-width": "100px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "Type" } },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "filter-select",
                                            attrs: {
                                              placeholder: "Select Type",
                                              "data-testid":
                                                "comm-advanced-filters-change-type-select",
                                            },
                                            on: { change: _vm.changeType },
                                            model: {
                                              value: _vm.type,
                                              callback: function ($$v) {
                                                _vm.type = $$v
                                              },
                                              expression: "type",
                                            },
                                          },
                                          _vm._l(_vm.types, function (item) {
                                            return _c(
                                              "el-option",
                                              {
                                                key:
                                                  item.value + "-" + item.label,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  item.icon
                                                    ? _c(
                                                        "i",
                                                        {
                                                          staticClass:
                                                            "material-icons",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.icon)
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(item.label) +
                                                      "\n                                        "
                                                  ),
                                                ]),
                                              ]
                                            )
                                          }),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form",
                                  {
                                    attrs: {
                                      "label-position": "left",
                                      "data-testid":
                                        "comm-advanced-filters-transfer-type-form",
                                      "label-width": "100px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "Transfer Type" } },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "filter-select",
                                            attrs: {
                                              placeholder: "Select Type",
                                              "data-testid":
                                                "comm-advanced-filters-transfer-type-select",
                                              clearable: "",
                                            },
                                            on: {
                                              change: _vm.changeTransferType,
                                            },
                                            model: {
                                              value: _vm.transfer_type,
                                              callback: function ($$v) {
                                                _vm.transfer_type = $$v
                                              },
                                              expression: "transfer_type",
                                            },
                                          },
                                          _vm._l(
                                            _vm.transfer_types,
                                            function (item) {
                                              return _c(
                                                "el-option",
                                                {
                                                  key:
                                                    item.value +
                                                    "-" +
                                                    item.label,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(item.label) +
                                                        "\n                                        "
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form",
                                  {
                                    attrs: {
                                      "label-position": "left",
                                      "data-testid":
                                        "comm-advanced-filters-callback-status-form",
                                      "label-width": "100px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "Callback Status" } },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "filter-select",
                                            attrs: {
                                              placeholder: "Select Status",
                                              "data-testid":
                                                "comm-advanced-filters-callback-status-select",
                                              clearable: "",
                                            },
                                            on: {
                                              change: _vm.changeCallbackStatus,
                                            },
                                            model: {
                                              value: _vm.callback_status,
                                              callback: function ($$v) {
                                                _vm.callback_status = $$v
                                              },
                                              expression: "callback_status",
                                            },
                                          },
                                          _vm._l(
                                            _vm.callback_statuses,
                                            function (item) {
                                              return _c(
                                                "el-option",
                                                {
                                                  key:
                                                    item.value +
                                                    "-" +
                                                    item.label,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(item.label) +
                                                        "\n                                "
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form",
                                  {
                                    attrs: {
                                      "label-position": "left",
                                      "label-width": "130px",
                                      "data-testid":
                                        "comm-advanced-filters-international-form",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: { label: "International Only" },
                                      },
                                      [
                                        _c("el-switch", {
                                          attrs: {
                                            "active-color": "#00a344",
                                            "data-testid":
                                              "comm-advanced-filters-international-switch",
                                            "active-value": 1,
                                            "inactive-value": 0,
                                          },
                                          on: {
                                            change: _vm.changeInternational,
                                          },
                                          model: {
                                            value: _vm.has_international,
                                            callback: function ($$v) {
                                              _vm.has_international = $$v
                                            },
                                            expression: "has_international",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "filter-header text-muted" },
                                  [_vm._v("Properties")]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "dropdown-divider" }),
                                _vm._v(" "),
                                _c(
                                  "el-form",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.show_tags,
                                        expression: "show_tags",
                                      },
                                    ],
                                    attrs: {
                                      "label-position": "left",
                                      "label-width": "150px",
                                      "data-testid":
                                        "comm-advanced-filters-tags-form",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "Tag" } },
                                      [
                                        _c("tag-selector", {
                                          staticClass: "filter-select",
                                          attrs: {
                                            multiple: true,
                                            value: _vm.filter_tags,
                                            category_prop:
                                              _vm.TagCategory
                                                .CAT_COMMUNICATIONS,
                                            loaded: "",
                                            "data-testid":
                                              "comm-advanced-filters-tags-select",
                                          },
                                          on: { change: _vm.changeTags },
                                          model: {
                                            value: _vm.filter_tags,
                                            callback: function ($$v) {
                                              _vm.filter_tags = $$v
                                            },
                                            expression: "filter_tags",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form",
                                  {
                                    attrs: {
                                      "label-position": "left",
                                      "data-testid":
                                        "comm-advanced-filters-call-disposition-form",
                                      "label-width": "150px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "Call Disposition" } },
                                      [
                                        _c("call-disposition-selector", {
                                          staticClass: "filter-select",
                                          attrs: {
                                            multiple: true,
                                            value: _vm.filter_call_dispositions,
                                            placeholder: "Call Dispositions",
                                          },
                                          on: {
                                            change: _vm.changeCallDispositions,
                                          },
                                          model: {
                                            value: _vm.filter_call_dispositions,
                                            callback: function ($$v) {
                                              _vm.filter_call_dispositions = $$v
                                            },
                                            expression:
                                              "filter_call_dispositions",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.show_tags,
                                        expression: "show_tags",
                                      },
                                    ],
                                    attrs: {
                                      "label-position": "left",
                                      "label-width": "200px",
                                      "data-testid":
                                        "comm-advanced-filters-not-disposed-form",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "Not Disposed" } },
                                      [
                                        _c("el-switch", {
                                          attrs: {
                                            disabled: !["all", "call"].includes(
                                              _vm.type
                                            ),
                                            "active-color": "#00a344",
                                            "active-value": 1,
                                            "inactive-value": 0,
                                            "data-testid":
                                              "comm-advanced-filters-not-disposed-switch",
                                          },
                                          on: { change: _vm.changeNotDisposed },
                                          model: {
                                            value: _vm.not_disposed,
                                            callback: function ($$v) {
                                              _vm.not_disposed = $$v
                                            },
                                            expression: "not_disposed",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.show_tags,
                                        expression: "show_tags",
                                      },
                                    ],
                                    attrs: {
                                      "label-position": "left",
                                      "data-testid":
                                        "comm-advanced-filters-unread-only-form",
                                      "label-width": "200px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "Unread Only" } },
                                      [
                                        _c("el-switch", {
                                          attrs: {
                                            "active-color": "#00a344",
                                            "active-value": 1,
                                            disabled: !["all"].includes(
                                              _vm.type
                                            ),
                                            "inactive-value": 0,
                                            "data-testid":
                                              "comm-advanced-filters-unread-only-switch",
                                          },
                                          on: { change: _vm.changeUnreadOnly },
                                          model: {
                                            value: _vm.unread_only,
                                            callback: function ($$v) {
                                              _vm.unread_only = $$v
                                            },
                                            expression: "unread_only",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form",
                                  {
                                    attrs: {
                                      "label-position": "left",
                                      "data-testid":
                                        "comm-advanced-filters-first-time-only-form",
                                      "label-width": "200px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "First-time Conversations",
                                        },
                                      },
                                      [
                                        _c("el-switch", {
                                          attrs: {
                                            "active-value": 1,
                                            "inactive-value": 0,
                                            "data-testid":
                                              "comm-advanced-filters-first-time-only-switch",
                                            "active-color": "#00a344",
                                          },
                                          on: {
                                            change: _vm.changeFirstTimeCaller,
                                          },
                                          model: {
                                            value: _vm.first_time_only,
                                            callback: function ($$v) {
                                              _vm.first_time_only = $$v
                                            },
                                            expression: "first_time_only",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form",
                                  {
                                    attrs: {
                                      "label-position": "left",
                                      "data-testid":
                                        "comm-advanced-filters-untagged-form",
                                      "label-width": "200px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "Untagged Conversations",
                                        },
                                      },
                                      [
                                        _c("el-switch", {
                                          attrs: {
                                            "active-value": 1,
                                            "inactive-value": 0,
                                            "data-testid":
                                              "comm-advanced-filters-untagged-switch",
                                            "active-color": "#00a344",
                                          },
                                          on: { change: _vm.changeUntagged },
                                          model: {
                                            value: _vm.untagged_only,
                                            callback: function ($$v) {
                                              _vm.untagged_only = $$v
                                            },
                                            expression: "untagged_only",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form",
                                  {
                                    attrs: {
                                      "label-position": "left",
                                      "data-testid":
                                        "comm-advanced-filters-exclude-automated-communications-form",
                                      "label-width": "228px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label:
                                            "Exclude automated communications",
                                        },
                                      },
                                      [
                                        _c("el-switch", {
                                          attrs: {
                                            "active-value": 1,
                                            "inactive-value": 0,
                                            "data-testid":
                                              "comm-advanced-filters-exclude-automated-communications-switch",
                                            "active-color": "#00a344",
                                          },
                                          on: {
                                            change:
                                              _vm.changedExcludeAutomatedCommunications,
                                          },
                                          model: {
                                            value:
                                              _vm.exclude_automated_communications,
                                            callback: function ($$v) {
                                              _vm.exclude_automated_communications =
                                                $$v
                                            },
                                            expression:
                                              "exclude_automated_communications",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form",
                                  {
                                    attrs: {
                                      "label-position": "left",
                                      "data-testid":
                                        "comm-advanced-filters-creator-type-form",
                                      "label-width": "219px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "Creator Type" } },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "filter-select",
                                            attrs: {
                                              placeholder:
                                                "Select Creator Type",
                                              "data-testid":
                                                "comm-advanced-filters-creator-type-select",
                                              clearable: "",
                                            },
                                            on: {
                                              change: _vm.changeCreatorType,
                                            },
                                            model: {
                                              value: _vm.creator_type,
                                              callback: function ($$v) {
                                                _vm.creator_type = $$v
                                              },
                                              expression: "creator_type",
                                            },
                                          },
                                          _vm._l(
                                            _vm.creator_types,
                                            function (item) {
                                              return _c(
                                                "el-option",
                                                {
                                                  key:
                                                    item.value +
                                                    "-" +
                                                    item.label,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(item.label) +
                                                        "\n                                        "
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                !_vm.campaign_id &&
                                _vm.hasPermissionTo("list campaign")
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "filter-header text-muted",
                                          },
                                          [_vm._v("Attribution")]
                                        ),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "dropdown-divider",
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "el-form",
                                          {
                                            attrs: {
                                              "label-position": "left",
                                              "data-testid":
                                                "comm-advanced-filters-campaign-form",
                                              "label-width": "150px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "Lines" } },
                                              [
                                                _c("campaign-selector", {
                                                  staticClass: "filter-select",
                                                  attrs: {
                                                    multiple: true,
                                                    value: _vm.filter_campaigns,
                                                  },
                                                  on: {
                                                    change: _vm.changeCampaigns,
                                                  },
                                                  model: {
                                                    value: _vm.filter_campaigns,
                                                    callback: function ($$v) {
                                                      _vm.filter_campaigns = $$v
                                                    },
                                                    expression:
                                                      "filter_campaigns",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.user_id
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-2" },
                                      [
                                        _vm.campaign_id
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "filter-header text-muted",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                Attribution\n                            "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.campaign_id
                                          ? _c("div", {
                                              staticClass: "dropdown-divider",
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.hasPermissionTo("list user")
                                          ? _c(
                                              "el-form",
                                              {
                                                attrs: {
                                                  "label-position": "left",
                                                  "label-width": "150px",
                                                  "data-testid":
                                                    "comm-advanced-filters-users-form",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  { attrs: { label: "Users" } },
                                                  [
                                                    _c("user-selector", {
                                                      staticClass:
                                                        "filter-select",
                                                      attrs: {
                                                        multiple: true,
                                                        value: _vm.filter_users,
                                                        "collapse-tags": true,
                                                        "data-testid":
                                                          "comm-advanced-filters-users-select",
                                                      },
                                                      on: {
                                                        change: _vm.changeUsers,
                                                      },
                                                      model: {
                                                        value: _vm.filter_users,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.filter_users = $$v
                                                        },
                                                        expression:
                                                          "filter_users",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.team_id && _vm.hasPermissionTo("list team")
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(
                                          "el-form",
                                          {
                                            attrs: {
                                              "label-position": "left",
                                              "label-width": "150px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Teams",
                                                  "data-testid":
                                                    "comm-advanced-filter-teams-item",
                                                },
                                              },
                                              [
                                                _c("team-selector", {
                                                  staticClass: "filter-select",
                                                  attrs: {
                                                    multiple: true,
                                                    value: _vm.filter_teams,
                                                  },
                                                  on: {
                                                    change: _vm.changeTeams,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.ring_group_id &&
                                _vm.hasPermissionTo("list ring group")
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(
                                          "el-form",
                                          {
                                            attrs: {
                                              "label-position": "left",
                                              "label-width": "150px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Ring Groups",
                                                  "data-testid":
                                                    "comm-advanced-filter-ring-groups-item",
                                                },
                                              },
                                              [
                                                _c("ring-group-selector", {
                                                  staticClass: "filter-select",
                                                  attrs: {
                                                    multiple: true,
                                                    value:
                                                      _vm.filter_ring_groups,
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.changeRingGroups,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.filter_ring_groups,
                                                    callback: function ($$v) {
                                                      _vm.filter_ring_groups =
                                                        $$v
                                                    },
                                                    expression:
                                                      "filter_ring_groups",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "el-form",
                                      {
                                        attrs: {
                                          "label-position": "left",
                                          "data-testid":
                                            "comm-advanced-filters-incoming-numbers-form",
                                          "label-width": "150px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "Numbers",
                                              "data-testid":
                                                "comm-advanced-filter-incoming-numbers-item",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticClass: "filter-select",
                                                attrs: {
                                                  "collapse-tags": "",
                                                  filterable: "",
                                                  multiple: "",
                                                  "data-testid":
                                                    "comm-advanced-filters-incoming-numbers-select",
                                                  placeholder: "Select Numbers",
                                                },
                                                on: {
                                                  change:
                                                    _vm.changeIncomingNumbers,
                                                },
                                                model: {
                                                  value:
                                                    _vm.filter_incoming_numbers,
                                                  callback: function ($$v) {
                                                    _vm.filter_incoming_numbers =
                                                      $$v
                                                  },
                                                  expression:
                                                    "filter_incoming_numbers",
                                                },
                                              },
                                              _vm._l(
                                                _vm.incomingNumbers,
                                                function (item, index) {
                                                  return _c(
                                                    "el-option",
                                                    {
                                                      key:
                                                        index +
                                                        "-" +
                                                        item.phone_number,
                                                      attrs: {
                                                        label: _vm.getFixPhone(
                                                          item.phone_number
                                                        ),
                                                        value: item.id,
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getFixPhone(
                                                              item.phone_number
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                !_vm.workflow_id &&
                                _vm.hasPermissionTo("list workflow")
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(
                                          "el-form",
                                          {
                                            attrs: {
                                              "label-position": "left",
                                              "data-testid":
                                                "comm-advanced-filters-sequences-form",
                                              "label-width": "150px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Sequences",
                                                  "data-testid":
                                                    "comm-advanced-filter-sequences-item",
                                                },
                                              },
                                              [
                                                _c("workflow-selector", {
                                                  staticClass: "filter-select",
                                                  attrs: {
                                                    multiple: true,
                                                    value: _vm.filter_workflows,
                                                  },
                                                  on: {
                                                    change: _vm.changeWorkflows,
                                                  },
                                                  model: {
                                                    value: _vm.filter_workflows,
                                                    callback: function ($$v) {
                                                      _vm.filter_workflows = $$v
                                                    },
                                                    expression:
                                                      "filter_workflows",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.broadcast_id &&
                                _vm.hasPermissionTo("list broadcast")
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(
                                          "el-form",
                                          {
                                            attrs: {
                                              "label-position": "left",
                                              "data-testid":
                                                "comm-advanced-filters-broadcasts-form",
                                              "label-width": "150px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Broadcasts",
                                                  "data-testid":
                                                    "comm-advanced-filter-broadcast-item",
                                                },
                                              },
                                              [
                                                _c("broadcast-selector", {
                                                  staticClass: "filter-select",
                                                  attrs: {
                                                    multiple: true,
                                                    value:
                                                      _vm.filter_broadcasts,
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.changeBroadcasts,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.filter_broadcasts,
                                                    callback: function ($$v) {
                                                      _vm.filter_broadcasts =
                                                        $$v
                                                    },
                                                    expression:
                                                      "filter_broadcasts",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.shouldShowTranscriptionFilters
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "filter-header text-muted",
                                          },
                                          [_vm._v("Transcription Analysis")]
                                        ),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "dropdown-divider",
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "el-form",
                                          {
                                            attrs: {
                                              "label-position": "left",
                                              "data-testid":
                                                "comm-advanced-filters-agent-sentiment-form",
                                              "label-width": "150px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Agent Sentiment",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticClass:
                                                      "filter-select",
                                                    attrs: {
                                                      placeholder:
                                                        "Select Agent Sentiment",
                                                      "data-testid":
                                                        "comm-advanced-filters-agent-sentiment-select",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.changeAgentSentiment,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.agent_sentiment,
                                                      callback: function ($$v) {
                                                        _vm.agent_sentiment =
                                                          $$v
                                                      },
                                                      expression:
                                                        "agent_sentiment",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.sentiments,
                                                    function (item) {
                                                      return _c(
                                                        "el-option",
                                                        {
                                                          key: item.value,
                                                          attrs: {
                                                            label: item.label,
                                                            value: item.value,
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  item.label
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form",
                                          {
                                            attrs: {
                                              "label-position": "left",
                                              "data-testid":
                                                "comm-advanced-filters-contact-sentiment-form",
                                              "label-width": "150px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Contact Sentiment",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticClass:
                                                      "filter-select",
                                                    attrs: {
                                                      placeholder:
                                                        "Select Contact Sentiment",
                                                      "data-testid":
                                                        "comm-advanced-filters-contact-sentiment-select",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.changeContactSentiment,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contact_sentiment,
                                                      callback: function ($$v) {
                                                        _vm.contact_sentiment =
                                                          $$v
                                                      },
                                                      expression:
                                                        "contact_sentiment",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.sentiments,
                                                    function (item) {
                                                      return _c(
                                                        "el-option",
                                                        {
                                                          key: item.value,
                                                          attrs: {
                                                            label: item.label,
                                                            value: item.value,
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  item.label
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form",
                                          {
                                            attrs: {
                                              "label-position": "left",
                                              "data-testid":
                                                "comm-advanced-filters-keywords-form",
                                              "label-width": "150px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Custom Keywords",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticClass:
                                                      "filter-select",
                                                    attrs: {
                                                      multiple: "",
                                                      filterable: "",
                                                      clearable: "",
                                                      "collapse-tags": "",
                                                      placeholder:
                                                        "Select keywords",
                                                      "data-testid":
                                                        "comm-advanced-filters-keywords-select",
                                                      "value-key": "value",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.changeTranscriptionKeywords,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.customTranscriptionKeywords,
                                                      callback: function ($$v) {
                                                        _vm.customTranscriptionKeywords =
                                                          $$v
                                                      },
                                                      expression:
                                                        "customTranscriptionKeywords",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.customKeywordOptions,
                                                    function (keyword) {
                                                      return _c("el-option", {
                                                        key: keyword.value,
                                                        attrs: {
                                                          label: keyword.value,
                                                          value: keyword,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.only_campaign_selector
                          ? _c("div", { staticClass: "col-12" }, [
                              !_vm.campaign_id
                                ? _c(
                                    "div",
                                    { staticClass: "mt-2" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "filter-header text-muted",
                                        },
                                        [_vm._v("Attribution")]
                                      ),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "dropdown-divider",
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-form",
                                        {
                                          attrs: {
                                            "label-position": "left",
                                            "data-testid":
                                              "comm-advanced-filters-campaign-attribution-form",
                                            "label-width": "150px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "Lines",
                                                "data-testid":
                                                  "comm-advanced-filter-attribution-item",
                                              },
                                            },
                                            [
                                              _c("campaign-selector", {
                                                staticClass: "filter-select",
                                                attrs: {
                                                  multiple: true,
                                                  value: _vm.filter_campaigns,
                                                },
                                                on: {
                                                  change: _vm.changeCampaigns,
                                                },
                                                model: {
                                                  value: _vm.filter_campaigns,
                                                  callback: function ($$v) {
                                                    _vm.filter_campaigns = $$v
                                                  },
                                                  expression:
                                                    "filter_campaigns",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasPermissionTo("list contact list item")
                          ? _c(
                              "div",
                              { staticClass: "col-12 mt-2 mb-4" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "filter-header text-muted" },
                                  [
                                    _vm._v(
                                      "\n                            Contact\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "dropdown-divider" }),
                                _vm._v(" "),
                                _c(
                                  "el-form",
                                  {
                                    attrs: {
                                      "label-position": "left",
                                      "label-width": "150px",
                                      "data-testid":
                                        "comm-advanced-filters-contact-list-form",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "Contact Lists" } },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "filter-select",
                                            attrs: {
                                              placeholder:
                                                "Select Contact List",
                                              "data-testid":
                                                "comm-advanced-filters-contact-lists-select",
                                              multiple: "",
                                              filterable: "",
                                              "collapse-tags": "",
                                              clearable: "",
                                            },
                                            on: {
                                              change: _vm.changeContactLists,
                                            },
                                            model: {
                                              value: _vm.filter_contact_lists,
                                              callback: function ($$v) {
                                                _vm.filter_contact_lists = $$v
                                              },
                                              expression:
                                                "filter_contact_lists",
                                            },
                                          },
                                          _vm._l(
                                            _vm.contact_lists,
                                            function (item) {
                                              return _c(
                                                "el-option",
                                                {
                                                  key:
                                                    item.id + "-" + item.name,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.id,
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(item.name) +
                                                        "\n                                        "
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 mt-3" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn greenish p-x-md pull-right",
                              attrs: {
                                "data-testid":
                                  "comm-advanced-filters-apply-button",
                              },
                              on: { click: _vm.applyFilters },
                            },
                            [
                              _vm._v(
                                "\n                            Apply\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn deep-orange p-x-md pull-right mr-2",
                              attrs: {
                                "data-testid":
                                  "comm-advanced-filters-reset-button",
                              },
                              on: { click: _vm.resetOurFilters },
                            },
                            [
                              _vm._v(
                                "\n                            Reset Filters\n                        "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.hasPermissionTo("list filter") && !_vm.only_date_filter
          ? _c("div", { staticClass: "mr-2 dropdown-toggle-caret-none" }, [
              _vm.checkSize()
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm white dropdown-toggle",
                      attrs: {
                        disabled: _vm.loading,
                        "data-testid":
                          "comm-advanced-filters-saved-filters-button",
                      },
                      on: { click: _vm.toggleSavedFiltersMenu },
                    },
                    [
                      _vm._v("\n                Saved Filters "),
                      _c("i", { staticClass: "el-icon-arrow-down" }),
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn white dropdown-toggle",
                      attrs: {
                        disabled: _vm.loading,
                        "data-testid":
                          "comm-advanced-filters-saved-filters-i-button",
                      },
                      on: { click: _vm.toggleSavedFiltersMenu },
                    },
                    [_c("i", { staticClass: "fa fa-save" })]
                  ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "saved-filters-menu dropdown-menu mega-dropdown-menu dropdown-menu-overlay lean-bottom-margin bold-label right-align no-select",
                  class: _vm.saved_filters_menu_visible ? "show" : "",
                  staticStyle: { left: "auto" },
                  attrs: {
                    "data-testid":
                      "comm-advanced-filters-saved-filters-dropdown-menu",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.noClose($event)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "row nav-padding" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("span", { staticClass: "filter-header text-muted" }, [
                        _vm._v(
                          "\n                            My Filters\n                            "
                        ),
                        _vm.hasPermissionTo("create filter")
                          ? _c(
                              "a",
                              {
                                staticClass: "pull-right text-dark-greenish",
                                attrs: {
                                  "data-testid":
                                    "comm-advanced-filters-save-filter-button",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.saveFilter("user")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                Save Filter\n                            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "dropdown-divider" }),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "list inset" },
                        _vm._l(_vm.user_filters, function (filter) {
                          return _c(
                            "li",
                            {
                              key: filter.id + "-user-filters",
                              staticClass: "filters pb-2 pt-2",
                            },
                            [
                              _vm.edit.id != filter.id
                                ? _c("div", { staticClass: "pull-right" }, [
                                    _vm.canChangeDefaultFilter &&
                                    (!_vm.defaultFilter ||
                                      _vm.defaultFilter.id != filter.id)
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "btn btn-xs",
                                            attrs: {
                                              "data-testid":
                                                "comm-advanced-filters-set-as-default-filter-button",
                                              href: "#",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.setAsDefaultFilter(
                                                  filter
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm.savingDefaultFilter == filter
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-spinner fa-spin",
                                                })
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                                        Set as Default\n                                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.canChangeDefaultFilter &&
                                    _vm.defaultFilter &&
                                    _vm.defaultFilter.id === filter.id
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "btn btn-xs",
                                            attrs: {
                                              "data-testid":
                                                "comm-advanced-filters-unset-default-filter-button",
                                              href: "#",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.unsetDefaultFilter()
                                              },
                                            },
                                          },
                                          [
                                            _vm.unsettingDefaultFilter &&
                                            _vm.defaultFilter == filter
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-spinner fa-spin",
                                                })
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                                        Unset Default\n                                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-xs btn-icon",
                                        attrs: {
                                          "data-testid":
                                            "comm-advanced-filters-done-button",
                                          href: "#",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.loadFilter(
                                              $event,
                                              filter
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "i",
                                          { staticClass: "material-icons" },
                                          [_vm._v("done")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.hasPermissionTo("update filter")
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "btn btn-xs btn-icon",
                                            attrs: {
                                              "data-testid":
                                                "comm-advanced-filters-edit-button",
                                              href: "#",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.toggleEdit(
                                                  filter.id,
                                                  filter.name
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              { staticClass: "material-icons" },
                                              [_vm._v("edit")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.hasPermissionTo("archive filter")
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "btn btn-xs btn-icon",
                                            attrs: {
                                              "data-testid":
                                                "comm-advanced-filters-delete-button",
                                              href: "#",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.deleteCustomFilter(
                                                  $event,
                                                  filter
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              { staticClass: "material-icons" },
                                              [_vm._v("delete")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _c("div", { staticClass: "pull-right" }, [
                                    _vm.hasPermissionTo("update filter")
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "btn btn-xs",
                                            attrs: {
                                              "data-testid":
                                                "comm-advanced-filters-cancel-button",
                                              href: "#",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.cancelEditFilter(
                                                  filter.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Cancel\n                                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.hasPermissionTo("update filter")
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "btn btn-xs greenish",
                                            attrs: {
                                              "data-testid":
                                                "comm-advanced-filters-save-button",
                                              href: "#",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.updateFields(
                                                  filter.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.loading_btn,
                                                    expression: "loading_btn",
                                                  },
                                                ],
                                                staticClass:
                                                  "material-icons loader",
                                              },
                                              [_vm._v("")]
                                            ),
                                            _vm._v(" "),
                                            _c("i", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.loading_btn,
                                                  expression: "!loading_btn",
                                                },
                                              ],
                                              staticClass: "fa fa-floppy-o",
                                            }),
                                            _vm._v(
                                              "\n                                        Save\n                                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm.edit.id != filter.id
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-badge",
                                          {
                                            staticClass:
                                              "default-filter-indicator mt-1",
                                            attrs: {
                                              hidden:
                                                !_vm.defaultFilter ||
                                                _vm.defaultFilter.id !=
                                                  filter.id,
                                              "data-testid":
                                                "comm-advanced-filters-name-badget",
                                              value: "Default",
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "text-blackish",
                                                attrs: {
                                                  title: filter.name,
                                                  "data-testid":
                                                    "comm-advanced-filters-name-link",
                                                  href: "#",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.loadFilter(
                                                      $event,
                                                      filter
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                " +
                                                    _vm._s(filter.name) +
                                                    "\n                                            "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("input", {
                                      directives: [
                                        { name: "focus", rawName: "v-focus" },
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: filter.name,
                                          expression: "filter.name",
                                        },
                                      ],
                                      ref: "name_" + filter.id,
                                      refInFor: true,
                                      staticClass:
                                        "form-control inline filter-input",
                                      attrs: {
                                        placeholder: "Label",
                                        type: "text",
                                        "data-testid":
                                          "comm-advanced-filter-name-input",
                                      },
                                      domProps: { value: filter.name },
                                      on: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.updateFields(filter.id)
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            filter,
                                            "name",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 mt-3" }, [
                      _c("span", { staticClass: "filter-header text-muted" }, [
                        _vm._v(
                          "\n                            Company Filters\n                            "
                        ),
                        _vm.hasPermissionTo("create filter") &&
                        _vm.hasRole("Company Admin")
                          ? _c(
                              "a",
                              {
                                staticClass: "pull-right text-dark-greenish",
                                attrs: {
                                  "data-testid":
                                    "comm-advanced-filters-save-company-filter-button",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.saveFilter("company")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                Save Filter\n                            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "dropdown-divider" }),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "list inset" },
                        _vm._l(_vm.company_filters, function (filter) {
                          return _c(
                            "li",
                            {
                              key: filter.id + "-company-filters",
                              staticClass: "filters pb-2 pt-2",
                            },
                            [
                              _vm.edit.id != filter.id
                                ? _c("div", { staticClass: "pull-right" }, [
                                    _vm.canChangeDefaultFilter &&
                                    (!_vm.defaultFilter ||
                                      _vm.defaultFilter.id != filter.id)
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "btn btn-xs",
                                            attrs: {
                                              "data-testid":
                                                "comm-advanced-filters-set-as-default-link-item",
                                              href: "#",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                _vm.setAsDefaultFilter(
                                                  filter
                                                ) &&
                                                  _vm.hasRole("Company Admin")
                                              },
                                            },
                                          },
                                          [
                                            _vm.savingDefaultFilter == filter
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-spinner fa-spin",
                                                })
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                                        Set as Default\n                                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.canChangeDefaultFilter &&
                                    _vm.defaultFilter &&
                                    _vm.defaultFilter.id === filter.id &&
                                    _vm.hasRole("Company Admin")
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "btn btn-xs",
                                            attrs: {
                                              "data-testid":
                                                "comm-advanced-filters-unset-default-link-item",
                                              href: "#",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.unsetDefaultFilter()
                                              },
                                            },
                                          },
                                          [
                                            _vm.unsettingDefaultFilter &&
                                            _vm.defaultFilter == filter
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-spinner fa-spin",
                                                })
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                                        Unset Default\n                                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-xs btn-icon",
                                        attrs: {
                                          "data-testid":
                                            "comm-advanced-filters-done-link-item",
                                          href: "#",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.loadFilter(
                                              $event,
                                              filter
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "i",
                                          { staticClass: "material-icons" },
                                          [_vm._v("done")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.hasPermissionTo("update filter") &&
                                    _vm.hasRole("Company Admin")
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "btn btn-xs btn-icon",
                                            attrs: {
                                              "data-testid":
                                                "comm-advanced-filters-edit-link-item",
                                              href: "#",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.toggleEdit(
                                                  filter.id,
                                                  filter.name
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              { staticClass: "material-icons" },
                                              [_vm._v("edit")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.hasPermissionTo("archive filter") &&
                                    _vm.hasRole("Company Admin")
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "btn btn-xs btn-icon",
                                            attrs: {
                                              "data-testid":
                                                "comm-advanced-filters-delete-link-item",
                                              href: "#",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.deleteCustomFilter(
                                                  $event,
                                                  filter
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              { staticClass: "material-icons" },
                                              [_vm._v("delete")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _c("div", { staticClass: "pull-right" }, [
                                    _vm.hasPermissionTo("update filter")
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "btn btn-xs",
                                            attrs: {
                                              "data-testid":
                                                "comm-advanced-filters-cancel-link-item",
                                              href: "#",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.cancelEditFilter(
                                                  filter.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Cancel\n                                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.hasPermissionTo("update filter")
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "btn btn-xs greenish",
                                            attrs: {
                                              "data-testid":
                                                "comm-advanced-filters-save-link-item",
                                              href: "#",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.updateFields(
                                                  filter.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.loading_btn,
                                                    expression: "loading_btn",
                                                  },
                                                ],
                                                staticClass:
                                                  "material-icons loader",
                                              },
                                              [_vm._v("")]
                                            ),
                                            _vm._v(" "),
                                            _c("i", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.loading_btn,
                                                  expression: "!loading_btn",
                                                },
                                              ],
                                              staticClass: "fa fa-floppy-o",
                                            }),
                                            _vm._v(
                                              "\n                                        Save\n                                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm.edit.id != filter.id
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-badge",
                                          {
                                            staticClass:
                                              "default-filter-indicator mt-1",
                                            attrs: {
                                              hidden:
                                                !_vm.defaultFilter ||
                                                _vm.defaultFilter.id !=
                                                  filter.id,
                                              "data-testid":
                                                "comm-advanced-filters-company-filter-name-badget",
                                              value: "Default",
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "text-blackish",
                                                attrs: {
                                                  title: filter.name,
                                                  "data-testid":
                                                    "comm-advanced-filters-company-filter-name-link",
                                                  href: "#",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.loadFilter(
                                                      $event,
                                                      filter
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                " +
                                                    _vm._s(filter.name) +
                                                    "\n                                            "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("input", {
                                      directives: [
                                        { name: "focus", rawName: "v-focus" },
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: filter.name,
                                          expression: "filter.name",
                                        },
                                      ],
                                      ref: "name_" + filter.id,
                                      refInFor: true,
                                      staticClass:
                                        "form-control inline filter-input",
                                      attrs: {
                                        "data-testid":
                                          "comm-advanced-filters-company-filter-name-input",
                                        placeholder: "Label",
                                        type: "text",
                                      },
                                      domProps: { value: filter.name },
                                      on: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.updateFields(filter.id)
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            filter,
                                            "name",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.no_exports && _vm.hasRole("Company Admin") && _vm.only_date_filter
          ? _c("div", { staticClass: "dropdown dropdown-toggle-caret-none" }, [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-outline b-dark-greenish text-dark-greenish",
                  attrs: {
                    disabled: _vm.loading,
                    "data-testid": "comm-advanced-filters-export-to-csv-button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.exportTo("csv")
                    },
                  },
                },
                [
                  _vm._v("\n                Export "),
                  _c("i", { staticClass: "el-icon-arrow-down" }),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.no_exports && _vm.hasRole("Company Admin") && !_vm.only_date_filter
          ? _c(
              "div",
              { staticClass: "dropdown dropdown-toggle-caret-none ml-auto" },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-outline b-dark-greenish text-dark-greenish dropdown-toggle",
                    attrs: {
                      disabled: _vm.loading,
                      "data-testid":
                        "comm-advanced-filters-toggle-export-menu-button",
                    },
                    on: { click: _vm.toggleExportsMenu },
                  },
                  [
                    _vm._v("\n                Export "),
                    _c("i", { staticClass: "el-icon-arrow-down" }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dropdown-menu dropdown-menu-right",
                    class: _vm.exports_menu_visible ? "show" : "",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: {
                          "data-testid":
                            "comm-advanced-filters-cvs-current-view-link",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.exportTo("csv")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-table" }),
                        _vm._v("\n                    CSV Current View"),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: {
                          "data-testid":
                            "comm-advanced-filters-cvs-all-columns-link",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.exportTo("csv-all")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-table" }),
                        _vm._v(
                          "\n                    CSV All Columns\n                "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      !_vm.only_date_filter
        ? _c(
            "el-dialog",
            {
              staticClass: "dialog-padding",
              attrs: {
                "before-close": _vm.handleClose,
                visible: _vm.dialogVisible,
                "append-to-body": "",
                title: "Create a new Filter",
                "data-testid": "comm-advanced-filters-create-filter-dialog",
                width: "30%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "create_filter",
                  attrs: {
                    model: _vm.custom_filter,
                    rules: _vm.rules,
                    "data-testid": "comm-advanced-filters-create-filter-form",
                    "label-position": "top",
                    "label-width": "100px",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.createFilter.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Save your current filter",
                        "data-testid":
                          "comm-advanced-filters-create-filter-item",
                        prop: "name",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          "data-testid":
                            "comm-advanced-filters-create-filter-input",
                          placeholder: "Name your filter",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.preValidateForm("create_filter")
                          },
                        },
                        model: {
                          value: _vm.custom_filter.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.custom_filter, "name", $$v)
                          },
                          expression: "custom_filter.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        "data-testid":
                          "comm-advanced-filters-create-filter-cancel-button",
                      },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        loading: _vm.loading_btn,
                        "data-testid":
                          "comm-advanced-filters-save-filter-cancel-button",
                        type: "success",
                      },
                      on: { click: _vm.createFilter },
                    },
                    [_vm._v("\n                Save\n            ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }