<template>
    <div data-testid="line-registration-wrapper">
        <div class="container-campaign-summary mt-5 mb-2">
            <h4 v-if="!requiresRegistration && !registered"
                class="font-weight-bold text-dark"
                data-testid="not-registration-title">
                <i class="fa fa-check text-greenish"></i>
                This Line does not require registration, yet.
            </h4>
            <template v-else-if="requiresRegistration && !registered">
                <h4 v-if="hasUseCase"
                    class="font-weight-bold text-dark"
                    data-testid="register-line-title">
                    <i class="fa fa-cancel text-greenish"></i>
                    Register this Line to enable the ability to Send SMS.
                </h4>
                <h5 v-else-if="!hasUseCase && !current_company.is_trial"
                    class="font-weight-bold text-dark break-word"
                    data-testid="proceed-title">
                    <i class="fa fa-cancel text-greenish"></i>
                    It looks like you don't have any existing messaging services to assign to. To proceed, please
                    <router-link class="text-blue"
                                 :to="{name: 'Account', query: {tab: 'compliance'}}">create a new messaging service
                    </router-link>
                    .
                </h5>
                <h5 v-else-if="!hasUseCase && current_company.is_trial"
                    class="font-weight-bold text-dark break-word"
                    data-testid="sorry-title">
                    <i class="fa fa-cancel text-greenish"></i>
                    Sorry, trial accounts are not eligible to register within the new line wizard. To unlock this feature, please upgrade your account.
                </h5>
            </template>
            <h4 v-else-if="registered"
                class="font-weight-bold text-dark"
                data-testid="almost-ready-title">
                <i class="fa fa-cancel text-greenish"></i>
                Your line is almost ready to be used!
            </h4>

            <el-table v-if="requiresRegistration && hasUseCase"
                      :data="filteredMessagingServices"
                      v-loading="available_messaging_services_loading"
                      data-testid="messaging-services-table"
                      :max-height="300">
                <el-table-column min-width="100"
                                 data-testid="first-column">
                    <template v-slot:header>
                        <el-input
                            v-model="messaging_service_search"
                            clearable
                            size="mini"
                            style="width: 100%"
                            placeholder="Type to search a Use Case"/>
                    </template>
                    <template v-slot="scope">
                        #{{ scope.row.id }} - {{ getUseCaseTitle(scope.row?.a2p_use_case?.use_case) }}
                        <span class="label ml-1 text-xxs blue"
                              v-if="messagingServiceHasLocalPresenceLines(scope.row)">
                            For Local Presence
                        </span>
                    </template>
                </el-table-column>
                <el-table-column align="center"
                                 data-testid="second-column"
                                 label="10DLC Numbers">
                    <template v-slot="scope">
                        <el-tooltip v-if="!possibleToAttachLine(scope.row) && !messagingServiceHasLocalPresenceLines(scope.row)"
                                    placement="right"
                                    data-testid="10dlc-tooltip"
                                    :content="scope.row.status | statusExplanation">
                            <el-tag type="danger"
                                    effect="dark"
                                    data-testid="10dlc-tag"
                                    size="mini">
                                Not Available [{{ scope.row.incoming_numbers?.length + selectedLinesToAttachIncomingNumbersCount ?? '0' }} / 400]
                            </el-tag>
                        </el-tooltip>
                        <span v-else>{{ scope.row.incoming_numbers?.length ?? '0' }} / 400</span>
                    </template>
                </el-table-column>
                <el-table-column align="center"
                                 data-testid="third-column">
                    <template v-slot="scope">
                        <el-tooltip
                            :disabled="possibleToAttachLine(scope.row)"
                            placement="right"
                            data-testid="attach-tooltip"
                            :content="reason">
                            <button class="btn btn-icon btn-sm btn-primary"
                                    :loading="attaching_line_to_messaging_service"
                                    data-testid="attach-button"
                                    :disabled="!possibleToAttachLine(scope.row) || attaching_line_to_messaging_service || registered"
                                    @click="attachLineToCampaign(scope.row.id)">
                                <i class="material-icons text-white">add</i>
                            </button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>

            <el-button class="mt-4"
                       type="success"
                       data-testid="next-line-button"
                       @click="goToLineDetails"
                       :disabled="!registered && requiresRegistration && hasUseCase">
                Next: Line Details
                <i class="el-icon-arrow-right el-icon-right"></i>
            </el-button>
        </div>
    </div>
</template>
<script>
import {acl_mixin, campaign_mixin, user_info_mixin} from '../mixins'
import {mapState} from 'vuex'
import * as ComplianceBundleStatus from '../constants/compliance-bundle-statuses'

export default {
    mixins: [
        acl_mixin,
        campaign_mixin,
        user_info_mixin
    ],

    props: {
        campaign: {
            required: true
        },
        available_messaging_services: {
            required: true,
            type: Array,
            default: () => []
        },
        available_messaging_services_loading: {
            required: true,
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            messaging_service_search: '',
            attaching_line_to_messaging_service: false,
            registered: false
        }
    },

    created() {
        VueEvent.listen('messaging_services', (event) => {
            if (event.error) {
                this.registered = false
                this.attaching_line_to_messaging_service = false
            }
        })
    },

    computed: {
        requiresRegistration() {
            return !this.campaign.is_fax && !this.campaign.has_tollfree_pn && !this.hasFakeNumber(this.campaign)
        },

        hasUseCase() {
            return this.available_messaging_services.length > 0
        },

        filteredMessagingServices() {
            if (this.messaging_service_search) {
                return this.available_messaging_services.filter(({id, use_case}) =>
                    id.toString().includes(this.messaging_service_search) ||
                    use_case.toLowerCase().includes(this.messaging_service_search.toLowerCase())
                )
            }
            return this.available_messaging_services
        },

        ...mapState('cache', ['current_company'])
    },

    methods: {
        attachLineToCampaign(id) {
            this.attaching_line_to_messaging_service = true
            let selected_line_ids = [this.campaign.id]

            axios
                .post(`/api/v1/compliance/messaging-services/${id}/attach-lines`, {
                    line_ids: selected_line_ids,
                })
                .then((res) => {
                    this.$notify({
                        duration: 2000,
                        title: 'Campaign',
                        message: res.data.message,
                        type: 'success',
                        showClose: true,
                        position: 'top-right'
                    })
                    this.registered = true
                })
                .catch((err) => {
                    this.registered = false
                    this.attaching_line_to_messaging_service = false
                    this.$root.handleErrors(err.response)
                })
        },

        goToLineDetails() {
            this.$emit('step-changed')
        },

        getUseCaseTitle(use_case) {
            if (!use_case) {
                return ''
            }

            return use_case
                .toLowerCase()
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
                .join(' ') // Join the words with spaces
        },

        hasFakeNumber(line) {
            // check we have any incoming number that has is fake flag set to true
            return line.incoming_numbers?.length > 0 && line.incoming_numbers.some(number => {
                return number.sid && typeof number.sid === 'string' && number.sid.includes('fake-')
            })
        },
    },

    filters: {
        statusExplanation(status) {
            return (
                {
                    [ComplianceBundleStatus.STATUS_STAGED]:
                        'Waiting for approval of A2P Brand.',
                    [ComplianceBundleStatus.STATUS_PENDING]:
                        'May take up to 48 hours to process.', // Pending
                    [ComplianceBundleStatus.STATUS_IN_REVIEW]: 'In review.', // In Review
                    [ComplianceBundleStatus.STATUS_REJECTED]:
                        'Our carrier will provide a rejection reason. Action needed.', // Rejected
                    [ComplianceBundleStatus.STATUS_APPROVED]:
                        'Use case is approved by our carrier. No further action needed.', // Approved
                }[status] || ''
            )
        },
    }
}
</script>
