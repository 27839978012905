<template>
    <base-tool
        :tool-name="tool.name"
        :tool-description="tool.description"
        @back="goBack"
    >
        <div class="row" v-loading="isLoading">
            <div class="col-12">
                <el-form-item label="Property">
                    <contact-property-selector
                        v-model="property"
                        placeholder="Select Contact Property"
                        @property-selected="handlePropertySelected"
                    />
                </el-form-item>
            </div>
        </div>
        
    </base-tool>
</template>

<script>
import BaseTool from './base-tool.vue'
import ToolMixin from './tool-mixin'
import ContactPropertySelector from '../../../contact-property-selector.vue'

export default {
    name: 'UpdateContactTool',

    mixins: [ToolMixin],

    components: {
        BaseTool,
        ContactPropertySelector,
    },

    props: {
        tool: {
            type: Object,
            required: true
        }
    },
    
    data() {
        return {
            property: null,
            isLoading: false,
        }
    },
    
    methods: {
        handlePropertySelected(property) {
            this.property = property
            console.log('property tool', property)
            this.$emit('property-selected', property)
        }
    },
}
</script>
