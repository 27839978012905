<template>
    <base-tool
        :tool-name="tool.name"
        :tool-description="tool.description"
        @back="goBack"
    >
        <tag-selector
            v-model="selectedTagIds"
            :multiple="false"
            class="w-100"
            @change="handleTagsChange"
            :category_prop="TagCategory.CAT_CONTACTS"
            :value="selectedTagIds"
        />
    </base-tool>
</template>

<script>
import BaseTool from './base-tool.vue'
import ToolMixin from './tool-mixin'
import TagSelector from '../../../tag-selector.vue'
import * as TagCategory from '../../../../constants/tag-categories'

export default {
    name: 'TagsTool',

    mixins: [ToolMixin],

    components: {
        BaseTool
    },

    props: {
        tool: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            selectedTagIds: [],
            TagCategory,
        }
    },

    methods: {
        handleTagsChange(selectedId) {
            if (!selectedId) {
                this.$emit('tags-selected', [])
                return
            }

            this.$emit('tags-selected', [selectedId])
        },
    },
}
</script>
