<template>
    <el-form-item prop="daily_digest_report_prompt_list">
        <span id="daily_digest_report_prompt_list"
              class="page-anchor">
        </span>
        <div class="row"
             style="display: flex; gap: 16px; align-items: stretch;">
            <!-- Daily Digest Report Sections -->
            <div class="highlights-container"
                 :class="{'col-md-6': digestReports.length}">
                <div class="form-label">
                    <h5>Daily Digest Report Sections</h5>
                    <small>
                        Configure sections and insights for your daily digest reports.
                    </small>
                    <p>
                        <i>
                            Click an item to edit or drag and drop to reorder sections.
                        </i>
                    </p>
                </div>
                <draggable v-model="digestReports"
                           handle=".drag-handle"
                           @end="onReorder">
                    <div class="draggable-item"
                         v-for="(item, index) in digestReports"
                         :key="item.id || index">
                        <el-tag class="highlight-tag font-weight-bold"
                                type="success"
                                closable
                                :disable-transitions="false"
                                @close="removeDigest(index)"
                                @click="editDigest(item, index)">
                            <span class="drag-handle"
                                  style="cursor: grab;">
                                ☰
                            </span>
                            {{ item.label.length > 50
                                ? item.label.slice(0, 50) + '...'
                                : item.label }}
                        </el-tag>
                    </div>
                </draggable>
                <div class="mt-2">
                    <el-button size="small"
                               @click="openDialog">
                        + Add Section
                    </el-button>
                    <el-button size="small"
                               type="primary"
                               class="ml-2"
                               @click="openTestEmailModal">
                        Send Test Email
                    </el-button>
                </div>
            </div>
            <!-- Daily Digest Report Preview -->
            <div class="col-md-6 summary-preview"
                 v-if="digestReports.length">
                <div class="form-label">
                    <h5>Daily Digest Report Preview</h5>
                    <small>
                        Include these items in the daily digest report email.
                    </small>
                </div>
                <div class="ai-effect-container mt-2">
                    <div class="ai-effect-gradient"></div>
                    <div class="ai-effect-blur"></div>
                    <div class="ai-effect-content p-2">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center gap-2">
                                <h3 class="ai-effect-gradient-text">
                                    Powered by AloAi
                                </h3>
                            </div>
                            <div class="text-left-align">
                                <div class="inline-preview">
                                    <div class="inline-summary-item"
                                         v-for="(item, index) in digestReports"
                                         :key="index">
                                        <span class="font-size-sm font-weight-bold"
                                              style="color: #000">
                                            {{ item.label }}
                                        </span>
                                        <br />
                                        <span class="font-size-xs"
                                              style="color: #000">
                                            {{ item.prompt }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Dialog for Creating or Editing a Digest Report Section -->
        <el-dialog title="Manage Daily Digest Report Section"
                   top="10vh"
                   width="45%"
                   append-to-bod
                   :visible.sync="dialog_visible">
            <!-- Steps Navigation -->
            <el-steps class="mb-3"
                      align-center
                      finish-status="success"
                      :active="current_step">
                <el-step title="Select Method" />
                <el-step title="Choose Template" />
                <el-step title="Customize Section" />
            </el-steps>

            <!-- Step 1: Selection Options -->
            <template v-if="current_step === 1">
                <el-radio-group v-model="selected_type"
                                @change="handleSelection">
                    <div class="row row-filter highlight-options">
                        <!-- Create from Scratch -->
                        <div class="col-md-6">
                            <el-card shadow="hover"
                                     class="cursor-pointer equal-height-card">
                                <el-radio label="scratch"
                                          class="w-100">
                                    <div class="d-flex align-items-center mb-2">
                                        <i class="text-2x mr-2 material-icons text-green-500">
                                            create
                                        </i>
                                        <span class="text-lg font-weight-bold text-grey-9">
                                            Create from scratch
                                        </span>
                                    </div>
                                    <p class="text-md l-h">
                                        Begin with a blank template to design a fully personalized section.
                                    </p>
                                </el-radio>
                            </el-card>
                        </div>
                        <!-- Use Template -->
                        <div class="col-md-6">
                            <el-card shadow="hover"
                                     class="cursor-pointer equal-height-card">
                                <el-radio label="template"
                                          class="w-100">
                                    <div class="d-flex align-items-center mb-2">
                                        <i class="text-2x mr-2 material-icons text-blue-500">
                                            layers
                                        </i>
                                        <span class="text-lg font-weight-bold text-grey-9">
                                            Use a template
                                        </span>
                                    </div>
                                    <p class="text-md l-h">
                                        Begin with a predefined template and personalize it to match your requirements.
                                    </p>
                                </el-radio>
                            </el-card>
                        </div>
                    </div>
                </el-radio-group>
            </template>

            <!-- Step 2: Use Template -->
            <template v-if="current_step === 2">
                <el-form-item class="pb-2">
                    <div class="form-label">
                        <h5>Predefined Section</h5>
                        <small>
                            Select a predefined section to populate the label and prompt.
                        </small>
                    </div>
                    <el-select class="w-full"
                               placeholder="Select a predefined section"
                               filterable
                               v-model="selected_template"
                               @change="applyTemplate">
                        <el-option v-for="option in templates"
                                   :key="option.id"
                                   :label="option.label"
                                   :value="option" />
                    </el-select>
                </el-form-item>
            </template>

            <!-- Step 3: Final Customization -->
            <template v-if="current_step === 3">
                <el-form class="no-border p-0">
                    <!-- Label Field -->
                    <el-form-item class="p-2">
                        <div class="form-label">
                            <h5>Label</h5>
                            <small>
                                Enter or edit the label for this section.
                            </small>
                        </div>
                        <el-input placeholder="Enter the label for this section"
                                  v-model="editing_digest.label" />
                    </el-form-item>

                    <!-- Prompt Field -->
                    <el-form-item class="p-2">
                        <div class="form-label">
                            <h5>Prompt</h5>
                            <small>
                                Please provide clear and detailed instructions to guide the AI effectively.
                            </small>
                        </div>
                        <el-input type="textarea"
                                  rows="4"
                                  maxlength="3000"
                                  placeholder="Enter the details for your section prompt"
                                  v-model="editing_digest.prompt" />
                    </el-form-item>

                    <!-- Order Field -->
                    <el-form-item class="p-2">
                        <div class="no-border form-label">
                            <h5>Order</h5>
                            <small>
                                Set the priority order for this section.
                            </small>
                        </div>
                        <el-input-number size="mini"
                                         v-model="editing_digest.order"
                                         :min="1"
                                         :max="digestReports.length + 1" />
                    </el-form-item>
                </el-form>
            </template>

            <!-- Footer Buttons -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">
                    Cancel
                </el-button>
                <el-button type="primary"
                           v-if="current_step === 3"
                           @click="saveDigest">
                    Save
                </el-button>
            </span>
        </el-dialog>

        <!-- Test Email Modal -->
        <el-dialog title="Send Test Daily Digest Report"
                   width="40%"
                   :visible.sync="test_email_modal_visible"
                   @close="closeTestEmailModal">
            <el-form ref="test_email_form"
                     label-position="top"
                     :model="test_email_form"
                     :rules="test_email_rules">
                <div class="alert alert-info">
                    <i class="el-icon-info mr-2"></i>
                    Send a test daily digest report to verify your configuration. The report will include all configured sections and insights.
                </div>
                <el-form-item label="Email Addresses"
                             prop="emails_array">
                    <el-select multiple
                               filterable
                               allow-create
                               default-first-option
                               placeholder="Enter or select email addresses"
                               class="w-100"
                               value-key="email"
                               v-model="test_email_form.emails_array"
                               @change="handleEmailsChange">
                        <el-option v-for="(email, index) in suggested_emails"
                                   :key="'email-option-' + email + '-' + index"
                                   :label="email"
                                   :value="email" />
                    </el-select>
                    <small class="form-text text-muted">
                        <i class="el-icon-info mr-1"></i>
                        You can type new email addresses or select from suggested ones. Press Enter to add multiple emails.
                    </small>
                </el-form-item>
                <el-form-item label="Report Date"
                             prop="date">
                    <el-date-picker type="date"
                                    class="w-100"
                                    value-format="yyyy-MM-dd"
                                    placeholder="Select date for the report"
                                    v-model="test_email_form.date"
                                    :picker-options="{ disabledDate(time) { return time.getTime() > Date.now() } }" />
                    <small class="form-text text-muted">
                        <i class="el-icon-time mr-1"></i>
                        Select a date to generate the report for that specific day.
                    </small>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeTestEmailModal">
                    Cancel
                </el-button>
                <el-button type="primary"
                           :loading="sending_test_email"
                           :disabled="!isTestEmailFormValid"
                           @click="submitTestEmail">
                    {{ sending_test_email ? 'Sending...' : 'Send Test Email' }}
                </el-button>
            </div>
        </el-dialog>
    </el-form-item>
</template>

<script>
    import draggable from "vuedraggable"
    import axios from "axios"
    export default {
        name: "DailyDigestReport",

        components: {
            draggable
        },

        props: {
            company_clone: {
                type: Object,
                required: true
            },
            value: {
                type: Array,
                default: () => []
            },
            templates: {
                type: Array,
                default: () => []
            }
        },

        data() {
            return {
                dialog_visible: false,
                current_step: 1,
                selected_type: "",
                selected_template: null,
                editing_digest: {
                    label: "",
                    prompt: "",
                    order: 1
                },
                editing_index: null,
                // Test Email Modal Data
                test_email_modal_visible: false,
                test_email_form: {
                    emails_array: [],
                    date: this.getDefaultTestDate()
                },
                test_email_rules: {
                    emails_array: [
                        {
                            required: true,
                            validator: this.validateEmails,
                            trigger: "change"
                        }
                    ],
                    date: [
                        {
                            required: true,
                            message: "Please select a date",
                            trigger: "change"
                        }
                    ]
                },
                suggested_emails: [],
                sending_test_email: false
            }
        },

        methods: {
            openDialog() {
                this.dialog_visible = true
                this.current_step = 1
                this.editing_digest = {
                    label: "",
                    prompt: "",
                    order: this.digestReports.length + 1
                }
                this.editing_index = null
                this.selected_type = ""
                this.selected_template = null
            },

            closeDialog() {
                this.dialog_visible = false
                this.editing_digest = {
                    label: "",
                    prompt: "",
                    order: this.digestReports.length + 1
                }
                this.editing_index = null
                this.selected_type = ""
                this.selected_template = null
            },

            handleSelection(value) {
                if (value === "template") {
                    this.current_step = 2
                } else {
                    this.current_step = 3
                }
            },

            applyTemplate(template) {
                if (template) {
                    this.editing_digest.label = template.label
                    this.editing_digest.prompt = template.prompt
                    this.current_step = 3
                }
            },

            saveDigest() {
                const trimmed_label = this.editing_digest.label.trim()
                if (!trimmed_label || !this.editing_digest.prompt.trim()) {
                    this.$notify({
                        type: "error",
                        message: "Both label and prompt are required."
                    })
                    return
                }

                // Check for duplicate label (case insensitive)
                const is_duplicate_label = this.digestReports.some((item, index) =>
                    item.label.toLowerCase() === trimmed_label.toLowerCase() &&
                    index !== this.editing_index
                )
                if (is_duplicate_label) {
                    this.$notify({
                        type: "error",
                        message: "The label must be unique. A duplicate label was found."
                    })
                    return
                }

                // Adjust order for logical placement
                this.editing_digest.order -= 0.5
                this.editing_digest.label = trimmed_label

                if (this.editing_index !== null) {
                    this.$set(this.digestReports, this.editing_index, { ...this.editing_digest })
                } else {
                    this.digestReports.push({ ...this.editing_digest })
                }

                // Sort the list by order.
                this.digestReports.sort((a, b) => a.order - b.order)

                this.onReorder()
                this.closeDialog()
            },

            removeDigest(index) {
                if (this.digestReports.length <= 1) {
                    this.$notify({
                        type: "error",
                        message: "At least one digest report configuration is required."
                    })
                    return
                }
                this.digestReports.splice(index, 1)
                this.onReorder()
            },

            editDigest(item, index) {
                this.editing_digest = { ...item }
                this.editing_index = index
                this.dialog_visible = true
                this.current_step = 3
            },

            onReorder() {
                // Reindex the list for standard order.
                const ordered = this.digestReports.map((item, index) => ({
                    ...item,
                    order: index + 1
                }));
                
                this.$emit("input", ordered);
                this.$emit("update-setting");
            },

            getDefaultTestDate() {
                const today = new Date()
                today.setDate(today.getDate() - 1)
                return today.toISOString().split("T")[0]
            },

            openTestEmailModal() {
                this.test_email_modal_visible = true
                axios
                    .get(`/api/v1/company/${this.company_clone.id}/aloai-report-recipients`)
                    .then(response => {
                        this.suggested_emails = response.data.map(user => user.email)
                    })
                    .catch(error => {
                        console.error("Failed to fetch suggested emails:", error)
                    })
            },

            closeTestEmailModal() {
                this.$refs.test_email_form && this.$refs.test_email_form.resetFields()
                this.test_email_modal_visible = false
            },

            handleEmailsChange(value) {
                this.test_email_form.emails_array = value.filter(email => email.trim() !== "")
            },

            // Test Email Submission
            submitTestEmail() {
                this.$refs.test_email_form.validate(valid => {
                    if (!valid) return
                    this.sending_test_email = true
                    axios
                        .post("/api/v1/transcription/daily-digest-test-email", {
                            emails: this.test_email_form.emails_array,
                            date: this.test_email_form.date
                        })
                        .then(response => {
                            this.$notify({
                                type: "success",
                                message: "Test email has been queued and will be sent shortly.",
                            })
                            this.closeTestEmailModal()
                        })
                        .catch(error => {
                            this.$notify({
                                type: "error",
                                message: error.response?.data?.message || "Failed to send test email",
                            })
                        })
                        .finally(() => {
                            this.sending_test_email = false
                        })
                })
            },

            // Email Validation
            validateEmails(rule, value, callback) {
                if (!Array.isArray(value) || value.length === 0) {
                    callback(new Error("Please enter at least one email address"))
                    return
                }
                const email_regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                const invalid_emails = value.filter(
                    email => typeof email === "string" && !email_regex.test(email.trim())
                )
                if (invalid_emails.length > 0) {
                    callback(new Error("Please enter valid email addresses"))
                    return
                }
                callback()
            }
        },

        computed: {
            // Digest Reports getter and setter
            digestReports: {
                get() {
                    return this.value
                },
                set(newVal) {
                    this.$emit("input", [...newVal])
                }
            },

            // Test Email Form Validation
            isTestEmailFormValid() {
                return (
                    this.test_email_form.emails_array.length > 0 &&
                    this.test_email_form.date
                )
            }
        }
    }
</script>

<style scoped>
.draggable-item {
    margin-bottom: 8px;
}

.highlight-tag {
    font-size: 15px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
}

.drag-handle {
    margin-right: 8px;
    font-weight: bold;
    cursor: grab;
    user-select: none;
}

.highlight-options .equal-height-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.highlights-container {
    flex: 1;
}

.summary-preview {
    flex: 1;
    overflow-y: hidden;
    background: #f9f9f9;
    padding: 8px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    max-height: 480px;
}

.ai-effect-content {
    overflow-y: auto;
    max-height: 400px;
}

.ai-effect-container {
    max-height: 450px;
    overflow: hidden;
}

.inline-summary-item {
    margin-bottom: 4px;
    line-height: 1.5;
}
</style>
