<template>
    <el-card>
        <!-- Start loader -->
        <div class="text-center mt-2"
             v-if="isLoading">
            <h5>
                <i class="fa fa-spinner fa-spin"></i>
                <span>Loading...</span>
            </h5>
        </div>
        <!-- End loader -->
        <div v-else>
            <el-form :name="`${integration}-property-mapping`"
                     class="text-left"
                     @submit.native.prevent>
                <!-- Start Default Fields -->
                <div class="d-flex justify-content-between">
                    <h5>Default Fields</h5>
                    <el-switch
                        v-model="editingEnabled"
                        class="mt-1"
                        active-text="Enable editing"
                    />
                </div>

                <div class="row form-group mt-3"
                     v-for="(prop,index) in defaultPropertyMappings"
                     :key="`${index}-default`"
                >
                    <!-- Start Aloware and HubSpot Lables -->
                    <template v-if="index === 0">
                        <div class="col-md-4 mb-1">
                            <small class="font-weight-bold">Aloware</small>
                        </div>
                        <div class="col-md-2 mb-1 text-center">
                            <small class="font-weight-bold">Direction</small>
                        </div>
                        <div class="col-md-4 mb-1">
                            <small class="font-weight-bold">{{ getIntegrationTitleName() }}</small>
                        </div>
                    </template>
                    <!-- End Aloware and HubSpot Labels -->

                    <!-- Start Aloware Fields -->
                    <div class="col-md-4">
                        <el-form-item class="mb-1">
                            <el-select v-model="prop.aloware_key"
                                       class="w-full"
                                       filterable
                                       :disabled="!prop.is_mappable || !editingEnabled"
                                       @change="handleSelectionChange(index, prop)">
                                <el-option
                                    v-for="option in alowareDefaultOptions"
                                    :key="option['key']"
                                    :label="option['label']"
                                    :disabled="!option['is_mappable'] || isSelectedOption(option['key'], prop.aloware_key)"
                                    :value="option['key']">
                                    <i v-if="isSelectedOption(option['key'], prop.aloware_key)"
                                       class="fa fa-ban text-grey-5">
                                    </i>
                                    {{ option['label'] }}
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <!-- End Aloware Fields -->

                    <div class="col-md-2 text-center">
                        <direction-selector :initial-state="prop.direction"
                                            :disabled="shouldDisableDefaultDirection(index, prop)"
                                            @change="direction => updateDefaultDirection(index, direction)">
                        </direction-selector>
                    </div>

                    <!-- Start Integration Fields -->
                    <div class="col-md-4">
                        <el-form-item class="mb-1">
                            <el-select v-model="prop.external_key"
                                       class="w-full"
                                       filterable
                                       :disabled="!prop.is_mappable || !editingEnabled">
                                <el-option-group
                                    v-for="group in hubspotPropertiesOptions"
                                    :key="group.label"
                                    :label="group.label"
                                >
                                    <el-option
                                        v-for="option in group.data"
                                        :key="option.name"
                                        :label="option.label"
                                        :value="option.name"
                                        :disabled="!option.is_mappable || isSelectedOption(option.name, prop.external_key, integration)">
                                        <i v-if="isSelectedOption(option.name, prop.external_key, integration)"
                                           class="fa fa-ban text-grey-5">
                                        </i>
                                        {{ `${option.label} (${option.field_type})` }}
                                    </el-option>
                                </el-option-group>
                            </el-select>
                        </el-form-item>
                    </div>
                    <!-- End HubSpot Fields -->

                    <!-- Start Delete button -->
                    <div v-if="prop.is_mappable"
                         class="col-md-2 text-center">
                        <el-button size="small"
                                   circle
                                   plain
                                   :disabled="!editingEnabled"
                                   @click="removeDefaultPropertyMapping(index)">
                            <i class="fa fa-trash"></i>
                        </el-button>
                    </div>
                    <div v-else class="col-md-2 text-center mt-2">
                        <i class="fa fa-lock fa-lg text-grey-4"></i>
                    </div>
                    <!-- End Delete Button -->
                </div>
                <!-- Start Add mapping button -->
                <div class="row form-group">
                    <div class="col-md-10 text-center">
                        <el-button size="small"
                                   :disabled="!editingEnabled"
                                   @click="addDefaultPropertyMapping">
                            <i class="fa fa-plus-circle"></i>
                            <span class="ml-1 font-weight-bold">ADD MAPPING</span>
                        </el-button>
                    </div>
                </div>
                <!-- End Add mapping button -->
                <!-- End Default Fields -->

                <!-- Start Custom Fields -->
              <template v-if="integration === 'hubspot'">
                <h5>Custom Fields</h5>
                <div class="row form-group mt-3"
                     v-for="(prop, index) in customPropertyMappings"
                     :key="`${index}-custom`"
                >
                    <!-- Start Aloware and Integration Lables -->
                    <template v-if="index === 0">
                        <div class="col-md-4 mb-1">
                            <small class="font-weight-bold">Aloware</small>
                        </div>
                        <div class="col-md-2 mb-1 text-center">
                            <small class="font-weight-bold">Direction</small>
                        </div>
                        <div class="col-md-4 mb-1">
                            <small class="font-weight-bold"> {{ getIntegrationTitleName() }} </small>
                        </div>
                    </template>
                    <!-- End Aloware and HubSpot Labels -->

                    <!-- Start Aloware Field -->
                    <div class="col-md-4">
                        <el-form-item class="mb-1">
                            <el-select v-model="prop.aloware_key"
                                       class="w-full"
                                       filterable
                                       :disabled="!prop.is_mappable || !editingEnabled">
                                <el-option
                                    v-for="option in alowareCustomOptions"
                                    :key="option['key']"
                                    :label="option['label']"
                                    :disabled="!option['is_mappable'] || isSelectedOption(option['key'], prop.aloware_key)"
                                    :value="option['key']">
                                    <i v-if="isSelectedOption(option['key'], prop.aloware_key)"
                                       class="fa fa-ban text-grey-5">
                                    </i>
                                    {{ option['label'] }}
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <!-- End Aloware Fields -->

                    <div class="col-md-2 text-center">
                        <direction-selector :initial-state="prop.direction"
                                            :disabled="!prop.is_mappable || !editingEnabled"
                                            @change="direction => updateCustomDirection(index, direction)">
                        </direction-selector>
                    </div>

                    <!-- Start HubSpot Fields -->
                    <div class="col-md-4">
                        <el-form-item class="mb-1">
                            <el-select v-model="prop.external_key"
                                       class="w-full"
                                       filterable
                                       :disabled="!prop.is_mappable || !editingEnabled">
                                <el-option-group
                                    v-for="group in hubspotPropertiesOptions"
                                    :key="group.label"
                                    :label="group.label"
                                >
                                    <el-option
                                        v-for="option in group.data"
                                        :key="option.name"
                                        :label="option.label"
                                        :value="option.name"
                                        :disabled="!option.is_mappable || isSelectedOption(option.name, prop.external_key, integration)">
                                        <i v-if="isSelectedOption(option.name, prop.external_key, integration)"
                                           class="fa fa-ban text-grey-5">
                                        </i>
                                        {{ `${option.label} (${option.field_type})` }}
                                    </el-option>
                                </el-option-group>
                            </el-select>
                        </el-form-item>
                    </div>
                    <!-- End HubSpot Fields -->

                    <!-- Start Delete button -->
                    <div v-if="prop.is_mappable"
                         class="col-md-2 text-center">
                        <el-button size="small"
                                   circle
                                   plain
                                   :disabled="!editingEnabled"
                                   @click="removeCustomPropertyMapping(index)">
                            <i class="fa fa-trash"></i>
                        </el-button>
                    </div>
                    <div v-else class="col-md-2 text-center mt-2">
                        <i class="fa fa-lock fa-lg text-grey-4"></i>
                    </div>
                    <!-- End Delete Button -->
                </div>
                <!-- Start Add mapping button -->
                <div class="row form-group">
                    <div class="col-md-10 text-center">
                        <el-button size="small"
                                   :disabled="!editingEnabled"
                                   @click="addCustomPropertyMapping">
                            <i class="fa fa-plus-circle"></i>
                            <span class="ml-1 font-weight-bold">ADD MAPPING</span>
                        </el-button>
                    </div>
                </div>
              </template>
                <!-- End Add mapping button -->
                <!-- End Custom Properties Fields -->
            </el-form>
        </div>
    </el-card>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {handleVuexApiCall, sleep} from '../../../utils/integration-helpers'
import DirectionSelector from "../direction-selector.vue"
import * as IntegrationPropertyMapDirections from '../../../constants/integration-property-map-directions'
import axios from "axios";

export default {
    name: 'integration-property-mapper',
    components: {DirectionSelector},
    props: {
      integration: {
        type: String,
        required: true,
      },
    },
    data: () => ({
        // ui
        isLoading: false,
        editingEnabled: false,

        // mappings
        defaultPropertyMappings: [],
        customPropertyMappings: [],
        IntegrationPropertyMapDirections
    }),
    computed: {
        ...mapGetters('hubspotPropertyMapping', ['getPropertyMappings', 'getCrmContactProperties', 'getAlowareContactProperties']),
        alowareDefaultOptions() {
            return this.$store.getters['hubspotPropertyMapping/getAlowareContactProperties']['default']
        },
        alowareCustomOptions() {
            return this.$store.getters['hubspotPropertyMapping/getAlowareContactProperties']['custom']
        },
        hubspotPropertiesOptions() {
            const newOptions = []

            for (const prop of this.$store.getters['hubspotPropertyMapping/getCrmContactProperties']) {
                let groupExists = false

                for (const newOption of newOptions) {
                    if (newOption.label === prop.group_label) {
                        newOption.data.push(prop)
                        groupExists = true
                    }
                }
                if (!groupExists) newOptions.push({label: prop.group_label, data: [prop]})
            }

            return newOptions
        }
    },
    async created() {
        this.isLoading = true

        if (this.integration === 'hubspot') {
          await Promise.all([this.fetchPropertyMappings(), this.fetchCrmContactProperties(), this.fetchAlowareContactProperties()])

        } else {
          // fetch mappings for other integrations
          try {
            const promises = await Promise.all([
              this.getIntegrationPropertyMappings(this.integration),
              this.getIntegrationItemProperties(this.integration),
              this.getAlowareDefaultProperties(this.integration),
            ])
          } catch (e) {
            console.log(e)
            this.$notify({
              title: 'Contact Property Mapping',
              message: 'Unable to fetch property mappings. Please contact customer support',
              type: 'error',
              showClose: true
            })
          }
        }

        const mappings = this.$store.getters['hubspotPropertyMapping/getPropertyMappings']

        this.defaultPropertyMappings = mappings.filter(mapping => mapping.is_default)
            .sort(mapping => !mapping.is_mappable ? -1 : 1) // push un-mappable properties on top of the list

        this.customPropertyMappings = mappings.filter(mapping => !mapping.is_default)

        this.isLoading = false
    },
    methods: {
        ...mapActions('hubspotPropertyMapping', [
            'fetchPropertyMappings',
            'fetchCrmContactProperties',
            'fetchAlowareContactProperties',
            'storePropertyMappings',
            'getIntegrationPropertyMappings',
            'getIntegrationItemProperties',
            'getAlowareDefaultProperties',
        ]),
        addDefaultPropertyMapping() {
            this.defaultPropertyMappings.push({
                aloware_key: '',
                external_key: '',
                is_mappable: true,
                is_default: true,
                direction: IntegrationPropertyMapDirections.DIRECTION_BI
            })
        },
        removeDefaultPropertyMapping(index) {
            this.defaultPropertyMappings.splice(index, 1)
        },
        handleSelectionChange(index, prop) {
            const selectedOption = this.alowareDefaultOptions.find(option => option.key === prop.aloware_key)

            if (!selectedOption) {
                return
            }

            if (selectedOption.direction !== prop.direction) {
                this.updateDefaultDirection(index, selectedOption.direction)
            }
        },
        shouldDisableDefaultDirection(index, prop) {
            const selectedOption = this.alowareDefaultOptions.find(option => option.key === prop.aloware_key)

            const isNotBiDirectional = selectedOption?.direction !== IntegrationPropertyMapDirections.DIRECTION_BI

            return !prop.is_mappable || !this.editingEnabled || isNotBiDirectional
        },
        addCustomPropertyMapping() {
            this.customPropertyMappings.push({
                aloware_key: '',
                external_key: '',
                is_mappable: true,
                is_default: false,
                direction: IntegrationPropertyMapDirections.DIRECTION_BI
            })
        },
        removeCustomPropertyMapping(index) {
            this.customPropertyMappings.splice(index, 1)
        },
        isSelectedOption(option, selectedOption, propOwner = 'aloware') {
            if (!['aloware', this.integration].includes(propOwner)) throw new Error(`propOwner must either be 'aloware' or ${this.integration}`)

            const mergedMappings = this.defaultPropertyMappings.concat(this.customPropertyMappings)
            const keyType = propOwner === 'aloware' ? 'aloware_key' : 'external_key'
            const optionKeys = mergedMappings.map(mapping => mapping[keyType])

            return optionKeys.includes(option) && option !== selectedOption
        },
        async saveMappings() {
            const mergedMappings = this.defaultPropertyMappings.concat(this.customPropertyMappings)

            // in case the user spams the save button while things are loading
            if (mergedMappings.length === 0) return true

            const errorNotifDuration = 5 * 1000
            if (!this.hasCompleteMappings(mergedMappings)) {
                this.$notify({
                    duration: errorNotifDuration,
                    offset: 175,
                    title: 'Contact Property Mapping',
                    message: 'One or more property mappings have no selected value',
                    type: 'error',
                    showClose: true
                })
                return false
            }

            const payload = { mappings: mergedMappings, integration: this.integration }
            const res = await handleVuexApiCall(this.storePropertyMappings, payload)

            if (!res.success) {
                this.$notify({
                    duration: errorNotifDuration,
                    offset: 175,
                    title: 'Contact Property Mapping',
                    message: 'Unable to save property mappings. Please contact customer support',
                    type: 'error',
                    showClose: true
                })
                // Give the user time to read before reloading the page
                await sleep(errorNotifDuration)
            }

            return true
        },
        hasCompleteMappings(mergedMappings) {
            // check if mappings all have values
            return mergedMappings.every(mapping => mapping['aloware_key'] && mapping['external_key'])
        },

        updateDefaultDirection(index, direction) {
            this.defaultPropertyMappings[index].direction = direction
        },

        updateCustomDirection(index, direction) {
            this.customPropertyMappings[index].direction = direction
        },

        getIntegrationTitleName() {
            return this.integration.toLowerCase().replace(/\b\w/g, s => s.toUpperCase())
        },
    }
}
</script>
