var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "companySettingsForm",
          staticClass: "form-aloware",
          attrs: { model: _vm.transcription_settings },
        },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "div",
                { staticClass: "col-md-10" },
                [
                  _c("div", { staticClass: "row pb-3" }, [
                    _c("div", { staticClass: "d-block w-100" }, [
                      _c("div", { staticClass: "ai-info-box" }, [
                        _c("div", { staticClass: "ai-info-box-header" }, [
                          _vm.usagePercentage < 100 && _vm.isTrial
                            ? _c("span", { staticClass: "ai-info-box-icon" }, [
                                _vm._v(
                                  "\n                                    🎁\n                                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", { staticClass: "ai-info-box-title" }, [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.modalContent.title) +
                                "\n                                "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "ai-info-box-content text-white" },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.modalContent.message) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ai-info-box-links" }, [
                          _c("strong", [_vm._v("Guides:")]),
                          _vm._v(" "),
                          _c("ul", { staticClass: "pl-4" }, [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://support.aloware.com/en/articles/10233960-guide-for-agents-using-aloai-voice-analytics",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                            Agents guide to AloAi Voice Analytics\n                                        "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://support.aloware.com/en/articles/10235067-guide-for-admins-using-aloai-voice-analytics",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                            Admins guide to AloAi Voice Analytics\n                                        "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(
                            "\n                                Revolutionize your calls with AloAi Voice Analytics; read the\n                                "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://aloware.com/blog/aloai-voice-analytics-announcement",
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                    blog post\n                                "
                              ),
                            ]
                          ),
                          _vm._v(
                            "\n                                to learn more!\n                                "
                          ),
                          _vm.isAnyCallRecordingDisabled
                            ? _c("div", [
                                _c("br"),
                                _vm._v(" "),
                                _c("span", { staticClass: "mr-1" }, [
                                  _vm._v("⚠️"),
                                ]),
                                _vm._v(" "),
                                _vm.isAllCallRecordingDisabled
                                  ? _c("strong", [
                                      _vm._v(
                                        "You’re currently not recording any calls. To take advantage of our AloAi Voice Analytics product, turn on call recordings:"
                                      ),
                                    ])
                                  : _c("strong", [
                                      _vm._v(
                                        "You’re currently not recording all your calls. To take advantage of our AloAi Voice Analytics product, turn on call recordings:"
                                      ),
                                    ]),
                                _vm._v(" "),
                                _c("ul", { staticClass: "pl-4" }, [
                                  _c("li", [
                                    _c("span", [
                                      _vm._v("For inbound call recordings: "),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.inboundCallRecordingUrl,
                                        },
                                      },
                                      [_vm._v("Link")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _c("span", [
                                      _vm._v("For outbound call recordings: "),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.outboundCallRecordingUrl,
                                        },
                                      },
                                      [_vm._v("Link")]
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.showWarning
                    ? _c(
                        "div",
                        { staticClass: "row pb-3" },
                        [
                          _c(
                            "el-alert",
                            { attrs: { "show-icon": "", type: "warning" } },
                            [
                              _c(
                                "span",
                                { staticClass: "d-block mb-1 text-black" },
                                [
                                  _vm._v(
                                    "\n                            You have used "
                                  ),
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("numFormat")(
                                          _vm.transcription_usage.used
                                        )
                                      ) +
                                        " / " +
                                        _vm._s(
                                          _vm._f("numFormat")(
                                            _vm.transcription_usage.available
                                          )
                                        )
                                    ),
                                  ]),
                                  _vm._v(
                                    " of AloAi Voice Analytics minutes.\n                            "
                                  ),
                                  !_vm.transcription_settings
                                    .overusage_restriction_enabled
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                                Additional minutes will be billed at "
                                        ),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("toCurrency")(
                                                _vm._f("fixRounding")(
                                                  _vm.auth.user.profile.rate
                                                    .transcription,
                                                  2
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(
                                          " per minute.\n                            "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.current_company &&
                  _vm.current_company.transcription_settings
                    ?.call_transcription_enabled &&
                  _vm.current_company.hubspot_integration_enabled
                    ? _c(
                        "div",
                        { staticClass: "row pb-3" },
                        [
                          _c(
                            "el-alert",
                            { attrs: { type: "info", "show-icon": "" } },
                            [
                              _c("span", { staticClass: "d-block mb-1" }, [
                                _c("b", [_vm._v("Attention:")]),
                                _vm._v(
                                  " HubSpot integration is enabled for this account. To avoid redundancy, consider disabling transcriptions in HubSpot.\n                        "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "pb-1",
                      class: {
                        "no-border":
                          _vm.transcription_settings.call_transcription_enabled,
                      },
                      attrs: { prop: "call_transcription_enabled" },
                    },
                    [
                      _c("span", {
                        staticClass: "page-anchor",
                        attrs: { id: "call_transcription_enabled" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-label" }, [
                        _c("h5", [_vm._v("Call Transcription")]),
                        _vm._v(" "),
                        _c("small", [
                          _vm._v(
                            "\n                            This feature allows you to review your calls with AloAi Voice Analytics.\n                        "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "switch-label" },
                        [
                          _c("el-switch", {
                            attrs: { "active-color": "#00a344" },
                            on: { change: _vm.updateSetting },
                            model: {
                              value:
                                _vm.transcription_settings
                                  .call_transcription_enabled,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.transcription_settings,
                                  "call_transcription_enabled",
                                  $$v
                                )
                              },
                              expression:
                                "transcription_settings.call_transcription_enabled",
                            },
                          }),
                          _vm._v(" "),
                          _c("label", [_vm._v("Enable call transcription")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.transcription_settings.call_transcription_enabled
                        ? [
                            _c(
                              "el-button",
                              {
                                staticClass: "my-2",
                                attrs: { type: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateTranscriptionEnabledUsers(
                                      true
                                    )
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v("Enable Transcription for all users"),
                                ]),
                                _vm._v(" "),
                                _vm.loading_enable_transcription_for_users
                                  ? _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons loader mr-2 pull-left",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                \n                            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "ml-md-2",
                                attrs: { type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateTranscriptionEnabledUsers(
                                      false
                                    )
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v("Disable Transcription for all users"),
                                ]),
                                _vm._v(" "),
                                _vm.loading_disable_transcription_for_users
                                  ? _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons loader mr-2 pull-left",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                \n                            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.transcription_settings.call_transcription_enabled
                        ? [
                            _c("p", { staticClass: "mb-0" }, [
                              !_vm.loading_users_list
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "text-dark-greenish el-green-hover mt-1",
                                      on: {
                                        click: function ($event) {
                                          return _vm.displayUserTranscriptionManagementDialog()
                                        },
                                      },
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          "\n                                    Manage User Transcription Settings\n                                    "
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "fas fa-angle-right mr-1",
                                        }),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.loading_users_list
                                ? _c(
                                    "i",
                                    {
                                      staticClass:
                                        "text-dark-greenish material-icons loader mr-2 pull-left mb-1",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                \n                            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.transcription_settings.call_transcription_enabled
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "no-border pb-2",
                          attrs: { prop: "on_demand_enabled" },
                        },
                        [
                          _c("span", {
                            staticClass: "page-anchor",
                            attrs: { id: "on_demand_enabled" },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-label" }, [
                            _c("h5", [
                              _vm._v(
                                "On-Demand Transcription and Summarization"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("small", [
                              _vm._v(
                                "\n                            Enable this option to manually transcribe and generate call summaries instead of automatically.\n                        "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "switch-label" },
                            [
                              _c("el-switch", {
                                attrs: { "active-color": "#00a344" },
                                on: { change: _vm.updateSetting },
                                model: {
                                  value:
                                    _vm.transcription_settings
                                      .on_demand_enabled,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.transcription_settings,
                                      "on_demand_enabled",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "transcription_settings.on_demand_enabled",
                                },
                              }),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v(
                                  "Enable on-demand transcription and summarization"
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transcription_settings?.call_transcription_enabled
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "pb-1",
                          class: {
                            "no-border":
                              _vm.transcription_settings.summarization_enabled,
                          },
                          attrs: { prop: "summarization_enabled" },
                        },
                        [
                          _c("span", {
                            staticClass: "page-anchor",
                            attrs: { id: "summarization_enabled" },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-label" }, [
                            _c("h5", [_vm._v("Summarization Settings")]),
                            _vm._v(" "),
                            _c("small", [
                              _vm._v(
                                "\n                            This feature allows you to summarize your calls using AloAi.\n                        "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "switch-label" },
                            [
                              _c("el-switch", {
                                attrs: { "active-color": "#00a344" },
                                on: { change: _vm.updateSetting },
                                model: {
                                  value:
                                    _vm.transcription_settings
                                      .summarization_enabled,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.transcription_settings,
                                      "summarization_enabled",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "transcription_settings.summarization_enabled",
                                },
                              }),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v("Enable call summarization"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transcription_settings?.summarization_enabled &&
                  _vm.transcription_settings?.call_transcription_enabled
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "no-border pb-2",
                          attrs: { prop: "summary_length" },
                        },
                        [
                          _c("span", {
                            staticClass: "page-anchor",
                            attrs: { id: "summary_length" },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-label" }, [
                            _c("h5", [_vm._v("Call Summary Length")]),
                            _vm._v(" "),
                            _c("small", [
                              _vm._v(
                                "Target length for the call summary. AloAi will do its best to honor this value, but it is not guaranteed."
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-input-number", {
                            attrs: {
                              min: 50,
                              max: 500,
                              step: 50,
                              size: "mini",
                            },
                            on: { change: _vm.handleInputConfirm },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                $event.preventDefault()
                              },
                            },
                            model: {
                              value: _vm.transcription_settings.summary_length,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.transcription_settings,
                                  "summary_length",
                                  $$v
                                )
                              },
                              expression:
                                "transcription_settings.summary_length",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v("words"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transcription_settings?.summarization_enabled &&
                  _vm.transcription_settings?.call_transcription_enabled
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "no-border pb-2",
                          attrs: { prop: "summary_highlights" },
                        },
                        [
                          _c("span", {
                            staticClass: "page-anchor",
                            attrs: { id: "summary_highlights" },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              staticStyle: {
                                display: "flex",
                                gap: "16px",
                                "align-items": "stretch",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "highlights-container",
                                  class: {
                                    "col-md-6":
                                      _vm.transcription_settings
                                        .summary_prompt_list.length,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "form-label" }, [
                                    _c("h5", [
                                      _vm._v("Call Summary Highlights"),
                                    ]),
                                    _vm._v(" "),
                                    _c("small", [
                                      _vm._v(
                                        "Include key details in the call summary or specify your custom highlights."
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _c("i", [
                                        _vm._v(
                                          "Click an item to edit or drag and drop to reorder highlights."
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "draggable",
                                    {
                                      attrs: { handle: ".drag-handle" },
                                      on: { end: _vm.handleReorder },
                                      model: {
                                        value:
                                          _vm.transcription_settings
                                            .summary_prompt_list,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.transcription_settings,
                                            "summary_prompt_list",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "transcription_settings.summary_prompt_list",
                                      },
                                    },
                                    _vm._l(
                                      _vm.transcription_settings
                                        .summary_prompt_list,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: item.id || index,
                                            staticClass: "draggable-item",
                                          },
                                          [
                                            _c(
                                              "el-tag",
                                              {
                                                staticClass:
                                                  "highlight-tag font-weight-bold",
                                                attrs: {
                                                  type: "success",
                                                  closable: "",
                                                  "disable-transitions": false,
                                                },
                                                on: {
                                                  close: function ($event) {
                                                    return _vm.removeSummaryHighlight(
                                                      index
                                                    )
                                                  },
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.editSummaryHighlight(
                                                      item,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "drag-handle",
                                                    staticStyle: {
                                                      cursor: "grab",
                                                    },
                                                  },
                                                  [_vm._v("☰")]
                                                ),
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      item.label.length > 50
                                                        ? item.label.slice(
                                                            0,
                                                            50
                                                          ) + "..."
                                                        : item.label
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small" },
                                      on: {
                                        click: _vm.openSummaryHighlightModal,
                                      },
                                    },
                                    [_vm._v("+ Add Highlight")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.transcription_settings.summary_prompt_list
                                .length
                                ? _c(
                                    "div",
                                    { staticClass: "col-md-6 summary-preview" },
                                    [
                                      _c("div", { staticClass: "form-label" }, [
                                        _c("h5", [
                                          _vm._v("Call Summary Preview"),
                                        ]),
                                        _vm._v(" "),
                                        _c("small", [
                                          _vm._v(
                                            "Include these highlights in the call summary."
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "ai-effect-container mt-2",
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "ai-effect-gradient",
                                          }),
                                          _vm._v(" "),
                                          _c("div", {
                                            staticClass: "ai-effect-blur",
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "ai-effect-content p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex items-center justify-between",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex items-center gap-2",
                                                    },
                                                    [
                                                      _c(
                                                        "h3",
                                                        {
                                                          staticClass:
                                                            "ai-effect-gradient-text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                Powered by AloAi\n                                            "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-left-align",
                                                    },
                                                    [
                                                      _vm.transcription_settings
                                                        .summary_prompt_list
                                                        .length
                                                        ? _c("div", {
                                                            staticClass:
                                                              "call_summary",
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "inline-preview",
                                                        },
                                                        _vm._l(
                                                          _vm
                                                            .transcription_settings
                                                            .summary_prompt_list,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "inline-summary-item",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "font-size-sm font-weight-bold",
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "#000",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                        " +
                                                                        _vm._s(
                                                                          item.label
                                                                        ) +
                                                                        "\n                                                    "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c("br"),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "font-size-xs",
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "#000",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                        " +
                                                                        _vm._s(
                                                                          item.prompt
                                                                        ) +
                                                                        "\n                                                    "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-dialog",
                                  {
                                    attrs: {
                                      title: "Manage Call Summary Highlight",
                                      visible: _vm.highlight_dialog_visible,
                                      top: "10vh",
                                      width: "45%",
                                    },
                                    on: {
                                      "update:visible": function ($event) {
                                        _vm.highlight_dialog_visible = $event
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-steps",
                                      {
                                        staticClass: "mb-3",
                                        attrs: {
                                          "align-center": "",
                                          "finish-status": "success",
                                          active: _vm.current_step,
                                        },
                                      },
                                      [
                                        _c("el-step", {
                                          attrs: { title: "Select Method" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-step", {
                                          attrs: { title: "Choose Template" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-step", {
                                          attrs: {
                                            title: "Customize Highlight",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.current_step === 1
                                      ? [
                                          _c(
                                            "el-radio-group",
                                            {
                                              on: {
                                                change:
                                                  _vm.handleHighlightSelection,
                                              },
                                              model: {
                                                value:
                                                  _vm.selected_highlight_type,
                                                callback: function ($$v) {
                                                  _vm.selected_highlight_type =
                                                    $$v
                                                },
                                                expression:
                                                  "selected_highlight_type",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "row row-filter highlight-options",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-md-6" },
                                                    [
                                                      _c(
                                                        "el-card",
                                                        {
                                                          staticClass:
                                                            "cursor-pointer equal-height-card",
                                                          attrs: {
                                                            shadow: "hover",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-radio",
                                                            {
                                                              staticClass:
                                                                "w-100",
                                                              attrs: {
                                                                label:
                                                                  "scratch",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-items-center mb-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "i",
                                                                    {
                                                                      staticClass:
                                                                        "text-2x mr-2 material-icons text-green-500",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "create"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "text-lg font-weight-bold text-grey-9",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Create from scratch"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "text-md l-h",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                            Begin with a blank template to design a fully personalized highlight.\n                                                        "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-md-6" },
                                                    [
                                                      _c(
                                                        "el-card",
                                                        {
                                                          staticClass:
                                                            "cursor-pointer equal-height-card",
                                                          attrs: {
                                                            shadow: "hover",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-radio",
                                                            {
                                                              staticClass:
                                                                "w-100",
                                                              attrs: {
                                                                label:
                                                                  "template",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-items-center mb-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "i",
                                                                    {
                                                                      staticClass:
                                                                        "text-2x mr-2 material-icons text-blue-500",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "layers"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "text-lg font-weight-bold text-grey-9",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Use a template"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "text-md l-h",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                            Begin with a predefined template and personalize it to match your requirements.\n                                                        "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.current_step === 2
                                      ? [
                                          _c(
                                            "el-form-item",
                                            { staticClass: "pb-2" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-label" },
                                                [
                                                  _c("h5", [
                                                    _vm._v(
                                                      "Predefined Highlight"
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("small", [
                                                    _vm._v(
                                                      "Select a predefined highlight to populate the label and prompt."
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass: "w-full",
                                                  attrs: {
                                                    placeholder:
                                                      "Select a predefined highlight",
                                                    filterable: "",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.applyPredefinedPrompt,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.selected_highlight,
                                                    callback: function ($$v) {
                                                      _vm.selected_highlight =
                                                        $$v
                                                    },
                                                    expression:
                                                      "selected_highlight",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.summary_highlight_options,
                                                  function (option) {
                                                    return _c("el-option", {
                                                      key: option.label,
                                                      attrs: {
                                                        label: option.label,
                                                        value: option,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.current_step === 3
                                      ? [
                                          _vm.editing_summary_highlight
                                            ? _c(
                                                "el-form",
                                                {
                                                  staticClass: "no-border p-0",
                                                },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    { staticClass: "p-2" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-label",
                                                        },
                                                        [
                                                          _c("h5", [
                                                            _vm._v("Label"),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("small", [
                                                            _vm._v(
                                                              "Enter or edit the label for this highlight."
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("el-input", {
                                                        attrs: {
                                                          placeholder:
                                                            "Enter the label for this highlight",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .editing_summary_highlight
                                                              .label,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.editing_summary_highlight,
                                                              "label",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "editing_summary_highlight.label",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-form-item",
                                                    { staticClass: "p-2" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-label",
                                                        },
                                                        [
                                                          _c("h5", [
                                                            _vm._v("Prompt"),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("small", [
                                                            _vm._v(
                                                              "Please provide clear and detailed instructions to guide the AI effectively."
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("el-input", {
                                                        attrs: {
                                                          type: "textarea",
                                                          rows: "4",
                                                          maxlength: "3000",
                                                          placeholder:
                                                            "Enter the details of your highlight prompt",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .editing_summary_highlight
                                                              .prompt,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.editing_summary_highlight,
                                                              "prompt",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "editing_summary_highlight.prompt",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-form-item",
                                                    { staticClass: "p-2" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "no-border form-label",
                                                        },
                                                        [
                                                          _c("h5", [
                                                            _vm._v("Order"),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("small", [
                                                            _vm._v(
                                                              "Set the priority order for this highlight."
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("el-input-number", {
                                                        attrs: {
                                                          min: 1,
                                                          max:
                                                            _vm
                                                              .transcription_settings
                                                              .summary_prompt_list
                                                              .length + 1,
                                                          size: "mini",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .editing_summary_highlight
                                                              .order,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.editing_summary_highlight,
                                                              "order",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "editing_summary_highlight.order",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "footer" },
                                        slot: "footer",
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            on: { click: _vm.closeStepDialog },
                                          },
                                          [_vm._v("Cancel")]
                                        ),
                                        _vm._v(" "),
                                        _vm.current_step === 3
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: { type: "primary" },
                                                on: {
                                                  click:
                                                    _vm.saveSummaryHighlight,
                                                },
                                              },
                                              [_vm._v("Save")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transcription_settings?.summarization_enabled &&
                  _vm.transcription_settings?.call_transcription_enabled
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "pb-1",
                          attrs: { prop: "sync_summaries_to_notes" },
                        },
                        [
                          _c("span", {
                            staticClass: "page-anchor",
                            attrs: { id: "sync_summaries_to_notes" },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-label" }, [
                            _c("h5", [
                              _vm._v(
                                "Sync summaries to notes (CRM activity sync)"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("small", [
                              _vm._v(
                                "Include call summaries in the notes during the CRM activity syncing to provide a quick overview of the conversation."
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "switch-label" },
                            [
                              _c("el-switch", {
                                attrs: { "active-color": "#00a344" },
                                on: { change: _vm.updateSetting },
                                model: {
                                  value:
                                    _vm.transcription_settings
                                      .sync_summaries_to_notes,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.transcription_settings,
                                      "sync_summaries_to_notes",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "transcription_settings.sync_summaries_to_notes",
                                },
                              }),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v("Enable summary sync with notes"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transcription_settings.call_transcription_enabled
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "pb-1",
                          attrs: { prop: "overusage_restriction_enabled" },
                        },
                        [
                          _c("span", {
                            staticClass: "page-anchor",
                            attrs: { id: "overusage_restriction_enabled" },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-label" }, [
                            _c("h5", [_vm._v("Overusage Billing Restriction")]),
                            _vm._v(" "),
                            _c("small", [
                              _vm._v(
                                "\n                            Automatically turn off the call transcription feature once your account exceeds the allotted minutes of AloAi Voice Analytics included in your plan. This ensures you\n                            won't\n                            incur additional charges of " +
                                  _vm._s(
                                    _vm._f("toCurrency")(
                                      _vm._f("fixRounding")(
                                        _vm.transcriptionRate,
                                        2
                                      )
                                    )
                                  ) +
                                  " per minute for additional transcription minutes.\n                        "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "switch-label" },
                            [
                              _vm.transcription_settings.is_trial
                                ? _c("el-tooltip", {
                                    attrs: {
                                      content:
                                        "Contact support to convert and start using AloAi Voice Analytics on your account",
                                      placement: "top-start",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function () {
                                            return [
                                              _c("el-switch", {
                                                attrs: {
                                                  "active-color": "#00a344",
                                                  disabled:
                                                    _vm.transcription_settings
                                                      .is_trial,
                                                },
                                                on: {
                                                  change: _vm.updateSetting,
                                                },
                                                model: {
                                                  value:
                                                    _vm.transcription_settings
                                                      .overusage_restriction_enabled,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.transcription_settings,
                                                      "overusage_restriction_enabled",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "transcription_settings.overusage_restriction_enabled",
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3878628317
                                    ),
                                  })
                                : _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      disabled:
                                        _vm.transcription_settings.is_trial,
                                    },
                                    on: { change: _vm.updateSetting },
                                    model: {
                                      value:
                                        _vm.transcription_settings
                                          .overusage_restriction_enabled,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.transcription_settings,
                                          "overusage_restriction_enabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "transcription_settings.overusage_restriction_enabled",
                                    },
                                  }),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v(
                                  "\n                            Turn off call transcriptions automatically when monthly plan limit is reached\n                        "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transcription_settings.call_transcription_enabled
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "pb-1",
                          attrs: { prop: "minimum_talk_time" },
                        },
                        [
                          _c("span", {
                            staticClass: "page-anchor",
                            attrs: { id: "minimum_talk_time" },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-label" }, [
                            _c("h5", [_vm._v("Minimum Talk-Time")]),
                            _vm._v(" "),
                            _c("small", [
                              _vm._v(
                                "\n                            Calls will only be transcribed when talk time is greater than this limit.\n                        "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-input-number", {
                            attrs: {
                              min: 45,
                              max: _vm.max_talk_time,
                              step: 15,
                              size: "mini",
                            },
                            on: { change: _vm.handleInputConfirm },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                $event.preventDefault()
                              },
                            },
                            model: {
                              value:
                                _vm.transcription_settings.minimum_talk_time,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.transcription_settings,
                                  "minimum_talk_time",
                                  $$v
                                )
                              },
                              expression:
                                "transcription_settings.minimum_talk_time",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v("Seconds"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transcription_settings.call_transcription_enabled
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "pb-1",
                          attrs: { prop: "custom_keywords" },
                        },
                        [
                          _c("span", {
                            staticClass: "page-anchor",
                            attrs: { id: "custom_keywords" },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-label" }, [
                            _c("h5", [_vm._v("Custom Keywords")]),
                            _vm._v(" "),
                            _c("small", [
                              _vm._v(
                                "\n                            Custom Keywords will be counted in AloAi Voice Analytics panel.\n                        "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.transcription_settings.custom_keywords,
                            function (custom_keyword) {
                              return _c(
                                "el-tag",
                                {
                                  key: custom_keyword,
                                  staticClass: "m-1",
                                  staticStyle: {
                                    "font-size": "15px!important",
                                  },
                                  attrs: {
                                    closable: "",
                                    type: "warning",
                                    "disable-transitions": false,
                                  },
                                  on: {
                                    close: function ($event) {
                                      return _vm.handleKeywordsClose(
                                        custom_keyword
                                      )
                                    },
                                  },
                                },
                                [_c("b", [_vm._v(_vm._s(custom_keyword))])]
                              )
                            }
                          ),
                          _vm._v(" "),
                          _vm.keyword_input_visible
                            ? _c("el-input", {
                                ref: "saveKeywordInput",
                                staticStyle: { width: "120px" },
                                attrs: { size: "medium" },
                                on: { blur: _vm.handleInputConfirm },
                                nativeOn: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                    return _vm.handleInputConfirm.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.custom_keyword_value,
                                  callback: function ($$v) {
                                    _vm.custom_keyword_value = $$v
                                  },
                                  expression: "custom_keyword_value",
                                },
                              })
                            : _c(
                                "el-button",
                                {
                                  attrs: { size: "small" },
                                  on: { click: _vm.showKeywordInput },
                                },
                                [
                                  _vm._v(
                                    "\n                        + New Keyword\n                    "
                                  ),
                                ]
                              ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transcription_settings.call_transcription_enabled
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "pb-1",
                          attrs: { prop: "custom_dictionary" },
                        },
                        [
                          _c("span", {
                            staticClass: "page-anchor",
                            attrs: { id: "custom_dictionary" },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-label" }, [
                            _c("h5", [_vm._v("Custom Vocabulary")]),
                            _vm._v(" "),
                            _c("small", [
                              _vm._v(
                                "\n                            Add words specific to your use case (industry terms, technical terms, names, etc.) when transcribing calls in order to increase accuracy for those custom\n                            vocabulary terms.\n                        "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.transcription_settings.custom_dictionary,
                            function (dictionary_word) {
                              return _c(
                                "el-tag",
                                {
                                  key: dictionary_word,
                                  staticClass: "m-1",
                                  staticStyle: { "font-size": "15px" },
                                  attrs: {
                                    closable: "",
                                    "disable-transitions": false,
                                  },
                                  on: {
                                    close: function ($event) {
                                      return _vm.handleDictionaryClose(
                                        dictionary_word
                                      )
                                    },
                                  },
                                },
                                [_c("b", [_vm._v(_vm._s(dictionary_word))])]
                              )
                            }
                          ),
                          _vm._v(" "),
                          _vm.dictionary_input_visible
                            ? _c("el-input", {
                                ref: "saveDictionaryWordInput",
                                staticStyle: { width: "120px" },
                                attrs: { size: "medium" },
                                on: { blur: _vm.handleInputConfirm },
                                nativeOn: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                    return _vm.handleInputConfirm.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.dictionary_word_value,
                                  callback: function ($$v) {
                                    _vm.dictionary_word_value = $$v
                                  },
                                  expression: "dictionary_word_value",
                                },
                              })
                            : _c(
                                "el-button",
                                {
                                  attrs: { size: "small" },
                                  on: { click: _vm.showDictionaryInput },
                                },
                                [
                                  _vm._v(
                                    "\n                        + New Word\n                    "
                                  ),
                                ]
                              ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transcription_settings.call_transcription_enabled
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "pb-1",
                          attrs: { prop: "custom_spelling" },
                        },
                        [
                          _c("span", {
                            staticClass: "page-anchor",
                            attrs: { id: "custom_spelling" },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-label" }, [
                            _c("h5", [_vm._v("Custom Spelling")]),
                            _vm._v(" "),
                            _c("small", [
                              _vm._v(
                                "\n                            Custom Spelling lets you customize how words are spelled or formatted in the transcriptions.\n                        "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _vm._l(
                                _vm.transcription_settings.custom_spelling,
                                function (spelling, index) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: index,
                                      staticClass: "m-1",
                                      staticStyle: {
                                        "font-size": "15px",
                                        cursor: "pointer",
                                      },
                                      attrs: {
                                        type: "success",
                                        closable: "",
                                        "disable-transitions": false,
                                      },
                                      on: {
                                        close: function ($event) {
                                          return _vm.removeSpelling(index)
                                        },
                                        click: function ($event) {
                                          return _vm.editSpelling(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(spelling.from.join(", ")) +
                                            " → " +
                                            _vm._s(spelling.to)
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      _vm.show_spelling_modal = true
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            + Add Spelling\n                        "
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transcription_settings.call_transcription_enabled
                    ? _c(
                        "el-dialog",
                        {
                          staticClass: "dialog-padding auto",
                          attrs: {
                            width: "30%",
                            center: "",
                            visible: _vm.show_spelling_modal,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.show_spelling_modal = $event
                            },
                            close: _vm.resetSpellingForm,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v("Add Custom Spelling"),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "el-tooltip",
                                          {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "content",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        "\n                                    Whenever "
                                                      ),
                                                      _c("strong", [
                                                        _vm._v("From"),
                                                      ]),
                                                      _vm._v(
                                                        " appears in the transcription, it will be replaced with "
                                                      ),
                                                      _c("strong", [
                                                        _vm._v("To"),
                                                      ]),
                                                      _vm._v(
                                                        ".\n                                "
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              1138066545
                                            ),
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("i", {
                                              staticClass: "el-icon-info ml-2",
                                              staticStyle: {
                                                cursor: "pointer",
                                                "font-size": "16px",
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3615171378
                          ),
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              staticClass: "pb-0",
                              staticStyle: { "margin-top": "-10px" },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "no-border p-0",
                                  attrs: { prop: "custom_spelling_from" },
                                },
                                [
                                  _c("div", { staticClass: "form-label" }, [
                                    _c("h5", [
                                      _vm._v("From (Words to Replace)"),
                                    ]),
                                    _vm._v(" "),
                                    _c("small", [
                                      _vm._v(
                                        "Enter the words to be replaced, one at a time."
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.spelling_input.from,
                                    function (word, index) {
                                      return _c(
                                        "el-tag",
                                        {
                                          key: index,
                                          staticClass: "m-1",
                                          staticStyle: { "font-size": "15px" },
                                          attrs: {
                                            type: "success",
                                            closable: "",
                                            "disable-transitions": false,
                                          },
                                          on: {
                                            close: function ($event) {
                                              return _vm.removeWord(index)
                                            },
                                          },
                                        },
                                        [_c("b", [_vm._v(_vm._s(word))])]
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _vm.from_word_input_visible
                                    ? _c("el-input", {
                                        ref: "fromWordInput",
                                        staticStyle: { width: "120px" },
                                        attrs: { size: "medium" },
                                        on: { blur: _vm.addFromWord },
                                        nativeOn: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            $event.preventDefault()
                                            return _vm.addFromWord.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.current_from_word,
                                          callback: function ($$v) {
                                            _vm.current_from_word = $$v
                                          },
                                          expression: "current_from_word",
                                        },
                                      })
                                    : _c(
                                        "el-button",
                                        {
                                          attrs: { size: "small" },
                                          on: { click: _vm.showFromWordInput },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                + New Word\n                            "
                                          ),
                                        ]
                                      ),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "no-border p-0",
                                  attrs: { prop: "custom_spelling_to" },
                                },
                                [
                                  _c("div", { staticClass: "form-label" }, [
                                    _c("h5", [_vm._v("To (Replacement Word)")]),
                                    _vm._v(" "),
                                    _c("small", [
                                      _vm._v(
                                        "Provide the word or phrase that will replace the specified input words."
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _vm.spelling_input.to
                                    ? _c(
                                        "el-tag",
                                        {
                                          staticClass: "m-1",
                                          staticStyle: { "font-size": "15px" },
                                          attrs: {
                                            type: "success",
                                            closable: "",
                                            "disable-transitions": false,
                                          },
                                          on: { close: _vm.removeToWord },
                                        },
                                        [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(_vm.spelling_input.to)
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.spelling_input.to ||
                                  _vm.to_word_input_visible
                                    ? _c("el-input", {
                                        ref: "toWordInput",
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          size: "medium",
                                          placeholder: "Enter replacement word",
                                        },
                                        on: { blur: _vm.addToWord },
                                        nativeOn: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            $event.preventDefault()
                                            return _vm.addToWord.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.current_to_word,
                                          callback: function ($$v) {
                                            _vm.current_to_word = $$v
                                          },
                                          expression: "current_to_word",
                                        },
                                      })
                                    : _c(
                                        "el-button",
                                        {
                                          attrs: { size: "small" },
                                          on: { click: _vm.showToWordInput },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Edit Word\n                            "
                                          ),
                                        ]
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v("The value in the "),
                            _c("strong", [_vm._v("To")]),
                            _vm._v(
                              " key is case-sensitive, while the values in the "
                            ),
                            _c("strong", [_vm._v("From")]),
                            _vm._v(" key is not."),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex justify-content-end p-0",
                              attrs: { slot: "footer" },
                              slot: "footer",
                            },
                            [
                              _c(
                                "el-button",
                                { on: { click: _vm.resetSpellingForm } },
                                [_vm._v("Cancel")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.addCustomSpelling },
                                },
                                [
                                  _vm._v(
                                    "\n                            Save\n                        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transcription_settings.call_transcription_enabled
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "pb-1",
                          attrs: { prop: "neutral_sentiment_messages" },
                        },
                        [
                          _c("span", {
                            staticClass: "page-anchor",
                            attrs: { id: "neutral_sentiment_messages" },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-label" }, [
                            _c("h5", [
                              _vm._v(
                                "Automatic Messages Sentiment Configuration"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("small", [
                              _vm._v(
                                "This setting allows administrators to mark specific messages as neutral, ensuring they do not impact overall sentiment scores."
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _vm._l(
                                _vm.transcription_settings
                                  .neutral_sentiment_messages,
                                function (message, index) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: index,
                                      staticClass: "neutral-sentiment-tag m-1",
                                      staticStyle: {
                                        "font-size": "15px",
                                        cursor: "pointer",
                                      },
                                      attrs: {
                                        closable: "",
                                        "disable-transitions": false,
                                      },
                                      on: {
                                        close: function ($event) {
                                          return _vm.removeNeutralMessage(index)
                                        },
                                        click: function ($event) {
                                          return _vm.editNeutralMessage(index)
                                        },
                                      },
                                    },
                                    [
                                      message.length > 100
                                        ? [
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item pull_left",
                                                attrs: {
                                                  placement: "bottom",
                                                  effect: "dark",
                                                  content: message,
                                                  "popper-style": {
                                                    maxWidth: "300px",
                                                    whiteSpace: "normal",
                                                    overflowWrap: "break-word",
                                                    wordBreak: "break-word",
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      message.slice(0, 100) +
                                                        "..."
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        : [
                                            _c("span", [
                                              _vm._v(_vm._s(message)),
                                            ]),
                                          ],
                                    ],
                                    2
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openNeutralDialog()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            + Add Message\n                        "
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-dialog",
                    {
                      staticClass: "p-0",
                      attrs: {
                        title: "Configure Neutral Sentiment Message",
                        width: "40%",
                        visible: _vm.neutral_dialog_visible,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.neutral_dialog_visible = $event
                        },
                        close: _vm.resetNeutralMessageDialog,
                      },
                    },
                    [
                      _c("p", { staticClass: "neutral-message-help-text" }, [
                        _vm._v(
                          "\n                        If this exact phrase appears in the transcription, that section will be marked as neutral in the sentiment analysis.\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form",
                        [
                          _c(
                            "el-form-item",
                            { staticClass: "no-border p-0 w-100" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: "6",
                                  maxlength: "3000",
                                  "show-word-limit": "",
                                  placeholder:
                                    "Enter Message with Neutral Sentiment",
                                },
                                model: {
                                  value: _vm.current_neutral_message,
                                  callback: function ($$v) {
                                    _vm.current_neutral_message = $$v
                                  },
                                  expression: "current_neutral_message",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-content-end p-0",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "el-button",
                            { on: { click: _vm.resetNeutralMessageDialog } },
                            [_vm._v("Cancel")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.saveNeutralMessage },
                            },
                            [_vm._v("Save")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.transcription_settings?.call_transcription_enabled
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "pb-1",
                          class: {
                            "no-border":
                              _vm.transcription_settings
                                .daily_digest_report_enabled,
                          },
                          attrs: { prop: "daily_digest_report_enabled" },
                        },
                        [
                          _c("span", {
                            staticClass: "page-anchor",
                            attrs: { id: "daily_digest_report_enabled" },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-label" }, [
                            _c("h5", [_vm._v("Daily Digest Report")]),
                            _vm._v(" "),
                            _c("small", [
                              _vm._v(
                                "\n                            Enable daily digest reports to receive comprehensive summaries of your call activities and insights. To assign roles for receiving these reports, configure the settings in "
                              ),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "text-dark-greenish el-green-hover font-weight-bold",
                                  attrs: {
                                    href: `/account?tab=reporting-settings#set_reports_recipients`,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("System Reports Recipients")]
                              ),
                              _vm._v(".\n                        "),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "switch-label" },
                            [
                              _c("el-switch", {
                                attrs: { "active-color": "#00a344" },
                                on: { change: _vm.updateSetting },
                                model: {
                                  value:
                                    _vm.transcription_settings
                                      .daily_digest_report_enabled,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.transcription_settings,
                                      "daily_digest_report_enabled",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "transcription_settings.daily_digest_report_enabled",
                                },
                              }),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v("Enable daily digest report"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transcription_settings.call_transcription_enabled &&
                  _vm.transcription_settings.daily_digest_report_enabled
                    ? _c("daily-digest-report", {
                        attrs: {
                          templates: _vm.digest_report_templates,
                          company_clone: _vm.company_clone,
                        },
                        on: { "update-setting": _vm.updateSetting },
                        model: {
                          value:
                            _vm.transcription_settings
                              .daily_digest_report_prompt_list,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.transcription_settings,
                              "daily_digest_report_prompt_list",
                              $$v
                            )
                          },
                          expression:
                            "transcription_settings.daily_digest_report_prompt_list",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transcription_settings.call_transcription_enabled
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "pb-1",
                          attrs: { prop: "automatic_redaction_enabled" },
                        },
                        [
                          _c("span", {
                            staticClass: "page-anchor",
                            attrs: { id: "automatic_redaction_enabled" },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-label" }, [
                            _c("h5", [_vm._v("PII Redaction")]),
                            _vm._v(" "),
                            _c("small", [
                              _vm._v(
                                "\n                            This feature minimizes sensitive information about individuals by automatically identifying and removing it from call transcripts.\n                        "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "switch-label" },
                            [
                              _vm.transcription_settings.is_trial
                                ? _c("el-tooltip", {
                                    attrs: {
                                      content:
                                        "Contact support to convert and start using AloAi Voice Analytics on your account",
                                      placement: "top-start",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function () {
                                            return [
                                              _c("el-switch", {
                                                attrs: {
                                                  "active-color": "#00a344",
                                                  disabled:
                                                    _vm.transcription_settings
                                                      .is_trial,
                                                },
                                                on: {
                                                  change: _vm.updateSetting,
                                                },
                                                model: {
                                                  value:
                                                    _vm.transcription_settings
                                                      .automatic_redaction_enabled,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.transcription_settings,
                                                      "automatic_redaction_enabled",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "transcription_settings.automatic_redaction_enabled",
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3311429170
                                    ),
                                  })
                                : _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      disabled:
                                        _vm.transcription_settings.is_trial,
                                    },
                                    on: { change: _vm.updateSetting },
                                    model: {
                                      value:
                                        _vm.transcription_settings
                                          .automatic_redaction_enabled,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.transcription_settings,
                                          "automatic_redaction_enabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "transcription_settings.automatic_redaction_enabled",
                                    },
                                  }),
                              _vm._v(" "),
                              _c("label", [_vm._v("Enable PII redaction")]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "el-alert el-alert--info is-light mt-3",
                              attrs: { role: "alert" },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-alert__icon el-icon-info is-big",
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "el-alert__content" }, [
                                _c(
                                  "p",
                                  { staticClass: "el-alert__description" },
                                  [
                                    _vm._v(
                                      "\n                                Personal Identifiable Information (PII) is any information that can be used to identify a person, such as a name, email address, or phone number.\n                                "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                                When you enable the feature, your call transcripts will look like this:\n                                "
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("ol", { staticClass: "mb-0" }, [
                                      _c("li", [
                                        _vm._v("Hi, my name is [PERSON_NAME]!"),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  false
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "no-border pb-1",
                          attrs: { prop: "content_moderation_enabled" },
                        },
                        [
                          _c("span", {
                            staticClass: "page-anchor",
                            attrs: { id: "content_moderation_enabled" },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-label" }, [
                            _c("h5", [_vm._v("Sensitive Content Moderation")]),
                            _vm._v(" "),
                            _c("small", [
                              _vm._v(
                                "\n                            By enabling this feature, any conversations with sensitive\n                            issues like drugs, racism and other inappropriate subjects will be\n                            filtered and flagged automatically.\n                        "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "switch-label" },
                            [
                              _vm.transcription_settings.is_trial
                                ? _c("el-tooltip", {
                                    attrs: {
                                      content:
                                        "Contact support to convert and start using AloAi Voice Analytics on your account",
                                      placement: "top-start",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function () {
                                            return [
                                              _c("el-switch", {
                                                attrs: {
                                                  "active-color": "#00a344",
                                                  disabled:
                                                    _vm.transcription_settings
                                                      .is_trial,
                                                },
                                                on: {
                                                  change: _vm.updateSetting,
                                                },
                                                model: {
                                                  value:
                                                    _vm.transcription_settings
                                                      .content_moderation_enabled,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.transcription_settings,
                                                      "content_moderation_enabled",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "transcription_settings.content_moderation_enabled",
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1900471303
                                    ),
                                  })
                                : _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      disabled:
                                        _vm.transcription_settings.is_trial,
                                    },
                                    on: { change: _vm.updateSetting },
                                    model: {
                                      value:
                                        _vm.transcription_settings
                                          .content_moderation_enabled,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.transcription_settings,
                                          "content_moderation_enabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "transcription_settings.content_moderation_enabled",
                                    },
                                  }),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v("Enable sensitive content moderation"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-2 d-none d-md-block ml-auto" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "list-group list-group-alo list-group-flush sticky-top",
                  },
                  [
                    _c("h6", { staticClass: "list-group-item" }, [
                      _vm._v("On This Page"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "list-group-item list-group-item-action",
                        attrs: { href: "#call_transcription_enabled" },
                        on: {
                          click: function ($event) {
                            return _vm.shine("call_transcription_enabled")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Call Transcription\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.transcription_settings.call_transcription_enabled
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "list-group-item list-group-item-action",
                            attrs: { href: "#on_demand_enabled" },
                            on: {
                              click: function ($event) {
                                return _vm.shine("on_demand_enabled")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        On-Demand Transcription and Summarization\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.transcription_settings.call_transcription_enabled
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "list-group-item list-group-item-action",
                            attrs: { href: "#summarization_enabled" },
                            on: {
                              click: function ($event) {
                                return _vm.shine("summarization_enabled")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Summarization Settings\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.transcription_settings.summarization_enabled &&
                    _vm.transcription_settings.call_transcription_enabled
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "list-group-item list-group-item-action",
                            attrs: { href: "#summary_length" },
                            on: {
                              click: function ($event) {
                                return _vm.shine("summary_length")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Call Summary Length\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.transcription_settings.summarization_enabled &&
                    _vm.transcription_settings.call_transcription_enabled
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "list-group-item list-group-item-action",
                            attrs: { href: "#summary_highlights" },
                            on: {
                              click: function ($event) {
                                return _vm.shine("summary_highlights")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Call Summary Highlights\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.transcription_settings.summarization_enabled &&
                    _vm.transcription_settings.call_transcription_enabled
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "list-group-item list-group-item-action",
                            attrs: { href: "#sync_summaries_to_notes" },
                            on: {
                              click: function ($event) {
                                return _vm.shine("sync_summaries_to_notes")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Sync summaries to notes (CRM activity sync)\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.transcription_settings.call_transcription_enabled
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "list-group-item list-group-item-action",
                            attrs: { href: "#overusage_restriction_enabled" },
                            on: {
                              click: function ($event) {
                                return _vm.shine(
                                  "overusage_restriction_enabled"
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Overusage Billing Restriction\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.transcription_settings.call_transcription_enabled
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "list-group-item list-group-item-action",
                            attrs: { href: "#minimum_talk_time" },
                            on: {
                              click: function ($event) {
                                return _vm.shine("minimum_talk_time")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Minimum Talk-Time\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.transcription_settings.call_transcription_enabled
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "list-group-item list-group-item-action",
                            attrs: { href: "#min_max_communication_duration" },
                            on: {
                              click: function ($event) {
                                return _vm.shine(
                                  "min_max_communication_duration"
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Sync transcriptions with Zoho CRM\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.transcription_settings.call_transcription_enabled
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "list-group-item list-group-item-action",
                            attrs: { href: "#custom_keywords" },
                            on: {
                              click: function ($event) {
                                return _vm.shine("custom_keywords")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Custom Keywords\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.transcription_settings.call_transcription_enabled
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "list-group-item list-group-item-action",
                            attrs: { href: "#custom_dictionary" },
                            on: {
                              click: function ($event) {
                                return _vm.shine("custom_dictionary")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Custom Vocabulary\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.transcription_settings.call_transcription_enabled
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "list-group-item list-group-item-action",
                            attrs: { href: "#custom_spelling" },
                            on: {
                              click: function ($event) {
                                return _vm.shine("custom_spelling")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Custom Spelling\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.transcription_settings.call_transcription_enabled
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "list-group-item list-group-item-action",
                            attrs: { href: "#neutral_sentiment_messages" },
                            on: {
                              click: function ($event) {
                                return _vm.shine("neutral_sentiment_messages")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Automatic Messages Sentiment Configuration\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.transcription_settings.call_transcription_enabled
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "list-group-item list-group-item-action",
                            attrs: { href: "#daily_digest_report_enabled" },
                            on: {
                              click: function ($event) {
                                return _vm.shine("daily_digest_report_enabled")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Daily Digest Report\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.transcription_settings.call_transcription_enabled &&
                    _vm.transcription_settings.daily_digest_report_enabled
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "list-group-item list-group-item-action",
                            attrs: { href: "#daily_digest_report_prompt_list" },
                            on: {
                              click: function ($event) {
                                return _vm.shine(
                                  "daily_digest_report_prompt_list"
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Daily Digest Report Sections\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.transcription_settings.call_transcription_enabled
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "list-group-item list-group-item-action",
                            attrs: { href: "#automatic_redaction_enabled" },
                            on: {
                              click: function ($event) {
                                return _vm.shine("automatic_redaction_enabled")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        PII Redaction\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    false
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "list-group-item list-group-item-action",
                            attrs: { href: "#content_moderation_enabled" },
                            on: {
                              click: function ($event) {
                                return _vm.shine("content_moderation_enabled")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Sensitive Content Moderation\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "Manage User Transcription Settings",
                    visible: _vm.users_enablement.display_dialog,
                    width: "40%",
                  },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(
                        _vm.users_enablement,
                        "display_dialog",
                        $event
                      )
                    },
                    close: _vm.closeUserTranscriptionManagementDialog,
                  },
                },
                [_c("account-transcription-user-enablement")],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }