<template>
    <div class="px-3 pb-3">
        <div slot="title"
            class="px-3 pt-3 pb-0">
            <h5 class="mb-0">
                Credit Usage Charges
            </h5>
            <p class="_300 mb-0">
                {{ date_range_legend }}        
            </p>
        </div>
        <div v-if="visibleResources.length > 0"
            class="padding">
            <div class="row box">
                <div class="col-12">
                    <div class="no-effect">
                        <div class="box-header pb-0">
                            <div class="row">
                                <div class="col-3">
                                    <el-input ref="resourceSearch"
                                                placeholder="Search a resource"
                                                prefix-icon="el-icon-search"
                                                clearable
                                                autofocus
                                                v-model="search_text">
                                    </el-input>
                                </div>
                            </div>
                        </div>
                        <div class="box-body pt-0">
                            <el-table
                                :data="visibleResources"
                                :default-sort="{ prop: 'name', order: 'ascending' }"
                                class="w-full"
                                fit
                                stripe>
                                <el-table-column
                                    prop="name"
                                    label="Resource"
                                    :min-width="200"
                                    sortable >
                                    <template v-slot="scope">
                                        <i class="fa fa-circle mr-2" :style="'color: ' + scope.row.color"></i>{{ scope.row.name }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="total_usage"
                                    label="Total Usage"
                                    :min-width="100" >
                                    <template v-slot="scope">
                                        {{ scope.row.total_quantity | numFormat }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="free_usage"
                                    label="Free Usage"
                                    :min-width="100" >
                                    <template v-slot:header>
                                        <span>Free Usage</span>
                                        <el-popover
                                            placement="top"
                                            width="auto"
                                            trigger="hover">
                                            Included in your plan
                                            <i
                                                class="ml-1 fas fa-info-circle"
                                                slot="reference">
                                            </i>
                                        </el-popover>
                                    </template>
                                    <template v-slot="scope">
                                        {{ scope.row.total_included | numFormat }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="paid_usage"
                                    label="Paid Usage"
                                    :min-width="100" >
                                    <template v-slot:header>
                                        <span>Paid Usage</span>
                                        <el-popover
                                            placement="top"
                                            width="auto"
                                            trigger="hover">
                                            Overage above your plan
                                            <i
                                                class="ml-1 fas fa-info-circle"
                                                slot="reference">
                                            </i>
                                        </el-popover>
                                    </template>
                                    <template v-slot="scope">
                                        {{ scope.row.total_paid | numFormat }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="rate"
                                    label="Rate"
                                    :min-width="100" >
                                    <template v-slot:header>
                                        <span>Rate</span>
                                        <el-popover
                                            placement="top"
                                            width="auto"
                                            trigger="hover">
                                            Cost per unit of paid usage
                                            <i
                                                class="ml-1 fas fa-info-circle"
                                                slot="reference">
                                            </i>
                                        </el-popover>
                                    </template>
                                    <template v-slot="scope">
                                        {{ getFormatedAmount(scope.row.rate) }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="cost"
                                    label="Cost"
                                    :min-width="100" >
                                    <template v-slot:header>
                                        <span>Cost</span>
                                        <el-popover
                                            placement="top"
                                            width="auto"
                                            trigger="hover">
                                            Cost for paid usage
                                            <i
                                                class="ml-1 fas fa-info-circle"
                                                slot="reference">
                                            </i>
                                        </el-popover>
                                    </template>
                                    <template v-slot="scope">
                                        {{ getFormatedAmount(scope.row.total_credit) }}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body d-flex py-3 px-3">
                    <h4 class="mb-0">Total</h4>
                    <h4 class="mb-0 ml-auto text-dark">{{ getFormatedAmount(summarizeTotalOfVisibleResources) }}</h4>
                </div>
            </div>
        </div>
        <div 
            v-else
            class="w-100">
            <h3 class="text-center">No results.</h3>
        </div>
    </div>
</template>

<script>
import ImgEmpty from "../../../misc/img-empty"

export default {
    name: "credited-usage-summary",

    components: {ImgEmpty},

    props: {
        date_range_legend: {
            required: true
        },
        usages: {
            required: true
        },
    },

    data() {
        return {
            is_visible: false,
            search_text: '',
            search_fields: ['name'],
        }
    },

    computed: {

        summarizeTotalOfVisibleResources() {
            let total = 0
            for (let resource of this.visibleResources) {
                total += resource.total_credit
            }
            return total
        },

        visibleResources() {
            const resource_usage = _.filter(this.usages, (usage, key) => {
                return key !== 'total'
            })

            if (resource_usage.length > 0) {
                if (this.search_text != "") {
                    let filtered = []
                    for (let resource of resource_usage) {
                        let keys = this.search_fields
                        for (let key of keys) {
                            if (key.indexOf('.') !== -1) {
                                let mixed = key.split(".")
                                if (resource[mixed[0]]) {
                                    if (resource[mixed[0]][mixed[1]].toString().toLowerCase().indexOf(this.search_text.toString().toLowerCase()) !== -1) {
                                        filtered.push(resource)
                                        break
                                    }
                                }
                            } else if (resource[key]) {
                                if (resource[key].toString().toLowerCase().indexOf(this.search_text.toString().toLowerCase()) !== -1) {
                                    filtered.push(resource)
                                    break
                                }
                            }
                        }
                    }
                    return filtered
                } else {
                    return resource_usage
                }
            }
            return []
        },
    },

    methods: {
        getFormatedAmount (amount) {
            if (amount === null) {
                return '-'
            }
            amount = this.$options.filters.fixRounding(amount)
            return this.$options.filters.toCurrency(amount)
        },
        show() {
            this.is_visible = true
        },

        closeDialog() {
            this.is_visible = false
        }
    },
}
</script>
