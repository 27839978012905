<template>
    <el-form
        :model="report_setting"
        :rules="rules"
        class="form-aloware"
        ref="companySettingsForm"
        @submit.prevent.native="submitForm('companySettingsForm')">
        <div class="row">
            <div class="col-md-7">
                <el-form-item
                    prop="default_report_period">
                    <span
                        class="page-anchor"
                        id="default_report_period">
                    </span>
                    <div class="form-label">
                        <h5>Default Report Period</h5>
                        <small>Configure how the default timeframe for the dashboard and its main graph.</small>
                    </div>
                    <el-select
                        class="w-full"
                        @change="updateParent('default_report_period')"
                        v-model="report_setting.default_report_period">
                        <el-option value="day"/>
                        <el-option value="week"/>
                        <el-option value="month"/>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <span class="page-anchor"
                          id="daily_activity_reports_enabled">
                    </span>
                    <div class="form-label">
                        <h5>Daily Activity Reports</h5>
                        <small>
                            Send daily reports of calls and texts, new lead dispositions,
                            and agents activities by email. This report is emailed to
                            account admins and includes unreturned statistics. </br>
                            An unreturned conversation is any contact that has an unread communication or is without a response.</br>
                            <a class="is-link"
                               href="https://support.aloware.com/en/articles/9034116-aloware-s-unreturned-conversations-your-key-to-unmissed-opportunities" 
                               target="_blank">
                               <i class="material-icons text-lg">help</i>
                               Click here to learn more
                            </a>
                        </small>
                    </div>
                    <div class="switch-label">
                        <el-switch
                            active-color="#00a344"
                            v-model="report_setting.daily_activity_reports_enabled"
                            @change="updateParent('daily_activity_reports_enabled')">
                        </el-switch>
                        <label>Enable daily activity reports</label>
                    </div>
                    <div class="form-label mt-2">
                        <small>
                            By selecting this box, all phone extension metrics will be included in the report.
                        </small>
                    </div>
                    <div>
                        <el-checkbox v-show="report_setting.daily_activity_reports_enabled"
                                     class="mr-2 mt-2"
                                     border
                                     v-model="report_setting.send_daily_activity_reports_with_user_extensions"
                                     @change="updateParent('send_daily_activity_reports_with_user_extensions')">
                            <strong>Include extensions</strong>
                        </el-checkbox>
                    </div>
                    <div class="form-label mt-2">
                        <small>
                            By selecting this box, only users with activity will be included in the report.
                        </small>
                    </div>
                    <div>
                        <el-checkbox v-show="report_setting.daily_activity_reports_enabled"
                                     class="mr-2 mt-2"
                                     border
                                     v-model="report_setting.send_daily_activity_reports_hiding_users_without_activity"
                                     @change="updateParent('send_daily_activity_reports_hiding_users_without_activity')">
                            <strong>Hide Users Without Activity</strong>
                        </el-checkbox>
                    </div>
                </el-form-item>

                <el-form-item
                    prop="send_daily_activity_reports_on"
                    v-show="report_setting.daily_activity_reports_enabled">
                    <span
                        class="page-anchor"
                        id="send_daily_activity_reports_on">
                    </span>
                    <div class="form-label">
                        <h5>Send Activity Reports On</h5>
                        <small>When to send the report, in your company timezone.</small>
                    </div>
                    <el-select
                        @change="updateParent('send_daily_activity_reports_on')"
                        filterable
                        placeholder="Select time"
                        class="w-full"
                        v-model="report_setting.send_daily_activity_reports_on">
                        <el-option
                            v-for="time in everyThirtyMinutes"
                            :key="time.value"
                            :label="time.label"
                            :value="time.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item v-show="report_setting.daily_activity_reports_enabled">
                    <span
                        class="page-anchor"
                        id="send_daily_activity_reports_for">
                    </span>
                    <div class="form-label">
                        <h5>Send Activity Reports For</h5>
                        <small>Receive report from the beginning of the day (00:00 am) or the previous cut off.</small>
                    </div>
                    <el-radio-group
                        @change="updateParent('send_daily_activity_reports_for')"
                        v-model="report_setting.send_daily_activity_reports_for">
                        <el-radio-button :label="1">Today</el-radio-button>
                        <el-radio-button :label="2">Last 24 hours</el-radio-button>
                    </el-radio-group>
                    <div>
                        <el-button
                            class="bg-deep-orange mt-2"
                            type="warning"
                            size="small"
                            :loading="test_activity_report.loading"
                            @click="sendActivityReports">
                            Test daily activity reports
                        </el-button>
                    </div>
                </el-form-item>

                <el-form-item>
                    <span
                        class="page-anchor"
                        id="set_reports_recipients">
                    </span>
                    <div class="form-label mb-3">
                        <h5>System Reports Recipients</h5>
                        <small>Assign roles that will receive reports and notifications for the following activities:</small>
                    </div>

                    <table class="table table-bordered table-hover table-sm">
                        <thead>
                            <tr>
                                <th></th>
                                <th class="text-center"
                                    v-for="role in AvailableReportingRoles">
                                    {{ role }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td valign="middle">
                                    <div class="w">
                                        <strong>Billing Warnings</strong>
                                    </div>
                                </td>
                                <td valign="middle"
                                    class="text-center"
                                    v-for="role in AvailableReportingRoles">
                                    <el-checkbox class="el-checkbox-height-fix"
                                                 :key="`billing-reports-role-${stripSpaces(role)}`"
                                                 :checked="reportingHasRole(ReportingRoles.BILLING_WARNINGS, role)"
                                                 :disabled="isBillingWarningsRoleCheckboxDisabled(role)"
                                                 @change="(checked) => onChangeReportingRole(checked, ReportingRoles.BILLING_WARNINGS, role)"/>
                                </td>                         
                            </tr>
                            <tr>
                                <td valign="middle">
                                    <div class="d-flex justify-content-between w-lg">
                                        <strong>Integration Reports</strong>
                                        <el-popover width="230"
                                                    trigger="hover"
                                                    placement="right"
                                                    data-testid="integration-reports-popover">
                                                    <div>
                                            <div class="pb-2 font-weight-bold">Integration Reports include:</div>
                                                <ul class="mb-0">
                                                    <li>Users Sync</li>
                                                    <li>Contacts Sync</li>
                                                    <li>Contact Dispositions Sync</li>
                                                    <li>Call Dispositions Sync</li>
                                                    <li>Failed Integration Connection Status</li>
                                                </ul>
                                            </div>
                                            <span slot="reference">
                                                <i class="fa fa-info-circle text-primary"></i>
                                            </span>
                                        </el-popover>
                                    </div>
                                </td>
                                <td valign="middle"
                                    class="text-center"
                                    v-for="role in AvailableReportingRoles">
                                    <el-checkbox class="el-checkbox-height-fix"
                                                 :key="`integration-reports-role-${stripSpaces(role)}`"
                                                 :checked="reportingHasRole(ReportingRoles.INTEGRATION_REPORTS, role)"
                                                 @change="(checked) => onChangeReportingRole(checked, ReportingRoles.INTEGRATION_REPORTS, role)"/>
                                </td>                               
                            </tr>
                            <tr>
                                <td valign="middle">
                                    <div class="d-flex justify-content-between w-lg">
                                        <strong>Account Reports</strong>
                                        <el-popover width="230"
                                                    trigger="hover"
                                                    placement="right"
                                                    data-testid="integration-reports-popover">
                                                    <div>
                                            <div class="pb-2 font-weight-bold">Account Reports include:</div>
                                                <ul class="mb-0">
                                                    <li>Daily Analytics Reports</li>
                                                </ul>
                                            </div>
                                            <span slot="reference">
                                                <i class="fa fa-info-circle text-primary"></i>
                                            </span>
                                        </el-popover>
                                    </div>
                                </td>
                                <td valign="middle"
                                    class="text-center"
                                    v-for="role in AvailableReportingRoles">
                                    <el-checkbox class="el-checkbox-height-fix"
                                                 :key="`account-reports-role-${stripSpaces(role)}`"
                                                 :checked="reportingHasRole(ReportingRoles.ACCOUNT_REPORTS, role)"
                                                 @change="(checked) => onChangeReportingRole(checked, ReportingRoles.ACCOUNT_REPORTS, role)"/>
                                </td>                               
                            </tr>
                            <tr v-if="showAloAiReportsSettings">
                                <td valign="middle">
                                    <div class="d-flex justify-content-between w-lg">
                                        <strong>AloAi Reports</strong>
                                        <el-popover width="230"
                                                    trigger="hover"
                                                    placement="right"
                                                    data-testid="integration-reports-popover">
                                                    <div>
                                            <div class="pb-2 font-weight-bold">AloAi Reports include:</div>
                                                <ul class="mb-0">
                                                    <li>AloAi Voice Analytics Reports</li>
                                                </ul>
                                            </div>
                                            <span slot="reference">
                                                <i class="fa fa-info-circle text-primary"></i>
                                            </span>
                                        </el-popover>
                                    </div>
                                </td>
                                <td valign="middle"
                                    class="text-center"
                                    v-for="role in AvailableReportingRoles">
                                    <el-checkbox class="el-checkbox-height-fix"
                                                 :key="`account-reports-role-${stripSpaces(role)}`"
                                                 :checked="reportingHasRole(ReportingRoles.ALOAI_REPORTS, role)"
                                                 @change="(checked) => onChangeReportingRole(checked, ReportingRoles.ALOAI_REPORTS, role)"/>
                                </td>                               
                            </tr>
                            <tr>
                                <td valign="middle">
                                    <div class="d-flex justify-content-between w-lg">
                                        <strong>Import/Export Reports</strong>
                                    </div>
                                </td>
                                <td valign="middle"
                                    class="text-center"
                                    v-for="role in AvailableReportingRoles">
                                    <el-checkbox class="el-checkbox-height-fix"
                                                 :key="`import-export-reports-role-${stripSpaces(role)}`"
                                                 :checked="reportingHasRole(ReportingRoles.IMPORT_EXPORT_REPORTS, role)"
                                                 @change="(checked) => onChangeReportingRole(checked, ReportingRoles.IMPORT_EXPORT_REPORTS, role)"/>
                                </td>                               
                            </tr>
                            <tr>
                                <td valign="middle">
                                    <div class="d-flex justify-content-between w-lg">
                                        <strong>Webhook Failure Notifications</strong>
                                    </div>
                                </td>
                                <td valign="middle"
                                    class="text-center"
                                    v-for="role in AvailableReportingRoles">
                                    <el-checkbox class="el-checkbox-height-fix"
                                                 :key="`webhook-failure-notifications-role-${stripSpaces(role)}`"
                                                 :checked="reportingHasRole(ReportingRoles.WEBHOOK_FAILURE_NOTIFICATIONS, role)"
                                                 @change="(checked) => onChangeReportingRole(checked, ReportingRoles.WEBHOOK_FAILURE_NOTIFICATIONS, role)"/>
                                </td>                               
                            </tr>
                        </tbody>
                    </table>
                </el-form-item>

                <el-form-item
                    prop="qualified_calls"
                    class="no-border pb-3">
                    <span
                        class="page-anchor"
                        id="qualified_calls">
                    </span>
                    <div class="form-label">
                        <h5>Qualified Calls</h5>
                        <small>
                            Set the criteria used on the reports to determine what is a qualified call. This value will be used on the Reports tab and the Dashboard.
                        </small>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold mr-2">Threshold 1</label>
                        <el-input-number
                            v-model="report_setting.qualified_calls_threshold1"
                            :min="0"
                            :max="1440"
                            @change="updateParent('qualified_calls_threshold1')">
                        </el-input-number>
                        <label class="ml-1">Minutes</label>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold mr-2">Threshold 2</label>
                        <el-input-number
                            v-model="report_setting.qualified_calls_threshold2"
                            :min="0"
                            :max="1440"
                            @change="updateParent('qualified_calls_threshold2')">
                        </el-input-number>
                        <label class="ml-1">Minutes</label>
                    </div>
                </el-form-item>
            </div>
            <div class="col-md-2 d-none d-md-block ml-auto">
                <div
                    class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70">
                    <h6 class="list-group-item">
                        On This Page
                    </h6>
                    <a href="#default_report_period"
                       class="list-group-item list-group-item-action"
                       @click="shine('default_report_period')">
                        Default Report Period
                    </a>
                    <a href="#daily_activity_reports_enabled"
                       class="list-group-item list-group-item-action"
                       @click="shine('daily_activity_reports_enabled')">
                        Daily Activity Reports
                    </a>
                    <a href="#send_daily_activity_reports_on"
                       v-show="report_setting.daily_activity_reports_enabled"
                       class="list-group-item list-group-item-action"
                       @click="shine('send_daily_activity_reports_on')">
                        Send Activity Reports On
                    </a>
                    <a v-show="report_setting.daily_activity_reports_enabled"
                       href="#send_daily_activity_reports_for"
                       class="list-group-item list-group-item-action"
                       @click="shine('send_daily_activity_reports_for')">
                        Send Activity Reports For
                    </a>
                    <a href="#set_reports_recipients"
                       class="list-group-item list-group-item-action"
                       @click="shine('set_reports_recipients')">
                        System Reports Recipients
                    </a>
                    <a href="#qualified_calls"
                       class="list-group-item list-group-item-action"
                       @click="shine('qualified_calls')">
                        Qualified Calls
                    </a>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
    import {form_handler, validator_mixin} from '../../mixins'
    import {mapState} from "vuex"
    import * as Roles from '../../constants/roles'
    import * as ReportingRoles from '../../constants/reporting-roles'

    export default {
        name: "account-report-settings",
        mixins: [form_handler, validator_mixin],
        props: {
            company_clone: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                report_setting: {},
                rules: {
                    send_daily_activity_reports_on: [
                        {validator: this.sendDailyActivityReportsOnValidator}
                    ],
                },
                test_activity_report: {
                    loading: false
                },
                Roles,
                AvailableReportingRoles: [
                    Roles.BILLING_ADMIN,
                    Roles.COMPANY_ADMIN,
                    Roles.COMPANY_SUPERVISOR,
                ],
                ReportingRoles,
            }
        },

        created() {
            // sets the needed property of the component
            if (this.company_clone && this.company_clone.id) {
                this.initializeProperties()
            }
        },

        methods: {
            initializeProperties() {
                // updates the properties from parent object
                this.report_setting = {
                    default_report_period: this.company_clone.default_report_period,
                    daily_activity_reports_enabled: this.company_clone.daily_activity_reports_enabled,
                    send_daily_activity_reports_on: this.company_clone.send_daily_activity_reports_on,
                    send_daily_activity_reports_for: this.company_clone.send_daily_activity_reports_for,
                    daily_sms_report: this.company_clone.daily_sms_report,
                    daily_outbound_sms_report: this.company_clone.daily_outbound_sms_report,
                    is_high_volume: this.company_clone.is_high_volume,
                    reseller_id: this.company_clone.reseller_id,
                    daily_spend_report: this.company_clone.daily_spend_report,
                    daily_dnc_report_enabled: this.company_clone.daily_dnc_report_enabled,
                    send_daily_activity_reports_with_user_extensions: this.company_clone.send_daily_activity_reports_with_user_extensions,
                    send_daily_activity_reports_hiding_users_without_activity: this.company_clone.send_daily_activity_reports_hiding_users_without_activity,
                    qualified_calls_threshold1: this.company_clone.qualified_calls_threshold1,
                    qualified_calls_threshold2: this.company_clone.qualified_calls_threshold2,
                    reports_roles_enabled: this.company_clone.reports_roles_enabled ?? {},
                }
            },

            updateParent(prop) {
                if (this.$refs.companySettingsForm) {
                    this.$refs.companySettingsForm.validate(valid => {
                        if (valid) {
                            this.$emit('update-parent', this.report_setting)
                        }
                    })
                }
            },

            sendActivityReports() {
                this.test_activity_report.loading = true;

                axios.post('api/v1/reports/activity-reports/email')
                    .then(res => {
                        this.test_activity_report.loading = false;
                        switch (res.status) {
                            case 200:
                                this.$notify({
                                    offset: 95,
                                    title: 'Activity Reports',
                                    message: 'Test activity report is sent.',
                                    type: 'success',
                                    showClose: true,
                                })
                                break;
                            default:
                                this.$notify({
                                    offset: 95,
                                    title: 'Activity Reports',
                                    message: res.error,
                                    type: 'error',
                                    showClose: true,
                                })
                        }
                    })
            },
            
            stripSpaces(text) {
                return text?.split(' ')?.join('-')
            },

            reportingHasRole(prop, role) {
                return this.report_setting.reports_roles_enabled[prop]?.includes(role)
            },

            onChangeReportingRole(checked, prop, role) {
                if (!this.report_setting.reports_roles_enabled[prop]) {
                    this.report_setting.reports_roles_enabled[prop] = [];
                }

                const target = this.report_setting.reports_roles_enabled[prop]

                if (checked) {
                    target.push(role)
                } else {
                    const index = target.indexOf(role)
                    if (index > -1) {
                        target.splice(index, 1)
                    }
                }

                // force state update
                this.report_setting.reports_roles_enabled = JSON.parse(JSON.stringify(this.report_setting.reports_roles_enabled))

                this.updateParent('reports_roles_enabled')
            },

            isBillingWarningsRoleCheckboxDisabled(role) {
                return role === Roles.BILLING_ADMIN && this.reportingHasRole(ReportingRoles.BILLING_WARNINGS, role)
            },
        },

        computed: {            
            ...mapState('cache', ['current_company']),

            everyThirtyMinutes() {
                const times = []

                for (let hour = 0; hour < 24; hour++) {
                    times.push({
                        label: moment({hour}).format('h:mm A'),
                        value: moment({hour}).format('HH:mm')
                    })
                    times.push({
                            label: moment({hour, minute: 30}).format('h:mm A'),
                            value: moment({hour, minute: 30}).format('HH:mm')
                        }
                    )
                }

                return times
            },

            showAloAiReportsSettings() {
                return this.current_company.transcription_enabled
            },
        },
    }
</script>
<style>
.el-checkbox-height-fix {
    height: 14px;
}
</style>
