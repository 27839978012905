var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: { placement: "right-start", width: "360", trigger: "click" },
      on: { show: _vm.onShow, hide: _vm.onHide },
      model: {
        value: _vm.show_popover,
        callback: function ($$v) {
          _vm.show_popover = $$v
        },
        expression: "show_popover",
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.is_busy,
              expression: "is_busy",
            },
          ],
        },
        [
          _c(
            "el-form-item",
            { staticClass: "mb-3" },
            [
              _c("div", { staticClass: "container-header d-flex" }, [
                _c("h6", { staticClass: "text-dark" }, [
                  _vm._v("Add List to Power Dialer"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "ml-auto",
                    staticStyle: { "margin-top": "-5px" },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn-dialog-close",
                        attrs: {
                          type: "text",
                          size: "large",
                          "data-testid": "close-popover",
                        },
                        on: {
                          click: function ($event) {
                            _vm.show_popover = false
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa fa-remove" })]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "lh-1p4 mb-2 text-dark" }, [
                _vm._v("Power Dialer User:"),
              ]),
              _vm._v(" "),
              _c("user-selector", {
                ref: "userSelector",
                staticClass: "mb-2",
                attrs: { hide_extensions: true, clearable: false },
                on: { change: _vm.changeUserId },
                model: {
                  value: _vm.user_id,
                  callback: function ($$v) {
                    _vm.user_id = $$v
                  },
                  expression: "user_id",
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "lh-1p4 mb-2 text-dark" }, [
                _vm._v("Power Dialer List:"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-full mb-2",
                      attrs: { placeholder: "Select List", filterable: "" },
                      on: { change: _vm.changePdList },
                      model: {
                        value: _vm.selected_pd_list_id,
                        callback: function ($$v) {
                          _vm.selected_pd_list_id = $$v
                        },
                        expression: "selected_pd_list_id",
                      },
                    },
                    _vm._l(_vm.pd_list_options, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.label, value: item.id },
                        },
                        [_c("span", [_vm._v(_vm._s(item.label))])]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { staticClass: "mb-2" }, [
                _c(
                  "div",
                  { staticClass: "form-label mb-1", attrs: { scope: "label" } },
                  [_c("label", [_vm._v("Push contacts to")])]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "el-radio",
                        {
                          staticClass: "w-full",
                          attrs: {
                            border: "",
                            size: "medium",
                            label: _vm.PowerDialer.DIRECTION_BOTTOM,
                            value: _vm.PowerDialer.DIRECTION_BOTTOM,
                          },
                          model: {
                            value: _vm.direction,
                            callback: function ($$v) {
                              _vm.direction = $$v
                            },
                            expression: "direction",
                          },
                        },
                        [_vm._v("\n              Bottom\n            ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "el-radio",
                        {
                          staticClass: "w-full",
                          attrs: {
                            border: "",
                            size: "medium",
                            label: _vm.PowerDialer.DIRECTION_TOP,
                            value: _vm.PowerDialer.DIRECTION_TOP,
                          },
                          model: {
                            value: _vm.direction,
                            callback: function ($$v) {
                              _vm.direction = $$v
                            },
                            expression: "direction",
                          },
                        },
                        [_vm._v("\n              Top\n            ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { staticClass: "mb-1" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-label mb-1 d-flex",
                    attrs: { scope: "label" },
                  },
                  [
                    _c("el-checkbox", {
                      staticClass: "fw-normal",
                      attrs: {
                        label: "Turn multiple numbers into separated tasks",
                      },
                      model: {
                        value: _vm.multiple_phone_numbers,
                        callback: function ($$v) {
                          _vm.multiple_phone_numbers = $$v
                        },
                        expression: "multiple_phone_numbers",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item ml-1",
                        staticStyle: { "margin-top": "2px" },
                        attrs: {
                          effect: "dark",
                          placement: "top",
                          width: "300",
                        },
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _vm._v(
                              "Any non-primary numbers of a contact will be turned into separate tasks"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "i",
                          {
                            staticClass: "material-icons text-blue-5",
                            staticStyle: {
                              cursor: "pointer",
                              "font-size": "15px",
                            },
                          },
                          [_vm._v("info_outline")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { staticClass: "mb-1" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-label mb-1 d-flex",
                    attrs: { scope: "label" },
                  },
                  [
                    _c("el-checkbox", {
                      attrs: { label: "Prevent duplicate phone numbers" },
                      model: {
                        value: _vm.prevent_duplicates,
                        callback: function ($$v) {
                          _vm.prevent_duplicates = $$v
                        },
                        expression: "prevent_duplicates",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item ml-1",
                        staticStyle: { "margin-top": "2px" },
                        attrs: {
                          effect: "dark",
                          placement: "top",
                          width: "300",
                        },
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _vm._v(
                              "If selected, duplicated numbers will not be included again"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "i",
                          {
                            staticClass: "material-icons text-blue-5",
                            staticStyle: {
                              cursor: "pointer",
                              "font-size": "15px",
                            },
                          },
                          [_vm._v("info_outline")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { staticClass: "mb-1" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-label d-flex",
                    attrs: { scope: "label" },
                  },
                  [
                    _c("el-checkbox", {
                      attrs: { label: "Add own contacts only" },
                      model: {
                        value: _vm.own_contacts_only,
                        callback: function ($$v) {
                          _vm.own_contacts_only = $$v
                        },
                        expression: "own_contacts_only",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item ml-1",
                        staticStyle: { "margin-top": "2px" },
                        attrs: {
                          effect: "dark",
                          placement: "top",
                          width: "300",
                        },
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _vm._v(
                              "If selected, it will add only the contacts owned by you"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "i",
                          {
                            staticClass: "material-icons text-blue-5",
                            staticStyle: {
                              cursor: "pointer",
                              "font-size": "15px",
                            },
                          },
                          [_vm._v("info_outline")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.isAllowedInternationalNumbers
                ? _c("el-form-item", { staticClass: "mb-1" }, [
                    _c(
                      "div",
                      { staticClass: "form-label", attrs: { scope: "label" } },
                      [
                        _c("el-checkbox", {
                          staticClass: "w-full",
                          attrs: { label: "Add international phone numbers" },
                          model: {
                            value: _vm.allow_international_phone_numbers,
                            callback: function ($$v) {
                              _vm.allow_international_phone_numbers = $$v
                            },
                            expression: "allow_international_phone_numbers",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticClass: "w-full",
                  attrs: {
                    type: "success",
                    size: "small",
                    disabled: !_vm.user_id || _vm.is_busy,
                  },
                  on: { click: _vm.addToPowerDialer },
                },
                [_vm._v("\n        Add to Power Dialer\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-block deep-purple btn-sm my-2",
          attrs: { slot: "reference" },
          slot: "reference",
        },
        [
          _c("i", { staticClass: "fa fa-phone pull-left" }),
          _vm._v(" "),
          _vm._t("default", function () {
            return [_vm._v("Add To PowerDialer")]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }