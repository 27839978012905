var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "35vw",
        top: "10vh",
        "append-to-body": "",
        title: "Import From HubSpot List",
        "before-close": _vm.closeHubSpotListImport,
        visible: _vm.show_dialog,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show_dialog = $event
        },
      },
    },
    [
      _c("al-alert", { attrs: { dimisable: true } }, [
        _c("span", {
          staticClass: "mr-5 text-dark break-word",
          domProps: {
            innerHTML: _vm._s(_vm.TAGS_DEPRECATION_IMPORT_CONTACTS_MESSAGE),
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "import_hubspot_form",
              staticClass: "mt-2 p-0",
              attrs: {
                "label-position": "top",
                rules: _vm.rules,
                model: _vm.hubspot_list_form,
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.importHubspotList.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "mb-3",
                  attrs: { prop: "list_id", label: "Select a HubSpot list" },
                },
                [
                  _c("hubspot-list-selector", {
                    ref: "hubspotListSelector",
                    attrs: { value: _vm.hubspot_list_form.list_id },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.hubspot_list_form,
                          "list_id",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mb-3", attrs: { prop: "dynamic_import" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.hubspot_list_form.dynamic_import,
                        callback: function ($$v) {
                          _vm.$set(_vm.hubspot_list_form, "dynamic_import", $$v)
                        },
                        expression: "hubspot_list_form.dynamic_import",
                      },
                    },
                    [_vm._v("Keep list in sync with HubSpot")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item ml-1",
                      staticStyle: { "margin-top": "2px" },
                      attrs: {
                        effect: "dark",
                        placement: "top",
                        "popper-class": "width-300",
                      },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _c("p", [
                            _c("b", [
                              _vm._v(
                                "Activate this option to automatically sync your HubSpot lists."
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "HubSpot's Active lists will be updated hourly, reflecting the addition and removal of contacts based on specific HubSpot criteria."
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "Static lists in HubSpot will remain unchanged until manual updates are made in the CRM, which will also be reflected in the app during periodic synchronization."
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "i",
                        {
                          staticClass: "material-icons text-blue-5",
                          staticStyle: { cursor: "pointer" },
                        },
                        [_vm._v("info_outline")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "container-footer d-flex mb-0 mt-5" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.closeHubSpotListImport },
                },
                [_vm._v("\n                Close\n            ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml-auto",
                  attrs: {
                    disabled: _vm.disableImportButton,
                    type: "success",
                    size: "small",
                    loading: _vm.checking,
                  },
                  on: { click: _vm.checkIfHubSpotListAlreadyExist },
                },
                [_vm._v("\n                Import\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }