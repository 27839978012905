<template>
    <div>
        <el-form ref="trigger_form"
                 label-position="top"
                 :model="trigger"
                 :rules="form_trigger"
                 v-if="!trigger_types.includes(trigger.type)"
                 @submit.prevent.native="">
            <span class="inline">When an </span>
            <span class="inline"
                  v-if="automation_trigger || power_dialer_trigger">
                {{ trigger.direction | fixCommDirection | toLowerCase }}
            </span>
            <el-form-item class="inline mb-3 no-border"
                          prop="direction"
                          v-else>
                <el-select placeholder="Select Direction"
                           :disabled="automation_trigger || power_dialer_trigger"
                           v-model="trigger.direction"
                           @change="customPreValidateForm('trigger_form')">
                    <el-option :label="direction | fixCommDirection"
                               :key="direction"
                               :value="direction"
                               v-for="direction in directions">
                    </el-option>
                </el-select>
            </el-form-item>
            <span class="inline"
                  v-if="trigger.type !== CommunicationTypes.EMAIL">
                {{ trigger.type | fixCommType | toLowerCase }}
            </span>
            <span class="inline"
                  v-if="trigger.type === CommunicationTypes.EMAIL && trigger.direction">
                email comes {{ trigger.direction == 1 ? 'in' : 'out' }}, then
            </span>
            <!-- Direction -->
            <template v-if="trigger.direction">
                <span class="inline"
                      v-show="trigger.type !== CommunicationTypes.EMAIL"> is </span>
                <span class="inline"
                      v-if="automation_trigger || power_dialer_trigger">
                    {{ trigger.disposition_status | translateDispositionStatusText | replaceDash }}
                </span>
                <el-form-item class="inline mb-3 no-border"
                              prop="disposition_status"
                              v-show="trigger.type !== CommunicationTypes.EMAIL"
                              v-else>
                    <el-select placeholder="Select Disposition Status"
                               style="width: 220px"
                               :disabled="automation_trigger || power_dialer_trigger"
                               v-model="trigger.disposition_status"
                               @change="checkTriggerForm">
                        <el-option :label="status | translateDispositionStatusText | replaceDash | capitalize"
                                   :key="status"
                                   :value="status"
                                   v-for="status in availableDispositionStatus">
                        </el-option>
                    </el-select>
                </el-form-item>
            </template>
            <template v-if="shouldShowTriggerVariable">
                <!-- Variable -->
                <span class="inline"
                      v-if="automation_trigger || power_dialer_trigger">
                    <span class="font-weight-bold">AND</span> its
                </span>
                <el-checkbox class="_600 mr-2 ml-2"
                             :disabled="automation_trigger || power_dialer_trigger"
                             v-model="trigger.has_condition"
                             v-else
                             @change="clearConditions">
                    and if
                </el-checkbox>
                <template v-if="trigger.has_condition">
                    <el-form-item class="inline mb-3 no-border"
                                  prop="variable">
                        <el-select placeholder="Select Variable"
                                   style="width: 170px"
                                   :disabled="power_dialer_trigger"
                                   v-model="trigger.variable"
                                   @change="customPreValidateForm('trigger_form')">
                            <el-option :label="variable.name"
                                       :key="variable.id"
                                       :value="variable.value"
                                       v-for="variable in availableVariables">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- For calls -->
                    <template v-if="trigger.type === CommunicationTypes.CALL">
                        <!-- Symbol -->
                        <template v-if="trigger.variable && trigger.variable !== 'last_transferred_leg'">
                            <span class="inline"> is </span>
                            <span class="inline"
                                  v-if="automation_trigger">
                                {{ symbolWords }}
                            </span>
                            <el-form-item class="inline mb-3 no-border"
                                          prop="symbol"
                                          v-else>
                                <el-select placeholder="Math Symbol"
                                           style="width: 130px"
                                           :disabled="automation_trigger"
                                           v-model="trigger.symbol"
                                           @change="customPreValidateForm('trigger_form')">
                                    <el-option :label="symbol"
                                               :key="symbol"
                                               :value="symbol"
                                               v-for="symbol in symbols">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </template>
                        <!-- Value -->
                        <template v-if="trigger.symbol">
                            <el-form-item class="inline mb-3 no-border"
                                          prop="value">
                                <el-input-number :min="0"
                                                 v-model="trigger.value"
                                                 @input="customPreValidateForm('trigger_form')">
                                </el-input-number>
                            </el-form-item>
                            <span class="inline"> seconds<span v-if="automation_trigger">,</span><span v-else>.</span> </span>
                        </template>
                    </template>
                    <!-- For text messages -->
                    <template v-if="trigger.type === CommunicationTypes.SMS">
                        <el-form-item v-if="trigger.variable !== 'first_time_message'"
                                      class="inline mb-3 no-border"
                                      prop="keywords">
                            <el-select placeholder="Choose or create keywords"
                                       style="width: 300px"
                                       multiple
                                       filterable
                                       allow-create
                                       default-first-option
                                       v-model="trigger.keywords"
                                       @change="customPreValidateForm('trigger_form')">
                                <el-option :label="keyword.label"
                                           :key="keyword.value"
                                           :value="keyword.value"
                                           v-for="keyword in keywords">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </template>
                </template>
            </template>

            <!-- Operation email -->
            <template v-if="trigger.disposition_status && trigger.type === CommunicationTypes.EMAIL">
                <el-form-item class="inline mb-3 no-border"
                              prop="operation">
                    <el-select placeholder="Select Operation"
                               style="width: 220px"
                               v-model="trigger.operation"
                               default-first-option
                               filterable
                               autocomplete="off"
                               clearable
                               @change="clearData">
                        <el-option :label="operation.name"
                                   :key="operation.id"
                                   :value="operation.value"
                                   v-for="operation in operations">
                        </el-option>
                    </el-select>
                </el-form-item>
            </template>
            <template v-if="trigger.operation && trigger.type === CommunicationTypes.EMAIL">
                <br>
                <!-- email trigger tag selection -->
                <el-form-item class="inline mb-3 no-border"
                              v-if="hasPermissionTo(['list tag', 'view tag']) && trigger.operation === 'tag'"
                              prop="tag_ids">
                    <tag-selector style="width: 300px"
                                  :multiple="true"
                                  :category_prop="TagCategory.CAT_COMMUNICATIONS"
                                  v-model="trigger.tag_ids"
                                  @change="changeTags">
                    </tag-selector>
                </el-form-item>

                <el-form-item class="inline mb-3 no-border"
                              v-if="hasPermissionTo(['list tag', 'view tag']) && ['tag_contact', 'untag_contact'].includes(trigger.operation)"
                              prop="tag_ids">
                    <tag-selector style="width: 300px"
                                  :multiple="true"
                                  :category_prop="TagCategory.CAT_CONTACTS"
                                  v-model="trigger.tag_ids"
                                  @change="changeTags">
                    </tag-selector>
                </el-form-item>

                <!-- email trigger sequence selection -->
                <el-form-item class="inline mb-3 no-border"
                              v-if="hasPermissionTo(['list workflow', 'view workflow']) && trigger.operation === 'enroll'"
                              prop="workflow_ids">
                    <workflow-selector style="width: 300px"
                                       :multiple="false"
                                       v-model="workflow_id"
                                       @change="changeWorkflows">
                    </workflow-selector>
                </el-form-item>

                <!-- email trigger disposition-status selection -->
                <el-form-item class="inline mb-3 no-border"
                              v-if="hasPermissionTo(['list disposition status', 'view disposition status']) && trigger.operation === 'dispose'"
                              prop="disposition_status_id">
                    <disposition-status-selector style="width: 300px"
                                                 :multiple="false"
                                                 v-model="trigger.disposition_status_id"
                                                 @change="changeDispositionStatus">
                    </disposition-status-selector>
                </el-form-item>

                <el-form-item class="inline mb-3 no-border"
                              v-if="trigger.operation === 'create_hubspot_ticket'"
                              prop="stage_id">
                    <hubspot-ticketing-pipeline-selector :ticket-name="trigger.ticket_name"
                                                         :pipeline-id="trigger.pipeline_id"
                                                         :stage-id="trigger.stage_id"
                                                         :ticket-description="trigger.ticket_description"
                                                         @selectedPipeline="changeSelectedPipeline"
                                                         @selectedStage="changeSelectedStage"
                                                         @changedTicketName="changeTicketName"
                                                         @changedTicketDescription="changeTicketDescription">
                    </hubspot-ticketing-pipeline-selector>
                </el-form-item>
            </template>
            <template v-if="trigger.disposition_status && trigger.type !== CommunicationTypes.EMAIL">
                <span class="inline"
                      v-if="!automation_trigger">
                    Then
                </span>
                <span class="inline"
                      v-if="automation_trigger">
                    {{ triggerOperationName | toLowerCase }}
                </span>
                <el-form-item class="inline mb-3 no-border"
                              prop="operation"
                              v-else>
                    <el-select placeholder="Select Operation"
                               style="width: 220px"
                               v-model="trigger.operation"
                               autocomplete="off"
                               :disabled="automation_trigger"
                               default-first-option
                               filterable
                               clearable
                               @change="clearData">
                        <el-option :label="operation.name"
                                   :key="operation.id"
                                   :value="operation.value"
                                   v-for="operation in operations">
                        </el-option>
                    </el-select>
                </el-form-item>
            </template>

            <div class="row"
                 v-if="trigger.type !== CommunicationTypes.EMAIL">
                <div class="col-12">
                    <!-- Operation -->
                    <template v-if="shouldShowOperationForTrigger">
                        <el-form-item class="inline mb-3 no-border"
                                      v-if="hasPermissionTo(['list tag', 'view tag']) && trigger.operation === 'tag'"
                                      prop="tag_ids">
                            <tag-selector style="width: 300px"
                                          :multiple="true"
                                          :category_prop="TagCategory.CAT_COMMUNICATIONS"
                                          v-model="trigger.tag_ids"
                                          @change="changeTags">
                            </tag-selector>
                        </el-form-item>

                        <el-form-item class="inline mb-3 no-border"
                                      v-if="hasPermissionTo(['list tag', 'view tag']) && ['tag_contact', 'untag_contact'].includes(trigger.operation)"
                                      prop="tag_ids">
                            <tag-selector style="width: 300px"
                                          :multiple="true"
                                          :category_prop="TagCategory.CAT_CONTACTS"
                                          v-model="trigger.tag_ids"
                                          @change="changeTags">
                            </tag-selector>
                        </el-form-item>

                        <el-form-item class="inline mb-3 no-border"
                                      prop="list_id"
                                      v-if="hasPermissionTo(['view contact list']) && ['add_to_list', 'remove_from_list'].includes(trigger.operation)">
                            <contact-lists-selector :multiple="false"
                                                    :value="selected_list"
                                                    :list_type="ContactListTypes.STATIC_LIST"
                                                    v-model="trigger.list_id"
                                                    @change="changeList"/>
                        </el-form-item>

                        <el-form-item class="inline mb-3 no-border"
                                      v-if="hasPermissionTo(['list workflow', 'view workflow']) && trigger.operation === 'enroll'"
                                      prop="workflow_ids">
                            <workflow-selector style="width: 300px"
                                               :multiple="false"
                                               v-model="workflow_id"
                                               @change="changeWorkflows">
                            </workflow-selector>
                        </el-form-item>

                        <el-form-item class="inline mb-3 no-border"
                                      v-if="hasPermissionTo(['list disposition status', 'view disposition status']) && trigger.operation === 'dispose'"
                                      prop="disposition_status_id">
                            <disposition-status-selector style="width: 300px"
                                                         :multiple="false"
                                                         v-model="trigger.disposition_status_id"
                                                         @change="changeDispositionStatus">
                            </disposition-status-selector>
                        </el-form-item>

                        <el-form-item class="inline mb-3 no-border"
                                      v-if="trigger.operation === 'create_hubspot_ticket'"
                                      prop="stage_id">
                            <hubspot-ticketing-pipeline-selector :ticket-name="trigger.ticket_name"
                                                                 :pipeline-id="trigger.pipeline_id"
                                                                 :stage-id="trigger.stage_id"
                                                                 :ticket-description="trigger.ticket_description"
                                                                 @selectedPipeline="changeSelectedPipeline"
                                                                 @selectedStage="changeSelectedStage"
                                                                 @changedTicketName="changeTicketName"
                                                                 @changedTicketDescription="changeTicketDescription">
                            </hubspot-ticketing-pipeline-selector>
                        </el-form-item>
                    </template>
                </div>
            </div>

            <div class="row no-select">
                <div class="col-12">
                    <span class="pull-right _600 opacity-5"
                          :class="[ trigger.valid ? 'text-dark-greenish' : 'text-danger' ]">
                        <i class="fa fa-check-circle"
                           v-if="trigger.valid"></i>
                        <i class="fa fa-times-circle"
                           v-else></i>
                        {{ trigger.valid ? 'Valid trigger' : 'Invalid trigger' }}
                    </span>
                </div>
            </div>
        </el-form>
        <el-form ref="trigger_form"
                 :model="trigger"
                 :rules="form_trigger"
                 v-else-if="trigger.type == 'contact' && automation_trigger && !power_dialer_trigger"
                 @submit.prevent.native="">
            <div class="row">
                <div class="col-12">
                    <span class="el-form-item__label inline">When a contact is created, then enroll to this sequence</span>
                </div>
            </div>

            <div class="row no-select">
                <div class="col-12">
                    <span class="pull-right _600 opacity-5"
                          :class="[ trigger.valid ? 'text-dark-greenish' : 'text-danger' ]">
                        <i class="fa fa-check-circle"
                           v-if="trigger.valid"></i>
                        <i class="fa fa-times-circle"
                           v-else></i>
                        {{ trigger.valid ? 'Valid trigger' : 'Invalid trigger' }}
                    </span>
                </div>
            </div>
        </el-form>
        <!-- contact trigger -->
        <el-form ref="trigger_form"
                 :model="trigger"
                 :rules="form_trigger"
                 v-else-if="trigger.type == 'contact'"
                 @submit.prevent.native="">
            <div class="row">
                <div class="col-12">
                    <span class="el-form-item__label inline">When a contact is created, then</span>
                    <el-form-item class="inline mb-3 no-border"
                                  prop="operation">
                        <el-select placeholder="Select Operation"
                                   style="width: 220px"
                                   v-model="trigger.operation"
                                   default-first-option
                                   autocomplete="off"
                                   filterable
                                   clearable
                                   @change="clearData">
                            <el-option :label="operation.name"
                                       :key="operation.id"
                                       :value="operation.value"
                                       v-for="operation in contactTriggerOperations">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <template v-if="trigger.operation">
                        <br>
                        <!-- contact trigger tags selection -->
                        <el-form-item class="inline mb-3 no-border"
                                      v-if="hasPermissionTo(['list tag', 'view tag']) && trigger.operation === 'tag'"
                                      prop="tag_ids">
                            <tag-selector style="width: 300px"
                                          :multiple="true"
                                          :category_prop="TagCategory.CAT_COMMUNICATIONS"
                                          v-model="trigger.tag_ids"
                                          @change="changeTags">
                            </tag-selector>
                        </el-form-item>

                        <el-form-item class="inline mb-3 no-border"
                                      v-if="hasPermissionTo(['list tag', 'view tag']) && ['tag_contact', 'untag_contact'].includes(trigger.operation)"
                                      prop="tag_ids">
                            <tag-selector style="width: 300px"
                                          :multiple="true"
                                          :category_prop="TagCategory.CAT_CONTACTS"
                                          v-model="trigger.tag_ids"
                                          @change="changeTags">
                            </tag-selector>
                        </el-form-item>

                        <el-form-item class="inline mb-3 no-border"
                                      prop="list_id"
                                      v-if="hasPermissionTo(['view contact list']) && ['add_to_list', 'remove_from_list'].includes(trigger.operation)">
                            <contact-lists-selector :multiple="false"
                                                    :value="selected_list"
                                                    :list_type="ContactListTypes.STATIC_LIST"
                                                    v-model="trigger.list_id"
                                                    @change="changeList"/>
                        </el-form-item>

                        <!-- contact trigger sequence selection -->
                        <el-form-item class="inline mb-3 no-border"
                                      v-if="hasPermissionTo(['list workflow', 'view workflow']) && trigger.operation === 'enroll'"
                                      prop="workflow_ids">
                            <workflow-selector style="width: 300px"
                                               :multiple="false"
                                               v-model="workflow_id"
                                               @change="changeWorkflows">
                            </workflow-selector>
                        </el-form-item>

                        <!-- contact trigger disposition-status selection -->
                        <el-form-item class="inline mb-3 no-border"
                                      v-if="hasPermissionTo(['list disposition status', 'view disposition status']) && trigger.operation === 'dispose'"
                                      prop="disposition_status_id">
                            <disposition-status-selector style="width: 300px"
                                                         :multiple="false"
                                                         v-model="trigger.disposition_status_id"
                                                         @change="changeDispositionStatus">
                            </disposition-status-selector>
                        </el-form-item>
                    </template>
                </div>
            </div>

            <div class="row no-select">
                <div class="col-12">
                    <span class="pull-right _600 opacity-5"
                          :class="[ trigger.valid ? 'text-dark-greenish' : 'text-danger' ]">
                        <i class="fa fa-check-circle"
                           v-if="trigger.valid"></i>
                        <i class="fa fa-times-circle"
                           v-else></i>
                        {{ trigger.valid ? 'Valid trigger' : 'Invalid trigger' }}
                    </span>
                </div>
            </div>
        </el-form>
        <el-form ref="trigger_form"
                 :model="trigger"
                 :rules="form_trigger"
                 v-else-if="trigger.type == 'disposition'"
                 @submit.prevent.native="">
            <div class="row">
                <div class="col-12">
                    <span class="inline">When a contact is disposed to </span>
                    <el-form-item class="inline mb-3 no-border"
                                  prop="disposition_status_id">
                        <el-select placeholder="Select Disposition"
                                   style="width: 220px"
                                   default-first-option
                                   autocomplete="off"
                                   filterable
                                   clearable
                                   v-model="trigger.disposition_status_id"
                                   @change="clearData">
                            <el-option :label="disposition.name"
                                       :key="disposition.id"
                                       :value="disposition.id"
                                       v-for="disposition in availableContactDispositionStatus">
                                <template v-if="disposition.is_external !== undefined">
                                    <i class="material-icons"
                                       :style="{ color: disposition.color }"
                                       v-if="disposition.is_external">lock</i>
                                    <i class="material-icons"
                                       :style="{ color: disposition.color }"
                                       v-else>label</i>
                                </template>
                                <span>{{ disposition.name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <span class="inline">, then </span>
                    <el-form-item class="inline mb-3 no-border"
                                  prop="enroll_action">
                        <el-select placeholder="Select Action"
                                   style="width: 220px"
                                   autocomplete="off"
                                   default-first-option
                                   filterable
                                   clearable
                                   v-model="trigger.enroll_action"
                                   @change="clearData">
                            <el-option :label="action.name"
                                       :key="action.id"
                                       :value="action.value"
                                       v-for="action in availableActions">
                                <span>{{ action.name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item class="inline mb-3 no-border"
                                  v-if="hasPermissionTo(['list workflow', 'view workflow']) && trigger.enroll_action && trigger.enroll_action === 'enroll' && power_dialer_trigger"
                                  prop="workflow_ids">
                        <workflow-selector style="width: 300px"
                                           :multiple="false"
                                           v-model="workflow_id"
                                           @change="changeWorkflows">
                        </workflow-selector>
                    </el-form-item>
                </div>
            </div>

            <div class="row no-select">
                <div class="col-12">
                    <span class="pull-right _600 opacity-5"
                          :class="[ trigger.valid ? 'text-dark-greenish' : 'text-danger' ]">
                        <i class="fa fa-check-circle"
                           v-if="trigger.valid"></i>
                        <i class="fa fa-times-circle"
                           v-else></i>
                        {{ trigger.valid ? 'Valid trigger' : 'Invalid trigger' }}
                    </span>
                </div>
            </div>
        </el-form>
        <el-form ref="trigger_form"
                 :model="trigger"
                 :rules="form_trigger"
                 v-else-if="trigger.type == 'tag'"
                 @submit.prevent.native="">
            <div class="row">
                <!-- WAT-876: deprecation banner -->
                <al-alert class="mb-2 mx-auto"
                         :dimisable="true"> 
                    <span class="text-dark"
                          v-html="TAGS_DEPRECATION_MESSAGE"/>
                </al-alert>
                <div class="col-12">
                    <span class="inline">When added tag </span>
                    <el-form-item class="inline mb-3 no-border"
                                  prop="tag_option_id">
                        <el-select placeholder="Select Option"
                                   style="width: 220px"
                                   default-first-option
                                   autocomplete="off"
                                   filterable
                                   clearable
                                   v-model="trigger.tag_option_id"
                                   @change="clearData">
                            <el-option :label="tag_option.name"
                                       :key="tag_option.id"
                                       :value="tag_option.id"
                                       v-for="tag_option in availableTagOptions">
                                <span>{{ tag_option.name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <span class="inline"> of </span>
                    <el-form-item class="inline mb-3 no-border"
                                  prop="tag_ids">
                        <tag-selector style="width: 300px"
                                      :multiple="true"
                                      :category_prop="TagCategory.CAT_CONTACTS"
                                      v-model="trigger.tag_ids"
                                      @change="changeTags">
                        </tag-selector>
                    </el-form-item>
                    <span class="inline">, then </span>
                    <el-form-item class="inline mb-3 no-border"
                                  prop="enroll_action">
                        <el-select placeholder="Select Action"
                                   style="width: 220px"
                                   autocomplete="off"
                                   default-first-option
                                   filterable
                                   clearable
                                   v-model="trigger.enroll_action"
                                   @change="clearData">
                            <el-option :label="action.name"
                                       :key="action.id"
                                       :value="action.value"
                                       v-for="action in availableActions">
                                <span>{{ action.name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item class="inline mb-3 no-border"
                                  v-if="hasPermissionTo(['list workflow', 'view workflow']) && trigger.enroll_action && trigger.enroll_action === 'enroll' && power_dialer_trigger"
                                  prop="workflow_ids">
                        <workflow-selector style="width: 300px"
                                           :multiple="false"
                                           v-model="workflow_id"
                                           @change="changeWorkflows">
                        </workflow-selector>
                    </el-form-item>
                </div>
            </div>

            <div class="row no-select">
                <div class="col-12">
                    <span class="pull-right _600 opacity-5"
                          :class="[ trigger.valid ? 'text-dark-greenish' : 'text-danger' ]">
                        <i class="fa fa-check-circle"
                           v-if="trigger.valid"></i>
                        <i class="fa fa-times-circle"
                           v-else></i>
                        {{ trigger.valid ? 'Valid trigger' : 'Invalid trigger' }}
                    </span>
                </div>
            </div>
        </el-form>
        <el-form ref="trigger_form"
                 :model="trigger"
                 :rules="form_trigger"
                 v-else-if="trigger.type == 'remove_tag'"
                 @submit.prevent.native="">
            <div class="row">
                <!-- WAT-876: deprecation banner -->
                <al-alert class="mb-2 mx-auto">
                    <span class="text-dark"
                          v-html="TAGS_DEPRECATION_MESSAGE"/>
                </al-alert>
                <div class="col-12">
                    <span class="inline">When removed tag </span>
                    <el-form-item class="inline mb-3 no-border"
                                  prop="tag_option_id">
                        <el-select placeholder="Select Option"
                                   style="width: 220px"
                                   default-first-option
                                   autocomplete="off"
                                   filterable
                                   clearable
                                   v-model="trigger.tag_option_id"
                                   @change="clearData">
                            <el-option :label="tag_option.name"
                                       :key="tag_option.id"
                                       :value="tag_option.id"
                                       v-for="tag_option in availableTagOptions">
                                <span>{{ tag_option.name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <span class="inline"> of </span>
                    <el-form-item class="inline mb-3 no-border"
                                  prop="tag_ids">
                        <tag-selector style="width: 300px"
                                      :multiple="true"
                                      :category_prop="TagCategory.CAT_CONTACTS"
                                      v-model="trigger.tag_ids"
                                      @change="changeTags">
                        </tag-selector>
                    </el-form-item>
                    <span class="inline">, then </span>
                    <el-form-item class="inline mb-3 no-border"
                                  prop="enroll_action">
                        <el-select placeholder="Select Action"
                                   style="width: 220px"
                                   autocomplete="off"
                                   default-first-option
                                   filterable
                                   clearable
                                   v-model="trigger.enroll_action"
                                   @change="clearData">
                            <el-option :label="action.name"
                                       :key="action.id"
                                       :value="action.value"
                                       v-for="action in availableActions">
                                <span>{{ action.name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item class="inline mb-3 no-border"
                                  v-if="hasPermissionTo(['list workflow', 'view workflow']) && trigger.enroll_action && trigger.enroll_action === 'enroll' && power_dialer_trigger"
                                  prop="workflow_ids">
                        <workflow-selector style="width: 300px"
                                           :multiple="false"
                                           v-model="workflow_id"
                                           @change="changeWorkflows">
                        </workflow-selector>
                    </el-form-item>
                </div>
            </div>

            <div class="row no-select">
                <div class="col-12">
                    <span class="pull-right _600 opacity-5"
                          :class="[ trigger.valid ? 'text-dark-greenish' : 'text-danger' ]">
                        <i class="fa fa-check-circle"
                           v-if="trigger.valid"></i>
                        <i class="fa fa-times-circle"
                           v-else></i>
                        {{ trigger.valid ? 'Valid trigger' : 'Invalid trigger' }}
                    </span>
                </div>
            </div>
        </el-form>
        <el-form ref="trigger_form"
                 :model="trigger"
                 :rules="form_trigger"
                 v-else-if="trigger.type == 'contact_list' || trigger.type == 'remove_contact_list'"
                 @submit.prevent.native="">
            <div class="row">
                <div class="col-12">
                    <span class="inline">When {{ trigger.type == 'contact_list' ? 'add' : 'remove' }} contact list </span>
                    <el-form-item class="inline mb-3 no-border"
                                  prop="contact_list_option_id">
                        <el-select placeholder="Select Option"
                                   style="width: 220px"
                                   default-first-option
                                   autocomplete="off"
                                   filterable
                                   clearable
                                   v-model="trigger.contact_list_option_id"
                                   @change="clearData">
                            <el-option :label="contact_list_option.name"
                                       :key="contact_list_option.id"
                                       :value="contact_list_option.id"
                                       v-for="contact_list_option in availableContactListOptions">
                                <span>{{ contact_list_option.name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <span class="inline"> of </span>
                    <el-form-item class="inline mb-3 no-border"
                                  prop="contact_list_id">
                        Select a list
                        <contact-lists-selector :user_id="auth.user.profile.id"
                                                :module_type="ContactListModuleTypes.PUBLIC_CONTACT_LIST"
                                                :list_type="ContactListTypes.STATIC_LIST"
                                                v-model="trigger.contact_list_id"
                                                @change="changeContactList">
                        </contact-lists-selector>
                    </el-form-item>
                    <span class="inline">, then </span>
                    <el-form-item class="inline mb-3 no-border"
                                  prop="enroll_action">
                        <el-select placeholder="Select Action"
                                   style="width: 220px"
                                   autocomplete="off"
                                   default-first-option
                                   filterable
                                   clearable
                                   v-model="trigger.enroll_action"
                                   @change="clearData">
                            <el-option :label="action.name"
                                       :key="action.id"
                                       :value="action.value"
                                       v-for="action in availableActions">
                                <span>{{ action.name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item class="inline mb-3 no-border"
                                  prop="workflow_ids"
                                  v-if="hasPermissionTo(['list workflow', 'view workflow']) && trigger.enroll_action && trigger.enroll_action === 'enroll' && power_dialer_trigger">
                        <workflow-selector style="width: 300px"
                                           :multiple="false"
                                           v-model="workflow_id"
                                           @change="changeWorkflows">
                        </workflow-selector>
                    </el-form-item>
                </div>
            </div>

            <div class="row no-select">
                <div class="col-12">
                    <span class="pull-right _600 opacity-5"
                          :class="[ trigger.valid ? 'text-dark-greenish' : 'text-danger' ]">
                        <i class="fa fa-check-circle"
                           v-if="trigger.valid"></i>
                        <i class="fa fa-times-circle"
                           v-else></i>
                        {{ trigger.valid ? 'Valid trigger' : 'Invalid trigger' }}
                    </span>
                </div>
            </div>
        </el-form>
        <el-form ref="trigger_form"
                 :model="trigger"
                 :rules="form_trigger"
                 v-else-if="trigger.type == 'call disposition'"
                 @submit.prevent.native="">
            <div class="row">
                <div class="col-12">
                    <span class="inline">When a call is disposed to </span>
                    <el-form-item class="inline mb-3 no-border"
                                  prop="call_disposition_id">
                        <el-select placeholder="Select Disposition"
                                   style="width: 220px"
                                   default-first-option
                                   autocomplete="off"
                                   filterable
                                   clearable
                                   v-model="trigger.call_disposition_id"
                                   @change="clearData">
                            <el-option :label="disposition.name"
                                       :key="disposition.id"
                                       :value="disposition.id"
                                       v-for="disposition in callDispositionAlphabeticalOrder">
                                <template v-if="disposition.is_external !== undefined">
                                    <i class="material-icons"
                                       :style="{ color: disposition.color }"
                                       v-if="disposition.is_external">lock</i>
                                    <i class="material-icons"
                                       :style="{ color: disposition.color }"
                                       v-else>label</i>
                                </template>
                                <span>{{ disposition.name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <span class="inline">, then </span>
                    <el-form-item class="inline mb-3 no-border"
                                  prop="enroll_action">
                        <el-select placeholder="Select Action"
                                   style="width: 220px"
                                   autocomplete="off"
                                   default-first-option
                                   filterable
                                   clearable
                                   v-model="trigger.enroll_action"
                                   @change="clearData">
                            <el-option :label="action.name"
                                       :key="action.id"
                                       :value="action.value"
                                       v-for="action in availableActions">
                                <span>{{ action.name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item class="inline mb-3 no-border"
                                  v-if="workflowSelectorEnabledForCallDispositionTrigger"
                                  prop="workflow_ids">
                        <workflow-selector style="width: 300px"
                                           :multiple="false"
                                           v-model="workflow_id"
                                           @change="changeWorkflows">
                        </workflow-selector>
                    </el-form-item>
                    <el-form-item class="inline mb-3 no-border"
                                  v-if="tagsSelectorEnabledForCallDispositionTrigger"
                                  prop="tag_ids">
                        <tag-selector style="width: 300px"
                                      :multiple="true"
                                      :category_prop="TagCategory.CAT_CONTACTS"
                                      v-model="trigger.tag_ids"
                                      @change="changeTags">
                        </tag-selector>
                    </el-form-item>
                    <el-form-item class="inline mb-3 no-border"
                                  prop="list_id"
                                  v-if="contactListSelectorEnabledForCallDispositionTrigger">
                        <contact-lists-selector :multiple="false"
                                                :value="selected_list"
                                                :list_type="ContactListTypes.STATIC_LIST"
                                                v-model="trigger.list_id"
                                                @change="changeList"/>
                    </el-form-item>
                </div>
            </div>

            <div class="row no-select">
                <div class="col-12">
                    <span class="pull-right _600 opacity-5"
                          :class="[ trigger.valid ? 'text-dark-greenish' : 'text-danger' ]">
                        <i class="fa fa-check-circle"
                           v-if="trigger.valid"></i>
                        <i class="fa fa-times-circle"
                           v-else></i>
                        {{ trigger.valid ? 'Valid trigger' : 'Invalid trigger' }}
                    </span>
                </div>
            </div>
        </el-form>
        <el-form ref="trigger_form"
                :model="trigger"
                :rules="form_trigger"
                v-else-if="trigger.type == 'dnc status'"
                @submit.prevent.native="">
            <div class="row">
                <div class="col-12">
                    <span class="inline">When a contact is </span>
                    <el-form-item class="inline mb-3 no-border"
                                  prop="trigger_option">
                        <el-select placeholder="Select"
                                   style="width: 220px"
                                   default-first-option
                                   autocomplete="off"
                                   filterable
                                   clearable
                                   v-model="trigger.trigger_option"
                                   @change="clearData">
                            <el-option :key="trigger_option.value"
                                       :value="trigger_option.value" 
                                       :label="trigger_option.label"
                                       v-for="trigger_option in dnc_trigger_options">
                                <span>{{ trigger_option.label }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <span class="inline">, then enroll to this sequence.</span>
                </div>
            </div>

            <div class="row no-select">
                <div class="col-12">
                    <span class="pull-right _600 opacity-5"
                          :class="[ trigger.valid ? 'text-dark-greenish' : 'text-danger' ]">
                        <i class="fa fa-check-circle"
                           v-if="trigger.valid"></i>
                        <i class="fa fa-times-circle"
                           v-else></i>
                        {{ trigger.valid ? 'Valid trigger' : 'Invalid trigger' }}
                    </span>
                </div>
            </div>
        </el-form>
        <el-form ref="trigger_form"
                 :model="trigger"
                 :rules="form_trigger"
                 v-else-if="trigger.type == 'appointment'"
                 @submit.prevent.native="">
            <div class="row">
                <div class="col-12">
                    <span class="inline">When an appointment is set to </span>
                    <el-form-item class="inline mb-3 no-border"
                                  prop="appointment_status_id">
                        <el-select placeholder="Select Appointment Status"
                                   style="width: 220px"
                                   default-first-option
                                   autocomplete="off"
                                   filterable
                                   clearable
                                   v-model="trigger.appointment_status_id"
                                   @change="clearData">
                            <el-option :label="appointment.label"
                                       :key="appointment.value"
                                       :value="appointment.value"
                                       v-for="appointment in appointmentOptions">
                                <span>{{ appointment.label }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <span class="inline">, then enroll to this sequence</span>
                </div>
            </div>

            <div class="row no-select">
                <div class="col-12">
                    <span class="pull-right _600 opacity-5"
                          :class="[ trigger.valid ? 'text-dark-greenish' : 'text-danger' ]">
                        <i class="fa fa-check-circle"
                           v-if="trigger.valid"></i>
                        <i class="fa fa-times-circle"
                           v-else></i>
                        {{ trigger.valid ? 'Valid trigger' : 'Invalid trigger' }}
                    </span>
                </div>
            </div>
        </el-form>
        <el-form ref="trigger_form"
                 :model="trigger"
                 :rules="form_trigger"
                 v-else-if="trigger.type == 'contact_owner'"
                 @submit.prevent.native="">
            <div class="row">
                <div class="col-12">
                    <span class="inline">
                        When a contact owner is set to
                    </span>
                    <el-form-item class="inline mb-3 no-border"
                                  prop="contact_owner_id">
                        <el-select placeholder="Select an User"
                                   style="width: 220px"
                                   autocomplete="off"
                                   default-first-option
                                   filterable
                                   clearable
                                   v-model="trigger.contact_owner_id"
                                   @change="clearData">
                            <el-option :label="user.name"
                                       :key="user.id"
                                       :value="user.id"
                                       v-for="user in filteredUsers">
                                <div class="media">
                                    <div class="media-body">
                                        <label class="cursor-pointer">{{ user.name }}</label>
                                        <small>{{ user.email }}</small>
                                    </div>
                                </div>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <span class="inline">, then </span>
                    <el-form-item class="inline mb-3 no-border"
                                  prop="enroll_action">
                        <el-select placeholder="Select Action"
                                   style="width: 220px"
                                   autocomplete="off"
                                   default-first-option
                                   filterable
                                   clearable
                                   v-model="trigger.enroll_action"
                                   @change="clearData">
                            <el-option :label="action.name"
                                       :key="action.id"
                                       :value="action.value"
                                       v-for="action in availableActions">
                                <span>{{ action.name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>

            <div class="row no-select">
                <div class="col-12">
                    <span class="pull-right _600 opacity-5"
                          :class="[ trigger.valid ? 'text-dark-greenish' : 'text-danger' ]">
                        <i class="fa fa-check-circle"
                           v-if="trigger.valid"></i>
                        <i class="fa fa-times-circle"
                           v-else></i>
                        {{ trigger.valid ? 'Valid trigger' : 'Invalid trigger' }}
                    </span>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import _ from 'lodash'
import auth from '../auth'
import {acl_mixin, form_validation_mixin} from '../mixins'
import {mapGetters, mapState} from 'vuex'
import HubspotTicketingPipelineSelector from "./hubspot-ticket-composer"
import * as CommunicationDispositionStatus from '../constants/communication-disposition-status'
import * as CommunicationDirection from '../constants/communication-direction'
import * as CommunicationTypes from '../constants/communication-types'
import * as TagCategory from '../constants/tag-categories'
import * as ContactListModuleTypes from '../constants/contact-list-module-types'
import * as ContactListTypes from '../constants/contact-list-types'
import * as TriggerVariables from '../constants/trigger-variables'
import * as TriggerOperations from '../constants/trigger-operations'
import ContactListsSelector from './contact-lists-selector'
import AlAlert from '../components/alert'
import {TAGS_DEPRECATION_MESSAGE} from '../constants/deprecation.messages'

export default {
    mixins: [
        acl_mixin,
        form_validation_mixin
    ],

    components: {
        HubspotTicketingPipelineSelector,
        ContactListsSelector,
        AlAlert
    },

    props: {
        trigger: {
            required: true
        },

        automation_trigger: {
            type: Boolean,
            default: false,
            required: false
        },

        power_dialer_trigger: {
            type: Boolean,
            default: false,
            required: false
        },

        disable_any_contact_disposition: {
            type: Boolean,
            default: false,
            required: false
        },

        disable_any_call_disposition: {
            type: Boolean,
            default: false,
            required: false
        },

        used_contact_disposition_statuses: {
            required: false
        }
    },

    data() {
        return {
            auth: auth,
            TAGS_DEPRECATION_MESSAGE,
            directions: [
                CommunicationDirection.INBOUND,
                CommunicationDirection.OUTBOUND
            ],
            disposition_status: [
                CommunicationDispositionStatus.DISPOSITION_STATUS_ABANDONED_NEW,
                CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW,
                CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_AND_VOICEMAIL_NEW,
                CommunicationDispositionStatus.DISPOSITION_STATUS_VOICEMAIL_NEW,
                CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW,
                CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW,
                CommunicationDispositionStatus.DISPOSITION_STATUS_PLACED_NEW,
                CommunicationDispositionStatus.DISPOSITION_STATUS_DEADEND_NEW
            ],
            symbols: [
                '>=',
                '<='
            ],
            variables: TriggerVariables.TRIGGER_VARIABLES,
            operations: TriggerOperations.TRIGGER_OPERATIONS,
            keywords: [
                {
                    value: 'yes',
                    label: 'yes'
                },
                {
                    value: 'interested',
                    label: 'interested'
                },
                {
                    value: 'not interested',
                    label: 'not interested'
                },
                {
                    value: 'stop',
                    label: 'stop'
                },
                {
                    value: 'more info',
                    label: 'more info'
                },
                {
                    value: 'thanks',
                    label: 'thanks'
                }
            ],
            dnc_trigger_options: [
                {
                    value: 1,
                    label: 'Set to DNC'
                },
                {
                    value: 2,
                    label: 'Removed from DNC'
                }
            ],
            form_trigger: {
                direction: [
                    {
                        required: true,
                        message: 'Please select a direction',
                        trigger: 'change'
                    }
                ],
                disposition_status: [
                    {
                        required: true,
                        message: 'Please select a disposition status',
                        trigger: 'change'
                    }
                ],
                enroll_action: [
                    {
                        required: true,
                        message: 'Please select an action',
                        trigger: 'change'
                    }
                ],
                operation: [
                    {
                        required: true,
                        message: 'Please select an operation',
                        trigger: 'change'
                    }
                ],
                disposition_status_id: [
                    {
                        required: true,
                        message: 'Please select a disposition',
                        trigger: 'change'
                    }
                ],
                appointment_status_id: [
                    {
                        required: true,
                        message: 'Please select appointment status',
                        trigger: 'change'
                    }
                ],
                call_disposition_id: [
                    {
                        required: true,
                        message: 'Please select a call disposition',
                        trigger: 'change'
                    }
                ],
                tag_ids: [
                    {
                        type: 'array',
                        required: true,
                        message: 'Please select one or more tags',
                        trigger: 'change'
                    }
                ],
                workflow_ids: [
                    {
                        type: 'array',
                        required: true,
                        message: 'Please select a sequence',
                        trigger: 'change'
                    }
                ],
                list_id: [
                    {
                        required: true,
                        message: 'Please select a contact list',
                        trigger: 'change'
                    }
                ],
                variable: [
                    {
                        required: true,
                        message: 'Please select a variable',
                        trigger: 'change'
                    }
                ],
                symbol: [
                    {
                        required: true,
                        message: 'Please select a math symbol',
                        trigger: 'change'
                    }
                ],
                value: [
                    {
                        required: true,
                        message: 'Please provide a number',
                        trigger: 'blur'
                    }
                ],
                keywords: [
                    {
                        type: 'array',
                        required: true,
                        message: 'Please add or select one or more keywords',
                        trigger: 'change'
                    }
                ],
                stage_id: [
                    {
                        required: true,
                        message: 'Please select a pipeline and stage',
                        trigger: 'change'
                    }
                ],
                trigger_option: [
                    {
                        required: true,
                        message: 'Please select an option',
                        trigger: 'change'
                    }
                ],
                tag_option_id: [
                    {
                        required: true,
                        message: 'Please select an option',
                        trigger: 'change'
                    }
                ],
                contact_list_option_id: [
                    {
                        required: true,
                        message: 'Please select an option',
                        trigger: 'change'
                    }
                ],
                contact_owner_id: [
                    {
                        required: true,
                        message: 'Please select an user',
                        trigger: 'change'
                    }
                ]
            },
            workflow_id: null,
            selected_list: null,
            CommunicationDispositionStatus,
            CommunicationDirection,
            CommunicationTypes,
            TagCategory,
            ContactListModuleTypes,
            ContactListTypes,
            trigger_types: [
                'contact',
                'disposition',
                'call disposition',
                'appointment',
                'dnc status',
                'tag',
                'remove_tag',
                'contact_list',
                'remove_contact_list',
                'contact_owner'
            ]
        }
    },

    computed: {
        ...mapState(['disposition_statuses', 'call_dispositions']),

        ...mapState('cache', ['current_company']),

        ...mapGetters('cache', ['isSimpSocial']),

        ...mapState(['users']),

        availableVariables() {
            if (!this.trigger || !this.trigger.type) {
                return []
            }

            // for calls
            if (this.trigger.type === CommunicationTypes.CALL) {
                switch (this.trigger.disposition_status) {
                    case CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW:
                        return this.variables.filter(variable => ![TriggerVariables.TRIGGER_VARIABLE_HAS_ALL_KEYWORDS.value, TriggerVariables.TRIGGER_VARIABLE_HAS_ANY_KEYWORDS.value].includes(variable.value))
                    case CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW:
                    case CommunicationDispositionStatus.DISPOSITION_STATUS_ABANDONED_NEW:
                        return this.variables.filter(variable => [TriggerVariables.TRIGGER_VARIABLE_WAIT_TIME.value, TriggerVariables.TRIGGER_VARIABLE_DURATION.value].includes(variable.value))
                    case CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW:
                        return []
                    default:
                        return this.variables.filter(variable => ![TriggerVariables.TRIGGER_VARIABLE_HAS_ALL_KEYWORDS.value, TriggerVariables.TRIGGER_VARIABLE_HAS_ANY_KEYWORDS.value, TriggerVariables.TRIGGER_VARIABLE_LAST_TRANSFERRED_LEG.value].includes(variable.value))
                }
            }

            // for text messages
            if (this.trigger.type === CommunicationTypes.SMS) {
                let ids = [TriggerVariables.TRIGGER_VARIABLE_HAS_ALL_KEYWORDS.id, TriggerVariables.TRIGGER_VARIABLE_HAS_ANY_KEYWORDS.id]
                // add the first time message variable for inbound messages
                if (this.trigger.direction === CommunicationDirection.INBOUND) {
                    ids.push(TriggerVariables.TRIGGER_VARIABLE_FIRST_TIME_MESSAGE.id)
                }
                return this.variables.filter(variable => ids.includes(variable.id))
            }

            if (this.trigger.type === CommunicationTypes.EMAIL) {
                return []
            }
        },

        filteredUsers() {
            if (this.users) {
                return this.users.filter((user) =>
                    !(user.role_names && user.role_names.length === 1 && user.read_only_access) && !user.is_destination
                )
            }
            return []
        },

        availableDispositionStatus() {
            if (!this.trigger || !this.trigger.type) {
                return []
            }

            // for calls
            if (this.trigger.type === CommunicationTypes.CALL) {
                return this.disposition_status
            }

            // for text messages and emails
            if (this.trigger.type === CommunicationTypes.SMS) {
                return [
                    CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW
                ]
            }

            if (this.trigger.type === CommunicationTypes.EMAIL) {
                this.trigger.disposition_status = CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW
                return [
                    CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW
                ]
            }
        },

        availableActions() {
            if (!this.trigger || !this.trigger.type) {
                return []
            }

            if (this.power_dialer_trigger) {
                return [
                    {
                        id: 1,
                        name: 'Apply tags to contact',
                        value: 'tag_contact'
                    },
                    {
                        id: 2,
                        name: 'Remove tags from contact',
                        value: 'untag_contact'
                    },
                    {
                        id: 3,
                        name: 'Remove task tag from contact',
                        value: 'untag'
                    },
                    {
                        id: 4,
                        name: 'Enroll to a sequence',
                        value: 'enroll'
                    }
                ]
            }

            if (!this.automation_trigger && !this.power_dialer_trigger) {
                return [
                    {
                        id: 1,
                        name: 'Enroll to this sequence',
                        value: 'enroll'
                    },
                    {
                        id: 2,
                        name: 'Disenroll from this sequence',
                        value: 'disenroll'
                    },
                    {
                        id: 3,
                        name: 'Add contact to list',
                        value: 'add_to_list'
                    },
                    {
                        id: 4,
                        name: 'Remove contact from list',
                        value: 'remove_from_list'
                    },
                    {
                        id: 5,
                        name: 'Remove contact from all lists',
                        value: 'remove_from_all_lists'
                    }
                ]
            }

            return [
                {
                    id: 1,
                    name: 'Enroll to this sequence',
                    value: 'enroll'
                },
                {
                    id: 2,
                    name: 'Disenroll from this sequence',
                    value: 'disenroll'
                }
            ]
        },

        contactDispositionStatusesAlphabeticalOrder() {
            if (this.disposition_statuses) {
                let disposition_statuses = _.clone(this.disposition_statuses)
                disposition_statuses = disposition_statuses.sort((a, b) => {
                    let textA = a.name.toUpperCase()
                    let textB = b.name.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
                if (!this.disable_any_contact_disposition) {
                    disposition_statuses.unshift({
                        id: 0,
                        name: 'Any Disposition'
                    })
                }

                return disposition_statuses
            }

            return []
        },

        callDispositionAlphabeticalOrder() {
            if (this.call_dispositions) {
                let call_dispositions = _.clone(this.call_dispositions)
                call_dispositions = call_dispositions.sort((a, b) => {
                    let textA = a.name.toUpperCase()
                    let textB = b.name.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
                if (!this.disable_any_call_disposition) {
                    call_dispositions.unshift({
                        id: 0,
                        name: 'Any Call Disposition'
                    })
                }

                return call_dispositions
            }

            return []
        },

        availableContactDispositionStatus() {
            if (this.used_contact_disposition_statuses) {
                let used_contact_disposition_statuses = this.used_contact_disposition_statuses.filter(disposition_status_id => disposition_status_id != this.trigger.disposition_status_id)
                return this.contactDispositionStatusesAlphabeticalOrder.filter(disposition_status => !used_contact_disposition_statuses.includes(disposition_status.id))
            }

            return this.contactDispositionStatusesAlphabeticalOrder
        },

        availableTagOptions() {
            return [
                {
                    id: 1,
                    name: 'Is any',
                    value: 'is_any'
                },
                {
                    id: 2,
                    name: 'Is none',
                    value: 'is_none'
                }
            ]
        },

        availableContactListOptions() {
            return [
                {
                    id: 1,
                    name: 'Is equal to',
                    value: 'is_equal'
                },
                {
                    id: 2,
                    name: 'Is not equal to',
                    value: 'is_not_equal'
                }
            ]
        },

        appointmentOptions() {
            return [
                {
                    value: CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_SET,
                    label: 'Set'
                },
                {
                    value: CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_SHOWN,
                    label: this.isSimpSocial ? 'Shown' : 'Completed'
                },
                {
                    value: CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_CANCELED,
                    label: 'Cancelled'
                },
                {
                    value: CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_MISSED,
                    label: 'Missed'
                }
            ]
        },

        triggerOperationName() {
            const operation = _.find(this.operations, {value: this.trigger.operation})
            return operation.name
        },

        symbolWords() {
            switch (this.trigger.symbol) {
                case '<=':
                    return 'less than or equals'
                case '>=':
                    return 'greater than or equals'
            }
        },

        hubspotEnabled() {
            if (this.current_company &&
                this.current_company.hubspot_integration_enabled) {
                return true
            }

            return false
        },

        workflowSelectorEnabledForCallDispositionTrigger() {
            return this.hasPermissionTo(['list workflow', 'view workflow']) && ( this.trigger.enroll_action && !['add_to_list', 'remove_from_list', 'remove_from_all_lists'].includes(this.trigger.enroll_action)) && !this.automation_trigger && ((this.power_dialer_trigger && this.trigger.enroll_action == 'enroll') || !this.power_dialer_trigger)
        },

        tagsSelectorEnabledForCallDispositionTrigger() {
            return this.hasPermissionTo(['list tag', 'view tag']) && ['tag_contact', 'untag_contact'].includes(this.trigger.enroll_action)
        },

        contactListSelectorEnabledForCallDispositionTrigger() {
            return this.hasPermissionTo(['view contact list']) && ['add_to_list', 'remove_from_list'].includes(this.trigger.enroll_action)
        },

        contactTriggerOperations() {
            return this.operations.filter(operation => !['remove_from_list', 'remove_from_all_lists'].includes(operation.value))
        },

        shouldShowTriggerVariable() {
            return this.trigger.direction && this.trigger.disposition_status && this.trigger.disposition_status !== CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW && this.availableVariables.length > 0
        },

        shouldShowOperationForTrigger() {
            const availableOptions = ['tag', 'tag_contact', 'untag_contact', 'enroll', 'dispose', 'create_hubspot_ticket', 'contact_list', 'remove_contact_list', 'add_to_list', 'remove_from_list']
            return this.trigger.operation && availableOptions.includes(this.trigger.operation)
        }
    },

    created() {
        if (this.trigger.workflow_ids && this.trigger.workflow_ids.length > 0) {
            this.workflow_id = this.trigger.workflow_ids[0]
        }

        if (this.automation_trigger) {
            this.addOperation({
                name: 'Disenroll from this sequence',
                value: 'disenroll'
            })
        }

        if (this.power_dialer_trigger) {
            this.addOperation({
                name: 'Remove task tag from contact',
                value: 'untag'
            })
        }

        if (!this.automation_trigger && !this.power_dialer_trigger && this.hubspotEnabled) {
            this.addOperation({
                name: 'Create HubSpot ticket',
                value: 'create_hubspot_ticket'
            })
        }

        if (!['contact'].includes(this.trigger.type)) {
            // check if we have `untag_contact` in the operations array
            this.addOperation({
                name: 'Remove tags from contact',
                value: 'untag_contact'
            }, 'tag_contact')
        }
    },

    mounted() {
        // If disposition status id is not in available communication disposition statuses now, set it as null and trigger validation
        if (this.availableContactDispositionStatus.findIndex((contact_disposition) => contact_disposition.id === this.trigger.disposition_status_id) < 0) {
            this.trigger.disposition_status_id = null
        }

        // If disposition status id is not in available call disposition statuses now, set it as null and trigger validation
        if (this.callDispositionAlphabeticalOrder.findIndex((call_disposition) => call_disposition.id === this.trigger.call_disposition_id) < 0) {
            this.trigger.call_disposition_id = null
        }

        this.customPreValidateForm('trigger_form')
    },

    methods: {
        resetVariable() {
            this.trigger.variable = null
            this.trigger.symbol = null
            this.trigger.value = 0
        },

        clearConditions() {
            this.resetVariable()
            this.trigger.keywords = []
            if (this.availableVariables.length == 1) {
                this.trigger.variable = this.availableVariables[0].value
            }

            this.customPreValidateForm('trigger_form')
        },

        clearData() {
            this.workflow_id = null

            if (!this.automation_trigger) {
                this.trigger.tag_ids = []
                this.trigger.workflow_ids = []
            }

            if (!this.automation_trigger && !this.power_dialer_trigger) {
                this.trigger.disposition_status_id = []
                this.trigger.list_id = []
            }

            this.customPreValidateForm('trigger_form')
        },

        checkTriggerForm() {
            this.resetVariable()
            this.trigger.has_condition = false
            this.trigger.operation = null
            this.clearData()
        },

        changeTags(event) {
            this.trigger.tag_ids = event
            this.customPreValidateForm('trigger_form')
        },

        changeContactList(event) {
            this.trigger.contact_list_id = event
            this.customPreValidateForm('trigger_form')
        },

        changeWorkflows(event) {
            if (event) {
                this.trigger.workflow_ids = [event]
            } else {
                this.trigger.workflow_ids = []
            }
            this.customPreValidateForm('trigger_form')
        },

        changeDispositionStatus(event) {
            this.trigger.disposition_status_id = event
            this.customPreValidateForm('trigger_form')
        },

        changeTicketName(ticket_name) {
            this.trigger.ticket_name = ticket_name
            this.customPreValidateForm('trigger_form')
        },

        changeSelectedPipeline(pipeline_id) {
            this.trigger.pipeline_id = pipeline_id
            this.trigger.stage_id = null
            this.customPreValidateForm('trigger_form')
        },

        changeSelectedStage(stage_id) {
            this.trigger.stage_id = stage_id
            this.customPreValidateForm('trigger_form')
        },

        changeTicketDescription(ticket_description) {
            this.trigger.ticket_description = ticket_description
            this.customPreValidateForm('trigger_form')
        },

        changeTagOptionStatus(event) {
            this.trigger.tag_option_id = event
            this.customPreValidateForm('trigger_form')
        },

        customPreValidateForm: _.debounce(function (form_name) {
            let form_element = _.get(this.$refs, form_name, null)

            if (!form_element) {
                return
            }

            let fields = form_element.fields
            if (fields.find((f) => f.validateState === 'validating')) {
                setTimeout(() => {
                    this.customPreValidateForm(form_name)
                }, 100)

                return
            }

            if (this.validateForm(form_name)) {
                this.validated = true
                this.trigger.valid = this.validated
                this.$emit('valid')
                return
            }

            this.validated = false
            this.trigger.valid = this.validated
            this.$emit('invalid')
        }, 100),

        addOperation(operationToAdd, afterOperation) {
            const existingOperation = this.operations.find(operation => operation.value === operationToAdd.value)
            // if not found, add it to the operation array
            if (existingOperation) {
                return
            }
            let insertionIndex = this.operations.length
            // find the biggest id in the operation array
            const biggestId = Math.max.apply(Math, this.operations.map(operation => operation.id))
            if (afterOperation) {
                const searchIndex = this.operations.findIndex(operation => operation.value === afterOperation)
                if (searchIndex >= 0) {
                    insertionIndex = searchIndex + 1
                }
            }
            this.operations.splice(insertionIndex, 0, {
                id: biggestId + 1,
                name: operationToAdd.name,
                value: operationToAdd.value
            })
        },

        changeList(selectedId) {
            if (selectedId) {
                this.trigger.list_id = selectedId
            } else {
                this.trigger.list_id = null
            }
            this.customPreValidateForm('trigger_form')
        }
    },

    watch: {
        validated() {
            if (this.validated) {
                this.$emit('valid')
            } else {
                this.$emit('invalid')
            }

            this.trigger.valid = this.validated
        }
    }
}
</script>
