var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-tool",
    {
      attrs: {
        "tool-name": _vm.tool.name,
        "tool-description": _vm.tool.description,
      },
      on: { back: _vm.goBack },
    },
    [
      _c(
        "el-select",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "custom-functions-selector",
          staticClass: "w-full",
          attrs: { placeholder: _vm.placeholder },
          on: { change: _vm.selectCustomFunction },
          model: {
            value: _vm.custom_function_id,
            callback: function ($$v) {
              _vm.custom_function_id = $$v
            },
            expression: "custom_function_id",
          },
        },
        _vm._l(_vm.custom_functions, function (custom_function) {
          return _c(
            "el-option",
            {
              key: custom_function.uuid,
              attrs: {
                label: custom_function.name,
                value: custom_function.uuid,
              },
            },
            [
              _c("span", { staticClass: "ml-2" }, [
                _vm._v(_vm._s(custom_function.name)),
              ]),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }