<template>
    <base-tool
        :tool-name="tool.name"
        :tool-description="tool.description"
        @back="goBack"
    >
        <el-select
            ref="custom-functions-selector"
            v-model="custom_function_id"
            v-loading="loading"
            class="w-full"
            :placeholder="placeholder"
             @change="selectCustomFunction"
        >
            <el-option
                v-for="custom_function in custom_functions"
                :key="custom_function.uuid"
                :label="custom_function.name"
                :value="custom_function.uuid"
            >
                <span class="ml-2">{{ custom_function.name }}</span>
            </el-option>
        </el-select>
    </base-tool>
</template>

<script>
import BaseTool from './base-tool.vue'
import * as AloAi from '../../../../constants/aloai'
import ToolMixin from './tool-mixin'

export default {
    name: 'CustomFunctionTool',

    mixins: [ToolMixin],

    components: {
        BaseTool,
    },

    props: {
        tool: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            placeholder: 'Select Custom Function',
            custom_function_id: null,
            loading: false,
            custom_functions: [],
        }
    },

    mounted() {
        this.getCustomFunctions()
    },

    methods: {
        async getCustomFunctions() {
            this.loading = true
            axios.get('/api/v1/aloai/tools')
                .then(res => {
                    this.custom_functions = res.data.data.filter(tool => tool.custom_type !== null)
                }).catch(err => {
                    console.log(err)
                }).finally(() => {
                    this.loading = false
                })
        },
        selectCustomFunction(id) {
            this.custom_function_id = id
            this.$emit('custom-function-selected', this.custom_functions.filter(tool => tool.uuid === id)[0])
        },
    }
}
</script>
