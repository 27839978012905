<template>
    <el-select ref="alo-ai-bots-selector"
               v-model="aloai_bot_id"
               v-loading="loading"
               class="w-full"
               :placeholder="placeholder"
               :multiple="multiple"
               :clearable="clearable"
               :collapse-tags="!no_collapse"
               :size="size"
               filterable
               default-first-option
               @change="selectAloAiBot">
        <template v-if="group_by_type">
            <el-option-group
                v-for="group in aloAiBotsGroupedByType"
                :key="group.type"
                :label="group.label">
                <el-option 
                    v-for="bot in group.bots"
                    :key="bot.id"
                    :label="bot.name"
                    :value="bot.id"
                    :disabled="!bot.enabled">
                    <el-tooltip :disabled="bot.enabled"
                                content="This AI agent is disabled"
                                placement="left">
                        <span class="ml-2">{{ bot.name }}</span>
                    </el-tooltip>
                </el-option>
            </el-option-group>
        </template>
        <template v-else>
            <el-option v-for="bot in aloAiBotsAlphabeticalOrder"
                       :key="bot.id"
                       :label="bot.name"
                       :value="bot.id"
                       :disabled="!bot.enabled">
                <el-tooltip :disabled="bot.enabled"
                            content="This AI agent is disabled"
                            placement="left">
                    <span class="ml-2">{{ bot.name }}</span>
                </el-tooltip>
            </el-option>
        </template>
    </el-select>
</template>

<script>
import {mapState} from 'vuex'
import {clone} from 'lodash'
import * as AloAi from '../../constants/aloai'

export default {
    props: {
        value: {
            required: false,
            type: [Number, String]
        },

        multiple: {
            type: Boolean,
            required: false,
            default: false
        },

        no_collapse: {
            type: Boolean,
            required: false,
            default: false
        },

        clearable: {
            type: Boolean,
            required: false,
            default: false
        },

        size: {
            type: String,
            default: '',
            required: false
        },

        type: {
            type: Number,
            default: null,
            required: false
        },

        direction: {
            type: Number,
            default: null,
            required: false
        },

        group_by_type: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            aloai_bot_id: this.value,
            loading: false,
            aloai_bots: [],
        }
    },

    computed: {
        ...mapState('cache', [
            'current_company'
        ]),

        placeholder() {
            return this.multiple ? 'Select AloAi Agents' : 'Select AloAi Agent'
        },

        aloAiBotsAlphabeticalOrder() {
            let bots = clone(this.aloai_bots)

            return bots.sort((a, b) => {
                let textA = a.name.toUpperCase()
                let textB = b.name.toUpperCase()
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
            })
        },

        aloAiBotsGroupedByType() {
            let bots = clone(this.aloai_bots)
            let text_bots = bots.filter(bot => bot.type === AloAi.TYPE_TEXT)
                .sort((a, b) => {
                    let textA = a.name.toUpperCase()
                    let textB = b.name.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })

            let voice_bots = bots.filter(bot => {
                    if (!this.current_company.aloai_outbound_voice_agents_enabled) {
                        return bot.type === AloAi.TYPE_VOICE && bot.direction !== AloAi.DIRECTION_OUTBOUND
                    }
                    return bot.type === AloAi.TYPE_VOICE
                })
                .sort((a, b) => {
                    let textA = a.name.toUpperCase()
                    let textB = b.name.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })

            let groups = [
                { type: AloAi.TYPE_VOICE, label: 'Outbound Voice Agents', bots: voice_bots },
                { type: AloAi.TYPE_TEXT, label: 'Outbound Text Agents', bots: text_bots },
            ]

            return groups.filter(group => group.bots.length > 0)
        }
    },

    mounted() {
        if (this.current_company.aloai_enabled) {
            this.getAloAiBots()
        }
    },

    methods: {
        selectAloAiBot(bot) {
            this.aloai_bot_id = bot
            this.$emit('change', bot)
        },

        async getAloAiBots() {
            this.loading = true
            let res = null
            let params = {
                page: 1,
                size: 50
            }

            if (this.type) {
                params.type = this.type === AloAi.TYPE_VOICE ? 'voice' : 'text'
            }

            if (this.direction) {
                params.direction = this.direction === AloAi.DIRECTION_OUTBOUND ? 'outbound' : 'inbound'
            }

            do {
                res = await axios.get('/api/v1/aloai/bot', {params: params})
                this.aloai_bots.push(...res.data.data)
                params.page++
            } while (res.data.pagination.next_page_url)

            this.loading = false
        }
    },

    watch: {
        value() {
            this.aloai_bot_id = this.value
        }
    }
}
</script>
