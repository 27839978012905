var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full width-500" },
    [
      _c("div", { staticClass: "login-brand" }, [
        _c("img", { attrs: { src: _vm.statics.logo, alt: "." } }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dashboard-intro mt-3 mb-3" },
        [
          !_vm.magic_link
            ? _c(
                "el-form",
                {
                  attrs: {
                    "label-position": "top",
                    "label-width": "300px",
                    model: _vm.user,
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.login.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Email", prop: "email" } },
                    [
                      _c("el-input", {
                        attrs: {
                          id: "email",
                          type: "email",
                          autocomplete: "on",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.login.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.user.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "email", $$v)
                          },
                          expression: "user.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Password", prop: "password" } },
                    [
                      _c("el-input", {
                        attrs: {
                          id: "password",
                          type: "password",
                          autocomplete: "on",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.login.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.user.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "password", $$v)
                          },
                          expression: "user.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "m-b-md" }, [
                    _c("label", { staticClass: "md-check no-select" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user.remember_me,
                            expression: "user.remember_me",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.user.remember_me)
                            ? _vm._i(_vm.user.remember_me, null) > -1
                            : _vm.user.remember_me,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.user.remember_me,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.user,
                                    "remember_me",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.user,
                                    "remember_me",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.user, "remember_me", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("i", { staticClass: "greenish" }),
                      _vm._v(" Keep me signed in\n                "),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.site_key
                    ? _c("vue-recaptcha", {
                        ref: "recaptcha",
                        staticClass: "g-recaptcha",
                        attrs: { sitekey: _vm.site_key },
                        on: { verify: _vm.onVerify, expired: _vm.onExpired },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "p-x-md btn-block mt-2",
                      attrs: {
                        type: "success",
                        disabled:
                          _vm.site_key !== "" &&
                          (_vm.loading || !_vm.user.recaptcha_response),
                      },
                      on: { click: _vm.login },
                    },
                    [
                      _c(
                        "i",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          staticClass: "material-icons loader pull-right",
                        },
                        [_vm._v("")]
                      ),
                      _vm._v("\n                Sign in\n                "),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.loading,
                            expression: "!loading",
                          },
                        ],
                        staticClass:
                          "el-icon-arrow-right el-icon-right pull-right",
                      }),
                    ]
                  ),
                ],
                1
              )
            : _c("div", { staticClass: "text-center" }, [
                _c("h4", { staticClass: "text-black" }, [
                  _vm._v("2FA Email Sent"),
                ]),
                _vm._v(" "),
                _c("p", { domProps: { innerHTML: _vm._s(_vm.error) } }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mb-2" },
                  [
                    _c("security-code", {
                      ref: "security_code",
                      staticClass: "mb-2",
                      on: { input: _vm.clearError, completed: _vm.verifyToken },
                      model: {
                        value: _vm.token,
                        callback: function ($$v) {
                          _vm.token = $$v
                        },
                        expression: "token",
                      },
                    }),
                    _vm._v(" "),
                    _vm.verification_message.length > 0
                      ? _c(
                          "p",
                          { class: "text-" + _vm.verification_message_type },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.verification_message) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("h4", { staticClass: "text-black mt-3" }, [
                  _vm._v("Go check your email!"),
                ]),
              ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "p-v-lg text-center mb-5" }, [
        !_vm.statics.whitelabel && _vm.register_status
          ? _c(
              "div",
              { staticClass: "mb-2" },
              [
                _vm._v("\n            New to Aloware?\n            "),
                _c(
                  "router-link",
                  {
                    staticClass: "text-greenish _600",
                    attrs: { to: { name: "SSU" } },
                  },
                  [_vm._v("\n                Sign up\n            ")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-2" },
          [
            _c(
              "router-link",
              {
                staticClass: "text-greenish _600",
                attrs: { to: { name: "Forgot" } },
              },
              [_vm._v("\n                Forgot password?\n            ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      !_vm.statics.whitelabel
        ? _c("div", { staticClass: "text-center" }, [
            _c(
              "a",
              {
                staticClass: "text-primary",
                attrs: {
                  href: "https://support.aloware.com/en/articles/9037819-troubleshooting-aloware-authentication-issues",
                },
              },
              [
                _vm._v(
                  "\n            Click here if you have trouble authenticating\n        "
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.statics.whitelabel && _vm.statics.host === "app.simpsocial.com"
        ? _c("div", { staticClass: "text-center mt-3" }, [
            _c(
              "div",
              {
                staticClass: "text-black-dk font-weight-bold letter-spacing-1",
              },
              [_vm._v("Download our new and improved app")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-content-center mt-2 store-icons" },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://apps.apple.com/us/app/simpsocial-mobile/id1584474667",
                      target: "_blank",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "ios-container" },
                      [
                        _c(
                          "el-image",
                          {
                            attrs: {
                              src: "assets/images/logos/app-store-badge.png",
                              fit: "fill",
                              alt: "google-badge",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline",
                                }),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://play.google.com/store/apps/details?id=com.simpsocial.mobile",
                      target: "_blank",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "ml-2" },
                      [
                        _c(
                          "el-image",
                          {
                            attrs: {
                              src: "assets/images/logos/google-play-store-badge.png",
                              fit: "fill",
                              alt: "google-badge",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline",
                                }),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("static-assets-preloader", { attrs: { assets: _vm.statics } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }