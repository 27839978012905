var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tool-content" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between align-items-center mb-3",
        },
        [
          _c("h5", { staticClass: "m-0 tool-title" }, [
            _vm._v(_vm._s(_vm.toolName)),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "back-button",
              attrs: { size: "mini", type: "text" },
              on: {
                click: function ($event) {
                  return _vm.$emit("back")
                },
              },
            },
            [
              _c("span", { staticClass: "back-text" }, [
                _vm._v("← Back to tools"),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { staticClass: "tool-description mb-3" }, [
        _vm._v(_vm._s(_vm.toolDescription)),
      ]),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }