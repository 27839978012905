<template>
    <div class="app-body"
         id="view"
         v-if="team">
        <div class="fixed-header padding pt-0 pb-0"
             :style="{top: (top_offset+50) + 'px'}">
            <div class="row mb-0 mt-0">
                <div class="col-12 p-2">
                    <div class="d-flex">
                        <div class="d-flex flex-fill">
                            <comm-advanced-filters-wrapper data-testid="activity-comm-advance-filters"
                                                           :team_id="team_id">
                            </comm-advanced-filters-wrapper>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="section === 'Activities'">
            <div class="padding">
                <div class="padding"
                     v-if="hasPermissionTo('list report')">
                    <div class="row">
                        <div class="col-12 p-0 mt-5">
                            <new-summary-report data-testid="activity-new-summary-report"
                                                :team_id="team_id">
                            </new-summary-report>
                        </div>
                    </div>
                </div>

                <div class="padding"
                     v-if="hasPermissionTo('list communication')">
                    <div class="row box">
                        <div class="col-12">
                            <div class="no-effect">
                                <div class="box-body">
                                    <comm-log-table data-testid="activity-comm-log-table"
                                                    :team_id="team_id">
                                    </comm-log-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-backtop target="html"
                    ref="backtop"
                    data-testid="activity-backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import auth from '../../auth'
import {
    acl_mixin,
    filter_mixin,
    scroll_mixin,
    styling_mixin,
} from '../../mixins'

export default {
    mixins: [
        scroll_mixin,
        filter_mixin,
        acl_mixin,
        styling_mixin
    ],

    props: {
        team_id: {
            required: true
        }
    },

    data() {
        return {
            auth: auth,
            team: null,
            reports_first_load: true
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company,
            show_graph: state => state.cache.show_graph
        }),

        ...mapGetters({
            section: 'getSection'
        }),
    },

    mounted() {
        this.setTitle()
        this.getTeam(this.team_id).then(() => {
            VueEvent.fire('page_title_updated', this.team.name)
        })
    },

    methods: {
        setTitle() {
            axios.get('/get-statics')
                .then(res => {
                    let name = res.data.name
                    this.setPageTitle('Team Activity - ' + name)
                })
                .catch(err => {
                    this.setPageTitle('Team Activity - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },

        getTeam(team_id) {
            return this.$APIV2.Teams.show(team_id)
                .then(res => {
                    this.team = res.data.data
                    return Promise.resolve(res)
                })
                .catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    return Promise.reject(err)
                })
        },

        reset() {
            this.team = null
        }
    },

    watch: {
        $route({params, query}) {
            this.reset()
            this.getTeam(this.team_id).then(() => {
                VueEvent.fire('page_title_updated', this.team.name)
            })
        },
        'show_graph': function () {
            this.reports_first_load = false
        }
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                if (res.data.user.is_reseller) {
                    next({name: 'Account Management Portal'})
                } else {
                    next()
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script> 