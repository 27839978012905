var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      staticClass: "p-0",
      attrs: {
        width: "400",
        trigger: "click",
        "popper-class": "variable-popover",
        placement: _vm.popoverPlacement,
      },
      on: { show: _vm.popoverShow },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("tag-selector", {
              attrs: {
                "data-testid": "aloai-tags-category-selector",
                category_prop: _vm.category,
                multiple: true,
                "no-collapse": true,
                custom_tags: _vm.customTags,
                disabled_options: _vm.disabled,
                disabled: _vm.disabled,
                clearable: false,
              },
              on: {
                change: function ($event) {
                  return _vm.emitSelectedTags($event)
                },
              },
              model: {
                value: _vm.selected_tags,
                callback: function ($$v) {
                  _vm.selected_tags = $$v
                },
                expression: "selected_tags",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-button",
        {
          class: _vm.variableBtnClass,
          attrs: {
            slot: "reference",
            id: "tags-popover-trigger",
            size: "mini",
            circle: "",
            disabled: _vm.disabled,
          },
          slot: "reference",
        },
        [_c("i", { class: "text-green " + _vm.icon })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }