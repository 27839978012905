var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-tool",
    {
      attrs: {
        "tool-name": _vm.tool.name,
        "tool-description": _vm.tool.description,
      },
      on: { back: _vm.goBack },
    },
    [
      _c(
        "div",
        { staticClass: "appointment-tool-container" },
        [
          _c("p", { staticClass: "mb-2 text-dark" }, [
            _vm._v("Assign the appointments to this user"),
          ]),
          _vm._v(" "),
          _c("user-selector", {
            attrs: { multiple: false, hide_extensions: true },
            on: { change: _vm.handleUserChange },
            model: {
              value: _vm.selectedUser,
              callback: function ($$v) {
                _vm.selectedUser = $$v
              },
              expression: "selectedUser",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }