var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ivr_step
    ? _c(
        "el-form",
        {
          ref: "ivr_step_form",
          attrs: { model: _vm.ivr_step, rules: _vm.rules },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          !_vm.ivr_step.listen_for_extension &&
          (!_vm.ivr_step.type || _vm.ivr_step.type !== "exit")
            ? [
                _c(
                  "el-form-item",
                  { staticClass: "inline mb-3 mr-1", attrs: { prop: "key" } },
                  [
                    _c("span", { staticClass: "el-form-item__label inline" }, [
                      _vm._v("When the caller presses"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "120px" },
                        attrs: { placeholder: "Select a key" },
                        on: {
                          change: function ($event) {
                            return _vm.preValidateForm("ivr_step_form")
                          },
                        },
                        model: {
                          value: _vm.ivr_step.key,
                          callback: function ($$v) {
                            _vm.$set(_vm.ivr_step, "key", $$v)
                          },
                          expression: "ivr_step.key",
                        },
                      },
                      _vm._l(_vm.availableKeys, function (key) {
                        return _c("el-option", {
                          key: key,
                          attrs: { label: key, value: key },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.ivr_step.key
                  ? [
                      _c(
                        "el-form-item",
                        { staticClass: "inline mb-3", attrs: { prop: "task" } },
                        [
                          _c(
                            "span",
                            { staticClass: "el-form-item__label inline" },
                            [_vm._v("then ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: {
                                placeholder: "Select a task",
                                filterable: "",
                                clearable: "",
                              },
                              on: {
                                change: _vm.changeIvrTask,
                                clear: function ($event) {
                                  return _vm.preValidateForm("ivr_step_form")
                                },
                              },
                              model: {
                                value: _vm.ivr_step.task,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ivr_step, "task", $$v)
                                },
                                expression: "ivr_step.task",
                              },
                            },
                            _vm._l(_vm.tasksAlphabeticalOrder, function (task) {
                              return _c(
                                "el-option",
                                {
                                  key: task.id,
                                  attrs: {
                                    label: task.name,
                                    value: task.value,
                                  },
                                },
                                [
                                  task.value === "route_to_aloai_agent"
                                    ? _c("sparkle-icon", {
                                        attrs: { width: "16", height: "16" },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      class: [
                                        task.value === "route_to_aloai_agent"
                                          ? "ai-effect-gradient-text"
                                          : "",
                                      ],
                                    },
                                    [_vm._v(_vm._s(task.name))]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.ivr_step.task === "route_to_other_campaign"
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "inline mb-3 ml-2",
                              attrs: { prop: "reroute_to_campaign_id" },
                            },
                            [
                              _c("campaign-selector", {
                                ref: "campaign_selector",
                                attrs: {
                                  value: _vm.ivr_step.reroute_to_campaign_id,
                                },
                                on: { change: _vm.changeRerouteToCampaign },
                                model: {
                                  value: _vm.ivr_step.reroute_to_campaign_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ivr_step,
                                      "reroute_to_campaign_id",
                                      $$v
                                    )
                                  },
                                  expression: "ivr_step.reroute_to_campaign_id",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ivr_step.task === "route_to_another_ivr"
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "inline mb-3 ml-2",
                              attrs: { prop: "reroute_to_ivr_id" },
                            },
                            [
                              _c("ivr-selector", {
                                ref: "ivr_selector",
                                attrs: {
                                  value: _vm.ivr_step.reroute_to_ivr_id,
                                },
                                on: { change: _vm.changeRerouteToIvr },
                                model: {
                                  value: _vm.ivr_step.reroute_to_ivr_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ivr_step,
                                      "reroute_to_ivr_id",
                                      $$v
                                    )
                                  },
                                  expression: "ivr_step.reroute_to_ivr_id",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ivr_step.task === "route_to_ring_group"
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "inline mb-3 ml-2",
                              attrs: { prop: "route_to_ring_group_id" },
                            },
                            [
                              _c("ring-group-selector", {
                                ref: "ring_group_selector",
                                on: { change: _vm.changeRouteToRingGroup },
                                model: {
                                  value: _vm.ivr_step.route_to_ring_group_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ivr_step,
                                      "route_to_ring_group_id",
                                      $$v
                                    )
                                  },
                                  expression: "ivr_step.route_to_ring_group_id",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ivr_step.task === "route_to_user"
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "inline mb-3 ml-2",
                              attrs: { prop: "route_to_user_id" },
                            },
                            [
                              _c("user-selector", {
                                ref: "user_selector",
                                attrs: { value: _vm.ivr_step.route_to_user_id },
                                on: { change: _vm.changeRouteToUser },
                                model: {
                                  value: _vm.ivr_step.route_to_user_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ivr_step,
                                      "route_to_user_id",
                                      $$v
                                    )
                                  },
                                  expression: "ivr_step.route_to_user_id",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ivr_step.task === "route_to_aloai_agent"
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "inline mb-3 ml-2",
                              attrs: { prop: "route_to_aloai_agent_id" },
                            },
                            [
                              _c("alo-ai-bots-selector", {
                                ref: "aloai_agent_selector",
                                staticClass: "width-300",
                                attrs: {
                                  type: _vm.AloAi.TYPE_VOICE,
                                  direction: _vm.AloAi.DIRECTION_INBOUND,
                                },
                                on: { change: _vm.changeRouteToAloAiAgent },
                                model: {
                                  value: _vm.ivr_step.route_to_aloai_agent_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ivr_step,
                                      "route_to_aloai_agent_id",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "ivr_step.route_to_aloai_agent_id",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showHubSpotOptions &&
                      _vm.ivr_step.task === "route_to_hs_deal_contact_owner"
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "inline mb-3 ml-2",
                              attrs: { prop: "route_to_hs_deal_contact_owner" },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "el-form-item__label inline" },
                                [_vm._v(" on ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    placeholder: "Select a pipeline",
                                    multiple: "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.preValidateForm(
                                        "ivr_step_form"
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.ivr_step
                                        .route_to_hs_deal_contact_owner,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ivr_step,
                                        "route_to_hs_deal_contact_owner",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ivr_step.route_to_hs_deal_contact_owner",
                                  },
                                },
                                _vm._l(_vm.pipelines, function (pipeline) {
                                  return _c("el-option", {
                                    key: pipeline.pipelineId,
                                    attrs: {
                                      label: pipeline.label,
                                      value: pipeline.pipelineId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasPermissionTo(["list workflow", "view workflow"]) &&
                      _vm.ivr_step.task === "enroll_to_workflow"
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "inline mb-3",
                              attrs: { prop: "workflow_ids" },
                            },
                            [
                              _c("workflow-selector", {
                                staticStyle: { width: "300px" },
                                attrs: { multiple: true, multipleLimit: 1 },
                                on: { change: _vm.changeWorkflows },
                                model: {
                                  value: _vm.ivr_step.workflow_ids,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ivr_step, "workflow_ids", $$v)
                                  },
                                  expression: "ivr_step.workflow_ids",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.showHubSpotOptions &&
                _vm.ivr_step.task === "route_to_hs_deal_contact_owner"
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c(
                            "span",
                            { staticClass: "el-form-item__label inline" },
                            [_vm._v("If agents were unavailable route to ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "inline mb-3 ml-2",
                              attrs: { prop: "fallback_ring_group_id" },
                            },
                            [
                              _c("ring-group-selector", {
                                ref: "ring_group_selector",
                                on: { change: _vm.changeFallbackRingGroup },
                                model: {
                                  value: _vm.ivr_step.fallback_ring_group_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ivr_step,
                                      "fallback_ring_group_id",
                                      $$v
                                    )
                                  },
                                  expression: "ivr_step.fallback_ring_group_id",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.has_say
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 d-flex" },
                        [
                          _c(
                            "el-form-item",
                            { staticClass: "inline mb-3" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "80px" },
                                  attrs: { placeholder: "Say / Play" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.preValidateForm(
                                        "ivr_step_form"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.is_say,
                                    callback: function ($$v) {
                                      _vm.is_say = $$v
                                    },
                                    expression: "is_say",
                                  },
                                },
                                _vm._l(_vm.message_options, function (option) {
                                  return _c("el-option", {
                                    key: option.id,
                                    attrs: {
                                      label: option.name,
                                      value: option.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.ivr_step.key &&
                          _vm.ivr_step.message_file === null &&
                          _vm.is_say === false
                            ? [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "inline mb-3 ml-2 mt-1",
                                    attrs: { prop: "message_file" },
                                  },
                                  [
                                    _c(
                                      "el-upload",
                                      {
                                        attrs: {
                                          action:
                                            "/api/v1/campaign/" +
                                            _vm.campaign.id +
                                            "/upload-ivr/general_ivr_file",
                                          headers: _vm.headers,
                                          "on-success": _vm.onSuccessMessage,
                                          "on-error": _vm.onFailedMessage,
                                          "on-progress":
                                            _vm.progressUploadMessage,
                                          "before-upload":
                                            _vm.beforeUploadMessage,
                                          "file-list":
                                            _vm.uploadFileList.upload,
                                          "show-file-list": true,
                                          multiple: false,
                                          disabled: _vm.loading_upload,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "inline",
                                            attrs: {
                                              size: "small",
                                              type: "primary",
                                              disabled: _vm.loading_upload,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Select File\n                            "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "ml-1 inline text-xxs" },
                                  [_vm._v("* MP3/WAV file (less than 8MB)")]
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.ivr_step.key && _vm.is_say === true
                            ? [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "inline mb-3 ml-2",
                                    attrs: { prop: "message_tts" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.ivr_step.message_tts,
                                              expression:
                                                "ivr_step.message_tts",
                                            },
                                          ],
                                          staticClass: "form-control pt-1 pb-1",
                                          staticStyle: { width: "500px" },
                                          attrs: { type: "text" },
                                          domProps: {
                                            value: _vm.ivr_step.message_tts,
                                          },
                                          on: {
                                            input: [
                                              function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.ivr_step,
                                                  "message_tts",
                                                  $event.target.value
                                                )
                                              },
                                              function ($event) {
                                                return _vm.preValidateForm(
                                                  "ivr_step_form"
                                                )
                                              },
                                            ],
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("variable-dialog"),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.ivr_step.message_file && _vm.is_say !== true
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("el-form-item", { staticClass: "inline mb-3" }, [
                            _c("div", { staticClass: "d-flex" }, [
                              _c("audio", { attrs: { controls: "" } }, [
                                _c("source", {
                                  attrs: {
                                    src:
                                      "/static/uploaded_file/" +
                                      _vm.ivr_step.message_file,
                                  },
                                }),
                                _vm._v(
                                  "\n                            Your browser does not support the audio element.\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center align-items-center",
                                },
                                [
                                  _vm.ivr_step.message_file !== null
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-danger ml-2",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.deleteFile(
                                                "general_ivr_file"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.loading_delete,
                                                  expression: "loading_delete",
                                                },
                                              ],
                                              staticClass:
                                                "material-icons loader pull-left",
                                            },
                                            [_vm._v("")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "i",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.loading_delete,
                                                  expression: "!loading_delete",
                                                },
                                              ],
                                              staticClass:
                                                "material-icons pull-left",
                                            },
                                            [_vm._v("")]
                                          ),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Remove file")]),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "_600",
                          on: {
                            change: function ($event) {
                              return _vm.preValidateForm("ivr_step_form")
                            },
                          },
                          model: {
                            value: _vm.has_extra,
                            callback: function ($$v) {
                              _vm.has_extra = $$v
                            },
                            expression: "has_extra",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    Also apply tags\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _vm.has_extra
                        ? [
                            _vm.hasPermissionTo(["list tag", "view tag"])
                              ? _c(
                                  "el-form-item",
                                  {
                                    staticClass: "inline mb-3",
                                    attrs: { prop: "tag_ids" },
                                  },
                                  [
                                    _c("tag-selector", {
                                      staticStyle: { width: "300px" },
                                      attrs: { multiple: true },
                                      on: { change: _vm.changeTags },
                                      model: {
                                        value: _vm.ivr_step.tag_ids,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.ivr_step, "tag_ids", $$v)
                                        },
                                        expression: "ivr_step.tag_ids",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]
            : _vm.ivr_step.listen_for_extension
            ? [
                _c("span", { staticClass: "el-form-item__label inline" }, [
                  _vm._v(
                    "When the caller enters an extension, connect the call to the user."
                  ),
                ]),
              ]
            : _vm.ivr_step.type && _vm.ivr_step.type === "exit"
            ? [
                _c(
                  "el-form-item",
                  { staticClass: "inline mb-3", attrs: { prop: "task" } },
                  [
                    _c("span", { staticClass: "el-form-item__label inline" }, [
                      _vm._v("If the caller didn't enter anything"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "300px" },
                        attrs: {
                          placeholder: "Select an exit strategy",
                          filterable: "",
                          clearable: "",
                        },
                        on: { change: _vm.changeIvrTask },
                        model: {
                          value: _vm.ivr_step.task,
                          callback: function ($$v) {
                            _vm.$set(_vm.ivr_step, "task", $$v)
                          },
                          expression: "ivr_step.task",
                        },
                      },
                      _vm._l(_vm.exitsAlphabeticalOrder, function (exit) {
                        return _c(
                          "el-option",
                          {
                            key: exit.id,
                            attrs: { label: exit.name, value: exit.value },
                          },
                          [
                            exit.value === "route_to_aloai_agent"
                              ? _c("sparkle-icon", {
                                  attrs: { width: "16", height: "16" },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                class: [
                                  exit.value === "route_to_aloai_agent"
                                    ? "ai-effect-gradient-text"
                                    : "",
                                ],
                              },
                              [_vm._v(_vm._s(exit.name))]
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.ivr_step.task
                  ? [
                      _vm.ivr_step.task === "route_to_other_campaign"
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "inline mb-3 ml-2",
                              attrs: { prop: "reroute_to_campaign_id" },
                            },
                            [
                              _c("campaign-selector", {
                                ref: "campaign_selector",
                                attrs: {
                                  value: _vm.ivr_step.reroute_to_campaign_id,
                                },
                                on: { change: _vm.changeRerouteToCampaign },
                                model: {
                                  value: _vm.ivr_step.reroute_to_campaign_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ivr_step,
                                      "reroute_to_campaign_id",
                                      $$v
                                    )
                                  },
                                  expression: "ivr_step.reroute_to_campaign_id",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ivr_step.task === "route_to_another_ivr"
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "inline mb-3 ml-2",
                              attrs: { prop: "reroute_to_ivr_id" },
                            },
                            [
                              _c("ivr-selector", {
                                ref: "ivr_selector",
                                attrs: {
                                  value: _vm.ivr_step.reroute_to_ivr_id,
                                },
                                on: { change: _vm.changeRerouteToIvr },
                                model: {
                                  value: _vm.ivr_step.reroute_to_ivr_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ivr_step,
                                      "reroute_to_ivr_id",
                                      $$v
                                    )
                                  },
                                  expression: "ivr_step.reroute_to_ivr_id",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ivr_step.task === "route_to_ring_group"
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "inline mb-3 ml-2",
                              attrs: { prop: "route_to_ring_group_id" },
                            },
                            [
                              _c("ring-group-selector", {
                                ref: "ring_group_selector",
                                on: { change: _vm.changeRouteToRingGroup },
                                model: {
                                  value: _vm.ivr_step.route_to_ring_group_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ivr_step,
                                      "route_to_ring_group_id",
                                      $$v
                                    )
                                  },
                                  expression: "ivr_step.route_to_ring_group_id",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ivr_step.task === "route_to_user"
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "inline mb-3 ml-2",
                              attrs: { prop: "route_to_user_id" },
                            },
                            [
                              _c("user-selector", {
                                ref: "user_selector",
                                attrs: { value: _vm.ivr_step.route_to_user_id },
                                on: { change: _vm.changeRouteToUser },
                                model: {
                                  value: _vm.ivr_step.route_to_user_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ivr_step,
                                      "route_to_user_id",
                                      $$v
                                    )
                                  },
                                  expression: "ivr_step.route_to_user_id",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ivr_step.task === "route_to_aloai_agent"
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "inline mb-3 ml-2",
                              attrs: { prop: "route_to_aloai_agent_id" },
                            },
                            [
                              _c("alo-ai-bots-selector", {
                                ref: "aloai_agent_selector",
                                staticClass: "width-300",
                                attrs: {
                                  type: _vm.AloAi.TYPE_VOICE,
                                  direction: _vm.AloAi.DIRECTION_INBOUND,
                                },
                                on: { change: _vm.changeRouteToAloAiAgent },
                                model: {
                                  value: _vm.ivr_step.route_to_aloai_agent_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ivr_step,
                                      "route_to_aloai_agent_id",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "ivr_step.route_to_aloai_agent_id",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.has_say
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 d-flex" },
                        [
                          _c(
                            "el-form-item",
                            { staticClass: "inline mb-3" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "80px" },
                                  attrs: { placeholder: "Say / Play" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.preValidateForm(
                                        "ivr_step_form"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.is_say,
                                    callback: function ($$v) {
                                      _vm.is_say = $$v
                                    },
                                    expression: "is_say",
                                  },
                                },
                                _vm._l(_vm.message_options, function (option) {
                                  return _c("el-option", {
                                    key: option.id,
                                    attrs: {
                                      label: option.name,
                                      value: option.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.is_say === false &&
                          _vm.ivr_step.message_file === null
                            ? [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "inline mb-3 ml-2 mt-1",
                                    attrs: { prop: "message_file" },
                                  },
                                  [
                                    _c(
                                      "el-upload",
                                      {
                                        attrs: {
                                          action:
                                            "/api/v1/campaign/" +
                                            _vm.campaign.id +
                                            "/upload-ivr/general_ivr_file",
                                          headers: _vm.headers,
                                          "on-success": _vm.onSuccessMessage,
                                          "on-error": _vm.onFailedMessage,
                                          "on-progress":
                                            _vm.progressUploadMessage,
                                          "before-upload":
                                            _vm.beforeUploadMessage,
                                          "file-list":
                                            _vm.uploadFileList.upload,
                                          "show-file-list": true,
                                          multiple: false,
                                          disabled: _vm.loading_upload,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "inline",
                                            attrs: {
                                              size: "small",
                                              type: "primary",
                                              disabled: _vm.loading_upload,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Select File\n                            "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "ml-1 inline text-xxs" },
                                  [_vm._v("* MP3/WAV file (less than 8MB)")]
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.is_say === true
                            ? [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "inline mb-3 ml-2",
                                    attrs: { prop: "message_tts" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.ivr_step.message_tts,
                                              expression:
                                                "ivr_step.message_tts",
                                            },
                                          ],
                                          staticClass: "form-control pt-1 pb-1",
                                          staticStyle: { width: "500px" },
                                          attrs: { type: "text" },
                                          domProps: {
                                            value: _vm.ivr_step.message_tts,
                                          },
                                          on: {
                                            input: [
                                              function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.ivr_step,
                                                  "message_tts",
                                                  $event.target.value
                                                )
                                              },
                                              function ($event) {
                                                return _vm.preValidateForm(
                                                  "ivr_step_form"
                                                )
                                              },
                                            ],
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("variable-dialog"),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.ivr_step.message_file && _vm.is_say !== true
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("el-form-item", { staticClass: "dinline mb-3" }, [
                            _c("div", { staticClass: "d-flex" }, [
                              _c("audio", { attrs: { controls: "" } }, [
                                _c("source", {
                                  attrs: {
                                    src:
                                      "/static/uploaded_file/" +
                                      _vm.ivr_step.message_file,
                                  },
                                }),
                                _vm._v(
                                  "\n                            Your browser does not support the audio element.\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center align-items-center",
                                },
                                [
                                  _vm.ivr_step.message_file !== null
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-danger ml-2",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.deleteFile(
                                                "general_ivr_file"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.loading_delete,
                                                  expression: "loading_delete",
                                                },
                                              ],
                                              staticClass:
                                                "material-icons loader pull-left",
                                            },
                                            [_vm._v("")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "i",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.loading_delete,
                                                  expression: "!loading_delete",
                                                },
                                              ],
                                              staticClass:
                                                "material-icons pull-left",
                                            },
                                            [_vm._v("")]
                                          ),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Remove file")]),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "_600",
                          on: {
                            change: function ($event) {
                              return _vm.preValidateForm("ivr_step_form")
                            },
                          },
                          model: {
                            value: _vm.has_extra,
                            callback: function ($$v) {
                              _vm.has_extra = $$v
                            },
                            expression: "has_extra",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    Also apply tags\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _vm.has_extra
                        ? [
                            _vm.hasPermissionTo(["list tag", "view tag"])
                              ? _c(
                                  "el-form-item",
                                  {
                                    staticClass: "inline mb-3",
                                    attrs: { prop: "tag_ids" },
                                  },
                                  [
                                    _c("tag-selector", {
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        category_prop:
                                          _vm.TagCategory.CAT_COMMUNICATIONS,
                                        multiple: true,
                                      },
                                      on: { change: _vm.changeTags },
                                      model: {
                                        value: _vm.ivr_step.tag_ids,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.ivr_step, "tag_ids", $$v)
                                        },
                                        expression: "ivr_step.tag_ids",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row no-select" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "span",
                {
                  staticClass: "pull-right _600 opacity-5",
                  class: [
                    _vm.ivr_step.valid ? "text-dark-greenish" : "text-danger",
                  ],
                },
                [
                  _vm.ivr_step.valid
                    ? _c("i", { staticClass: "fa fa-check-circle" })
                    : _c("i", { staticClass: "fa fa-times-circle" }),
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.ivr_step.valid ? "Valid step" : "Invalid step"
                      ) +
                      "\n            "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }