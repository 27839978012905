var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "generic_webhook_settings",
      staticClass: "generic-webhook",
      attrs: { rules: _vm.rules, model: _vm.webhook },
    },
    [
      _c(
        "el-card",
        {
          staticClass: "mt-3",
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("h6", [_vm._v("Connect to the API")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "Build the API call for this custom function, choosing the method,\n                URL, headers, query parameters, inputs from the contact and\n                authentication."
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "el-tabs",
                  {
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "Endpoint",
                          name: "endpoint",
                          "data-tab-error": "endpoint",
                        },
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v("\n                            Endpoint"),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.tabLabelErrorIcon(_vm.tab_errors.endpoint)
                                ),
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "row justify-content-center pt-3" },
                          [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c("el-form-item", { attrs: { prop: "url" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "row justify-content-center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "col-2" },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "w-100",
                                              model: {
                                                value: _vm.webhook.http_method,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.webhook,
                                                    "http_method",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "webhook.http_method",
                                              },
                                            },
                                            _vm._l(
                                              _vm.http_method_options,
                                              function (http_method) {
                                                return _c("el-option", {
                                                  key: http_method,
                                                  attrs: {
                                                    label: http_method,
                                                    value: http_method,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-10" },
                                        [
                                          _c("el-input", {
                                            attrs: { type: "url" },
                                            on: {
                                              input: function ($event) {
                                                return _vm.preValidateForm(
                                                  "generic_webhook_settings"
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.webhook.url,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.webhook,
                                                  "url",
                                                  $$v
                                                )
                                              },
                                              expression: "webhook.url",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "timeout_in_seconds" } },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", { staticClass: "text-dark" }, [
                                        _vm._v("Timeout"),
                                      ]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "Set the timeout for the API call in seconds."
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      attrs: { type: "number" },
                                      on: {
                                        input: function ($event) {
                                          return _vm.preValidateForm(
                                            "generic_webhook_settings"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.webhook.timeout_in_seconds,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.webhook,
                                            "timeout_in_seconds",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "webhook.timeout_in_seconds",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "Headers",
                          name: "headers",
                          "data-tab-error": "headers",
                        },
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v("\n                            Headers"),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.tabLabelErrorIcon(_vm.tab_errors.headers)
                                ),
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "row justify-content-center pt-3" },
                          [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c("p", [
                                  _vm._v(
                                    "\n                                    Headers allow you to send additional information with your\n                                    custom function. You can use them to include content types,\n                                    or other custom data.\n                                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.webhook.headers.length > 0
                                  ? _c(
                                      "el-row",
                                      [
                                        _c("el-col", { attrs: { span: 2 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "form-label" },
                                            [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "text-dark invisible",
                                                },
                                                [_vm._v("Actions")]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-col", { attrs: { span: 11 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "form-label" },
                                            [
                                              _c(
                                                "h5",
                                                { staticClass: "text-dark" },
                                                [_vm._v("Name")]
                                              ),
                                              _vm._v(" "),
                                              _c("small", [
                                                _vm._v(
                                                  "Tell what this header is called."
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-col", { attrs: { span: 11 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "form-label" },
                                            [
                                              _c(
                                                "h5",
                                                { staticClass: "text-dark" },
                                                [_vm._v("Value")]
                                              ),
                                              _vm._v(" "),
                                              _c("small", [
                                                _vm._v(
                                                  "Tell what this header is for."
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.webhook.headers,
                                  function (header, index) {
                                    return _c(
                                      "el-row",
                                      {
                                        staticClass: "mb-2",
                                        attrs: { gutter: 20 },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "text-center",
                                            attrs: { span: 2 },
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm danger",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.removeHeader(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-trash pull-left",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 11 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { prop: "key" } },
                                              [
                                                _c("el-input", {
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.sanitizeHeaderKey(
                                                        index,
                                                        header.key
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: header.key,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        header,
                                                        "key",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "header.key",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 11 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { prop: "value" } },
                                              [
                                                _c("el-input", {
                                                  model: {
                                                    value: header.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        header,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "header.value",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "my-2",
                                    attrs: {
                                      type: "primary",
                                      plain: "",
                                      size: "mini",
                                      icon: "el-icon-plus",
                                    },
                                    on: { click: _vm.addHeader },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Add header\n                                "
                                    ),
                                  ]
                                ),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "Authorization",
                          name: "authorization",
                          "data-tab-error": "authorization",
                        },
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v(
                              "\n                            Authorization"
                            ),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.tabLabelErrorIcon(
                                    _vm.tab_errors.authorization
                                  )
                                ),
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "row justify-content-center pt-3" },
                          [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c("p", [
                                  _vm._v(
                                    "\n                                    If your API requires a token, you can add it here. The AloAi\n                                    will use it when running this custom function.\n                                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.webhook.authorization.length > 0
                                  ? _c(
                                      "el-row",
                                      [
                                        _c("el-col", { attrs: { span: 2 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "form-label" },
                                            [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "text-dark invisible",
                                                },
                                                [_vm._v("Actions")]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-col", { attrs: { span: 11 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "form-label" },
                                            [
                                              _c(
                                                "h5",
                                                { staticClass: "text-dark" },
                                                [_vm._v("Name")]
                                              ),
                                              _vm._v(" "),
                                              _c("small", [
                                                _vm._v(
                                                  "Tell what this header is called."
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-col", { attrs: { span: 11 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "form-label" },
                                            [
                                              _c(
                                                "h5",
                                                { staticClass: "text-dark" },
                                                [_vm._v("Value")]
                                              ),
                                              _vm._v(" "),
                                              _c("small", [
                                                _vm._v(
                                                  "Tell what this header is for."
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.webhook.authorization,
                                  function (authorization, index) {
                                    return _c(
                                      "el-row",
                                      {
                                        staticClass: "mb-2",
                                        attrs: { gutter: 20 },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "text-center",
                                            attrs: { span: 2 },
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm danger",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.removeAuthorization(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-trash pull-left",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 11 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { prop: "key" } },
                                              [
                                                _c("el-input", {
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.sanitizeAuthorizationKey(
                                                        index,
                                                        authorization.key
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: authorization.key,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        authorization,
                                                        "key",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "authorization.key",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 11 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { prop: "value" } },
                                              [
                                                _c("el-input", {
                                                  model: {
                                                    value: authorization.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        authorization,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "authorization.value",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "my-2",
                                    attrs: {
                                      type: "primary",
                                      plain: "",
                                      size: "mini",
                                      icon: "el-icon-plus",
                                    },
                                    on: { click: _vm.addAuthorization },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Add authorization\n                                "
                                    ),
                                  ]
                                ),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.webhook.http_method !== "GET"
                      ? _c(
                          "el-tab-pane",
                          {
                            attrs: {
                              label: "Body",
                              name: "body",
                              "data-tab-error": "body",
                            },
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _vm._v("\n                            Body"),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.tabLabelErrorIcon(_vm.tab_errors.body)
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "row justify-content-center pt-3",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col-12" },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "\n                                    The body contains the main data sent with your custom\n                                    function. It is typically used to share detailed\n                                    information. You can customize the body to match the data\n                                    structure required.\n                                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm.webhook.body.length > 0
                                      ? _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 2 } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c(
                                                      "h5",
                                                      {
                                                        staticClass:
                                                          "text-dark invisible",
                                                      },
                                                      [_vm._v("Actions")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c(
                                                      "h5",
                                                      {
                                                        staticClass:
                                                          "text-dark",
                                                      },
                                                      [_vm._v("Type")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "Tell from where this data comes."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c(
                                                      "h5",
                                                      {
                                                        staticClass:
                                                          "text-dark",
                                                      },
                                                      [_vm._v("Name")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "Tell what this field is called."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c(
                                                      "h5",
                                                      {
                                                        staticClass:
                                                          "text-dark",
                                                      },
                                                      [_vm._v("Value")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "Tell what is this field value."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.webhook.body,
                                      function (body, index) {
                                        return _c(
                                          "el-row",
                                          {
                                            staticClass: "mb-2",
                                            attrs: { gutter: 20 },
                                          },
                                          [
                                            _c(
                                              "el-col",
                                              {
                                                staticClass: "text-center",
                                                attrs: { span: 2 },
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm danger",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.removeBody(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-trash pull-left",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  { attrs: { prop: "type" } },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticClass: "w-100",
                                                        model: {
                                                          value: body.type,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              body,
                                                              "type",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "body.type",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.types,
                                                        function (type) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: type,
                                                              attrs: {
                                                                label: type,
                                                                value: type,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  { attrs: { prop: "key" } },
                                                  [
                                                    _c("el-input", {
                                                      on: {
                                                        blur: function (
                                                          $event
                                                        ) {
                                                          return _vm.sanitizeBodyKey(
                                                            index,
                                                            body.key
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: body.key,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            body,
                                                            "key",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "body.key",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  { attrs: { prop: "value" } },
                                                  [
                                                    body.type == "Variable"
                                                      ? _c(
                                                          "el-select",
                                                          {
                                                            staticClass:
                                                              "w-100",
                                                            model: {
                                                              value: body.value,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    body,
                                                                    "value",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "body.value",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.variables,
                                                            function (
                                                              variable
                                                            ) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: variable,
                                                                  attrs: {
                                                                    label:
                                                                      variable,
                                                                    value:
                                                                      variable,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    body.type == "Static"
                                                      ? _c("el-input", {
                                                          model: {
                                                            value: body.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                body,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "body.value",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "my-2",
                                        attrs: {
                                          type: "primary",
                                          plain: "",
                                          size: "mini",
                                          icon: "el-icon-plus",
                                        },
                                        on: { click: _vm.addBody },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Add body\n                                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.webhook.http_method == "GET"
                      ? _c(
                          "el-tab-pane",
                          {
                            attrs: {
                              label: "Query Parameters",
                              name: "query_parameters",
                              "data-tab-error": "query_parameters",
                            },
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _vm._v(
                                  "\n                            Query Parameters"
                                ),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.tabLabelErrorIcon(
                                        _vm.tab_errors.query_parameters
                                      )
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "row justify-content-center pt-3",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col-12" },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "\n                                    Query parameters let you send extra information in the\n                                    custom function URL. They are useful for passing\n                                    identifiers, filters, or other custom data.\n                                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm.webhook.query_parameters.length > 0
                                      ? _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 2 } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c(
                                                      "h5",
                                                      {
                                                        staticClass:
                                                          "text-dark invisible",
                                                      },
                                                      [_vm._v("Actions")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c(
                                                      "h5",
                                                      {
                                                        staticClass:
                                                          "text-dark",
                                                      },
                                                      [_vm._v("Type")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "Tell from where this data comes."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c(
                                                      "h5",
                                                      {
                                                        staticClass:
                                                          "text-dark",
                                                      },
                                                      [_vm._v("Name")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "Tell what this field is called."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c(
                                                      "h5",
                                                      {
                                                        staticClass:
                                                          "text-dark",
                                                      },
                                                      [_vm._v("Value")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "Tell what is this field value."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.webhook.query_parameters,
                                      function (query_parameter, index) {
                                        return _c(
                                          "el-row",
                                          {
                                            staticClass: "mb-2",
                                            attrs: { gutter: 20 },
                                          },
                                          [
                                            _c(
                                              "el-col",
                                              {
                                                staticClass: "text-center",
                                                attrs: { span: 2 },
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm danger",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.removeQueryParameter(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-trash pull-left",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  { attrs: { prop: "type" } },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticClass: "w-100",
                                                        model: {
                                                          value:
                                                            query_parameter.type,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              query_parameter,
                                                              "type",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "query_parameter.type",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.types,
                                                        function (type) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: type,
                                                              attrs: {
                                                                label: type,
                                                                value: type,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  { attrs: { prop: "key" } },
                                                  [
                                                    _c("el-input", {
                                                      on: {
                                                        blur: function (
                                                          $event
                                                        ) {
                                                          return _vm.sanitizeQueryParameterKey(
                                                            index,
                                                            query_parameter.key
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          query_parameter.key,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            query_parameter,
                                                            "key",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "query_parameter.key",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  { attrs: { prop: "value" } },
                                                  [
                                                    query_parameter.type ==
                                                    "Variable"
                                                      ? _c(
                                                          "el-select",
                                                          {
                                                            staticClass:
                                                              "w-100",
                                                            model: {
                                                              value:
                                                                query_parameter.value,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    query_parameter,
                                                                    "value",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "query_parameter.value",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.variables,
                                                            function (
                                                              variable
                                                            ) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: variable,
                                                                  attrs: {
                                                                    label:
                                                                      variable,
                                                                    value:
                                                                      variable,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    query_parameter.type ==
                                                    "Static"
                                                      ? _c("el-input", {
                                                          model: {
                                                            value:
                                                              query_parameter.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                query_parameter,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "query_parameter.value",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "my-2",
                                        attrs: {
                                          type: "primary",
                                          plain: "",
                                          size: "mini",
                                          icon: "el-icon-plus",
                                        },
                                        on: { click: _vm.addQueryParameter },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Add query parameter\n                                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "mt-3",
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("h6", [_vm._v("Test the API Call")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "Get a sample response, with test values for any placeholder in the\n                API call."
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              staticClass: "row",
            },
            [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  !_vm.isInitializing && _vm.variables.length > 0
                    ? _c(
                        "el-button",
                        {
                          staticClass: "my-2",
                          attrs: {
                            type: "primary",
                            size: "mini",
                            icon: "el-icon-plus",
                            plain: "",
                          },
                          on: { click: _vm.initializeCustomFunction },
                        },
                        [
                          _vm._v(
                            "\n                    Initialize custom function\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isInitializing || _vm.variables.length === 0
                    ? _c(
                        "div",
                        [
                          _vm.variables.length > 0
                            ? _c("p", [
                                _vm._v(
                                  "\n                        Please, provide some values for the mapped variables before\n                        testing the custom function.\n                    "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.values, function (value, index) {
                            return _c(
                              "el-row",
                              { staticClass: "mb-2", attrs: { gutter: 20 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-label" },
                                          [
                                            _c(
                                              "h5",
                                              { staticClass: "text-dark" },
                                              [_vm._v(_vm._s(value.key))]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          model: {
                                            value: value.value,
                                            callback: function ($$v) {
                                              _vm.$set(value, "value", $$v)
                                            },
                                            expression: "value.value",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "my-2",
                              attrs: {
                                type: "primary",
                                size: "mini",
                                plain: "",
                              },
                              on: { click: _vm.callCustomFunction },
                            },
                            [
                              _vm._v(
                                "\n                        Make API Call\n                    "
                              ),
                            ]
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.fields.length > 0 && !_vm.isLoading
        ? _c(
            "el-card",
            {
              staticClass: "mt-3",
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("h6", [
                          _vm._v(
                            "Map the API response fields to be used in the conversation"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("small", [
                          _vm._v(
                            "Provide the API response fields to be used in the conversation to be\n                mapped."
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3498520001
              ),
            },
            [
              _vm._v(" "),
              _c("div", { staticClass: "row justify-content-center" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _vm.webhook.response_mapping.length > 0
                      ? _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 2 } }, [
                              _c("div", { staticClass: "form-label" }, [
                                _c(
                                  "h5",
                                  { staticClass: "text-dark invisible" },
                                  [_vm._v("Actions")]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 11 } }, [
                              _c("div", { staticClass: "form-label" }, [
                                _c("h5", { staticClass: "text-dark" }, [
                                  _vm._v("Name"),
                                ]),
                                _vm._v(" "),
                                _c("small", [
                                  _vm._v("Tell what this field is called."),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 11 } }, [
                              _c("div", { staticClass: "form-label" }, [
                                _c("h5", { staticClass: "text-dark" }, [
                                  _vm._v("Value"),
                                ]),
                                _vm._v(" "),
                                _c("small", [
                                  _vm._v("Tell what is this field value."),
                                ]),
                              ]),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(
                      _vm.webhook.response_mapping,
                      function (response_mapping, index) {
                        return _c(
                          "el-row",
                          { staticClass: "mb-2", attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "text-center",
                                attrs: { span: 2 },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm danger",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.removeResponse(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-trash pull-left",
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "mb-0",
                                    attrs: { prop: "key" },
                                  },
                                  [
                                    _c("el-input", {
                                      on: {
                                        blur: function ($event) {
                                          return _vm.sanitizeResponseMappingKey(
                                            index,
                                            response_mapping.key
                                          )
                                        },
                                      },
                                      model: {
                                        value: response_mapping.key,
                                        callback: function ($$v) {
                                          _vm.$set(response_mapping, "key", $$v)
                                        },
                                        expression: "response_mapping.key",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "mb-0",
                                    attrs: { prop: "value" },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "w-100",
                                        model: {
                                          value: response_mapping.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              response_mapping,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "response_mapping.value",
                                        },
                                      },
                                      _vm._l(_vm.fields, function (field) {
                                        return _c("el-option", {
                                          key: field,
                                          attrs: { label: field, value: field },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "my-2",
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-plus",
                          plain: "",
                        },
                        on: { click: _vm.addResponseMapping },
                      },
                      [
                        _vm._v(
                          "\n                    Add response mapping\n                "
                        ),
                      ]
                    ),
                  ],
                  2
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }