var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-body", attrs: { id: "view" } }, [
    _vm.hasPermissionTo(_vm.CREATE_TEAM) && _vm.team?.id
      ? _c(
          "div",
          {
            staticClass: "fixed-header padding pt-0 pb-0",
            style: { top: _vm.top_offset + 50 + "px" },
          },
          [
            _c("div", { staticClass: "row mb-0 mt-0" }, [
              _c(
                "div",
                { staticClass: "col-12 p-2" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Team Activity",
                          params: { team_id: _vm.team.id },
                        },
                      },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn purplish pull-left",
                          attrs: { "data-testid": "activity-team-button" },
                        },
                        [
                          _c("i", { staticClass: "fa fa-signal" }),
                          _vm._v(
                            "\n                        Activity\n                    "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "teams p-3 mt-5 pb-5" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c(
                    "el-tabs",
                    {
                      model: {
                        value: _vm.activeTabName,
                        callback: function ($$v) {
                          _vm.activeTabName = $$v
                        },
                        expression: "activeTabName",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { name: "general", label: "General" } },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "teamFormRef",
                              attrs: { model: _vm.teamForm, rules: _vm.rules },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Team Name",
                                    required: "",
                                    prop: "name",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "Type here",
                                      maxlength: 80,
                                      disabled: !_vm.hasPermissionTo(
                                        _vm.CREATE_TEAM
                                      ),
                                    },
                                    model: {
                                      value: _vm.teamForm.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.teamForm, "name", $$v)
                                      },
                                      expression: "teamForm.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Description",
                                    prop: "description",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      placeholder: "Type here",
                                      disabled: !_vm.hasPermissionTo(
                                        _vm.CREATE_TEAM
                                      ),
                                    },
                                    model: {
                                      value: _vm.teamForm.description,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.teamForm,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "teamForm.description",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            name: "users",
                            label: "Users",
                            disabled: !_vm.team?.id,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-between pt-1",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-items-center w-50",
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "w-50 mr-3",
                                    attrs: {
                                      placeholder: "Search Users",
                                      "prefix-icon": "el-icon-search",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchUsers,
                                      callback: function ($$v) {
                                        _vm.searchUsers = $$v
                                      },
                                      expression: "searchUsers",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex w-50 justify-content-end",
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "w-25",
                                      attrs: {
                                        slot: "reference",
                                        "data-testid":
                                          "team-creator-open-modal-button",
                                        type: "success",
                                      },
                                      on: { click: _vm.openAddUsersModal },
                                      slot: "reference",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                Add Users\n                                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.isLoading,
                                  expression: "isLoading",
                                },
                              ],
                              ref: "company-users-team-list",
                              staticClass: "w-full mb-3",
                              attrs: { stripe: "", data: _vm.filteredUsers },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  "data-testid": "users-status-column",
                                  prop: "status",
                                  align: "left",
                                  width: "120",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "status d-flex align-items-center justify-content-left",
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "b-white mr-2",
                                              class: [
                                                _vm.$options.filters.agentStatusClass(
                                                  scope.row.agent_status
                                                ),
                                              ],
                                            }),
                                            _vm._v(
                                              "\n                                                    " +
                                                _vm._s(
                                                  _vm._f("fixAgentStatus")(
                                                    scope.row.agent_status
                                                  )
                                                ) +
                                                "\n                                                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  "data-testid": "users-id-column",
                                  prop: "id",
                                  label: "ID",
                                  width: "100",
                                  sortable: "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          { staticClass: "text-greyish" },
                                          [_vm._v(_vm._s(scope.row.id))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  "data-testid": "users-name-column",
                                  prop: "name",
                                  label: "Name",
                                  "min-width": 200,
                                  sortable: "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "User Dialog",
                                                params: {
                                                  user_id: scope.row.id,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-dark-greenish text-ellipsis",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(scope.row.name || "-")
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            !scope.row.is_destination
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-greyish text-ellipsis",
                                                    attrs: {
                                                      title: scope.row.email,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                        " +
                                                        _vm._s(
                                                          scope.row.email
                                                        ) +
                                                        "\n                                                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        scope.row.read_only_access
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-inline-block label blue",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                    Reporters are not billed\n                                                "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  "data-testid": "users-description-column",
                                  prop: "description",
                                  label: "Description",
                                  "min-width": 200,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.description
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.description)
                                              ),
                                            ])
                                          : _c("span", [_vm._v("-")]),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  "data-testid": "users-last-login-column",
                                  prop: "last_login",
                                  label: "Last login",
                                  "min-width": 200,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.last_login &&
                                        !scope.row.is_destination
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f(
                                                    "fixFullDateUTCRelative"
                                                  )(scope.row.last_login)
                                                )
                                              ),
                                            ])
                                          : _c("span", [_vm._v("-")]),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  "data-testid": "users-last-role-names-column",
                                  prop: "role_names",
                                  label: "Role",
                                  "min-width": 200,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        !scope.row.is_destination
                                          ? _vm._l(
                                              scope.row.role_names,
                                              function (role_name) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: role_name,
                                                    staticClass: "row",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-ellipsis",
                                                            attrs: {
                                                              title: role_name,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                                " +
                                                                _vm._s(
                                                                  role_name
                                                                ) +
                                                                "\n                                                            "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            )
                                          : _c(
                                              "span",
                                              { staticClass: "text-ellipsis" },
                                              [
                                                _vm._v(
                                                  "\n                                                    Extension\n                                                "
                                                ),
                                              ]
                                            ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  "data-testid": "users-last-extension-column",
                                  prop: "extension",
                                  label: "Extension",
                                  sortable: "",
                                  "min-width": 120,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-greyish text-ellipsis",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.extension || "-")
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  "data-testid": "users-last-status-column",
                                  label: "Status",
                                  prop: "active",
                                  align: "left",
                                  width: "100",
                                  sortable: "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "status d-flex align-items-center justify-content-left",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                    " +
                                                _vm._s(
                                                  scope.row.active
                                                    ? "Active"
                                                    : "Paused"
                                                ) +
                                                "\n                                                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  "data-testid": "users-last-answer-by-column",
                                  prop: "answer_by",
                                  label: "Answer By",
                                  align: "left",
                                  sortable: "",
                                  "min-width": 150,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.answer_by ===
                                        _vm.AnswerTypes.BY_BROWSER
                                          ? _c(
                                              "span",
                                              { staticClass: "text-greyish" },
                                              [
                                                _vm._v(
                                                  "\n                                                    Browser / Apps\n                                                "
                                                ),
                                              ]
                                            )
                                          : scope.row.answer_by ===
                                            _vm.AnswerTypes.BY_PHONE_NUMBER
                                          ? _c(
                                              "span",
                                              { staticClass: "text-greyish" },
                                              [
                                                scope.row.phone_number
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("fixPhone")(
                                                            scope.row
                                                              .phone_number
                                                          )
                                                        )
                                                      ),
                                                    ])
                                                  : _c("span", [_vm._v("-")]),
                                              ]
                                            )
                                          : scope.row.answer_by ===
                                            _vm.AnswerTypes.BY_IP_PHONE
                                          ? _c(
                                              "span",
                                              { staticClass: "text-greyish" },
                                              [
                                                _vm._v(
                                                  "\n                                                    SIP\n                                                "
                                                ),
                                              ]
                                            )
                                          : scope.row.answer_by ===
                                            _vm.AnswerTypes.BY_NONE
                                          ? _c(
                                              "span",
                                              { staticClass: "text-greyish" },
                                              [
                                                _vm._v(
                                                  "\n                                                    Will not answer\n                                                "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: "Operations",
                                  width: "180",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "reference" },
                                            slot: "reference",
                                          },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _vm.hasPermissionTo(
                                                    _vm.CREATE_TEAM
                                                  )
                                                    ? _c(
                                                        "router-link",
                                                        {
                                                          attrs: {
                                                            to: {
                                                              name: "User Activity",
                                                              params: {
                                                                user_id:
                                                                  scope.row.id,
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-sm btn-block purplish",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-signal pull-left",
                                                              }),
                                                              _vm._v(
                                                                "\n                                                                    Activity\n                                                                "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "d-flex mt-2" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-block danger flex-grow-1",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleRemoveUsersModal(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-trash pull-left",
                                                    }),
                                                    _vm._v(
                                                      "\n                                                            Remove\n                                                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.hasPermissionTo(_vm.ADD_SUPERVISOR_TO_TEAM) &&
                      _vm.hasPermissionTo(_vm.REMOVE_SUPERVISOR_FROM_TEAM)
                        ? _c(
                            "el-tab-pane",
                            {
                              attrs: {
                                name: "supervisors",
                                label: "Supervisors",
                                disabled: !_vm.team?.id,
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "label" },
                                [
                                  _c("span", [_vm._v("Supervisors")]),
                                  _vm._v(" "),
                                  _c("team-supervisor-tooltip", {
                                    attrs: { placement: "top" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content-between pt-1",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center w-50",
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "w-50 mr-3",
                                        attrs: {
                                          placeholder: "Search Supervisors",
                                          "prefix-icon": "el-icon-search",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.searchSupervisors,
                                          callback: function ($$v) {
                                            _vm.searchSupervisors = $$v
                                          },
                                          expression: "searchSupervisors",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex w-50 justify-content-end",
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "w-25",
                                          attrs: {
                                            slot: "reference",
                                            type: "success",
                                            "data-testid":
                                              "team-supervisor-add-button",
                                          },
                                          on: {
                                            click: _vm.openAddSupervisorsModal,
                                          },
                                          slot: "reference",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                                Add Supervisors\n                                            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.isLoading,
                                      expression: "isLoading",
                                    },
                                  ],
                                  ref: "company-supervisors-team-list",
                                  staticClass: "w-full mb-3",
                                  attrs: {
                                    stripe: "",
                                    data: _vm.filteredSupervisors,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      "data-testid": "supervisors-id-column",
                                      prop: "id",
                                      label: "ID",
                                      width: "100",
                                      sortable: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                { staticClass: "text-greyish" },
                                                [_vm._v(_vm._s(scope.row.id))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2427425926
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      "data-testid": "supervisors-name-column",
                                      prop: "name",
                                      label: "Name",
                                      "min-width": 200,
                                      sortable: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "User Dialog",
                                                      params: {
                                                        user_id: scope.row.id,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-dark-greenish text-ellipsis",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.first_name
                                                        ) +
                                                          " " +
                                                          _vm._s(
                                                            scope.row.last_name
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-greyish text-ellipsis",
                                                      attrs: {
                                                        title: scope.row.email,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                        " +
                                                          _vm._s(
                                                            scope.row.email
                                                          ) +
                                                          "\n                                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2597633560
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      "data-testid":
                                        "supervisors-role-names-column",
                                      prop: "role_names",
                                      label: "Role",
                                      "min-width": 200,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return _vm._l(
                                              scope.row.role_names,
                                              function (role_name) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: role_name,
                                                    staticClass: "row",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-ellipsis",
                                                            attrs: {
                                                              title: role_name,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                            " +
                                                                _vm._s(
                                                                  role_name
                                                                ) +
                                                                "\n                                                        "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            )
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2253552131
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      "data-testid":
                                        "supervisors-status-column",
                                      label: "Status",
                                      prop: "active",
                                      align: "left",
                                      width: "100",
                                      sortable: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "status d-flex align-items-center justify-content-left",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    " +
                                                      _vm._s(
                                                        scope.row.active
                                                          ? "Active"
                                                          : "Paused"
                                                      ) +
                                                      "\n                                                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3377224613
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "Operations",
                                      width: "180",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "reference" },
                                                  slot: "reference",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "row" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-12",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-sm btn-block danger",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleRemoveSupervisorsModal(
                                                                      scope.row
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-trash pull-left",
                                                              }),
                                                              _vm._v(
                                                                "\n                                                                Remove\n                                                            "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3780503618
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            name: "ring-groups",
                            label: "Associated Ring Groups",
                            disabled: !_vm.team?.id,
                          },
                        },
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c("span", [_vm._v("Associated Ring Groups")]),
                                _vm._v(" "),
                                _c("team-ring-groups-tooltip", {
                                  attrs: { placement: "top" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-between pt-1",
                            },
                            [
                              _c("el-input", {
                                staticClass: "w-50",
                                attrs: {
                                  placeholder: "Search Ring Groups",
                                  "prefix-icon": "el-icon-search",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchRingGroups,
                                  callback: function ($$v) {
                                    _vm.searchRingGroups = $$v
                                  },
                                  expression: "searchRingGroups",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.isLoading,
                                  expression: "isLoading",
                                },
                              ],
                              ref: "company-ring-group-team-list",
                              staticClass: "w-full",
                              attrs: {
                                "max-height": "350",
                                stripe: "",
                                data: _vm.filteredRingGroups,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { prop: "name", label: "Name" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                                                " +
                                            _vm._s(scope.row?.name || "-") +
                                            "\n                                            "
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        width: "30%",
                        center: "",
                        visible: _vm.isDeleteUsersModalVisible,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.isDeleteUsersModalVisible = $event
                        },
                        close: _vm.closeDeleteUsersModal,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "dialog--title mt-4",
                          attrs: { slot: "title" },
                          slot: "title",
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.deleteUsersModalConfig.title) +
                              "\n                                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "break-word text-center",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.deleteUsersModalConfig.description
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-content-center mt-5 row",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "mb-2",
                              attrs: { slot: "reference", type: "default" },
                              on: { click: _vm.closeDeleteUsersModal },
                              slot: "reference",
                            },
                            [
                              _vm._v(
                                "\n                                        No, Keep User\n                                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "mb-2",
                              attrs: {
                                slot: "reference",
                                type: "danger",
                                "data-testid": "team-delete-users-button",
                                disabled: _vm.isLoading,
                              },
                              on: { click: _vm.onDeleteUsers },
                              slot: "reference",
                            },
                            [
                              _vm._v(
                                "\n                                        Yes\n                                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        width: "30%",
                        center: "",
                        visible: _vm.isAddUsersModalVisible,
                        loading: _vm.isLoading,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.isAddUsersModalVisible = $event
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "dialog--title mt-4",
                          attrs: { slot: "title" },
                          slot: "title",
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.addUsersModalConfig.title) +
                              "\n                                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "w-full",
                          attrs: {
                            multiple: "",
                            filterable: "",
                            remote: "",
                            placeholder: "Search Users",
                            "collapse-tags": "",
                            "data-testid": "team-add-users-select",
                          },
                          model: {
                            value: _vm.selectedUsersToAdd,
                            callback: function ($$v) {
                              _vm.selectedUsersToAdd = $$v
                            },
                            expression: "selectedUsersToAdd",
                          },
                        },
                        _vm._l(_vm.filteredUsersToAdd, function (user) {
                          return _c("el-option", {
                            key: user.id,
                            attrs: {
                              label: user.full_name + " - " + user.email,
                              value: user.id,
                            },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-content-center mt-5 row",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "mb-2",
                              attrs: { slot: "reference", type: "default" },
                              on: { click: _vm.closeAddUsersModal },
                              slot: "reference",
                            },
                            [
                              _vm._v(
                                "\n                                        Cancel\n                                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "mb-2",
                              attrs: {
                                slot: "reference",
                                type: "success",
                                "data-testid": "team-add-users-button",
                                disabled:
                                  _vm.isLoading ||
                                  !_vm.selectedUsersToAdd.length,
                              },
                              on: { click: _vm.onAddUsers },
                              slot: "reference",
                            },
                            [
                              _vm._v(
                                "\n                                        Add Users\n                                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        width: "30%",
                        center: "",
                        visible: _vm.isDeleteSupervisorsModalVisible,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.isDeleteSupervisorsModalVisible = $event
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "dialog--title mt-4",
                          attrs: { slot: "title" },
                          slot: "title",
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.deleteSupervisorsModalConfig.title) +
                              "\n                                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "break-word text-center",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.deleteSupervisorsModalConfig.description
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-content-center mt-5 row",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "mb-2",
                              attrs: { slot: "reference", type: "default" },
                              on: { click: _vm.closeDeleteSupervisorsModal },
                              slot: "reference",
                            },
                            [
                              _vm._v(
                                "\n                                        No, Keep Supervisor\n                                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "mb-2",
                              attrs: {
                                slot: "reference",
                                type: "danger",
                                "data-testid": "team-delete-supervisors-button",
                                disabled: _vm.isLoading,
                              },
                              on: { click: _vm.onRemoveSupervisors },
                              slot: "reference",
                            },
                            [
                              _vm._v(
                                "\n                                        Yes\n                                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        width: "30%",
                        center: "",
                        visible: _vm.isAddSupervisorsModalVisible,
                        loading: _vm.isLoading,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.isAddSupervisorsModalVisible = $event
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "dialog--title mt-4",
                          attrs: { slot: "title" },
                          slot: "title",
                        },
                        [
                          _vm._v(
                            "\n                                    Add supervisors to: " +
                              _vm._s(_vm.team.name) +
                              "\n                                    "
                          ),
                          _c("team-supervisor-tooltip", {
                            attrs: { placement: "bottom" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "w-full",
                          attrs: {
                            multiple: "",
                            filterable: "",
                            remote: "",
                            placeholder: "Search Supervisors",
                            "collapse-tags": "",
                            "data-testid": "team-add-supervisors-select",
                          },
                          model: {
                            value: _vm.selectedSupervisorsToAdd,
                            callback: function ($$v) {
                              _vm.selectedSupervisorsToAdd = $$v
                            },
                            expression: "selectedSupervisorsToAdd",
                          },
                        },
                        _vm._l(_vm.filteredSupervisorsToAdd, function (user) {
                          return _c("el-option", {
                            key: user.id,
                            attrs: {
                              label: user.full_name + " - " + user.email,
                              value: user.id,
                            },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-content-center mt-5 row",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "mb-2",
                              attrs: { slot: "reference", type: "default" },
                              on: { click: _vm.closeAddSupervisorsModal },
                              slot: "reference",
                            },
                            [
                              _vm._v(
                                "\n                                        Cancel\n                                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "mb-2",
                              attrs: {
                                slot: "reference",
                                type: "success",
                                "data-testid": "team-add-supervisors-button",
                                disabled:
                                  _vm.isLoading ||
                                  !_vm.selectedSupervisorsToAdd.length,
                              },
                              on: { click: _vm.onAddSupervisors },
                              slot: "reference",
                            },
                            [
                              _vm._v(
                                "\n                                        Add Supervisors\n                                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "fixed-footer padding pb-0" }, [
      _c("div", { staticClass: "form-group row mb-0 mt-0" }, [
        _c(
          "div",
          { staticClass: "col-12 d-none d-md-block p-2 edit-operations" },
          [
            _c(
              "el-button",
              {
                staticClass: "pull-left",
                attrs: { "data-testid": "back-button" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push({ name: "Teams" })
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-arrow-left" }),
                _vm._v("\n                    Back\n                "),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "pull-right ml-2",
                attrs: {
                  type: "default",
                  disabled: !_vm.hasChanges,
                  "data-testid": "cancel-team-button",
                },
                on: { click: _vm.resetFormData },
              },
              [_vm._v("\n                    Cancel\n                ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "pull-right ml-0",
                attrs: {
                  slot: "reference",
                  type: "success",
                  loading: _vm.isLoading,
                  disabled:
                    !_vm.hasChanges || !_vm.hasPermissionTo(_vm.CREATE_TEAM),
                  "data-testid": "save-team-button",
                },
                on: { click: _vm.onSaveTeam },
                slot: "reference",
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.team?.id ? "Save Changes" : "Create Team") +
                    "\n                "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }