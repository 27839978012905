var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "agent_settings",
      staticClass: "aloai-agents",
      attrs: { rules: _vm.rules, model: _vm.bot },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "el-tabs",
              {
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c(
                  "el-tab-pane",
                  {
                    attrs: {
                      label: "Configure",
                      name: "configure",
                      "data-tab-error": "configure",
                    },
                  },
                  [
                    _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                      _vm._v("\n                        Configure"),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.tabLabelErrorIcon(_vm.tab_errors.configure)
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row justify-content-center pt-3" },
                      [
                        _c(
                          "div",
                          { staticClass: "col-md-12 col-lg-10 main-col" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "name" } },
                              [
                                _c("div", { staticClass: "form-label" }, [
                                  _c("h5", { staticClass: "text-dark" }, [
                                    _vm._v("Name"),
                                  ]),
                                  _vm._v(" "),
                                  _c("small", [
                                    _vm._v(
                                      "\n                                        What name would you like to give to this agent?\n                                    "
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  on: {
                                    input: function ($event) {
                                      return _vm.preValidateForm(
                                        "agent_settings"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.bot.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.bot, "name", $$v)
                                    },
                                    expression: "bot.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              [
                                _c("div", { staticClass: "form-label" }, [
                                  _c("h5", { staticClass: "text-dark" }, [
                                    _vm._v("Description"),
                                  ]),
                                  _vm._v(" "),
                                  _c("small", [
                                    _vm._v(
                                      "Describe the purpose of this agent."
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  attrs: { type: "textarea", rows: 2 },
                                  model: {
                                    value: _vm.bot.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.bot, "description", $$v)
                                    },
                                    expression: "bot.description",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            false
                              ? _c(
                                  "el-form-item",
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", { staticClass: "text-dark" }, [
                                        _vm._v(
                                          "Allow access to contact information"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                        Will this agent have access to your contact personal information?\n                                    "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "w-100",
                                        model: {
                                          value:
                                            _vm.bot
                                              .allow_access_contact_information,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.bot,
                                              "allow_access_contact_information",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "bot.allow_access_contact_information",
                                        },
                                      },
                                      _vm._l(
                                        _vm.access_contact_information_options,
                                        function (access_contact_information) {
                                          return _c("el-option", {
                                            key: access_contact_information.value,
                                            attrs: {
                                              label:
                                                access_contact_information.name,
                                              value:
                                                access_contact_information.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("hr"),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              [
                                _c("div", { staticClass: "form-label" }, [
                                  _c("h5", { staticClass: "text-dark" }, [
                                    _vm._v("Agent type"),
                                  ]),
                                  _vm._v(" "),
                                  _c("small", [
                                    _vm._v(
                                      "\n                                        What is the main channel of communication for this agent?\n                                    "
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      disabled: !_vm.blockAgentTypeChange,
                                      placement: "left",
                                      content:
                                        "Agent type cannot be changed after creation.",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "w-100",
                                        attrs: {
                                          disabled: _vm.blockAgentTypeChange,
                                        },
                                        on: { change: _vm.updateBotType },
                                        model: {
                                          value: _vm.bot.type,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.bot, "type", $$v)
                                          },
                                          expression: "bot.type",
                                        },
                                      },
                                      _vm._l(_vm.types, function (type) {
                                        return _c("el-option", {
                                          key: type.value,
                                          attrs: {
                                            label: type.name,
                                            value: type.value,
                                            disabled: _vm.lockVoiceOption(type),
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              [
                                _c("div", { staticClass: "form-label" }, [
                                  _c("h5", { staticClass: "text-dark" }, [
                                    _vm._v("Direction"),
                                  ]),
                                  _vm._v(" "),
                                  _c("small", [
                                    _vm._v(
                                      "\n                                        Will this agent start the conversations or wait for your\n                                        contacts to reach out first?\n                                    "
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "w-100",
                                    model: {
                                      value: _vm.bot.direction,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.bot, "direction", $$v)
                                      },
                                      expression: "bot.direction",
                                    },
                                  },
                                  _vm._l(_vm.directions, function (direction) {
                                    return _c("el-option", {
                                      key: direction.value,
                                      attrs: {
                                        label: direction.name,
                                        value: direction.value,
                                        disabled: _vm.lockOutboundOption(
                                          _vm.bot.type,
                                          direction.value
                                        ),
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.bot.direction == _vm.AloAi.DIRECTION_OUTBOUND
                              ? _c(
                                  "el-form-item",
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", { staticClass: "text-dark" }, [
                                        _vm._v("Default Outbound Line"),
                                      ]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                        Select the default outbound line for this agent.\n                                    "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "w-100",
                                        attrs: {
                                          placeholder:
                                            "Select default outbound line",
                                          filterable: "",
                                        },
                                        model: {
                                          value:
                                            _vm.bot
                                              .default_outbound_campaign_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.bot,
                                              "default_outbound_campaign_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "bot.default_outbound_campaign_id",
                                        },
                                      },
                                      _vm._l(_vm.lines, function (line) {
                                        return _c("el-option", {
                                          key: line.id,
                                          attrs: {
                                            label: line.name,
                                            value: line.id,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("hr"),
                            _vm._v(" "),
                            _vm.bot.type === _vm.AloAi.TYPE_TEXT
                              ? _c(
                                  "el-form-item",
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", { staticClass: "text-dark" }, [
                                        _vm._v("Text model"),
                                      ]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                        Choose the text model the agent needs to operate on. Opt for\n                                        speed or depth to suit your agent's role.\n                                    "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "w-100",
                                        model: {
                                          value: _vm.bot.model,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.bot, "model", $$v)
                                          },
                                          expression: "bot.model",
                                        },
                                      },
                                      _vm._l(_vm.models, function (model) {
                                        return _c("el-option", {
                                          key: model.value,
                                          attrs: {
                                            label: model.name,
                                            value: model.value,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.bot.type === _vm.AloAi.TYPE_VOICE
                              ? _c(
                                  "el-form-item",
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", { staticClass: "text-dark" }, [
                                        _vm._v("AI model for calls"),
                                      ]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                        Choose the AI model the agent needs to operate on when receiving/making calls. Opt for\n                                        speed or depth to suit your agent's role.\n                                    "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "w-100",
                                        attrs: { filterable: "" },
                                        model: {
                                          value: _vm.bot.voice_model,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.bot,
                                              "voice_model",
                                              $$v
                                            )
                                          },
                                          expression: "bot.voice_model",
                                        },
                                      },
                                      _vm._l(
                                        _vm.groupedVoiceModels,
                                        function (group) {
                                          return _c(
                                            "el-option-group",
                                            {
                                              key: group.label,
                                              attrs: { label: group.label },
                                            },
                                            _vm._l(
                                              group.models,
                                              function (model) {
                                                return _c("el-option", {
                                                  key: model.id,
                                                  attrs: {
                                                    label: model.name,
                                                    value: model.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.bot.type === _vm.AloAi.TYPE_VOICE
                              ? _c(
                                  "el-form-item",
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", { staticClass: "text-dark" }, [
                                        _vm._v("Language"),
                                      ]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "Select language for the voice agent to respond in"
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "w-100",
                                        attrs: {
                                          filterable: "",
                                          placeholder: "Select language",
                                        },
                                        model: {
                                          value: _vm.bot.language,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.bot, "language", $$v)
                                          },
                                          expression: "bot.language",
                                        },
                                      },
                                      _vm._l(
                                        _vm.filteredLanguages,
                                        function (lang) {
                                          return _c("el-option", {
                                            key: lang.code,
                                            attrs: {
                                              label: lang.name,
                                              value: lang.code,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.bot.type === _vm.AloAi.TYPE_VOICE
                              ? _c("el-form-item", [
                                  _c("div", { staticClass: "form-label" }, [
                                    _c("h5", { staticClass: "text-dark" }, [
                                      _vm._v("Select voice"),
                                    ]),
                                    _vm._v(" "),
                                    _c("small", [
                                      _vm._v(
                                        " Choose a preset voice for this agent. "
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center width-300",
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "w-100",
                                          attrs: {
                                            filterable: "",
                                            placeholder: "Select voice",
                                          },
                                          on: { change: _vm.stopAudio },
                                          model: {
                                            value: _vm.bot.voice_id,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.bot, "voice_id", $$v)
                                            },
                                            expression: "bot.voice_id",
                                          },
                                        },
                                        _vm._l(
                                          _vm.groupedVoices,
                                          function (group) {
                                            return _c(
                                              "el-option-group",
                                              {
                                                key: group.label,
                                                attrs: { label: group.label },
                                              },
                                              _vm._l(
                                                group.voices,
                                                function (voice) {
                                                  return _c("el-option", {
                                                    key: voice.id,
                                                    attrs: {
                                                      label: voice.name,
                                                      value: voice.id,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "voice-option",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "gender-circle",
                                                                      class: {
                                                                        "gender-male":
                                                                          voice.gender ===
                                                                          "male",
                                                                        "gender-female":
                                                                          voice.gender ===
                                                                          "female",
                                                                        "gender-unknown":
                                                                          voice.gender ===
                                                                          "unknown",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                            " +
                                                                          _vm._s(
                                                                            _vm.getGenderName(
                                                                              voice.gender
                                                                            )
                                                                          ) +
                                                                          "\n                                                        "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                                                        " +
                                                                      _vm._s(
                                                                        voice.name
                                                                      ) +
                                                                      "\n                                                    "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "ml-2",
                                          attrs: { circle: "" },
                                          on: { click: _vm.toggleAudio },
                                        },
                                        [
                                          _c("i", {
                                            class: _vm.isPlaying
                                              ? "fas fa-stop"
                                              : "fas fa-play",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("hr"),
                            _vm._v(" "),
                            _c("el-form-item", [
                              _c("div", { staticClass: "form-label" }, [
                                _c("h5", { staticClass: "text-dark" }, [
                                  _vm._v("Engagement expiration"),
                                ]),
                                _vm._v(" "),
                                _c("small", [
                                  _vm._v(
                                    "\n                                        How long will the agent's engagement with a contact last before it expires?\n                                    "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "row align-items-center" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-6" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "mb-0",
                                          attrs: { prop: "ttl" },
                                        },
                                        [
                                          _c("el-input-number", {
                                            staticClass: "w-100",
                                            attrs: {
                                              min: 1,
                                              max: 14,
                                              precision: 0,
                                              controls: true,
                                            },
                                            on: { change: _vm.updateBotTTL },
                                            model: {
                                              value: _vm.bot_ttl,
                                              callback: function ($$v) {
                                                _vm.bot_ttl = $$v
                                              },
                                              expression: "bot_ttl",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-6" }, [
                                    _vm._v(
                                      "\n                                        Days\n                                    "
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-tab-pane",
                  {
                    attrs: {
                      label: "Advanced",
                      name: "advanced",
                      "data-tab-error": "advanced",
                    },
                  },
                  [
                    _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                      _vm._v("\n                        Advanced"),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.tabLabelErrorIcon(_vm.tab_errors.advanced)
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row justify-content-center pt-3" },
                      [
                        _c(
                          "div",
                          { staticClass: "col-md-12 col-lg-10 main-col" },
                          [
                            _vm.bot.type == _vm.AloAi.TYPE_VOICE
                              ? _c(
                                  "el-form-item",
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", { staticClass: "text-dark" }, [
                                        _vm._v("Responsiveness"),
                                      ]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "Control how fast the agent responds after users finish speaking."
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-slider", {
                                      attrs: {
                                        "show-input": "",
                                        min: 0.1,
                                        max: 1,
                                        step: 0.01,
                                      },
                                      model: {
                                        value:
                                          _vm.bot.settings.retellai
                                            .responsiveness,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.bot.settings.retellai,
                                            "responsiveness",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "bot.settings.retellai.responsiveness",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.bot.type == _vm.AloAi.TYPE_VOICE
                              ? _c(
                                  "el-form-item",
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", { staticClass: "text-dark" }, [
                                        _vm._v("Interruption Sensitivity"),
                                      ]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "Control how sensitively the agent can be interrupted by human speech."
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-slider", {
                                      attrs: {
                                        "show-input": "",
                                        "show-stops": "",
                                        min: 0.1,
                                        max: 1,
                                        step: 0.1,
                                      },
                                      model: {
                                        value:
                                          _vm.bot.settings.retellai
                                            .interruption_sensitivity,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.bot.settings.retellai,
                                            "interruption_sensitivity",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "bot.settings.retellai.interruption_sensitivity",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "container-header d-flex justify-content-between",
                              },
                              [
                                _c("div", { staticClass: "d-flex" }, [
                                  _c("h3", { staticClass: "listing-heading" }, [
                                    _vm._v(
                                      "\n                                        Custom Functions\n                                    "
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "my-2",
                                        attrs: {
                                          type: "primary",
                                          plain: "",
                                          size: "mini",
                                          icon: "el-icon-plus",
                                        },
                                        on: {
                                          click: _vm.openCustomFunctionModal,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        Add\n                                    "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.toolsCustomList?.length > 0
                              ? _c(
                                  "el-table",
                                  {
                                    staticClass: "w-full",
                                    attrs: {
                                      "row-key": "uuid",
                                      data: _vm.toolsCustomList,
                                      stripe: "",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: { label: "Name" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(scope.row.name) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3644936986
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: { label: "Description" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      scope.row.description
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1410565883
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "Actions",
                                        align: "center",
                                        width: "100",
                                        fixed: "right",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm info",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.handleCustomFunctionEdit(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-pencil pull-left",
                                                    }),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm danger",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.handleCustomFunctionDelete(
                                                          scope.row.uuid
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-trash pull-left",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3564242338
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-tab-pane",
                  {
                    attrs: {
                      label: "Instructions",
                      name: "instructions",
                      "data-tab-error": "instructions",
                    },
                  },
                  [
                    _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                      _vm._v("\n                        Prompt"),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.tabLabelErrorIcon(_vm.tab_errors.instructions)
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row justify-content-center pt-3" },
                      [
                        _c(
                          "div",
                          { staticClass: "col-md-12 col-lg-10 main-col" },
                          [
                            _c("model-settings", {
                              attrs: { bot: _vm.bot, toolsList: _vm.toolsList },
                              on: {
                                validate: function ($event) {
                                  return _vm.preValidateForm("agent_settings")
                                },
                                "save-instructions": _vm.saveInstructions,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.bot.type == _vm.AloAi.TYPE_VOICE
                  ? _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "Post-Call Actions",
                          name: "post_call_actions",
                          "data-tab-error": "post_call_actions",
                        },
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v(
                              "\n                        Post-Call Actions"
                            ),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.tabLabelErrorIcon(
                                    _vm.tab_errors.post_call_actions
                                  )
                                ),
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "row justify-content-center pt-3" },
                          [
                            _c(
                              "div",
                              { staticClass: "col-md-12 col-lg-10 main-col" },
                              [
                                _c("post-call-actions-tab", {
                                  attrs: { bot: _vm.bot },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-tab-pane",
                  {
                    attrs: {
                      label: "Schedule",
                      name: "schedule",
                      "data-tab-error": "schedule",
                    },
                  },
                  [
                    _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                      _vm._v("\n                        Schedule"),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.tabLabelErrorIcon(_vm.tab_errors.schedule)
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row justify-content-center pt-3" },
                      [
                        _c(
                          "div",
                          { staticClass: "col-md-12 col-lg-10 main-col" },
                          [_c("schedule-tab", { attrs: { bot: _vm.bot } })],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.bot.type == _vm.AloAi.TYPE_TEXT
                  ? _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "Actions",
                          name: "actions",
                          "data-tab-error": "actions",
                        },
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v("\n                        Actions"),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.tabLabelErrorIcon(_vm.tab_errors.actions)
                                ),
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "row justify-content-center pt-3" },
                          [
                            _c(
                              "div",
                              { staticClass: "col-md-12 col-lg-10 main-col" },
                              [
                                _vm.bot.type == _vm.AloAi.TYPE_TEXT
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-label d-flex" },
                                          [
                                            _c("el-switch", {
                                              staticClass:
                                                "mr-2 align-self-center",
                                              attrs: {
                                                "active-color": "#00BF50",
                                              },
                                              model: {
                                                value:
                                                  _vm.bot.follow_up_enabled,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.bot,
                                                    "follow_up_enabled",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "bot.follow_up_enabled",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "h5",
                                              { staticClass: "mb-0 mt-1" },
                                              [_vm._v("Follow Up")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-label" },
                                              [
                                                _c("small", [
                                                  _vm._v(
                                                    "\n                                            Set the frequency you want this agent to follow up the\n                                            conversation when the contact is unresponsive.\n                                        "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.bot.follow_up_enabled,
                                                    expression:
                                                      "bot.follow_up_enabled",
                                                  },
                                                ],
                                                staticClass: "row",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-4" },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "no-border",
                                                      },
                                                      [
                                                        _c(
                                                          "el-input",
                                                          {
                                                            staticClass:
                                                              "w-100",
                                                            attrs: {
                                                              type: "number",
                                                              disabled:
                                                                !_vm.bot
                                                                  .follow_up_enabled,
                                                              max: 100,
                                                              min: 1,
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.handleFollowUpFrequencyInput,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.bot
                                                                  .follow_up_frequency,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.bot,
                                                                    "follow_up_frequency",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "bot.follow_up_frequency",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "template",
                                                              {
                                                                slot: "append",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                        Attempt"
                                                                ),
                                                                _vm.bot
                                                                  .follow_up_frequency !=
                                                                1
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        "s"
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.bot.follow_up_enabled,
                                                    expression:
                                                      "bot.follow_up_enabled",
                                                  },
                                                ],
                                                staticClass: "form-label my-2",
                                              },
                                              [
                                                _c("small", [
                                                  _vm._v(
                                                    "\n                                            Set a delay within each follow up attempt.\n                                        "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("DurationPicker", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.bot.follow_up_enabled,
                                                  expression:
                                                    "bot.follow_up_enabled",
                                                },
                                              ],
                                              attrs: {
                                                current_seconds:
                                                  _vm.bot.follow_up_delay,
                                              },
                                              on: {
                                                update_seconds:
                                                  _vm.onUpdateFollowUpDelay,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.createCustomFunctionDialogVisible
        ? _c("custom-function", {
            ref: "custom_function_dialog",
            attrs: { open: true, tool: _vm.customFunctionData || undefined },
            on: { close: _vm.onCustomFunctionDialogClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }