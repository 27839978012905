var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("list user")
    ? _c(
        "div",
        { staticClass: "ring-group-manage-team-inbox" },
        [
          _c("div", { staticClass: "text-left mb-2" }, [
            _c("div", { staticClass: "form-label" }, [
              _c("h5", [_vm._v("Connected Users and Teams")]),
              _vm._v(" "),
              _c("small", [
                _vm._v(
                  "\n            These are the users and teams that have direct access to the "
                ),
                _c("strong", [_vm._v(_vm._s(_vm.EINBOX_NAME))]),
                _vm._v(" created by this "),
                _c("strong", [_vm._v("Ring Group")]),
                _vm._v(". To make changes, update the "),
                _c("strong", [_vm._v("Ring Group Builder")]),
                _vm._v(" under the "),
                _c("strong", [_vm._v("General")]),
                _vm._v(" tab.\n        "),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "list-ring-group mb-5" }, [
            _c(
              "div",
              { staticClass: "list-ring-group-item d-flex align-items-center" },
              [
                _c("div", { staticClass: "layer-label" }, [
                  _vm._v("Connected"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-100" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _c("div", { staticClass: "select-label" }, [
                        _vm._v("Users:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "flex-grow-1 w-100",
                          attrs: {
                            placeholder: "",
                            multiple: "",
                            disabled: true,
                          },
                          model: {
                            value: _vm.user_ids,
                            callback: function ($$v) {
                              _vm.user_ids = $$v
                            },
                            expression: "user_ids",
                          },
                        },
                        [
                          _vm.allUsers && _vm.allUsers.length > 0
                            ? _c(
                                "el-option-group",
                                { key: "Users", attrs: { label: "Users" } },
                                _vm._l(_vm.allUsers, function (user) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: `user-${user.id}`,
                                      staticClass: "p-0",
                                      attrs: {
                                        label: user.name,
                                        value: user.id,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "media" }, [
                                        _c(
                                          "div",
                                          { staticClass: "media-body" },
                                          [
                                            _c("label", [
                                              _vm._v(_vm._s(user.name)),
                                            ]),
                                            _vm._v(" "),
                                            _c("small", [
                                              _vm._v(
                                                _vm._s(user.email) +
                                                  " - " +
                                                  _vm._s(_vm.getLabel(user))
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center mt-3" },
                    [
                      _c("div", { staticClass: "select-label" }, [
                        _vm._v("Teams:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "teams-select flex-grow-1 w-100",
                          attrs: {
                            placeholder: "",
                            multiple: "",
                            disabled: true,
                          },
                          model: {
                            value: _vm.team_ids,
                            callback: function ($$v) {
                              _vm.team_ids = $$v
                            },
                            expression: "team_ids",
                          },
                        },
                        [
                          _vm.filteredTeams && _vm.filteredTeams.length > 0
                            ? _c(
                                "el-option-group",
                                { attrs: { label: "Teams" } },
                                _vm._l(_vm.filteredTeams, function (team) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: team.id,
                                      staticClass: "p-0",
                                      attrs: {
                                        label: team.name,
                                        value: team.id,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "media" }, [
                                        _c(
                                          "div",
                                          { staticClass: "media-body" },
                                          [
                                            _c("label", [
                                              _vm._v(_vm._s(team.name)),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "text-left mb-2" }, [
            _c("div", { staticClass: "form-label" }, [
              _c("h5", [_vm._v("Watching Users and Teams")]),
              _vm._v(" "),
              _c("small", [
                _vm._v(
                  "\n            These users and teams have view-only access to the "
                ),
                _c("strong", [_vm._v(_vm._s(_vm.EINBOX_NAME))]),
                _vm._v(" created by this "),
                _c("strong", [_vm._v("Ring Group")]),
                _vm._v(". They will receive notifications for "),
                _c("strong", [_vm._v("Missed")]),
                _vm._v(" and "),
                _c("strong", [_vm._v("Abandoned")]),
                _vm._v(" calls.\n        "),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "list-ring-group mb-2" }, [
            _c(
              "div",
              { staticClass: "list-ring-group-item d-flex align-items-center" },
              [
                _c("div", { staticClass: "layer-label" }, [_vm._v("Watching")]),
                _vm._v(" "),
                _c("div", { staticClass: "w-100" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _c("div", { staticClass: "select-label" }, [
                        _vm._v("Users:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "Select one or more users.",
                            placement: "top",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "flex-grow-1 w-100",
                              attrs: {
                                placeholder: "Add one or more users",
                                multiple: "",
                                filterable: "",
                                "default-first-option": "",
                                disabled: _vm.loading,
                              },
                              model: {
                                value: _vm.ring_group.watcher_user_ids,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ring_group,
                                    "watcher_user_ids",
                                    $$v
                                  )
                                },
                                expression: "ring_group.watcher_user_ids",
                              },
                            },
                            [
                              _vm.allUsers && _vm.allUsers.length > 0
                                ? _c(
                                    "el-option-group",
                                    { key: "Users", attrs: { label: "Users" } },
                                    _vm._l(_vm.allUsers, function (user) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: `user-${user.id}`,
                                          staticClass: "p-0",
                                          attrs: {
                                            label: user.name,
                                            value: user.id,
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "media" }, [
                                            _c(
                                              "div",
                                              { staticClass: "media-body" },
                                              [
                                                _c("label", [
                                                  _vm._v(_vm._s(user.name)),
                                                ]),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    _vm._s(user.email) +
                                                      " - " +
                                                      _vm._s(_vm.getLabel(user))
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center mt-3" },
                    [
                      _c("div", { staticClass: "select-label" }, [
                        _vm._v("Teams:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "Select one or more teams.",
                            placement: "top",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "teams-select flex-grow-1 w-100",
                              attrs: {
                                placeholder: "Add one or more teams",
                                multiple: "",
                                filterable: "",
                                "default-first-option": "",
                                "popper-class": "teams-select-popper",
                                disabled: _vm.loading,
                              },
                              model: {
                                value: _vm.ring_group.watcher_team_ids,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ring_group,
                                    "watcher_team_ids",
                                    $$v
                                  )
                                },
                                expression: "ring_group.watcher_team_ids",
                              },
                            },
                            [
                              _vm.filteredTeams && _vm.filteredTeams.length > 0
                                ? _c(
                                    "el-option-group",
                                    { attrs: { label: "Teams" } },
                                    _vm._l(_vm.filteredTeams, function (team) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: team.id,
                                          staticClass: "p-0",
                                          attrs: {
                                            label: team.name,
                                            value: team.id,
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "media" }, [
                                            _c(
                                              "div",
                                              { staticClass: "media-body" },
                                              [
                                                _c("label", [
                                                  _vm._v(_vm._s(team.name)),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("ring-group-reporting", {
            staticClass: "mt-4",
            attrs: {
              settings: _vm.ring_group.reporting_settings,
              "ring-group-name": _vm.ring_group.name,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }