import { render, staticRenderFns } from "./queue-log-table.vue?vue&type=template&id=d35f8580"
import script from "./queue-log-table.vue?vue&type=script&lang=js"
export * from "./queue-log-table.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/app/releases/20250403194912Z/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d35f8580')) {
      api.createRecord('d35f8580', component.options)
    } else {
      api.reload('d35f8580', component.options)
    }
    module.hot.accept("./queue-log-table.vue?vue&type=template&id=d35f8580", function () {
      api.rerender('d35f8580', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/user/components/queue-log-table.vue"
export default component.exports