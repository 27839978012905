<template>
    <base-tool
        :tool-name="tool.name"
        :tool-description="tool.description"
        @back="goBack"
    >
        <contact-lists-selector
            v-model="selectedListIds"
            :multiple="true"
            :value="selectedListIds"
            :list_type="ContactListTypes.STATIC_LIST"
            @change="handleListsChange"
        />
    </base-tool>
</template>

<script>
import BaseTool from './base-tool.vue'
import ContactListsSelector from '../../../contact-lists-selector'
import * as ContactListTypes from '../../../../constants/contact-list-types'
import ToolMixin from './tool-mixin'

export default {
    name: 'ListsTool',

    mixins: [ToolMixin],

    components: {
        BaseTool,
        ContactListsSelector
    },

    props: {
        tool: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            selectedListIds: [],
            ContactListTypes,
        }
    },

    methods: {
        handleListsChange(listIds, listItems) {
            if (!listIds || !listIds.length) {
                this.$emit('lists-selected', [], [])
                return
            }
            
            this.$emit('lists-selected', listIds, listItems)
        }
    }
}
</script>
