<template>
    <div class="app-body"
         id="view">
        <aup-consent @loaded="loading_aup = false"></aup-consent>

        <div class="container-fluid pt-3 d-relative"
             v-if="hasPermissionTo('create broadcast message') && auth.user.profile.bulk_sms_enabled && !loading_aup">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="box col-md-8"
                             style="z-index: 1">
                            <div class="no-effect">
                                <div class="box-body pt-3">
                                    <el-form ref="bulk_message_form"
                                             label-position="top"
                                             :model="bulk_message"
                                             :rules="rules"
                                             @submit.prevent.native="preventSubmit">

                                        <el-form-item prop="name"
                                                      label="Name:">
                                            <div class="width-400">
                                                <el-form-item prop="name">
                                                    <el-input placeholder="Optional"
                                                              maxlength="190"
                                                              show-word-limit
                                                              v-model="bulk_message.name"
                                                              @input="preValidateForm('bulk_message_form')">
                                                    </el-input>
                                                </el-form-item>
                                            </div>
                                        </el-form-item>

                                        <el-form-item label="From:"
                                                      prop="campaign_id">
                                            <campaign-selector ref="campaign_selector"
                                                               placeholder="Lines"
                                                               style="width: 400px;"
                                                               clearable
                                                               is_automated
                                                               check_blocked_messaging
                                                               :value="bulk_message.campaign_id"
                                                               :capabilities="capabilities"
                                                               @change="updateCampaignId">
                                            </campaign-selector>

                                            <el-popover ref="popover_campaign"
                                                        placement="right"
                                                        class="ml-2"
                                                        title="Line"
                                                        width="400"
                                                        trigger="hover"
                                                        content="The line you want to send the bulk messages campaign from.">
                                            </el-popover>
                                            <span class="form-hover-help"
                                                  v-popover:popover_campaign>
                                                What is this?
                                            </span>
                                        </el-form-item>

                                        <div class="row mt-5">
                                            <div class="col-12">
                                                <el-form-item label="Select a group of contacts:">
                                                    <contact-tags-filters-selector
                                                        ref="contactTagFilterSelector"
                                                        :show_integrations="true"
                                                        :tag_category="TagCategories.CAT_CONTACTS"
                                                        @contactTagsGroupChanged="contactTagsGroupChanged"
                                                        @hubspotListChanged="hubspotListChanged"
                                                        @salesforceListChanged="salesforceListChanged"
                                                        @zohoViewChanged="zohoViewChanged"
                                                        @pipedriveFilterChanged="pipedriveFilterChanged"
                                                        @resetTagSelector="resetTagSelector"
                                                        @resetContactFiltersSelector="resetContactFiltersSelector"
                                                        @resetIntegrationsSelector="resetIntegrationsSelector">
                                                    </contact-tags-filters-selector>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="row mt-3 mb-2">
                                            <div class="col-12">
                                                <broadcast-schedule :message="bulk_message"
                                                                    ref="broadcast_schedule">
                                                </broadcast-schedule>
                                            </div>
                                        </div>

                                        <el-form-item label="Throttling:"
                                                      prop="throttle_limit">
                                            <el-tooltip placement="top"
                                                        :disabled="mpsTooltip === ''">
                                                <div slot="content"
                                                     v-html="mpsTooltip">
                                                </div>
                                                <el-select placeholder="Options"
                                                           class="no-select"
                                                           style="width: 400px;"
                                                           v-model="bulk_message.throttle_limit"
                                                           @change="preValidateForm('bulk_message_form')">
                                                    <el-option v-for="option in throttlingOptions"
                                                               :key="option.id"
                                                               :label="option.name"
                                                               :value="option.value">
                                                        <span>{{ option.name }}</span>
                                                    </el-option>
                                                </el-select>
                                            </el-tooltip>

                                            <el-popover ref="popover_throttle_limit"
                                                        placement="right"
                                                        title="Throttling"
                                                        class="ml-2"
                                                        width="400"
                                                        trigger="hover"
                                                        content="This is an hourly throttling limit on your bulk message campaign.">
                                            </el-popover>
                                            <span class="form-hover-help"
                                                  v-popover:popover_throttle_limit>
                                                What is this?
                                            </span>
                                        </el-form-item>

                                        <div class="row mb-3">
                                            <div class="col-12 d-flex">
                                                <div class="box pt-2 pb-2 bulk-sms-counter pull-left mr-2"
                                                     :style="contacts_count == 0 ? {border: 'solid 1px #C4183C'} : {}"
                                                     v-if="!bulk_message.salesforce_list_id && !bulk_message.view_id && !bulk_message.filter_id"
                                                     v-loading="loading_count">
                                                    <span class="inline p-x text-center">
                                                        <span class="h4 block m-a-0">{{ contacts_count }}</span>
                                                        <small class="text-xs text-muted">{{ (contacts_count > 1 || contacts_count == 0) ? 'Contacts' : 'Contact' }}</small>
                                                    </span>
                                                </div>

                                                <div class="box pt-2 pb-2 bulk-sms-counter pull-left mr-2"
                                                     :class="getClass"
                                                     v-if="current_company && current_company.reseller_id != 357 && !bulk_message.view_id">
                                                    <span class="inline p-x text-center">
                                                        <span class="h4 block m-a-0">{{ messagesPrice | fixRounding | toCurrency }}</span>
                                                        <small class="text-xs text-muted">Credits</small>
                                                    </span>
                                                </div>

                                                <div class="box pt-2 pb-2 bulk-sms-counter pull-left"
                                                     v-if="current_company && current_company.reseller_id != 357"
                                                     :class="segmentsCounterClass">
                                                    <span class="inline p-x text-center">
                                                        <span class="h4 block m-a-0">{{ messageCount }}</span>
                                                        <small class="text-xs text-muted">Segments</small>
                                                        <small class="text-xs text-muted"
                                                               v-if="hasMoreThanAscii">
                                                            with non ASCII Characters
                                                        </small>
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="col-12"
                                                 v-if="!isSimpSocial">
                                                <div class="warning-card rounded d-flex flex-column mt-3 p-3">
                                                    This calculator is meant to provide the best estimate for the cost of the broadcast. Actual charges from carriers may vary. <span v-if="selected_campaign && contacts_count">Estimated carrier surcharges of ${{ carrierFee }} will also apply.</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-2 mt-5">
                                            <div class="col-12">
                                                <div class="nav-active-greenish">
                                                    <ul class="nav nav-tabs">
                                                        <li class="nav-item">
                                                            <a class="border border-right-0 rounded-top-left nav-link active"
                                                               href=""
                                                               data-toggle="tab"
                                                               aria-expanded="true"
                                                               :data-target="'#bulk_message'"
                                                               @click.prevent="preventSubmit">
                                                                <i class="material-icons">message</i> Message
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div class="tab-content p-a m-b-md border">
                                                        <div class="tab-pane animated fadeIn text-muted active"
                                                             id="bulk_message"
                                                             aria-expanded="true">
                                                            <div class="row">
                                                                <div class="col-lg-8">
                                                                    <span class="pull-right"><b>Limit:</b> {{ smartEncodedMessageLength }} / 1600</span>
                                                                </div>
                                                            </div>
                                                            <el-form-item prop="message_body">
                                                                <div class="row">
                                                                    <div class="col-lg-8">
                                                                        <input-with-info-text class="pt-1 pb-1"
                                                                                              info-text="[Text <b>STOP</b> to unsubscribe]"
                                                                                              placeholder="Compose your message:"
                                                                                              :model-value="bulk_message.message_body"
                                                                                              :is-loading="generatingUrls"
                                                                                              :is-info-text-active="is_optout_active"
                                                                                              :rows="2"
                                                                                              :left-pixels="12"
                                                                                              :bottom-pixels="2"
                                                                                              v-model="bulk_message.message_body">
                                                                        </input-with-info-text>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <variable-dialog btn_class="w-full"
                                                                                         :in_input_group="false">
                                                                        </variable-dialog>
                                                                        <br>
                                                                        <template-list-dialog btn_class="w-full"/>
                                                                        <el-popover ref="popover_message"
                                                                                    placement="right"
                                                                                    class="ml-2"
                                                                                    title="Message"
                                                                                    width="400"
                                                                                    trigger="hover"
                                                                                    content="The text message you want to send to the selected group of contacts. If the user has no contact name or 'Aloware Contact' as the name, then the variable will be blank">
                                                                        </el-popover>
                                                                        <span class="form-hover-help"
                                                                              v-popover:popover_message>
                                                                            What is this?
                                                                        </span>

                                                                        <el-checkbox v-model="is_optout_active"
                                                                                     :disabled="current_company.force_opt_out_phrase"
                                                                                     @change="imposeMessageCharLimit">
                                                                            <el-tooltip class="item"
                                                                                        effect="dark"
                                                                                        placement="bottom"
                                                                                        :disabled="!isOptoutForced"
                                                                                        :content="optout_tooltip_text">
                                                                                <span>Add opt-out phrase for this message</span>
                                                                            </el-tooltip>
                                                                        </el-checkbox>

                                                                        <div v-if="current_company.simpsocial_integration_enabled">
                                                                            <div>
                                                                                <el-button class="mb-2 w-full"
                                                                                           @click.prevent="openCarForm">
                                                                                    <i class="fa fa-car"></i>
                                                                                    Inventory
                                                                                </el-button>
                                                                            </div>
                                                                            <div>
                                                                                <el-button class="w-full"
                                                                                           @click.prevent="sendCreditApplicationLink">
                                                                                    <i class="fa fa-edit"></i>
                                                                                    Credit Application
                                                                                </el-button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-md-8">
                                                                        <span class="pull-right ml-4">
                                                                            <b>Message parts:</b> {{ messageTtsCount }} / {{ charactersPerPage }}

                                                                        </span>
                                                                        <span class="pull-right"
                                                                              :class="messageCounterClass">
                                                                            Message(s): {{ messageCount }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </el-form-item>
                                                            <div class="warning-card rounded d-flex flex-column p-3 mt-5"
                                                                    v-if="showMessageSentAsMmsDueToAssets">
                                                                The message will be sent via MMS because it has an attachment or GIF attached to it, which may lead to higher-than-expected charges for this broadcast.
                                                            </div>
                                                            <div class="warning-card rounded d-flex flex-column p-3 mt-5"
                                                                    v-else-if="showMessageSentAsMmsWarning">
                                                                The selected line is configured to send long messages via MMS, which may lead to higher-than-expected charges for this broadcast.
                                                            </div>
                                                            <div class="warning-card rounded d-flex flex-column p-3 mt-5"
                                                                    v-else-if="showMessageSentFromTollFreeNumberWarning">
                                                                The selected line is configured with a Toll-free Number, which may lead to higher-than-expected charges for this broadcast.
                                                            </div>
                                                            <div class="warning-card rounded d-flex flex-column p-3 mt-5"
                                                                    v-else-if="showMessageSentFromTollFreeNumberAsMmsWarning">
                                                                The selected line is configured with a Toll-free Number and to send long messages via MMS which may lead to higher-than-expected charges for this broadcast.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-2 mt-2">
                                            <div class="col-12">
                                                <el-form-item>
                                                    <el-switch active-color="#00a344"
                                                               v-model="mms_switch"
                                                               :active-value="1"
                                                               :inactive-value="0"
                                                               @change="changeMMSSwitch">
                                                    </el-switch>
                                                    <label class="el-form-item__label ml-2 float-none">
                                                        Send a GIF / MMS
                                                    </label>
                                                </el-form-item>
                                                <el-form-item label="Media File or GIF (Optional):"
                                                              prop="file_name"
                                                              v-if="bulk_message.file_name !== null && mms_switch">
                                                    <div class="d-flex justify-content-start align-items-center">
                                                        <button class="btn btn-sm blue mr-3"
                                                                @click.prevent="removeMediaFileOrGif(active_tab)">
                                                            <i class="fa fa-sync-alt"></i>
                                                            <span>Change</span>
                                                        </button>

                                                        <button class="btn btn-sm btn-danger"
                                                                @click.prevent="removeMediaFileOrGif(active_tab)">
                                                            <i class="fa fa-trash"></i>
                                                            <span>Remove</span>
                                                        </button>
                                                    </div>
                                                </el-form-item>

                                                <div class="nav-active-greenish"
                                                     v-else-if="mms_switch">
                                                    <ul class="nav nav-tabs">
                                                        <li class="nav-item">
                                                            <a class="border border-right-0 rounded-top-left"
                                                               href=""
                                                               data-toggle="tab"
                                                               aria-expanded="true"
                                                               :class="uploadsNavClass.media"
                                                               :data-target="attachments.media.target"
                                                               @click.prevent="clearAttachmentsAlert('media')">
                                                                <i class="material-icons">insert_photo</i> Media file
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a class="border rounded-top-right"
                                                               aria-expanded="false"
                                                               href=""
                                                               data-toggle="tab"
                                                               :class="uploadsNavClass.gif"
                                                               :data-target="attachments.gif.target"
                                                               @click.prevent="clearAttachmentsAlert('gif')">
                                                                <i class="material-icons">gif</i> GIF file
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div class="tab-content p-a m-b-md border">
                                                        <div id="media-file"
                                                             aria-expanded="true"
                                                             :class="uploadsContainerClass.media">
                                                            <el-form-item label="Media File:"
                                                                          prop="file_name"
                                                                          v-if="active_tab === 'media'">
                                                                <el-upload
                                                                    :action="'/api/v1/broadcasts/upload/media'"
                                                                    :headers="headers"
                                                                    :on-success="onSuccessUpload"
                                                                    :on-error="onFailedUpload"
                                                                    :on-progress="progressUpload"
                                                                    :before-upload="beforeUpload"
                                                                    :file-list="uploadFileList.upload"
                                                                    :limit="1"
                                                                    :multiple="false"
                                                                    drag>
                                                                    <i class="el-icon-upload"></i>
                                                                    <div class="el-upload__text">
                                                                        Drop file here or <em>click to upload</em>
                                                                    </div>
                                                                    <div class="el-upload__tip"
                                                                         slot="tip">
                                                                        <span v-if="current_company.id == 2859">Media file such as jpg, png or gif (less than 1 MB)</span>
                                                                        <span v-else>Media file such as jpg, png or gif (less than 5 MB)</span>
                                                                    </div>
                                                                </el-upload>
                                                                <el-progress :text-inside="true"
                                                                             :stroke-width="18"
                                                                             :percentage="uploadPercentage.upload"
                                                                             :status="uploadStatus.upload"
                                                                             v-if="bulk_message.file_name === null">
                                                                </el-progress>
                                                            </el-form-item>
                                                        </div>
                                                        <div id="search-gif"
                                                             aria-expanded="false"
                                                             :class="uploadsContainerClass.gif"
                                                             v-if="active_tab === 'gif'">
                                                            <el-form-item label="GIF:"
                                                                          prop="file_name">
                                                                <div class="pos-rlt">
                                                                    <search-giphy @selected="setGif"></search-giphy>
                                                                </div>
                                                            </el-form-item>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12">
                                                <el-card class="box-card help-card d-none d-sm-none d-md-block">
                                                    <div class="text item">
                                                        <span class="_600">Tips for a successful bulk message campaign:</span>
                                                        <br>
                                                        <span class="_400">
                                                            - Make sure you have enough numbers attached to the line.
                                                            A good estimate is a new number per 200 attempts.
                                                            Go to the line settings and add more numbers.
                                                        </span>
                                                        <br>
                                                        <span class="_400">
                                                            - Avoid including unbranded URL shorteners like <b>bit.ly</b>.
                                                        </span>
                                                        <br>
                                                        <span class="_400"
                                                              v-if="!statics.whitelabel">
                                                            - If you have any questions about large volumes, please contact our support at support@aloware.com.
                                                        </span>
                                                        <br>
                                                        <span class="_400"
                                                              v-if="!isSimpSocial">
                                                            - Make sure you have enough credits before sending out a bulk message campaign. To recharge, please visit
                                                            <router-link :to="{ name: 'Account', query: { tab: 'billing' }}"
                                                                         class="text-dark-greenish _600"
                                                                         title="Billing">
                                                                Billing
                                                            </router-link>
                                                            .
                                                        </span>
                                                        <br v-if="!isSimpSocial">
                                                        <span class="_400">
                                                            - Make sure to select at least one contact.
                                                        </span>
                                                        <br>
                                                        <span class="_400">
                                                            - Make sure to select a line.
                                                        </span>
                                                    </div>
                                                </el-card>
                                            </div>
                                        </div>
                                    </el-form>

                                    <div class="text-right mt-3">
                                        <button class="btn btn-md greyish mr-2"
                                                @click="resetForm('bulk_message_form')">
                                            Reset
                                        </button>

                                        <button class="btn btn-md greenish"
                                                :disabled="loading || !validated || !countValidated || isMessagingBlocked"
                                                @click="sendBulkMessage">
                                            <i class="material-icons loader"
                                               v-show="loading">
                                                &#xE863;
                                            </i>
                                            Send Bulk Message
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 pos-rlt"
                             style="z-index: 1">
                            <div class="pos-fix col-lg-3 col-md-4 p-0"
                                 style="right: 10px;"
                                 v-if="previewMessage || attachments.file_url">
                                <div class="m-b message">
                                    <div class="d-flex flex-column align-items-end text-right">
                                        <div class="inline r-2x message-body effect7 mt-1 blue-800 text-left"
                                             style="max-width: unset;">
                                            <span class="arrow pull-top arrow-bluish right"></span>
                                            <vue-load-image v-if="attachments.file_url !== null">
                                                <img slot="image"
                                                     class="img-fluid d-block r-2x"
                                                     :src='attachments.file_url'
                                                     v-if="attachments.file_url !== null"/>
                                                <img slot="preloader"
                                                     src="/assets/images/loading.svg"/>
                                                <div slot="error">Error!</div>
                                            </vue-load-image>

                                            <div class="p-a p-y-sm handle-whitespace"
                                                 v-if="previewMessage">
                                                <span>{{ previewMessage }}</span>
                                            </div>

                                            <div class="p-a p-y-sm"
                                                 v-if="attachments.file_url && !previewMessage">
                                                <i>Your message will appear here</i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-backtop target="html"
                    ref="backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>

        <new-car ref="newCarMessage"
                 :contact_id="null"
                 :key="prop_counter"
                 :selected_campaign_id="bulk_message.campaign_id"
                 @send-car="sendCar"
                 @hide="newCarFormClosed">
        </new-car>
    </div>
</template>

<style scoped>
.el-checkbox {
    margin-right: 30px;
    margin-left: auto;
    min-width: 70px;
}

.el-checkbox + .el-checkbox {
    margin-left: auto;
}
</style>

<script>
import auth from '../../auth'
import {mapActions, mapGetters, mapState} from 'vuex'
import {
    acl_mixin,
    form_validation_mixin,
    scroll_mixin,
    sms_mixin,
    string_mixin,
    url_shortener_mixin,
    supervisor_mixin
} from '../../mixins'
import VariableDialog from '../../components/variable-dialog'
import SearchGiphy from '../../components/search-giphy'
import TemplateListDialog from '../../components/template-list-dialog'
import VueLoadImage from 'vue-load-image'
import AupConsent from '../../components/aup-consent'
import NewCar from '../../components/new-car'
import {CONTACTS_WITHOUT_DNC_WITHOUT_OPTOUT} from '../../constants/filter-dnc-options'
import { IS_OPT_OUT_FORCED_TEXT } from '../../constants/compliance-messages'
import * as TagCategories from '../../constants/tag-categories'
import InputWithInfoText from '../../components/input-with-info-text'
import isEmpty from 'lodash/isEmpty'

export default {
    components: {
        TemplateListDialog,
        VariableDialog,
        SearchGiphy,
        AupConsent,
        NewCar,
        'vue-load-image': VueLoadImage,
        InputWithInfoText,
    },

    mixins: [
        acl_mixin,
        sms_mixin,
        scroll_mixin,
        form_validation_mixin,
        string_mixin,
        url_shortener_mixin,
        supervisor_mixin
    ],

    data() {
        return {
            campaigns: [],
            carrier_fees: [],
            reporting_url: localStorage.getItem('reporting_url'),
            attachments: {
                media: {
                    file: null,
                    disabled: false,
                    active: true,
                    target: '#media-file'
                },
                gif: {
                    disabled: false,
                    active: false,
                    target: '#search-gif'
                },
                pdf: {
                    file: null,
                    disabled: false,
                    active: false,
                    target: '#pdf-file'
                },
                file_url: null
            },
            default_attachments: {},
            auth: auth,
            contacts_count: 0,
            loading: false,
            loading_count: false,
            checkAllUS: false,
            checkAllCA: false,
            isUSIndeterminate: true,
            isCAIndeterminate: true,
            filter_tags: null,
            bulk_message: {
                name: '',
                count: 0,
                file_name: null,
                filters: {
                    tags: []
                },
                list_id: null,
                view_id: null,
                salesforce_list_id: null,
                filter_id: null,
                campaign_id: null,
                attachment_type: null,
                run_at_date: null,
                run_at_time: null,
                message_body: '',
                throttle_limit: null,
            },
            selected_campaign: null,
            rules: {
                file_name: [
                    {
                        required: false,
                        message: 'Please upload your media file',
                        trigger: 'change'
                    }
                ],
                file_url: [
                    {
                        required: false,
                        message: 'Please select your GIF file',
                        trigger: 'change'
                    }
                ],
                campaign_id: [
                    {
                        validator: (rule, value, callback) => {
                            const selectedCampaign = this.campaigns.find(campaign => campaign.id === value)
                            if (isEmpty(selectedCampaign)) {
                                callback(new Error('Please select a line'))
                            }
                            callback()
                        },
                        trigger: 'change'
                    }
                ],
                throttle_limit: [
                    {
                        required: true,
                        message: 'Please select a throttling option',
                        trigger: 'change'
                    }
                ],
                message_body: [
                    {
                        required: true,
                        message: 'Message cannot be empty',
                        trigger: 'change'
                    }
                ]
            },

            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + auth.user.profile.api_token,
                'X-Socket-Id': window.Echo.socketId()
            },
            uploadPercentage: {
                upload: 0
            },
            uploadStatus: {
                upload: 'success'
            },
            uploadFileList: {
                upload: []
            },
            mms_switch: 0,
            active_tab: 'media',
            loading_aup: true,
            simpsocial_credit_application_link: null,
            prop_counter: 0,
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true,
            message_total_count: 0,
            business_hours: null,
            send_next_business_hour_window: false,
            accept_outside_business_hours: false,
            outside_business_hours: false,
            is_before_broadcast_hours: false,
            current_campaign_mps: 0,
            is_optout_active: true,
            optout_message: '\nText STOP to unsubscribe.',
            TagCategories,
            optout_tooltip_text: IS_OPT_OUT_FORCED_TEXT,
            shouldSendAsMms: false,
            charactersPerPage: 160,
            is_long_code: true,
            capabilities: ['sms', 'mms'],
        }
    },

    computed: {
        ...mapState(['usage', 'tag_options', 'filter_options']),
        ...mapState('cache', ['current_company']),

        ...mapGetters({
            filter: 'getFilter'
        }),

        ...mapGetters('cache', ['isSimpSocial']),

        useMmsRate() {
            return this.mms_switch
        },

        activeCampaignsAlphabeticalOrder() {
            if (this.campaigns) {
                let campaigns = _.clone(this.campaigns)
                return campaigns
                    .filter(campaign => campaign.active == true)
                    .sort((a, b) => {
                        let textA = a.name.toUpperCase()
                        let textB = b.name.toUpperCase()
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                    })
            }

            return []
        },

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        },

        checkCredits() {
            // SimpSocial can send bulk messages without checking credits
            if (this.isSimpSocial) {
                return true
            }

            let available_local_sms = this.usage.local_sms.included - this.usage.local_sms.used

            // check if we are within the limits of plan
            if (this.usage && available_local_sms >= this.contacts_count && this.usage.plan.type == 1) {
                return true
            }

            let extra_number_of_sms = this.contacts_count - available_local_sms

            // when we pass the limits of plan check extra credits needed matches the current company credits
            if (this.usage && extra_number_of_sms > 0 && this.usage.credits >= this.getSmsPricing(extra_number_of_sms) && this.usage.plan.type == 1) {
                return true
            }

            if (this.usage && this.usage.credits >= this.messagesPrice) {
                return true
            }

            return false
        },

        messagesPrice() {
            return this.getSmsPricing(this.contacts_count)
        },

        getClass() {
            if (this.contacts_count == 0) {
                return 'dker'
            }

            if (this.checkCredits) {
                return 'dark-greenish'
            }

            return 'danger'
        },

        previewMessage() {
            if (this.getMessageBodyLength() === 0) {
                return null
            }
            let preview_message = this.getMessageBody()
            preview_message = preview_message.replace("[FirstName]", "John")
            preview_message = preview_message.replace("[LastName]", "Doe")
            preview_message = preview_message.replace("[FullName]", "John Doe")
            preview_message = preview_message.replace("[Email]", "john.doe@aloware.com")
            preview_message = preview_message.replace("[Address]", "One Infinite Loop")
            preview_message = preview_message.replace("[Website]", "www.apple.com")
            preview_message = preview_message.replace("[CompanyName]", "Apple Inc")
            preview_message = preview_message.replace("[CSF1]", "CSF1")
            preview_message = preview_message.replace("[CSF2]", "CSF2")
            preview_message = preview_message.replace("[ContactNotes]", "contact notes")
            preview_message = preview_message.replace("[LeadNumber]", "(855) 256-2001")
            preview_message = preview_message.replace("[City]", "Los Angeles")
            preview_message = preview_message.replace("[State]", "CA")
            preview_message = preview_message.replace("[FullState]", "California")
            preview_message = preview_message.replace("[Country]", "US")
            preview_message = preview_message.replace("[DateOfBirth]", "02/25/1990")
            if (this.bulk_message.campaign_id && this.getCampaign(this.bulk_message.campaign_id)) {
                preview_message = preview_message.replace("[LineName]", this.getCampaign(this.bulk_message.campaign_id).name)
                preview_message = preview_message.replace("[TrackingNumber]", this.$options.filters.fixPhone(this.getCampaign(this.bulk_message.campaign_id).incoming_numbers[0].phone_number))
            }
            preview_message = preview_message.replace("[ContactId]", "1234")

            return preview_message
        },

        uploadsNavClass() {
            return {
                media: {
                    'disabled': this.attachments.media.disabled,
                    'nav-link': true,
                    'active': this.attachments.media.active
                },
                gif: {
                    'disabled': this.attachments.gif.disabled,
                    'nav-link': true,
                    'active': this.attachments.gif.active
                },
                pdf: {
                    'disabled': this.attachments.pdf.disabled,
                    'nav-link': true,
                    'active': this.attachments.pdf.active
                }
            }
        },

        uploadsContainerClass() {
            return {
                media: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.active_tab
                },
                gif: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.active_tab
                },
                pdf: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.active_tab
                },
            }
        },

        hasMoreThanAscii() {
            if (this.getMessageBody() && this.getMessageBodyLength()) {
                return this.hasUnicode
            }

            return false
        },

        messageTtsCount () {
            if (this.smartEncodedMessageLength > 0) {
                return (this.smartEncodedMessageLength % this.charactersPerPage) || this.charactersPerPage
            }

            return 0
        },

        messageCount() {
            const messageLength = this.smartEncodedMessageLength

            // Return 0 if message is empty or length is 0
            if (messageLength === 0) {
                this.segments = 0
                this.charactersPerPage = 160

                return this.segments
            } else if (this.shouldSendAsMms) {
                this.segments = 1
                this.charactersPerPage = 1600

                return this.segments
            }

            // @see https://www.twilio.com/docs/glossary/what-sms-character-limit
            // 1. when encoded in GSM-7
            //  - the SMS can contain up to 160 characters, but only if there's a single segment
            //  - if there are more than 1 segments, each segment can contain 153 characters, as 7 are used for the segment headers
            // 2. when encoded in UCS-2
            //  - the SMS can contain up to 70 characters, but only if there's a single segment
            //  - if there are more than 1 segments, each segment can contain 67 characters, as 3 are used for the segment headers
            const charactersPerSinglePage = this.hasUnicode ? 70 : 160
            const charactersPerMultiplePages = this.hasUnicode ? 67 : 153

            if (messageLength <= charactersPerSinglePage) {
                this.segments = 1
                this.charactersPerPage = charactersPerSinglePage
            } else {
                this.segments = Math.ceil(messageLength / charactersPerMultiplePages)
                this.charactersPerPage = charactersPerMultiplePages
            }

            return this.segments
        },

        messageCounterClass() {
            if (this.messageCount > 1) {
                return 'text-danger'
            }

            return 'text-success'
        },

        segmentsCounterClass() {
            if (this.messageCount == 0) {
                return 'dker'
            }

            if (this.messageCount == 1) {
                return 'dark-greenish'
            }

            return 'danger'
        },

        countValidated() {
            // Zoho and Pipedrive don't send back the count of contacts in views/filters
            return this.contacts_count != 0 || this.bulk_message.view_id || this.bulk_message.filter_id || this.bulk_message.salesforce_list_id
        },

        hasBroadcastTimeframe() {
            return this.current_company &&
                this.current_company.hasOwnProperty('broadcast_open') &&
                this.current_company.hasOwnProperty('broadcast_close')
        },

        companyTimezone() {
            let utc_date = moment().tz('UTC')
            return moment(utc_date).tz(this.current_company.timezone)
        },

        isBeforeBroadcastTime() {
            if (this.hasBroadcastTimeframe) {
                const company = this.current_company
                const run_at_time = moment(this.runAtDateTime)
                const broadcast_open = moment(this.bulk_message.run_at_date + ' ' + company.broadcast_open)

                return run_at_time.isBefore(broadcast_open)
            }
        },

        scheduleIsPast() {
            if (this.$refs.broadcast_schedule.scheduled) {
                const company_datetime_now = this.companyTimezone.format('MM/DD/YYYY HH:mm:ss')
                return moment(this.runAtDateTime).isBefore(company_datetime_now)
            }

            return false
        },

        runAtDateTime() {
            return this.bulk_message.run_at_date + ' ' + this.bulk_message.run_at_time + ':00'
        },

        throttlingOptions() {
            let currentCampaign = null
            if (this.bulk_message.campaign_id) {
                currentCampaign = this.campaigns.find(campaign => campaign.id === this.bulk_message.campaign_id)
            }
            let options = []
            // @custom for SimpSocial resold accounts
            if (this.isSimpSocial) {
                options = [
                    {
                        id: 1,
                        name: '240 per hour',
                        value: 4
                    },
                    {
                        id: 2,
                        name: '480 per hour',
                        value: 8
                    },
                    {
                        id: 3,
                        name: '960 per hour',
                        value: 16
                    },
                ]

                return options
            }

            if (!currentCampaign) {
                return []
            }

            let mps_list = [0.05, 0.1, 0.25, 0.5, 1, 2, 3, 4, 6, 8, 10]
            let counter = 1
            for (let index = 0; mps_list[index] <= currentCampaign.max_mps; index++) {
                options.push({
                    id: counter++,
                    name: mps_list[index] * 60 * 60 + ' per hour',
                    value: mps_list[index] * 60
                })
            }
            return options
        },

        mpsTooltip() {
            let currentCampaign = null

            if (!this.bulk_message.campaign_id) {
                return 'Please select a Line'
            }

            currentCampaign = this.campaigns.find(campaign => campaign.id === this.bulk_message.campaign_id)

            // Don't display the tooltip to SimpSocial resold accounts
            if (this.isSimpSocial) {
                return ''
            }

            let tooltip = 'Throttling comes directly from the carrier based on brand trust score.'

            if (this.current_campaign_mps <= 0.25) {
                tooltip += ' To increase your MPS rate, please register your line <a style="text-decoration: underline; font-weight: bold" href="/account?tab=compliance">here</a>'
            }

            return tooltip
        },

        isMessagingBlocked() {
            const selectedCampaign = this.campaigns.find(campaign => campaign.id === this.bulk_message.campaign_id)

            if (isEmpty(selectedCampaign)) {
                return false
            }

            return selectedCampaign.blocked_messaging_information['blocked'] && !this.current_company.is_trial
        },

        isOptoutForced() {
            return this.current_company.force_opt_out_phrase
        },

        showMessageSentAsMmsDueToAssets() {
            return this.useMmsRate && !this.isLongSmsAsMmsEnabled
        },

        showMessageSentAsMmsWarning() {
            // Only MMS
            return this.shouldApplyMmsRate() && !this.shouldApplyTollFreeRate()
        },

        showMessageSentFromTollFreeNumberWarning() {
            // Only TFN
            return !this.shouldApplyMmsRate() && this.shouldApplyTollFreeRate()
        },

        showMessageSentFromTollFreeNumberAsMmsWarning() {
            // MMS + TFN
            return this.shouldApplyMmsRate() && this.shouldApplyTollFreeRate()
        },

        carrierFee() {
            if (!this.selected_campaign) {
                return 0
            }

            let prefix = this.is_long_code ? 'long_code' : 'short_code'
            // tollfree takes precedence at the end
            if (this.shouldApplyTollFreeRate()) {
                prefix = 'toll_free'
            }

            const suffix = this.shouldApplyMmsRate() ? 'mms' : 'sms'

            // matches the constants from CarrierFee.php
            const carrier_fee_name = `${prefix}_${suffix}`
            const carrier_fee_per_segment = this.carrier_fees.find(fee => fee.name === carrier_fee_name)?.price ?? 0

            return (carrier_fee_per_segment * this.segments * this.contacts_count).toFixed(2)
        },

        isLongSmsAsMmsEnabled() {
          return this.selected_campaign?.send_long_messages_as_mms || (this.selected_campaign?.id && this.current_company.send_long_messages_as_mms)
        },
    },

    mounted() {
        if (!this.auth.user.profile.bulk_sms_enabled) {
            this.goBack()
        }
        if (!this.hasPermissionTo('create broadcast message')) {
            this.goBack()
        }
        this.initializeFilterOptions()
        this.default_attachments = _.cloneDeep(this.attachments)
        this.rules.file_name.required = this.bulk_message.file_name === null
        this.rules.file_url.required = this.bulk_message.file_name === null
        this.setTitle()
        this.imposeMessageCharLimit()
        this.getCampaigns()
        this.getCarrierFees()

        // Set dates
        this.run_at_date = this.companyTimezone.format('MM/DD/YYYY')
        this.run_at_time = this.companyTimezone.format('HH:mm')

        // only get credit application link when enabled
        if (this.auth.user.profile.company.simpsocial_integration_enabled) {
            axios.get('/integrations/simpsocial/credit-application-link').then(res => {
                this.simpsocial_credit_application_link = res.data.link
            })
        }

        this.messageLength(this.getMessageBody())
    },

    updated() {
        this.preValidateForm('bulk_message_form')
    },

    methods: {
        updateCampaignId(campaignId) {
            this.bulk_message.campaign_id = campaignId

            this.selected_campaign = this.getCampaign(campaignId)
            this.shouldSendAsMms = (this.mms_switch != 0) || this.isLongSmsAsMmsEnabled

            const short_code_numbers = this.selected_campaign?.incoming_numbers?.filter(number => number.is_short_code).length || 0
            const long_code_numbers = this.selected_campaign?.incoming_numbers?.filter(number => !number.is_short_code).length || 0
            this.is_long_code = long_code_numbers >= short_code_numbers

            this.preValidateForm('bulk_message_form')
        },

        imposeMessageCharLimit() {
            // Cleanup string from unwanted encoding - quotes
            this.bulk_message.message_body = this.cleanStringToUTF8(this.bulk_message.message_body.toString())
            const message_body = this.bulk_message.message_body.toString()
            const message_body_length = this.getMessageBodyLength()

            // force reinstatement of maxlength (if bypassed)
            if (message_body && message_body_length > this.getMessageLengthLimit()) {
                this.bulk_message.message_body = message_body.substr(0, this.getMessageLengthLimit())
                this.message_total_count = this.getMessageBodyLength()
            } else {
                this.message_total_count = message_body_length
            }

            this.preValidateForm('bulk_message_form')
        },

        preventSubmit() {
            return false
        },

        initializeFilterOptions() {
            VueEvent.fire('reset_events')
            this.resetContactFilters()
            this.filter_options.is_bulk = true
            this.filter_options.no_export = true
            this.filter_options.no_save_as_tag = true
        },

        setTitle() {
            axios.get('/get-statics').then(res => {
                this.statics = res.data
                this.loading_whitelabel = false
                let name = res.data.name
                this.setPageTitle('Bulk Message - ' + name)
            }).catch(err => {
                this.setPageTitle('Bulk Message - Aloware')
                console.log(err)
                this.$root.handleErrors(err.response)
                this.loading_whitelabel = false
            })
        },

        // executes when there are tag changes from child component
        contactTagsGroupChanged(val = null) {
            this.filter_tags = val
            if (val !== null) {
                this.bulk_message.filters.tags = [val]
            }
            // refresh the count if not empty
            if (!_.isEmpty(this.bulk_message.filters.tags)) {
                this.loading_count = true
                this.bulk_message.filters.export_type = 'count'
                this.bulk_message.filters.is_bulk = true
                // exclude DNC'd contacts from query
                const params = Object.assign({}, this.bulk_message.filters, {'dnc_option': CONTACTS_WITHOUT_DNC_WITHOUT_OPTOUT})
                axios.get(this.reporting_url + '/api/v1/contact/get-contacts-count', {
                    params: params
                }).then(res => {
                    this.contacts_count = res.data.total_contact_count
                    this.loading_count = false
                }).catch(err => {
                    this.contacts_count = 0
                    this.$root.handleErrors(err.response)
                    this.loading_count = false
                })
                this.preValidateForm('bulk_message_form')
            }
        },

        hubspotListChanged(list) {
            this.bulk_message.list_id = list.list_id
            if (list && list.raw_data && list.raw_data.additionalProperties.hs_list_size) {
                this.contacts_count = parseInt(list.raw_data.additionalProperties.hs_list_size)
            } else {
                this.contacts_count = 0
            }
            this.preValidateForm('bulk_message_form')
        },

        salesforceListChanged(list) {
            this.bulk_message.salesforce_list_id = list.list_id
            this.preValidateForm('bulk_message_form')
        },

        zohoViewChanged(view) {
            this.bulk_message.view_id = view.view_id
            this.preValidateForm('bulk_message_form')
        },

        pipedriveFilterChanged(filter) {
            this.bulk_message.filter_id = filter.filter_id
            this.preValidateForm('bulk_message_form')
        },

        // resets the tag selector when changing tabs
        resetTagSelector() {
            this.bulk_message.filters.tags = []
            this.contacts_count = 0
            this.tag_options.is_reset = !this.tag_options.is_reset
            this.preValidateForm('bulk_message_form')
        },

        // resets the contact filter when changing tabs
        resetContactFiltersSelector() {
            this.bulk_message.filters.tags = []
            this.contacts_count = 0
            this.initializeFilterOptions()
            this.preValidateForm('bulk_message_form')
        },

        // resets the integrations when changing tabs
        resetIntegrationsSelector() {
            this.bulk_message.list_id = null
            this.bulk_message.view_id = null
            this.bulk_message.salesforce_list_id = null
            this.bulk_message.filter_id = null
            this.contacts_count = 0
            this.preValidateForm('bulk_message_form')
        },

        cleanUpFilter() {
            for (let index in this.bulk_message.filters) {
                if (isVariableEmpty(this.bulk_message.filters[index]) &&
                    (typeof this.bulk_message.filters[index] === 'number' &&
                        this.bulk_message.filters[index] != 0)) {
                    delete this.bulk_message.filters[index]
                }
            }
        },

        // executes when there are contact filter changes
        contactFiltersChanged() {
            this.bulk_message.filters = _.clone(this.filter)
            this.cleanUpFilter()
            this.loading_count = true
            this.bulk_message.filters.export_type = 'count'
            this.bulk_message.filters.is_bulk = true
            axios.get(this.reporting_url + '/api/v1/contact/get-contacts-count', {
                params: this.bulk_message.filters
            }).then(res => {
                this.contacts_count = res.data.total_contact_count
                this.loading_count = false
            }).catch(err => {
                this.contacts_count = 0
                this.$root.handleErrors(err.response)
                this.loading_count = false
            })
            this.preValidateForm('bulk_message_form')
        },

        setGif(url) {
            this.attachments.media.target = ''
            this.attachments.media.active = false
            this.attachments.gif.active = true
            this.attachments.media.disabled = true
            this.bulk_message.file_name = url
            this.attachments.file_url = url
            this.bulk_message.attachment_type = 'gif'
            this.preValidateForm('bulk_message_form')
        },

        showBroadcastTimeframeWarning() {
            const company = this.current_company

            // get extracted date string only to compose a valid datetime with broadcast timeframes
            const current_date = this.companyTimezone.format('YYYY-MM-DD')
            const format = 'h:mm a'
            let broadcast_open = `${current_date} ${company.broadcast_open}`
            let broadcast_close = `${current_date} ${company.broadcast_close}`

            broadcast_open = moment(broadcast_open).format(format)
            broadcast_close = moment(broadcast_close).format(format)

            if (this.$refs.broadcast_schedule.scheduled) {
                this.$refs.broadcast_schedule.setAssumedNextBusinessHourWindow()
            }

            let is_before_text = (this.isBeforeBroadcastTime || this.is_before_broadcast_hours) ? 'broadcast window' : 'day'
            let next_broadcast_schedule_text = (this.isBeforeBroadcastTime || this.is_before_broadcast_hours) ? moment(this.bulk_message.run_at_date).format('MM/DD/YYYY') : moment(this.bulk_message.run_at_date).add(1, 'days').format('MM/DD/YYYY')

            const confirmation_text = `Schedule this message to send on next ${is_before_text} <b>(${next_broadcast_schedule_text})</b> at <b>${broadcast_open} ${this.companyTimezone.format('z')}</b>`
            const message = `<div>Unable to send bulk message since the current time is outside of your account's defined Broadcast Business Hours: <span class="font-weight-bold">${broadcast_open} - ${broadcast_close} ${this.companyTimezone.add(1, 'days').format('z')}.</span><span class="pl-2"><a href="/account?tab=settings#broadcasts_business_hours" class="border-bottom border-primary text-primary" target="_blank">Edit</a></span></div><div class="mt-3">${confirmation_text}?</div>`

            this.$msgbox({
                title: 'Warning - Outside of Business Hours',
                message: message,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'dialog-business-hours',
                dangerouslyUseHTMLString: true,
            }).then(() => {
                this.send_next_business_hour_window = true
                this.$refs.broadcast_schedule.setScheduleToNextBusinessHourWindow()
                this.accept_outside_business_hours = true
                // attempt to send again, this time, consent has been given to send outside broadcast time setting
                this.sendBulkMessage()
            }).catch(() => {
                // reset to current date & time setting
                this.$refs.broadcast_schedule.isScheduledToNextBusinessHourWindow = false
            })
        },

        updateCurrentCompanyDetails() {
            return axios.get(`/api/v1/company/${this.current_company.id}`)
                .then(res => {
                    this.setCurrentCompany(res.data)
                })
        },

        notifySendSchedulePassed() {
            this.$notify({
                offset: 95,
                title: 'Send Bulk Message',
                message: 'Send schedule has already passed. Kindly update the send schedule. Thank you.',
                type: 'warning',
                showClose: true,
            })
        },

        generateConfirmationMessage() {
            let message = 'Are you sure you want to send this bulk message?';

            if (this.showMessageSentAsMmsDueToAssets) {
                return `${message}\n\nThe message will be sent via MMS because it has an attachment or GIF attached to it, which may lead to higher-than-expected charges for this broadcast.`;
            } else if (this.showMessageSentFromTollFreeNumberAsMmsWarning) {
                return `${message}\n\nThe selected line is configured with a Toll-free Number and to send long messages via MMS which may lead to higher-than-expected charges for this broadcast.`;
            } else if (this.showMessageSentFromTollFreeNumberWarning) {
                return `${message}\n\nThe selected line is configured with a Toll-free Number, which may lead to higher-than-expected charges for this broadcast.`;
            } else if (this.showMessageSentAsMmsWarning) {
                return `${message}\n\nThe selected line is configured to send long messages via MMS, which may lead to higher-than-expected charges.`;
            }

            return '';
        },

        confirmOptOutMessageIncluded() {
            this.$confirm('You haven\'t included the mandatory opt-out message in the SMS as required by law. Are you sure you want to proceed without it?', 'Opt-out Message Confirmation', {
                confirmButtonText: 'Yes, I want to proceed',
                cancelButtonText: 'No, I\'ll add it',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.proceedToSendOrConfirm()
            }).catch(() => {
                return
            })
        },

        confirmMessageSend() {
            const confirmationMessage = this.generateConfirmationMessage();

            if (confirmationMessage) {
                return this.$confirm(confirmationMessage, 'Confirm Message Send', {
                    confirmButtonText: 'Send',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.proceedToSendOrConfirm();
                }).catch(() => {
                    console.log('Message send canceled.');
                    this.loading = false
                });
            }

            return this.proceedToSendOrConfirm();
        },

        proceedToSendOrConfirm() {
            // If user already accepted send broadcast outside broadcast hours
            if (this.accept_outside_business_hours == true) {
                this.sendBroadcast()
            } else {
                this.confirmOutsideBusinessHours()
            }
        },

        confirmOutsideBusinessHours() {
            // If user not yet accepted send broadcast outside broadcast hours, show a notification UI/warning
            this.$confirm('Are you sure that you have the consent of all the recipients?', 'Warning', {
                confirmButtonText: 'Yes, please send',
                cancelButtonText: 'No',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.sendBroadcast()
            }).catch(() => {
                this.send_next_business_hour_window = false
                this.loading = false
                // reset to current date & time setting
                this.$refs.broadcast_schedule.isScheduledToNextBusinessHourWindow = false
            })
        },

        async sendBulkMessage() {
            this.loading = true

            // in case the broadcast setting gets updated, we will be able to reference to that
            await this.updateCurrentCompanyDetails()

            if (this.scheduleIsPast) {
                this.notifySendSchedulePassed()
                this.$refs.broadcast_schedule.initTimeSelectOptions()
                this.loading = false
                return
            }

            if (!this.is_optout_active) {
                this.confirmOptOutMessageIncluded()
                this.loading = false
                return
            }

            this.confirmMessageSend()
        },

        removeOptoutPhraseFromMessageBody() {
            if (this.is_optout_active && this.bulk_message.message_body.includes(this.optout_message)) {
                return this.bulk_message.message_body = this.bulk_message.message_body.replace(this.optout_message, '')
            }

            return this.bulk_message.message_body
        },

        sendBroadcast() {
            this.bulk_message.count = this.contacts_count
            this.bulk_message.message_body = this.getMessageBody()

            axios.post('/api/v1/broadcasts/send-bulk-messages', {
                ...this.bulk_message,
                accept_outside_business_hours: this.accept_outside_business_hours,
                opt_out_bypassed: !this.is_optout_active
            })
                .then(res => {
                    if (this.accept_outside_business_hours == false && res.data.outside_business_hours == true) {
                        this.loading = false
                        this.outside_business_hours = true
                        this.is_before_broadcast_hours = res.data.is_before_broadcast_hours
                        this.showBroadcastTimeframeWarning()
                        return
                    }
                    // UI notfication
                    this.$notify.success({
                        offset: 95,
                        title: 'Bulk Message',
                        dangerouslyUseHTMLString: true,
                        message: `We have put your bulk message campaign on our outbound queue. Please wait a few minutes for us to send your messages. Meanwhile, you can monitor the progress from the dashboard's communication logs table.`,
                        duration: 5000
                    })
                    // Reset contact tag selector
                    if (this.$refs.contactTagFilterSelector) {
                        this.$refs.contactTagFilterSelector.resetSelectors()
                    }
                    this.loading = false
                    this.$router.push({name: 'Broadcast', query: {status: 'all'}})
                    this.newBroadcast(res.data.broadcast)
                })
                .catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading = false
                    this.send_next_business_hour_window = false
                    this.accept_outside_business_hours = false
                    this.is_before_broadcast_hours = false
                    this.bulk_message.message_body = this.getMessageBody(false)
                    this.removeOptoutPhraseFromMessageBody()
                })
        },

        removeMediaFileOrGif(type = 'media', should_validate = true) {
            this.bulk_message.file_name = null
            this.bulk_message.attachment_type = null
            this.resetAttachments()
            switch (type) {
                case 'media':
                    this.uploadPercentage.upload = 0
                    break
                case 'gif':
                    this.attachments.gif.active = true
                    this.attachments.media.active = false
                    break
            }
            this.active_tab = type
            if (should_validate) {
                this.preValidateForm('bulk_message_form')
            }
        },

        resetForm(form_name) {
            this.bulk_message.count = 0
            this.bulk_message.message_body = ''
            this.bulk_message.file_name = null
            this.bulk_message.target_campaign_id = null
            this.bulk_message.campaign_id = null
            this.bulk_message.throttle_limit = null
            this.bulk_message.attachment_type = null
            this.bulk_message.run_at_date = null
            this.bulk_message.run_at_time = null
            this.scheduled = false
            this.resetAttachments()
            this.contacts_count = 0
            this.validated = false
            this.isUSIndeterminate = true
            this.isCAIndeterminate = true

            let form_element = _.get(this.$refs, form_name, null)

            if (form_element) {
                form_element.resetFields()
                form_element.clearValidate()
            }

            this.active_tab = 'media'
        },

        resetAttachments() {
            for (let attachment_index in this.default_attachments) {
                if (typeof this.attachments[attachment_index] === 'object') {
                    for (let index in this.attachments[attachment_index]) {
                        this.attachments[attachment_index][index] = this.default_attachments[attachment_index][index]
                    }
                } else {
                    this.attachments[attachment_index] = this.default_attachments[attachment_index]
                }
            }
        },

        clearAttachmentsAlert(attachment_type) {
            this.active_tab = attachment_type
            if (this.bulk_message.file_name === null) {
                return
            }

            let message = ''
            if (attachment_type !== 'gif') {
                message = 'Please remove the selected GIF to continue.'
            } else if (attachment_type !== 'pdf') {
                message = 'Please remove the pdf file to continue.'
            } else {
                message = 'Please remove the media file to continue.'
            }
            this.$alert(message, 'Oops!', {
                confirmButtonText: 'Ok',
                center: true,
                callback: cancel => {
                }
            })
        },

        onSuccessUpload(res, file) {
            this.$notify({
                offset: 95,
                title: 'Bulk Message',
                message: 'File uploaded successfully.',
                type: 'success',
                showClose: true,
            })
            this.$set(this.bulk_message, 'file_name', res.file_name)
            this.bulk_message.attachment_type = 'media'
            this.attachments.gif.target = ''
            this.attachments.gif.active = false
            this.attachments.media.active = true
            this.attachments.gif.disabled = true

            this.uploadStatus.upload = 'success'
            this.uploadPercentage.upload = 0
            this.preValidateForm('bulk_message_form')

            // create a new FileReader to read this image and convert to base64 format
            let reader = new FileReader()
            // Define a callback function to run, when FileReader finishes its job
            reader.onload = (e) => {
                // Note: arrow function used here, so that "this.attachments" refers to the attachments of Vue component
                // Read image as base64 and set to attachments
                this.attachments.file_url = e.target.result
            }
            // Start the reader job - read file as a data url (base64 format)
            reader.readAsDataURL(file.raw)
        },

        onFailedUpload(err) {
            this.handleUploadErrors(err.message)
            this.uploadStatus.upload = 'exception'
            this.uploadPercentage.upload = 0
            this.preValidateForm('bulk_message_form')
        },

        beforeUpload() {
            this.attachments.gif.disabled = true
            this.uploadStatus.upload = 'success'
            this.uploadPercentage.upload = 0
        },

        progressUpload(event) {
            this.uploadPercentage.upload = parseInt(event.percent)
        },

        handleUploadErrors(error) {
            if (typeof error === 'string') {
                error = JSON.parse(error)
            }
            let err
            if (error.message === 'This action is unauthorized.') {
                err = {
                    status: 403,
                }
            } else {
                let is_error = typeof error.error !== 'undefined'
                err = {
                    status: 422,
                    data: {
                        errors: is_error ? [error.error] : error.errors.file
                    }
                }
            }
            this.uploadPercentage.upload = 0
            this.$root.handleErrors(err)
        },

        getCampaign(id) {
            if (!id) {
                return null
            }
            let found = this.campaigns.find(campaign => campaign.id === id)
            if (found) {
                return found
            }

            return null
        },

        changeMMSSwitch(mms_switch) {
            this.mms_switch = mms_switch
            if (mms_switch === 0) {
                this.removeMediaFileOrGif('media')
                this.removeMediaFileOrGif('gif')
                this.shouldSendAsMms = this.isLongSmsAsMmsEnabled
            } else {
                this.attachments.media.active = true
                this.attachments.gif.active = false
                this.active_tab = 'media'
                this.shouldSendAsMms = true
            }
        },

        shouldApplyTollFreeRate() {
            return this.selected_campaign?.has_tollfree_pn
        },

        shouldApplyMmsRate() {
            return this.useMmsRate || this.shouldSendAsMms
        },

        getSmsPricing(contacts_count = 0) {
            const local_sms_rate = this.auth.user.profile.rate.local_sms
            const local_mms_rate = this.auth.user.profile.rate.local_mms
            const tollfree_sms_rate = this.auth.user.profile.rate.tollfree_sms
            const tollfree_mms_rate = this.auth.user.profile.rate.tollfree_mms

            let message_rate = local_sms_rate
            if (this.shouldApplyTollFreeRate() && this.shouldApplyMmsRate()) {
                message_rate = tollfree_mms_rate
            } else if (this.shouldApplyTollFreeRate()) {
                message_rate = tollfree_sms_rate
            } else if (this.shouldApplyMmsRate()) {
                message_rate = local_mms_rate
            }

            return contacts_count * this.messageCount * message_rate
        },

        ...mapActions(['resetContactFilters']),

        /**
         * ---------------------------------
         * | SimpSociaal Integration
         * _________________________________
         */
        openCarForm() {
            this.$refs.newCarMessage.show_add = true
            this.$refs.newCarMessage.send_car.campaign_id = this.bulk_message.campaign_id
            this.$refs.newCarMessage.bulk_message_mode = true
        },

        sendCar(car) {
            let msg = this.$options.filters.strLimit(car.description, 100, true)
            msg += '\r\n'
            msg += car.car_link + '_[ContactId]'

            this.bulk_message.message_body = msg
            if (car.galleries && car.galleries.length > 0 && car.galleries[0].url) {
                this.attachments.gif.target = ''
                this.attachments.gif.active = false
                this.attachments.media.active = true
                this.attachments.gif.disabled = true
                this.attachments.file_url = car.galleries[0].url

                this.bulk_message.attachment_type = 'media'
                axios.post('/integrations/simpsocial/image', {
                    file_url: car.galleries[0].url
                }).then(res => {
                    this.bulk_message.file_name = res.data.uploaded_file.uuid
                })
            }
        },

        sendCreditApplicationLink() {
            if (this.simpsocial_credit_application_link.length > 0) {
                if (this.bulk_message.message_body == null) {
                    this.bulk_message.message_body = ''
                }
                this.bulk_message.message_body += 'Credit Application Link: ' + this.simpsocial_credit_application_link
            }
        },

        newCarFormClosed() {
            this.prop_counter += 1
        },

        getOptoutMessage() {
            return this.is_optout_active ? `${this.optout_message}` : ''
        },

        getOptoutMessageLength() {
            return this.getOptoutMessage().length
        },

        getMessageBodyLength() {
            const message_body = this.bulk_message.message_body || ''
            return message_body.length + this.getOptoutMessageLength()
        },

        getMessageBody(with_optout_message = true) {
            const message_body = this.bulk_message.message_body || ''

            return `${message_body}${with_optout_message ? this.getOptoutMessage() : ''}`
        },

        getMessageLengthLimit() {
            return this.is_optout_active ? 1600 - this.getOptoutMessageLength() : 1600
        },

        getCampaigns() {
            axios.get('/api/v1/campaign').then(res => {
                this.campaigns = res.data
            }).catch(err => {
                console.log(err)
            })
        },

        getCarrierFees() {
            axios.get('/api/v1/carrier-fees').then(res => {
                this.carrier_fees = res.data
            }).catch(err => {
                console.log(err)
            })
        },

        ...mapActions('cache', ['setCurrentCompany'])
    },

    watch: {
        'filter_options.is_changed': function () {
            if (this.filter.changed) {
                this.contactFiltersChanged()
            }
        },
        'bulk_message.message_body': function (message) {
            this.messageLength(this.getMessageBody())

            this.imposeMessageCharLimit()
        }
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                let auth = {
                    user: {
                        profile: res.data.user
                    }
                }

                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next(vm => {
                        if (!vm.hasPermissionTo('create broadcast message', auth.user.profile.user_permissions)) {
                            next({name: 'Dashboard'})
                        }
                    })
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script>
