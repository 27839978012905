var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _vm.empty_overall_stats
              ? _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("el-alert", {
                        staticClass: "mb-3",
                        attrs: {
                          title: "Empty results",
                          type: "info",
                          description:
                            "The current filters didn't match any search result.",
                          "data-tes":
                            "transcription-reporting-empty-results-alert",
                          closable: false,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-4" },
                [
                  _c(
                    "h5",
                    { staticClass: "text-greyish mb-0 align-self-end" },
                    [_vm._v("Overall Stats")]
                  ),
                  _vm._v(" "),
                  _c("summary-report-card", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.overall_stat_loading,
                        expression: "overall_stat_loading",
                      },
                    ],
                    attrs: {
                      data: _vm.overallStats,
                      chart_type: null,
                      col: 4,
                      is_transcription: true,
                      "data-testid": "overall-stats-summary-report-card",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.sentimentCharts
                ? _c(
                    "div",
                    { staticClass: "col-4" },
                    [
                      _c(
                        "h5",
                        { staticClass: "text-greyish mb-0 align-self-end" },
                        [
                          _vm._v(
                            "\n                                Agent Sentiment\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("highcharts", {
                        ref: "agent_chart",
                        attrs: {
                          "data-testid": "agent-sentiment-highcharts",
                          options: _vm.sentimentCharts.agent_chart_options,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.sentimentCharts
                ? _c(
                    "div",
                    { staticClass: "col-4" },
                    [
                      _c(
                        "h5",
                        { staticClass: "text-greyish mb-0 align-self-end" },
                        [
                          _vm._v(
                            "\n                                Customer Sentiment\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("highcharts", {
                        ref: "customer_chart",
                        attrs: {
                          "data-testid": "customer-sentiment-highcharts",
                          options: _vm.sentimentCharts.contact_chart_options,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "padding" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-8" },
                [
                  _c("div", { staticClass: "row" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 text-right" },
                      [
                        _vm.hasRole("Company Admin")
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "Account",
                                    query: { tab: "transcription-settings" },
                                    hash: "#custom_keywords",
                                  },
                                  "data-testid": "word-analytics-router-link",
                                },
                              },
                              [
                                !_vm.isEmpty(_vm.customKeywordsTable)
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            "Modify your custom keywords",
                                          "data-testid":
                                            "word-analytics-edit-tooltip",
                                          placement: "bottom",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              "data-testid":
                                                "word-analytics-edit-button",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                Edit\n                                            "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "Add your custom keywords",
                                          "data-testid":
                                            "word-analytics-add-tooltip",
                                          placement: "bottom",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "success",
                                              "data-testid":
                                                "word-analytics-add-button",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                Add\n                                            "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            "padding-left": "0!important",
                            "padding-right": "0!important",
                          },
                          attrs: { span: 7 },
                        },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.custom_keyword_table_loading,
                                  expression: "custom_keyword_table_loading",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.customKeywordsTable,
                                "default-sort": {
                                  prop: "value",
                                  order: "descending",
                                },
                                "data-testid": "word-analytics-table",
                                height: "400",
                              },
                            },
                            [
                              _c("template", { slot: "empty" }, [
                                _vm._v(_vm._s(_vm.empty_keywords_text)),
                              ]),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "name",
                                  label: "Keywords",
                                  sortable: "",
                                  align: "center",
                                  "data-testid": "word-analytics-name-column",
                                  "header-align": "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "value",
                                  label: "Occurrences",
                                  sortable: "",
                                  align: "center",
                                  "data-testid": "word-analytics-value-column",
                                  "header-align": "center",
                                },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c("highcharts", {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.custom_keyword_table_loading,
                                expression: "custom_keyword_table_loading",
                              },
                            ],
                            ref: "custom_keyword_chart",
                            attrs: {
                              "data-testid": "word-analytics-highcharts",
                              options: _vm.customKeywords,
                              loading: _vm.loading,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            "padding-left": "0!important",
                            "padding-right": "0!important",
                          },
                          attrs: { span: 7 },
                        },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.custom_keyword_table_loading,
                                  expression: "custom_keyword_table_loading",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.highlights,
                                "default-sort": {
                                  prop: "value",
                                  order: "descending",
                                },
                                "data-testid": "word-analytics-table-1",
                                height: "400",
                              },
                            },
                            [
                              _c("template", { slot: "empty" }, [
                                _vm._v(_vm._s(_vm.empty_highlights_text)),
                              ]),
                              _vm._v(" "),
                              _c("el-table-column", {
                                staticStyle: {
                                  "font-size": "10px!important",
                                  color: "blue",
                                },
                                attrs: {
                                  prop: "name",
                                  label: "Highlights",
                                  sortable: "",
                                  align: "center",
                                  "data-testid": "word-analytics-name-column-1",
                                  "header-align": "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "value",
                                  label: "Occurrences",
                                  sortable: "",
                                  align: "center",
                                  "data-testid":
                                    "word-analytics-value-column-1",
                                  "header-align": "center",
                                },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-4" },
                [
                  _c(
                    "h5",
                    { staticClass: "text-greyish mb-0 align-self-end" },
                    [
                      _vm._v(
                        "\n                                Top Categories\n                            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("highcharts", {
                    ref: "top_categories_chart",
                    attrs: {
                      loading: _vm.loading,
                      "data-testid": "top-categories-highcharts",
                      options: _vm.top5Categories,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "padding" }, [
            _c("div", { staticClass: "row" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "col-12" }, [
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    "\n                                " +
                      _vm._s(_vm.getTranscriptionHistoryCount) +
                      " transcriptions\n                            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c(
                    "el-input",
                    {
                      ref: "saveTagInput",
                      staticStyle: { width: "250px" },
                      attrs: {
                        clearable: "",
                        placeholder: "Look up a word",
                        size: "medium",
                        "data-testid": "transcription-history-search-input",
                      },
                      on: { change: _vm.onEnter, clear: _vm.fetchHistoryTable },
                      model: {
                        value: _vm.q,
                        callback: function ($$v) {
                          _vm.q = $$v
                        },
                        expression: "q",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-input__icon el-icon-search",
                        attrs: { slot: "suffix" },
                        slot: "suffix",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.history_table_loading,
                          expression: "history_table_loading",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: {
                        data: _vm.getTranscriptionHistory,
                        "data-testid": "transcription-history-table",
                        fit: "",
                        stripe: "",
                      },
                    },
                    [
                      _c("template", { slot: "empty" }, [
                        _vm._v("No Transcriptions to display."),
                      ]),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          width: "55",
                          "data-testid": "transcription-history-column",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: _vm.dispositionTooltipData(
                                        scope.row.communication
                                          .disposition_status2,
                                        scope.row.communication.direction,
                                        scope.row.communication.type,
                                        scope.row.communication.callback_status
                                      ),
                                      "data-testid":
                                        "transcription-history-column-tooltip",
                                      placement: "right",
                                    },
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "Communication",
                                            params: {
                                              communication_id:
                                                scope.row.communication.id,
                                            },
                                          },
                                          "data-testid":
                                            "transcription-history-column-router-link",
                                        },
                                      },
                                      [
                                        _c("div", {
                                          staticClass:
                                            "status-icon centered-content",
                                          attrs: {
                                            state:
                                              scope.row.communication
                                                .disposition_status2,
                                          },
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.stateToIcon(
                                                scope.row.communication
                                                  .disposition_status2,
                                                scope.row.communication
                                                  .direction,
                                                scope.row.communication.type,
                                                scope.row.communication
                                                  .callback_status
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "Agent",
                          "data-testid": "transcription-history-agent-column",
                          "min-width": "162",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "row" }, [
                                  scope.row.agent
                                    ? _c(
                                        "div",
                                        { staticClass: "col-12 d-flex" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "User Activity",
                                                  params: {
                                                    user_id: scope.row.agent.id,
                                                  },
                                                },
                                                "data-testid":
                                                  "user-activity-router-link",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-dark-greenish",
                                                  attrs: {
                                                    title:
                                                      scope.row.agent.full_name,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                        " +
                                                      _vm._s(
                                                        scope.row.agent
                                                          .full_name
                                                      ) +
                                                      "\n                                                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 d-flex" },
                                    [
                                      scope.row.communication.campaign_id
                                        ? _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "Line Activity",
                                                  params: {
                                                    campaign_id:
                                                      scope.row.communication
                                                        .campaign_id,
                                                  },
                                                },
                                                "data-testid":
                                                  "line-activity-router-link",
                                              },
                                            },
                                            [
                                              _vm.getCampaign(
                                                scope.row.communication
                                                  .campaign_id
                                              )
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "text-dark-greenish",
                                                      attrs: {
                                                        "data-testid":
                                                          "user-activity-link",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                        " +
                                                          _vm._s(
                                                            _vm.getCampaign(
                                                              scope.row
                                                                .communication
                                                                .campaign_id
                                                            ).name
                                                          ) +
                                                          "\n                                                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row" }, [
                                  scope.row.communication.type !== 5
                                    ? _c(
                                        "div",
                                        { staticClass: "col-12 d-flex" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-greyish" },
                                            [
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm._f("fixPhone")(
                                                      scope.row.communication
                                                        .incoming_number
                                                    )
                                                  ) +
                                                  "\n                                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        { staticClass: "col-12 d-flex" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-greyish" },
                                            [
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    scope.row.communication
                                                      .incoming_number
                                                  ) +
                                                  "\n                                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "Talk Time",
                          prop: "talk_time",
                          "data-testid":
                            "transcription-history-talk-time-column",
                          width: "120",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.communication.type ===
                                _vm.CommunicationTypes.CALL
                                  ? _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-12 d-flex" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "\n                                                    " +
                                                _vm._s(
                                                  _vm._f("fixDuration")(
                                                    scope.row.communication
                                                      .talk_time
                                                  )
                                                ) +
                                                "\n                                                "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-12 d-flex" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getVisibleStatus(
                                            scope.row.communication
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "Duration",
                          prop: "duration",
                          "data-testid":
                            "transcription-history-duration-column",
                          width: "110",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                ![
                                  _vm.CommunicationTypes.SMS,
                                  _vm.CommunicationTypes.EMAIL,
                                ].includes(scope.row.communication.type)
                                  ? _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-12 d-flex" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("fixDuration")(
                                                  scope.row.communication
                                                    .duration
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-12 d-flex" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getVisibleStatus(
                                            scope.row.communication
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "Contact",
                          "data-testid": "transcription-history-contact-column",
                          "min-width": "162",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row d-flex align-items-center",
                                  },
                                  [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c("div", { staticClass: "row" }, [
                                        scope.row.contact
                                          ? _c(
                                              "div",
                                              { staticClass: "col-12 d-flex" },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      target: "_blank",
                                                      href: _vm.getContactTalkRedirectURL(
                                                        scope.row.contact.id
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        staticClass:
                                                          "item pull-left",
                                                        attrs: {
                                                          effect: "dark",
                                                          content:
                                                            "Click For More Info",
                                                          "data-testid":
                                                            "more-info-contact-tooltip",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-dark-greenish",
                                                            attrs: {
                                                              title: _vm._f(
                                                                "fixContactName"
                                                              )(
                                                                scope.row
                                                                  .contact.name
                                                              ),
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                                                  " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "fixContactName"
                                                                  )(
                                                                    scope.row
                                                                      .contact
                                                                      .name
                                                                  )
                                                                ) +
                                                                "\n                                                                            "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    scope.row.communication
                                                      .first_time_caller
                                                      ? _c("i", {
                                                          staticClass:
                                                            "on b-white bottom caller-status pull-left",
                                                          attrs: {
                                                            title:
                                                              "First Time Conversation",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "col-12 d-flex" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-dark-greenish pull-left",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                            " +
                                                        _vm._s(
                                                          _vm._f("fixPhone")(
                                                            scope.row
                                                              .communication
                                                              .lead_number
                                                          )
                                                        ) +
                                                        "\n                                                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                scope.row.communication
                                                  .first_time_caller
                                                  ? _c("i", {
                                                      staticClass:
                                                        "on b-white bottom caller-status pull-left",
                                                      attrs: {
                                                        title:
                                                          "First Time Conversation",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ]
                                            ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-12 d-flex" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "text-greyish" },
                                              [
                                                _vm._v(
                                                  "\n                                                            " +
                                                    _vm._s(
                                                      _vm._f("fixPhone")(
                                                        scope.row.communication
                                                          .lead_number
                                                      )
                                                    ) +
                                                    "\n                                                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      scope.row.contact.disposition_status_id
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "mt-1",
                                              attrs: {
                                                "data-testid":
                                                  "comm-log-tag-div",
                                              },
                                            },
                                            [
                                              scope.row.contact
                                                .disposition_status_id
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-greyish text-xs",
                                                    },
                                                    [
                                                      _c(
                                                        "i",
                                                        {
                                                          staticClass:
                                                            "material-icons",
                                                          style: {
                                                            color:
                                                              _vm.$options.filters.dispositionStatusColor(
                                                                scope.row
                                                                  .contact
                                                                  .disposition_status_id
                                                              ),
                                                          },
                                                        },
                                                        [_vm._v("label")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-grey-900",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "dispositionStatusName"
                                                              )(
                                                                scope.row
                                                                  .contact
                                                                  .disposition_status_id
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.contact &&
                                      scope.row.contact.company_name
                                        ? _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-12 d-flex text-pink-500 text-xs",
                                              },
                                              [
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons",
                                                  },
                                                  [_vm._v("business_center")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "ml-1" },
                                                  [
                                                    _vm._v(
                                                      "\n                                                            " +
                                                        _vm._s(
                                                          scope.row.contact
                                                            .company_name
                                                        ) +
                                                        "\n                                                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "Start Time",
                          "data-testid":
                            "transcription-history-start-time-column",
                          prop: "created_at",
                          "min-width": "162",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", { staticClass: "text-greyish" }, [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(
                                        _vm._f("fixDateTime")(
                                          scope.row.communication.created_at
                                        )
                                      ) +
                                      "\n                                        "
                                  ),
                                ]),
                                _vm._v(" "),
                                scope.row.communication.call_disposition_id
                                  ? _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-12 d-flex text-xs",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-bolt",
                                            style: {
                                              color:
                                                _vm.$options.filters.callDispositionColor(
                                                  scope.row.call_disposition_id
                                                ),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "ml-1 text-grey-900",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "callDispositionName"
                                                    )(
                                                      scope.row.communication
                                                        .call_disposition_id
                                                    )
                                                  ) +
                                                  "\n                                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.communication.has_recording ||
                                scope.row.recording_is_deleted
                                  ? _c("div", { staticClass: "row mt-2" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-12" },
                                        [
                                          _c("communication-audio", {
                                            attrs: {
                                              communication:
                                                scope.row.communication,
                                              "data-testid":
                                                "has-recording-communication-audio",
                                              type: _vm.UploadedFileTypes
                                                .TYPE_CALL_RECORDING,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.communication.has_voicemail
                                  ? _c("div", { staticClass: "row mt-2" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-12" },
                                        [
                                          _c("communication-audio", {
                                            attrs: {
                                              communication:
                                                scope.row.communication,
                                              "data-testid":
                                                "has-voiceemail-communication-audio",
                                              type: _vm.UploadedFileTypes
                                                .TYPE_CALL_VOICEMAIL,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "Agent Sentiment",
                          "data-testid":
                            "transcription-history-agent-sentiment-column",
                          "min-width": "162",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", { staticClass: "text-greyish" }, [
                                  _c(
                                    "span",
                                    {
                                      class:
                                        _vm.sentiment_colors[
                                          scope.row.agent_sentiment
                                        ],
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                " +
                                          _vm._s(scope.row.agent_sentiment) +
                                          "\n                                            "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "Contact Sentiment",
                          "data-testid":
                            "transcription-history-contact-sentiment-column",
                          "min-width": "162",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", { staticClass: "text-greyish" }, [
                                  _c(
                                    "span",
                                    {
                                      class:
                                        _vm.sentiment_colors[
                                          scope.row.contact_sentiment
                                        ],
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                " +
                                          _vm._s(scope.row.contact_sentiment) +
                                          "\n                                            "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "Operations",
                          "data-testid":
                            "transcription-history-operations-column",
                          width: "250",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "Call Details",
                                      "data-testid":
                                        "transcription-history-operations-tooltip",
                                      placement: "bottom",
                                    },
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm greyish btn-icon opaq",
                                        attrs: {
                                          "data-testid":
                                            "go-to-communication-info-button",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.gotoCommunicationInfo(
                                              scope.row.communication.id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "i",
                                          { staticClass: "material-icons" },
                                          [_vm._v("info_outline")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                !scope.row.communication
                                  .transcription_is_deleted &&
                                scope.row.communication.has_transcription
                                  ? _c("communication-transcription-button", {
                                      staticClass: "d-inline",
                                      attrs: {
                                        communication: scope.row.communication,
                                        button_text: "Show Transcription",
                                        single_button: true,
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("el-pagination", {
                    staticClass: "pull-right pt-4",
                    attrs: {
                      background: "",
                      layout: "sizes, prev, pager, next",
                      "hide-on-single-page": true,
                      "page-sizes": _vm.page_sizes,
                      "page-size": _vm.max_per_page,
                      total: _vm.getTranscriptionHistoryCount,
                      "page-count": _vm.getTotalPages,
                      "current-page": _vm.transcription_page,
                      "data-testid":
                        "transcription-reporting-components-pagination",
                    },
                    on: {
                      "update:pageSize": function ($event) {
                        _vm.max_per_page = $event
                      },
                      "update:page-size": function ($event) {
                        _vm.max_per_page = $event
                      },
                      "update:currentPage": function ($event) {
                        _vm.transcription_page = $event
                      },
                      "update:current-page": function ($event) {
                        _vm.transcription_page = $event
                      },
                      "current-change": _vm.handleCurrentChange,
                      "size-change": _vm.handleSizeChange,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6" }, [
      _c("h5", { staticClass: "text-greyish mb-0 align-self-end" }, [
        _vm._v(
          "\n                                        Word Analytics\n                                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("h5", { staticClass: "text-greyish mb-0 align-self-end" }, [
        _vm._v(
          "\n                                Transcription History\n                            "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }