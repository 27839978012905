/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import {mapActions, mapGetters, mapState} from 'vuex'
import router from './router'
import broadcast from './broadcast'
import store from './store'
import auth from './auth'
import {Loading, Notification} from 'element-ui'
import {acl_mixin, chargebee_mixin, communication_mixin, helper_mixin, html_mixin, user_mixin} from './mixins'
import {getCookie, removeCookie} from '../utils/functions'
import CommunicationNotification from './components/communication-notification'
import VoicemailNotification from './components/voicemail-notification'
import MessagingServiceNotification from './components/messaging-service-notification'
import ComplianceInvitationNotification from './components/compliance-invitation-notification'
import ComplianceRegistrationNotification from './components/compliance-registration-notification'
import * as ExportStatus from './constants/export-status'
import * as AgentStatus from './constants/agent-status'
import * as CommunicationTypes from './constants/communication-types'
import * as CommunicationDispositionStatus from "./constants/communication-disposition-status"
import * as CommunicationCurrentStatus from "./constants/communication-current-status"
import * as ComplianceBundleStatuses from "./constants/compliance-bundle-statuses"
import './plugins/vue-tab-events'

import TeamsApi from './api/v2/Teams'

// Set global mixins here
Vue.mixin(html_mixin)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
const app = new Vue({
    mixins: [communication_mixin, chargebee_mixin, user_mixin, helper_mixin, acl_mixin],
    el: '#app',
    router,
    store,

    components: {
        'communication-notification': CommunicationNotification,
        'voicemail-notification': VoicemailNotification
    },

    data: {
        auth: auth,
        user: auth.user,
        chargebeeInstance: null,
        sb: null,
        fullstory_org_id: localStorage.getItem('fullstory_org_id'),
        loading: true,
        loading_campaigns: false,
        loading_users: false,
        loading_extensions: false,
        loading_disposition_statuses: false,
        loading_call_disposition: false,
        loading_activity_types: false,
        loading_exports: false,
        loading_workflows: false,
        loading_ring_groups: false,
        loading_teams: false,
        loading_ivrs: false,
        loading_ivr_prompt_files: false,
        loading_scripts: false,
        loading_whitelabel: false,
        loading_lead_sources: false,
        loading_sms_templates: false,
        is_widget: false,
        notification_audio: new Audio('/static/ivr/default-communication-notification.mp3'),
        statics: {
            logo: null,
            logo_inverse: null,
            logo_square: null,
            logo_square_inverse: null,
            host: null,
            referer: null,
            name: null,
            domain: null,
            whitelabel: false,
            path: null
        },
        enableAudio: false,
        appointment_notified_in_app: [],
        appointment_notified_desktop: [],
        reminder_notified_in_app: [],
        reminder_notified_desktop: [],
        communication_notified_desktop: [],
        voicemail_notified_desktop: [],
        communication_notified_in_app: [],
        voicemail_notified_in_app: [],
        notifications: [],
        page_component_key: 0,
        show_default_app_selection: false,
        ExportStatus
    },

    computed: {
        ...mapState(['campaigns', 'users', 'ring_groups', 'fishing_mode_notification_audio']),
        ...mapState('cache', ['current_company', 'timezones']),
        ...mapGetters(['isAppLoading']),

        sleepMode() {
            // check sleep mode
            if (this.auth.user && this.auth.user.profile && this.auth.user.profile.sleep_mode === false) {
                return false
            }

            return true
        },

        shouldDisplayAppComponents() {
            if (this.is_widget || !this.user.authenticated || !this.user.profile || !this.current_company) {
                return false
            }

            // If is impersonated from Admin, always show component. The suspended paywall will not show up.
            if (this.user.adminImpersonated) {
                return true
            }

            return this.user.profile.enabled
        },

        shouldDisplayAppNotifications() {
            if (this.is_widget || !this.user.authenticated || !this.user.profile || !this.current_company) {
                return false
            }

            // If is impersonated from Admin, always show component. The suspended paywall will not show up.
            if (this.user.adminImpersonated) {
                return true
            }

            return this.user.profile.enabled
        }
    },

    created() {
        this.getStatics()

        this.getTimezones()

        // Talk2 redirection rule
        this.$router.beforeEach((to, from, next) => {
            if (this.talkRedirect(to)) {
                return
            }

            next()
        })
    },

    mounted() {
        if (!this.user || !this.user.profile) {
            this.autoAuth()
        }

        if (!this.user?.authenticated) {
            this.setFullStory()
        }

        // evaluate
        this.showAloTalkPopup()

        // customResize to avoid conflicts with the resize eventListener used by third-party libraries
        // Set the offset
        window.addEventListener('customResize', () => {
            this.fixTopMenu()
        })

        if (!Push.Permission.has() && Push.Permission.get() != Push.Permission.DENIED) {
            Push.Permission.request()
        }

        const shouldUpdateCompanyData = this.current_company ? getCookie(`should_update_company_data-${this.current_company?.id}`) : false
        if ((this.user.authenticated && !this.current_company) || Boolean(shouldUpdateCompanyData)) {
            this.getCurrentCompany()
            removeCookie(`should_update_company_data-${this.current_company.id}`)
        }

        // check auth every 5 minutes
        const checkInterval = 5 * 60 * 1000
        if (!window.sessionIntervalId) {
            window.sessionIntervalId = setInterval(() => {
                const now = new Date().getTime()
                const lastRun = localStorage.getItem('checkAuthIntervalLastRun') || 0

                // only runs if last run was at least the defined time ago (to avoid multiple tabs running multiple requests)
                if (now - lastRun >= checkInterval) {
                    // this is a recursive authentication check with 3 tries
                    this.checkAuth()
                    localStorage.setItem('checkAuthIntervalLastRun', now)
                }
            }, checkInterval)
        }

        if (localStorage.getItem('billing_status') == true) {
            try {
                this.chargebeeInstance = Chargebee.init({
                    site: localStorage.getItem('chargebee_site')
                })
            } catch (err) {
                console.log(err)
            }
        }

        if (this.mediaPlaybackRequiresUserGesture()) {
            window.addEventListener('keydown', this.removeBehaviorsRestrictions())
            window.addEventListener('mousedown', this.removeBehaviorsRestrictions())
            window.addEventListener('touchstart', this.removeBehaviorsRestrictions())
        } else {
            this.enableAudio = true
        }

        VueEvent.listen('credits_added', (data) => {
            if (this.auth.user.profile && this.auth.user.profile.is_reseller) {
                return
            }
            data = Math.round(data * 100) / 100
            this.$notify({
                offset: 95,
                title: 'Credits',
                dangerouslyUseHTMLString: true,
                message: '$' + data + ' credits has been added to your account.',
                type: 'success',
                showClose: true
            })
        })

        VueEvent.listen('plan_changed', (data) => {
            this.$notify({
                offset: 95,
                title: 'Plan',
                dangerouslyUseHTMLString: true,
                message: 'Your account plan has been successfully changed to ' + data.name,
                type: 'success',
                showClose: true
            })
        })

        VueEvent.listen('export_updated', (data) => {
            if (data && this.hasRole('Company Admin')) {
                if (data.status == ExportStatus.FINISHED) {
                    this.$notify.success({
                        offset: 95,
                        title: 'Exports',
                        dangerouslyUseHTMLString: true,
                        message: `Your export is ready. You can download it from the export page or <a href="${data.url}" class="text-dark-greenish _400">click here</a>.`,
                        duration: 5000,
                        onClick: () => {
                            Notification.closeAll()
                        }
                    })
                }

                if (data.status == ExportStatus.FAILED) {
                    this.$notify.error({
                        offset: 95,
                        title: 'Exports',
                        dangerouslyUseHTMLString: true,
                        message: `Unfortunately we failed to export your data. Please contact support.`,
                        duration: 5000
                    })
                }
            }
        })

        VueEvent.listen('new_version', (message) => {
            if (this.is_widget) {
                return
            }

            let href = '<a class="mt-2 d-block" href="' + this.$route.fullPath + '"><i class="material-icons text-whitish _600">refresh</i> <span class="text-whitish _600">Refresh Page</span></a>'
            this.$notify({
                offset: 0,
                dangerouslyUseHTMLString: true,
                message: message + href,
                type: 'success',
                position: 'bottom-left',
                customClass: 'upgrade-notification',
                duration: 0,
                showClose: true
            })
        })

        VueEvent.listen('bulk_contacts_deleted', (data) => {
            const notification = {
                offset: 95,
                title: data.success ? 'Tag Delete Processed' : 'Failed Tag Delete Process',
                dangerouslyUseHTMLString: true,
                message: data.message,
                duration: 5000
            }

            if (!data.success) {
                this.$notify.error(notification)
                return
            }

            this.$notify.success(notification)
        })

        // new in-app voicemail notification
        VueEvent.listen('new_in_app_voicemail', (communication) => {
            if (this.checkCommunicationMatchesUserAccessibility(communication) && !this.sleepMode) {
                this.handleInAppVoicemailNotification(communication)
            }
        })

        // new in-app change of compliance registration status notification
        VueEvent.listen('compliance_registration_updated', (compliance_model) => {
            if (!this.sleepMode) {
                this.handleInAppComplianceRegistrationNotification(compliance_model)
            }
        })

        // new in-app compliance invitation notification
        VueEvent.listen('compliance_invitation', () => {
            if (!this.sleepMode) {
                this.handleInAppComplianceInvitationNotification()
            }
        })
        // new in-app messaging service event
        VueEvent.listen('messaging_services', (data) => {
            if (!this.sleepMode) {
                this.handleInAppMessagingServiceNotification(data)
            }
        })

        VueEvent.listen('update_communication', (data) => {
            // if disposition status is not in-progress, close call notification
            if (data.disposition_status2 !== CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW) {
                this.closeNotification(data.id)
            }

            // if current status is not queued / ring all, close call notification
            if (![CommunicationCurrentStatus.CURRENT_STATUS_QUEUED_NEW, CommunicationCurrentStatus.CURRENT_STATUS_RINGALL_NEW].includes(data.current_status2)) {
                this.closeNotification(data.id)
            }
        })

        VueEvent.listen('reloadPageComponent', () => {
            this.page_component_key += 1
        })
    },

    methods: {
        ...mapActions(['resetVuex', 'setUsage', 'setTopOffset', 'logoutUser']),
        ...mapActions('cache', ['setCurrentCompany', 'setTimezones']),
        ...mapActions(['setAppLoading']),

        autoAuth() {
            const urlParams = new URLSearchParams(window.location.search)
            const from_talk_2 = urlParams.get('from_talk_2')
            const logout = urlParams.get('logout')
            const token = urlParams.get('token')
            const hashed_token = localStorage.getItem('hashed_token')

            if (from_talk_2 && logout) {
                let user_id = localStorage.getItem('previous_user_id')
                if (user_id) {
                    this.auth.impersonate(user_id, true).then(res => {
                        this.logoutUser()
                        window.location.href = '/'
                    }).catch(err => {
                        console.log(err)
                    })
                }
            }

            // sanity check: from talk 2 or token is same with what is saved on the local storage
            if (Number(from_talk_2) !== 1 || token === hashed_token) {
                return
            }

            // sanity check: token should be present
            if (!token || token === 'null' || token === null) {
                return
            }

            localStorage.setItem('hashed_token', token)

            let params = {
                shared_token: token
            }

            axios.get('/get-cookie-user', {params})
                .then(res => {
                    // success
                    localStorage.setItem('api_token', res.data.meta.token)
                    window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('api_token')
                    store.commit('SET_FIRST_LOGIN', res.data.data.first_login)

                    let billing_status = localStorage.getItem('billing_status')
                    if (billing_status == true) {
                        let portal_session = res.data.data.portal_session
                        // put portal session for using on app-header component
                    }

                    this.setCurrentCompany(res.data.data.company)
                    this.resetVuex()
                    this.setUsage(res.data.data.usage)
                    localStorage.setItem('company_id', res.data.data.company.id)

                    window.location.href = this.getRedirectUrl(res.data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        },

        getLocalStorageItem(key, default_value = false) {
            let item = localStorage.getItem(key)

            // if item does not exist, just return the configured default value
            if (item === null) {
                return default_value
            }

            return item
        },

        getTimezones() {
            return axios.get('/api/v1/timezones')
                .then(res => {
                    this.setTimezones(res.data)
                })
        },

        closeNotification(communication_id) {
            let notification = this.notifications.find(notification => notification.communication_id == communication_id)
            if (notification && notification.notification) {
                notification.notification.close()
                this.notifications = this.notifications.filter(notification => notification.communication_id != communication_id)
            }
            this.stopAudio()
        },

        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics')
                .then(res => {
                    this.statics = res.data
                    this.loading_whitelabel = false
                })
                .catch(err => {
                    console.log(err)
                    this.handleErrors(err.response)
                    this.loading_whitelabel = false
                })
        },

        removeBehaviorsRestrictions() {
            window.removeEventListener('keydown', this.removeBehaviorsRestrictions())
            window.removeEventListener('mousedown', this.removeBehaviorsRestrictions())
            window.removeEventListener('touchstart', this.removeBehaviorsRestrictions())
            this.enableAudio = true
        },

        mediaPlaybackRequiresUserGesture() {
            // test if play() is ignored when not called from an input event handler
            let audio = document.createElement('audio')
            let promise = audio.play()
            if (promise !== undefined) {
                promise.catch(err => {
                    // Auto-play was prevented
                    // Show a UI element to let the user manually start playback
                    return true
                }).then(() => {
                    // Auto-play started
                    return audio.paused
                })
            }
        },

        playAudio(should_play_fishing_notification_sound = false) {
            if (!this.enableAudio) {
                return
            }

            let promise
            if (should_play_fishing_notification_sound) {
                this.fishing_mode_notification_audio.loop = true
                promise = this.fishing_mode_notification_audio.play()
            } else {
                promise = this.notification_audio.play()
            }

            if (promise !== undefined) {
                promise.catch(err => {
                    // Auto-play was prevented
                    // Show a UI element to let the user manually start playback
                    console.log(err)
                })
            }
        },

        stopAudio() {
            if (!this.enableAudio) {
                return
            }

            this.stopNotificationAudio()
        },

        handleInAppVoicemailNotification(communication) {
            if (this.is_widget) {
                return
            }
            if (this.voicemail_notified_in_app.includes(communication.id)) {
                return
            }
            this.voicemail_notified_in_app.push(communication.id)
            let ComponentClass = Vue.extend(VoicemailNotification)
            let instance = new ComponentClass({
                parent: this,
                propsData: {communication: communication}
            })
            instance.$mount()
            let handleNotificationClick = () => {
                this.$router.push({
                    name: 'Communication',
                    params: {
                        communication_id: communication.id
                    }
                }).catch(err => {
                })
                notification.close()
            }

            let notification = this.$notify({
                dangerouslyUseHTMLString: true,
                message: instance.$el.innerHTML,
                duration: 10000,
                offset: 95,
                iconClass: 'fa fa-phone text-red-500',
                onClick: handleNotificationClick
            })

            $(notification.$el).find('.btn-listen').on('click', function () {
                handleNotificationClick()
            })
            this.playAudio()
        },

        handleInAppComplianceRegistrationNotification(compliance_model) {
            if (this.is_widget) {
                return
            }
            let ComponentClass = Vue.extend(ComplianceRegistrationNotification)
            let instance = new ComponentClass({
                parent: this,
                propsData: {
                    compliance_model: compliance_model,
                }
            })
            instance.$mount()
            let iconClass = (compliance_model.status === ComplianceBundleStatuses.STATUS_APPROVED) ? 'fa fa-check-circle text-green-500' : 'fa fa-times-circle text-red-500'

            let notification = this.$notify({
                dangerouslyUseHTMLString: true,
                message: instance.$el.innerHTML,
                offset: 95,
                iconClass: iconClass,
            })

            let handleNotificationClick = () => {
                this.$router.push({
                    name: 'Account',
                    query: {
                        tab: 'compliance',
                    },
                }).catch(err => {
                })
                notification.close()
            }

            $(notification.$el).find('.btn-status').on('click', function () {
                handleNotificationClick()
            })
            this.playAudio()
        },

        handleInAppComplianceInvitationNotification() {
            if (this.is_widget) {
                return
            }
            let ComponentClass = Vue.extend(ComplianceInvitationNotification)
            let instance = new ComponentClass({
                parent: this,
            })
            instance.$mount()
            let iconClass = 'fa fa-check-circle text-green-500'

            let notification = this.$notify({
                dangerouslyUseHTMLString: true,
                message: instance.$el.innerHTML,
                offset: 95,
                iconClass: iconClass,
            })

            let handleNotificationClick = () => {
                notification.close()
            }

            $(notification.$el).find('.btn-remind-later').on('click', function () {
                // just close the notif, reminders will be sent later automatically
                handleNotificationClick()
            })

            $(notification.$el).find('.btn-register').on('click', function () {
                handleNotificationClick()
            })
            this.playAudio()
        },

        handleInAppMessagingServiceNotification(data) {
            let ComponentClass = Vue.extend(MessagingServiceNotification)
            let instance = new ComponentClass({
                parent: this,
                propsData: {data}
            })
            instance.$mount()
            let iconClass = 'fa ' + (data.success ? 'fa-check-circle text-green-500' : 'fa-times-circle text-red-500')

            this.$notify({
                dangerouslyUseHTMLString: true,
                message: instance.$el.innerHTML,
                duration: 0,
                offset: 95,
                iconClass: iconClass,
            })
            this.playAudio()
        },

        checkAuth(auth_try = 1) {
            if (this.user.profile != null) {
                let authenticated = false
                this.auth.check(true).then((res) => {
                    authenticated = true
                }).catch((err) => {
                    auth_try++
                    // check if we are authenticated after 3 retries
                    if (auth_try > 3) {
                        // error
                        this.user.authenticated = false
                        this.user.profile = null
                        window.location.href = '/'
                    } else {
                        this.checkAuth(auth_try)
                    }
                })
            }
        },

        // refresh the current_company state
        getCurrentCompany() {
            // sanity check
            if (!this.user || !this.user.profile) {
                return
            }

            this.setIsUpdatingCurrentCompany(true)
            return axios.get('/api/v1/company/' + this.user.profile.company_id, {
                mode: 'no-cors',
            }).then(res => {
                this.setCurrentCompany(res.data)
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                this.setIsUpdatingCurrentCompany(false)
            })
        },

        getCampaigns() {
            if (this.hasPermissionTo('list campaign')) {
                this.loading_campaigns = true
                return axios.get('/api/v1/campaign', {
                    mode: 'no-cors',
                }).then(res => {
                    this.setCampaigns(res.data)
                    this.loading_campaigns = false
                }).catch(err => {
                    console.log(err)
                    this.loading_campaigns = false
                })
            }
        },

        getRingGroups() {
            if (this.hasPermissionTo('list ring group')) {
                this.loading_ring_groups = true
                return axios.get('/api/v1/ring-group').then(res => {
                    this.setRingGroups(res.data)
                    this.loading_ring_groups = false
                }).catch(err => {
                    console.log(err)
                    this.loading_ring_groups = false
                })
            }
        },

        getTeams() {
            if (!this.hasPermissionTo('list team')) {
                return
            }

            this.loading_teams = true

            return TeamsApi.list().then(teams => {
                this.setTeams(teams)
                this.loading_teams = false
            }).catch(err => {
                console.log('ERROR GETTING TEAMS', err)
                console.log(err)
                this.loading_teams = false
            });
        },

        getContactLists() {
            if (!this.hasPermissionTo('list contact list item')) {
                return
            }
            return axios
                .get('/api/v2/contacts-list/dashboard-filter')
                .then(res => { this.setContactLists(res.data) })
                .catch(err => {
                  console.log(err)
                })
        },

        getTeams() {
            if (this.hasPermissionTo('list team')) {
                this.loading_teams = true

                return TeamsApi.list().then(teams => {
                    this.setTeams(teams)
                    this.loading_teams = false
                }).catch(err => {
                    console.log('ERROR GETTING TEAMS', err)
                    this.loading_teams = false
                })
            }
        },

        getIvrs() {
            if (this.hasPermissionTo('list campaign')) {
                this.loading_ivrs = true
                return axios.get('/api/v1/campaign/ivr').then(res => {
                    this.setIvrs(res.data)
                    this.loading_ivrs = false
                }).catch(err => {
                    console.log(err)
                    this.loading_ivrs = false
                })
            }
        },

        getIvrPromptFiles() {
            this.loading_ivr_prompt_files = true
            return axios.get('/api/v1/campaign/ivr-prompt-file').then(res => {
                this.setIvrPromptFiles(res.data)
                this.loading_ivr_prompt_files = false
            }).catch(err => {
                console.log(err)
                this.loading_ivr_prompt_files = false
            })
        },

        getUsers() {
            if (this.hasPermissionTo('list user')) {
                this.loading_users = true
                return axios.get('/api/v2/users', {
                    mode: 'no-cors'
                }).then(res => {
                    this.setUsers(res.data)
                    this.loading_users = false
                }).catch(err => {
                    console.log(err)
                    this.loading_users = false
                })
            }
        },

        getExtensions(page = 1) {
            if (this.hasPermissionTo('list user')) {
                if (page === 1) {
                    this.loading_extensions = true
                }
                let params = {
                    user_type_filter: 'Extensions',
                    page: page,
                    per_page: 500,
                    token: Math.random().toString(36)
                }
                return axios.get('/api/v2/users', {params}).then(res => {
                    if (res.data.data && res.data.data.length) {
                        let new_users = res.data.data
                        this.setUsers([...this.users, ...new_users])
                    }
                    if (res.data.current_page != res.data.last_page) {
                        this.getExtensions(page + 1)
                    } else {
                        this.loading_extensions = false
                    }
                }).catch(err => {
                    console.log(err)
                    this.loading_extensions = false
                })
            }
        },

        getDispositionStatuses() {
            if (this.hasPermissionTo('list disposition status')) {
                this.loading_disposition_statuses = true
                return axios.get('/api/v1/disposition-status').then(res => {
                    this.setDispositionStatuses(res.data)
                    this.setDispositionStatusesLoaded(true)
                    VueEvent.fire('disposition_status_loaded')
                    this.loading_disposition_statuses = false
                }).catch(err => {
                    console.log(err)
                    this.loading_disposition_statuses = false
                })
            }
        },

        getCallDispositions() {
            if (this.hasPermissionTo('list disposition status')) {
                this.loading_call_disposition = true
                return axios.get('/api/v1/call-disposition').then(res => {
                    this.setCallDispositions(res.data)
                    this.setCallDispositionsLoaded(true)
                    VueEvent.fire('call_disposition_loaded')
                    this.loading_call_disposition = false
                }).catch(err => {
                    console.log(err)
                    this.loading_call_disposition = false
                })
            }
        },

        getActivityTypes() {
            this.loading_activity_types = true
            return axios.get('/api/v1/activity-types').then(res => {
                this.setActivityTypes(res.data)
                this.setActivityTypesLoaded(true)
                VueEvent.fire('activity_types_loaded')
                this.loading_activity_types = false
            }).catch(err => {
                console.log(err)
                this.loading_activity_types = false
            })
        },

        getScripts() {
            if (this.hasPermissionTo('list script')) {
                this.loading_scripts = true
                return axios.get('/api/v1/script').then(res => {
                    this.setScripts(res.data)
                    this.loading_scripts = false
                }).catch(err => {
                    console.log(err)
                    this.loading_scripts = false
                })
            }
        },

        getSmsTemplates() {
            if (this.hasPermissionTo('list sms template')) {
                this.loading_sms_templates = true
                return axios.get('/api/v1/sms-template').then(res => {
                    this.loading_sms_templates = false
                    this.setSmsTemplates(res.data)
                }).catch(err => {
                    console.log(err)
                    this.loading_sms_templates = false
                })
            }
        },

        getWorkflows(page = 1) {
            if (this.is_widget) {
                return
            }

            let params = {
                size: 100,
                page
            }

            if (this.hasPermissionTo('list workflow')) {
                this.loading_workflows = true
                return axios.get('/api/v1/automations/workflows', {
                    mode: 'no-cors',
                    params
                }).then(res => {
                    res.data.data.forEach((workflow) => {
                        this.newWorkflow(workflow)
                    })

                    if (res.data.current_page !== res.data.last_page) {
                        this.getWorkflows(page + 1)
                    } else {
                        this.loading_workflows = false
                    }
                }).catch(err => {
                    this.loading_workflows = false
                    console.log(err)
                })
            }
        },

        getBroadcasts() {
            if (this.is_widget) {
                return
            }

            if (this.hasPermissionTo('list broadcast')) {
                this.loading_workflows = true
                return axios.get('/api/v1/broadcasts', {
                    mode: 'no-cors',
                }).then(res => {
                    this.loading_workflows = false
                    this.setBroadcast(res.data)
                }).catch(err => {
                    this.loading_workflows = false
                    console.log(err)
                })
            }
        },

        getLeadSources() {
            if (this.is_widget) {
                return
            }

            this.loading_lead_sources = true
            return axios.get('/api/v1/lead-sources').then(res => {
                this.setLeadSources(res.data)
            }).catch(err => {
                this.loading_lead_sources = false
                console.log(err)
            })
        },

        getAttributeDictionaries() {
            if (this.is_widget) {
                return
            }

            return axios.get('/api/v1/attribute-dictionary').then(res => {
                this.setAttributeDictionaries(res.data.data)
            })
        },

        getCountries() {
            if (this.is_widget) {
                return
            }

            const countries = window.countriesAndTimezones.getAllCountries()
            const countriesArr = Object.values(countries)

            let mappedCountries = countriesArr.map((country) => {
                if (!['US', 'CA'].includes(country.id)) {
                    return {
                        id: country.id,
                        name: country.name
                    }
                }
            }).filter((country) => country !== undefined)

            mappedCountries.unshift({
                id: 'CA',
                name: 'Canada'
            })

            mappedCountries.unshift({
                id: 'US',
                name: 'United States'
            })

            this.setCountries(mappedCountries)
        },

        getCampaign(id) {
            if (!id) {
                return null
            }
            let found = this.campaigns.find(campaign => campaign.id === id)
            if (found) {
                return found
            }

            return null
        },

        getRingGroup(id) {
            if (!id) {
                return null
            }
            let found = this.ring_groups.find(ring_group => ring_group.id === id)
            if (found) {
                return found
            }

            return null
        },

        handleUploadErrors(error) {
            if (typeof error === 'string') {
                error = JSON.parse(error)
            }
            let err
            if (error.message === 'This action is unauthorized.') {
                err = {
                    status: 403,
                }
            } else {
                err = {
                    status: 422,
                    data: {
                        errors: error.errors.file
                    }
                }
            }

            this.handleErrors(err)
        },

        handleErrors(response, title = null, onCloseNotificationCallback = null) {
            // Default duration = 4500 per 'Notification' component docs
            const notificationDuration = (onCloseNotificationCallback) ? 10000 : 4500
            if (response && response.status) {
                let message = ''

                switch (response.status) {
                    case 401:
                        if (response.data.error) {
                            message += '<p class="pt-1 pb-1">- ' + response.data.error + '</p>'
                        }
                        for (let index in response.data.errors) {
                            message += '<p class="pt-1 pb-1">- ' + response.data.errors[index] + '</p>'
                        }
                        break
                    case 403:
                        message = 'You do not have enough permissions to make this request.'
                        if (response.data && response.data.error) {
                            message = response.data.error
                        }
                        break
                    case 404:
                        message = 'Requested resource not found.'
                        break
                    case 400:
                        message = response.data.error
                        break
                    case 422:
                        message = response.data.message ?? ''
                        for (let index in response.data.errors) {
                            message += '<p class="pt-1 pb-1">- ' + response.data.errors[index] + '</p>'
                        }
                        break
                    case 500:
                        message = 'Oops! We are having some problems right now, please try again later.'
                        if (response.data && response.data.error) {
                            message = response.data.error
                        }
                        break
                }

                this.$notify({
                    offset: 95,
                    title: (title) ? title : 'Error',
                    dangerouslyUseHTMLString: true,
                    message: message,
                    type: 'error',
                    showClose: true,
                    onClose: onCloseNotificationCallback || null,
                    duration: notificationDuration,
                })
            }
        },

        handleSingleValidationError(message, errors, title) {
            message = message ? message : 'Invalid input.'

            if (errors) {
                let first_key = Object.keys(errors)
                message = (errors[first_key]) ? errors[first_key][0] : message
            }

            this.$notify({
                offset: 95,
                title: title || 'Error',
                dangerouslyUseHTMLString: true,
                message: message,
                type: 'error',
                showClose: true
            })
        },

        unsubscribeFromPusher() {
            console.log('exiting pusher')
            if (this.user) {
                // just leave the channels
                broadcast.leave(this.user)
            }
        },

        getAgentStatus(getTry = 1) {
            if (!this.auth.user.authenticated) {
                return
            }
            axios.post('/api/v1/profile/get-agent-status').then(res => {
                if (this.auth.user && this.auth.user.profile) {
                    this.auth.user.profile.agent_status = res.data.agent_status
                }
            }).catch((err) => {
                let errorCode = err?.response?.status
                console.log(err)
                getTry++
                // check if we could get agent status after 3 retries
                if (getTry > 3 || [401, 429].includes(errorCode)) {
                    // error
                    Sentry.captureException(err)
                } else {
                    this.getAgentStatus(getTry)
                }
            })
        },

        addSentryContext() {
            if (!this.user) {
                return
            }

            Sentry.configureScope((scope) => {
                scope.setUser({
                    id: this.user.profile.id,
                    email: this.user.profile.email,
                    name: this.user.profile.name,
                    company_id: this.user.profile.company_id,
                    company_name: this.user.profile.company_name
                })
            })
        },

        showAloTalkPopup() {
            let company_id = this.current_company ? this.current_company.id : null
            let show_notifier_company_level = JSON.parse(this.getLocalStorageItem(`show_default_app_selection_${company_id}`, false))
            let current_user_id = localStorage.getItem('current_user_id')

            let show_notifier_user_level = false
            if (company_id && (this.user.profile || current_user_id)) {
                let user_id = this.user.profile ? this.user.profile.id : current_user_id
                show_notifier_user_level = JSON.parse(this.getLocalStorageItem(`show_default_app_selection_${company_id}_${user_id}`, false))
            }

            if (show_notifier_company_level === true || show_notifier_user_level === true) {
                this.show_default_app_selection = true
            }
        },

        fullStoryIdentify(profile) {
            // Sanity check: verify if user is really there
            if (!profile) {
                return
            }
            window.axios.get('/fullstory-meta').then(({data}) => {
                this.$FullStory.identify(profile.id, {
                    ...data,
                    timezone_str: window.timezone
                })
            }).catch(() => {
                console.log('Error while retrieving fullstory metadata from server. Using local variables.')
                this.$FullStory.identify(profile.id, {
                    displayName: profile.name,
                    email: profile.email,
                    timezone_str: window.timezone,
                    companyId_int: profile.company_id,
                    companyName_str: profile.company_name,
                    userRoles_strs: profile.user_roles
                })
            })
        },

        // identify or anonymize user
        setFullStory() {
            // Identify user on fullstory
            if (this.loading || !this.fullstory_org_id) {
                return
            }

            let profile = this.auth?.user?.profile
            if (profile && this.user.authenticated) {
                this.fullStoryIdentify(profile)
                return
            }
            this.$FullStory.anonymize()
        },

        fixTopMenu() {
            // Get intercom iframe DOM height
            let intercom_iframe = document.querySelector('[name=intercom-banner-frame]')
            let intercom_iframe_height = this.getIntercomIframeHeight(intercom_iframe)
            let is_top_notification = intercom_iframe ? intercom_iframe.getBoundingClientRect().top == 0 : true

            // only do this for full width banners
            let offset_height = (intercom_iframe && intercom_iframe.offsetWidth == window.innerWidth && is_top_notification) ? intercom_iframe_height : 0

            let header_notifications = document.getElementsByClassName('header-notification')
            for (let header_notification of header_notifications) {
                if (header_notification.clientHeight > 0) {
                    offset_height += header_notification.clientHeight
                    header_notification.style.top = -1 * header_notification.clientHeight + 'px'
                    header_notification.style.position = 'absolute'
                }
            }

             if (offset_height > 0) {
                document.body.style.marginTop = 0
                document.body.style.paddingTop = offset_height + 'px'
                document.body.style.maxHeight = null

                this.setTopOffset(offset_height - 1)
            } else if (offset_height === 0) {
                document.body.style.marginTop = 0
                document.body.style.paddingTop = '0px'

                this.setTopOffset(0)
            }
        },

        talkRedirect(to, blank = true) {
            if (['Contact', 'Contacts', 'PowerDialer'].includes(to.name)) {
                const talk2Url = this.getTalkUrl()
                const data = this.$router.resolve(to)
                const url = talk2Url + data.href

                if (blank) {
                    window.open(url, '_blank')
                } else {
                    window.location.href = url
                }

                return true
            }

            return false
        },

        ...mapActions([
            'setCampaigns',
            'setRingGroups',
            'setContactLists',
            'setTeams',
            'setIvrs',
            'setIvrPromptFiles',
            'setUsers',
            'setDispositionStatuses',
            'setDispositionStatusesLoaded',
            'setCallDispositions',
            'setCallDispositionsLoaded',
            'setActivityTypes',
            'setActivityTypesLoaded',
            'setScripts',
            'setCountries',
            'setBroadcast',
            'setLeadSources',
            'setAttributeDictionaries',
            'setSmsTemplates',
            'setOnCall',
            'newWorkflow',
            'stopNotificationAudio',
            'setTeams',
            'setIsUpdatingCurrentCompany'
        ]),
        ...mapActions('cache', ['setCurrentCompany', 'setTimezones'])
    },

    beforeDestroy() {
        // event for listening before tab/browser close
        this.unsubscribeFromPusher()
    },

    watch: {
        'current_company.talk_enabled': {
            handler(val) {
                if (val) {
                    this.showAloTalkPopup()
                }
                if (val === false) {
                    this.show_default_app_selection = false
                }
            },
            immediate: true
        },

        'user.authenticated': async function (newVal, oldVal) {
            if (newVal && !oldVal) {
                this.loading = true
                this.addSentryContext()
                const getCurrentCompany = this.getCurrentCompany()
                const getCampaigns = this.getCampaigns()
                const getUsers = this.getUsers()
                const getWorkflows = this.getWorkflows()
                const getDispositionStatuses = this.getDispositionStatuses()
                const getCallDispositions = this.getCallDispositions()
                const getActivityTypes = this.getActivityTypes()
                const getRingGroups = this.getRingGroups()
                const getContactLists = this.getContactLists()
                const getTeams = this.getTeams()
                const getIvrs = this.getIvrs()
                const getIvrPromptFiles = this.getIvrPromptFiles()
                const getScripts = this.getScripts()
                const getCountries = this.getCountries()
                const getLeadSources = this.getLeadSources()
                const getBroadcasts = this.getBroadcasts()
                const getAttributeDictionaries = this.getAttributeDictionaries()
                const getSmsTemplates = this.getSmsTemplates()

                broadcast.listen(this.user)
                this.setAppLoading(true)

                let loadingInstance = Loading.service({
                    lock: true,
                    customClass: 'blue-purple-gradient',
                    fullscreen: true
                })
                await Promise.all([
                    getCurrentCompany,
                    getCampaigns,
                    getRingGroups,
                    getUsers,
                    getDispositionStatuses,
                    getCallDispositions,
                    getContactLists,
                    getActivityTypes,
                    getSmsTemplates,
                    getScripts,
                    getLeadSources,
                    getAttributeDictionaries,
                    getWorkflows,
                    getBroadcasts,
                    getCountries,
                    getIvrs,
                    getIvrPromptFiles,
                ])
                this.loading = false
                this.setAppLoading(false)
                loadingInstance.close()

                // load extensions
                this.getExtensions()
            }

            this.setFullStory()
        },

        '$route': function (to) {
            // check if must redirect to Talk in page reload
            this.talkRedirect(to, false)
        }
    }
})
