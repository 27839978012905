<template>
    <div class="tool-selector d-flex">
        <div>
            <el-popover
                placement="bottom"
                width="400"
                trigger="click"
                v-model="popoverVisible"
                popper-class="tool-selector-popover"
                :disabled="disabled"
                @hide="onHide"
            >
                <div v-if="!selectedTool">
                    <div class="tool-list">
                        <div
                            v-for="(tool, index) in filteredTools"
                            :key="index"
                            class="tool-item mb-2 p-3"
                            @click="selectTool(tool.id)"
                        >
                            <!-- Tool name and count -->
                            <div class="d-flex align-items-center mb-2">
                                <i :class="[tool.icon, 'tool-icon']" class="mr-2"></i>
                                <strong>{{ tool.name }} <span v-if="toolCounts[tool.id] > 0">({{ toolCounts[tool.id] }})</span></strong>
                            </div>

                            <!-- Tool description -->
                            <div class="tool-description">
                                {{ tool.description }}
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else class="tool-content-wrapper">
                    <component
                        v-if="currentToolComponent"
                        :is="currentToolComponent"
                        :tool="selectedToolObj"
                        @back="backToToolList"
                        @property-selected="onPropertySelected"
                        @campaign-selected="onCampaignSelected"
                        @send-sms-selected="onSendSmsSelected"
                        @appointment-selected="onAppointmentSelected"
                        @disposition-selected="onDispositionSelected"
                        @tags-selected="onTagsSelected"
                        @lists-selected="onListsSelected"
                        @custom-function-selected="onCustomFunctionSelected"
                    />
                </div>

                <el-button
                    slot="reference"
                    type="primary"
                    plain
                    size="mini"
                    icon="el-icon-plus"
                    class="tool-selector-button my-2"
                    :disabled="disabled"
                >
                    Add Action <span v-if="totalToolCount > 0">({{ totalToolCount }})</span>
                </el-button>
            </el-popover>
        </div>
    </div>
</template>

<script>
import * as AloAi from '../../../constants/aloai'
import BaseTool from './tools/base-tool'
import UpdateContactTool from './tools/update-contact-tool'
import TransferTool from './tools/transfer-tool'
import SendSmsTool from './tools/send-sms-tool'
import AppointmentTool from './tools/appointment-tool'
import DispositionTool from './tools/disposition-tool'
import TagsTool from './tools/tags-tool'
import ListsTool from './tools/lists-tool'
import CustomFunctionTool from './tools/custom-function-tool'

export default {
    name: 'ToolSelector',

    components: {
        BaseTool,
        UpdateContactTool,
        TransferTool,
        SendSmsTool,
        AppointmentTool,
        DispositionTool,
        TagsTool,
        ListsTool,
        CustomFunctionTool,
    },

    props: {
        availableTools: {
            type: Array,
            default: () => []
        },
        botType: {
            type: String,
            default: ''
        },
        instructionsContent: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            popoverVisible: false,
            selectedTool: null,
            AloAi,
            toolCounts: {
                [AloAi.TOOL_UPDATE_CONTACT]: 0,
                [AloAi.TOOL_TRANSFER_CALL]: 0,
                [AloAi.TOOL_SEND_SMS]: 0,
                [AloAi.TOOL_CREATE_APPOINTMENT]: 0,
                [AloAi.TOOL_ADD_DISPOSITION]: 0,
                [AloAi.TOOL_ADD_TAGS]: 0,
                [AloAi.TOOL_ADD_LISTS]: 0,
                [AloAi.TOOL_DISENGAGE_CONTACT]: 0,
                [AloAi.TOOL_CUSTOM_FUNCTION]: 0
            },
            tools: [
                {
                    id: AloAi.TOOL_UPDATE_CONTACT,
                    name: 'Update Contact Property',
                    icon: 'el-icon-edit',
                    description: 'Update a property of the contact.'
                },
                {
                    id: AloAi.TOOL_TRANSFER_CALL,
                    name: 'Transfer Call',
                    icon: 'el-icon-phone-outline',
                    description: 'Transfer the current call to another line.'
                },
                {
                    id: AloAi.TOOL_SEND_SMS,
                    name: 'Send SMS',
                    icon: 'el-icon-message',
                    description: 'Send an SMS to the current contact.'
                },
                {
                    id: AloAi.TOOL_CREATE_APPOINTMENT,
                    name: 'Create Appointment',
                    icon: 'el-icon-date',
                    description: 'Schedule an appointment for the contact when specific condition is met.'
                },
                {
                    id: AloAi.TOOL_ADD_DISPOSITION,
                    name: 'Add Contact Disposition',
                    icon: 'el-icon-tickets',
                    description: 'Add a disposition status to the contact when specific condition is met.'
                },
                {
                    id: AloAi.TOOL_ADD_LISTS,
                    name: 'Add Contact to a List',
                    icon: 'el-icon-notebook-2',
                    description: 'Add the contact to a public or private list.'
                },
                {
                    id: AloAi.TOOL_ADD_TAGS,
                    name: 'Add Contact Tag',
                    icon: 'el-icon-collection-tag',
                    description: 'Add tag to the contact for categorization.'
                },
                {
                    id: AloAi.TOOL_DISENGAGE_CONTACT,
                    name: 'Disengage Contact',
                    icon: 'el-icon-remove-outline',
                    description: 'Prevents this agent from interacting with the contact in the future.'
                },
                {
                    id: AloAi.TOOL_CUSTOM_FUNCTION,
                    name: 'Custom Function',
                    icon: 'el-icon-remove-outline',
                    description: 'Runs a custom function that is configured in the bot'
                }
            ]
        }
    },

    mounted() {
        // Initialize tool counts when component is mounted
        this.$nextTick(() => {
            this.checkInstructionsToolCounts()
        })
    },

    computed: {
        selectedToolObj() {
            return this.tools.find(tool => tool.id === this.selectedTool) || {}
        },

        filteredTools() {
            if (!this.availableTools || this.availableTools.length === 0) {
                return this.tools
            }

            return this.tools.filter(tool => {
                return this.availableTools.some(availableTool => availableTool.key === tool.id)
            })
        },

        currentToolComponent() {
            const componentMap = {
                [AloAi.TOOL_UPDATE_CONTACT]: 'UpdateContactTool',
                [AloAi.TOOL_TRANSFER_CALL]: 'TransferTool',
                [AloAi.TOOL_SEND_SMS]: 'SendSmsTool',
                [AloAi.TOOL_CREATE_APPOINTMENT]: 'AppointmentTool',
                [AloAi.TOOL_ADD_DISPOSITION]: 'DispositionTool',
                [AloAi.TOOL_ADD_TAGS]: 'TagsTool',
                [AloAi.TOOL_ADD_LISTS]: 'ListsTool',
                [AloAi.TOOL_CUSTOM_FUNCTION]: 'CustomFunctionTool',
            }

            return componentMap[this.selectedTool]
        },

        totalToolCount() {
            return Object.values(this.toolCounts).reduce((sum, count) => sum + count, 0)
        }
    },

    methods: {
        selectTool(toolId) {
            if (this.disabled) {
                return
            }

            // Special case for Disengage Contact - immediately adds text to instructions
            if (toolId === AloAi.TOOL_DISENGAGE_CONTACT) {
                // Add the appointment text directly
                this.$emit('disengage-contact-selected', {text: '{Disengage with the contact}'})

                // Close the popover
                this.hidePopover()
                return
            }

            // Regular case for other tools, open the popover
            this.selectedTool = toolId
        },

        /**
         * Back to the tool card list
         */
        backToToolList() {
            this.selectedTool = null
        },

        onDispositionSelected(disposition) {
            this.$emit('disposition-selected', disposition)
            if (disposition) {
                this.hidePopover()
            }
        },

        onTagsSelected(tags) {
            this.$emit('tags-selected', tags)
            if (tags && tags.length) {
                this.hidePopover()
            }
        },

        onListsSelected(listIds, listItems) {
            this.$emit('lists-selected', listIds, listItems)
            if (listIds && listIds.length) {
                this.hidePopover()
            }
        },

        onPropertySelected(property) {
            this.$emit('property-selected', property)
            if (property) {
                this.hidePopover()
            }
        },

        onCampaignSelected(campaignId) {
            this.$emit('campaign-selected', campaignId)
            if (campaignId) {
                this.hidePopover()
            }
        },

        onAppointmentSelected(appointment) {
            this.$emit('create-appointment-selected', appointment)
            if (appointment) {
                this.popoverVisible = false
            }
        },

        onSendSmsSelected(message) {
            this.$emit('send-sms-selected', message)
            if (message) {
                this.hidePopover()
            }
        },

        onCustomFunctionSelected(customFunction) {
            this.$emit('custom-function-selected', { 
                text: '{Run this function: '+customFunction.name+'|' + customFunction.key + '}' 
            })
            this.hidePopover()
        },

        /**
         * Hide the popover
         */
        hidePopover() {
            this.popoverVisible = false
        },

        /**
         * Check if the instructions contain any tool markers
         * for card counts of each selected tool
         */
        checkInstructionsToolCounts() {
            // If availableTools is null, we can't properly manipulate the selection
            if (!this.availableTools) {
                return
            }

            // Regex to count the number of times each tool appears in the instructions
            const toolMarkers = {
                [AloAi.TOOL_UPDATE_CONTACT]: /\{Update contact property:.*?\}/g,
                [AloAi.TOOL_TRANSFER_CALL]: /\{Transfer to this line:.*?\}/g,
                [AloAi.TOOL_SEND_SMS]: /\{Send SMS:.*?\}/g,
                [AloAi.TOOL_CREATE_APPOINTMENT]: /\{Create appointment with:.*?\}/g,
                [AloAi.TOOL_ADD_DISPOSITION]: /\{Add \(.*?\) disposition to the contact\}/g,
                [AloAi.TOOL_ADD_TAGS]: /\{Add .*? tag\(s\) to the contact\}/g,
                [AloAi.TOOL_ADD_LISTS]: /\{Add contact to .*? list\(s\)\}/g,
                [AloAi.TOOL_DISENGAGE_CONTACT]: /\{Disengage with the contact\}/g,
                [AloAi.TOOL_CUSTOM_FUNCTION]: /\{Run this function:.*?\}/g,
            }

            // Check if specific tool markers exist in instructions
            Object.keys(this.toolCounts).forEach(key => {
                const newCount = (this.instructionsContent.match(toolMarkers[key]) || []).length

                this.toolCounts[key] = newCount
            })
        },

        openToolSelector() {
            if (!this.disabled) {
                this.popoverVisible = true
            }
        },

        onHide() {
            this.$emit('hide')
        }
    },

    watch: {
        popoverVisible(newValue) {
            if (!newValue) {
                setTimeout(() => {
                    this.selectedTool = null
                }, 1000)
            }
        },

        instructionsContent: {
            handler() {
                this.checkInstructionsToolCounts()
            },
            immediate: true
        },

        availableTools: {
            handler(newValue) {
                if (newValue && newValue.length > 0) {
                    this.$nextTick(() => {
                        this.checkInstructionsToolCounts()
                    })
                }
            },
            immediate: true
        }
    }
}
</script>

<style scoped>
.tool-selector {
    display: flex;
    width: 100%;
}

.tool-list {
    max-height: 350px;
    overflow-y: auto;
}

.tool-item {
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    border: 1px solid #EBEEF5;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.tool-item:hover {
    background-color: #f5f7fa;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.tool-description {
    font-size: 0.85rem;
    color: #606266;
    line-height: 1.4;
}

.tool-icon {
    font-size: 18px;
    color: #409EFF;
}

.tool-selector-button {
    margin-top: 8px;
    margin-bottom: 8px;
}
</style>
