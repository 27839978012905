<template>
    <el-dialog class="user-to-teams--dialog"
               width="40%"
               center
               append-to-body
               :title="modalConfig.title"
               :visible.sync="visible"
               v-if="user">
        <div class="user-to-teams">
            <p class="break-word text-center">{{ modalConfig.description }}</p>

            <!-- Search input -->
            <el-input placeholder="Search teams..."
                      prefix-icon="el-icon-search"
                      clearable
                      class="mb-3"
                      v-model="searchQuery" />

            <div class="content" ref="teamsContainer">
                <div class="d-flex align-items-center justify-content-between user-to-teams--divider"
                     :key="team.id"
                     v-for="team in all_teams">
                    <div class="user-to-teams--label">{{ team.name }}</div>
                    <el-button type="primary"
                               icon="el-icon-plus"
                               :disabled="isLoadingData"
                               :loading="isLoadingData"
                               data-testid="add-user-to-team"
                               v-if="!isUserInTeam(team)"
                               @click="onAddUserToTeam(team)" />
                    <el-button type="success"
                               icon="el-icon-check"
                               :disabled="isLoadingData"
                               :loading="isLoadingData"
                               data-testid="user-remove-from-team-button"
                               v-else
                               @click="onRemoveUserFromTeam(team)" />
                </div>

                <div class="user-to-teams d-flex align-items-center justify-content-center"
                     v-if="isLoadingData">
                    <el-spinner />
                </div>

                <!-- Intersection observer target -->
                <div ref="infiniteScrollTrigger" class="mt-2"></div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { debounce } from 'lodash'

export default {
    name: 'AddUserToTeamModal',

    props: {
        isVisible: {
            type: Boolean,
            required: true
        },

        user: {
            type: Object,
            default: () => ({}),
            required: true
        },

        teams: {
            type: Array,
            required: true
        },

        isLoadingUserTeams: {
            type: Boolean,
            required: true
        }
    },

    data () {
        return {
            isLoading: false,
            all_teams: [],
            visible: false,
            searchQuery: '',
            currentPage: 1,
            perPage: 20,
            hasMorePages: true,
            observer: null
        }
    },

    created () {
        this.debouncedSearch = debounce(() => {
            this.resetPagination()
            this.fetchTeams()
        }, 300)
    },

    computed: {
        modalConfig () {
            return {
                title: 'Choose a Team',
                description: `Add a team to ${this.user.first_name}`
            }
        },

        isLoadingData () {
            return this.isLoading || this.isLoadingUserTeams
        }
    },

    methods: {
        fetchTeams () {
            if (!this.hasMorePages || this.isLoading) return

            this.isLoading = true

            this.$APIV2.Teams.index({
                page: this.currentPage,
                per_page: this.perPage,
                search: this.searchQuery
            })
                .then(res => {
                    const newTeams = res.data?.data || []
                    this.all_teams = this.currentPage === 1
                        ? newTeams
                        : [...this.all_teams, ...newTeams]
                    this.hasMorePages = newTeams.length === this.perPage
                    this.currentPage++
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },

        setupInfiniteScroll () {
            this.observer = new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting) {
                    this.fetchTeams()
                }
            }, {
                root: this.$refs.teamsContainer,
                threshold: 0.5
            })

            if (this.$refs.infiniteScrollTrigger) {
                this.observer.observe(this.$refs.infiniteScrollTrigger)
            }
        },

        resetPagination () {
            this.currentPage = 1
            this.hasMorePages = true
            this.all_teams = []
        },

        onAddUserToTeam(team) {
            this.isLoading = true

            this.$APIV1.User.associateTeams(this.user?.id, [team.id])
                .then(response => {
                    this.$emit('fetch-user-teams')
                    this.$notify({
                        title: 'Success',
                        message: `User has been added to ${team.name} team`,
                        type: 'success'
                    })
                })
                .catch(error => {
                    console.log(error)
                }).finally(() => {
                    this.isLoading = false
                })
        },

        onRemoveUserFromTeam(team) {
            this.isLoading = true
            const users_ids = [this.user.id]

            this.$APIV2.Teams.removeUsers(team.id, { users_ids })
                .then(response => {
                    this.$emit('fetch-user-teams')
                    this.$notify({
                        title: 'Success',
                        message: `User has been removed from ${team.name} team`,
                        type: 'success'
                    })
                })
                .catch(error => {
                    console.log(error)
                }).finally(() => {
                    this.isLoading = false
                })
        },

        isUserInTeam(team) {
            return this.teams.some(t => t.id === team.id)
        }
    },

    watch: {
        isVisible (value) {
            this.visible = value

            if (value) {
                this.resetPagination()
                this.fetchTeams()
                this.$nextTick(() => {
                    this.setupInfiniteScroll()
                })
            }
        },

        visible (value) {
            if (!value) {
                this.$emit('close')
                if (this.observer) {
                    this.observer.disconnect()
                }
                this.debouncedSearch.cancel()
            }
        },

        searchQuery () {
            this.debouncedSearch()
        }
    },

    beforeDestroy () {
        if (this.observer) {
            this.observer.disconnect()
        }
        this.debouncedSearch.cancel()
    }
}
</script>
