<template>
  <el-popover placement="right-start"
              width="360"
              trigger="click"
              v-model="show_popover"
              @show="onShow"
              @hide="onHide"
  >
    <el-form v-loading="is_busy">
      <el-form-item class="mb-3">
        <div class="container-header d-flex">
          <h6 class="text-dark">Add List to Power Dialer</h6>
          <div class="ml-auto" style="margin-top: -5px;">
            <el-button type="text"
                       class="btn-dialog-close"
                       size="large"
                       data-testid="close-popover"
                       @click="show_popover = false">
                <i class="fa fa-remove"></i>
            </el-button>
          </div>
        </div>

        <p class="lh-1p4 mb-2 text-dark">Power Dialer User:</p>
        <user-selector ref="userSelector"
                       class="mb-2"
                       v-model="user_id"
                       :hide_extensions="true"
                       :clearable="false"
                       @change="changeUserId">
        </user-selector>

        <p class="lh-1p4 mb-2 text-dark">Power Dialer List:</p>
        <el-form-item>
          <el-select class="w-full mb-2"
                     placeholder="Select List"
                     filterable
                     v-model="selected_pd_list_id"
                     @change="changePdList">
            <el-option :key="item.id"
                       :label="item.label"
                       :value="item.id"
                       v-for="item in pd_list_options">
              <span>{{ item.label }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="mb-2">
          <div scope="label" class="form-label mb-1">
            <label>Push contacts to</label>
          </div>
          <div class="row">
            <div class="col">
              <el-radio class="w-full"
                        border
                        size="medium"
                        :label="PowerDialer.DIRECTION_BOTTOM"
                        :value="PowerDialer.DIRECTION_BOTTOM"
                        v-model="direction">
                Bottom
              </el-radio>
            </div>
            <div class="col">
              <el-radio class="w-full"
                        border
                        size="medium"
                        :label="PowerDialer.DIRECTION_TOP"
                        :value="PowerDialer.DIRECTION_TOP"
                        v-model="direction">
                Top
              </el-radio>
            </div>
          </div>
        </el-form-item>
        <el-form-item class="mb-1">
          <div scope="label" class="form-label mb-1 d-flex">
            <el-checkbox label="Turn multiple numbers into separated tasks"
                         class="fw-normal"
                         v-model="multiple_phone_numbers">
            </el-checkbox>
            <el-tooltip class="item ml-1"
                        effect="dark"
                        placement="top"
                        width="300"
                        style="margin-top: 2px">
              <div slot="content">Any non-primary numbers of a contact will be turned into separate tasks</div>
              <i class="material-icons text-blue-5" style="cursor: pointer;font-size: 15px">info_outline</i>
            </el-tooltip>
          </div>
        </el-form-item>
        <el-form-item class="mb-1">
          <div scope="label" class="form-label mb-1 d-flex">
            <el-checkbox label="Prevent duplicate phone numbers"
                         v-model="prevent_duplicates">
            </el-checkbox>
            <el-tooltip class="item ml-1"
                        effect="dark"
                        placement="top"
                        width="300"
                        style="margin-top: 2px">
              <div slot="content">If selected, duplicated numbers will not be included again</div>              
              <i class="material-icons text-blue-5" style="cursor: pointer;font-size: 15px">info_outline</i>
            </el-tooltip>
          </div>
        </el-form-item>
        <el-form-item class="mb-1">
          <div scope="label" class="form-label d-flex">
            <el-checkbox label="Add own contacts only"
                         v-model="own_contacts_only">
            </el-checkbox>
            <el-tooltip class="item ml-1"
                        effect="dark"
                        placement="top"
                        width="300"
                        style="margin-top: 2px">
              <div slot="content">If selected, it will add only the contacts owned by you</div>
              <i class="material-icons text-blue-5" style="cursor: pointer;font-size: 15px">info_outline</i>
            </el-tooltip>
          </div>
        </el-form-item>
        <el-form-item class="mb-1" v-if="isAllowedInternationalNumbers">
          <div scope="label" class="form-label">
            <el-checkbox v-model="allow_international_phone_numbers"
                         label="Add international phone numbers"
                         class="w-full">
            </el-checkbox>
          </div>
        </el-form-item>
      </el-form-item>
      <div>
        <el-button type="success"
                   size="small"
                   class="w-full"
                   :disabled="!user_id || is_busy"
                   @click="addToPowerDialer">
          Add to Power Dialer
        </el-button>
      </div>
    </el-form>
    <button class="btn btn-block deep-purple btn-sm my-2" slot="reference">
      <i class="fa fa-phone pull-left"></i>
      <slot>Add To PowerDialer</slot>
    </button>
  </el-popover>
</template>

<script>
import auth from "../auth";
import * as PowerDialer from "../constants/power-dialer";
import * as International from "../constants/international-tier";
import { isEmpty, cloneDeep } from 'lodash'

export default {
  name: "add-tasks-to-user-power-dialer",

  props: {
    contactList: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      auth: auth,
      user_id: auth.user.profile.id,
			show_popover: false,
      multiple_phone_numbers: false,
      prevent_duplicates: true,
      own_contacts_only: false,
      allow_international_phone_numbers: false,
      direction: PowerDialer.DIRECTION_BOTTOM,
      PowerDialer,
      contact_list: null,
      selected_pd_list_id: null,
      pd_list_options: [],
      is_busy: false,
    };
  },

  computed: {
    isAllowedInternationalNumbers() {
      return this.currentCompany?.international_tier !== International.TIER_1
    },
  },

  methods: {
    onShow() {
      this.getUserPowerDialerLists()
    },

    addToPowerDialer() {
      const params = {
        list_id: this.contactList.id,
        contact_list_id: this.selected_pd_list_id,
        multiple_phone_numbers: this.multiple_phone_numbers,
        prevent_duplicates: this.prevent_duplicates,
        allow_international_phone_numbers: this.allow_international_phone_numbers,
        own_contacts_only: this.own_contacts_only,
        direction: this.direction,
        selected_all: true,
      }

      if (this.isMyQueueSelected(this.selected_pd_list_id)) {
        delete params.contact_list_id
      }

      if (!isEmpty(this.contactList.filters)) {
        params.filter_groups = cloneDeep(this.contactList.filters)
        delete params.list_id
      }

      this.is_busy = true

      axios.post(`/api/v2/power-dialer-list-items`, params).then(res => {
        this.$notify({
          offset: 95,
          title: 'Add To PowerDialer',
          message: res.data.message,
          type: 'success',
          showClose: true,
        })

        this.show_dialog = false
        this.is_busy = false
        this.show_popover = false
        setTimeout(() => {
          this.resetForm()
        }, 300)
      }).catch(err => {
          this.is_busy = false
          this.$root.handleErrors(err.response)
          console.log(err)
      })
    },

    changeUserId(user_id) {
      this.user_id = user_id
    },

    changePdList(list_id) {
      this.selected_pd_list_id = list_id
    },

    getUserPowerDialerLists() {
      const params = {
        user_id: this.user_id
      }

      axios
        .get("/api/v2/power-dialer-lists/my-queue", { params })
        .then((res) => {
          this.getPowerDialerLists();

          // pre-select My Queue list as default
          this.pd_list_options = [
            {
              id: res.data.id,
              label: res.data.name,
            },
          ];
          this.$nextTick(() => {
            this.selected_pd_list_id = res.data.id
          })
        })
        .catch((err) => {
          this.$root.handleErrors(err.response)
          console.log(err);
        });
    },

    getPowerDialerLists() {
      const params = {};
      if (this.user_id) {
        params.user_id = this.user_id
      }

      axios
        .get(`/api/v2/power-dialer-folders`, {
          params: params,
        })
        .then((res) => {
          const data = res.data[0];
          const mapList = (list) => {
            return {
              id: list.id,
              label: list.name,
            };
          };

          // make tree selection out off nested folders
          if (data?.child_folders && data.child_folders.length > 0) {
            let nestedFolders = []

            const mapFolder = (folder) => {
              let lists = [];

              // lists inside folder
              if (folder?.lists && folder.lists.length > 0) {
                lists = folder.lists.map(mapList)
              }

              // folders inside folder
              if (folder?.child_folders && folder.child_folders.length > 0) {
                nestedFolders = folder.child_folders.map(mapFolder)
              }

              return {
                id: folder.id,
                label: folder.name,
                children: [...lists, ...nestedFolders],
              };
            };

            const folders = data.child_folders.map((folder) =>
              mapFolder(folder)
            );
            this.pd_list_options = [...this.pd_list_options, ...folders]
          }

          // lists in root folder
          if (data?.lists && data.lists.length > 0) {
            const options = data.lists.map(mapList)

            this.pd_list_options = [...this.pd_list_options, ...options]
          }
        })
        .catch((err) => {
          this.$root.handleErrors(err.response)
          console.log(err);
        });
    },

		// check if authed user My Queue is selected
    isMyQueueSelected(selected_pd_list_id) {
      if (this.user_id !== auth.user.profile.id) {
        return false
      }

      const option = this.pd_list_options.find(
        (item) => item.id === selected_pd_list_id
      )
      return option && option.label === 'My Queue'
    },

    resetForm() {
      this.user_id = auth.user.profile.id
      this.multiple_phone_numbers = false
      this.prevent_duplicates = true
      this.own_contacts_only = false
      this.allow_international_phone_numbers = false
      this.direction = PowerDialer.DIRECTION_BOTTOM
    },

    onHide() {
      setTimeout(() => {
        this.resetForm()
      }, 300)
    },
  },
	watch: {
    user_id(value) {
      if (!value) {
        return;
      }

      this.pd_list_options = []
      this.getUserPowerDialerLists()
    },
  },
};
</script>
