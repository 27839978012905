<template>
    <el-form
        ref="agent_settings"
        class="aloai-agents"
        :rules="rules"
        :model="bot"
    >
        <div class="row">
            <div class="col-12">
                <el-tabs
                    v-model="activeName"
                >
                    <!-- Configure tab -->
                    <el-tab-pane
                        label="Configure"
                        name="configure"
                        data-tab-error="configure"
                    >
                        <span slot="label">
                            Configure<span v-html="tabLabelErrorIcon(tab_errors.configure)"></span>
                        </span>
                        <div class="row justify-content-center pt-3">
                            <div class="col-md-12 col-lg-10 main-col">
                                <el-form-item prop="name">
                                    <div class="form-label">
                                        <h5 class="text-dark">Name</h5>
                                        <small>
                                            What name would you like to give to this agent?
                                        </small>
                                    </div>
                                    <el-input
                                        v-model="bot.name"
                                        @input="preValidateForm('agent_settings')"
                                    />
                                </el-form-item>

                                <el-form-item>
                                    <div class="form-label">
                                        <h5 class="text-dark">Description</h5>
                                        <small>Describe the purpose of this agent.</small>
                                    </div>
                                    <el-input
                                        type="textarea"
                                        :rows="2"
                                        v-model="bot.description"
                                    />
                                </el-form-item>

                                <!-- Hide for now -->
                                <el-form-item v-if="false">
                                    <div class="form-label">
                                        <h5 class="text-dark">Allow access to contact information</h5>
                                        <small>
                                            Will this agent have access to your contact personal information?
                                        </small>
                                    </div>
                                    <el-select
                                        v-model="bot.allow_access_contact_information"
                                        class="w-100"
                                    >
                                        <el-option
                                            v-for="access_contact_information in access_contact_information_options"
                                            :key="access_contact_information.value"
                                            :label="access_contact_information.name"
                                            :value="access_contact_information.value"
                                        />
                                    </el-select>
                                </el-form-item>

                                <hr/>

                                <el-form-item>
                                    <div class="form-label">
                                        <h5 class="text-dark">Agent type</h5>
                                        <small>
                                            What is the main channel of communication for this agent?
                                        </small>
                                    </div>

                                    <el-tooltip
                                        :disabled="!blockAgentTypeChange"
                                        placement="left"
                                        content="Agent type cannot be changed after creation."
                                    >
                                        <el-select
                                            class="w-100"
                                            v-model="bot.type"
                                            :disabled="blockAgentTypeChange"
                                            @change="updateBotType"
                                        >
                                            <el-option
                                                v-for="type in types"
                                                :key="type.value"
                                                :label="type.name"
                                                :value="type.value"
                                                :disabled="lockVoiceOption(type)"
                                            />
                                        </el-select>
                                    </el-tooltip>
                                </el-form-item>

                                <el-form-item>
                                    <div class="form-label">
                                        <h5 class="text-dark">Direction</h5>
                                        <small>
                                            Will this agent start the conversations or wait for your
                                            contacts to reach out first?
                                        </small>
                                    </div>
                                    <el-select
                                        v-model="bot.direction"
                                        class="w-100"
                                    >
                                        <el-option
                                            v-for="direction in directions"
                                            :key="direction.value"
                                            :label="direction.name"
                                            :value="direction.value"
                                            :disabled="lockOutboundOption(bot.type, direction.value)"
                                        />
                                    </el-select>
                                </el-form-item>

                                <el-form-item v-if="bot.direction == AloAi.DIRECTION_OUTBOUND">
                                    <div class="form-label">
                                        <h5 class="text-dark">Default Outbound Line</h5>
                                        <small>
                                            Select the default outbound line for this agent.
                                        </small>
                                    </div>
                                    <el-select
                                        class="w-100"
                                        v-model="bot.default_outbound_campaign_id"
                                        placeholder="Select default outbound line"
                                        filterable
                                    >
                                        <el-option
                                            v-for="line in lines"
                                            :key="line.id"
                                            :label="line.name"
                                            :value="line.id"
                                        />
                                    </el-select>
                                </el-form-item>

                                <hr/>

                                <el-form-item v-if="bot.type === AloAi.TYPE_TEXT">
                                    <div class="form-label">
                                        <h5 class="text-dark">Text model</h5>
                                        <small>
                                            Choose the text model the agent needs to operate on. Opt for
                                            speed or depth to suit your agent's role.
                                        </small>
                                    </div>
                                    <el-select
                                        class="w-100"
                                        v-model="bot.model"
                                    >
                                        <el-option
                                            v-for="model in models"
                                            :key="model.value"
                                            :label="model.name"
                                            :value="model.value"
                                        />
                                    </el-select>
                                </el-form-item>

                                <el-form-item v-if="bot.type === AloAi.TYPE_VOICE">
                                    <div class="form-label">
                                        <h5 class="text-dark">AI model for calls</h5>
                                        <small>
                                            Choose the AI model the agent needs to operate on when receiving/making calls. Opt for
                                            speed or depth to suit your agent's role.
                                        </small>
                                    </div>
                                    <el-select
                                        v-model="bot.voice_model"
                                        filterable
                                        class="w-100"
                                    >
                                        <el-option-group
                                            v-for="group in groupedVoiceModels"
                                            :key="group.label"
                                            :label="group.label"
                                        >
                                            <el-option
                                                v-for="model in group.models"
                                                :key="model.id"
                                                :label="model.name"
                                                :value="model.value"
                                            />
                                        </el-option-group>
                                    </el-select>
                                </el-form-item>

                                <el-form-item v-if="bot.type === AloAi.TYPE_VOICE">
                                    <div class="form-label">
                                        <h5 class="text-dark">Language</h5>
                                        <small>Select language for the voice agent to respond in</small>
                                    </div>
                                    <el-select
                                        class="w-100"
                                        v-model="bot.language"
                                        filterable
                                        placeholder="Select language"
                                    >
                                        <el-option
                                            v-for="lang in filteredLanguages"
                                            :key="lang.code"
                                            :label="lang.name"
                                            :value="lang.code"
                                        />
                                    </el-select>
                                </el-form-item>

                                <el-form-item v-if="bot.type === AloAi.TYPE_VOICE">
                                    <div class="form-label">
                                        <h5 class="text-dark">Select voice</h5>
                                        <small> Choose a preset voice for this agent. </small>
                                    </div>
                                    <div class="d-flex align-items-center width-300">
                                        <el-select
                                            class="w-100"
                                            v-model="bot.voice_id"
                                            @change="stopAudio"
                                            filterable
                                            placeholder="Select voice"
                                        >
                                            <el-option-group
                                                v-for="group in groupedVoices"
                                                :key="group.label"
                                                :label="group.label"
                                            >
                                                <el-option
                                                    v-for="voice in group.voices"
                                                    :key="voice.id"
                                                    :label="voice.name"
                                                    :value="voice.id"
                                                >
                                                    <template #default>
                                                        <span class="voice-option">
                                                            <span class="gender-circle"
                                                                  :class="{'gender-male': voice.gender === 'male', 'gender-female': voice.gender === 'female', 'gender-unknown': voice.gender === 'unknown'}">
                                                                {{ getGenderName(voice.gender) }}
                                                            </span>
                                                            {{ voice.name }}
                                                        </span>
                                                    </template>
                                                </el-option>
                                            </el-option-group>
                                        </el-select>
                                        <el-button
                                            class="ml-2"
                                            circle
                                            @click="toggleAudio"
                                        >
                                            <i :class="isPlaying ? 'fas fa-stop' : 'fas fa-play'"></i>
                                        </el-button>
                                    </div>
                                </el-form-item>

                                <hr/>

                                <el-form-item>
                                    <div class="form-label">
                                        <h5 class="text-dark">Engagement expiration</h5>
                                        <small>
                                            How long will the agent's engagement with a contact last before it expires?
                                        </small>
                                    </div>
                                    <div class="row align-items-center">
                                        <div class="col-6">
                                            <el-form-item
                                                class="mb-0"
                                                prop="ttl"
                                            >
                                                <el-input-number
                                                    class="w-100"
                                                    v-model="bot_ttl"
                                                    :min="1"
                                                    :max="14"
                                                    :precision="0"
                                                    :controls="true"
                                                    @change="updateBotTTL"
                                                />
                                            </el-form-item>
                                        </div>
                                        <div class="col-6">
                                            Days
                                        </div>
                                    </div>
                                </el-form-item>
                            </div>
                        </div>
                    </el-tab-pane>

                    <!-- Advanced tab -->
                    <el-tab-pane
                        label="Advanced"
                        name="advanced"
                        data-tab-error="advanced"                        
                    >
                        <span slot="label">
                            Advanced<span v-html="tabLabelErrorIcon(tab_errors.advanced)"></span>
                        </span>
                        <div class="row justify-content-center pt-3">
                            <div class="col-md-12 col-lg-10 main-col">
                                <el-form-item v-if="bot.type == AloAi.TYPE_VOICE">
                                    <div class="form-label">
                                        <h5 class="text-dark">Responsiveness</h5>
                                        <small>Control how fast the agent responds after users finish speaking.</small>
                                    </div>
                                    <el-slider v-model="bot.settings.retellai.responsiveness"
                                        show-input
                                        :min=0.1
                                        :max=1
                                        :step=0.01
                                    />
                                </el-form-item>
                                <el-form-item v-if="bot.type == AloAi.TYPE_VOICE">
                                    <div class="form-label">
                                        <h5 class="text-dark">Interruption Sensitivity</h5>
                                        <small>Control how sensitively the agent can be interrupted by human speech.</small>
                                    </div>
                                    <el-slider v-model="bot.settings.retellai.interruption_sensitivity"
                                        show-input
                                        show-stops
                                        :min=0.1
                                        :max=1
                                        :step=0.1
                                    />
                                </el-form-item>
                                <div class="container-header d-flex justify-content-between">
                                    <div class="d-flex">
                                        <h3 class="listing-heading">
                                            Custom Functions
                                        </h3>
                                    </div>
                                    <div class="d-flex">
                                        <el-button type="primary" plain size="mini" icon="el-icon-plus" class="my-2"
                                            @click="openCustomFunctionModal">
                                            Add
                                        </el-button>
                                    </div>
                                </div>
                                <el-table
                                    class="w-full"
                                    row-key="uuid"
                                    :data="toolsCustomList" 
                                    stripe
                                    v-if="toolsCustomList?.length > 0"
                                >
                                    <el-table-column label="Name">
                                        <template v-slot="scope">
                                            {{ scope.row.name }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Description">
                                        <template v-slot="scope">
                                            {{ scope.row.description }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="Actions"
                                        align="center"
                                        width="100"
                                        fixed="right"
                                    >
                                        <template v-slot="scope">
                                            <button
                                                class="btn btn-sm info"
                                                @click.prevent="handleCustomFunctionEdit(scope.row)"
                                            >
                                                <i class="fa fa-pencil pull-left"></i>
                                            </button>
                                            <button 
                                                class="btn btn-sm danger"
                                                @click.prevent="handleCustomFunctionDelete(scope.row.uuid)"
                                            >
                                                <i class="fa fa-trash pull-left"></i>
                                            </button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </el-tab-pane>

                    <!-- Instructions tab -->
                    <el-tab-pane
                        label="Instructions"
                        name="instructions"
                        data-tab-error="instructions"
                    >
                        <span slot="label">
                            Prompt<span v-html="tabLabelErrorIcon(tab_errors.instructions)"></span>
                        </span>
                        <div class="row justify-content-center pt-3">
                            <div class="col-md-12 col-lg-10 main-col">
                                <model-settings
                                    :bot="bot"
                                    :toolsList="toolsList"
                                    @validate="preValidateForm('agent_settings')"
                                    @save-instructions="saveInstructions"
                                />
                            </div>
                        </div>
                    </el-tab-pane>

                    <!-- Post-Call Actions tab -->
                    <el-tab-pane
                        label="Post-Call Actions"
                        name="post_call_actions"
                        data-tab-error="post_call_actions"
                        v-if="bot.type == AloAi.TYPE_VOICE"
                    >
                        <span slot="label">
                            Post-Call Actions<span v-html="tabLabelErrorIcon(tab_errors.post_call_actions)"></span>
                        </span>
                        <div class="row justify-content-center pt-3">
                            <div class="col-md-12 col-lg-10 main-col">
                                <post-call-actions-tab :bot="bot" />
                            </div>
                        </div>
                    </el-tab-pane>

                    <!-- Schedule tab -->
                    <el-tab-pane
                        label="Schedule"
                        name="schedule"
                        data-tab-error="schedule"
                    >
                        <span slot="label">
                            Schedule<span v-html="tabLabelErrorIcon(tab_errors.schedule)"></span>
                        </span>
                        <div class="row justify-content-center pt-3">
                            <div class="col-md-12 col-lg-10 main-col">
                                <schedule-tab :bot="bot"/>
                            </div>
                        </div>
                    </el-tab-pane>

                    <!-- Actions tab -->
                    <el-tab-pane
                        label="Actions"
                        name="actions"
                        data-tab-error="actions"
                        v-if="bot.type == AloAi.TYPE_TEXT"
                    >
                        <span slot="label">
                            Actions<span v-html="tabLabelErrorIcon(tab_errors.actions)"></span>
                        </span>
                        <div class="row justify-content-center pt-3">
                            <div class="col-md-12 col-lg-10 main-col">
                                <div v-if="bot.type == AloAi.TYPE_TEXT">
                                    <div class="form-label d-flex">
                                        <el-switch
                                            class="mr-2 align-self-center"
                                            v-model="bot.follow_up_enabled"
                                            active-color="#00BF50"
                                        />
                                        <h5 class="mb-0 mt-1">Follow Up</h5>
                                    </div>

                                    <el-form-item>
                                        <div class="form-label">
                                            <small>
                                                Set the frequency you want this agent to follow up the
                                                conversation when the contact is unresponsive.
                                            </small>
                                        </div>

                                        <!-- Follow Up -->
                                        <div
                                            v-show="bot.follow_up_enabled"
                                            class="row"
                                        >
                                            <div class="col-4">
                                                <el-form-item class="no-border">
                                                    <el-input
                                                        type="number"
                                                        class="w-100"
                                                        v-model="bot.follow_up_frequency"
                                                        :disabled="!bot.follow_up_enabled"
                                                        :max="100"
                                                        :min="1"
                                                        @input="handleFollowUpFrequencyInput"
                                                    >
                                                        <template slot="append">
                                                            Attempt<span v-if="bot.follow_up_frequency != 1">s</span>
                                                        </template>
                                                    </el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div
                                            v-show="bot.follow_up_enabled"
                                            class="form-label my-2"
                                        >
                                            <small>
                                                Set a delay within each follow up attempt.
                                            </small>
                                        </div>

                                        <DurationPicker
                                            v-show="bot.follow_up_enabled"
                                            :current_seconds="bot.follow_up_delay"
                                            @update_seconds="onUpdateFollowUpDelay"
                                        />
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <custom-function
            v-if="createCustomFunctionDialogVisible"
            ref="custom_function_dialog"
            :open="true"            
            :tool="customFunctionData || undefined"
            @close="onCustomFunctionDialogClose"
        />
    </el-form>
</template>

<script>
import { MessageBox } from 'element-ui'
import _ from 'lodash'
import * as AloAi from '../../../constants/aloai'
import { acl_mixin, aloai_mixin, form_validation_mixin } from '../../../mixins'
import Variables from '../../messenger/variables'
import DurationPicker from './duration-picker'
import ModelSettings from './model-settings'
import PostCallActionsTab from './tabs/post-call-actions-tab'
import ScheduleTab from './tabs/schedule-tab'
import CustomFunction from './custom-function'

export default {
    name: 'aloai-form-settings',

    mixins: [form_validation_mixin, acl_mixin, aloai_mixin],

    components: {
        ModelSettings,
        Variables,
        DurationPicker,
        ScheduleTab,
        PostCallActionsTab,
        CustomFunction
    },

    props: {
        lines: {
            required: false,
            default: []
        },
        bot_id: {
            required: false,
            default: null
        },
        bot: {
            required: true,
        },
    },

    data() {
        return {
            customFunctionData: null,
            createCustomFunctionDialogVisible: false,
            access_contact_information_options: [
                {
                    name: 'Yes',
                    value: true
                }, {
                    name: 'No',
                    value: false
                }
            ],
            types: [{
                name: 'Text',
                value: AloAi.TYPE_TEXT
            }, {
                name: 'Voice',
                value: AloAi.TYPE_VOICE
            }],
            directions: [{
                name: 'Inbound',
                value: AloAi.DIRECTION_INBOUND
            }, {
                name: 'Outbound',
                value: AloAi.DIRECTION_OUTBOUND
            }],
            models: [{
                name: 'GPT-4o mini',
                value: AloAi.MODEL_GPT_4O_MINI
            }, {
                name: 'GPT-4o',
                value: AloAi.MODEL_GPT_4O
            }],
            voice_models: [],
            voices_list: [],
            rules: {
                name: [
                    {
                        required: true,
                        message: 'Please provide a name for the agent',
                        trigger: 'blur'
                    }
                ],
                opener: [
                    {
                        required: true,
                        message: 'Please provide an opener for the agent',
                        trigger: 'blur'
                    }
                ],
                ttl: [
                    {
                        required: true,
                        message: 'Please provide an engagement expiration time for the agent',
                        trigger: 'blur'
                    }
                ],
                instructions: {
                    validator: (_, value, callback) => {
                        if (!this.bot.instructions[0].content) {
                            callback(new Error('Please provide instructions for the agent'))
                        }

                        callback()
                    },
                    trigger: 'blur',
                },
                language: [
                    {
                        required: true,
                        message: 'Please select a language for the voice agent',
                        trigger: 'change'
                    }
                ],
            },
            loading: false,
            selected_campaigns: [],
            functions_list: [],
            tab_errors: {
                configure: false,
                instructions: false,
                actions: false,
                post_call_actions: false,
                schedule: false,
                advanced: false
            },
            bot_ttl: 7,
            error_notification: null,
            activeName: 'configure',
            isPlaying: false,
            audio: null,
            toolsList: null,
            toolsCustomList: null,
            AloAi,
            languages: [],
        }
    },

    computed: {
        blockAgentTypeChange() {
            return this.bot_id ? true : false
        },

        filteredLanguages() {
            let selected_voice = this.voices_list.find((voice) => voice.id === this.bot.voice_id)
            let filtered_languages = this.languages

            // If using a Deepgram Voice, this.languages must only show en-US and en-GB
            if (selected_voice && selected_voice.provider === AloAi.PROVIDER_DEEPGRAM) {
                filtered_languages = filtered_languages.filter((language) => {
                    return language.code === AloAi.LANGUAGE_EN_US || language.code === AloAi.LANGUAGE_EN_GB
                })

                // If selected language is not any of the US or GB languages, set the first language in the filtered list as the selected language
                if (!filtered_languages.some((language) => language.code === this.bot.language)) {
                    this.bot.language = filtered_languages[0].code
                }

                return filtered_languages
            }

            return this.languages
        },

        filteredVoices() {
            let voices_clone = this.voices_list

            // If using OpenAI Realtime model, show OpenAI voices only
            if (voices_clone.length > 0 && (this.bot.voice_model === AloAi.VOICE_MODEL_GPT_4O_REALTIME || this.bot.voice_model === AloAi.VOICE_MODEL_GPT_4O_MINI_REALTIME)) {
                // Realtime API only supports OpenAI voices
                let voices_filtered = voices_clone.filter((voice) => {
                    return voice.provider === AloAi.PROVIDER_OPENAI
                })

                // Check if selected voice is supported by the selected model
                let model_supported = voices_filtered.some((voice) => {
                    return voice.id === this.bot.voice_id
                })

                // If not supported, set the first voice in the filtered list as the selected voice
                if (!model_supported) {
                    this.bot.voice_id = voices_filtered[0].id
                }

                return voices_filtered
            }

            return voices_clone
        },

        groupedVoices() {
            const voices = this.filteredVoices

            // Initialize groups for ElevenLabs and OpenAI
            const groups = {
                elevenlabs: [],
                openai: []
            }

            voices.forEach(voice => {
                if (groups.hasOwnProperty(voice.provider)) {
                    groups[voice.provider].push(voice)
                }
            })

            // Return groups in from less to more voices order
            let voice_groups = [
                {label: AloAi.LABEL_ELEVENLABS, voices: groups.elevenlabs},
                {label: AloAi.LABEL_OPENAI, voices: groups.openai},
            ]

            return voice_groups
        },

        groupedVoiceModels() {
            // Initialize groups for each provider
            const groups = {
                claude: [],
                openai: [],
                'openai-realtime': []
            }

            // Group models by provider
            this.voice_models.forEach(model => {
                if (groups.hasOwnProperty(model.provider)) {
                    groups[model.provider].push(model)
                }
            })

            // Return groups in desired order with proper labels
            return [
                {label: AloAi.LABEL_CLAUDE, models: groups.claude},
                {label: AloAi.LABEL_OPENAI, models: groups.openai},
                {label: AloAi.LABEL_OPENAI_REALTIME, models: groups[AloAi.GROUP_OPENAI_REALTIME]}
            ].filter(group => group.models.length > 0) // Only show groups that have models
        },
    },

    mounted() {
        this.types[1].name = !this.isAloAiVoiceAgentsEnabled() ? 'Voice (Coming Soon)' : 'Voice'
        this.fetchVoices()
        this.fetchVoiceModels()
        this.fetchLanguages()
    },

    methods: {
        openCustomFunctionModal() {
            this.customFunctionData = null
            this.createCustomFunctionDialogVisible = true
            this.$refs.custom_function_dialog.clearForm()
        },
        onCustomFunctionDialogClose() {
            this.customFunctionData = null
            this.createCustomFunctionDialogVisible = false
            this.fetchTools()
        },
        handleCustomFunctionEdit(row) {
            this.customFunctionData = row.tool
            this.createCustomFunctionDialogVisible = true
        },
        handleCustomFunctionDelete(uuid) {
            MessageBox.confirm('Are you sure you want to delete this custom function?', 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                axios
                    .delete(`/api/v1/aloai/tools/${uuid}`)
                    .then(() => {
                        this.fetchTools()
                        this.$notify({
                            offset: 95,
                            title: 'AloAi Custom Functions',
                            dangerouslyUseHTMLString: true,
                            message: 'Custom function deleted successfully.',
                            type: 'success',
                            showClose: true,
                            duration: 5000,
                        })
                    })
                    .catch((error) => {
                        this.$notify.error({
                            offset: 95,
                            title: 'AloAi Custom Functions',
                            message: error.response?.data?.message ?? `There was an error deleting the custom function`
                        })
                    })
            });            
        },
        handleClick(tab, event) {
        },

        submitForm() {
            this.resetTabErrors()
            this.closeErrorNotification()

            if (this.validateForm('agent_settings') === true) {
                return true
            } else {
                this.error_notification = this.$notify({
                    offset: 95,
                    title: 'AloAi Agents',
                    dangerouslyUseHTMLString: true,
                    message: this.getFormErrorsMessage('agent_settings'),
                    type: 'error',
                    showClose: true,
                    duration: 5000,
                })
            }

            return false
        },

        getFormErrorsMessage(form_name) {
            let form_element = _.get(this.$refs, form_name, null)
            let err_message = '<div class="mb-2">Please resolve AloAi agent configuration errors</div>'

            form_element.fields.forEach((element) => {
                if (element.validateState === 'error') {
                    // get the parent tab pane of the element
                    let parent = element.$parent

                    while (parent && parent.$options._componentTag !== 'el-tab-pane') {
                        parent = parent.$parent
                    }

                    const tab = parent.$attrs['data-tab-error'] || parent.name.replace('-', '_')
                    const tab_label = parent.$slots.label[0].children[0].text

                    // show the error icon on the element's parent tab pane
                    if (tab !== "") {
                        this.tab_errors[tab] = true
                    }

                    err_message += `<div class="mb-2"><i class="fa fa-remove text-danger mr-2"></i> ${tab_label} > ${element.validateMessage}</div>`
                }
            })

            return err_message
        },

        tabLabelErrorIcon(tab_error) {
            if (tab_error) {
                return ' <i class="fa fa-exclamation-circle text-danger ml-1"></i>'
            }

            return ''
        },

        resetTabErrors() {
            this.tab_errors = {
                configure: false,
                instructions: false,
                actions: false,
                deployment: false,
            }
        },

        closeErrorNotification() {
            if (this.error_notification) {
                this.error_notification.close()
            }
        },

        updateBotTTL() {
            this.bot.ttl = this.bot_ttl * 60 * 24
            this.preValidateForm('agent_settings')
        },

        updateBotType() {
            if (this.bot.type === AloAi.TYPE_VOICE) {
                this.bot.direction = AloAi.DIRECTION_INBOUND
                this.bot.follow_up_enabled = false
            }
            this.fetchTools()
        },

        toggleAudio() {
            // Stop the current audio if it's playing
            if (this.isPlaying && this.audio) {
                this.audio.pause()
                this.audio.currentTime = 0
                this.isPlaying = false
                return
            }
            // Lookup the currently selected voice from the dynamically loaded voices_list
            const selectedVoice = this.voices_list.find(
                (voice) => voice.id === this.bot.voice_id
            )

            // Play the new audio
            if (selectedVoice && selectedVoice.audio_url) {
                this.audio = new Audio(selectedVoice.audio_url)
                this.audio.play()
                this.audio.addEventListener('ended', this.stopAudio)
                this.isPlaying = true
            }
        },

        stopAudio() {
            if (this.audio) {
                this.audio.pause()
                this.audio.currentTime = 0
            }
            this.isPlaying = false
        },

        onUpdateFollowUpDelay(seconds) {
            this.bot.follow_up_delay = seconds
        },

        handleFollowUpFrequencyInput(value) {
            // The element UI component handles the input as a string, so we need to parse it to an integer
            this.bot.follow_up_frequency = parseInt(value) || 0
        },

        fetchTools() {
            axios
                .get(`/api/v1/aloai/tools?type=${this.bot.type ?? 1}`)
                .then((res) => {
                    this.toolsList = res.data.data
                    this.toolsCustomList = this.toolsList.filter(tool => tool.custom_type !== null)
                })
                .catch((err) => {
                    console.log(err)
                })
        },

        fetchVoices() {
            axios.get('/api/v1/aloai/bot/voice/voices')
                .then((res) => {
                    // Expecting the response to be an array of voices
                    this.voices_list = res.data.data
                })
                .catch((err) => {
                    console.error(err)
                })
        },

        fetchVoiceModels() {
            axios.get('/api/v1/aloai/bot/voice/models')
                .then((res) => {
                    // Expecting the response to be an array of voice models with "id" and "name"
                    this.voice_models = res.data
                })
                .catch((err) => {
                    console.error(err)
                })
        },

        fetchLanguages() {
            axios.get('/api/v1/aloai/bot/voice/languages')
                .then((res) => {
                    this.languages = res.data
                })
                .catch((err) => {
                    console.error(err)
                })
        },

        getGenderName(gender) {
            switch (gender) {
                case 'male':
                    return 'M'
                case 'female':
                    return 'F'
                case 'unknown':
                    return 'U'
                default:
                    return '-'
            }
        },
    },

    watch: {
        bot: function (newVal) {
            this.fetchTools()

            if (newVal.ttl) {
                this.bot_ttl = newVal.ttl / 60 / 24
            }

            if (newVal.tools && newVal.tools.length) {
                this.functions_list = newVal.tools
            }
        },
        'bot.type': function (new_type) {
            this.fetchTools()
        },
        'bot.direction': function (newDir) {
            if (newDir !== AloAi.DIRECTION_OUTBOUND) {
                this.bot.default_outbound_campaign_id = null
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        this.closeErrorNotification()
        next()
    },
}
</script>

<style scoped>
.main-col {
    max-width: 700px;
    min-width: 400px;
}

.voice-option {
    display: flex;
    align-items: center;
}

.gender-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 5px;
    color: white;
    font-size: 10px;
}

.gender-male {
    background-color: blue;
}

.gender-female {
    background-color: pink;
}

.gender-unknown {
    background-color: grey;
}
</style>
