<template>
    <div class="app-body"
         id="view">
        <div class="fixed-header padding pt-0 pb-0"
             :style="{top: (top_offset+50) + 'px'}"
             v-if="hasPermissionTo(CREATE_TEAM) && team?.id">
            <div class="row mb-0 mt-0">
                <div class="col-12 p-2">
                    <router-link :to="{ name: 'Team Activity', params: {team_id: team.id}}">
                        <button class="btn purplish pull-left"
                                data-testid="activity-team-button">
                            <i class="fa fa-signal"></i>
                            Activity
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="teams p-3 mt-5 pb-5">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <el-tabs v-model="activeTabName">
                                    <el-tab-pane name="general" label="General">
                                        <el-form ref="teamFormRef"
                                                 :model="teamForm"
                                                 :rules="rules">
                                            <el-form-item label="Team Name"
                                                          required
                                                          prop="name">
                                                <el-input placeholder="Type here"
                                                          :maxlength="80"
                                                          :disabled="!hasPermissionTo(CREATE_TEAM)"
                                                          v-model="teamForm.name" />
                                            </el-form-item>
                                            <el-form-item label="Description"
                                                          prop="description">
                                                <el-input type="textarea"
                                                          placeholder="Type here"
                                                          :disabled="!hasPermissionTo(CREATE_TEAM)"
                                                          v-model="teamForm.description" />
                                            </el-form-item>
                                        </el-form>
                                    </el-tab-pane>
                                    <el-tab-pane name="users"
                                                 label="Users"
                                                 :disabled="!team?.id">
                                        <div class="d-flex align-items-center justify-content-between pt-1">
                                            <div class="d-flex align-items-center w-50">
                                                <el-input class="w-50 mr-3"
                                                          placeholder="Search Users"
                                                          prefix-icon="el-icon-search"
                                                          clearable
                                                          v-model="searchUsers" />
                                            </div>
                                            <div class="d-flex w-50 justify-content-end">
                                                <el-button class="w-25"
                                                           data-testid="team-creator-open-modal-button"
                                                           type="success"
                                                           slot="reference"
                                                           @click="openAddUsersModal">
                                                    Add Users
                                                </el-button>
                                            </div>
                                        </div>

                                        <el-table class="w-full mb-3"
                                                  ref="company-users-team-list"
                                                  stripe
                                                  :data="filteredUsers"
                                                  v-loading="isLoading">
                                            <el-table-column data-testid="users-status-column"
                                                             prop="status"
                                                             align="left"
                                                             width="120">
                                                <template v-slot="scope">
                                                    <span class="status d-flex align-items-center justify-content-left">
                                                        <i class="b-white mr-2"
                                                           :class="[ $options.filters.agentStatusClass(scope.row.agent_status) ]"></i>
                                                        {{ scope.row.agent_status | fixAgentStatus }}
                                                    </span>
                                                </template>
                                            </el-table-column>

                                            <el-table-column data-testid="users-id-column"
                                                             prop="id"
                                                             label="ID"
                                                             width="100"
                                                             sortable>
                                                <template v-slot="scope">
                                                    <span class="text-greyish">{{ scope.row.id }}</span>
                                                </template>
                                            </el-table-column>

                                            <el-table-column data-testid="users-name-column"
                                                             prop="name"
                                                             label="Name"
                                                             :min-width="200"
                                                             sortable>
                                                <template v-slot="scope">
                                                    <router-link :to="{ name: 'User Dialog', params: { user_id: scope.row.id }}">
                                                        <span class="text-dark-greenish text-ellipsis">{{ scope.row.name || "-" }}</span>
                                                        <span class="text-greyish text-ellipsis"
                                                              :title="scope.row.email"
                                                              v-if="!scope.row.is_destination">
                                                            {{ scope.row.email }}
                                                        </span>
                                                    </router-link>
                                                    <span class="d-inline-block label blue"
                                                          v-if="scope.row.read_only_access">
                                                        Reporters are not billed
                                                    </span>
                                                </template>
                                            </el-table-column>

                                            <el-table-column data-testid="users-description-column"
                                                             prop="description"
                                                             label="Description"
                                                             :min-width="200">
                                                <template v-slot="scope">
                                                    <span v-if="scope.row.description">{{ scope.row.description }}</span>
                                                    <span v-else>-</span>
                                                </template>
                                            </el-table-column>

                                            <el-table-column data-testid="users-last-login-column"
                                                             prop="last_login"
                                                             label="Last login"
                                                             :min-width="200">
                                                <template v-slot="scope">
                                                    <span v-if="scope.row.last_login && !scope.row.is_destination">{{ scope.row.last_login | fixFullDateUTCRelative }}</span>
                                                    <span v-else>-</span>
                                                </template>
                                            </el-table-column>

                                            <el-table-column data-testid="users-last-role-names-column"
                                                             prop="role_names"
                                                             label="Role"
                                                             :min-width="200">
                                                <template v-slot="scope">
                                                    <template v-if="!scope.row.is_destination">
                                                        <div class="row"
                                                             :key="role_name"
                                                             v-for="role_name in scope.row.role_names">
                                                            <div class="col-12">
                                                                <span class="text-ellipsis"
                                                                      :title="role_name">
                                                                    {{ role_name }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <span class="text-ellipsis"
                                                          v-else>
                                                        Extension
                                                    </span>
                                                </template>
                                            </el-table-column>

                                            <el-table-column data-testid="users-last-extension-column"
                                                             prop="extension"
                                                             label="Extension"
                                                             sortable
                                                             :min-width="120">
                                                <template v-slot="scope">
                                                    <span class="text-greyish text-ellipsis">{{ scope.row.extension || "-" }}</span>
                                                </template>
                                            </el-table-column>

                                            <el-table-column data-testid="users-last-status-column"
                                                             label="Status"
                                                             prop="active"
                                                             align="left"
                                                             width="100"
                                                             sortable>
                                                <template v-slot="scope">
                                                    <span class="status d-flex align-items-center justify-content-left">
                                                        {{ scope.row.active ? 'Active' : 'Paused' }}
                                                    </span>
                                                </template>
                                            </el-table-column>

                                            <el-table-column data-testid="users-last-answer-by-column"
                                                             prop="answer_by"
                                                             label="Answer By"
                                                             align="left"
                                                             sortable
                                                             :min-width="150">
                                                <template v-slot="scope">
                                                    <span class="text-greyish"
                                                          v-if="scope.row.answer_by === AnswerTypes.BY_BROWSER">
                                                        Browser / Apps
                                                    </span>
                                                    <span class="text-greyish"
                                                          v-else-if="scope.row.answer_by === AnswerTypes.BY_PHONE_NUMBER">
                                                        <span v-if="scope.row.phone_number">{{ scope.row.phone_number | fixPhone }}</span>
                                                        <span v-else>-</span>
                                                    </span>
                                                    <span class="text-greyish"
                                                          v-else-if="scope.row.answer_by === AnswerTypes.BY_IP_PHONE">
                                                        SIP
                                                    </span>
                                                    <span class="text-greyish"
                                                          v-else-if="scope.row.answer_by === AnswerTypes.BY_NONE">
                                                        Will not answer
                                                    </span>
                                                </template>
                                            </el-table-column>

                                            <el-table-column align="center"
                                                             label="Operations"
                                                             width="180">
                                                <template v-slot="scope">
                                                    <div slot="reference">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <router-link :to="{ name: 'User Activity', params: {user_id: scope.row.id }}"
                                                                           v-if="hasPermissionTo(CREATE_TEAM)">
                                                                    <button class="btn btn-sm btn-block purplish">
                                                                        <i class="fa fa-signal pull-left"></i>
                                                                        Activity
                                                                    </button>
                                                                </router-link>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex mt-2">
                                                            <button class="btn btn-sm btn-block danger flex-grow-1"
                                                                    @click="handleRemoveUsersModal(scope.row)">
                                                                <i class="fa fa-trash pull-left" />
                                                                Remove
                                                            </button>
                                                        </div>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </el-tab-pane>

                                    <el-tab-pane name="supervisors"
                                                 label="Supervisors"
                                                 :disabled="!team?.id"
                                                 v-if="hasPermissionTo(ADD_SUPERVISOR_TO_TEAM) && hasPermissionTo(REMOVE_SUPERVISOR_FROM_TEAM)">
                                        <template slot="label">
                                            <span>Supervisors</span>
                                            <team-supervisor-tooltip placement="top" />
                                        </template>
                                        <div class="d-flex align-items-center justify-content-between pt-1">
                                            <div class="d-flex align-items-center w-50">
                                                <el-input class="w-50 mr-3"
                                                          placeholder="Search Supervisors"
                                                          prefix-icon="el-icon-search"
                                                          clearable
                                                          v-model="searchSupervisors" />
                                            </div>
                                            <div class="d-flex w-50 justify-content-end">
                                                <el-button class="w-25"
                                                           slot="reference"
                                                           type="success"
                                                           data-testid="team-supervisor-add-button"
                                                           @click="openAddSupervisorsModal">
                                                    Add Supervisors
                                                </el-button>
                                            </div>
                                        </div>

                                        <el-table class="w-full mb-3"
                                                  ref="company-supervisors-team-list"
                                                  stripe
                                                  :data="filteredSupervisors"
                                                  v-loading="isLoading">
                                            <el-table-column data-testid="supervisors-id-column"
                                                             prop="id"
                                                             label="ID"
                                                             width="100"
                                                             sortable>
                                                <template v-slot="scope">
                                                    <span class="text-greyish">{{ scope.row.id }}</span>
                                                </template>
                                            </el-table-column>

                                            <el-table-column data-testid="supervisors-name-column"
                                                             prop="name"
                                                             label="Name"
                                                             :min-width="200"
                                                             sortable>
                                                <template v-slot="scope">
                                                    <router-link :to="{ name: 'User Dialog', params: { user_id: scope.row.id }}">
                                                        <span class="text-dark-greenish text-ellipsis">{{ scope.row.first_name }} {{ scope.row.last_name }}</span>
                                                        <span class="text-greyish text-ellipsis"
                                                              :title="scope.row.email">
                                                            {{ scope.row.email }}
                                                        </span>
                                                    </router-link>
                                                </template>
                                            </el-table-column>

                                            <el-table-column data-testid="supervisors-role-names-column"
                                                             prop="role_names"
                                                             label="Role"
                                                             :min-width="200">
                                                <template v-slot="scope">
                                                    <div class="row"
                                                         :key="role_name"
                                                         v-for="role_name in scope.row.role_names">
                                                        <div class="col-12">
                                                            <span class="text-ellipsis"
                                                                  :title="role_name">
                                                                {{ role_name }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </template>
                                            </el-table-column>

                                            <el-table-column data-testid="supervisors-status-column"
                                                             label="Status"
                                                             prop="active"
                                                             align="left"
                                                             width="100"
                                                             sortable>
                                                <template v-slot="scope">
                                                    <span class="status d-flex align-items-center justify-content-left">
                                                        {{ scope.row.active ? 'Active' : 'Paused' }}
                                                    </span>
                                                </template>
                                            </el-table-column>

                                            <el-table-column align="center"
                                                             label="Operations"
                                                             width="180">
                                                <template v-slot="scope">
                                                    <div slot="reference">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <button class="btn btn-sm btn-block danger"
                                                                        @click="handleRemoveSupervisorsModal(scope.row)">
                                                                    <i class="fa fa-trash pull-left"></i>
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </el-tab-pane>

                                    <el-tab-pane name="ring-groups"
                                                 label="Associated Ring Groups"
                                                 :disabled="!team?.id">
                                        <template slot="label">
                                            <div class="d-flex align-items-center">
                                                <span>Associated Ring Groups</span>
                                                <team-ring-groups-tooltip placement="top" />
                                            </div>
                                        </template>
                                        <div class="d-flex align-items-center justify-content-between pt-1">
                                            <el-input class="w-50"
                                                      placeholder="Search Ring Groups"
                                                      prefix-icon="el-icon-search"
                                                      clearable
                                                      v-model="searchRingGroups" />
                                        </div>

                                        <el-table class="w-full"
                                                  ref="company-ring-group-team-list"
                                                  max-height="350"
                                                  stripe
                                                  :data="filteredRingGroups"
                                                  v-loading="isLoading">
                                            <el-table-column prop="name"
                                                             label="Name">
                                                <template v-slot="scope">
                                                    {{ scope.row?.name || '-' }}
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </el-tab-pane>
                                </el-tabs>

                                <el-dialog width="30%"
                                           center
                                           :visible.sync="isDeleteUsersModalVisible"
                                           @close="closeDeleteUsersModal">
                                    <div class="dialog--title mt-4"
                                         slot="title">
                                        {{ deleteUsersModalConfig.title }}
                                    </div>
                                    <p class="break-word text-center"
                                       v-html="deleteUsersModalConfig.description" />
                                    <div class="d-flex justify-content-center mt-5 row">
                                        <el-button class="mb-2"
                                                   type="default"
                                                   slot="reference"
                                                   @click="closeDeleteUsersModal">
                                            No, Keep User
                                        </el-button>
                                        <el-button class="mb-2"
                                                   type="danger"
                                                   slot="reference"
                                                   data-testid="team-delete-users-button"
                                                   :disabled="isLoading"
                                                   @click="onDeleteUsers">
                                            Yes
                                        </el-button>
                                    </div>
                                </el-dialog>

                                <el-dialog width="30%"
                                           center
                                           :visible.sync="isAddUsersModalVisible"
                                           :loading="isLoading">
                                    <div class="dialog--title mt-4"
                                         slot="title">
                                        {{ addUsersModalConfig.title }}
                                    </div>
                                    <el-select class="w-full"
                                               multiple
                                               filterable
                                               remote
                                               placeholder="Search Users"
                                               collapse-tags
                                               data-testid="team-add-users-select"
                                               v-model="selectedUsersToAdd">
                                        <el-option :key="user.id"
                                                   :label="user.full_name + ' - ' + user.email"
                                                   :value="user.id"
                                                   v-for="user in filteredUsersToAdd" />
                                    </el-select>
                                    <div class="d-flex justify-content-center mt-5 row">
                                        <el-button class="mb-2"
                                                   type="default"
                                                   slot="reference"
                                                   @click="closeAddUsersModal">
                                            Cancel
                                        </el-button>
                                        <el-button class="mb-2"
                                                   type="success"
                                                   slot="reference"
                                                   data-testid="team-add-users-button"
                                                   :disabled="isLoading || !selectedUsersToAdd.length"
                                                   @click="onAddUsers">
                                            Add Users
                                        </el-button>
                                    </div>
                                </el-dialog>

                                <el-dialog width="30%"
                                           center
                                           :visible.sync="isDeleteSupervisorsModalVisible">
                                    <div class="dialog--title mt-4"
                                         slot="title">
                                        {{ deleteSupervisorsModalConfig.title }}
                                    </div>
                                    <p class="break-word text-center"
                                       v-html="deleteSupervisorsModalConfig.description" />
                                    <div class="d-flex justify-content-center mt-5 row">
                                        <el-button class="mb-2"
                                                   type="default"
                                                   slot="reference"
                                                   @click="closeDeleteSupervisorsModal">
                                            No, Keep Supervisor
                                        </el-button>
                                        <el-button class="mb-2"
                                                   type="danger"
                                                   slot="reference"
                                                   data-testid="team-delete-supervisors-button"
                                                   :disabled="isLoading"
                                                   @click="onRemoveSupervisors">
                                            Yes
                                        </el-button>
                                    </div>
                                </el-dialog>

                                <el-dialog width="30%"
                                           center
                                           :visible.sync="isAddSupervisorsModalVisible"
                                           :loading="isLoading">
                                    <div class="dialog--title mt-4"
                                         slot="title">
                                        Add supervisors to: {{ team.name }}
                                        <team-supervisor-tooltip placement="bottom" />
                                    </div>
                                    <el-select class="w-full"
                                               multiple
                                               filterable
                                               remote
                                               placeholder="Search Supervisors"
                                               collapse-tags
                                               data-testid="team-add-supervisors-select"
                                               v-model="selectedSupervisorsToAdd">
                                        <el-option :key="user.id"
                                                   :label="user.full_name + ' - ' + user.email"
                                                   :value="user.id"
                                                   v-for="user in filteredSupervisorsToAdd" />
                                    </el-select>
                                    <div class="d-flex justify-content-center mt-5 row">
                                        <el-button class="mb-2"
                                                   type="default"
                                                   slot="reference"
                                                   @click="closeAddSupervisorsModal">
                                            Cancel
                                        </el-button>
                                        <el-button class="mb-2"
                                                   type="success"
                                                   slot="reference"
                                                   data-testid="team-add-supervisors-button"
                                                   :disabled="isLoading || !selectedSupervisorsToAdd.length"
                                                   @click="onAddSupervisors">
                                            Add Supervisors
                                        </el-button>
                                    </div>
                                </el-dialog>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="fixed-footer padding pb-0">
            <div class="form-group row mb-0 mt-0">
                <div class="col-12 d-none d-md-block p-2 edit-operations">
                    <el-button class="pull-left"
                              data-testid="back-button"
                              @click="$router.push({ name: 'Teams' })">
                        <i class="fa fa-arrow-left"></i>
                        Back
                    </el-button>
                    <el-button class="pull-right ml-2"
                              type="default"
                              :disabled="!hasChanges"
                              data-testid="cancel-team-button"
                              @click="resetFormData">
                        Cancel
                    </el-button>
                    <el-button class="pull-right ml-0"
                              type="success"
                              slot="reference"
                              :loading="isLoading"
                              :disabled="!hasChanges || !hasPermissionTo(CREATE_TEAM)"
                              data-testid="save-team-button"
                              @click="onSaveTeam">
                        {{ team?.id ? 'Save Changes' : 'Create Team' }}
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../../auth'
import { acl_mixin, styling_mixin } from '../../mixins'
import { mapState, mapActions } from 'vuex'
import * as AgentStatusLabels from '../../constants/agent-status-labels'
import * as AnswerTypes from '../../constants/answer-types'
import { COMPANY_ADMIN, COMPANY_SUPERVISOR } from '../../constants/roles'
import { ADD_SUPERVISOR_TO_TEAM, REMOVE_SUPERVISOR_FROM_TEAM, CREATE_TEAM } from '../../constants/permissions'
import TeamSupervisorTooltip from './team-supervisor-tooltip.vue'
import TeamRingGroupsTooltip from './team-ring-groups-tooltip.vue'

export default {
    name: 'TeamSettings',

    components: {
        TeamSupervisorTooltip,
        TeamRingGroupsTooltip
    },

    props: {
        selectedTeam: {
            type: Object,
            required: false,
            default: () => { return {} }
        }
    },

    mixins: [
        acl_mixin,
        styling_mixin
    ],

    data () {
        return {
            COMPANY_ADMIN,
            COMPANY_SUPERVISOR,
            ADD_SUPERVISOR_TO_TEAM,
            REMOVE_SUPERVISOR_FROM_TEAM,
            CREATE_TEAM,
            auth: auth,
            team: {},
            isLoading: false,
            searchUsers: '',
            searchSupervisors: '',
            selectedUsersToAdd: [],
            searchUsersToAddQuery: '',
            searchRingGroups: '',
            selectedUsers: [],
            selectedSupervisors: [],
            selectedSupervisorsToAdd: [],
            isDeleteUsersModalVisible: false,
            isAddUsersModalVisible: false,
            isAddSupervisorsModalVisible: false,
            isDeleteSupervisorsModalVisible: false,
            activeTabName: 'general',
            pagination: {
                page: 1,
                size: 20,
                paginate: true
            },
            AnswerTypes,
            AgentStatusLabels,
            teamForm: {
                name: '',
                description: '',
                users_ids: [],
                supervisors_ids: []
            },
            rules: {
                name: [
                    { required: true, message: 'Team Name is required', trigger: 'blur' }
                ]
            }
        }
    },

    computed: {
        ...mapState(['users']),

        hasChanges() {
            if (!this.team?.id) return true; // Allow button to be active for new teams
            return this.changedName || this.changedDescription
        },

        changedName () {
            return this.teamForm.name !== this.team.name
        },

        changedDescription () {
            return this.team.description === null ?
                        this.teamForm.description.length > 0 :
                        this.teamForm.description !== this.team.description
        },
                
        deleteUsersModalConfig () {
            const userName = this.selectedUsers[0] ? this.selectedUsers[0].full_name : ''
            return {
                title: `Removing user from the team`,
                description: `Do you want to remove <strong>${userName}</strong> from this team?` 
            }
        },

        deleteSupervisorsModalConfig () {
            const supervisorName = this.selectedSupervisors[0] ? this.selectedSupervisors[0].full_name : ''
            return {
                title: `Removing supervisor from the team`,
                description: `Do you want to remove <strong>${supervisorName}</strong> from this team?`
            }
        },
        addUsersModalConfig () {
            return {
                title: `Add users to: ${this.team.name}`
            }
        },

        filteredUsers () {
            if (!this.team?.users) return []

            return this.team.users.map(teamUser => {
                // Find the full user data from the store
                const fullUserData = this.users.find(u => u.id === teamUser.id) || {}

                // Merge team user data with store user data
                return {
                    ...teamUser,
                    ...fullUserData,
                    // Ensure these properties are present
                    agent_status: fullUserData.agent_status || 'offline',
                    name: fullUserData.name || teamUser.full_name,
                    is_destination: fullUserData.is_destination || false,
                    role_names: fullUserData.role_names || [],
                    read_only_access: fullUserData.read_only_access || false
                }
            }).filter(user => {
                if (!this.searchUsers) return true
                return user.full_name.toLowerCase().includes(this.searchUsers.toLowerCase()) ||
                       user.email?.toLowerCase().includes(this.searchUsers.toLowerCase()) ||
                       user.extension?.toLowerCase().includes(this.searchUsers.toLowerCase())
            })
        },

        filteredUsersToAdd () {
            if (!this.users || !this.team?.users) return []
            
            // Discard extensions as possible users to be added to the team
            return this.users.filter(user =>
                !this.team.users.find(u => u.id === user.id) &&
                !user.is_destination
            )
        },

        filteredSupervisors () {
            if (!this.team?.supervisors) return []

            return this.team.supervisors.map(supervisor => {
                const fullUserData = this.users.find(u => u.id === supervisor.id) || {}
                return {
                    ...supervisor,
                    full_name: `${supervisor.first_name} ${supervisor.last_name}`,
                    email: fullUserData.email || null,
                    active: fullUserData.active || false,
                    role_names: fullUserData.role_names || []
                }
            }).filter(supervisor => {
                if (!this.searchSupervisors) return true
                return supervisor.full_name.toLowerCase().includes(this.searchSupervisors.toLowerCase()) ||
                       supervisor.email?.toLowerCase().includes(this.searchSupervisors.toLowerCase())
            })
        },

        filteredSupervisorsToAdd () {
            if (!this.users || !this.team?.supervisors) return []
            
            return this.users.filter(user =>
                !this.team.supervisors?.find(s => s.id === user.id) &&
                user.role_names?.includes('Company Supervisor')
            )
        },

        filteredRingGroups () {
            if (!this.team?.ring_groups) return []
            
            return this.team.ring_groups.filter(rg => {
                return rg.name.toLowerCase().includes(this.searchRingGroups.toLowerCase())
            })
        }
    },

    methods: {
        ...mapActions(['setCurrentTeam']),

        resetFormData() {
            this.teamForm = {
                name: this.team.name || '',
                description: this.team.description || '',
                users_ids: this.team.users?.map(user => user.id) || [],
                supervisors_ids: this.team.supervisors?.map(user => user.id) || []
            }
        },

        backToTeamsList () {
            this.$emit('back-to-teams-list')
        },

        handleRemoveUsersModal (user = null) {
            if (user) {
                this.cleanSelectedUsers()
                this.selectedUsers.push(user)
            }

            if (!this.selectedUsers.length) {
                return
            }

            this.isDeleteUsersModalVisible = true
        },

        handleRemoveSupervisorsModal (supervisor = null) {
            if (supervisor) {
                this.cleanSelectedSupervisors()
                this.selectedSupervisors.push(supervisor)
            }

            if (!this.selectedSupervisors.length) {
                return
            }

            this.isDeleteSupervisorsModalVisible = true
        },

        closeDeleteUsersModal () {
            this.cleanSelectedUsers()
            this.isDeleteUsersModalVisible = false
        },

        cleanSelectedUsers () {
            this.selectedUsers = []
            this.$refs['company-users-team-list'].clearSelection()
        },

        onDeleteUsers () {
            this.isLoading = true
            const users_ids = this.selectedUsers.map(user => user.id)

            this.$APIV2.Teams.removeUsers(this.team.id, { users_ids })
                .then(res => {
                    this.closeDeleteUsersModal()

                    this.$notify({
                        title: 'Success',
                        message: 'User removed from the team successfully.',
                        type: 'success',
                        showClose: true
                    })

                    this.selectedUsers = []
                    this.fetchTeam(this.team.id)
                })
                .catch(err => {
                    this.closeDeleteUsersModal()

                    this.$notify({
                        title: 'Error',
                        message: 'An error occurred while removing user from the team.',
                        type: 'error',
                        showClose: true
                    })

                    console.log(err)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },

        openAddUsersModal () {
            this.isAddUsersModalVisible = true
        },

        closeAddUsersModal () {
            this.isAddUsersModalVisible = false
            this.selectedUsersToAdd = []
        },

        onAddUsers () {
            this.isLoading = true
            const users_ids = [...this.selectedUsersToAdd]

            this.$APIV2.Teams.addUsers(this.team.id, { users_ids })
                .then(res => {
                    this.selectedUsersToAdd = []
                    this.closeAddUsersModal()
                    this.fetchTeam(this.team.id)

                    this.$notify({
                        title: 'Success',
                        message: 'User(s) added to the team successfully.',
                        type: 'success',
                        showClose: true
                    })
                })
                .catch(err => {
                    this.closeAddUsersModal()

                    this.$notify({
                        title: 'Error',
                        message: 'An error occurred while adding user(s) to the team.',
                        type: 'error',
                        showClose: true
                    })

                    console.log(err)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },

        fetchTeam (teamId) {
            if (!teamId) {
                this.team = {}
                this.teamForm = {
                    name: '',
                    description: '',
                    users_ids: [],
                    supervisors_ids: []
                }
                return
            }

            this.isLoading = true
            this.$APIV2.Teams.show(teamId)
                .then(res => {
                    this.team = res.data.data
                    this.resetFormData()
                    this.setCurrentTeam(this.team)
                })
                .catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },

        openAddSupervisorsModal () {
            this.isAddSupervisorsModalVisible = true
        },

        closeAddSupervisorsModal () {
            this.isAddSupervisorsModalVisible = false
            this.selectedSupervisorsToAdd = []
        },

        onAddSupervisors () {
            this.isLoading = true
            const supervisors_ids = [...this.selectedSupervisorsToAdd]

            this.$APIV2.Teams.addSupervisors(this.team.id, { supervisors_ids })
                .then(res => {
                    this.selectedSupervisorsToAdd = []
                    this.closeAddSupervisorsModal()
                    this.fetchTeam(this.team.id)

                    this.$notify({
                        title: 'Success',
                        message: 'Supervisor(s) added to the team successfully.',
                        type: 'success',
                        showClose: true
                    })
                })
                .catch(err => {
                    this.closeAddSupervisorsModal()

                    this.$notify({
                        title: 'Error',
                        message: 'An error occurred while adding supervisor(s) to the team.',
                        type: 'error',
                        showClose: true
                    })

                    console.log(err)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },

        onRemoveSupervisors () {
            this.isLoading = true
            const supervisors_ids = this.selectedSupervisors.map(supervisor => supervisor.id)

            this.$APIV2.Teams.removeSupervisors(this.team.id, { supervisors_ids })
                .then(res => {
                    this.closeDeleteSupervisorsModal()

                    this.$notify({
                        title: 'Success',
                        message: 'Supervisor removed from the team successfully.',
                        type: 'success',
                        showClose: true
                    })

                    this.selectedSupervisors = []
                    this.fetchTeam(this.team.id)
                })
                .catch(err => {
                    this.closeDeleteSupervisorsModal()

                    this.$notify({
                        title: 'Error',
                        message: 'An error occurred while removing supervisor from the team.',
                        type: 'error',
                        showClose: true
                    })

                    console.log(err)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },

        closeDeleteSupervisorsModal () {
            this.isDeleteSupervisorsModalVisible = false
            this.cleanSelectedSupervisors()
        },

        cleanSelectedSupervisors () {
            this.selectedSupervisors = []
            this.$refs['company-supervisors-team-list']?.clearSelection()
        },

        onSaveTeam() {
            this.$refs.teamFormRef.validate(valid => {
                if (valid) {
                    // Don't proceed if there are no changes
                    if (this.team?.id && !this.hasChanges) {
                        return;
                    }

                    this.isLoading = true
                    const apiCall = this.team?.id 
                        ? this.$APIV2.Teams.update(this.team.id, this.teamForm)
                        : this.$APIV2.Teams.store(this.teamForm)

                    apiCall.then(res => {
                        this.isLoading = false
                        const isNew = !this.team?.id
                        
                        this.$notify({
                            title: 'Success',
                            message: `Team ${isNew ? 'created' : 'updated'} successfully`,
                            type: 'success',
                            showClose: true
                        })

                        if (isNew && res.data?.data?.id) {
                            this.team = res.data.data
                            // Navigate to the team settings page with the new ID
                            this.$router.replace({ 
                                name: 'Team', 
                                params: { team_id: res.data.data.id }
                            })
                        } else {
                            this.fetchTeam(this.team.id)
                        }
                    }).catch(err => {
                        this.isLoading = false
                        if (err?.response?.data?.message) {
                            this.$notify({
                                title: 'Error',
                                message: err.response.data.message,
                                type: 'error',
                                showClose: true
                            })
                        } else {
                            this.$root.handleErrors(err.response)
                        }
                    })
                }
            })
        }
    },

    created () {
        if (this.$route.params.team_id) {
            this.fetchTeam(this.$route.params.team_id)
        }
    },

    watch: {
        selectedTeam: {
            handler(val) {
                this.team = val
                if (val?.id) {
                    this.resetFormData()
                }
            },
            immediate: true,
            deep: true
        }
    },

    beforeRouteEnter (to, from, next) {
        auth.check()
            .then((res) => {
                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({ name: 'Account Management Portal' })
                } else {
                    next(vm => {
                        if (!vm.hasRole(COMPANY_ADMIN) && !vm.hasRole(COMPANY_SUPERVISOR)) {
                            next({ name: 'Dashboard' })
                        }
                    })
                }
            })
            .catch(() => {
                next({ name: 'Login', query: { redirect: to.fullPath } })
            })
    },

    beforeRouteLeave (to, from, next) {
        this.setCurrentTeam({})
        next()
    }
}
</script>

<style scoped>
.fixed-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 10;
    border-top: 1px solid #ebeef5;
}

.edit-operations {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
