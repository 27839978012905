<template>
  <div v-loading="loading">
      <el-form  id="powerDialerSessionSettingForm"
                class="rounded d-flex flex-column"
                style="border-radius: 8px !important;"
                ref="powerDialerSessionSettingForm"
                :rules="form_rules"
                :model="setting_clone">
          <div class="d-flex flex-row justify-content-between mb-4">
              <el-form-item prop="name" >
                  <div class="d-flex flex-row">
                      <input placeholder="Untitled"
                             class="power-dialer-form-input"
                             style="border-radius: 5px;width: 102px"
                             type="text"
                             v-model="setting_clone.name"
                             @focus="show_edit_title_icon = false"
                             @blur="show_edit_title_icon = true"
                             @input="preValidateForm('powerDialerSessionSettingForm')">
                      <i class="material-icons">mode_edit</i>
                  </div>
              </el-form-item>
              <div class="d-flex flex-column " >Session Settings</div>
              <div class="d-flex flex-row">
                  <el-button class="alo-button"
                             style="font-size: 13px !important;height: 30px;border-radius: 5px !important;"
                             @click="cancel">
                      Cancel
                  </el-button>
                  <el-button class="alo-button"
                             style="font-size: 13px !important;height: 30px;border-radius: 5px !important;"
                             type="primary"
                             @click="submit">
                      Save
                  </el-button>
              </div>
          </div>
          <div class="mb-2">
              <b class="text-black" style="font-size: 14px">Basics</b>
          </div>
          <div class="d-flex flex-row mb-3">
                  <div class="d-flex flex-column w-50 mr-3">
                      <el-form-item prop="campaign_id">
                          <span class="mb-2 title-label">Line</span>
                          <campaign-selector  v-model="setting_clone.campaign_id"
                                              :value="session_setting ? session_setting.campaign_id : null"
                                              class="w-full"
                                              :clearable="true"
                                              @change="changeCampaign">
                          </campaign-selector>
                      </el-form-item>
                  </div>
              <div class="d-flex flex-column w-50">
                  <div class="mb-2">
                      <span class="mr-2 title-label">Skip Outside Daytime Hours</span>
                      <el-tooltip class="item"
                                  effect="dark"
                                  placement="top-start"
                                  width="300"
                                  v-if="true">
                          <div slot="content">
                              <div style="width: 300px">
                                  Skip Outside Daytime Hours: Enabling this will only call contacts within the Open & Closed Times defined in Power Dialer Settings. If a contact is outside the Open and Closd Times, the dialer will push the task to the bottom of the list (instead of failing it).
                              </div>
                          </div>
                          <i class="material-icons text-blue-5" style="cursor: pointer;font-size: 15px">info_outline</i>
                      </el-tooltip>
                  </div>
                  <el-switch :active-value="1" :inactive-value="0" v-model="setting_clone.skip_outside_daytime_hours"></el-switch>

                  <div class="my-2">
                    <span class="mr-2 title-label">Require Agent Redial</span>
                    <el-tooltip class="item"
                                effect="dark"
                                placement="top-start"
                                width="300">
                        <div slot="content">
                            <div style="width: 300px">
                                Require Agent Redial: By selecting this option, agents are required to redial a contact when they don't pick the call up.
                            </div>
                        </div>
                        <i class="material-icons text-blue-5" style="cursor: pointer;font-size: 15px">info_outline</i>
                    </el-tooltip>
                </div>
                <el-switch :active-value="1" :inactive-value="0" v-model="setting_clone.force_redial"></el-switch>
              </div>
          </div>
          <div class="d-flex flex-row mb-3">
              <div class="d-flex flex-column w-50 mr-3">
                  <el-form-item prop="warmup_period_in_seconds">
                      <span class="mr-2 title-label">
                          Warmup Period (seconds)
                      </span>
                      <el-tooltip class="item"
                                  effect="dark"
                                  placement="top-start"
                                  width="300"
                                  v-if="true">
                          <div slot="content">
                              <div style="width: 300px">
                                  Warmup Period: The amount of time given to the agent between calls. This allows the agent to prepare for the next call.
                              </div>
                          </div>
                          <i class="material-icons text-blue-5" style="cursor: pointer;font-size: 15px">info_outline</i>
                      </el-tooltip>
                      <el-select class="w-100"
                                 v-model="setting_clone.warmup_period_in_seconds"
                                 clearable>
                          <el-option v-for="duration in warm_up_durations"
                                     :value="duration"
                                     :key="duration">
                          </el-option>
                      </el-select>
                  </el-form-item>
              </div>
              <div class="d-flex flex-column w-50">
                  <el-form-item prop="script_id">
                      <span class="mb-2 title-label">Phone Scripts</span>
                      <el-select class="w-100"
                                 v-model="setting_clone.script_id"
                                 clearable>
                          <el-option v-for="script in scripts"
                                     :label="script.title"
                                     :value="script.id"
                                     :key="script.id">
                          </el-option>
                      </el-select>
                  </el-form-item>
              </div>
          </div>
          <div class="mb-2">
              <b class="text-black" style="font-size: 14px">Customizations</b>
          </div>
          <div class="d-flex flex-row mb-3">
              <div class="d-flex flex-column w-50 mr-3">
                  <span class="mb-2 title-label">
                      Set Call Disposition Shortcuts
                  </span>
                  <el-select v-model="setting_clone.call_disposition_ids" multiple>
                      <el-option v-for="call_disposition in call_dispositions"
                                 :label="call_disposition.name"
                                 :value="call_disposition.id"
                                 :key="call_disposition.id">
                          <i class="fa fa-bolt"
                             :style="{ color: call_disposition.color }"></i>
                          <span class="ml-2">{{ call_disposition.name }}</span>
                      </el-option>
                  </el-select>
              </div>
              <div class="d-flex flex-column w-50">
                  <span class="mb-2 title-label">
                      Set Contact Disposition Shortcuts
                  </span>
                  <el-select v-model="setting_clone.contact_disposition_ids" multiple>
                      <el-option v-for="disposition in disposition_statuses"
                                 :label="disposition.name"
                                 :value="disposition.id"
                                 :key="disposition.id">
                          <template v-if="disposition.is_external !== undefined">
                              <i v-if="disposition.is_external"
                                 :style="{ color: disposition.color }"
                                 class="material-icons">lock</i>
                              <i v-else
                                 :style="{ color: disposition.color }"
                                 class="material-icons">label</i>
                          </template>
                          <span>{{ disposition.name }}</span>
                      </el-option>
                  </el-select>
              </div>
          </div>
          <div class="d-flex flex-row mb-3">
              <div class="d-flex flex-column w-50 mr-3">
                  <span class="mb-2 title-label">
                      Set Session Metrics
                  </span>
                  <el-select v-model="setting_clone.metric_options"
                             multiple
                             :multiple-limit="4">
                      <el-option-group
                          v-for="metric_group in metric_options"
                          :key="metric_group.label"
                          :label="metric_group.label">
                          <el-option
                              v-for="item in metric_group.options"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                          </el-option>
                      </el-option-group>
                  </el-select>
              </div>
              <div class="w-50"></div>
          </div>
      </el-form>
  </div>
</template>
<script>
  import {mapState} from 'vuex'
  import {form_validation_mixin} from '../../../mixins'

  export default {
      mixins: [
          form_validation_mixin
      ],

      props: {
          session_setting_id: {
              required: false,
              type: Number
          },

          session_setting: {
              required: false,
          }
      },

      data() {
          return {
              loading: false,
              show_edit_title_icon: true,
              setting: {},
              setting_clone: {
                  campaign_id: null
              },
              warm_up_durations: [],
              metric_options: [],
              form_rules: {
                  name: {
                      required: true,
                      message: 'Session setting name is required.',
                      trigger: 'blur'
                  },
                  campaign_id: {
                      required: true,
                      message: 'Please select a line',
                      trigger: 'blur'
                  }
              },
          }
      },

      computed: {
          ...mapState(['disposition_statuses', 'call_dispositions', 'scripts']),

          mode() {
              if (this.setting_clone.id || this.session_setting_id) {
                  return 'update'
              }

              return 'create'
          }
      },

      mounted() {
          this.setSetting()
          this.loadWarmUpDurations()
          this.loadMetricOptions()
      },

      methods: {
          changeCampaign(campaign_id) {
              this.setting_clone.campaign_id = campaign_id

              this.preValidateForm('powerDialerSessionSettingForm')
          },

          loadMetricOptions() {
              this.loading = true
              axios.get('/api/v2/dialer-sessions/metrics/options')
                  .then(res => {
                      this.loading = false
                      this.metric_options = res.data
                  })
                  .catch(err => {
                      this.loading = false
                      this.$root.handleErrors(err.response)
                  })
          },

          loadWarmUpDurations() {
              this.loading = true
              axios.get('/api/v2/dialer-sessions/warmup-durations')
                  .then(res => {
                      this.loading = false
                      this.warm_up_durations = res.data
                  })
                  .catch(err => {
                      this.loading = false
                      this.$root.handleErrors(err.response)
                  })
          },

          setSetting() {
              if (this.session_setting) {
                    console.log('this.setting_clone = this.session_setting', this.session_setting)
                  this.setting_clone = this.session_setting
              } else if(this.session_setting_id) {
                  this.loadSetting()
              }
          },

          loadSetting() {
              this.loading = true
              axios.get('/api/v1/dialer-sessions/' + this.session_setting_id)
              .then(res => {
                  this.loading = false
                  this.setting_clone = res.data
              })
              .catch(err => {
                  this.loading = false
                  this.$root.handleErrors(err.response)
              })
          },

          submit() {
              this.preValidateForm('powerDialerSessionSettingForm')

              if (this.validateForm('powerDialerSessionSettingForm')) {
                  if (this.mode === 'create') {
                      this.create()
                  }
                  else if (this.mode === 'update') {
                      this.update()
                  }
              }
          },

          cancel() {
              this.setting_clone = {}
              this.setSetting()
              this.$emit('cancel')
          },

          create() {
              this.loading = true
              this.setting_clone.is_company_scope = true
              axios.post('/api/v2/dialer-sessions', this.setting_clone)
              .then(res => {
                  this.loading = false
                  this.setting_clone = res.data
                  this.$notify({
                      offset: 95,
                      title: 'Power Dialer Session',
                      message: 'Session setting created',
                      type: 'success',
                      showClose: true
                  })

                  this.$emit('success', res.data)
              })
              .catch(err => {
                  this.loading = false
                  this.$root.handleErrors(err.response)
              })
          },

          update() {
              this.loading = true
              axios.patch('/api/v2/dialer-sessions/' + this.setting_clone.id, this.setting_clone)
              .then(res => {
                  this.loading = false
                  this.setting = res.data
                  this.$notify({
                      offset: 95,
                      title: 'Power Dialer Session',
                      message: 'Session setting updated',
                      type: 'success',
                      showClose: true
                  })
              })
              .catch(err => {
                  this.loading = false
                  this.$root.handleErrors(err.response)
              })
          }
      },

      watch: {
          session_setting(val) {
              this.setting_clone = this.session_setting
          }
      }
  }
</script>