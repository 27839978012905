<template>
    <div class="row nav-padding">
        <div class="col-12">
            <div class="container-listing px-0">
                <div class="container-body">
                    <div class="container-listing-tools d-flex flex-wrap justify-content-between mb-1">
                        <div class="d-flex">
                            <alo-date-range-picker
                                v-model="date_range">
                            </alo-date-range-picker>
                            <el-button
                                size="medium"
                                @click="showAllTimeData">
                                Show All-time
                            </el-button>
                        </div>
                        <div class="dropdown">
                            <button
                                class="btn blue-500 dropdown-toggle"
                                @click="export_options = !export_options">
                                Export Summary
                            </button>
                            <div
                                class="dropdown-menu dropdown-menu-right"
                                :class="export_options ? 'show' : ''">
                                <a class="dropdown-item"
                                   @click.prevent="exportToCsv">
                                    <i class="fa fa-table"></i>
                                    CSV File
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-4">
                <company-usage-graph :date_range="date_range"></company-usage-graph>
            </div>

            <company-usage-logs
                :date_range="date_range">
            </company-usage-logs>
        </div>
    </div>
</template>

<script>
    import auth from "../../../auth"
    import CompanyUsageLogs from "./company-usage-logs"
    import AloDateRangePicker from "../../alo-date-range-picker"
    import CompanyUsageGraph from "./company-usage-graph"

    export default {
        name: "company-usage",
        components: {CompanyUsageGraph, AloDateRangePicker, CompanyUsageLogs},
        props: {
            company: {
                required: true,
                type: Object
            }
        },
        data() {
            return {
                export_options: false,
                auth: auth,
                filters: {},
                date_range: [
                    moment().startOf('month').format('YYYY-M-DD'),
                    moment().format('YYYY-M-DD')
                ],
            }
        },
        methods: {
            prepareFilters() {
                if (Array.isArray(this.date_range)) {
                    this.filters.from_date = this.date_range[0]
                    this.filters.to_date = this.date_range[1]
                } else {
                    this.filters.from_date = null
                    this.filters.to_date = null
                }
                this.filters.export_type = '.csv';
            },
            showAllTimeData() {
                this.date_range = null
            },
            exportToCsv() {
                this.prepareFilters()
                axios.get('/api/v1/reports/account-usage', {
                    params: this.filters
                }).then(() => {
                    this.export_options = !this.export_options;
                    this.$notify.info({
                        offset: 95,
                        title: 'Exports',
                        dangerouslyUseHTMLString: true,
                        message: `Your export is on its way. You can check the progress from the export page or <b class="text-dark-greenish pointer">click here</b>.`,
                        duration: 3000,
                        onClick: () => {
                            this.$router.push({name: 'Account', query: {tab: 'exports'}}).catch((err) => {})
                            this.$notify.closeAll()
                        }
                    });
                }).catch(err => {
                    this.$notify.error({
                        offset: 95,
                        title: 'Ops...',
                        dangerouslyUseHTMLString: true,
                        message: 'We something wrong, try to export this report again later.',
                        duration: 3000,
                    });
                })
            },

            preventSubmit() {
                return
            },
        }
    }
</script>

