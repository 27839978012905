export const ADD_CREDIT_CARD = 'add credit card'
export const ADD_USER_TO_TEAM = 'add user to team'
export const ADD_SUPERVISOR_TO_TEAM = 'add supervisor to team'
export const ANSWER_CALL = 'answer call'
export const ARCHIVE_CAMPAIGN = 'archive campaign'
export const ARCHIVE_COMMUNICATION = 'archive communication'
export const ARCHIVE_COMPANY = 'archive company'
export const ARCHIVE_CONTACT = 'archive contact'
export const ARCHIVE_CONTACT_FOLDER = 'archive contact folder'
export const ARCHIVE_CONTACT_LIST = 'archive contact list'
export const ARCHIVE_CONTACT_LIST_ITEM = 'archive contact list item'
export const ARCHIVE_FILTER = 'archive filter'
export const ARCHIVE_SCRIPT = 'archive script'
export const ARCHIVE_SMS_TEMPLATE = 'archive sms template'
export const ARCHIVE_USER = 'archive user'
export const BARGE_AND_WHISPER_ON_CALL = 'barge and whisper on call'
export const BULK_CREATE_CONTACT_LIST_ITEM = 'bulk create contact list item'
export const BUY_CREDITS = 'buy credits'
export const CAMPAIGN_ROUTING_TEST = 'campaign routing test'
export const CHANGE_AUTO_RECHARGE = 'change auto recharge'
export const CHANGE_CONTACT_OWNERSHIP = 'change contact ownership'
export const CHANGE_CURRENT_COMPANY = 'change current company'
export const CHANGE_INTEGRATION_SETTINGS_COMPANY = 'change integration settings company'
export const CHANGE_PLAN = 'change plan'
export const CREATE_BROADCAST_MESSAGE = 'create broadcast message'
export const CREATE_BROADCAST_RVM = 'create broadcast rvm'
export const CREATE_CAMPAIGN = 'create campaign'
export const CREATE_COMPANY = 'create company'
export const CREATE_CONTACT = 'create contact'
export const CREATE_CONTACT_FOLDER = 'create contact folder'
export const CREATE_CONTACT_LIST = 'create contact list'
export const CREATE_CONTACT_LIST_BOOKMARK = 'create contact list bookmark'
export const CREATE_CONTACT_LIST_ITEM = 'create contact list item'
export const CREATE_DISPOSITION_STATUS = 'create disposition status'
export const CREATE_FILTER = 'create filter'
export const CREATE_LEAD_SOURCE = 'create lead source'
export const CREATE_RING_GROUP = 'create ring group'
export const CREATE_SCRIPT = 'create script'
export const CREATE_SEQUENCE = 'create sequence'
export const CREATE_SMS_TEMPLATE = 'create sms template'
export const CREATE_TAG = 'create tag'
export const CREATE_TEAM = 'create team'
export const CREATE_USER = 'create user'
export const CREATE_WORKFLOW = 'create workflow'
export const DELETE_BROADCAST = 'delete broadcast'
export const DELETE_CONTACT_LIST_BOOKMARK = 'delete contact list bookmark'
export const DELETE_DISPOSITION_STATUS = 'delete disposition status'
export const DELETE_FILE_CAMPAIGN = 'delete file campaign'
export const DELETE_FILE_SEQUENCE = 'delete file sequence'
export const DELETE_LEAD_SOURCE = 'delete lead source'
export const DELETE_RING_GROUP = 'delete ring group'
export const DELETE_SEQUENCE = 'delete sequence'
export const DELETE_TAG = 'delete tag'
export const DELETE_TEAM = 'delete team'
export const DELETE_WORKFLOW = 'delete workflow'
export const DISPOSE_CONTACT = 'dispose contact'
export const GET_CONTACT_FOLDER_ACCESS_USERS = 'get contact folder access users'
export const IMPERSONATE_USER = 'impersonate user'
export const LIST_BROADCAST = 'list broadcast'
export const LIST_CAMPAIGN = 'list campaign'
export const LIST_COMMUNICATION = 'list communication'
export const LIST_COMPANY = 'list company'
export const LIST_CONTACT = 'list contact'
export const LIST_CONTACT_FOLDER = 'list contact folder'
export const LIST_CONTACT_LIST_BOOKMARK = 'list contact list bookmark'
export const LIST_CONTACT_LIST_ITEM = 'list contact list item'
export const LIST_DISPOSITION_STATUS = 'list disposition status'
export const LIST_FILTER = 'list filter'
export const LIST_LEAD_SOURCE = 'list lead source'
export const LIST_INSIGHTS = 'list insights'
export const LIST_REPORT = 'list report'
export const LIST_RING_GROUP = 'list ring group'
export const LIST_SCRIPT = 'list script'
export const LIST_SEQUENCE = 'list sequence'
export const LIST_SMS_TEMPLATE = 'list sms template'
export const LIST_TAG = 'list tag'
export const LIST_TEAM = 'list team'
export const LIST_USER = 'list user'
export const LIST_WORKFLOW = 'list workflow'
export const MAKE_CALL = 'make call'
export const MODIFY_CONTACT_RING_GROUPS = 'modify contact ring groups'
export const MOVE_CONTACT_FOLDER = 'move contact folder'
export const NOTE_COMMUNICATION = 'note communication'
export const REMOVE_USER_CONTACT_FOLDER_ACCESS = 'remove user contact folder access'
export const REMOVE_USER_FROM_TEAM = 'remove user from team'
export const REMOVE_SUPERVISOR_FROM_TEAM = 'remove supervisor from team'
export const RESTORE_CAMPAIGN = 'restore campaign'
export const RESTORE_COMMUNICATION = 'restore communication'
export const RESTORE_COMPANY = 'restore company'
export const RESTORE_CONTACT = 'restore contact'
export const RESTORE_FILTER = 'restore filter'
export const RESTORE_SCRIPT = 'restore script'
export const RESTORE_SMS_TEMPLATE = 'restore sms template'
export const RESTORE_USER = 'restore user'
export const SEE_CHARGEBEE_PORTAL = 'see chargebee portal'
export const SEE_INTEGRATION_DETAILS_COMPANY = 'see integration details company'
export const SEE_PLANS_PAGE = 'see plans page'
export const SEE_RESELLER_PAGE = 'see reseller page'
export const SEE_USAGE_PAGE = 'see usage page'
export const SEND_BULK_RVM = 'send bulk rvm'
export const SEND_BULK_SMS = 'send bulk sms'
export const SEND_FAX = 'send fax'
export const SEND_SMS = 'send sms'
export const SET_DEFAULT_FILTER_CAMPAIGN = 'set default filter campaign'
export const SET_DEFAULT_FILTER_COMPANY = 'set default filter company'
export const SHOW_CONTACT_LIST_BOOKMARK = 'show contact list bookmark'
export const STORE_USER_CONTACT_FOLDER_ACCESS = 'store user contact folder access'
export const TAG_COMMUNICATION = 'tag communication'
export const TAG_CONTACT = 'tag contact'
export const TOGGLE_ACTIVE_STATUS_CAMPAIGN = 'toggle active status campaign'
export const TOGGLE_ACTIVE_USER = 'toggle active user'
export const TOGGLE_BLOCK_CONTACT = 'toggle block contact'
export const UPDATE_BROADCAST = 'update broadcast'
export const UPDATE_CAMPAIGN = 'update campaign'
export const UPDATE_COMPANY = 'update company'
export const UPDATE_CONTACT = 'update contact'
export const UPDATE_CONTACT_FOLDER = 'update contact folder'
export const UPDATE_CONTACT_LIST = 'update contact list'
export const UPDATE_CONTACT_LIST_ITEM = 'update contact list item'
export const UPDATE_DISPOSITION_STATUS = 'update disposition status'
export const UPDATE_FILTER = 'update filter'
export const UPDATE_LEAD_SOURCE = 'update lead source'
export const UPDATE_PROFILE = 'update profile'
export const UPDATE_RING_GROUP = 'update ring group'
export const UPDATE_SCRIPT = 'update script'
export const UPDATE_SEQUENCE = 'update sequence'
export const UPDATE_SMS_TEMPLATE = 'update sms template'
export const UPDATE_TAG = 'update tag'
export const UPDATE_TEAM = 'update team'
export const UPDATE_USER = 'update user'
export const UPDATE_USER_CONTACT_FOLDER_ACCESS = 'update user contact folder access'
export const UPDATE_WORKFLOW = 'update workflow'
export const UPLOAD_FILE_CAMPAIGN = 'upload file campaign'
export const UPLOAD_FILE_SEQUENCE = 'upload file sequence'
export const VIEW_CAMPAIGN = 'view campaign'
export const VIEW_COMMUNICATION = 'view communication'
export const VIEW_COMPANY = 'view company'
export const VIEW_CONTACT = 'view contact'
export const VIEW_CONTACT_FOLDER = 'view contact folder'
export const VIEW_CONTACT_LIST = 'view contact list'
export const VIEW_CONTACT_LIST_ITEM = 'view contact list item'
export const VIEW_DISPOSITION_STATUS = 'view disposition status'
export const VIEW_FILTER = 'view filter'
export const VIEW_RING_GROUP = 'view ring group'
export const VIEW_SCRIPT = 'view script'
export const VIEW_SEQUENCE = 'view sequence'
export const VIEW_SMS_TEMPLATE = 'view sms template'
export const VIEW_TAG = 'view tag'
export const VIEW_UNASSIGNED_CONTACTS = 'view unassigned contacts'
export const VIEW_USER = 'view user'
export const VIEW_TEAM = 'view team'
export const VIEW_WORKFLOW = 'view workflow'
