var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(
          "el-form-item",
          { staticClass: "mb-2" },
          [
            _c("div", { staticClass: "form-label" }, [
              _c("h5", [_vm._v("Email Notification")]),
              _vm._v(" "),
              _c("small", [
                _vm._v(
                  "\n                    By enabling this, an email with the summary is sent at the end of each call.\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "switch-label d-flex align-items-center" },
              [
                _c("el-switch", {
                  attrs: { "active-color": "#00BF50" },
                  model: {
                    value: _vm.bot.post_call_notification,
                    callback: function ($$v) {
                      _vm.$set(_vm.bot, "post_call_notification", $$v)
                    },
                    expression: "bot.post_call_notification",
                  },
                }),
                _vm._v(" "),
                _c(
                  "small",
                  { staticClass: "font-weight-bold custom-toggle-label ml-2" },
                  [_vm._v("Enable email notification")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.bot.post_call_notification
              ? _c(
                  "el-radio-group",
                  {
                    staticClass: "w-100 pl-5",
                    model: {
                      value: _vm.bot.post_call_notification_mode,
                      callback: function ($$v) {
                        _vm.$set(_vm.bot, "post_call_notification_mode", $$v)
                      },
                      expression: "bot.post_call_notification_mode",
                    },
                  },
                  [
                    _c(
                      "el-radio",
                      {
                        staticClass: "w-100",
                        attrs: {
                          label:
                            _vm.AloAi.POST_CALL_NOTIFICATION_MODE_ALL_USERS,
                        },
                      },
                      [_c("small", [_vm._v("All users")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio",
                      {
                        staticClass: "w-100",
                        attrs: {
                          label:
                            _vm.AloAi.POST_CALL_NOTIFICATION_MODE_CONTACT_OWNER,
                        },
                      },
                      [_c("small", [_vm._v("Contact's Owner")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio",
                      {
                        staticClass: "w-100",
                        attrs: {
                          label:
                            _vm.AloAi
                              .POST_CALL_NOTIFICATION_MODE_SPECIFIC_USERS,
                        },
                      },
                      [_c("small", [_vm._v("Specific Users")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pt-1 pl-4 w-100" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              rules: [
                                {
                                  validator: _vm.validateSpecificUsers,
                                  trigger: "change",
                                },
                              ],
                              prop: "post_call_notification_users",
                            },
                          },
                          [
                            _c("user-selector", {
                              attrs: {
                                value: _vm.bot.post_call_notification_users,
                                disabled:
                                  _vm.bot.post_call_notification_mode !==
                                  _vm.AloAi
                                    .POST_CALL_NOTIFICATION_MODE_SPECIFIC_USERS,
                                "selected-users": _vm.selectedUsers,
                                hide_extensions: true,
                                multiple: "",
                                placeholder: "Select users to notify",
                              },
                              on: { change: _vm.changeUser },
                              model: {
                                value: _vm.bot.post_call_notification_users,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.bot,
                                    "post_call_notification_users",
                                    $$v
                                  )
                                },
                                expression: "bot.post_call_notification_users",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.bot.post_call_notification
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "mt-2 w-100",
                            attrs: {
                              rules: [
                                {
                                  validator: _vm.validateEmailNotification,
                                  trigger: ["change", "blur"],
                                },
                              ],
                              prop: "post_call_notification_emails",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center mb-1 custom-label",
                              },
                              [
                                _c("small", [
                                  _vm._v("Send to specific email addresses"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "ml-2",
                                    attrs: {
                                      disabled:
                                        !_vm.bot.post_call_notification_mode,
                                      effect: "dark",
                                      content:
                                        "Press 'Enter' to add multiple email addresses.",
                                      placement: "top",
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-info" })]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticClass: "w-100",
                                attrs: {
                                  disabled:
                                    !_vm.bot.post_call_notification_mode,
                                  multiple: "",
                                  filterable: "",
                                  "allow-create": "",
                                  "default-first-option": "",
                                  placeholder: "Enter email addresses",
                                },
                                on: { create: _vm.handleEmailCreate },
                                model: {
                                  value: _vm.bot.post_call_notification_emails,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.bot,
                                      "post_call_notification_emails",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "bot.post_call_notification_emails",
                                },
                              },
                              _vm._l(_vm.emailHistory, function (email, index) {
                                return _c("el-option", {
                                  key: "email-option-" + email + "-" + index,
                                  attrs: { label: email, value: email },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }