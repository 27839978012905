var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body", attrs: { id: "view" } },
    [
      _c("image-assets-preloader"),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.show_help,
            title: "The wallboard",
            top: "10vh",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_help = $event
            },
          },
        },
        [
          _c("p", { staticClass: "text-md _600 break-word" }, [
            _vm._v("PLACEHOLDER"),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("welcome-to-aloware"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "fixed-header padding pt-0 pb-0",
          style: { top: _vm.top_offset + 50 + "px" },
        },
        [
          _c("div", { staticClass: "row mb-0 mt-0" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-fill justify-content-between p-2" },
              [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("ring-group-dropdown", {
                      attrs: { ring_group_id: _vm.ring_group_id },
                    }),
                    _vm._v(" "),
                    _vm.ring_group_id
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn deep-orange p-x-md pull-right ml-2",
                            staticStyle: { height: "40px" },
                            on: {
                              click: function ($event) {
                                _vm.ring_group_id = null
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Reset Filters\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isTrial && !_vm.isSimpSocial
                  ? _c("video-modal-activator", { attrs: { size: "small" } })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("al-alert", { staticClass: "mt-5 pt-3", attrs: { dimisable: true } }, [
        _c("span", {
          staticClass: "mr-5 text-dark",
          domProps: { innerHTML: _vm._s(_vm.DEPRECATION_MESSAGE) },
        }),
      ]),
      _vm._v(" "),
      _vm.hasPermissionTo("list report")
        ? _c("div", { staticClass: "padding mt-1" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 p-0" },
                [
                  _c("wallboard-summary-report", {
                    attrs: { ring_group_id: _vm.ring_group_id },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermissionTo("list communication")
        ? _c(
            "div",
            { staticClass: "padding", attrs: { id: "queue_log_table" } },
            [
              _c("div", { staticClass: "row box" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "no-effect" }, [
                    _c(
                      "div",
                      { staticClass: "box-body" },
                      [
                        _c("queue-log-table", {
                          attrs: { ring_group_id: _vm.ring_group_id },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermissionTo("list communication")
        ? _c(
            "div",
            { staticClass: "padding", attrs: { id: "live_log_table" } },
            [
              _c("div", { staticClass: "row box" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "no-effect" }, [
                    _c(
                      "div",
                      { staticClass: "box-body" },
                      [
                        _c("live-log-table", {
                          attrs: { ring_group_id: _vm.ring_group_id },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermissionTo("list communication")
        ? _c(
            "div",
            { staticClass: "padding", attrs: { id: "parked_log_table" } },
            [
              _c("div", { staticClass: "row box" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "no-effect" }, [
                    _c(
                      "div",
                      { staticClass: "box-body" },
                      [
                        _c("parked-log-table", {
                          attrs: { ring_group_id: _vm.ring_group_id },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("el-backtop", {
        ref: "backtop",
        attrs: { target: "html", bottom: 20, right: 20 },
      }),
      _vm._v(" "),
      _vm.isTrial && !_vm.isSimpSocial
        ? _c("video-modal", {
            staticClass: "ml-auto",
            attrs: {
              title:
                "🚀 Mastering Aloware Wallboard: Elevate Your Contact Center Insights",
              "cookie-name": "wallboard",
              notes:
                "🚀 Amplify your insights with <strong>Aloware Wallboard!</strong> </br></br> 🌟 Visualize, track, and dominate. Dive into real-time data like never before. </br></br> Your dashboard revolution starts here!",
              "video-url":
                "https://www.youtube.com/embed/D2w_cctKdzo?si=yRBRRxGMSTqaO89f",
              "learn-more-link":
                "https://support.aloware.com/en/articles/9034191-introducing-aloware-wallboard-your-real-time-communication-metrics-dashboard",
              "has-activator-button": true,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }