var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      staticClass: "w-100 mb-3",
      attrs: {
        placeholder: "Select an instruction template",
        "popper-class": "template-select-dropdown",
      },
      on: { change: _vm.onTemplateChange },
      model: {
        value: _vm.selectedTemplate,
        callback: function ($$v) {
          _vm.selectedTemplate = $$v
        },
        expression: "selectedTemplate",
      },
    },
    [
      _c(
        "el-option",
        { key: 0, attrs: { label: "Custom Template", value: 0 } },
        [
          _c("div", { staticClass: "template-option" }, [
            _c("p", { staticClass: "font-weight-bold mb-0" }, [
              _vm._v("Custom Template"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "text-muted text-wrap" }, [
              _vm._v(
                "\n                Create your own custom instructions for your agent's unique requirements.\n            "
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.templates, function (template) {
        return _c(
          "el-option",
          {
            key: template.id,
            attrs: { label: template.name, value: template.id },
          },
          [
            _c("div", { staticClass: "template-option" }, [
              _c("p", { staticClass: "font-weight-bold mb-0" }, [
                _vm._v(_vm._s(template.name)),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "text-muted text-wrap" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.getShortDescription(template.description)) +
                    "\n            "
                ),
              ]),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }