export const PRE_SIGNUP_SUBMISSION_TYPES = {
    ssu: {
        id: 1,
        visible_name: 'Self-sign up'
    },
    admin: {
        id: 2,
        visible_name: 'Aloware'
    },
    reseller: {
        id: 3,
        visible_name: 'Partner'
    },
    platform_lead: {
        id: 4,
        visible_name: 'Platform lead'
    }
}

export const PRE_SIGNUP_STATUS_APPROVED = 'Approved';
export const PRE_SIGNUP_STATUS_REGISTERED = 'Registered';
export const PRE_SIGNUP_STATUS_REJECTED = 'Rejected';
export const PRE_SIGNUP_STATUS_INVITED = 'Invited';
export const PRE_SIGNUP_STATUS_EXPIRED = 'Expired';
