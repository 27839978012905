<template>
    <el-popover width="400"
                trigger="click"
                class="p-0"
                popper-class="variable-popover"
                :placement="popoverPlacement"
                @show="popoverShow">
        <div class="row">
            <div class="col-12">
                <el-select placeholder="Select disposition status"
                           data-testid="contact-disposition-select"
                           class="w-full"
                           clearable
                           filterable
                           :multiple="false"
                           v-model="dispositionStatusId"
                           @change="emitSelectedDisposition($event)">
                    <el-option :key="disposition_status.id + '-disp-' + index"
                               :label="disposition_status.name"
                               :value="disposition_status.id"
                               v-for="(disposition_status, index) in dispositionStatusesAlphabeticalOrder">
                        <template v-if="disposition_status.is_external !== undefined">
                            <i class="material-icons"
                               :style="{ color: disposition_status.color }"
                               v-if="disposition_status.is_external">
                               lock
                            </i>
                            <i class="material-icons" 
                               :style="{ color: disposition_status.color }"
                               v-else>
                               label
                            </i>
                        </template>
                        <span>
                            [{{ disposition_status.id }}] {{ disposition_status.name }}
                        </span>
                    </el-option>
                </el-select>
            </div>
        </div>
        <el-button id="dispositions-popover-trigger"
                   slot="reference"
                   size="mini"
                   circle
                   :class="variableBtnClass"
                   :disabled="disabled">
            <i :class="'text-warning ' + icon"></i>
        </el-button>
    </el-popover>
</template>

<script>
import {mapState} from 'vuex'

export default {
    props: {
        disabled: {
            required: false,
            default: false
        },
        popoverPlacement: {
            required: false,
            default: "bottom-start"
        },
        icon: {
            required: false,
            default: "fa fa-file"
        },
        variableBtnClass: {
            required: false,
            default: "border-0 bg-transparent"
        },
        selectedDisposition: {
            type: Object,
            required: false,
        },
    },

    data() {
        return {
            disposition: {},
            disposition_status_id: null,
        }
    },

    computed: {
        ...mapState(['disposition_statuses']),

        dispositionStatusId: {
            get: function() {
                return this.disposition_status_id
            },

            set: function(disposition_status_id) {
                this.disposition_status_id = disposition_status_id
            }
        },

        dispositionStatusesAlphabeticalOrder() {
            if (this.disposition_statuses) {
                let disposition_statuses = _.clone(this.disposition_statuses)
                    .sort((a, b) => {
                        let textA = a.name.toUpperCase()
                        let textB = b.name.toUpperCase()
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                    })

                return disposition_statuses
            }
            return []
        },
    },

    methods: {
        popoverShow() {
            if (this.$refs[('dispositions_dropdown')]) {
                this.$refs[('dispositions_dropdown')].focusToSelector()
            }
        },

        emitSelectedDisposition(id) {
            let disposition = this.disposition_statuses.find(disposition => disposition.id == id)
            this.$emit('disposition-selected', disposition)
        }
    },

    watch: {
        selectedDisposition() {
            this.disposition = this.selectedDisposition
            this.disposition_status_id = this.selectedDisposition.id
        }
    }
}
</script>
