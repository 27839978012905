<template>
    <base-tool
        :tool-name="tool.name"
        :tool-description="tool.description"
        @back="goBack"
    >
        <campaign-selector
            check_blocked_messaging
            is_automated
            :show_paused="false"
            @input="handleCampaignChange"
        />
    </base-tool>
</template>

<script>
import BaseTool from './base-tool.vue'
import CampaignSelector from '../../../campaign-selector'
import ToolMixin from './tool-mixin'

export default {
    name: 'TransferTool',

    mixins: [ToolMixin],

    components: {
        BaseTool,
        CampaignSelector
    },

    props: {
        tool: {
            type: Object,
            required: true
        }
    },

    methods: {
        handleCampaignChange(campaignId) {
            if (!campaignId) {
                this.$emit('campaign-selected', null)
                return
            }

            this.$emit('campaign-selected', campaignId)
        }
    }
}
</script>
