<template>
    <div class="subscription-modal">
        <el-steps class="mb-4"
                  align-center
                  finish-status="success"
                  :active="currentStep"  >
            <el-step title="Plan Selection"/>
            <el-step title="Confirmation"/>
            <el-step title="Payment Successful"/>
        </el-steps>

        <div v-if="currentStep === 0">
            <el-form ref="subscriptionForm" :rules="rules">
                <div>
                    <el-form-item prop="selectedPlanIdentifier">
                        <div class="plans-header-container">
                            <div class="plans-header-section">
                                <h3>Startup Packages</h3>
                                <p class="section-subtitle">Simple, all-inclusive solution for up to 5 users</p>
                            </div>
                            <div class="plans-header-section">
                                <h3>Pro Plans + AI ✨</h3>
                                <p class="section-subtitle">Truly unlimited agent minutes, no long term contracts, no hidden fees.</p>
                            </div>
                        </div>
                        <div class="plan-cards-container">
                            <div class="plans-grid">
                                <!-- Startup Plans -->
                                <template v-for="plan in regularPlans">
                                    <div class="plan-card"
                                         :key="getPlanIdentifier(plan)"
                                         :class="{
                                             'plan-card-selected': getPlanIdentifier(plan) === selectedPlanIdentifier,
                                             'startup-plan': getPlanCategory(plan) === 'startup',
                                             'is-expanded': isExpanded(plan)
                                         }">
                                        <div class="plan-card-header flex-grow-1 d-flex flex-column">
                                            <h4 class="plan-name">{{ getDisplayName(plan) }}</h4>
                                            <div class="plan-price startup-price">
                                                <span class="amount">${{ plan.price }}</span>
                                                <template v-if="plan.included_users">
                                                    <span class="period"> / up to {{ plan.included_users }} users</span>
                                                </template>
                                            </div>
                                            <div class="period-text">per account per month</div>
                                            <div class="expand-section mt-auto">
                                                <button type="button"
                                                        class="expand-toggle-button" 
                                                        @click.stop="togglePlanExpansion(plan)"
                                                        :class="{ 'is-expanded': isExpanded(plan) }">
                                                    <span>{{ isExpanded(plan) ? 'Hide Features' : 'View Features' }}</span>
                                                    <i :class="isExpanded(plan) ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="plan-card-body"
                                             :class="{ 'is-expanded': isExpanded(plan) }">
                                            <div class="plan-features">
                                                <template v-if="planFeatures[getPlanIdentifier(plan)]">
                                                    <div v-for="(feature, index) in planFeatures[getPlanIdentifier(plan)]"
                                                         :key="index"
                                                         class="feature">
                                                        <i class="el-icon-check"></i>
                                                        <div class="feature-content" v-html="feature.text"></div>
                                                        <div v-if="feature.tooltip" class="tooltiptext">
                                                            <div class="tooltip-content">{{ feature.tooltip }}</div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                        <div class="plan-card-footer">
                                            <button type="button"
                                                    class="plan-select-button"
                                                    @click.prevent="selectPlan(plan)">
                                                Get {{ getDisplayName(plan) }}
                                            </button>
                                        </div>
                                    </div>
                                </template>

                                <!-- Pro Plans -->
                                <template v-for="groupedPlan in groupedPlans">
                                    <div class="plan-card"
                                         :key="groupedPlan.title"
                                         :class="{
                                             'plan-card-selected': isSelectedPlanGroup(groupedPlan),
                                             'most-popular': groupedPlan.title === 'xPro + AI ✨',
                                             'is-expanded': isExpanded(groupedPlan)
                                         }">
                                        <img v-if="groupedPlan.title === 'xPro + AI ✨'"
                                             src="/assets/images/ribbon.png"
                                             alt="Most Popular"
                                             class="ribbon-icon" />
                                        <div class="plan-card-header">
                                            <h4 class="plan-name">{{ groupedPlan.title }}</h4>
                                            <div class="plan-price">
                                                <span class="amount">${{ getSelectedVariantPrice(groupedPlan) }}</span>
                                                <span class="period">/ user</span>
                                            </div>
                                            <div class="period-text">minimum 5 users</div>
                                            <div class="billing-toggle mt-3">
                                                <span :class="{ 'active': isMonthlySelected(groupedPlan) }"
                                                      @click.stop="selectBillingPeriod(groupedPlan, 'monthly')">
                                                    Monthly
                                                </span>
                                                <span :class="{ 'active': !isMonthlySelected(groupedPlan) }"
                                                      @click.stop="selectBillingPeriod(groupedPlan, 'quarterly')">
                                                    Quarterly
                                                </span>
                                            </div>
                                            <div class="expand-section">
                                                <button type="button"
                                                        class="expand-toggle-button" 
                                                        @click.stop="togglePlanExpansion(groupedPlan)"
                                                        :class="{ 'is-expanded': isExpanded(groupedPlan) }">
                                                    <span>{{ isExpanded(groupedPlan) ? 'Hide Features' : 'View Features' }}</span>
                                                    <i :class="isExpanded(groupedPlan) ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="plan-card-body"
                                             :class="{ 'is-expanded': isExpanded(groupedPlan) }">
                                            <div class="plan-features">
                                                <template v-if="groupedPlan.title === 'uPro + AI ✨'">
                                                    <div v-for="(feature, index) in uProFeatures"
                                                         :key="index"
                                                         class="feature tooltip-container">
                                                        <i class="el-icon-check"></i>
                                                        <div class="feature-content" v-html="feature.text"></div>
                                                        <div class="tooltiptext" v-if="feature.tooltip">
                                                            <div class="tooltip-content">{{ feature.tooltip }}</div>
                                                        </div>
                                                    </div>
                                                </template>
                                                
                                                <template v-else-if="groupedPlan.title === 'xPro + AI ✨'">
                                                    <div v-for="(feature, index) in xProFeatures"
                                                         :key="index"
                                                         class="feature tooltip-container">
                                                        <i class="el-icon-check"></i>
                                                        <div class="feature-content" v-html="feature.text"></div>
                                                        <div class="tooltiptext" v-if="feature.tooltip">
                                                            <div class="tooltip-content">{{ feature.tooltip }}</div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                        <div class="plan-card-footer">
                                            <button type="button"
                                                    class="plan-select-button"
                                                    @click.prevent="selectBillingPeriod(groupedPlan, isMonthlySelected(groupedPlan) ? 'monthly' : 'quarterly', true)">
                                                Get {{ groupedPlan.title }}
                                            </button>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </el-form-item>
                </div>

                <div class="quantity-section mb-4"
                     ref="quantitySection"
                     v-if="!isAccountBasedPlan && selectedPlan">
                    <el-form-item prop="quantity_user_seat">
                        <label class="form-label">
                            <b>Number of Users</b>
                        </label>
                        <div class="quantity-controls">
                            <el-button type="default"
                                icon="el-icon-minus"
                                :disabled="quantity_user_seat <= minUserSeat"
                                @click="decrementUsers">
                            </el-button>
                            <el-input-number class="quantity-input"
                                :min="minUserSeat"
                                :max="maxUserSeat"
                                :controls="false"
                                v-model="quantity_user_seat"
                                @change="$refs.subscriptionForm.validateField('quantity_user_seat')"/>
                            <el-button type="default"
                                icon="el-icon-plus"
                                :disabled="quantity_user_seat >= maxUserSeat"
                                @click="incrementUsers">
                            </el-button>
                        </div>
                        <div class="quantity-info mt-2 text-muted"
                             v-if="selectedPlan">
                            {{ message }}
                        </div>
                    </el-form-item>
                </div>

                <div class="cost-summary"
                     v-if="selectedPlan">
                    <div class="d-flex justify-content-between align-items-center p-3 bg-light">
                        <div class="cost-label">
                            <h6 class="mb-0">Estimated Total</h6>
                            <small class="text-muted">
                                ${{ selectedPlan.price }} × {{ isAccountBasedPlan ? '1 account' : `${quantity_user_seat} users` }}
                            </small>
                        </div>
                        <div class="cost-amount">
                            <h5 class="mb-0">${{ totalCost }}</h5>
                            <small class="text-muted">
                              per {{ getTermPeriodName(selectedPlan.term).toLowerCase() }}
                            </small>
                        </div>
                    </div>
                </div>
            </el-form>
        </div>

        <!-- Step 2: Summary -->
        <div v-if="currentStep === 1">
            <div class="p-4 border">
            <h5 class="mb-3 text-center">Review Your Purchase</h5>

            <div class="order-details">
                <div class="detail-row mb-3">
                    <div class="detail-label">Selected Plan:</div>
                    <div class="detail-value fw-bold">{{ formatPlanName(selectedPlan.name) }}</div>
                </div>

                <div class="detail-row mb-3">
                    <div class="detail-label">{{ isAccountBasedPlan ? 'Number of Accounts:' : 'Number of Users:' }}</div>
                    <div class="detail-value fw-bold">{{ isAccountBasedPlan ? '1' : quantity_user_seat }}</div>
                </div>

                <div class="detail-row mb-3">
                    <div class="detail-label">{{ isAccountBasedPlan ? 'Price per Account:' : 'Price per User:' }}</div>
                    <div class="detail-value">${{ selectedPlan.price }}</div>
                </div>

                <div class="detail-row mb-3">
                    <div class="detail-label">Billing Frequency:</div>
                    <div class="detail-value">{{ getBillingFrequencyName(selectedPlan.term) }}</div>
                </div>

                <div class="total-cost p-3 mt-4 bg-light">
                    <div class="detail-row">
                        <div class="detail-label h6 mb-0">Total Amount:</div>
                        <div class="detail-value h5 mb-0 text-success">${{ totalCost }}</div>
                    </div>
                    <div class="text-muted small text-end">
                        per {{ getTermPeriodName(selectedPlan.term).toLowerCase() }}
                    </div>
                </div>
                <el-checkbox class="checkbox-md mt-2 q-pr-xs"
                             color="primary"
                             dense
                             v-model="agreed_to_terms">
                    <template slot="default">
                        I agree to
                        <a class="text-bluish _600"
                           :href="statics.link_terms_conditions"
                           target="_blank">
                            Terms and Conditions
                        </a>
                        and
                        <a class="text-bluish _600"
                           :href="statics.acceptable_use_policy"
                           target="_blank">
                            Acceptable Use Policy.
                        </a>
                    </template>
                </el-checkbox>
                <el-checkbox class="checkbox-md mt-2"
                             v-model="accepted_non_refundable_subscription">
                    By clicking "Confirm & Pay", I acknowledge that I will be charged immediately, and all purchases are final and non-refundable.
                </el-checkbox>
            </div>
          </div>
        </div>

        <!-- Step 3: Payment Successful -->
        <div class="payment-success"
             v-if="currentStep === 2">
            <div class="summary-container p-3 border">
                <h5 class="mb-3 text-success text-center ">Payment Successful</h5>

                <div class="mb-3">
                    <b class="mb-3">Subscription Details:</b>
                    <div class="ml-3 mt-3">
                        <div class="mb-2">Status: <span class="text-capitalize">{{ paymentResult.subscription.status }}</span></div>
                        <div class="mb-2">Billing Period: {{ paymentResult.subscription.billing_period }}
                          {{ paymentResult.subscription.billing_period_unit }}
                        </div>
                        <div class="mb-2">Start Date: {{ formatDate(paymentResult.subscription.started_at) }}</div>
                        <div class="mb-2">Next Billing: {{ formatDate(paymentResult.subscription.next_billing_at) }}</div>
                        <div class="mb-2">Plan Amount: ${{ (paymentResult.subscription.plan_amount / 100).toFixed(2) }}</div>
                    </div>
                </div>

                <div class="mb-3"
                     v-if="paymentResult.invoice">
                    <b class="mb-3">Invoice Details:</b>
                    <div class="ml-3 mt-3">
                        <div class="mb-2">Invoice Number: #{{ paymentResult.invoice.id }}</div>
                        <div class="mb-2">Amount Paid: ${{ (paymentResult.invoice.amount_paid / 100).toFixed(2) }}</div>
                        <div class="mb-2">Status: <span class="text-capitalize">{{ paymentResult.invoice.status }}</span></div>
                        <div class="mb-2">Payment Date: {{ formatDate(paymentResult.invoice.paid_at) }}</div>
                        <div class="mb-2">Payment Period: {{ formatPaymentPeriod(paymentResult.invoice) }}</div>
                    </div>
                </div>

                <div class="mb-3"
                     v-if="paymentResult.invoice?.billing_address">
                    <b>Billing Address:</b>
                    <div class="ml-3 mt-3">
                        <div class="mb-2">Billing Admin: {{ paymentResult.invoice.billing_address.first_name }}
                          {{ paymentResult.invoice.billing_address.last_name }}
                        </div>
                        <div class="mb-2">Company: {{ paymentResult.invoice.billing_address.company }}</div>
                    </div>
                </div>
          </div>

          <div class="text-center mt-4">
              <el-button type="primary"
                         @click="closeModal">
                Done
              </el-button>
          </div>
        </div>

        <div class="d-flex justify-content-between mt-4"
             v-if="currentStep < 2">
            <el-button type="info"
                v-if="currentStep > 0"
                @click="previousStep">
              Back
            </el-button>

            <div class="ml-auto">
                <el-button type="success"
                    :disabled="!isStep1Valid"
                    v-if="currentStep < 1"
                    @click="nextStep">
                  Next
                </el-button>

                <el-tooltip :disabled="!disablePayment"
                            :content="payment_disable_message"
                            v-else>
                    <el-button type="success"
                               :loading="loading"
                               :disabled="disablePayment"
                               @click="confirmSubscription">
                      Confirm & Pay
                    </el-button>
                </el-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import {acl_mixin, statics_mixin} from "../../mixins"
import {
    PLAN_TYPES,
    uProFeatures,
    xProFeatures,
    startupFeatures,
    MIN_LICENSES,
    MIN_USERS
} from './plan-features'

// Define constants outside the component
const SUBSCRIPTION_CONSTANTS = {
    MIN_STEP: 0,
    MAX_STEP: 2,
    DEFAULT_USER_SEAT: 1,
    INFINITE_USERS: Infinity
}

export default {
    name: 'SubscriptionModal',

    mixins: [
        acl_mixin,
        statics_mixin
    ],

    props: {
        visible: {
            type: Boolean,
            required: true,
            default: false
        }
    },

    data () {
        return {
            currentStep: SUBSCRIPTION_CONSTANTS.MIN_STEP,
            selectedPlanIdentifier: null,
            quantity_user_seat: SUBSCRIPTION_CONSTANTS.DEFAULT_USER_SEAT,
            loading: false,
            plans: [],
            minUserSeat: SUBSCRIPTION_CONSTANTS.DEFAULT_USER_SEAT,
            maxUserSeat: SUBSCRIPTION_CONSTANTS.INFINITE_USERS,
            minLicenses: MIN_LICENSES,
            minUsers: MIN_USERS,
            message: '',
            invalidQuantity: false,
            agreed_to_terms: false,
            accepted_non_refundable_subscription: false,
            payment_disable_message: '',
            paymentResult: null,
            selectedBillingPeriods: {},
            _memoized: {},
            rules: {
                selectedPlanIdentifier: [
                    {
                        validator: (rule, value, callback) => {
                            if (!this.selectedPlanIdentifier) {
                                callback(new Error('Please select a plan'))
                            } else {
                                callback()
                            }
                        },
                        trigger: ['change', 'blur']
                    }
                ],
                quantity_user_seat: [
                    {
                        validator: (rule, value, callback) => {
                            if (!this.selectedPlanIdentifier) {
                                callback(new Error('Please select a plan first'))
                                return
                            }

                            if (this.quantity_user_seat <= 0 || !Number.isInteger(this.quantity_user_seat)) {
                                callback(new Error('Please provide a valid quantity'))
                                return
                            }
                            
                            const isPro = !this.selectedPlan?.name?.startsWith('SU-');
                            const minRequired = isPro ? this.minUsers : 1;
                            
                            if (this.quantity_user_seat > this.maxUserSeat) {
                                callback(new Error(`Maximum of ${this.maxUserSeat} users`))
                            } else if (this.quantity_user_seat < minRequired) {
                                callback(new Error(`Minimum of ${minRequired} users`))
                            } else {
                                callback()
                            }
                        },
                        trigger: 'change'
                    }
                ],
            },
            expandedPlans: new Set(),
        }
    },

    created () {
        this.getPlans()
        this.getStatics()
    },

    computed: {
        isStep1Valid () {
            return this.selectedPlanIdentifier && !this.invalidQuantity
        },

        totalCost () {
            if (!this.selectedPlanIdentifier) {
              return
            }
            
            let cost = this.selectedPlan.price;
            if (!this.isAccountBasedPlan) {
                cost *= this.quantity_user_seat;
            }
            return cost.toFixed(2);
        },

        selectedPlan() {
            if (!this.selectedPlanIdentifier) return null;
            return this.plans.find(plan => this.getPlanIdentifier(plan) === this.selectedPlanIdentifier);
        },

        groupedPlans() {
            return this.memoize('groupedPlans', () => {
                // Group by plan name patterns instead of IDs
                const uProPlans = this.plans.filter(p => 
                    p.name.toLowerCase().includes('upro') && !p.name.startsWith('SU-')
                );
                const xProPlans = this.plans.filter(p => 
                    p.name.toLowerCase().includes('xpro')
                );

                const groups = [];

                // Group uPro plans
                const uProMonthly = uProPlans.find(p => p.term === 1);
                const uProQuarterly = uProPlans.find(p => p.term === 3);
                if (uProMonthly && uProQuarterly) {
                    groups.push({
                        title: 'uPro + AI ✨',
                        variants: [uProMonthly, uProQuarterly],
                        defaultPeriod: 'monthly'
                    });
                }

                // Group xPro plans
                const xProMonthly = xProPlans.find(p => p.term === 1);
                const xProQuarterly = xProPlans.find(p => p.term === 3);
                if (xProMonthly && xProQuarterly) {
                    groups.push({
                        title: 'xPro + AI ✨',
                        variants: [xProMonthly, xProQuarterly],
                        defaultPeriod: 'monthly'
                    });
                }

                return groups;
            });
        },

        regularPlans() {
            return this.memoize('regularPlans', () => {
                // Filter startup plans by name pattern
                return this.plans
                    .filter(plan => plan.name.startsWith('SU-'))
                    .sort((a, b) => a.price - b.price);
            });
        },

        uProFeatures() {
            return uProFeatures
        },
        
        xProFeatures() {
            return xProFeatures
        },

        startupFeatures() {
            return startupFeatures
        },

        isAccountBasedPlan() {
            // Check if it's a startup plan by name
            return this.selectedPlan?.name?.startsWith('SU-');
        },

        disablePayment() {
            if (!this.agreed_to_terms) {
              this.payment_disable_message = 'Accept terms and conditions first.'
            }

            if(!this.accepted_non_refundable_subscription){
              this.payment_disable_message = 'Accept the non-refundable purchase terms first.'
            }

            return !this.accepted_non_refundable_subscription || !this.agreed_to_terms
        },

        planFeatures() {
            return this.memoize('planFeatures', () => {
                const features = {};
                
                this.plans.forEach(plan => {
                    const identifier = this.getPlanIdentifier(plan);
                    if (plan.name.startsWith('SU-')) {
                        const featureKey = plan.included_users === 3 ? 
                            'startup-3' : 'startup-5';
                        features[identifier] = startupFeatures[featureKey];
                    } else if (plan.name.toLowerCase().includes('upro')) {
                        features[identifier] = uProFeatures;
                    } else if (plan.name.toLowerCase().includes('xpro')) {
                        features[identifier] = xProFeatures;
                    }
                });
                
                return features;
            });
        },
    },

    methods: {
        nextStep () {
            this.currentStep++
        },

        previousStep () {
            this.currentStep--
        },

        async getPlans() {
            await this.safeRequest(async () => {
                const res = await axios.get('/api/v2/plans/self-checkout')
                this.plans = res.data.data // Access the plans from the data property
                this.resetState()
            })
        },

        resetState() {
            this.currentStep = SUBSCRIPTION_CONSTANTS.MIN_STEP
            this.selectedPlanIdentifier = null
            this.quantity_user_seat = SUBSCRIPTION_CONSTANTS.DEFAULT_USER_SEAT
            this.minUserSeat = SUBSCRIPTION_CONSTANTS.DEFAULT_USER_SEAT
            this.maxUserSeat = SUBSCRIPTION_CONSTANTS.INFINITE_USERS
            this.message = ''
            this.paymentResult = null
            this.selectedBillingPeriods = {}
            this._memoized = {}
            this.agreed_to_terms = false
            this.accepted_non_refundable_subscription = false
        },

        selectPlan(plan) {
            event?.preventDefault()
            event?.stopPropagation()
            
            const isPro = !plan.name.startsWith('SU-')
            
            if (isPro) {
                this.minUserSeat = this.minUsers
                this.quantity_user_seat = this.minUsers
                this.maxUserSeat = Infinity
                this.message = `Minimum of ${this.minUsers} users`
            } else {
                this.minUserSeat = 1
                this.quantity_user_seat = 1
                this.maxUserSeat = plan.included_users
                this.message = `Maximum of ${plan.included_users} users`
            }
            
            this.selectedPlanIdentifier = this.getPlanIdentifier(plan)
            this.$refs.subscriptionForm?.validateField('selectedPlanIdentifier')
            this.$refs.subscriptionForm?.validateField('quantity_user_seat')
        },

        handlePlanChange() {
            if (!this.selectedPlan) return

            const isPro = !this.selectedPlan.name.startsWith('SU-')
            
            if (!isPro) {
                this.quantity_user_seat = 1
                this.minUserSeat = 1
                this.maxUserSeat = this.selectedPlan.included_users
                this.message = `Maximum of ${this.maxUserSeat} users`
            } else {
                this.minUserSeat = this.minUsers
                this.quantity_user_seat = this.minUsers
                this.maxUserSeat = Infinity
                this.message = `Minimum of ${this.minUsers} users`
            }

            this.$refs.subscriptionForm?.validateField('quantity_user_seat')
        },

        async confirmSubscription() {
            this.loading = true;
            
            try {
                const payload = {
                    plan_id: this.selectedPlan.id,
                    user_count: this.quantity_user_seat,
                    total_cost: this.totalCost,
                    accepted_non_refundable_subscription: this.accepted_non_refundable_subscription,
                    agreed_to_terms: this.agreed_to_terms,
                };

                const response = await this.safeRequest(() =>
                    axios.post('/api/v1/subscription/self-checkout', payload)
                );

                this.paymentResult = response.data;
                this.currentStep = 2;
                this.$message.success('Subscription confirmed successfully');
            } finally {
                this.loading = false;
            }
        },

        formatDate (timestamp) {
            if (!timestamp) return 'N/A'
            return new Date(timestamp * 1000).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            })
        },

        closeModal () {
            this.$emit('update:visible', false)

            window.location.reload()
        },

        incrementUsers () {
            if (this.quantity_user_seat < this.maxUserSeat) {
                this.quantity_user_seat++
            }
        },

        decrementUsers () {
            if (this.quantity_user_seat > this.minUserSeat) {
                this.quantity_user_seat--
            }
        },

        getLicenseInfo (plan) {
            if (plan.included_users > 0) {
                return `From 1 to ${plan.included_users} users`
            } else {
                return `Minimum ${this.minLicenses} users`
            }
        },

        isMonthlySelected(groupedPlan) {
            return this.selectedBillingPeriods[groupedPlan.title] !== 'quarterly'
        },

        selectBillingPeriod(groupedPlan, period) {
            // Prevent default behavior
            event?.preventDefault();
            event?.stopPropagation();
            
            // Update billing period
            this.$set(this.selectedBillingPeriods, groupedPlan.title, period);
            
            // Always select the plan when changing billing period
            const selectedVariant = groupedPlan.variants.find(v => 
                period === 'monthly' ? v.term === 1 : v.term === 3
            );
            if (selectedVariant) {
                // Set Pro plan values first before changing identifier
                this.minUserSeat = this.minUsers;
                this.quantity_user_seat = this.minUsers;
                this.message = `Minimum of ${this.minUsers} users`;
                
                // Then update the selected plan
                this.selectedPlanIdentifier = this.getPlanIdentifier(selectedVariant);
                this.$refs.subscriptionForm?.validateField('selectedPlanIdentifier');
                this.$refs.subscriptionForm?.validateField('quantity_user_seat');
            }
        },

        getSelectedVariantPrice(groupedPlan) {
            const selectedVariant = this.isMonthlySelected(groupedPlan) ?
                groupedPlan.variants[0] : groupedPlan.variants[1];
            return selectedVariant ? (selectedVariant.price / selectedVariant.term).toFixed(2) : 0;
        },

        getSelectedVariantDescription(groupedPlan) {
            const variant = this.isMonthlySelected(groupedPlan) ? 
                groupedPlan.variants[0] : groupedPlan.variants[1]
            return variant.description
        },

        isSelectedPlanGroup(groupedPlan) {
            return groupedPlan.variants.some(variant => 
                this.getPlanIdentifier(variant) === this.selectedPlanIdentifier
            );
        },

        scrollToQuantitySection() {
            // Wait for Vue to update the DOM
            this.$nextTick(() => {
                if (this.$refs.quantitySection) {
                    this.$refs.quantitySection.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }
            })
        },

        getMostPopularClass(groupedPlan) {
            return groupedPlan.title === 'xPro + AI ✨'
        },

        getDisplayName(plan) {
            if (plan.name.startsWith('SU-')) {
                return `${plan.included_users}-user package`;
            }
            return this.formatPlanName(plan.name);
        },

        getTermPeriodName(term) {
            switch (term) {
                case 1:
                    return 'Month'
                case 3:
                    return 'Quarter'
                case 12:
                    return 'Year'
                default:
                    return 'Month'
            }
        },

        getBillingFrequencyName(term) {
            switch (term) {
                case 1:
                    return 'Monthly'
                case 3:
                    return 'Quarterly'
                case 12:
                    return 'Yearly'
                default:
                    return 'Monthly'
            }
        },

        formatPaymentPeriod(invoice) {
            if (!invoice?.line_items?.length) return 'N/A'
            
            const lineItem = invoice.line_items[0]
            const startDate = new Date(lineItem.date_from * 1000)
            const endDate = new Date((lineItem.date_to - 86400) * 1000)
            
            // Format dates in UTC to ensure consistency across timezones
            const formatOptions = {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                timeZone: 'UTC'
            }

            return `${startDate.toLocaleDateString('en-US', formatOptions)} - ${endDate.toLocaleDateString('en-US', formatOptions)}`
        },

        formatPlanName(planName) {
            if (!planName) return '';
            return planName.replace(/\s*-\s*(Monthly|Quarterly|Yearly)$/, '');
        },

        getPlanCategory(plan) {
            if (plan.name.startsWith('SU-')) return 'startup';
            if (plan.name.toLowerCase().includes('upro')) return 'upro';
            if (plan.name.toLowerCase().includes('xpro')) return 'xpro';
            return 'other';
        },

        getPlanIdentifier(plan) {
            // Create a unique identifier based on plan characteristics
            return `${plan.name}-${plan.included_users}-${plan.term}`;
        },

        // Add error handling helper
        async safeRequest(requestFn) {
            try {
                return await requestFn()
            } catch (error) {
                console.error(error)
                this.$root.handleErrors(error.response)
                throw error
            }
        },

        // Add memoization helper
        memoize(key, fn) {
            if (!this._memoized) this._memoized = {}
            if (!this._memoized[key]) {
                this._memoized[key] = fn()
            }
            return this._memoized[key]
        },

        validateUserCount() {
            if (!this.selectedPlan) return;

            // Validate against plan limits
            if (this.quantity_user_seat < this.minUserSeat) {
                this.$refs.subscriptionForm?.validateField('quantity_user_seat');
                return false;
            }

            if (this.quantity_user_seat > this.maxUserSeat && this.maxUserSeat !== Infinity) {
                this.$refs.subscriptionForm?.validateField('quantity_user_seat');
                return false;
            }

            return true;
        },

        togglePlanExpansion(plan) {
            // Stop event propagation
            event?.stopPropagation();
            event?.preventDefault();
            
            const planKey = plan.title || this.getPlanIdentifier(plan);
            
            // Create a new Set to avoid mutation
            const newExpandedPlans = new Set(this.expandedPlans);
            
            if (newExpandedPlans.has(planKey)) {
                newExpandedPlans.delete(planKey);
            } else {
                newExpandedPlans.add(planKey);
            }
            
            // Replace the entire Set
            this.expandedPlans = newExpandedPlans;
        },

        isExpanded(plan) {
            const planKey = plan.title || this.getPlanIdentifier(plan);
            return this.expandedPlans.has(planKey);
        },
    },

    watch: {
        visible(newValue) {
            if (!newValue) {
                this.resetState();
            }
        },
        selectedPlanIdentifier(newValue) {
            if (newValue) {
                this.handlePlanChange();
            }
        },
        quantity_user_seat(newValue) {
            const isPro = !this.selectedPlan?.name?.startsWith('SU-');
            const minRequired = isPro ? this.minUsers : 1;
            this.invalidQuantity = newValue < minRequired || newValue > this.maxUserSeat || !Number.isInteger(newValue);
            
            if (isPro && newValue < this.minUsers) {
                this.quantity_user_seat = this.minUsers;
            }
        },
        // Clear memoization cache when plans change
        plans() {
            this._memoized = {};
        }
    }
}
</script>

<style scoped>
.subscription-modal {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 1.5rem;
}

.plans-header-container {
    display: none;
}

/* Show headers only when cards are in one row (large screens) */
@media (min-width: 1400px) {
    .plans-header-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 2rem;
        gap: 2rem;
        padding: 0 1rem;
    }

    .plans-header-section {
        flex: 1;
    }

    .plans-header-section h3 {
        font-size: 1.5rem;
        font-weight: 600;
        color: #1D2939;
        margin: 0 0 0.5rem 0;
        text-align: center;
    }

    .plans-header-section .section-subtitle {
        font-size: 0.875rem;
        color: #475467;
        margin: 0;
        line-height: 1.5;
        text-align: center;
    }
}

/* Hide headers when cards are in multiple rows */
@media (max-width: 1399px) {
    .plans-header-container {
        display: none;
    }
}

.plan-cards-container {
    display: block;
    margin: 0 1rem;
}

.plans-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 1.5rem;
    margin-top: 1rem;
}

.plan-card {
    position: relative;
    border: 1px solid #e4e7ec;
    border-radius: 12px;
    padding: 1.5rem;
    background: white;
    display: flex;
    flex-direction: column;
    min-width: 240px;
    width: 100%;
    transition: all 0.3s ease;
    height: fit-content;
    min-height: 418px;
    overflow: visible !important;
}

.plan-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.05);
    border-color: #409EFF;
}

.plan-card:not(.is-expanded) .plan-card-body {
    max-height: 0;
    opacity: 0;
    margin: 0;
    transform: translateY(-20px);
}

.plan-card-body {
    overflow: visible !important;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    max-height: 2000px;
    opacity: 0;
    transform: translateY(-20px);
    margin: 0;
    will-change: max-height, opacity, transform;
}

.plan-card-body.is-expanded {
    opacity: 1;
    transform: translateY(0);
    margin-top: 1.5rem;
}

.plan-card-selected {
    border: 2px solid #409EFF !important;
    background-color: #F6FAFF;
}

.plan-card-selected:hover {
    border-color: #409EFF !important;
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.05);
}

.plan-card-header {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #e4e7ec;
    text-align: center;
    position: relative;
}

.billing-toggle {
    display: inline-flex;
    background: #F2F4F7;
    border-radius: 20px;
    padding: 4px;
    gap: 4px;
    margin: 0 auto;
    user-select: none;
}

.billing-toggle span {
    cursor: pointer;
    padding: 0.375rem 1rem;
    border-radius: 20px;
    transition: all 0.2s ease;
    font-size: 0.65rem;
    color: #475467;
    min-width: 80px;
    text-align: center;
    font-weight: 500;
}

.billing-toggle span:hover {
    background: rgba(64, 158, 255, 0.08);
}

.billing-toggle span.active {
    background: white;
    color: #1D2939;
    font-weight: 600;
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1);
}

.plan-name {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1D2939;
    margin-bottom: 1rem;
}

.plan-price {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 0.25rem;
    margin-bottom: 0.5rem;
}

.plan-price .amount {
    font-size: 2rem;
    font-weight: 700;
    color: #101828;
    line-height: 1;
}

.plan-price .period {
    font-size: 1rem;
    color: #475467;
}

.period-text {
    font-size: 0.875rem;
    color: #475467;
    margin-top: 0.5rem;
}

.plan-features {
    position: relative;
    overflow: visible !important;
    display: flex;
    flex-direction: column;
    gap: 0.90rem;
    padding: 0;
    opacity: 0;
    transform: translateY(-10px);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
    will-change: opacity, transform;
    overflow-wrap: break-word;
    word-break: break-word;
}

.plan-card-body.is-expanded .plan-features {
    opacity: 1;
    transform: translateY(0);
    padding: 1rem 0;
}

.feature {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    font-size: 0.875rem;
    color: #475467;
    line-height: 1.5;
    position: relative;
    overflow-wrap: break-word;
    word-break: break-word;
}

.feature i {
    color: #409EFF;
    font-size: 1rem;
    flex-shrink: 0;
    margin-top: 0.2rem;
}

.feature-content {
    flex: 1;
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
}

/* Tooltip styles */
.tooltip-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.tooltip-container .tooltiptext {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 12px 16px;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 50%;
    transform: translate(-50%, -110%);
    min-width: 250px;
    max-width: 400px;
    font-size: 0.8125rem;
    opacity: 0;
    transition: all 0.2s ease;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: normal;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    pointer-events: none;
    line-height: 1.6;
    margin-bottom: 5px;
}

.tooltip-container .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
    margin-left: 0;
}

.tooltip-container:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tooltip-content {
    line-height: 1.6;
    color: #fff;
    font-weight: 400;
    padding: 0 4px;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.plan-card-footer {
    margin-top: auto;
    padding-top: 1rem;
    border-top: 1px solid #e4e7ec;
}

.plan-select-button {
    width: 100%;
    padding: 0.875rem 1.5rem;
    border-radius: 8px;
    border: 1px solid #101828;
    background-color: #101828;
    color: white;
    font-weight: 600;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
    z-index: 2;
}

.plan-select-button:hover {
    background-color: #1D2939;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.plan-card-selected .plan-select-button {
    background-color: #409EFF;
    border-color: #409EFF;
}

.expansion-indicator {
    display: none;
}

.quantity-controls {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.quantity-input {
    width: 120px;
}

.quantity-info {
    font-size: 0.9rem;
}

.cost-summary {
    border: 1px solid #dcdfe6;
    margin-top: 2rem;
}

.cost-amount {
    text-align: right;
}

@media (min-width: 1400px) {
    .plans-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 992px) and (max-width: 1399px) {
    .plans-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 991px) {
    .plans-grid {
        grid-template-columns: 1fr;
    }
}

.summary-container {
    background-color: #f8f9fa;
}

.payment-success {
    text-align: left;
}

.success-icon {
    color: #67C23A;
}

.text-capitalize {
    text-transform: capitalize;
}

.order-details {
    max-width: 500px;
    margin: 0 auto;
    word-break: break-word;
}

.detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.detail-label {
    color: #606266;
}

.detail-value {
    text-align: right;
}

.alert {
    background-color: #fdf6ec;
    color: #ec8b02;
    padding: 1rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.total-cost {
    border: 1px solid #dcdfe6;
}

.fw-bold {
    font-weight: 600;
}

.form-label{
  font-size: 15px;
}

.ribbon-icon {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 80px;
    height: 80px;
    z-index: 1;
    pointer-events: none;
}

.most-popular {
    padding-right: 2.25rem;
}

.plan-section {
    margin-bottom: 3rem;
}

.section-header {
    text-align: center;
    margin-bottom: 1.2rem;
}

.section-header h3 {
    font-size: 1.75rem;
    font-weight: 700;
    color: #1D2939;
    margin-bottom: 0.5rem;
    text-align: center;
}

.section-subtitle {
    color: #475467;
    font-size: 1rem;
    margin: 0;
    text-align: center;
    font-weight: 500;
}

.startup-price {
    margin-bottom: 0.5rem;
}

.minimum-users-note {
    font-size: 0.875rem;
    color: #475467;
    text-align: center;
    margin-top: 0.5rem;
}

.expand-section {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px dashed #e4e7ec;
}

.expand-toggle-button {
    background: #f9fafb;
    border: 1px solid #e4e7ec;
    border-radius: 6px;
    color: #475467;
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0.375rem 1.25rem;
    width: 80%;
    margin: 0 auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
    user-select: none;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    position: relative;
    z-index: 2;
}

.expand-toggle-button:hover {
    background: #f3f4f6;
    border-color: #d0d5dd;
    color: #101828;
    box-shadow: 0 2px 4px rgba(16, 24, 40, 0.1);
}

.expand-toggle-button:active,
.expand-toggle-button.is-expanded {
    background: #f3f4f6;
    border-color: #d0d5dd;
    color: #101828;
    transform: translateY(1px);
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.expand-toggle-button i {
    font-size: 0.75rem;
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
}

.expand-toggle-button.is-expanded i {
    transform: rotate(180deg);
}
</style>
