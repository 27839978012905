<template>
    <div class="app-body"
         id="view">
        <welcome-to-aloware></welcome-to-aloware>

        <div class="fixed-header padding pt-0 pb-0" :style="{top: (top_offset+50) + 'px'}">
            <div class="row mb-0 mt-0">
                <div class="col-12 p-2">
                    <div class="d-flex">
                        <div class="d-flex flex-fill">
                            <comm-advanced-filters-wrapper :no_exports="true"
                                                           :only_date_filter="!isNotReportingWithSubCategories()"
                                                           :report_category="sub_category_export">
                            </comm-advanced-filters-wrapper>
                            <div class="d-flex ml-auto">
                                <div v-on-clickaway="closeAllMenus">
                                    <button @click="toggleReportsMenu"
                                            class="report-settings-btn pull-left btn btn-sm greenish dropdown-toggle mr-2 ml-2">
                                        <i class="fa fa-cog"></i>
                                    </button>

                                    <div :class="reports_menu_visible ? 'show' : ''"
                                         @click="noClose($event)"
                                         class="reports-menu dropdown-menu mega-dropdown-menu dropdown-menu-overlay lean-bottom-margin bold-label left-align dropdown-menu-right no-select"
                                         style="width: 725px;">
                                        <div class="row nav-padding">
                                            <div class="col-12 d-flex flex-wrap">
                                                <div class="w-50 d-inline-block">
                                                    <span class="filter-header text-muted w-50">
                                                        My Reports
                                                    </span>
                                                </div>
                                                <div class="w-50 text-right">
                                                    <button @click="resetReportSettings"
                                                            class="btn btn-xs btn-danger mr-2">
                                                        Reset
                                                    </button>
                                                    <button :disabled="loading_reports_settings || !reportsHasChanges"
                                                            @click="updateReportsSettings($event, true)"
                                                            class="btn btn-xs greenish">
                                                        <i class="material-icons loader"
                                                           v-show="loading_reports_settings">&#xE863;</i>
                                                        Save
                                                    </button>
                                                </div>
                                                <div class="dropdown-divider break"></div>
                                            </div>
                                        </div>
                                        <div class="row pr-3">
                                            <div :key="category"
                                                 class="col-12 p-0"
                                                 v-for="(report, category) in reports"
                                                 v-if="report.show && !isNotReportingWithSubCategories(category)">
                                                <ul class="px-5"
                                                    :class="report.class">
                                                    <span class="filter-header text-muted mb-2">
                                                        {{ category.replace('_', ' ') | ucwords }}
                                                    </span>
                                                    <el-checkbox-group v-model="report.settings">
                                                        <el-checkbox :disabled="loading_reports_settings"
                                                                     :key="category + ' - ' + report_type.value"
                                                                     :label="report_type.value"
                                                                     class="w-50 mr-0"
                                                                     v-for="report_type in report.data"
                                                                     v-if="report_type.menu_show && report_type.active">
                                                            {{ report_type.label }}
                                                        </el-checkbox>
                                                    </el-checkbox-group>
                                                </ul>
                                            </div>
                                        </div>
                                        <!--<el-divider content-position="center">
                                            <span>The following reports will be retired on Sep 15th</span>
                                        </el-divider>
                                        <div class="row pr-3">
                                            <div :key="category"
                                                 class="col-4 p-0"
                                                 v-for="(report, category) in reports"
                                                 v-if="report.show && ['lines', 'callers', 'ring_groups'].includes(category)">
                                                <ul :class="report.class">
                                                    <span class="filter-header text-muted">
                                                        {{ category.replace('_', ' ') | ucwords }}
                                                    </span>
                                                    <el-checkbox-group v-model="report.settings">
                                                        <el-checkbox :disabled="loading_reports_settings"
                                                                     :key="category + ' - ' + report_type.value"
                                                                     :label="report_type.value"
                                                                     v-for="report_type in report.data"
                                                                     v-if="report_type.menu_show">
                                                            {{ report_type.label }}
                                                        </el-checkbox>
                                                    </el-checkbox-group>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="row pr-3">
                                            <div :key="category"
                                                 class="col-4 p-0"
                                                 v-for="(report, category) in reports"
                                                 v-if="report.show && ['users', 'performance', 'leads'].includes(category)">
                                                <ul :class="report.class">
                                                    <span class="filter-header text-muted">
                                                        {{ category.replace('_', ' ') | ucwords }}
                                                    </span>
                                                    <el-checkbox-group v-model="report.settings">
                                                        <el-checkbox :disabled="loading_reports_settings"
                                                                     :key="category + ' - ' + report_type.value"
                                                                     :label="report_type.value"
                                                                     v-for="report_type in report.data"
                                                                     v-if="report_type.menu_show">
                                                            {{ report_type.label }}
                                                        </el-checkbox>
                                                    </el-checkbox-group>
                                                </ul>
                                            </div>
                                        </div>-->
                                    </div>
                                </div>
                                <div class="ml-3 mt-2"
                                     v-if="isEmptyReports()">
                                    Please customize your report dashboard
                                </div>

                                <video-modal-activator class="pl-4"
                                                       v-if="isTrial && !isSimpSocial"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="padding mt-5 pt-3"
             style="position: relative; overflow: hidden;"
             v-for="(report, category) in reports"
             v-if="hasPermissionTo('list report') && !isEmptyReports() && report_category == category">
            <div class="row"
                 v-if="report_category !== 'activity_reporting'">
                <div class="col-12">
                    <el-alert class="big _400"
                              description="Please be advised that these reports will be retired on Sep 15th. Please use the new activity reports instead."
                              effect="dark"
                              show-icon
                              title="Note:"
                              type="error">
                    </el-alert>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-12 text-center">
                    <el-radio-group @change="changeSubCategory(false)"
                                    class="col-12 no-select pull-left mt-1"
                                    size="small"
                                    v-if="!isNotReportingWithSubCategories() && !isEmptyReports(report_category)"
                                    v-model="sub_category">
                        <el-radio-button :key="data.label + '-' + index"
                                         :label="data.label.replace('-', ' ') | ucwords"
                                         v-for="(data, index) in reports[report_category].data"
                                         v-if="auth.user.profile.reports_settings[report_category].includes(index) && data.active">
                        </el-radio-button>
                    </el-radio-group>
                </div>
            </div>

            <div v-for="(report_type, index) in report.data">
                <template
                    v-if="report_category == category && !auth.user.profile.lead_gen_tools_enabled  && category == 'performance' && [1,2].includes(index) && auth.user.profile.reports_settings[category].includes(index) && reports[category].queue[index].started">
                </template>
                <div class="row mt-3 mb-3"
                     v-else-if="report_category == category && auth.user.profile.reports_settings[category].includes(index) && reports[category].queue[index].started"
                     :class="{'box': (report_category != 'activity_reporting' || sub_category != 'Contacts')}">
                    <div :class="{'col-md-8': isNotReportingWithSubCategories()}"
                         class="col-12">
                        <div :class="{'box-body': (report_category != 'activity_reporting' && sub_category != 'Contacts')}">
                            <component :key="report_key"
                                       :base="!isNotReportingWithSubCategories() ? sub_category : report_type.base"
                                       :is="report_type.component"
                                       :report_index="index"
                                       :series_name="report_type.series_name"
                                       :stacked="report_type.stacked"
                                       @reportFiltersChanged="reportFiltersChanged"
                                       @reportLoaded="queueReports"/>
                        </div>
                    </div>
                    <reports-descriptions :category="category"
                                          :report_type_index="index"
                                          v-if="isNotReportingWithSubCategories()">
                    </reports-descriptions>
                </div>
            </div>

            <div class="csat-calls-panel" :class="{'csat-panel-enter': csat_calls_panel.enabled}">
                <h3>CSAT Score {{ csat_calls_panel.score }}</h3>
                <i @click="closeCsatCallsPanel" class="fa fa-close pull-left"></i>
                <div class="b-a p-2 rounded mb-2">
                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>Call</th>
                                <th>Talk Time</th>
                            </tr>
                        </thead>
                        <tbody v-if="!csat_calls_panel.loading">
                            <tr v-for="call in csat_calls_panel.calls.data" :key="call.id">
                                <td class="link"><a :href="'/communication/' + call.id" target="_blank">{{ call.campaign ? call.campaign.name : '-' }}</a></td>
                                <td><span class="talk-time">Talk time: {{ call.talk_time_formatted }}</span></td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr class="text-center">
                                <td colspan="2"><i class="material-icons loader mt-5" style="font-size: 42px;">&#xE863;</i></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="1" class="text-center">
                                    <el-button type="success"
                                            size="small"
                                            @click="setCsatCallsPanelCalls(csat_calls_panel.calls.prev_page_url)"
                                            :disabled="!csat_calls_panel.calls.prev_page_url || csat_calls_panel.loading"
                                            icon="el-icon-arrow-left">
                                        Prev Page
                                    </el-button>
                                </td>
                                <td colspan="1" class="text-center">
                                    <el-button type="success"
                                            size="small"
                                            @click="setCsatCallsPanelCalls(csat_calls_panel.calls.next_page_url)"
                                            :disabled="!csat_calls_panel.calls.next_page_url || csat_calls_panel.loading">
                                        Next Page
                                        <i class="el-icon-arrow-right"></i>
                                    </el-button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>

        <el-backtop target="html"
                    ref="backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>

        <video-modal class="pl-2"
                     title="📊 Mastering Aloware Reporting: Data-Driven Insights"
                     cookie-name="reports"
                     notes="🔥 Power through data with the <strong>Reporting Feature!</strong> </br></br> 📊 Dive deep into metrics, uncover hidden patterns, and strategize with precision. </br></br> Elevate your data narrative and shine in the analytics arena now! 💥📈"
                     video-url="https://www.youtube.com/embed/l0d2odTjHWU?si=alANQbZXsIVe4XMV"
                     learn-more-link="https://support.aloware.com/en/articles/9033817-generating-reports-in-aloware"
                     :has-activator-button="true"
                     v-if="isTrial && !isSimpSocial"/>
    </div>
</template>

<script>
import auth from '../../auth'
import {mapActions, mapGetters, mapState} from 'vuex'
import {mixin as clickaway} from 'vue-clickaway'
import {
    acl_mixin,
    date_mixin,
    scroll_mixin,
    styling_mixin
} from '../../mixins'
import ReportsOverviewPieGraph from "../../components/reports-overview-pie-graph"
import ReportsTagsColumnGraph from "../../components/reports-tags-column-graph"
import ReportsStatusColumnGraph from "../../components/reports-status-column-graph"
import ReportsTalktimeColumnGraph from "../../components/reports-talktime-column-graph"
import ReportsAvgTalktimeColumnGraph from "../../components/reports-avg-talktime-column-graph"
import ReportsIncomingNumberLocationGraph from "../../components/reports-incoming-number-location-graph"
import ReportsTalktimeColumnInverseGraph from "../../components/reports-talktime-column-inverse-graph"
import ReportsCallTimePerCampaignGraph from "../../components/reports-call-time-per-campaign-graph"
import ReportsCallerLocationGraph from "../../components/reports-caller-location-graph"
import ReportsUserLocationGraph from "../../components/reports-user-location-graph"
import ReportsAgentStatusGraph from "../../components/reports-agent-status-graph"
import ReportsAverageWaittimeColumnGraph from "../../components/reports-average-waittime-column-graph"
import ReportsLeadsIntakeGraph from "../../components/reports-leads-intake-graph"
import ReportsLeadsCreatedVsDncGraph from "../../components/reports-leads-created-vs-dnc-graph"
import ReportsActivityReportingTab from "../../components/reports-activity-reporting-tab"
import VideoModal from '../../components/video-modal.vue'
import VideoModalActivator from '../../components/video-modal-activator.vue'

export default {
    mixins: [
        styling_mixin,
        date_mixin,
        acl_mixin,
        scroll_mixin,
        clickaway
    ],

    components: {
        ReportsOverviewPieGraph,
        ReportsTagsColumnGraph,
        ReportsStatusColumnGraph,
        ReportsTalktimeColumnGraph,
        ReportsAvgTalktimeColumnGraph,
        ReportsIncomingNumberLocationGraph,
        ReportsTalktimeColumnInverseGraph,
        ReportsCallTimePerCampaignGraph,
        ReportsCallerLocationGraph,
        ReportsUserLocationGraph,
        ReportsAgentStatusGraph,
        ReportsAverageWaittimeColumnGraph,
        ReportsLeadsIntakeGraph,
        ReportsLeadsCreatedVsDncGraph,
        ReportsActivityReportingTab,
        VideoModal,
        VideoModalActivator
    },

    props: {
        report_category: {
            type: String,
            default: 'activity_reporting'
        }
    },

    data() {
        return {
            auth: auth,
            report: null,
            env: null,
            reports_menu_visible: false,
            reports: {
                activity_reporting: {
                    data: [
                        {
                            label: "Lines",
                            component: 'ReportsActivityReportingTab',
                            base: 'line',
                            stacked: null,
                            series_name: null,
                            active: true
                        },
                        {
                            label: "Ring Groups",
                            component: 'ReportsActivityReportingTab',
                            base: 'ring-group',
                            stacked: null,
                            series_name: null,
                            active: true
                        },
                        {
                            label: "Users",
                            component: 'ReportsActivityReportingTab',
                            base: 'user',
                            stacked: null,
                            series_name: null,
                            active: true
                        },
                        {
                            label: "Sequences",
                            component: 'ReportsActivityReportingTab',
                            base: 'workflow',
                            stacked: null,
                            series_name: null,
                            active: true
                        },
                        {
                            label: "Broadcasts",
                            component: 'ReportsActivityReportingTab',
                            base: 'broadcasts',
                            stacked: null,
                            series_name: null,
                            active: true
                        },
                        {
                            label: "Call Dispositions",
                            component: 'ReportsActivityReportingTab',
                            base: 'call_dispositions',
                            stacked: null,
                            series_name: null,
                            active: true
                        },
                        {
                            label: "Contact Dispositions",
                            component: 'ReportsActivityReportingTab',
                            base: 'contact_dispositions',
                            stacked: null,
                            series_name: null,
                            active: true
                        },
                        {
                            label: "CSAT",
                            component: 'ReportsActivityReportingTab',
                            base: 'csat',
                            stacked: null,
                            series_name: null,
                            active: true
                        },
                    ],
                    settings: [],
                    queue: [],
                    show: false,
                    class: {}
                }
            },
            loading_reports_settings: false,
            disable_clickaway: false,
            reports_loading: false,
            sub_category: 'Lines',
            sub_category_export: 'lines_activity_reporting',
            whitelabel: false,
            loading_whitelabel: true,
            report_key: 0
        }
    },

    computed: {
        ...mapGetters({
            filter: 'getFilter',
        }),
        ...mapState('cache', ['current_company']),
        ...mapState(['csat_calls_panel']),
        ...mapGetters('cache', ['isTrial', 'isSimpSocial']),

        reportsHasChanges() {
            if (this.auth.user && this.auth.user.profile) {
                // we loop through the default reports if there are changes
                for (let index in this.reports) {
                    // the current user reports settings
                    let user_reports_settings = []
                    if (this.auth.user.profile.reports_settings && typeof this.auth.user.profile.reports_settings[index] !== 'undefined') {
                        user_reports_settings = auth.user.profile.reports_settings[index]
                    }
                    // the new reports settings where changes are added
                    let changed_reports_settings = this.reports[index].settings
                    if (JSON.stringify(user_reports_settings) !== JSON.stringify(changed_reports_settings)) {
                        return true
                    }
                }
            }
            return false
        }
    },

    beforeCreate() {
        axios.get('/build-info.json')
            .then(res => {
                this.env = res.data.env
            })
            .catch(err => {
                console.log(err)
            })
    },

    created() {
        this.getWhitelabelStatus()
        this.initializeReports()
        this.switchReport()
        this.toFirstCategoryOrIndex(true)
    },

    mounted() {
        this.setTitle()
        if (!this.hasPermissionTo('list report')) {
            this.goBack()
        }
        this.resetOurFilters()

        this.reports_loading = true
        this.loadReports()
    },

    updated() {
        if (this.isEmptyReports(this.report_category) && this.auth.user && this.auth.user.profile && this.report.settings == this.auth.user.profile.reports_settings) {
            this.initializeReports()
            this.toFirstCategoryOrIndex()
            this.queueReports()
            this.reports_loading = true
            this.loadReports()
        }
        this.switchReport()
    },

    methods: {
        initializeReports() {
            if (!this.auth.user || !this.auth.user.profile) {
                return false
            }
            // set default values on our reports
            if (this.auth.user.profile.reports_settings === null) {
                this.auth.user.profile.reports_settings = {}
            }
            for (let index in this.reports) {
                if (!this.auth.user.profile.reports_settings[index]) {
                    this.auth.user.profile.reports_settings[index] = []
                }
                if (this.auth.user.profile.reports_settings[index]) {
                    this.auth.user.profile.reports_settings[index].sort()
                }
                this.$set(
                    this.reports[index],
                    'settings',
                    _.clone(this.auth.user.profile.reports_settings[index])
                )
                if (!this.reports[index].settings) {
                    this.reports[index].settings = []
                }
                this.reports[index].show = true
                for (let report_index in this.reports[index].data) {
                    this.reports[index].data[report_index].value = parseInt(report_index)
                    this.reports[index].data[report_index].menu_show = true
                    this.reports[index].queue[report_index] = {
                        started: false,
                        loaded: false
                    }
                }
            }

            // we do not show some reports on some user roles
            /*this.reports.ring_groups.show = true
            this.reports.users.show = true
            this.reports.performance.show = true
            this.reports.leads.show = true

            // specific conditions for showing these report types in the menu and graph
            this.reports.performance.data[1].menu_show = this.auth.user.profile.lead_gen_tools_enabled
            this.reports.performance.data[2].menu_show = this.auth.user.profile.lead_gen_tools_enabled
            this.reports.performance.data[3].menu_show = false
            this.reports.performance.data[4].menu_show = false*/
        },

        isEmptyReports(category = null) {
            // check if reports settings is not null
            if (!this.auth.user || !this.auth.user.profile || !this.auth.user.profile.reports_settings) {
                return true
            }
            // check if specific category exists and has active reports
            if (category && typeof this.auth.user.profile.reports_settings[category] !== 'undefined' && this.auth.user.profile.reports_settings[category].length) {
                return false
            }
            // for general reports check, return false if 1 category exists and has active reports
            if (!category) {
                for (let index in this.reports) {
                    if (typeof this.auth.user.profile.reports_settings[index] !== 'undefined' && this.auth.user.profile.reports_settings[index].length > 0) {
                        return false
                    }
                }
            }
            // empty reports
            return true
        },

        isNotReportingWithSubCategories(category = null) {
            category = !category ? this.report_category : category
            return category != 'activity_reporting'
        },

        loadReports() {
            if (this.isEmptyReports() || typeof this.reports[this.report_category] === 'undefined') {
                return
            }
            // sort so the settings queuing of reports are in correct order
            this.reports[this.report_category].settings.sort()
            // initiate the queueing of reports
            const reports_settings_first_enabled = parseInt(this.reports[this.report_category].settings[0])
            if (typeof reports_settings_first_enabled === 'number' &&
                this.reports[this.report_category].queue[reports_settings_first_enabled]) {
                this.reports[this.report_category].queue[reports_settings_first_enabled].started = true
            }
        },

        resetReportsQueues() {
            for (let category_index in this.reports) {
                for (let queue_index in this.reports[category_index].queue) {
                    this.reports[category_index].queue[queue_index] = {
                        started: false,
                        loaded: false
                    }
                }
            }
        },

        switchReport() {
            switch (this.report_category) {
                case 'lines':
                    this.report = 'Lines'
                    break
                case 'ring_groups':
                    this.report = 'Ring Groups'
                    break
                case 'callers':
                    this.report = 'Callers'
                    break
                case 'users':
                    this.report = 'Users'
                    break
                case 'performance':
                    this.report = 'Performance'
                    break
                case 'leads':
                    this.report = 'Leads'
                    break
                case 'activity_reporting':
                    if (this.hasRole('Company Seller')) {
                        this.goBack()
                    }
                    // if previous report was not activity reporting,
                    // determine the sub category and navigate to it
                    if (this.report != 'Activity Reporting') {
                        this.toFirstActivityReportingSubCategory()
                        this.changeSubCategory(true)
                    }
                    this.report = 'Activity Reporting'
                    break
            }
        },

        toFirstCategoryOrIndex(update_sub_category) {
            // check if all reports are empty, go back to reports index
            // or else, navigate to the first identified report category
            let first_non_empty_key = null
            let non_empty_reports_count = 0
            for (let index in this.reports) {
                if (this.reports[index].settings.length) {
                    non_empty_reports_count++
                    // identify the first report category and navigate to its path
                    first_non_empty_key = index
                    break
                }
            }

            // if there is no available report, go to index
            if (non_empty_reports_count == 0) {
                this.$router.push({
                    path: '/reports'
                }).catch(err => {
                })
                return
            }

            // if there's no reports settings for the current report category,
            // then go to the first available category
            if (typeof this.reports[this.report_category] === 'undefined' ||
                (typeof this.reports[this.report_category] !== 'undefined' && !this.reports[this.report_category].settings.length) &&
                first_non_empty_key) {
                this.$router.push({
                    path: '/reports/' + first_non_empty_key
                }).catch(err => {
                })
                return
            }

            // now we've come here, it means that we have an available report
            // and the category has reports and active subcategories
            // if we're in activity reporting, we have to update the
            // activity reporting sub category and the export name
            this.toFirstActivityReportingSubCategory()

            // now, we have to navigate to the report category
            let route_data = {
                path: '/reports/' + this.report_category
            }
            // if category is activity reporting, we need to also include the sub category
            // in the route as query parameter. this is for the default route (no query parameter
            // in URL
            if (this.report_category == 'activity_reporting') {
                route_data.query = {
                    sub_category: this.sub_category.toLowerCase()
                }
            }

            // if sub category exists in the route query, then include it
            if (!update_sub_category && this.$route.query.sub_category) {
                route_data.query = this.$route.query
                route_data.query.sub_category = route_data.query.sub_category.toLowerCase()
                // now we have to check if the sub category is available/activated in the settings
                let found = this.reports[this.report_category].data.find(activity_report => activity_report.label.toLowerCase() == this.sub_category.toLowerCase())
                let index = found ? this.reports[this.report_category].data.indexOf(found) : null
                index = !this.auth.user.profile.reports_settings[this.report_category].includes(index) ? _.first(this.auth.user.profile.reports_settings[this.report_category]) : index
                route_data.query.sub_category = this.reports[this.report_category].data[index].label.toLowerCase()
                let query = Object.assign({}, route_data.query)
                // update the current route with query parameter
                this.$router.replace({query: query}).catch(err => {
                })
                return
            }

            // then we navigate to it
            this.$router.push(route_data).catch(err => {
            })
        },

        toFirstActivityReportingSubCategory() {
            // don't proceed if category is not activity reporting or this category is not enabled
            if (this.report_category != 'activity_reporting' || this.isEmptyReports(this.report_category)) {
                return
            }
            // get the first active sub category
            let first_active_sub_category = _.first(this.auth.user.profile.reports_settings[this.report_category])
            this.sub_category = this.reports.activity_reporting.data[first_active_sub_category].label
            this.sub_category_export = this.sub_category.toLowerCase().replace(' ', '_') + '_' + this.report_category

            // if sub category query parameter exist, use it instead
            let sub_category = this.$route.query.sub_category
            sub_category = sub_category ? this.$options.filters.ucwords(sub_category).replace(/_|-/g, '') : null
            let found = this.reports[this.report_category].data.find(category => category.label == sub_category && category.active)
            if (this.report_category == 'activity_reporting' &&
                sub_category &&
                found &&
                this.auth.user.profile.reports_settings[this.report_category].includes(found.value)) {
                this.sub_category = sub_category
                this.sub_category_export = this.sub_category.toLowerCase().replace(' ', '_') + '_' + this.report_category
            }
        },

        setTitle() {
            axios.get('/get-statics')
                .then(res => {
                    let name = res.data.name
                    this.setPageTitle('Reports - ' + name)
                })
                .catch(err => {
                    this.setPageTitle('Reports - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },

        closeCsatCallsPanel() {
            this.hideCsatCallsPanel()
        },

        scrollWindowToTop() {
            $("html,body").animate({
                scrollTop: 0
            }, 500)
        },

        isSameRoute() {
            // we have to check if we're not going to change the the same current route
            // as it's unnecessary
            let query = Object.assign({}, this.$route.query)
            query.sub_category = this.sub_category.toLowerCase()
            let queryString = Object.keys(query).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
            }).join('&')
            let full_path = this.$route.path + '?' + queryString
            return this.$route.fullPath == full_path
        },

        changeSubCategory(force_replace_route) {
            if (this.isNotReportingWithSubCategories())
                return

            // force replace the route with query parameter
            if (force_replace_route) {
                let query = Object.assign({}, this.$route.query)
                query.sub_category = this.sub_category.toLowerCase()
                if (!this.isSameRoute()) {
                    this.$router.replace({query})
                }
            }

            // update the sub category export string
            this.sub_category_export = this.sub_category.toLowerCase().replace(' ', '_') + '_' + this.report_category
            this.report_key += 1
        },

        resetOurFilters() {
            this.resetFilters()
            VueEvent.fire('reset_date_range')
        },

        toggleReportsMenu() {
            this.reports_menu_visible = !this.reports_menu_visible
        },

        updateReportsSettings($event, close = false) {
            if (close) {
                $event.preventDefault()
                $event.stopPropagation()
            }
            this.reports_loading = true
            this.loading_reports_settings = true
            let new_settings = {}
            for (let index in this.reports) {
                new_settings[index] = this.reports[index].settings
            }
            axios.patch('/api/v1/user/' + this.auth.user.profile.id + '/reports-settings', new_settings)
                .then(res => {
                    this.$set(this.auth.user.profile, 'reports_settings', res.data.reports_settings)
                    for (let index in this.reports) {
                        this.auth.user.profile.reports_settings[index] = this.auth.user.profile.reports_settings[index].sort()
                        this.reports[index].settings = _.clone(this.auth.user.profile.reports_settings[index])
                    }
                    this.loading_reports_settings = false
                    this.$notify({
                        offset: 95,
                        title: 'Reports',
                        message: 'Successfully saved reports settings',
                        type: 'success',
                        showClose: true,
                    })

                    this.toFirstCategoryOrIndex()
                    this.changeSubCategory(true)
                    // queue the newly added reports
                    this.queueReports()

                    if (close) {
                        this.reports_menu_visible = false
                    }
                }).catch(err => {
                console.log(err)
                this.$root.handleErrors(err.response)
                this.reports_loading = false
                this.loading_reports_settings = false
                if (close) {
                    this.reports_menu_visible = false
                }
            })
        },

        noClose(event) {
            if (event) {
                event.stopPropagation()
            }
        },

        closeAllMenus() {
            if (!this.disable_clickaway && this.reports_menu_visible && this.reportsHasChanges) {
                this.disable_clickaway = true
                this.$confirm('You have changes on the reports settings. Are you sure you want to leave?', 'Warning', {
                    confirmButtonText: 'Yes, Leave',
                    cancelButtonText: 'No, Stay',
                    customClass: 'width-500 fixed',
                    type: 'warning'
                }).then(res => {
                    for (let index in this.reports) {
                        this.reports[index].settings = _.clone(this.auth.user.profile.reports_settings[index])
                    }
                    this.reports_menu_visible = false
                    this.disable_clickaway = false
                }).catch(() => {
                    this.disable_clickaway = false
                    this.reports_menu_visible = true
                })
            } else if (this.reports_menu_visible) {
                this.reports_menu_visible = false
            }
        },

        queueReports(report_index) {
            if (report_index !== undefined) {
                // a report has been loaded. set loaded flag to true
                this.reports[this.report_category].queue[report_index].loaded = true
            }

            // if there's still a report being loaded, let's just leave it for the
            // incoming loaded report to continue loading the rest of the reports
            for (let index in this.reports[this.report_category].settings) {
                let settings_index = parseInt(this.reports[this.report_category].settings[index])
                if (this.reports[this.report_category].queue[settings_index].started &&
                    !this.reports[this.report_category].queue[settings_index].loaded) {
                    return
                }
            }

            // start the loading of the unloaded reports
            for (let index in this.reports[this.report_category].settings) {
                let settings_index = parseInt(this.reports[this.report_category].settings[index])
                if (this.reports[this.report_category].queue[settings_index] !== undefined &&
                    !this.reports[this.report_category].queue[settings_index].started) {
                    this.$set(this.reports[this.report_category].queue, settings_index, {
                        started: true,
                        loaded: false
                    })
                    return
                }
            }
            this.reports_loading = false
        },

        reportFiltersChanged() {
            // we have to reinitialize the reports
            // so that the reports will reload
            this.initializeReports()
            this.$nextTick(() => {
                this.reports_loading = true
                this.loadReports()
            })
        },

        resetReportSettings() {
            for (let index in this.reports) {
                this.reports[index].settings = []
            }
        },

        getWhitelabelStatus() {
            this.loading_whitelabel = true
            axios.get('/get-statics').then(res => {
                this.whitelabel = res.data.whitelabel
                this.loading_whitelabel = false
            }).catch(err => {
                console.log(err)
                this.loading_whitelabel = false
                this.$root.handleErrors(err.response)
            })
        },

        ...mapActions(['resetFilters', 'hideCsatCallsPanel', 'setCsatCallsPanelCalls'])
    },

    watch: {
        report: function (val, oldVal) {
            this.scrollWindowToTop()
        }
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next()
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    },

    beforeRouteUpdate(to, from, next) {
        this.resetReportsQueues()
        this.$nextTick(() => {
            this.reports_loading = true
            this.loadReports()
        })
        next()
    },

    destroyed() {
        this.resetFilters()
    }
}
</script>

<style scoped>
.csat-calls-panel {
    margin-top: 8px;
    position: absolute;
    right: -400px;
    top: 0;
    height: 100%;
    border-left: 1px solid #e9e9e9;
    width: 400px;
    background: #fff;
    z-index: 3;
    transition: all 0.2s ease-in-out;
}

.csat-panel-enter {
    right: 0;
}

.csat-calls-panel h3 {
    color: #000;
    font-size: 22px;
    text-align: center;
    margin-top: 15px;
}

.csat-calls-panel ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.csat-calls-panel hr {
    margin-bottom: 0;
}

.csat-calls-panel table td.link:hover {
    color: blue;
}

.csat-calls-panel ul li {
    padding: 20px;
    position: relative;
    border-bottom: 1px solid #e9e9e9;
}

.csat-calls-panel ul li a {
    color: blue;
}

.csat-calls-panel ul li span.talk-time {
    position: absolute;
    right: 15px;
    font-size: 14px;
}
.csat-calls-panel .fa-close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}
</style>
