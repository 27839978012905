<template>
    <el-dialog
        class="dialog-blank"
        width="45vw"
        top="7vh"
        append-to-body
        :show-close="false"
        :visible="visible">
        <el-form
            :key="form_key"
            class="form-aloware"
            @submit.native.prevent>
            <div class="container-listing rounded p-4">
                <div class="container-header d-flex">
                    <div v-if="mode === 'add'">
                        <div class="d-flex">
                            <el-button
                                v-if="!select_type"
                                class="mr-3"
                                size="medium"
                                round
                                @click="selectType">
                                <i class="fa fa-arrow-left"></i>
                                Change Type
                            </el-button>
                            <h4 class="listing-heading">Add New Step</h4>
                        </div>
                    </div>
                    <div v-if="mode === 'edit'">
                        <h4 class="listing-heading">Edit Step</h4>
                        <small v-if="sequence.id"
                               class="text-grey">
                            #{{ sequence.id }}
                        </small>
                    </div>
                    <div class="ml-auto">
                        <el-button type="text"
                                   class="btn-dialog-close"
                                   size="large"
                                   @click="close">
                            <i class="fa fa-remove"></i>
                        </el-button>
                    </div>
                </div>
                <div class="container-body">
                    <div v-if="mode === 'add' && select_type">
                        <div class="container-step-type-list">
                            <div class="form-label">
                                <h5>Sequence Step Type</h5>
                                <small>Please choose one sequence step type below.</small>
                            </div>
                            <div class="mb-4">
                                <el-input
                                    v-model="sequence_type_search"
                                    placeholder="Search sequence step..."
                                    clearable
                                    @change="searchSequenceType"
                                    @keyup.native="searchSequenceType">
                                    <i slot="prefix"
                                       class="el-input__icon el-icon-search">
                                    </i>
                                </el-input>
                            </div>
                            <el-form-item class="no-border"
                                          :error="errors.first('type')"
                                          id="form-item-sequence-types">
                                <el-radio-group
                                    v-if="types.length > 0"
                                    v-model="sequence.type"
                                    class="w-full radio-group-hover"
                                    @change="changeType">
                                    <div
                                        v-for="type in types"
                                        :key="type.type"
                                        class="mb-3">
                                        <el-radio-button
                                            :label="type.type"
                                            class="radio-btn-block"
                                            :disabled="disableSequenceTypeOption(type.type)">
                                            <div class="d-flex">
                                                <img :src="type.img_src"
                                                     class="align-self-center mr-3"/>
                                                <div class="flex-grow-1 align-self-center text-left mr-3">
                                                    <h6>{{ type.name }}</h6>
                                                    <p class="mb-0 sequence-type-description">{{ type.description }}</p>
                                                    <p class="mt-2 sequence-type-description-danger mb-0 text-danger"
                                                       v-if="disableSequenceTypeOption(type.type)">
                                                        <span v-if="emailStepsDisabled(type.type)">
                                                            You are not allowed to add this step because the accounts has no email integration
                                                        </span>
                                                        <span v-if="smsStepsDisabled(type.type)">
                                                            Company's SMS capability is currently disabled
                                                        </span>
                                                        <span v-if="messagingDisabled(type.type)"
                                                              style="display:block; word-wrap:break-word; white-space: normal;">
                                                            {{ blockedMessagingInformation['reason'] }}
                                                        </span>
                                                        <span v-if="aloAiBotDisabled(type.type)"
                                                              class="d-block w-100 text-center">Company's AloAi currently disabled</span>
                                                        <span v-if="progressiveDialerTrialDisabled(type.type) || sendRinglessVoicemailDisabled(type.type)"
                                                              class="d-block w-100 text-center">Subscribe now to get access</span>
                                                        <span v-if="progressiveDialerDisabled(type.type)"
                                                              class="d-block w-100 text-center">Your account does not have progressive dialer enabled. Please contact support.</span>
                                                    </p>
                                                </div>
                                                <div class="align-self-center">
                                                    <i class="fa fa-chevron-right"></i>
                                                </div>
                                            </div>
                                        </el-radio-button>
                                    </div>
                                </el-radio-group>
                                <div v-if="types.length <= 0"
                                     class="text-center py-5">
                                    <img-empty class="w-75 mx-auto"></img-empty>
                                    <p class="lead">
                                        We couldn't find "{{ sequence_type_search }}"
                                    </p>
                                </div>
                            </el-form-item>
                        </div>
                    </div>
                    <div
                        v-if="!select_type"
                        class="mb-4">
                        <div class="d-flex mb-3">
                            <img :src="sequence.img_src"
                                 class="mr-3"/>
                            <div>
                                <h6 class="mb-0">{{ sequence.name }}</h6>
                                <small v-html="sequence.description"></small>
                            </div>
                        </div>
                        <!-- WAT-876: deprecation banner -->
                        <al-alert
                        :dimisable="true"
                        v-if="showDeprecationBannersForTags" class="mb-1">
                            <span class="text-dark"
                                  v-html="TAGS_DEPRECATION_MESSAGE"/>
                        </al-alert>
                        <div class="card p-4 mb-3">
                            <div class="form-label">
                                <h5><i class="fa fa-clock-o"></i> Delay</h5>
                                <small>
                                    Set the delay between the previous step and this current step. If you don't want
                                    a delay,
                                    just choose "No Delay".
                                </small>
                            </div>
                            <div class="mb-3">
                                <el-radio-group v-model="sequence.delay_type">
                                    <el-radio-button :label="Sequence.DELAY_NONE"
                                                     class="no-select">
                                        No Delay
                                    </el-radio-button>
                                    <el-radio-button :label="Sequence.DELAY_TYPE_MINUTES"
                                                     class="no-select">
                                        Default Delay
                                    </el-radio-button>
                                    <el-radio-button :label="Sequence.DELAY_TYPE_TIME_OF_DAY"
                                                     class="no-select">
                                        Time of Day
                                    </el-radio-button>
                                </el-radio-group>
                            </div>
                            <div v-if="sequence.delay_type === Sequence.DELAY_TYPE_MINUTES">
                                <small>Choose the duration of the delay.</small>
                                <div class="row mt-1">
                                    <div class="col-4">
                                        <el-form-item
                                            class="no-border"
                                            :error="errors.first('converted_delay')">
                                            <el-input-number
                                                class="w-100"
                                                v-model="sequence.converted_delay"
                                                :min="0">
                                            </el-input-number>
                                        </el-form-item>
                                    </div>
                                    <div class="col-6">
                                        <el-form-item
                                            class="no-border"
                                            :error="errors.first('delay_unit')">
                                            <el-select v-model="sequence.delay_unit"
                                                       :key="delay_unit_key"
                                                       class="w-full"
                                                       @change="changeDelayUnit">
                                                <el-option
                                                    v-for="item in delay_units"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </div>
                            </div>
                            <div v-if="sequence.delay_type === Sequence.DELAY_TYPE_TIME_OF_DAY">
                                <small>
                                    Choose the delay in days and the time of the day you want this step to be
                                    processed.
                                </small>
                                <div class="row mb-3">
                                    <div class="col-4">
                                        <el-form-item
                                            class="no-border"
                                            :error="errors.first('converted_delay')">
                                            <el-input-number class="w-80"
                                                             :min="0"
                                                             v-model="sequence.converted_delay"/>
                                            <span class="ml-1">Days</span>
                                        </el-form-item>
                                    </div>
                                    <div class="col-4">
                                        <el-form-item
                                            class="no-border"
                                            :error="errors.first('converted_time_of_day')">
                                            <el-time-select class="w-100"
                                                            placeholder="Choose the time of day"
                                                            :picker-options="time_picker_options"
                                                            v-model="sequence.converted_time_of_day"/>
                                        </el-form-item>
                                    </div>
                                    <div class="col-4">
                                        <el-form-item class="no-border"
                                                      :error="errors.first('delay_timezone')">
                                            <el-select clearable
                                                       class="w-full"
                                                       placeholder="Select Timezone"
                                                       v-model="sequence.delay_timezone">
                                                <el-option :value="parseInt(delayType)"
                                                           :key="parseInt(delayType)"
                                                           :label="Sequence.DELAY_TIMEZONE_TYPES[delayType]"
                                                           v-for="delayType in Object.keys(Sequence.DELAY_TIMEZONE_TYPES)">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </div>
                                <p class="lh-1">
                                    <i class="fa fa-info-circle text-warning"></i>
                                    If the previous step is processed after this step's time of day settings,
                                    this will be executed in the next day.
                                </p>
                            </div>
                            <div v-if="sequence.delay_type === Sequence.DELAY_NONE">
                                <p class="mb-0">
                                    <i class="fa fa-info-circle text-warning"></i>
                                    This step will be processed without delays.
                                </p>
                            </div>

                            <div class="mt-3"
                                 v-if="sequence.type === Sequence.TYPE_REMOVE_FROM_POWER_DIALER">
                                <el-switch
                                    active-color="#00a344"
                                    v-model="sequence.remove_all_users_from_powerdialer_lists"
                                    @change="toggleRemoveAllUserPowerDialerLists"></el-switch>
                                <span><b>Remove from all User's PowerDialer lists.</b></span>
                            </div>

                            <div class="mt-3"
                                 v-if="sequence.type === Sequence.TYPE_REMOVE_FROM_CONTACT_LIST">
                                <el-switch active-color="#00a344"
                                           v-model="sequence.remove_all_users_from_powerdialer_lists"
                                           @change="toggleRemoveAllUserPowerDialerLists">
                                </el-switch>
                                <span><b>Remove from all Contact lists.</b></span>
                            </div>

                            <div class="mt-3"
                                 v-if="sequence.type === Sequence.TYPE_CALL_LEAD">
                                <el-checkbox
                                    class="w-full checkbox-label-description mb-4"
                                    v-model="sequence.confirmation_by_sms"
                                    border>
                                    <label>Send an SMS message before the call</label>
                                    <small>
                                        This will send a yes/no confirmation text to the customer
                                    </small>
                                </el-checkbox>
                            </div>
                        </div>

                        <div class="pt-3">
                            <div class="alert alert-warning alert-aloware"
                                 v-if="sequence.type == Sequence.TYPE_CALL_LEAD || sequence.type == Sequence.TYPE_CALL_RING_GROUP">
                                <i class="fas fa-exclamation-circle"></i> Dialer only calls the contact if an agent is available in a Ring Group below.
                            </div>

                            <div v-if="sequence.type === Sequence.TYPE_ADD_TO_CONTACT_LIST">
                                <el-form-item class="no-border"
                                              :error="errors.first('contact_list_id')">
                                    <div class="form-label">
                                        <h5>List</h5>
                                        <small>
                                            Choose the list to add the enrolled contacts.
                                        </small>
                                    </div>
                                    <contact-lists-selector :user_id="auth.user.profile.id"
                                                            :module_type="ContactListModuleTypes.PUBLIC_CONTACT_LIST"
                                                            v-model="sequence.contact_list_id"
                                                            @change="changeContactList"/>
                                </el-form-item>
                            </div>

                            <div v-if="sequence.type === Sequence.TYPE_CALL_LEAD">
                                <el-form-item
                                    class="no-border"
                                    :error="errors.first('ring_group_id')">
                                    <div class="form-label">
                                        <h5>Ring Group</h5>
                                        <small>Choose the ring group you want the enrolled contact to be connected.</small>
                                    </div>

                                    <ring-group-selector
                                        v-model="sequence.ring_group_id"
                                        class="w-full"
                                        @change="changeRingGroup">
                                    </ring-group-selector>
                                </el-form-item>

                                <el-form-item
                                    class="no-border">
                                    <div class="form-label">
                                        <h5>Message</h5>
                                        <small>
                                            Please design your message to follow this guideline: <br/>
                                        </small>

                                        <div class="alert alert-success alert-aloware">
                                            "If you want to be connected now, press 1. If you want to be called at a later time, press 2. To be removed from our list, please press 9."
                                        </div>
                                    </div>
                                    <comm-tools v-model="sequence"
                                                :errors="errors"
                                                :is_optout_active="is_optout_active"
                                                @change-is-optout-active="updateIsOptoutActive">
                                    </comm-tools>
                                </el-form-item>
                            </div>
                            <div v-if="sequence.type === Sequence.TYPE_CALL_RING_GROUP">
                                <el-form-item
                                    class="no-border"
                                    :error="errors.first('ring_group_id')">
                                    <div class="form-label">
                                        <h5>Ring Group</h5>
                                        <small>Choose the ring group you want the enrolled contact to be connected.</small>
                                    </div>

                                    <ring-group-selector
                                        v-model="sequence.ring_group_id"
                                        class="w-full"
                                        @change="changeRingGroup">
                                    </ring-group-selector>
                                </el-form-item>

                                <el-form-item
                                    class="no-border">
                                    <div class="form-label">
                                        <h5>Message</h5>
                                        <small>
                                            Please design your message to follow this guideline:
                                        </small>

                                        <div class="alert alert-success alert-aloware">
                                            "Hey [AgentName], we are about to call [FirstName] [LastName] from [State]."
                                        </div>
                                    </div>
                                    <comm-tools v-model="sequence"
                                                :errors="errors"
                                                :is_optout_active="is_optout_active"
                                                @change-is-optout-active="updateIsOptoutActive">
                                    </comm-tools>
                                </el-form-item>
                            </div>
                            <div v-if="sequence.type === Sequence.TYPE_SMS_LEAD">
                                <el-form-item
                                    class="no-border">
                                    <div class="form-label">
                                        <h5>Text Message</h5>
                                        <small>
                                            To ensure delivery, try to make your message as personal as possible. <br/>
                                            You can use short codes like [FirstName] or [TrackingNumber].
                                        </small>
                                    </div>
                                    <comm-tools v-model="sequence"
                                                :errors="errors"
                                                :is_optout_active="is_optout_active"
                                                @change-is-optout-active="updateIsOptoutActive">
                                    </comm-tools>
                                </el-form-item>

                                <el-form-item class="no-border">
                                    <el-checkbox class="w-full checkbox-label-description mb-4"
                                                 v-model="sequence.use_personal_line"
                                                 border>
                                        <label>Use contact owner's personal line</label>
                                        <small>
                                            Uses the contact owner's personal line to send out the message.
                                        </small>
                                    </el-checkbox>

                                    <p class="lh-1p4"
                                       v-if="sequence.use_personal_line">
                                        If the contact does not have an owner or the owner does not have a personal line, we will use the
                                        sequence default outbound line to send the message.
                                    </p>
                                </el-form-item>
                            </div>
                            <div v-if="sequence.type === Sequence.TYPE_MMS">
                                <comm-tools v-model="sequence"
                                            :errors="errors"
                                            :is_optout_active="is_optout_active"
                                            @change-is-optout-active="updateIsOptoutActive">
                                </comm-tools>
                            </div>
                            <div v-if="sequence.type === Sequence.TYPE_SMS_RING_GROUP">
                                <el-form-item
                                    class="no-border"
                                    :error="errors.first('ring_group_id')">
                                    <div class="form-label">
                                        <h5>Ring Group</h5>
                                        <small>Choose the ring group you want the enrolled contact to be connected.</small>
                                    </div>

                                    <ring-group-selector
                                        v-model="sequence.ring_group_id"
                                        class="w-full"
                                        @change="changeRingGroup">
                                    </ring-group-selector>
                                </el-form-item>
                                <el-form-item
                                    class="no-border">
                                    <div class="form-label">
                                        <h5>Text Message</h5>
                                        <small>
                                            To ensure delivery, try to make your message as personal as possible. <br/>
                                            You can use short codes like [FirstName] or [TrackingNumber].
                                        </small>
                                    </div>
                                    <comm-tools v-model="sequence"
                                                :errors="errors"
                                                :is_optout_active="is_optout_active"
                                                @change-is-optout-active="updateIsOptoutActive">
                                    </comm-tools>
                                </el-form-item>
                            </div>
                            <div v-if="sequence.type === Sequence.TYPE_ADD_TAG_CONTACT">
                                <el-form-item
                                    class="no-border"
                                    :error="errors.first('tag_id')">
                                    <div class="form-label">
                                        <h5>Tag</h5>
                                        <small>Choose the tag you want the enrolled contacts to be tagged.</small>
                                    </div>
                                    <tag-selector
                                        v-model="sequence.tag_id"
                                        :category_prop="TagCategory.CAT_CONTACTS"
                                        class="w-full"
                                        @change="changeTag">
                                    </tag-selector>
                                </el-form-item>
                            </div>
                            <div v-if="sequence.type === Sequence.TYPE_CONTACT_TO_LINE">
                                <el-form-item
                                    class="no-border"
                                    :error="errors.first('campaign_id')">
                                    <div class="form-label">
                                        <h5>Line</h5>
                                        <small>Choose the line to add for the enrolled contacts.</small>
                                    </div>
                                    <campaign-selector
                                        v-model="sequence.campaign_id"
                                        class="w-full"
                                        @change="changeCampaign">
                                    </campaign-selector>
                                </el-form-item>
                            </div>
                            <div v-if="sequence.type === Sequence.TYPE_UPDATE_CONTACT_LEAD_SOURCE">
                                <el-form-item
                                    class="no-border"
                                    :error="errors.first('lead_source_id')">
                                    <div class="form-label">
                                        <h5>Lead Source</h5>
                                        <small>Enter lead source name</small>
                                    </div>
                                    <lead-source-selector v-model="sequence.lead_source_id">
                                    </lead-source-selector>
                                </el-form-item>
                            </div>
                            <div v-if="sequence.type === Sequence.TYPE_REMOVE_TAG_CONTACT">
                                <el-form-item
                                    class="no-border"
                                    :error="errors.first('tag_id')">
                                    <div class="form-label">
                                        <h5>Tag</h5>
                                        <small>Choose the tag you want to remove from the enrolled contact.</small>
                                    </div>
                                    <div class="mt-1 mb-1">
                                        <el-checkbox
                                            class="w-full"
                                            v-model="sequence.remove_all_tags"
                                            @change="toggleRemoveAllTags">
                                            <small>Remove All Tags</small>
                                        </el-checkbox>
                                    </div>
                                    <tag-selector
                                        class="w-full"
                                        v-model="sequence.tag_id"
                                        :category_prop="TagCategory.CAT_CONTACTS"
                                        :disabled="sequence.remove_all_tags"
                                        @change="changeTag">
                                    </tag-selector>
                                </el-form-item>
                            </div>
                            <div v-if="sequence.type === Sequence.TYPE_ADD_TO_POWER_DIALER">
                                <div class="mb-3">
                                    <el-radio-group v-model="sequence.distribute_to"
                                                    @change="checkDistributeTo">
                                        <el-radio-button class="no-select"
                                                         :label="Sequence.ASSIGN_TO_USER">
                                            User
                                        </el-radio-button>
                                        <el-radio-button class="no-select"
                                                         :label="Sequence.ASSIGN_TO_RING_GROUP">
                                            Ring Group
                                        </el-radio-button>
                                    </el-radio-group>
                                </div>
                                <div v-if="sequence.distribute_to === Sequence.ASSIGN_TO_USER">
                                    <el-form-item
                                        class="no-border"
                                        :error="errors.first('user_id')">
                                        <div class="form-label">
                                            <h5>User</h5>
                                            <small>
                                                Choose the PowerDialer user you want the enrolled contact to be added.
                                            </small>
                                        </div>
                                        <user-selector
                                            v-model="sequence.user_id"
                                            :hide_extensions="true"
                                            @change="changeUser">
                                        </user-selector>
                                    </el-form-item>

                                    <el-form-item
                                        class="no-border"
                                        :error="errors.first('contact_list_id')"
                                        v-if="sequence.user_id">
                                        <div class="form-label">
                                            <h5>PowerDialer List</h5>
                                            <small>
                                                Choose the PowerDialer user's list to which you want to add the enrolled contact.
                                            </small>
                                        </div>
                                        <contact-lists-selector
                                            v-model="sequence.contact_list_id"
                                            :user_id="sequence.user_id"
                                            :module_type="ContactListModuleTypes.POWER_DIALER_LIST"
                                            @change="changeContactList"/>
                                    </el-form-item>
                                </div>
                                <div v-if="sequence.distribute_to === Sequence.ASSIGN_TO_RING_GROUP">
                                    <el-form-item
                                        class="no-border"
                                        :error="errors.first('ring_group_id')">
                                        <div class="form-label">
                                            <h5>Ring Group</h5>
                                            <small>Choose the ring group you want the enrolled contact to be connected.</small>
                                        </div>

                                        <ring-group-selector
                                            v-model="sequence.ring_group_id"
                                            class="w-full"
                                            @change="changeRingGroup">
                                        </ring-group-selector>
                                    </el-form-item>

                                    <el-checkbox name="events"
                                                 class="w-full checkbox-label-description mb-4"
                                                 v-model="sequence.assign_to_available_users"
                                                 border>
                                        <label>Assign to available users only</label>
                                        <small>
                                            The enrolled contact will be added to power dialer list of available users
                                        </small>
                                    </el-checkbox>
                                </div>


                                <el-form-item class="no-border"
                                              prop="push_contact_to"
                                              :error="errors.first('push_contact_to')">
                                    <div class="form-label">
                                        <h5>Power Dialer Position</h5>
                                        <small>
                                            Choose whether you want the enrolled contact to be added at the top or
                                            bottom of the user's PowerDialer.
                                        </small>
                                    </div>
                                    <el-radio-group v-model="sequence.push_contact_to"
                                                    @change="pushContactToChange">
                                        <el-radio-button :label="Sequence.PUSH_CONTACTS_TO_BOTTOM">Bottom
                                        </el-radio-button>
                                        <el-radio-button :label="Sequence.PUSH_CONTACTS_TO_TOP">Top</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>

                                <el-checkbox name="events"
                                             class="w-full checkbox-label-description mb-4"
                                             v-model="sequence.add_to_contact_owner"
                                             v-if="sequence.distribute_to === Sequence.ASSIGN_TO_USER"
                                             border>
                                    <label>Add to contact owner's PowerDialer</label>
                                    <small>
                                        If the enrolled contact does not have an owner, it will be added the the
                                        selected user's PowerDialer.
                                    </small>
                                </el-checkbox>

                                <el-checkbox name="events"
                                             class="w-full checkbox-label-description mb-4"
                                             v-model="sequence.multiple_phone_numbers"
                                             border>
                                    <label>Allow multiple phone numbers</label>
                                    <small>
                                        Add all contact's phone numbers when there are more than one.
                                    </small>
                                </el-checkbox>

                                <el-checkbox name="events"
                                             class="w-full checkbox-label-description mb-4"
                                             v-model="sequence.prevent_duplicate_contacts"
                                             border>
                                    <label>Prevent duplicates</label>
                                    <small>
                                        Remove duplicated contacts in the PowerDialer queue.
                                    </small>
                                </el-checkbox>

                                <el-checkbox name="allow_international_phone_numbers"
                                             class="w-full checkbox-label-description mb-4"
                                             v-model="sequence.allow_international_phone_numbers"
                                             :disabled="!hasInternationalAccess"
                                             border>
                                    <label>
                                        Allow international phone numbers

                                        <el-tooltip v-if="!hasInternationalAccess"
                                                    class="item ml-2"
                                                    effect="dark"
                                                    content="This account has no international access"
                                                    placement="top">
                                            <i class="fas fa-info-circle text-dark"></i>
                                        </el-tooltip>
                                    </label>
                                    <small>
                                        Either to exclude or include international phone numbers in Power Dialer.
                                    </small>
                                </el-checkbox>
                            </div>
                            <div v-if="sequence.type === Sequence.TYPE_DISPOSE_CONTACT">
                                <el-form-item
                                    class="no-border"
                                    :error="errors.first('disposition_status_id')">
                                    <div class="form-label">
                                        <h5>Disposition Status</h5>
                                        <small>Choose the Disposition Status you want for the enrolled contact.</small>
                                    </div>
                                    <disposition-status-selector v-model="sequence.disposition_status_id"
                                                                 :clearable="true"
                                                                 @change="changeDispositionStatus">
                                    </disposition-status-selector>
                                </el-form-item>
                            </div>
                            <div v-if="sequence.type === Sequence.TYPE_DNC_CONTACT">
                                <div class="alert alert-warning alert-aloware">
                                    <i class="fa fa-exclamation-triangle"></i> <strong>Warning:</strong>
                                    The enrolled contact will be marked as DNC (Do Not Contact) and you will never be able to
                                    communicate to the contact anymore.
                                </div>
                            </div>
                            <div v-if="sequence.type === Sequence.TYPE_CHANGE_CONTACT_OWNER">
                                <el-form-item
                                    class="no-border"
                                    :error="errors.first('user_id')">
                                    <div class="form-label">
                                        <h5>Contact's Owner</h5>
                                        <small>Choose the the user you want to assign the enrolled contact.</small>
                                    </div>
                                    <user-selector
                                        v-model="sequence.user_id"
                                        :hide_extensions="true"
                                        @change="changeUser">
                                    </user-selector>
                                </el-form-item>
                            </div>
                            <div v-if="sequence.type === Sequence.TYPE_WEBHOOK">
                                <el-form-item class="no-border">
                                    <div class="form-label">
                                        <h5>Authentication Method</h5>
                                        <small>Select your webhook endpoint authentication method.</small>
                                    </div>
                                    <el-radio-group v-model="sequence.auth_method"
                                                    size="medium">
                                        <el-radio-button :label="Sequence.AUTH_METHOD_NONE">None</el-radio-button>
                                        <el-radio-button :label="Sequence.AUTH_METHOD_BASIC">Basic</el-radio-button>
                                        <el-radio-button :label="Sequence.AUTH_METHOD_TOKEN_BASED">Bearer</el-radio-button>
                                    </el-radio-group>

                                    <div class="mt-3">
                                        <div v-if="sequence.auth_method === Sequence.AUTH_METHOD_BASIC"
                                             class="row">
                                            <div class="col-6">
                                                <el-form-item class="no-border"
                                                              :error="errors.first('credentials.username')">
                                                    <div class="form-label">
                                                        <label>Username</label>
                                                    </div>
                                                    <el-input v-model="sequence.credentials.username"></el-input>
                                                </el-form-item>
                                            </div>
                                            <div class="col-6">
                                                <el-form-item class="no-border"
                                                              :error="errors.first('credentials.password')">
                                                    <div class="form-label">
                                                        <label>Password</label>
                                                    </div>
                                                    <el-input v-model="sequence.credentials.password"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div v-if="sequence.auth_method === Sequence.AUTH_METHOD_TOKEN_BASED">
                                            <el-form-item class="no-border"
                                                          :error="errors.first('credentials.api_token')">
                                                <div class="form-label">
                                                    <label>API Bearer Token</label>
                                                    <small>Enter the API token of your webhook endpoint.</small>
                                                </div>
                                                <el-input v-model="sequence.credentials.api_token"></el-input>
                                            </el-form-item>
                                        </div>
                                    </div>
                                </el-form-item>

                                <el-form-item class="no-border"
                                              :error="errors.first('url')"
                                              prop="url">
                                    <div class="form-label">
                                        <h5>URL</h5>
                                        <small>Provide a valid webhook endpoint URL.</small>
                                    </div>
                                    <el-input v-model="sequence.url"></el-input>
                                </el-form-item>
                            </div>
                            <div v-if="sequence.type === Sequence.TYPE_EMAIL_LEAD">
                                <el-form-item
                                    class="no-border"
                                    :error="errors.first('message_subject')">
                                    <div class="form-label">
                                        <h5>Email Subject</h5>
                                        <small>Provide the email subject</small>
                                    </div>
                                    <el-input
                                        v-model="sequence.message_subject"
                                        class="w-full">
                                    </el-input>
                                </el-form-item>
                                <el-form-item>
                                    <comm-tools v-model="sequence"
                                                :is_optout_active="is_optout_active"
                                                @change-is-optout-active="updateIsOptoutActive"/>
                                </el-form-item>
                            </div>
                            <div v-if="sequence.type === Sequence.TYPE_EMAIL_RING_GROUP">
                                <el-form-item
                                    class="no-border"
                                    :error="errors.first('ring_group_id')">
                                    <div class="form-label">
                                        <h5>Ring Group</h5>
                                        <small>Choose the ring group you want the enrolled contact to be connected.</small>
                                    </div>

                                    <ring-group-selector
                                        v-model="sequence.ring_group_id"
                                        class="w-full"
                                        @change="changeRingGroup">
                                    </ring-group-selector>
                                </el-form-item>
                                <el-form-item
                                    class="no-border"
                                    :error="errors.first('message_subject')">
                                    <div class="form-label">
                                        <h5>Email Subject</h5>
                                        <small>Provide the email subject</small>
                                    </div>
                                    <el-input
                                        v-model="sequence.message_subject"
                                        class="w-full">
                                    </el-input>
                                </el-form-item>
                                <el-form-item>
                                    <comm-tools v-model="sequence"
                                                :is_optout_active="is_optout_active"
                                                @change-is-optout-active="updateIsOptoutActive"/>
                                </el-form-item>
                            </div>
                            <div v-if="sequence.type === Sequence.TYPE_RVM">
                                <el-form-item class="no-border"
                                              :error="errors.first('message_tts')">
                                    <comm-tools v-model="sequence"
                                                :is_optout_active="is_optout_active"
                                                @change-is-optout-active="updateIsOptoutActive"/>
                                </el-form-item>
                            </div>
                            <div v-if="sequence.type === Sequence.TYPE_CHANGE_CONTACT_OWNER_ROUND_ROBIN">
                                <el-form-item class="no-border">
                                    <div class="form-label">
                                        <h5>Round-robin Contact Ownership</h5>
                                        <small>
                                            Choose whether you want to round-robin contact ownership to all users or a certain ring group.
                                        </small>
                                    </div>
                                    <el-radio-group
                                        class="mb-2"
                                        v-model="round_robin_ring_group"
                                        @change="changeRoundRobinSettings">
                                        <el-radio-button :label="false">
                                            All Users
                                        </el-radio-button>
                                        <el-radio-button :label="true">Users in a Ring Group</el-radio-button>
                                    </el-radio-group>

                                    <div v-if="!round_robin_ring_group">
                                        <i class="fa fa-info-circle text-success"></i> The enrolled contact will be round-robin assigned to all users.
                                    </div>
                                </el-form-item>
                                <el-form-item v-if="round_robin_ring_group"
                                              class="no-border"
                                              :error="errors.first('ring_group_id')">
                                    <div class="form-label">
                                        <h5>Ring Group</h5>
                                        <small>
                                            The enrolled contact will be round-robin assigned to the users of the chosen Ring group.
                                        </small>
                                    </div>
                                    <ring-group-selector
                                        v-model="sequence.ring_group_id"
                                        class="w-full"
                                        @change="changeRingGroup">
                                    </ring-group-selector>
                                </el-form-item>
                            </div>
                            <div v-if="sequence.type === Sequence.TYPE_REMOVE_FROM_POWER_DIALER && !sequence.remove_all_users_from_powerdialer_lists">
                                <el-form-item
                                    class="no-border"
                                    :error="errors.first('user_id')">
                                    <div class="form-label">
                                        <h5>User</h5>
                                        <small>Choose the PowerDialer user you want the enrolled contact to be removed from.</small>
                                    </div>
                                    <user-selector
                                        v-model="sequence.user_id"
                                        :hide_extensions="true"
                                        @change="changeUser">
                                    </user-selector>
                                </el-form-item>

                                <el-form-item
                                    class="no-border"
                                    :error="errors.first('contact_list_id')"
                                    v-if="sequence.user_id">
                                    <div class="form-label">
                                        <h5>PowerDialer List</h5>
                                        <small>Choose the PowerDialer user's list you want the enrolled contact to be removed from.</small>
                                    </div>
                                    <contact-lists-selector
                                        :user_id="sequence.user_id"
                                        :module_type="ContactListModuleTypes.POWER_DIALER_LIST"
                                        v-model="sequence.contact_list_id"
                                        @change="changeContactList"/>
                                </el-form-item>
                            </div>
                            <div v-if="sequence.type === Sequence.TYPE_REMOVE_FROM_CONTACT_LIST && !sequence.remove_all_users_from_powerdialer_lists">
                                <el-form-item class="no-border"
                                              :error="errors.first('contact_list_id')">
                                    <div class="form-label">
                                        <h5>List</h5>
                                        <small>Choose the List to remove from the enrolled contacts.</small>
                                    </div>
                                    <contact-lists-selector :user_id="auth.user.profile.id"
                                                            :module_type="ContactListModuleTypes.PUBLIC_CONTACT_LIST"
                                                            v-model="sequence.contact_list_id"
                                                            @change="changeContactList"/>
                                </el-form-item>
                            </div>
                            <div v-if="sequence.type === Sequence.TYPE_ENROLL_TO_OTHER_WORKFLOW">
                                <el-form-item
                                    class="no-border"
                                    :error="errors.first('enroll_workflow_id')">
                                    <div class="form-label">
                                        <h5>Sequence</h5>
                                        <small>
                                            Choose the Sequence you want to enroll the contacts to.
                                        </small>
                                    </div>
                                    <workflow-selector
                                        v-model="sequence.enroll_workflow_id"
                                        @change="changeEnrollWorkflow"/>
                                </el-form-item>
                            </div>
                                <div v-if="sequence.type === Sequence.TYPE_START_ALOAI_CONVERSATION">
                                    <el-form-item
                                        class="no-border"
                                        :error="errors.first('aloai_bot_id')">
                                        <div class="form-label">
                                            <h5>AloAi Agent</h5>
                                            <small>
                                                Choose the AloAi Agent you want to start a conversation.
                                            </small>
                                        </div>
                                        <alo-ai-bots-selector
                                            ref="aloAiBotsSelector"
                                            :key="sequence.aloai_agent_type"
                                            v-model="sequence.aloai_bot_id"
                                            :direction="AloAi.DIRECTION_OUTBOUND"
                                            :group_by_type="true"
                                            @change="changeAloAiBot"/>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mt-4">
                        <el-button @click="close">
                            Cancel
                        </el-button>
                        <el-tooltip
                                    :disabled="!progressiveDialerDisabled(sequence.type)"
                                    class="item ml-2"
                                    effect="dark"
                                    content="Your account does not have progressive dialer enabled. Please contact support."
                                    placement="top">
                            <el-button
                                v-if="!select_type"
                                type="success"
                                class="ml-auto"
                                :loading="saving"
                                :disabled="progressiveDialerDisabled(sequence.type)"
                                @click="saveSequence">
                                <strong v-if="mode === 'edit'">Save Settings</strong>
                                <strong v-if="mode === 'add'">Add Step</strong>
                            </el-button>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </el-form>
    </el-dialog>
</template>

<script>
import {kyc_mixin, user_info_mixin, user_mixin} from '../../mixins'
import ErrorBag from "../../ErrorBag"
import TagSelector from "../tag-selector"
import UserSelector from "../user-selector"
import RingGroupSelector from "../ring-group-selector"
import CommTools from "./comm-tools"
import DispositionStatusSelector from "../disposition-status-selector"
import WorkflowSelector from "../workflow-selector"
import ImgEmpty from "../misc/img-empty"
import {mapState} from "vuex"
import CampaignSelector from "../campaign-selector"
import LeadSourceSelector from "../lead-source-selector"
import ContactListsSelector from '../contact-lists-selector.vue'
import AloAiBotsSelector from '../aloai/alo-ai-bots-selector.vue'
import isEmpty from 'lodash/isEmpty'
import AlAlert from '../../components/alert'
import {TAGS_DEPRECATION_MESSAGE} from '../../constants/deprecation.messages'
import * as TagCategory from "../../constants/tag-categories"
import * as Sequence from '../../constants/sequence'
import * as International from '../../constants/international-tier'
import * as ContactListModuleTypes from '../../constants/contact-list-module-types'
import * as AloAi from '../../constants/aloai'


export default {
    name: "sequence-manager",

    mixins: [
        user_info_mixin,
        user_mixin,
        kyc_mixin
    ],

    components: {
        LeadSourceSelector,
        CampaignSelector,
        ImgEmpty,
        DispositionStatusSelector,
        CommTools,
        RingGroupSelector,
        UserSelector,
        TagSelector,
        ContactListsSelector,
        WorkflowSelector,
        AloAiBotsSelector,
        AlAlert
    },

    data() {
        return {
            TAGS_DEPRECATION_MESSAGE,
            Sequence,
            sequence_type_search: '',
            round_robin_ring_group: false,
            delay_unit_key: 1,
            visible: false,
            saving: false,
            workflow: null,
            default_sequence: null,
            sequence: {},
            previous_sequence: null,
            mode: 'add',
            default_types: [],
            types: [],
            errors: new ErrorBag({}),
            delay_units: [
                {
                    value: Sequence.UNIT_MINUTES,
                    label: 'Minutes'
                },
                {
                    value: Sequence.UNIT_HOURS,
                    label: 'Hours'
                },
                {
                    value: Sequence.UNIT_DAYS,
                    label: 'Days'
                },
            ],
            time_picker_options: {
                start: '00:00',
                step: '00:15',
                end: '23:45'
            },
            select_type: false,
            form_key: 1,
            is_optout_active: true,
            optout_message: '\nText STOP to unsubscribe.',
            want_to_proceed_without_optout: false,
            TagCategory,
            ContactListModuleTypes,
            AloAi
        }
    },

    computed: {
        ...mapState(['campaigns']),
        ...mapState('cache', ['current_company']),

        hasInternationalAccess() {
            if (this.current_company && this.current_company.international_tier !== International.TIER_1) {
                return true
            }

            return false
        },

        blockedMessagingInformation() {
            const result = {
                'blocked': false,
                'reason': null,
                'automated_only': false
            }
            // get the campaign_id from the workflow
            let campaign_id = this.workflow?.campaign_id
            // find the selected campaign
            let campaign = this.campaigns.find(campaign => campaign.id === campaign_id)

            // if the campaign is not found, return false
            if (isEmpty(campaign)) {
                return result
            }

            return campaign.blocked_messaging_information
        },

        showDeprecationBannersForTags() {
            // WAT-876:add
            return this.sequence.type === Sequence.TYPE_ADD_TAG_CONTACT || this.sequence.type === Sequence.TYPE_REMOVE_TAG_CONTACT
        },
    },

    created() {
        this.fetchTypes()
    },

    methods: {
        /**
         * Searches the sequence types and filters the result
         */
        searchSequenceType: _.debounce(function () {
            if (this.sequence_type_search) {
                this.types = _.filter(this.default_types, (type) => {
                    let search = this.sequence_type_search.toLowerCase()
                    return type.name.toLowerCase().includes(search) || type.description.toLowerCase().includes(search)
                })
            } else {
                this.types = _.clone(this.default_types)
            }
        }, 100),

        toggleRemoveAllUserPowerDialerLists() {
            if (this.sequence.remove_all_users_from_powerdialer_lists === true) {
                this.sequence.user_id = null
            }
        },

        addSequence(workflow, previous_sequence = null) {
            this.workflow = workflow
            this.previous_sequence = previous_sequence

            this.default_sequence = {
                delay_type: Sequence.DELAY_NONE,
                delay_unit: Sequence.UNIT_MINUTES,
                workflow: this.workflow,
                workflow_id: this.workflow.id,
                ring_group_id: null,
                tag_id: null,
                remove_all_tags: false,
                campaign_id: null,
                lead_source: null,
                disposition_status_id: null,
                user_id: null,
                push_contact_to: Sequence.PUSH_CONTACTS_TO_BOTTOM,
                type: null,
                ratio: 1,
                delay: 0,
                time_of_day: null,
                gif_url: null,
                message_tts: '',
                message_file: null,
                amd_enabled: false,
                amd_voicemail_tts: null,
                amd_voicemail_file: null,
                url: null,
                credentials: {
                    api_token: '',
                    username: '',
                    password: '',
                },
                add_to_contact_owner: false,
                multiple_phone_numbers: false,
                prevent_duplicate_contacts: false,
                allow_international_phone_numbers: false,
                use_personal_line: false,
                confirmation_by_sms: false,
                auth_method: Sequence.AUTH_METHOD_NONE,
                confirm_message: false,
                distribute_to: Sequence.ASSIGN_TO_USER,
                assign_to_available_users: false,
                remove_all_users_from_powerdialer_lists: false,
                aloai_agent_type: AloAi.TYPE_TEXT,
                aloai_bot_id: null
            }
            this.sequence = _.clone(this.default_sequence)

            this.mode = 'add'
            this.select_type = true

            this.visible = true

            // force the form to re-render
            this.form_key++
        },

        editSequence(sequence) {
            sequence.distribute_to = (sequence.user_id) ? Sequence.ASSIGN_TO_USER : Sequence.ASSIGN_TO_RING_GROUP
            this.default_sequence = _.clone(sequence)
            this.sequence = _.clone(sequence)

            // Set default agent type if not set
            if (this.sequence.type === Sequence.TYPE_START_ALOAI_CONVERSATION && !this.sequence.aloai_agent_type) {
                this.sequence.aloai_agent_type = AloAi.TYPE_TEXT
            }

            if (!this.sequence.credentials) {
                this.sequence.credentials = {}
            }

            this.sequence.gif_url = null
            if (sequence.type === Sequence.TYPE_MMS && sequence.message_file && sequence.message_file.includes('.gif')) {
                this.sequence.gif_url = sequence.message_file
                this.sequence.message_file = null
            }

            if (sequence.type === Sequence.TYPE_CHANGE_CONTACT_OWNER_ROUND_ROBIN) {
                this.round_robin_ring_group = this.sequence.ring_group_id > 0
            }

            this.checkIfMessageHasOptout()

            this.mode = 'edit'
            this.select_type = false

            this.visible = true

            // force the form to re-render
            this.form_key++
        },

        checkIfMessageHasOptout() {
            if (this.sequence?.message_tts?.includes(this.optout_message) || this.current_company.force_opt_out_phrase) {
                this.is_optout_active = true
                this.sequence.message_tts = this.sequence?.message_tts?.replace(this.optout_message, '')
                return
            }

            this.is_optout_active = false
        },

        fetchTypes() {
            axios.get(`/api/v1/automations/sequences/types`).then(res => {
                this.default_types = res.data
                this.types = _.clone(this.default_types)
            })
        },

        confirmOptOutMessageIncluded() {
            this.$confirm('You haven\'t included the mandatory opt-out message in the SMS as required by law. Are you sure you want to proceed without it?', 'Opt-out Message Confirmation', {
                confirmButtonText: 'Yes, I want to proceed',
                cancelButtonText: 'No, I\'ll add it',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.want_to_proceed_without_optout = true
                this.saveSequence()
            }).catch(() => {
                return
            })
        },

        saveSequence() {
            if ([Sequence.TYPE_SMS_RING_GROUP, Sequence.TYPE_SMS_LEAD, Sequence.TYPE_MMS].includes(this.sequence.type) && !this.is_optout_active && !this.want_to_proceed_without_optout) {
                return this.confirmOptOutMessageIncluded()
            }

            if (this.mode === 'edit') {
                this.updateSequence()
            }

            if (this.mode === 'add') {
                this.createSequence()
            }
        },

        createSequence() {
            let previous_sequence_id = ''
            if (this.previous_sequence) {
                previous_sequence_id = `/${this.previous_sequence.id}`
            }

            this.saving = true
            this.addOptoutMessageToSequenceMessage()

            axios.post(`/api/v1/automations/sequences/${this.workflow.id}${previous_sequence_id}`, {
                ...this.sequence,
                opt_out_bypassed: !this.is_optout_active
            }).then(res => {
                this.saving = false
                this.errors = new ErrorBag({})

                this.close()
            }).catch(err => {
                if (err.response.status === 400) {
                    if (err.response.data.message) {
                        const notify_title = this.mode === 'add' ? 'Add New Step' : 'Edit Step'
                        this.$notify({
                            offset: 75,
                            title: notify_title,
                            message: err.response.data.message,
                            type: 'error',
                            showClose: true,
                            duration: 3000
                        })
                    }
                }
                if (err.response.status === 422) {
                    this.errors = new ErrorBag(err.response.data.errors)
                }
                this.saving = false
            })
        },

        addOptoutMessageToSequenceMessage() {
            if (![Sequence.TYPE_SMS_RING_GROUP, Sequence.TYPE_SMS_LEAD, Sequence.TYPE_MMS].includes(this.sequence.type)) {
                return
            }

            if (this.is_optout_active) {
                this.sequence.message_tts = `${this.sequence.message_tts} ${this.optout_message}`
            }
        },

        updateSequence() {
            this.saving = true
            this.addOptoutMessageToSequenceMessage()

            axios.put(`/api/v1/automations/sequences/${this.sequence.id}`, this.sequence).then(res => {

                this.saving = false
                this.errors = new ErrorBag({})

                this.close()
            }).catch(err => {
                if (err.response.status === 422) {
                    this.errors = new ErrorBag(err.response.data.errors)
                }
                this.saving = false
            })
        },

        deleteSequence(sequence) {
            this.$confirm('Are you sure you want to delete this sequence step? This will affect the flow of your sequences.', 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                axios.delete(`/api/v1/automations/sequences/${sequence.id}`).then(res => {
                    this.$emit('before-close')
                    this.$notify({
                        offset: 75,
                        title: 'Delete Sequence',
                        message: res.data.message,
                        type: 'success',
                        showClose: true,
                        duration: 3000
                    })
                })
            }).catch(() => {
            })
        },

        changeType(type) {
            this.select_type = false

            let selected_type = _.find(this.types, {'type': type})
            if (selected_type) {
                this.sequence.name = selected_type.name
                this.sequence.description = selected_type.description
                this.sequence.img_src = selected_type.img_src
            }
        },

        selectType() {
            this.select_type = true
            this.sequence.type = null
        },

        close() {
            this.$emit('before-close')
            this.visible = false

            // reset type search
            this.sequence_type_search = ''
            this.searchSequenceType()

            this.errors = new ErrorBag({})
            this.want_to_proceed_without_optout = false
        },

        changeDelayUnit() {
            this.delay_unit_key++
        },

        changeTag(tag_id) {
            this.sequence.tag_id = tag_id
        },

        toggleRemoveAllTags() {
            if (this.sequence.remove_all_tags) {
                this.sequence.tag_id = null
            }
        },

        changeCampaign(campaign_id) {
            this.sequence.campaign_id = campaign_id
        },

        changeUser(user_id) {
            this.sequence.user_id = user_id
            this.sequence.contact_list_id = null

            // remove the error message if user_id is valid
            if (user_id) {
                this.errors.clear('user_id')
            }
        },

        changeEnrollWorkflow(enroll_workflow_id) {
            this.sequence.enroll_workflow_id = enroll_workflow_id

            if (enroll_workflow_id) {
                this.errors.clear('enroll_workflow_id')
            }
        },

        changeAloAiBot(aloai_bot_id) {
            this.sequence.aloai_bot_id = aloai_bot_id

            if (aloai_bot_id) {
                this.errors.clear('aloai_bot_id')
            }
        },

        pushContactToChange(push_contact_to) {
            this.sequence.push_contact_to = push_contact_to

            // remove the error message if push_contact_to is valid
            if (push_contact_to) {
                this.errors.clear('push_contact_to')
            }
        },

        changeRingGroup(ring_group_id) {
            this.sequence.ring_group_id = ring_group_id
        },

        changeDispositionStatus(disposition_status_id) {
            this.sequence.disposition_status_id = disposition_status_id
        },

        changeRoundRobinSettings(value) {
            if (!value) {
                this.sequence.ring_group_id = null
            }
        },

        changeContactList(value) {
            this.sequence.contact_list_id = value

            if (value) {
                this.errors.clear('contact_list_id')
            }
        },

        disableSequenceTypeOption(type) {
            // For email and SMS steps or Bulk Messaging
            return this.emailStepsDisabled(type) ||
                this.smsStepsDisabled(type) ||
                this.messagingDisabled(type) ||
                this.aloAiBotDisabled(type) ||
                this.progressiveDialerTrialDisabled(type) ||
                this.progressiveDialerDisabled(type) ||
                this.sendRinglessVoicemailDisabled(type)
        },

        emailStepsDisabled(type) {
            if (
                this.workflow
                && this.current_company
                && [Sequence.TYPE_EMAIL_LEAD, Sequence.TYPE_EMAIL_RING_GROUP].includes(type)
                // Email sending conditions
                && !this.workflow.has_email_intake_route
                && !this.current_company.sendgrid_integration_enabled
                && !this.current_company.mailgun_integration_enabled
                && !this.current_company.smtp_integration_enabled
            ) {
                return true
            }

            return false
        },

        smsStepsDisabled(type) {
            return !this.current_company.sms_enabled && [Sequence.TYPE_SMS_RING_GROUP, Sequence.TYPE_SMS_LEAD, Sequence.TYPE_MMS].includes(type)
        },

        checkDistributeTo() {
            if (this.sequence.distribute_to === Sequence.ASSIGN_TO_USER) {
                this.sequence.ring_group_id = null
                this.sequence.assign_to_available_users = false
            } else if (this.sequence.distribute_to === Sequence.ASSIGN_TO_RING_GROUP) {
                this.sequence.user_id = null
                this.sequence.add_to_contact_owner = false
            }
        },

        messagingDisabled(type) {
            if (![Sequence.TYPE_SMS_RING_GROUP, Sequence.TYPE_SMS_LEAD, Sequence.TYPE_MMS].includes(type)) {
                return false
            }

            // Allow to interact with UI sequences on Trial, handle it on backend
            if (this.isTrial) {
                return false
            }

            return this.blockedMessagingInformation['blocked']
        },

        updateIsOptoutActive(event) {
            this.is_optout_active = event
        },

        aloAiBotDisabled(type) {
            if (type !== Sequence.TYPE_START_ALOAI_CONVERSATION) {
                return false
            }

            return !this.current_company.aloai_enabled
        },

        progressiveDialerTrialDisabled(type) {
            if (type !== Sequence.TYPE_CALL_LEAD) {
                return false
            }

            return this.isTrial
        },

        progressiveDialerDisabled(type) {
            if (type !== Sequence.TYPE_CALL_LEAD) {
                return false
            }

            return !this.current_company.progressive_dialer_enabled
        },

        sendRinglessVoicemailDisabled(type) {
            if (type != Sequence.TYPE_RVM) {
                return false
            }

            return this.isTrial
        },

        changeAloAiType(type) {
            this.sequence.aloai_agent_type = type
            // Clear the selected bot when changing types
            this.sequence.aloai_bot_id = null

            if (type) {
                this.errors.clear('aloai_agent_type')
            }
        }
    }
}
</script>
