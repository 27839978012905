const base_path = `/api/v2/teams`

export default {
    /**
     * Endpoint for fetching teams.
     * params parameter is used to paginate the data that will be fetch
     * @param params {Object} - { page: 1, per_page: 10 }
     * @handler Api/V2/Teams/TeamController@index
     * @return Promise
     */
    index(params) {
        return axios.get(`${base_path}`, { params })
    },

    /**
     * Endpoint for fetching a team.
     * @param id {Number} - Team ID
     * @handler Api/V2/Teams/TeamController@show
     * @returns Promise
     */
    show(id) {
        return axios.get(`${base_path}/${id}`)
    },

    /**
     * Endpoint for creating a team.
     * @param data {Object} - { name: 'Team Name', description: 'Team Description'}
     * @handler Api/V2/Teams/TeamController@store
     * @returns Promise
     */
    store(data) {
        return axios.post(`${base_path}`, data)
    },

    /**
     * Endpoint for deleting a team.
     * @param id {Number} - Team ID
     * @handler Api/V2/Teams/TeamController@destroy
     * @returns Promise
     */
    destroy(id) {
        return axios.delete(`${base_path}/${id}`)
    },

    /**
     * Endpoint for updating a team.
     * @param id {Number} - Team ID
     * @param data {Object} - { id: 1, name: 'Team Name', description: 'Team Description'}
     * @handler Api/V2/Teams/TeamController@update
     * @returns Promise
     */
    update(id, data) {
        return axios.put(`${base_path}/${id}`, data)
    },

    /**
     * Endpoint for adding users to a team.
     * @param id {Number} - Team ID
     * @param data {Object} - { users_id: [1, 2, 3]}
     * @handler Api/V2/Teams/TeamController@addUsers
     * @returns Promise
     */
    addUsers(id, data) {
        return axios.post(`${base_path}/${id}/users`, data)
    },

    /**
     * Endpoint for removing users from a team.
     * @param id {Number} - Team ID
     * @param data {Object} - { users_id: [1, 2, 3]}
     * @handler Api/V2/Teams/TeamController@removeUsers
     * @returns Promise
     */
    removeUsers(id, data) {
        return axios.delete(`${base_path}/${id}/users`, { data })
    },

    /**
     * Endpoint for adding supervisors to a team.
     * @param id {Number} - Team ID
     * @param data {Object} - { supervisors_ids: [1, 2, 3]}
     * @handler Api/V2/Teams/TeamController@addSupervisors
     * @returns Promise
     */
    addSupervisors(id, data) {
        return axios.post(`${base_path}/${id}/supervisors`, data)
    },

    /**
     * Endpoint for removing supervisors from a team.
     * @param id {Number} - Team ID
     * @param data {Object} - { supervisors_ids: [1, 2, 3]}
     * @handler Api/V2/Teams/TeamController@removeSupervisors
     * @returns Promise
     */
    removeSupervisors(id, data) {
        return axios.delete(`${base_path}/${id}/supervisors`, { data })
    },

    /**
     * Endpoint for fetching teams to fill a select dropdown.
     * @handler Api/V2/Teams/TeamController@list
     * @returns Promise
     */
    async list() {
        const res = await axios.get(`${base_path}/list`)
        return res.data
    }
}
