var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex items-center justify-content-center w-full" },
    [
      !_vm.isSubmitted && !_vm.isSubmitSuccess
        ? _c("div", { staticClass: "width-500" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "signup-wrapper" },
              [
                _c("div", { staticClass: "login-brand" }, [
                  _c("img", { attrs: { src: _vm.statics.logo, alt: "." } }),
                ]),
                _vm._v(" "),
                _c(
                  "el-form",
                  {
                    ref: "signupForm",
                    staticClass: "mt-3",
                    attrs: {
                      "label-position": "top",
                      "label-width": "300px",
                      model: _vm.ssu,
                      rules: _vm.rules,
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "email" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "Work email",
                              type: "email",
                              loading: _vm.isValidatingEmail,
                            },
                            model: {
                              value: _vm.ssu.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.ssu, "email", $$v)
                              },
                              expression: "ssu.email",
                            },
                          },
                          [
                            _vm.isValidatingEmail
                              ? _c("template", { slot: "append" }, [
                                  _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons loader pull-right",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                \n                            "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { staticClass: "p-0" },
                      [
                        _c(
                          "el-col",
                          { staticClass: "p-0 pr-3", attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "first_name" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "First name" },
                                  model: {
                                    value: _vm.ssu.first_name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ssu, "first_name", $$v)
                                    },
                                    expression: "ssu.first_name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { staticClass: "p-0", attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "last_name" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "Last name" },
                                  model: {
                                    value: _vm.ssu.last_name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ssu, "last_name", $$v)
                                    },
                                    expression: "ssu.last_name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "company_name" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Company name" },
                          model: {
                            value: _vm.ssu.company_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.ssu, "company_name", $$v)
                            },
                            expression: "ssu.company_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "team_size" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "no-select w-100",
                            attrs: { placeholder: "Team size", clearable: "" },
                            model: {
                              value: _vm.ssu.team_size,
                              callback: function ($$v) {
                                _vm.$set(_vm.ssu, "team_size", $$v)
                              },
                              expression: "ssu.team_size",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "1 => Just Me 👻", value: "1" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: {
                                label: "2-5 => We’re starting 📞",
                                value: "2-5",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: {
                                label: "6-20 => It's quite a team 🎯",
                                value: "6-20",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: {
                                label: "21-50 => Growing fast 🚀",
                                value: "21-50",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: {
                                label:
                                  "50+ => We’re bigger than Aloware, call us fast 👔",
                                value: "50+",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("phone", {
                      attrs: { "has-error": _vm.hasPhoneNumberError },
                      on: { input: _vm.updatePhoneNumber },
                    }),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { prop: "phone_number" } }, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.hasPhoneNumberError,
                              expression: "hasPhoneNumberError",
                            },
                          ],
                          staticClass: "el-form-item__error",
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.validatePhoneNumber()) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        staticClass: "mt-3 signup-checkbox__container",
                        model: {
                          value: _vm.ssu.terms_and_conditions,
                          callback: function ($$v) {
                            _vm.$set(_vm.ssu, "terms_and_conditions", $$v)
                          },
                          expression: "ssu.terms_and_conditions",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    I affirm that I've reviewed and accepted Aloware's\n                    "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "signup-checkbox__link",
                            attrs: {
                              href: "https://aloware.com/terms-and-conditions",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Terms and Conditions\n                    "
                            ),
                          ]
                        ),
                        _vm._v(
                          "\n                    and\n                    "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "signup-checkbox__link",
                            attrs: {
                              href: "https://aloware.com/privacy-policy",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Privacy Policy.\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        staticClass: "mt-3 signup-checkbox__container",
                        model: {
                          value: _vm.ssu.communications,
                          callback: function ($$v) {
                            _vm.$set(_vm.ssu, "communications", $$v)
                          },
                          expression: "ssu.communications",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    By checking this box, I agree to receive communications, including email, calls, and text messages from Aloware regarding announcements and company updates.\n                    Reply to any messages with STOP at any time to stop receiving messages and request for help by replying HELP.\n                    The frequency of messages varies. Message and data rates may apply.\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.site_key
                      ? _c("vue-recaptcha", {
                          ref: "recaptcha",
                          staticClass: "g-recaptcha pt-3",
                          attrs: { sitekey: _vm.site_key },
                          on: { verify: _vm.onVerify, expired: _vm.onExpired },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn-block mt-3 signup-submit__button",
                        attrs: {
                          type: "primary",
                          size: "large",
                          disabled: _vm.isStartTrialButtonDisabled,
                        },
                        on: { click: _vm.onSubmit },
                      },
                      [
                        _c(
                          "i",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isLoading,
                                expression: "isLoading",
                              },
                            ],
                            staticClass: "material-icons loader pull-right",
                          },
                          [
                            _vm._v(
                              "\n                        \n                    "
                            ),
                          ]
                        ),
                        _vm._v(
                          "\n                    Start Trial\n                    "
                        ),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isLoading,
                              expression: "!isLoading",
                            },
                          ],
                          staticClass:
                            "el-icon-arrow-right el-icon-right pull-right",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm.isSubmitted && !_vm.isSubmitSuccess && !_vm.submissionError
        ? _c("div", { staticClass: "signup-wrapper__success" }, [_vm._m(1)])
        : _vm.isSubmitted && !_vm.isSubmitSuccess && _vm.submissionError
        ? _c("div", { staticClass: "signup-wrapper__success" }, [
            _c("div", [
              _c("h3", { staticClass: "signup-wrapper__success__title" }, [
                _vm._v(_vm._s(_vm.submissionError)),
              ]),
              _vm._v(" "),
              _vm._m(2),
            ]),
          ])
        : _c("div", { staticClass: "signup-wrapper__success" }, [_vm._m(3)]),
      _vm._v(" "),
      _c("static-assets-preloader", { attrs: { assets: _vm.statics } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("h3", { staticClass: "signup--title" }, [
        _vm._v("Welcome to Aloware"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "signup--subtitle" }, [
        _vm._v("Start your trial, "),
        _c("strong", [_vm._v("no credit card required.")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h3", { staticClass: "signup-wrapper__success__title" }, [
        _vm._v(
          "\n                Sorry, we couldn’t verify your business information.\n            "
        ),
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("p", [
        _vm._v("\n                Please call us at: (855) 256-2001 "),
        _c("br"),
        _vm._v("\n                or email us at: "),
        _c("a", { attrs: { href: "mailto:sales@aloware.com" } }, [
          _vm._v("sales@aloware.com"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "signup-wrapper__success__subtitle" }, [
      _c("a", { attrs: { href: "/login" } }, [
        _vm._v(
          "\n                    Click here to proceed to the login page\n                "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h3", { staticClass: "signup-wrapper__success__title" }, [
        _vm._v("Your trial account is almost ready!"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "signup-wrapper__success__subtitle" }, [
        _vm._v("Please check your inbox and confirm your email"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }