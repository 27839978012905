var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      staticClass: "p-0",
      attrs: {
        width: "400",
        trigger: "click",
        "popper-class": "variable-popover",
        placement: _vm.popoverPlacement,
      },
      on: { show: _vm.popoverShow, hide: _vm.popoverHide },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("campaign-selector", {
              attrs: {
                check_blocked_messaging: "",
                is_automated: "",
                show_paused: false,
              },
              on: {
                input: function ($event) {
                  return _vm.emitSelectedCampaign($event)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-button",
        {
          class: _vm.variableBtnClass,
          attrs: {
            slot: "reference",
            id: "campaigns-popover-trigger",
            size: "mini",
            circle: "",
            disabled: _vm.disabled,
          },
          slot: "reference",
        },
        [_c("i", { class: "text-primary " + _vm.icon })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }