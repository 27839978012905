var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-card", [
    _vm.isLoading
      ? _c("div", { staticClass: "text-center mt-2" }, [
          _c("h5", [
            _c("i", { staticClass: "fa fa-spinner fa-spin" }),
            _vm._v(" "),
            _c("span", [_vm._v("Loading...")]),
          ]),
        ])
      : _c(
          "div",
          [
            _c(
              "el-form",
              {
                staticClass: "text-left",
                attrs: { name: `${_vm.integration}-property-mapping` },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between" },
                  [
                    _c("h5", [_vm._v("Default Fields")]),
                    _vm._v(" "),
                    _c("el-switch", {
                      staticClass: "mt-1",
                      attrs: { "active-text": "Enable editing" },
                      model: {
                        value: _vm.editingEnabled,
                        callback: function ($$v) {
                          _vm.editingEnabled = $$v
                        },
                        expression: "editingEnabled",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.defaultPropertyMappings, function (prop, index) {
                  return _c(
                    "div",
                    {
                      key: `${index}-default`,
                      staticClass: "row form-group mt-3",
                    },
                    [
                      index === 0
                        ? [
                            _c("div", { staticClass: "col-md-4 mb-1" }, [
                              _c("small", { staticClass: "font-weight-bold" }, [
                                _vm._v("Aloware"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-2 mb-1 text-center" },
                              [
                                _c(
                                  "small",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("Direction")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-4 mb-1" }, [
                              _c("small", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(_vm.getIntegrationTitleName())),
                              ]),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c(
                            "el-form-item",
                            { staticClass: "mb-1" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "w-full",
                                  attrs: {
                                    filterable: "",
                                    disabled:
                                      !prop.is_mappable || !_vm.editingEnabled,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleSelectionChange(
                                        index,
                                        prop,
                                        "aloware",
                                        "default"
                                      )
                                    },
                                  },
                                  model: {
                                    value: prop.aloware_key,
                                    callback: function ($$v) {
                                      _vm.$set(prop, "aloware_key", $$v)
                                    },
                                    expression: "prop.aloware_key",
                                  },
                                },
                                _vm._l(
                                  _vm.alowareDefaultOptions,
                                  function (option) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: option["key"],
                                        attrs: {
                                          label: option["label"],
                                          disabled:
                                            !option["is_mappable"] ||
                                            _vm.isSelectedOption(
                                              option["key"],
                                              prop.aloware_key
                                            ),
                                          value: option["key"],
                                        },
                                      },
                                      [
                                        _vm.isSelectedOption(
                                          option["key"],
                                          prop.aloware_key
                                        )
                                          ? _c("i", {
                                              staticClass:
                                                "fa fa-ban text-grey-5",
                                            })
                                          : _vm._e(),
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              option.direction ===
                                                _vm
                                                  .IntegrationPropertyMapDirections
                                                  .DIRECTION_OUTBOUND
                                                ? `${option.label} (outbound-only)`
                                                : `${option.label}`
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-2 text-center" },
                        [
                          _c("direction-selector", {
                            attrs: {
                              "initial-state": prop.direction,
                              disabled: _vm.shouldDisableDirection(
                                index,
                                prop,
                                "default"
                              ),
                            },
                            on: {
                              change: (direction) =>
                                _vm.updateDefaultDirection(index, direction),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c(
                            "el-form-item",
                            { staticClass: "mb-1" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "w-full",
                                  attrs: {
                                    filterable: "",
                                    disabled:
                                      !prop.is_mappable || !_vm.editingEnabled,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleSelectionChange(
                                        index,
                                        prop,
                                        "crm",
                                        "default"
                                      )
                                    },
                                  },
                                  model: {
                                    value: prop.external_key,
                                    callback: function ($$v) {
                                      _vm.$set(prop, "external_key", $$v)
                                    },
                                    expression: "prop.external_key",
                                  },
                                },
                                _vm._l(
                                  _vm.hubspotPropertiesOptions,
                                  function (group) {
                                    return _c(
                                      "el-option-group",
                                      {
                                        key: group.label,
                                        attrs: { label: group.label },
                                      },
                                      _vm._l(group.data, function (option) {
                                        return _c(
                                          "el-option",
                                          {
                                            key: option.name,
                                            attrs: {
                                              label: option.label,
                                              value: option.name,
                                              disabled:
                                                !option.is_mappable ||
                                                _vm.isSelectedOption(
                                                  option.name,
                                                  prop.external_key,
                                                  _vm.integration
                                                ),
                                            },
                                          },
                                          [
                                            _vm.isSelectedOption(
                                              option.name,
                                              prop.external_key,
                                              _vm.integration
                                            )
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-ban text-grey-5",
                                                })
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  option.direction ===
                                                    _vm
                                                      .IntegrationPropertyMapDirections
                                                      .DIRECTION_INBOUND
                                                    ? `${option.label} (${option.field_type}, read-only)`
                                                    : `${option.label} (${option.field_type})`
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      prop.is_mappable
                        ? _c(
                            "div",
                            { staticClass: "col-md-2 text-center" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "small",
                                    circle: "",
                                    plain: "",
                                    disabled: !_vm.editingEnabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeDefaultPropertyMapping(
                                        index
                                      )
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fa fa-trash" })]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "col-md-2 text-center mt-2" },
                            [
                              _c("i", {
                                staticClass: "fa fa-lock fa-lg text-grey-4",
                              }),
                            ]
                          ),
                    ],
                    2
                  )
                }),
                _vm._v(" "),
                _c("div", { staticClass: "row form-group" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-10 text-center" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "small",
                            disabled: !_vm.editingEnabled,
                          },
                          on: { click: _vm.addDefaultPropertyMapping },
                        },
                        [
                          _c("i", { staticClass: "fa fa-plus-circle" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml-1 font-weight-bold" }, [
                            _vm._v("ADD MAPPING"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.integration === "hubspot"
                  ? [
                      _c("h5", [_vm._v("Custom Fields")]),
                      _vm._v(" "),
                      _vm._l(
                        _vm.customPropertyMappings,
                        function (prop, index) {
                          return _c(
                            "div",
                            {
                              key: `${index}-custom`,
                              staticClass: "row form-group mt-3",
                            },
                            [
                              index === 0
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-4 mb-1" },
                                      [
                                        _c(
                                          "small",
                                          { staticClass: "font-weight-bold" },
                                          [_vm._v("Aloware")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-md-2 mb-1 text-center",
                                      },
                                      [
                                        _c(
                                          "small",
                                          { staticClass: "font-weight-bold" },
                                          [_vm._v("Direction")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-4 mb-1" },
                                      [
                                        _c(
                                          "small",
                                          { staticClass: "font-weight-bold" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getIntegrationTitleName()
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-4" },
                                [
                                  _c(
                                    "el-form-item",
                                    { staticClass: "mb-1" },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "w-full",
                                          attrs: {
                                            filterable: "",
                                            disabled:
                                              !prop.is_mappable ||
                                              !_vm.editingEnabled,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.handleSelectionChange(
                                                index,
                                                prop,
                                                "aloware",
                                                "custom"
                                              )
                                            },
                                          },
                                          model: {
                                            value: prop.aloware_key,
                                            callback: function ($$v) {
                                              _vm.$set(prop, "aloware_key", $$v)
                                            },
                                            expression: "prop.aloware_key",
                                          },
                                        },
                                        _vm._l(
                                          _vm.alowareCustomOptions,
                                          function (option) {
                                            return _c(
                                              "el-option",
                                              {
                                                key: option["key"],
                                                attrs: {
                                                  label: option["label"],
                                                  disabled:
                                                    !option["is_mappable"] ||
                                                    _vm.isSelectedOption(
                                                      option["key"],
                                                      prop.aloware_key
                                                    ),
                                                  value: option["key"],
                                                },
                                              },
                                              [
                                                _vm.isSelectedOption(
                                                  option["key"],
                                                  prop.aloware_key
                                                )
                                                  ? _c("i", {
                                                      staticClass:
                                                        "fa fa-ban text-grey-5",
                                                    })
                                                  : _vm._e(),
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(option["label"]) +
                                                    "\n                            "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-2 text-center" },
                                [
                                  _c("direction-selector", {
                                    attrs: {
                                      "initial-state": prop.direction,
                                      disabled:
                                        !prop.is_mappable ||
                                        !_vm.editingEnabled ||
                                        _vm.shouldDisableDirection(
                                          index,
                                          prop,
                                          "custom"
                                        ),
                                    },
                                    on: {
                                      change: (direction) =>
                                        _vm.updateCustomDirection(
                                          index,
                                          direction
                                        ),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-4" },
                                [
                                  _c(
                                    "el-form-item",
                                    { staticClass: "mb-1" },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "w-full",
                                          attrs: {
                                            filterable: "",
                                            disabled:
                                              !prop.is_mappable ||
                                              !_vm.editingEnabled,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.handleSelectionChange(
                                                index,
                                                prop,
                                                "crm",
                                                "custom"
                                              )
                                            },
                                          },
                                          model: {
                                            value: prop.external_key,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                prop,
                                                "external_key",
                                                $$v
                                              )
                                            },
                                            expression: "prop.external_key",
                                          },
                                        },
                                        _vm._l(
                                          _vm.hubspotPropertiesOptions,
                                          function (group) {
                                            return _c(
                                              "el-option-group",
                                              {
                                                key: group.label,
                                                attrs: { label: group.label },
                                              },
                                              _vm._l(
                                                group.data,
                                                function (option) {
                                                  return _c(
                                                    "el-option",
                                                    {
                                                      key: option.name,
                                                      attrs: {
                                                        label: option.label,
                                                        value: option.name,
                                                        disabled:
                                                          !option.is_mappable ||
                                                          _vm.isSelectedOption(
                                                            option.name,
                                                            prop.external_key,
                                                            _vm.integration
                                                          ),
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleSelectionChange(
                                                            index,
                                                            prop,
                                                            "crm",
                                                            "custom"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.isSelectedOption(
                                                        option.name,
                                                        prop.external_key,
                                                        _vm.integration
                                                      )
                                                        ? _c("i", {
                                                            staticClass:
                                                              "fa fa-ban text-grey-5",
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(
                                                        "\n                                  " +
                                                          _vm._s(
                                                            option.direction ===
                                                              _vm
                                                                .IntegrationPropertyMapDirections
                                                                .DIRECTION_INBOUND
                                                              ? `${option.label} (${option.field_type}, read-only)`
                                                              : `${option.label} (${option.field_type})`
                                                          ) +
                                                          "\n                                "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              prop.is_mappable
                                ? _c(
                                    "div",
                                    { staticClass: "col-md-2 text-center" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            circle: "",
                                            plain: "",
                                            disabled: !_vm.editingEnabled,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeCustomPropertyMapping(
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-trash",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass: "col-md-2 text-center mt-2",
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-lock fa-lg text-grey-4",
                                      }),
                                    ]
                                  ),
                            ],
                            2
                          )
                        }
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "row form-group" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-10 text-center" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  disabled: !_vm.editingEnabled,
                                },
                                on: { click: _vm.addCustomPropertyMapping },
                              },
                              [
                                _c("i", { staticClass: "fa fa-plus-circle" }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "ml-1 font-weight-bold" },
                                  [_vm._v("ADD MAPPING")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }