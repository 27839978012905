<template>
    <el-dialog :show-close="false"
               :visible.sync="open"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :destroy-on-close="true"
               @open="resetDialog"
               top="10vh"
               width="60%">
        <template #title="{ close, titleId, titleClass }">
            <div class="my-header">
                <h4 :id="titleId"
                    :class="titleClass">{{ dialogTitle }}</h4>
                <div>
                    <el-button type="danger"
                               @click="$emit('close')">
                        Close
                    </el-button>
                    <el-button type="primary"
                               :disabled="loading"
                               @click="submitForm">
                        Save
                    </el-button>
                </div>
            </div>
        </template>
        <el-form ref="custom_function_settings"
                 class="custom-function"
                 :rules="rules"
                 :model="tool"
        >
            <!-- Setup -->
            <el-card>
                <template #header>
                    <h6>Set up your custom function details</h6>
                    <small>Tell your AloAi Agent what this custom function is for, when to use it, and what to say when it's running.</small>
                </template>
                <div class="row justify-content-center">
                    <div class="col-12">
                        <el-form-item prop="name">
                            <div class="form-label">
                                <h5 class="text-dark">Function name</h5>
                                <small>
                                    What name would you like to give to this custom function?
                                </small>
                            </div>
                            <el-input
                                v-model="tool.name"
                                @input="preValidateForm('custom_function_settings')"
                            />
                        </el-form-item>

                        <el-form-item prop="description">
                            <div class="form-label">
                                <h5 class="text-dark">Function description</h5>
                                <small>Give a description to help the agent understand what this custom function does.</small>
                            </div>
                            <el-input
                                type="textarea"
                                :rows="2"
                                v-model="tool.description"
                            />
                        </el-form-item>

                        <el-form-item prop="type">
                            <div class="form-label">
                                <h5 class="text-dark">Reply to the contact during execution?</h5>
                                <small>Use this option if you want the agent to say something during the function execution. This is useful when the execution takes more than 3 seconds.</small>
                            </div>
                            <el-switch v-model="tool.reply_during_execution" />
                        </el-form-item>
                    
                        <el-form-item prop="type">
                            <div class="form-label">
                                <h5 class="text-dark">Reply to the contact after execution?</h5>
                                <small>Use this option if you want the agent to say something after the function execution.</small>
                            </div>
                            <el-switch v-model="tool.reply_after_execution" />
                        </el-form-item>
                    </div>
                </div>
            </el-card>

            <!-- Variables -->
            <el-card class="mt-3">
                <template #header>
                    <h6>Variables</h6>
                    <small>These are the inputs this agent will request the contact to provide in the middle of the conversation.</small>
                </template>
                <div class="row justify-content-center">
                    <div class="col-12">
                        <el-row v-if="tool.variables.length > 0">
                            <el-col :span="2">
                                <div class="form-label">
                                    <h5 class="text-dark invisible">Actions</h5>
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div class="form-label">
                                    <h5 class="text-dark">Name</h5>
                                    <small>Tell what this variable is called.</small>
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div class="form-label">
                                    <h5 class="text-dark">Description</h5>
                                    <small>Tell what this variable is for.</small>
                                </div>
                            </el-col>
                            <el-col :span="5">
                                <div class="form-label">
                                    <h5 class="text-dark">Type</h5>
                                    <small>What type is this?</small>
                                </div>
                            </el-col>
                            <el-col :span="5">
                                <div class="form-label">
                                    <h5 class="text-dark">Required</h5>
                                    <small>Is required to execute?</small>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row class="mt-2"
                                :gutter="20"
                                v-for="(variable, index) in tool.variables">
                            <el-col :span="2"
                                    class="text-center">
                                <button class="btn btn-sm danger"
                                        @click.stop.prevent="removeVariable(index)">
                                    <i class="fa fa-trash pull-left"></i>
                                </button>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item prop="name">                                    
                                    <el-input
                                        v-model="variable.name"
                                        @blur="sanitizeVariableName(index,variable.name)"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item prop="description">
                                    <el-input v-model="variable.description"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="5">
                                <el-form-item prop="type">
                                    <el-select v-model="variable.type"
                                                class="w-100">
                                        <el-option
                                            v-for="type in types"
                                            :key="type"
                                            :label="type"
                                            :value="type"
                                        />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="5">
                                <el-form-item prop="required">
                                    <el-select v-model="variable.required"
                                                class="w-100">
                                        <el-option
                                            v-for="required in [{ key: true, label: 'Yes' }, { key: false, label: 'No' }]"
                                            :key="required.key"
                                            :label="required.label"
                                            :value="required.key"
                                        />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-button type="primary"
                                    plain size="mini"
                                    icon="el-icon-plus"
                                    class="my-2"
                                    @click="addVariable">
                            Add variable
                        </el-button>
                    </div>
                </div>
            </el-card>            
            <generic-webhook-form ref="generic_webhook_form"
                                    :webhook="tool.tool_parameters"
                                    :variables="availableVariables">
            </generic-webhook-form>
        </el-form>
    </el-dialog>
</template>

<script>
import {acl_mixin, form_validation_mixin} from '../../../mixins';
import GenericWebhookForm from '../../generic-webhook/form.vue';

export default {
    name: 'custom-function',

    mixins: [form_validation_mixin, acl_mixin],

    components: {
        GenericWebhookForm,
    },
    props: {
        tool: {
            required: false,
            type: Object,
            default: () => ({
                uuid: null,
                name: '',
                description: '',
                reply_during_execution: false,
                reply_after_execution: false,
                variables: [],
                tool_parameters: undefined
            })
        },
        open: {
            type: Boolean,
            required: true,
            default: false,
        }
    },

    data() {
        return {
            loading: false,
            activeName: 'basic',
            types: [
                'string',
                'number',
            ],
            rules: {
                name: [
                    {
                        required: true,
                        message: 'Please provide a name for the custom function',
                        trigger: 'blur'
                    }
                ],
                description: [
                    {
                        required: true,
                        message: 'Please provide a description for the custom function',
                        trigger: 'blur'
                    }
                ],
                reply_during_execution: [
                    {
                        required: true,
                        message: 'Please provide if the custom function should reply during execution',
                        trigger: 'blur'
                    }
                ],
                reply_after_execution: [
                    {
                        required: true,
                        message: 'Please provide if the custom function should reply after execution',
                        trigger: 'blur'
                    }
                ],
                variables: [
                    {
                        required: false,
                        type: 'array',
                        len: 0,
                        fields: {
                            name: {
                                required: true,
                                message: 'Please provide a name for the variable',
                            },
                            type: {
                                required: true,
                                message: 'Please provide a type for the variable',
                            },
                            description: {
                                required: true,
                                message: 'Please provide a description for the variable',
                            },
                            required: {
                                required: true,
                                message: 'Please provide a required value for the variable',
                            },
                        }
                    }
                ]
            },
            tab_errors: {
                basic: false,
                variables: false,
            },
        }
    },    
    computed: {
        dialogTitle() {
            return this.tool.uuid ? 'Edit Custom Function' : 'Create Custom Function'
        },
        availableVariables() {
            return this.tool?.variables?.filter(variable => variable.name !== '').map(variable => variable.name);
        }
    },
    methods: {
        resetDialog() {
            this.activeName = 'basic'  
        },
        sanitizeVariableName(index, name) {
            this.tool.variables[index].name = name.replace(/[^a-zA-Z0-9]/g, '_').toLowerCase();
        },
        removeVariable(index) {
            this.tool.variables = this.tool.variables.filter((_, i) => i !== index)
        },
        store() {
            axios({
                method: this.tool.uuid ? 'PUT' : 'POST',
                url: this.tool.uuid ? `/api/v1/aloai/tools/${this.tool.uuid}` : `/api/v1/aloai/tools`,
                data: {
                    ...this.tool,
                    tool_type: 1,
                    tool_parameters: {
                        ...this.$refs.generic_webhook_form.getFormData(),
                        name: this.tool.name,
                        description: this.tool.description,
                    }
                }
            })
                .then((response) => {
                    this.$notify.success({
                        offset: 95,
                        title: 'AloAi Custom Function',
                        message: 'Custom function created successfully.',
                    })

                    this.$emit('close')

                    this.clearForm()
                })
                .catch((error) => {
                    this.$notify.error({
                        offset: 95,
                        title: 'AloAi Custom Function',
                        message: error.response?.data?.message ?? 'There was an error creating the custom function'
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        submitForm() {
            this.loading = true

            const isFormValidate = this.validateForm('custom_function_settings') === true;
            const isWebhookFormValid = this.$refs.generic_webhook_form.isFormValidate();

            if (isFormValidate && isWebhookFormValid) {
                this.store()
            } else {
                let errors = this.getFormErrorsMessage('custom_function_settings')

                if (!isWebhookFormValid) {
                    this.tab_errors.configure = true
                    errors += this.$refs.generic_webhook_form.getFormErrors()
                }

                this.error_notification = this.$notify({
                    offset: 95,
                    title: 'AloAi Custom Function',
                    dangerouslyUseHTMLString: true,
                    message: errors,
                    type: 'error',
                    showClose: true,
                    duration: 5000,
                })
                this.loading = false
            }
        },
        addVariable: function () {
            this.tool.variables.push({
                name: '',
                type: '',
                description: '',
                required: false,
            });
        },
        handleClick(tab, event) {
        },
        clearForm() {
            const form_element = _.get(this.$refs, 'custom_function_settings', null)
            form_element.resetFields()

            this.$refs.generic_webhook_form.clearForm();
        },
        getFormErrorsMessage(form_name) {
            let form_element = _.get(this.$refs, form_name, null)
            let err_message = '<div class="mb-2">Please resolve custom function configuration errors</div>'

            form_element.fields.forEach((element) => {
                if (element.validateState === 'error') {
                    // get the parent tab pane of the element
                    let parent = element.$parent

                    while (parent && parent.$options._componentTag !== 'el-tab-pane') {
                        parent = parent.$parent
                    }

                    const tab = parent.$attrs['data-tab-error'] || parent.name.replace('-', '_')
                    const tab_label = parent.$slots.label[0].children[0].text

                    // show the error icon on the element's parent tab pane
                    if (tab !== "") {
                        this.tab_errors[tab] = true
                    }

                    err_message += `<div class="mb-2"><i class="fa fa-remove text-danger mr-2"></i> ${tab_label} > ${element.validateMessage}</div>`
                }
            })

            return err_message
        },

        tabLabelErrorIcon(tab_error) {
            if (tab_error) {
                return ' <i class="fa fa-exclamation-circle text-danger ml-1"></i>'
            }

            return ''
        },

        resetTabErrors() {
            this.tab_errors = {
                basic: false,
                variables: false,
            }
        },

        closeErrorNotification() {
            if (this.error_notification) {
                this.error_notification.close()
            }
        },
    },
}
</script>
<style scoped>
.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
}
</style>
