<template>
    <div v-if="communication">
        <el-collapse v-model="activeName"
                     class="border-0 contact-activity"
                     accordion>
            <el-collapse-item name="main">
                <template slot="title">
                    <div class="text-lt p-x">
                        <span>
                            <el-tooltip class="item"
                                        effect="dark"
                                        v-if="communication.disposition_status2"
                                        :content="dispositionTooltipData(communication.disposition_status2, communication.direction, communication.type, communication.callback_status)"
                                        placement="bottom">
                                <span class="mr-2"
                                      :state="communication.disposition_status2"
                                      v-html="stateToIcon(communication.disposition_status2, communication.direction, communication.type, communication.callback_status)">
                                </span>
                            </el-tooltip>

                            <span v-if="![CommunicationTypes.NOTE, CommunicationTypes.SYSNOTE, CommunicationTypes.APPOINTMENT, CommunicationTypes.REMINDER].includes(communication.type)">{{
                                    communication.direction | fixCommDirection
                                }}</span>
                            {{ communication.type | fixCommType }}
                        </span>
                        <span v-if="communication.type === CommunicationTypes.CALL && activity_mode">
                            - {{ communication.duration | fixDuration }}
                        </span>
                    </div>
                </template>

                <div class="p-x p-t b-t b-light">
                    <template
                        v-if="[CommunicationTypes.SMS, CommunicationTypes.EMAIL, CommunicationTypes.NOTE, CommunicationTypes.SYSNOTE, CommunicationTypes.APPOINTMENT, CommunicationTypes.REMINDER].includes(communication.type)">
                        <div v-if="communication.attachments && communication.attachments.length > 0"
                             v-for="(attachment, index) in communication.attachments"
                             :key="index">
                            <vue-load-image v-if="(attachment.mime_type && isAttachmentImage(attachment.mime_type)) || !attachment.mime_type">
                                <img slot="image"
                                     class="img-fluid d-block r-2x"
                                     :class="index > 0 ? 'mb-1' : ''"
                                     :src="attachment.url"/>
                                <img slot="preloader"
                                     src="/assets/images/loading.svg"/>
                                <div slot="error">Error on loading the image attachment</div>
                            </vue-load-image>
                            <template v-if="attachment.mime_type">
                                <div v-if="attachment.mime_type && isAttachmentAudio(attachment.mime_type)">
                                    <audio class="audio-player"
                                           controls>
                                        <source :src="attachment.url"
                                                :type="attachment.mime_type">
                                        Your browser does not support the audio element.
                                    </audio>
                                </div>

                                <div v-if="attachment.mime_type && isAttachmentVideo(attachment.mime_type)">
                                    <video width="320"
                                           class="rounded"
                                           controls>
                                        <source :src="attachment.url"
                                                :type="attachment.mime_type">
                                        Your browser does not support the video tag.
                                    </video>
                                </div>

                                <a :href="attachment.url"
                                   target="_blank"
                                   v-if="attachment.mime_type && (isAttachmentText(attachment.mime_type) || isAttachmentApplication(attachment.mime_type))">
                                    <div class="p-2 text-center">
                                        <figure>
                                            <img height="100"
                                                 width="100"
                                                 src="/assets/images/app-icons/file.svg">
                                            <figcaption>{{ attachment.name ? attachment.name : 'Click Here To Download' }}</figcaption>
                                        </figure>
                                    </div>
                                </a>
                            </template>
                        </div>

                        <div v-if="communication.body">
                            <span class="text-muted"
                                  v-if="communication.type !== CommunicationTypes.SMS"
                                  v-html="$options.filters.nl2br(parsedBody)"
                                  v-linkified:options="{ target: '_blank' }">
                            </span>
                            <span class="text-muted"
                                  v-else
                                  v-linkified:options="{ target: '_blank' }">
                                {{ communication.body }}
                            </span>
                        </div>
                    </template>

                    <span class="text-muted"
                          v-if="communication.type === CommunicationTypes.CALL">
                        This call {{
                            communication.disposition_status2 === CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW ? 'is' : 'was'
                        }} {{ communication.disposition_status2 | translateDispositionStatusText | replaceDash }}.
                    </span>

                    <div class="form-horizontal b-b mt-2"
                         v-if="communication.type === CommunicationTypes.CALL && current_company && call_dispositions.length > 0 && dialer_mode">
                        <div class="form-group mb-2 mt-3">
                            <div class="d-flex align-items-center">
                                <label class="form-control-label p-0">Call Disposition:</label>
                                <el-popover placement="right"
                                            class="ml-1"
                                            width="200"
                                            trigger="click"
                                            v-if="current_company.force_call_disposition"
                                            content="Your account admin has mandated call dispositions.">
                                    <el-button type="text"
                                               class="p-0"
                                               slot="reference">
                                        <i class="el-icon-info text-danger"></i>
                                    </el-button>
                                </el-popover>
                            </div>
                            <div class="d-flex align-items-center pt-2">
                                <call-disposition-selector :communication="communication"
                                                           @callDisposed="callDisposed">
                                </call-disposition-selector>
                            </div>
                        </div>
                        <div v-if="current_company.hubspot_integration_enabled"
                             class="form-group mb-2 mt-3">
                            <div class="d-flex align-items-center">
                                <label class="form-control-label p-0">HubSpot Type:</label>
                            </div>
                            <div class="d-flex align-items-center pt-2">
                                <hubspot-activity-type-selector
                                    :communication="communication"
                                    @activityTypeChosen="activityTypeChosen">
                                </hubspot-activity-type-selector>
                            </div>
                        </div>
                    </div>

                    <div class="form-horizontal b-b mt-2"
                         v-if="verbose || display_contact">
                        <div class="form-group row mb-0"
                             v-if="communication.contact">
                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label">Contact:</label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center">
                                <a target="_blank"
                                   :href="getContactTalkRedirectURL(communication.contact_id)">
                                    <el-tooltip class="item pull-left"
                                                effect="dark"
                                                content="Click For More Info"
                                                placement="top">
                                        <span class="text-dark-greenish">
                                            {{ communication.contact.name | fixContactName }}
                                        </span>
                                    </el-tooltip>
                                </a>
                            </div>
                        </div>

                        <div class="form-group mb-2"
                             v-if="communication.contact && disposition_statuses.length > 0 && dialer_mode && !hide_contact_disposition">
                            <label v-if="hasPermissionTo('dispose contact')"
                                   class="form-control-label p-0">Contact Disposition:</label>
                            <el-popover v-if="current_company.force_contact_disposition"
                                        class="ml-1"
                                        content="Your account admin has mandated contact dispositions."
                                        placement="right"
                                        trigger="click"
                                        width="200">
                                <el-button slot="reference"
                                           class="p-0"
                                           type="text">
                                    <i class="el-icon-info text-danger"></i>
                                </el-button>
                            </el-popover>
                            <div v-if="hasPermissionTo('dispose contact')"
                                 class="d-flex align-items-center pt-2">
                                <contact-disposition-selector :key="'contact-disposition-' + communication.contact_id"
                                                              :contact="communication.contact"
                                                              @contactDisposed="contactDisposed">
                                </contact-disposition-selector>
                            </div>
                        </div>
                    </div>

                    <div class="form-horizontal b-b mt-2"
                         v-if="dialer_mode">
                        <div class="form-group mb-2">
                            <label class="form-control-label p-0">Send message:</label>
                            <send-template :contact_id="communication.contact_id"
                                           :campaign_id="communication.campaign_id"
                                           :phone_number="communication.lead_number">
                            </send-template>
                        </div>
                    </div>

                    <div class="form-horizontal b-b a"
                         v-if="(verbose || activity_mode) && ![CommunicationTypes.NOTE, CommunicationTypes.SYSNOTE, CommunicationTypes.APPOINTMENT, CommunicationTypes.REMINDER].includes(communication.type) && !dialer_mode">
                        <div class="form-group row mb-0">
                            <label class="form-control-label col-xl-5 col-12">Disposition:</label>
                            <div v-if="communication.direction === CommunicationDirections.INBOUND
                            && communication.disposition_status2 === CommunicationDispositionStatus.DISPOSITION_STATUS_ABANDONED_NEW
                            && [CallbackStatus.CALLBACK_STATUS_INITIATED, CallbackStatus.CALLBACK_STATUS_REQUESTED].includes(communication.callback_status)"
                                 class="d-flex align-items-center col-xl-7 col-12"
                            >
                                Callback {{
                                    communication.callback_status | translateCallbackStatusText | replaceDash |
                                        capitalize
                                }}
                            </div>
                            <div v-else class="d-flex align-items-center col-xl-7 col-12">
                                {{
                                    communication.disposition_status2 | translateDispositionStatusText | replaceDash |
                                        capitalize
                                }}
                            </div>
                        </div>
                    </div>

                    <div class="form-horizontal"
                         v-if="(verbose || activity_mode) && ![CommunicationTypes.NOTE, CommunicationTypes.SYSNOTE, CommunicationTypes.APPOINTMENT, CommunicationTypes.REMINDER].includes(communication.type)">
                        <div class="form-group row mb-0">
                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label">From:</label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="form-control-item d-flex align-items-center"
                                 v-if="communication.direction === CommunicationDirections.INBOUND && communication.type !== CommunicationTypes.EMAIL">
                                <div>
                                    <div>{{ communication.lead_number | fixPhone }}</div>
                                    <a target="_blank"
                                       :href="getContactTalkRedirectURL(communication.contact_id)"
                                       v-if="communication.contact">
                                        <el-tooltip class="item pull-left"
                                                    effect="dark"
                                                    content="Click For More Info"
                                                    placement="top">
                                            <div class="text-dark-greenish">
                                                {{ communication.contact.name | fixContactName }}
                                            </div>
                                        </el-tooltip>
                                    </a>
                                </div>
                            </div>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="form-control-item d-flex align-items-center"
                                 v-else-if="communication.direction === CommunicationDirections.INBOUND && communication.type === CommunicationTypes.EMAIL">
                                <div>
                                    <div>{{ communication.lead_number }}</div>
                                    <a target="_blank"
                                       :href="getContactTalkRedirectURL(communication.contact_id)"
                                       v-if="communication.contact">
                                        <el-tooltip class="item pull-left"
                                                    effect="dark"
                                                    content="Click For More Info"
                                                    placement="top">
                                            <div class="text-dark-greenish">
                                                {{ communication.contact.name | fixContactName }}
                                            </div>
                                        </el-tooltip>
                                    </a>
                                </div>
                            </div>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="form-control-item d-flex align-items-center"
                                 v-else-if="communication.direction === CommunicationDirections.OUTBOUND && communication.type === CommunicationTypes.EMAIL">
                                <div>
                                    <div>{{ communication.incoming_number }}</div>
                                    <div class="d-flex"
                                         v-if="usedCampaign">
                                        <router-link
                                            :to="{ name: 'Line Activity', params: { campaign_id: usedCampaign.id }}">
                                            <el-tooltip class="item pull-left"
                                                        effect="dark"
                                                        content="Click For More Info"
                                                        placement="top">
                                                <span class="text-dark-greenish">
                                                    {{ usedCampaign?.name }}
                                                </span>
                                            </el-tooltip>
                                        </router-link>
                                    </div>
                                    <div>
                                        <router-link :to="{ name: 'User Activity', params: {user_id: communication.user_id }}">
                                            <el-tooltip class="item pull-left"
                                                        effect="dark"
                                                        content="Click For More Info"
                                                        placement="top">
                                                <div class="text-dark-greenish"
                                                     :title="getUserName(getUser(communication.user_id, communication?.user))">
                                                    {{ getUserName(getUser(communication.user_id, communication?.user)) }}
                                                </div>
                                            </el-tooltip>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="form-control-item d-flex align-items-center"
                                 v-else>
                                <div>
                                    <div>{{ communication.incoming_number | fixPhone }}</div>
                                    <div class="d-flex">
                                        <router-link
                                            :to="{ name: 'Line Activity', params: { campaign_id: usedCampaign.id }}"
                                            v-if="usedCampaign">
                                            <el-tooltip class="item pull-left"
                                                        effect="dark"
                                                        content="Click For More Info"
                                                        placement="top">
                                                <span class="text-dark-greenish">
                                                    {{ usedCampaign?.name }}
                                                </span>
                                            </el-tooltip>
                                        </router-link>
                                    </div>
                                    <div>
                                        <router-link :to="{ name: 'User Activity', params: { user_id: communication.user_id }}">
                                            <el-tooltip class="item pull-left"
                                                        effect="dark"
                                                        content="Click For More Info"
                                                        placement="top">
                                                <div class="text-dark-greenish"
                                                     :title="getUserName(getUser(communication.user_id, communication?.user))">
                                                    {{ getUserName(getUser(communication.user_id, communication?.user)) }}
                                                </div>
                                            </el-tooltip>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-horizontal b-b"
                         v-if="(verbose || activity_mode) && ![CommunicationTypes.NOTE, CommunicationTypes.SYSNOTE, CommunicationTypes.APPOINTMENT, CommunicationTypes.REMINDER].includes(communication.type)">
                        <div class="form-group row mb-0">
                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label">To:</label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="form-control-item d-flex align-items-center"
                                 v-if="communication.direction === CommunicationDirections.INBOUND && communication.type !== CommunicationTypes.EMAIL">
                                <div>
                                    <div>{{ communication.incoming_number | fixPhone }}</div>
                                    <div class="d-flex"
                                         v-if="usedCampaign">
                                        <router-link
                                            :to="{ name: 'Line Activity', params: { campaign_id: usedCampaign.id }}">
                                            <el-tooltip class="item pull-left"
                                                        effect="dark"
                                                        content="Click For More Info"
                                                        placement="top">
                                                <span class="text-dark-greenish">
                                                    {{ usedCampaign?.name }}
                                                </span>
                                            </el-tooltip>
                                        </router-link>
                                    </div>
                                    <div>
                                        <router-link :to="{ name: 'User Activity', params: {user_id: communication.user_id }}"
                                                     v-if="communication.user_id">
                                            <el-tooltip class="item pull-left"
                                                        effect="dark"
                                                        content="Click For More Info"
                                                        placement="top">
                                                <div class="text-dark-greenish"
                                                     :title="getUserName(getUser(communication.user_id, communication?.user))">
                                                    {{ getUserName(getUser(communication.user_id, communication?.user)) }}
                                                </div>
                                            </el-tooltip>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="form-control-item d-flex align-items-center"
                                 v-else-if="communication.direction === CommunicationDirections.INBOUND && communication.type === CommunicationTypes.EMAIL">
                                <div>
                                    <div>{{ communication.incoming_number | fixPhone }}</div>
                                    <div class="d-flex"
                                         v-if="usedCampaign">
                                        <router-link
                                            :to="{ name: 'Line Activity', params: { campaign_id: usedCampaign.id }}">
                                            <el-tooltip class="item pull-left"
                                                        effect="dark"
                                                        content="Click For More Info"
                                                        placement="top">
                                                <span class="text-dark-greenish">
                                                    {{ usedCampaign?.name }}
                                                </span>
                                            </el-tooltip>
                                        </router-link>
                                    </div>
                                    <a target="_blank"
                                       :href="getContactTalkRedirectURL(communication.contact_id)"
                                       v-if="communication.contact">
                                        <el-tooltip class="item pull-left"
                                                    effect="dark"
                                                    content="Click For More Info"
                                                    placement="top">
                                            <div class="text-dark-greenish">
                                                {{ communication.contact.name | fixContactName }}
                                            </div>
                                        </el-tooltip>
                                    </a>
                                </div>
                            </div>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="form-control-item d-flex align-items-center"
                                 v-else-if="communication.direction === CommunicationDirections.OUTBOUND && communication.type === CommunicationTypes.EMAIL">
                                <div>
                                    <div>{{ communication.lead_number }}</div>
                                    <a target="_blank"
                                       :href="getContactTalkRedirectURL(communication.contact_id)"
                                       v-if="communication.contact">
                                        <el-tooltip class="item pull-left"
                                                    effect="dark"
                                                    content="Click For More Info"
                                                    placement="top">
                                            <div class="text-dark-greenish">
                                                {{ communication.contact.name | fixContactName }}
                                            </div>
                                        </el-tooltip>
                                    </a>
                                </div>
                            </div>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="form-control-item d-flex align-items-center"
                                 v-else>
                                <div>
                                    <div>{{ communication.lead_number | fixPhone }}</div>
                                    <a target="_blank"
                                       :href="getContactTalkRedirectURL(communication.contact_id)"
                                       v-if="communication.contact">
                                        <el-tooltip class="item pull-left"
                                                    effect="dark"
                                                    content="Click For More Info"
                                                    placement="top">
                                            <div class="text-dark-greenish">
                                                {{ communication.contact.name | fixContactName }}
                                            </div>
                                        </el-tooltip>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-horizontal b-b"
                         v-if="communication.type === CommunicationTypes.CALL && !dialer_mode">
                        <target-users-tree class="form-group row mb-0 pt-2 pb-2"
                                           :communication="communication"
                                           :isForm="true"/>
                    </div>

                    <div class="form-horizontal"
                         :class="[communication.type !== CommunicationTypes.NOTE ? 'b-b': '']"
                         v-if="[CommunicationTypes.CALL, CommunicationTypes.SMS, CommunicationTypes.EMAIL, CommunicationTypes.NOTE, CommunicationTypes.APPOINTMENT, CommunicationTypes.REMINDER].includes(communication.type)">
                        <div class="form-group row mb-0 pt-2 pb-2"
                             v-if="communication.type !== CommunicationTypes.NOTE">
                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label">User:</label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center">
                                <el-tooltip class="item"
                                            effect="dark"
                                            popper-class="rejection-tooltip"
                                            :content="rejectionTooltipData(communication.rejected_by_app, communication.type)"
                                            placement="top"
                                            v-if="communication.rejected_by_app !== 0">
                                    <div :state="communication.rejected_by_app"
                                         class="status-icon d-inline-block"
                                         v-html="rejectionToIcon(communication.rejected_by_app)">
                                    </div>
                                </el-tooltip>
                                <div v-else-if="getUser(communication.user_id, communication?.user)">
                                    <router-link
                                        :to="{ name: 'User Activity', params: {user_id: communication.user_id }}">
                                        <el-tooltip class="item pull-left"
                                                    effect="dark"
                                                    content="Click For More Info"
                                                    placement="top">
                                            <div>
                                                <span v-if="communication.user.type === User.TYPE_AI_AGENT" class="ai-effect-gradient-text"
                                                    :title="getUserName(getUser(communication.user_id, communication?.user))">
                                                    <sparkle-icon width="16" height="16"/>
                                                    {{ getUserName(getUser(communication.user_id, communication?.user)) }}
                                                </span>
                                                <span v-else class="text-dark-greenish"
                                                      :title="getUserName(getUser(communication.user_id, communication?.user))">
                                                    {{ getUserName(getUser(communication.user_id, communication?.user)) }}
                                                </span>
                                            </div>
                                        </el-tooltip>
                                    </router-link>
                                </div>
                                <div v-else>
                                    <span class="ai-effect-gradient-text"
                                          v-if="communication.resolution2 === CommunicationResolution.RESOLUTION_ALOAI_NEW">
                                        <sparkle-icon width="16" height="16"/>
                                        AloAi Agent
                                    </span>
                                    <span class="text-greyish"
                                          v-else>
                                        -
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-horizontal b-b"
                         v-if="communication.type === CommunicationTypes.CALL && communication.attempting_users && communication.attempting_users.length > 0 && verbose">
                        <div class="form-group row mb-0 pt-2 pb-2">
                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label">Attempting Users:</label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center">
                                <span class="text-greyish">
                                    <ul class="list list-unstyled inset mb-0">
                                        <li v-for="(attempting_user, index) in communication.attempting_users"
                                            :key="attempting_user + '-user-' + index"
                                            v-if="getUser(attempting_user)"
                                            class="pb-1">
                                            <router-link
                                                :to="{ name: 'User Activity', params: {user_id: getUser(attempting_user).id }}">
                                                <span :class="getAttemptingClass(attempting_user, communication.disposition_status2, communication.user_id)"
                                                      :title="getUserName(getUser(attempting_user))">
                                                    {{ getUserName(getUser(attempting_user)) }}
                                                </span>
                                            </router-link>
                                        </li>
                                    </ul>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="form-horizontal b-b"
                         v-if="communication.type === CommunicationTypes.CALL && communication.metadata && communication.metadata.reports && verbose">
                        <div class="form-group row mb-0 pt-2 pb-2">
                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label">
                                Answered with Fishing Mode:
                            </label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center">{{ communication.metadata.reports.is_fishing ? 'Yes' : 'No' }}
                            </div>
                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label">
                                Barged:
                            </label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center">{{ communication.metadata.reports.is_barge ? 'Yes' : 'No' }}
                            </div>
                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label">
                                Whispered:
                            </label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center">{{ communication.metadata.reports.is_whisper ? 'Yes' : 'No' }}
                            </div>
                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label">
                                Queued:
                            </label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center">{{ communication.metadata.reports.is_queued ? 'Yes' : 'No' }}
                            </div>
                            <label
                                v-if="communication.disposition_status2 === CommunicationDispositionStatus.DISPOSITION_STATUS_ABANDONED_NEW && communication.metadata.reports.last_queue_position && communication.metadata.reports.last_queue_position !== null"
                                :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']" class="form-control-label">
                                Last position in queue:
                            </label>
                            <div
                                v-if="communication.disposition_status2 === CommunicationDispositionStatus.DISPOSITION_STATUS_ABANDONED_NEW && communication.metadata.reports.last_queue_position && communication.metadata.reports.last_queue_position !== null"
                                :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                class="d-flex align-items-center">{{ communication.metadata.reports.last_queue_position }}
                            </div>
                        </div>
                    </div>

                    <div class="form-horizontal"
                         v-if="![CommunicationTypes.NOTE, CommunicationTypes.SYSNOTE, CommunicationTypes.APPOINTMENT, CommunicationTypes.REMINDER].includes(communication.type)">
                        <div class="form-group row mb-0">
                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label"
                                   v-if="communication.type === CommunicationTypes.CALL">
                                Started at:
                            </label>
                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label"
                                   v-else>
                                Sent at:
                            </label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center">
                                {{ communication.created_at | fixCommunicationDateTime }}
                            </div>
                        </div>
                    </div>

                    <div class="form-horizontal b-b"
                         v-if="communication.type === CommunicationTypes.SMS">
                        <div class="form-group row mb-0">
                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label">
                                Current Status:
                            </label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center">
                                {{
                                    communication.current_status2 !==
                                    CommunicationCurrentStatus.CURRENT_STATUS_COMPLETED_NEW
                                        ? $options.filters.translateCurrentStatusText(communication.current_status2) :
                                        $options.filters.translateDispositionStatusText(communication.disposition_status2) | replaceDash |
                                        capitalize
                                }}
                            </div>
                        </div>
                    </div>

                    <div class="form-horizontal b-b"
                         v-if="communication.type === CommunicationTypes.CALL && verbose">
                        <div class="form-group row mb-0">
                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label">Ended at:</label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center">
                                {{ communication.created_at | fixCommunicationDateTime(communication.duration) }}
                            </div>
                        </div>
                    </div>

                    <div class="form-horizontal"
                         v-if="[CommunicationTypes.CALL, CommunicationTypes.RVM].includes(communication.type) && verbose">
                        <div class="form-group row mb-0">
                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label">Duration:</label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center">
                                {{ communication.duration | fixDuration }}
                            </div>
                        </div>
                    </div>

                    <div class="form-horizontal"
                         v-if="(communication.type === CommunicationTypes.SMS) && verbose">
                        <div class="form-group row mb-0">
                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label">Parts:</label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center">
                                {{ communication.duration }}
                            </div>
                        </div>
                    </div>

                    <div class="form-horizontal b-b"
                         v-if="communication.type === CommunicationTypes.CALL">
                        <div class="form-group row mb-0">
                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label">Talk time:</label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center">
                                {{ communication.talk_time | fixDuration }}
                            </div>
                        </div>
                    </div>

                    <div class="form-horizontal b-b"
                         v-if="communication.type === CommunicationTypes.CALL && verbose">
                        <div class="form-group row mb-0">
                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label">Wait time:</label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center">
                                <span v-if="communication.disposition_status2 === CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW">{{ communication.wait_time | fixDuration }}</span>
                                <span v-else>-</span>
                            </div>
                        </div>
                    </div>

                    <div class="form-horizontal b-b"
                         v-if="communication.type === CommunicationTypes.CALL && verbose">
                        <div class="form-group row mb-0">
                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label">Hold time:</label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center">
                                <span v-if="communication.disposition_status2 === CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW">{{ communication.hold_time | fixDuration }}</span>
                                <span v-else>-</span>
                            </div>
                        </div>
                    </div>

                    <div class="form-horizontal"
                         v-if="![CommunicationTypes.NOTE, CommunicationTypes.SYSNOTE, CommunicationTypes.APPOINTMENT, CommunicationTypes.REMINDER].includes(communication.type)">
                        <div class="form-group row mb-0">
                            <template v-if="communication.ring_group_id">
                                <label class="form-control-label"
                                       :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']">
                                    Ring Group:
                                </label>
                                <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                     class="d-flex align-items-center">
                                    <router-link
                                        :to="{ name: 'Ring Group Activity', params: { ring_group_id: communication.ring_group_id }}"
                                        v-if="getRingGroup(communication.ring_group_id) && !getRingGroup(communication.ring_group_id).call_waiting">
                                        <el-tooltip class="item pull-left"
                                                    effect="dark"
                                                    content="Click For More Info"
                                                    placement="top">
                                        <span class="text-dark-greenish">
                                            {{ getRingGroup(communication.ring_group_id).name }}
                                        </span>
                                        </el-tooltip>
                                    </router-link>
                                    <template v-else>
                                        <span v-if="getRingGroup(communication.ring_group_id)?.call_waiting">
                                            Call waiting Queue
                                        </span>
                                        <span v-else>
                                            Deleted Ring Group
                                        </span>
                                    </template>
                                </div>
                            </template>

                            <template v-if="communication.workflow_id">
                                <label class="form-control-label"
                                       :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']">
                                    Sequence:
                                </label>
                                <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                     class="d-flex align-items-center">
                                    <router-link
                                        :to="{ name: 'Sequence Manager', params: { workflow_id: communication.workflow_id }}"
                                        v-if="getWorkflow(communication.workflow_id)">
                                        <el-tooltip class="item pull-left"
                                                    effect="dark"
                                                    content="Click For More Info"
                                                    placement="top">
                                            <span class="text-dark-greenish">
                                                {{ getWorkflow(communication.workflow_id).name }}
                                            </span>
                                        </el-tooltip>
                                    </router-link>
                                    <template v-else>
                                        Deleted Sequence
                                    </template>
                                </div>
                            </template>

                            <template v-if="communication.broadcast_id">
                                <label class="form-control-label"
                                       :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']">
                                    Broadcast:
                                </label>
                                <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                     class="d-flex align-items-center">
                                    <router-link
                                        :to="{ name: 'Broadcast Activity', params: { broadcast_id: communication.broadcast_id }}"
                                        v-if="getBroadcast(communication.broadcast_id)">
                                        <el-tooltip class="item pull-left"
                                                    effect="dark"
                                                    content="Click For More Info"
                                                    placement="top">
                                        <span class="text-dark-greenish">
                                            {{ getBroadcast(communication.broadcast_id).name }}
                                        </span>
                                        </el-tooltip>
                                    </router-link>
                                    <template v-else>
                                        Deleted Broadcast
                                    </template>
                                </div>
                            </template>

                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label"
                                   v-if="communication.transfer_prior_user_ids">
                                Transferred from:
                            </label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center"
                                 v-if="communication.transfer_prior_user_ids">
                                <router-link
                                    v-for="(user_id, index) in communication.transfer_prior_user_ids"
                                    v-if="getUser(user_id)"
                                    :key="user_id + '-user-' + index"
                                    :to="{ name: 'User Activity', params: {user_id: user_id }}">
                                    <el-tooltip class="item pull-left"
                                                effect="dark"
                                                content="Click For More Info"
                                                placement="top">
                                        <span class="text-dark-greenish"
                                              :title="getUserName(getUser(user_id))">
                                            {{ getUserName(getUser(user_id)) }}
                                        </span>
                                    </el-tooltip>
                                </router-link>
                            </div>

                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label"
                                   v-if="communication.transfer_target_user_ids">
                                Transferred to:
                            </label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center"
                                 v-if="communication.transfer_target_user_ids">
                                <router-link
                                    v-for="(user_id, index) in communication.transfer_target_user_ids"
                                    v-if="getUser(user_id)"
                                    :key="user_id + '-user-' + index"
                                    :to="{ name: 'User Activity', params: {user_id: user_id }}">
                                    <el-tooltip class="item pull-left"
                                                effect="dark"
                                                content="Click For More Info"
                                                placement="top">
                                        <span class="text-dark-greenish"
                                              :title="getUserName(getUser(user_id))">
                                            {{ getUserName(getUser(user_id)) }}
                                        </span>
                                    </el-tooltip>
                                </router-link>
                            </div>

                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label"
                                   v-if="communication.transfer_target_user_ids">
                                Cold transferred?
                            </label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center"
                                 v-if="communication.transfer_target_user_ids">
                                <span class="text-dark">
                                    {{ communication.in_cold_transfer | fixBooleanType }}
                                </span>
                            </div>

                            <template v-if="communication.metadata && communication.metadata.new_communication_id">
                                <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                       class="form-control-label">
                                    Child Call:
                                </label>
                                <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                     class="d-flex align-items-center">
                                    <span class="text-dark-greenish">
                                        <router-link :to="{ name: 'Communication', params: {communication_id: communication.metadata.new_communication_id }}">
                                            More info
                                        </router-link>
                                    </span>
                                </div>
                            </template>

                            <template v-if="communication.metadata && communication.metadata.original_communication_id">
                                <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                       class="form-control-label">
                                    Parent Call:
                                </label>
                                <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                     class="d-flex align-items-center">
                                    <span class="text-dark-greenish">
                                        <router-link :to="{ name: 'Communication', params: {communication_id: communication.metadata.original_communication_id }}">
                                            More info
                                        </router-link>
                                    </span>
                                </div>
                            </template>

                            <template v-if="communication.metadata && communication.metadata.active_communication_id">
                                <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                       class="form-control-label">
                                    Child Call:
                                </label>
                                <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                     class="d-flex align-items-center">
                                    <span class="text-dark-greenish">
                                        <router-link :to="{ name: 'Communication', params: {communication_id: communication.metadata.active_communication_id }}">
                                            More info
                                        </router-link>
                                    </span>
                                </div>
                            </template>

                            <template v-if="communication.metadata && communication.metadata.fake_communication_id">
                                <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                       class="form-control-label">
                                    Parent Call:
                                </label>
                                <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                     class="d-flex align-items-center">
                                    <span class="text-dark-greenish">
                                        <router-link :to="{ name: 'Communication', params: {communication_id: communication.metadata.fake_communication_id }}">
                                            More info
                                        </router-link>
                                    </span>
                                </div>
                            </template>

                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label"
                                   v-if="getUser(communication.user_id, communication?.user) && communication.type === CommunicationTypes.SMS && communication.direction === CommunicationDirections.INBOUND">
                                Received by:
                            </label>
                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label"
                                   v-if="getUser(communication.user_id, communication?.user) && communication.type === CommunicationTypes.SMS && communication.direction === CommunicationDirections.OUTBOUND">
                                Sent by:
                            </label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center"
                                 v-if="getUser(communication.user_id, communication?.user) && communication.type === CommunicationTypes.SMS">
                                <span class="text-dark">
                                    {{ getUser(communication.user_id, communication?.user).name }}
                                </span>
                            </div>

                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label"
                                   v-if="communication.type === CommunicationTypes.CALL">
                                Recording:
                            </label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center"
                                 v-if="communication.type === CommunicationTypes.CALL">
                                <span class="text-dark-greenish">
                                    <communication-audio :communication="communication"
                                                         :type="UploadedFileTypes.TYPE_CALL_RECORDING"
                                                         :popover-direction="communication.direction === CommunicationDirections.INBOUND ? 'right' : 'left'">
                                    </communication-audio>
                                </span>
                            </div>
                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label"
                                   v-if="communication.type === CommunicationTypes.CALL && communication.metadata && communication.metadata.transcription_info">
                                Transcription:
                            </label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center"
                                 v-if="communication.type === CommunicationTypes.CALL && communication.metadata && communication.metadata.transcription_info">
                                <div class="w-full"
                                     v-if="communication.metadata && communication.metadata.transcription_info && communication.metadata.transcription_info.status == 4">
                                    <communication-transcription-button
                                        v-if="!communication.transcription_is_deleted && communication.metadata.transcription_info.summary"
                                        :communication="communication"
                                        :show_form="showTranscription"
                                    ></communication-transcription-button>
                                    <span class="text-grey-900"
                                          v-if="communication.transcription_is_deleted">
                                      deleted
                                    </span>
                                </div>
                                <div v-else>
                                    <span>Processing...</span>
                                </div>
                            </div>

                            <template v-if="[CommunicationTypes.FAX, CommunicationTypes.EMAIL].includes(communication.type) && communication.attachments && communication.attachments.length > 0">
                                <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                       class="form-control-label">
                                    Files:
                                </label>
                                <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                     class="d-flex align-items-center">
                                    <span v-for="(attachment, index) in communication.attachments"
                                          :key="index"
                                          class="text-dark-greenish">
                                        <a class="text-dark-greenish"
                                           target="_blank"
                                           :href="attachment.url">
                                            Click Here To Download
                                        </a>
                                    </span>
                                </div>
                            </template>

                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label"
                                   v-if="[CommunicationTypes.CALL, CommunicationTypes.RVM].includes(communication.type)">
                                Voicemail:
                            </label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex flex-column align-items-start"
                                 v-if="[CommunicationTypes.CALL, CommunicationTypes.RVM].includes(communication.type)">
                                <span class="text-dark-greenish">
                                    <communication-audio :communication="communication"
                                                         :type="UploadedFileTypes.TYPE_CALL_VOICEMAIL">
                                    </communication-audio>
                                </span>
                                <br>
                                <template v-if="(activity_mode || verbose) && communication.has_voicemail">
                                    <div class="w-full"
                                         v-if="communication.metadata && communication.metadata.voicemail_transcription">
                                        <p><span class="600">Transcription:</span> “{{ communication.metadata.voicemail_transcription }}”</p>
                                    </div>
                                    <div class="w-full"
                                         v-else>
                                        <p>{{ communication.voicemail_duration | fixDuration }} - (Transcription Failed) </p>
                                    </div>
                                </template>
                            </div>

                            <template v-if="communication.type === CommunicationTypes.SMS && communication.direction === CommunicationDirections.OUTBOUND && sentAsMmsLabel">
                                <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']" class="form-control-label">
                                    Sent as MMS:
                                </label>
                                <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']" class="d-flex align-items-center">
                                    <span class="text-dark">
                                        {{ sentAsMmsLabel }}
                                    </span>
                                </div>
                            </template>

                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label">Notes:</label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex flex-column justify-content-center pt-2">
                                <template v-if="activity_mode || verbose">
                                    <div class="w-full"
                                         v-if="communication.notes">
                                        {{ communication.notes }}
                                    </div>
                                    <div>
                                        <communication-note ref="communication_notes"
                                                            :small="dialer_mode"
                                                            :communication="communication">
                                            <template v-slot:trigger>
                                                <div>
                                                    <i class="text-dark-greenish el-icon-document"></i>
                                                    <span class="text-dark-greenish _700 pointer"
                                                          v-if="communication.notes">Change Notes</span>
                                                    <span class="text-dark-greenish _700 pointer"
                                                          v-else>Add Notes</span>
                                                </div>
                                            </template>
                                        </communication-note>
                                    </div>
                                </template>

                                <communication-note ref="communication_notes"
                                                    :small="dialer_mode"
                                                    :communication="communication"
                                                    v-else>
                                    <template v-slot:trigger>
                                        <div>
                                            <i class="text-dark-greenish el-icon-document"></i>
                                            <span class="text-dark-greenish _700 pointer"
                                                  v-if="communication.notes">Change Notes</span>
                                            <span class="text-dark-greenish _700 pointer"
                                                  v-else>Add Notes</span>
                                        </div>
                                    </template>
                                </communication-note>
                            </div>

                            <template v-if="verbose && communication.csat_score">
                                <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                       class="form-control-label">
                                    CSAT Score:
                                </label>
                                <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                     class="d-flex flex-column justify-content-center pt-2">
                                    <div class="w-full">
                                        <el-rate v-model="communication.csat_score" disabled></el-rate>
                                    </div>
                                </div>
                            </template>

                            <template v-if="verbose">
                                <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                       class="form-control-label">
                                    Creator Type:
                                </label>
                                <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                     class="d-flex flex-column justify-content-center pt-2">
                                    <div class="w-full">
                                        {{ communication.creator_type | translateCreatorTypeText }}
                                    </div>
                                </div>
                            </template>

                            <label :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                   class="form-control-label">Tags:</label>
                            <div :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                 class="d-flex align-items-center pt-2">
                                <communication-tags :communication="communication"></communication-tags>
                            </div>

                            <template
                                v-if="communication.type === CommunicationTypes.CALL && current_company && call_dispositions.length > 0 && !dialer_mode">
                                <div class="d-flex align-items-center"
                                     :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']">
                                    <label class="form-control-label">Call Disposition:</label>
                                    <el-popover placement="right"
                                                class="ml-1"
                                                width="200"
                                                trigger="click"
                                                v-if="current_company.force_call_disposition"
                                                content="Your account admin has mandated call dispositions.">
                                        <el-button type="text"
                                                   class="p-0"
                                                   slot="reference">
                                            <i class="el-icon-info text-danger"></i>
                                        </el-button>
                                    </el-popover>
                                </div>
                                <div class="d-flex align-items-center pt-2"
                                     :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']">
                                    <call-disposition-selector :communication="communication"></call-disposition-selector>
                                </div>
                                <div class="d-flex align-items-center"
                                     :class="[dialer_mode ? 'col-5' : 'col-xl-5 col-12']"
                                     v-if="current_company.hubspot_integration_enabled">
                                    <label class="form-control-label">HubSpot Type:</label>
                                </div>
                                <div class="d-flex align-items-center pt-2"
                                     :class="[dialer_mode ? 'col-7' : 'col-xl-7 col-12']"
                                     v-if="current_company.hubspot_integration_enabled">
                                    <hubspot-activity-type-selector
                                        :communication="communication">
                                    </hubspot-activity-type-selector>
                                </div>
                            </template>
                        </div>

                        <div class="b-t"
                             v-if="hasCustomFields">
                            <div class="form-group row mb-0" v-for="(custom_field, key) in communication.metadata?.custom_fields" :key="key">
                                <label class="form-control-label col-xl-5">{{ convertToTitleCase(key) }}:</label>
                                <div class="d-flex align-items-center col-xl-7">
                                    <span> {{ custom_field }} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row p-l p-r"
                     v-if="![CommunicationTypes.NOTE, CommunicationTypes.SYSNOTE, CommunicationTypes.APPOINTMENT, CommunicationTypes.REMINDER].includes(communication.type) && activity_mode">
                    <div class="col-12">
                        <router-link
                            :to="{ name: 'Communication', params: {communication_id: communication.id}}">
                            <button class="btn btn-sm greyish text-left">
                                <i class="material-icons mr-2">info</i>
                                More Details
                            </button>
                        </router-link>
                    </div>
                </div>
                <div class="row p-l p-r"
                     v-if="[CommunicationTypes.APPOINTMENT, CommunicationTypes.REMINDER].includes(communication.type)">
                    <div class="col-12 text-center">
                        <div class="text-center pb-3 b-b">
                            <el-radio-group v-model="communication.disposition_status2"
                                            :disabled="loading_update_engagement"
                                            @change="changeEngagementStatus">
                                <template v-if="communication.type == CommunicationTypes.APPOINTMENT">
                                    <el-radio-button :label="CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_SET">
                                        Set
                                    </el-radio-button>
                                    <el-radio-button :label="CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_SHOWN">
                                        {{ isSimpSocial ? 'Shown' : 'Completed' }}
                                    </el-radio-button>
                                    <el-radio-button :label="CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_CANCELED">
                                        Canceled
                                    </el-radio-button>
                                    <el-radio-button :label="CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_MISSED">
                                        Missed
                                    </el-radio-button>
                                </template>
                                <template v-else>
                                    <el-radio-button :label="CommunicationDispositionStatus.DISPOSITION_STATUS_PLACED_NEW">
                                        Pending
                                    </el-radio-button>
                                    <el-radio-button :label="CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW">
                                        Completed
                                    </el-radio-button>
                                    <el-radio-button :label="CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW">
                                        Canceled
                                    </el-radio-button>
                                    <el-radio-button :label="CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW">
                                        Missed
                                    </el-radio-button>
                                </template>
                            </el-radio-group>
                        </div>
                        <div class="text-center pt-3">
                            <a :href="getCalendarTalkRedirectURL({ communication_id: communication.id, view: 'month' })" target="_blank">
                                <el-button size="">
                                    <i class="fa fa-calendar mr-1"></i>
                                    Open in Calendar
                                </el-button>
                            </a>
                        </div>
                        <sms-reminders :communication_id="communication.id"
                                       :campaign_id="campaign_id"
                                       :contact="contact"
                                       :appointment_datetime="communication.engagement_data.appointment_datetime">
                        </sms-reminders>
                    </div>
                </div>
            </el-collapse-item>
        </el-collapse>
        <div class="p-x-sm p-y-sm b-t width-300"
             v-if="communication.notes && !activeName">
            <strong>Notes:</strong>
            <p v-html="$options.filters.nl2br(communication.notes)"></p>
        </div>
        <div class="p-x-sm p-y-sm b-t width-300 ml-2"
             v-if="!communication.transcription_is_deleted && communication.has_transcription && !activeName && !dialer_mode">
            <communication-transcription-button
                :communication="communication"
                :button_text="'Show Transcription'"
            ></communication-transcription-button>
        </div>
        <div class="p-x-sm p-y-sm b-t width-300"
             v-if="communication.body && communication.type === CommunicationTypes.NOTE && communication.direction === CommunicationDirections.INBOUND && !activeName">
            <strong>Notes:</strong>
            <p v-html="$options.filters.nl2br(parsedBody)"></p>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {acl_mixin, avatar_mixin, calendar_talk_mixin, communication_info_mixin, contact_talk_mixin, mentions_mixin, user_info_mixin} from '../mixins'
import {mapGetters, mapState} from 'vuex'
import VueLoadImage from 'vue-load-image'
import ContactDispositionSelector from './contact-disposition-selector'
import SmsReminders from '../components/sms-reminders'
import TargetUsersTree from './target-users-tree'
import HubspotActivityTypeSelector from './hubspot-activity-type-selector'
import SparkleIcon from "../../images/app-icons/sparkle-bold-icon.vue"
import * as AnswerTypes from '../constants/answer-types'
import * as CommunicationCurrentStatus from '../constants/communication-current-status'
import * as CommunicationDispositionStatus from '../constants/communication-disposition-status'
import * as CommunicationTypes from '../constants/communication-types'
import * as CommunicationDirections from '../constants/communication-direction'
import * as UploadedFileTypes from '../constants/uploaded-file-types'
import * as CallbackStatus from '../constants/callback-status'
import * as CommunicationResolution from "../constants/communication-resolution"
import * as User from "../constants/user"

export default {
    mixins: [
        acl_mixin,
        avatar_mixin,
        contact_talk_mixin,
        communication_info_mixin,
        user_info_mixin,
        mentions_mixin,
        calendar_talk_mixin
    ],

    components: {
        SparkleIcon,
        HubspotActivityTypeSelector,
        TargetUsersTree,
        ContactDispositionSelector,
        SmsReminders,
        'vue-load-image': VueLoadImage
    },

    props: {
        communication: {
            required: true
        },

        contact: {
            required: false
        },

        verbose: {
            required: false,
            default: false,
            type: Boolean
        },

        display_contact: {
            required: false,
            default: false,
            type: Boolean
        },

        activity_mode: {
            required: false,
            default: false,
            type: Boolean
        },

        dialer_mode: {
            required: false,
            default: false,
            type: Boolean
        },

        hide_contact_disposition: {
            required: false,
            default: false,
            type: Boolean
        },

        campaign_id: {
            required: false
        },
    },

    data() {
        return {
            auth: auth,
            showTranscription: false,
            loading_dispose: false,
            activeName: '',
            loading_update_engagement: false,
            test: "text",
            AnswerTypes,
            CommunicationDirections,
            CommunicationCurrentStatus,
            CommunicationDispositionStatus,
            CommunicationTypes,
            UploadedFileTypes,
            CallbackStatus,
            User,
        }
    },

    created() {
        if (!this.activity_mode) {
            this.activeName = 'main'
        }
        // Default to Expand/Open this component collapse component if comm type is appointment, reminder or note
        if (this.communication && [CommunicationTypes.APPOINTMENT, CommunicationTypes.REMINDER, CommunicationTypes.NOTE].includes(this.communication.type)) {
            this.activeName = 'main'
        }
    },

    computed: {
        ...mapState(['campaigns', 'workflows', 'broadcasts', 'disposition_statuses', 'call_dispositions', 'ring_groups', 'users']),

        ...mapState('cache', ['current_company']),

        ...mapGetters('cache', ['isSimpSocial']),

        CommunicationResolution() {
            return CommunicationResolution
        },

        parsedBody() {
            if (this.communication.type === CommunicationTypes.NOTE) {
                return this.parseMentionToView(this.communication.body)
            }

            return this.communication.body
        },

        usedCampaign() {
            if (!this.communication.campaign_id) {
                return null
            }

            const communicationIncomingNumber = _.get(this.communication, 'incoming_number', null)
            if (!communicationIncomingNumber) {
                return null
            }

            return this.getCampaign(this.communication.campaign_id)
        },

        sentAsMmsLabel() {
            const sendAsMms = this.communication.metadata?.send_as_mms

            if (typeof sendAsMms !== 'undefined') {
                return sendAsMms ? 'Yes' : 'No'
            }
        },

        hasCustomFields() {
            return this.communication.metadata?.custom_fields
                && Object.keys(this.communication.metadata.custom_fields).length > 0
        }
    },

    methods: {
        getCampaign(id) {
            if (!id) {
                return null
            }
            let found = this.campaigns.find(campaign => campaign.id === id)
            if (found) {
                return found
            }

            return null
        },

        getRingGroup(id) {
            if (!id) {
                return null
            }
            let found = this.ring_groups.find(ring_group => ring_group.id === id)
            if (found) {
                return found
            }

            return null
        },

        getWorkflow(id) {
            if (!id) {
                return null
            }
            let found = this.workflows.find(workflow => workflow.id === id)
            if (found) {
                return found
            }

            return null
        },

        getBroadcast(id) {
            if (!id) {
                return null
            }
            let found = this.broadcasts.find(broadcast => broadcast.id === id)
            if (found) {
                return found
            }

            return null
        },

        dispose(disposition_status) {
            this.loading_dispose = true
            axios.post('/api/v1/contact/' + this.communication.contact_id + '/dispose', {disposition_status}).then((res) => {
                this.loading_dispose = false
                this.$notify({
                    offset: 95,
                    title: 'Contact',
                    message: 'Contact disposed',
                    type: 'success',
                    showClose: true,
                })
                this.communication.contact.disposition_status_id = res.data.disposition_status_id
            }).catch((err) => {
                this.loading_dispose = false
                this.$root.handleErrors(err.response)
            })
        },

        changeEngagementStatus(status) {
            const params = {
                status: status,
                entity_type: "communication", // it means entity id for communication table
            }

            this.loading_update_engagement = true
            axios.post(`/api/v1/calendar/events/contact/${this.communication.contact_id}/update/${this.communication.id}`, params).then(res => {
                this.loading_update_engagement = false
                this.$notify({
                    offset: 95,
                    title: 'Contact',
                    message: 'Engagement updated.',
                    type: 'success',
                    showClose: true
                })
                this.$emit('update', res.data)
            }).catch(err => {
                this.loading_update_engagement = false
                this.$root.handleErrors(err.response)
            })
        },

        callDisposed(call_disposition_id) {
            if (typeof call_disposition_id !== 'undefined') {
                this.communication.call_disposition_id = call_disposition_id
            }
            this.$emit('callDisposed')
        },

        activityTypeChosen(activity_type) {
            if (typeof activity_type !== 'undefined') {
                if (!this.communication.public_metadata) {
                    this.communication.public_metadata = {}
                }
                this.communication.public_metadata.activity_type = activity_type
            }
        },

        contactDisposed(disposition_status_id) {
            if (typeof disposition_status_id !== 'undefined') {
                this.communication.contact.disposition_status_id = disposition_status_id
            }
            if (this.communication.contact.disposition_status_id) {
                this.$emit('contactDisposed')
            } else {
                this.$emit('contactNotDisposed')
            }
        },

        convertToTitleCase(key) {
            return key
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        }
    }
}
</script>
