var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "operating_hours" } },
            [
              _c("div", { staticClass: "form-label" }, [
                _c("small", [
                  _vm._v(
                    "\n                        This setting sets the working hours for\n                        this agent. Note that the times are\n                        local to "
                  ),
                  _c("b", [_vm._v("your company")]),
                  _vm._v(
                    " timezone, currently set\n                        to\n                        "
                  ),
                  _vm.current_company
                    ? _c("b", [_vm._v(_vm._s(_vm.current_company.timezone))])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("business-hours", {
                staticClass: "custom_operating_hours_font",
                attrs: {
                  type: "select",
                  color: "#00BF50",
                  days: _vm.bot.operating_hours,
                  "time-increment": _vm.time_increment,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "no-border" },
            [
              _c(
                "el-checkbox",
                {
                  staticClass: "w-full checkbox-label-description mt-2",
                  attrs: { name: "process-holidays", border: "" },
                  model: {
                    value: _vm.bot.process_on_holidays,
                    callback: function ($$v) {
                      _vm.$set(_vm.bot, "process_on_holidays", $$v)
                    },
                    expression: "bot.process_on_holidays",
                  },
                },
                [
                  _c("label", { staticClass: "mb-1" }, [
                    _c("span", { staticClass: "mr-1" }, [
                      _vm._v("Work on US national holidays"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "\n                        Enabling this will allow this agent to work during (US) holidays.\n                    "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", { staticClass: "text-dark" }, [
      _c("i", { staticClass: "el-icon-time" }),
      _vm._v(" "),
      _c("span", [_vm._v("Working Hours")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }