export const PLAN_TYPES = {
    STARTUP_THREE: 'startup-3',
    STARTUP_FIVE: 'startup-5',
    UPRO_MONTHLY: 'upro-monthly',
    UPRO_QUARTERLY: 'upro-quarterly',
    XPRO_MONTHLY: 'xpro-monthly',
    XPRO_QUARTERLY: 'xpro-quarterly'
}

export const uProFeatures = [
    { 
        text: '<b><a href="https://support.aloware.com/en/articles/9033796-understanding-aloware-billing" target="_blank" rel="noopener noreferrer">Unlimited minutes and texts <i class="el-icon-top-right"></i></a></b>'
    },
    { 
        text: 'HubSpot workflows integration',
        tooltip: 'Use HubSpot workflows to trigger calls, texts, and other phone automations.'
    },
    { text: '5000 AloAi Voice Analytics minutes' },
    { text: '<b>Power Dialer</b>' },
    { text: 'AI call transcriptions' },
    { text: 'AI call summaries' },
    { text: 'AI sentiment analysis' },
    { 
        text: 'HubSpot dynamic lists',
        tooltip: 'Aloware syncs your dynamic HubSpot contact lists into the PowerDialer.'
    },
    { 
        text: 'Dedicated success manager',
        tooltip: '10 users minimum'
    },
    { text: 'Call coaching, listen & barge' },
    { text: 'Call & text sequences' },
    { text: 'Full API access' },
    { 
        text: '<a href="https://aloware.com/blog/numberguard/" target="_blank" rel="noopener noreferrer">Spam label removal (Add-on)</a>'
    },
    { 
        text: '<a href="https://support.aloware.com/en/articles/9020267-step-by-step-guide-setting-up-local-presence-with-aloware" target="_blank" rel="noopener noreferrer">Local presence (Add-on)</a>'
    },
    { 
        text: '<a href="https://aloware.com/ai-bot/" target="_blank" rel="noopener noreferrer">AloAI Agent for SMS (Add-on)</a>',
        tooltip: 'Includes 100 SMS agent enrollments per month.'
    }
]

export const xProFeatures = [
    { text: '<b>Everything in uPro</b>' },
    { text: 'Salesforce integration' },
    { text: 'Unlimited AloAi Voice Analytics minutes' },
    { text: 'AI keyword detection & search' },
    { text: 'AI trending topics' },
    { text: 'AI call scoring' },
    { text: 'AI agent monitoring' },
    { 
        text: 'White-glove onboarding',
        tooltip: '10 users minimum'
    },
    { text: 'Guaranteed 99.99% SLA' },
    { text: 'Managed 10DLC compliance' },
    { text: 'Lowest SMS marketing rates' },
    { text: '3x Agent training sessions (25 users min)' },
    { 
        text: '<a href="https://aloware.com/blog/numberguard/" target="_blank" rel="noopener noreferrer">Spam label removal (Add-on)</a>'
    },
    { 
        text: '<a href="https://aloware.com/ai-bot/" target="_blank" rel="noopener noreferrer">AloAI Agent for SMS (Add-on)</a>',
        tooltip: 'Includes 100 SMS agent enrollments per month.'
    }
]

export const startupFeatures = {
    'startup-3': [ // 3-user package
        { text: '<b>Everything in uPro</b>' },
        { text: '1000 minutes & texts per month' },
        { text: 'Up to 3 users' },
        { text: 'Up to 5 US local phone numbers' },
        { text: '10DLC brand & campaign included' },
        { text: 'CRM integration & webhooks' },
        { text: 'AloAi Voice Analytics' },
        { text: '$25 credits / mo (sms fees, toll-free calls)' }
    ],
    'startup-5': [ // 5-user package
        { text: '<b>Everything in uPro</b>' },
        { text: '5000 minutes & texts per month' },
        { text: 'Up to 5 users' },
        { text: 'Up to 10 US local phone numbers' },
        { text: '10DLC brand & campaign included' },
        { text: 'CRM integration & webhooks' },
        { text: 'AloAi Voice Analytics' },
        { text: '$100 credits / mo (sms fees, toll-free calls)' }
    ]
}

export const MIN_LICENSES = 5
export const MIN_USERS = 5
