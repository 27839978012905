<template>
    <div class="formatted-instructions">
        <!-- Wrapper div with fixed height to prevent layout shifts -->
        <div class="instructions-container">
            <!-- Display view (formatted with tags) -->
            <div
                v-if="!isEditing"
                @click="enableEditMode"
                class="formatted-display el-textarea"
            >
                <div
                    class="el-textarea__inner formatted-content"
                    ref="formattedContent"
                    :class="{ 'is-empty': !localValue }"
                    :data-placeholder="placeholder"
                >
                    <template v-if="localValue">
                        <template v-for="(part, index) in formattedParts">
                            <!-- Regular text -->
                            <span v-if="part.type === 'text'" :key="'text-' + index">{{ part.content }}</span>

                            <!-- Tool tag -->
                            <el-tag
                                v-else-if="part.type === 'tool'"
                                :key="'tool-' + index"
                                type="primary"
                                size="small"
                                effect="plain"
                                class="tool-tag"
                            >
                                {{ part.content }}
                            </el-tag>
                        </template>
                    </template>
                    <template v-else>
                        <template v-for="(part, index) in formattedPlaceholder">
                            <!-- Regular text -->
                            <span v-if="part.type === 'text'" :key="'placeholder-text-' + index" class="placeholder-text">{{ part.content }}</span>

                            <!-- Tool tag -->
                            <el-tag
                                v-else-if="part.type === 'tool'"
                                :key="'placeholder-tool-' + index"
                                type="primary"
                                size="small"
                                effect="plain"
                                class="tool-tag placeholder-tag"
                            >
                                {{ part.content }}
                            </el-tag>
                        </template>
                    </template>
                </div>
            </div>

            <!-- Editing view (when in edit mode) -->
            <el-input
                v-else
                class="w-100 aloai-instructions"
                type="textarea"
                ref="textareaInput"
                v-model="localValue"
                :placeholder="placeholder"
                :rows="rows"
                :readonly="readonly"
                @input="onInput"
                @focus="onFocus"
                @blur="handleBlur"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'FormattedInstructionsInput',

    props: {
        value: {
            type: String,
            default: ''
        },
        rows: {
            type: Number,
            default: 14
        },
        minRows: {
            type: Number,
            default: 14
        },
        isEditing: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: 'You are a solution-focused support agent who resolves customer issues efficiently. Ask clarifying questions to understand the problem completely before offering solutions. Maintain a compassionate tone while staying direct and helpful. Avoid making promises outside our service scope.\n\nIf the customer has account access issues, walk them through the reset process. For billing disputes, gather order details then use {Transfer to this line: Billing Team|289}. For technical problems beyond basic troubleshooting, use {Transfer to this line: Technical Support|645}. If upgrades are requested, use {Create Appointment} to schedule a consultation.\n\nAfter each interaction, add {Add ( 12501: Support Handled ) disposition to the contact} to track the outcome. For issues requiring monitoring, add {Add [ needs-followup ] tag(s) to the contact}.'
        }
    },

    data() {
        return {
            localValue: this.value,
            isFocused: false,
            cursorPosition: 0,
            blurTimeout: null
        }
    },

    computed: {
        formattedParts() {
            if (!this.localValue) {
                return [{type: 'text', content: ''}]
            }

            return this.formatText(this.localValue)
        },

        formattedPlaceholder() {
            return this.formatText(this.placeholder)
        }
    },

    methods: {
        formatText(text) {
            if (!text) {
                return [{type: 'text', content: ''}]
            }

            const parts = []
            const regex = /(\{([^{}]+)\})/g
            let lastIndex = 0
            let match

            while ((match = regex.exec(text)) !== null) {
                // Add text before the match
                if (match.index > lastIndex) {
                    parts.push({
                        type: 'text',
                        content: text.substring(lastIndex, match.index)
                    })
                }

                // Process the content inside the braces
                let content = match[2]

                // 1. Handle Transfer/line tag with |ID - keep the line name but remove the ID
                if (content.includes('Transfer to this line:')) {
                    const pipeIndex = content.indexOf('|')
                    if (pipeIndex !== -1) {
                        // Extract the line name (everything between "Transfer to this line:" and the pipe)
                        const lineName = content.substring('Transfer to this line:'.length, pipeIndex).trim()
                        content = `Transfer to ${lineName}`
                    }
                }

                // 2. Handle disposition tags with ID prefix like "ID: Name"
                if (content.includes('Add (') && content.includes(') disposition')) {
                    // Extract just the disposition name, removing the ID and parentheses
                    const dispositionMatch = content.match(/Add \(([^:]+): ([^)]+)\) disposition/)
                    if (dispositionMatch) {
                        content = `Add ${dispositionMatch[2]} disposition`
                    } else {
                        // Fallback - just remove parentheses and trim
                        content = content.replace(/Add \([^)]+\) disposition/, (match) => {
                            // Extract just the name part after the colon if present
                            const colonIndex = match.indexOf(':')
                            if (colonIndex !== -1) {
                                const nameStart = colonIndex + 1
                                const nameEnd = match.indexOf(')')
                                return `Add ${match.substring(nameStart, nameEnd).trim()} disposition`
                            }
                            return match.replace(/\([^)]+\)/, '').trim()
                        })
                    }

                    // Remove "to the contact" suffix
                    content = content.replace(' to the contact', '')
                }

                // 3. Handle tag(s) - remove brackets and make singular
                if (content.includes('tag(s)')) {
                    // Extract tag names without brackets
                    content = content.replace(/\[ ([^\]]+) \]/g, '$1')

                    // Remove pluralization
                    content = content.replace('tag(s)', 'tag')

                    // Remove "to the contact" suffix
                    content = content.replace(' to the contact', '')
                }

                // 4. Handle list(s) - remove brackets and make singular
                if (content.includes('list(s)')) {
                    // Extract list names without brackets
                    content = content.replace(/\[ ([^\]]+) \]/g, '$1')

                    // Remove pluralization
                    content = content.replace('list(s)', 'list')

                    // Clean up the text
                    content = content.replace('Add contact to ', 'Add to ')
                }

                // 5. Handle appointment for user with ID
                if (content.includes('Create appointment with:') && content.includes('|')) {
                    // Remove the user ID after the pipe
                    const pipeIndex = content.indexOf('|')
                    if (pipeIndex !== -1) {
                        content = content.substring(0, pipeIndex)
                    }
                }

                // 6. General cleanup and trimming
                content = content.trim()

                // Add the cleaned-up tool tag
                parts.push({
                    type: 'tool',
                    content: content
                })

                lastIndex = match.index + match[0].length
            }

            // Add any remaining text
            if (lastIndex < text.length) {
                parts.push({
                    type: 'text',
                    content: text.substring(lastIndex)
                })
            }

            return parts
        },

        onInput(val) {
            this.localValue = val
            this.$emit('input', val)
            this.updateCursorPosition()
        },

        updateCursorPosition() {
            if (this.$refs.textareaInput && this.$refs.textareaInput.$el) {
                const textarea = this.$refs.textareaInput.$el.querySelector('textarea')
                if (textarea) {
                    this.cursorPosition = textarea.selectionStart
                }
            }
        },

        onFocus() {
            // Clear any pending blur timeout
            if (this.blurTimeout) {
                clearTimeout(this.blurTimeout)
                this.blurTimeout = null
            }

            this.isFocused = true
            this.$emit('focus')

            this.$nextTick(() => {
                // Restore cursor position
                if (this.$refs.textareaInput && this.$refs.textareaInput.$el) {
                    const textarea = this.$refs.textareaInput.$el.querySelector('textarea')
                    if (textarea) {
                        textarea.setSelectionRange(this.cursorPosition, this.cursorPosition)
                    }
                }
            })
        },

        handleBlur(event) {
            // Save cursor position before blur
            this.updateCursorPosition()

            // Set a short delay before actually blurring
            this.blurTimeout = setTimeout(() => {
                this.isFocused = false
                this.$emit('blur')
                this.blurTimeout = null
            }, 10) // Short timeout to avoid race conditions
        },

        // Method to get the underlying textarea for parent component event binding
        getTextarea() {
            if (this.$refs.textareaInput && this.$refs.textareaInput.$el) {
                return this.$refs.textareaInput.$el.querySelector('textarea')
            }
            return null
        },

        enableEditMode() {
            this.$emit('update:isEditing', true)
            setTimeout(() => {
                if (this.$refs.textareaInput) {
                    this.$refs.textareaInput.focus()

                    // Reset scroll position to top when entering edit mode via `click` event
                    this.$nextTick(() => {
                        const textarea = this.$refs.textareaInput.$el.querySelector('textarea')
                        if (textarea) {
                            textarea.scrollTop = 0
                        }
                    })
                }
            }, 25)
        }
    },

    watch: {
        value(newVal) {
            if (newVal !== this.localValue) {
                this.localValue = newVal
            }
        },

        isEditing(newVal) {
            // If entering edit mode via `edit` button, ensure text starts from the top
            if (newVal) {
                this.$nextTick(() => {
                    if (this.$refs.textareaInput && this.$refs.textareaInput.$el) {
                        const textarea = this.$refs.textareaInput.$el.querySelector('textarea')
                        if (textarea) {
                            textarea.scrollTop = 0
                        }
                    }
                })
            }
        }
    },

    beforeDestroy() {
        if (this.blurTimeout) {
            clearTimeout(this.blurTimeout)
        }
    }
}
</script>

<style scoped>
.formatted-instructions {
    width: 100%;
}

.instructions-container {
    position: relative;
    width: 100%;
    min-height: 294px; /* 14 rows * 21px line height */
}

.formatted-display {
    cursor: text;
    height: 100%;
    min-height: 294px; /* 14 rows * 21px line height */
}

.formatted-content {
    display: block;
    width: 100%;
    padding: 5px 15px;
    overflow-y: auto;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 400;
    white-space: pre-wrap;
    word-break: break-word;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    background-color: #FFF;
    color: #606266;
    box-sizing: border-box;
    height: 100%;
    min-height: 294px; /* 14 rows * 21px line height */
}

.placeholder-text {
    color: #BBBEC3;
    padding: 5px 0;
}

.formatted-content.is-empty::before {
    content: attr(data-placeholder);
    display: none;
    width: 100%;
    color: #BBBEC3;
    font-style: normal;
    padding: 5px 0;
}

.formatted-content:empty::before {
    content: '';
    display: inline-block;
    width: 100%;
    min-height: 1.5em; /* Ensure at least one line of height */
}

.formatted-content:hover {
    border-color: #C0C4CC;
}

.tool-tag {
    vertical-align: middle;
    font-weight: 800;
    margin: 5px 0;
    border-color: #0067f4 !important;
    border-width: 2px;
}

/* Fix El-input and display view to have consistent sizing */
.el-textarea, .formatted-display {
    width: 100%;
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    vertical-align: bottom;
}

/* Make sure the textarea fills the container */
.aloai-instructions {
    height: 100%;
    min-height: 294px; /* 14 rows * 21px line height */
}

.aloai-instructions >>> .el-textarea__inner {
    min-height: 294px; /* 14 rows * 21px line height */
    height: 100% !important;
    overflow-y: auto !important;
    resize: none;
}

/* Style the placeholder in edit mode */
.aloai-instructions >>> .el-textarea__inner::placeholder {
    color: #BBBEC3;
    opacity: 1;
}

.placeholder-tag {
    opacity: 0.55;
    border-color: #D0D7E3 !important; /* Much grayer blue, almost imperceptible */
    color: #A6A8AB !important; /* Gray text instead of blue */
}
</style>
