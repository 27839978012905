<template>
    <div class="p-2 bg-white">
        <h5 class="text-dark mb-1 vertical-center mt-1">Users by Status</h5>
        <div class="d-flex">
            Total Users:
            <small class="ml-1 label badge greenish align-self-center"
                   v-if="agents">
                {{ agents.length }}
            </small>
        </div>
        <div class="d-flex my-2">
            <el-input
                ref="agentStatusTableSearch"
                class="width-500"
                placeholder="Press ENTER to search agents..."
                suffix-icon="el-icon-search"
                clearable
                autofocus
                v-model="search_text"
                @change="searchAndFilterAgents">
            </el-input>
            <el-select placeholder="Select"
                       class="ml-2 width-150"
                       popper-class="agent-status-filter"
                       v-model="agent_status_filter"
                       @change="searchAndFilterAgents">
                <el-option-group
                    v-for="group in statusFilterOptions"
                    :key="group.label"
                    :label="group.label">
                    <el-option v-for="(item, index) in group.options"
                               :key="index"
                               :label="item.label"
                               :value="item.value">
                        <i class="b-white bottom status-badge"
                           :class="getAgentStatusClass(item.value)">
                        </i>
                        <span class="ml-2">{{ item.label }}</span>
                    </el-option>
                </el-option-group>
            </el-select>
            <ring-group-dropdown class="ml-2 w-md"
                                 :ring_group_id="ringGroupIdFilter"
                                 clearable>
            </ring-group-dropdown>
            <button class="btn greenish pointer ml-2"
                    style="height: 40px"
                    :disabled="isLoading"
                    @click.prevent="refreshAgents">
                <i class="material-icons loader"
                   v-show="isLoading">
                    &#xE863;
                </i>
                <i class="material-icons"
                   v-show="!isLoading">refresh</i>
            </button>
            <span class="align-self-center text-xxs text-greyish font-weight-normal ml-2">
                Ring Group Filter applies to entire page
            </span>
        </div>
        <el-table class="w-full mt-3 table-condensed agents-condensed-summary-report-table"
                  v-loading="loading_agents"
                  :empty-text="users_table_empty_text"
                  row-key="id"
                  ref="agents_table"
                  :data="agents"
                  stripe>
            <el-table-column
                label="ID"
                prop="id"
                width="100"
                sortable>
            </el-table-column>
            <el-table-column
                label="Agents"
                prop="full_name"
                width="220"
                sortable>
                <template v-slot="scope">
                    <a :href="`/users?uid=${scope.row.id}`"
                       target="_blank">
                        {{ scope.row.full_name }}
                    </a>
                </template>
            </el-table-column>
            <el-table-column
                label="Status"
                prop="agent_status"
                width="150"
                sortable>
                <template v-slot="scope">
                    <template v-if="hasRole('Company Admin')">
                        <el-popover
                            placement="right"
                            width="200"
                            :ref="`agent_status-${scope.row.id}`">
                            <agent-status-dropdown
                                is_wallboard
                                :user_id="scope.row.id"
                                :forced_disabled_ui="false"
                                :index="scope.row.id">
                            </agent-status-dropdown>
                            <button class="btn btn-block width-200 text-white font-weight-600 agent-status-button"
                                    :class="$options.filters.agentStatusClass(scope.row.agent_status)"
                                    :disabled="scope.row.disable_change_status"
                                    slot="reference">
                                {{ getAgentStatusLabel(scope.row.agent_status) }}
                            </button>
                        </el-popover>
                    </template>
                    <template v-else>
                        <button class="btn btn-block text-white width-200 agent-status-button"
                                :class="[ $options.filters.agentStatusClass(scope.row.agent_status) ]"
                                :disabled="true">
                            {{ getAgentStatusLabel(scope.row.agent_status) }}
                        </button>
                    </template>
                </template>
            </el-table-column>
            <el-table-column
                label="Status Duration"
                prop="last_agent_status_change"
                width="230"
                sortable>
                <template v-slot="scope">
                    <alo-timeago v-if="scope.row.last_agent_status_change"
                                 :from_time="convertDateTime(scope.row.last_agent_status_change)"/>
                    <span v-else>--</span>
                </template>
            </el-table-column>
            <el-table-column
                label="Ring Groups"
                prop="ring_group_ids">
                <template v-slot="scope">
                    <div class="d-flex"
                         v-if="getUserRingGroups(scope.row).length >= 1">
                        <div v-for="ring_group_id in getUserRingGroups(scope.row, true)"
                             :key="ring_group_id + '-' + scope.row.id"
                             class="align-self-center">
                            <span v-if="getRingGroup(ring_group_id) && getRingGroup(ring_group_id).name">{{ getRingGroup(ring_group_id).name | fixName }}</span>
                            <span v-else>Deleted Ring Group</span>
                        </div>
                        <el-popover
                            placement="right"
                            width="200"
                            trigger="hover"
                            v-if="getUserRingGroups(scope.row).length > 1">
                            <div v-for="ring_group_id in getUserRingGroups(scope.row)"
                                 :key="ring_group_id + '-' + scope.row.id">
                                <span v-if="getRingGroup(ring_group_id) && getRingGroup(ring_group_id).name">{{ getRingGroup(ring_group_id).name | fixName }}</span>
                                <span v-else>Deleted Ring Group</span>
                            </div>
                            <span class="ml-2 pointer text-success"
                                  slot="reference">
                                + {{ getUserRingGroups(scope.row).length - 1 }} more
                            </span>
                        </el-popover>
                    </div>
                    <div v-else>
                        --
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="Last Login"
                prop="last_login"
                sortable>
                <template v-slot="scope">
                    <span v-if="scope.row.last_login">
                        {{ scope.row.last_login | fixFullDateUTCRelative }}
                    </span>
                    <span v-else>--</span>
                </template>
            </el-table-column>
            <el-table-column
                label="Last Updated"
                prop="last_agent_status_change"
                sortable>
                <template v-slot="scope">
                    <span v-if="scope.row.last_agent_status_change">
                        {{ scope.row.last_agent_status_change | fixFullDateUTCRelative }}
                    </span>
                    <span v-else>--</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import _ from 'lodash'
import auth from '../../auth'
import {mapState} from 'vuex'
import {acl_mixin, users_mixin} from '../../mixins'
import AgentStatusDropdown from '../../components/misc/agent-status-dropdown'
import RingGroupDropdown from '../../components/misc/ring-group-dropdown'
import * as AgentStatus from '../../constants/agent-status'
import * as AgentStatusLabels from '../../constants/agent-status-labels'
import AloTimeago from "./alo-timeago"

export default {
    mixins: [acl_mixin, users_mixin],

    components: {
        AloTimeago,
        AgentStatusDropdown,
        RingGroupDropdown,
    },

    props: {
        ring_group_id: {required: true},
    },

    data() {
        return {
            auth: auth,
            loading_agents: false,
            users_table_empty_text: 'Loading users',
            search_text: '',
            agent_status_filter: null,
            agents: [],
            manual_agent_statuses: [{
                value: AgentStatus.AGENT_STATUS_OFFLINE,
                label: 'Offline',
            }, {
                value: AgentStatus.AGENT_STATUS_ACCEPTING_CALLS,
                label: 'Available',
            }, {
                value: AgentStatus.AGENT_STATUS_NOT_ACCEPTING_CALLS,
                label: 'Busy',
            }, {
                value: AgentStatus.AGENT_STATUS_ON_BREAK,
                label: 'On Break',
            }],
            system_agent_statuses: [{
                value: AgentStatus.AGENT_STATUS_ON_CALL,
                label: 'On Call',
            }, {
                value: AgentStatus.AGENT_STATUS_RINGING,
                label: 'Ringing',
            }, {
                value: AgentStatus.AGENT_STATUS_ON_WRAP_UP,
                label: 'Wrap Up',
            }],
            disable_change_statuses: [
                AgentStatus.AGENT_STATUS_AUTO_DIAL,
                AgentStatus.AGENT_STATUS_ON_CALL,
                AgentStatus.AGENT_STATUS_RINGING
            ],
            AgentStatus,
            AgentStatusLabels,
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company,
            users: state => state.users,
            ring_groups: state => state.ring_groups,
        }),

        ringGroupIdFilter() {
            return this.ring_group_id
        },

        statusFilterOptions() {
            return [{
                label: '',
                options: [{
                    value: null,
                    label: 'All',
                    disabled: false,
                }]
            }, {
                label: 'Manual Statuses',
                options: this.manual_agent_statuses
            }, {
                label: 'System Statuses',
                options: this.system_agent_statuses
            }]
        },

        isLoading() {
            return this.loading_agents || this.$root.loading_users || this.$root.loading_extensions
        }
    },

    created() {
        VueEvent.listen('agent_status_updated', (event) => {
            if (!event) {
                return
            }

            let ref = this.$refs[`agent_status-${event.user_id}`]
            if (ref) {
                ref.doClose()
            }

            // check if status button needs to be disabled for manually changing user status
            this.shouldDisableStatusButtonForUser(event.user_id, event.agent_status)

            // re-apply filters
            this.searchAndFilterAgents()
        })
    },

    mounted() {
        // focus agent status table search on page visit
        if (this.$refs.agentStatusTableSearch) {
            this.$refs.agentStatusTableSearch.focus()
        }

        this.getAgents()
    },

    methods: {
        getAgents() {
            let results = []

            this.getQualifiedUsers()

            results = _.sortBy(this.agents, [function (user) {
                const statuses = {
                    [AgentStatus.AGENT_STATUS_ACCEPTING_CALLS]: 1,
                    [AgentStatus.AGENT_STATUS_ON_CALL]: 2,
                    [AgentStatus.AGENT_STATUS_RINGING]: 3,
                    [AgentStatus.AGENT_STATUS_ON_WRAP_UP]: 4,
                    [AgentStatus.AGENT_STATUS_NOT_ACCEPTING_CALLS]: 5,
                    [AgentStatus.AGENT_STATUS_ON_BREAK]: 6,
                    [AgentStatus.AGENT_STATUS_OFFLINE]: 7,
                }

                return statuses[user.agent_status]
            }])

            this.agents = results
        },

        getRingGroup(id) {
            if (!id) {
                return null
            }
            let found = this.ring_groups.find(ring_group => ring_group.id === id)
            if (found) {
                return found
            }

            return null
        },

        getQualifiedUsers() {
            let users = this.activeQualifiedUsers

            // page-wide ring group filter
            if (this.ring_group_id) {
                users = users.filter(user => user.ring_group_ids.includes(this.ring_group_id))
            }

            // each needs a flag to disable status button (for on call/on ringing)
            this.agents = users.map(user => ({...user, disable_change_status: this.disable_change_statuses.includes(user.agent_status)}))
        },

        refreshAgents() {
            if (this.isLoading) {
                return
            }

            this.loading_agents = true

            // reload the users list
            this.$root.getUsers().then(() => {
                this.$root.getExtensions()
                this.loading_agents = false
            })
        },

        searchAndFilterAgents() {
            this.getAgents()

            // search agent's name
            if (this.search_text.length) {
                let regex = new RegExp('^' + this.search_text + '.*', 'gi')
                this.agents = this.agents.filter(agent => agent.first_name.match(regex) || agent.last_name.match(regex) || agent.full_name.match(regex))
            }

            if (this.agent_status_filter !== null) {
                this.agents = this.agents.filter(agent => +agent.agent_status === +this.agent_status_filter)
            }
        },

        resetSortAndFilters() {
            this.search_text = ''
            this.agent_status_filter = null
            this.$refs.agents_table.clearSort()
        },

        getUserRingGroups(user, first_only = false) {
            let ring_group_ids = _.uniq(user.ring_group_ids)

            if (first_only && ring_group_ids.length > 0) {
                return [ring_group_ids[0]]
            }

            return ring_group_ids
        },

        getAgentStatusLabel(agent_status) {
            // the + ensures that the value will always be integer
            const status = this.AgentStatusLabels.LABELS.find(label => +agent_status === +label.value)

            return _.startCase(status.label)
        },

        getAgentStatusClass(agent_status) {
            if (agent_status === null) {
                return 'border border-dark'
            }

            return this.$options.filters.agentStatusClass(agent_status)
        },

        shouldDisableStatusButtonForUser(user_id, agent_status) {
            if (this.disable_change_statuses.includes(agent_status)) {
                let agent = this.agents.find(agent => +agent.id === +user_id)
                // IF agent is found, set the disable_change_status
                if (agent) {
                    agent.disable_change_status = true
                }
            }
        },

        convertDateTime(last_agent_status_change) {
            return moment.utc(last_agent_status_change)
        },
    },

    watch: {
        users: {
            deep: true,
            handler () {
                this.users_table_empty_text = 'Loading users'
                this.getAgents()

                // enclosed in condition so it doesn't trigger constant refresh when search and filter are empty
                if (this.search_text.length > 1 || this.agent_status_filter !== null) {
                  this.searchAndFilterAgents()
                }

                if (this.agents.length < 1) {
                  this.users_table_empty_text = 'No users'
                }
            }
        }
    },
}
</script>
