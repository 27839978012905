<template>
    <div class="tool-content">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h5 class="m-0 tool-title">{{ toolName }}</h5>
            <el-button 
                size="mini" 
                @click="$emit('back')" 
                type="text" 
                class="back-button"
            >
                <span class="back-text">← Back to tools</span>
            </el-button>
        </div>
        
        <p class="tool-description mb-3">{{ toolDescription }}</p>
        
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'BaseTool',

    props: {
        toolName: {
            type: String,
            required: true
        },
        toolDescription: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
.tool-content {
    animation: fadeIn 0.3s ease-in-out;
}

.tool-title {
    font-weight: 600;
    color: #303133;
}

.back-button {
    padding: 6px 10px;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.back-button:hover {
    color: #409EFF;
    background-color: rgba(64, 158, 255, 0.1);
}

.back-text {
    font-size: 0.85rem;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(5px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>
