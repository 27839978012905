<template>
    <div class="container-account-imports app-body"
         id="view">
        <div class="container-listing min-height-screen">
            <div class="container-header">
                <div class="d-flex">
                    <h3 class="listing-heading">Lists</h3>

                    <el-popover v-if="!enabledToImportContacts()"
                                ref="import-contacts-popover"
                                placement="top"
                                width="200"
                                class="custom-popover"
                                popper-class="btn-primary"
                                trigger="hover">
                        <custom-message-display :config="customMessage('contacts.import')" />
                    </el-popover>
                    <el-dropdown
                        size="medium"
                        trigger="click"
                        class="ml-auto"
                        v-popover:import-contacts-popover
                        ref="importSelection"
                        :disabled="!enabledToImportContacts()"
                        @command="importContact">
                        <el-button
                            class="import-selection-button"
                            type="primary">
                            Create List <i class="el-icon-arrow-down el-icon--right ml-2"></i>
                        </el-button>
                        <template v-slot:dropdown>
                            <el-dropdown-menu class="account-import-selection-container">
                                <el-dropdown-item
                                    command="import_from_hubspot"
                                    class="d-flex align-items-center">
                                    <div class="icon-container hubspot-icon-container">
                                        <i class="fab fa-hubspot item-icon"></i>
                                    </div>
                                    <span class="ml-2">Import from HubSpot</span>
                                </el-dropdown-item>
                                <el-dropdown-item
                                    command="import_from_csv"
                                    class="d-flex align-items-center">
                                    <img
                                        src="/assets/images/icons/csv-svg-icon-12.svg"
                                        alt=""
                                        class="csv-icon">
                                    <span class="ml-2">Import CSV</span>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>

                    <video-modal class="pl-2"
                                 title="🚀 Mastering List Management in Aloware"
                                 cookie-name="lists"
                                 notes="🔥 Unleash the power of <strong>Aloware Lists!</strong> </br></br> 📊 Organize, categorize, and skyrocket your contact management game. </br></br> Dive in and watch your efficiency soar! 💥📈"
                                 video-url="https://www.youtube.com/embed/aSdCAyV0bW4?si=EGKB-l66moF9q9HZ"
                                 learn-more-link="https://support.aloware.com/en/articles/9031374-user-s-guide-simplifying-contact-imports-for-admins-and-agents"
                                 activator-size="small"
                                 v-if="isTrial && !isSimpSocial"/>
                </div>

              <p class="mb-1 text-grey-8 _600">
                    Create public lists that agents can access in {{ applicationName }} Talk.
                    <a
                        class="text-blue _600"
                        href="https://support.aloware.com/en/articles/9034179-creating-contact-lists-for-users-in-aloware-talk"
                        target="_blank"
                        v-if="!isTrial && !isSimpSocial">
                        Learn more
                    </a>
                </p>
            </div>

            <div class="container-body">
                <div class="container-listing-tools d-flex flex-wrap mb-1">
                    <div class="d-flex flex-wrap mr-auto">
                        <div class="d-flex mr-2 search-toolbar">
                            <el-input
                                id="search-input"
                                ref="contactListSearch"
                                placeholder="Search contact lists..."
                                v-model="filters.search"
                                clearable
                                @keyup.native.prevent="searchContactLists"
                                @change="searchContactLists">
                                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                            </el-input>
                        </div>
                    </div>
                    <div class="d-flex mt-2 mt-xl-0 flex-wrap flex-lg-nowrap">
                        <div class="mr-2 align-self-center">
                            <span class="text-grey">Total Contacts List:</span> <strong>{{ pagination.total }}</strong>
                        </div>

                        <el-pagination
                            class="align-self-center"
                            layout="prev, pager, next"
                            :current-page.sync="filters.page"
                            :page-size.sync="filters.size"
                            :total="pagination.total"
                            :pager-count="5"
                            @size-change="fetchContactList(1)"
                            @current-change="fetchContactList">
                        </el-pagination>
                    </div>
                </div>

                <div class="table-responsive"
                     v-loading="loading">
                    <table v-if="pagination.data.length > 0" class="table">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Date Created</th>
                            <th># of Contacts</th>
                            <th>Type</th>
                            <th>Show in Public Folder</th>
                            <th>Source</th>
                            <th>Import Status</th>
                            <th>Imported At</th>
                            <th style="min-width: 170px;"></th>
                        </tr>
                        </thead>

                        <tbody>
                            <tr v-for="contact_list in pagination.data">
                                <td class="align-middle">
                                    <el-button
                                        v-if="contact_list.show_in_public_folder"
                                        @click="openAdminList(contact_list)"
                                        type="text"
                                        class="p-0 text-blue">
                                        <i class="material-icons text-blue">launch</i> {{ contact_list.name }}
                                    </el-button>
                                    <span v-else class="_400">
                                        {{ contact_list.name }}
                                    </span>
                                </td>
                                <td class="align-middle">
                                    <span>{{ contact_list.created_at | fixDateTime }}</span>
                                </td>
                                <td class="align-middle">
                                    <span>{{ (contact_list?.no_of_contacts ?? 0) | numFormat }}</span>
                                </td>
                                <td class="align-middle">
                                    <span>{{ contact_list.type === ContactListTypes.STATIC_LIST ? 'Static' : 'Dynamic' }}</span>
                                </td>
                                <td class="align-middle">
                                    <el-switch
                                        @change="handleShowInPublicFolder(contact_list)"
                                        v-model="contact_list.show_in_public_folder"
                                        active-color="#13ce66">
                                    </el-switch>
                                </td>
                                <td class="align-middle">
                                    <span>{{ contact_list.source_name | ucwords }}</span>
                                </td>
                                <td class="align-middle">
                                    <span :class="`import-status-${contact_list.import_status + 1}`">
                                        {{ contact_list.import_status_name | ucwords }}
                                    </span>
                                </td>
                                <td class="align-middle">
                                  <span>{{ contact_list.imported_at | fixDateTime }}</span>
                                </td>
                                <td class="align-middle text-right">
                                    <button class="btn btn-block btn-info btn-sm"
                                            v-if="contact_list.source === 3"
                                            @click="showPullHubspotListLogModal(contact_list)">
                                        <i class="fas fa-info-circle pull-left"></i>
                                        View Logs
                                    </button>
                                    <button class="btn btn-block btn-dark-warning text-white btn-sm"
                                            v-if="shouldShowSplitOption(contact_list)"
                                            @click="showSplitListForm(contact_list)">
                                        <i class="fas fa-clone pull-left"></i>
                                        Split
                                    </button>
                                    <button class="btn btn-block btn-warning btn-sm"
                                            @click="showAssignContactForm(contact_list)">
                                        <i class="fas fa-sign-in-alt pull-left"></i>
                                        Assign Contacts
                                    </button>
                                    <div class="my-2"
                                         v-if="current_company?.automation_enabled && showListActionButton(contact_list)">                                        
                                        <workflow-bulk-enroller model="contact_list"
                                                                :id="contact_list.id">
                                            Enroll Contacts
                                        </workflow-bulk-enroller>
                                    </div>
                                    <add-list-to-power-dialer :contact-list="contact_list"
                                                              v-if="current_company?.auto_dialer_enabled && showListActionButton(contact_list)">
                                    </add-list-to-power-dialer>
                                    <button class="btn btn-block info text-white btn-sm"
                                            @click="stageToEditContactList(contact_list)">
                                        <i class="fas fa-pen pull-left"></i>
                                        Rename
                                    </button>
                                    <button class="btn btn-block btn-danger btn-sm"
                                            @click="deleteContactList(contact_list)">
                                        <i class="fa fa-trash pull-left"></i>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="pagination.data.length <= 0"
                         class="text-center mx-auto w-50 py-5">
                        <div class="w-75 mx-auto">
                            <img-empty></img-empty>
                        </div>
                        <p class="lead">
                            We can't seem to find contact list
                            <span v-if="filters.search.length > 0">that matches "{{ filters.search }}"</span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="container-footer d-flex mb-5">
                <div class="ml-auto">
                    <el-pagination
                        layout="sizes, prev, pager, next"
                        :current-page.sync="filters.page"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size.sync="filters.size"
                        :hide-on-single-page="true"
                        :total="pagination.total"
                        @size-change="fetchContactList(1)"
                        @current-change="fetchContactList">
                    </el-pagination>
                </div>
            </div>
        </div>

        <el-dialog
            width="35vw"
            top="10vh"
            append-to-body
            title="Rename Contact List"
            :before-close="closeDialog"
            :visible.sync="show_dialog">
            <el-form
                v-if="selected_model"
                :model="selected_model"
                ref="rename_form">
                <el-form-item
                    label="Name"
                    prop="name"
                    :error="errors.first('name')"
                    :rules="{required: true, message: 'Please specify the name.', trigger: ['blur', 'change']}">
                    <el-input
                        v-model="selected_model.name"
                        size="small"
                        placeholder="Static List One...">
                    </el-input>
                </el-form-item>
            </el-form>

            <div
                slot="footer"
                class="dialog-footer">
                <el-button
                    @click="closeDialog"
                    size="small"
                    class="flex-grow-1">
                    Cancel
                </el-button>
                <el-button
                    @click="updateContactListName"
                    :disabled="!isNameChanged"
                    size="small"
                    type="success">
                    Save
                </el-button>
            </div>
        </el-dialog>

        <import-wizard
            :is_contact_list="true"
            @importStarted="handleImportStarted"
            ref="csv_import">
        </import-wizard>

        <admin-list-import-from-hubspot-list
            :contact_list="selected_model"
            @importStarted="handleImportStarted"
            ref="import_from_hubspot_list">
        </admin-list-import-from-hubspot-list>

        <admin-list-assign-contact-form
            @closed="closeAssignmentForm"
            :contact_list="selected_model"
            ref="assign_contact_form">
        </admin-list-assign-contact-form>

        <admin-split-list-form ref="split_list_form"
                               :contact_list="selected_model"
                               @closed="closeSplitListForm"
                               @hidden="hideSplitListForm">
        </admin-split-list-form>

        <pull-hubspot-list-logs
            ref="pull_hubspot_list_logs"
            :contactList="selectedContactList">
        </pull-hubspot-list-logs>        
    </div>
</template>

<script>
    import debounce from 'lodash/debounce'
    import cloneDeep from 'lodash/cloneDeep'
    import auth from '../../auth'
    import {mapState, mapGetters} from 'vuex'
    import {
        acl_mixin,
        paginator_mixin,
        scroll_mixin,
        validator_mixin,
        kyc_mixin
    } from '../../mixins'
    import ErrorBag from '../../ErrorBag'
    import ImgEmpty from "../../components/misc/img-empty"
    import ImportWizard from "../../components/import-wizard/import-wizard"
    import AdminListAssignContactForm from '../../components/admin-list/admin-list-assign-contact-form'
    import AdminSplitListForm from '../../components/admin-list/admin-split-list-form'
    import AdminListImportFromHubspotList from  '../../components/admin-list/admin-list-import-from-hubspot-list'
    import PullHubspotListLogs from '../../components/pull-hubspot-list-logs'
    import * as ContactListTypes from '../../constants/contact-list-types'
    import VideoModal from '../../components/video-modal.vue'
    import CustomMessageDisplay from '../../components/kyc/custom-message-display'
    import AddListToPowerDialer from '../../components/add-list-to-power-dialer'
    import WorkflowBulkEnroller from '../../components/automations/workflow-bulk-enroller'

    export default {
        name: "AdminListIndex",

        mixins: [
            validator_mixin,
            acl_mixin,
            paginator_mixin,
            scroll_mixin,
            kyc_mixin
        ],

        components: {
            ImgEmpty,
            ImportWizard,
            AdminListAssignContactForm,
            AdminSplitListForm,
            AdminListImportFromHubspotList,
            PullHubspotListLogs,
            VideoModal,
            CustomMessageDisplay,
            AddListToPowerDialer,
            WorkflowBulkEnroller,
        },

        data() {
            const columns = [
                {label: 'Name', prop: 'name', show_overflow_tooltip: true, sortable: true},
                {label: 'Date Created', prop: 'created_at', show_overflow_tooltip: true, sortable: true},
                {label: '# of Contacts', prop: 'no_of_contacts', show_overflow_tooltip: true, sortable: true},
                {label: 'Type', prop: 'type', show_overflow_tooltip: true, sortable: true},
                {label: 'Show in Public Folder', prop: 'show_in_public_folder', show_overflow_tooltip: false, sortable: false},
                {label: 'Open in Alo Talk', prop: 'open_in_alo_talk', show_overflow_tooltip: false, sortable: false},
            ]
            return {
                auth: auth,
                pagination: {
                    total: 0,
                    data: []
                },
                filters: {
                    page: 1,
                    size: 10,
                    search: ''
                },
                loading: false,
                selectedContactList: {},
                show_dialog: false,
                contacts_list: [],
                columns: columns,
                selected_model: {},
                name: '',
                errors: new ErrorBag({}),
                ContactListTypes,
                minimunContactsToSplit: 50
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),
            ...mapGetters('cache', [
                'isTrial',
            ]),

            isNameChanged() {
                let from_list = this.pagination.data.find(d => this.selected_model && d.id === this.selected_model.id)

                return from_list && from_list.name !== this.selected_model.name
            },

            applicationName() {
                return this.isSimpSocial ? '' : this.name
            }
        },

        created() {
            this.fetchContactList(1)

            VueEvent.listen('contact_list_import_csv', _ => {
                this.notification('success', 'Contacts successfully imported.')

                this.fetchContactList(1)
            })

            VueEvent.listen('contact_list_import_hubspot', _ => {
                this.notification('success', 'Contacts successfully imported.')

                this.fetchContactList(1)
            })

            VueEvent.listen('contact_list_created', _ => {
                this.notification('success', 'Contact list successfully created.')

                this.fetchContactList(1)
            })
        },

        mounted() {
            this.setTitle()

            // focus contact list search input on page visit
            if (this.$refs.contactListSearch) {
                this.$refs.contactListSearch.focus()
            }
        },

        methods: {
            showPullHubspotListLogModal(contactList) {
                if (this.$refs.pull_hubspot_list_logs) {
                  this.selectedContactList = contactList
                  this.$refs.pull_hubspot_list_logs.show_dialog = true
                }
            },

            setTitle() {
                axios.get('/get-statics')
                    .then(res => {
                        this.name = res.data.name
                        this.setPageTitle('Lists - ' + this.name)
                    })
                    .catch(err => {
                        this.setPageTitle('Lists - Aloware')
                        console.log(err)
                        this.$root.handleErrors(err.response)
                    })
            },

            openAdminList(contact_list) {
                // not public list? then do not open in AloTalk
                if (!contact_list.show_in_public_folder) {
                    return
                }

                let talk_url = this.getTalkUrl()

                if (talk_url) {
                    talk_url += `/contacts/list/${contact_list.id}?type=public`
                    window.open(talk_url)
                }
            },

            showAssignContactForm(contact_list) {
                if (this.$refs.assign_contact_form) {
                    this.selected_model = cloneDeep(contact_list)
                    this.$refs.assign_contact_form.show_dialog = true
                }
            },

            showSplitListForm(list) {
                if (this.$refs.split_list_form) {
                    this.selected_model = cloneDeep(list)
                    this.$refs.split_list_form.show_dialog = true
                }
            },

            closeAssignmentForm() {
                this.selected_model = {}
            },

            closeSplitListForm() {
                this.selected_model = {}
            },

            hideSplitListForm() {
                this.selected_model = {}
            },

            importContact(from) {
                if (from === 'import_from_csv') {
                    if (this.$refs.csv_import) {
                        this.$refs.csv_import.start()
                    }
                }
                else if (from === 'import_from_hubspot') {
                    if (this.$refs.import_from_hubspot_list) {
                        this.$refs.import_from_hubspot_list.show_dialog = true
                    }
                }
            },

            searchContactLists: debounce(function () {
                this.fetchContactList()
            }, 300),

            fetchContactList(page) {
                this.loading = true
                this.filters.page = page

                this.$APIV2.ContactList.getContactsList(this.filters)
                    .then(res => {
                        this.pagination = res.data
                    }).catch(err => {
                        console.log(err)
                    }).finally(_ => {
                        this.loading = false
                    })
            },

            handleShowInPublicFolder(contact_list) {
                let show = contact_list.show_in_public_folder

                this.$confirm(`Are you sure you want to ${show ? 'show' : 'remove'} this in public folder?`, 'Confirm', {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    customClass: 'width-500 fixed',
                    confirmButtonClass: 'el-button--success',
                    type: 'warning'
                }).then(() => {
                    let post_data = {
                        show_in_public_folder: show,
                        from_admin_list: true
                    }

                    this.updateContactList(post_data, contact_list)
                }).catch(() => {
                    contact_list.show_in_public_folder = !contact_list.show_in_public_folder
                });
            },

            stageToEditContactList(contact_list) {
                this.show_dialog = true
                this.selected_model = cloneDeep(contact_list)
            },

            resetProperties() {
                this.selected_model = {}
                this.show_dialog = false

                this.errors.clearAll()
            },

            updateContactListName() {
                if (this.selected_model) {
                    this.$refs.rename_form.validate(valid => {
                        if (valid) {
                            let post_data = {
                                name: this.selected_model.name,
                                from_admin_list: true
                            }

                            this.updateContactList(post_data, this.selected_model)
                        }
                    })
                }
            },

            updateContactList(post_data, contact_list) {
                this.$APIV2.ContactList.updateContactList(post_data, contact_list.id)
                    .then(res => {
                        let index = this.pagination.data.findIndex(cl => cl.id === contact_list.id)

                        if (index > -1) {
                            res.data.data.no_of_contacts = contact_list.no_of_contacts

                            this.pagination.data.splice(index, 1, res.data.data)

                            this.notification('success', `Contact list <b>${res.data.data.name}</b> has been successfully updated`)

                            this.resetProperties()
                        }
                    })
                    .catch(err => {
                        if (err.response.status === 422) {
                            this.errors = new ErrorBag(err.response.data.errors)
                        }
                    })
            },

            deleteContactList(contact_list) {
                this.$confirm(`Are you sure you want to delete this contact list? This action is irreversible.`, 'Warning', {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    customClass: 'w-50 fixed',
                    confirmButtonClass: 'el-button--danger',
                    type: 'warning'
                }).then(() => {
                    let params = {
                        from_admin_list: true
                    }

                    this.$APIV2.ContactList.deleteContactList(contact_list.id, params)
                    .then(res => {
                        let index = this.pagination.data.findIndex(cl => cl.id === contact_list.id)

                        if (index > -1) {
                            this.pagination.data.splice(index, 1)

                            this.pagination.total--

                            this.notification('success', `Contact list <b>${contact_list.name}</b> has been deleted.`)
                        }
                    })
                }).catch(() => {});
            },

            closeDialog() {
                if (this.isNameChanged) {
                    this.$confirm(`Are you sure you want to close this form?`, 'Contact List', {
                        confirmButtonText: "Yes, I'm sure",
                        cancelButtonText: "No, I'm not",
                        type: 'warning',
                        customClass: 'width-500 fixed'
                    }).then(() => {
                        this.resetProperties()
                    }).catch(err => {})

                    return
                }

                this.resetProperties()
            },

            notification(type, message) {
                this.$notify({
                    offset: 75,
                    title: 'Contact List',
                    message: message,
                    type: type,
                    dangerouslyUseHTMLString: true,
                    showClose: true,
                    duration: 5000
                })
            },

            handleImportStarted() {
                this.fetchContactList(1)
            },

            shouldShowSplitOption(list) {
                const isAgent = auth.user.profile.role_names.includes('Company Agent')
                if (list.show_in_public_folder && isAgent) {
                    return false
                }
                return list.type === ContactListTypes.STATIC_LIST && list.no_of_contacts > this.minimunContactsToSplit
            },

            addToPowerDialer(list) {
                if (this.$refs.add_to_power_dialer) {
                    this.$refs.add_to_power_dialer.show_dialog = true
                    this.$refs.add_to_power_dialer.contact_list = list
                }
            },

            showListActionButton(contactList) {
                return this.isAdmin && contactList.no_of_contacts > 0 && contactList.show_in_public_folder
            }
        },

        watch: {
            'current_company.talk_enabled'() {
                /**
                 * let's listen to our admin, if a user is currently looking at our admin list
                 * and we disabled the talk_enabled setting, we will redirect the user to the dashboard
                 */
                if (this.current_company && !this.current_company.talk_enabled) {
                    this.$router.push({name: "Dashboard"})
                }
            },
        },

        beforeRouteEnter(to, from, next) {
            auth.check()
                .then((res) => {
                    let auth = {
                        user: {
                            profile: res.data.user
                        }
                    }

                    // if talk is not enabled just redirect to our dashboard
                    if (!res.data.user.company.talk_enabled) {
                        next({name: 'Dashboard'})
                    }

                    if (res.data.user.is_reseller) {
                        // redirect to account management portal if the company is a reseller
                        next({name: 'Account Management Portal'})
                    } else {
                        next(vm => {
                            if (!vm.hasRole('Company Admin') && !vm.hasRole('Company Supervisor')) {
                                next({name: 'Dashboard'})
                            }
                        })
                    }
                })
                .catch((err) => {
                    next({name: 'Login', query: {redirect: to.fullPath}})
                })
        }
    }
</script>
