var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-footer" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.shouldShow,
            expression: "shouldShow",
          },
        ],
        staticClass: "footer",
      },
      [
        _c("div", { staticClass: "row text-xs" }, [
          !_vm.loading_whitelabel && !_vm.whitelabel
            ? _c("div", { staticClass: "col-4 d-none d-md-block" }, [
                _c("div", { staticClass: "m-y" }, [
                  _c("span", [
                    _vm._v("© "),
                    _c("strong", [_vm._v("Aloware, Inc")]),
                    _vm._v(" 2016-" + _vm._s(new Date().getFullYear()) + "."),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.loading_whitelabel && !_vm.whitelabel
            ? _c("div", { staticClass: "col-3 d-none d-md-block" }, [_vm._m(0)])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "col-5 d-none d-md-block" }, [
            !_vm.loading_whitelabel && !_vm.whitelabel
              ? _c("div", { staticClass: "m-y pull-right" }, [_vm._m(1)])
              : _vm._e(),
            _vm._v(" "),
            _vm.host == "app.simpsocial.com"
              ? _c("div", { staticClass: "m-y pull-right" }, [
                  _c("span", [
                    _vm._v(
                      "© " +
                        _vm._s(new Date().getFullYear()) +
                        " All rights reserved. SimpSocial®, Text Gurus®, Warrior Crm® are a registered trademark of SimpSocial®, LLC"
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "m-y" }, [
      _c("div", { staticClass: "nav text-xs d-flex justify-content-center" }, [
        _c(
          "a",
          {
            staticClass: "nav-link m-r text-new-blue _500",
            attrs: {
              target: "_blank",
              href: "https://aloware.com/terms-and-conditions",
            },
          },
          [
            _vm._v(
              "\n                            Terms\n                        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "nav-link m-r text-new-blue _500",
            attrs: {
              target: "_blank",
              href: "https://aloware.com/privacy-policy",
            },
          },
          [
            _vm._v(
              "\n                            Privacy\n                        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "nav-link m-r text-danger _500",
            attrs: {
              target: "_blank",
              href: "https://angel.co/company/aloware/jobs",
            },
          },
          [
            _vm._v(
              "\n                            We are Hiring!\n                        "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _vm._v("Built with "),
      _c("i", { staticClass: "fa fa-heart text-danger" }),
      _vm._v(" in City of Angels."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }