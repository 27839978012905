<template>
    <div class="row nav-padding"
         v-loading="loading">
        <div class="col-12">
            <div class="d-flex mb-3">
                <div class="ml-auto">
                    <el-button @click="fetchImports">
                        <i class="fa fa-refresh"></i> Refresh
                    </el-button>
                    <el-button @click.prevent="startImportWizard()"
                               plain
                               type="success"
                               v-popover:import-contacts
                               v-if="type === ImportType.TYPE_CONTACTS">
                        <i class="fas fa-file-import"></i>
                        Import from CSV
                    </el-button>
                </div>
            </div>
        </div>

        <!-- WAT-876: deprecation banner -->
        <al-alert :dimisable="true"
                  v-if="!loading && type === ImportType.TYPE_CONTACTS" class="mx-auto">
            <span class="mr-5 text-dark"
                  v-html="TAGS_DEPRECATION_MESSAGE" />
        </al-alert>

        <div class="col-12 el-tabs--border-card">
            <!--
            ------------------------------------------------------------------------------------
            | Contact Import table
            ------------------------------------------------------------------------------------
            -->
            <el-table :data="imports"
                      class="w-full"
                      v-if="type === ImportType.TYPE_CONTACTS">
                <el-table-column label="File Imported"
                                 width="250px">
                    <template v-slot="scope">
                        <el-tooltip class="item"
                                    content="Click to download"
                                    effect="dark"
                                    placement="top">
                            <a :href="scope.row.download_url">
                                <div class="media text-success">
                                    <div class="media-body">
                                        <strong>
                                            <i class="fas fa-file-csv mr-1"></i>
                                            {{ scope.row.uploaded_file.original_file | strLimit(25)}}
                                        </strong>
                                    </div>
                                </div>
                                ID: {{ scope.row.id }}
                            </a>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="Imported By">
                    <template v-slot="scope">
                        <div class="">
                            <h6 class="mb-0">{{ scope.row.created_by != null ? scope.row.created_by.name : '-' }}</h6>
                            <span>{{ scope.row.created_at | momentFormat('MMM D, YYYY h:mma z', true) }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Tags">
                    <template v-slot="scope">
                        <div v-for="tag in scope.row.tags">
                            <el-tooltip :content="tag.name"
                                        class="item"
                                        effect="dark"
                                        placement="top">
                                <router-link :to="{ name: 'Contacts', query: {'tag_id': tag.id}}">
                                    <span :style="'color:' + tag.color "><i class="fa fa-tag"></i> {{ tag.name | strLimit(40) }}</span>
                                </router-link>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="List">
                    <template v-slot="scope">
                        {{ scope.row.contact_list != null ? scope.row.contact_list.name : '-' }}
                    </template>
                </el-table-column>
                <el-table-column label="Line">
                    <template v-slot="scope">
                        {{ scope.row.campaign != null ? scope.row.campaign.name : '-' }}
                    </template>
                </el-table-column>
                <el-table-column label="Assigned To">
                    <template v-slot="scope">
                        <div class="media">
                            <span v-if="scope.row.assign_contacts_to === ImportAssignContactsTo.ASSIGN_CONTACTS_TO_USER">
                                <h6 class="mb-0">{{ scope.row.user != null ? scope.row.user.name : '-' }}</h6>
                                <small class="text-muted"
                                       v-if="scope.row.user != null">
                                    User
                                </small>
                            </span>
                            <span v-if="scope.row.assign_contacts_to === ImportAssignContactsTo.ASSIGN_CONTACTS_TO_RING_GROUP">
                                <h6 class="mb-0">{{ scope.row.ring_group != null ? scope.row.ring_group.name : '-' }}</h6>
                                <small class="text-muted"
                                       v-if="scope.row.ring_group != null">
                                    Ring Group
                                </small>
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Rows">
                    <template v-slot="scope">
                        {{ scope.row.rows > 0 ? scope.row.rows : '...' }}
                    </template>
                </el-table-column>
                <el-table-column label="Imported">
                    <template v-slot="scope">
                        {{ scope.row.imported }}
                        <span v-if="scope.row.cascade_contacts">(Multiple)</span>
                    </template>
                </el-table-column>
                <el-table-column label="Updated">
                    <template v-slot="scope">
                        {{ scope.row.updated }}
                    </template>
                </el-table-column>
                <el-table-column label="Failed">
                    <template v-slot="scope">
                        <el-button
                            v-if="scope.row.failed > 0"
                            @click="showImportLogs(scope.row, ImportLogFilterTypes.FILTER_FAILED)"
                            type="text"
                            class="text-danger text-decoration-underline">
                            {{ scope.row.failed }}
                        </el-button>
                        <span v-else>{{ scope.row.failed }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Skipped">
                    <template v-slot="scope">
                        <el-button
                            v-if="scope.row.ignored > 0"
                            @click="showImportLogs(scope.row, ImportLogFilterTypes.FILTER_SKIPPED)"
                            type="text"
                            class="text-warning text-decoration-underline">
                            {{ scope.row.ignored }}
                        </el-button>
                        <span v-else>{{ scope.row.ignored }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Status">
                    <template v-slot="scope">
                        <span :class="'import-status-' + ImportStatus.STATUS_DRAFT"
                              v-if="scope.row.status === ImportStatus.STATUS_DRAFT">
                            Draft
                        </span>
                        <span :class="'import-status-' + ImportStatus.STATUS_QUEUED"
                              v-if="scope.row.status === ImportStatus.STATUS_QUEUED">
                            Queued
                        </span>
                        <span :class="'import-status-' + ImportStatus.STATUS_IN_PROGRESS"
                              v-if="scope.row.status === ImportStatus.STATUS_IN_PROGRESS">
                            In Progress
                        </span>
                        <span :class="'import-status-' + ImportStatus.STATUS_FINISHED"
                              v-if="scope.row.status === ImportStatus.STATUS_FINISHED">
                            Finished
                        </span>
                        <span :class="'import-status-' + ImportStatus.STATUS_FAILED"
                              v-if="scope.row.status === ImportStatus.STATUS_FAILED">
                            Failed
                        </span>
                        <span
                            :class="'import-status-' + ImportStatus.STATUS_STOPPED"
                            v-if="scope.row.status === ImportStatus.STATUS_STOPPED">
                            Stopped
                        </span>
                    </template>
                </el-table-column>
                <el-table-column padding="0" width="60px">
                    <template v-slot="scope">
                        <el-dropdown trigger="click">
                            <el-button size="small">
                                <i class="fa fa-ellipsis-v"></i>
                            </el-button>
                            <template v-slot:dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item
                                        :disabled="!isRunnable(scope.row)"
                                        @click.native="rerunImport(scope.row)">
                                        <i class="fas fa-file-import"></i>
                                        Run Import
                                    </el-dropdown-item>
                                    <el-dropdown-item
                                        @click.native="showImportLogs(scope.row)">
                                        <i class="fas fa-exclamation-triangle"></i>
                                        Show Import Logs
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native="deleteImport(scope.row)">
                                        <i class="far fa-trash-alt"></i>
                                        Delete
                                    </el-dropdown-item>
                                    <el-dropdown-item
                                        v-if="[ImportStatus.STATUS_IN_PROGRESS, ImportStatus.STATUS_QUEUED].includes(scope.row.status)"
                                        @click.native="disable(scope.row)">
                                        <strong class="text-danger"><i class="fa fa-remove"></i> Stop</strong>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>

            <div class="py-4 clearfix">
                <el-pagination :current-page.sync="pager.current_page"
                               :hide-on-single-page="false"
                               :page-count="pager.last_page"
                               :page-size="parseInt(pager.per_page)"
                               :page-sizes="[10, 20, 30, 50]"
                               :total="pager.total"
                               @current-change="fetchImports"
                               @size-change="handleSizeChange"
                               background
                               class="pull-right"
                               layout="sizes, prev, pager, next">
                </el-pagination>
            </div>

            <import-wizard ref="importWizard"
                           @onClose="fetchImports"/>
            <import-logs
                :import_contact="selected_import"
                @close-dialog="closeImportLogDialog"
                ref="importLogs">
            </import-logs>
        </div>
    </div>
</template>

<script>
import { cloneDeep, has } from 'lodash'
import auth from "../../auth"
import ImportWizard from '../../components/import-wizard/import-wizard'
import ImportLogs from "../../components/account/import/import-logs"
import * as ImportType from "../../constants/import-types"
import * as ImportStatus from "../../constants/import-status"
import * as ImportAssignContactsTo from '../../constants/import-assign-contacts-to'
import * as ImportLogFilterTypes from '../../constants/import-log-filter-types'
import { kyc_mixin } from '../../mixins'
import Alert from '../../components/alert'

import { TAGS_DEPRECATION_MESSAGE } from '../../constants/deprecation.messages'

export default {
        name: "import-listing",

        mixins: [
            kyc_mixin
        ],

        components: {
            ImportWizard,
            ImportLogs,
            'al-alert': Alert
        },

        data() {
            return {
                auth: auth,
                loading: false,
                imports: [],
                pager: {},
                per_page: 20,
                ImportStatus,
                ImportType,
                type: ImportType.TYPE_CONTACTS,
                ImportAssignContactsTo,
                ImportLogFilterTypes,
                selected_import: null,
                TAGS_DEPRECATION_MESSAGE
            }
        },

        mounted() {
            if (this.$route.query.type) {
                this.type = parseInt(this.$route.query.type)
            }
            this.fetchImports()
        },

        watch: {
            type: function () {
                this.fetchImports(1, this.per_page);
            }
        },

        methods: {
            showImportLogs(contact_import, log_type = ImportLogFilterTypes.FILTER_ALL) {
                if (this.$refs.importLogs) {
                    this.$refs.importLogs.filters.log_type = log_type
                    this.$refs.importLogs.visible = true
                    this.selected_import = cloneDeep(contact_import)
                }
            },
            closeImportLogDialog() {
                this.selected_import = null
            },
            handleSizeChange(per_page) {
                this.per_page = per_page
                this.fetchImports(1)
            },

            fetchImports(page = 1, per_page = 20) {
                this.loading = true
                axios.get('/api/v1/imports', {
                    params: {
                        page: page,
                        per_page: this.per_page,
                        type: this.type
                    }
                }).then(response => {

                    let data = response.data
                    this.imports = data.data
                    this.pager = data

                    this.loading = false
                }).catch(err => {

                })
            },

            startImportWizard() {
                this.$refs.importWizard.start()
            },

            rerunImport(import_model) {
                if (this.type === ImportType.TYPE_CONTACTS) {
                    this.$refs.importWizard.rerun(import_model)
                }
            },

            deleteImport(import_model) {
                this.$confirm(`Are you sure you want to delete this import?`, 'Warning', {
                    confirmButtonText: 'Yes, Remove',
                    cancelButtonText: 'No, Keep',
                    customClass: 'width-500 fixed',
                    type: 'warning',
                }).then(() => {
                    axios.delete(`/api/v1/imports/${import_model.id}`).then(res => {
                        this.imports.splice(this.imports.indexOf(import_model), 1)
                    })
                }).catch(err => {
                    console.log(err)
                })
            },

            isRunnable(import_model) {
                let statuses = [ImportStatus.STATUS_DRAFT, ImportStatus.STATUS_FAILED, ImportStatus.STATUS_STOPPED]
                let import_status = Number(import_model.status)

                return statuses.includes(import_status)
            },

            disable(import_model) {
                this.$confirm(`Are you sure you want to stop this running import?`, 'Warning', {
                    confirmButtonText: 'Yes, Stop',
                    cancelButtonText: 'No, Keep',
                    customClass: 'width-500 fixed',
                    type: 'warning',
                }).then(() => {
                    axios.put(`/api/v1/imports/${import_model.id}/disable`).then(res => {
                        this.fetchImports();
                    }).catch(err => {

                    })
                }).catch(err => {
                    console.log(err)
                })
            }
        },
    }
</script>
