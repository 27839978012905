<template>
    <div data-testid="line-wizard-wrapper">
        <el-steps :active="current_step"
                  align-center
                  finish-status="success"
                  data-testid="line-wizard-steps"
                  class="mb-3">
            <el-step title="Name"
                     data-testid="name-step">
            </el-step>
            <el-step title="Search Phone Number"
                     data-testid="search-step">
            </el-step>
            <el-step title="Line Registration"
                     data-testid="line-registration-step">
            </el-step>
            <el-step title="Finish"
                     data-testid="finish-step">
            </el-step>
        </el-steps>
        <div>
            <div v-show="current_step === Steps.STEP_NAME"
                 class="step-1">
                <el-form ref="campaign_info"
                         label-position="top"
                         label-width="300px"
                         :rules="rules_0"
                         :model="campaign"
                         data-testid="name-step-form"
                         @submit.native.prevent>
                    <template v-if="is_fax">
                        <p class="form-head">
                            Name
                        </p>
                    </template>
                    <template v-else>
                        <p class="form-head">
                            Name and Call Routing
                        </p>
                    </template>
                    <div class="dropdown-divider"></div>
                    <el-form-item
                        data-testid="name-step-item"
                        label="Name"
                        prop="name">
                        <em class="text-muted"
                            v-if="is_fax">
                            Example: Office Fax
                        </em>
                        <template v-if="!is_fax">
                            <em class="text-muted">
                                Example: Local Support Number
                            </em>
                        </template>
                        <div class="row">
                            <div class="col-md-8 col-12">
                                <el-input ref="campaign_name"
                                          data-testid="campaing-name-input"
                                          v-model="campaign.name"
                                          @input="preValidateForm">
                                </el-input>
                            </div>
                            <div class="col-md-4">
                                <el-popover
                                    ref="popover_name"
                                    placement="right-start"
                                    title="Line Name"
                                    width="200"
                                    trigger="hover"
                                    data-testid="line-name-popover"
                                    content="Use a name that is short and easily identifiable in your reports.">
                                </el-popover>
                                <span class="form-hover-help"
                                      v-popover:popover_name>
                                    What should I name this?
                                </span>
                            </div>
                        </div>
                    </el-form-item>
                    <template v-if="!is_fax">
                        <el-form-item prop="call_router_behavior"
                                      data-testid="call-router-behavior-item"
                                      label="Call Routing">
                            <el-popover placement="bottom"
                                        :title="CampaignCallRouterBehavior.FAKE_CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD_RING_GROUP | fakeHumanReadableCallRouterBehavior"
                                        width="400"
                                        trigger="hover"
                                        content="Connect calls to a Ring Group without a queue."
                                        ref="popover_ring_group">
                            </el-popover>

                            <el-popover placement="bottom"
                                        :title="CampaignCallRouterBehavior.FAKE_CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD_USER | fakeHumanReadableCallRouterBehavior"
                                        width="400"
                                        trigger="hover"
                                        content="Connect calls to a User directly."
                                        data-testid="connect-user-popover"
                                        ref="popover_user">
                            </el-popover>

                            <el-popover placement="bottom"
                                        :title="CampaignCallRouterBehavior.FAKE_CALL_ROUTER_BEHAVIOR_MODE_DEAD_END | fakeHumanReadableCallRouterBehavior"
                                        width="400"
                                        trigger="hover"
                                        data-test="dead-end-popover"
                                        content="Do not connect calls & hangup after playing greeting (not recommended)."
                                        ref="popover_dead_end">
                            </el-popover>

                            <el-radio-group v-model="call_router_behavior"
                                            size="small"
                                            data-test="call-router-behavior-radio-group"
                                            @change="changeCallRouterBehavior">
                                <el-radio-button :label="1"
                                                 data-test="ring-group-radio"
                                                 v-popover:popover_ring_group>
                                    {{ CampaignCallRouterBehavior.FAKE_CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD_RING_GROUP | fakeHumanReadableCallRouterBehavior }}
                                </el-radio-button>
                                <el-radio-button :label="2"
                                                 data-testid="forward-user-radio"
                                                 v-popover:popover_user>
                                    {{ CampaignCallRouterBehavior.FAKE_CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD_USER | fakeHumanReadableCallRouterBehavior }}
                                </el-radio-button>
                                <el-radio-button :label="3"
                                                 data-test="mode-dead-end-ratio"
                                                 v-popover:popover_dead_end>
                                    {{ CampaignCallRouterBehavior.FAKE_CALL_ROUTER_BEHAVIOR_MODE_DEAD_END | fakeHumanReadableCallRouterBehavior  }}
                                </el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="Ring Group"
                                      prop="ring_group_id"
                                      data-test="ring-group-item"
                                      v-if="call_router_behavior === CampaignCallRouterBehavior.FAKE_CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD_RING_GROUP"
                                      class="mb-4">
                            <div class="row">
                                <div class="col-md-8 col-12">
                                    <ring-group-selector v-if="hasPermissionTo('list ring group')"
                                                         v-model="campaign.ring_group_id"
                                                         :default_first="true"
                                                         style="width: 300px"
                                                         data-test="ring-group-selector"
                                                         @change="changeRingGroup">
                                    </ring-group-selector>
                                </div>
                                <div class="col-md-4 col-12">
                                    <el-popover ref="popover_ring_group_id"
                                                placement="right-start"
                                                title="Ring Group"
                                                width="200"
                                                trigger="hover"
                                                data-test="ring-group-popover"
                                                content="Delivers the call to a Ring Group when a customer rings.">
                                    </el-popover>
                                    <span class="form-hover-help"
                                          v-popover:popover_ring_group_id>
                                        Which ring group do I use?
                                    </span>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="User"
                                      prop="user_id"
                                      data-testid="user-item"
                                      v-if="call_router_behavior === CampaignCallRouterBehavior.FAKE_CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD_USER"
                                      class="mb-4">
                            <div class="row">
                                <div class="col-md-8 col-12">
                                    <user-selector v-model="campaign.user_id"
                                                   style="width: 300px"
                                                   class="pull-left"
                                                   data-testid="user-selector"
                                                   @change="changeUser">
                                    </user-selector>
                                    <el-popover ref="popover_call_waiting"
                                                placement="top-start"
                                                title="Call Waiting"
                                                width="200"
                                                trigger="hover"
                                                data-testid="call-waiting-popover"
                                                content="Place new calls on hold while on a call">
                                    </el-popover>
                                    <div class="pull-right mt-1 mr-3">
                                        <el-switch v-model="campaign.has_call_waiting"
                                                   data-testid="call-waiting-switch"
                                                   active-color="#00BF50">
                                        </el-switch>
                                        <span v-popover:popover_call_waiting>
                                            Call Waiting
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12">
                                    <el-popover ref="popover_user_id"
                                                placement="right-start"
                                                title="User"
                                                width="200"
                                                trigger="hover"
                                                data-test="user-popover"
                                                content="Delivers the call to a User when a customer rings.">
                                    </el-popover>
                                    <span class="form-hover-help"
                                          v-popover:popover_user_id>
                                        Which user do I use?
                                    </span>
                                </div>
                            </div>
                        </el-form-item>
                    </template>
                    <div class="text-right mt-4">
                        <el-button class="pull-left"
                                   icon="el-icon-arrow-left"
                                   type="info"
                                   data-testid="back-button"
                                   v-if="current_step >= 2"
                                   @click="backForm">
                            Back
                        </el-button>
                        <el-button type="success"
                                   data-testid="next-phone-button"
                                   @click="submitForm"
                                   :disabled="!validated">
                            Next: Select Phone Number
                            <i class="el-icon-arrow-right el-icon-right"></i>
                        </el-button>
                    </div>
                </el-form>
            </div>
            <div v-if="current_step === Steps.STEP_PHONE_NUMBER"
                 data-test="phone-number-step"
                 id="step_2">
                <p class="form-head">
                    Country, Local or Toll-free number, Search with prefix
                </p>
                <div class="dropdown-divider"></div>
                <div>
                    <el-form ref="country_code"
                             label-position="top"
                             class="mb-4"
                             data-testid="country-code-form"
                             :model="campaign"
                             :rules="rules_1"
                             :inline="true">
                        <el-form-item class="w-100"
                                      prop="country_code"
                                      data-testid="country-code-item"
                                      label="Country">
                            <country-selector :show_prefix="true"
                                              :supported_country_codes="supported_country_codes"
                                              :initial_country_code="initial_country_code"
                                              :force_initial_country_code="true"
                                              :show_tips="true"
                                              :disabled="loading"
                                              :phone_number_type="phone_number_type"
                                              data-testid="country-selector"
                                              @changed="changeCountry">
                            </country-selector>
                        </el-form-item>
                    </el-form>
                    <div v-if="campaign.country_code !== null">
                        <div class="row mb-4">
                            <div class="col-12">
                                <el-form v-if="campaign.country_code !== null"
                                         ref="campaign_number_search"
                                         class="mb-2"
                                         label-position="top"
                                         :inline="true"
                                         data-testid="number-search-form"
                                         @submit.prevent.native="changeAreaCode">
                                    <el-form-item class="d-inline-block width-300 fixed">
                                        <label class="el-form-item__label d-inline-block col-12">Number</label>
                                        <el-input type="text"
                                                  pattern="\d*"
                                                  :maxlength="area_code_max_length"
                                                  v-model="number_search"
                                                  clearable
                                                  data-testid="number-search-input"
                                                  @keyup.enter.native="changeAreaCode">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item class="col-3 d-inline-block px-0"
                                                  data-testid="match-to-item">
                                        <label class="el-form-item__label d-inline-block col-12">Match To</label>
                                        <el-select v-model="match_to"
                                                   default-first-option
                                                   filterable
                                                   autocomplete="off"
                                                   data-testid="match-to-select"
                                                   @change="changeMatchTo">
                                            <el-option value="area code"
                                                       label="Area Code"
                                                       :disabled="disableAreaCode">
                                            </el-option>
                                            <el-option value="left"
                                                       label="First part of number">
                                                First part of number
                                            </el-option>
                                            <el-option value="any"
                                                       label="Anywhere in number">
                                                Anywhere in number
                                            </el-option>
                                            <el-option value="right"
                                                       label="Last part of number">
                                                Last part of number
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item class="col-2 d-inline-block pl-0 pr-2 align-bottom"
                                                  data-testid="area-code-item">
                                        <el-button type="primary"
                                                   data-testid="search-button"
                                                   @click.prevent="changeAreaCode"
                                                   :disabled="loading">
                                            <i class="fa fa-search"></i>
                                            Search
                                        </el-button>
                                    </el-form-item>
                                </el-form>
                                <el-form label-position="left"
                                         data-testid="type-form">
                                    <el-form-item class="mb-0 mt-3">
                                        <label class="el-form-item__label col-12 px-0">Type</label>
                                    </el-form-item>
                                </el-form>
                                <el-radio-group v-model="phone_number_type"
                                                size="small"
                                                class="no-select mb-2"
                                                data-testid="phone-number-type-radio-group"
                                                v-if="['US', 'CA'].includes(campaign.country_code)"
                                                @change="changePhoneNumberType">
                                    <el-radio-button :label="IncomingNumberTypes.LOCAL_PHONE_NUMBERS"
                                                     data-testid="local-radio">
                                        Local
                                    </el-radio-button>
                                    <el-radio-button :label="IncomingNumberTypes.TOLL_FREE_NUMBERS"
                                                     data-testid="toll-free-radio">
                                        Toll-free
                                    </el-radio-button>
                                </el-radio-group>
                                <el-radio-group v-model="phone_number_type"
                                                size="small"
                                                class="no-select mb-2"
                                                data-testid="phone-number-type-radio-group"
                                                v-else
                                                @change="changePhoneNumberType">
                                    <el-tooltip :content="local_tooltip"
                                                placement="top"
                                                data-testid="local-tooltip"
                                                :disabled="!shouldDisableLocalOption(campaign.country_code)">
                                        <el-radio-button :label="IncomingNumberTypes.LOCAL_PHONE_NUMBERS"
                                                         :disabled="shouldDisableLocalOption(campaign.country_code)"
                                                         data-testid="local-radio">
                                            Local
                                        </el-radio-button>
                                    </el-tooltip>

                                    <el-tooltip :content="mobile_tooltip"
                                                placement="top"
                                                data-testid="mobile-tooltip"
                                                :disabled="!shouldDisableMobileOption(campaign.country_code)">
                                        <el-radio-button :label="IncomingNumberTypes.MOBILE_PHONE_NUMBERS"
                                                         :disabled="shouldDisableMobileOption(campaign.country_code)"
                                                         data-testid="mobile-radio">
                                            Mobile
                                        </el-radio-button>
                                    </el-tooltip>
                                </el-radio-group>

                                <el-form ref="campaign_incoming_number"
                                         label-position="top"
                                         label-width="300px"
                                         :rules="rules_2"
                                         :model="campaign"
                                         data-testid="incoming-number-form"
                                         v-loading="loading">
                                    <el-form-item prop="incoming_number"
                                                  data-testid="incoming-number-item">
                                        <div v-if="[IncomingNumberTypes.LOCAL_PHONE_NUMBERS, IncomingNumberTypes.MOBILE_PHONE_NUMBERS].includes(phone_number_type)">
                                            <div v-if="!changed">
                                                <label class="el-form-item__label">
                                                    Phone Numbers <span v-if="local_phone_number !== null">to {{ local_phone_number }}</span>
                                                    ({{ auth.user.profile.rate.local_pn | fixRounding(2) | toCurrency }}/mo surcharge)
                                                </label>
                                            </div>
                                            <div v-else>
                                                <label class="el-form-item__label">Phone Numbers {{ searched_string }}</label>
                                            </div>
                                            <div v-if="available_numbers">
                                                <div class="el-form-item__content">
                                                    <el-radio-group v-model="campaign.incoming_number"
                                                                    class="w-full"
                                                                    data-testid="incoming-number-radio-group"
                                                                    @change="changeNumber">
                                                        <div class="row"
                                                             v-for="i in Math.ceil(available_numbers.length / 4)">
                                                            <div class="col-md-3 col-6"
                                                                 v-for="item in available_numbers.slice((i - 1) * 4, i * 4)">
                                                                <el-radio :label="item.sid">
                                                                    {{ item.phone_number | fixPhone }}
                                                                </el-radio>
                                                            </div>
                                                        </div>
                                                        <div class="row"
                                                             v-if="available_numbers.length === 0">
                                                            <div class="col-12">
                                                                <span class="b-l pl-2 text-sm text-muted"
                                                                      data-testid="no-numbers-available">
                                                                    No numbers available
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </el-radio-group>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="phone_number_type === IncomingNumberTypes.TOLL_FREE_NUMBERS">
                                            <label class="el-form-item__label">Toll-Free Numbers ({{ auth.user.profile.rate.tollfree_pn | fixRounding(2) | toCurrency }}/mo surcharge)</label>
                                            <div class="el-form-item__content">
                                                <el-radio-group v-model="campaign.incoming_number"
                                                                class="w-full"
                                                                data-testid="toll-free-number-radio-group"
                                                                @change="changeNumber">
                                                    <div class="row"
                                                         v-for="i in Math.ceil(available_tollfree_numbers.length / 4)">
                                                        <div class="col-md-3 col-6"
                                                             v-for="item in available_tollfree_numbers.slice((i - 1) * 4, i * 4)">
                                                            <el-radio :label="item.sid">
                                                                {{ item.phone_number | fixPhone }}
                                                            </el-radio>
                                                        </div>
                                                    </div>
                                                    <div class="row"
                                                         v-if="available_tollfree_numbers.length === 0">
                                                        <div class="col-12">
                                                            <span class="b-l pl-2 text-sm text-muted"
                                                                  data-testid="no-numbers-available">
                                                                No numbers available
                                                            </span>
                                                        </div>
                                                    </div>
                                                </el-radio-group>
                                            </div>
                                        </div>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-right mt-4">
                    <el-button class="pull-left"
                               icon="el-icon-arrow-left"
                               type="info"
                               data-testid="back-button"
                               @click="backForm">
                        Back
                    </el-button>
                    <el-button type="success"
                               data-testid="activate-fax-line-btn"
                               @click="submitForm"
                               :disabled="!validated || loading">
                        <span v-if="is_fax">
                            Activate Fax
                        </span>
                        <span v-else>
                            Activate Line
                        </span>
                        <i class="el-icon-arrow-right el-icon-right"></i>
                    </el-button>
                </div>
            </div>
            <div v-if="current_step === Steps.STEP_LINE_REGISTRATION"
                 id="step_3"
                 class="text-center">
                <LineRegistration
                    data-testid="line-registration-component"
                    :campaign="campaign_created"
                    :available_messaging_services="available_messaging_services"
                    :available_messaging_services_loading="available_messaging_services_loading"
                    @step-changed="stepChanged"
                />
            </div>
            <div v-if="current_step === Steps.STEP_FINISH"
                 id="step_4"
                 class="text-center">
                <line-details :campaign="campaign_created"
                              data-testid="line-details-component"
                              @close="close">
                </line-details>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {mapActions, mapState} from 'vuex'
import {
    acl_mixin,
    form_validation_mixin,
    phone_wizard_mixin,
    teams_mixin,
    campaign_mixin
} from '../mixins'
import * as CampaignCallRouterBehavior from "../constants/campaign-call-router-behaviors"
import RingGroupSelector from './ring-group-selector'
import LineRegistration from './line-registration'
import * as Steps from '../constants/line-wizard-steps'
import * as IncomingNumberTypes from '../constants/incoming-number-types'
import LineDetails from "./line-details.vue"

export default {
    mixins: [
        acl_mixin,
        form_validation_mixin,
        phone_wizard_mixin,
        teams_mixin,
        campaign_mixin
    ],

    props: {
        is_fax: {
            required: false,
            type: Boolean,
            default: false
        }
    },

    components: {
        LineDetails,
        RingGroupSelector,
        LineRegistration
    },

    data() {
        return {
            available_messaging_services: [],
            available_messaging_services_loading: false,
            auth: auth,
            current_step: 0,
            //percentage: 0,
            campaign: {
                name: null,
                country_code: null,
                phone_number_type: IncomingNumberTypes.LOCAL_PHONE_NUMBERS,
                is_fax: false,
                ring_group_id: null,
                user_id: null,
                incoming_number: null,
                call_router_behavior: null,
                is_tollfree: false,
                has_call_waiting: false
            },
            number_search: null,
            searched_string: '',
            match_to: 'area code',
            available_numbers: [],
            available_tollfree_numbers: [],
            loading: false,
            changed: false,
            success: false,
            campaign_created: null,
            first_user: null,
            CampaignCallRouterBehavior,
            IncomingNumberTypes,
            rules_0: {
                name: [
                    {
                        required: true,
                        message: 'Please provide a line name',
                        trigger: 'blur'
                    },
                ],
                call_router_behavior: [
                    {
                        required: true,
                        message: 'Please select a routing behavior',
                        trigger: 'change'
                    },
                ],
                ring_group_id: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.call_router_behavior === CampaignCallRouterBehavior.FAKE_CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD_RING_GROUP && !value) {
                                callback(new Error('Please select a ring group'))
                            }
                            callback()
                        },
                        trigger: 'blur'
                    }
                ],
                user_id: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.call_router_behavior === CampaignCallRouterBehavior.FAKE_CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD_USER && !value) {
                                callback(new Error('Please select a user'))
                            }
                            callback()
                        },
                        trigger: 'blur'
                    }
                ],
            },
            rules_1: {
                country_code: [
                    {
                        required: true,
                        message: 'Please select a country',
                        trigger: 'blur'
                    }
                ]
            },
            rules_2: {
                incoming_number: [
                    {
                        required: true,
                        message: 'Please select a phone number to proceed',
                        trigger: 'blur'
                    }
                ]
            },
            phone_number_type: IncomingNumberTypes.LOCAL_PHONE_NUMBERS,
            call_router_behavior: CampaignCallRouterBehavior.FAKE_CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD_RING_GROUP,
            initial_country_code: null,
            supported_country_codes: ['US', 'PR', 'CA', 'GB', 'AU'],
            area_code_max_length: 3,
            local_phone_number: null,
            Steps
        }
    },

    computed: {
        ...mapState({
            users: state => state.users,
            ring_groups: state => state.ring_groups,
            current_company: state => state.cache.current_company
        }),

        firstUser() {
            return this.getCampaignFirstUserPhoneNumber(this.campaign, this.ring_groups, this.users, this.all_teams, true)
        },

        disableAreaCode() {
            if (!['US', 'CA'].includes(this.campaign.country_code)) {
                this.match_to = this.match_to === 'area code' ? 'left' : this.match_to

                return true
            }

            return false
        },
    },

    created() {
        if (this.auth.user && this.auth.user.profile && this.auth.user.profile.company) {
            this.initial_country_code = this.auth.user.profile.company.country
        }
    },

    mounted() {
        if (this.ring_groups.length) {
            this.campaign.ring_group_id = this.ring_groups[0].id
        }
        if (this.is_fax) {
            this.campaign.is_fax = true
            this.campaign.call_router_behavior = CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_DEAD_END
        } else {
            this.campaign.call_router_behavior = CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD
        }
        this.setCampaignNameFocus()
        this.getAvailableMessagingServices()
    },

    methods: {
        getAvailableMessagingServices() {
            this.available_messaging_services_loading = true

            axios
                .get('/api/v1/compliance/messaging-services', {
                    params: {
                        is_messaging_service: true,
                        to_attach: true,
                    },
                })
                .then(({data}) => {
                    this.available_messaging_services = data || []
                })
                .finally(() => {
                    this.available_messaging_services_loading = false
                })
        },

        createCampaign() {
            return axios.post('/api/v1/campaign', this.campaign)
                .then(res => {
                    this.newCampaign(res.data)
                    return Promise.resolve(res)
                })
                .catch(err => {
                    return Promise.reject(err)
                })
        },

        searchIncomingNumber(limit) {
            this.available_numbers = []
            this.available_tollfree_numbers = []
            this.campaign.incoming_number = null
            this.validated = false

            if (this.campaign.country_code === 'GB' && !this.canBuyUkLocalNumbers && !this.canBuyUkMobileNumbers) {
                return
            }

            this.loading = true

            if ([this.IncomingNumberTypes.LOCAL_PHONE_NUMBERS, this.IncomingNumberTypes.MOBILE_PHONE_NUMBERS].includes(this.phone_number_type)) {
                axios.get('/api/v1/available-numbers', {
                    params: {
                        is_mobile: (this.phone_number_type == this.IncomingNumberTypes.MOBILE_PHONE_NUMBERS),
                        area_code: this.number_search,
                        match_to: this.match_to,
                        is_fax: this.is_fax,
                        country_code: this.campaign.country_code,
                        limit: limit
                    }
                }).then(res => {
                    this.available_numbers = res.data
                    this.loading = false
                }).catch(err => {
                    if (err.response.data.message?.includes('Local.json was not found')) {
                        this.available_tollfree_numbers = []
                    } else {
                        this.$root.handleErrors(err.response)
                    }
                    this.loading = false
                })
            }

            if (this.phone_number_type == this.IncomingNumberTypes.TOLL_FREE_NUMBERS) {
                axios.get('/api/v1/available-numbers', {
                    params: {
                        is_tollfree: true,
                        area_code: this.number_search,
                        match_to: this.match_to,
                        is_fax: this.is_fax,
                        country_code: this.campaign.country_code,
                        limit: 8
                    }
                }).then(res => {
                    this.available_tollfree_numbers = res.data
                    this.loading = false
                }).catch(err => {
                    if (err.response.data.message.includes('TollFree.json was not found')) {
                        this.available_tollfree_numbers = []
                    } else {
                        this.$root.handleErrors(err.response)
                    }
                    this.loading = false
                })
            }

            this.searched_string = ''
            if (this.number_search) {
                switch (this.match_to) {
                    case 'area code':
                        this.searched_string = 'in the ' + this.number_search + ' area code'
                        break
                    case 'left':
                        this.searched_string = 'with ' + this.number_search + ' on the first part'
                        break
                    case 'right':
                        this.searched_string = 'with ' + this.number_search + ' on the last part'
                        break
                    case 'any':
                        this.searched_string = 'with ' + this.number_search + ' anywhere in it'
                        break
                }
            }
        },

        submitForm() {
            switch (this.current_step) {
                case 0:
                    if (this.validateForm('campaign_info')) {
                        this.local_phone_number = null
                        this.number_search = ''
                        this.first_user = this.firstUser

                        // if we find the user
                        if (this.first_user) {
                            this.local_phone_number = this.first_user.phone_number
                        }

                        this.searchIncomingNumber(12)
                        this.current_step = 1
                        if (this.auth.user && this.auth.user.profile && this.auth.user.profile.company) {
                            this.campaign.country_code = this.auth.user.profile.company.country
                        }
                    }
                    // resets validation
                    this.validated = false
                    break
                case 1:
                    if (this.validateForm('country_code') && this.validateForm('campaign_incoming_number')) {
                        this.loading = true
                        this.createCampaign()
                            .then(res => {
                                this.campaign_created = res.data
                                this.loading = false
                                this.current_step = 2
                                this.$emit('created')
                            })
                            .catch(err => {
                                this.$root.handleErrors(err.response)
                                this.loading = false
                            })
                    }
                    break
                case 2:
                    this.current_step = 3
                    this.success = true
                    this.validated = true
                    break
                default:
                    break
            }
        },

        backForm() {
            this.current_step = this.current_step - 1
            this.validated = true
        },

        preValidateForm(reset = false) {
            let form_names = []
            switch (this.current_step) {
                case 0:
                    form_names = ['campaign_info']
                    break
                case 1:
                    form_names = ['country_code', 'campaign_incoming_number']
                    break
                default:
                    break
            }
            if (reset === true) {
                for (let form_index in form_names) {
                    let form_element = _.get(this.$refs, form_names[form_index], null)
                    if (form_element) {
                        form_element.clearValidate()
                    }
                }
                return
            }
            if (form_names.length > 0) {
                for (let form_index in form_names) {
                    let form_element = _.get(this.$refs, form_names[form_index], null)
                    if (form_element) {
                        let fields = form_element.fields
                        if (fields.find((f) => f.validateState === 'validating')) {
                            setTimeout(() => {
                                this.preValidateForm()
                            }, 100)
                        } else {
                            this.validated = this.validateForm(form_names[form_index]);
                        }
                    }
                }
            }
        },

        changeAreaCode() {
            let phone_number = null
            if (this.first_user) {
                phone_number = this.first_user.phone_number
            }
            this.changed = this.number_search !== this.getAreaCode(phone_number);
            this.preValidateForm()
            this.searchIncomingNumber(12)
        },

        changeNumber() {
            this.preValidateForm()
        },

        changeCountry(country_code) {
            this.preValidateForm()
            this.campaign.country_code = country_code
            this.campaign.is_tollfree = false

            if (country_code === 'GB') {
                if (this.canBuyUkMobileNumbers) {
                    this.phone_number_type = this.IncomingNumberTypes.MOBILE_PHONE_NUMBERS
                } else if (this.canBuyUkLocalNumbers) {
                    this.phone_number_type = this.IncomingNumberTypes.LOCAL_PHONE_NUMBERS
                } else {
                    // Fallback to US
                    this.campaign.country_code = 'US'
                    this.phone_number_type = this.IncomingNumberTypes.LOCAL_PHONE_NUMBERS
                }
            } else {
                this.phone_number_type = this.IncomingNumberTypes.LOCAL_PHONE_NUMBERS
            }

            this.searchIncomingNumber(12)
        },

        changePhoneNumberType() {
            this.number_search = ''
            this.local_phone_number = null
            if (this.first_user) {
                this.local_phone_number = this.first_user.phone_number
            }
            this.campaign.is_tollfree = this.phone_number_type !== this.IncomingNumberTypes.LOCAL_PHONE_NUMBERS
            this.searchIncomingNumber(12)
        },

        changeCallRouterBehavior() {
            switch (this.call_router_behavior) {
                case CampaignCallRouterBehavior.FAKE_CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD_RING_GROUP:
                case CampaignCallRouterBehavior.FAKE_CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD_USER:
                    this.campaign.call_router_behavior = CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD
                    break
                case CampaignCallRouterBehavior.FAKE_CALL_ROUTER_BEHAVIOR_MODE_DEAD_END:
                    this.campaign.call_router_behavior = CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_DEAD_END
                    break
                default:
                    break
            }
            this.preValidateForm()
        },

        changeRingGroup(id) {
            this.campaign.ring_group_id = id
            this.campaign.user_id = null
            this.preValidateForm()
        },

        changeUser(id) {
            this.campaign.user_id = id
            this.campaign.ring_group_id = null
            this.preValidateForm()
        },

        changeMatchTo(match_to) {
            if (match_to === 'area code') {
                this.area_code_max_length = 3
                this.number_search = this.number_search.slice(0, 3)
            } else {
                this.area_code_max_length = 10
            }
            this.preValidateForm()
        },

        getAreaCode(phoneNumber) {
            if (phoneNumber) {
                return this.$options.filters.areaCode(phoneNumber)
            }

            return ''
        },

        close: function () {
            this.$parent.$parent.prop_counter += 1
            this.$parent.$parent.dialogVisible_new = false
        },

        setCampaignNameFocus() {
            Vue.nextTick(() => {
                if (this.$refs.campaign_name) {
                    this.$refs.campaign_name.focus()
                }
            })
        },

        stepChanged() {
            this.submitForm()
        },

        ...mapActions(['newCampaign'])
    },

    watch: {
        'campaign.call_router_behavior': function () {
            setTimeout(() => {
                this.preValidateForm()
            }, 100)
        }
    }
}
</script>
