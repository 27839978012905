<template>
    <el-dialog :width="getModalSize"
               class="dialog-blank"
               top="4vh"
               :before-close="closeDialog"
               :visible="is_visible"
               :append-to-body="true">
        <div class="padding pt-3 mt-3"
             id="container-usage-summary">
            <template>
                <credited-usage-summary
                    :date_range_legend="date_range"
                    :usages="getUsageByChargeType('credit')">
                </credited-usage-summary>
            </template>
        </div>
    </el-dialog>
</template>

<script>

import ImgEmpty from "../../misc/img-empty"
import CreditedUsageSummary from "./dialogs/credited-usage-summary";

export default {
    name: "company-usage-summary-dialog-container",

    components: {ImgEmpty, CreditedUsageSummary},

    props: {
        date_range: {
            required: false,

        },
        usages: {
            required: true,
        },
    },

    computed: {
        getModalSize() {
            const size = window.screen.width <= 1800 ? '75vw' : '60vw'
            return size
        },
    },

    data() {
        return {
            is_visible: false,
            active_view: 'credited-usage',
        }
    },

    methods: {
        show() {
            this.is_visible = true
        },

        closeDialog() {
            this.is_visible = false
        },

        getUsageByChargeType(charge_type) {
            const usage = {};
            for (const [key, value] of Object.entries(this.usages)) {
                if (value.charge_type === charge_type) {
                    usage[key] = value;
                }
            }
            // Add the total resource
            usage.total = this.usages.total;
            return usage;
        },
    },
}
</script>
